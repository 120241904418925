import React from 'react';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import DropDown, { DropDownElement } from '../helpers/DropDown';
import Button from '../helpers/Button';
import AutoComplete from '../helpers/AutoComplete';
import _Input from '../helpers/Input';
import ErrorDisplay from '../decorators/DisplayError';
import PrettyError from '../decorators/PrettyError';
import Select from '../helpers/Select';

const Input = PrettyError(ErrorDisplay(_Input));

const renderSuggestion = suggest => <span>{suggest.text}</span>;

const AddLpContactPopup = props => {
  const {
    onChange,
    onLpChange,
    contact,
    lp,
    isValid,
    suggestRelation,
    onCreate,
    suggestSuffix,
    closable,
    submitting,
    onFindEmail,
  } = props;

  const prefix = contact.get('prefix');
  const prefixes = contact.get('prefixes');
  const suggestions = lp.get('suggests');

  const header = 'Create new LP profile';
  const onClickProps = submitting ? {} : { onClick: onCreate };
  const footer = (
    <Button {...onClickProps} className="btn-primary btn-xs" disabled={!isValid}>
      Create {submitting ? <i className="fa fa-spinner fa-spin" style={{ position: 'static' }} /> : null}
    </Button>
  );

  const prefixesContent = prefixes.map((prefix, i) => {
    const attributes = prefix.get('attributes').toJS();

    return (
      <DropDownElement {...attributes} key={i} value={prefix.get('value')}>
        {prefix.get('name')}
      </DropDownElement>
    );
  });

  const statuses = lp.get('statuses');
  const status = lp.get('lpCurrentStatus');

  return (
    <Popup closable={closable} footer={footer} header={header} id="newExecProf">
      <div className="row">
        <AutoComplete
          checkOn="text"
          formGroupClass="col-md-6"
          getSuggestion={suggestRelation.onFetch}
          getSuggestionValue={suggestRelation.getValue}
          inputProps={{
            autoFocus: true,
            id: '2sitoveryyuolepopiup', // TODO remove this after autosuggest will not require id
            className: 'form-control',
            label: 'Relation',
            name: 'suggestRelation',
          }}
          onSuggestionSelected={suggestRelation.onSelect}
          onUpdateSuggestions={suggestRelation.onUpdate}
          renderSuggestion={renderSuggestion}
          suggestions={suggestions}
          text={suggestRelation.value}
        />
        <div className="col-md-6">
          <Select
            className="input-sm"
            id="f-current-status"
            label="Current Status"
            name="lpCurrentStatus"
            onChange={onLpChange}
            options={statuses}
            selectClassName="text-bold"
            value={status}
            showLabel
          />
        </div>
      </div>

      <div className="row media-450">
        <FormGroup className="col-lg-12 col-sm-3 col-xs-4">
          <label className="control-label" htmlFor="w-full-name3">
            Full Name
          </label>
        </FormGroup>
      </div>

      <div className="row media-450">
        <FormGroup className="col-lg-1 col-sm-3 col-xs-4">
          <DropDown
            btnContent={prefix}
            className="btn-default btn-xs small-line full-width text-right"
            id="newlpProfilePrefix"
            name="prefix"
            onChange={onChange}
            root={contact}
            deep
          >
            {prefixesContent}
          </DropDown>
        </FormGroup>

        <FormGroup className="col-lg-10 col-sm-6 col-xs-4">
          <Input
            className="input-md"
            label="Full Name"
            name="fullName"
            onChange={onChange}
            placeholder="Full Name"
            root={contact}
            deep
          />
        </FormGroup>

        <FormGroup className="col-lg-1 col-sm-3 col-xs-4">{suggestSuffix}</FormGroup>
      </div>

      <div className="clearfix" />

      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-md"
            label="First Name"
            name="firstName"
            onChange={onChange}
            placeholder="First Name"
            root={contact}
            deep
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-md"
            label="Last Name"
            name="lastName"
            onChange={onChange}
            placeholder="Last Name"
            root={contact}
            deep
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Input label="Nick" name="nick" onChange={onChange} placeholder="Nick" root={contact} deep showLabel />
        </FormGroup>
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            label="Pronunciation"
            name="pronounce"
            onChange={onChange}
            placeholder="Pronunciation"
            root={contact}
            deep
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row">
        <FormGroup className="col-lg-5 col-sm-5">
          <Input
            className="input-sm"
            d="f-email3"
            label="Email"
            name="email"
            onChange={onChange}
            placeholder="Email"
            root={contact}
            type="emailVerify"
            deep
            required
            showLabel
          />
        </FormGroup>
        <div className="col-lg-1 col-sm-1">
          <label>&nbsp;</label>
          <div className="clearfix">
            <Button className="btn-primary btn-xs pull-right" onClick={onFindEmail}>
              Finder
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default AddLpContactPopup;
