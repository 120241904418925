/**
 * Prevent drop event.
 *
 * @param {object} event
 */
function dropPrevent(event) {
  if (event.type === 'drop') {
    if (event.stopPropagation) {
      event.preventDefault();
      event.stopPropagation();
    }

    return false; // (IE)
  }
}

function addEvent(el) {
  if (el.addEventListener) {
    el.addEventListener('drop', dropPrevent, true);
  } else if (el.attachEvent) {
    el.attachEvent('ondrop', dropPrevent);
  }
}

function removeEvent(el) {
  if (el.removeEventListener) {
    el.removeEventListener('drop', dropPrevent, true);
  } else if (el.DetachEvent) {
    el.DetachEvent('ondrop', dropPrevent);
  }
}

/**
 * Prevent drop event.
 */
export function preventDrop() {
  addEvent(document);
}

/**
 * Remove code of drop event prevention.
 */
export function allowDrop() {
  removeEvent(document);
}
