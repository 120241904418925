import { CALL_API } from '../../middleware/api';
import uniqueId from '../../utils/uniqueId';
import percent from '../../helpers/percent';

export const SORT_TABLE = Symbol('SORT_TABLE');
export const LOADING_ASSOCIATES = Symbol('LOADING_ASSOCIATES');
export const LOADING_ASSOCIATES_PAGE = Symbol('LOADING_ASSOCIATES_PAGE');
export const LOADED_ASSOCIATES_SUCCESS = Symbol('LOADED_ASSOCIATES_SUCCESS');
export const LOADED_ASSOCIATES_FAILURE = Symbol('LOADED_ASSOCIATES_FAILURE');
export const CHANGE_APPROVAL_GRID = Symbol('CHANGE_APPROVAL_GRID');
export const SAVE_APPROVAL_SUCCESS = Symbol('SAVE_APPROVAL_SUCCESS');
export const SETTING_APPROVED_STATUS = Symbol('SETTING_APPROVED_STATUS');
export const SET_APPROVED_STATUS_SUCCESS = Symbol('SET_APPROVED_STATUS_SUCCESS');
export const SET_APPROVED_STATUS_FAIL = Symbol('SET_APPROVED_STATUS_FAIL');
export const CHANGE_APPROVAL_NUMBER_OF_APPROVED = Symbol('CHANGE_APPROVAL_NUMBER_OF_APPROVED');
export const CHANGE_NO_APPROVAL_NUMBER_OF_APPROVED = Symbol('CHANGE_NO_APPROVAL_NUMBER_OF_APPROVED');
export const CHANGE_APPROVAL_PERCENT_OF_APPROVED = Symbol('CHANGE_APPROVAL_PERCENT_OF_APPROVED');
export const CHANGE_NO_APPROVAL_PERCENT_OF_APPROVED = Symbol('CHANGE_NO_APPROVAL_PERCENT_OF_APPROVED');

const LIMIT = 100;

/**
 * Load associate list.
 *
 * @param id {Number} Approval id.
 * @returns Action.
 */
export function loadAssociates(projectId, approvalId, page, sort) {
  const query = {
    limit: LIMIT,
  };

  if (sort) {
    query.sort = sort;
  }

  if (page) {
    query.page = page;
  }

  const path =
    approvalId === 'no_approval_lists'
      ? `/api/v1/projects/${projectId}/no_approval_lists`
      : `/api/v1/projects/${projectId}/approval_lists/${approvalId}`;

  return {
    [CALL_API]: {
      method: 'get',
      path,
      query,
      unifier: page === 1 ? `/api/v1/projects/${projectId}/approval_lists/LOADING_ASSOCIATES` : undefined,
      maxCount: page === 1 ? 2 : undefined,
      startType: page === 1 ? LOADING_ASSOCIATES : LOADING_ASSOCIATES_PAGE,
      errorType: LOADED_ASSOCIATES_FAILURE,
      successType: LOADED_ASSOCIATES_SUCCESS,
    },
    requestId: uniqueId(),
  };
}

/**
 * Sort associate table by column.
 *
 * @param field {String} Column name.
 * @param direction {String} Sort direction.
 * @returns Action.
 */
export function sort(sorting) {
  return {
    type: SORT_TABLE,
    sorting,
  };
}

export function changeGrid(index, field, value) {
  return {
    type: CHANGE_APPROVAL_GRID,
    index,
    field,
    value,
  };
}

export function saveAssociate({ targetId, linkId, body }) {
  return {
    targetId,
    linkId,
    body,
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/companies/${targetId}/buyers/${linkId}`,
      body: {
        priority: body.pri,
        approved: body.approved,
        bsnClient: body.bsnClient,
        harveyComment: body.harveyComment,
      },
      successType: SAVE_APPROVAL_SUCCESS,
    },
  };
}

/**
 * Set approved status for target buyer in app list.
 *
 * @param {*} index
 * @param {number} targetBuyerId Id of the target buyer.
 * @param {boolean|""} approved Approved state.
 * @param {number} projectId Id of the project.
 * @param {number|string} approvalId Id of the current approval.
 * @param {boolean|""} prevApprovedState Previous approved state.
 */
export function setApprovedStatus({ index, targetBuyerId, approved, projectId, approvalId, prevApprovedState }) {
  return {
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/projects/${projectId}/target_buyers/${targetBuyerId}`,
      unifier: `put /api/v1/projects/${projectId}/target_buyers/${targetBuyerId} ${targetBuyerId}${approved}${projectId}`,
      body: {
        approved,
      },
      startType: SETTING_APPROVED_STATUS,
      errorType: SET_APPROVED_STATUS_FAIL,
      successType: SET_APPROVED_STATUS_SUCCESS,
      afterSuccess: ({ dispatch, getState }) => {
        const { common } = getState().approval;

        if (approvalId === 'no_approval_lists') {
          const totalApproved = common.getIn(['noApproval', 'numberApproved']);
          const numberOfTargets = common.getIn(['noApproval', 'numberTargets']);

          const newTotalApproved = resolveNewTotalApproved(prevApprovedState, approved, totalApproved);

          dispatch(changeNoApprovalApprovedNumber(newTotalApproved));
          dispatch(changeNoApprovalApprovedPercent(newTotalApproved, numberOfTargets));
        } else {
          const totalApproved = common.getIn(['approval', 'numberApproved']);
          const numberOfTargets = common.getIn(['approval', 'numberTargets']);

          const newTotalApproved = resolveNewTotalApproved(prevApprovedState, approved, totalApproved);

          dispatch(changeApprovalApprovedNumber(newTotalApproved));
          dispatch(changeApprovalApprovedPercent(newTotalApproved, numberOfTargets));
        }
      },
    },
    index,
    approved,
  };
}

/**
 * Action changing number of approved targets for approval list.
 *
 * @param {number} approvedCount Number of approved targets.
 */
function changeApprovalApprovedNumber(approvedCount) {
  return {
    type: CHANGE_APPROVAL_NUMBER_OF_APPROVED,
    approvedCount,
  };
}

/**
 * Action changing number of approved targets for no approval list.
 *
 * @param {number} approvedCount Number of approved targets.
 */
function changeNoApprovalApprovedNumber(approvedCount) {
  return {
    type: CHANGE_NO_APPROVAL_NUMBER_OF_APPROVED,
    approvedCount,
  };
}

/**
 * Action changing percent of approved targets for approval list.
 *
 * @param {number} approvedCount Number of approved targets.
 * @param {number} numberOfTargets Total number of targets in approval list.
 */
function changeApprovalApprovedPercent(approvedCount, numberOfTargets) {
  return {
    type: CHANGE_APPROVAL_PERCENT_OF_APPROVED,
    percent: percent(approvedCount, numberOfTargets),
  };
}

/**
 * Action changing percent of approved targets for no approval list.
 *
 * @param {number} approvedCount Number of approved targets.
 * @param {number} numberOfTargets Total number of targets in no approval list.
 */
function changeNoApprovalApprovedPercent(approvedCount, numberOfTargets) {
  return {
    type: CHANGE_NO_APPROVAL_PERCENT_OF_APPROVED,
    percent: percent(approvedCount, numberOfTargets),
  };
}

/**
 * Resolve complete count of approved targets.
 *
 * @param {boolean|""} prevApproved Previous approved state.
 * @param {boolean|""} currentApproved Current approved state.
 * @param {number} totalApprovedCount Total approved number of targets.
 * @returns {number} Complete count of approved targets.
 */
function resolveNewTotalApproved(prevApproved, currentApproved, totalApprovedCount) {
  if (prevApproved && !currentApproved) {
    return (totalApprovedCount -= 1);
  }

  if (!prevApproved && currentApproved) {
    return (totalApprovedCount += 1);
  }

  return totalApprovedCount;
}
