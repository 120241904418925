import isFunction from '../../utils/isFunction';

const DEFAULT_MESSAGE = 'Please, confirm action.';

export default function confirm(message = DEFAULT_MESSAGE, onOk, context) {
  if (isFunction(message)) return confirm(undefined, message, onOk);

  return function(...rest) {
    const name = 'ConfirmPopup';
    const onCancel = context.closePopup;
    const props = {
      message,
      onCancel,
      onOk: () => onOk(...rest),
    };

    context.openPopup(name, props);
  };
}
