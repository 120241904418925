import React, { PureComponent } from 'react';

import Checkbox from '../../../helpers/Checkbox';

class CriteriaCheckbox extends PureComponent {
  render() {
    const { props } = this;
    const { buyer: root, label, name, onChange, disabled } = props;

    return (
      <div className="form-group group-inline b-checkbox ib">
        <Checkbox deep="checked" disabled={disabled} label={label} name={name} onChange={onChange} root={root} />
      </div>
    );
  }
}

export default class CriteriaCheckboxes extends PureComponent {
  render() {
    const { buyer: root, onChange, disabled } = this.props;

    return (
      <form onChange={onChange}>
        <CriteriaCheckbox buyer={root} disabled={disabled} label="Buyout" name="buyout" onChange={onChange} />
        <CriteriaCheckbox
          buyer={root}
          disabled={disabled}
          label="Take Private"
          name="takePrivate"
          onChange={onChange}
        />
        <CriteriaCheckbox buyer={root} disabled={disabled} label="Recap" name="recap" onChange={onChange} />
        <CriteriaCheckbox buyer={root} disabled={disabled} label="Minority" name="minority" onChange={onChange} />
        <CriteriaCheckbox buyer={root} disabled={disabled} label="Mezz" name="mezz" onChange={onChange} />
        <CriteriaCheckbox buyer={root} disabled={disabled} label="Turnaround" name="turnaround" onChange={onChange} />
        <CriteriaCheckbox buyer={root} disabled={disabled} label="Carve-out" name="carveOut" onChange={onChange} />
      </form>
    );
  }
}
