import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { fromJS, List, Map } from 'immutable';
import PropTypes from 'prop-types';

import { InputProps } from '../helpers/AutoComplete';
import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import Select from '../helpers/Select';
import Checkbox from '../helpers/Checkbox';
import FormGroup from '../helpers/FormGroup';

/**
 * Run windward report popup.
 *
 * @param {object} props Props.
 * @param {object} props.firstDealMaker FirstDealMaker suggests element.
 * @param {boolean} props.isDisabledRunButton Whether submit button is disabled or not.
 * @param {object} props.module Module suggests element.
 * @param {Function} props.onCheckboxFieldUpdate Handle checkbox's field update.
 * @param {Function} props.onClose Handle popup close.
 * @param {Function} props.onRadioFieldUpdate Handle radio button's field update.
 * @param {Function} props.onRunWindwardReport Handle popup submit.
 * @param {Function} props.onSelectReport Handle select report type.
 * @param {boolean} props.popupLoading Whether popup is loading or not.
 * @param {boolean} props.researchAnalyst ResearchAnalyst suggests element.
 * @param {object} props.secondDealMaker SecondDealMaker suggests element.
 * @param {string} props.selectedTemplateType Selected template type.
 * @param {List} props.templates Template types list.
 *
 */
const RunWindwardReportPopup = props => {
  const [radioFieldsValues, setRadioFieldsValues] = useState(new Map());

  useEffect(() => {
    setInitRadioFieldsValues(selectedTemplate);
  }, [props.selectedTemplateType]);

  const loading = props.popupLoading ? (
    <div className="loading">
      <i className="fa fa-spinner fa-pulse fa-3x fa-fw login-spinner" />
    </div>
  ) : null;
  const header = 'Run Windward Report';
  const footer = [
    <Button
      key="run"
      className="btn-primary btn-xs"
      disabled={props.isDisabledRunButton}
      onClick={props.onRunWindwardReport}
    >
      Run
    </Button>,
    <Button key="close" className="btn-default btn-xs" onClick={props.onClose}>
      Close
    </Button>,
  ];

  const templates = props.templates.map(template =>
    Map({
      name: template.get('title'),
      value: template.get('name'),
    }),
  );

  const selectedTemplate = props.templates.filter(
    template => fromJS(props.selectedTemplateType) === template.get('name'),
  );

  const formContent = selectedTemplate?.map(template => {
    const checkboxFields = template.get('checkboxFields');
    const suggestedFields = template.get('suggestedFields');
    const radioFields = template.get('radioFields');
    let checkboxContent = null;
    let suggestedContent = null;
    let radioContent = null;

    if (checkboxFields?.size > 0) {
      checkboxContent = checkboxFields.map(field => (
        <Checkbox
          key={field.get('name')}
          className="mb10"
          label={field.get('title')}
          labelClassName="mb10"
          name={field.get('name')}
          onChange={props.onCheckboxFieldUpdate}
        />
      ));
    }

    if (suggestedFields?.size > 0) {
      const sortedFields = suggestedFields.sortBy(field => field.get('title'));

      suggestedContent = sortedFields.map(field => (
        <InputProps key={field.get('name')} label={field.get('title')} name={field.get('name')}>
          {getSuggest(field.get('name'))}
        </InputProps>
      ));
    }

    if (radioFields?.size > 0) {
      radioContent = radioFields.map(field => {
        const radioButtons = field.get('values').map(item => {
          const key = field.get('name');
          const value = radioFieldsValues.get(key);

          return (
            <div key={item.get('name')} className="radio-wrap radio-wrap--reverse pr16">
              <label htmlFor={item.get('name')}>{item.get('title')}</label>
              <input
                checked={value === item.get('name')}
                id={item.get('name')}
                name={field.get('name')}
                onChange={event => onRadioFieldUpdate(event, key)}
                type="radio"
                value={item.get('name')}
              />
            </div>
          );
        });

        return (
          <FormGroup key={field.get('name')} className="d-flex">
            {radioButtons}
          </FormGroup>
        );
      });
    }

    return (
      <div key={template.get('name')} className="windward-report-fields__form-content">
        {checkboxContent}
        {suggestedContent}
        {radioContent}
      </div>
    );
  });

  function setInitRadioFieldsValues(selectedTemplate) {
    const radioFields = selectedTemplate.get(0)?.get('radioFields');

    if (radioFields?.size > 0) {
      radioFields.forEach(field => {
        const key = field.get('name');
        const initValue = field
          .get('values')
          .get(0)
          .get('name');

        setRadioFieldsValues(radioFieldsValues.set(key, initValue));
        props.onRadioFieldUpdate({
          target: {
            name: key,
            value: initValue,
          },
        });
      });
    }
  }

  function onRadioFieldUpdate(event, fieldKey) {
    setRadioFieldsValues(radioFieldsValues.set(fieldKey, event.target.value));
    props.onRadioFieldUpdate(event);
  }

  function getSuggest(name) {
    switch (name) {
      case 'firstDealMaker':
        return props.firstDealMaker;

      case 'secondDealMaker':
        return props.secondDealMaker;

      case 'researchAnalyst':
        return props.researchAnalyst;

      default:
        return null;
    }
  }

  return (
    <Popup footer={footer} header={header} id="runWindwardReportPopup" loading={loading}>
      <div className="flex-stretched">
        <div className="modal-control">
          <InputProps label="Select Module:" name="module">
            {props.module}
          </InputProps>
        </div>
        <Select
          id="dropdownType"
          label="Select Report:"
          name="windwardReportType"
          onChange={props.onSelectReport}
          options={templates}
          wrapperClassName={classNames('modal-control')}
          showLabel
        />
      </div>
      <div className="windward-report-fields">
        <span className="text-bold">Optional Fields:</span>
        <form>{formContent}</form>
      </div>
    </Popup>
  );
};

RunWindwardReportPopup.propTypes = {
  firstDealMaker: PropTypes.instanceOf(Object).isRequired,
  isDisabledRunButton: PropTypes.bool.isRequired,
  module: PropTypes.instanceOf(Object).isRequired,
  onCheckboxFieldUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRadioFieldUpdate: PropTypes.func.isRequired,
  onRunWindwardReport: PropTypes.func.isRequired,
  onSelectReport: PropTypes.func.isRequired,
  popupLoading: PropTypes.bool.isRequired,
  secondDealMaker: PropTypes.instanceOf(Object).isRequired,
  selectedTemplateType: PropTypes.string.isRequired,
  templates: PropTypes.instanceOf(List).isRequired,
};

export default RunWindwardReportPopup;
