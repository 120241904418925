import React from 'react';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import DropDown, { DropDownElement } from '../helpers/DropDown';
import Button from '../helpers/Button';
import AutoComplete, { InputProps } from '../helpers/AutoComplete';
import _Input from '../helpers/Input';
import _Textarea from '../helpers/Textarea';
import ErrorDisplay from '../decorators/DisplayError';
import PrettyError from '../decorators/PrettyError';

const Input = PrettyError(ErrorDisplay(_Input));
const Textarea = PrettyError(ErrorDisplay(_Textarea));

const yearMap = {
  value: {
    '-1': '?',
  },
};
const renderSuggestion = suggest => <span>{suggest.text}</span>;

const EditContactPopup = props => {
  const { companyId, titleProps, onChange, contact, prefixes, isValid, suffixesSuggest, onSave } = props;

  const prefix = contact.get('prefix');
  const isSubContact = companyId !== contact.getIn(['pivot', 'entityId']);
  const parentCompanyName = contact.get('entityName');

  const header = (
    <div>
      Change contact profile
      <br />
      <div className="modal-warning" hidden={!isSubContact}>
        You are editing data for a contact at {parentCompanyName}
      </div>
    </div>
  );
  const footer = (
    <Button className="btn-primary btn-xs" disabled={!isValid} onClick={onSave}>
      Save
    </Button>
  );

  const prefixesContent = prefixes.map((prefix, i) => {
    const attributes = prefix.get('attributes').toJS();

    return (
      <DropDownElement {...attributes} key={i} value={prefix}>
        {prefix.get('name')}
      </DropDownElement>
    );
  });

  const advisorInfo = titleProps.showExtraInfo ? (
    <div className="row mb15">
      <FormGroup className="col-lg-4 col-sm-4">
        <Input
          label="Company"
          name="pivot.company"
          onChange={onChange}
          placeholder="Company"
          value={contact.getIn(['pivot', 'company'])}
          showLabel
        />
      </FormGroup>
      <FormGroup className="col-lg-4 col-sm-4">
        <Input
          className="input-md"
          label="Title"
          name="pivot.advTitle"
          onChange={onChange}
          placeholder="Title"
          value={contact.getIn(['pivot', 'advTitle'])}
          showLabel
        />
      </FormGroup>
      <FormGroup className="col-lg-4 col-sm-4">
        <Input
          className="input-md"
          label="Web"
          name="pivot.advWeb"
          onChange={onChange}
          placeholder="Web"
          value={contact.getIn(['pivot', 'advWeb'])}
          showLabel
        />
      </FormGroup>
    </div>
  ) : null;

  return (
    <Popup footer={footer} header={header} id="newExecProf">
      <div className="row media-450">
        <FormGroup className="col-lg-3 col-sm-3 col-xs-4">
          <label className="control-label" htmlFor="w-full-name3">
            Full Name
          </label>
          <DropDown
            btnContent={prefix}
            className="btn-default btn-xs small-line full-width text-right"
            id="newlpProfilePrefix"
            name="prefix"
            onChange={onChange}
            root={contact}
            deep
          >
            {prefixesContent}
          </DropDown>
        </FormGroup>

        <FormGroup className="col-lg-6 col-sm-6 col-xs-4">
          <label className="control-label" htmlFor="w-full-name3" />
          <Input
            className="input-md"
            id="w-full-name7"
            label="Full Name"
            name="fullName"
            onChange={onChange}
            placeholder="Full Name"
            root={contact}
            deep
          />
        </FormGroup>

        <FormGroup className="col-lg-3 col-sm-3 col-xs-4">
          <label className="control-label" htmlFor="w-full-name3" />
          {suffixesSuggest}
        </FormGroup>
      </div>

      <div className="clearfix" />

      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-md"
            label="First Name"
            name="firstName"
            onChange={onChange}
            placeholder="First Name"
            root={contact}
            deep
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-md"
            label="Last Name"
            name="lastName"
            onChange={onChange}
            placeholder="Last Name"
            root={contact}
            deep
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Input label="Nick" name="nick" onChange={onChange} placeholder="Nick" root={contact} deep showLabel />
        </FormGroup>
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            label="Pronunciation"
            name="pronounce"
            onChange={onChange}
            placeholder="Pronunciation"
            root={contact}
            deep
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row">
        <InputProps className="form-control input-sm" label="Title" name="pivot.title" placeholder="Title" required>
          <AutoComplete
            checkOn="text"
            formGroupClass="col-lg-6 col-sm-6"
            getSuggestion={titleProps.onFetch}
            getSuggestionValue={titleProps.getValue}
            loading={titleProps.loading}
            minSuggestLength={0}
            onSuggestionSelected={titleProps.onSelect}
            onUpdateSuggestions={titleProps.onUpdate}
            renderSuggestion={renderSuggestion}
            suggestions={titleProps.suggestions}
            text={titleProps.value}
          />
        </InputProps>
        <FormGroup className="col-lg-3 col-sm-3" hidden={isSubContact}>
          <Input
            label="% ownership"
            maxLength="20"
            name="ownershipPercent"
            onChange={onChange}
            placeholder="%"
            prefix="pivot"
            root={contact}
            deep
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-3 col-sm-3">
          <Input
            label="Age"
            maxLength="3"
            name="age"
            onChange={onChange}
            placeholder="Age"
            root={contact}
            deep
            showLabel
          />
        </FormGroup>
      </div>
      {advisorInfo}
      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Textarea
            label="Contact Notes"
            name="notes"
            onChange={onChange}
            placeholder="Contact Notes"
            prefix="pivot"
            root={contact}
            rows="3"
            deep
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-3 col-xs-3" hidden={isSubContact}>
          <Input
            className="input-sm"
            label="Start"
            name="startYear"
            onChange={onChange}
            placeholder="Start"
            prefix="pivot"
            root={contact}
            deep
            required
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-3 col-xs-3" hidden={isSubContact}>
          <Input
            className="input-sm"
            displayMap={yearMap}
            label="End"
            name="endYear"
            onChange={onChange}
            placeholder="End"
            prefix="pivot"
            root={contact}
            deep
            required
            showLabel
          />
        </FormGroup>
      </div>
    </Popup>
  );
};

export default EditContactPopup;
