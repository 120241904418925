import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { unwrap as _unwrap } from '../../../utils/ChangeSpy';
import UrlMaker from '../../../routing/UrlMaker';
import config from '../../../config';
import Textarea from '../../helpers/Textarea';
import { EmailTemplatesContextMenuWrapper } from '../../helpers/EmailTemplatesContextMenuWrapper';
import { getSafeLink } from '../../../utils/url';
import uniqueId from '../../../utils/uniqueId';
import downloadFile from '../../../utils/downloadFile';
import If from '../../helpers/If';
import ItemSorter from './ItemSorter';
import CompanyContactsLabels from './CompanyContactsLabels';
import './styles.scss';

const vCardUrl = config.values.getIn(['contact', 'vCardUrl'], '');

const unwrap = value => {
  value = _unwrap(value);
  if (value && value.get) value = value.get('name', '');

  return value;
};

const ContactName = props => {
  const { contact } = props;
  const prefix = unwrap(contact.get('prefix', ''));
  const fullName = unwrap(contact.get('fullName', ''));
  const suffix = unwrap(contact.get('suffix', ''));
  const endYear = unwrap(contact.getIn(['pivot', 'endYear']));
  let className = '';

  if (parseInt(endYear, 10) > 1) className = 'text-muted';

  return (
    <b className={className}>
      {prefix} {fullName} {suffix}
    </b>
  );
};

const ContactPosition = props => {
  const { contact } = props;

  const title = unwrap(contact.getIn(['pivot', 'title'], ''));
  const endYear = unwrap(contact.getIn(['pivot', 'endYear'], ''));
  let workTime = '';

  if (endYear && endYear !== -1) {
    workTime = (
      <i>
        ({unwrap(contact.getIn(['pivot', 'startYear'], '')) || '?'}-{endYear})
      </i>
    );
  }

  return (
    <small>
      {title} {workTime}
    </small>
  );
};

const AdvisorExtra = props => {
  const { contact } = props;
  const isAdvisor = unwrap(contact.getIn(['pivot', 'title'], '')).toUpperCase() === 'ADVISOR';

  if (!isAdvisor) return null;

  const title = unwrap(contact.getIn(['pivot', 'advTitle'], ''));
  const company = unwrap(contact.getIn(['pivot', 'company'], ''));
  const web = getSafeLink(unwrap(contact.getIn(['pivot', 'advWeb'], '')), '');

  return (
    <div>
      <small>
        {title}, {company}{' '}
        {web ? (
          <a href={`//${web}`} rel="noopener noreferrer" target="_blank">
            ({web})
          </a>
        ) : null}
      </small>
    </div>
  );
};

const ContactExtraField = props => {
  const { contact, field, wrapper = v => v } = props;
  const val = unwrap(contact.getIn(field.split('.')));

  if (!val) return null;

  return <span>{wrapper(val)}</span>;
};

const getOwnerLabel = value => {
  const result = isNaN(value) ? `${value} owner` : `${value}% owner`;

  return <span>{result}</span>;
};

const CompanyActiveContact = props => {
  const { contact, onDel, onCopy, onEdit, onEditChannel, reorderChannels, canEditData } = props;
  const mainClassName = classNames(canEditData ? 'clickable' : 'unclickable', {
    'text-italic text-muted': unwrap(contact.getIn(['pivot', 'endYear'], '')),
  });

  return (
    <div className="project-contacts__tab tab-content nav-stacked">
      <div className="tab-pane fade in active" id="home">
        <div className={mainClassName} onClick={onEdit}>
          <div>
            <ContactName contact={contact} />
          </div>
          <div>
            <ContactPosition contact={contact} />
          </div>
          <AdvisorExtra contact={contact} />
        </div>
        <div className={classNames(canEditData ? 'clickable' : 'unclickable')} onClick={onEdit}>
          <small>
            <ContactExtraField contact={contact} field="nick" wrapper={v => `"${v}"`} />
            <ContactExtraField contact={contact} field="pronounce" wrapper={v => <i>({v})</i>} />
          </small>
        </div>
        <div className={classNames(canEditData ? 'clickable' : 'unclickable', 'text-right')} onClick={onEdit}>
          <small>
            <ContactExtraField contact={contact} field="pivot.ownershipPercent" wrapper={v => getOwnerLabel(v)} />
          </small>
          <div className="text-right" onClick={onEdit}>
            <small>
              <ContactExtraField contact={contact} field="age" wrapper={v => <span>age {v}</span>} />
            </small>
          </div>
        </div>
        <Textarea
          className="form-control"
          disabled={!canEditData}
          name="notes"
          onChange={onEdit}
          onDrop={event => {
            event.preventDefault();
          }}
          placeholder="Contact notes"
          root={contact.get('pivot')}
          deep
        />
        <div className="mb0 list adr">
          <ItemSorter
            className="mb10"
            items={contact.get('phones').toJS()}
            name="phones"
            onCopy={onCopy}
            onDel={onDel}
            onEditChannel={onEditChannel}
            readOnly={!canEditData}
            reorderChannels={reorderChannels}
          />
          <ItemSorter
            items={contact.get('emails').toJS()}
            name="emails"
            onCopy={onCopy}
            onDel={onDel}
            onEditChannel={onEditChannel}
            readOnly={!canEditData}
            reorderChannels={reorderChannels}
            verifyField="isVerify"
            isVisibleVerifyIcon
          />
        </div>
      </div>
    </div>
  );
};

const CompanyActiveContactLinks = props => {
  const {
    contact,
    addContact,
    onEditChannel,
    hasPermissions,
    companyInfo,
    emailTemplates,
    onOpenEmail,
    fetchEmailTemplates,
    findDirectors,
  } = props;
  const content = [];

  const linkedInLink = contact.get('linkedin', '');
  let linkedIn = null;

  if (linkedInLink) {
    linkedIn = (
      <li>
        <a href={linkedInLink} rel="noopener noreferrer" target="_blank">
          <i className="fa fa-linkedin-square" title="Open LinkedIn" />
        </a>
      </li>
    );
  }

  contact.get('emails', []).some((email, i) => {
    const emailValue = unwrap(email.get('email', ''));

    content.push(
      <li key={`email_${i}`}>
        <EmailTemplatesContextMenuWrapper
          companyInfo={companyInfo}
          email={emailValue}
          emailTemplates={emailTemplates}
          fetchEmailTemplates={fetchEmailTemplates}
          findDirectors={findDirectors}
          id={uniqueId()}
          onOpenEmail={onOpenEmail}
          templateType="company"
        >
          <i className="fa fa-envelope" title="Send a email" />
        </EmailTemplatesContextMenuWrapper>
      </li>,
    );

    return true;
  });

  const handleDownloadVCard = event => {
    event.preventDefault();
    downloadFile({
      url: UrlMaker.create(vCardUrl).mapParams({ contactId: contact.get('id') }),
    });
  };

  content.push(
    <li key="vcard">
      <a onClick={handleDownloadVCard}>
        <i className="fa fa-file-text" title="Download a vCard" />
      </a>
    </li>,
  );

  content.push(
    <li key="profile">
      <a href={`/contact/${contact.get('id')}`} rel="noopener noreferrer" target="_blank">
        <i className="fa fa-reply" title="Open this persons's full profile" />
      </a>
    </li>,
  );

  return (
    <ul>
      <li>
        <i className="fa fa-plus-square" onClick={addContact} title="Add a new contact at this company" />
      </li>
      {linkedIn}
      {content.map(reactElement => (
        <If key={reactElement.key} value={hasPermissions}>
          {reactElement}
        </If>
      ))}
      <li>
        <i className="fa fa-plus-square-o" onClick={() => onEditChannel('phones', 'new')} title="Add new phone" />
      </li>
      <li>
        <i className="fa fa-plus-square-o" onClick={() => onEditChannel('emails', 'new')} title="Add new email" />
      </li>
    </ul>
  );
};

CompanyActiveContactLinks.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

/**
 */
const CompanyContacts = props => {
  const {
    canEditData,
    contacts,
    opened,
    addContact,
    delContact,
    openContact,
    onDel,
    onCopy,
    onEdit,
    onEditChannel,
    reorderContacts,
    reorderChannels,
    companyInfo,
    findDirectors,
    fetchEmailTemplates,
    emailTemplates,
    onOpenEmail,
    hasPermissions,
  } = props;

  if (!contacts || !contacts.size)
    return (
      <div className="row text-center highlight mb0">
        <p>No contacts yet</p>
        <If value={canEditData}>
          <p>
            <a onClick={addContact}>Add one</a>
          </p>
        </If>
      </div>
    );

  const handleDeleteClick = (event, ...rest) => {
    if (canEditData) {
      return delContact(event, ...rest);
    }
  };

  return (
    <div className="project-contacts highlight wrap-border-full">
      <div className="col-xs-3 pl0">
        <CompanyContactsLabels
          canEditData={canEditData}
          contacts={contacts}
          onClick={openContact}
          onDel={handleDeleteClick}
          opened={opened}
          reorderContacts={reorderContacts}
        />
      </div>
      <div className="col-xs-9 pr0">
        <CompanyActiveContact
          canEditData={canEditData}
          contact={contacts.get(opened)}
          onCopy={onCopy}
          onDel={onDel}
          onEdit={onEdit}
          onEditChannel={onEditChannel}
          reorderChannels={reorderChannels}
        />
      </div>
      <If value={canEditData}>
        <div className="project-contacts-settings text-right">
          <CompanyActiveContactLinks
            addContact={addContact}
            companyInfo={companyInfo}
            contact={contacts.get(opened)}
            emailTemplates={emailTemplates}
            fetchEmailTemplates={fetchEmailTemplates}
            findDirectors={findDirectors}
            hasPermissions={hasPermissions}
            onEditChannel={onEditChannel}
            onOpenEmail={onOpenEmail}
          />
        </div>
      </If>
      <div className="clearfix" />
    </div>
  );
};

export { CompanyContacts };
export default CompanyContacts;
