import { fromJS } from 'immutable';

import * as ActionDetailType from '../../../actions/companyDetail';
import * as ActionType from '../../../actions/company/buyer/info';
import { SAVED_COMPANY_BUYER as SAVED_COMPANY_BUYER_CEO } from '../../../actions/company/buyer/ceo';
import config from '../../../config';
import handleApiError from '../../decorators/handleApiError';
import highStatusActualize from '../../decorators/highStatusActualize';
import { autoRemoveId, autoNullId } from '../../decorators/suggest';
import getValidator from '../../../utils/getValidator';
import { spy, setIn, getIn, toJS, revertToOriginal } from '../../../utils/ChangeSpy';

const statuses = config.values.getIn(['company', 'statuses']);

const SCHEMA = {
  type: 'object',
};

const FIELDS_TO_SPY = [
  'buyerCurrentStatus',
  'buyerHighStatus',
  'buyerPriority',
  'buyerHarvcoLeadId',
  'buyerUserSuggest',
];

const validator = getValidator(SCHEMA);

const defaultState = spy(
  fromJS({
    statuses,
    loaded: false,
    isValid: true,
    inputErrors: {},
    buyerCurrentStatus: statuses.getIn([0, 'value']),
    buyerHighStatus: statuses.getIn([0, 'value']),
    highStatusUpdated: '',
    buyerPriority: '',
    buyerHarvcoLeadId: null,
    buyerUserSuggest: '',
    subsidiaries: [{}],
    ceo123: '',
    ceoAbc: '',
    buyerUser: {
      id: 0,
      userName: '',
    },
    type: null,
  }),
  FIELDS_TO_SPY,
);

let wrappedReducer = reducer; // (state, action) => checkValidity(reducer(state, action), action);

wrappedReducer = highStatusActualize(/CurrentStatus$/, 'HighStatus', ActionType.UPDATE_COMPANY, wrappedReducer);
wrappedReducer = autoRemoveId('buyerUserSuggest', 'buyerHarvcoLeadId', ActionType.UPDATE_COMPANY, wrappedReducer);
wrappedReducer = autoNullId('buyerUserSuggest', 'buyerHarvcoLeadId', ActionType.UPDATE_COMPANY, wrappedReducer);

wrappedReducer = handleApiError(ActionType.ERRORED_COMPANY_BUYER, wrappedReducer);

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.UPDATE_COMPANY:
      return checkValidity(changeField(state, action));

    case ActionType.REVERT_UPDATE:
      return checkValidity(revertToOriginal(state, action.field));

    case ActionType.LOADED_USERS:
      return state.setIn(
        ['suggests'],
        action.page === 1 ? fromJS(action.response.data) : state.get('suggests').concat(action.response.data),
      );

    case ActionType.FETCH_SUBSIDIARIES_SUCCESS:
      return state.set('subsidiaries', fromJS(action.response.data.portstat || [{}]));

    case ActionDetailType.FETCHING_COMPANY_SUCCESS:
      return spy(state.merge(mapResponse(action)), FIELDS_TO_SPY);

    case SAVED_COMPANY_BUYER_CEO:
      return action.fields.reduce((ret, field) => {
        if (['ceo123', 'ceoAbc'].indexOf(field) > -1) {
          return ret.set(field, action.body[field]);
        }

        return ret;
      }, state);

    case ActionDetailType.SAVE_COMPANY_SUCCESS: {
      return spy(state.merge(action.body), FIELDS_TO_SPY);
    }

    case ActionType.SAVED_COMPANY_BUYER:
      return spy(setValueAsOriginalByMap(state, action.fields), FIELDS_TO_SPY);

    case ActionType.ERRORED_COMPANY_BUYER:
      return state;

    case ActionType.CLOSE_COMPANY_BUYER_VALIDATION_ERROR:
      return state.deleteIn(['inputErrors', action.field]).deleteIn(['newContact', 'inputErrors', action.field]);

    default:
      return state;
  }
}

function setValueAsOriginalByMap(state, map) {
  return spy(
    map.reduce((result, key) => {
      const value = getIn(result, key);

      return result.setIn(key.split('.'), value);
    }, state),
    map,
  );
}

function mapResponse(action) {
  const {
    buyerType,
    buyerCurrentStatus,
    buyerHighStatus,
    buyerPriority,
    buyerHarvcoLeadId,
    highStatusUpdated,
    ceo123,
    ceoAbc,
    parentId,
    parent,
    buyerUser,
  } = action.response.data;

  return {
    buyerCurrentStatus,
    buyerHighStatus,
    buyerPriority,
    buyerType,
    buyerHarvcoLeadId,
    highStatusUpdated,
    buyerUser,
    ceo123,
    ceoAbc,
    parentId,
    parent,
    buyerUserSuggest: (buyerUser || { userName: '' }).userName,
    loaded: true,
  };
}

function clearChangedFieldError(state, action) {
  const split = action.name.split('.');

  for (let i = 0; i < split.length; i++) {
    const pre = split.slice(0, i);
    const post = split.slice(i);

    state = state.deleteIn([...pre, 'inputErrors', post.join('.')]);
  }

  const split2 = action.name.replace(/\.pivot\./, '.').split('.');

  for (let i = 0; i < split2.length; i++) {
    const pre = split2.slice(0, i);
    const post = split2.slice(i);

    state = state.deleteIn([...pre, 'inputErrors', post.join('.')]);
  }

  return state;
}

function changeField(state, action) {
  if (/Year$/.test(action.name)) {
    state = setIn(state, action.name, action.value || null);
  }

  return clearChangedFieldError(setIn(state, action.name, action.value), action);
}

function checkValidity(state) {
  const plainState = toJS(state);
  const isValid = validator.isValid(plainState);

  const inputErrors = validator.getFirstError(plainState);

  return state.merge({
    inputErrors,
    isValid,
  });
}
