import { fromJS, List } from 'immutable';
import * as ActionType from '../../../actions/company/buyer/ceo';
import * as ActionDetailType from '../../../actions/companyDetail';
import getValidator from '../../../utils/getValidator';
import { spy, setIn, getIn, mergeDeepToOriginal, toJS } from '../../../utils/ChangeSpy';

const SCHEMA = {
  type: 'object',
};

const FIELDS_TO_SPY = ['execLeadNotes', 'ceoAbc', 'ceo123', 'showExecs'];

const ENTITY_FIELDS_TO_SPY = ['category', 'details', 'dsplHarvcoLeadUserName', 'dsplClientLeadFLName', 'status'];

const validator = getValidator(SCHEMA);

const defaultState = spy(
  fromJS({
    currentExecutiveLeads: {
      queryResults: [],
      isFetching: false,
      page: 0,
      totalPages: 0,
      sortBy: {
        sortModel: [],
      },
    },
    activeProjects: {
      queryResults: [],
      isFetching: false,
      page: 0,
      totalPages: 0,
      sortBy: {
        sortModel: [],
      },
      suggestions: {
        harvcoLead: {
          list: [],
          loading: false,
        },
        clientLead: {
          list: [],
          loading: false,
        },
      },
    },
    ceoAbc: '',
    ceo123: '',
    showExecs: false,
    execLeadNotes: '',
  }),
  FIELDS_TO_SPY,
);

const wrappedReducer = reducer;

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionDetailType.FETCHING_COMPANY_SUCCESS:
      return spy(state.merge(mapResponse(action)), FIELDS_TO_SPY);

    case ActionType.FETCHING_CEO_CURRENT_EXECUTIVE_LEADS:
      return state.mergeDeep({
        currentExecutiveLeads: {
          isFetching: true,
          page: action.query.page,
        },
      });

    case ActionType.FETCHING_CEO_CURRENT_EXECUTIVE_LEADS_SUCCESS: {
      if (action.response.meta.pagination.currentPage === 1) {
        state = state.setIn(['currentExecutiveLeads', 'queryResults'], new List());
      }

      const startAt = state.getIn(['currentExecutiveLeads', 'queryResults']).size;

      return state.mergeDeep({
        currentExecutiveLeads: {
          queryResults: state.getIn(['currentExecutiveLeads', 'queryResults']).concat(addOrderToList(startAt, action)),
          isFetching: false,
          totalPages: action.response.meta.pagination.totalPages,
        },
      });
    }

    case ActionType.FETCHING_CEO_CURRENT_EXECUTIVE_LEADS_FAILURE:
      return state.mergeDeep({
        currentExecutiveLeads: {
          isFetching: false,
        },
      });

    case ActionType.FETCHING_CEO_ACTIVE_PROJECTS:
      return state.mergeDeep({
        activeProjects: {
          isFetching: true,
          page: action.query.page,
        },
      });

    case ActionType.FETCHING_CEO_ACTIVE_PROJECTS_SUCCESS: {
      if (action.response.meta.pagination.currentPage === 1) {
        state = state.setIn(['activeProjects', 'queryResults'], new List());
      }

      const counter = state.getIn(['activeProjects', 'queryResults']).size;

      return state.mergeDeep({
        activeProjects: {
          queryResults: state
            .getIn(['activeProjects', 'queryResults'])
            .concat(fromJS(activeProjectsMapper(counter, action.response.data))),
          isFetching: false,
          totalPages: action.response.meta.pagination.totalPages,
        },
      });
    }

    case ActionType.FETCHING_CEO_ACTIVE_PROJECTS_FAILURE:
      return state.mergeDeep({
        activeProjects: {
          isFetching: false,
        },
      });

    case ActionType.UPDATE_CEO:
      Object.keys(action.filterData).forEach(key => {
        if (List.isList(action.filterData[key]) || Array.isArray(action.filterData[key])) {
          state = state.setIn([action.filterName, key], new List());
        }
      });

      return state.mergeDeep({
        [action.filterName]: action.filterData,
      });

    case ActionType.UPDATE_COMPANY:
      return checkValidity(changeField(state, action));

    case ActionType.SAVED_COMPANY_BUYER:
      return spy(setValueAsOriginalByMap(state, action.fields), FIELDS_TO_SPY);

    case ActionType.ERRORED_COMPANY_BUYER:
      return state;

    case ActionType.SAVE_CEO_PROJECTS_SUCCESS:
      return mergeDeepToOriginal(state, {
        activeProjects: {
          queryResults: action.body,
        },
      });

    case ActionType.FETCHING_SUGGESTIONS:
      return state.setIn(['activeProjects', 'suggestions', 'harvcoLead', 'loading'], true);

    case ActionType.FETCHING_SUGGESTIONS_SUCCESS:
      return state
        .setIn(['activeProjects', 'suggestions', 'harvcoLead', 'list'], List(action.response.data))
        .setIn(['activeProjects', 'suggestions', 'harvcoLead', 'loading'], false);

    case ActionType.FETCHING_CLIENT_SUGGESTIONS:
      return state.setIn(['activeProjects', 'suggestions', 'clientLead', 'loading'], true);

    case ActionType.FETCHING_CLIENT_SUGGESTIONS_SUCCESS:
      return state
        .setIn(['activeProjects', 'suggestions', 'clientLead', 'list'], List(action.response.data))
        .setIn(['activeProjects', 'suggestions', 'clientLead', 'loading'], false);

    case ActionType.CLEAR_CLIENT_LEAD_SUGGESTIONS:
      return state
        .setIn(['activeProjects', 'suggestions', 'clientLead', 'list'], List())
        .setIn(['activeProjects', 'suggestions', 'clientLead', 'loading'], false);

    case ActionType.CLEAR_HARVCO_LEAD_SUGGESTIONS:
      return state
        .setIn(['activeProjects', 'suggestions', 'harvcoLead', 'list'], List())
        .setIn(['activeProjects', 'suggestions', 'harvcoLead', 'loading'], false);

    case ActionType.SELECT_HARVCO_LEAD_SUGGESTIONS:
      return state.updateIn(['activeProjects', 'queryResults'], list =>
        list.map(p => {
          if (p.get('eprojectId') === action.eprojectId) {
            return p.set('harvcoLeadId', action.id).setIn(['dsplHarvcoLeadUserName', 'value'], action.text);
          }

          return p;
        }),
      );

    case ActionType.SELECT_CLIENT_LEAD_SUGGESTIONS:
      return state.updateIn(['activeProjects', 'queryResults'], list =>
        list.map(p => {
          if (p.get('eprojectId') === action.eprojectId) {
            return p.set('clientLeadId', action.id).setIn(['dsplClientLeadFLName', 'value'], action.text);
          }

          return p;
        }),
      );

    default:
      return state;
  }
}

function setValueAsOriginalByMap(state, map) {
  return spy(
    map.reduce((result, key) => {
      const value = getIn(result, key);

      return result.setIn(key.split('.'), value);
    }, state),
    map,
  );
}

function clearChangedFieldError(state, action) {
  const split = action.name.split('.');

  for (let i = 0; i < split.length; i++) {
    const pre = split.slice(0, i);
    const post = split.slice(i);

    state = state.deleteIn([...pre, 'inputErrors', post.join('.')]);
  }

  const split2 = action.name.replace(/\.pivot\./, '.').split('.');

  for (let i = 0; i < split2.length; i++) {
    const pre = split2.slice(0, i);
    const post = split2.slice(i);

    state = state.deleteIn([...pre, 'inputErrors', post.join('.')]);
  }

  return state;
}

function changeField(state, action) {
  return clearChangedFieldError(setIn(state, action.name, action.value), action);
}

function checkValidity(state) {
  const plainState = toJS(state);
  const isValid = validator.isValid(plainState);

  const inputErrors = validator.getFirstError(plainState);

  return state.merge({
    inputErrors,
    isValid,
  });
}

function mapResponse(action) {
  const { execLeadNotes, ceoAbc, ceo123, showExecs } = action.response.data;

  return {
    execLeadNotes,
    ceoAbc,
    ceo123,
    showExecs,
  };
}

function activeProjectsMapper(startAt = 1, data) {
  return data.map((value, i) => {
    value.order = startAt + i;
    value.harvcoLeadLoading = false;
    value.clientLeadLoading = false;

    return spy(value, ENTITY_FIELDS_TO_SPY);
  });
}

/**
 * Add order to list.
 *
 * @param {*} startAt
 * @param {*} action
 */
function addOrderToList(startAt = 1, action) {
  return fromJS(
    action.response.data.map((d, i) => {
      d.order = startAt + i;

      return d;
    }),
  );
}
