import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import connectOptions, { mergeProps } from '../utils/connectOptions';

import { removeApiError } from '../actions/applicationErrors';

/**
 * Wrapper that displays api's errors.
 *
 * @augments React.Component
 */
class ApiErrorDisplayWrapper extends PureComponent {
  constructor(...props) {
    super(...props);

    this.planned = false;
  }

  componentDidUpdate() {
    if (this.planned) return;
    if (this.context.hasPopup()) {
      this.planned = true;

      return this.context.onClosePopup(() => {
        setImmediate(() => {
          this.openPopup();
          this.planned = false;
        });
      });
    }
    this.openPopup();
  }

  openPopup() {
    if (this.props.errors.size === 0) return;

    const error = this.props.errors.get(0);

    this.context.openPopup('ApiErrorPopup', {
      // possible feature     onRetry: this.onRetry,
      error,
    });
    this.context.onClosePopup(() => {
      this.onClose(error);
    });
  }

  onClose(error) {
    this.props.removeApiError({ error });
  }

  /**
   * Render function.
   *
   * @returns {XML}
   */
  render() {
    const { children } = this.props;

    return <div className="full-height">{children}</div>;
  }
}

ApiErrorDisplayWrapper.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  hasPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
};

/**
 * Map application's state to appState property.
 *
 * @param state {Object} Application's state.
 * @returns {{appState: object}}
 */
function mapStateToProps(state) {
  return {
    errors: state.applicationErrors.get('api'),
  };
}

export { ApiErrorDisplayWrapper };
export default connect(mapStateToProps, { removeApiError }, mergeProps, connectOptions)(ApiErrorDisplayWrapper);
