import React from 'react';
import config from '../../config';

const check = ({ deleteApproval, loggedUser, openPopup, closePopup }) => (projectId, approvalId, numberTargets) => {
  if (!loggedUser) return;

  const hasDeletion = loggedUser.get('roles').reduce((ret, role) => {
    if ([config.DIRECTOR, config.FINANCE_ADMIN, config.ADMINISTRATOR].indexOf(role.get('slug')) > -1) return true;

    return ret;
  }, false);

  if (hasDeletion) {
    openPopup('ConfirmPopup', {
      message: `WARNING! You are about to delete an Approval List. This Approval List has ${numberTargets ||
        0} target companies associated with this list. Deleting this Approval List will remove all records of its existence, and is irreversible. Do you wish to continue?`,
      onOk: () => {
        deleteApproval(projectId, approvalId);
        closePopup();
      },
      onCancel: closePopup,
      yes: 'Yes',
      no: 'No',
    });

    return;
  }

  const needConfirmation = loggedUser.get('roles').reduce((ret, role) => {
    if ([config.ANALYST_ASSOCIATE].indexOf(role.get('slug')) > -1) return true;

    return ret;
  }, false);

  if (needConfirmation) {
    openPopup('InformationPopup', {
      message: {
        texts: [
          'Permission Denied. If you need to delete an Approval List, please discuss with your Director/Project Manager',
        ],
        className: 'text-danger',
      },
      header: (
        <span>
          <i aria-hidden="true" className="fa fa-exclamation-triangle text-danger" />
          Error Alert
        </span>
      ),
    });

    return;
  }

  openPopup('InformationPopup', {
    message: {
      texts: ["You don't have permission to delete these items"],
      className: 'text-danger',
    },
    header: (
      <span>
        <i aria-hidden="true" className="fa fa-exclamation-triangle text-danger" />
        Error Alert
      </span>
    ),
  });
};

export default check;
