import React, { useMemo } from 'react';
import { fromJS, Map, List } from 'immutable';
import classNames from 'classnames';

import { isResearcher } from 'src/utils/checkPermissions';
import config from '../../../config';
import SubTabs from '../../../components/helpers/SubTabs';
import UrlMaker from '../../../routing/UrlMaker';
import CompanyBuyerStatus from './CompanyStatus';
import { getIn } from '../../../utils/ChangeSpy';

/**
 * Check tab is default and user role "Director+".
 *
 * @param {boolean} isDefaultAddOn Default AddOn.
 * @param {string} userRole User role.
 * @returns {boolean} Whether delete is allowed or not.
 */
function isAllowedToDelete(isDefaultAddOn, userRole) {
  if (isDefaultAddOn) return false;

  return [config.DIRECTOR, config.FINANCE_ADMIN].includes(userRole);
}

/**
 * Check tab Retainer is available.
 *
 * @param {string} status Status.
 * @param {string} userRole User role.
 * @returns {boolean} Whether RetainerTab is allowed or not.
 */
function isAllowedRetainerTab(status, userRole) {
  if ([config.PROCESS_MANAGER, config.RESEARCH_INTERN, config.JUNIOR_ANALYST].includes(userRole)) {
    return false;
  }

  const statusValue = Number(status);

  if (statusValue >= 9.0) {
    if ([config.ANALYST_ASSOCIATE, config.DIRECTOR, config.FINANCE_ADMIN, config.ADMINISTRATOR].includes(userRole)) {
      return true;
    }
  }

  return false;
}

/**
 *
 * @returns {React.Component}
 */
const CompanyBuyer = ({
  userRole,
  buyerProjects,
  location,
  harvcoSuggest,
  companyId,
  type,
  buyer,
  children,
  statuses,
  onChange,
  onAddAddonProject,
  onRemoveAddonProject,
  onHighStatusUpdate,
  isDuplicateCompany,
}) => {
  const tabsList = fromJS([
    { to: '/company/:companyId/buyer/marketing', name: 'Marketing/Approach', showOn: ['financial', 'strategic'] },
    { to: '/company/:companyId/buyer/leads', name: 'Leads', showOn: ['financial', 'strategic'] },
    { to: '/company/:companyId/buyer/criteria', name: 'Criteria', showOn: ['financial', 'strategic'] },
    { to: '/company/:companyId/buyer/projects', name: 'Projects', showOn: ['financial'] },
    { to: '/company/:companyId/buyer/fund', name: 'Portfolio/Fund', showOn: ['financial'] },
    { to: '/company/:companyId/buyer/ceo', name: 'CEO-Backing', showOn: ['financial'] },
  ]);

  const buyerProjectsTabList = buyerProjects.map(buyerProject => {
    const isDefaultAddOn = buyerProject.get('isDefaultAddOn');
    const addonProjectId = buyerProject.get('id');
    const to = isDefaultAddOn
      ? `/company/:companyId/buyer/add-on-project`
      : `/company/:companyId/buyer/add-on-project/${addonProjectId}`;
    const name = isDefaultAddOn ? 'Add-On Project' : buyerProject.get('category');
    const onRemoveTab = isAllowedToDelete(isDefaultAddOn, userRole)
      ? event => onRemoveAddonProject(event, addonProjectId)
      : null;

    return Map({
      ...buyerProject,
      to,
      showOn: ['strategic'],
      name,
      onRemoveTab,
    });
  });

  const tabAdd = List(
    fromJS([
      {
        to: '',
        name: '+',
        showOn: ['strategic'],
        className: 'add-addon-project',
        onClick: onAddAddonProject,
      },
    ]),
  );

  const tabRetainer = List(
    fromJS([
      {
        to: '/company/:companyId/buyer/retainer',
        name: 'Agreement',
        showOn: ['financial', 'strategic'],
      },
    ]),
  );

  const concatRetainer = isAllowedRetainerTab(getIn(buyer, 'buyerHighStatus'), userRole) ? tabRetainer : Map();
  const lowerType = type && type.toLowerCase();
  const allowedForType = tabsList
    .concat(buyerProjectsTabList)
    .concat(tabAdd)
    .concat(concatRetainer)
    .filter(link => link.get('showOn').indexOf(lowerType) !== -1);

  let content = null;

  if (location) {
    content = allowedForType.reduce((result, tab) => {
      if (result) return result;

      const url = UrlMaker.create(tab.get('to'))
        .mapParams({ companyId })
        .replace('/?$', '');

      if (String(location.pathname).indexOf(url) !== -1) {
        return children;
      }

      return null;
    }, null);
  }

  const isResearcherUser = useMemo(() => isResearcher(userRole), [userRole]);

  return (
    <div className="tab-pane active full-height flexWrapper flexItem" id="slide-2">
      <CompanyBuyerStatus
        buyer={buyer}
        companyId={companyId}
        harvcoSuggest={harvcoSuggest}
        isDuplicateCompany={isDuplicateCompany}
        onChange={onChange}
        onHighStatusUpdate={onHighStatusUpdate}
        statuses={statuses}
        type={type}
      />
      <div className="flexItem flexWrapper full-height min-h300">
        {!isResearcherUser && (
          <SubTabs getLink={tab => UrlMaker.create(tab.get('to')).mapParams({ companyId })} tabs={allowedForType} />
        )}
        <div className={classNames('col-md-12 tab-content flexItem flexWrapper', { mt10: isResearcherUser })}>
          {content}
        </div>
      </div>
    </div>
  );
};

export { CompanyBuyer };
export default CompanyBuyer;
