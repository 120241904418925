import React from 'react';
import PropTypes from 'prop-types';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { convertDateString } from '../../../../utils/dateFormat';
import EmptyRow from '../EmptyRow';

const EventsTable = props => {
  const { events, onAddEvent, onEventRemove } = props;

  return (
    <>
      <div className="trade-show-field-text">
        <label className="col-md-8 mb5">Events</label>
      </div>
      <div className="events-table-container">
        <table className="table table-bordered events-table">
          <thead>
            <tr>
              <th>
                <span className="add-row" onClick={onAddEvent} title="Add New">
                  +
                </span>
                Year
              </th>
              <th>Title</th>
              <th>City</th>
              <th>State</th>
              <th>Date Start</th>
              <th>Date End</th>
            </tr>
          </thead>
          <tbody>
            {events.length ? (
              events.map((event, index) => (
                <tr key={index}>
                  <td>
                    <ContextMenuTrigger id={index.toString()}>
                      {event.year} {event.yearSuffix}
                    </ContextMenuTrigger>
                  </td>
                  <td>
                    <ContextMenuTrigger id={index.toString()}>{event.title}</ContextMenuTrigger>
                  </td>
                  <td>
                    <ContextMenuTrigger id={index.toString()}>{event.city}</ContextMenuTrigger>
                  </td>
                  <td>
                    <ContextMenuTrigger id={index.toString()}>{event.state}</ContextMenuTrigger>
                  </td>
                  <td>
                    <ContextMenuTrigger id={index.toString()}>{convertDateString(event.startDate)}</ContextMenuTrigger>
                  </td>
                  <td>
                    <ContextMenuTrigger id={index.toString()}>{convertDateString(event.endDate)}</ContextMenuTrigger>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <EmptyRow colSpan={6} text="No Rows To Display" />
              </tr>
            )}
          </tbody>
        </table>
        {events.map((event, index) => (
          <div key={index}>
            {!(Object.keys(event).length === 0) && (
              <ContextMenu id={index.toString()}>
                <MenuItem data={event} onClick={() => onEventRemove(index)}>
                  <i className="fa fa-remove" /> Delete
                </MenuItem>
              </ContextMenu>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

EventsTable.propTypes = {
  events: PropTypes.instanceOf(Array).isRequired,
  onAddEvent: PropTypes.func,
  onEventRemove: PropTypes.func,
};

export default EventsTable;
