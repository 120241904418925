import PropTypes from 'prop-types';

import React from 'react';
import { Map } from 'immutable';
import config from '../../../config';
import CompanyExecuteTable from './CompanyExecuteTable';

const columnDefs = config.tables.getIn(['companyExecutive', 'columnDefs']);

const CompanyExecutive = props => {
  const { executive, onDoubleClickRow } = props;

  return <CompanyExecuteTable columnDefs={columnDefs} data={executive} onDoubleClickRow={onDoubleClickRow} />;
};

CompanyExecutive.propTypes = {
  executive: PropTypes.instanceOf(Map).isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
};

export default CompanyExecutive;
