import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import classNames from 'classnames';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import uniqueId from '../../utils/uniqueId';
import Table, { Column, Row } from '../helpers/Table';
import config from '../../config';
import { noop } from '../../utils/noop';

const noWrap = v => v;

const ContextMenuWrapper = (onClick, extraWrap = noWrap) => {
  const ContextMenuWrapped = (value, data) => {
    const id = uniqueId();
    const text = value === 'no date' ? null : value;

    return (
      <div>
        <ContextMenuTrigger id={id}>{extraWrap(text, data)}</ContextMenuTrigger>

        <ContextMenu id={id}>
          <MenuItem data={data.toJS()} onClick={onClick}>
            <i className="fa fa-remove" /> Delete
          </MenuItem>
        </ContextMenu>
      </div>
    );
  };

  return ContextMenuWrapped;
};

const wrapBacklogged = (text, data) => {
  const activity = data.getIn(['activityMap', 'text'], '');

  if (data.get('backlogged', false) && !data.get('completed', false) && config.BACKLOGGED_EVENTS.includes(activity))
    return (
      <span>
        <i className="fa fa-clock-o" /> {text}
      </span>
    );

  return <span>{text}</span>;
};

/**
 * Stateless component for contact's activities.
 * Display table where rows are activities.
 *
 * @link http://screencloud.net/v/jS6q on page (right top part)
 * @link http://screencloud.net/v/7yc2 component itself
 * @param props {Object}.
 * @param props.events {Immutable.List} List with activities info. If now activities
 *     are on contact - should be an empty List.
 * @returns {React.Component}
 */

class ContactActivities extends PureComponent {
  render() {
    return ContactActivitiesRender(this.props, this.context);
  }
}

const ContactActivitiesRender = (props, context) => {
  const { onMouseEnter = noop, onMouseLeave = noop } = props;
  const { onAddEvent, onEditEvent, onDeleteEvent } = context;
  /**
   * Var for activity rows.
   *
   * @type {React.Component[]}
   */
  const rows = props.events
    .get('all')
    .map(data => (
      <Row
        key={data.get('id')}
        className={getRowClassName(data)}
        data={data}
        onDoubleClick={event => onEditEvent(event, data)}
        onMouseEnter={event => onMouseEnter(event, data.get('id'))}
        onMouseLeave={event => onMouseLeave(event, data.get('id'))}
      />
    ));

  const dateTitle = (
    <span onClick={onAddEvent}>
      <i aria-hidden="true" className="fa fa-plus-square fa-2x" />
      Date
    </span>
  );

  const current = props.events.get('current');
  const style = {
    top: current.get('tooltipTop'),
    left: current.get('tooltipLeft'),
  };

  const container = (
    <div className="eventTooltip" style={style}>
      {current.get('tooltipText')}
    </div>
  );
  const portal = createPortal(container, document.querySelector('body'));
  const tooltipPortal = current.get('showTooltip') && portal;

  return (
    <div className="contactActivities flexWrapper flexItem">
      <Table bodyClassName="flexItem" className="table table-striped table-hover table-dates flexItem flexWrapper">
        <Column field="dateString" title={dateTitle} valueWrapper={ContextMenuWrapper(onDeleteEvent)} />
        <Column field="userName" title="Name" valueWrapper={ContextMenuWrapper(onDeleteEvent)} />
        <Column
          field="fullActivity"
          title="Activity"
          valueWrapper={ContextMenuWrapper(onDeleteEvent, wrapBacklogged)}
        />
        {rows}
      </Table>
      {tooltipPortal}
    </div>
  );
};

ContactActivitiesRender.propTypes = {
  events: PropTypes.instanceOf(Map).isRequired,
};

ContactActivities.contextTypes = {
  onAddEvent: PropTypes.func.isRequired,
  onDeleteEvent: PropTypes.func.isRequired,
  onEditEvent: PropTypes.func.isRequired,
};

export default ContactActivities;

function getRowClassName(event) {
  const level = event.get('warnLevel');

  return classNames('clickable', {
    info: level === 0 || level === 1,
    warning: level === 2,
    danger: level === 3,
    'text-bold': event.get('isMarked'),
  });
}
