import PropTypes from 'prop-types';

import React from 'react';
import { List } from 'immutable';
import Tabs from '../helpers/Tabs';

const Browse = props => {
  const { tabs, children } = props;

  return (
    <div className="container">
      <div className="row">
        <div className="container-fluid">
          <div className="BrowseTabs">
            <Tabs tabClass="BrowseTabs-nav nav nav-tabs" tabs={tabs} />
            <div className="BrowseTabs-content tab-content">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Browse.propTypes = {
  tabs: PropTypes.instanceOf(List).isRequired,
};
export default Browse;
