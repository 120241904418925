import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import BrowseCheckbox from './BrowseCheckbox';

class BrowseEmployeeInputRange extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;

    if (isNaN(value)) {
      event.preventDefault();

      return false;
    }
    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        text: {
          [name]: value,
        },
      },
    });
  }

  handleBlur(event) {
    const { name, value } = event.target;
    const { info } = this.props;
    let updateValue = Math.round(value * 100) / 100;
    const min = parseInt(info.getIn(['text', 'min']), 10);
    const max = parseInt(info.getIn(['text', 'max']), 10);

    if (name === 'min') {
      if (updateValue > max) {
        updateValue = max;
      } else if (updateValue < 0) {
        updateValue = 0;
      }
    } else if (name === 'max') {
      if (updateValue < min) {
        updateValue = min;
      }
    }
    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        text: {
          [name]: updateValue,
        },
      },
    });
  }

  render() {
    const { info } = this.props;
    const text = {
      min: info.getIn(['text', 'min']),
      max: info.getIn(['text', 'max']),
    };

    return (
      <BrowseCheckbox {...this.props}>
        <div className="BrowseControl-content BrowseControl-content--slider">
          <div className="form-inline">
            <div className="form-group">
              <input
                className="form-control"
                name="min"
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                type="text"
                value={text.min}
              />
            </div>
            <div className="form-group">&nbsp;-&nbsp;</div>
            <div className="form-group">
              <input
                className="form-control"
                name="max"
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                type="text"
                value={text.max}
              />
            </div>
          </div>
        </div>
      </BrowseCheckbox>
    );
  }
}

BrowseEmployeeInputRange.propTypes = {
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BrowseEmployeeInputRange;
