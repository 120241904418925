import PropTypes from 'prop-types';

import React from 'react';
import Immutable from 'immutable';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Checkbox from '../helpers/Checkbox';
import ErrorList from '../helpers/ErrorList';
import ComboSelectBox from '../helpers/ComboSelectBox';

/**
 * Change status popup components.
 *
 * @param props {Object}.
 * @param onUpdate {Function} To handle update button.
 * @param onClose {Function} To handle close button.
 * @param onChange {Function} To handle change of drop down list and checkboxes.
 * @param errors {Immutable.List} List of error.
 * @param status {String} Value of drop down list.
 * @param approved {Boolean} Value of checkbox approved.
 * @param passed {Boolean} Value of checkbox passed.
 * @param noResponse {Boolean} Value of checkbox no response.
 * @param showSpinner {Boolean} Show spinner icon.
 * @param statuses {Immutable.List} List of status.
 * @param disabled {Boolean} If set to true, will disabled update button.
 * @class
 */
const ChangeStatusPopup = props => {
  const {
    onUpdate,
    onClose,
    onChange,
    errors,
    status,
    approved,
    passed,
    noResponse,
    statuses,
    showSpinner,
    disabled,
  } = props;
  const header = 'Change All Status Codes';
  const footer = [
    <button
      key="create"
      className="btn btn-primary btn-xs"
      data-dismiss="modal"
      disabled={disabled}
      onClick={onUpdate}
      type="button"
    >
      {showSpinner ? <i className="fa fa-spinner fa-pulse fa-fw button" /> : null} Update
    </button>,
    <button key="close" className="btn btn-default btn-xs" data-dismiss="modal" onClick={onClose} type="button">
      Close
    </button>,
  ];

  return (
    <Popup footer={footer} header={header} id="changeStatusPopup">
      <div className="row media-450 mb20">
        <div className="col-xs-12">
          <div className="form-inline row mb5">
            <FormGroup className="col-xs-6">
              <strong>Update current statuses:</strong>
              <ComboSelectBox name="status" onChange={onChange} options={statuses} value={status} autoFocus />
            </FormGroup>
          </div>
          <div className="form-inline row">
            <FormGroup className="col-xs-12 group-checkbox">
              <strong>Apply to:</strong>
              <Checkbox checked={approved} label="Approved" name="approved" onChange={onChange} />
              <Checkbox checked={passed} label="Passed" name="passed" onChange={onChange} />
              <Checkbox checked={noResponse} label="No Response" name="noResponse" onChange={onChange} />
            </FormGroup>
          </div>
        </div>
      </div>
      <ErrorList className="mt20" errors={errors} />
    </Popup>
  );
};

ChangeStatusPopup.propTypes = {
  approved: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  errors: PropTypes.instanceOf(Immutable.List).isRequired,
  noResponse: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  passed: PropTypes.bool.isRequired,
  showSpinner: PropTypes.bool.isRequired,
  status: PropTypes.number.isRequired,
  statuses: PropTypes.instanceOf(Immutable.List).isRequired,
};

export default ChangeStatusPopup;
