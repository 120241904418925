/**
 * Open internal link.
 *
 * @param link {String} Internal link.
 * @param inNewTab {Boolean(default=true)} Open in a new tab.
 * @returns {boolean}
 */
export function openInternalLink(link, inNewTab = true) {
  const host = window.location.hostname;
  const { port } = window.location;

  if (inNewTab) {
    window.open(`//${host}${port ? ':' : ''}${port}${link}`, '_blank');
  } else {
    window.open(`//${host}${port ? ':' : ''}${port}${link}`);
  }
}

/**
 * Open external link.
 *
 * @param link {String} External link.
 * @param inNewTab {Boolean(default=true)} Open in a new tab.
 * @returns {boolean}
 */
export function openExternalLink(link, inNewTab = true) {
  if (inNewTab) {
    window.open(link, '_blank');
  } else {
    window.open(link);
  }
}

/**
 * If link is not empty, return new link without any protocol.
 *
 * @param link {String} External link.
 */
export function getSafeLink(link, defaultValue) {
  if (link) {
    return link.replace(/http:\/\/|https:\/\/|tcp:\/\//gi, '');
  }

  return defaultValue;
}
