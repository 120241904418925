import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Popups from '../../components/Popup';
import { saveBuyers } from '../../actions/company/companyTarget';
import config from '../../config';
import { saveInfo } from '../../actions/company/buyer/info';

class UpdateHighStatusPopupContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.highStatus = this.props.popup.getIn(['props', 'highStatus']);
    this.currentStatus = this.props.popup.getIn(['props', 'currentStatus']);
    this.statuses = this.props.popup.getIn(['props', 'statuses']);
    this.companyId = this.props.popup.getIn(['props', 'companyId']);
    this.buyerId = this.props.popup.getIn(['props', 'buyerId']);
    this.type = this.props.popup.getIn(['props', 'type']);
    this.directorEmail = this.props.popup.getIn(['props', 'directorEmail']);
    this.shouldSendEmail = [config.ADMINISTRATOR, config.DIRECTOR].includes(this.props.userRole)
      ? false
      : this.props.popup.getIn(['props', 'isStatusExpired']);
    this.state = {
      highStatus: this.highStatus,
      correcting: false,
      loading: false,
    };
  }

  handleClose() {
    this.context.closePopup();
  }

  handleSave() {
    if (this.shouldSendEmail) {
      const legacyHigh = this.statuses.find(s => s.get('value') === this.highStatus);
      const currhigh = this.statuses.find(s => s.get('value') === this.state.highStatus);
      const body = `The High Status on this record is incorrect and should be changed from "${legacyHigh.get(
        'value',
      )}-${legacyHigh.get('name')}" to "${currhigh.get('value')}-${currhigh.get(
        'name',
      )}". The change took place more than 24 hours ago, and now needs Director approval for the change.\n\nLink to record: ${
        window.location.href
      }`;
      const subject = 'High Status mistakes';

      window.location = `mailto:${this.directorEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body,
      )}`;
    } else if (this.type === 'buyer') {
      this.setState({ loading: true });

      const body =
        Number(this.currentStatus) > Number(this.state.highStatus)
          ? {
              buyerHighStatus: this.state.highStatus,
              buyerCurrentStatus: this.state.highStatus,
            }
          : { buyerHighStatus: this.state.highStatus };

      this.props.saveInfo({
        companyId: this.companyId,
        body,
        afterSuccess: this.handleClose,
      });
    } else {
      const data = {
        companyId: this.companyId,
        id: this.buyerId,
        body: {
          highStatus: this.state.highStatus,
        },
        afterSuccess: this.handleClose,
      };

      if (Number(this.currentStatus) > Number(this.state.highStatus)) {
        data.body.currentStatus = this.state.highStatus;
      }
      this.setState({ loading: true });
      this.props.saveBuyers(data);
    }
  }

  handleChange({ target: { value, name, checked } }) {
    if (name === 'correcting') {
      this.setState({ correcting: checked });
    } else {
      this.setState({ highStatus: value });
    }
  }

  render() {
    return (
      <div>
        <Popups.UpdateHighStatusPopup
          correcting={this.state.correcting}
          highStatus={this.state.highStatus}
          loading={this.state.loading}
          onChange={this.handleChange}
          onClose={this.handleClose}
          onSave={this.handleSave}
          shouldSendEmail={this.shouldSendEmail}
          statuses={this.statuses}
        />
      </div>
    );
  }
}

UpdateHighStatusPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userRole: state.auth.getIn(['user', 'roles', 0, 'slug']),
});

export { UpdateHighStatusPopupContainer };
export default connect(mapStateToProps, { saveBuyers, saveInfo })(UpdateHighStatusPopupContainer);
