import { fromJS } from 'immutable';

import {
  spy,
  mergeDeep,
  setIn,
  getIn,
  getOriginalIn,
  setOriginalIn,
  mergeToOriginal,
  revertToOriginal,
} from '../../utils/ChangeSpy';
import * as ActionType from '../../actions/companyDetail';
import config from '../../config';
import handleApiError from '../decorators/handleApiError';

const { states, entityTypes } = config.company;

const FIELDS_TO_SPY = [
  'legalName',
  'abbrName',
  'dbaName',
  'web',
  'companyType',
  'phone',
  'address',
  'city',
  'state',
  'zip',
  'country.countryId',
  'country.countryName',
  'tickerExchange',
  'tickerSymbol',
  'parentId',
  'businessModelId',
];

const defaultState = fromJS({
  isFetching: false,
  info: spy(
    {
      legalName: '',
      abbrName: '',
      dbaName: '',
      web: '',
      companyType: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      tickerExchange: '',
      tickerSymbol: '',
      country: {
        countryName: '',
        countryId: 0,
      },
      parentId: null,
      showDBA: false,
      showCountry: false,
      isValidAddress: false,
      businessModelId: 0,
      isApproved: true,
      createdResearcherId: null,
    },
    FIELDS_TO_SPY,
  ),
  states,
  countries: {
    loaded: false,
    data: [],
    pagination: {
      currentPage: 0,
      totalPages: 0,
    },
  },
  entityTypes: entityTypes.filter(v => !!v),
  parentCompany: spy(
    {
      suggestions: [],
      text: '',
      selected: null,
      loading: false,
      shortInfoText: '', // parent company information text
    },
    ['text', 'shortInfoText'],
  ),
  error: null,
  inputErrors: {},
  formError: [],
});

let wrappedReducer = reducer;

wrappedReducer = handleApiError(ActionType.SAVE_COMPANY_ERROR, wrappedReducer);

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.UPDATE_COMPANY_INFO:
      return revertCompanyType(state, action).update('info', info => mergeDeep(info, action.info));

    case ActionType.REVERT_COMPANY_PARENT: {
      let tmp = revertToOriginal(state, ['parentCompany', 'text']);

      tmp = revertToOriginal(tmp, ['info', 'parentId']);

      return revertToOriginal(tmp, ['parentCompany', 'shortInfoText']);
    }

    case ActionType.UPDATE_COMPANY_COUNTRY: {
      if (typeof action.info.text !== 'undefined') {
        state = state.updateIn(['info', 'country'], state => setIn(state, 'countryId', 0));
        state = state.updateIn(['info', 'country'], state => setIn(state, 'countryName', action.info.text));
        if (action.info.text === getOriginalIn(state, 'info.country.countryName')) {
          state = state.updateIn(['info', 'country'], state =>
            setIn(state, 'countryId', getOriginalIn(state, 'countryId')),
          );
        }
      }

      if (action.info.selected) {
        state = state.updateIn(['info', 'country'], state => setIn(state, 'countryId', action.info.selected.id));
        state = state.updateIn(['info', 'country'], state => setIn(state, 'countryName', action.info.selected.text));
      }

      return state;
    }

    case ActionType.REVERT_COMPANY_COUNTRY: {
      state = state.updateIn(['info', 'country'], state => revertToOriginal(state, 'countryId'));

      return state.updateIn(['info', 'country'], state => revertToOriginal(state, 'countryName'));
    }

    case ActionType.UPDATE_PARENT_COMPANY: {
      if (action.info.suggestions && action.info.suggestions.length === 0) {
        state = state.setIn(['parentCompany', 'suggestions'], defaultState.getIn(['parentCompany', 'suggestions']));
      }

      if (typeof action.info.text !== 'undefined') {
        state = state.update('info', state => setIn(state, 'parentId', 0));
        state = state.update('parentCompany', state => setIn(state, 'text', action.info.text));
        state = state.update('parentCompany', state => setIn(state, 'selected', null));
        state = state.update('parentCompany', state => setIn(state, 'shortInfoText', ''));
        if (action.info.text === getOriginalIn(state, 'parentCompany.text')) {
          state = state.update('info', state => setIn(state, 'parentId', getOriginalIn(state, 'parentId')));
        }
      }

      if (action.info.selected) {
        state = state.update('info', state => setIn(state, 'parentId', action.info.selected.id));
        state = state.update('parentCompany', state => setIn(state, 'selected', fromJS(action.info.selected)));
        state = state.update('parentCompany', state =>
          setIn(state, 'shortInfoText', getShortInfo(action.info.selected)),
        );
      }

      return state;
    }

    case ActionType.FETCHING_COMPANY:
      return defaultState.merge({
        isFetching: true,
        error: null,
      });

    case ActionType.FETCHING_COMPANY_FAILURE:
      return state.merge({
        isFetching: false,
        error: action.response,
      });

    case ActionType.FETCHING_COMPANY_SUCCESS:
      return state.merge({
        isFetching: false,
        info: mapResponse(action.response),
        parentCompany: spy(
          {
            suggestions: [],
            text: action.response.data.parent.legalName,
            selected: action.response.data.parent || null,
            shortInfoText: getShortInfo(action.response.data.parent),
          },
          ['text', 'shortInfoText'],
        ),
      });

    case ActionType.SAVE_COMPANY_SUCCESS:
      if ('parentId' in action.body) {
        state = setOriginalIn(state, 'parentCompany.text', getIn(state, 'parentCompany.text'));
      }

      if ('countryId' in action.body) {
        state = setOriginalIn(state, 'info.country.countryName', getIn(state, 'info.country.countryName'));
        state = setOriginalIn(state, 'info.country.countryId', getIn(state, 'info.country.countryId'));
      }

      return state.update('info', info => mergeToOriginal(info, action.body));

    case ActionType.FETCHING_LIST_COMPANY:
      return state.setIn(['parentCompany', 'loading'], true);

    case ActionType.FETCHING_LIST_COMPANY_SUCCESS:
      return state
        .setIn(['parentCompany', 'suggestions'], fromJS(action.response.data))
        .setIn(['parentCompany', 'loading'], false);

    case ActionType.CLOSE_VALIDATION_ERROR:
      return state.deleteIn(['info', 'inputErrors', action.field]).deleteIn(['inputErrors', action.field]);

    case ActionType.LOAD_COUNTRIES_SUCCESS:
      return mapCountries(state, action);

    case ActionType.COUNTRIES_LOADED:
      return state.setIn(['countries', 'loaded'], true);

    case ActionType.ON_COMPANY_INFO_HEIGHT_CHANGE:
      return state.set('height', action.height);

    case ActionType.CONFIRM_TARGET_SUCCESS:
      return state.setIn(['info', 'isApproved'], true);

    default:
      return state;
  }
}

function revertCompanyType(state, action) {
  const tmp = ['Public', 'ForPub'];

  if (action.info && action.info.companyType && tmp.indexOf(action.info.companyType) === -1) {
    const tmpState = revertToOriginal(state, ['info', 'tickerExchange']);

    return revertToOriginal(tmpState, ['info', 'tickerSymbol']);
  }

  return state;
}

/**
 * Return the short of parent company info.
 */
function getShortInfo(comp) {
  if (comp) {
    return `${comp.companyType} ${comp.revenue} / ${comp.employees ? comp.employees : 0} emp. ${comp.city}, ${
      comp.state
    }`;
  }

  return '';
}

function mapCountries(state, action) {
  const {
    data,
    meta: { pagination },
  } = action.response;
  const countries = state.getIn(['countries', 'data']).toJS();

  return state
    .merge({
      countries: {
        pagination,
        loaded: pagination.currentPage >= pagination.totalPages,
      },
    })
    .setIn(['countries', 'data'], fromJS(countries.concat(data)));
}

function mapResponse({ data }) {
  if (data.dbaName) {
    data.showDBA = true;
  }

  if (data.country) {
    data.showCountry = true;
    data.country.countryId = data.country.id;
    delete data.country.id;
  } else {
    data.country = {
      countryId: 0,
      countryName: '',
    };
  }

  if (config.HAS_PARENT_COMPANY.indexOf(data.companyType) === -1) {
    data.parentId = null;
  } else {
    data.parentIn = data.parentIn || 0;
  }

  // If 'notApproved' equals 1, then this company created by Researcher. And needs to approve.
  data.isApproved = data.notApproved !== 1;

  data.createdResearcherId = data.createdResearcherId || null;

  return spy(data, FIELDS_TO_SPY);
}
