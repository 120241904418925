import { CALL_API } from '../middleware/api';

export const FETCHING_LEADS = 'FETCHING_LEADS';
export const FETCHING_LEADS_SUCCESS = 'FETCHING_LEADS_SUCCESS';
export const FETCHING_LEADS_FAILURE = 'FETCHING_LEADS_FAILURE';

export const FETCHING_FILTERS = 'FETCHING_FILTERS';
export const FETCHING_FILTERS_SUCCESS = 'FETCHING_FILTERS_SUCCESS';
export const FETCHING_FILTERS_FAILURE = 'FETCHING_FILTERS_FAILURE';

export const FETCHING_SORT_SETTINGS = 'FETCHING_SORT_SETTINGS';
export const FETCHING_SORT_SETTINGS_SUCCESS = 'FETCHING_SORT_SETTINGS_SUCCESS';
export const FETCHING_SORT_SETTINGS_FAILURE = 'FETCHING_SORT_SETTINGS_FAILURE';

export const CHANGING_FILTERS = 'CHANGING_FILTERS';
export const CHANGING_SORTING_FIELDS = 'CHANGING_SORTING_FIELDS';
export const CHANGING_SORTING_ORDER = 'CHANGING_SORTING_ORDER';

export const GENERATE_REPORT = 'GENERATE_REPORT';

export const UPDATE_SORT = 'UPDATE_SORT';

/** Get filters for leads companies filters. */
export function getFilters() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/events/leads_report/filters',
      startType: FETCHING_FILTERS,
      successType: FETCHING_FILTERS_SUCCESS,
      errorType: FETCHING_FILTERS_FAILURE,
    },
  };
}

/** Get start sort settings which are set from the last generated leads report. */
export function getStartSortSettings() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/events/leads_report/sorts',
      startType: FETCHING_SORT_SETTINGS,
      successType: FETCHING_SORT_SETTINGS_SUCCESS,
      errorType: FETCHING_SORT_SETTINGS_FAILURE,
    },
  };
}

/**
 * Get leads companies data.
 *
 * @param {object} params Params.
 * @param {Array} params.usersIds User IDs list.
 * @param {number} params.page Page number.
 * @param {number} params.limit Request limit.
 * @param {string} params.sort Sort data.
 * @param {boolean} params.hideDuplicates Hide duplicates.
 */
export function getLeads({ usersIds, page = 1, limit = 1000, sort, sorts, hideDuplicates }) {
  const query = {
    page,
    limit,
  };

  const body = {
    user_ids: usersIds,
    hide_duplicates: hideDuplicates,
    sort: sort || undefined,
    sorts,
  };

  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/events/leads_report',
      query,
      body,
      startType: FETCHING_LEADS,
      successType: FETCHING_LEADS_SUCCESS,
      errorType: FETCHING_LEADS_FAILURE,
    },
  };
}

/**
 * Create an action for changing filters.
 *
 * @param {object} field Changed field.
 * @param {object} moduleIndex Module index.
 * @param {object} userIndex User index.
 */
export const changeFilters = (field, moduleIndex, userIndex = null) => dispatch => {
  dispatch({
    type: CHANGING_FILTERS,
    field,
    moduleIndex,
    userIndex,
  });
};

/**
 * Create an action for changing sorting fields.
 *
 * @param {string} field Sorting field.
 * @param {number} direction Sorting direction (+1 | -1).
 * @param {boolean} included Whether field is included to sort list or not (from all available sorting fields).
 * @param {number} order Sorted order.
 */
export const changeSortingFields = (field, direction, included, order) => ({
  type: CHANGING_SORTING_FIELDS,
  field,
  direction,
  included,
  order,
});

/**
 * Create an action for changing sorting order.
 *
 * @param {string} orderedList Sorted fields names with order value.
 */
export const changeSortingOrder = orderedList => ({
  type: CHANGING_SORTING_ORDER,
  orderedList,
});

/**
 * Create an action for generate report.
 *
 * @param {string} sort Sort data.
 * @param {object} sorts Multi sort model by smart filter.
 * @param {boolean} hideDuplicates Hide duplicates.
 */
export const generateReport = (sort, sorts, hideDuplicates) => (dispatch, getState) => {
  dispatch({
    type: GENERATE_REPORT,
  });

  const usersIds = getState()
    .leadsReport.getIn(['filters', 'usersIds'])
    .toArray();

  if (usersIds.length !== 0) {
    dispatch(getLeads({ usersIds, sort, sorts, hideDuplicates }));
  }
};

/** Create an action for update report table sort. */
export const updateSort = sort => ({
  type: UPDATE_SORT,
  sort,
});
