import { fromJS } from 'immutable';

import { getIn, setIn } from '../../utils/ChangeSpy';

/**
 * Always check if current action should append or replace suggests.
 *
 * @param namePath {String} Path to name value in state (dot `.` as separator).
 * @param listPath {String} Path to suggest value in state (dot `.` as separator).
 * @param loadType {String|Symbol} Action type that is used in success load.
 * @param reducer {Function} Reducer.
 * @returns {Function}
 */
export function check(namePath, listPath, loadType, reducer) {
  return function(state, action) {
    if (action.type === loadType) {
      state = updateSuggestIfActual(state, namePath, listPath, action);
    }

    return reducer(state, action);
  };
}

/**
 * Always check if current action should append or replace suggests.
 *
 * @param rule {checkWithDynPaths~rule} Function that get `action` as argument and calculate
 *    if it is suggest, namePath and listPath.
 * @param loadType {String|Symbol} Action type that is used in success load.
 * @param reducer {Function} Reducer.
 * @returns {Function}
 */
export function checkWithDynPaths(rule, loadType, reducer) {
  return function(state, action) {
    if (action.type === loadType) {
      const { isSuggest, namePath, listPath } = rule(action);

      if (isSuggest) {
        state = updateSuggestIfActual(state, namePath, listPath, action);
      }
    }

    return reducer(state, action);
  };
}

/**
 * Always check if current action should append or replace suggests.
 *
 * @param rule {checkWithDynPaths~rule} Function that get `action` as argument and calculate
 *    if it is suggest, namePath and listPath.
 * @param changeType {String|Symbol} Action type that is used in success load.
 * @param reducer {Function} Reducer.
 * @returns {Function}
 */
export function resetFieldByOther(namePath, listPath, changeType, reducer) {
  return function(state, action) {
    if (action.type === changeType && action.name === namePath) {
      state = setIn(state, listPath, '');
    }

    return reducer(state, action);
  };
}

/**
 * Always check if current action should append or replace suggests.
 *
 * @param rule {checkWithDynPaths~rule} Function that get `action` as argument and calculate
 *    if it is suggest, namePath and listPath.
 * @param changeType {String|Symbol} Action type that is used in success load.
 * @param reducer {Function} Reducer.
 * @returns {Function}
 */
export function resetFieldByOtherWithDynPaths(rule, changeType, reducer, defaultValue = '') {
  return function(state, action) {
    if (action.type === changeType) {
      const { isSuggest, idPath } = rule(action);

      if (isSuggest) {
        state = setIn(state, idPath, defaultValue);
      }
    }

    return reducer(state, action);
  };
}

/**
 * @callback checkWithDynPaths~rule
 * @param action {object} Usual action object.
 * @returns {object} Structure is `{ isSuggest: bool, namePath: string, listPath: string }`.
 */

/**
 * Always change id value to `0` on name value changes.
 *
 * @param namePath {String} Path to name value in state (dot `.` as separator).
 * @param idPath {String} Path to id value in state (dot `.` as separator).
 * @param changeType {String|Symbol} Action type that is used in update name value.
 * @param reducer {Function}.
 * @returns {Function}
 */
export function autoRemoveId(namePath, idPath, changeType, reducer, field = 'name') {
  return function(state, action) {
    state = reducer(state, action);
    if (action.type === changeType && action[field] === namePath) {
      state = setIn(state, idPath, 0);
    }

    return state;
  };
}

/**
 * Always change id value to `0` on name value changes.
 *
 * @param rule {autoRemoveIdWithDynPaths~rule} Function that get `action` as argument and calculate if it is suggest, namePath and idPath.
 * @param changeType {String|Symbol} Action type that is used in update name value.
 * @param reducer {Function} Reducer.
 * @returns {Function}
 */
export function autoRemoveIdWithDynPaths(rule, changeType, reducer) {
  return function(state, action) {
    state = reducer(state, action);
    if (action.type === changeType) {
      const { isSuggest, namePath, idPath } = rule(action);

      if (isSuggest && action.name === namePath) {
        state = setIn(state, idPath, 0);
      }
    }

    return state;
  };
}

/**
 * Always change id value to `0` on name value changes.
 *
 * @param namePath {String} Path to name value in state (dot `.` as separator).
 * @param idPath {String} Path to id value in state (dot `.` as separator).
 * @param changeType {String|Symbol} Action type that is used in update name value.
 * @param reducer {Function}.
 * @param field {String}.
 * @returns {Function}
 */
export function autoNullId(namePath, idPath, changeType, reducer, field = 'name') {
  return function(state, action) {
    state = reducer(state, action);
    if (action.type === changeType && action[field] === namePath) {
      if (getIn(state, namePath) === '') {
        state = setIn(state, idPath, null);
      }
    }

    return state;
  };
}

/**
 * Always change id value to `0` on name value changes.
 *
 * @param rule {autoRemoveIdWithDynPaths~rule} Function that get `action` as argument and calculate
 *    if it is suggest, namePath and idPath.
 * @param changeType {String|Symbol} Action type that is used in update name value.
 * @param reducer {Function} Reducer.
 * @returns {Function}
 */
export function autoNullIdWithDynPaths(rule, changeType, reducer) {
  return function(state, action) {
    state = reducer(state, action);
    if (action.type === changeType) {
      const { isSuggest, namePath, idPath } = rule(action);

      if (isSuggest && action.name === namePath && getIn(state, namePath) === '') {
        state = setIn(state, idPath, null);
      }
    }

    return state;
  };
}

/**
 * Check if should update suggestions. Replace suggestions if first page of loading.
 * Append if not.
 *
 * @param state {object}.
 * @param suggestValuePath {String} Path to value of suggest input.
 * @param suggestsPath {String} Path to suggests.
 * @param action {object}.
 * @returns {object} State.
 */
function updateSuggestIfActual(state, suggestValuePath, suggestsPath, action) {
  const currentValue = getIn(state, suggestValuePath);
  const regExp = new RegExp(String(action.filter).replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1'), 'i');

  if (regExp.test(currentValue)) {
    return setSuggests(state, action, suggestsPath);
  }

  return state;
}

/**
 * Check if current page is 1. If it is, replace, if it is not, append suggests.
 *
 * @param state {object}.
 * @param action {object}.
 * @param path {String}.
 * @returns {object} State.
 */
function setSuggests(state, action, path) {
  const { data, meta } = action.response;
  const immutableData = fromJS(data);
  let result = immutableData;

  try {
    const { pagination } = meta;
    const { currentPage } = pagination;

    if (currentPage !== 1) {
      result = getIn(state, path).concat(immutableData);
    }
  } catch (e) {
    result = immutableData;
  }

  return setIn(state, path, result);
}
