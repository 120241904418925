import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import Popups from '../../components/Popup';

class RuntimeErrorPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.context.closePopup();
  }

  render() {
    const { error } = this.props;

    return <Popups.ErrorPopup error={error} onClose={this.onClose} />;
  }
}

RuntimeErrorPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
};

RuntimeErrorPopupContainer.propTypes = {
  error: PropTypes.node,
};

function mapStateToProps(state) {
  return {
    error: state.popup.getIn(['props', 'error']),
  };
}

export { RuntimeErrorPopupContainer };
export default connect(mapStateToProps, {}, mergeProps, connectOptions)(RuntimeErrorPopupContainer);
