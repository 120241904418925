import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';

import getZIndex from '../../utils/getZIndex';

export default function DisplayErrorDecorator(Comp) {
  class DisplayError extends PureComponent {
    constructor(props, context) {
      super(props, context);

      this.savePortalRef = this.savePortalRef.bind(this);
      this.rememberComponent = this.rememberComponent.bind(this);
      this.portal = null;
      this.component = null;
    }

    savePortalRef(portal) {
      if (this.portal) {
        if (this.portal.node) {
          const { node } = this.portal;

          if (Array.prototype.indexOf.call(node.parentNode, node) !== -1) {
            node.parentNode.removeChild(node); // portal have some problems with self-removing. PR created.
          }
        }
      }
      this.portal = portal;
    }

    renderError() {
      const { error } = this.props;

      if (!error) return null;

      const targetElement = this.component;

      if (!targetElement) return null;

      const bodyRect = document.documentElement.getBoundingClientRect();
      const targetRect = targetElement.getBoundingClientRect();

      if (targetRect.bottom === 0 && targetRect.top === 0) return null;

      const zIndex = getZIndex(targetElement) + 1;
      const top = targetRect.bottom - bodyRect.top;
      const left = targetRect.left - bodyRect.left;
      const { width } = targetRect;

      const style = {
        position: 'absolute',
        minWidth: '150px',
        maxHeight: '200px',
        overflowY: 'auto',
        top,
        left,
        width,
        zIndex,
      };

      const container = (
        <div ref={this.savePortalRef} style={style}>
          {error}
        </div>
      );
      const portal = createPortal(container, document.querySelector('body'));

      return portal;
    }

    rememberComponent(ref) {
      this.component = ref;
    }

    render() {
      const { error, ...rest } = this.props;

      return (
        <div ref={this.rememberComponent} className="clearfix">
          <Comp {...rest} />
          {this.renderError()}
        </div>
      );
    }
  }

  DisplayError.propTypes = {
    error: PropTypes.element,
  };

  DisplayError.displayName = `DisplayError(${Comp.displayName || Comp.name})`;

  return DisplayError;
}
