import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import moment from 'moment';

import Popups from '../../components/Popup';
import { unwrap } from '../../utils/ChangeSpy';
import AutoCompleteContainer from '../AutoComplete';
import config from '../../config';
import { findAnalysts, findDirectors, handleUpdateCompany } from '../../actions/company/companyTarget';
import { activateTargetRole } from '../../actions/company/targetCreate';

class ActiveTargetPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.onClose = this.onClose.bind(this);
    this.getSuggest = this.getSuggest.bind(this);
    this.onSuggestChange = this.onSuggestChange.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.save = this.save.bind(this);
    this.form = this.props.formData.toJS();
    this.state = {
      message: '',
      processing: false,
    };
  }

  UNSAFE_componentWillMount() {
    const user = this.context.currentUser;

    if (user) {
      const suggestDirectorId = user.get('moduleId');
      const suggestDirectorValue = user.get('module', '');
      let suggestAnalystId;
      let suggestAnalystValue;

      const roles = user.get('roles', List());

      if (roles.size && roles.getIn([0, 'slug']) === config.ANALYST_ASSOCIATE) {
        suggestAnalystValue = user.get('userName');
        suggestAnalystId = user.get('id');
      }

      this.context.updatePopupFormData({
        suggestDirectorId,
        suggestDirectorValue,
        suggestAnalystId,
        suggestAnalystValue,
      });
    }
  }

  onClose() {
    this.context.closePopup();
  }

  onCreate() {
    const { popup, formData } = this.props;
    const companyId = popup.getIn(['props', 'companyId'], 0);

    if (companyId > 0 && !this.state.processing) {
      if (formData.get('suggestDirectorValue')) {
        this.setState(
          {
            message: '',
            processing: true,
          },
          this.save,
        );
      } else {
        this.setState({
          message: 'You must select a module',
          processing: false,
        });
      }
    }
  }

  save() {
    const { popup, activateTargetRole, formData } = this.props;
    const companyId = popup.getIn(['props', 'companyId'], 0);

    activateTargetRole({
      body: {
        recordSubOwnerId: formData.get('suggestAnalystId'),
        recordOwnerId: formData.get('suggestDirectorId'),
        lastResearcherId: formData.get('suggestAnalystId'),
        lastResearchedDate: moment().format('YYYY-MM-DD'),
      },
      companyId,
      afterSuccess: () => {
        this.context.redirectForce(window.location);
      },
      afterError: () => {
        this.setState({
          message: 'Active target role failed. Please try again',
          processing: false,
        });
      },
    });
  }

  onSuggestChange({ name, value }) {
    if (name === 'suggests') {
      this.props.handleUpdateCompany({ name, value });
    } else {
      this.form[name] = value;
      this.context.updatePopupFormData({
        ...this.form,
        [name]: value,
      });
    }
  }

  getSuggest(name) {
    const { formData, suggestions, findDirectors, findAnalysts } = this.props;
    const find = name === 'suggestDirector' ? findDirectors : findAnalysts;

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={find}
        idName={`${name}Id`}
        idValue="idValue"
        suggestions={suggestions}
        suggestsName="suggests"
        value={formData.get(`${name}Value`)}
        valueName={`${name}Value`}
      />
    );
  }

  render() {
    const { popup, buyerName } = this.props;
    const { message, processing } = this.state;
    const {
      props: { isFinacialBuyer = false },
    } = popup.toJS();
    const suggestDirector = this.getSuggest('suggestDirector');
    const suggestAnalyst = this.getSuggest('suggestAnalyst');

    return (
      <Popups.ActiveTargetPopup
        buyerName={buyerName}
        isFinacialBuyer={isFinacialBuyer}
        message={message}
        onClose={this.onClose}
        onCreate={this.onCreate}
        processing={processing}
        suggestAnalyst={suggestAnalyst}
        suggestDirector={suggestDirector}
      />
    );
  }
}

ActiveTargetPopupContainer.contextTypes = {
  currentUser: PropTypes.instanceOf(Map).isRequired,
  redirectForce: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  updatePopupFormData: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  ...props,
  formData: state.popup.get('formData'),
  suggestions: state.targetCompany.target.get('suggests'),
  buyerName: unwrap(state.targetCompany.info.getIn(['info', 'legalName'])),
});

export default connect(mapStateToProps, {
  findAnalysts,
  findDirectors,
  handleUpdateCompany,
  activateTargetRole,
})(ActiveTargetPopupContainer);
