import { fromJS } from 'immutable';
import * as ActionType from '../../actions/contact/contactTarget';

/**
 * Default state of targets. Is an empty list.
 *
 * @type {Immutable.Map}
 */
const defaultState = fromJS({
  loading: false,
  error: null,
  targets: [],
});

/**
 * Contact's targets reducer.
 *
 * @param [state=defaultState] {Immutable.List} List of targets.
 * @param action {Object} Object describes action.
 * @param action.type {Symbol|String} Action's type.
 * @param [action.response] {Object} API's response.
 * @returns {Immutable.Map}
 */
export default function(state = defaultState, action) {
  switch (action.type) {
    case ActionType.LOAD_CONTACT_TARGET:
      return defaultState.merge({ loading: true });

    case ActionType.LOADED_CONTACT_TARGET:
      return state.merge({
        loading: false,
        targets: mapResponse(action.response),
      });

    case ActionType.ERROR_CONTACT_TARGET:
      return state.merge({ loading: false });

    default:
      return state;
  }
}

/**
 * Map response to set defaults values and get some non-pretty names values.
 *
 * @param response {Object} Response body from API.
 * @param response.data {object[]} Array of buyers.
 * @returns {object[]}
 */
function mapResponse({ data }) {
  return data.map(target => {
    let endYear = target.endYear || 'current';

    if (endYear === -1 || endYear === '-1') endYear = '?';

    return { ...target, endYear };
  });
}
