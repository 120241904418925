import PropTypes from 'prop-types';
import React from 'react';
import { Map, List } from 'immutable';

import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import FormGroup from '../helpers/FormGroup';
import AutoSuggestion from '../helpers/AutoSuggestion';
import Select from '../helpers/Select';
import Checkbox from '../helpers/Checkbox';
import ErrorList from '../helpers/ErrorList';

/**
 * Add/Update buyer popup component.
 *
 * @param props {Object}.
 * @param props.buyer {Immutable.Map} Buyer information.
 * @param props.project {Immutable.Map} Project information.
 * @param props.approval {Immutable.Map} Approval list information.
 * @param props.currentStatus {String} Current status.
 * @param props.highStatus {String} High status.
 * @param props.statuses {Immutable.List} List of statuses.
 * @param props.waiting {Boolean} Show spinner icon if set to true.
 * @param props.activeBuyer {Boolean} Checkbox value of buyer.
 * @param props.overrideCurrent {Boolean} Checkbox value of current status.
 * @param props.overrideHigh {Boolean} Checkbox value of high status.
 * @param props.overrideProject {Boolean} Checkbox value of project.
 * @param props.overrideApproval {Boolean} Checkbox value of approval list.
 * @param props.disableProject {Boolean} Disable project suggestion if set to true.
 * @param props.disableApproval {Boolean} Disable approval suggestion if set to true.
 * @param props.errors {Immutable.List} List of errors.
 * @param props.onChange {Function} Function to handle on input control changing.
 * @param props.onSave {Function} Function to handle on save button.
 * @param props.onClose {Function} Function to handle on close button.
 * @param props.onSelect {Function} Function to handle on selecting suggestion.
 * @param props.onSuggestionClose {Function} Function to handle on closing suggestion.
 * @returns {React.Component}
 */
const AddUpdateBuyerPopup = props => {
  const {
    buyer,
    project,
    approval,
    currentStatus,
    highStatus,
    statuses,
    waiting,
    activeBuyer,
    overrideCurrent,
    overrideHigh,
    overrideProject,
    overrideApproval,
    buyerSuggestions,
    projectSuggestions,
    approvalSuggestions,
    disableProject,
    disableApproval,
    errors,
    onChange,
    onSave,
    onClose,
    onSelect,
    onSuggestionClose,
    canSave,
  } = props;
  const spinner = waiting ? <i className="fa fa-spinner fa-pulse fa-fw" /> : null;
  const header = 'Add/Update Buyer';
  const buyerValue = buyer.get('value');
  const buyerMode = buyer.get('mode');
  const projectValue = project.get('value');
  const projectMode = project.get('mode');
  const approvalValue = approval.get('value');
  const approvalMode = approval.get('mode');
  const suggestionWidth = 300;
  const footer = [
    <Button
      key="ok"
      className="btn-primary btn-xs"
      data-dismiss="modal"
      disabled={!canSave || waiting}
      onClick={onSave}
    >
      {spinner} Save
    </Button>,
    <Button key="close" className="btn-default btn-xs" data-dismiss="modal" onClick={onClose}>
      Close
    </Button>,
  ];

  return (
    <Popup footer={footer} header={header} id="addUpdateBuyer">
      <div className="row">
        <div className="col-xs-3">
          <FormGroup>
            <label htmlFor="buyerInput">Buyer</label>
            <AutoSuggestion
              id="buyerInput"
              mode={buyerMode}
              name="buyer"
              onSuggestionClose={onSuggestionClose}
              onSuggestionSelect={onSelect}
              onTextChange={onChange}
              placeholder="Buyer"
              suggestions={buyerSuggestions}
              value={buyerValue}
              width={suggestionWidth}
              autoFocus
            />
          </FormGroup>
          <div className="group-checkbox">
            <Checkbox
              checked={activeBuyer}
              label="Set as active buyer"
              name="activeBuyer"
              onChange={event => onChange({ event })}
            />
          </div>
        </div>
        <div className="col-xs-2">
          <FormGroup>
            <Select
              defaultText=" "
              defaultTextValue=""
              id="currentSelect"
              label="Current"
              name="currentStatus"
              onChange={event => onChange({ event })}
              options={statuses}
              value={currentStatus}
              showLabel
            />
          </FormGroup>
          <div className="group-checkbox">
            <Checkbox
              checked={overrideCurrent}
              label="Override"
              name="overrideCurrent"
              onChange={event => onChange({ event })}
            />
          </div>
        </div>
        <div className="col-xs-2">
          <FormGroup>
            <Select
              defaultText=" "
              defaultTextValue=""
              id="highSelect"
              label="High"
              name="highStatus"
              onChange={event => onChange({ event })}
              options={statuses}
              value={highStatus}
              disableDefault
              showLabel
            />
          </FormGroup>
          <div className="group-checkbox">
            <Checkbox
              checked={overrideHigh}
              label="Override"
              name="overrideHigh"
              onChange={event => onChange({ event })}
            />
          </div>
        </div>
        <div className="col-xs-3">
          <FormGroup>
            <label htmlFor="projectInput">Project</label>
            <AutoSuggestion
              disabled={disableProject}
              id="projectInput"
              mode={projectMode}
              name="project"
              onSuggestionClose={onSuggestionClose}
              onSuggestionSelect={onSelect}
              onTextChange={onChange}
              placeholder="Project"
              suggestions={projectSuggestions}
              value={projectValue}
              width={suggestionWidth}
            />
          </FormGroup>
          <div className="group-checkbox">
            <Checkbox
              checked={overrideProject}
              label="Override"
              name="overrideProject"
              onChange={event => onChange({ event })}
            />
          </div>
        </div>
        <div className="col-xs-2">
          <FormGroup>
            <label htmlFor="approvalInput">Approval List</label>
            <AutoSuggestion
              disabled={disableApproval}
              id="approvalInput"
              mode={approvalMode}
              name="approval"
              onSuggestionClose={onSuggestionClose}
              onSuggestionSelect={onSelect}
              onTextChange={onChange}
              placeholder="Approval List"
              suggestions={approvalSuggestions}
              value={approvalValue}
              width={suggestionWidth}
            />
          </FormGroup>
          <div className="group-checkbox">
            <Checkbox
              checked={overrideApproval}
              label="Override"
              name="overrideApproval"
              onChange={event => onChange({ event })}
            />
          </div>
        </div>
      </div>
      <ErrorList className="mt20" errors={errors} />
    </Popup>
  );
};

AddUpdateBuyerPopup.propTypes = {
  activeBuyer: PropTypes.bool.isRequired,
  approval: PropTypes.instanceOf(Map).isRequired,
  buyer: PropTypes.instanceOf(Map).isRequired,
  currentStatus: PropTypes.string.isRequired,
  disableApproval: PropTypes.bool.isRequired,
  disableProject: PropTypes.bool.isRequired,
  errors: PropTypes.instanceOf(List).isRequired,
  highStatus: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSuggestionClose: PropTypes.func.isRequired,
  overrideApproval: PropTypes.bool.isRequired,
  overrideCurrent: PropTypes.bool.isRequired,
  overrideHigh: PropTypes.bool.isRequired,
  overrideProject: PropTypes.bool.isRequired,
  project: PropTypes.instanceOf(Map).isRequired,
  statuses: PropTypes.instanceOf(List).isRequired,
  waiting: PropTypes.bool.isRequired,
};

export default AddUpdateBuyerPopup;
