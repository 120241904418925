import * as React from 'react';

const WarningMessage = props => {
  const { show, messageText } = props;

  if (show) {
    return <div>{messageText}</div>;
  }

  return null;
};

export default WarningMessage;
