import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { List, Map } from 'immutable';
import classNames from 'classnames';

import Tabs from '../helpers/Tabs';
import DropDown, { DropDownElement } from '../helpers/DropDown';
import UrlMaker from '../../routing/UrlMaker';
import LoadAnimation from '../decorators/LoadAnimation';
import ContactRouting from '../../routing/ContactRouting';
import ContactActivities from './ContactActivities';
import ContactInfo from './ContactInfo';
import downloadFile from '../../utils/downloadFile';

class Contact extends PureComponent {
  constructor(props) {
    super(props);
    this.onTopPanelRef = this.onTopPanelRef.bind(this);
    this.getHeight = this.getHeight.bind(this);
    this.state = {
      topHeight: 0,
      tabHeight: 0,
    };
  }

  getHeight(elm) {
    let elmHeight;
    let elmMargin;

    if (document.all) {
      // IE
      elmHeight = parseInt(elm.currentStyle.height, 10);
      elmMargin = parseInt(elm.currentStyle.marginTop, 10) + parseInt(elm.currentStyle.marginBottom, 10);
    } else {
      // Mozilla
      elmHeight = parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('height'), 10);
      elmMargin =
        parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-top'), 10) +
        parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-bottom'), 10);
    }

    return elmHeight + elmMargin;
  }

  onTopPanelRef(input) {
    this.topPanel = input;
  }

  componentDidMount() {
    if (this.topPanel) {
      this.setState({
        topHeight: this.getHeight(this.topPanel),
      });
    }
  }

  render() {
    return ContactRender(this.props, this.state, this.onTopPanelRef);
  }
}

/**
 *  Contact page component.
 *
 *  @link http://screencloud.net/v/w8S0 component itself
 *  @param props {Object}.
 *  @param props.tabs {Immutable.List} List of tabs of bottom part (name + link).
 *  @param props.values {Immutable.Map} Map with application constants.
 *  @param props.onChange {Function} On contact field change callback.
 *  @param props.contact {Immutable.Map} Contact's all info map. Activities and meta info are
 *      placed in it.
 */
const ContactRender = (props, state, onTopPanelRef) => {
  const {
    showSave,
    onBlurInfo,
    onSubmitInfo,
    onSave,
    tabs,
    values,
    contactInfo,
    contactEventProps,
    children,
    onChange,
    params,
    suffixSuggest,
    ...rest
  } = props;

  const tabsContent = tabs.map(tab => {
    const to = UrlMaker.create(tab.get('to')).mapParams(params);

    return tab.set('to', to);
  });

  const vCardUrl = UrlMaker.create(values.get('vCardUrl')).mapParams(params);

  const availableTabs = Map({
    target: contactInfo.get('hasTargets'),
    buyer: contactInfo.get('hasBuyers'),
    exec: contactInfo.get('isExecutive'),
    lp: contactInfo.get('isLP'),
  });

  const topHeight = `calc(100% - ${state.topHeight}px)`;

  if (isNaN(params.contactId)) return children;

  return (
    <div className="full-height contact__main">
      <ContactTopPart
        contactEventProps={contactEventProps}
        contactInfo={contactInfo}
        onBlurInfo={onBlurInfo}
        onChange={onChange}
        onRef={onTopPanelRef}
        onSubmitInfo={onSubmitInfo}
        suffixSuggest={suffixSuggest}
      />

      <div className="container contact__tab-section" role="main" style={{ height: topHeight }}>
        <div className="highlight wrap-border">
          <Tabs availableTabs={availableTabs} tabs={tabsContent} />
        </div>

        <div className="pt10 top-border-none" style={{ height: 'calc(100% - 36px)' }}>
          <ContactRouting />
        </div>
      </div>

      <ContactFooter {...rest} onSave={onSave} showSave={showSave} values={values} vCardUrl={vCardUrl} />
    </div>
  );
};

class ContactTopPart extends PureComponent {
  render() {
    const { onBlurInfo, onSubmitInfo, contactInfo, suffixSuggest, contactEventProps, onChange, onRef } = this.props;

    const topSideClassName = classNames('top_side container contact-info__top-section-wrapper', {
      'do-not-mail': contactInfo.getIn(['doNotMail', 'value'], false),
    });

    return (
      <div ref={onRef} className={topSideClassName}>
        <div className="row contact-info__top-section">
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 contact-info__left-part">
            <ContactInfo
              info={contactInfo}
              onBlur={onBlurInfo}
              onChange={onChange}
              onSubmit={onSubmitInfo}
              suffixSuggest={suffixSuggest}
            />
          </div>

          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 contact-info__right-part">
            <div className="highlight no-padding wrap-border-full table-responsive contact-activity__container flexWrapper mb0">
              <ContactActivities {...contactEventProps} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
class ContactFooter extends PureComponent {
  handleDownloadVCard(event) {
    event.preventDefault();

    downloadFile({ url: this.props.vCardUrl });
  }

  render() {
    const { values, showSave, onSave } = this.props;

    const footerDropDown = values.get('footerDropDown').map((elem, i) => {
      const attributes = elem.get('attributes').toJS();

      return (
        <DropDownElement {...attributes} key={i} onClick={this.props[elem.get('onClickField')]}>
          {elem.get('name')}
        </DropDownElement>
      );
    });

    const saveButtonClassName = classNames('btn btn-default btn-xs', {
      hidden: !showSave,
    });

    return (
      <footer className="navbar-fixed-bottom">
        <div className="container">
          <div className="row">
            <div className="container-fluid text-right">
              <div className="btns">
                <button
                  className="btn btn-xs"
                  onClick={event => this.handleDownloadVCard(event)}
                  title="Download a vCard"
                  type="button"
                >
                  <i aria-hidden="true" className="fa fa-user-plus" />
                </button>

                <DropDown
                  align="right"
                  btnContent="New"
                  className="btn-default btn-xs"
                  id="dropdownMenu7"
                  wrapperClassName="ib"
                >
                  {footerDropDown}
                </DropDown>

                <button className={saveButtonClassName} onClick={onSave} type="button">
                  <i className="icon-user icon-white" />
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

ContactRender.propTypes = {
  /**
   * This component needs children property (passed by `<Comp>{children}</Comp>`).
   * Children should be React.Component.
   */
  children: PropTypes.element,
  onChange: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  tabs: PropTypes.instanceOf(List).isRequired,
  values: PropTypes.instanceOf(Map).isRequired,
};

export default LoadAnimation(Contact);
