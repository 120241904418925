import { CALL_API } from '../middleware/api';

export const FETCHING_FIND_EMAIL = 'FETCHING_FIND_EMAIL';
export const FETCHING_FIND_EMAIL_FAILURE = 'FETCHING_FIND_EMAIL_FAILURE';
export const FETCHING_FIND_EMAIL_SUCCESS = 'FETCHING_FIND_EMAIL_SUCCESS';
export const CLEAR_FOUND_EMAIL = 'CLEAR_FOUND_EMAIL';
export const CLOSE_VALIDATION_ERROR = 'CLOSE_VALIDATION_ERROR';
export const UPDATE_FINDER = 'UPDATE_FINDER';

/**
 * Find email.
 *
 * @param params {FormData} Form data find email.
 * @returns Action.
 */
export function findEmail(params) {
  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/browse/email/find`,
      body: { ...params },
      skipGlobalErrorHandler: true,
      unifier: 'post /api/v1/browse/email/find',
      startType: FETCHING_FIND_EMAIL,
      errorType: FETCHING_FIND_EMAIL_FAILURE,
      successType: FETCHING_FIND_EMAIL_SUCCESS,
    },
  };
}

/**
 * Clear found email..
 *
 * @returns Action.
 */
export function clearFoundEmail() {
  return {
    type: CLEAR_FOUND_EMAIL,
  };
}

/**
 * Remove validation errors.
 *
 * @returns {{type: symbol, field: string}}
 */
export function closeValidationError({ field }) {
  return {
    type: CLOSE_VALIDATION_ERROR,
    field,
  };
}

export function handleUpdateFinder(info) {
  return {
    type: UPDATE_FINDER,
    ...info,
  };
}
