import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { ContextMenuTrigger } from 'react-contextmenu';

import { isDeepChanged } from '../../../../../utils/ChangeSpy';

class ChangeSpyCellRender extends PureComponent {
  constructor(props) {
    super(props);
    this.collect = this.collect.bind(this);
  }

  collect() {
    return {
      rowIndex: this.props.rowIndex,
      rowData: this.props.data,
    };
  }

  render() {
    const { value, colDef, data } = this.props;
    const divClassName = classNames('ag-cell ag-cell-not-inline-editing', {
      'cell cell--marked': isDeepChanged(data),
    });

    return (
      <ContextMenuTrigger collect={this.collect} id={colDef.contextMenuId}>
        <div className={divClassName}>{value}</div>
      </ContextMenuTrigger>
    );
  }
}

export default ChangeSpyCellRender;
