import { fromJS } from 'immutable';

import { CHANGE_NA_POPUP_FORM } from '../../actions/approval/naPopup';

const defaultState = fromJS({
  action: {
    date: null,
    assigned: '',
    status: '',
    activity: '',
    description: '',
    approved: false,
    passed: false,
    noResponse: false,
    override: false,
  },
  errors: [],
  status: '',
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case CHANGE_NA_POPUP_FORM:
      return state.setIn(['action', action.name], action.value);

    default:
      return state;
  }
}
