import { CALL_API } from '../middleware/api';

export const LOADED_INDUSTRY_TAGS_SUCCESS = Symbol('LOADED_INDUSTRY_TAGS_SUCCESS');
export const LOADING_INDUSTRY_TAGS = Symbol('LOADING_INDUSTRY_TAGS');
export const LOADED_INDUSTRY_TAGS_FAIL = Symbol('LOADED_INDUSTRY_TAGS_FAIL');

export const INDUSTRY_TAG_SELECTION = Symbol('INDUSTRY_TAG_SELECTION');
export const INDUSTRY_TAG_SELECTIONS = Symbol('INDUSTRY_TAG_SELECTIONS');
export const TOGGLE_TAG_LIST = Symbol('TOGGLE_TAG_LIST');
export const RESET_TAG_LIST = Symbol('RESET_TAG_LIST');

/**
 * Load list of industry tags.
 */
export function loadIndustryTags(selectedTags = {}) {
  return {
    selectedTags,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/industry_categories/parents?include=children',
      startType: LOADING_INDUSTRY_TAGS,
      successType: LOADED_INDUSTRY_TAGS_SUCCESS,
      errorType: LOADED_INDUSTRY_TAGS_FAIL,
    },
  };
}

/**
 * Update list of selected industry tags.
 */
export function selectTag(id, parentId) {
  return {
    type: INDUSTRY_TAG_SELECTION,
    id,
    parentId,
  };
}

/**
 * Select tags.
 */
export function selectTags(ids) {
  return {
    type: INDUSTRY_TAG_SELECTIONS,
    ids,
  };
}

/**
 * Toggle list of tags.
 */
export function toggleTagList(id, ids) {
  return {
    type: TOGGLE_TAG_LIST,
    id,
    ids,
  };
}

/**
 * Reset tag list.
 */
export function reset(previousTags) {
  return {
    type: RESET_TAG_LIST,
    previousTags,
  };
}
