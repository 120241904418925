import isFunction from './isFunction';

export function AbortingError(message) {
  if (!Error.captureStackTrace) this.stack = new Error().stack;
  else Error.captureStackTrace(this, this.constructor);
  this.message = message;
}

AbortingError.prototype = new Error();
AbortingError.prototype.name = 'AbortingError';
AbortingError.prototype.constructor = AbortingError;

export default function createTaskQueuePool() {
  const queues = new Map();

  const getQueue = (queueId, maxCount, dislodging) => {
    const tempQueue = queues.get(queueId);

    if (!tempQueue) {
      const queue = {
        waiters: [],
        inWork: false,
        currentWorker: null,
        dislodging,
        maxCount,
        id: queueId,
      };

      queues.set(queueId, queue);

      return queue;
    }

    return tempQueue;
  };

  const sanitizeQueue = queueId => {
    const queue = queues.get(queueId);

    if (!queue) return;
    if (queue.waiters.length > 0 || queue.inWork) return;
    queues.delete(queueId);
  };

  const abortCurrentTask = queueId => {
    const queue = queues.get(queueId);

    if (!queue) {
      // eslint-disable-next-line no-console
      console.warn(`Manually abort task on empty queue with unifier "${queueId}"`);

      return;
    }

    if (!queue.currentWorker) {
      throw new Error('[SHOULD_NEVER_HAPPENED] queue without #currentWorker');
    }

    if (!queue.currentWorker.abort) {
      // eslint-disable-next-line no-console
      console.warn('Expect #abort() method in currentWorker, but found nothing.');

      return;
    }

    try {
      queue.currentWorker.abort();
      queue.inWork = false;
      queue.currentWorker = null;
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.warn(`Manually abort task with unifier "${queue.id}"`);
        if (queue.waiters.length > 0) {
          // eslint-disable-next-line no-console
          console.warn('Not implemented start another waiters after cancel current task');
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const abortQueue = queueId => {
    const queue = queues.get(queueId);

    if (!queue) {
      // eslint-disable-next-line no-console
      console.warn(`Manually abort all tasks on empty queue with unifier "${queueId}"`);

      return;
    }

    if (!queue.currentWorker) {
      throw new Error('[SHOULD_NEVER_HAPPENED] queue without #currentWorker');
    }

    if (!queue.currentWorker.abort) {
      // eslint-disable-next-line no-console
      console.warn('Expect #abort() method in currentWorker, but found nothing.');

      return;
    }

    try {
      queue.currentWorker.abort();
      queue.waiters.forEach(waiter => waiter.abort());
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.warn(`Manually abort all tasks in queue with unifier "${queue.id}"`);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const closeCurrentTaskIfNeed = queue => {
    if (!queue.dislodging) return queue;
    if (queue.inWork === false) return queue;
    if (!queue.currentWorker) {
      // eslint-disable-next-line no-console
      console.warn('Unexpected empty current worker on isWork = true.');

      return queue;
    }

    if (!queue.currentWorker.abort) {
      // eslint-disable-next-line no-console
      console.warn('Expect #abort() method in currentWorker, but found nothing.');

      return queue;
    }

    try {
      queue.currentWorker.abort();
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.warn(`Abort task with unifier "${queue.id}"`);
      }
      queue.inWork = false;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    return queue;
  };

  const removeExtraTask = queue => {
    closeCurrentTaskIfNeed(queue);

    let { maxCount } = queue;
    const { waiters, inWork } = queue;

    if (inWork) maxCount--;
    while (waiters.length && waiters.length > maxCount) {
      const taskCreator = waiters.shift();

      try {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.warn(`Prevent task start on queue with id "${queue.id}"`);
        }
        taskCreator(new AbortingError('Aborting by rules'));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Failed remove task from queue by call with aborting error as first argument.\n${e}`);
      }
    }
  };

  const tryDoQueue = queueId => {
    const queue = queues.get(queueId);

    if (!queue) return Promise.resolve();
    removeExtraTask(queue);
    if (queue.inWork) return Promise.resolve();
    queue.inWork = !!queue.waiters.length;

    const promiseLikeObject = queue.waiters.shift()();

    queue.currentWorker = promiseLikeObject;

    const promiseFinally = () => {
      queue.inWork = false;
      queue.currentWorker = null;
      sanitizeQueue(queueId);

      return tryDoQueue(queueId);
    };

    return promiseLikeObject.then(promiseFinally).catch(err => {
      promiseFinally();

      throw err;
    });
  };

  const appendToQueue = (queueId, maxCount, waiter, dislodging) => {
    const queue = getQueue(queueId, maxCount, dislodging);

    queue.waiters.push(waiter);

    return tryDoQueue(queueId);
  };

  function pushToQueue(queueId, maxCount = 1, promiseCreator, dislodging) {
    if (isFunction(maxCount)) {
      return pushToQueue(queueId, 1, maxCount, promiseCreator);
    }

    if (maxCount < 1) {
      throw new Error("Max count of waiters can't be less then 1.");
    }

    return appendToQueue(queueId, maxCount, promiseCreator, dislodging);
  }

  return {
    push: pushToQueue,
    append: pushToQueue,
    clear: function clear(queueId) {
      if (typeof queueId === 'undefined') queues.clear();
      else queues.delete(queueId);
    },
    abortCurrentTask,
    abortQueue,
  };
}
