import React from 'react';

/**
 * HOC to subscribe for tab event.
 */
export function subscribe(Comp, eventName = 'onNotify', callback = 'handleTabNotification') {
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);
      this.storageChanged = this.storageChanged.bind(this);
    }

    componentDidMount() {
      window.addEventListener('storage', this.storageChanged);
    }

    componentWillUnmount() {
      window.removeEventListener('storage', this.storageChanged);
    }

    storageChanged(event) {
      const func = this._instance[callback];

      if (
        func &&
        ((typeof eventName === 'string' && event.key === eventName) ||
          (eventName instanceof Array && eventName.indexOf(event.key) > -1)) &&
        event.newValue
      ) {
        func(JSON.parse(event.newValue), event.key);
      }
    }

    render() {
      return (
        <Comp
          {...this.props}
          ref={input => {
            this._instance = input;
          }}
        />
      );
    }
  }

  WrappedComponent.displayName = `WrappedComponent(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    'WrappedComponent'})`;

  return WrappedComponent;
}

/**
 * HOC to add tab notifying function to component.
 */
export function publish(Comp) {
  /**
   * Notify event to all opening tabs.
   *
   * @param {string} eventName Name of event.
   * @param {JSON} data Data to convey.
   */
  function notifyTab(data = {}, eventName = 'onNotify') {
    localStorage.setItem(eventName, JSON.stringify(data));
    localStorage.removeItem(eventName);
  }

  const Wrapper = props => <Comp {...props} notifyTab={notifyTab} />;

  return Wrapper;
}
