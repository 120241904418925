import React from 'react';
import Popup from '../helpers/Popup';
import Button from '../helpers/Button';

const DescriptionChangePopup = props => {
  const { onClick } = props;
  const header = 'Instructions';
  const footer = (
    <Button className="btn-primary btn-xs" data-dismiss="modal" onClick={onClick}>
      OK
    </Button>
  );

  return (
    <Popup footer={footer} header={header} id="descriptionChange">
      <div className="row des-change">
        <div className="col-xs-12">
          <p>
            Company Descriptions are used in a variety of places, but they need to be formatted such that they will fit
            grammatically into a sentence such as this
          </p>
          <p>“We have identified your company as &lt;&lt;Merged Description&gt;&gt;.”</p>
          <p>Here are a couple of general rules:</p>
          <ul>
            <li>
              The description must start with a lower cased letter, unless the first word is a proper noun in the
              possessive form.
            </li>
            <li>The final character of the description must not be any form of punctuation</li>
          </ul>
          <table className="table">
            <thead>
              <tr>
                <th>Potential Description</th>
                <th>Acceptable?</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>a manufacturer of widgets</td>
                <td>
                  <i aria-hidden="true" className="fa fa-lg fa-check-circle" />
                </td>
              </tr>
              <tr>
                <td>a leading distributer of widgets</td>
                <td>
                  <i aria-hidden="true" className="fa fa-lg fa-check-circle" />
                </td>
              </tr>
              <tr>
                <td>the world's largest manufacturer of widgets</td>
                <td>
                  <i aria-hidden="true" className="fa fa-lg fa-check-circle" />
                </td>
              </tr>
              <tr>
                <td>Mississippi's sole provider of widget packaging equipment</td>
                <td>
                  <i aria-hidden="true" className="fa fa-lg fa-check-circle" />
                </td>
              </tr>
              <tr>
                <td>an outsourced provider of contract widget manufacturing services</td>
                <td>
                  <i aria-hidden="true" className="fa fa-lg fa-check-circle" />
                </td>
              </tr>
              <tr>
                <td>the largest franchisor of widget shops in the United States</td>
                <td>
                  <i aria-hidden="true" className="fa fa-lg fa-check-circle" />
                </td>
              </tr>
              <tr>
                <td>Houston's sole widget dealer, supplying all of western Texas</td>
                <td>
                  <i aria-hidden="true" className="fa fa-lg fa-check-circle" />
                </td>
              </tr>
              <tr>
                <td>manufacturer of widgets</td>
                <td>
                  <i aria-hidden="true" className="fa fa-lg fa-times-circle" />
                </td>
              </tr>
              <tr>
                <td>Installer of widget processing equipment</td>
                <td>
                  <i aria-hidden="true" className="fa fa-lg fa-times-circle" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Popup>
  );
};

export default DescriptionChangePopup;
