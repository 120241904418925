import React, { PureComponent } from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import classNames from 'classnames';
import FormGroup from './FormGroup';
import { InputProps } from './AutoComplete';
import AutoCompleteContainer from '../../containers/AutoComplete';

const emptyMap = Map();

const EmailTemplateMenuItems = ({ data, ...rest }) => {
  if (!data) return null;

  const { email, onOpenEmail, onEditEmailTemplate, onCopyEmailTemplate } = rest;
  const menu = data.map(template => (
    <MenuItem key={template.get('id')} onClick={() => onOpenEmail(email, template)}>
      <div className={classNames('menuItem')}>
        <span className={classNames('menuItem-label')}>{template.get('label')}</span>
        <div>
          <button
            className={classNames('btnIcon')}
            onClick={event => onCopyEmailTemplate(event, template)}
            type="button"
          >
            <i className="fa fa-copy" title="Copy" />
          </button>
          <button
            className={classNames('btnIcon')}
            onClick={event => onEditEmailTemplate(event, template)}
            type="button"
          >
            <i className="fa fa-edit" title="Edit" />
          </button>
        </div>
      </div>
    </MenuItem>
  ));

  return <div className={classNames('menu-item-templates')}>{menu}</div>;
};

/**
 * Email templates context menu wrapper component.
 *
 * @param {object} props Props.
 * @param {Map} props.companyInfo Company data.
 * @param {string} props.email Email.
 * @param {List} props.emailTemplates Email templates.
 * @param {Function} props.fetchEmailTemplates Fetch email templates by suggested users.
 * @param {Function} props.findDirectors Find user by suggestions search pattern.
 * @param {string} props.id Context menu ID.
 * @param {Function} props.onOpenEmail Handle email link open.
 * @param {string} props.templateType Template type ("company | executive").
 */
export class EmailTemplatesContextMenuWrapper extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.handleSuggestChange = this.handleSuggestChange.bind(this);
    this.handleAddEmailTemplate = this.handleAddEmailTemplate.bind(this);
    this.handleEditEmailTemplate = this.handleEditEmailTemplate.bind(this);
    this.handleCopyEmailTemplate = this.handleCopyEmailTemplate.bind(this);
    this.handleShowMenu = this.handleShowMenu.bind(this);
    this.state = {
      emailTemplateValue: props.companyInfo && props.companyInfo.get('suggestDirector'),
      userId: props.companyInfo && props.companyInfo.getIn(['recordOwnerId', 'value']),
    };
  }

  handleSuggestChange({ name, value }) {
    if (name === 'suggestDirector') {
      this.setState({ emailTemplateValue: value });
    }

    if (name === 'recordOwnerId') {
      this.props.fetchEmailTemplates({ userId: value });
      this.setState({ userId: value });
    }
  }

  handleAddEmailTemplate() {
    this.context.openPopup('AddEmailTemplatePopup', {
      headerTitle: 'Create email template',
      userId: this.state.userId,
      isNew: true,
      emailTemplate: emptyMap,
      templateType: this.props.templateType,
    });
  }

  handleEditEmailTemplate(event, emailTemplate) {
    event.stopPropagation();

    this.context.openPopup('AddEmailTemplatePopup', {
      headerTitle: 'Change email template',
      userId: this.state.userId,
      isNew: false,
      emailTemplate,
      templateType: this.props.templateType,
    });
  }

  handleCopyEmailTemplate(event, emailTemplate) {
    event.stopPropagation();

    this.context.openPopup('AddEmailTemplatePopup', {
      headerTitle: 'Create email template - Copy',
      userId: this.state.userId,
      isNew: true,
      emailTemplate: emailTemplate.delete('id').set('label', `${emailTemplate.get('label')} - Copy`),
      templateType: this.props.templateType,
    });
  }

  async handleShowMenu() {
    await this.setState({ emailTemplateValue: this.props.companyInfo.get('suggestDirector') });
    await this.setState({ userId: this.props.companyInfo.getIn(['recordOwnerId', 'value']) });

    const { userId } = this.state;

    if (userId) {
      this.props.fetchEmailTemplates({ userId });
    }
  }

  render() {
    const { children, emailTemplates, email, onOpenEmail, companyInfo, findDirectors, id } = this.props;
    const { emailTemplateValue, userId } = this.state;

    return (
      <>
        <ContextMenuTrigger holdToDisplay={0} id={id}>
          {children}
        </ContextMenuTrigger>

        <ContextMenu hideOnLeave={false} id={id} onShow={this.handleShowMenu}>
          <MenuItem onClick={event => event.stopPropagation()} preventClose>
            <FormGroup className="form-inline-block">
              <InputProps className="form-control input-sm text-bold bounded" name="director" placeholder="Director">
                <AutoCompleteContainer
                  change={this.handleSuggestChange}
                  find={findDirectors}
                  idName="recordOwnerId"
                  idValue="recordOwnerId"
                  suggestions={companyInfo.get('suggests')}
                  value={emailTemplateValue}
                  valueName="suggestDirector"
                  appendToContainer
                />
              </InputProps>
            </FormGroup>
          </MenuItem>
          {userId && (
            <MenuItem onClick={this.handleAddEmailTemplate}>
              <a className="clickable">
                <b>Add New</b>
              </a>
            </MenuItem>
          )}
          <EmailTemplateMenuItems
            data={emailTemplates}
            email={email}
            onCopyEmailTemplate={this.handleCopyEmailTemplate}
            onEditEmailTemplate={this.handleEditEmailTemplate}
            onOpenEmail={onOpenEmail}
          />
        </ContextMenu>
      </>
    );
  }
}

EmailTemplatesContextMenuWrapper.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

EmailTemplatesContextMenuWrapper.propTypes = {
  companyInfo: PropTypes.instanceOf(Map).isRequired,
  email: PropTypes.string.isRequired,
  emailTemplates: PropTypes.instanceOf(List).isRequired,
  fetchEmailTemplates: PropTypes.func.isRequired,
  findDirectors: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onOpenEmail: PropTypes.func.isRequired,
  templateType: PropTypes.string.isRequired,
};
