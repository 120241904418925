import React from 'react';
import classNames from 'classnames';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import _Input from '../helpers/Input';
import Select from '../helpers/Select';
import Button from '../helpers/Button';
import DisplayError from '../decorators/DisplayError';
import PrettyError from '../decorators/PrettyError';

const Input = PrettyError(DisplayError(_Input));

const EditAddressPopup = props => {
  const { address, onChange, onSave, onBlur, states, triggerShowCountry } = props;
  const header = 'Edit Address';
  const footer = (
    <Button className="btn-primary btn-xs" data-dismiss="modal" onClick={onSave}>
      Save
    </Button>
  );

  const showCountry = address.getIn(['showCountry', 'value'], false);

  const countryClassName = classNames('f-country col-lg-4 col-sm-12', {
    hide: !showCountry,
  });

  const countryIconClassName = classNames('fa', {
    'fa-plus': !showCountry,
    'fa-minus': showCountry,
  });

  return (
    <Popup footer={footer} header={header} id="editAddress">
      <div className="row media-450">
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="k-address3"
            label="Address"
            name="address"
            onChange={onChange}
            placeholder="Address"
            value={address.get('address', '')}
            autoFocus
            required
            showLabel
          />
        </FormGroup>

        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="k-type3"
            label="Type"
            name="type"
            onChange={onChange}
            placeholder="Type"
            value={address.get('type', '')}
            required
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row media-450">
        <FormGroup className="col-lg-4 col-xs-4">
          <Input
            className="input-sm"
            id="k-city3"
            label="City"
            name="city"
            onChange={onChange}
            placeholder="City"
            value={address.get('city', '')}
            required
            showLabel
          />
        </FormGroup>

        <FormGroup className="mt17 col-lg-4 col-xs-4">
          <Select
            id="k-some3.1"
            label="State"
            name="state"
            onChange={onChange}
            options={states}
            selectClassName="input-sm"
            value={address.get('state', '')}
          />
        </FormGroup>

        <FormGroup className="input-group mt17 city-index2 pr16 col-lg-4 col-xs-4">
          <Input
            className="form-control input-sm"
            disabled={showCountry}
            name="zip"
            onBlur={onBlur}
            onChange={onChange}
            placeholder="Zip Code"
            type="text"
            value={address.get('zip', '')}
            required
          />
          <span className="input-group-addon" onClick={triggerShowCountry}>
            <i aria-hidden="true" className={countryIconClassName} />
          </span>
        </FormGroup>

        <FormGroup className={countryClassName}>
          <Input
            className="input-sm"
            id="o-country"
            label="Country"
            name="countryName"
            onChange={onChange}
            placeholder="Country"
            value={address.get('countryName', '')}
            required
            showLabel
          />
        </FormGroup>
      </div>
    </Popup>
  );
};

export default EditAddressPopup;
