import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { bindActionCreators } from 'redux';

import connectOptions, { mergeProps } from '../utils/connectOptions';
import * as actions from '../actions/windward';
import S3FilesTree from '../components/S3Files/S3FilesTree';
import { showError } from '../utils/MessagePopup';
import Spinner from '../components/helpers/Spinner';

export class Windward extends PureComponent {
  componentDidMount() {
    const { fetchWindwardTemplates, fetchWindwardReports } = this.props;

    fetchWindwardTemplates();
    fetchWindwardReports();
  }

  handleUpload = (event, module, fileName) => {
    event.preventDefault();

    const { currentTarget } = event;
    const { fetchWindwardTemplates, uploadTemplate } = this.props;
    const formData = new FormData(currentTarget);

    formData.delete('module');
    formData.set('module_id', module);
    formData.set('file_name', fileName);

    const afterSuccess = () => {
      fetchWindwardTemplates();
      this.context.closePopup();
    };

    const afterError = ({ response }) => {
      const errors = response.body.errors.map(error => error.messages.join('\n'));

      showError(this.context.openPopup, errors);
    };

    uploadTemplate({ formData, module, afterSuccess, afterError });
  };

  handleDelete = rowInfo => {
    const { fetchWindwardTemplates, deleteWindwardTemplate } = this.props;
    const afterSuccess = () => fetchWindwardTemplates();

    deleteWindwardTemplate({ moduleId: rowInfo.moduleId, fileName: rowInfo.title, afterSuccess });
  };

  handleOpenTemplateUploader = () => {
    this.context.openPopup('UploadTemplatePopup', {
      onUpload: (event, module, fileName) => this.handleUpload(event, module, fileName),
    });
  };

  render() {
    const { isLoading, isDeleting, templates, reports, fetchWindwardTemplates, fetchWindwardReports } = this.props;

    return (
      <React.Fragment>
        <Helmet title="Windward Templates" />

        <div className="s3-file-manager container full-height">
          {(isLoading || isDeleting) && <Spinner className="s3-file-manager-spinner" />}

          <div className="row full-height">
            <div className="col-sm-6">
              <div className="s3-title">
                <h3>Windward Templates</h3>
                <button className="btn btn-primary btn-s3-upload" onClick={this.handleOpenTemplateUploader}>
                  <i className="fa fa-cloud-upload" />
                  Upload
                </button>
                <button className="btn btn-primary btn-s3-upload" onClick={fetchWindwardTemplates}>
                  <i className="fa fa-refresh" />
                  Refresh
                </button>
              </div>

              <S3FilesTree data={templates.toJS()} onDelete={this.handleDelete} />
            </div>
            <div className="col-sm-6">
              <div className="s3-title">
                <h3>Windward Reports</h3>
                <button className="btn btn-primary btn-s3-upload" onClick={fetchWindwardReports}>
                  <i className="fa fa-refresh" />
                  Refresh
                </button>
              </div>

              <S3FilesTree data={reports.toJS()} onDelete={this.handleDelete} readOnly />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Windward.propTypes = {
  deleteWindwardTemplate: PropTypes.func.isRequired,
  fetchWindwardReports: PropTypes.func.isRequired,
  fetchWindwardTemplates: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
  isLoading: PropTypes.bool,
  uploadTemplate: PropTypes.func.isRequired,
};

Windward.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  templates: state.windward.get('templates'),
  reports: state.windward.get('reports'),
  inputErrors: state.windward.get('inputErrors'),
  isLoading: state.windward.get('loading'),
  isDeleting: state.windward.get('isDeleting'),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions)(Windward);
