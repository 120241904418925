import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { List } from 'immutable';

import Table, { Column, Row } from '../../helpers/Table';

class ContactExecutiveIndustries extends PureComponent {
  render() {
    return ContactExecutiveIndustriesRender(this.props, this.context);
  }
}

/**
 * Contact executive table with industries.
 *
 * @param props {Object}.
 * @param props.industries {Immutable.List} Industries definition.
 * @returns {XML}
 * @class
 */
const ContactExecutiveIndustriesRender = props => {
  const { industries, onAdd, changed } = props;

  const title = (
    <span onClick={onAdd}>
      <i className="fa fa-plus-square fa-2x" />
      <span className={classNames({ changed })}>Industry</span>
    </span>
  );
  const rows = industries.map((industry, i) => <Row key={i} data={industry} />);

  const wrapper = (value, data) => (
    <span
      className={classNames({
        'text-danger': data.get('deprecated') || data.get('deletedAt'),
      })}
    >
      {value}
    </span>
  );

  return (
    <div className="highlight table-responsive ib">
      <Table className="table table-bordered table-hover">
        <Column className="plus-icon" field="industryLabel" title={title} valueWrapper={wrapper} />
        {rows}
      </Table>
    </div>
  );
};

ContactExecutiveIndustriesRender.propTypes = {
  industries: PropTypes.instanceOf(List).isRequired,
};

export default ContactExecutiveIndustries;
