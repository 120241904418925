import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Map, List } from 'immutable';

import AgGridTable from '../../../helpers/AgGridTable';
import { handleSortModelChanged } from '../../../../utils/sorting';

class ApprovedCellRenderer extends PureComponent {
  render() {
    const content =
      this.props.data.approved === '' ? null : this.props.data.approved ? (
        <i className="fa fa-check" />
      ) : (
        <i className="fa fa-close" />
      );

    return <div className="approvedWrapper">{content}</div>;
  }
}

class ProjectCell extends PureComponent {
  render() {
    const { data } = this.props;
    const projectCategory = data.dsplProjectCategory ? data.dsplProjectCategory : '';
    const username = data.dsplProjectHarvcoLeadUserName ? ` (${data.dsplProjectHarvcoLeadUserName})` : '';

    return <span>{projectCategory + username}</span>;
  }
}

class CompanyBuyerLeadsTable extends PureComponent {
  constructor(props) {
    super(props);

    this.gridApi = null;
    this.gridColumnApi = null;
    this.onGridReady = this.onGridReady.bind(this);
    this.handleRowDoubleClicked = this.handleRowDoubleClicked.bind(this);
    this.onSort = this.onSort.bind(this);
  }

  componentDidMount() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  componentDidUpdate() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  getColumnDefs() {
    if (this.props.columnDefs !== this.lastColumnDefs) {
      this.lastColumnDefs = this.props.columnDefs;
      this.columnDefs = this.props.columnDefs.map(columnDef => {
        const field = columnDef.get('field');

        switch (field) {
          case 'approved':
            return columnDef.set('cellRendererFramework', ApprovedCellRenderer);

          case 'dsplProjectCategory':
            return columnDef.set('cellRendererFramework', ProjectCell);

          default:
            return columnDef;
        }
      });
    }

    return this.columnDefs;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  handleRowDoubleClicked(row) {
    this.props.onDoubleClickRow(row);
  }

  onSort(sortModel) {
    handleSortModelChanged(sortModel, this.props.data, this.props.onChange);
  }

  getRowNodeId(data) {
    return data.order;
  }

  render() {
    const { data, onDoubleClickRow, onGetNextPageData } = this.props;

    return (
      <div className="flexItem">
        <AgGridTable
          columnDefs={this.getColumnDefs()}
          getRowNodeId={this.getRowNodeId}
          isFetching={data.get('isFetching')}
          onGetNextPageData={onGetNextPageData}
          onGridReady={this.onGridReady}
          onRowDoubleClicked={onDoubleClickRow}
          onSortModelChanged={this.onSort}
          page={data.get('page')}
          rowData={data.get('queryResults')}
          totalPages={data.get('totalPages')}
          sortable
        />
      </div>
    );
  }
}

CompanyBuyerLeadsTable.propTypes = {
  columnDefs: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
  onGetNextPageData: PropTypes.func.isRequired,
};

export default CompanyBuyerLeadsTable;
