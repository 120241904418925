import Immutable, { fromJS, List, Map } from 'immutable';
import * as ActionType from '../actions/tradeShow';
import { getIn, setIn, spy, toJS } from '../utils/ChangeSpy';
import { dateToShortString } from '../utils/dateFormat';

const FIELDS_TO_SPY = [
  'name',
  'abbr',
  'description',
  'city',
  'state',
  'website',
  'startDate',
  'endDate',
  'events',
  'industryCategories',
  'businessModels',
  'users',
];

const USER_FIELDS_TO_SPY = ['id', 'text'];

const defaultState = spy(
  fromJS({
    isFetching: false,
    sending: false,
    loadingStatistics: false,
    loadingCompanies: false,
    name: {
      value: '',
      origin: '',
    },
    abbr: {
      value: '',
      origin: '',
    },
    description: {
      value: '',
      origin: '',
    },
    city: '',
    state: '',
    website: {
      value: '',
      origin: '',
    },
    startDate: '',
    endDate: '',
    events: [],
    industryCategories: [],
    businessModels: [],
    users: [],
    statistics: {},
    companies: [],
    sortBy: {
      sortModel: [],
    },
    companiesTotalPages: 0,
    companiesCurrentPage: 0,
  }),
  FIELDS_TO_SPY,
);

const wrappedReducer = reducer;

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.FETCHING_TRADE_SHOW:
      return state.mergeDeep({
        isFetching: true,
      });

    case ActionType.FETCHING_TRADE_SHOW_SUCCESS:
      if (action.response) {
        return spy(state.merge(mapResponse(action)), FIELDS_TO_SPY);
      }

      return state.set('isFetching', false);

    case ActionType.UPDATE_TEXT_FIELD:
      return updateTextField(state, action);

    case ActionType.ADD_NEW_EVENT:
      return setIn(state, 'events', addEvent(state, action));

    case ActionType.REMOVE_EVENT:
      return setIn(state, 'events', removeEvent(state, action));

    case ActionType.ADD_USER:
      return setIn(state, 'users', addUser(state, action));

    case ActionType.REMOVE_USER:
      return setIn(state, 'users', removeUser(state, action));

    case ActionType.TRADE_SHOW_INDUSTRIES_UPDATE:
      return updateTags(state, action);

    case ActionType.LOADING_STATISTICS:
      return state.merge({ loadingStatistics: true });

    case ActionType.LOADING_STATISTICS_SUCCESS:
      return state.merge({
        loadingStatistics: false,
        statistics: action.response.data,
      });

    case ActionType.LOADING_COMPANIES:
      return state.merge({ loadingCompanies: true });

    case ActionType.LOADING_COMPANIES_SUCCESS:
      return state.merge({
        loadingCompanies: false,
        companies: state.get('companies').concat(action.response.data),
        companiesTotalPages: action.response.meta.pagination.totalPages,
        companiesCurrentPage: action.response.meta.pagination.currentPage,
      });

    case ActionType.SEND_TRADE_SHOW:
      return state.merge({
        sending: true,
      });

    case ActionType.SEND_TRADE_SHOW_SUCCESS:
      return setValueAsOriginalByMap(state.merge({ sending: false }), FIELDS_TO_SPY);

    case ActionType.UPDATE_COMPANIES_TABLE_SORT: {
      Object.keys(action.filterData).forEach(key => {
        if (List.isList(action.filterData[key]) || Array.isArray(action.filterData[key])) {
          state = state.setIn([action.name, action.filterName, key], Immutable.List());
        }
      });

      return state.merge({
        companies: [],
        [action.filterName]: action.filterData,
      });
    }

    default:
      return state;
  }
}

function mapResponse(action) {
  const { data } = action.response;

  return {
    ...data,
    events: data.events.map(event => ({
      ...event,
      startDate: dateToShortString(new Date(event.startDate)),
      endDate: dateToShortString(new Date(event.endDate)),
    })),
    users: data.users.map((user, index) =>
      spy(
        Map({
          index,
          id: user.id,
          text: user.userName,
        }),
        USER_FIELDS_TO_SPY,
      ),
    ),
    name: {
      value: data.name,
      origin: data.name,
    },
    website: {
      value: data.website,
      origin: data.website,
    },
    abbr: {
      value: data.abbr,
      origin: data.abbr,
    },
    description: {
      value: data.description,
      origin: data.description,
    },
    isFetching: false,
  };
}

function updateTextField(state, action) {
  const value = {
    value: action.value,
    origin: toJS(state.get(action.field)).origin,
  };

  return setIn(state, [action.field], value);
}

function addEvent(state, action) {
  const { events } = toJS(state);

  const newEvent = {
    ...action.value,
    yearSuffix: '',
    startDate: dateToShortString(new Date(action.value.startDate)),
    endDate: dateToShortString(new Date(action.value.endDate)),
  };

  events.push(newEvent);

  return events;
}

function removeEvent(state, action) {
  return toJS(state).events.filter((event, index) => index !== action.id);
}

function addUser(state, action) {
  return state
    .get('users')
    .get('value')
    .push(
      spy(
        Map({
          index: action.index,
          id: action.id,
          text: action.text,
        }),
        USER_FIELDS_TO_SPY,
      ),
    );
}

function removeUser(state, action) {
  return state
    .get('users')
    .get('value')
    .filter(user => user.get('id').get('value') !== action.id && user.get('id').get('value') !== null);
}

function updateTags(state, action) {
  const tags = new List(action.industryCategories.map(tag => Map(tag)));
  const models = new List(action.businessModels.map(model => Map(model)));

  const newState = setIn(state, 'industryCategories', tags);

  return setIn(newState, 'businessModels', models);
}

function setValueAsOriginalByMap(state, map) {
  return spy(
    map.reduce((result, key) => {
      const value = getIn(result, key);

      if (typeof value === 'object' && value !== null && 'origin' in value) {
        value.origin = value.value;
      }

      return result.setIn(key.split('.'), value);
    }, state),
    map,
  );
}
