import { combineReducers } from 'redux';
import info from './contactInfo';
import events from './contactEvents';
import lp from './contactLP';
import targets from './contactTarget';
import buyers from './contactBuyer';
import executive from './contactExecutive';

const contactDetailReducer = combineReducers({
  info,
  lp,
  events,
  targets,
  buyers,
  executive,
});

export default contactDetailReducer;
