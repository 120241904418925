import Immutable from 'immutable';
import config from '../../../../config';
import {
  LOADED_PROJECT_SUCCESS,
  UPDATE_PROJECT_INFORMATION,
  CHANGE_PROJECT_ROW_MODE,
  RESET_ALL_ROW_TO_VIEW_MODE,
  SELECT_PROJECT_SUGGESTION,
  SAVED_PROJECT_SUCCESS,
  LOADING_PROJECT_SUGGESTION,
  LOADED_PROJECT_SUGGESTION_FAILURE,
  LOADED_PROJECT_SUGGESTION_SUCCESS,
  REVERT_CHANGES,
} from '../../../../actions/company/buyer/addOnProject/main';

const defaultState = Immutable.fromJS({
  id: 0,
  category: {
    name: '',
    origin: '',
    dirty: false,
  },
  active: {
    value: true,
    dirty: false,
  },
  type: '',
  status: {
    name: '',
    origin: '',
    dirty: false,
  },
  dateCreated: '',
  details: {
    name: '',
    origin: '',
    dirty: false,
  },
  buyerId: 0,
  buyerName: '',
  companyType: '',
  clientLead: {
    id: -1,
    name: '',
    origin: '',
    mode: config.VIEW_MODE,
    dirty: false,
  },
  harvcoLead: {
    id: -1,
    name: '',
    origin: '',
    mode: config.VIEW_MODE,
    dirty: false,
  },
  exec: {
    id: -1,
    name: '',
    origin: '',
    mode: config.VIEW_MODE,
    dirty: false,
  },
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case LOADED_PROJECT_SUCCESS:
      return getProject(action.response);

    case UPDATE_PROJECT_INFORMATION:
      return updateProjectInfo(state, action);

    case CHANGE_PROJECT_ROW_MODE:
      return state.setIn([action.field, 'mode'], action.mode);

    case SELECT_PROJECT_SUGGESTION:
      return selectProjectSuggestion(state, action);

    case RESET_ALL_ROW_TO_VIEW_MODE:
      return switchToView(state);

    case LOADING_PROJECT_SUGGESTION:
      return state.setIn([action.field, 'mode'], config.LOADING_MODE);

    case LOADED_PROJECT_SUGGESTION_FAILURE:
      return state.setIn([action.field, 'mode'], config.SUGGEST_MODE);

    case LOADED_PROJECT_SUGGESTION_SUCCESS:
      return state.setIn([action.field, 'mode'], config.SUGGEST_MODE);

    case SAVED_PROJECT_SUCCESS:
      return clearDirty(state);

    case REVERT_CHANGES:
      return defaultState;

    default:
      return state;
  }
}

/**
 * Set all dirty fields to false.
 *
 * @param state {Immutable.Map} State response.
 * @returns {Immutable.Map}
 */
function clearDirty(state) {
  return state.mergeDeep({
    category: { dirty: false },
    status: { dirty: false },
    details: { dirty: false },
    clientLead: { dirty: false },
    harvcoLead: { dirty: false },
    exec: { dirty: false },
  });
}

/**
 * Update project information.
 *
 * @param state {Immutable.Map} State response.
 * @param action {Object} State response.
 * @returns {Immutable.Map}
 */
function updateProjectInfo(state, action) {
  if (action.field === 'active') {
    return state.mergeDeep({
      active: {
        value: action.value,
        dirty: true,
      },
    });
  }

  const value = action.value || '';

  if (value.length) {
    return state.mergeDeep({
      [action.field]: {
        name: action.value,
        dirty: true,
      },
    });
  }

  return state.mergeDeep({
    [action.field]: {
      id: -1,
      origin: '',
      name: '',
      dirty: true,
    },
  });
}

/**
 * Switch all fields to view mode.
 *
 * @param response {Object} Response.
 * @returns {Immutable.Map}
 */
function switchToView(state) {
  return state.mergeDeep({
    clientLead: {
      name: state.getIn(['clientLead', 'origin']),
      mode: config.VIEW_MODE,
    },
    harvcoLead: {
      name: state.getIn(['harvcoLead', 'origin']),
      mode: config.VIEW_MODE,
    },
    exec: { name: state.getIn(['exec', 'origin']), mode: config.VIEW_MODE },
  });
}

/**
 * Select project suggestion.
 *
 * @param response {Object} Response.
 * @returns List {Immutable.List}.
 */
const selectProjectSuggestion = (state, action) => {
  const item = state.get(action.field);

  return state.set(
    action.field,
    Immutable.Map({
      id: action.suggestionId,
      name: action.suggestionName,
      origin: action.suggestionName,
      mode: config.VIEW_MODE,
      dirty: item.get('dirty') || item.get('id') !== action.suggestionId,
    }),
  );
};

/**
 * Get project from response.
 *
 * @param response {Object} Response.
 * @returns project {Immutable.Map} project.
 */
const getProject = response => {
  if (response.status === 'success') {
    const p = response.data;
    const result = {
      id: p.id,
      category: {
        name: p.category,
        origin: p.category,
      },
      active: {
        value: p.active,
        dirty: false,
      },
      type: p.type,
      status: {
        name: p.status,
        origin: p.status,
      },
      dateCreated: p.dateCreated,
      details: {
        name: p.details,
        origin: p.details,
      },
      buyerId: 0,
      buyerName: '',
      companyType: '',
      clientLead: {
        id: -1,
        name: '',
        origin: '',
        mode: config.VIEW_MODE,
      },
      harvcoLead: {
        id: -1,
        name: '',
        origin: '',
        mode: config.VIEW_MODE,
      },
      exec: {
        id: -1,
        name: '',
        origin: '',
        mode: config.VIEW_MODE,
      },
    };

    if (p.buyer) {
      result.buyerId = p.buyer.id;
      result.buyerName = p.buyer.legalName;
      result.companyType = p.buyer.companyType;
    }

    if (p.clientLead) {
      result.clientLead.id = p.clientLead.id;
      result.clientLead.name = p.clientLead.fullName;
      result.clientLead.origin = p.clientLead.fullName;
    }

    if (p.harvcoLead) {
      result.harvcoLead.id = p.harvcoLead.id;
      result.harvcoLead.name = p.harvcoLead.userName;
      result.harvcoLead.origin = p.harvcoLead.userName;
    }

    if (p.exec) {
      result.exec.id = p.exec.id;
      result.exec.name = p.exec.fullName;
      result.exec.origin = p.exec.fullName;
    }

    return Immutable.fromJS(result);
  }

  return Immutable.Map();
};
