import approval from './approval.json';
import approvalListIds from './approval-list-ids.json';
import target from './target.json';
import targetBuyer from './target-buyer.json';

export default {
  approval,
  approvalListIds,
  target,
  targetBuyer,
};
