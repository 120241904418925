import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'immutable';
import { ContextMenu, MenuItem } from 'react-contextmenu';

/**
 * Context menu.
 *
 * @param props {Object}.
 * @param id {string} Context menu's unique id.
 * @param onItemClick {Function} Handle menu item's event click.
 * @param actionConfigs {Immutable.List} List of action.
 * @class
 */
const ActionContextMenu = props => {
  const { id, actionConfigs, onItemClick } = props;
  const menu = actionConfigs.map((a, i) => (
    <MenuItem key={i} data={{ key: a.get('key') }} onClick={onItemClick}>
      {a.get('name')}
    </MenuItem>
  ));

  return <ContextMenu id={id}>{menu}</ContextMenu>;
};

ActionContextMenu.propTypes = {
  actionConfigs: PropTypes.instanceOf(List).isRequired,
  id: PropTypes.string.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

export default ActionContextMenu;
