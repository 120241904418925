import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { Map } from 'immutable';

import isFunction from '../../utils/isFunction';

const defaultInputErrors = Map();

export default function InputErrorDisplayDecorator(onErrorCloseCb = 'onErrorClose', field = 'inputError', Component) {
  if (Component === undefined && field === 'inputError') {
    return InputErrorDisplayDecorator(undefined, undefined, onErrorCloseCb);
  }
  if (!isFunction(onErrorCloseCb) && typeof onErrorCloseCb !== 'string') {
    throw new Error("InputErrorDisplayDecorator's first argument should be a function of prop name.");
  } else if (Component === undefined) return InputErrorDisplayDecorator(onErrorCloseCb, undefined, field);

  class InputErrorDisplay extends PureComponent {
    constructor() {
      super();

      this.defaultOnError = this.defaultOnError.bind(this);
    }

    defaultOnError(e, field) {
      this.props[onErrorCloseCb]({ field });
    }

    getOnError() {
      if (isFunction(onErrorCloseCb)) return onErrorCloseCb;

      return this.defaultOnError;
    }

    getChildContext() {
      return {
        onErrorClose: this.getOnError(),
        inputErrors: this.getInputErrors(),
      };
    }

    getInputErrors() {
      return this.props.inputErrors || defaultInputErrors;
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  InputErrorDisplay.displayName = `InputErrorDisplay(${Component.displayName || Component.name})`;

  InputErrorDisplay.childContextTypes = {
    onErrorClose: PropTypes.func.isRequired,
    inputErrors: PropTypes.instanceOf(Map).isRequired,
  };

  return InputErrorDisplay;
}
