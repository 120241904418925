import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IdleTracker from 'idle-tracker';
import { connect } from 'react-redux';

import LoginForm from '../components/LoginForm/LoginForm';
import {
  handleLogin,
  handleInputValidation,
  sendGoogle2FAQRCode,
  resetGoogle2FAStatus,
  getStatusByActivityDetector,
} from '../actions/auth';
import { clearCode, hasCode, getIsTwoFa } from '../middleware/activate';

const IDLE_TIME = 3 * 60 * 60 * 1000;
const IDLE_TIMESTAMP = 'idle_timestamp';

// More info https://github.com/roderickhsiao/idle-tracker
class ActivityDetector extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isActive: true,
    };

    this.idleTracker = new IdleTracker({
      timeout: IDLE_TIME,
      onIdleCallback: () => {
        if (this.checkDeactivation()) {
          this.deactivatedApp();
        }
      },
    });
  }

  componentDidMount() {
    this.runActivityDetector();

    window.addEventListener('beforeunload', this.confirmLeavePage);
    window.addEventListener('storage', this.openModalLogin);
    window.addEventListener('visibilitychange', this.changeVisibility);
  }

  componentWillUnmount() {
    this.stopActivityDetector();

    window.removeEventListener('beforeunload', this.confirmLeavePage);
    window.removeEventListener('storage', this.openModalLogin);
    window.removeEventListener('visibilitychange', this.changeVisibility);
  }

  deactivatedApp = () => {
    this.setState({ isActive: false });
    localStorage.removeItem(IDLE_TIMESTAMP);
    this.props.resetGoogle2FAStatus();
    clearCode();
  };

  confirmLeavePage = event => {
    if (!hasCode()) {
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    }
  };

  openModalLogin = () => {
    const { google2FaStatus } = this.props;

    if (google2FaStatus && google2FaStatus.get('isGoogle2FAPassed')) {
      this.setState({ isActive: true });
    }
  };

  changeVisibility = () => {
    if (document.visibilityState === 'hidden') {
      localStorage.setItem(IDLE_TIMESTAMP, Date.now().toString());
      this.stopActivityDetector();
    }

    if (document.visibilityState === 'visible') {
      const { getStatusByActivityDetector } = this.props;

      setTimeout(() => {
        if (getIsTwoFa()) {
          if (this.checkDeactivation() || !hasCode()) {
            this.deactivatedApp();
            this.stopActivityDetector();
          } else {
            getStatusByActivityDetector({ afterSuccess: this.runActivityDetector });
          }
        }

        if (!getIsTwoFa()) {
          if (this.checkDeactivation() || !hasCode()) {
            this.deactivatedApp();
            this.stopActivityDetector();
          } else {
            this.runActivityDetector();
          }
        }
      }, 200);
    }
  };

  runActivityDetector = () => {
    const { google2FaStatus } = this.props;

    if (!getIsTwoFa()) {
      this.setState({ isActive: true });
    }

    if (getIsTwoFa() && google2FaStatus) {
      if (google2FaStatus.get('isGoogle2FAPassed')) {
        this.setState({ isActive: true });
      }
    }

    localStorage.setItem(IDLE_TIMESTAMP, Date.now().toString());
    this.idleTracker.start();
  };

  stopActivityDetector = () => {
    this.idleTracker.end();
  };

  getValue(form, fieldName, id) {
    try {
      return form[fieldName].value;
    } catch (e) {
      try {
        return document.getElementById(id).value;
      } catch (e2) {
        return new window.FormData(form).get(fieldName);
      }
    }
  }

  afterSuccess = () => {
    this.runActivityDetector();
  };

  handleGoogle2FAAuth(event) {
    event.preventDefault();

    const form = event.currentTarget;
    const confirmationCode = this.getValue(form, 'confirmationCode', 'confirmationCode');
    const { sendGoogle2FAQRCode, getStatusByActivityDetector } = this.props;

    sendGoogle2FAQRCode({
      confirmationCode,
      afterSuccess: () => {
        getStatusByActivityDetector({ afterSuccess: this.afterSuccess });
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const form = event.currentTarget;
    const { google2FaStatus, handleInputValidation, handleLogin, getStatusByActivityDetector } = this.props;
    const inputUsername = this.getValue(form, 'username', 'inputUsername');
    const inputPassword = this.getValue(form, 'password', 'inputPassword');

    if (!inputUsername) return handleInputValidation('Please input username!');
    if (!inputPassword) return handleInputValidation('Please input password!');

    if (getIsTwoFa()) {
      handleLogin({
        skipOnResetLogin: true,
        inputUsername,
        inputPassword,
        afterSuccess: () => {
          getStatusByActivityDetector({
            afterSuccess: () => {
              if (google2FaStatus && !google2FaStatus.get('isGoogle2FAPassed')) {
                this.setState({ isActive: false });
                localStorage.removeItem(IDLE_TIMESTAMP);
                this.stopActivityDetector();
              } else {
                this.afterSuccess();
              }
            },
          });
        },
      });
    } else {
      handleLogin({
        skipOnResetLogin: true,
        inputUsername,
        inputPassword,
        afterSuccess: this.afterSuccess,
      });
    }
  }

  getLoginCaption() {
    return <div className="caption-activity-detector">Session has expired. Please login to continue.</div>;
  }

  checkDeactivation() {
    const timeout = Number(localStorage.getItem(IDLE_TIMESTAMP)) + IDLE_TIME;

    return Date.now() > timeout;
  }

  render() {
    const { children, error, isFetching, google2FaStatus, google2FaSecret } = this.props;
    const { isActive } = this.state;

    return (
      <React.Fragment>
        {!isActive && (
          <div className="modal-activity-detector">
            <LoginForm
              caption={this.getLoginCaption()}
              error={error}
              google2FaSecret={google2FaSecret}
              google2FaStatus={google2FaStatus}
              isFetching={isFetching}
              onGoogle2FAAuth={event => this.handleGoogle2FAAuth(event)}
              onSubmit={event => this.handleSubmit(event)}
            />
          </div>
        )}
        {children}
      </React.Fragment>
    );
  }
}

ActivityDetector.propTypes = {
  error: PropTypes.string.isRequired,
  getStatusByActivityDetector: PropTypes.func.isRequired,
  handleInputValidation: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  resetGoogle2FAStatus: PropTypes.func.isRequired,
  sendGoogle2FAQRCode: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isFetching: state.auth.get('isFetching'),
  google2FaStatus: state.auth.get('google2FaStatus'),
  google2FaSecret: state.auth.get('google2FaSecret'),
  error: state.auth.get('error'),
  location: state.router.location,
});

export { ActivityDetector };
export default connect(mapStateToProps, {
  handleLogin,
  sendGoogle2FAQRCode,
  handleInputValidation,
  resetGoogle2FAStatus,
  getStatusByActivityDetector,
})(ActivityDetector);
