import { fromJS, List, Map } from 'immutable';
import moment from 'moment';

import * as ActionDetailType from '../../../actions/companyDetail';
import * as ActionType from '../../../actions/company/buyer/retainer';
import handleApiError from '../../decorators/handleApiError';
import getValidator from '../../../utils/getValidator';
import { spy, setIn, getIn, toJS, revertToOriginal } from '../../../utils/ChangeSpy';
import { autoNullIdWithDynPaths } from '../../decorators/suggest';

const SCHEMA = {
  type: 'object',
};

const FIELDS_TO_SPY = [
  'contractualName',
  'retainer',
  'startDate',
  'endDate',
  'notificationCancellationDate',
  'notificationStartDate',
  'retainerNote',
  'tailMonths',
  'tailLastIntroduced',
  'tailTermination',
  'targetId',
  'targetName',
];

const validator = getValidator(SCHEMA);

const defaultState = spy(
  fromJS({
    loaded: false,
    isValid: true,
    inputErrors: {},
    contractualName: '',
    retainer: null,
    startDate: null,
    endDate: null,
    notificationCancellationDate: null,
    notificationStartDate: null,
    tailMonths: null,
    tailLastIntroduced: false,
    tailTermination: false,
    retainerNote: '',
    targetsSuggests: [],
    companyRetainers: List(),
  }),
  FIELDS_TO_SPY,
);

const ENTITY_VALIDATORS = {};

let wrappedReducer = reducer;

wrappedReducer = handleApiError(ActionType.ERRORED_COMPANY_BUYER, wrappedReducer);

wrappedReducer = autoNullIdWithDynPaths(checkTargetsSuggest, ActionType.UPDATE_COMPANY, wrappedReducer);

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.UPDATE_COMPANY:
      return checkValidity(changeField(state, action));

    case ActionType.LOADING_RETAINER:
      return state.merge({
        loading: true,
      });

    case ActionType.LOADED_COMPANY_RETAINER: {
      return state.merge({
        loading: false,
      });
    }

    case ActionType.ADD_COMPANY_RETAINER: {
      return spy(appendCompanyRetainer(state, action), FIELDS_TO_SPY);
    }

    case ActionType.DELETE_COMPANY_RETAINER: {
      const companyRetainers = state.get('companyRetainers').filter(retainer => retainer.get('id') !== action.id);

      return state.set('companyRetainers', companyRetainers);
    }

    case ActionType.REVERT_UPDATE:
      return checkValidity(revertToOriginal(state, action.field));

    case ActionDetailType.FETCHING_COMPANY_SUCCESS:
      return spy(state.merge(mapResponse(action)), FIELDS_TO_SPY);

    case ActionType.SAVED_COMPANY_BUYER:
      return spy(setValueAsOriginalByMap(state, action.fields), FIELDS_TO_SPY);

    case ActionType.ERRORED_COMPANY_BUYER:
      return state;

    case ActionType.FIND_TARGETS: {
      const data = action.response.data.map(target => ({
        text: target.legalName,
        id: target.id,
      }));

      return state.setIn(
        ['targetsSuggests'],
        action.page === 1 ? fromJS(data) : state.get('targetsSuggests').concat(data),
      );
    }

    default:
      return state;
  }
}

function setValueAsOriginalByMap(state, map) {
  return spy(
    map.reduce((result, key) => {
      const value = getIn(result, key);

      return result.setIn(key.split('.'), value);
    }, state),
    map,
  );
}

function mapResponse(action) {
  const {
    contractualName,
    retainer,
    startDate,
    endDate,
    notificationStartDate,
    notificationCancellationDate,
    retainerNote,
    tailMonths,
    tailLastIntroduced,
    tailTermination,
    companyRetainers,
  } = action.response.data;

  return {
    contractualName,
    retainer,
    startDate: moment(startDate),
    endDate: moment(endDate),
    notificationStartDate: moment(notificationStartDate),
    notificationCancellationDate: moment(notificationCancellationDate),
    retainerNote,
    tailMonths,
    tailLastIntroduced,
    tailTermination,
    companyRetainers: companyRetainers
      .sort((a, b) => b.id - a.id)
      .map(companyRetainer =>
        spy(
          Map({
            ...companyRetainer,
            startDate: moment(companyRetainer.startDate),
            endDate: moment(companyRetainer.endDate),
            notificationStartDate: moment(companyRetainer.notificationStartDate),
            notificationCancellationDate: moment(companyRetainer.notificationCancellationDate),
            targetId: companyRetainer.targetId,
            targetName: companyRetainer.targetCompany.legalName ?? '',
          }),
          FIELDS_TO_SPY,
        ),
      ),
    loaded: true,
  };
}

function changeField(state, action) {
  state = setIn(state, action.name, action.value);

  const index = action.name.split('.')[1];

  if (action.name === `companyRetainers.${index}.targetName`) {
    state = setIn(state, `companyRetainers.${index}.targetId`, 0);
  }

  return state;
}

function checkValidity(state) {
  const plainState = toJS(state);
  const isValid = validator.isValid(plainState);

  const inputErrors = validator.getFirstError(plainState);

  const detailsValidity = Object.keys(ENTITY_VALIDATORS).reduce(
    res =>
      /* res[`${key}IsValid`] = ENTITY_VALIDATORS[key].isValid(plainState[key]);
    if (res[`${key}IsValid`]) return res;
    const error = ENTITY_VALIDATORS[key].getFirstError(plainState[key]);
    const errorField = Object.keys(error)[0];
    inputErrors[`${key}.${errorField}`] = error[errorField]; */
      res,
    {},
  );

  return state.merge({
    ...detailsValidity,
    inputErrors,
    isValid,
  });
}

function appendCompanyRetainer(state, action) {
  return state.set(
    'companyRetainers',
    state.get('companyRetainers').unshift(spy(fromJS(action.retainer), FIELDS_TO_SPY)),
  );
}

function checkTargetsSuggest({ name }) {
  const index = name.split('.')[1];

  if (index) {
    return {
      isSuggest: true,
      namePath: `companyRetainers.${index}.targetName`,
      idPath: `companyRetainers.${index}.targetId`,
    };
  }

  return { isSuggest: false };
}
