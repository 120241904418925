import React from 'react';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Button from '../helpers/Button';
import Input from '../helpers/Input';

const NewPositionPopup = () => {
  const header = 'Add a New Position at a Company';
  const footer = [
    <Button key="save" className="btn-primary btn-xs" data-dismiss="modal">
      Save
    </Button>,
    <Button key="cancel" className="btn-default btn-xs" data-dismiss="modal">
      Cancel
    </Button>,
  ];

  return (
    <Popup footer={footer} header={header} id="newPosition">
      <div className="row media-450">
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="k-company-name"
            label="Company Name"
            name="company-name"
            placeholder="Company Name"
            autoFocus
            required
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-xs-6">
          <Input className="input-sm" id="k-title" label="Title" name="title" placeholder="Title" required showLabel />
        </FormGroup>
      </div>
      <div className="row media-450">
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="k-revenue"
            label="P&L-Revenue"
            name="revenue"
            placeholder="P&L-Revenue"
            required
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="k-ebitda"
            label="P&L-EBITDA"
            name="company-name"
            placeholder="P&L-EBITDA"
            required
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row media-450">
        <FormGroup className="col-lg-6 col-xs-6">
          <Input className="input-sm" id="k-start" label="Start" name="start" placeholder="Start" required showLabel />
        </FormGroup>
        <FormGroup className="col-lg-6 col-xs-6">
          <Input className="input-sm" id="k-end" label="End" name="end" placeholder="End" required showLabel />
        </FormGroup>
      </div>
    </Popup>
  );
};

export default NewPositionPopup;
