import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import Popups from '../../components/Popup';

import {
  findCompanies,
  changeField,
  findTitles,
  clearSuggest,
  addContactPosition,
  closeValidationError,
  resetPosition,
} from '../../actions/contact/contactExecutive';
import { reloadContactDetail } from '../../actions/contactDetail';

class AddPositionPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.onErrorClose = this.onErrorClose.bind(this);
    this.onFetch = this.onFetch.bind(this);
    this.onFetchTitles = this.onFetchTitles.bind(this);
    this.onSelectCompany = this.onSelectCompany.bind(this);
    this.onCreateCompany = this.onCreateCompany.bind(this);
    this.onSelectTitle = this.onSelectTitle.bind(this);
    this.onUpdateCompany = this.onUpdateCompany.bind(this);
    this.onUpdateTitle = this.onUpdateTitle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.getSuggestCompany = this.getSuggestCompany.bind(this);
  }

  componentWillUnmount() {
    this.props.resetPosition();
  }

  componentDidMount() {
    const { popup } = this.props;
    const {
      props: { newTargetId, newTargetIdLegalName },
    } = popup.toJS();

    if (newTargetId > 0 && newTargetIdLegalName) {
      this.onSelectCompany({ target: { name: '' } }, { suggestion: { id: newTargetId, text: newTargetIdLegalName } });
    }
  }

  onErrorClose(e, field) {
    this.props.closeValidationError({ field });
  }

  getChildContext() {
    return {
      onErrorClose: this.onErrorClose,
      inputErrors: this.props.inputErrors,
    };
  }

  static getFields() {
    return { id: 'newPosition.entityId', value: 'newPosition.suggestCompany' };
  }

  onCreate() {
    const id = this.props.contact.get('id', 0);
    const entityId = this.props.executive.getIn(['newPosition', 'entityId'], 0);
    const tempCompany = this.props.executive.get('newPosition').toJS();

    if ('startYear' in tempCompany) {
      tempCompany.startYear = parseInt(tempCompany.startYear, 10) || null;
    }
    Object.keys(tempCompany).forEach(field => {
      if (tempCompany[field] === null) delete tempCompany[field];
    });

    this.props.addContactPosition({ ...tempCompany, entityId, id }, () => {
      this.props.reloadContactDetail({ id }, this.onClose);
    });
  }

  onClose() {
    this.context.closePopup();
  }

  onChange(event) {
    const { name: preName, value } = event.target;
    const name = /^newPosition\./.test(preName) ? preName : `newPosition.${preName}`;

    this.props.changeField({ name, value });
  }

  onFetch({ value }) {
    this.props.findCompanies({ filter: value });
  }

  onClear() {
    this.props.clearSuggest();
  }

  static onGetValue(suggestion) {
    return suggestion.value;
  }

  onSelectCompany(e, { suggestion }) {
    e.target.name = 'newPosition.suggestCompany';

    const { value, id } = AddPositionPopupContainer.getFields();

    this.props.changeField({ name: value, value: suggestion.text });
    this.props.changeField({ name: id, value: suggestion.id });
  }

  onUpdateCompany(param) {
    if ('text' in param) {
      const name = 'newPosition.suggestCompany';
      const value = param.text;

      this.props.changeField({ name, value });
    }

    if ('suggestions' in param) {
      const name = 'suggests';
      const value = param.suggestions;

      this.props.changeField({ name, value });
    }
  }

  onCreateCompany() {
    this.context.onClosePopup(() => {
      this.context.openPopup('AddCompanyTargetPopup', {
        fromPositionPopup: true,
        cb: this.props.popup.getIn(['props', 'cb']),
      });
    });
    this.context.closePopup();
  }

  getSuggestCompany() {
    return {
      onAdd: this.onCreateCompany,
      onFetch: this.onFetch,
      onUpdate: this.onUpdateCompany,
      getValue: AddPositionPopupContainer.onGetValue,
      onSelect: this.onSelectCompany,
      loading: this.props.executive.get('loadingExecCompanies'),
    };
  }

  onSave() {
    this.props.addContactPosition();
  }

  getTitlesProp() {
    return {
      onFetch: this.onFetchTitles,
      onUpdate: this.onUpdateTitle,
      getValue: AddPositionPopupContainer.onGetValue,
      onSelect: this.onSelectTitle,
      loading: this.props.executive.getIn(['newPosition', 'titleLoading']),
    };
  }

  onFetchTitles({ value }) {
    this.props.findTitles({ filter: value });
  }

  onUpdateTitle(param) {
    if ('text' in param) {
      const name = 'newPosition.title';
      const value = param.text;

      this.props.changeField({ name, value });
    }

    if ('suggestions' in param) {
      const name = 'suggests';
      const value = param.suggestions;

      this.props.changeField({ name, value });
    }
  }

  onSelectTitle(e, { suggestion }) {
    this.props.changeField({
      name: 'newPosition.title',
      value: suggestion.text,
    });
  }

  render() {
    const { children, contact, executive } = this.props;
    const position = executive.get('newPosition');

    const suggestCompany = this.getSuggestCompany();
    const suggestTitles = this.getTitlesProp();

    return (
      <div>
        <Popups.AddPositionPopup
          contact={contact}
          executive={executive}
          isValid={this.props.inputErrors && this.props.inputErrors.size === 0 && position.get('suggestCompany')}
          onChange={this.onChange}
          onClose={this.onClose}
          onCreate={this.onCreate}
          position={position}
          suggestCompany={suggestCompany}
          suggestTitles={suggestTitles}
        />
        {children}
      </div>
    );
  }
}

AddPositionPopupContainer.contextTypes = {
  onClosePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

AddPositionPopupContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};

function mapStateToProps(state, props) {
  return {
    ...props,
    inputErrors: state.contact.executive.get('newPositionInputErrors', Map()),
    contact: state.contact.info,
    executive: state.contact.executive,
  };
}

export { AddPositionPopupContainer };
export default connect(
  mapStateToProps,
  {
    findCompanies,
    findTitles,
    clearSuggest,
    addContactPosition,
    reloadContactDetail,
    changeField,
    closeValidationError,
    resetPosition,
  },
  mergeProps,
  connectOptions,
)(AddPositionPopupContainer);
