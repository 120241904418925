import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import ContactTarget from '../../components/Contact/ContactTarget';
import { loadContactTarget } from '../../actions/contact/contactTarget';

import config from '../../config';

const columns = config.tables.getIn(['contactTarget', 'columns']);

/**
 * ContactTargets tab container.
 *
 * @param props {Object}.
 * @param props.targets {Immutable.List} Container get this from app's state.
 * It is list of targets to display.
 * @param props.columns {Immutable.List} Container get this from app's config.
 * It is a meta info about table's columns.
 * @param loadContactTarget {Function} Action creator to load target list for contact.
 */
class ContactTargetContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onDoubleClick = this.onDoubleClick.bind(this);
  }

  /**
   * Before component will mount request targets data.
   */
  UNSAFE_componentWillMount() {
    const { contactId } = this.props.match.params;

    this.props.loadContactTarget({ id: contactId });
  }

  /**
   * On table row DoubleClick open company page.
   *
   * @param e {React.SyntheticEvent}.
   * @param index {Number} Row indexs.
   */
  onDoubleClick(e, index) {
    const companyId = this.props.targetsState.getIn(['targets', index, 'entityId']);

    window.open(`/company/${companyId}`);
  }

  render() {
    const { targetsState, ...rest } = this.props;
    const loading = targetsState.get('loading');
    const targets = targetsState.get('targets');

    return <ContactTarget {...rest} loading={loading} onDoubleClick={this.onDoubleClick} targets={targets} />;
  }
}

function mapStateToProps(state) {
  return {
    targetsState: state.contact.targets,
    columns,
  };
}

ContactTargetContainer.propTypes = {
  columns: PropTypes.instanceOf(List).isRequired,
  loadContactTarget: PropTypes.func.isRequired,
  targetsState: PropTypes.instanceOf(Map).isRequired,
};

export { ContactTargetContainer };
export default connect(mapStateToProps, { loadContactTarget }, mergeProps, connectOptions)(ContactTargetContainer);
