import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { List, Map } from 'immutable';
import classNames from 'classnames';
import { ResizableBox } from 'react-resizable';

import WysiwygView from '../helpers/WysiwygView';

const CompanyLabel = ({ note }) => {
  const name = note.get('legalName', '');

  if (!name) return <span />;
  {
    const projectName = note.get('projectName', '');

    if (!projectName) return <span className="text-muted">({name})</span>;

    return (
      <span className="text-muted">
        ({name} - {projectName})
      </span>
    );
  }
};

/**
 * Component is used to display 1 deal note.
 *
 * @param props {Object} Any extra props that allowed on <p> tag.
 * @param props.onMouseEnter {Function} OnMouseEnter callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.onMouseLeave {Function} OnMouseLeave callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.note {Immutable.Map} Map, that should describe note. #content {string} is note content.
 * #[isMarked=false] {boolean} uf `true` append .text-bold class to element. #eventId is deal note's eventId.
 * @returns {XML}`
 * @private
 * @class
 */
const CompanyDealNote = (props, { onEditEvent }) => {
  const { onMouseEnter, onMouseLeave, note, className = '', ...rest } = props;

  const isMarked = note.get('isMarked', false);
  const eventId = note.get('eventId');
  const isBoldContent = isMarked ? 'font-weight: bold;' : 'font-weight: normal;';

  const topContent = (
    <div className="contact__deal-note" onDoubleClick={event => onEditEvent(event, note.set('id', eventId))}>
      <strong>{note.get('date')}</strong> <span className="text-muted">{note.get('userName', '')}</span>{' '}
      <CompanyLabel note={note} />
    </div>
  );
  const content = note.get('content', '');
  const wrapBoldContent = `<span style='${isBoldContent}'>${content}</span>`;

  return (
    <div
      {...rest}
      className={className}
      onMouseEnter={event => onMouseEnter(event, eventId)}
      onMouseLeave={event => onMouseLeave(event, eventId)}
    >
      {topContent}
      <WysiwygView html={wrapBoldContent} onDoubleClick={event => onEditEvent(event, note.set('id', eventId))} />
    </div>
  );
};

CompanyDealNote.propTypes = {
  note: PropTypes.instanceOf(Map).isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

CompanyDealNote.contextTypes = {
  onEditEvent: PropTypes.func.isRequired,
};

/**
 * Component is used to display list of deal notes.
 *
 * @param props.onMouseEnter {Function} OnMouseEnter callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.onMouseLeave {Function} OnMouseLeave callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.onToggle {Function} Toggle deal note section.
 * @param props.isClosed {Boolean} Set to true will close note section.
 * @param props.dealNotes {Immutable.List} List of Maps, that should describe note. #content {string} is note content.
 * #[isMarked=false] {boolean} uf `true` append .text-bold class to element. #eventId is deal note's eventId.
 * @returns {XML}
 * @class
 */
class CompanyDealNotes extends PureComponent {
  constructor(props) {
    super(props);
    this.onResizeStop = this.onResizeStop.bind(this);

    this.panelRef = React.createRef();
  }

  onResizeStop(event, data) {
    if (this.props.onChange) {
      this.props.onChange({ target: { name: 'dealNotes', value: data } });
    }
  }

  render() {
    const { onMouseEnter, onMouseLeave, onToggle, isClosed, dealNotes, dealNoteSize } = this.props;

    if (isClosed) {
      return (
        <div className="dn-panel note-close react-resizable">
          <div className="switcher" onClick={onToggle}>
            <i aria-hidden="true" className="fa fa-caret-square-o-right fa-2x" />
          </div>
          <div ref={this.panelRef} className="content" />
          <span className="react-resizable-handle" />
        </div>
      );
    }

    const nonEmptyNotes = dealNotes.filter(note => note.get('content') !== '<p><br></p>'); // Check if the wysiwyg text not empty.

    const content =
      nonEmptyNotes.size === 0 ? (
        <p>No rows to display</p>
      ) : (
        nonEmptyNotes.map(note => (
          <CompanyDealNote
            key={note.get('eventId')}
            note={note}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        ))
      );
    const className = classNames('dn-panel', {
      'note-close': isClosed,
      normal: !isClosed,
    });
    const boxProps = {
      axis: 'x',
      minConstraints: dealNoteSize.minConstraints,
      maxConstraints: [window.innerWidth - 100, dealNoteSize.height],
      width: dealNoteSize.width,
      height: dealNoteSize.height,
    };

    return (
      <ResizableBox {...boxProps} className={className} onResizeStop={this.onResizeStop}>
        <div className="switcher" onClick={onToggle}>
          <i aria-hidden="true" className="fa fa-caret-square-o-left fa-2x" />
        </div>
        <div ref={this.panelRef} className="content">
          {content}
        </div>
      </ResizableBox>
    );
  }
}

CompanyDealNotes.propTypes = {
  dealNotes: PropTypes.instanceOf(List).isRequired,
  dealNoteSize: PropTypes.object.isRequired,
  isClosed: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export { CompanyDealNote };
export default CompanyDealNotes;
