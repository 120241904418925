import { fromJS } from 'immutable';

import * as ActionType from '../../actions/companyDetail';

const defaultState = fromJS({
  queryResults: [],
  isFetching: false,
  page: 0,
  totalPages: 0,
});

const wrappedReducer = reducer;

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.FETCHING_COMPANY_SUCCESS:
      return state.mergeDeep({
        queryResults: action.response.data.limitedPartners,
        page: 1,
        totalPages: 1,
      });

    default:
      return state;
  }
}
