import { fromJS, List } from 'immutable';
import * as ActionType from '../../../actions/company/buyer/leads';

const defaultState = fromJS({
  queryResults: [],
  isFetching: false,
  page: 0,
  totalPages: 0,
  sortBy: {
    sortModel: [
      {
        colId: 'currentStatus',
        sort: 'desc',
      },
    ],
  },
  status: {
    selected: {
      name: '5',
      value: '5',
    },
  },
});

const wrappedReducer = reducer;

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.FETCHING_BUYER_TARGETS:
      return state.mergeDeep({
        isFetching: true,
        page: action.query.page,
      });

    case ActionType.FETCHING_BUYER_TARGETS_SUCCESS: {
      if (action.response.meta.pagination.currentPage === 1) {
        state = state.set('queryResults', new List());
      }

      const counter = state.get('queryResults').size;

      return state.mergeDeep({
        queryResults: state.get('queryResults').concat(addOrderToList(counter, action)),
        isFetching: false,
        totalPages: action.response.meta.pagination.totalPages,
      });
    }

    case ActionType.FETCHING_BUYER_TARGETS_FAILURE:
      return state.mergeDeep({
        isFetching: false,
      });

    case ActionType.UPDATE_LEADS:
      Object.keys(action.filterData).forEach(key => {
        if (List.isList(action.filterData[key]) || Array.isArray(action.filterData[key])) {
          state = state.setIn([action.filterName, key], new List());
        }
      });

      return state.mergeDeep({
        [action.filterName]: action.filterData,
      });

    default:
      return state;
  }
}

function addOrderToList(startAt = 1, action) {
  return fromJS(
    action.response.data.map((d, i) => {
      d.order = startAt + i;

      return d;
    }),
  );
}
