import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Immutable from 'immutable';
import CompanyBuyerLeads from '../../../components/Company/CompanyBuyer/CompanyBuyerLeads';
import { buildSortByParam } from '../../../helpers/paramBuilder';
import targetStatuses from '../../../utils/targetStatuses';
import { fetchTargetStatuses } from '../../../actions/statuses';

import { getBuyerTargets, updateLeads } from '../../../actions/company/buyer/leads';

const { func, object } = PropTypes;

class CompanyBuyerLeadsContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      statuses: Immutable.List(),
    };
    this.handleDoubleClickRow = this.handleDoubleClickRow.bind(this);
    this.handleCurrentStatusChange = this.handleCurrentStatusChange.bind(this);
    this.handleGetNextPageData = this.handleGetNextPageData.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { companyId } = this.props.match.params;
    const { leads, getBuyerTargets, fetchTargetStatuses } = this.props;
    const sortBy = buildSortByParam(leads);

    fetchTargetStatuses(({ response: { data } }) => {
      this.setState({
        statuses: targetStatuses(Immutable.fromJS(data)),
      });
    });

    getBuyerTargets({
      companyId,
      current_status: 5,
      sortBy,
    });
  }

  componentDidUpdate(oldProps) {
    const { leads, getBuyerTargets } = this.props;
    const { companyId } = this.props.match.params;

    if (oldProps.leads !== leads) {
      const oldSortBy = buildSortByParam(oldProps.leads);
      const sortBy = buildSortByParam(leads);

      if (sortBy !== oldSortBy || oldProps.leads.get('status') !== leads.get('status')) {
        getBuyerTargets({
          companyId,
          page: 1,
          current_status: leads.getIn(['status', 'selected', 'value'], 5),
          sortBy,
        });
      }
    }
  }

  handleGetNextPageData(page) {
    const { companyId } = this.props.match.params;
    const { leads, getBuyerTargets } = this.props;
    const sortBy = buildSortByParam(leads);

    getBuyerTargets({
      companyId,
      page,
      current_status: leads.getIn(['status', 'selected', 'value'], 5),
      sortBy,
    });
  }

  handleDoubleClickRow(row) {
    window.open(`/company/${row.data.targetId}`, '_blank');
  }

  handleCurrentStatusChange(event) {
    const { value } = event.target;

    this.props.updateLeads({
      filterName: 'status',
      filterData: {
        selected: {
          name: value,
          value,
        },
      },
    });
  }

  handleChange(data) {
    this.props.updateLeads({
      filterName: data.filterName,
      filterData: data.filterData,
    });
  }

  render() {
    const { leads, isDuplicateCompany } = this.props;
    const { statuses } = this.state;

    return (
      <div className="tab-pane active full-height flexItem flexWrapper" id="slide-3">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p0 full-height flexItem flexWrapper">
          <CompanyBuyerLeads
            isDuplicateCompany={isDuplicateCompany}
            leads={leads}
            onChange={this.handleChange}
            onCurrentStatusChange={this.handleCurrentStatusChange}
            onDoubleClickRow={this.handleDoubleClickRow}
            onGetNextPageData={this.handleGetNextPageData}
            statuses={statuses}
          />
        </div>
      </div>
    );
  }
}

CompanyBuyerLeadsContainer.propTypes = {
  fetchTargetStatuses: func.isRequired,
  getBuyerTargets: func.isRequired,
  isDuplicateCompany: PropTypes.bool.isRequired,
  leads: object.isRequired,
  updateLeads: func.isRequired,
};
export { CompanyBuyerLeadsContainer };
export default connect(
  state => ({
    leads: state.targetCompany.buyer.leads,
    isDuplicateCompany: state.targetCompany.mergeInfo.get('isDuplicateCompany'),
  }),
  dispatch =>
    bindActionCreators(
      {
        getBuyerTargets,
        updateLeads,
        fetchTargetStatuses,
      },
      dispatch,
    ),
)(CompanyBuyerLeadsContainer);
