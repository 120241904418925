import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import Popups from '../../components/Popup';
import { unwrap } from '../../utils/ChangeSpy';
import {
  loadIndustryTags,
  selectTag,
  toggleTagList,
  reset as resetIndustry,
  selectTags,
} from '../../actions/industryTag';

import {
  selectBusinessModel,
  mergeBusinessModel,
  resetBusinessModels,
  loadBusinessModels,
} from '../../actions/company/companyTarget';

class TagsManagementPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    if (this.props.popup.getIn(['props', 'cb'])) {
      this.context.onClosePopup(this.props.popup.getIn(['props', 'cb']));
    }
  }

  componentDidMount() {
    const { popup, tags, models } = this.props;
    const addNew = popup.getIn(['props', 'addNew']);
    const selectedTags = popup.getIn(['props', 'selectedTags'], Immutable.Map()).toJS();
    const selectedModels = popup.getIn(['props', 'selectedModels'], Immutable.Map()).toJS();

    if (tags.size === 0) {
      this.props.loadIndustryTags(selectedTags);
    } else if (!addNew) {
      this.props.selectTags(selectedTags);
    }

    if (models.size === 0) {
      this.props.loadBusinessModels(selectedModels);
    } else if (!addNew) {
      this.props.mergeBusinessModel(selectedModels);
    }
  }

  handleClose() {
    const { popup, resetIndustry, resetBusinessModels, tags, models } = this.props;
    const addNew = popup.getIn(['props', 'addNew']);
    const updateBack = popup.getIn(['props', 'callback']);

    if (!addNew) {
      updateBack(this.getSelectedTags(tags), this.getSelectedModels(models));
      resetIndustry();
      resetBusinessModels();
    }
    this.context.closePopup();
  }

  getSelectedTags(tags) {
    const result = [];

    tags.forEach(tag => {
      if (tag.get('checked')) {
        result.push(tag.toJS());
      }
      tag.get('children', Immutable.List()).forEach(child => {
        if (child.get('checked')) {
          result.push(child.toJS());
        }
      });
    });

    return result;
  }

  getSelectedModels(models) {
    const result = [];

    unwrap(models).forEach(model => {
      if (model.get('checked')) {
        result.push(model.toJS());
      }
    });

    return result;
  }

  handleChange(id, parentId) {
    this.props.selectTag(id, parentId);
  }

  handleModelChange(id) {
    this.props.selectBusinessModel(id);
  }

  handleClick(id, ids) {
    this.props.toggleTagList(id, ids);
  }

  render() {
    const { popup, tags, models } = this.props;
    const clientView = popup.getIn(['props', 'clientView']);

    return (
      <div>
        <Popups.TagsManagementPopup
          businessModels={models}
          clientView={clientView}
          onChange={this.handleChange}
          onClick={this.handleClick}
          onClose={this.handleClose}
          onModelChange={this.handleModelChange}
          tags={tags}
        />
      </div>
    );
  }
}

TagsManagementPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  models: state.businessModels.get('models'),
  tags: state.industryTags.get('tags'),
  loading: state.industryTags.get('loading'),
  saving: state.industryTags.get('saving'),
});

export { TagsManagementPopupContainer };
export default connect(mapStateToProps, {
  loadIndustryTags,
  selectTag,
  toggleTagList,
  resetIndustry,
  selectTags,
  selectBusinessModel,
  mergeBusinessModel,
  resetBusinessModels,
  loadBusinessModels,
})(TagsManagementPopupContainer);
