import PropTypes from 'prop-types';

import React from 'react';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import _Input from '../helpers/Input';
import PrettyError from '../decorators/PrettyError';
import DisplayError from '../decorators/DisplayError';

const Input = PrettyError(DisplayError(_Input));

/**
 * Mailing Template Upload popup.
 *
 * @param props {Object}.
 * @param props.onUpload {Function} To handle clicking on create button.
 * @param props.onClose {Function} To handle closing popup.
 * @param props.onChange {Function} To handle text changing.
 * @param props.onChoose {Function} To handle file changing.
 * @param props.name {String} File name.
 * @param props.disabledUpload {Boolean} If set to true, will disable create button.
 * @returns {React.Component}
 * @component
 */
const UploadPopup = props => {
  const {
    isUploading,
    disabledUpload,
    onUpload,
    hasErrors,
    onClose,
    onChange,
    onChoose,
    name,
    mimeType,
    setFormElement,
  } = props;
  const header = 'Upload a Mailing Template';
  const footer = [
    <button
      key="create"
      className="btn btn-primary btn-xs"
      disabled={disabledUpload || isUploading || hasErrors}
      onClick={onUpload}
      type="button"
    >
      {isUploading && <i className="fa fa-spinner fa-pulse fa-fw" />} Upload
    </button>,
    <button key="close" className="btn btn-default btn-xs" onClick={onClose} type="button">
      Close
    </button>,
  ];

  return (
    <Popup footer={footer} header={header} id="uploadPopup">
      <div className="row media-450 mb30">
        <form ref={setFormElement} encType="multipart/form-data">
          <FormGroup className="col-lg-6 col-xs-12">
            <div className="mb15">
              <Input
                className="input-sm"
                id="k-template-name"
                label="Template Name"
                name="name"
                onChange={onChange}
                placeholder="Template Name"
                value={name}
                autoFocus
                required
                showLabel
              />
            </div>
            <div>
              <Input
                className="input-sm uploader"
                id="k-template-file"
                name="file"
                placeholder=""
                type="file"
                {...(mimeType === null ? {} : { accept: mimeType })}
                onChange={onChoose}
                required
              />
            </div>
          </FormGroup>
        </form>
      </div>
    </Popup>
  );
};

UploadPopup.propTypes = {
  disabledUpload: PropTypes.bool,
  isUploading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onChoose: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  setFormElement: PropTypes.func.isRequired,
};

UploadPopup.defaultProps = {
  isUploading: false,
  disabledUpload: false,
  hasErrors: false,
  mimeType: null,
};

export default UploadPopup;
