import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';

import connectOptions, { mergeProps } from '../utils/connectOptions';
import { appendCounter, deleteCounter, refresh, startFind, endFind } from '../actions/autoComplete';
import { getOriginal, unwrap } from '../utils/ChangeSpy';
import _AutoComplete from '../components/helpers/AutoComplete';
import PrettyError from '../components/decorators/PrettyError';
import DisplayError from '../components/decorators/DisplayError';
import uniqueId from '../utils/uniqueId';

const AutoComplete = PrettyError(DisplayError(_AutoComplete));

class AutoCompleteContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.id = uniqueId();
    this.bindMethods();
  }

  componentDidMount() {
    this.props.appendCounter(this.id);
  }

  componentWillUnmount() {
    this.props.deleteCounter(this.id);
  }

  getCounter() {
    return this.props.autoComplete.get(this.id);
  }

  static onGetValue(suggestion = { value: '' }) {
    return suggestion.value;
  }

  bindMethods() {
    this.onFetchData = this.onFetchData.bind(this);
    this.onFetchNextData = this.onFetchNextData.bind(this);
    this.onUpdateSuggestions = this.onUpdateSuggestions.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
  }

  onFetchData({ value }) {
    this.props.refresh(this.id);
    this.props.startFind(this.id);
    this.props.find({
      filter: value,
      page: 1,
      afterSuccess: ({ response }) => {
        this.props.endFind(this.id, response);
      },
    });
  }

  onFetchNextData({ value }) {
    const counter = this.getCounter();

    if (counter.get('hasOther')) {
      this.props.startFind(this.id);
      this.props.find({
        filter: value,
        page: counter.get('page'),
        afterSuccess: ({ response }) => {
          this.props.endFind(this.id, response);
        },
      });
    }
  }

  onUpdateSuggestions(param) {
    if ('text' in param) {
      const name = this.props.valueName;
      const value = param.text;

      this.change({ name, value });
    }

    if ('suggestions' in param) {
      const name = this.props.suggestsName;
      const value = param.suggestions;

      this.change({ name, value });
    }
  }

  renderSuggestion(suggestion) {
    if (this.props.render) return this.props.render(suggestion);

    const { text, ...rest } = suggestion;

    return <span {...rest}>{text}</span>;
  }

  onSelect(event, { suggestion }) {
    this.changeValueField({ suggestion });
    this.changeIdField({ suggestion });

    if (this.props.onSelectCallback) {
      this.props.onSelectCallback(suggestion);
    }
  }

  changeValueField({ suggestion }) {
    if (suggestion) {
      const name = this.props.valueName;
      const value = suggestion.text;

      this.change({ name, value });
    }
  }

  changeIdField({ suggestion }) {
    if (suggestion) {
      const name = this.props.idName;
      const value = suggestion.id;

      this.change({ name, value });
    }
  }

  change({ name, value }) {
    this.props.change({ name, value });
  }

  onBlur(...rest) {
    const { rollbackOnBlur = true } = this.props;

    this.props.refresh(this.id);
    if (rollbackOnBlur) {
      const name = this.props.valueName;
      const value = getOriginal(this.props.value);
      const idValue = unwrap(this.props.idValue);

      if (idValue !== null && !idValue) {
        const { idName } = this.props;
        const idValue = getOriginal(this.props.idValue);

        this.change({ name, value });
        this.change({ name: idName, value: idValue });
      }
    }

    if (this.props.inputProps.onBlur) {
      return this.props.inputProps.onBlur(...rest);
    }
  }

  getLoading() {
    const counter = this.getCounter();

    if (!counter) return false;
    if ('loading' in this.props) {
      return this.props.loading;
    }

    return counter.get('loading');
  }

  getName() {
    let name = this.props.valueName;

    if (!name && this.props.inputProps) {
      name = this.props.inputProps.name || this.props.name;
    }

    return name;
  }

  render() {
    const {
      suggestions,
      value,
      inputProps,
      addNew,
      appendToContainer,
      highlightFirst,
      loadable,
      focusInputOnSuggestionClick,
    } = this.props;
    const loading = loadable && this.getLoading();

    return (
      <AutoComplete
        addNew={addNew}
        appendToContainer={appendToContainer}
        checkOn="text"
        focusInputOnSuggestionClick={focusInputOnSuggestionClick}
        formGroupClass=" "
        getNextSuggestion={this.onFetchNextData}
        getSuggestion={this.onFetchData}
        getSuggestionValue={AutoCompleteContainer.onGetValue}
        highlightFirst={highlightFirst}
        inputProps={{ ...inputProps, onBlur: this.onBlur }}
        loading={loading}
        minSuggestLength={this.props.minSuggestLength}
        name={this.getName()}
        onSuggestionSelected={this.onSelect}
        onUpdateSuggestions={this.onUpdateSuggestions}
        renderSuggestion={this.renderSuggestion}
        suggestIfEmpty={this.props.suggestIfEmpty}
        suggestions={suggestions}
        text={value}
      />
    );
  }
}

function getField(state, props, field, name) {
  if (!props.rootPath) return props[field];

  return props.rootPath.reduce((root, key) => root[key] || {}, state).getIn(name.split('.'));
}

function mapStateToProps(state, props) {
  const { valueName = '', suggestsName = '', idName = '', change, find, render, inputProps } = props;

  return {
    autoComplete: state.autoComplete,
    inputProps,
    suggestions: getField(state, props, 'suggestions', suggestsName),
    valueName,
    suggestsName,
    idName,
    value: getField(state, props, 'value', valueName) || '',
    idValue: getField(state, props, 'idValue', idName),
    change,
    find,
    render,
  };
}

AutoCompleteContainer.propTypes = {
  change: PropTypes.func.isRequired,
  find: PropTypes.func.isRequired,
  focusInputOnSuggestionClick: PropTypes.bool,
  idName: PropTypes.string.isRequired,
  inputProps: PropTypes.object,
  render: PropTypes.func,
  rootPath: PropTypes.arrayOf(PropTypes.string),
  suggestIfEmpty: PropTypes.bool,
  suggestions: PropTypes.instanceOf(List),
  suggestsName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  valueName: PropTypes.string.isRequired,
};

AutoCompleteContainer.defaultProps = {
  valueName: '',
  suggestsName: '',
  idName: '',
  appendToContainer: false,
  loadable: true,
  value: '',
  suggestions: List(),
};

export { AutoCompleteContainer };
export default connect(
  mapStateToProps,
  {
    appendCounter,
    deleteCounter,
    startFind,
    endFind,
    refresh,
  },
  mergeProps,
  connectOptions,
)(AutoCompleteContainer);
