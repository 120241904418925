/**
 * Mapper statuses DTO to usage in the Select component and the Browse Status Filter.
 *
 * @param {Array.<{}>} statusesDTO - Array of statuses from server.
 * @returns {Array.<{}>} Array of statuses.
 */
export const statusesMapper = statusesDTO =>
  statusesDTO.map(status => ({
    name: status.d,
    value: status.c || status.s,
  }));
