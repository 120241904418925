import { CALL_API } from '../../middleware/api';
import config from '../../config';

export const LOADING_BUYER_POPUP_SUGGESTION = Symbol('LOADING_BUYER_POPUP_SUGGESTION');
export const LOADED_BUYER_POPUP_SUGGESTION_FAILURE = Symbol('LOADED_BUYER_POPUP_SUGGESTION_FAILURE');
export const LOADED_BUYER_POPUP_SUGGESTION_SUCCESS = Symbol('LOADED_BUYER_POPUP_SUGGESTION_SUCCESS');
export const UPDATE_BUYER_POPUP_FIELD = Symbol('UPDATE_BUYER_POPUP_FIELD');
export const RESET_BUYER_POPUP_FORM = Symbol('RESET_BUYER_POPUP_FORM');
export const SELECT_BUYER_POPUP_SUGGESTION = Symbol('SELECT_BUYER_POPUP_SUGGESTION');
export const CLOSE_BUYER_POPUP_SUGGESTION = Symbol('CLOSE_BUYER_POPUP_SUGGESTION');

/**
 * Load suggestion.
 *
 * @param id {Number} Buyer's id or project's id.
 * @param name {Number} Field name.
 * @param value {Number} Value.
 * @returns Action.
 */
export function loadSuggestion(name, value, id) {
  const api = {
    buyer: {
      path: '/api/v1/browse/buyer_names',
    },
    project: {
      path: `/api/v1/browse/buyers/${id}/projects`,
    },
    approval: {
      path: `/api/v1/browse/projects/${id}/approval_lists`,
    },
  };

  return {
    [CALL_API]: {
      ...api[name],
      method: 'get',
      query: { like: value, limit: config.REQUEST_THROTTLING },
      startType: LOADING_BUYER_POPUP_SUGGESTION,
      errorType: LOADED_BUYER_POPUP_SUGGESTION_FAILURE,
      successType: LOADED_BUYER_POPUP_SUGGESTION_SUCCESS,
    },
    name,
  };
}

/**
 * Update field in state.
 *
 * @param name {Number} Field name.
 * @param value {Number} Value.
 * @returns Action.
 */
export function updateField(name, value) {
  return {
    type: UPDATE_BUYER_POPUP_FIELD,
    name,
    value,
  };
}

/**
 * Select a suggestion.
 *
 * @param name {Number} Field name.
 * @param id {Number} Suggestion's id.
 * @param value {String} Suggestion's value.
 * @returns Action.
 */
export function selectSuggestion(name, id, value) {
  return {
    type: SELECT_BUYER_POPUP_SUGGESTION,
    name,
    id,
    value,
  };
}

/**
 * Close suggestion.
 *
 * @param name {Number} Field name.
 * @returns Action.
 */
export function closeSuggestion(name) {
  return {
    type: CLOSE_BUYER_POPUP_SUGGESTION,
    name,
  };
}

export function resetForm() {
  return {
    type: RESET_BUYER_POPUP_FORM,
  };
}
