import { fromJS } from 'immutable';

import config from '../../config';
import * as ActionType from '../../actions/contact/contactLP';
import { autoNullId, autoRemoveId, check } from '../decorators/suggest';
import handleApiError from '../decorators/handleApiError';
import { spy, setIn, mergeToOriginal, getOriginalIn, merge } from '../../utils/ChangeSpy';
import highStatusActualize from '../decorators/highStatusActualize';

const statuses = config.values.getIn(['contactLP', 'statuses']);
const FIELDS_TO_SPY = ['suggestRelation', 'lpPrimaryRelId', 'lpCurrentStatus', 'lpHighStatus', 'lpNotes'];

const defaultState = spy(
  fromJS({
    loading: false,
    suggests: [],
    suggestRelation: '',
    lpPrimaryRelId: 0,
    lpCurrentStatus: 0,
    statuses,
    lpHighStatus: 0,
    lpNotes: '',
    inputErrors: {},
  }),
  FIELDS_TO_SPY,
);

function changeField(state, { name, value }) {
  switch (name) {
    case undefined:
      return state;

    case 'status': {
      const check = status => status.get('value') === value;
      const lpCurrentStatus = statuses.find(check, null, statuses.get(0));

      return merge(state, { lpCurrentStatus });
    }

    case 'suggestRelation': {
      return changeSuggest(state, { name, value });
    }

    case 'lpPrimaryRelId': {
      return setIn(state, name, value);
    }

    default:
      return setIn(state, name, value);
  }
}

function changeSuggest(state, { name, value }) {
  if (value === getOriginalIn(state, name)) {
    return merge(state, {
      [name]: value,
      lpPrimaryRelId: getOriginalIn(state, 'lpPrimaryRelId'),
    });
  }

  if (value === '') {
    return merge(state, { [name]: value, lpPrimaryRelId: null });
  }

  const users = state.get('suggests').filter(v => v.get('text') === value);

  if (users.size === 1)
    return merge(state, {
      suggestRelation: value,
      lpPrimaryRelId: users.getIn([0, 'id'], 0),
    });

  return merge(state, { suggestRelation: value, lpPrimaryRelId: 0 });
}

function mapResponse({ data }) {
  const { limitedPartnersOwner, lpPrimaryRelId, relationships, lpHighStatus = 0, lpNotes } = data;

  let { lpCurrentStatus } = data;

  const suggestRelation = limitedPartnersOwner && limitedPartnersOwner.userName;

  const check = status => status.get('value') === lpCurrentStatus;

  lpCurrentStatus = statuses.find(check, null, statuses.get(0)).get('value');

  return spy(
    {
      loading: false,
      suggestRelation,
      lpPrimaryRelId,
      relationships,
      lpCurrentStatus,
      lpHighStatus,
      lpNotes,
    },
    FIELDS_TO_SPY,
  );
}

// eslint-disable-next-line import/no-mutable-exports
let reducer = highStatusActualize(
  'lpCurrentStatus',
  'lpHighStatus',
  ActionType.CHANGE_CONTACT_LP_FIELD,
  handleApiError(ActionType.ERRORED_SAVE_CONTACT_LP, (state = defaultState, action) => {
    switch (action.type) {
      case ActionType.CHANGE_CONTACT_LP_FIELD:
        return changeField(state, action).merge({ inputErrors: {} });

      case ActionType.LOAD_CONTACT_LP:
        return defaultState.merge({ loading: true });

      case ActionType.LOADED_LP_USERS:
        return state.merge({ suggests: action.response.data });

      case ActionType.CLEAR_LP_USERS:
        return state.merge({ suggests: [] });

      case ActionType.LOADED_CONTACT_LP:
        return defaultState.merge(mapResponse(action.response));

      case ActionType.SAVED_CONTACT_LP:
        return spy(mergeToOriginal(state, action.body), FIELDS_TO_SPY);

      case ActionType.ERROR_CONTACT_LP:
        return state.merge({
          loading: false,
          inputErrors: getErrors(action.response.errors),
        });

      default:
        return state;
    }
  }),
);

reducer = autoNullId('suggestRelation', 'lpPromaryRelId', ActionType.CHANGE_CONTACT_LP_FIELD, reducer);
reducer = autoRemoveId('suggestRelation', 'lpPromaryRelId', ActionType.CHANGE_CONTACT_LP_FIELD, reducer);
reducer = check('suggestRelation', 'suggests', ActionType.LOADED_LP_USERS, reducer);

export default reducer;

function getErrors(errors) {
  return errors || {};
}
