import { fromJS } from 'immutable';

import { LOADED_APPROVALS_SUCCESS } from '../../actions/approval/main';

const defaultState = fromJS({
  id: 0,
  buyerId: 0,
  name: '',
  buyer: '',
  harvcoLead: '',
  clientLead: '',
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case LOADED_APPROVALS_SUCCESS:
      return mapProject(state, action);

    default:
      return state;
  }
}

/**
 * Map project data from api response.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action object.
 * @returns {Immutable.Map}
 */
function mapProject(state, action) {
  const res = action.response;

  if (res.status === 'success') {
    return state.merge({
      id: res.data.id,
      buyerId: res.data.buyerId,
      category: res.data.category,
      buyer: res.data.buyer ? res.data.buyer.legalName : '',
      harvcoLead: res.data.harvcoLead ? res.data.harvcoLead.userName : '',
      clientLead: res.data.clientLead ? res.data.clientLead.fullName : '',
    });
  }

  return state;
}
