import PropTypes from 'prop-types';

import React, { useCallback, useState } from 'react';
import { Map } from 'immutable';
import readXlsxFile from 'read-excel-file';
import BrowseCheckbox from './BrowseCheckbox';
import { schema } from '../../config/constants/search/xlsxUploadScema.js';

function BrowseUploadSearch(props) {
  const [fileName, setFileName] = useState('');
  const [fileChosen, setFileChosen] = useState(false);

  const UPLOAD_SEARCH_TOOLTIP =
    'Your Excel file may have 1-3 columns with the headers "Name", "Website", and/or "Phone"';

  const uploadTemplatePath = 'https://harvey.saritasa.rocks/reports/uploadTemplate.xlsx';

  const handleChange = useCallback(event => {
    const fileInput = event.target;

    if (!fileInput.files[0]) {
      setFileName('');
      setFileChosen(false);

      props.onChange({
        filterName: props.filter.get('name'),
        filterData: {
          data: [],
        },
      });
    } else {
      setFileName(fileInput.files[0].name);
      setFileChosen(true);

      readXlsxFile(fileInput.files[0], { schema }).then(data => {
        if (!data.rows.length) {
          setFileName('');
        }
        // The data will be append search filter to send to server.

        props.onChange({
          filterName: props.filter.get('name'),
          filterData: {
            data: data.rows,
          },
        });
      });
    }
  }, []);

  const resetFile = event => {
    const fileInput = event.target;

    fileInput.value = null;
  };

  return (
    <BrowseCheckbox {...props}>
      <label className="custom-upload-label">
        <span className="custom-upload">Choose File</span>
        <span>
          {fileName && !props.clearStatus ? (
            fileName
          ) : (
            <>
              No file chosen&nbsp;
              <i
                aria-hidden="true"
                className="fa fa-question upload-help"
                onClick={event => event.preventDefault()}
                title={UPLOAD_SEARCH_TOOLTIP}
              />
            </>
          )}
        </span>
        <input
          accept=".xlsx"
          className="file-upload-input"
          id="input"
          onChange={handleChange}
          onClick={resetFile}
          type="file"
        />
      </label>
      {fileChosen && !fileName && (
        <div className="upload-error">
          Error: the format of this file is incorrect. Download a template to see a properly formatted Excel file.
        </div>
      )}
      <div className="template-download">
        <a href={uploadTemplatePath} download>
          Download Template
        </a>
      </div>
    </BrowseCheckbox>
  );
}

BrowseUploadSearch.propTypes = {
  clearStatus: PropTypes.bool,
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  submitOnEnter: PropTypes.bool,
};

BrowseUploadSearch.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

export default BrowseUploadSearch;
