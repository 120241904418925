import PropTypes from 'prop-types';

import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Stateless component for general result row.
 *
 * @param props {Object}.
 * @param props.className {String} Row class.
 * @param props.linkTo {String} Link to.
 * @param props.children {Any} Child elements.
 * @returns {React.Component}
 * @class
 */
const GeneralResultRow = ({ children, linkTo, className = '' }) => (
  <Link className={`search-result__line ${className}`} target="_blank" to={linkTo}>
    {children}
  </Link>
);

GeneralResultRow.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  linkTo: PropTypes.string.isRequired,
};

export default GeneralResultRow;
