import React from 'react';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Input from '../helpers/Input';
import DropDown from '../helpers/DropDown';
import Button from '../helpers/Button';

const NewLPProfilePopup = () => {
  const header = 'New LP Profile';
  const footer = (
    <Button className="btn-primary btn-xs" data-dismiss="modal">
      Create
    </Button>
  );

  return (
    <Popup footer={footer} header={header} id="newLpProf">
      <div className="row media-450">
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="f-researcher"
            label="Researcher"
            name="researcher"
            placeholder="Researcher"
            autoFocus
            required
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="f-status3"
            label="Status"
            name="status"
            placeholder="Status"
            required
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row media-450">
        <FormGroup className="col-lg-3 col-sm-3 col-xs-4">
          <label className="control-label" htmlFor="w-full-name3">
            Full Name
          </label>
          <DropDown btnContent="Dropdown" className="btn-default btn-xs" id="dropdownMenu3" wrapperClassName="ib">
            {/* TODO append dropdown content */}
          </DropDown>
        </FormGroup>

        <FormGroup className="col-lg-6 col-sm-6 col-xs-4 ml-20">
          <Input
            className="input-sm"
            id="w-full-name3"
            label="Full Name"
            name="fullName"
            placeholder="Full Name"
            required
          />
        </FormGroup>
        <FormGroup className="col-lg-3 col-sm-3 col-xs-4">
          <DropDown btnContent="Dropdown" className="btn-default btn-xs" id="dropdownMenu4">
            {/* TODO append dropdown content */}
          </DropDown>
        </FormGroup>
      </div>

      <div className="clearfix" />

      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-sm"
            id="f-first-name3"
            label="First Name"
            name="firstName"
            placeholder="First Name"
            required
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-sm"
            id="f-last-name-3"
            label="Last Name"
            name="lastName"
            placeholder="Last Name"
            required
            showLabel
          />
        </FormGroup>
        >
      </div>
      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Input className="input-sm" id="f-nick3" label="Nick" name="nick" placeholder="Nick" required showLabel />
        </FormGroup>
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-sm"
            id="f-pronunciation3"
            label="Pronunciation"
            name="pronunciation"
            placeholder="Pronunciation"
            required
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-sm"
            id="f-email3"
            label="Email"
            name="email"
            placeholder="Email"
            type="email"
            required
            showLabel
          />
        </FormGroup>
      </div>
    </Popup>
  );
};

export default NewLPProfilePopup;
