import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map, fromJS, List } from 'immutable';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import { unwrap, getChanged } from '../../utils/ChangeSpy';

import CountryDropDown from '../CountryDropDown';

import {
  handleGetSuggestion,
  handleUpdateParentCompany,
  revertFields,
  closeValidationError,
  changeField,
  saveCompany,
  findDirectors,
  findAnalyst,
} from '../../actions/company/targetCreate';
import Popups from '../../components/Popup';
import AutoCompleteContainer from '../AutoComplete';
import { changeField as changePositionField } from '../../actions/contact/contactExecutive';

class AddCompanyTargetPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.onErrorClose = this.onErrorClose.bind(this);
    this.onToggleCountry = this.onToggleCountry.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSuggestChange = this.onSuggestChange.bind(this);

    this.handleToggleDBA = this.handleToggleDBA.bind(this);
    this.handleToggleCountry = this.handleToggleCountry.bind(this);
    this.handleUpdateSuggestions = this.handleUpdateSuggestions.bind(this);
    this.handleGetSuggestion = this.handleGetSuggestion.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
    this.handleParentCompanyClick = this.handleParentCompanyClick.bind(this);
    this.onChangeCountryName = this.onChangeCountryName.bind(this);
    this.onSelectCountry = this.onSelectCountry.bind(this);
  }

  componentDidMount() {
    this.props.changeField({
      name: 'directorName',
      value: this.context.currentUser.get('module') || '',
    });
    this.props.changeField({
      name: 'recordOwnerId',
      value: this.context.currentUser.get('moduleId') || null,
    });
    this.props.changeField({
      name: 'analystName',
      value: this.context.currentUser.get('userName') || '',
    });
    this.props.changeField({
      name: 'recordSubOwnerId',
      value: this.context.currentUser.get('id') || null,
    });
    this.props.changePositionField({
      name: 'newPosition.suggestCompany',
      value: '',
    });
    this.props.changePositionField({
      name: 'newPosition.entityId',
      value: null,
    });
  }

  onErrorClose(e, field) {
    this.props.closeValidationError({ field });
  }

  getChildContext() {
    return {
      onErrorClose: this.onErrorClose,
      inputErrors: this.props.inputErrors,
    };
  }

  onCreate(event) {
    event.preventDefault();

    const body = getChanged(this.props.company);

    if (body.country && body.country.countryId) {
      body.countryId = body.country.countryId;
      delete body.country;
    }

    const that = this;

    this.props.saveCompany({
      body,
      afterSuccess: ({ getState }) => {
        const newTargetId = getState().targetCompany.targetCreate.get('prevId');

        that.context.closePopup();
        that.context.openPopup('AddPositionPopup', {
          newTargetId,
          newTargetIdLegalName: that.legalName,
        });
      },
      afterError: () => {
        that.context.closePopup();
        that.context.openPopup('ApiErrorPopup', {
          error: "Couldn't create a new target company!",
        });
      },
    });
  }

  onChange(event) {
    const {
      target: { name, value },
    } = event;

    if (!name || name === 'parentCompany') return;
    if (name === 'companyType') {
      const typeHasParent = ['DivPri', 'DivLBO', 'DivPub', 'DivVC', 'DivNFP', 'DivFor'];

      if (typeHasParent.indexOf(value) > -1) {
        this.props.handleUpdateParentCompany({ text: '', selected: null });
        this.props.changeField({ name: 'parentId', value: null });
      }
    }
    this.props.changeField({ name, value });
    if (name === 'legalName') {
      this.legalName = value;
    }
  }

  onToggleCountry() {
    return this.props.changeField({
      name: 'showCountry',
      value: !this.props.company.get('showCountry'),
    });
  }

  onClose() {
    this.context.closePopup();
  }

  onSuggestChange({ name, value }) {
    return this.props.changeField({ name, value });
  }

  getSuggests() {
    return {
      suggestDirector: this.getModuleSuggests(),
      suggestAnalyst: this.getAnalystSuggests(),
    };
  }

  getModuleSuggests() {
    const prefix = '';

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={opts => {
          this.props.findDirectors({
            ...opts,
            field: `${prefix}directorName`,
          });
        }}
        idName={`${prefix}recordOwnerId`}
        inputProps={{ name: `${prefix}directorName` }}
        name={`${prefix}directorName`}
        rootPath={['targetCompany', 'targetCreate']}
        suggestsName={`${prefix}directorSuggests`}
        valueName={`${prefix}directorName`}
      />
    );
  }

  getAnalystSuggests() {
    const prefix = '';

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={opts => {
          this.props.findAnalyst({
            ...opts,
            field: `${prefix}analystName`,
          });
        }}
        idName={`${prefix}recordSubOwnerId`}
        inputProps={{ name: `${prefix}analystName` }}
        name={`${prefix}analystName`}
        rootPath={['targetCompany', 'targetCreate']}
        suggestsName={`${prefix}analystSuggests`}
        valueName={`${prefix}analystName`}
      />
    );
  }

  handleToggleDBA() {
    const currentValue = this.props.targetCompany.getIn(['showDBA']);

    this.props.handleUpdateCompanyInfo({
      showDBA: !currentValue,
    });
  }

  handleToggleCountry() {
    const currentValue = this.props.targetCompany.getIn(['showCountry']);

    this.props.handleUpdateCompanyInfo({
      showCountry: !currentValue,
    });
  }

  handleParentCompanyClick() {
    const selectedCompany = this.props.targetCompany.get('parentCompany').get('selected');

    if (selectedCompany) {
      window.open(`/company/${selectedCompany.get('id')}`);
    }
  }

  handleUpdateSuggestions(update) {
    this.props.handleUpdateParentCompany(update);
  }

  handleGetSuggestion({ value }) {
    this.props.handleGetSuggestion(value);
  }

  static handleGetSuggestionValue(suggestion) {
    return suggestion.legalName;
  }

  static handleRenderSuggestion(suggestion) {
    return <div>{suggestion.legalName}</div>;
  }

  handleSuggestionSelected(event, { suggestion }) {
    this.props.handleUpdateParentCompany({
      text: suggestion.legalName,
      selected: suggestion,
      suggestions: [],
    });
  }

  onChangeCountryName(event) {
    this.props.changeField({
      name: 'country.countryName',
      value: event.target.value,
    });
  }

  onSelectCountry(selection) {
    this.props.changeField({ name: 'country.countryId', value: selection.id });
    this.props.changeField({
      name: 'country.countryName',
      value: selection.text,
    });
  }

  render() {
    const { children, company, states, entityTypes, isValid, tags, businessModels } = this.props;
    const companyInfoProps = {
      onSubmit: this.onBlurInfo,
      onCompanyInfoChange: this.handleCompanyInfoChange,
      onToggleDBA: this.handleToggleDBA,
      onToggleCountry: this.handleToggleCountry,
      onParentCompanyClick: this.handleParentCompanyClick,
    };
    const suggestions = company.getIn(['parentCompany', 'suggestions'], fromJS([]));
    const text = company.getIn(['parentCompany', 'text']) || '';
    const suggestionProps = {
      name: 'parentCompany',
      inputProps: {
        className: 'parentCompany form-control',
        id: 'parentCompany',
        name: 'parentCompany',
        disabled: false,
      },
      suggestions,
      onUpdateSuggestions: this.handleUpdateSuggestions,
      getSuggestion: this.handleGetSuggestion,
      getSuggestionValue: AddCompanyTargetPopupContainer.handleGetSuggestionValue,
      renderSuggestion: AddCompanyTargetPopupContainer.handleRenderSuggestion,
      onSuggestionSelected: this.handleSuggestionSelected,
      checkOn: 'text',
      text,
    };
    const countryComponent = (
      <CountryDropDown
        name="country.countryId"
        onChange={this.onChangeCountryName}
        onSelect={this.onSelectCountry}
        value={company.getIn(['country', 'countryName'])}
      />
    );

    return (
      <div>
        <Popups.AddCompanyTargetPopup
          {...companyInfoProps}
          {...this.getSuggests()}
          businessModels={businessModels}
          company={company}
          countryComponent={countryComponent}
          entityTypes={entityTypes}
          error={{
            businessModel: '',
            industryCategory: '',
          }}
          isValid={isValid}
          onChange={this.onChange}
          onCreate={this.onCreate}
          onToggleCountry={this.onToggleCountry}
          states={states}
          suggestionProps={suggestionProps}
          tags={tags}
        />
        {children}
      </div>
    );
  }
}

AddCompanyTargetPopupContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
  redirectForce: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

AddCompanyTargetPopupContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map).isRequired,
};

const defaultErrors = Map();

function mapStateToProps(state, props) {
  return {
    ...props,
    inputErrors: state.targetCompany.targetCreate.get('inputErrors', defaultErrors),
    isValid: state.targetCompany.targetCreate.get('isValid', true),
    states: state.targetCompany.targetCreate.get('states'),
    entityTypes: state.targetCompany.targetCreate.get('entityTypes'),
    company: state.targetCompany.targetCreate,
    targetCompany: state.targetCompany.targetCreate,
    target: state.targetCompany.target,
    tags: state.industryTags.get('tags'),
    businessModels: unwrap(state.businessModels.get('models', List())),
  };
}

export { AddCompanyTargetPopupContainer };

const Export = connect(
  mapStateToProps,
  {
    revertFields,
    changePositionField,
    handleGetSuggestion,
    changeField,
    saveCompany,
    closeValidationError,
    findDirectors,
    findAnalyst,
    handleUpdateParentCompany,
  },
  mergeProps,
  connectOptions,
)(AddCompanyTargetPopupContainer);

export default Export;
