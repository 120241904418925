import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import moment from 'moment/moment';

import logo from '../../assets/img/content/logo-2.png';

const TopSection = ({ onlineApproval }) => {
  const buyerLegalName = onlineApproval.get('buyerLegalName');
  const today = moment(new Date()).format('dddd, MMMM DD, YYYY');

  return (
    <>
      <div className="row">
        <div className="col-sm-3">
          <h6 className="priority-codes">Priority Codes:</h6>
          <ul className="list-unstyled">
            <li>&ldquo;A&rdquo; High Priority</li>
            <li>&ldquo;B&rdquo; Medium Priority</li>
            <li>&ldquo;C&rdquo; Low Priority</li>
          </ul>
        </div>
        <div className="col-sm-6 text-center">
          <h3 className="buyer-name">{buyerLegalName}</h3>
          <h5 className="approval-list-label mt15">{onlineApproval.get('approvalListLabel')}</h5>
          <h5 className="mt5 mb5">{today}</h5>
          <h5 className="mt5 mb5">Sorted by : Sales</h5>
        </div>
        <div className="col-sm-3">
          <div className="right-part">
            <img alt="" className="img-responsive img-logo" src={logo} />
            <ul className="list-unstyled mt5">
              {onlineApproval.get('approvalListUsers', List()).map(user => {
                const title = user.get('title') ? `, ${user.get('title')}` : null;

                return (
                  <li key={user.get('lastName')}>
                    <h5 className="mt5 mb5">
                      {user.get('firstName')} {user.get('lastName')}
                      {title}
                    </h5>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

TopSection.propTypes = {
  onlineApproval: PropTypes.instanceOf(Map).isRequired,
};

export default memo(TopSection);
