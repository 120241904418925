import PropTypes from 'prop-types';

import React from 'react';
import Immutable from 'immutable';
import FormGroup from '../helpers/FormGroup';
import Input from '../helpers/Input';

/**
 * User text input component.
 *
 * @param props {Object}.
 * @param props.label {String} Label of text control.
 * @param props.name {String} Name of text control.
 * @param props.formMode {Function} Form mode (view|edit).
 * @param props.formError {Immutable.Map} Error on form.
 * @param props.selectedUser {Immutable.Map} Selected user.
 * @param props.type {String} Type of control.
 * @param props.className {String} Input class name.
 * @returns {React.Component}
 * @class
 */
const UserInput = props => {
  const { label, name, formMode, className, formError, selectedUser, type = 'text' } = props;
  const id = `${label.replace(/\s/g, '_')}_Input`;
  const disabled = formMode === 'view';
  const hasError = formError.get(name, '').length > 0 && formMode === 'edit';
  const value = selectedUser.get(name, '');
  const inputClassName = disabled ? null : className;

  return (
    <FormGroup className={hasError ? 'has-error' : ''}>
      <Input
        className={inputClassName}
        disabled={disabled}
        id={id}
        name={name}
        placeholder={`${label}`}
        type={type}
        value={value}
      />
    </FormGroup>
  );
};

UserInput.propTypes = {
  className: PropTypes.string,
  formError: PropTypes.instanceOf(Immutable.Map).isRequired,
  formMode: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selectedUser: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default UserInput;
