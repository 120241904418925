import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map, fromJS, List } from 'immutable';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import config from '../../config';
import { checkPhone, formatPhone } from '../services/phoneRules';
import checkZip from '../services/zipRules';

import {
  handleGetSuggestion,
  handleUpdateParentCompany,
  closeValidationError,
  changeField,
  saveCompany,
  findDirectors,
  findAnalyst,
  checkCompanyByWebsite,
  revertFields as resetCompanyFields,
} from '../../actions/company/targetCreate';
import { resetBusinessModels } from '../../actions/company/companyTarget';
import { reset as resetTags } from '../../actions/industryTag';
import Popups from '../../components/Popup';
import CountryDropDown from '../CountryDropDown';
import AutoCompleteContainer from '../AutoComplete';
import { getChanged, unwrap } from '../../utils/ChangeSpy';
import canHasParent from '../../components/Company/canHasParent';

export class AddCompanyTargetPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.fromPositionPopup = this.props.popup.getIn(['props', 'fromPositionPopup']);
    if (this.fromPositionPopup) {
      AddCompanyTargetPopupContainer.closable = true;
      this.context.onClosePopup(this.props.popup.getIn(['props', 'cb']));
    } else {
      AddCompanyTargetPopupContainer.closable = false;
    }
    this.onErrorClose = this.onErrorClose.bind(this);
    this.onToggleCountry = this.onToggleCountry.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSuggestChange = this.onSuggestChange.bind(this);
    this.isValidPhone = this.isValidPhone.bind(this);
    this.handleToggleDBA = this.handleToggleDBA.bind(this);
    this.handleToggleCountry = this.handleToggleCountry.bind(this);
    this.handleUpdateSuggestions = this.handleUpdateSuggestions.bind(this);
    this.handleGetSuggestion = this.handleGetSuggestion.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
    this.handleParentCompanyClick = this.handleParentCompanyClick.bind(this);
    this.onChangeCountryName = this.onChangeCountryName.bind(this);
    this.onSelectCountry = this.onSelectCountry.bind(this);
    this.clearFormError = this.clearFormError.bind(this);
    this.showPhoneError = this.showPhoneError.bind(this);
    this.showZipError = this.showZipError.bind(this);
    this.isValidZip = this.isValidZip.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onSaveCompany = this.onSaveCompany.bind(this);
    this.state = {
      phone: '',
      zip: '',
      industryCategoryIds: '',
      businessModel: '',
      submitting: false,
      parentCompany: '',
    };
    this.handleClick = this.handleClick.bind(this);
    this.isValidIndustryCategory = this.isValidIndustryCategory.bind(this);
  }

  componentDidMount() {
    const user = this.context.currentUser;

    if (user) {
      this.props.changeField({
        name: 'directorName',
        value: user.get('module') || '',
      });
      this.props.changeField({
        name: 'recordOwnerId',
        value: user.get('moduleId') || null,
      });

      const roles = user.get('roles', List());

      if (roles.size && roles.getIn([0, 'slug']) === config.ANALYST_ASSOCIATE) {
        this.props.changeField({
          name: 'analystName',
          value: user.get('userName'),
        });
        this.props.changeField({
          name: 'recordSubOwnerId',
          value: user.get('id'),
        });
      }
    }
  }

  onErrorClose(e, field) {
    this.props.closeValidationError({ field });
  }

  getChildContext() {
    return {
      onErrorClose: (event, ...rest) => {
        if (rest[0] === 'zip') {
          this.setState(prevState => ({ prevState, zip: '' }));
        } else {
          this.onErrorClose(event, ...rest);
        }
      },
      inputErrors: this.props.inputErrors,
    };
  }

  isValidPhone(phone) {
    if (this.props.company.get('showCountry', false)) return true;

    if (!checkPhone(phone)) {
      this.showPhoneError();

      return false;
    }

    return true;
  }

  isParentCompanyValid = () => {
    const companyType = this.props.company.get('companyType');
    const parentCompany = this.props.targetCompany.get('parentCompany').get('selected');

    if (canHasParent(companyType)) {
      this.onParenCompanyBlur();

      return parentCompany;
    }

    return true;
  };

  isValidZip(zip) {
    if (!checkZip(zip)) {
      this.showZipError();

      return false;
    }

    return true;
  }

  isValidIndustryCategory(ids, businessModelIds) {
    let result = true;

    if (!ids.length) {
      this.setState({
        industryCategoryIds: 'Industry Tags is required',
      });
      result = false;
    }

    if (!businessModelIds.length) {
      this.setState({
        businessModel: 'Business Model is required',
      });
      result = false;
    }

    return result;
  }

  onCreateForPosition(event) {
    event.preventDefault();

    const body = getChanged(this.props.company);

    if (body.country && body.country.countryId) {
      body.countryId = body.country.countryId;
      delete body.country;
    }

    const that = this;

    this.props.saveCompany({
      body,
      afterSuccess: ({ getState }) => {
        const newTargetId = getState().targetCompany.targetCreate.get('prevId');

        that.context.closePopup();
        that.context.openPopup('AddPositionPopup', {
          newTargetId,
          newTargetIdLegalName: that.legalName,
        });
      },
      afterError: () => {
        that.context.closePopup();
        that.context.openPopup('ApiErrorPopup', {
          error: "Couldn't create a new target company!",
        });
      },
    });
  }

  onCreate(event) {
    event.preventDefault();
    if (!this.state.submitting) {
      const phone = unwrap(this.props.company.get('phone', ''));
      const web = unwrap(this.props.company.get('web', ''));
      const zip = unwrap(this.props.company.get('zip', ''));
      const industryCategoryIds = this.getIndustryTagIds(this.props.tags);
      const businessModelIds = this.getBusinessModelIds(this.props.businessModels);
      const userRole = this.props.userRole.get('slug');

      if (
        this.isValidPhone(phone) &&
        this.isValidZip(zip) &&
        this.isValidIndustryCategory(industryCategoryIds, businessModelIds) &&
        this.isParentCompanyValid()
      ) {
        this.clearFormError();

        const body = getChanged(this.props.company);

        if (body.country && body.country.countryId) {
          body.countryId = body.country.countryId;
          delete body.country;
        }

        let afterSuccess = ({ response }) => {
          setTimeout(() => {
            this.context.redirectForce(`/company/${response.id}`);
          }, 0);
        };

        let afterError = () => {
          this.setState(prevState => ({ ...prevState, submitting: false }));
        };

        if (this.fromPositionPopup) {
          afterSuccess = () => this.context.closePopup();
          afterError = () => this.context.closePopup();
        }

        this.setState(prevState => ({ ...prevState, submitting: true }));

        const afterCheckSuccess = ({ response: { isExist } }) => {
          if (isExist) {
            this.context.openPopup('ConfirmPopup', {
              message: 'This profile appears to be a duplicate. Proceed?',
              onOk: () => this.onSaveCompany(body, industryCategoryIds, businessModelIds, afterSuccess, afterError),
              onCancel: () => {
                this.props.resetCompanyFields();
                this.props.resetBusinessModels();
                this.props.resetTags();
                this.context.closePopup();
              },
              yes: 'Continue',
              no: 'Cancel',
            });
          } else {
            this.onSaveCompany(body, industryCategoryIds, businessModelIds, afterSuccess, afterError);
          }
        };

        if (userRole === 'researcher' && web.length > 0) {
          this.props.checkCompanyByWebsite(web, afterCheckSuccess);
        } else {
          this.onSaveCompany(body, industryCategoryIds, businessModelIds, afterSuccess, afterError);
        }
      }
    }
  }

  onSaveCompany(body, industryCategoryIds, businessModelIds, afterSuccess, afterError) {
    return this.props.saveCompany({
      body: {
        ...body,
        parentId: body.parentId || null,
        industryCategoryIds: industryCategoryIds.concat(businessModelIds),
      },
      afterSuccess,
      afterError,
    });
  }

  getBusinessModelIds(models) {
    const result = [];

    models.forEach(t => {
      if (t.get('checked')) {
        result.push(t.get('id'));
      }
    });

    return result;
  }

  getIndustryTagIds(tags) {
    const result = [];

    tags.forEach(t => {
      if (t.get('checked')) {
        result.push(t.get('id'));
      }
      t.get('children').forEach(c => {
        if (c.get('checked')) {
          result.push(c.get('id'));
        }
      });
    });

    return result;
  }

  onChange(event) {
    const {
      target: { name, value },
    } = event;

    if (!name || name === 'parentCompany') return;
    if (name === 'companyType') {
      const typeHasParent = ['DivPri', 'DivLBO', 'DivPub', 'DivVC', 'DivNFP', 'DivFor'];

      if (typeHasParent.indexOf(value) === -1) {
        this.props.handleUpdateParentCompany({ text: '', selected: null });
        this.props.changeField({ name: 'parentId', value: null });
      } else {
        this.props.changeField({ name: 'parentId', value: 0 });
      }
    }
    this.props.changeField({ name, value });
  }

  onToggleCountry() {
    const currentValue = this.props.company.get('showCountry');

    if (currentValue) {
      // If users show off country field, clear country information.
      this.props.changeField({ name: 'country.countryId', value: null });
      this.props.changeField({ name: 'country.countryName', value: '' });
      this.props.changeField({
        name: 'showCountry',
        value: false,
      });
    } else {
      this.clearFormError();
      this.props.changeField({
        name: 'showCountry',
        value: true,
      });
    }
  }

  onClose() {
    this.context.closePopup();
  }

  onSuggestChange({ name, value }) {
    return this.props.changeField({ name, value });
  }

  getSuggests() {
    return {
      suggestDirector: this.getModuleSuggests(),
      suggestAnalyst: this.getAnalystSuggests(),
    };
  }

  getModuleSuggests() {
    const prefix = '';

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={opts => {
          this.props.findDirectors({
            ...opts,
            field: `${prefix}directorName`,
          });
        }}
        idName={`${prefix}recordOwnerId`}
        inputProps={{ name: `${prefix}directorName` }}
        name={`${prefix}directorName`}
        rootPath={['targetCompany', 'targetCreate']}
        suggestsName={`${prefix}directorSuggests`}
        valueName={`${prefix}directorName`}
      />
    );
  }

  getAnalystSuggests() {
    const prefix = '';

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={opts => {
          this.props.findAnalyst({
            ...opts,
            field: `${prefix}analystName`,
          });
        }}
        idName={`${prefix}recordSubOwnerId`}
        inputProps={{ name: `${prefix}analystName` }}
        name={`${prefix}analystName`}
        rootPath={['targetCompany', 'targetCreate']}
        suggestsName={`${prefix}analystSuggests`}
        valueName={`${prefix}analystName`}
      />
    );
  }

  handleToggleDBA() {
    const currentValue = this.props.targetCompany.getIn(['showDBA']);

    this.props.handleUpdateCompanyInfo({
      showDBA: !currentValue,
    });
  }

  handleToggleCountry() {
    const currentValue = this.props.targetCompany.getIn(['showCountry']);

    this.props.handleUpdateCompanyInfo({
      showCountry: !currentValue,
    });
  }

  handleParentCompanyClick() {
    const selectedCompany = this.props.targetCompany.get('parentCompany').get('selected');

    if (selectedCompany) {
      window.open(`/company/${selectedCompany.get('id')}`);
    }
  }

  handleUpdateSuggestions(update) {
    this.props.handleUpdateParentCompany(update);
  }

  handleGetSuggestion({ value }) {
    this.props.handleGetSuggestion(value);
  }

  static handleGetSuggestionValue(suggestion) {
    return suggestion.legalName;
  }

  static handleRenderSuggestion(suggestion) {
    return <div>{suggestion.legalName}</div>;
  }

  handleSuggestionSelected(event, { suggestion }) {
    this.clearFormError();
    this.props.handleUpdateParentCompany({
      text: suggestion.legalName,
      selected: suggestion,
      suggestions: [],
    });
  }

  onChangeCountryName(event) {
    this.props.changeField({
      name: 'country.countryName',
      value: event.target.value,
    });
  }

  onSelectCountry(selection) {
    this.props.changeField({ name: 'country.countryId', value: selection.id });
    this.props.changeField({
      name: 'country.countryName',
      value: selection.text,
    });
  }

  clearFormError() {
    this.setState({
      phone: '',
      zip: '',
      businessModel: '',
      industryCategoryIds: '',
      parentCompany: '',
    });
  }

  showPhoneError() {
    this.setState(prevState => ({
      ...prevState,
      phone:
        'Phone must be a valid North American area code and format (###-###-####) or +[numbers, spaces and dashes] if international',
    }));
  }

  showParentCompanyError = () => {
    this.setState(prevState => ({
      ...prevState,
      parentCompany:
        'Must select an existing Parent Profile. If one does not exist, then Parent Company must be profiled.',
    }));
  };

  showZipError() {
    this.setState(prevState => ({
      ...prevState,
      zip:
        'Zip code should follow U.S. (XXXXX or XXXXX-XXXX) or Canadian (A1A 1A1) format; otherwise, please set address to International ("...")',
    }));
  }

  onParenCompanyBlur = () => {
    this.clearFormError();

    const selectedCompany = this.props.targetCompany.get('parentCompany').get('selected');

    if (!selectedCompany) {
      this.props.handleUpdateParentCompany({ text: '' });
      this.showParentCompanyError();
    }
  };

  onBlur({ target: { name, value = '' } }) {
    if (this.props.company.get('showCountry', false)) {
      this.clearFormError();

      return;
    }

    if (name === 'phone') {
      if (this.isValidPhone(value)) {
        this.clearFormError();
        this.props.changeField({
          name: 'phone',
          value: value ? formatPhone(value) : '',
        });
      } else {
        this.showPhoneError();
      }
    } else if (name === 'zip' && !checkZip(value)) {
      this.showZipError();
    }
  }

  handleClick() {
    this.context.openPopup('TagsManagementPopup', {
      addNew: true,
      clientView: true,
      cb: () =>
        this.context.openPopup('AddCompanyTargetPopup', {
          fromPositionPopup: this.fromPositionPopup,
          cb: this.props.popup.getIn(['props', 'cb']),
        }),
    });
  }

  render() {
    const { children, company, states, entityTypes, isValid, businessModels, tags } = this.props;
    const companyInfoProps = {
      onSubmit: this.onBlurInfo,
      onCompanyInfoChange: this.handleCompanyInfoChange,
      onToggleDBA: this.handleToggleDBA,
      onToggleCountry: this.handleToggleCountry,
      onParentCompanyClick: this.handleParentCompanyClick,
    };
    const suggestions = company.getIn(['parentCompany', 'suggestions'], fromJS([]));
    const text = company.getIn(['parentCompany', 'text']) || '';
    const suggestionProps = {
      name: 'parentCompany',
      inputProps: {
        className: 'parentCompany form-control',
        id: 'parentCompany',
        name: 'parentCompany',
        disabled: false,
        onBlur: this.onParenCompanyBlur,
      },
      error: this.state.parentCompany,
      canDisplayError: this.state.parentCompany,
      onErrorClose: this.clearFormError,
      suggestions,
      onUpdateSuggestions: this.handleUpdateSuggestions,
      getSuggestion: this.handleGetSuggestion,
      getSuggestionValue: AddCompanyTargetPopupContainer.handleGetSuggestionValue,
      renderSuggestion: AddCompanyTargetPopupContainer.handleRenderSuggestion,
      onSuggestionSelected: this.handleSuggestionSelected,
      checkOn: 'text',
      text,
      highlightFirst: false,
    };
    const countryComponent = (
      <CountryDropDown
        name="country.countryId"
        onChange={this.onChangeCountryName}
        onSelect={this.onSelectCountry}
        value={company.getIn(['country', 'countryName'])}
      />
    );

    return (
      <div>
        <Popups.AddCompanyTargetPopup
          {...companyInfoProps}
          {...this.getSuggests()}
          businessModels={businessModels}
          company={company}
          countryComponent={countryComponent}
          entityTypes={entityTypes}
          error={{
            phone: this.state.phone,
            zip: this.state.zip,
            businessModel: this.state.businessModel,
            industryCategoryIds: this.state.industryCategoryIds,
            parentCompany: this.state.parentCompany,
          }}
          fromPositionPopup={this.fromPositionPopup}
          isValid={isValid}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onClick={this.handleClick}
          onClose={this.onClose}
          onCreate={this.onCreate}
          onErrorClose={this.clearFormError}
          onToggleCountry={this.onToggleCountry}
          states={states}
          submitting={this.state.submitting}
          suggestionProps={suggestionProps}
          tags={tags}
        />
        {children}
      </div>
    );
  }
}

AddCompanyTargetPopupContainer.contextTypes = {
  currentUser: PropTypes.instanceOf(Map).isRequired,
  redirectForce: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
};

AddCompanyTargetPopupContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map).isRequired,
};

const defaultErrors = Map();

function mapStateToProps(state, props) {
  return {
    ...props,
    businessModels: unwrap(state.businessModels.get('models', List())),
    inputErrors: state.targetCompany.targetCreate.get('inputErrors', defaultErrors),
    isValid: state.targetCompany.targetCreate.get('isValid', true),
    states: state.targetCompany.targetCreate.get('states'),
    entityTypes: state.targetCompany.targetCreate.get('entityTypes'),
    company: state.targetCompany.targetCreate,
    targetCompany: state.targetCompany.targetCreate,
    target: state.targetCompany.target,
    tags: state.industryTags.get('tags'),
    userRole: state.auth.getIn(['user', 'roles', 0]),
  };
}

const Export = connect(
  mapStateToProps,
  {
    handleGetSuggestion,
    changeField,
    saveCompany,
    closeValidationError,
    findDirectors,
    findAnalyst,
    handleUpdateParentCompany,
    checkCompanyByWebsite,
    resetCompanyFields,
    resetTags,
    resetBusinessModels,
  },
  mergeProps,
  connectOptions,
)(AddCompanyTargetPopupContainer);

Export.closable = AddCompanyTargetPopupContainer.closable;

export default Export;
