import { fromJS } from 'immutable';

import { toJS, spy, setIn, getOriginalIn } from '../../utils/ChangeSpy';
import * as ActionType from '../../actions/company/buyerCreate';
import config from '../../config';
import handleApiError from '../decorators/handleApiError';
import { autoNullId, check, resetFieldByOther } from '../decorators/suggest';
import getValidator from '../../utils/getValidator';
import { TYPE_FINANCIAL, TYPE_STRATEGIC } from '../../config/constants/common';

const { states, entityTypes } = config.company;

const types = fromJS([
  { name: '', value: '', disabled: true },
  { name: TYPE_STRATEGIC, value: TYPE_STRATEGIC },
  { name: TYPE_FINANCIAL, value: TYPE_FINANCIAL },
]);

const SCHEMA = {
  type: 'object',
  properties: {
    buyerType: {
      title: 'Buyer Type',
      description: 'should be valid type',
      oneOf: [
        { type: 'string', pattern: /^Strategic$/ },
        { type: 'string', pattern: /^Financial/ },
      ],
    },
    harvcoName: {
      title: 'Harvco Contact',
      description: 'should not be empty',
      oneOf: [{ type: 'string', pattern: /./ }],
    },
    buyerHarvcoLeadId: {
      title: 'Harvco Contact',
      description: 'should be valid user',
      errorTo: 'harvcoLeadName',
      type: 'number',
      minimum: 1,
    },
    revenue: {
      title: 'Revenue',
      description: 'should be empty or valid number',
      oneOf: [{ type: 'number', minimum: 0 }, { type: 'string', pattern: /\d*\.?\d*/ }, { type: 'null' }],
    },
    employees: {
      title: 'Employees',
      description: 'should be empty or valid number',
      oneOf: [{ type: 'number', minimum: 0 }, { type: 'string', pattern: /\d*\.?\d*/ }, { type: 'null' }],
    },
    phone: config.phoneValidation,
  },
};

const FIELDS_TO_SPY = [
  'buyerType',
  'buyerHarvcoLeadId',
  'harvcoName',
  'analystName',
  'recordSubOwnerId',
  'legalName',
  'abbrName',
  'dbaName',
  'web',
  'companyType',
  'tickerExchange',
  'tickerSumbol',
  'parentId',
  'phone',
  'address',
  'city',
  'state',
  'zip',
  'country.countryId',
  'country.countryName',
  'revenue',
  'employees',
  'description',
];

const defaultState = fromJS(
  spy(
    {
      buyerTypes: types,
      buyerType: '',
      buyerHarvcoLeadId: null,
      buyerHarvcoLeadSuggests: [],
      harvcoName: '',
      harvcoSuggests: [],
      analystName: '',
      analystSuggests: [],
      recordSubOwnerId: null,
      isFetching: false,
      legalName: '',
      tickerExchange: '',
      tickerSymbol: '',
      abbrName: '',
      dbaName: '',
      web: '',
      phone: null,
      address: null,
      city: '',
      state: '',
      companyType: '',
      zip: '',
      country: {
        countryId: 0,
        countryName: '',
      },
      showCountry: false,
      isValidAddress: false,
      revenue: '',
      employees: '',
      description: '',
      states: states.map(state => ({ value: state, name: state })),
      parentId: null,
      entityTypes: entityTypes.map((type, i) => ({
        disabled: i === 0,
        value: type,
        name: type,
      })),
      error: null,
      inputErrors: {},
      isValid: true,
      parentCompany: spy(
        {
          suggestions: [],
          text: '',
          selected: null,
        },
        ['text'],
      ),
    },
    FIELDS_TO_SPY,
  ),
);

const validator = getValidator(SCHEMA);

let wrappedReducer = handleApiError(ActionType.SAVE_COMPANY_ERROR, reducer);

wrappedReducer = check('harvcoName', 'harvcoSuggests', ActionType.LOADED_HARVCOS, wrappedReducer);
wrappedReducer = check('analystName', 'analystSuggests', ActionType.LOADED_ANALYSTS, wrappedReducer);
wrappedReducer = autoNullId('harvcoName', 'buyerHarvcoLeadId', ActionType.UPDATE_FIELD, wrappedReducer);
wrappedReducer = autoNullId('analystName', 'recordSubOwnerId', ActionType.UPDATE_FIELD, wrappedReducer);
wrappedReducer = resetFieldByOther('showCountry', 'city', ActionType.UPDATE_FIELD, wrappedReducer);
wrappedReducer = resetFieldByOther('showCountry', 'state', ActionType.UPDATE_FIELD, wrappedReducer);
wrappedReducer = resetFieldByOther('showCountry', 'zip', ActionType.UPDATE_FIELD, wrappedReducer);
wrappedReducer = resetFieldByOther('showCountry', 'country.countryId', ActionType.UPDATE_FIELD, wrappedReducer);
wrappedReducer = resetFieldByOther('showCountry', 'country.countryName', ActionType.UPDATE_FIELD, wrappedReducer);

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.UPDATE_FIELD:
      return checkValidity(setIn(state, action.name, action.value));

    case ActionType.UPDATE_PARENT_COMPANY: {
      if (action.info.suggestions && action.info.suggestions.length === 0) {
        state = state.setIn(['parentCompany', 'suggestions'], defaultState.getIn(['parentCompany', 'suggestions']));
      }

      if (typeof action.info.text !== 'undefined') {
        state = setIn(state, 'parentId', 0);
        state = state.update('parentCompany', state => setIn(state, 'text', action.info.text));
        state = state.update('parentCompany', state => setIn(state, 'selected', null));
        if (action.info.text === getOriginalIn(state, 'parentCompany.text')) {
          state = setIn(state, 'parentId', getOriginalIn(state, 'parentId'));
        }
      }

      if (action.info.selected) {
        state = setIn(state, 'parentId', action.info.selected.id);
        state = state.update('parentCompany', state => setIn(state, 'selected', fromJS(action.info.selected)));
      }

      return checkValidity(state);
    }

    case ActionType.SAVE_COMPANY:
      return state.set('isFetching', true);

    case ActionType.SAVE_COMPANY_SUCCESS:
      return defaultState;

    case ActionType.CLOSE_VALIDATION_ERROR:
      return state.deleteIn(['inputErrors', action.field]);

    case ActionType.LOADED_HARVCOS:
      return state;

    case ActionType.LOADED_ANALYSTS:
      return state;

    case ActionType.FETCHING_LIST_COMPANY_SUCCESS:
      return state.setIn(['parentCompany', 'suggestions'], fromJS(action.response.data));

    default:
      return state;
  }
}

function checkValidity(state) {
  const plainState = toJS(state);
  const isValid = validator.isValid(plainState);

  const inputErrors = validator.getFirstError(plainState);

  return state.merge({
    inputErrors,
    isValid,
  });
}
