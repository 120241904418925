import React from 'react';
import CompanyTradeGroupsTable from './CompanyTradeGroupsTable';
import config from '../../../config';

const CompanyTradeGroups = props => {
  const { handleDoubleClickRow, onAdd, data, onChange } = props;
  const TABLE_TITLE = 'All Trade Groups (Trade Shows, Industry Associations, List Sources)';

  const columnDefs = config.tables.getIn(['companyTradeGroups', 'columnDefs']);

  return (
    <div className="container">
      <div className="row">
        <div className="ml100 mr100 mt50">
          <h3 className="ml20 mb30">{TABLE_TITLE}</h3>
          <CompanyTradeGroupsTable
            columnDefs={columnDefs}
            data={data}
            onChange={onChange}
            onDoubleClickRow={handleDoubleClickRow}
          />
          <div className="col">
            <button className="pull-right mr10 mt70" onClick={onAdd}>
              Add Trade Group
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyTradeGroups;
