import React from 'react';
import classNames from 'classnames';
import Popup from '../helpers/Popup';
import _Button from '../helpers/Button';
import LoadAnimation from '../decorators/LoadAnimation';
import Select from '../helpers/Select';
import Checkbox from '../helpers/Checkbox';
import WarningMessage from '../helpers/WarningMessage';

const Button = LoadAnimation(_Button, undefined, undefined, true);

const UpdateHighStatusPopup = props => {
  const { statuses, highStatus, onChange, onSave, onClose, correcting, loading, shouldSendEmail } = props;
  const saveClassCss = classNames('btn-primary btn-xs', { hide: !correcting });
  const footer = [
    <Button key="save" className={saveClassCss} data-dismiss="modal" loading={loading} onClick={onSave} autoFocus>
      {shouldSendEmail ? 'Email Director' : 'Save'}
    </Button>,
    <_Button key="close" className="btn-default btn-xs" data-dismiss="modal" onClick={onClose}>
      Close
    </_Button>,
  ];

  return (
    <Popup footer={footer} header="Update High Status" id="updateHighStatusPopup">
      <div className="row">
        <div className="col-md-12 form-group mb20 checkbox-flex">
          <Checkbox
            checked={correcting}
            label="The High Status was changed in error, and I am correcting the mistake."
            name="correcting"
            onChange={onChange}
          />
          <WarningMessage
            messageText="(High Status was changed more than 24 hours ago and needs Director approval to change.)"
            show={shouldSendEmail}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 form-group">
          <Select
            className="form-control"
            disabled={!correcting}
            emptyVal=""
            name="highStatus"
            onChange={onChange}
            options={statuses}
            value={highStatus}
            showValue
          />
        </div>
      </div>
    </Popup>
  );
};

export default UpdateHighStatusPopup;
