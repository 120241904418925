import React, { useState, useEffect } from 'react';
import { Map, List } from 'immutable';
import PropTypes from 'prop-types';

import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import FormGroup from '../helpers/FormGroup';
import config from '../../config';
import { InputProps } from '../helpers/AutoComplete';
import AutoComplete from '../../containers/AutoComplete';
import downloadFile from '../../utils/downloadFile';
import PrettyError from '../decorators/PrettyError';
import DisplayError from '../decorators/DisplayError';
import _Select from '../helpers/Select';

const Select = PrettyError(DisplayError(_Select));

/** Conflict report popup component. */
const ConflictReportPopup = (props, context) => {
  const { companyInfoData, statuses, findUsers, findDirectors, clearUsers } = props;

  const [lowestStatus, setLowestStatus] = useState('1.00');
  const [highestStatus, setHighestStatus] = useState('10.00');
  const [include, setInclude] = useState('all');
  const [selectedInclude, setSelectedInclude] = useState([]);
  const [suggestValue, setSuggestValue] = useState('');
  const [activity, setActivity] = useState('active');
  const [formError, setFormError] = useState(null);

  useEffect(() => {
    setFormError(null);
  }, [include, activity]);

  /**
   * Form submit handler.
   *
   * @param {SyntheticEvent} event The react `SyntheticEvent`.
   */
  function handleSubmit(event) {
    event.preventDefault();

    if (isFormValid()) {
      const url = config.main.get('conflictReport');

      const selectedQuery =
        selectedInclude.length > 0 ? `&${selectedInclude.map(item => `selected[]=${item.id}`).join('&')}` : '';

      downloadFile({
        url: `${url}?include=${include}&activity=${activity}&status_range[low]=${lowestStatus}&status_range[high]=${highestStatus}${selectedQuery}`,
      });

      context.closePopup();
    }
  }

  /** Check form is valid or not. */
  function isFormValid() {
    setFormError(null);

    if ((include === 'lead' || include === 'team') && selectedInclude.length === 0) {
      setFormError('Please select individuals');

      return false;
    }

    return true;
  }

  /**
   * Handle lowest status change.
   *
   * @param {SyntheticEvent} event Select change event.
   */
  function handleLowestStatusChange(event) {
    const { value } = event.target;

    setLowestStatus(value);

    if (Number(value) > Number(highestStatus)) {
      setHighestStatus(value);
    }
  }

  /**
   * Handle highest status change.
   *
   * @param {SyntheticEvent} event Select change event.
   */
  function handleHighestStatusChange(event) {
    const { value } = event.target;

    setHighestStatus(value);

    if (Number(value) < Number(lowestStatus)) {
      setLowestStatus(value);
    }
  }

  /**
   * Handle "Include" radio input change.
   *
   * @param {SyntheticEvent} event Input change event.
   */
  function handleIncludeChange(event) {
    setInclude(event.target.value);
    setSuggestValue('');
    setSelectedInclude([]);
    clearUsers();
  }

  /**
   * Handle "Activity" radio input change.
   *
   * @param {SyntheticEvent} event Input change event.
   */
  function handleActivityChange(event) {
    setActivity(event.target.value);
  }

  /**
   * Check "Activity" field on click with the same value.
   *
   * @param {SyntheticEvent} event Input click event.
   */
  function handleActivitySameClick(event) {
    const newActivity = event.target.value;

    if (newActivity === activity) {
      setActivity('inactive');
    }
  }

  /**
   * Handle suggests changed.
   *
   * @param {object} payload Autocomplete payload.
   * @param {string} payload.name Suggest field name.
   * @param {number} payload.value Suggest field value.
   */
  function handleSuggestChange({ name, value }) {
    if (name === 'suggestText') {
      setSuggestValue(value);
    }
  }

  /**
   * Handle suggests selected.
   *
   * @param {object} payload Autocomplete select payload.
   * @param {number} payload.id Suggest id.
   * @param {string} payload.text Suggest field value text.
   */
  function handleSuggestSelect({ id, text }) {
    const isSameInclude = selectedInclude.find(include => include.id === id);

    if (isSameInclude === undefined) {
      setSelectedInclude([...selectedInclude, { id, name: text }]);
    }
    setSuggestValue('');
  }

  /**
   * Handle suggests deleted.
   *
   * @param {SyntheticEvent} event Button click event.
   */
  function handleRemoveSelectedInclude(event) {
    const { name } = event.target;
    const id = parseInt(name.replace('include_', ''), 10);

    setSelectedInclude(selectedInclude.filter(item => item.id !== id));
  }

  const header = 'Download Conflict Report';
  const footer = (
    <Button className="btn-xs btn-primary" onClick={handleSubmit} type="button">
      Download
    </Button>
  );

  const renderSelectedInclude =
    selectedInclude.length > 0 &&
    selectedInclude.map(include => (
      <div key={include.id} className="w160 MultiselectItem">
        {include.name}
        <button
          aria-label="close"
          className="MultiselectItem-close close"
          name={`include_${include.id}`}
          onClick={handleRemoveSelectedInclude}
          type="button"
        >
          &times;
        </button>
      </div>
    ));

  const renderDirectors = include === 'lead' && (
    <>
      <FormGroup className="w160 form-inline-block">
        <InputProps className="form-control text-bold" placeholder="Lead Director">
          <AutoComplete
            change={handleSuggestChange}
            find={findDirectors}
            highlightFirst={false}
            onSelectCallback={handleSuggestSelect}
            suggestions={companyInfoData.get('suggests')}
            value={suggestValue}
            valueName="suggestText"
          />
        </InputProps>
      </FormGroup>
      {renderSelectedInclude}
    </>
  );

  const renderUsers = include === 'team' && (
    <>
      <FormGroup className="w160 form-inline-block">
        <InputProps className="form-control text-bold" placeholder="User">
          <AutoComplete
            change={handleSuggestChange}
            find={findUsers}
            highlightFirst={false}
            onSelectCallback={handleSuggestSelect}
            suggestions={companyInfoData.get('suggests')}
            value={suggestValue}
            valueName="suggestText"
          />
        </InputProps>
      </FormGroup>
      {renderSelectedInclude}
    </>
  );

  const renderFormError = formError && <p className="block-center mb0 mt15 error-text">{formError}</p>;

  return (
    <Popup footer={footer} header={header} id="ConflictReportPopup">
      <p className="mb4">Current Buyer Status:</p>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <FormGroup className="col-xs-12 d-flex pb8">
            <label className="text-normal pr20 pl8 min-w70" htmlFor="lowestStatus">
              Lowest
            </label>
            <Select
              id="lowestStatus"
              label="Lowest"
              name="lowestStatus"
              onChange={handleLowestStatusChange}
              options={statuses}
              selectClassName="input-sm"
              value={lowestStatus}
              showValue
            />
          </FormGroup>
          <FormGroup className="col-xs-12 d-flex">
            <label className="text-normal pr20 pl8 min-w70" htmlFor="highestStatus">
              Highest
            </label>
            <Select
              id="highestStatus"
              label="Highest"
              name="highestStatus"
              onChange={handleHighestStatusChange}
              options={statuses}
              selectClassName="input-sm"
              value={highestStatus}
              showValue
            />
          </FormGroup>
        </div>

        <p className="mt15 mb4">Individuals Included:</p>
        <FormGroup>
          <div className="radio-wrap radio-wrap--reverse">
            <label htmlFor="includeDirectors">Lead Directors</label>
            <input
              checked={include === 'lead'}
              id="includeDirectors"
              name="include"
              onChange={handleIncludeChange}
              type="radio"
              value="lead"
            />
          </div>
          {renderDirectors}
        </FormGroup>
        <FormGroup>
          <div className="radio-wrap radio-wrap--reverse">
            <label htmlFor="includeUsers">Project team / Users</label>
            <input
              checked={include === 'team'}
              id="includeUsers"
              name="include"
              onChange={handleIncludeChange}
              type="radio"
              value="team"
            />
          </div>
          {renderUsers}
        </FormGroup>
        <FormGroup>
          <div className="radio-wrap radio-wrap--reverse">
            <label htmlFor="includeAll">All</label>
            <input
              checked={include === 'all'}
              id="includeAll"
              name="include"
              onChange={handleIncludeChange}
              type="radio"
              value="all"
            />
          </div>
        </FormGroup>

        <p className="mt20 mb4">Active/Inactive Projects:</p>

        <FormGroup>
          <div className="radio-wrap radio-wrap--reverse">
            <label htmlFor="activityActive">Active: Only include &quot;Active&quot; Projects</label>
            <input
              checked={activity === 'active'}
              id="activityActive"
              name="activity"
              onChange={handleActivityChange}
              onClick={handleActivitySameClick}
              type="radio"
              value="active"
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="radio-wrap radio-wrap--reverse radio-wrap--start">
            <label htmlFor="activityAll">
              All: Include both &quot;Active&quot; and &quot;Inactive&quot; Projects <br />
              where activity occurred during the date range provided
            </label>
            <input
              checked={activity === 'all'}
              id="activityAll"
              name="activity"
              onChange={handleActivityChange}
              onClick={handleActivitySameClick}
              type="radio"
              value="all"
            />
          </div>
        </FormGroup>
        {renderFormError}
      </form>
    </Popup>
  );
};

ConflictReportPopup.propTypes = {
  clearUsers: PropTypes.func.isRequired,
  companyInfoData: PropTypes.instanceOf(Map).isRequired,
  findDirectors: PropTypes.func.isRequired,
  findUsers: PropTypes.func.isRequired,
  statuses: PropTypes.instanceOf(List).isRequired,
};

ConflictReportPopup.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
};

export default ConflictReportPopup;
