import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import { handleGetHarvcoLeadSuggestion, handleGetClientLeadSuggestion } from '../../actions/popup';

import AddNewCEOProjectPopup from '../../components/Popup/AddNewCEOProjectPopup';

class AddNewCEOProjectContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.handleGetSuggestionValue = this.handleGetSuggestionValue.bind(this);
    this.handleRenderSuggestion = this.handleRenderSuggestion.bind(this);

    this.handleUpdateHarvcoLeadSuggestions = this.handleUpdateHarvcoLeadSuggestions.bind(this);
    this.handleGetHarvcoLeadSuggestion = this.handleGetHarvcoLeadSuggestion.bind(this);
    this.handleHarvcoLeadSuggestionSelected = this.handleHarvcoLeadSuggestionSelected.bind(this);

    this.handleUpdateClientLeadSuggestions = this.handleUpdateClientLeadSuggestions.bind(this);
    this.handleGetClientLeadSuggestion = this.handleGetClientLeadSuggestion.bind(this);
    this.handleClientLeadSuggestionSelected = this.handleClientLeadSuggestionSelected.bind(this);
    this.getHarvcoLeadFail = this.getHarvcoLeadFail.bind(this);
    this.getHarvcoLeadSuccess = this.getHarvcoLeadSuccess.bind(this);
    this.getClientLeadSuccess = this.getClientLeadSuccess.bind(this);
    this.getClientLeadFail = this.getClientLeadFail.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;

    this.context.updatePopupFormData({
      [name]: value,
    });
  }

  handleCreate() {
    const { popup } = this.props;
    const onCreate = popup.getIn(['props', 'onCreate'], () => {});
    const onSuccessResponse = popup.getIn(['props', 'onSuccessResponse'], () => {});

    onCreate(popup.get('formData'), {
      beforeStart: this.context.startPopupLoading,
      afterSuccess: () => {
        this.context.endPopupLoading();
        onSuccessResponse();
      },
      afterError: ({ resBody }) => {
        this.context.endPopupLoading(resBody);
      },
    });
  }

  handleClose() {
    this.context.closePopup();
  }

  handleGetSuggestionValue(suggestion) {
    return suggestion.text;
  }

  handleRenderSuggestion(suggestion) {
    return <div>{suggestion.text}</div>;
  }

  handleUpdateHarvcoLeadSuggestions(update) {
    this.context.updatePopupFormData({
      harvcoLead: update,
    });
  }

  getHarvcoLeadSuccess({ response }) {
    this.context.updatePopupFormData({ harvcoLoading: false });
    this.context.updatePopupFormData({
      harvcoLead: {
        suggestions: response.data,
      },
    });
  }

  getHarvcoLeadFail() {
    this.context.updatePopupFormData({ harvcoLoading: false });
  }

  handleGetHarvcoLeadSuggestion({ value }) {
    this.context.updatePopupFormData({ harvcoLoading: true });
    this.props.handleGetHarvcoLeadSuggestion(value, this.getHarvcoLeadSuccess, this.getHarvcoLeadFail);
  }

  handleHarvcoLeadSuggestionSelected(event, { suggestion }) {
    this.context.updatePopupFormData({
      harvcoLead: {
        text: suggestion.text,
        suggestions: [],
        selected: suggestion,
      },
    });
  }

  handleUpdateClientLeadSuggestions(update) {
    this.context.updatePopupFormData({
      clientLead: update,
    });
  }

  getClientLeadSuccess({ response }) {
    this.context.updatePopupFormData({ clientLoading: false });
    this.context.updatePopupFormData({
      clientLead: {
        suggestions: response.data,
      },
    });
  }

  getClientLeadFail() {
    this.context.updatePopupFormData({ clientLoading: false });
  }

  handleGetClientLeadSuggestion({ value }) {
    const buyerId = this.props.popup.getIn(['props', 'buyerId']);

    this.context.updatePopupFormData({ clientLoading: true });
    this.props.handleGetClientLeadSuggestion(buyerId, value, this.getClientLeadSuccess, this.getClientLeadFail);
  }

  handleClientLeadSuggestionSelected(event, { suggestion }) {
    this.context.updatePopupFormData({
      clientLead: {
        text: suggestion.text,
        suggestions: [],
        selected: suggestion,
      },
    });
  }

  render() {
    const { popup } = this.props;
    const formData = popup.get('formData');

    const harvcoLeadSuggestionsProps = {
      inputProps: {
        className: 'form-control',
        id: 'harvcoLeadDropdown',
        name: 'harvcoLeadDropdown',
        label: 'HarvCo Lead',
        disabled: false,
        placeholder: '',
      },
      suggestions: popup.getIn(['formData', 'harvcoLead', 'suggestions'], new List()),
      onUpdateSuggestions: this.handleUpdateHarvcoLeadSuggestions,
      getSuggestion: this.handleGetHarvcoLeadSuggestion,
      getSuggestionValue: this.handleGetSuggestionValue,
      renderSuggestion: this.handleRenderSuggestion,
      onSuggestionSelected: this.handleHarvcoLeadSuggestionSelected,
      text: popup.getIn(['formData', 'harvcoLead', 'text'], ''),
      loading: popup.getIn(['formData', 'harvcoLoading'], false),
    };
    const clientLeadSuggestionsProps = {
      inputProps: {
        className: 'form-control',
        id: 'clientLeadDropdown',
        name: 'clientLeadDropdown',
        label: 'Client Lead',
        disabled: false,
        placeholder: '',
      },
      suggestions: popup.getIn(['formData', 'clientLead', 'suggestions'], new List()),
      onUpdateSuggestions: this.handleUpdateClientLeadSuggestions,
      getSuggestion: this.handleGetClientLeadSuggestion,
      getSuggestionValue: this.handleGetSuggestionValue,
      renderSuggestion: this.handleRenderSuggestion,
      onSuggestionSelected: this.handleClientLeadSuggestionSelected,
      text: popup.getIn(['formData', 'clientLead', 'text'], ''),
      loading: popup.getIn(['formData', 'clientLoading'], false),
    };

    return (
      <div>
        <AddNewCEOProjectPopup
          clientLeadSuggestions={clientLeadSuggestionsProps}
          formData={formData}
          harvcoLeadSuggestions={harvcoLeadSuggestionsProps}
          isPopupFetching={this.props.popup.get('isPopupFetching')}
          isPopupFetchingError={this.props.popup.get('isPopupFetchingError')}
          onChange={this.handleChange}
          onClose={this.handleClose}
          onCreate={this.handleCreate}
        />
      </div>
    );
  }
}

AddNewCEOProjectContainer.propTypes = {
  popup: PropTypes.instanceOf(Map).isRequired,
};

AddNewCEOProjectContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  updatePopupFormData: PropTypes.func.isRequired,
  startPopupLoading: PropTypes.func.isRequired,
  endPopupLoading: PropTypes.func.isRequired,
};

export { AddNewCEOProjectContainer };
export default connect(
  (state, props) => ({
    ...props,
  }),
  {
    handleGetHarvcoLeadSuggestion,
    handleGetClientLeadSuggestion,
  },
  mergeProps,
  connectOptions,
)(AddNewCEOProjectContainer);
