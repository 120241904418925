import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Map } from 'immutable';
import DatePicker from '@vespaiach/react-datepicker';

import BrowseCheckbox from './BrowseCheckbox';

const MAX_DATE_TOOLTIP =
  'Selecting the End Date criteria while leaving the values blank will search for Retainers with No End Date';

function BrowseDateFilter(props) {
  const onStartDateChange = useCallback(date => {
    props.onChange({
      filterName: props.filter.get('name'),
      filterData: {
        min: date.isValid() ? date : null,
      },
    });
  }, []);

  const onEndDateChange = useCallback(date => {
    props.onChange({
      filterName: props.filter.get('name'),
      filterData: {
        max: date.isValid() ? date : null,
      },
    });
  }, []);

  const onRemoveDate = dateType => {
    props.onChange({
      filterName: props.filter.get('name'),
      filterData: {
        [dateType]: null,
      },
    });
  };

  return (
    <BrowseCheckbox {...props}>
      <div className="BrowseControl-content BrowseControl-content--activity">
        <div className="form-inline">
          <div className="form-group">
            <label>From</label>
            <div className="d-flex">
              <DatePicker
                className="form-control"
                dateFormat="MM/DD/YYYY"
                dropdownMode="select"
                onChange={onStartDateChange}
                selected={props.info.get('min')}
                showDayInMonthOnly
                showMonthDropdown
                showYearDropdown
              />
              <button
                className="ml10 close btn-xs"
                onClick={() => {
                  onRemoveDate('min');
                }}
              >
                &times;
              </button>
            </div>
          </div>
          <div className="form-group">
            <label>To</label>
            <div className="d-flex">
              <DatePicker
                className="form-control"
                dateFormat="MM/DD/YYYY"
                dropdownMode="select"
                onChange={onEndDateChange}
                selected={props.info.get('max')}
                showDayInMonthOnly
                showMonthDropdown
                showYearDropdown
              />
              <button
                className="ml10 close btn-xs"
                onClick={() => {
                  onRemoveDate('max');
                }}
              >
                &times;
              </button>
              <i
                aria-hidden="true"
                className="fa fa-question max-date-help"
                onClick={event => event.preventDefault()}
                title={MAX_DATE_TOOLTIP}
              />
            </div>
          </div>
        </div>
      </div>
    </BrowseCheckbox>
  );
}

BrowseDateFilter.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  submitOnEnter: PropTypes.bool,
};

BrowseDateFilter.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

export default BrowseDateFilter;
