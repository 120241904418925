import { CALL_API, CHAIN_API } from '../../middleware/api';

export const START_EDIT_COMPANY_EVENT = Symbol('START_EDIT_COMPANY_EVENT');
export const CHANGE_COMPANY_EVENT = Symbol('CHANGE_COMPANY_EVENT');
export const SAVE_COMPANY_EVENT = Symbol('SAVE_COMPANY_EVENT');
export const SAVE_COMPANY_EVENT_ERROR = Symbol('SAVE_COMPANY_EVENT_ERROR');
export const DELETE_COMPANY_EVENT = Symbol('DELETE_COMPANY_EVENT');
export const ENTER_COMPANY_EMAIL = Symbol('ENTER_COMPANY_EMAIL');
export const ENTER_COMPANY_DEAL_NOTE = Symbol('ENTER_COMPANY_DEAL_NOTE');
export const LEAVE_COMPANY_EMAIL = Symbol('LEAVE_COMPANY_EMAIL');
export const LEAVE_COMPANY_DEAL_NOTE = Symbol('LEAVE_COMPANY_DEAL_NOTE');
export const ENTER_COMPANY_EVENT = Symbol('ENTER_COMPANY_EVENT');
export const LEAVE_COMPANY_EVENT = Symbol('LEAVE_COMPANY_EVENT');
export const FETCHING_EVENT = Symbol('FETCHING_EVENT');
export const FETCHING_EVENT_SUCCESS = Symbol('FETCHING_EVENT_SUCCESS');
export const FETCHING_EVENT_FAILURE = Symbol('FETCHING_EVENT_FAILURE');
export const LOADED_EVENT_LIST = Symbol('LOADED_EVENT_LIST');
export const LOADED_EVENT_LIST_SUCCESS = Symbol('LOADED_EVENT_LIST_SUCCESS');
export const LOADED_EVENT_LIST_FAILURE = Symbol('LOADED_EVENT_LIST_FAILURE');
export const RESET_CURRENT_EVENT = Symbol('RESET_CURRENT_EVENT');
export const LOADED_USERS = Symbol('LOADED_USERS');
export const LOADED_DIRECTORS = Symbol('LOADED_DIRECTORS');
export const LOADED_ANALYSTS = Symbol('LOADED_ANALYSTS');
export const LOADED_DEALMAKERS = Symbol('LOADED_DEALMAKERS');

export const CLOSE_VALIDATION_ERROR = Symbol('CLOSE_VALIDATION_ERROR');

/**
 * Reset current event to default state.
 */
export function resetCurrentEvent() {
  return {
    type: RESET_CURRENT_EVENT,
  };
}

/**
 * Spawned on mouse entering to deal note.
 *
 * @param eventId {Number}.
 * @returns {{type: symbol, eventId: number}}
 */
export function enterCompanyDealNote({ eventId }) {
  return {
    type: ENTER_COMPANY_DEAL_NOTE,
    eventId,
  };
}

/**
 * Spawned on mouse leave deal note.
 *
 * @param eventId {Number}.
 * @returns {{type: symbol, eventId: number}}
 */
export function leaveCompanyDealNote({ eventId }) {
  return {
    type: LEAVE_COMPANY_DEAL_NOTE,
    eventId,
  };
}

/**
 * Spawned on mouse entering to event.
 *
 * @param eventId {Number}.
 * @returns {{type: symbol, eventId: number}}
 */
export function enterCompanyEvent({ eventId, ...rest }) {
  return {
    ...rest,
    type: ENTER_COMPANY_EVENT,
    eventId,
  };
}

/**
 * Spawned on mouse leave event.
 *
 * @param eventId {Number}.
 * @returns {{type: symbol, eventId: number}}
 */
export function leaveCompanyEvent({ eventId }) {
  return {
    type: LEAVE_COMPANY_EVENT,
    eventId,
  };
}

/**
 * Spawned on mouse entering to email.
 *
 * @param eventId {Number}.
 * @returns {{type: symbol, eventId: number}}
 */
export function enterCompanyEmail({ eventId }) {
  return {
    type: ENTER_COMPANY_EMAIL,
    eventId,
  };
}

/**
 * Spawned on mouse leave email.
 *
 * @param eventId {Number}.
 * @returns {{type: symbol, eventId: number}}
 */
export function leaveCompanyEmail({ eventId }) {
  return {
    type: LEAVE_COMPANY_EMAIL,
    eventId,
  };
}

/**
 * Starts editing of company event.
 *
 * @param eventId {Number}.
 * @param approach
 * @param afterSuccess {Function} After success event.
 * @returns {{type: symbol, eventId: number}}
 */
export function startEditCompanyEvent({ eventId, approach, ...rest }) {
  if (eventId) {
    return {
      [CALL_API]: {
        method: 'get',
        path: `/api/v1/events/${eventId}`,
        startType: FETCHING_EVENT,
        errorType: FETCHING_EVENT_FAILURE,
        successType: FETCHING_EVENT_SUCCESS,
      },
      eventId,
    };
  }

  return {
    ...rest,
    type: START_EDIT_COMPANY_EVENT,
    eventId,
    approach,
  };
}

/**
 * Change value in company event.
 *
 * @param field {String} Name of field to change.
 * @param value {String|Number} Value from input.
 * @returns {{type: symbol, field: string, value: string|number}}
 */
export function changeCompanyEvent({ field, value }) {
  return {
    type: CHANGE_COMPANY_EVENT,
    field,
    value,
  };
}

/**
 * Save company event.
 *
 * @param isPrompt {Boolean} If true, will prompt users to add new next action event after completing current event.
 */
export function saveCompanyEvent({ body, afterSuccess, afterError, isPrompt = false }) {
  const { id } = body;
  let method = 'put';
  let path = `/api/v1/events/${id}`;

  if (!id) {
    method = 'post';
    path = '/api/v1/events';
  }

  return {
    [CHAIN_API]: [
      () => ({
        isNew: !id,
        isPrompt,
        [CALL_API]: {
          method,
          path,
          body,
          startType: SAVE_COMPANY_EVENT,
          errorType: SAVE_COMPANY_EVENT_ERROR,
          skipGlobalErrorHandler: true,
          afterError,
          afterSuccess,
        },
      }),
    ],
  };
}

export function reloadEvents({ entityId, afterSuccess, ...rest }) {
  return {
    ...rest,
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies/${entityId}?include=events`,
      startType: LOADED_EVENT_LIST,
      successType: LOADED_EVENT_LIST_SUCCESS,
      errorType: LOADED_EVENT_LIST_FAILURE,
      maxCount: 2,
      afterSuccess,
    },
  };
}

/**
 * Remove event.
 *
 * @param id {Number} Event id.
 * @param entityId {Number} EntityId.
 * @param afterSuccess {Function} After success callback.
 * @returns {MiddlewareApi.CallApi}
 */
export function deleteCompanyEvent({ id, entityId, afterSuccess }) {
  return {
    [CHAIN_API]: [
      () => ({
        [CALL_API]: {
          method: 'delete',
          path: `/api/v1/events/${id}`,
          successType: DELETE_COMPANY_EVENT,
          successParams: { eventId: id },
          afterSuccess,
          afterError: ({ dispatch, ...r }, ...rest) => {
            afterSuccess({ dispatch, ...r }, ...rest);
            dispatch(reloadEvents({ entityId }));
          },
        },
      }),
      () => ({
        [CALL_API]: {
          method: 'get',
          path: `/api/v1/companies/${entityId}?include=events`,
          startType: LOADED_EVENT_LIST,
          successType: LOADED_EVENT_LIST_SUCCESS,
          errorType: LOADED_EVENT_LIST_FAILURE,
          afterSuccess,
        },
      }),
    ],
  };
}

/**
 * Load users by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findUsers({ filter, page, afterSuccess }) {
  return {
    filter,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
        page,
      },
      successType: LOADED_USERS,
      afterSuccess,
    },
  };
}

/**
 * Load directors by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findDirectors({ filter, page, afterSuccess }) {
  return {
    filter,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
        page,
      },
      successType: LOADED_DIRECTORS,
      afterSuccess,
    },
  };
}

/**
 * Load analysts by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findAnalysts({ filter, page, afterSuccess }) {
  return {
    filter,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
        page,
      },
      successType: LOADED_ANALYSTS,
      afterSuccess,
    },
  };
}

/**
 * Load dealmakers by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findDealMakers({ filter, page, afterSuccess }) {
  return {
    filter,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
        page,
      },
      successType: LOADED_DEALMAKERS,
      afterSuccess,
    },
  };
}

export function closeValidationError({ field }) {
  return {
    type: CLOSE_VALIDATION_ERROR,
    field,
  };
}
