import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CompanyBuyerMarketing from '../containers/Company/buyer/Marketing';
import CompanyBuyerLeads from '../containers/Company/buyer/Leads';
import CompanyBuyerCriteria from '../containers/Company/buyer/Criteria';
import CompanyBuyerAddOnProject from '../containers/Company/buyer/AddOnProject';
import CompanyBuyerProjects from '../containers/Company/buyer/Projects';
import CompanyBuyerFund from '../containers/Company/buyer/Fund';
import CompanyBuyerCeo from '../containers/Company/buyer/Ceo';
import CompanyBuyerRetainer from '../containers/Company/buyer/Retainer';
import AddOnProjectCustomContainer from '../containers/Company/buyer/AddOnProjectCustom';

export default function CompanyBuyerRouting() {
  return (
    <Switch>
      <Route component={CompanyBuyerMarketing} path="/company/:companyId/buyer/marketing" />
      <Route component={CompanyBuyerLeads} path="/company/:companyId/buyer/leads" />
      <Route component={CompanyBuyerCriteria} path="/company/:companyId/buyer/criteria" />
      <Route component={AddOnProjectCustomContainer} path="/company/:companyId/buyer/add-on-project/:id" exact />
      <Route component={CompanyBuyerAddOnProject} path="/company/:companyId/buyer/add-on-project/" exact />
      <Route component={CompanyBuyerProjects} path="/company/:companyId/buyer/projects" />
      <Route component={CompanyBuyerFund} path="/company/:companyId/buyer/fund" />
      <Route component={CompanyBuyerCeo} path="/company/:companyId/buyer/ceo" />
      <Route component={CompanyBuyerRetainer} path="/company/:companyId/buyer/retainer" />
    </Switch>
  );
}
