import format from 'string-template';

/**
 * A simple string template function based on named or indexed arguments.
 *
 * @param {string} string Source string.
 * @param {object} fields Fields for replace.
 * @returns {string}
 */
export default function formatString(string, fields) {
  const validString = string.replace(/<</g, '{').replace(/>>/g, '}');

  return format(validString, fields);
}
