import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import Popups from '../../components/Popup';

class ConfirmPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.onOk = this.onOk.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onOk() {
    const { popup } = this.props;
    const onOk = popup.getIn(['props', 'onOk'], () => {});

    onOk();
  }

  onCancel() {
    const { popup } = this.props;
    const onCancel = popup.getIn(['props', 'onCancel'], () => {});

    onCancel();
  }

  getMessage() {
    const { popup } = this.props;
    const message = popup.getIn(['props', 'message']);

    if (typeof message === 'function') return message();

    return message;
  }

  render() {
    const { children, popup } = this.props;
    const extra = popup.toJS().props;

    if (this.props.popup.getIn(['props', 'yes'])) extra.yes = this.props.popup.getIn(['props', 'yes']);
    if (this.props.popup.getIn(['props', 'no'])) extra.no = this.props.popup.getIn(['props', 'no']);

    return (
      <div>
        <Popups.ConfirmPopup {...extra} message={this.getMessage()} onCancel={this.onCancel} onOk={this.onOk} />
        {children}
      </div>
    );
  }
}

ConfirmPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    ...props,
  };
}

export { ConfirmPopupContainer };
export default connect(mapStateToProps, {}, mergeProps, connectOptions)(ConfirmPopupContainer);
