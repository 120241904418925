import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'immutable';

import Table, { Row, Column } from '../helpers/TableToAgGridTable';

const BacklogTable = props => {
  const {
    backlogs,
    loading,
    columnConfigs,
    height,
    currentPage,
    totalPages,
    onSort,
    onDoubleClick,
    onGetNextPageData,
    onGridReady,
  } = props;

  const columns = columnConfigs.map((column, index) => {
    if (column.get('field') === 'latestDate') {
      return (
        <Column
          key={index}
          field={column.get('field')}
          sort={column.get('sort')}
          title={column.get('title')}
          valueGetter={dateTransform}
        />
      );
    }

    return <Column key={index} field={column.get('field')} sort={column.get('sort')} title={column.get('title')} />;
  });

  const rows = backlogs.map((row, index) => <Row key={index} data={row} />);

  return (
    <div>
      <Table
        className="table-bordered table-hover"
        height={height}
        loading={loading}
        onGetNextPageData={onGetNextPageData}
        onGridReady={onGridReady}
        onRowDoubleClicked={onDoubleClick}
        onSortModelChanged={onSort}
        page={currentPage}
        totalPages={totalPages}
        sortable
      >
        {columns}
        {rows}
      </Table>
    </div>
  );
};

BacklogTable.propTypes = {
  backlogs: PropTypes.instanceOf(List).isRequired,
  columnConfigs: PropTypes.instanceOf(List).isRequired,
  currentPage: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onGetNextPageData: PropTypes.func.isRequired,
  onGridReady: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default BacklogTable;

function dateTransform({ data }) {
  return data.latestDate.isValid() ? data.latestDate.format('L') : '';
}
