import { fromJS } from 'immutable';

import * as ActionType from '../actions/applicationErrors';

const defaultState = fromJS({
  api: [],
  runtime: [],
});

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'DELETE_PERM_ERROR':
      return state.update('api', error => error.concat(["You don't have permission to delete this item."]));

    case ActionType.ADD_API_ERROR:
      return getApiErrorText(state, action);

    case ActionType.REMOVE_API_ERROR:
      return state.update('api', errors => errors.filter(error => error !== action.error));

    case ActionType.ADD_RUNTIME_ERROR:
      return state.update('runtime', errors => errors.concat(getRuntimeErrorText(action)));

    case ActionType.REMOVE_RUNTIME_ERROR:
      return state.update('runtime', errors => errors.filter(error => error !== action.error));

    default:
      return state;
  }
}

function getApiErrorText(state, action) {
  let text = 'Server is unavailable now. Please try again later on contact your administrator';

  if (action.response) {
    if (action.response.statusCode === 401) return state;
    if (process.env.NODE_ENV === 'production') {
      text = getProdApiMessage(action);
    } else {
      text = `> Status: ${action.response.statusCode}

> Message will shown on production:
===============================
${getProdApiMessage(action)}
===============================
> Request: ${JSON.stringify(action.requestParams, null, 2)}
-------------------------------
> Response body: ${JSON.stringify(action.resBody, null, 2)}`;
    }
  }

  return state.update('api', errors => errors.concat(text));
}

function getRuntimeErrorText(action) {
  if (process.env.NODE_ENV === 'production') {
    return getProdRuntimeMessage(action);
  }

  return `> Error type: ${action.error.constructor.name}
> Error message: ${action.error.message}

> Message will shown on production:
===============================
${getProdRuntimeMessage(action)}
===============================
> Stack trace:
${action.error.stack}`;
}

function getProdApiMessage(action) {
  switch (action.response.statusCode) {
    case 422:
      return "You can't do such action with this.";

    case 502:

    // fallthrough
    case 503:

    // fallthrough
    case 504:
      return 'Server is in process of restart. Please try again later.';

    case 500:

    // fallthrough
    case 501:
      return `Internal server error. Please try again later.
 Contact your administrator if error happens again.`;

    default:
      return tryToFetchErrorMessage(action, 'Unexpected error.');
  }
}

function getProdRuntimeMessage() {
  return 'Some error happened in application.';
}

function tryToFetchErrorMessage(action, defaultError) {
  const { errors } = action.resBody;
  const message = action.resBody.message || defaultError;

  if (errors) {
    return Object.keys(errors).reduce((carry, key) => {
      carry += `\n${errors[key].join('\n')}`;

      return carry;
    }, message);
  }

  return message;
}
