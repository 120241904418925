import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Map } from 'immutable';

import connectOptions, { mergeProps } from '../../utils/connectOptions';
import Popups from '../../components/Popup';
import { getIn } from '../../utils/ChangeSpy';
import {
  saveAddOnProject,
  changeField,
  revertUpdate,
  findUsers,
  findClients,
  findExecutive,
  getProjectsApprovalLists,
} from '../../actions/company/buyer/addOnProject/addOnProjectCreate';
import { getCompanyInfo } from '../../actions/companyDetail';
import AutoCompleteContainer from '../AutoComplete';

class AddAddonProjectPopupContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeApprovalListIds = this.handleChangeApprovalListIds.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleUserSuggestChange = this.handleUserSuggestChange.bind(this);
  }

  componentDidMount() {
    const { revertUpdate, changeField, getProjectsApprovalLists, popup } = this.props;
    const user = this.context.currentUser;
    const companyId = popup.getIn(['props', 'companyId']);

    revertUpdate();
    getProjectsApprovalLists({ companyId });

    changeField({
      name: 'harvcoLead',
      value: user.get('module') || '',
    });
    changeField({
      name: 'harvcoLeadId',
      value: user.get('moduleId') || null,
    });
  }

  handleChange(event) {
    const { name, value: val, checked, type } = event.target;
    const value = type === 'checkbox' ? checked : val;

    return this.props.changeField({ name, value });
  }

  handleChangeApprovalListIds(name, value) {
    this.props.changeField({ name, value });
  }

  handleSave() {
    const { history, addOnProjectCreate, popup, saveAddOnProject, getCompanyInfo } = this.props;

    this.setState({ loading: true });

    const companyId = popup.getIn(['props', 'companyId']);
    const clientLeadId = getIn(addOnProjectCreate, 'clientLeadId');
    const execId = getIn(addOnProjectCreate, 'execId');
    const harvcoLeadId = getIn(addOnProjectCreate, 'harvcoLeadId');

    const body = {
      buyerId: companyId,
      category: getIn(addOnProjectCreate, 'category'),
      details: getIn(addOnProjectCreate, 'details'),
      status: getIn(addOnProjectCreate, 'status'),
      active: getIn(addOnProjectCreate, 'active'),
      type: 'Add-on',
      approvalListIds: getIn(addOnProjectCreate, 'approvalListIds'),
      isDefaultAddOn: false,
    };

    if (clientLeadId !== '') {
      body.clientLeadId = clientLeadId;
    }

    if (execId !== '') {
      body.execId = execId;
    }

    if (harvcoLeadId !== '') {
      body.harvcoLeadId = harvcoLeadId;
    }

    saveAddOnProject({
      body,
      afterSuccess: ({ response }) => {
        this.setState({ loading: false });
        this.handleClose();
        getCompanyInfo({ companyId });
        setTimeout(() => {
          history.push(`/company/${companyId}/buyer/add-on-project/${response.id}`);
        });
      },
      afterError: () => {
        this.setState({ loading: false });
      },
    });
  }

  handleClose() {
    this.props.revertUpdate();
    this.context.closePopup();
  }

  handleUserSuggestChange({ name, value }) {
    return this.props.changeField({ name, value });
  }

  getUserSuggests() {
    return (
      <AutoCompleteContainer
        change={this.handleUserSuggestChange}
        find={opts => {
          this.props.findUsers({
            ...opts,
            field: 'harvcoLead',
          });
        }}
        idName="harvcoLeadId"
        inputProps={{
          name: 'harvcoLead',
          className: 'form-control',
          label: 'HarvCo Lead',
        }}
        name="harvcoLead"
        rootPath={['targetCompany', 'buyer', 'addOnProject', 'addOnProjectCreate']}
        suggestsName="suggestsUser"
        valueName="harvcoLead"
      />
    );
  }

  getClientSuggests() {
    const { findClients, popup } = this.props;
    const companyId = popup.getIn(['props', 'companyId']);

    return (
      <AutoCompleteContainer
        change={this.handleUserSuggestChange}
        find={opts => {
          findClients({
            ...opts,
            data: { companyId },
            field: 'clientLead',
          });
        }}
        idName="clientLeadId"
        inputProps={{
          name: 'clientLead',
          className: 'form-control',
          label: 'Client Lead',
        }}
        name="clientLead"
        rootPath={['targetCompany', 'buyer', 'addOnProject', 'addOnProjectCreate']}
        suggestsName="suggestsClient"
        valueName="clientLead"
      />
    );
  }

  getExecutiveSuggests() {
    return (
      <AutoCompleteContainer
        change={this.handleUserSuggestChange}
        find={opts => {
          this.props.findExecutive({
            ...opts,
            field: 'executive',
          });
        }}
        idName="execId"
        inputProps={{
          name: 'executive',
          className: 'form-control',
          label: 'Executive',
        }}
        name="executive"
        rootPath={['targetCompany', 'buyer', 'addOnProject', 'addOnProjectCreate']}
        suggestsName="suggestsExecutive"
        valueName="executive"
      />
    );
  }

  render() {
    const { isValid, addOnProjectCreate, approvalList, onErrorClose } = this.props;
    const { loading } = this.state;

    return (
      <Popups.AddAddonProjectPopup
        addOnProjectCreate={addOnProjectCreate}
        approvalList={approvalList}
        isValid={isValid}
        loading={loading}
        onChange={this.handleChange}
        onChangeApprovalListIds={this.handleChangeApprovalListIds}
        onClose={this.handleClose}
        onErrorClose={onErrorClose}
        onSave={this.handleSave}
        suggestClient={this.getClientSuggests()}
        suggestExecutive={this.getExecutiveSuggests()}
        suggestUser={this.getUserSuggests()}
      />
    );
  }
}

AddAddonProjectPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};

function mapStateToProps(state, props) {
  return {
    ...props,
    inputErrors: state.targetCompany.buyer.addOnProject.addOnProjectCreate.get('inputErrors', Map()),
    isValid: state.targetCompany.buyer.addOnProject.addOnProjectCreate.get('isValid', false),
    addOnProjectCreate: state.targetCompany.buyer.addOnProject.addOnProjectCreate,
    approvalList: state.targetCompany.buyer.addOnProject.approvalList,
  };
}

export { AddAddonProjectPopupContainer };

export default connect(
  mapStateToProps,
  {
    getCompanyInfo,
    getProjectsApprovalLists,
    saveAddOnProject,
    changeField,
    revertUpdate,
    findUsers,
    findClients,
    findExecutive,
  },
  mergeProps,
  connectOptions,
)(withRouter(AddAddonProjectPopupContainer));
