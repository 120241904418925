import React, { PureComponent } from 'react';
import classNames from 'classnames';

import { isWrapped, unwrap } from '../../../utils/ChangeSpy';

class TextEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);

    this.state = {
      value: unwrap(this.props.value),
      error: false,
    };
  }

  getValue() {
    if (this.state.error) {
      return this.props.value;
    }

    if (isWrapped(this.props.value)) {
      return Object.assign({}, this.props.value, { value: this.state.value });
    }

    return this.state.value;
  }

  onChange({ target: { value } }) {
    this.setState({ value, error: !this.props.column.colDef.isValid(value) });
  }

  isPopup() {
    return false;
  }

  afterGuiAttached() {
    this.editor.select();
  }

  render() {
    const { value, error } = this.state;
    const { onBlur } = this.props.column.colDef;
    const inputClassName = classNames('form-control input-sm ap-in-editor', {
      'ap-in-editor--error': error,
    });

    return (
      <span className="control-wrap">
        <input
          ref={input => {
            this.editor = input;
          }}
          className={inputClassName}
          onBlur={onBlur}
          onChange={this.onChange}
          placeholder={this.props.column.placeHolder}
          type="text"
          value={value}
        />
      </span>
    );
  }
}

export default TextEditor;
