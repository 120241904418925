import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Button from '../helpers/Button';
import _Input from '../helpers/Input';
import ErrorDisplay from '../decorators/DisplayError';
import PrettyError from '../decorators/PrettyError';
import Spinner from '../helpers/Spinner';

const Input = PrettyError(ErrorDisplay(_Input));

const EmailFinderPopup = props => {
  const { onChange, finder, onFind } = props;
  const isLoading = finder.get('loading');
  const email = finder.getIn(['data', 'email'], null);

  const footer = (
    <Button className="btn-primary btn-xs" disabled={isLoading} onClick={onFind}>
      Find
    </Button>
  );

  return (
    <Popup footer={footer} header="Email Finder" id="findEmail">
      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-md"
            label="Full Name"
            name="fullName"
            onChange={onChange}
            placeholder="John Doe"
            root={finder}
            autoFocus
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-md"
            label="Company Domain Name"
            name="domain"
            onChange={onChange}
            placeholder="company.com"
            root={finder}
            showLabel
          />
        </FormGroup>
      </div>
      {isLoading && <Spinner />}
      {email && !isLoading && (
        <div className="row mt20">
          <div className="col-lg-6 col-sm-6">
            <strong>
              {email} <i className="text-success fa fa-check" />
            </strong>
          </div>
          <div className="col-lg-6 col-sm-6 text-right">
            <Button className="btn-xs">
              <CopyToClipboard text={email}>
                <div>
                  <strong>
                    <i className="fa fa-clipboard" />
                  </strong>
                  Copy to Clipboard
                </div>
              </CopyToClipboard>
            </Button>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default EmailFinderPopup;
