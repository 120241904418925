import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Popups from '../../components/Popup';

class DescriptionChangePopupContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.context.closePopup();
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        <Popups.DescriptionChangePopup onClick={this.handleClick} />
        {children}
      </div>
    );
  }
}

DescriptionChangePopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
};

export default DescriptionChangePopupContainer;
