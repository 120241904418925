import PropTypes from 'prop-types';
import React from 'react';
import { Map, List } from 'immutable';

import ContainerPanel from './ContainerPanel';
import TopHeader from './TopHeader';
import SettingPanel from './SettingPanel';
import FootWithExport from '../FootWithExport';
import Grid from './Grid';

/**
 * Stateless component for next actionList.
 *
 * @param props {Object}.
 * @param props.userName {Immutable.Map} User name.
 * @param props.actions {Immutable.List} List of next action items.
 * @param props.settings {Immutable.Map} User's filering and sorting settings information.
 * @param props.common {Immutable.Map} Common information.
 * @param props.selected {Immutable.List} List of selected next action items.
 * @param props.disabled {Boolean} If true, disabled top drop down menu.
 * @param props.exportingDisabled {Boolean} If true, disable exporting button.
 * @param props.actionConfigs {Immutable.List} Actions of available actions.
 * @param props.setGridReference {Function} To hold grid ref.
 * @param props.sorts {Immutable.Map} List of sorting items.
 * @param props.typeFilters {Immutable.Map} Approach type filters data.
 * @param props.mailFilters {Immutable.Map} Mailing filters data.
 * @param props.activityFilters {Immutable.Map} Activity filters data.
 * @param props.userFilters {Immutable.Map} User type filters data.
 * @param props.onChangeSort {Function} To handle changing sorting field.
 * @param props.onChangeOrder {Function} To handle changing sorting order.
 * @param props.onSelectAll {Function} To select all next actions.
 * @param props.onDeselectAll {Function} To deselect all next actions.
 * @param props.onRowSelected {Function} Handle row selection.
 * @param props.onExportClick {Function} Handle exporting action actions to excel.
 * @param props.onMenuSelect {Function} Handle context menu item's click event.
 * @param props.onFilterChange {Function} To handle changing filtering field.
 * @param props.onBLClick {Function} To handle setting backlog.
 * @returns {React.Component}
 */
const Action = props => {
  const {
    userName,
    actions,
    common,
    selected,
    disabled,
    exportingDisabled,
    actionConfigs,
    setGridReference,
    sorts,
    typeFilters,
    mailFilters,
    activityFilters,
    userFilters,
    onSelectAll,
    onDeselectAll,
    onChangeSort,
    onChangeOrder,
    onRowSelected,
    onExportClick,
    onMenuSelect,
    onFilterChange,
    onBLClick,
  } = props;
  const isFetchingAction = common.get('loadingAction');
  const isFetchingSetting = common.get('loadingSetting');
  const total = common.get('total');

  return (
    <div>
      <ContainerPanel>
        <TopHeader userName={userName} />
        <div className="row flex-stretched flex-wrap-md">
          <Grid
            actionConfigs={actionConfigs}
            disabled={disabled}
            isFetching={isFetchingAction}
            list={actions}
            onBLClick={onBLClick}
            onDeselectAll={onDeselectAll}
            onMenuSelect={onMenuSelect}
            onRowSelected={onRowSelected}
            onSelectAll={onSelectAll}
            selected={selected}
            setGridReference={setGridReference}
          />
          <SettingPanel
            activityFilters={activityFilters}
            isFetching={isFetchingSetting}
            mailFilters={mailFilters}
            onChangeOrder={onChangeOrder}
            onChangeSort={onChangeSort}
            onFilterChange={onFilterChange}
            showing={actions.size}
            sorts={sorts}
            total={total}
            typeFilters={typeFilters}
            userFilters={userFilters}
          />
        </div>
      </ContainerPanel>
      <FootWithExport disabled={exportingDisabled} onClick={onExportClick} />
    </div>
  );
};

Action.propTypes = {
  actionConfigs: PropTypes.instanceOf(List).isRequired,
  actions: PropTypes.instanceOf(List).isRequired,
  activityFilters: PropTypes.instanceOf(Map),
  common: PropTypes.instanceOf(Map).isRequired,
  disabled: PropTypes.bool.isRequired,
  exportingDisabled: PropTypes.bool.isRequired,
  mailFilters: PropTypes.instanceOf(Map),
  onBLClick: PropTypes.func.isRequired,
  onChangeOrder: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onDeselectAll: PropTypes.func.isRequired,
  onExportClick: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onMenuSelect: PropTypes.func.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  selected: PropTypes.instanceOf(List).isRequired,
  setGridReference: PropTypes.func.isRequired,
  sorts: PropTypes.instanceOf(List),
  typeFilters: PropTypes.instanceOf(Map),
  userFilters: PropTypes.instanceOf(Map),
  userName: PropTypes.string.isRequired,
};

export default Action;
