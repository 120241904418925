import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import Immutable from 'immutable';
import { ContextMenuTrigger } from 'react-contextmenu';
import classNames from 'classnames';

import Input, { InlineInput } from '../../helpers/Input';
import config from '../../../config';
import Spinner from '../../helpers/Spinner';
import doubleClickCheck from '../../../utils/doubleClickCheck';
import { unwrap } from '../../../utils/ChangeSpy';
import getPercent from '../../../utils/getPercent';

const APPROVALS_STATS_SUM_COLUMNS = [
  'numberTargets',
  'numberOfContacted',
  'numberOfLeads',
  'numberOfCc',
  'numberOfVisit',
  'numberOfNextActions',
  'numberOfPriorityA',
  'numberOfPriorityB',
  'numberOfPriorityC',
  'numberOfApprovedX',
];
const TOTAL_APPROVALS_RETURNED = [
  'numberOfContacted',
  'numberOfLeads',
  'numberOfCc',
  'numberOfVisit',
  'numberOfNextActions',
  'numberOfPriorityA',
  'numberOfPriorityB',
  'numberOfPriorityC',
  'numberOfApprovedX',
];

/**
 * Return sum by field.
 *
 * @param {Immutable.List} approvals Immutable List of approvals.
 * @param {string} field Field name.
 */
const getSum = (approvals, field) =>
  approvals.reduce((sum, approval) => {
    const approvalField = approval.get(field) || 0;

    return (sum += approvalField);
  }, 0);

/**
 * Approval list component.
 *
 * @param props {Object}.
 * @returns {React.Component}
 * @class
 */
const Approvals = props => {
  const {
    approvals,
    noApprovals,
    common,
    contextId,
    approvalColumns,
    onInsert,
    onChange,
    onClick,
    onDoubleClick,
    onSort,
    onNoApprovalDoubleClick,
  } = props;

  const spinner = common.get('approvalLoading') ? <Spinner /> : null;

  const noData = () => {
    if (approvals.size === 0 && noApprovals.size === 0) {
      return <div className="table-message">No items to display</div>;
    }

    return null;
  };

  return (
    <div className="col-md-12">
      <div className="table-edit table-responsive">
        <table className="table table-striped table-condensed sort with-stats">
          <Columns columns={approvalColumns} common={common} onClick={onSort} onInsert={onInsert} />
          <Rows
            approvals={approvals}
            columns={approvalColumns}
            contextId={contextId}
            noApprovals={noApprovals}
            onChange={onChange}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            onNoApprovalDoubleClick={onNoApprovalDoubleClick}
          />
          {approvals.size > 0 && <StatsRow approvals={approvals} columns={approvalColumns} noApprovals={noApprovals} />}
        </table>
        {spinner}
        {noData()}
      </div>
    </div>
  );
};

Approvals.propTypes = {
  approvalColumns: PropTypes.instanceOf(Immutable.List).isRequired,
  approvals: PropTypes.instanceOf(Immutable.List).isRequired,
  common: PropTypes.instanceOf(Immutable.Map).isRequired,
  noApprovals: PropTypes.instanceOf(Immutable.List).isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onInsert: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default Approvals;

class StatsRow extends PureComponent {
  render() {
    const { approvals, noApprovals, columns } = this.props;
    const approvalsReturned = approvals.filter(approval => approval.getIn(['dateReceived', 'value'], 0) !== null);
    const sumReturnedTargets = getSum(approvalsReturned, 'numberTargets');
    const sumApproved = getSum(approvalsReturned, 'numberApproved');

    const mergedApprovalsWithNoApprovals = approvals.concat(noApprovals);

    const cellsReturned = columns.map((col, i) => {
      // it needs to prevent render unnecessary td
      if (i === 2) return null;

      if (col.get('field') === 'applistLabel') {
        return (
          <td key={col.get('field')} colSpan="2">
            Total (Returned Approval Lists):
          </td>
        );
      }

      if (col.get('field') === 'numberApproved') {
        const sum = getSum(approvalsReturned, col.get('field'));

        return (
          <td key={col.get('field')} className="text-right">
            {sum} ({getPercent(sum, sumReturnedTargets)}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfContacted') {
        const sum = getSum(approvalsReturned, col.get('field'));
        const percent = getPercent(sum, sumApproved);

        return (
          <td key={col.get('field')} className="text-right">
            {sum} ({percent}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfLeads') {
        const sum = getSum(approvalsReturned, col.get('field'));
        const percent = getPercent(sum, sumApproved);

        return (
          <td key={col.get('field')} className="text-right">
            {sum} ({percent}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfCc') {
        const sum = getSum(approvalsReturned, col.get('field'));
        const percent = getPercent(sum, sumApproved);

        return (
          <td key={col.get('field')} className="text-right">
            {sum} ({percent}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfVisit') {
        const sum = getSum(approvalsReturned, col.get('field'));
        const percent = getPercent(sum, sumApproved);

        return (
          <td key={col.get('field')} className="text-right">
            {sum} ({percent}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfNextActions') {
        const sum = getSum(approvalsReturned, col.get('field'));
        const percent = getPercent(sum, getSum(approvalsReturned, 'numberTargets'));

        return (
          <td key={col.get('field')} className="text-right">
            {sum} ({percent}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfPriorityA') {
        const sum = getSum(approvalsReturned, col.get('field'));
        const percent = getPercent(sum, getSum(approvalsReturned, 'numberTargets'));

        return (
          <td key={col.get('field')} className="text-center">
            {sum} ({percent}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfPriorityB') {
        const sum = getSum(approvalsReturned, col.get('field'));
        const percent = getPercent(sum, getSum(approvalsReturned, 'numberTargets'));

        return (
          <td key={col.get('field')} className="text-center">
            {sum} ({percent}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfPriorityC') {
        const sum = getSum(approvalsReturned, col.get('field'));
        const percent = getPercent(sum, getSum(approvalsReturned, 'numberTargets'));

        return (
          <td key={col.get('field')} className="text-center">
            {sum} ({percent}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfApprovedX') {
        const sum = getSum(approvalsReturned, col.get('field'));
        const percent = getPercent(sum, getSum(approvalsReturned, 'numberTargets'));

        return (
          <td key={col.get('field')} className="text-center">
            {sum} ({percent}%)
          </td>
        );
      }

      if (APPROVALS_STATS_SUM_COLUMNS.includes(col.get('field'))) {
        const sum = getSum(approvalsReturned, col.get('field'));

        return <td key={col.get('field')}>{sum}</td>;
      }

      return <td key={col.get('field')} />;
    });

    const cellsTotal = columns.map((col, i) => {
      // it needs to prevent render unnecessary td
      if (i === 2) return null;

      if (col.get('field') === 'applistLabel') {
        return (
          <td key={col.get('field')} colSpan="2">
            Total (All):
          </td>
        );
      }

      if (col.get('field') === 'numberApproved') {
        const sum = getSum(mergedApprovalsWithNoApprovals, col.get('field'));
        const percent = getPercent(sum, getSum(mergedApprovalsWithNoApprovals, 'numberTargets'));

        return (
          <td key={col.get('field')} className="text-right">
            {sum} ({percent}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfContacted') {
        const sum = getSum(mergedApprovalsWithNoApprovals, col.get('field'));
        const percent = getPercent(sum, getSum(mergedApprovalsWithNoApprovals, 'numberApproved'));

        return (
          <td key={col.get('field')} className="text-right">
            {sum} ({percent}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfLeads') {
        const sum = getSum(mergedApprovalsWithNoApprovals, col.get('field'));
        const percent = getPercent(sum, getSum(mergedApprovalsWithNoApprovals, 'numberApproved'));

        return (
          <td key={col.get('field')} className="text-right">
            {sum} ({percent}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfCc') {
        const sum = getSum(mergedApprovalsWithNoApprovals, col.get('field'));
        const percent = getPercent(sum, getSum(mergedApprovalsWithNoApprovals, 'numberApproved'));

        return (
          <td key={col.get('field')} className="text-right">
            {sum} ({percent}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfVisit') {
        const sum = getSum(mergedApprovalsWithNoApprovals, col.get('field'));
        const percent = getPercent(sum, getSum(mergedApprovalsWithNoApprovals, 'numberApproved'));

        return (
          <td key={col.get('field')} className="text-right">
            {sum} ({percent}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfNextActions') {
        const sum = getSum(mergedApprovalsWithNoApprovals, col.get('field'));
        const percent = getPercent(sum, getSum(mergedApprovalsWithNoApprovals, 'numberTargets'));

        return (
          <td key={col.get('field')} className="text-right">
            {sum} ({percent}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfPriorityA') {
        const sum = getSum(mergedApprovalsWithNoApprovals, col.get('field'));
        const percent = getPercent(sum, getSum(mergedApprovalsWithNoApprovals, 'numberTargets'));

        return (
          <td key={col.get('field')} className="text-center">
            {sum} ({percent}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfPriorityB') {
        const sum = getSum(mergedApprovalsWithNoApprovals, col.get('field'));
        const percent = getPercent(sum, getSum(mergedApprovalsWithNoApprovals, 'numberTargets'));

        return (
          <td key={col.get('field')} className="text-center">
            {sum} ({percent}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfPriorityC') {
        const sum = getSum(mergedApprovalsWithNoApprovals, col.get('field'));
        const percent = getPercent(sum, getSum(mergedApprovalsWithNoApprovals, 'numberTargets'));

        return (
          <td key={col.get('field')} className="text-center">
            {sum} ({percent}%)
          </td>
        );
      }

      if (col.get('field') === 'numberOfApprovedX') {
        const sum = getSum(mergedApprovalsWithNoApprovals, col.get('field'));
        const percent = getPercent(sum, getSum(mergedApprovalsWithNoApprovals, 'numberTargets'));

        return (
          <td key={col.get('field')} className="text-center">
            {sum} ({percent}%)
          </td>
        );
      }

      if (APPROVALS_STATS_SUM_COLUMNS.includes(col.get('field'))) {
        const sum = getSum(mergedApprovalsWithNoApprovals, col.get('field'));

        return <td key={col.get('field')}>{sum}</td>;
      }

      return <td key={col.get('field')} />;
    });

    return (
      <tfoot>
        <tr className="stats-row font-bold">{cellsReturned}</tr>
        <tr className="stats-row">{cellsTotal}</tr>
      </tfoot>
    );
  }
}

const getThTitle = field => {
  switch (field) {
    case 'numberApproved':
      return 'Approved% = #Approved / #Targets';

    case 'numberOfContacted':
      return 'Contacted% = #Contacted / #Approved';

    case 'numberOfLeads':
      return 'Leads% = #Leads / #Approved';

    case 'numberOfCc':
      return 'CC% = #CC / #Approved';

    case 'numberOfVisit':
      return 'Visit% = #Visit / #Approved';

    case 'numberOfNextActions':
      return 'NAs% = #NAs / #Targets';

    case 'numberOfPriorityA':
      return 'A% = #A / #Targets';

    case 'numberOfPriorityB':
      return 'B% = #B / #Targets';

    case 'numberOfPriorityC':
      return 'C% = #C / #Targets';

    case 'numberOfApprovedX':
      return 'X% = #X / #Targets';

    default:
      return '';
  }
};

/**
 * Table columns component.
 *
 * @param props {Object}.
 * @param props.common {Immutable.Map} Common information.
 * @param props.columns {Immutable.List} List of column configuration.
 * @param props.onClick {Function} Function to handle event of clicking column head.
 * @param props.onInsert {Function} Function to handle event of adding a new row.
 * @returns {React.Component}
 * @class
 */
const Columns = props => {
  const { common, columns, onClick, onInsert } = props;
  const sortDirection = common.get('approvalSortDirection');
  const sortField = common.get('approvalSortField');
  const type = config.values.getIn(['project', 'types', 'APPROVALS']);

  return (
    <thead>
      <tr>
        {columns.map((column, index) => {
          const field = column.get('field');
          const headerName = column.get('headerName');

          if (field === 'applistLabel') {
            return (
              <th key={index}>
                <span
                  className="add-row"
                  onClick={event => {
                    event.stopPropagation();
                    onInsert(type);
                  }}
                  title="Add row"
                >
                  +
                </span>
                <a
                  className="text-regular"
                  onClick={event =>
                    onClick(event, {
                      direction: sortDirection === 'up' ? 'down' : 'up',
                      field,
                      type,
                    })
                  }
                >
                  {headerName} {field === sortField ? <i className={`fa fa-caret-${sortDirection}`} /> : null}
                </a>
              </th>
            );
          }

          return (
            <th key={index} title={getThTitle(field)}>
              <a
                className="text-regular"
                onClick={event =>
                  onClick(event, {
                    direction: sortDirection === 'up' ? 'down' : 'up',
                    field,
                    type,
                  })
                }
              >
                {headerName} {field === sortField ? <i className={`fa fa-caret-${sortDirection}`} /> : null}
              </a>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

Columns.propTypes = {
  columns: PropTypes.instanceOf(Immutable.List).isRequired,
  common: PropTypes.instanceOf(Immutable.Map).isRequired,
  onClick: PropTypes.func.isRequired,
  onInsert: PropTypes.func.isRequired,
};

/**
 * Table rows component.
 *
 * @param props {Object}.
 * @param props.approvals {Immutable.Map} Approval list information.
 * @param props.onChange {Function} Function to handle changing row information.
 * @param props.onClick {Function} Function to handle event of clicking on row.
 * @param props.onDoubleClick {Function} Function to handle event of double clicking on row.
 * @returns {React.Component}
 * @class
 */
const Rows = props => {
  const {
    approvals,
    onChange,
    onClick,
    onDoubleClick,
    contextId,
    noApprovals,
    columns,
    onNoApprovalDoubleClick,
  } = props;
  const type = config.values.getIn(['project', 'types', 'APPROVALS']);

  const sumNoApprovalReturnedTargets = getSum(noApprovals, 'numberTargets');
  const sumNoApproved = getSum(noApprovals, 'numberApproved');

  const cellsNoApproval = columns.map((col, i) => {
    // it needs to prevent render unnecessary td
    if (i === 2) return null;

    if (col.get('field') === 'applistLabel') {
      return (
        <td key={col.get('field')} colSpan="2">
          (No Approval List)
        </td>
      );
    }

    if (col.get('field') === 'numberTargets') {
      return (
        <td key={col.get('field')} className="text-right">
          {sumNoApprovalReturnedTargets}
        </td>
      );
    }

    if (col.get('field') === 'numberApproved') {
      const sum = getSum(noApprovals, col.get('field'));

      return (
        <td key={col.get('field')} className="text-right">
          {sum} ({getPercent(sum, sumNoApprovalReturnedTargets)}%)
        </td>
      );
    }

    if (TOTAL_APPROVALS_RETURNED.includes(col.get('field'))) {
      const sum = getSum(noApprovals, col.get('field'));
      const centered = ['numberOfPriorityA', 'numberOfPriorityB', 'numberOfPriorityC', 'numberOfApprovedX'];
      const cls = centered.includes(col.get('field')) ? 'text-center' : 'text-right';

      return (
        <td key={col.get('field')} className={cls}>
          {sum} ({getPercent(sum, sumNoApproved)}%)
        </td>
      );
    }

    if (APPROVALS_STATS_SUM_COLUMNS.includes(col.get('field'))) {
      return <td key={col.get('field')}>{getSum(noApprovals, col.get('field'))}</td>;
    }

    return <td key={col.get('field')} />;
  });

  const renderCellsNoApprovals = noApprovals.size > 0 && (
    <tr className="no-approval-list__row" onDoubleClick={onNoApprovalDoubleClick}>
      {cellsNoApproval}
    </tr>
  );

  return (
    <tbody>
      {approvals.map((ap, index) => {
        if (ap.get('mode') === config.EDIT_MODE) {
          return <Edit key={index} onChange={onChange} row={ap} />;
        }

        return (
          <View
            key={index}
            contextId={contextId}
            onClick={onClick}
            onCollect={() => ({ type, approval: ap })}
            onDoubleClick={onDoubleClick}
            row={ap}
          />
        );
      })}
      {renderCellsNoApprovals}
    </tbody>
  );
};

Rows.propTypes = {
  approvals: PropTypes.instanceOf(Immutable.List).isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
};

/**
 * Row in view mode component.
 *
 * @param props {Object}.
 * @param props.row {Immutable.Map} Row information.
 * @param props.onClick {Function} Function to handle event of clicking on row.
 * @param props.onDoubleClick {Function} Function to handle event of double clicking on row.
 * @returns {React.Component}
 * @class
 */
const View = props => {
  const { row, onClick, onDoubleClick, contextId, onCollect } = props;
  const className = classNames('cell', { 'cell--marked': row.get('dirty') });
  const dateSentMoment = unwrap(row.get('dateSent'));
  const dateSent = dateSentMoment && dateSentMoment.isValid() ? dateSentMoment.format('L') : '';
  const dateReceivedMoment = unwrap(row.get('dateReceived'));
  const dateReceived = dateReceivedMoment && dateReceivedMoment.isValid() ? dateReceivedMoment.format('L') : '';
  const rowType = config.values.getIn(['project', 'types', 'APPROVALS']);
  const percentApproved = `${row.get('percentApproved')}%`;
  const numberTargets = row.get('numberTargets');
  const numberApproved = row.get('numberApproved');
  const numberOfContacted = row.get('numberOfContacted');
  const numberOfLeads = row.get('numberOfLeads');
  const numberOfCc = row.get('numberOfCc');
  const numberOfVisit = row.get('numberOfVisit');
  const numberOfNextActions = row.get('numberOfNextActions');
  const numberOfPriorityA = row.get('numberOfPriorityA');
  const numberOfPriorityB = row.get('numberOfPriorityB');
  const numberOfPriorityC = row.get('numberOfPriorityC');
  const numberOfApprovedX = row.get('numberOfApprovedX');
  const applistLabel = unwrap(row.get('applistLabel'));

  const onRowClick = event => {
    event.stopPropagation();
    doubleClickCheck({ row, type: rowType }, ({ eventType, data }) => {
      if (eventType === 'click') {
        onClick(data);
      } else {
        onDoubleClick(data);
      }
    });
  };

  const Tr = ({ children, ...rest }) => (
    <tr {...rest} onClick={onRowClick}>
      {children}
    </tr>
  );

  return (
    <ContextMenuTrigger collect={onCollect} id={contextId} renderTag={Tr}>
      <td className={className}>
        <div>{applistLabel}</div>
      </td>
      <td className={className}>
        <div>{dateSent}</div>
      </td>
      <td className={className}>
        <div>{dateReceived}</div>
      </td>
      <td className={className}>
        <div>{numberTargets}</div>
      </td>
      <td className={className}>
        <div>
          {numberApproved} ({percentApproved})
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfContacted} ({getPercent(numberOfContacted, numberApproved)}%)
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfLeads} ({getPercent(numberOfLeads, numberApproved)}%)
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfCc} ({getPercent(numberOfCc, numberApproved)}%)
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfVisit} ({getPercent(numberOfVisit, numberApproved)}%)
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfNextActions} ({getPercent(numberOfNextActions, numberTargets)}%)
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfPriorityA} ({getPercent(numberOfPriorityA, numberTargets)}%)
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfPriorityB} ({getPercent(numberOfPriorityB, numberTargets)}%)
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfPriorityC} ({getPercent(numberOfPriorityC, numberTargets)}%)
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfApprovedX} ({getPercent(numberOfApprovedX, numberTargets)}%)
        </div>
      </td>
    </ContextMenuTrigger>
  );
};

View.propTypes = {
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  row: PropTypes.instanceOf(Immutable.Map).isRequired,
};

/**
 * Row in edit mode component.
 *
 * @param props {Object}.
 * @param props.row {Immutable.Map} Row information.
 * @param props.onChange {Function} Function to handle changing row information.
 * @returns {React.Component}
 * @class
 */
const Edit = props => {
  const { row, onChange } = props;
  const type = config.values.getIn(['project', 'types', 'APPROVALS']);
  const numberOfContacted = row.get('numberOfContacted');
  const numberOfLeads = row.get('numberOfLeads');
  const numberOfCc = row.get('numberOfCc');
  const numberOfVisit = row.get('numberOfVisit');
  const numberOfNextActions = row.get('numberOfNextActions');
  const numberTargets = row.get('numberTargets');
  const numberOfPriorityA = row.get('numberOfPriorityA');
  const numberOfPriorityB = row.get('numberOfPriorityB');
  const numberOfPriorityC = row.get('numberOfPriorityC');
  const numberOfApprovedX = row.get('numberOfApprovedX');

  return (
    <tr
      onClick={event => {
        event.stopPropagation();
      }}
    >
      <td className="cell">
        <div>
          <Input
            label="Approval List"
            name="applistLabel"
            onChange={event => onChange({ event, data: { row, type } })}
            placeholder="approval list"
            value={unwrap(row.get('applistLabel'))}
            autoFocus
          />
        </div>
      </td>
      <td className="cell">
        <div>
          <InlineInput
            name="dateSent"
            onChange={event => onChange({ event, data: { row, type } })}
            placeholder="date"
            type="date"
            value={unwrap(row.get('dateSent'))}
          />
        </div>
      </td>
      <td className="cell">
        <div>
          <InlineInput
            name="dateReceived"
            onChange={event => onChange({ event, data: { row, type } })}
            placeholder="date"
            type="date"
            value={unwrap(row.get('dateReceived'))}
          />
        </div>
      </td>
      <td className="cell">
        <div>{numberTargets}</div>
      </td>
      <td className="cell">
        <div>
          {row.get('numberApproved')} ({row.get('percentApproved')}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfContacted} ({getPercent(numberOfContacted, numberTargets)}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfLeads} ({getPercent(numberOfLeads, numberTargets)}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfCc} ({getPercent(numberOfCc, numberTargets)}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfVisit} ({getPercent(numberOfVisit, numberTargets)}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfNextActions} ({getPercent(numberOfNextActions, numberTargets)}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfPriorityA} ({getPercent(numberOfPriorityA, numberTargets)}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfPriorityB} ({getPercent(numberOfPriorityB, numberTargets)}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfPriorityC} ({getPercent(numberOfPriorityC, numberTargets)}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfApprovedX} ({getPercent(numberOfApprovedX, numberTargets)}%)
        </div>
      </td>
    </tr>
  );
};

Edit.propTypes = {
  onChange: PropTypes.func.isRequired,
  row: PropTypes.instanceOf(Immutable.Map).isRequired,
};
