import config from '../config';
import { unwrap } from './ChangeSpy';

/**
 * Get email body.
 *
 * @param {Map} to Email recipient.
 * @param {string} from Email sender.
 * @param {string} body Email body.
 * @returns {string} Email body.
 */
export default function getEmailBody(to, from, body) {
  const props = { to, from, body };
  const emailTemplate = config.values.getIn(['company', 'emailTemplate'], '');

  return emailTemplate.replace(/({[^\s\d]+})/g, str => unwrap(props[str.slice(1, -1)]) || '');
}
