import React, { useState } from 'react';
import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import Select from '../helpers/Select';

const ClientSummaryReportPopup = props => {
  const [activeOnly, setActiveOnly] = useState(null);
  const {
    onGenerate,
    header = 'Confirm Action',
    loading = false,
    statuses,
    onHighestStatusChange,
    onLowestStatusChange,
    currentHighestStatus,
    currentLowestStatus,
  } = props;
  const spinner = loading ? <i className="fa fa-spinner fa-pulse fa-fw button" /> : null;
  const footer = [
    <Button
      key="ok"
      className="btn-primary btn-xs"
      data-dismiss="modal"
      disabled={activeOnly === null}
      onClick={() => onGenerate(activeOnly)}
      autoFocus
    >
      {spinner} Generate
    </Button>,
  ];

  const onRadioChange = event => {
    const { id } = event.target;

    if (id === 'activeOnly') {
      setActiveOnly(true);
    } else {
      setActiveOnly(false);
    }
  };

  return (
    <Popup footer={footer} header={header} id="clientSummaryReport">
      <label>You are about to generate a Client Summary Report</label>
      <div className="report-select">
        <div>
          <input id="activeOnly" name="report" onChange={onRadioChange} type="radio" />
          <label htmlFor="activeOnly">Active Projects Only</label>
        </div>
        <div>
          <input id="all" name="report" onChange={onRadioChange} type="radio" />
          <label htmlFor="all">Active & Inactive Projects</label>
        </div>
      </div>
      <div className="d-flex">
        <div className="col-md-3">Targets lowest status</div>
        <Select
          label="Target status"
          name="targetStatuses"
          onChange={onLowestStatusChange}
          options={statuses}
          selectClassName="form-control"
          value={currentLowestStatus}
          showValue
        />
      </div>
      <div className="d-flex mt10">
        <div className="col-md-4">Targets highest status</div>
        <Select
          label="Target status"
          name="targetStatuses"
          onChange={onHighestStatusChange}
          options={statuses}
          selectClassName="form-control"
          value={currentHighestStatus}
          showValue
        />
      </div>
    </Popup>
  );
};

export default ClientSummaryReportPopup;
