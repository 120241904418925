import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Popups from '../../components/Popup';

class ErrorPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.context.closePopup();
  }

  render() {
    return (
      <Popups.ErrorPopup
        error={this.props.popup.getIn(['props', 'error'])}
        formError={this.props.popup.getIn(['props', 'formError'])}
        onClose={this.onClose}
      />
    );
  }
}

ErrorPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
};

ErrorPopupContainer.propTypes = {
  error: PropTypes.node,
};

export default ErrorPopupContainer;
