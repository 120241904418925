import { CALL_API } from '../../../middleware/api';
import { convertMoneyToNumber } from '../../../utils/string';
import { dateToString } from '../../../utils/dateFormat';

export const LOADED_COMPANY_RETAINER = Symbol('LOADED_COMPANY_RETAINER');
export const LOADING_RETAINER = Symbol('LOADING_RETAINER');
export const UPDATE_COMPANY = Symbol('UPDATE_COMPANY');
export const REVERT_UPDATE = Symbol('REVERT_UPDATE');
export const LOADED_USERS = Symbol('LOADED_USERS');
export const SAVED_COMPANY_BUYER = Symbol('SAVED_COMPANY_BUYER');
export const ERRORED_COMPANY_BUYER = Symbol('ERRORED_COMPANY_BUYER');
export const CLOSE_COMPANY_BUYER_VALIDATION_ERROR = Symbol('CLOSE_COMPANY_BUYER_VALIDATION_ERROR');
export const ADD_COMPANY_RETAINER = Symbol('ADD_COMPANY_RETAINER');
export const DELETE_COMPANY_RETAINER = Symbol('DELETE_COMPANY_RETAINER');
export const FIND_TARGETS = Symbol('FIND_TARGETS');

export function handleUpdateCompany(info) {
  return {
    type: UPDATE_COMPANY,
    ...info,
  };
}

export function getRetainer({ companyId }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies/${companyId}/`,
      startType: LOADING_RETAINER,
      successType: LOADED_COMPANY_RETAINER,
    },
  };
}

export function saveInfo({ companyId, body }) {
  return {
    fields: Object.keys(body),
    [CALL_API]: {
      method: 'put',
      unifier: `buyer put /api/v1/companies/${companyId} {${JSON.stringify(body)}}`,
      path: `/api/v1/companies/${companyId}`,
      body: {
        ...body,
        retainer: body.retainer ? convertMoneyToNumber(body.retainer) : body.retainer,
        start_date: body.startDate === '' ? null : dateToString(body.startDate),
        end_date: body.endDate === '' ? null : dateToString(body.endDate),
        notification_cancellation_date:
          body.notificationCancellationDate === '' ? null : dateToString(body.notificationCancellationDate),
        notification_start_date: body.notificationStartDate === '' ? null : dateToString(body.notificationStartDate),
      },
      successType: SAVED_COMPANY_BUYER,
      errorType: ERRORED_COMPANY_BUYER,
      skipGlobalErrorHandler: true,
    },
  };
}

export function updateCompanyRetainers({ companyId, body, afterSuccess }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/companies/${companyId}/bulk/buyer_retainers`,
      body: { data: body },
      afterSuccess,
    },
  };
}

export function closeValidationError({ field }) {
  return {
    type: CLOSE_COMPANY_BUYER_VALIDATION_ERROR,
    field,
  };
}

export function revertUpdate({ field }) {
  return {
    type: REVERT_UPDATE,
    field,
  };
}

export function updateRetainer({ companyId, value }) {
  return function(dispatch) {
    dispatch({
      [CALL_API]: {
        method: 'put',
        unifier: `buyers retainer put /api/v1/companies/${companyId}`,
        path: `/api/v1/companies/${companyId}`,
        body: {
          start_date: value && value.isValid() ? value.format('YYYY-MM-DD') : null,
        },
        afterError: ({ dispatch }) => {
          dispatch(getRetainer({ companyId }));
        },
        skipGlobalErrorHandler: true,
      },
    });
  };
}

export function addCompanyRetainer(retainer) {
  return {
    type: ADD_COMPANY_RETAINER,
    retainer,
  };
}

export function removeCompanyRetainer(id) {
  return {
    type: DELETE_COMPANY_RETAINER,
    id,
  };
}

/**
 * Get targets list by search value.
 *
 * @param {string} filter Search value.
 * @param {number} page Number page.
 * @param {Function} afterSuccess After success callback function.
 */
export function findTargets({ filter, page, afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/companies',
      query: {
        like: filter,
        type: 'target',
        limit: 100,
        page,
      },
      successType: FIND_TARGETS,
      afterSuccess,
    },
  };
}
