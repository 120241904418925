import PropTypes from 'prop-types';

import React from 'react';
import { List, Map } from 'immutable';

import WysiwygView from '../../helpers/WysiwygView';

const BuyerLabel = () => null;

// const name = note.get('legalName', '');
// if (!name) return <span/>;
// {
//   const projectName = note.get('projectName', '');
//   if (!projectName) return <span className="text-muted">({name})</span>;
//   return <span className="text-muted">
//   ({name} - {projectName})
// </span>;
// }
/**
 * Component is used to display 1 deal note.
 *
 * @param props {Object} Any extra props that allowed on <p> tag.
 * @param props.onMouseEnter {Function} OnMouseEnter callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.onMouseLeave {Function} OnMouseLeave callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.note {Immutable.Map} Map, that should describe note. #content {string} is note content.
 * #[isMarked=false] {boolean} uf `true` append .text-bold class to element. #eventId is deal note's eventId.
 * @returns {XML}
 * @private
 * @class
 */
const BuyerDealNote = (props, { onEditEvent }) => {
  const { onMouseEnter, onMouseLeave, note, className = '', ...rest } = props;

  const isMarked = note.get('isMarked', false);
  const eventId = note.get('eventId');
  const isBoldContent = isMarked ? 'font-weight: bold;' : 'font-weight: normal;';

  const topContent = (
    <div className="contact__deal-note" onDoubleClick={event => onEditEvent(event, note.set('id', eventId))}>
      <strong>{note.get('date')}</strong> <span className="text-muted">{note.get('userName', '')}</span>{' '}
      <BuyerLabel note={note} />
    </div>
  );
  const content = note.get('content', '');

  const wrapBoldContent = `<span style='${isBoldContent}'>${content}</span>`;

  return (
    <div
      {...rest}
      className={className}
      onMouseEnter={event => onMouseEnter(event, eventId)}
      onMouseLeave={event => onMouseLeave(event, eventId)}
    >
      {topContent}
      <WysiwygView html={wrapBoldContent} onDoubleClick={event => onEditEvent(event, note.set('id', eventId))} />
    </div>
  );
};

BuyerDealNote.propTypes = {
  note: PropTypes.instanceOf(Map).isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

BuyerDealNote.contextTypes = {
  onEditEvent: PropTypes.func.isRequired,
};

/**
 * Component is used to display list of deal notes.
 *
 * @param props.onMouseEnter {Function} OnMouseEnter callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.onMouseLeave {Function} OnMouseLeave callback. 1st arg is event. 2nd is deal note's eventId.
 * @returns {XML}
 * @class
 */
const BuyerDealNotes = props => {
  const { onMouseEnter, onMouseLeave, buyerDealNotes } = props;

  const nonEmptyNotes = buyerDealNotes.filter(note => note.get('content') !== '<p><br></p>'); // Check if the wysiwyg text not empty.

  const content =
    nonEmptyNotes.size === 0 ? (
      <p> No rows to display </p>
    ) : (
      nonEmptyNotes.map((note, i) => (
        <BuyerDealNote key={i} note={note} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
      ))
    );

  return <div className="highlight bg-white">{content}</div>;
};

BuyerDealNotes.propTypes = {
  buyerDealNotes: PropTypes.instanceOf(List).isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

export { BuyerDealNote };
export default BuyerDealNotes;
