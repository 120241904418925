import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import GridRow from './GridRow';

/**
 * Stateless component for a target next action row.
 *
 * @param props {Object}.
 * @param props.action {Immutable.Map} Next action row.
 * @param props.index {Number} Row number.
 * @param props.onRowSelected {function} To handle checkbox change event.
 * @returns {React.Component}
 */
const ExecRow = props => {
  const { action, index, onRowSelected } = props;
  const actionId = action.get('id');
  const project = (
    <span
      className={classNames({
        'text-uppercase text-danger text-bold': !action.get('projectId'),
      })}
    >
      {action.get('projectName')}
    </span>
  );
  const buyer = (
    <span
      className={classNames({
        'text-uppercase text-danger text-bold': !action.get('buyerId'),
      })}
    >
      {action.get('buyerName')}
    </span>
  );
  const checked = action.get('selected', false);
  const date = action.get('date');
  const userName = action.get('userName');
  const secondUserName = (
    <div>
      <div>{action.get('recordOwnerUserName')}</div>
      <div>{action.get('recordSubOwnerUserName')}</div>
    </div>
  );
  const activity = action.get('activity');
  const legalName = action.get('legalName');
  const link = action.get('execId') ? (
    <Link target="_blank" to={`/contact/${action.get('execId')}`}>
      {legalName}
    </Link>
  ) : (
    'MISSING'
  );
  const currentStatus = action.get('currentStatus');
  const highStatus = action.get('highStatus');
  const industryLabel = action.get('industryLabel');

  return (
    <GridRow action={action}>
      <td>{index}</td>
      <td>
        <input checked={checked} name="selected" onChange={onRowSelected} type="checkbox" value={actionId} />
      </td>
      <td>{date}</td>
      <td>{userName}</td>
      <td>{secondUserName}</td>
      <td colSpan="2">{activity}</td>
      <td>{link}</td>
      <td>
        <strong>{currentStatus}</strong>&nbsp;/&nbsp;{highStatus}
      </td>
      <td>{industryLabel}</td>
      <td colSpan="8">
        {buyer} - {project}
      </td>
    </GridRow>
  );
};

ExecRow.propTypes = {
  action: PropTypes.instanceOf(Map).isRequired,
  index: PropTypes.number,
  onRowSelected: PropTypes.func.isRequired,
};

export default ExecRow;
