import { connect } from 'react-redux';
import { List } from 'immutable';

import connectOptions, { mergeProps } from '../../../utils/connectOptions';
import AutoCompleteEditor from '../../../components/helpers/AutoCompleteCellEditor';
import {
  loadUsersSuggestion,
  setSuggestion,
  selectUserSuggestion,
} from '../../../actions/company/buyer/addOnProject/suggestion';

const mapStateToProps = state => {
  const { suggestions } = state.targetCompany.buyer.addOnProject;

  return {
    suggestions: suggestions.getIn(['user', 'list']).toJS(),
    loading: suggestions.getIn(['user', 'loading']),
    placeholder: 'User',
  };
};

const mapDispatchToProps = dispatch => ({
  selectSuggestion: ({ selected, rowNode }) => {
    dispatch(
      selectUserSuggestion({
        rowIndex: rowNode.rowIndex,
        id: selected.id,
        text: selected.text,
      }),
    );
  },

  loadSuggestion: ({ value }) => {
    dispatch(loadUsersSuggestion(value));
  },

  clearSuggestion: () => {
    dispatch(setSuggestion({ path: 'user.list', value: List() }));
  },
});

export default AutoCompleteEditor(Comp =>
  connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    ...connectOptions,
    forwardRef: true,
  })(Comp),
);
