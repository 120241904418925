import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map, fromJS, List } from 'immutable';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import CountryDropDown from '../CountryDropDown';
import {
  handleGetSuggestion,
  handleUpdateParentCompany,
  closeValidationError,
  changeField,
  saveCompany,
  findHarvcos,
  findAnalyst,
} from '../../actions/company/buyerCreate';

import Popups from '../../components/Popup';
import AutoCompleteContainer from '../AutoComplete';
import { getChanged, unwrap, getIn } from '../../utils/ChangeSpy';
import { checkPhone, formatPhone } from '../services/phoneRules';
import checkZip from '../services/zipRules';

class AddCompanyBuyerPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.onErrorClose = this.onErrorClose.bind(this);
    this.onToggleCountry = this.onToggleCountry.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSuggestChange = this.onSuggestChange.bind(this);
    this.onChangeCountryName = this.onChangeCountryName.bind(this);
    this.onSelectCountry = this.onSelectCountry.bind(this);

    this.handleToggleDBA = this.handleToggleDBA.bind(this);
    this.handleToggleCountry = this.handleToggleCountry.bind(this);
    this.handleUpdateSuggestions = this.handleUpdateSuggestions.bind(this);
    this.handleGetSuggestion = this.handleGetSuggestion.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
    this.handleParentCompanyClick = this.handleParentCompanyClick.bind(this);

    this.isValidPhone = this.isValidPhone.bind(this);
    this.showPhoneError = this.showPhoneError.bind(this);
    this.showZipError = this.showZipError.bind(this);
    this.isValidZip = this.isValidZip.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.clearFormError = this.clearFormError.bind(this);
    this.onBICClick = this.onBICClick.bind(this);
    this.isValidIndustryCategory = this.isValidIndustryCategory.bind(this);
    this.state = {
      phone: '',
      zip: '',
      industryCategory: '',
      businessModel: '',
      submitting: false,
    };
  }

  componentDidMount() {
    this.props.changeField({
      name: 'harvcoName',
      value: this.context.currentUser.get('module') || '',
    });
    this.props.changeField({
      name: 'buyerHarvcoLeadId',
      value: this.context.currentUser.get('moduleId') || null,
    });
  }

  onErrorClose(e, field) {
    this.props.closeValidationError({ field });
  }

  getChildContext() {
    return {
      onErrorClose: (event, ...rest) => {
        if (rest[0] === 'zip') {
          this.setState(prevState => ({ ...prevState, zip: '' }));
        } else {
          this.onErrorClose(event, ...rest);
        }
      },
      inputErrors: this.props.inputErrors,
    };
  }

  getBusinessModelIds(models) {
    const result = [];

    models.forEach(model => {
      if (model.get('checked')) {
        result.push(model.get('id'));
      }
    });

    return result;
  }

  getIndustryTagIds(tags) {
    const result = [];

    tags.forEach(tag => {
      if (tag.get('checked')) {
        result.push(tag.get('id'));
      }
      tag.get('children').forEach(child => {
        if (child.get('checked')) {
          result.push(child.get('id'));
        }
      });
    });

    return result;
  }

  onCreate(event) {
    event.preventDefault();
    if (!this.state.submitting) {
      const phone = unwrap(this.props.company.get('phone', ''));
      const zip = unwrap(this.props.company.get('zip', ''));
      const industryCategoryIds = this.getIndustryTagIds(this.props.tags);
      const businessModelIds = this.getBusinessModelIds(this.props.businessModels);

      if (
        this.isValidPhone(phone) &&
        this.isValidZip(zip) &&
        this.isValidIndustryCategory(industryCategoryIds, businessModelIds)
      ) {
        this.clearFormError();

        const body = getChanged(this.props.company);

        if (body.country && body.country.countryId) {
          body.countryId = body.country.countryId;
          delete body.country;
        }

        const afterSuccess = ({ response }) => {
          setTimeout(() => {
            this.context.redirectForce(`/company/${response.id}`);
          }, 0);
        };

        const afterError = () => {
          this.setState({ submitting: false });
        };

        this.setState({ submitting: true }, () => {
          this.props.saveCompany({
            body: {
              ...body,
              parentId: body.parentId || null,
              industryCategoryIds: industryCategoryIds.concat(businessModelIds),
            },
            afterSuccess,
            afterError,
          });
        });
      }
    }
  }

  onChange(event) {
    const {
      target: { name, value },
    } = event;

    if (!name || name === 'parentCompany') return;
    if (name === 'companyType') {
      const typeHasParent = ['DivPri', 'DivLBO', 'DivPub', 'DivVC', 'DivNFP', 'DivFor'];

      if (typeHasParent.indexOf(value) === -1) {
        this.props.handleUpdateParentCompany({ text: '', selected: null });
        this.props.changeField({ name: 'parentId', value: null });
      } else {
        this.props.changeField({ name: 'parentId', value: 0 });
      }
    }
    this.props.changeField({ name, value });
  }

  onToggleCountry() {
    return this.props.changeField({
      name: 'showCountry',
      value: !this.props.company.get('showCountry'),
    });
  }

  onClose() {
    this.context.closePopup();
  }

  onSuggestChange({ name, value }) {
    return this.props.changeField({ name, value });
  }

  getSuggests() {
    return {
      suggestHarvco: this.getModuleSuggests(),
      suggestAnalyst: this.getAnalystSuggests(),
    };
  }

  getModuleSuggests() {
    const prefix = '';

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={opts => {
          this.props.findHarvcos({
            ...opts,
            field: `${prefix}harvcoName`,
          });
        }}
        idName={`${prefix}buyerHarvcoLeadId`}
        inputProps={{ name: `${prefix}harvcoName` }}
        name={`${prefix}harvcoName`}
        rootPath={['targetCompany', 'buyerCreate']}
        suggestsName={`${prefix}harvcoSuggests`}
        valueName={`${prefix}harvcoName`}
      />
    );
  }

  getAnalystSuggests() {
    const prefix = '';

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={opts => {
          this.props.findAnalyst({
            ...opts,
            field: `${prefix}analystName`,
          });
        }}
        idName={`${prefix}recordSubOwnerId`}
        inputProps={{ name: `${prefix}analystName` }}
        name={`${prefix}analystName`}
        rootPath={['targetCompany', 'buyerCreate']}
        suggestsName={`${prefix}analystSuggests`}
        valueName={`${prefix}analystName`}
      />
    );
  }

  handleToggleDBA() {
    const currentValue = this.props.buyerCompany.getIn(['showDBA']);

    this.props.handleUpdateCompanyInfo({
      showDBA: !currentValue,
    });
  }

  handleToggleCountry() {
    const currentValue = this.props.buyerCompany.getIn(['showCountry']);

    this.props.handleUpdateCompanyInfo({
      showCountry: !currentValue,
    });
  }

  handleParentCompanyClick() {
    const selectedCompany = this.props.buyerCompany.get('parentCompany').get('selected');

    if (selectedCompany) {
      window.open(`/company/${selectedCompany.get('id')}`);
    }
  }

  handleUpdateSuggestions(update) {
    this.props.handleUpdateParentCompany(update);
  }

  handleGetSuggestion({ value }) {
    this.props.handleGetSuggestion(value);
  }

  static handleGetSuggestionValue(suggestion) {
    return suggestion.legalName;
  }

  static handleRenderSuggestion(suggestion) {
    return <div>{suggestion.legalName}</div>;
  }

  handleSuggestionSelected(event, { suggestion }) {
    this.props.handleUpdateParentCompany({
      text: suggestion.legalName,
      selected: suggestion,
      suggestions: [],
    });
  }

  onChangeCountryName(event) {
    this.props.changeField({
      name: 'country.countryName',
      value: event.target.value,
    });
  }

  onSelectCountry(selection) {
    this.props.changeField({ name: 'country.countryId', value: selection.id });
    this.props.changeField({
      name: 'country.countryName',
      value: selection.text,
    });
  }

  isValidPhone(phone) {
    const tmp = phone || '';

    if (this.props.company.get('showCountry', false)) return true;

    if (tmp && !checkPhone(tmp)) {
      this.showPhoneError();

      return false;
    }

    return true;
  }

  isValidZip(zip) {
    if (!checkZip(zip)) {
      this.showZipError();

      return false;
    }

    return true;
  }

  isValidIndustryCategory(ids, businessModelIds) {
    let result = true;

    if (!ids.length) {
      this.setState({
        industryCategory: 'Industry Tags is required',
      });
      result = false;
    }

    if (!businessModelIds.length) {
      this.setState({
        businessModel: 'Business Model is required',
      });
      result = false;
    }

    return result;
  }

  showPhoneError() {
    this.setState(prevState => ({
      ...prevState,
      phone:
        'Phone must be a valid North American area code and format (###-###-####) or +[numbers, spaces and dashes] if international',
    }));
  }

  showZipError() {
    this.setState(prevState => ({
      ...prevState,
      zip:
        'Zip code should follow U.S. (XXXXX or XXXXX-XXXX) or Canadian (A1A 1A1) format; otherwise, please set address to International ("...")',
    }));
  }

  onBlur({ target: { name, value = '' } }) {
    if (this.props.company.get('showCountry', false)) {
      this.clearFormError();

      return;
    }

    if (name === 'phone') {
      if (this.isValidPhone(value)) {
        this.clearFormError();
        this.props.changeField({
          name: 'phone',
          value: value ? formatPhone(value) : '',
        });
      } else {
        this.showPhoneError();
      }
    } else if (name === 'zip' && !checkZip(value)) {
      this.showZipError();
    }
  }

  clearFormError() {
    this.setState({
      phone: '',
      zip: '',
      businessModel: '',
      industryCategory: '',
    });
  }

  onBICClick() {
    this.context.openPopup('TagsManagementPopup', {
      addNew: true,
      clientView: true,
      cb: () =>
        this.context.openPopup('AddBuyerCompanyPopup', {
          fromPositionPopup: this.fromPositionPopup,
          cb: this.props.popup.getIn(['props', 'cb']),
        }),
    });
  }

  render() {
    const { children, company, states, entityTypes, isValid, businessModels, tags } = this.props;

    const companyInfoProps = {
      onSubmit: this.onBlurInfo,
      onCompanyInfoChange: this.handleCompanyInfoChange,
      onToggleDBA: this.handleToggleDBA,
      onToggleCountry: this.handleToggleCountry,
      onParentCompanyClick: this.handleParentCompanyClick,
    };
    const suggestions = company.getIn(['parentCompany', 'suggestions'], fromJS([]));
    const text = company.getIn(['parentCompany', 'text']) || '';
    const suggestionProps = {
      name: 'parentCompany',
      inputProps: {
        className: 'parentCompany form-control',
        id: 'parentCompany',
        name: 'parentCompany',
        disabled: false,
      },
      suggestions,
      onUpdateSuggestions: this.handleUpdateSuggestions,
      getSuggestion: this.handleGetSuggestion,
      getSuggestionValue: AddCompanyBuyerPopupContainer.handleGetSuggestionValue,
      renderSuggestion: AddCompanyBuyerPopupContainer.handleRenderSuggestion,
      onSuggestionSelected: this.handleSuggestionSelected,
      checkOn: 'text',
      text,
    };

    const countryComponent = (
      <CountryDropDown
        name="country.countryId"
        onChange={this.onChangeCountryName}
        onSelect={this.onSelectCountry}
        value={company.getIn(['country', 'countryName'])}
      />
    );

    return (
      <div>
        <Popups.AddBuyerCompanyPopup
          {...companyInfoProps}
          {...this.getSuggests()}
          businessModels={businessModels}
          company={company}
          countryComponent={countryComponent}
          entityTypes={entityTypes}
          error={{
            phone: this.state.phone,
            zip: this.state.zip,
            businessModel: this.state.businessModel,
            industryCategory: this.state.industryCategory,
          }}
          isValid={isValid}
          onBICClick={this.onBICClick}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onCreate={this.onCreate}
          onErrorClose={this.clearFormError}
          onToggleCountry={this.onToggleCountry}
          states={states}
          submitting={this.state.submitting}
          suggestionProps={suggestionProps}
          tags={tags}
        />
        {children}
      </div>
    );
  }
}

AddCompanyBuyerPopupContainer.contextTypes = {
  currentUser: PropTypes.instanceOf(Map).isRequired,
  redirectForce: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

AddCompanyBuyerPopupContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map).isRequired,
};

const defaultErrors = Map();

function mapStateToProps(state, props) {
  return {
    ...props,
    businessModels: unwrap(state.businessModels.get('models', List())),
    tags: getIn(state.industryTags, 'tags', List()),
    inputErrors: state.targetCompany.buyerCreate.get('inputErrors', defaultErrors),
    isValid: state.targetCompany.buyerCreate.get('isValid', true),
    states: state.targetCompany.buyerCreate.get('states'),
    entityTypes: state.targetCompany.buyerCreate.get('entityTypes'),
    company: state.targetCompany.buyerCreate,
    buyerCompany: state.targetCompany.buyerCreate,
    target: state.targetCompany.target,
  };
}

AddCompanyBuyerPopupContainer.closable = false;

AddCompanyBuyerPopupContainer.contextTypes = {
  currentUser: PropTypes.instanceOf(Map).isRequired,
  redirectForce: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
};

export { AddCompanyBuyerPopupContainer };

const Export = connect(
  mapStateToProps,
  {
    handleGetSuggestion,
    changeField,
    saveCompany,
    closeValidationError,
    findHarvcos,
    findAnalyst,
    handleUpdateParentCompany,
  },
  mergeProps,
  connectOptions,
)(AddCompanyBuyerPopupContainer);

Export.closable = false;

export default Export;
