export function getApprovalMailToLink(emailFields) {
  const emails = emailFields.emails.join(',');

  const emailSubject = `${emailFields.approvalListLabel} - ${emailFields.buyerLegalName}`;

  const linksText = emailFields.link !== '' ? `: ${emailFields.link}` : '.';
  const expirationDateText = emailFields.expirationDate
    ? `(link will be available until ${emailFields.expirationDate}).`
    : '';
  const emailBody = `${emailFields.approvalListLabel} is ready for review${linksText}
  \nPlease follow the link and submit your approvals and exclusions ${expirationDateText}`;

  const subjectQueryString = `subject=${encodeURIComponent(emailSubject)}`;
  const bodyQueryString = `body=${encodeURIComponent(emailBody)}`;

  return `mailto:${emails}?${subjectQueryString}&${bodyQueryString}`;
}
