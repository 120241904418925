import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ContactTarget from '../containers/Contact/ContactTarget';
import ContactBuyer from '../containers/Contact/ContactBuyer';
import ContactExecutive from '../containers/Contact/ContactExecutive';
import ContactLP from '../containers/Contact/ContactLP';

export default function ContactRouting() {
  return (
    <Switch>
      <Route component={ContactTarget} path="/contact/:contactId/target" />
      <Route component={ContactBuyer} path="/contact/:contactId/buyer" />
      <Route component={ContactExecutive} path="/contact/:contactId/executive" />
      <Route component={ContactLP} path="/contact/:contactId/lp" />
    </Switch>
  );
}
