import PropTypes from 'prop-types';
import React from 'react';
import { List, Map } from 'immutable';

import Table, { Column, Row } from '../helpers/Table';
import config from '../../config';
import Input from '../helpers/Input';
import { unwrap } from '../../utils/ChangeSpy';
import FormSubmitOnBlur from '../helpers/FormSubmitOnBlur';
import Spinner from '../helpers/Spinner';

/**
 * Target panel component.
 *
 * @param props {Object}.
 * @param props.targetColumns {Immutable.List} Target column configuration.
 * @param props.targets {Immutable.List} Target List.
 * @param props.common {Immutable.Map} Common Object.
 * @param props.onSort {Function} Handle event of sorting table.
 * @param props.onChange {Function}.
 * @param props.onSubmit {Function}.
 * @returns {React.Component}
 * @class
 */
const TargetTable = props => {
  const { targetColumns, targets, common, projectData, onDoubleClick, onSort, onChange, onSubmit, onTableRef } = props;
  const type = config.values.getIn(['project', 'types', 'TARGETS']);
  const columns = getColumns(
    targetColumns,
    common.get('targetSortField'),
    common.get('targetSortDirection'),
    onSort,
    type,
    onChange,
    onSubmit,
  );
  const label = `Universe (${projectData.get('buyerName')} - ${projectData.getIn([
    'category',
    'name',
  ])} is the "Active" Buyer - Project)`;
  const spinner = common.get('targetLoading') ? <Spinner /> : null;

  return (
    <div className="project-universe">
      <label>{label}</label>
      <div ref={onTableRef} className="table-main table-responsive">
        {spinner}
        <Table className="table table-striped sort">
          {columns}
          {targets.map((target, i) => (
            <Row key={i} data={target.set('i', i + 1)} onDoubleClick={() => onDoubleClick({ row: target, type })} />
          ))}
        </Table>
      </div>
    </div>
  );
};

TargetTable.propTypes = {
  common: PropTypes.instanceOf(Map).isRequired,
  onSort: PropTypes.func.isRequired,
  targetColumns: PropTypes.instanceOf(List).isRequired,
  targets: PropTypes.instanceOf(List).isRequired,
};

export default TargetTable;

/**
 * Generate table columns.
 *
 * @param targetColumns {Immutable.List} Target column configuration.
 * @param field {Immutable.List} Target List.
 * @param direction {String} Direction of sorting.
 * @param onClick {Function} Handle event of clicking on column head.
 * @param type {String} Type of table.
 * @param onChange {Function} Handle event of change.
 * @param onSubmit {Function} Handle event of submit.
 * @returns {React.Component}
 */
function getColumns(targetColumns, field, direction, onClick, type, onChange, onSubmit) {
  return targetColumns.map((col, index) => {
    const colField = col.get('field');

    if (colField === 'approved') {
      return drawApprovedColumn(
        index,
        col.get('headerName'),
        colField,
        onClick,
        colField === field ? direction : null,
        type,
        onChange,
        onSubmit,
      );
    }

    if (colField === 'priority') {
      return drawPriorityColumn(
        index,
        col.get('headerName'),
        colField,
        onClick,
        colField === field ? direction : null,
        type,
        onChange,
        onSubmit,
      );
    }

    return drawColumn(index, col.get('headerName'), colField, onClick, colField === field ? direction : null, type);
  });
}

/**
 * Draw column head.
 *
 * @param key {number} Column key.
 * @param headerName {String} Column title.
 * @param field {String} Sorting field name.
 * @param onClick {Function} Handle event of clicking on column head.
 * @param direction {String} Direction of sorting.
 * @param type {String} Type of table.
 * @returns {React.Component}
 */
function drawColumn(key, headerName, field, onClick, direction, type) {
  return (
    <Column
      key={key}
      field={field}
      title={
        <a
          className="text-regular"
          onClick={event =>
            onClick(event, {
              field,
              direction: direction === 'up' ? 'down' : 'up',
              type,
            })
          }
        >
          {headerName} {direction ? <i aria-hidden="true" className={`fa fa-caret-${direction}`} /> : null}
        </a>
      }
    />
  );
}

/**
 * Generate True|False value column.
 *
 * @param key {number} Column key.
 * @param headerName {String} Column title.
 * @param field {String} Sorting field name.
 * @param onClick {Function} Handle event of clicking on column head.
 * @param onChange {Function} Handle event of changing.
 * @param onSubmit {Function} Handle event of submitting.
 * @param direction {String} Direction of sorting.
 * @param type {String} Type of table.
 * @returns {React.Component}
 */
function drawApprovedColumn(key, headerName, field, onClick, direction, type, onChange, onSubmit) {
  const change = (event, value, data) => {
    onChange({ target: { value: true } }, data, 'processing');
    onChange({ target: { value } }, data, 'approved');
    onSubmit(event, {
      field: 'approved',
      tbId: data.get('tbId'),
      id: data.get('id'),
      approved: value,
    });
  };

  return (
    <Column
      key={key}
      className="project-priority-column"
      field={field}
      title={
        <a
          className="text-regular"
          onClick={event =>
            onClick(event, {
              field,
              direction: direction === 'up' ? 'down' : 'up',
              type,
            })
          }
        >
          {headerName} {direction ? <i aria-hidden="true" className={`fa fa-caret-${direction}`} /> : null}
        </a>
      }
      valueWrapper={(value, data) => {
        const approved = unwrap(value);

        if (data.get('processing')) {
          return <i className="fa fa-spinner fa-spin" />;
        }

        if (approved === true) {
          return (
            <div>
              <i
                className="icon-approved fa fa-check clickable"
                onClick={event => {
                  change(event, null, data);
                }}
              />
            </div>
          );
        }

        if (approved === false) {
          return (
            <div>
              <i
                className="icon-rejected fa fa-times clickable"
                onClick={event => {
                  change(event, null, data);
                }}
              />
            </div>
          );
        }

        return (
          <div>
            <i
              className="icon-approved-not fa fa-check clickable mr10"
              onClick={event => {
                change(event, true, data);
              }}
            />
            <i
              className="icon-approved-not fa fa-times clickable"
              onClick={event => {
                change(event, false, data);
              }}
            />
          </div>
        );
      }}
    />
  );
}

/**
 * Generate True|False value column.
 *
 * @param key {number} Column key.
 * @param headerName {String} Column title.
 * @param field {String} Sorting field name.
 * @param onClick {Function} Handle event of clicking on column head.
 * @param direction {String} Direction of sorting.
 * @param type {String} Type of table.
 * @param onChange {Function} Handle event of changing.
 * @param onSubmit {Function} Handle event of submitting.
 * @returns {React.Component}
 */
function drawPriorityColumn(key, headerName, field, onClick, direction, type, onChange, onSubmit) {
  return (
    <Column
      key={key}
      className="project-priority-column"
      field={field}
      title={
        <a
          className="text-regular"
          onClick={event =>
            onClick(event, {
              field,
              direction: direction === 'up' ? 'down' : 'up',
              type,
            })
          }
        >
          {headerName} {direction ? <i aria-hidden="true" className={`fa fa-caret-${direction}`} /> : null}
        </a>
      }
      valueWrapper={(value, i) => (
        <FormSubmitOnBlur className="mr5" onSubmit={onSubmit} focusOnClick>
          <Input className="form-control input-sm" onChange={event => onChange(event, i, field)} value={value || ''} />
        </FormSubmitOnBlur>
      )}
    />
  );
}
