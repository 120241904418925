import React, { PureComponent } from 'react';
import DatePicker from '@vespaiach/react-datepicker';

class DatePickerEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onRef = this.onRef.bind(this);

    this.state = {
      value: this.props.value && this.props.value.isValid() ? this.props.value : null,
    };
  }

  getValue() {
    return this.state.value;
  }

  onChange(date) {
    this.setState({
      value: date,
    });
  }

  isPopup() {
    return false;
  }

  onRef(input) {
    this.picker = input;
  }

  afterGuiAttached() {
    this.picker.setFocus();
  }

  componentDidUpdate(prevProps, prevState) {
    const { value: prev } = prevState;
    const { value: curr } = this.state;

    if ((prev && curr && this.state.value.diff(prevState.value) !== 0) || prev !== curr) {
      this.props.stopEditing();
    }
  }

  render() {
    const { value } = this.state;

    return (
      <DatePicker
        ref={this.onRef}
        className="form-control input-sm"
        dropdownMode="select"
        onChange={this.onChange}
        selected={value}
        showDayInMonthOnly
        showMonthDropdown
        showYearDropdown
      />
    );
  }
}

export default DatePickerEditor;
