import { CALL_API } from '../../../../middleware/api';
import { loadTemplates } from './template';

export const CHANGE_TEMPLATE_NAME = Symbol('CHANGE_TEMPLATE_NAME');
export const CHANGE_UPLOADING_FILE = Symbol('CHANGE_UPLOADING_FILE');
export const CLEAR_VALIDATION_ERROR = Symbol('CLEAR_VALIDATION_ERROR');
export const VALIDATE_FORM = Symbol('VALIDATE_FORM');
export const UPLOADING_FORM = Symbol('UPLOADING_FORM');
export const UPLOADED_FORM_FAILURE = Symbol('UPLOADED_FORM_FAILURE');
export const UPLOADED_FORM_SUCCESS = Symbol('UPLOADED_FORM_SUCCESS');
export const RESET_POPUP = Symbol('RESET_POPUP');
export const CLEAR_TEMPLATE_POPUP_ERROR = Symbol('CLEAR_TEMPLATE_POPUP_ERROR');

/**
 * Change template name.
 *
 * @param name {String} Template name.
 * @returns Action.
 */
export function changeName(name) {
  return {
    type: CHANGE_TEMPLATE_NAME,
    name,
  };
}

/**
 * Change file uploading name.
 *
 * @param file {String} File name.
 * @returns Action.
 */
export function changeFile(file) {
  return {
    type: CHANGE_UPLOADING_FILE,
    file,
  };
}

/**
 * Reset popup form.
 */
export function reset() {
  return {
    type: RESET_POPUP,
  };
}

/**
 * Validate form.
 */
export function validate() {
  return {
    type: VALIDATE_FORM,
  };
}

/**
 * Upload template.
 *
 * @param projectId {Number} Project id.
 * @param formData {FormData} Form data to upload.
 * @returns Action.
 */
export function upload(projectId, formData) {
  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/projects/${projectId}/templates`,
      body: formData,
      startType: UPLOADING_FORM,
      errorType: UPLOADED_FORM_FAILURE,
      skipGlobalErrorHandler: true,
      successType: UPLOADED_FORM_SUCCESS,
      afterSuccess: ({ dispatch }) => {
        dispatch(loadTemplates(projectId));
      },
    },
  };
}

/**
 * Clear validation error.
 *
 * @returns Action.
 */
export function clearValidationError(field) {
  return {
    type: CLEAR_VALIDATION_ERROR,
    field,
  };
}

export function clearTemplatePopupError() {
  return {
    type: CLEAR_TEMPLATE_POPUP_ERROR,
  };
}
