import isFunction from '../../utils/isFunction';
import isArray from '../../utils/isArray';
import isRegExp from '../../utils/isRegExp';
import { getOriginalIn, setIn } from '../../utils/ChangeSpy';

/**
 *
 * @param field
 * @param action
 * @returns Boolean if not field or field path (dotted String, String[]).
 */
function isCurrentStatus(field, action) {
  if (typeof field === 'string') return action.name === field;
  if (isRegExp(field)) return field.test(action.name);
  if (isFunction(field)) return !!field(action);
  if (isArray(field)) return field.join('.') === action.name;

  return false;
}

function getHighName(field, highField, name) {
  if (typeof field === 'string') return highField;
  if (isRegExp(field)) return name.replace(field, highField);
  throw new Error('Not implemented.');
}

export default function highStatusActualize(field, highField, actionType, reducer) {
  return function highStatusActualize(state, action) {
    if (actionType === action.type) {
      if (isCurrentStatus(field, action)) {
        const { name, value } = action;
        const highName = getHighName(field, highField, name);
        const highOriginalValue = getOriginalIn(state, highName, 0);
        const max = Math.max(highOriginalValue, value);

        const highNextValue = parseFloat(highOriginalValue) === max ? highOriginalValue : value;

        state = setIn(state, highName, highNextValue);
      }
    }

    return reducer(state, action);
  };
}
