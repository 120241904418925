/**
 * Check if argument is an instance of Function.
 *
 * @param arg {*}.
 * @returns {boolean}
 */
export default function isFunction(arg) {
  return Object.prototype.toString.call(arg) === '[object Function]';
}

/**
 * Check if argument is an instance of Function and execute it.
 *
 * @param arg {*}.
 * @param ...res {*} Function's parameters.
 * @returns {boolean}
 */
export function execIfFunction(func, ...rest) {
  if (isFunction(func)) {
    func(...rest);
  }
}
