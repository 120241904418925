import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import Popups from '../../components/Popup';
import { getChanged, isDeepChanged, getIn, unwrap } from '../../utils/ChangeSpy';
import * as TARGET from '../../actions/company/companyTarget';
import * as BUYER from '../../actions/company/buyer/marketing';
import config from '../../config';
import { getBirthYear } from '../../utils/yearAgeConverter';
import AutoComplete from '../AutoComplete';

const prefixes = config.values.getIn(['contact', 'prefixes']);
const suffixes = config.values.getIn(['contact', 'suffixes']);

class EditContactPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onFetchTitles = this.onFetchTitles.bind(this);
    this.onUpdateTitle = this.onUpdateTitle.bind(this);
    this.onSelectTitle = this.onSelectTitle.bind(this);
    this.onErrorClose = this.onErrorClose.bind(this);
    this.onChangeSuggestion = this.onChangeSuggestion.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    this.context.onClosePopup(() => {
      const field = this.props.pathPrefix.slice(0, -1);

      this.props.revertUpdate({ field });
    });
  }

  getChildContext() {
    return {
      onErrorClose: this.onErrorClose,
      inputErrors: this.props.inputErrors,
    };
  }

  onErrorClose(e, field) {
    this.props.closeValidationError({ field });
    this.props.closeValidationError({
      field: `${this.props.pathPrefix}${field}`,
    });
  }

  onChange(event) {
    const { name, value } = event.target;

    return this.props.handleUpdateCompany({
      name: `${this.props.pathPrefix}${name}`,
      value,
    });
  }

  onSave() {
    if (this.props.inputErrors.size === 0) {
      const contactId = this.props.contact.get('id');
      const { companyId } = this.props;
      const isAdvisor = unwrap(this.props.contact.getIn(['pivot', 'title'], '')).toUpperCase() === 'ADVISOR';
      const saved = getChanged(this.props.contact);

      saved.fullName = unwrap(this.props.contact.get('fullName'));
      saved.firstName = unwrap(this.props.contact.get('firstName'));
      saved.lastName = unwrap(this.props.contact.get('lastName'));
      saved.nick = unwrap(this.props.contact.get('nick'));
      saved.pronounce = unwrap(this.props.contact.get('pronounce'));
      saved.advTitle = unwrap(this.props.contact.getIn(['pivot', 'advTitle']));
      saved.advWeb = unwrap(this.props.contact.getIn(['pivot', 'advWeb']));
      saved.company = unwrap(this.props.contact.getIn(['pivot', 'company']));

      const prefix = unwrap(this.props.contact.get('prefix')).get('value');
      const suffix = unwrap(this.props.contact.get('suffix')).get('value');
      const body = { prefix, suffix };

      this.copySaved(body, saved, isAdvisor);
      this.ageHandle(body);
      this.ownershipPercentHandle(body);
      if (this.validate(body)) {
        if (parseInt(body.age, 10) === 0) {
          delete body.age;
          delete body.birthYear;
        }
        this.props.saveCompanyContact({ companyId, contactId, body, saved }, () => {
          this.context.closePopup();
        });
      }
    }
  }

  ageHandle(body) {
    if ('age' in body) {
      body.birthYear = parseInt(getBirthYear(body.age), 10);
    }
  }

  ownershipPercentHandle(body) {
    if (body.ownershipPercent === '') {
      body.ownershipPercent = null;
    }
  }

  /**
   * Api request needs the specials parameters stay outside of pivot object.
   * The specials parameters will be available only when Title = "Advisor".
   */
  copySaved(body, saved, isAdvisor) {
    const specials = ['advTitle', 'advWeb', 'company'];

    if (saved) {
      Object.keys(saved).forEach(key => {
        if (key !== 'pivot' && key !== 'prefix' && key !== 'suffix') {
          body[key] = saved[key];
        }
      });
      if (saved.pivot) {
        Object.keys(saved.pivot).forEach(key => {
          if (specials.indexOf(key) < 0) {
            body[key] = saved.pivot[key];
          }
        });

        if (!isAdvisor) {
          specials.forEach(key => {
            body[key] = '';
            saved.pivot[key] = '';
          });
        } else {
          specials.forEach(key => {
            if (saved.pivot[key]) {
              body[key] = saved.pivot[key];
            }
          });
        }
      }
    }
  }

  validate(contact) {
    let isValid = true;
    const { pathPrefix, addValidationError } = this.props;
    const path = pathPrefix.substring(0, pathPrefix.length - 1);

    if ((contact.birthYear && !/^\d+$/i.test(contact.birthYear)) || (contact.age && !/^\d+$/i.test(contact.age))) {
      isValid = false;
      addValidationError({
        path,
        field: 'age',
        message: 'Age is not valid.',
      });
    }

    return isValid;
  }

  onFetchTitles({ value }) {
    this.props.findTitles({
      filter: value,
      prefix: this.props.pathPrefix.slice(0, -1),
    });
  }

  onUpdateTitle(param) {
    if ('text' in param) {
      const name = 'pivot.title';
      const value = param.text;

      this.props.handleUpdateCompany({
        name: `${this.props.pathPrefix}${name}`,
        value,
      });
    }

    if ('suggestions' in param) {
      const name = 'suggests';
      const value = param.suggestions;

      this.props.handleUpdateCompany({
        name: `${this.props.pathPrefix}${name}`,
        value,
      });
    }
  }

  onSelectTitle(e, { suggestion }) {
    this.props.handleUpdateCompany({
      name: `${this.props.pathPrefix}pivot.title`,
      value: suggestion.text,
    });
  }

  static onGetValue(suggestion) {
    return suggestion.value;
  }

  getSuggestProps() {
    const value = this.props.contact.getIn(['pivot', 'title'], '');
    const text = unwrap(value);

    return {
      onFetch: this.onFetchTitles,
      suggestions: this.props.contact.getIn(['suggestions']),
      onUpdate: this.onUpdateTitle,
      getValue: EditContactPopupContainer.onGetValue,
      onSelect: this.onSelectTitle,
      value,
      showExtraInfo: text && text.toUpperCase() === 'ADVISOR',
      loading: this.props.loading,
    };
  }

  onChangeSuggestion(obj) {
    if (typeof obj.value === 'string') {
      const { value } = obj;
      const nextValue = this.props.suffixes.find(
        suffix => suffix.get('name') === value,
        null,
        Map({
          name: value || '',
          value: value || '',
        }),
      );

      this.props.handleUpdateCompany({
        name: `${this.props.pathPrefix}${'suffix'}`,
        value: nextValue,
      });
    }
  }

  getSuffixesSuggest() {
    const value = getIn(this.props.contactInfo, 'suffix').get('name');
    const idValue = value;
    const regExp = new RegExp(String(value).replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1'), 'i');
    const className = classNames('form-control input-sm', {
      changed: isDeepChanged(this.props.contactInfo.get('suffix')),
    });
    const suggestions = this.props.suffixes
      .filter(suffix => regExp.test(suffix.get('name')))
      .map(suffix => ({
        id: suffix.get('value'),
        text: suffix.get('name'),
      }));

    return (
      <AutoComplete
        change={this.onChangeSuggestion}
        find={args => {
          setTimeout(
            () =>
              args.afterSuccess({
                response: {
                  data: this.props.suffixes
                    .filter(suffix => regExp.test(suffix.get('name')))
                    .map(suffix => ({
                      id: suffix.get('value'),
                      text: suffix.get('name'),
                    })),
                },
              }),
            0,
          );
        }}
        idValue={idValue}
        inputProps={{ className }}
        loading={false}
        name="suffix"
        suggestions={suggestions}
        suggestsName="suffixes"
        value={value}
        suggestIfEmpty
      />
    );
  }

  render() {
    const { children, contact, prefixes, suffixes, companyId } = this.props;

    return (
      <div>
        <Popups.EditContactPopup
          companyId={companyId}
          contact={contact}
          onChange={this.onChange}
          onSave={this.onSave}
          prefixes={prefixes}
          suffixes={suffixes}
          suffixesSuggest={this.getSuffixesSuggest()}
          titleProps={this.getSuggestProps()}
          isValid
        />
        {children}
      </div>
    );
  }
}

EditContactPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
};

EditContactPopupContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};

function mapStateToProps(state, props) {
  const openedContact = props.popup.getIn(['props', 'openedContact']);
  const isBuyer = props.popup.getIn(['props', 'mode']) === 'buyer';
  const root = isBuyer ? state.targetCompany.buyer.marketing : state.targetCompany.target;
  const contact = root.getIn(['contacts', openedContact]);

  return {
    companyId: state.targetCompany.info.getIn(['info', 'id']),
    isValid: contact.get('isValid', true),
    inputErrors: contact.get('inputErrors', Map()),
    pathPrefix: `contacts.${openedContact}.`,
    prefixes,
    suffixes,
    contact,
    contactInfo: contact,
    loading: contact.get('loading'),
  };
}

function mapDispatchToProps(dispatch, props) {
  const isBuyer = props.popup.getIn(['props', 'mode']) === 'buyer';

  if (isBuyer) {
    const {
      handleUpdateCompany,
      saveCompanyContact,
      revertUpdate,
      closeValidationError,
      findTitles,
      addValidationError,
    } = BUYER;

    return bindActionCreators(
      {
        handleUpdateCompany,
        saveCompanyContact,
        revertUpdate,
        closeValidationError,
        findTitles,
        addValidationError,
      },
      dispatch,
    );
  }

  const {
    handleUpdateCompany,
    saveCompanyContact,
    revertUpdate,
    closeValidationError,
    findTitles,
    addValidationError,
  } = TARGET;

  return bindActionCreators(
    {
      handleUpdateCompany,
      saveCompanyContact,
      revertUpdate,
      closeValidationError,
      findTitles,
      addValidationError,
    },
    dispatch,
  );
}

export { EditContactPopupContainer };

const Connected = connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions)(EditContactPopupContainer);

export default Connected;
