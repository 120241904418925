import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';

const PROPS_TO_SYNC = ['valueKey', 'nameKey'];

const NULL_VALUE = 'null';

const DEFAULT_OPTION_VALUE = 'Retain original status codes';

export default function withDoNothingOption(WrappedComboBox) {
  return class WithDoNothingOption extends PureComponent {
    constructor(props) {
      super(props);

      const valueKey = props.valueKey || 'value';
      const nameKey = props.nameKey || 'name';

      this.state = {
        options: fromJS([
          {
            [valueKey]: NULL_VALUE,
            [nameKey]: DEFAULT_OPTION_VALUE,
          },
        ]).concat(props.options),
        valueKey,
        nameKey,
      };

      this.handleChange = this.handleChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      let changed = false;

      PROPS_TO_SYNC.forEach(field => {
        changed = true;
        if (nextProps[field] !== this.props[field]) {
          this.setState(() => ({ [field]: nextProps[field] }));
        }
      });
      if (changed || nextProps.options !== this.props.options) {
        this.setState(({ valueKey, nameKey }) => ({
          options: fromJS([
            {
              [valueKey]: NULL_VALUE,
              [nameKey]: DEFAULT_OPTION_VALUE,
            },
          ]).concat(nextProps.options),
        }));
      }
    }

    handleChange(event) {
      const { type, value, checked = null } = event.target;

      if (typeof this.props.onChange === 'function') {
        this.props.onChange({
          target: {
            name: this.props.name,
            value: value === NULL_VALUE ? null : value,
            type,
            checked,
          },
        });
      }
    }

    getValue() {
      if (this.props.value === null) {
        return NULL_VALUE;
      }

      return this.props.value;
    }

    render() {
      return (
        <WrappedComboBox
          {...this.props}
          onChange={this.handleChange}
          options={this.state.options}
          value={this.getValue()}
        />
      );
    }
  };
}
