import PropTypes from 'prop-types';

import React from 'react';
import Immutable from 'immutable';

import DatePicker from '@vespaiach/react-datepicker';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Button from '../helpers/Button';

const PushNextActionDatesPopup = props => {
  const { onUpdate, onClose, onChange, onDateChange, formData, isPopupFetching, isPopupFetchingError } = props;
  const header = 'Push Next Action Dates';
  const footer = [
    <Button key="addTag" className="btn-primary btn-xs" onClick={onUpdate}>
      Update
    </Button>,
    <Button key="close" className="btn-default btn-xs" onClick={onClose}>
      Close
    </Button>,
  ];
  const loading = isPopupFetching ? (
    <div className="loading">
      <i className="fa fa-spinner fa-pulse fa-3x fa-fw login-spinner" />
    </div>
  ) : null;
  const errorContent = isPopupFetchingError ? (
    <div className="error text-danger">
      <p>{isPopupFetchingError.get('message')}</p>
      <ul>
        {isPopupFetchingError.get('errors', []).map((error, i) => (
          <li key={i}>{error}</li>
        ))}
      </ul>
    </div>
  ) : null;

  return (
    <Popup footer={footer} header={header} id="pushNextActionDatesPopup" loading={loading}>
      <div className="row media-450">
        <div className="col-xs-12">
          <div>Adjust the dates of any existing Next Actions for the selected companies:</div>
          <div className="form-inline row">
            <FormGroup className="col-xs-12 group-checkbox">
              <div className="mt10">
                <input
                  checked={formData.get('forward', false)}
                  id="forwardCb"
                  name="forward"
                  onChange={onChange}
                  type="checkbox"
                  autoFocus
                />
                <label htmlFor="forwardCb">
                  Push all next action dates forward:
                  <input
                    disabled={!formData.get('forward', false)}
                    name="forwardDays"
                    onChange={onChange}
                    type="number"
                    value={formData.get('forwardDays', '')}
                  />
                  days
                </label>
              </div>
              <div className="mt10">
                <input
                  checked={formData.get('backward', false)}
                  id="backwardCb"
                  name="backward"
                  onChange={onChange}
                  type="checkbox"
                />
                <label htmlFor="backwardCb">
                  Push all next action dates backward:
                  <input
                    disabled={!formData.get('backward', false)}
                    name="backwardDays"
                    onChange={onChange}
                    type="number"
                    value={formData.get('backwardDays', '')}
                  />
                  days
                </label>
              </div>
              <div className="mt10">
                <input
                  checked={formData.get('following', false)}
                  id="followingCb"
                  name="following"
                  onChange={onChange}
                  type="checkbox"
                />
                <label htmlFor="followingCb">
                  Set all next action dates to the following date:
                  <DatePicker
                    className="form-control"
                    dateFormat="MM/DD/YYYY"
                    disabled={!formData.get('following', false)}
                    dropdownMode="select"
                    onChange={onDateChange}
                    selected={formData.get('followingDate')}
                    showDayInMonthOnly
                    showMonthDropdown
                    showYearDropdown
                  />
                </label>
              </div>
            </FormGroup>
          </div>
        </div>
      </div>
      {errorContent}
    </Popup>
  );
};

PushNextActionDatesPopup.propTypes = {
  formData: PropTypes.instanceOf(Immutable.Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default PushNextActionDatesPopup;
