import { fromJS, List } from 'immutable';

import {
  CHANGE_INDUSTRY_LABEL,
  SAVING_INDUSTRY,
  SAVED_INDUSTRY_FAILURE,
  SAVED_INDUSTRY_SUCCESS,
} from '../../actions/project/industryPopup';
import getErrorResponse from '../../utils/getErrorResponse';

const defaultState = fromJS({
  industryLabel: '',
  status: 'edit',
  errors: [],
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case CHANGE_INDUSTRY_LABEL:
      return resetErrors(state).merge({
        industryLabel: action.label,
        status: 'edit',
      });

    case SAVING_INDUSTRY:
      return resetErrors(state).set('status', 'saving');

    case SAVED_INDUSTRY_FAILURE:
      return state.merge({
        errors: getErrorResponse(action.response),
        status: 'error',
      });

    case SAVED_INDUSTRY_SUCCESS:
      return resetErrors(state).set('status', 'saved');

    default:
      return defaultState;
  }
}

function resetErrors(state) {
  return state.set('errors', List());
}
