import { fromJS } from 'immutable';

import * as actions from '../actions/statuses';
import { statusesMapper } from '../utils/mappers';

const defaultState = fromJS({
  targetStatuses: [],
  buyerStatuses: [],
  executiveStatuses: [],
});

export default function statuses(state = defaultState, action) {
  switch (action.type) {
    case actions.FETCH_TARGET_STATUSES_SUCCESS:
      return state.set('targetStatuses', statusesMapper(action.response.data));

    case actions.FETCH_BUYER_STATUSES_SUCCESS:
      return state.set('buyerStatuses', statusesMapper(action.response.data));

    case actions.FETCH_EXECUTIVE_STATUSES_SUCCESS:
      return state.set('executiveStatuses', statusesMapper(action.response.data));

    default:
      return state;
  }
}
