import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';

import Checkbox from '../../helpers/Checkbox';

/**
 * Stateless component for showing filter panel.
 *
 * @param props {Object}.
 * @param props.filters {Immutable.Map} Filter data.
 * @returns {React.Component} Component.
 */
const FilterList = props => {
  const { filters, capitalizeLabel = true } = props;

  if (!filters || filters.size === 0) {
    return null;
  }

  const filterName = `${filters.get('name')}:`;
  const content = filters.get('filters').map((f, i) => {
    const text = f.get('text');
    const name = f.get('name');
    const checked = f.get('checked', false);

    return (
      <Checkbox
        key={i}
        capitalizeLabel={capitalizeLabel}
        defaultChecked={checked}
        id={name}
        label={text}
        name={name}
        value={name}
      />
    );
  });

  return (
    <div className="mb5">
      <p>{filterName}</p>
      {content}
    </div>
  );
};

FilterList.propTypes = {
  capitalizeLabel: PropTypes.bool,
  filters: PropTypes.instanceOf(Map),
};

export default FilterList;
