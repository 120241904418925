import React from 'react';
import PropTypes from 'prop-types';

const EmptyRow = props => (
  <td className="text-center" colSpan={props.colSpan}>
    {props.text}
  </td>
);

EmptyRow.propTypes = {
  colSpan: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

export default EmptyRow;
