import { CALL_API } from '../middleware/api';

export const SHOW_POPUP = Symbol('SHOW_POPUP');
export const HIDE_POPUP = Symbol('HIDE_POPUP');
export const FETCHING_POPUP = Symbol('FETCHING_POPUP');
export const FETCHING_POPUP_SUCCESS = Symbol('FETCHING_POPUP_SUCCESS');
export const FETCHING_POPUP_FAILURE = Symbol('FETCHING_POPUP_FAILURE');
export const UPDATE_POPUP_FORM_DATA = Symbol('UPDATE_POPUP_FORM_DATA');

export function showPopup({ name = null, props = {} }) {
  return {
    type: SHOW_POPUP,
    name,
    props,
  };
}

export function hidePopup() {
  return {
    type: HIDE_POPUP,
  };
}

export function handleStartPopupLoading() {
  return {
    type: FETCHING_POPUP,
  };
}

export function handleEndPopupLoading(error) {
  return error
    ? {
        type: FETCHING_POPUP_FAILURE,
        error,
      }
    : {
        type: FETCHING_POPUP_SUCCESS,
      };
}

export function handleUpdatePopupFormData(formData) {
  return {
    type: UPDATE_POPUP_FORM_DATA,
    formData,
  };
}

export function handleGetUsersSuggestion(value, afterSuccess) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/users?like=${value}`,
      afterSuccess,
    },
  };
}

export function handleGetHarvcoLeadSuggestion(value, afterSuccess, afterError) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/users_by_role?like=${value}&role=director`,
      afterSuccess,
      afterError: err => {
        if (afterError) afterError(err);
      },
    },
  };
}

export function handleGetClientLeadSuggestion(buyerId, value, afterSuccess) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/buyers/${buyerId}/people?like=${value}`,
      afterSuccess,
    },
  };
}

export function handleGetExecutiveSuggestion(value, afterSuccess) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/execs?like=${value}`,
      afterSuccess,
    },
  };
}

/**
 * Load tradeshows by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findTradeShows({ filter, page, afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/tradeshows',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        page,
        limit: 100,
        like: filter,
      },
      afterSuccess,
    },
  };
}

/**
 * Loads trade show selected from suggests list.
 *
 * @param id Trade show id.
 * @param afterSuccess Success callback function.
 * @returns Action.
 */
export function loadSelectedTradeShow(id, afterSuccess) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/tradeshows/${id}`,
      afterSuccess,
    },
  };
}

/**
 * Assign targets to selected TradeShow event.
 *
 * @param {object} params Params.
 * @param {number} params.tradeShowId Trade show id.
 * @param {string} params.eventId Event id.
 * @param {number} params.targetsIds Targets ids.
 * @param {Function} params.afterSuccess Success callback function.
 * @returns Action.
 */
export function assignTradeShowToTarget({ tradeShowId, eventId, targetsIds, afterSuccess }) {
  const data = {
    trade_show_id: tradeShowId,
    trade_show_event_id: Number(eventId),
    selected_ids: targetsIds,
  };

  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/browse/tradeshows`,
      body: { data },
      afterSuccess,
    },
  };
}
