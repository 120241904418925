import React, { PureComponent } from 'react';
import { List, Map } from 'immutable';
import outsideClick from 'react-click-outside';
import classNames from 'classnames';
import { ContextMenuTrigger } from 'react-contextmenu';
import PropTypes from 'prop-types';

import config from '../../../../../config';
import { unwrap, isDeepChanged } from '../../../../../utils/ChangeSpy';
import Table from '../../../../helpers/AgGridTable';
import { DeleteItem, getTotalStats } from '../../AddOnProject/MidPanel/Approvals';
import getPercent from '../../../../../utils/getPercent';
import DateEditor from './DateEditor';
import TextEditor from './TextEditor';
import AgHeader from './AgHeader';

/**
 * To fix issue: "Cannot refresh cells with cellRendererFramework".
 */
class GeneralFormat extends PureComponent {
  constructor(props) {
    super(props);
    this.collect = this.collect.bind(this);
  }

  collect() {
    return {
      rowIndex: this.props.rowIndex,
      rowData: this.props.data,
    };
  }

  render() {
    const {
      value,
      column: { colId },
      data,
      colDef,
    } = this.props;

    const isNoApproval = data.id === 'no_approval_lists';

    const divClassName = classNames('ag-cell ag-cell-not-inline-editing', {
      'cell cell--marked': isDeepChanged(data),
      'no-approval-list__row': isNoApproval,
    });
    let content = null;

    if (colId === 'dateReceived' || colId === 'dateSent') {
      const dt = value.value;

      content = <div className={divClassName}>{dt && dt.isValid() ? dt.format('MM/DD/YYYY') : ''}</div>;
    } else if (colId === 'applistLabel') {
      content = <div className={divClassName}>{value.value}</div>;
    } else {
      content = <div className={divClassName}>{value}</div>;
    }

    if (isNoApproval) return content;

    return (
      <ContextMenuTrigger collect={this.collect} id={colDef.contextMenuId}>
        {content}
      </ContextMenuTrigger>
    );
  }
}

/**
 * Approval list component.
 *
 * @param props {Object}.
 * @param approvals {Immutable.List} Approval list.
 * @param common {Immutable.Map} Common object.
 * @param approvalColumns {Immutable.List} Approval column configuration.
 * @param onInsert {Function} Handle event of adding a new row.
 * @param onChange {Function} Handle event of switching a row into edit mode.
 * @param onClick {Function} Handle event of clicking on a row.
 * @param onRowDoubleClicked {Function} Handle event of double clicking on a row.
 * @param onSort {Function} Handle event of sorting a column.
 * @param {boolean} canEditData Whether user can edit any active element that can be changed or clicked.
 * @returns {React.Component}
 * @constructor
 */
class Approvals extends PureComponent {
  constructor(props) {
    super(props);
    this.onGridReady = this.onGridReady.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this);
    this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
    this.onInsert = this.onInsert.bind(this);
    this.onSort = this.onSort.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onContextMenuClick = this.onContextMenuClick.bind(this);
    this.onRowValueChanged = this.onRowValueChanged.bind(this);
    this.mapApprovalsData = this.mapApprovalsData.bind(this);
    this.actions = List([
      Map({
        key: 'delete',
        name: DeleteItem,
      }),
    ]);
    /**
     * Ag-grid doesn't support a way to prevent editing mode when users hit any "Printable Key Pressed".
     * So we use "cellClickToEdit" variable to only allow editing mode from mouse click event.
     */
    this.checkEditable = this.checkEditable.bind(this);
    this.preventEditing = this.preventEditing.bind(this);
    this.cellClickToEdit = false;

    this.columns = config.tables.getIn(['project', 'approval', 'columns']).map(col => {
      const field = col.get('field');

      if (field === 'dateReceived') {
        return col.merge({
          editable: this.checkEditable,
          cellRendererFramework: GeneralFormat,
          headerComponentFramework: AgHeader,
          sortable: true,
          cellEditorFramework: DateEditor,
        });
      }

      if (field === 'dateSent') {
        return col.merge({
          editable: this.checkEditable,
          cellRendererFramework: GeneralFormat,
          headerComponentFramework: AgHeader,
          sortable: true,
          cellEditorFramework: DateEditor,
        });
      }

      if (field === 'applistLabel') {
        return col.merge({
          editable: this.checkEditable,
          cellEditorFramework: TextEditor,
          headerComponentFramework: AgHeader,
          onClick: this.onInsert,
          sortable: true,
          cellRendererFramework: GeneralFormat,
        });
      }

      if (field === 'numberApproved') {
        return col.merge({
          headerComponentFramework: AgHeader,
          cellRendererFramework: GeneralFormat,
          headerTooltip: 'Approved% = #Approved / #Targets',
        });
      }

      if (field === 'numberOfContacted') {
        return col.merge({
          headerComponentFramework: AgHeader,
          cellRendererFramework: GeneralFormat,
          headerTooltip: 'Contacted% = #Contacted / #Approved',
        });
      }

      if (field === 'numberOfLeads') {
        return col.merge({
          headerComponentFramework: AgHeader,
          cellRendererFramework: GeneralFormat,
          headerTooltip: 'Leads% = #Leads / #Approved',
        });
      }

      if (field === 'numberOfCc') {
        return col.merge({
          headerComponentFramework: AgHeader,
          cellRendererFramework: GeneralFormat,
          headerTooltip: 'CC% = #CC / #Approved',
        });
      }

      if (field === 'numberOfVisit') {
        return col.merge({
          headerComponentFramework: AgHeader,
          cellRendererFramework: GeneralFormat,
          headerTooltip: 'Visit% = #Visit / #Approved',
        });
      }

      if (field === 'numberOfNextActions') {
        return col.merge({
          headerComponentFramework: AgHeader,
          cellRendererFramework: GeneralFormat,
          headerTooltip: 'NAs% = #NAs / #Targets',
        });
      }

      if (field === 'numberOfPriorityA') {
        return col.merge({
          headerComponentFramework: AgHeader,
          cellRendererFramework: GeneralFormat,
          headerTooltip: 'A% = #A / #Targets',
        });
      }

      if (field === 'numberOfPriorityB') {
        return col.merge({
          headerComponentFramework: AgHeader,
          cellRendererFramework: GeneralFormat,
          headerTooltip: 'B% = #B / #Targets',
        });
      }

      if (field === 'numberOfPriorityC') {
        return col.merge({
          headerComponentFramework: AgHeader,
          cellRendererFramework: GeneralFormat,
          headerTooltip: 'C% = #C / #Targets',
        });
      }

      if (field === 'numberOfApprovedX') {
        return col.merge({
          headerComponentFramework: AgHeader,
          cellRendererFramework: GeneralFormat,
          headerTooltip: 'X% = #X / #Targets',
        });
      }

      return col.merge({
        headerComponentFramework: AgHeader,
        sortable: true,
        cellRendererFramework: GeneralFormat,
      });
    });
  }

  componentDidUpdate() {
    const { approvals } = this.props;

    if (this.gridApi) {
      this.gridApi.setPinnedBottomRowData(getTotalStats(approvals));
      this.gridApi.sizeColumnsToFit();
      this.gridApi.refreshCells({ force: true });
    }

    if (approvals.size && !unwrap(approvals.getIn([approvals.size - 1, 'id'])) && this.gridApi) {
      const rowIndex = approvals.size - 1;

      this.gridApi.startEditingCell({ rowIndex, colKey: 'applistLabel' });
      this.gridApi.setFocusedCell(rowIndex, 'applistLabel');
      this.gridApi.ensureIndexVisible(rowIndex);
    }
    this.cellClickToEdit = false;
  }

  checkEditable(params) {
    if (params.node.data.id !== 'no_approval_lists') {
      return this.cellClickToEdit;
    }
  }

  preventEditing() {
    this.cellClickToEdit = false;
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-unused-expressions
    this.gridApi && this.gridApi.removeEventListener('rowEditingStarted', this.preventEditing);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.addEventListener('rowEditingStarted', this.preventEditing);
  }

  onCellClicked(e) {
    if (!this.props.canEditData) {
      return;
    }

    const {
      column: { colId },
      node: { rowIndex },
    } = e;

    // Don't turn to editing mode when grid is loading
    if (this.props.common.get('approvalLoading')) return;

    if (colId === 'applistLabel' || colId === 'dateReceived' || colId === 'dateSent') {
      this.cellClickToEdit = true;
      this.gridApi.startEditingCell({
        rowIndex,
        colKey: colId,
      });
    } else {
      this.gridApi.stopEditing();
    }
  }

  onRowDoubleClicked(params) {
    if (!this.props.canEditData) {
      return;
    }

    const { onRowDoubleClick } = this.props;

    if (params.data.id > 0) {
      onRowDoubleClick({
        row: Map({
          id: params.data.id,
          projectId: params.data.projectId,
        }),
        type: 'approvals',
      });
    } else if (params.data.id === 'no_approval_lists') {
      onRowDoubleClick({
        row: Map({
          id: 'no_approval_lists',
          projectId: params.data.projectId,
        }),
        type: 'approvals',
      });
    }
  }

  onInsert() {
    if (!this.props.canEditData) {
      return;
    }

    if (!this.gridApi) return;
    this.gridApi.stopEditing();

    let rowIndex = -1;

    this.gridApi.forEachNode(rowNode => {
      if (rowNode.data.id === 0) {
        rowIndex = rowNode.rowIndex;

        return false;
      }

      return true;
    });
    if (rowIndex === -1) {
      const transaction = this.gridApi.updateRowData({
        add: [
          {
            id: 0,
            applistLabel: { value: '' },
            dateReceived: { value: null },
            dateSent: { value: null },
            numberApproved: 0,
            numberCalled: 0,
            numberMailed: 0,
            numberTargets: 0,
            percentApproved: 0,
            percentCalled: 0,
            percentMailed: 0,
          },
        ],
      });

      rowIndex = transaction.add[0].rowIndex;
    }
    this.cellClickToEdit = true;
    this.gridApi.startEditingCell({ rowIndex, colKey: 'applistLabel' });
  }

  onSort(e) {
    if (!this.props.canEditData) {
      return;
    }

    if (this.gridApi) {
      this.gridApi.stopEditing(true);
    }

    if (e && e.length) {
      this.props.onSort(e, {
        type: 'approvals',
        direction: e[0].sort === 'asc' ? 'down' : 'up',
        field: e[0].colId,
      });
    }
  }

  handleClickOutside(event) {
    if (this.gridApi) {
      if (!/react-datepicker/.test(event.target.className)) {
        // Don't stop editing when users click on datepicker.
        this.gridApi.stopEditing();
      }
    }
  }

  getRowNodeId(data) {
    return data.index;
  }

  onContextMenuClick(event, data) {
    if (!this.props.canEditData) {
      return;
    }

    const { id, numberTargets } = data.rowData;

    if (id !== 'no_approval_lists') {
      this.props.onDelete(event, id, numberTargets);
    }
  }

  onRowValueChanged({ data }) {
    if (data.id > 0 || data.mode === 'edit') {
      this.props.onChange({
        type: 'approvals',
        rowIndex: data.index,
        field: 'applistLabel',
        value: data.applistLabel.value,
      });
      this.props.onChange({
        type: 'approvals',
        rowIndex: data.index,
        field: 'dateReceived',
        value: data.dateReceived.value,
      });
      this.props.onChange({
        type: 'approvals',
        rowIndex: data.index,
        field: 'dateSent',
        value: data.dateSent.value,
      });
    } else if (data.id === 0 && (data.applistLabel.value || data.dateReceived.value || data.dateSent.value)) {
      this.props.onRowInsert({
        applistLabel: data.applistLabel.value,
        dateReceived: data.dateReceived.value,
        dateSent: data.dateSent.value,
      });
    }
  }

  mapApprovalsData() {
    return this.props.approvals.map(approval => {
      const percentApproved = `${approval.get('percentApproved')}%`;
      const numberTargets = approval.get('numberTargets');
      const numberApproved = approval.get('numberApproved');
      const numberOfContacted = approval.get('numberOfContacted');
      const numberOfLeads = approval.get('numberOfLeads');
      const numberOfCc = approval.get('numberOfCc');
      const numberOfVisit = approval.get('numberOfVisit');
      const numberOfNextActions = approval.get('numberOfNextActions');
      const numberOfPriorityA = approval.get('numberOfPriorityA');
      const numberOfPriorityB = approval.get('numberOfPriorityB');
      const numberOfPriorityC = approval.get('numberOfPriorityC');
      const numberOfApprovedX = approval.get('numberOfApprovedX');

      return approval
        .set('numberApproved', `${numberApproved} (${percentApproved})`)
        .set('numberOfContacted', `${numberOfContacted} (${getPercent(numberOfContacted, numberApproved)}%)`)
        .set('numberOfLeads', `${numberOfLeads} (${getPercent(numberOfLeads, numberApproved)}%)`)
        .set('numberOfCc', `${numberOfCc} (${getPercent(numberOfCc, numberApproved)}%)`)
        .set('numberOfVisit', `${numberOfVisit} (${getPercent(numberOfVisit, numberApproved)}%)`)
        .set('numberOfNextActions', `${numberOfNextActions} (${getPercent(numberOfNextActions, numberTargets)}%)`)
        .set('numberOfPriorityA', `${numberOfPriorityA} (${getPercent(numberOfPriorityA, numberTargets)}%)`)
        .set('numberOfPriorityB', `${numberOfPriorityB} (${getPercent(numberOfPriorityB, numberTargets)}%)`)
        .set('numberOfPriorityC', `${numberOfPriorityC} (${getPercent(numberOfPriorityC, numberTargets)}%)`)
        .set('numberOfApprovedX', `${numberOfApprovedX} (${getPercent(numberOfApprovedX, numberTargets)}%)`);
    });
  }

  render() {
    const { common } = this.props;
    const approvals = this.mapApprovalsData();

    return (
      <div className="col-md-8 col-approvals full-height">
        <div className="table-edit ag-approval full-height">
          <Table
            columnDefs={this.columns}
            contextActionList={this.actions}
            editType="fullRow"
            getRowNodeId={this.getRowNodeId}
            isFetching={common.get('approvalLoading')}
            onCellClicked={this.onCellClicked}
            onContextMenuClick={this.onContextMenuClick}
            onGridReady={this.onGridReady}
            onRowDoubleClicked={this.onRowDoubleClicked}
            onRowValueChanged={this.onRowValueChanged}
            onSortModelChanged={this.onSort}
            rowData={approvals}
            sortable
          />>
        </div>
      </div>
    );
  }
}

Approvals.propTypes = {
  approvals: PropTypes.instanceOf(List).isRequired,
  canEditData: PropTypes.bool.isRequired,
  common: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onRowDoubleClicked: PropTypes.func,
  onSort: PropTypes.func.isRequired,
};

export default outsideClick(Approvals);
