import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List, fromJS } from 'immutable';
import moment from 'moment';
import MarkMailingCompletedPopup from '../../components/Popup/MarkMailingCompletedPopup';
import targetStatuses from '../../utils/targetStatuses';
import { fetchTargetStatuses } from '../../actions/statuses';

class MarkMailingCompletedPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      statuses: List(),
    };
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.props.fetchTargetStatuses(({ response: { data } }) => {
      this.setState({
        statuses: targetStatuses(fromJS(data)),
      });
    });

    this.context.updatePopupFormData({
      date: moment(),
      status: {
        selected: {
          name: '4',
          value: '4',
        },
      },
    });
  }

  handleDateChange({ target }) {
    if (target.value.isValid()) {
      this.context.updatePopupFormData({
        [target.name]: target.value,
      });
    }
  }

  handleStatusChange(event) {
    const { value } = event.target;

    this.context.updatePopupFormData({
      status: {
        selected: {
          name: value,
          value,
        },
      },
    });
  }

  handleSuccessResponse({ errors }) {
    if (errors && errors.length > 0) {
      const updateListStatuses = this.props.popup.getIn(['props', 'updateListStatuses'], () => {});
      const eventIds = errors.map(({ eventId }) => eventId);
      let alstr = '';

      alstr = errors.reduce((acc, error) => {
        acc += `${error.error}\n`;

        return acc;
      }, '');

      this.context.openPopup('ConfirmPopup', {
        message: alstr,
        header: 'The state of the list has been changed',
        submessage: 'Click “Update” to remove these targets from the list',
        yes: 'Update',
        no: 'Cancel',
        onOk: () => {
          updateListStatuses(eventIds);
          this.context.closePopup();
        },
        onCancel: () => this.context.closePopup(),
      });
    }
  }

  handleUpdate() {
    const { popup } = this.props;
    const onUpdate = popup.getIn(['props', 'onUpdate'], () => {});
    const refreshGrid = popup.getIn(['props', 'refreshGrid'], () => {});

    onUpdate(popup.get('formData'), {
      beforeStart: this.context.startPopupLoading,
      afterSuccess: ({ response }) => {
        this.context.endPopupLoading();
        refreshGrid();
        this.handleSuccessResponse(response.skipped);
      },
      skipGlobalErrorHandler: true,
      afterError: ({ resBody }) => {
        this.context.endPopupLoading(resBody);
      },
    });
  }

  handleClose() {
    this.context.closePopup();
  }

  render() {
    const { popup } = this.props;
    const { statuses } = this.state;

    return (
      <div>
        <MarkMailingCompletedPopup
          formData={popup.get('formData')}
          isPopupFetching={popup.get('isPopupFetching')}
          isPopupFetchingError={popup.get('isPopupFetchingError')}
          onClose={this.handleClose}
          onDateChange={this.handleDateChange}
          onStatusChange={this.handleStatusChange}
          onUpdate={this.handleUpdate}
          statusCodes={statuses}
        />
      </div>
    );
  }
}

MarkMailingCompletedPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  updatePopupFormData: PropTypes.func.isRequired,
  startPopupLoading: PropTypes.func.isRequired,
  endPopupLoading: PropTypes.func.isRequired,
};

export { MarkMailingCompletedPopupContainer };
export default connect(
  (state, props) => ({
    ...props,
  }),
  { fetchTargetStatuses },
)(MarkMailingCompletedPopupContainer);
