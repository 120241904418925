import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Map, fromJS } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import Popups from '../../components/Popup';
import { getChanged, isDeepChanged, getIn, unwrap } from '../../utils/ChangeSpy';
import * as TARGET from '../../actions/company/companyTarget';
import * as BUYER from '../../actions/company/buyer/marketing';
import config from '../../config';
import { getBirthYear } from '../../utils/yearAgeConverter';
import AutoComplete from '../AutoComplete';
import getPhoneChanged, {
  checkPhoneIfNeeded,
  formatPhoneIfNeeded,
  checkExtensionIfNeeded,
  checkCountryIfNeeded,
} from '../services/phoneRules';
import stripOffChars from '../../utils/stripOffChars';
import { checkNameChangable, checkFirstName, checkLastName, parseFullName } from '../services/nameRules';

const prefixes = config.values.getIn(['contact', 'prefixes']);
const suffixes = config.values.getIn(['contact', 'suffixes']);

class AddCompanyContactPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.onUndo = this.onUndo.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onFetchTitles = this.onFetchTitles.bind(this);
    this.onUpdateTitle = this.onUpdateTitle.bind(this);
    this.onSelectTitle = this.onSelectTitle.bind(this);
    this.onChangeSuggestion = this.onChangeSuggestion.bind(this);
    this.onFindEmail = this.onFindEmail.bind(this);
    this.updateFullName = this.updateFullName.bind(this);
    this.updateFirstName = this.updateFirstName.bind(this);
    this.updateLastName = this.updateLastName.bind(this);
    this.onExtensionBlur = this.onExtensionBlur.bind(this);
    this.onCountryBlur = this.onCountryBlur.bind(this);
    this.onPhoneBlur = this.onPhoneBlur.bind(this);
    this.setError = this.setError.bind(this);
    this.clearAllError = this.clearAllError.bind(this);
    this.state = {
      fullName: '',
      firstName: '',
      lastName: '',
      country: '',
      extension: '',
      unformattedPhone: '',
    };
    this.changable = {
      fullName: false,
      firstName: false,
      lastName: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { phoneCodes, loadCountryPhoneCodes } = this.props;

    if (!phoneCodes.size) {
      loadCountryPhoneCodes();
    }
  }

  componentDidMount() {
    this.context.onClosePopup(() => {
      const field = this.props.pathPrefix.slice(0, -1);

      this.props.revertUpdate({ field });
    });
  }

  getChildContext() {
    return {
      onErrorClose: () => {
        this.clearAllError();
      },
      inputErrors: fromJS(this.state),
    };
  }

  onChange({ target: { name, value } }) {
    const { phoneCodes, pathPrefix, handleUpdateCompany } = this.props;

    switch (name) {
      case 'country': {
        const index = phoneCodes.findIndex(p => p.get('code') === value);

        if (index > -1) {
          handleUpdateCompany({
            name: `${pathPrefix}code`,
            value: phoneCodes.getIn([index, 'dialCode']),
          });
        }
        break;
      }

      case 'firstName':
        this.updateFirstName(value);

        return;

      case 'lastName':
        this.updateLastName(value);

        return;

      case 'fullName':
        this.updateFullName(value);

        return;

      default:
        break;
    }

    return handleUpdateCompany({
      name: `${pathPrefix}${name}`,
      value: name === 'extension' ? stripOffChars(value, '', /[^\d]/gi) : value,
    });
  }

  onUndo() {
    this.props.handleUpdateCompany({
      name: `${this.props.pathPrefix}type`,
      value: 'Direct',
    });
  }

  onBlur(event) {
    const {
      target: { name, value },
    } = event;
    const { pathPrefix, handleUpdateCompany } = this.props;

    if (name === 'unformattedPhone') {
      this.onPhoneBlur(event);
    } else if (name === 'extension') {
      this.onExtensionBlur(event);
    } else if (name === 'country') {
      this.onCountryBlur(event);
    } else if (name === 'firstName' && !checkFirstName(value)) {
      this.setError({ firstInitialsName: true });
      handleUpdateCompany({
        name: `${pathPrefix}firstName`,
        value: '',
      });
    } else if (name === 'lastName' && !checkLastName(value)) {
      this.setError({ lastSuffixesName: true });
    }
  }

  onFocus({ target: { name } }) {
    const { contact } = this.props;
    const firstName = unwrap(contact.get('firstName') || '');
    const lastName = unwrap(contact.get('lastName') || '');
    const fullName = unwrap(contact.get('fullName') || '');

    if (['firstName', 'lastName', 'fullName'].indexOf(name) > -1) {
      this.changable = checkNameChangable(name, {
        firstName,
        lastName,
        fullName,
      });
    }
  }

  onPhoneBlur({ target: { value } }) {
    const type = unwrap(this.props.contact.get('type', ''));

    if (checkPhoneIfNeeded(type, value)) {
      this.onChange({
        target: {
          name: 'unformattedPhone',
          value: formatPhoneIfNeeded(type, value),
        },
      });
      this.setError({ unformattedPhone: false });
    } else {
      this.setError({ unformattedPhone: true });
    }
  }

  onExtensionBlur({ target: { value } }) {
    const type = unwrap(this.props.contact.get('type', ''));

    if (checkExtensionIfNeeded(type, value)) {
      this.setError({ extension: false });
    } else {
      this.setError({ extension: true });
    }
  }

  onCountryBlur({ target: { value } }) {
    const type = unwrap(this.props.contact.get('type', ''));

    if (checkCountryIfNeeded(type, value)) {
      this.setError({ country: false });
    } else {
      this.setError({ country: true });
    }
  }

  setError({
    unformattedPhone,
    firstName,
    lastName,
    fullName,
    extension,
    country,
    lastSuffixesName,
    firstInitialsName,
  }) {
    const errors = { ...this.state };

    if (unformattedPhone === true) {
      errors.unformattedPhone =
        'Phone must be a valid North American area code and format (###-###-####) or +[numbers, spaces and dashes] if international';
    } else if (unformattedPhone === false) {
      errors.unformattedPhone = '';
    }

    if (extension === true) {
      errors.extension = 'The extension field is required when type is Extension.';
    } else if (extension === false) {
      errors.extension = '';
    }

    if (country === true) {
      errors.country = 'The country field is required when type is International.';
    } else if (country === false) {
      errors.country = '';
    }

    if (firstName === true) {
      errors.firstName = 'The field is required.';
    } else if (firstName === false) {
      errors.firstName = '';
    }

    if (firstInitialsName === true) {
      errors.firstName = 'Enter only the first name with no initials, etc.';
    } else if (firstInitialsName === false) {
      errors.firstName = firstName ? errors.firstName : '';
    }

    if (lastName === true) {
      errors.lastName = 'The field is required.';
    } else if (lastName === false) {
      errors.lastName = '';
    }

    if (lastSuffixesName === true) {
      errors.lastName = 'Enter only the last name with no initials, suffixes, etc.';
    } else if (lastSuffixesName === false) {
      errors.lastName = lastName ? errors.lastName : '';
    }

    if (fullName === true) {
      errors.fullName = 'The field is required.';
    } else if (fullName === false) {
      errors.fullName = '';
    }

    this.setState(errors);
  }

  clearAllError() {
    this.setError({
      unformattedPhone: false,
      extension: false,
      country: false,
      firstName: false,
      firstInitialsName: false,
      lastSuffixesName: false,
      lastName: false,
      fullName: false,
    });
  }

  updateFirstName(name) {
    const { contact, pathPrefix, handleUpdateCompany } = this.props;
    const tmp = this.trimDoubleSpaces(name);

    const firstName = unwrap(contact.get('firstName') || '');
    let fullName = unwrap(contact.get('fullName') || '');

    fullName = fullName.indexOf(firstName) > -1 ? fullName.substring(firstName.length) : fullName;

    if (this.changable.firstName) {
      handleUpdateCompany({
        name: `${pathPrefix}firstName`,
        value: tmp,
      });
    }

    if (this.changable.fullName) {
      handleUpdateCompany({
        name: `${pathPrefix}fullName`,
        value: this.trimDoubleSpaces(`${tmp} ${fullName}`),
      });
    }
  }

  updateLastName(name) {
    const { contact, pathPrefix, handleUpdateCompany } = this.props;
    const tmp = this.trimDoubleSpaces(name);

    const lastName = unwrap(contact.get('lastName') || '');
    let fullName = unwrap(contact.get('fullName') || '');

    fullName = fullName.lastIndexOf(lastName) > -1 ? fullName.substring(0, fullName.lastIndexOf(lastName)) : fullName;

    if (this.changable.lastName) {
      handleUpdateCompany({
        name: `${pathPrefix}lastName`,
        value: tmp,
      });
    }

    if (this.changable.fullName) {
      handleUpdateCompany({
        name: `${pathPrefix}fullName`,
        value: this.trimDoubleSpaces(`${fullName} ${tmp}`),
      });
    }
  }

  updateFullName(name) {
    const { pathPrefix, handleUpdateCompany } = this.props;
    const arrayStr = parseFullName(name);

    if (this.changable.firstName) {
      handleUpdateCompany({
        name: `${pathPrefix}firstName`,
        value: arrayStr[0],
      });
    }

    if (this.changable.lastName) {
      handleUpdateCompany({
        name: `${pathPrefix}lastName`,
        value: arrayStr[1],
      });
    }

    if (this.changable.fullName) {
      handleUpdateCompany({
        name: `${pathPrefix}fullName`,
        value: arrayStr[2],
      });
    }
  }

  trimDoubleSpaces(name) {
    const tmp = name || '';

    return tmp.replace(/\s{2,}/g, ' ');
  }

  onSave() {
    const errors = this.checkValidity();
    const isValid = !Object.keys(errors).some(key => errors[key] === true);

    if (isValid) {
      const { companyId, verifyEmail, contact } = this.props;
      const contactId = contact.get('id');
      const phone = unwrap(contact.get('unformattedPhone', ''));
      const saved = getChanged(contact);
      const pivot = saved.pivot || {};
      const prefix = unwrap(contact.get('prefix')).get('value');
      const suffix = unwrap(contact.get('suffix')).get('value');
      const isVerify = verifyEmail.get('status') === 'valid';
      let body = { ...pivot, ...saved, prefix, suffix, ...(isVerify && { isVerify }) };

      if (phone === '') {
        delete body.unformattedPhone;
        delete body.type;
        delete body.extension;
        delete body.country;
      } else {
        body = { ...body, ...getPhoneChanged(contact) };
      }

      if ('age' in body) {
        body.birthYear = getBirthYear(body.age);
      }
      this.moveOut(body);
      this.props.saveCompanyContact({ companyId, contactId, isNew: true, body, saved }, () => {
        this.context.closePopup();
      });

      return;
    }
    this.setError(errors);
  }

  /**
   * Api request needs ['advTitle', 'advWeb', 'company'] parameters stay outside of pivot object.
   */
  moveOut(body) {
    if (body.pivot) {
      ['advTitle', 'advWeb', 'company'].forEach(key => {
        if (body.pivot[key]) {
          body[key] = body.pivot[key];
          delete body.pivot[key];
        }

        return true;
      });
    }
  }

  checkValidity() {
    const errors = {
      unformattedPhone: false,
      extension: false,
      country: false,
      fullName: false,
      firstName: false,
      lastName: false,
    };
    const required = ['fullName', 'firstName', 'lastName'];
    const { contact } = this.props;
    const phone = unwrap(contact.get('unformattedPhone', ''));
    const extension = unwrap(contact.get('extension', ''));
    const country = unwrap(contact.get('country', ''));
    const type = unwrap(contact.get('type', ''));

    required.forEach(field => {
      errors[field] = unwrap(this.props.contact.get(field)) === '';
    });

    if (phone !== '') {
      errors.unformattedPhone = !checkPhoneIfNeeded(type, phone);
      errors.extension = !checkExtensionIfNeeded(type, extension);
      errors.country = !checkCountryIfNeeded(type, country);
    }

    return errors;
  }

  static onGetValue(suggestion) {
    return suggestion.value;
  }

  onFetchTitles({ value }) {
    this.props.findTitles({ filter: value, prefix: 'newContact' });
  }

  onUpdateTitle(param) {
    if ('text' in param) {
      this.onSelectTitle(null, { suggestion: { text: param.text } });
    }

    if ('suggestions' in param) {
      const name = 'suggests';
      const value = param.suggestions;

      this.props.handleUpdateCompany({
        name: `${this.props.pathPrefix}${name}`,
        value,
      });
    }
  }

  onSelectTitle(e, { suggestion }) {
    this.props.handleUpdateCompany({
      name: `${this.props.pathPrefix}pivot.title`,
      value: suggestion.text,
    });
  }

  getSuggestProps() {
    const value = this.props.contact.getIn(['pivot', 'title'], '');
    const text = unwrap(value);

    return {
      onFetch: this.onFetchTitles,
      suggestions: this.props.contact.getIn(['suggestions']),
      onUpdate: this.onUpdateTitle,
      getValue: AddCompanyContactPopupContainer.onGetValue,
      onSelect: this.onSelectTitle,
      value,
      showExtraInfo: text && text.toUpperCase() === 'ADVISOR',
      loading: this.props.loading,
    };
  }

  onChangeSuggestion(obj) {
    if (typeof obj.value === 'string') {
      const { value } = obj;
      const nextValue = this.props.suffixes.find(
        suffix => suffix.get('name') === value,
        null,
        Map({
          name: value || '',
          value: value || '',
        }),
      );

      this.props.handleUpdateCompany({
        name: `${this.props.pathPrefix}${'suffix'}`,
        value: nextValue,
      });
    }
  }

  getSuffixesSuggest() {
    const value = getIn(this.props.contactInfo, 'suffix').get('name');
    const idValue = value;
    const regExp = new RegExp(String(value).replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1'), 'i');
    const className = classNames('form-control input-sm', {
      changed: isDeepChanged(this.props.contactInfo.get('suffix')),
    });
    const suggestions = this.props.suffixes
      .filter(suffix => regExp.test(suffix.get('name')))
      .map(suffix => ({
        id: suffix.get('value'),
        text: suffix.get('name'),
      }));

    return (
      <AutoComplete
        change={this.onChangeSuggestion}
        find={args => {
          setTimeout(
            () =>
              args.afterSuccess({
                response: {
                  data: this.props.suffixes
                    .filter(suffix => regExp.test(suffix.get('name')))
                    .map(suffix => ({
                      id: suffix.get('value'),
                      text: suffix.get('name'),
                    })),
                },
              }),
            0,
          );
        }}
        idValue={idValue}
        inputProps={{ className }}
        loading={false}
        name="suffix"
        suggestions={suggestions}
        suggestsName="suffixes"
        value={value}
        suggestIfEmpty
      />
    );
  }

  onFindEmail() {
    this.context.openPopup('EmailFinderPopup', this.props);
  }

  render() {
    const { children, contact, prefixes, suffixes, phoneCodes } = this.props;

    return (
      <div>
        <Popups.AddCompanyContactPopup
          contact={contact}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onFindEmail={this.onFindEmail}
          onFocus={this.onFocus}
          onSave={this.onSave}
          onUndo={this.onUndo}
          phoneCodes={phoneCodes}
          prefixes={prefixes}
          suffixes={suffixes}
          suffixSuggest={this.getSuffixesSuggest()}
          titleProps={this.getSuggestProps()}
        />
        {children}
      </div>
    );
  }
}

AddCompanyContactPopupContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
};

AddCompanyContactPopupContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};

function mapStateToProps(state, props) {
  const isBuyer = props.popup.getIn(['props', 'mode']) === 'buyer';
  const root = isBuyer ? state.targetCompany.buyer.marketing : state.targetCompany.target;
  const contact = root.get('newContact');
  const phoneCodes = root.get('phoneCodes');

  return {
    companyId: state.targetCompany.info.getIn(['info', 'id']),
    inputErrors: contact.get('inputErrors', Map()),
    pathPrefix: 'newContact.',
    prefixes,
    suffixes,
    contact,
    contactInfo: contact,
    loading: contact.get('loading'),
    phoneCodes,
    verifyEmail: state.verifyEmail,
  };
}

function mapDispatchToProps(dispatch, props) {
  const isBuyer = props.popup.getIn(['props', 'mode']) === 'buyer';

  if (isBuyer) {
    const {
      handleUpdateCompany,
      saveCompanyContact,
      revertUpdate,
      closeValidationError,
      addValidationError,
      findTitles,
    } = BUYER;

    return bindActionCreators(
      {
        handleUpdateCompany,
        saveCompanyContact,
        revertUpdate,
        closeValidationError,
        findTitles,
        addValidationError,
        loadCountryPhoneCodes: TARGET.loadCountryPhoneCodes,
      },
      dispatch,
    );
  }

  const {
    handleUpdateCompany,
    saveCompanyContact,
    revertUpdate,
    closeValidationError,
    addValidationError,
    findTitles,
    loadCountryPhoneCodes,
  } = TARGET;

  return bindActionCreators(
    {
      handleUpdateCompany,
      saveCompanyContact,
      revertUpdate,
      closeValidationError,
      findTitles,
      addValidationError,
      loadCountryPhoneCodes,
    },
    dispatch,
  );
}

export { AddCompanyContactPopupContainer };

const Connected = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
  connectOptions,
)(AddCompanyContactPopupContainer);

export default Connected;
