import select from 'select';

export default function(text) {
  const fakeElem = document.createElement('textarea');
  const { style } = fakeElem;

  style.fontSize = '12pt';
  style.border = '0';
  style.padding = '0';
  style.margin = '0';
  style.position = 'absolute';
  style.right = style.top = '-9999px';
  fakeElem.setAttribute('readonly', '');
  fakeElem.value = text;

  document.body.appendChild(fakeElem);
  setTimeout(() => document.body.removeChild(fakeElem), 1000);

  select(fakeElem);

  document.execCommand('copy');
}
