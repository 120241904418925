import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Map, List } from 'immutable';

import AgGridTable from '../../../helpers/AgGridTable';
import TextEditor from '../../../helpers/TextEditor';
import { handleSortModelChanged } from '../../../../utils/sorting';

class DefaultCellRenderer extends PureComponent {
  render() {
    const currentCol = this.props.colDef.field.replace('.value', '');
    const wrapperClass =
      this.props.data[currentCol].value === this.props.data[currentCol].__ORIGINAL_VALUE_KEY__ ? '' : 'changed';

    return <div className={wrapperClass}>{this.props.value}</div>;
  }
}

class CompanyBuyerCEOTable extends PureComponent {
  constructor(props) {
    super(props);

    this.gridApi = null;
    this.gridColumnApi = null;
    this.onGridReady = this.onGridReady.bind(this);
    this.handleRowDoubleClicked = this.handleRowDoubleClicked.bind(this);
    this.onSort = this.onSort.bind(this);
    this.handleCellClicked = this.handleCellClicked.bind(this);
    this.handleCellValueChanged = this.handleCellValueChanged.bind(this);
  }

  componentDidMount() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  componentDidUpdate() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
      if (this.rowNode) {
        this.gridApi.redrawRows({ rowNodes: [this.rowNode] });
        this.rowNode = null;
      }
    }

    if (this.props.data.get('focusElement')) {
      document.getElementById(this.props.data.get('focusElement')).focus();
    }
  }

  getColumnDefs() {
    if (this.props.columnDefs !== this.lastColumnDefs) {
      this.lastColumnDefs = this.props.columnDefs;
      this.lastColumnDefs = this.props.columnDefs.map(columnDef => {
        const field = columnDef.get('field');
        const editable = this.props.canEditData;

        switch (field) {
          case 'category.value':

          // fallthrough
          case 'details.value':

          // fallthrough
          case 'status.value':
            return columnDef.merge({
              cellEditorFramework: TextEditor,
              cellRendererFramework: DefaultCellRenderer,
              editable,
            });

          case 'dsplClientLeadFLName.value':
            return columnDef.merge({
              cellEditorFramework: this.props.clientLeadEditor,
              cellRendererFramework: DefaultCellRenderer,
              editable,
            });

          case 'dsplHarvcoLeadUserName.value':
            return columnDef.merge({
              cellEditorFramework: this.props.harvcoLeadEditor,
              cellRendererFramework: DefaultCellRenderer,
              editable,
            });

          default:
            return columnDef;
        }
      });
    }

    return this.lastColumnDefs;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  handleRowDoubleClicked(row) {
    this.props.onDoubleClickRow(row);
  }

  onSort(sortModel) {
    if (!this.props.canEditData) {
      return;
    }

    sortModel[0].colId = sortModel[0].colId.replace('.value', '');
    handleSortModelChanged(sortModel, this.props.data, this.props.onChange);
  }

  handleCellClicked(data) {
    if (!this.props.canEditData) {
      return;
    }

    this.gridApi.startEditingCell({
      rowIndex: data.node.rowIndex,
      colKey: data.colDef.field,
    });
  }

  handleCellValueChanged(event) {
    if (!this.props.canEditData) {
      return;
    }

    const { field } = event.colDef;

    if (
      (field === 'category.value' || field === 'details.value' || field === 'status.value') &&
      event.newValue !== event.oldValue
    ) {
      this.props.onUpdateCell({
        updateField: field.replace('.value', ''),
        newValue: event.newValue,
        data: event.data,
      });
    }
    // Save row node to redraw it later
    this.rowNode = event.node;
  }

  getRowNodeId(data) {
    return data.order;
  }

  render() {
    const { data, onDoubleClickRow, onGetNextPageData, title, onAddCEOProject } = this.props;
    const button = onAddCEOProject ? (
      <button className="addActiveProjectBtn-ceo" onClick={onAddCEOProject}>
        <i className="fa fa-plus" />
      </button>
    ) : null;

    return (
      <div className="CompanyBuyerLeadsTable full-height">
        <div className="title">{title}</div>
        {button}
        <AgGridTable
          columnDefs={this.getColumnDefs()}
          getRowNodeId={this.getRowNodeId}
          isFetching={data.get('isFetching')}
          onCellClicked={this.handleCellClicked}
          onCellValueChanged={this.handleCellValueChanged}
          onGetNextPageData={onGetNextPageData}
          onGridReady={this.onGridReady}
          onRowDoubleClicked={onDoubleClickRow}
          onSortModelChanged={this.onSort}
          page={data.get('page')}
          rowData={data.get('queryResults')}
          totalPages={data.get('totalPages')}
          sortable
        />
      </div>
    );
  }
}

CompanyBuyerCEOTable.propTypes = {
  canEditData: PropTypes.bool.isRequired,
  columnDefs: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
  onGetNextPageData: PropTypes.func.isRequired,
  onUpdateCell: PropTypes.func.isRequired,
};

export default CompanyBuyerCEOTable;
