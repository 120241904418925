import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import classNames from 'classnames';
import { execIfFunction } from '../../utils/isFunction';
import terminateBubblingEvent from '../../utils/terminateBubblingEvent';

/**
 * Combo Select Box component.
 *
 * @param props {Object}.
 * @param props.name {String} Name of control.
 * @param props.valueKey {String} Value key of Option list.
 * @param props.nameKey {String} Name key of Option list.
 * @param props.options {Immutable.List} Option list.
 * @param props.placeholder {String} Placeholder.
 * @param props.onChange {Function} Will be called when users select an option.
 * @class
 */
class ComboSelectBox extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleMove = this.handleMove.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.setContainerElement = this.setContainerElement.bind(this);
    this.setMenuElement = this.setMenuElement.bind(this);

    this.state = {
      options: props.options,
      valueKey: props.valueKey || 'value',
      nameKey: props.nameKey || 'name',
      opened: false,
      inputText: '',
      cursor: -1,
      width: props.width,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClose);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClose);
  }

  componentDidUpdate() {
    const { cursor } = this.state;

    if (cursor >= 0 && this.menu) {
      const scroll = this.getCursorHeight(this.menu, cursor);
      const menuScrollHeight = this.menu.scrollHeight;
      const menuHeight = this.menu.clientHeight;

      if (scroll < menuScrollHeight - menuHeight) {
        this.menu.scrollTop = scroll;
      }
    }

    // If box's width is not set, try to make it equal to parent container
    if (this.container && !this.state.width) {
      this.setState({
        width: this.container.offsetWidth,
      });
    }
  }

  getCursorHeight(node, cursor) {
    let sum = 0;
    const len = Math.min(node.childNodes.length, cursor);

    for (let i = 0; i < len; i++) {
      sum += node.childNodes[i].offsetHeight || 0;
    }

    return sum;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      options: nextProps.options,
    });
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { valueKey } = this.state;

    this.setState({
      options: this.props.options.map(op => op.set('selected', op.get(valueKey) === value)),
      opened: false,
      inputText: name,
    });
    execIfFunction(this.props.onChange, {
      target: {
        name: this.props.name,
        value,
        type: 'comboselectbox',
        checked: null,
      },
    });
  }

  handleClick(event) {
    terminateBubblingEvent(event);
    this.setState(prevState => ({
      opened: !prevState.opened,
    }));
  }

  handleClose() {
    this.setState({
      opened: false,
    });
  }

  handleTextChange(event) {
    terminateBubblingEvent(event);

    const { nameKey } = this.state;
    const { options } = this.props;
    const { value } = event.target;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    this.setState({
      options:
        inputLength > 0
          ? options.filter(
              op =>
                op
                  .get(nameKey)
                  .toLowerCase()
                  .slice(0, inputLength) === inputValue,
            )
          : options,
      opened: true,
      inputText: value,
    });
  }

  handleMove(event) {
    const end = this.state.options.size - 1;
    const code = event.keyCode || event.which;

    if (code === 38) {
      this.setState(prevState => ({
        cursor: prevState.cursor > 0 ? prevState.cursor - 1 : 0,
      }));
    } else if (code === 40) {
      this.setState(prevState => ({
        cursor: prevState.cursor < end ? prevState.cursor + 1 : end,
      }));
    }
  }

  handleEnter(event) {
    if (event.key === 'Enter') {
      event.preventDefault();

      const { options, cursor, nameKey, valueKey } = this.state;

      if (cursor > -1 && cursor < options.size) {
        this.handleChange({
          target: {
            name: options.getIn([cursor, nameKey]),
            value: options.getIn([cursor, valueKey]),
          },
        });
      }
    }
  }

  setContainerElement(container) {
    this.container = container;
  }

  setMenuElement(menu) {
    this.menu = menu;
  }

  render() {
    const { placeholder, className, value, disabled } = this.props;

    const { options, opened, valueKey, nameKey, cursor, width } = this.state;
    let { inputText } = this.state;
    const ddlClassName = classNames(className, 'dropdown combo-select', {
      open: opened,
    });
    const hasData = options.size > 0;

    const mapOption = (option, index) => (
      <MenuItem
        key={index}
        hover={cursor === index}
        name={option.get(nameKey)}
        onClick={this.handleChange}
        selected={option.get('selected') === true}
        value={option.get(valueKey)}
      />
    );

    const styles = { style: {} };

    if (width) {
      styles.style.width = width;
    }

    if (Immutable.Map.isMap(value)) {
      inputText =
        options.filter(op => op.get('value') === value.get('value')).getIn([0, 'name'], inputText) || inputText;
    }

    return (
      <div ref={this.setContainerElement} className={ddlClassName}>
        <div className="input-group" onClick={event => event.stopPropagation()}>
          <input
            autoComplete="off"
            className="form-control"
            disabled={disabled}
            name="comboInput"
            onChange={this.handleTextChange}
            onKeyDown={this.handleMove}
            onKeyPress={this.handleEnter}
            placeholder={placeholder}
            type="text"
            value={inputText}
          />
          <span className="input-group-btn">
            <button
              className={classNames('btn btn-default', { disabled })}
              disabled={disabled}
              onClick={this.handleClick}
              type="button"
            >
              <i aria-hidden="true" className="fa fa-caret-down" />
            </button>
          </span>
        </div>
        <Menu
          {...styles}
          className="auto-suggestion dropdown-menu"
          hasData={hasData}
          setMenuElement={this.setMenuElement}
        >
          {options.map(mapOption)}
        </Menu>
      </div>
    );
  }
}

ComboSelectBox.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  name: PropTypes.string,
  nameKey: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.instanceOf(Immutable.List).isRequired,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  width: PropTypes.number,
};

export default ComboSelectBox;

const MenuItem = props => {
  const { name, value, selected = false, onClick, hover = false } = props;
  const className = classNames({ active: selected });
  const hoverClass = classNames({ cursor: hover });

  return (
    <li className={className}>
      <a
        className={hoverClass}
        onClick={() => onClick({ target: { name, value } })}
        style={{
          overflowX: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        title={name}
      >
        <span style={{ textOverflow: 'ellipsis' }}>{name}</span>
      </a>
    </li>
  );
};

MenuItem.propTypes = {
  hover: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

const Menu = props => {
  const { children, hasData, setMenuElement, ...rest } = props;

  if (hasData) {
    return (
      <ul ref={setMenuElement} style={{ overflowX: 'hidden' }} {...rest}>
        {children}
      </ul>
    );
  }

  return (
    <ul ref={setMenuElement} style={{ overflowX: 'hidden' }} {...rest}>
      <li>No data</li>
    </ul>
  );
};

Menu.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  hasData: PropTypes.bool.isRequired,
  setMenuElement: PropTypes.func.isRequired,
};
