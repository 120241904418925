export const AUTOCOMPL_APPEND_COUNTER = Symbol('AUTOCOMPL_APPEND_COUNTER');
export const AUTOCOMPL_DELETE_COUNTER = Symbol('AUTOCOMPL_DELETE_COUNTER');
export const AUTOCOMPL_REFRESH_COUNTER = Symbol('AUTOCOMPL_REFRESH_COUNTER');
export const AUTOCOMPL_FETCHING = Symbol('AUTOCOMPL_FETCHING');
export const AUTOCOMPL_FETCHED = Symbol('AUTOCOMPL_FETCHED');

export function appendCounter(id) {
  return {
    type: AUTOCOMPL_APPEND_COUNTER,
    id,
  };
}

export function deleteCounter(id) {
  return {
    type: AUTOCOMPL_DELETE_COUNTER,
    id,
  };
}

export function startFind(id) {
  return {
    type: AUTOCOMPL_FETCHING,
    id,
  };
}

export function endFind(id, response) {
  return {
    type: AUTOCOMPL_FETCHED,
    id,
    response,
  };
}

export function refresh(id) {
  return {
    type: AUTOCOMPL_REFRESH_COUNTER,
    id,
  };
}
