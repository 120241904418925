import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'immutable';

import uniqueId from '../../utils/uniqueId';
import Spinner from '../helpers/Spinner';
import Button from '../helpers/Button';

const UserList = props => {
  const { users, loading = false, onChange, onReloadClick, onOneOffApproachClick, onClick } = props;

  if (loading) {
    return (
      <div className="top-user-bg">
        <Spinner />
      </div>
    );
  }

  // Split user list to an array which array's elements have two users
  // Convert to array because of using Maps as children is not yet fully supported.
  const pairUserList = users.groupBy((element, index) => Math.floor(index / 2)).toArray();

  const mapUserCheckbox = (pair, index) => <UserCheckBoxGroup key={index} onChange={onChange} pairUser={pair} />;

  return (
    <div className="top-user-bg">
      {pairUserList.map(mapUserCheckbox)}
      <div className="col-xs-12 col-sm-12 col-md-6 pull-right mailing-history">
        <div className="form-group group-inline">
          <div className="btn-toolbar backlog-userList-pull-right">
            <Button onClick={onOneOffApproachClick}>One-Off Approach</Button>
            <Button onClick={onReloadClick}>Reload</Button>
            <Button onClick={onClick}>Mailing History</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

UserList.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  users: PropTypes.instanceOf(List).isRequired,
};

export default UserList;

const UserCheckBox = props => {
  const { userId, userName, checked, onChange, id = uniqueId() } = props;

  return (
    <div className="form-group group-inline group-checkbox">
      <input checked={checked} id={id} onChange={onChange} type="checkbox" value={userId} />
      <label htmlFor={id}>{userName}</label>
    </div>
  );
};

UserCheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
};

const UserCheckBoxGroup = props => {
  const { pairUser, onChange } = props;
  const firstUser = pairUser.get(0);
  const secondUser = pairUser.get(1);

  const firstCheckbox = firstUser ? (
    <UserCheckBox
      checked={firstUser.get('selected', false)}
      onChange={onChange}
      userId={firstUser.get('id')}
      userName={firstUser.get('name')}
    />
  ) : null;

  const secondCheckbox = secondUser ? (
    <UserCheckBox
      checked={secondUser.get('selected', false)}
      onChange={onChange}
      userId={secondUser.get('id')}
      userName={secondUser.get('name')}
    />
  ) : null;

  if (!firstUser && !secondUser) {
    return null;
  }

  return (
    <div className="col-xs-6 col-sm-6 col-md-2 col-lg-2">
      {firstCheckbox}
      {secondCheckbox}
    </div>
  );
};

UserCheckBoxGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  pairUser: PropTypes.instanceOf(List).isRequired,
};
