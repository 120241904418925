import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Map, List } from 'immutable';

import AgGridTable from '../../helpers/AgGridTable';
import { noop } from '../../../utils/noop';

class CompanyExecuteTable extends PureComponent {
  constructor(props) {
    super(props);

    this.gridApi = null;
    this.gridColumnApi = null;
    this.onGridReady = this.onGridReady.bind(this);
    this.handleRowDoubleClicked = this.handleRowDoubleClicked.bind(this);
  }

  componentDidMount() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  componentDidUpdate() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  getColumnDefs() {
    if (this.props.columnDefs !== this.lastColumnDefs) {
      this.lastColumnDefs = this.props.columnDefs;
      this.columnDefs = this.props.columnDefs.map(columnDef => {
        const field = columnDef.get('field');

        switch (field) {
          default:
            return columnDef;
        }
      });
    }

    return this.columnDefs;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  handleRowDoubleClicked(row) {
    this.props.onDoubleClickRow(row);
  }

  getRowNodeId(data) {
    return data.id;
  }

  render() {
    const { data, onDoubleClickRow } = this.props;

    return (
      <div className="CompanyExecuteTable full-height">
        <AgGridTable
          columnDefs={this.getColumnDefs()}
          getRowNodeId={this.getRowNodeId}
          isFetching={data.get('isFetching')}
          onGetNextPageData={noop}
          onGridReady={this.onGridReady}
          onRowDoubleClicked={onDoubleClickRow}
          onSortModelChanged={noop}
          page={data.get('page')}
          rowData={data.get('queryResults')}
          sortable={false}
          totalPages={data.get('totalPages')}
          enableClientSideSorting
        />
      </div>
    );
  }
}

CompanyExecuteTable.propTypes = {
  columnDefs: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
};

export default CompanyExecuteTable;
