import { fromJS, List } from 'immutable';
import moment from 'moment';
import { sortBy } from 'underscore';

import * as ActionType from '../../actions/contact/contactExecutive';
import * as suggestDecorators from '../decorators/suggest';
import * as strDecorators from '../decorators/strDecorators';
import handleApiError from '../decorators/handleApiError';
import getValidator from '../../utils/getValidator';
import {
  spy,
  setIn,
  getIn,
  getOriginalIn,
  merge,
  unwrap,
  toJS,
  mergeToOriginal,
  revertToOriginal,
} from '../../utils/ChangeSpy';
import { uniqueNewId, isNew } from '../../utils/uniqueId';
import config from '../../config';

import nullable from '../../config/validation/rules/nullable';
import year from '../../config/validation/rules/year';

const SCHEMA = {
  type: 'object',
  properties: {
    lastResearchedDate: {
      title: 'Last Researched Date',
      description: 'should be a date',
      oneOf: [{ type: 'null' }, { type: 'object' }],
    },
    funds: {
      type: 'array',
      items: {
        type: 'object',
      },
    },
    positions: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          title: {
            required: true,
            title: 'Title',
            description: 'should not be empty',
            type: 'string',
            pattern: /\w+/,
          },
          pnl: nullable({
            title: 'P&L-Revenue',
            descriptions: 'should be integer or empty',
            anyOf: [{ type: 'string', pattern: /^[1-90\.]*$/ }, { type: 'integer' }],
          }),
          pnlEbitda: nullable({
            title: 'P&L-EBITDA',
            description: 'should be integer or empty',
            anyOf: [{ type: 'string', pattern: /^[1-90\.]*$/ }, { type: 'integer' }],
          }),
          startYear: nullable(
            year({
              title: 'Start Year',
              description: 'should be valid year, ? or empty',
              anyOf: [{ type: 'string', pattern: /^\?$/ }],
            }),
          ),
          endYear: nullable(
            year({
              title: 'End Year',
              description: 'should be valid year, ? or empty',
              anyOf: [{ type: 'number', value: -1 }],
            }),
          ),
        },
      },
    },
    newPosition: {
      type: 'object',
    },
    newFund: {
      type: 'object',
    },
    contactIndustries: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          industryLabel: {
            title: 'Industry',
            description: 'should contain valid industry name',
            type: 'string',
            minLength: 1,
          },
          pivot: {
            type: 'object',
            properties: {
              industryId: {
                required: true,
                title: 'Industry',
                description: 'should contain valid industry name',
                errorTo: 'contactIndustries.#.industryLabel',
                anyOf: [{ type: 'number', minimum: 1 }],
              },
            },
          },
        },
      },
    },
    recordOwnerId: {
      type: 'number',
      minimum: 1,
    },
  },
};

const FUND_SCHEMA = {
  type: 'object',
  properties: {
    suggestCompany: {
      title: 'Fund',
      description: 'should not be empty',
      type: 'string',
      minLength: 1,
    },
    fundId: {
      required: true,
      title: 'Fund',
      description: 'should contain valid fund name',
      errorTo: 'suggestCompany',
      anyOf: [{ type: 'number', minimum: 1 }],
    },
    title: {
      title: 'Title',
      type: 'string',
    },
  },
};

const POSITION_SCHEMA = {
  type: 'object',
  properties: {
    title: {
      required: true,
      title: 'Title',
      description: 'should not be empty',
      type: 'string',
      pattern: /\w+/,
    },
    suggestCompany: {
      title: 'Company',
      description: 'should be a valid company name',
      type: 'string',
      pattern: /\w+/,
    },
    entityId: {
      title: 'Company',
      description: 'should be a valid company name',
      errorTo: 'suggestCompany',
      anyOf: [{ type: 'number', minimum: 1 }],
    },
    pnl: nullable({
      title: 'P&L-Revenue',
      description: 'should be integer or empty',
      anyOf: [{ type: 'string', pattern: /^[1-90]*$/ }, { type: 'integer' }],
    }),
    pnlEbitda: nullable({
      title: 'P&L-EBITDA',
      description: 'should be integer or empty',
      anyOf: [{ type: 'string', pattern: /^[1-90]*$/ }, { type: 'integer' }],
    }),
    startYear: nullable(
      year({
        title: 'Start Year',
        description: 'should be valid year, ? or empty',
        anyOf: [{ type: 'string', pattern: /^\?$/ }],
      }),
    ),
    endYear: nullable(
      year({
        title: 'End Year',
        description: 'should be valid year, ? or empty',
        anyOf: [{ type: 'number', value: -1 }],
      }),
    ),
  },
};

const validator = getValidator(SCHEMA);
const newFundValidator = getValidator(FUND_SCHEMA);
const newPositionValidator = getValidator(POSITION_SCHEMA);

const NULLABLE_SUGGEST = ['suggestDirector', 'suggestAnalyst', 'suggestDealmaker', 'suggestResearcher'];

const statuses = config.values.getIn(['contactExecutive', 'status']);

const DATE_FORMAT = 'MM/DD/YYYY';
const FIELDS_TO_SPY = [
  'suggestDirector',
  'suggestAnalyst',
  'suggestDealmaker',
  'suggestResearcher',
  'quality',
  'interest',
  'relationship',
  'lastResearchedDate',
  'recordOwnerId',
  'recordSubOwnerId',
  'dealmakerId',
  'lastResearcherId',
  'legacyResume',
  'legacyMbo',
  'legacyCorpBuyer',
  'legacyNeedsFund',
  'legacyPeExperience',
  'legacyEvents',
  'legacyMeet',
  'legacySource',
  'legacyNextStep',
  'legacyPersonalBio',
  'trackRecord',
  'researchNotes',
  'industryId',
  'selectedBICs',
  'selectedBMs',
];

const COMPANIES_FIELDS_TO_SPY = ['pnl', 'pnlEbitda', 'startYear', 'endYear', 'title', 'companyBio'];

const FUNDS_FIELDS_TO_SPY = ['activeFund', 'currentStatus', 'highStatus', 'eprojectCategory', 'eprojectId'];
const INDUSTRY_FIELDS_TO_SPY = ['industryLabel', 'pivot.industryId'];
const TAG_FIELDS_TO_SPY = ['tag'];

const defaultState = fromJS(
  spy(
    {
      industryId: 0,

      statuses,
      suggests: [],
      error: null,
      suggestCompany: '',
      suggestTitle: '',
      suggestDirector: '',
      suggestAnalyst: '',
      suggestDealmaker: '',
      suggestResearcher: '',
      recordOwnerId: 0,
      recordSubOwnerId: 0,
      dealmakerId: 0,
      lastResearcherId: 0,
      contactIndustries: [],
      industryTags: [],
      selectedBICs: [],
      selectedBMs: [],
      lastResearchedDate: moment(),
      harvcoTags: [],
      funds: [],
      positions: [],
      newPosition: {
        id: uniqueNewId(),
        error: null,
        titleLoading: false,
      },
      newFund: spy(
        {
          id: uniqueNewId(),
          fundId: 0,
          eprojectId: 0,
          currentStatus: unwrap(statuses.getIn([0, 'value'])),
          highStatus: unwrap(statuses.getIn([0, 'value'])),
          error: null,
          activeFund: false,
        },
        [...FUNDS_FIELDS_TO_SPY, 'fundId'],
      ),
      loading: false,
      loadingFunds: false,
      loadingPositions: false,
      inputErrors: {},
    },
    FIELDS_TO_SPY,
  ),
);

const emptyUser = {
  id: 0,
  userName: '',
};

const emptyTag = {
  tag: '',
  isEditing: true,
};

const emptyIndustry = {
  id: 0,
  industryLabel: '',
  pivot: {
    priority: 0,
    industryId: 0,
  },
  isEditing: true,
};

let wrappedReducer = reducer;

wrappedReducer = suggestDecorators.check('suggestDirector', 'suggests', ActionType.LOADED_EXEC_USERS, wrappedReducer);
wrappedReducer = suggestDecorators.check('suggestAnalyst', 'suggests', ActionType.LOADED_EXEC_USERS, wrappedReducer);
wrappedReducer = suggestDecorators.check('suggestDealmaker', 'suggests', ActionType.LOADED_EXEC_USERS, wrappedReducer);
wrappedReducer = suggestDecorators.check('suggestResearcher', 'suggests', ActionType.LOADED_EXEC_USERS, wrappedReducer);

wrappedReducer = strDecorators.emptyToNullByRegexp(/\.pnl$/, ActionType.CHANGE_CONTACT_EXECUTIVE_FIELD, wrappedReducer);
wrappedReducer = strDecorators.emptyToNullByRegexp(
  /\.pnlEbitda$/,
  ActionType.CHANGE_CONTACT_EXECUTIVE_FIELD,
  wrappedReducer,
);
wrappedReducer = strDecorators.emptyToNullByRegexp(/Year$/, ActionType.CHANGE_CONTACT_EXECUTIVE_FIELD, wrappedReducer);
wrappedReducer = strDecorators.transformByRegexp(
  /endYear$/,
  '?',
  -1,
  ActionType.CHANGE_CONTACT_EXECUTIVE_FIELD,
  wrappedReducer,
);
wrappedReducer = handleApiError(ActionType.ERRORED_SAVE_CONTACT_EXECUTIVE, wrappedReducer);
export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.LOADED_EXEC_USERS:
      return action.page === 1
        ? state.merge({ suggests: action.response.data })
        : state.update('suggests', suggests => suggests.concat(fromJS(action.response.data)));

    case ActionType.CHANGE_CONTACT_EXECUTIVE_FIELD:
      return changeField(state, action);

    case ActionType.LOAD_CONTACT_EXECUTIVE:
      return defaultState.merge({ loading: true });

    case ActionType.LOADED_CONTACT_EXECUTIVE:
      return state.merge(mapResponse(action.response)).merge({ loading: false });

    case ActionType.ERROR_CONTACT_EXECUTIVE:
      return state.merge({
        loading: false,
        loadingPositions: false,
        loadingFunds: false,
      });

    case ActionType.LOAD_CONTACT_EXECUTIVE_FUNDS:
      return state.merge({ loadingFunds: true });

    case ActionType.LOADED_CONTACT_EXECUTIVE_FUNDS:
      return mergeFundsData(state, action).merge({ loadingFunds: false });

    case ActionType.LOAD_CONTACT_EXECUTIVE_POSITIONS:
      return state.merge({ loadingPositions: true });

    case ActionType.LOADED_CONTACT_EXECUTIVE_POSITIONS:
      return mergePositionsData(state, action).merge({
        loadingPositions: false,
      });

    case ActionType.FETCHING_EXEC_COMPANIES:
      return state.set('loadingExecCompanies', true);

    case ActionType.LOADED_EXEC_COMPANIES: {
      const { status, data, meta } = action.response;

      if (status === 'success') {
        const {
          pagination: { currentPage, total },
        } = meta;
        const tmp =
          total === 0
            ? state.set('suggests', List())
            : currentPage === 1
            ? state.set('suggests', fromJS(data))
            : state.update('suggests', suggests => suggests.concat(fromJS(data)));

        return tmp.set('loadingExecCompanies', false);
      }

      return state;
    }

    case ActionType.LOADED_EXEC_INDUSTRIES:
      return state.merge({ suggests: action.response.data });

    case ActionType.LOADED_EXEC_TITLES:
      return setLoading(
        state.merge({
          suggests: action.response.data
            .filter(val => new RegExp(String(action.filter).replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1'), 'i').test(val))
            .map(title => ({ text: title })),
        }),
        action,
        false,
      );

    case ActionType.LOADING_EXEC_TITLES:
      return setLoading(state, action, true);

    case ActionType.LOADED_EXEC_PROJECTS:
      return action.page === 1
        ? state.merge({ suggests: action.response.data })
        : state.update('suggests', suggests => suggests.concat(fromJS(action.response.data)));

    case ActionType.CLEAR_SUGGEST:
      return state.merge({ suggests: [] });

    case ActionType.ADD_CONTACT_POSITION_ERROR:
      return state.mergeDeep({ newPosition: { error: action.message } });

    case ActionType.SAVE_CONTACT_FUND:
      return changeList(state, 'funds', action, FUNDS_FIELDS_TO_SPY);

    case ActionType.ADD_CONTACT_TAG:
      return state.update('harvcoTags', tags => tags.push(getNewTag(tags)));

    case ActionType.ADD_CONTACT_INDUSTRY:
      return state.update('contactIndustries', industries => industries.push(getNewIndustry(industries)));

    case ActionType.SAVE_CONTACT_POSITION:
      return changeList(state, 'positions', action, COMPANIES_FIELDS_TO_SPY);

    case ActionType.SAVED_CONTACT_EXECUTIVE:
      return spy(setValueAsOriginalByMap(state, action.fields), FIELDS_TO_SPY);

    case ActionType.SET_FIELD_ON_ONE:
      return state.update(action.field, list =>
        list.map((entity, i) => setIn(entity, action.name, i === action.index ? action.value : action.other)),
      );

    case ActionType.SAVED_CONTACT_INDUSTRY:
      return revertToOriginal(
        revertToOriginal(mergeToOriginal(mergeToOriginal(state, 'selectedBMs'), 'selectedBICs'), 'selectedBMs'),
        'selectedBICs',
      );

    case ActionType.SAVED_CONTACT_TAGS:
      return state.set('harvcoTags', fromJS(action.response.data.harvcoTags.map(mapTags)));

    case ActionType.MAKE_CONTACT_FUND_ACTIVE:
      return state.set(
        'funds',
        state
          .get('funds')
          .map((fund, i) => setIn(fund, 'activeFund', i === action.index))
          .sort(sortFunds),
      );

    case ActionType.DEL_CONTACT_FUND: {
      const { id } = action;

      return state.update('funds', funds => funds.filter(fund => fund.get('id') !== id));
    }

    case ActionType.DEL_CONTACT_POSITION: {
      const { id } = action;

      return state.update('positions', positions => positions.filter(position => position.get('id') !== id));
    }

    case ActionType.DEL_CONTACT_INDUSTRY: {
      const { id } = action;

      return state.update('contactIndustries', industries => industries.filter(industry => industry.get('id') !== id));
    }

    case ActionType.DEL_CONTACT_TAG: {
      const { id } = action;

      return state.update('harvcoTags', tags => tags.filter(tag => tag.get('id') !== id));
    }

    case ActionType.ADD_CONTACT_FUND: {
      return state
        .update('funds', funds => funds.push(spy(updateNewFundToActual(state, action), FUNDS_FIELDS_TO_SPY)))
        .set('newFund', defaultState.get('newFund'));
    }

    case ActionType.TRIGGER_CONTACT_ACCORDION:
      return state.update(action.field, funds =>
        funds.map((fund, i) =>
          i !== action.index ? fund.set('isOpened', false) : fund.set('isOpened', !fund.get('isOpened')),
        ),
      );

    case ActionType.CLOSE_CONTACT_EXEC_VALIDATION_ERROR:
      return state.deleteIn(['inputErrors', action.field]);

    case ActionType.RESET_NEW_POSITION:
      return state.set('newPosition', defaultState.get('newPosition'));

    case ActionType.UPDATE_INDUSTRY_TAGS:
      return updateIndustryTags(state, action);

    default:
      return state;
  }
}

function updateIndustryTags(state, action) {
  const inds = state
    .get('industryTags')
    .filter(t => t.get('deprecated'))
    .toJS();
  let selectedBICs = [];
  let selectedBMs = [];
  const bm = [];
  const bic = [];

  action.tags.forEach(t => {
    selectedBICs.push(t.id);
    bic.push({
      industryLabel: t.category,
      deprecated: false,
      deletedAt: t.deletedAt,
    });
  });
  action.models.forEach(t => {
    selectedBMs.push(t.id);
    bm.push({
      industryLabel: t.category,
      deprecated: false,
      deletedAt: t.deletedAt,
    });
  });
  selectedBICs = sortBy(selectedBICs);
  selectedBMs = sortBy(selectedBMs);

  let tmp = setIn(state, 'selectedBICs', selectedBICs);

  tmp = setIn(tmp, 'selectedBMs', selectedBMs);

  return tmp.set('industryTags', fromJS(bic.concat(bm).concat(inds)));
}

function setLoading(state, action, loadingState) {
  if (action.positionId > 0) {
    const index = state.get('positions').findIndex(p => p.get('id') === action.positionId);

    if (index !== null && index !== undefined) {
      return state.setIn(['positions', index, 'loading'], loadingState);
    }

    return state;
  }

  return state.setIn(['newPosition', 'titleLoading'], loadingState);
}

function isEmployed(positions) {
  return positions.filter(position => !position.endYear).length > 0;
}

function isSuggest(name) {
  return (
    [
      'suggestDirector',
      'suggestAnalyst',
      'suggestDealmaker',
      'suggestResearcher',
      'newFund.suggestCompany',
      'newPosition.suggestCompany',
      'suggestCompany',
    ].indexOf(name) !== -1 || /industryLabel$/.test(name)
  );
}

function updateNewFundToActual(state, action) {
  const { id } = action.response;
  const newFund = state.get('newFund');

  return newFund
    .set('id', id)
    .set('fundLegalName', newFund.get('suggestCompany'))
    .delete('suggestCompany')
    .set('eprojectCategory', newFund.get('suggestProject') || '')
    .delete('suggestProject');
}

function getSuggestIdField(name) {
  switch (name) {
    case 'suggestDirector':
      return 'recordOwnerId';

    case 'suggestAnalyst':
      return 'recordSubOwnerId';

    case 'suggestDealmaker':
      return 'dealmakerId';

    case 'suggestResearcher':
      return 'lastResearcherId';

    case 'suggestCompany':
      return 'entityId';

    case 'newFund.suggestCompany':
      return 'newFund.fundId';

    case 'newPosition.suggestCompany':
      return 'newPosition.entityId';
  }

  if (/industryLabel$/.test(name)) {
    return name.replace(/industryLabel$/, 'pivot.industryId');
  }
  throw Error('Unexpected suggest field name.');
}

function checkValidity(state) {
  const plainState = toJS(state);
  const { newFund } = plainState;
  const { newPosition } = plainState;

  delete plainState.newFund;
  delete plainState.newPosition;

  const isValid = validator.isValid(plainState);

  return state.merge({
    newPositionInputErrors: newPositionValidator.getErrors(newPosition),
    newFundInputErrors: newFundValidator.getErrors(newFund),
    inputErrors: validator.getFirstError(plainState),
    isValid,
  });
}

function changeField(state, action) {
  const { name, value } = action;

  if (isSuggest(name)) {
    return checkValidity(updateSuggest(state, action), action);
  }

  if (/currentStatus$/.test(name)) {
    // to properly change high status
    const highName = name.replace(/currentStatus$/, 'highStatus');
    const highOriginalValue = getOriginalIn(state, highName, 0);
    const max = Math.max(highOriginalValue, value);
    const highNextValue = parseFloat(highOriginalValue) === max ? highOriginalValue : value;

    state = setIn(state, highName, highNextValue);
  }

  return checkValidity(setIn(state, name, value), action);
}

function updateSuggest(state, action) {
  const { name, value } = action;
  const idField = getSuggestIdField(name);

  if (value === getOriginalIn(state, name)) {
    return merge(state, {
      [name]: value,
      [idField]: getOriginalIn(state, idField),
    });
  }

  if (value === '' && isNullableSuggest(name)) {
    return merge(state, { [name]: value, [idField]: null });
  }

  const suggests = state.get('suggests').filter(v => v.get('text') === value);

  if (suggests.size === 1)
    return merge(state, {
      [name]: value,
      [idField]: suggests.getIn([0, 'id'], 0),
    });

  return merge(state, { [name]: value, [idField]: 0 });
}

function isNullableSuggest(name) {
  return NULLABLE_SUGGEST.indexOf(name) !== -1;
}

function mapPositions(position) {
  position.companyName = position.legalName;
  position.id = position.entityContactsId;
  position.startYear = position.startYear || null;
  position.endYear = position.endYear || null;

  return spy(position, COMPANIES_FIELDS_TO_SPY);
}

function mapFunds(fund) {
  return spy(fund, FUNDS_FIELDS_TO_SPY);
}

function mergePositionsData(state, action) {
  const {
    response: { data },
  } = action;
  const positions = fromJS(data.map(mapPositions));

  return state.merge({
    isEmployed: isEmployed(data),
    positions: sortPosition(positions),
  });
}

function mergeFundsData(state, action) {
  const {
    response: { data },
  } = action;
  const funds = fromJS(data.map(mapFunds));

  return state.set('funds', funds);
}

function mapDateField(value) {
  // eslint-disable-next-line no-param-reassign
  value.date = moment(value.date);
  // eslint-disable-next-line no-param-reassign
  value.dateString = value.date.format(DATE_FORMAT);

  return value;
}

function mapResponse({ data }) {
  const {
    lastResearcherId = 0,
    researcherUser = emptyUser,
    directorUser = emptyUser,
    analystUser = emptyUser,
    dealmakerUser = emptyUser,
    lastResearchedDate,

    quality,
    interest,
    relationship,

    legacyResume,
    legacyMbo,
    legacyCorpBuyer,
    legacyNeedsFund,
    legacyPeExperience,
    legacyEvents,
    legacyMeet,
    legacySource,
    legacyNextStep,

    legacyPersonalBio,
    trackRecord,
    researchNotes,

    contactIndustries,
    contactBusinessModels,
    contactIndustryCategories,
    harvcoTags,
  } = data;

  return spy(
    {
      quality,
      interest,
      relationship,

      suggestDirector: directorUser.userName,
      recordOwnerId: directorUser.id,
      suggestAnalyst: analystUser.userName,
      recordSubOwnerId: analystUser.id,
      suggestDealmaker: dealmakerUser.userName,
      dealmakerId: dealmakerUser.id,
      suggestResearcher: researcherUser.userName,
      lastResearcherId,
      lastResearchedDate: moment(lastResearchedDate),

      legacyResume: !!legacyResume,
      legacyMbo: !!legacyMbo,
      legacyCorpBuyer: !!legacyCorpBuyer,
      legacyNeedsFund: !!legacyNeedsFund,
      legacyPeExperience: !!legacyPeExperience,
      legacyEvents: !!legacyEvents,
      legacyMeet: !!legacyMeet,
      legacySource,
      legacyNextStep,

      legacyPersonalBio,
      trackRecord,
      researchNotes,

      contactIndustries: contactIndustries.map(mapIndustries),
      selectedBICs: sortBy(contactIndustryCategories.map(c => c.id)),
      selectedBMs: sortBy(contactBusinessModels.map(c => c.id)),
      industryTags: contactIndustryCategories
        .map(c => ({
          industryLabel: c.category,
          deprecated: false,
          deletedAt: c.deletedAt,
        }))
        .concat(
          contactBusinessModels.map(c => ({
            industryLabel: c.category,
            deprecated: false,
            deletedAt: c.deletedAt,
          })),
        )
        .concat(
          contactIndustries.map(c => ({
            industryLabel: c.industryLabel,
            deprecated: true,
            deletedAt: c.deletedAt,
          })),
        ),
      harvcoTags: harvcoTags.map(mapTags),
    },
    FIELDS_TO_SPY,
  );
}

function sortFunds(fundA, fundB) {
  if (getIn(fundA, 'activeFund')) return -1;
  if (getIn(fundB, 'activeFund')) return 1;

  return 0; // order by active last->go upper
}

function setValueAsOriginalByMap(state, map) {
  return spy(
    map.reduce((result, key) => {
      const value = getIn(result, key);

      return result.setIn(key.split('.'), value);
    }, state),
    map,
  );
}

function changeList(state, field, action, FIELDS_TO_SPY) {
  return state.set(
    field,
    state.get(field).map(entity => {
      if ('id' in action && entity.get('id') !== action.id) {
        return entity;
      }

      let updated = setValueAsOriginalByMap(entity, action.fields);

      if (isNew(action.id)) {
        updated = setIn('id', action.response.id);
      }

      return spy(updated, FIELDS_TO_SPY);
    }),
  );
}

function mapTags(tag) {
  return spy(mapDateField(tag), TAG_FIELDS_TO_SPY);
}

function mapIndustries(industry) {
  return spy(industry, INDUSTRY_FIELDS_TO_SPY);
}

function getNewIndustry(industries) {
  let industry = fromJS(mapIndustries(emptyIndustry));

  industry = setIn(industry, 'id', uniqueNewId());
  industry = setIn(industry, 'pivot.priority', industries.size);

  return industry;
}

function getNewTag() {
  let tag = fromJS(mapTags(emptyTag));

  tag = setIn(tag, 'id', uniqueNewId());

  return tag;
}

/**
 * Sorting order:
 * 1. ""End"" of tenure in descending order, with ""Current"" at the top.
 * 2. ""Start"" of tenure in descending order.
 */
function sortPosition(positions) {
  const tmp = positions.sortBy(
    pos => (pos.getIn(['startYear', 'value']) ? pos.getIn(['startYear', 'value']) : -Infinity),
    (yearA, yearB) => {
      if (yearA > yearB) {
        return -1;
      }

      if (yearA < yearB) {
        return 1;
      }

      if (yearA === yearB) {
        return 0;
      }
    },
  );

  return tmp.sortBy(
    pos => (pos.getIn(['endYear', 'value']) ? pos.getIn(['endYear', 'value']) : Number(Infinity)),
    (yearA, yearB) => {
      if (yearA > yearB) {
        return -1;
      }

      if (yearA < yearB) {
        return 1;
      }

      if (yearA === yearB) {
        return 0;
      }
    },
  );
}
