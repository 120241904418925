import React from 'react';
import classNames from 'classnames';

import { isDeepChanged } from '../../utils/ChangeSpy';
import PrettyError from '../decorators/PrettyError';
import DisplayError from '../decorators/DisplayError';
import If from '../helpers/If';
import CompanyMarkets from './Details/CompanyMarkets';
import CompanyProducts from './Details/CompanyProducts';
import CompanyCustomers from './Details/CompanyCustomers';
import CompanyCompetitors from './Details/CompanyCompetitors';
import CompanyFacilities from './Details/CompanyFacilities';
import CompanyTradeShows from './Details/CompanyTradeShows';
import _Board from './Details/Broad';
import CompanyCustomFields from './Details/CompanyCustomFields';
import CompanyHarvcoTags from './Details/CompanyHarvcoTags';
import { createIndustryErrorMsg, INDUSTRY_CATEGORIES } from '../../utils/industryTagsHelper';

const Broad = PrettyError(DisplayError(_Board));

/**
 * Stateless component for contact's activities.
 * Display table where rows are activities.
 *
 * @link http://screencloud.net/v/jS6q on page (right top part)
 * @link http://screencloud.net/v/7yc2 component itself
 * @param props {Object}.
 * @param props.events {Immutable.List} List with activities info. If now activities
 *     are on contact - should be an empty List.
 * @returns {React.Component}
 */
const CompanyDetails = props => {
  const {
    hasPermissions,
    canEditData,
    details,
    suggestCreate,
    onAdd = (...rest) => console.log('onAdd', rest),
    onClick,
    onChange,
    onDelete,
    onTradeShowDelete,
    industryCategories,
    isDuplicateCompany,
    businessModels,
    onUpdateTags,
    error,
    onErrorClose,
  } = props;
  const callbacks = { onAdd, onClick, onChange, onDelete };
  const headerClass = classNames('panel-title', {
    'pl5 changed': isDeepChanged(details) || isDeepChanged(industryCategories),
  });

  const errorMsg = createIndustryErrorMsg(error);

  return (
    <div className="panel panel-default">
      <div className="panel-heading" data-parent="#accordion" data-target="#acc-slide-2" data-toggle="collapse">
        <h4 className={headerClass}>Company Details</h4>
      </div>
      <div className="panel-collapse collapse in" id="acc-slide-2">
        <div className="panel-body company-target-accordion">
          <div className="company-details">
            <CompanyMarkets
              {...callbacks}
              canEditData={canEditData}
              data={details.get('targetMarkets')}
              disabled={isDuplicateCompany}
              extraClassName="grow_1"
            />
            <CompanyProducts
              {...callbacks}
              canEditData={canEditData}
              data={details.get('targetProducts')}
              disabled={isDuplicateCompany}
              extraClassName="grow_1"
            />
            <CompanyCustomers
              {...callbacks}
              canEditData={canEditData}
              data={details.get('targetCustomers')}
              disabled={isDuplicateCompany}
              extraClassName="grow_1"
            />
            <If value={hasPermissions}>
              <CompanyCompetitors
                {...callbacks}
                canEditData={canEditData}
                data={details.get('targetCompetitors')}
                disabled={isDuplicateCompany}
                extraClassName="grow_1"
              />
            </If>
            <CompanyFacilities
              {...callbacks}
              canEditData={canEditData}
              data={details.get('targetFacilities')}
              disabled={isDuplicateCompany}
              extraClassName="grow_2"
            />
            <CompanyCustomFields
              {...callbacks}
              canEditData={canEditData}
              data={details.get('customFields')}
              disabled={isDuplicateCompany}
              extraClassName="grow_3"
            />
            <div className="company-details__table table-responsive grow_2 mb4">
              <Broad
                {...callbacks}
                businessModels={businessModels}
                canEditData={canEditData}
                data={industryCategories}
                deprecatedIndustries={details.get('industries')}
                disabled={isDuplicateCompany}
                error={errorMsg}
                name="industryCategories"
                onClick={onUpdateTags}
                onErrorClose={event => onErrorClose(event, INDUSTRY_CATEGORIES)}
              />
            </div>
            <CompanyTradeShows
              canEditData={canEditData}
              data={details.get('tradeShowTargets')}
              disabled={isDuplicateCompany}
              extraClassName="trade-shows-table"
              onAdd={onAdd}
              onClick={onClick}
              onDelete={onTradeShowDelete}
              suggestCreate={suggestCreate}
            />
            <CompanyHarvcoTags
              {...callbacks}
              canEditData={canEditData}
              data={details.get('harvcoTags')}
              disabled={isDuplicateCompany}
              extraClassName="grow_2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
