import { addGlobalErrorType, addMiddlewareErrorType } from '../middleware/api';

export const ADD_API_ERROR = Symbol('ADD_API_ERROR');
export const ADD_RUNTIME_ERROR = Symbol('ADD_RUNTIME_ERROR');
export const REMOVE_API_ERROR = Symbol('REMOVE_API_ERROR');
export const REMOVE_RUNTIME_ERROR = Symbol('REMOVE_RUNTIME_ERROR');

export function removeApiError({ error }) {
  return {
    type: REMOVE_API_ERROR,
    error,
  };
}

export function removeRuntimeError({ error }) {
  return {
    type: REMOVE_RUNTIME_ERROR,
    error,
  };
}

export function addRuntimeError({ error }) {
  return {
    type: ADD_RUNTIME_ERROR,
    error,
  };
}

addGlobalErrorType(ADD_API_ERROR);
addMiddlewareErrorType(ADD_RUNTIME_ERROR);
