import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { unwrap } from '../../utils/ChangeSpy';

export default function MismissAlertDecorator(Comp, Alert) {
  /**
   * Add a dismiss panel below input element.
   *
   * @class
   */
  class MismissAlert extends PureComponent {
    constructor(props) {
      super(props);
      this.onBlur = this.onBlur.bind(this);
      this.show = this.show.bind(this);
      this.check = this.check.bind(this);
      this.onChange = this.onChange.bind(this);
      this.onFocus = this.onFocus.bind(this);
      this.onClick = this.onClick.bind(this);

      this.state = {
        showReminder: false,
      };
      this.changed = false;
    }

    onBlur(event) {
      this.check();
      if (this.props.onBlur) {
        this.props.onBlur(event);
      }
    }

    onFocus(event) {
      this.show(false);
      if (this.props.onFocus) {
        this.props.onFocus(event);
      }
    }

    show(state) {
      if (state) {
        if (this.changed) {
          this.setState({
            showReminder: true,
          });
        }
      } else {
        this.setState({
          showReminder: false,
        });
        this.changed = false;
      }
    }

    check() {
      const text = unwrap(this.props.value) || '';

      if (
        /\w+\./gi.test(text) ||
        /(\s+N\s+|\s+B\s+|\s+E\s+|\s+W\s+|\s+NbE\s+|\s+NNE\s+|\s+NEbN\s+|\s+NE\s+)/g.test(text) ||
        /\b\w{1,4}(,|\s+)/gi.test(text)
      ) {
        this.show(true);
      }
    }

    onChange(event) {
      this.changed = true;
      if (this.props.onChange) {
        this.props.onChange(event);
      }
    }

    onClick() {
      this.show(false);
    }

    render() {
      const dismisClass = classNames('alert alert-warning alert-dismissible', {
        hide: !this.state.showReminder,
      });

      return (
        <div className="dismis__panel">
          <Comp {...this.props} onBlur={this.onBlur} onChange={this.onChange} onFocus={this.onFocus} />
          <div className={dismisClass}>
            <button aria-label="Close" className="close" onClick={this.onClick} type="button">
              <span aria-hidden="true">&times;</span>
            </button>
            <Alert />
          </div>
        </div>
      );
    }
  }

  MismissAlert.displayName = `MismissAlert(${Comp.displayName || Comp.name})`;

  return MismissAlert;
}
