import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fromJS, List, Map } from 'immutable';

import { getModuleSuggestions } from '../../actions/popup/windwardReportsPopup';
import Popups from '../../components/Popup';
import { clearWindwardTemplate, uploadTemplate, handleUpdateWindwardTemplates } from '../../actions/windward';

/** Upload windward templates modal component. */
class UploadTemplatePopupContainer extends PureComponent {
  state = {
    fileName: null,
    module: {
      suggestions: List(),
      pagination: {},
    },
    moduleLoading: false,
    moduleText: '',
    selectedUserId: null,
  };

  componentWillUnmount() {
    this.props.clearWindwardTemplate();
  }

  /** Close popup method. */
  handleClose = () => {
    this.context.closePopup();
  };

  handleSelectFile = event => {
    const { name } = event.target;

    this.setState({ fileName: event.target.files.item(0).name });

    return this.props.handleUpdateWindwardTemplates({
      name,
      value: event.target.files.item(0).name,
    });
  };

  handleGetSuggestionForModule = ({ value }) => {
    this.setState({ moduleLoading: true });
    this.props.getModuleSuggestion(value).then(response => this.updateModuleSuggestion(response));
  };

  updateModuleSuggestion = suggestions => {
    if (!suggestions || !suggestions.hasOwnProperty('data')) {
      return;
    }

    this.setState({
      moduleLoading: false,
      module: {
        suggestions: fromJS([...suggestions.data]),
        pagination: suggestions.meta && suggestions.meta.pagination,
      },
    });
  };

  handleSuggestionSelected = (event, { suggestion }) => {
    if (this.state.selectedUserId === suggestion.id) {
      return;
    }

    this.props.handleUpdateWindwardTemplates({
      name: 'module',
      value: suggestion.id,
    });

    this.setState({
      moduleText: suggestion.text,
      selectedUserId: suggestion.id,
    });
  };

  handleGetNextSuggestionForModule = ({ value }) => {
    const { pagination } = this.state.module;

    if (pagination && pagination.currentPage < pagination.totalPages) {
      this.setState({ moduleLoading: true });
      this.props.getModuleSuggestion(value).then(response => this.updateModuleSuggestion(response));
    }
  };

  handleUpdateFilter = suggestion => {
    if (suggestion.hasOwnProperty('text')) {
      this.setState({ moduleText: suggestion.text });
      this.handleGetSuggestionForModule({ value: suggestion.text });
    }
  };

  handleUploadTemplate = event => {
    const { fileName, selectedUserId } = this.state;
    const onUpload = this.props.popup.getIn(['props', 'onUpload']);

    onUpload(event, selectedUserId, fileName);
  };

  handleRenderSuggestion = suggestion => <div>{suggestion.text}</div>;

  handleGetSuggestionValue = suggestion => suggestion.id;

  render() {
    const { isValid, isUploading, inputErrors } = this.props;
    const { fileName, module, moduleText, moduleLoading } = this.state;
    const moduleProps = {
      suggestions: module.suggestions,
      moduleLoading,
      moduleText,
      getSuggestion: this.handleGetSuggestionForModule,
      getNextSuggestion: this.handleGetNextSuggestionForModule,
      updateSuggestions: this.handleUpdateFilter,
      renderSuggestion: this.handleRenderSuggestion,
      getSuggestionValue: this.handleGetSuggestionValue,
      suggestionSelected: this.handleSuggestionSelected,
    };
    const preparedErrors = Object.values(inputErrors.toJS()).map(error => error.replace(/[\r\n]/g, ''));

    return (
      <Popups.UploadTemplatePopup
        fileName={fileName}
        id="uploadTemplatePopup"
        inputErrors={fromJS([...preparedErrors])}
        isUploading={isUploading}
        isValid={isValid}
        onClose={this.handleClose}
        onSelectFile={this.handleSelectFile}
        onSelectModule={this.handleGetSuggestionForModule}
        onUpload={this.handleUploadTemplate}
        {...moduleProps}
      />
    );
  }
}

UploadTemplatePopupContainer.propTypes = {
  clearWindwardTemplate: PropTypes.func.isRequired,
  getModuleSuggestion: PropTypes.func.isRequired,
  handleUpdateWindwardTemplates: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
  isValid: PropTypes.bool,
};

UploadTemplatePopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  ...props,
  isUploading: state.windward.get('isUploading'),
  inputErrors: state.windward.get('inputErrors'),
  isValid: state.windward.get('isValid'),
});

const mapDispatchToProps = dispatch => ({
  getModuleSuggestion: (actionFrom, value) => dispatch(getModuleSuggestions(actionFrom, value)),
  ...bindActionCreators(
    {
      clearWindwardTemplate,
      uploadTemplate,
      handleUpdateWindwardTemplates,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadTemplatePopupContainer);
