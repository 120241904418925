import { fromJS } from 'immutable';
import moment from 'moment';
import * as ActionType from '../actions/dashboards';
import { dayToString, stringToDate } from '../utils/dateFormat';

import config from '../config';
import { setIn } from '../utils/ChangeSpy';

const widgets = config.main.get('widgets').toJS();
const widgetsOrder = [];

const getWidgets = () => {
  for (const i in widgets) {
    if (widgets.hasOwnProperty(i)) {
      const index = widgetsOrder.length + 1;

      widgets[i].style = {
        left: 0,
        top: 0,
        width: 500,
        height: 200,
        zIndex: 1 + index,
      };

      widgets[i].data = [];

      widgetsOrder.push(i);
    }
  }

  return widgets;
};
const defaultState = fromJS({
  popupDates: {
    to: moment(),
    from: moment().subtract(7, 'days'),
  },
  currentDrag: null,
  positionX: 0,
  positionY: 0,
  searchText: '',
  widgetsOrder,
}).merge({ widgets: fromJS(getWidgets()) });

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.LOADED_WIDGETS:
      return mapResponse(state, action.response);

    case ActionType.MOVE_WIDGET_TO_TOP:
      return moveLastActiveWidgetToTop(state, action.name);

    case ActionType.START_DRAG:
      return moveLastActiveWidgetToTop(
        state.merge({
          currentDrag: action.name,
          positionX: action.x,
          positionY: action.y,
        }),
        action.name,
      );

    case ActionType.DRAG:
      return drag(state, action);

    case ActionType.STOP_DRAG:
      return drag(state, action).mergeDeep({
        currentDrag: null,
      });

    case ActionType.TRIGGER_WIDGET_VISIBILITY:
      return moveLastActiveWidgetToTop(
        state.updateIn(['widgets', action.name], widget => {
          widget = widget.set('visible', !widget.get('visible'));
          widget = widget.setIn(['style', 'left'], 0);
          widget = widget.setIn(['style', 'top'], 0);
          widget = widget.set('portlet', action.name);
          widget = widget.set('loaded', false);
          widget = widget.set('loading', false);

          return widget;
        }),
        action.name,
      );

    case ActionType.SORT_WIDGET_DATA:
      return sortWidgetData(state, action);

    case ActionType.LOADING_WIDGET_DATA:
      return state.setIn(['widgets', action.name, 'loading'], true);

    case ActionType.SELECTED_IDS: {
      const path = ['widgets', action.name, 'data', action.order, 'selected'];
      const selected = state.getIn(path);

      return state.setIn(path, !selected);
    }

    case ActionType.SELECTED_ALL_IDS: {
      const data = state.getIn(['widgets', action.name, 'data']).toJS();

      Object.entries(data).forEach(item => {
        state = state.setIn(['widgets', action.name, 'data', item[0], 'selected'], true);
      });

      return state;
    }

    case ActionType.RESET_ALL_IDS: {
      const data = state.getIn(['widgets', action.name, 'data']).toJS();

      Object.entries(data).forEach(item => {
        state = state.setIn(['widgets', action.name, 'data', item[0], 'selected'], false);
      });

      return state;
    }

    case ActionType.LOADED_WIDGET_DATA:
      return preSort(
        state
          .setIn(['widgets', action.name, 'loading'], false)
          .setIn(['widgets', action.name, 'loaded'], true)
          .setIn(['widgets', action.name, 'data'], mapDataResponse(action)),
        action,
      );

    case ActionType.CHANGE_SEARCH_TEXT:
      return state.set('searchText', action.text);

    case ActionType.CHANGE_WIDGET_SIZES:
      return moveLastActiveWidgetToTop(
        state.mergeIn(['widgets', action.name, 'style'], {
          height: action.height,
          width: action.width,
        }),
      );

    case ActionType.CHANGE_FIELD:
      return setIn(state, action.field, action.value);

    default:
      return state;
  }
}

function moveLastActiveWidgetToTop(state, widgetName) {
  const widgetsOrder = state
    .get('widgetsOrder')
    .filter(v => v !== widgetName)
    .push(widgetName);
  const widgets = state
    .get('widgets')
    .map((widget, key) => widget.setIn(['style', 'zIndex'], widgetsOrder.indexOf(key)));

  return state.merge({
    widgets,
    widgetsOrder,
  });
}

function drag(state, action) {
  const current = state.get('currentDrag');

  if (current === null) return state;

  return state.mergeDeep({
    currentDrag: current,
    positionX: action.x,
    positionY: action.y,
    widgets: {
      [current]: {
        style: {
          left: state.getIn(['widgets', current, 'style', 'left'], 0) + action.x - state.get('positionX'),
          top: state.getIn(['widgets', current, 'style', 'top'], 0) + action.y - state.get('positionY'),
        },
      },
    },
  });
}

const FIELD_MAP = {
  x: 'left',
  y: 'top',
  w: 'width',
  h: 'height',
};

function mapResponse(state, { dashprefs }) {
  return (dashprefs || []).reduce((res, widget) => {
    const styled = Object.keys(widget).reduce((res, field) => {
      if (field === 'portlet') return setIn(res, ['widgets', widget.portlet, field], widget[field]);

      return setIn(res, ['widgets', widget.portlet, 'style', FIELD_MAP[field]], widget[field]);
    }, res);

    let widgetMoved = moveLastActiveWidgetToTop(styled, widget.portlet);

    widgetMoved = widgetMoved.setIn(['widgets', widget.portlet, 'visible'], true);
    if (widget.c) {
      widgetMoved = widgetMoved.setIn(
        ['widgets', widget.portlet, 'columns'],
        fromJS(filterColumn(widget.c, 'industry', 'execIndustryLabel')),
      );
    }

    return widgetMoved;
  }, state);
}

function mapDataResponse(action) {
  return fromJS(
    action.response.data.map((row, index) => {
      if (row.date) row.date = dayToString(moment(row.date));
      if (row.naDate) row.naDate = dayToString(moment(row.naDate));
      row.order = index;
      row.selected = false;

      return row;
    }),
  );
}

/**
 * Re-order data list to make sorting function work.
 */
function reorder(data) {
  return data.map((d, i) => d.set('order', i));
}

function sortWidgetData(state, action) {
  return state.updateIn(['widgets', action.widget], widget =>
    widget
      .update('data', data => reorder(data.sort(sortByOrder(action.order))))
      .update('columns', columns =>
        action.order.reduce(
          (res, column) => {
            const columnIndex = res.findKey(col => col.get('field') === column.colId);

            return res.setIn([columnIndex, 'sort'], column.sort);
          },
          columns.map(column => column.remove('sort')),
        ),
      ),
  );
}

function sortByOrder(order) {
  order = order.map(column => {
    column.flow = column.sort === 'asc' ? 1 : -1;

    return column;
  });

  return function(a, b) {
    return order.reduce((res, column) => {
      if (res) return res;

      const aVal = getValueOrData(a, column.colId);
      const bVal = getValueOrData(b, column.colId);
      const typeofA = typeof aVal;
      const typeofB = typeof bVal;

      if (typeofA !== typeofB) return 1;
      if (typeofA === 'string') return aVal.localeCompare(bVal) * column.flow;

      return (aVal - bVal) * column.flow || 0;
    }, 0);
  };
}

function getValueOrData(state, field) {
  let value = state.get(field);

  if (field === 'date' || field === 'naDate') {
    value = stringToDate(value).valueOf();
  }

  return value;
}

/**
 * Try to sort table after loaded.
 */
function preSort(state, action) {
  const columns = state.getIn(['widgets', action.name, 'columns']);
  const sortings = columns.filter(c => !!c.get('sort'));

  if (sortings.size > 0) {
    const sortAction = {
      widget: action.name,
      order: [
        {
          colId: sortings.getIn([0, 'field']),
          sort: sortings.getIn([0, 'sort']),
        },
      ],
    };

    return sortWidgetData(state, sortAction);
  }

  return state;
}

function filterColumn(data, ...rest) {
  return data.filter(d => rest.indexOf(d.field) < 0);
}
