/*
 * Export jQuery into window.$ and window.jQuery because bootstrap component
 * do not require it as node_module but search it in global space.
 */
window.$ = window.jQuery = require('jquery');
// Activate Bootstrap bindings for dropdowns, modals and so.
require('bootstrap');

window.$(document).ready(() => {
  const { $ } = window;
  // Show&Hide inputs
  // $(document).on('click', '.abbr .input-group-addon', function () {
  //    $('.dba').toggleClass('hide');
  //    $(this).find('.fa-plus, .fa-minus').toggleClass('hide');
  // });

  // $(document).on('click', '.city-index .input-group-addon, .city-index2 .input-group-addon', function () {
  //    $('.f-country').toggleClass('hide');
  //    $(this).find('.fa-plus, .fa-minus').toggleClass('hide');
  // });

  // Toggle vertical accordions on user panels. Also change icon on it.
  $(document).on('click', '.btn-switcher', function() {
    $('article.accordion section').toggleClass('acc-close');
    $(this)
      .find('.fa-caret-square-o-left, .fa-caret-square-o-right')
      .toggleClass('hide');
  });

  $(document).on('click', 'label.f-activity span', function() {
    $(this)
      .find('.fa-cogs')
      .toggleClass('active');
    $('.block').toggleClass('show');
  });

  // //TODO does not work. review when elements with such selector will be coded.
  // $('.BrowseControl-label input').each(function () {
  //    var $checkbox = $(this);
  //    $checkbox.on('change', function () {
  //        $checkbox.parent().siblings().toggle();
  //    });
  // });
});
