import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { SortableContainer } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import arrayMove from 'array-move';

import { changeSortingFields, changeSortingOrder, getStartSortSettings } from '../../actions/leadsReport';
import SorterRow from '../helpers/SorterRow';
import Select from '../helpers/Select';
import { sortByNumber } from '../../utils/sorting';

const DEFAULT_SORT_SELECT_TEXT = 'Add...';

/**
 * Leads report sorter component.
 *
 * @param {object} props Props.
 * @param {boolean} props.isStartSortFetching Show spinner icon when start sort settings is loading.
 * @param {Function} props.getStartSortSettings Method for getting start sort settings.
 * @param {Function} props.changeSortingFields Method for handle sort fields change.
 * @param {Function} props.changeSortingOrder Method for handle sort order change.
 * @param {Immutable.List} props.sorts List of sorting items.
 */
const SorterComponent = props => {
  const { isStartSortFetching, changeSortingFields, changeSortingOrder, getStartSortSettings, sorts } = props;

  useEffect(() => {
    getStartSortSettings();
  }, []);

  const spinner = isStartSortFetching ? (
    <div className="text-center">
      <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
    </div>
  ) : null;

  const SortableList = SortableContainer(({ items, onChangeSort }) => (
    <div className="sort-menu">
      {items.map((item, index) => (
        <SorterRow
          key={item.name}
          childProps={{
            columnName: item.name,
            direction: item.sd,
            order: item.order,
            defaultOrder: -1,
            onChangeSort,
          }}
          index={index}
        >
          {item.text}
        </SorterRow>
      ))}
    </div>
  ));

  const includedItems = sortByNumber(
    sorts.filter(item => item.get('include') === true),
    'order',
    -1,
  ).toJS();

  function handleSortFieldsChange(field, direction, included, order) {
    if (field !== DEFAULT_SORT_SELECT_TEXT) {
      changeSortingFields(field, direction, included, order);
    }

    if (included === false) {
      const reorderedItems = includedItems.filter(item => item.name !== field);

      reorderedItems.forEach((item, index) => changeSortingFields(item.name, item.sd, true, index + 1));
    }
  }

  function handleSortDragEndDrop({ oldIndex, newIndex }) {
    const newList = arrayMove(includedItems, oldIndex, newIndex);

    const orderedFieldsNames = {};

    for (let i = 0; i < newList.length; i++) {
      orderedFieldsNames[newList[i].name] = i;
    }

    changeSortingOrder(orderedFieldsNames);
  }

  function handleSelectChange(event, includedItems) {
    const isIncluded = includedItems.find(item => item.name === event.target.value);

    if (includedItems.length === 0 || isIncluded === undefined) {
      handleSortFieldsChange(event.target.value, 1, true, includedItems.length + 1);
    }
  }

  return (
    <>
      <p className="mt2 mb5 leads-settings__sort-title">Sort Order</p>
      {spinner}
      {isStartSortFetching === false && (
        <div className="leads-settings__sort-data">
          <SortableList
            distance={1}
            items={includedItems}
            lockAxis="y"
            onChangeSort={handleSortFieldsChange}
            onSortEnd={handleSortDragEndDrop}
          />
          <Select
            defaultText="Add..."
            id="sortLeadsReportSelect"
            label="sorts"
            name="sortLeadsReportSelect"
            nameKey="text"
            onChange={event => handleSelectChange(event, includedItems)}
            options={sorts}
            value={DEFAULT_SORT_SELECT_TEXT}
            valueKey="name"
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  isStartSortFetching: state.leadsReport.get('fetchingStartSortSettings'),
  sorts: state.leadsReport.get('sorts'),
});

const mapActionToProps = {
  getStartSortSettings,
  changeSortingFields,
  changeSortingOrder,
};

SorterComponent.propTypes = {
  changeSortingFields: PropTypes.func.isRequired,
  changeSortingOrder: PropTypes.func.isRequired,
  getStartSortSettings: PropTypes.func.isRequired,
  isStartSortFetching: PropTypes.bool.isRequired,
  sorts: PropTypes.instanceOf(List).isRequired,
};

const Sorter = connect(mapStateToProps, mapActionToProps)(React.memo(SorterComponent));

export { Sorter };
