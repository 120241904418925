import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import React, { PureComponent, Children } from 'react';
import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';
import { List } from 'immutable';

import getZIndex from '../../utils/getZIndex';
import uniqueId from '../../utils/uniqueId';

import { PureDeepValue } from '../decorators/DeepValue';
import { PureChangeDetection } from '../decorators/ChangeDetection';
import FormGroup from './FormGroup';
import { hasCode } from '../../middleware/activate';

const noSuggestions = List();
const fullWidth = { display: 'block' };

/**
 * General auto suggestion component.
 *
 * @param props {Object}.
 * @param props.name {string} Name of control.
 * @param props.label {string} Label of control.
 * @param props.disabled {boolean} To disable or enable input control.
 * @param [props.changed=false] {boolean} To mark value as changed.
 * @param props.showLabel {boolean} To show or hide label of control.
 * @param props.hasError {boolean} To show red border if set to true.
 * @param props.className {string} Css class of control.
 * @param props.onChange {function} To handle text change event.
 * @param props.onSuggestionsFetchRequested {function} To recalculate list of users.
 * @param props.onSuggestionsClearRequested {function} To clear list of users.
 * @param props.getSuggestionValue {function} To get a text value for input control.
 * @param props.renderSuggestion {function} To render suggestion list.
 * @param props.onSuggestionSelected {function} To handle suggestion selection.
 * @param props.id {string} Id of control.
 * @param props.placeholder {string} Place holder text of control.
 * @param props.value {string} Value of text control.
 * @param props.suggestions {Array} Array of suggestions.
 * @returns {React.Component}
 * @class
 */
class AutoComplete extends PureComponent {
  constructor(props) {
    super(props);

    this.id = uniqueId();
    this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddNew = this.handleAddNew.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(this);
    this.shouldSuggest = this.shouldSuggest.bind(this);
    this.handleSuggestionsSelected = this.handleSuggestionsSelected.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.saveRef = this.saveRef.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.container = null;
    this.canDisplay = true;
    this.state = {
      loading: false,
    };
  }

  static defaultProps = {
    highlightFirst: true,
  };

  handleSuggestionsClearRequested() {
    const { suggestions, onUpdateSuggestions } = this.props;

    if (suggestions) {
      if (suggestions.size > 0 && onUpdateSuggestions) {
        onUpdateSuggestions({
          suggestions: noSuggestions,
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.loading !== nextProps.loading) {
      this.setState({ loading: nextProps.loading });
    } else if (!('loading' in nextProps)) {
      if (this.props.suggestions !== nextProps.suggestions) {
        this.setState({ loading: false });
      }
    } else {
      this.setState({ loading: this.props.loading });
    }
  }

  handleInputChange(event) {
    event.preventDefault();

    const { onUpdateSuggestions } = this.props;

    this.canDisplay = true;
    if (event.target.value !== undefined) {
      if (typeof event.target.value === 'string' && onUpdateSuggestions) {
        onUpdateSuggestions({
          text: event.target.value,
          selected: null,
        });
      }
    }
  }

  handleBlur(event) {
    this.canDisplay = true;
    if (this.props.inputProps && this.props.inputProps.onBlur) {
      return this.props.inputProps.onBlur(event);
    }
  }

  handleAddNew() {
    this.props.onUpdateSuggestions({
      suggestions: noSuggestions,
    });
    this.props.addNew(this.props.text);
  }

  handleScroll(event) {
    // Stop high-light suggestion item to prevent scrolling jumps when users scroll up/down
    // this.container.resetHighlightedSuggestionOnMouseLeave();

    if (this.props.getNextSuggestion) {
      const { target } = event;
      const height = target.clientHeight;
      const currentBottom = target.scrollTop + target.clientHeight;
      const maxBottom = target.scrollHeight;

      if (currentBottom + height > maxBottom) {
        this.props.getNextSuggestion({ value: this.props.text });
      }
    }
  }

  handleSuggestionsSelected(suggests, ...rest) {
    this.canDisplay = false;
    if (suggests && suggests.value === 'add new') {
      return this.handleAddNew();
    }
    this.props.onSuggestionSelected(suggests, ...rest);
  }

  renderSuggestionsContainer({ children, containerProps }) {
    if (!this.container) return null;

    const targetElement = this.container.input;

    if (!targetElement) return null;

    const { appendToContainer } = this.props;
    const bodyRect = document.documentElement.getBoundingClientRect();
    const targetRect = targetElement.getBoundingClientRect();
    const top = appendToContainer ? '20px' : targetRect.bottom - bodyRect.top;
    const left = appendToContainer ? 0 : targetRect.left - bodyRect.left;
    const width = Math.max(targetRect.width, 150);
    const noData = this.getNoData();
    const loader = this.getLoader();
    const zIndex = getZIndex(targetElement) + 10;

    const style = {
      position: 'absolute',
      top,
      left,
      width,
      border: '1px solid gray',
      background: '#FFF',
      maxHeight: '200px',
      overflowY: 'auto',
      zIndex,
    };

    const divClass = classNames('autocomplete-div-hide-on-empty', {
      hidden: !(children || loader || noData),
    });
    const container = (
      <div {...containerProps} className={divClass} onScroll={this.handleScroll} style={style}>
        {children}
        {loader}
        {noData}
      </div>
    );

    const portal = createPortal(container, document.querySelector('body'));

    return appendToContainer ? container : portal;
  }

  shouldSuggest(value) {
    const { suggestIfEmpty = false, addNew = false, minSuggestLength = 0 } = this.props;

    if (suggestIfEmpty) return true;
    if (!this.canDisplay) return false;

    return addNew || (value || '').trim().length > minSuggestLength;
  }

  getAddNew() {
    const { addNew } = this.props;

    return !!addNew;
  }

  getLoader() {
    const { loading } = this.state;

    if (!loading) return null;

    return (
      <ul className="react-autosuggest__suggestions-list" role="listbox">
        <li className="react-autosuggest__suggestion" role="option">
          <i className="fa fa-spinner fa-spin" /> Loading...
        </li>
      </ul>
    );
  }

  getNoData() {
    const { suggestions, text } = this.props;
    const { loading } = this.state;

    if (loading) return null;
    if (suggestions === noSuggestions) return null;
    if (!text || text.length === 0) return null;
    if (!suggestions) return null;
    if (suggestions.size > 0) {
      return null;
    }

    return (
      <ul className="react-autosuggest__suggestions-list" role="listbox">
        <li className="react-autosuggest__suggestion" role="option">
          No data
        </li>
      </ul>
    );
  }

  getSuggests(suggests) {
    const { addNew } = this.props;

    if (!addNew) return suggests;

    return [
      {
        text: 'Add New...',
        style: fullWidth,
        value: 'add new',
        onClick: this.handleAddNew,
      },
      ...suggests,
    ];
  }

  saveRef(ref) {
    this.container = ref;
    if (!this.props.inputProps) return;
    if (!this.props.inputProps.ref) return;
    this.props.inputProps.ref(ref);
  }

  renderSuggestion(...rest) {
    return <div className="react-autosuggest__suggestion-wrapper">{this.props.renderSuggestion(...rest)}</div>;
  }

  render() {
    const {
      suggestions = noSuggestions,
      onSuggestionSelected,
      inputProps: _inputProps,
      getSuggestion,
      getSuggestionValue,
      renderSuggestion,
      text = '',
      changed = false,
      loading,
      formGroupClass,
      highlightFirst,
      onFocus,
      ...extraProps
    } = this.props;

    const inputProps = {
      id: this.id,
      ..._inputProps,
      value: text,
      onChange: this.handleInputChange,
      onBlur: this.handleBlur,
      className: classNames(_inputProps.className, { changed }),
    };
    const suggestionsArray = this.getSuggests(suggestions ? suggestions.toJS() : []);
    const labelClassName = classNames({ hidden: !inputProps.label });
    const FormGroupClass = classNames(formGroupClass, {
      'input-group': !formGroupClass,
    });

    const wrappedGetSuggestions = (...rest) => {
      if (hasCode()) {
        this.setState({ loading });
        getSuggestion(...rest);
      }
    };

    return (
      <FormGroup className={FormGroupClass}>
        <label className={labelClassName} htmlFor={inputProps.id}>
          {inputProps.label}
        </label>
        <Autosuggest
          {...extraProps}
          ref={this.saveRef}
          getSuggestionValue={getSuggestionValue}
          highlightFirstSuggestion={highlightFirst}
          inputProps={inputProps}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          onSuggestionSelected={this.handleSuggestionsSelected}
          onSuggestionsFetchRequested={wrappedGetSuggestions}
          renderSuggestion={this.renderSuggestion}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          shouldRenderSuggestions={this.shouldSuggest}
          suggestions={suggestionsArray}
        />
        {this.props.children}
      </FormGroup>
    );
  }
}

AutoComplete.propTypes = {
  addNew: PropTypes.func,
  getNextSuggestion: PropTypes.func,
  getSuggestion: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func.isRequired,
  highlightFirst: PropTypes.bool,
  inputProps: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  minSuggestLength: PropTypes.number,
  onSuggestionSelected: PropTypes.func.isRequired,
  onUpdateSuggestions: PropTypes.func,
  renderSuggestion: PropTypes.func.isRequired,
  suggestIfEmpty: PropTypes.bool,
};

AutoComplete.defaultProps = {
  highlightFirst: true,
};

const Extended = PureDeepValue(PureChangeDetection(AutoComplete));

export default Extended;

/**
 * @param props {Object} InputProps for AutoCompleteComponent.
 * @returns {InputProps.props.children}
 * @class
 */
export class InputProps extends PureComponent {
  render() {
    const { props } = this;
    const { children, ...inputProps } = props;
    let result = children;

    Children.map(children, child => {
      const { inputProps: _inputProps = {}, ...rest } = child.props;
      const inputPropObject = {
        inputProps: { ..._inputProps, ...inputProps },
      };
      const Component = child.type;

      result = <Component {...inputPropObject} {...rest} />;
    });

    return result;
  }
}
