import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import classNames from 'classnames';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import Popups from '../../components/Popup';
import { getChanged, getIn, isDeepChanged, unwrap } from '../../utils/ChangeSpy';
import { findUsers, changeField, clearSuggest, makeContactLP } from '../../actions/contact/contactLP';
import { changeContactInfoField, closeValidationError, reloadContactDetail } from '../../actions/contactDetail';

import AutoComplete from '../AutoComplete';

class AddLpContactPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.onLpChange = this.onLpChange.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onFetchUsers = this.onFetchUsers.bind(this);
    this.onUpdateRelation = this.onUpdateSuggestions.bind(this, 'suggestRelation');
    this.onSelectRelation = this.onSelectUser.bind(this, 'suggestRelation');
    this.onErrorClose = this.onErrorClose.bind(this);
    this.onChangeSuggestion = this.onChangeSuggestion.bind(this);
    this.onFindEmail = this.onFindEmail.bind(this);
    this.state = { submitting: false };
  }

  componentDidMount() {
    const module = {
      username: this.context.currentUser.get('module') || '',
      id: this.context.currentUser.get('moduleId') || 0,
    };

    this.props.changeField({ name: 'suggestRelation', value: module.username });
    this.props.changeField({ name: 'lpPrimaryRelId', value: module.id });
  }

  getChildContext() {
    return {
      onErrorClose: this.onErrorClose,
      inputErrors: this.props.inputErrors,
    };
  }

  onErrorClose(e, field) {
    this.props.closeValidationError({ field });
  }

  onChange(event) {
    const { name, value } = event.target;

    return this.props.changeContactInfoField({ name, value });
  }

  onLpChange(event) {
    const { name, value } = event.target;

    return this.props.changeField({ name, value });
  }

  onCreate() {
    if (this.props.contact.get('isValid')) {
      const id = this.props.contact.get('id') || 'new';
      const body = getChanged(this.props.contact);
      const prefix = unwrap(this.props.contact.get('prefix')).get('value');
      const suffix = unwrap(this.props.contact.get('suffix')).get('value');
      const lpFields = getChanged(this.props.lp);

      const afterSuccess = ({ response }) => {
        this.props.reloadContactDetail({ id: response.id }, this.onClose);
      };

      const afterError = () => {
        this.setState({ submitting: false });
      };

      this.setState({ submitting: true }, () => {
        this.props.makeContactLP({ ...body, ...lpFields, prefix, suffix, id }, afterSuccess, afterError);
      });
    }
  }

  onClose() {
    this.context.closePopupUnsafe();
  }

  static onGetValue(suggestion) {
    return suggestion.value;
  }

  onFetchUsers({ value }) {
    this.props.findUsers({ filter: value });
  }

  onUpdateSuggestions(suggestionName, param) {
    if ('text' in param) {
      const name = suggestionName;
      const value = param.text;

      this.props.changeField({ name, value });
    }

    if ('suggestions' in param) {
      const name = 'suggests';
      const value = param.suggestions;

      this.props.changeField({ name, value });
    }
  }

  onSelectUser(name, e, { suggestion }) {
    this.props.changeField({ name, value: suggestion.text });
  }

  getSuggestProps(name) {
    return {
      onFetch: this.onFetchUsers,
      onUpdate: this[`onUpdate${name}`],
      getValue: AddLpContactPopupContainer.onGetValue,
      onSelect: this[`onSelect${name}`],
      value: this.props.lp.get(`suggest${name}`, ''),
    };
  }

  onChangeSuggestion(obj) {
    if (typeof obj.value === 'string') {
      const { value } = obj;
      const nextValue = this.props.contactInfo.get('suffixes').find(
        suffix => suffix.get('name') === value,
        null,
        Map({
          name: value || '',
          value: value || '',
        }),
      );

      this.props.changeContactInfoField({ name: 'suffix', value: nextValue });
    }
  }

  getSuffixesSuggest() {
    const value = getIn(this.props.contactInfo, 'suffix').get('name');
    const idValue = value;
    const regExp = new RegExp(String(value).replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1'), 'i');
    const className = classNames('form-control input-sm', {
      changed: isDeepChanged(this.props.contactInfo.get('suffix')),
    });
    const suggestions = getIn(this.props.contactInfo, 'suffixes')
      .filter(suffix => regExp.test(suffix.get('name')))
      .map(suffix => ({
        id: suffix.get('value'),
        text: suffix.get('name'),
      }));

    return (
      <AutoComplete
        change={this.onChangeSuggestion}
        find={args => {
          setTimeout(
            () =>
              args.afterSuccess({
                response: {
                  data: getIn(this.props.contactInfo, 'suffixes')
                    .filter(suffix => regExp.test(suffix.get('name')))
                    .map(suffix => ({
                      id: suffix.get('value'),
                      text: suffix.get('name'),
                    })),
                },
              }),
            0,
          );
        }}
        idValue={idValue}
        inputProps={{ className, name: 'suffix' }}
        loading={false}
        name="suffix"
        suggestions={suggestions}
        suggestsName="suffixes"
        value={value}
        suggestIfEmpty
      />
    );
  }

  onFindEmail() {
    this.context.openPopup('EmailFinderPopup', this.props);
  }

  render() {
    const { children, contact, lp } = this.props;

    const suggestRelation = this.getSuggestProps('Relation');
    const isValid = this.props.inputErrors && this.props.inputErrors.size === 0;

    return (
      <div>
        <Popups.AddLpContactPopup
          contact={contact}
          isValid={isValid}
          lp={lp}
          onChange={this.onChange}
          onCreate={this.onCreate}
          onFindEmail={this.onFindEmail}
          onLpChange={this.onLpChange}
          submitting={this.state.submitting}
          suggestRelation={suggestRelation}
          suggestSuffix={this.getSuffixesSuggest()}
        />
        {children}
      </div>
    );
  }
}

AddLpContactPopupContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopupUnsafe: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
};

AddLpContactPopupContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};

AddLpContactPopupContainer.closable = false;

function mapStateToProps(state, props) {
  return {
    ...props,
    inputErrors: state.contact.info.get('inputErrors'),
    contact: state.contact.info,
    contactInfo: state.contact.info,
    lp: state.contact.lp,
  };
}

export { AddLpContactPopupContainer };

const Connected = connect(
  mapStateToProps,
  {
    findUsers,
    changeContactInfoField,
    clearSuggest,
    makeContactLP,
    reloadContactDetail,
    changeField,
    closeValidationError,
  },
  mergeProps,
  connectOptions,
)(AddLpContactPopupContainer);

Connected.closable = false;

export default Connected;
