import React, { PureComponent } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import Input from '../../../helpers/Input';
import Textarea from '../../../helpers/Textarea';
import Checkbox from '../../../helpers/Checkbox';
import { unwrap } from '../../../../utils/ChangeSpy';
import { dayToString } from '../../../../utils/dateFormat';

export default class RestartAgreements extends PureComponent {
  render() {
    const { isReadOnly, retainers, onChange, onDelete } = this.props;
    const NAME_PREFIX = 'companyRetainers';

    return retainers.map((retainer, i) => {
      const startDate = unwrap(retainer.get('startDate'));
      const restartAgreement = moment.isMoment(startDate) && startDate.isValid() ? `- ${dayToString(startDate)}` : null;
      const formClassName = classNames('formRetainersItem', {
        'new-agreement': typeof retainer.get('id') === 'string',
      });

      if (unwrap(retainer.get('type') === 'retainer')) {
        return (
          <div key={retainer.get('id')} className={formClassName}>
            <div className="formRetainersItemTitleWrap">
              <h5 className="formRetainersItemTitle">Restart Agreement {restartAgreement} </h5>
            </div>
            <button
              className="formRetainersItemDelete"
              disabled={isReadOnly}
              onClick={() => onDelete(retainer.get('id'))}
              title="Delete"
              type="button"
            >
              <i className="fa fa-remove" />
            </button>
            <div className="row">
              <div className="col-xs-12 col-sm-2 col-md-2">
                <div className="form-group">
                  <Input
                    className="input-sm retainer-contractual-input"
                    disabled={isReadOnly}
                    label="Contractual Name"
                    name={`${NAME_PREFIX}.${i}.contractualName`}
                    onChange={onChange}
                    value={retainer.get('contractualName')}
                    showLabel
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2">
                <div className="form-group">
                  <Input
                    className="input-sm"
                    disabled={isReadOnly}
                    label="Monthly Retainer"
                    name={`${NAME_PREFIX}.${i}.retainer`}
                    type="money"
                    value={retainer.get('retainer')}
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-2 col-md-2">
                <div className="form-group">
                  <Input
                    className="input-sm"
                    disabled={isReadOnly}
                    label="Start Date"
                    name={`${NAME_PREFIX}.${i}.startDate`}
                    onChange={onChange}
                    placeholder="Start Date"
                    title="This is the date, per the contract, that the project kicks off"
                    type="date"
                    value={retainer.get('startDate')}
                    showLabel
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2">
                <div className="form-group">
                  <Input
                    className="input-sm"
                    disabled={isReadOnly}
                    label="End Date"
                    name={`${NAME_PREFIX}.${i}.endDate`}
                    onChange={onChange}
                    placeholder="End Date"
                    title="This is the date the agreement officially terminates"
                    type="date"
                    value={retainer.get('endDate')}
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-2 col-md-2">
                <div className="form-group">
                  <Input
                    className="input-sm"
                    disabled={isReadOnly}
                    label="Notification to Start Date"
                    name={`${NAME_PREFIX}.${i}.notificationStartDate`}
                    onChange={onChange}
                    placeholder="Notification to Start Date"
                    title="This is the date we were informed of the client's intent to start"
                    type="date"
                    value={retainer.get('notificationStartDate')}
                    showLabel
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2">
                <div className="form-group">
                  <Input
                    className="input-sm"
                    disabled={isReadOnly}
                    label="Notification to Cancel Date"
                    name={`${NAME_PREFIX}.${i}.notificationCancellationDate`}
                    onChange={onChange}
                    placeholder="Notification to Cancel Date"
                    title="This is the date that the client informed us of their intent to cancel"
                    type="date"
                    value={retainer.get('notificationCancellationDate')}
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-4 text-bold">Tail</div>
            </div>
            <div className="row">
              <div className="col-xs-4">
                <div className="row agreement-tail">
                  <div className="col-xs-4">
                    <div className="form-group d-flex">
                      <Input
                        className="input-sm tail-months-input"
                        disabled={isReadOnly}
                        id={`retainer-tail-months-${i}`}
                        min="0"
                        name={`${NAME_PREFIX}.${i}.tailMonths`}
                        onChange={onChange}
                        type="number"
                        value={retainer.get('tailMonths')}
                      />
                      <label htmlFor={`retainer-tail-months-${i}`}>Months from</label>
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <Checkbox
                      checked={retainer.get('tailLastIntroduced')}
                      disabled={isReadOnly}
                      id={`retainer-tail-last-introduced-${i}`}
                      label="Last Introduced"
                      labelClassName="last-introduced-label"
                      name={`${NAME_PREFIX}.${i}.tailLastIntroduced`}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-xs-4">
                    <Checkbox
                      checked={retainer.get('tailTermination')}
                      disabled={isReadOnly}
                      id={`retainer-tail-termination-${i}`}
                      label="Termination"
                      labelClassName="termination-label"
                      name={`${NAME_PREFIX}.${i}.tailTermination`}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-4 col-md-4">
                <Textarea
                  disabled={isReadOnly}
                  label="Notes"
                  name={`${NAME_PREFIX}.${i}.retainerNote`}
                  onChange={onChange}
                  placeholder=""
                  rows="4"
                  value={retainer.get('retainerNote')}
                  showLabel
                />
              </div>
            </div>
          </div>
        );
      }

      return null;
    });
  }
}
