/**
 * Normalize #value name in action object to int or default value.
 *
 * @param name {String} Name's name need to be changed.
 * @param type {String|Symbol} Action type that change value.
 * @param reducer {Function}.
 * @returns {*}
 */
export default function emptyToNull(name, type, reducer) {
  return function(state, action) {
    if (action.type === type) {
      if (action.name === name) {
        action.value = action.value === '' ? null : action.value;
      }
    }

    return reducer(state, action);
  };
}

/**
 * Normalize #value name in action object to int or default value.
 *
 * @param regExp {RegExp} Name's name regexp need to be changed.
 * @param type {String|Symbol} Action type that change value.
 * @param reducer {Function}.
 * @returns {*}
 */
export function emptyToNullByRegexp(regExp, type, reducer) {
  return function(state, action) {
    if (action.type === type) {
      if (regExp.test(action.name)) {
        action.value = action.value === '' ? null : action.value;
      }
    }

    return reducer(state, action);
  };
}

/**
 * Normalize #value name in action object to int or default value.
 *
 * @param regExp {RegExp} Name's name regexp need to be changed.
 * @param value {String} Value to transform.
 * @param target {*} Value after transform.
 * @param type {String|Symbol} Action type that change value.
 * @param reducer {Function}.
 * @returns {*}
 */
export function transformByRegexp(regExp, value, target, type, reducer) {
  return function(state, action) {
    if (action.type === type) {
      if (regExp.test(action.name)) {
        action.value = action.value === value ? target : action.value;
      }
    }

    return reducer(state, action);
  };
}
