import React from 'react';
import { Map } from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';

import Checkbox from '../../../../helpers/Checkbox';
import config from '../../../../../config';
import Spinner from '../../../../helpers/Spinner';
import { unwrap } from '../../../../../utils/ChangeSpy';
import ProjectInfo from './ProjectInfo';
import ProjectSuggestionInput from './ProjectSuggestionInput';
import ProjectTextArea from './ProjectTextArea';
import ProjectTextInput from './ProjectTextInput';

/**
 * Project component.
 *
 * @param props {Object}.
 * @param props.projectData {Immutable.Map} Project information data.
 * @param props.common {Immutable.Map} Common Object.
 * @param props.onTextChange {Function} Handle event of changing text.
 * @param props.onSuggestionClose {Function} Handle event of closing suggestion.
 * @param props.onSuggestionSelect {Function} Handle event of selecting a suggestion.
 * @param props.onSuggestionInputClick {Function} Handle event of clicking on input element.
 * @param {boolean} props.canEditData Whether user can edit any active element that can be changed or clicked.
 * @returns {React.Component}
 * @constructor
 */
const TopPanel = props => {
  const {
    projectData,
    common,
    onTextChange,
    onSuggestionClose,
    onSuggestionSelect,
    onSuggestionInputClick,
    canEditData,
  } = props;
  const createdDate = moment(projectData.get('dateCreated'));
  const createdDateString = createdDate.isValid() ? createdDate.format('L') : null;
  const type = config.values.getIn(['project', 'types', 'PROJECT']);
  const loading = common.get('projectLoading');
  const spinner = loading ? <Spinner className="text-white" /> : null;

  return (
    <div className="project-header mb10">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl0">
        <div className="b-project">
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 p0">
            <ProjectTextInput
              canEditData={canEditData}
              className="input-sm mb10"
              field={projectData.get('category')}
              onTextChange={onTextChange}
              type={type}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6">
          <div className="b-project ">
            <ProjectTextArea
              canEditData={canEditData}
              className="pl0"
              field={projectData.get('details')}
              id="projectDetails"
              label="Project Scope"
              name="details"
              onTextChange={onTextChange}
            />
            <ProjectTextArea
              canEditData={canEditData}
              field={projectData.get('status')}
              id="projectStatus"
              label="Status"
              name="status"
              onTextChange={onTextChange}
            />
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-3">
          <div className="b-project">
            <ProjectSuggestionInput
              canEditData={canEditData}
              id="projectHarvCoLead"
              label="HarvCo Lead"
              name="harvcoLead"
              onSuggestionClose={onSuggestionClose}
              onSuggestionInputClick={onSuggestionInputClick}
              onSuggestionSelect={onSuggestionSelect}
              onTextChange={onTextChange}
              row={projectData.get('harvcoLead')}
              suggestions={common.get('harvcoLeadSuggestions')}
            />
            <ProjectSuggestionInput
              canEditData={canEditData}
              id="projectClientLead"
              label="Client Lead"
              name="clientLead"
              onSuggestionClose={onSuggestionClose}
              onSuggestionInputClick={onSuggestionInputClick}
              onSuggestionSelect={onSuggestionSelect}
              onTextChange={onTextChange}
              row={projectData.get('clientLead')}
              suggestions={common.get('clientLeadSuggestions')}
            />
            <ProjectSuggestionInput
              canEditData={canEditData}
              id="projectExecutive"
              label="Executive"
              name="exec"
              onSuggestionClose={onSuggestionClose}
              onSuggestionInputClick={onSuggestionInputClick}
              onSuggestionSelect={onSuggestionSelect}
              onTextChange={onTextChange}
              row={projectData.get('exec')}
              suggestions={common.get('execSuggestions')}
            />
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-3">
          <div className="b-project">
            <ProjectInfo label="Created" value={createdDateString} />
            <ProjectInfo label="Type" value={projectData.get('type')} />
            <div className="group-checkbox">
              <Checkbox
                checked={projectData.get('active')}
                disabled={!canEditData}
                label={unwrap(projectData.get('active')) ? 'Active' : 'Inactive'}
                name="active"
                onChange={event => onTextChange({ event, data: { type } })}
                onClick={event => event.stopPropagation()}
              />
            </div>
          </div>
        </div>
      </div>
      {spinner}
    </div>
  );
};

TopPanel.propTypes = {
  canEditData: PropTypes.bool.isRequired,
  common: PropTypes.instanceOf(Map).isRequired,
  onSuggestionClose: PropTypes.func.isRequired,
  onSuggestionInputClick: PropTypes.func.isRequired,
  onSuggestionSelect: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  projectData: PropTypes.instanceOf(Map).isRequired,
};

export default TopPanel;
