import PropTypes from 'prop-types';

import React, { Component } from 'react';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import Popups from '../../components/Popup';
import { updateStatusCode, saveStatusCodes, getBuyers } from '../../actions/company/companyTarget';
import { reloadEvents } from '../../actions/company/companyEvents';

import { unwrap } from '../../utils/ChangeSpy';

class StatusCodePopupContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.getStatusSummary = this.getStatusSummary.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    const { popup, updateStatusCode } = this.props;
    const start = popup.getIn(['props', 'start']);
    const end = popup.getIn(['props', 'end']);

    updateStatusCode({
      field: 'statusUpdate',
      value: Immutable.Map({ mid: '4.60', start, end, waiting: false }),
    });
  }

  onChange(event) {
    this.props.updateStatusCode({
      field: 'statusUpdate.mid',
      value: event.target.value,
    });
  }

  onSave() {
    const { popup, statusUpdate } = this.props;
    const companyId = popup.getIn(['props', 'companyId']);
    const buyerId = popup.getIn(['props', 'buyerId']);
    const start = statusUpdate.get('mid');
    const end = statusUpdate.get('end');

    this.props.updateStatusCode({
      field: 'statusUpdate.waiting',
      value: true,
    });

    this.props.saveStatusCodes({
      companyId,
      buyerId,
      start: {
        bsnHarvco: this.getStatusSummary(start),
        currentStatus: start,
      },
      end: {
        bsnHarvco: this.getStatusSummary(end),
        currentStatus: end,
      },
      afterSuccess: () => {
        this.props.updateStatusCode({
          field: 'statusUpdate.waiting',
          value: false,
        });
        this.props.reloadEvents({ entityId: companyId });
        this.props.getBuyers({ companyId, afterSuccess: () => {} });
        this.context.closePopup();
      },
    });
  }

  getStatusSummary(value) {
    const { statuses } = this.props;
    const key = statuses.findKey(s => s.get('value') === value);

    if (key !== undefined && key !== null) {
      return statuses.getIn([key, 'summary']);
    }

    return '';
  }

  render() {
    const { statusUpdate, statuses, buyer } = this.props;

    return (
      <div>
        <Popups.StatusCodePopup
          buyer={buyer}
          onChange={this.onChange}
          onSave={this.onSave}
          statuses={statuses}
          statusUpdate={statusUpdate}
        />
      </div>
    );
  }
}

StatusCodePopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const buyers = state.targetCompany.target.get('buyers');
  const key = buyers.findKey(b => unwrap(b.get('activeBuyer')));
  let buyer = '';

  if (key !== null && key !== undefined) {
    buyer = unwrap(buyers.getIn([key, 'dsplBuyerLegalName'], ''));
  }

  return {
    ...props,
    statuses: state.targetCompany.target.get('statuses'),
    statusUpdate: state.targetCompany.target.get('statusUpdate'),
    buyer,
  };
};

export default connect(
  mapStateToProps,
  {
    updateStatusCode,
    saveStatusCodes,
    reloadEvents,
    getBuyers,
  },
  mergeProps,
  connectOptions,
)(StatusCodePopupContainer);
