import { CALL_API } from '../middleware/api';

export const FETCHING_ALL_TRANSACTIONS = Symbol('FETCHING_ALL_TRANSACTIONS');
export const FETCHING_ALL_TRANSACTIONS_SUCCESS = Symbol('FETCHING_ALL_TRANSACTIONS_SUCCESS');
export const FETCHING_ADDON_TRANSACTIONS = Symbol('FETCHING_ADDON_TRANSACTIONS');
export const FETCHING_ADDON_TRANSACTIONS_SUCCESS = Symbol('FETCHING_ADDON_TRANSACTIONS_SUCCESS');
export const FETCHING_PLATFORM_TRANSACTIONS = Symbol('FETCHING_PLATFORM_TRANSACTIONS');
export const FETCHING_PLATFORM_TRANSACTIONS_SUCCESS = Symbol('FETCHING_PLATFORM_TRANSACTIONS_SUCCESS');
export const FETCHING_BUYERS = Symbol('FETCHING_BUYERS');
export const FETCHING_BUYERS_SUCCESS = Symbol('FETCHING_BUYERS_SUCCESS');
export const FETCHING_DEALS_CLOSED = Symbol('FETCHING_DEALS_CLOSED');
export const FETCHING_DEALS_CLOSED_SUCCESS = Symbol('FETCHING_DEALS_CLOSED_SUCCESS');
export const FETCHING_DEALS_CLOSED_BY_STATE = Symbol('FETCHING_DEALS_CLOSED_BY_STATE');
export const FETCHING_DEALS_CLOSED_BY_STATE_SUCCESS = Symbol('FETCHING_DEALS_CLOSED_BY_STATE_SUCCESS');
export const FETCHING_DEALS_CLOSED_BY_YEARS = Symbol('FETCHING_DEALS_CLOSED_BY_YEARS');
export const FETCHING_DEALS_CLOSED_BY_YEARS_SUCCESS = Symbol('FETCHING_DEALS_CLOSED_BY_YEARS_SUCCESS');
export const FETCHING_EXECUTIVE_DEALS = Symbol('FETCHING_EXECUTIVE_DEALS');
export const FETCHING_EXECUTIVE_DEALS_SUCCESS = Symbol('FETCHING_EXECUTIVE_DEALS_SUCCESS');
export const FETCHING_PROFESSIONALS = Symbol('FETCHING_PROFESSIONALS');
export const FETCHING_PROFESSIONALS_SUCCESS = Symbol('FETCHING_PROFESSIONALS_SUCCESS');
export const FETCHING_TARGETS_STATS = Symbol('FETCHING_TARGETS_STATS');
export const FETCHING_TARGETS_STATS_SUCCESS = Symbol('FETCHING_TARGETS_STATS_SUCCESS');
export const FETCHING_BUYERS_STATS = Symbol('FETCHING_BUYERS');
export const FETCHING_BUYERS_STATS_SUCCESS = Symbol('FETCHING_BUYERS_STATS_SUCCESS');
export const FETCHING_EXECUTIVES_STATS = Symbol('FETCHING_EXECUTIVES_STATS');
export const FETCHING_EXECUTIVES_STATS_SUCCESS = Symbol('FETCHING_EXECUTIVES_STATS_SUCCESS');
export const FETCHING_TARGETS_APPROACHED_BY_YEARS = Symbol('FETCHING_TARGETS_APPROACHED_BY_YEARS');
export const FETCHING_TARGETS_APPROACHED_BY_YEARS_SUCCESS = Symbol('FETCHING_TARGETS_APPROACHED_BY_YEARS_SUCCESS');

/** Get general statistics for all transactions. */
export function getStatsAllTransactions() {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/general_stats/all_transactions`,
      startType: FETCHING_ALL_TRANSACTIONS,
      successType: FETCHING_ALL_TRANSACTIONS_SUCCESS,
    },
  };
}

/** Get general statistics for add/on transactions. */
export function getStatsAddOnTransactions() {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/general_stats/addon_transactions`,
      startType: FETCHING_ADDON_TRANSACTIONS,
      successType: FETCHING_ADDON_TRANSACTIONS_SUCCESS,
    },
  };
}

/** Get general statistics for platform transactions. */
export function getStatsPlatformTransactions() {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/general_stats/platform_transactions`,
      startType: FETCHING_PLATFORM_TRANSACTIONS,
      successType: FETCHING_PLATFORM_TRANSACTIONS_SUCCESS,
    },
  };
}

/** Get general statistics for buyers. */
export function getStatsBuyers() {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/general_stats/buyers`,
      startType: FETCHING_BUYERS,
      successType: FETCHING_BUYERS_SUCCESS,
    },
  };
}

/** Get general statistics for deals. */
export function getStatsDeals() {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/general_stats/deal_closed`,
      startType: FETCHING_DEALS_CLOSED,
      successType: FETCHING_DEALS_CLOSED_SUCCESS,
    },
  };
}

/** Get general statistics for deals by state. */
export function getStatsDealsByState() {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/general_stats/deal_closed_by_state`,
      startType: FETCHING_DEALS_CLOSED_BY_STATE,
      successType: FETCHING_DEALS_CLOSED_BY_STATE_SUCCESS,
    },
  };
}

/**
 * Get general statistics for deals by years.
 *
 * @param {boolean} isExtended Check if extended data is being requested.
 */
export function getStatsDealsByYears(isExtended = false) {
  const query = isExtended ? 'recent_years=10' : null;

  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/general_stats/deal_closed_by_years`,
      query,
      startType: FETCHING_DEALS_CLOSED_BY_YEARS,
      successType: FETCHING_DEALS_CLOSED_BY_YEARS_SUCCESS,
    },
  };
}

/** Get general statistics for executive deals. */
export function getStatsExecutiveDeals() {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/general_stats/executive_deals`,
      startType: FETCHING_EXECUTIVE_DEALS,
      successType: FETCHING_EXECUTIVE_DEALS_SUCCESS,
    },
  };
}

/** Get general statistics for professionals. */
export function getStatsProfessionals() {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/general_stats/professionals`,
      startType: FETCHING_PROFESSIONALS,
      successType: FETCHING_PROFESSIONALS_SUCCESS,
    },
  };
}

/** Get statistics for targets depending on status. */
export function getTargetsStatsByStatus() {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/general_stats/targets_stats`,
      startType: FETCHING_TARGETS_STATS,
      successType: FETCHING_TARGETS_STATS_SUCCESS,
    },
  };
}

/** Get statistics for buyers depending on status. */
export function getBuyersStatsByStatus() {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/general_stats/buyers_stats`,
      startType: FETCHING_BUYERS_STATS,
      successType: FETCHING_BUYERS_STATS_SUCCESS,
    },
  };
}

/** Get statistics for executives depending on status. */
export function getExecutivesStatsByStatus() {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/general_stats/executives_stats`,
      startType: FETCHING_EXECUTIVES_STATS,
      successType: FETCHING_EXECUTIVES_STATS_SUCCESS,
    },
  };
}

/**
 * Get statistics for targets approached by years.
 *
 * @param {boolean} isExtended Check if extended data is being requested.
 */
export function getTargetsApproachedByYears(isExtended = false) {
  const query = isExtended ? 'recent_years=10' : null;

  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/general_stats/targets_approached_by_years`,
      query,
      startType: FETCHING_TARGETS_APPROACHED_BY_YEARS,
      successType: FETCHING_TARGETS_APPROACHED_BY_YEARS_SUCCESS,
    },
  };
}
