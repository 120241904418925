import { fromJS } from 'immutable';
import { spy, setIn, setOriginalIn, getIn, revert } from '../../../../utils/ChangeSpy';

import {
  START_LOAD_PROJECT_TAGS,
  LOAD_PROJECT_TAGS_SUCCESS,
  UPDATE_PROJECT_TAGS,
  SAVE_PROJECT_TAGS_SUCCESS,
  SET_PROJECT_TAGS_VALIDATION_ERROR,
  CLOSE_PROJECT_TAGS_ERRORS,
} from '../../../../actions/company/buyer/addOnProject/projectTags';
import { REVERT_CHANGES } from '../../../../actions/company/buyer/addOnProject/main';
import { BUSINESS_MODELS, INDUSTRY_CATEGORIES } from '../../../../utils/industryTagsHelper';

const defaultState = spy(
  fromJS({
    loading: false,
    validationErrors: '',
    businessModels: [],
    industryCategories: [],
  }),
  [BUSINESS_MODELS, INDUSTRY_CATEGORIES],
);

export default function(state = defaultState, action) {
  switch (action.type) {
    case START_LOAD_PROJECT_TAGS:
      return state.set('loading', true);

    case LOAD_PROJECT_TAGS_SUCCESS: {
      const { projectBusinessModels } = action.response.data;
      const { projectIndustryCategories } = action.response.data;

      const businessModels = spy(fromJS({ businessModels: projectBusinessModels }), [BUSINESS_MODELS]).get(
        BUSINESS_MODELS,
      );
      const industryCategories = spy(fromJS({ industryCategories: projectIndustryCategories }), [
        INDUSTRY_CATEGORIES,
      ]).get(INDUSTRY_CATEGORIES);

      return state
        .set('loading', false)
        .set(BUSINESS_MODELS, businessModels)
        .set(INDUSTRY_CATEGORIES, industryCategories);
    }

    case UPDATE_PROJECT_TAGS: {
      let tempState = setIn(state, BUSINESS_MODELS, fromJS(action.models));

      tempState = setIn(tempState, INDUSTRY_CATEGORIES, fromJS(action.categories));

      return tempState;
    }

    case SAVE_PROJECT_TAGS_SUCCESS: {
      let tempState = setOriginalIn(state, BUSINESS_MODELS, getIn(state, BUSINESS_MODELS));

      tempState = setOriginalIn(tempState, INDUSTRY_CATEGORIES, getIn(state, INDUSTRY_CATEGORIES));

      return tempState.set('loading', false);
    }

    case SET_PROJECT_TAGS_VALIDATION_ERROR: {
      return state.set('validationErrors', action.error);
    }

    case CLOSE_PROJECT_TAGS_ERRORS: {
      return state.set('validationErrors', '');
    }

    case REVERT_CHANGES:
      return revert(state);

    default:
      return state;
  }
}
