import { CALL_API } from '../../middleware/api';
import { loadAssociates } from './associate';

export const CHANGE_STATUS_POPUP_FORM = Symbol('CHANGE_STATUS_POPUP_FORM');
export const LOADED_STATUSES_SUCCESS = Symbol('LOADED_STATUSES_SUCCESS');
export const APPLYING_NEW_STATUS = Symbol('APPLYING_NEW_STATUS');
export const APPLIED_NEW_STATUS_FAILURE = Symbol('APPLIED_NEW_STATUS_FAILURE');
export const APPLIED_NEW_STATUS_SUCCESS = Symbol('APPLIED_NEW_STATUS_SUCCESS');
export const RESET = Symbol('RESET');
export const VALIDATE = Symbol('VALIDATE');

/**
 * Save form's data changing.
 *
 * @param name {String} Form's field name.
 * @param approvalId {Any} Form's field value.
 * @returns Action.
 */
export function changeForm(name, value) {
  return {
    type: CHANGE_STATUS_POPUP_FORM,
    name,
    value,
  };
}

/**
 * Reset popup.
 *
 * @returns Action.
 */
export function reset() {
  return {
    type: RESET,
  };
}

/**
 * Load all available statuses.
 *
 * @returns Action.
 */
export function loadStatuses() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/buyers/statuses',
      successType: LOADED_STATUSES_SUCCESS,
    },
  };
}

/**
 * Validate data.
 *
 * @returns Action.
 */
export function validate() {
  return {
    type: VALIDATE,
  };
}

/**
 * Apply new status for which associated satisfy the condition.
 *
 * @param status {Float} Drop down list value.
 * @param statusText {String} Drop down list text.
 * @param state {String} State to be applied new status.
 * @param projectId {Number} Project id.
 * @param approvalId {Number} Approval id.
 * @returns Action.
 */
export function updateStatuses(status, statusText, states, projectId, approvalId) {
  const listUrl = approvalId === 'no_approval_lists' ? 'no_approval_lists' : `approval_lists/${approvalId}`;

  return {
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/projects/${projectId}/${listUrl}/update_statuses`,
      body: {
        status,
        statusText,
        states,
      },
      startType: APPLYING_NEW_STATUS,
      errorType: APPLIED_NEW_STATUS_FAILURE,
      successType: APPLIED_NEW_STATUS_SUCCESS,
      afterSuccess: ({ dispatch }) => {
        dispatch(loadAssociates(projectId, approvalId));
      },
    },
  };
}
