export const INDUSTRY_CATEGORIES = 'industryCategories';
export const BUSINESS_MODELS = 'businessModels';
export const BIC = 'bic';

export const INDUSTRY_CATEGORY_ERROR = 'Broad Industry Category is required.';
export const BUSINESS_MODEL_ERROR = 'Business model is required.';

export const BUYER_TAGS_ERROR = 'Buyer Industry Tags are required. Please, check Marketing/Approach tab.';

export const INDUSTRY_TAGS_ERROR = 'Industry Tags are required';

/**
 * Creates error message for industry tags tables.
 *
 * @param error
 * @returns {string}
 */
export function createIndustryErrorMsg(error) {
  let errorMsg = '';

  if (error.businessModel && error.industryCategory) {
    errorMsg = `${error.businessModel}, and ${error.industryCategory}`;
  } else {
    errorMsg += (error.businessModel || '') + (error.industryCategory || '');
  }

  return errorMsg;
}
