import React from 'react';
import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import Input from '../helpers/Input';

const AddHarvcoTagPopup = props => {
  const { isPopupFetching, isPopupFetchingError, onClose, onChange, onAddTag } = props;

  const header = 'Add HarvCo Tag';
  const footer = [
    <Button key="addTag" className="btn-primary btn-xs" onClick={onAddTag}>
      Add Tag
    </Button>,
    <Button key="close" className="btn-default btn-xs" onClick={onClose}>
      Close
    </Button>,
  ];
  const loading = isPopupFetching ? (
    <div className="loading">
      <i className="fa fa-spinner fa-pulse fa-3x fa-fw login-spinner" />
    </div>
  ) : null;
  const errorContent = isPopupFetchingError ? (
    <p className="error text-danger">{isPopupFetchingError.get('message')}</p>
  ) : null;

  return (
    <Popup footer={footer} header={header} id="addHarvCoTag" loading={loading}>
      <Input
        className="input-md"
        id="tag"
        label="Apply the following HarvCo Tag to the selected companies:"
        name="tag"
        onChange={onChange}
        placeholder=""
        autoFocus
        showLabel
      />
      {errorContent}
    </Popup>
  );
};

export default AddHarvcoTagPopup;
