import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Map } from 'immutable';

import BrowseCheckbox from './BrowseCheckbox';

function BrowseBuyerActivityFilter(props) {
  const minValue = 0;
  const [maxValue, setMaxValue] = useState(10);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [type, setType] = useState('number');

  useEffect(() => {
    props.onChange({
      filterName: props.filter.get('name'),
      filterData: {
        min,
        max,
        type,
      },
    });
  }, [min, max, type]);

  const handleTypeChange = event => {
    const { checked } = event.target;

    if (checked) {
      setMaxValue(100);
      setMax(100);
      setMin(0);
    } else {
      setMax(0);
      setMin(0);
    }

    setType(checked ? 'percent' : 'number');
  };

  const handleMinChange = event => {
    const { value } = event.target;

    if (value < minValue) {
      setMin(minValue);
    } else if (value > max) {
      setMin(max);
    } else {
      setMin(value ? Number(value) : '');
    }
  };

  const handleMaxChange = event => {
    const { value } = event.target;

    if (value > maxValue && type === 'percent') {
      setMax(maxValue);
    } else if (value < min) {
      setMax(min);
    } else {
      setMax(value ? Number(value) : '');
    }
  };

  return (
    <BrowseCheckbox {...props}>
      <div className="d-flex mt10">
        <span>#</span>
        <label className="switch">
          <input className="toggle-checkbox" onChange={handleTypeChange} type="checkbox" />
          <span className="slider round" />
        </label>
        <span>%</span>
      </div>
      <div className="d-flex activity-range">
        <input
          className="activity-range-input"
          onChange={handleMinChange}
          placeholder="Min"
          type="number"
          value={min}
        />
        <div className="form-group">&nbsp;-&nbsp;</div>
        <input
          className="activity-range-input"
          onChange={handleMaxChange}
          placeholder="Max"
          type="number"
          value={max}
        />
      </div>
    </BrowseCheckbox>
  );
}

BrowseBuyerActivityFilter.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  submitOnEnter: PropTypes.bool,
};

BrowseBuyerActivityFilter.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

export default BrowseBuyerActivityFilter;
