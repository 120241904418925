import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { List } from 'immutable';

import Grid from '../../helpers/AgGridTable';
import config from '../../../config';
import Menu from './Menu';
import TextEditor from './TextEditor';

/**
 * To fix issue: "Cannot refresh cells with cellRendererFramework".
 */
const PriComponent = ({ data }) => <span>{data.pri.value}</span>;

const CommentComponent = ({ data }) => <span>{data.bsnClient.value}</span>;

const HarveyCommentComponent = ({ data }) => <span>{data.harveyComment.value}</span>;

const NaDateRender = ({ data }) => <span>{data.naDate.isValid() ? data.naDate.format('MM/DD/YYYY') : ''}</span>;

const LaDateRender = ({ data }) => <span>{data.laDate.isValid() ? data.laDate.format('MM/DD/YYYY') : ''}</span>;

class ApprovedRender extends PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(approved) {
    const { data } = this.props;

    this.props.colDef.onClick({
      index: data.order,
      targetBuyerId: data.linkId,
      approved,
    });
  }

  render() {
    const { data } = this.props;

    if (data.loading) {
      return <i className="fa fa-spinner fa-spin" />;
    }

    if (data.approved.value === true) {
      return <i className="icon-approved fa fa-check clickable" onClick={() => this.onClick(null)} />;
    }

    if (data.approved.value === false) {
      return <i className="icon-rejected fa fa-times clickable" onClick={() => this.onClick(null)} />;
    }

    return (
      <div>
        <i className="icon-approved-not fa fa-check clickable mr10" onClick={() => this.onClick(true)} />
        <i className="icon-approved-not fa fa-times clickable" onClick={() => this.onClick(false)} />
      </div>
    );
  }
}

/**
 * Side menu component.
 *
 * @param props {Object}.
 * @param approvals {Immutable.List} Approval list.
 * @param associates {Immutable.List} Associate list.
 * @param common {Immutable.Map} Common Object.
 * @param columnConfigs {Immutable.List} Associate columns configuration.
 * @param onSort {Function} Handle event of sorting associate table.
 * @param onLoad {Function} Handle event of loading more.
 * @param onSelect {Function} Handle event of selecting a menu item.
 * @param {boolean} props.isNoApproval No approval list selected state.
 * @returns {React.Component}
 * @class
 */
class BodySection extends PureComponent {
  state = {
    loading: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.common.get('associatesLoading') !== state.loading) {
      return {
        loading: props.common.get('associatesLoading'),
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.onGridReady = this.onGridReady.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.state = { loading: true };
    this.columnDefs = config.tables.getIn(['approval', 'associate']).map(col => {
      switch (col.get('field')) {
        case 'approved':
          return col.merge({
            cellRendererFramework: ApprovedRender,
            onClick: this.props.onClick,
          });

        case 'revenue':
          return col.merge({
            cellEditorFramework: TextEditor,
            editable: true,
            placeHolder: 'Revenue',
            onBlur: this.onBlur,
            isValid: val => !isNaN(val),
          });

        case 'pri':
          return col.merge({
            cellRendererFramework: PriComponent,
            cellEditorFramework: TextEditor,
            editable: true,
            placeHolder: 'pri',
            onBlur: this.onBlur,
            isValid: val => !val || val.length <= 10,
          });

        case 'bsnClient':
          return col.merge({
            cellRendererFramework: CommentComponent,
            cellEditorFramework: TextEditor,
            editable: true,
            placeHolder: 'Client Comments',
            onBlur: this.onBlur,
            isValid: () => true,
          });

        case 'harveyComment':
          return col.merge({
            cellRendererFramework: HarveyCommentComponent,
            cellEditorFramework: TextEditor,
            editable: true,
            placeHolder: 'Harvey comments',
            onBlur: this.onBlur,
            isValid: () => true,
          });

        case 'employees':
          return col.merge({
            cellEditorFramework: TextEditor,
            editable: true,
            placeHolder: 'Employees',
            onBlur: this.onBlur,
            isValid: val => !isNaN(val),
          });

        case 'naDate':
          return col.set('cellRendererFramework', NaDateRender);

        case 'laDate':
          return col.set('cellRendererFramework', LaDateRender);

        default:
          return col;
      }
    });
  }

  onBlur() {
    if (this.api) {
      this.api.stopEditing();
    }
  }

  onGridReady(params) {
    this.api = params.api;
    this.api.showLoadingOverlay();
  }

  onCellClicked(e) {
    const {
      column: { colId },
      node: { rowIndex },
    } = e;

    if (['revenue', 'pri', 'employees', 'bsnClient', 'harveyComment'].indexOf(colId) > -1) {
      this.api.startEditingCell({
        rowIndex,
        colKey: colId,
      });
    } else {
      this.api.stopEditing();
    }
  }

  componentDidUpdate() {
    if (this.api) {
      this.api.sizeColumnsToFit();
    }
  }

  onCellValueChanged(params) {
    const {
      oldValue,
      newValue,
      colDef: { field, isValid },
      data: { targetId, linkId, order },
    } = params; // linkId = target_buyer_id
    let oVal = oldValue;
    let nVal = newValue;
    let buyerId = null;

    if (field === 'pri' || field === 'bsnClient' || field === 'harveyComment') {
      oVal = oldValue.value;
      nVal = newValue.value;
      buyerId = linkId;
    }

    if (oVal !== nVal && isValid(nVal)) {
      this.props.onUpdateCompany(
        { [field === 'pri' ? 'priority' : field]: nVal || null },
        {
          companyId: targetId,
          buyerId,
          merge: { name: field, value: nVal, index: order },
        },
      );
    }
  }

  getRowNodeId(data) {
    return data.order.toString();
  }

  render() {
    const { approvals, associates, common, onSort, onLoad, onSelect, onOpenLink, isNoApproval } = this.props;

    return (
      <div className="row approval-body">
        <div className="col-md-2 approval-body__left-side">
          <Menu approvals={approvals} common={common} isNoApproval={isNoApproval} onClick={onSelect} />
        </div>
        <div className="col-md-10 approval-body__right-side">
          <Grid
            columnDefs={this.columnDefs}
            getRowNodeId={this.getRowNodeId}
            isFetching={this.state.loading}
            onCellClicked={this.onCellClicked}
            onCellValueChanged={this.onCellValueChanged}
            onGetNextPageData={onLoad}
            onGridReady={this.onGridReady}
            onRowDoubleClicked={onOpenLink}
            onSortModelChanged={onSort}
            page={common.getIn(['paging', 'currentPage'])}
            rowData={associates}
            totalPages={common.getIn(['paging', 'totalPages'])}
            sortable
          />
        </div>
      </div>
    );
  }
}

BodySection.propTypes = {
  approvals: PropTypes.instanceOf(List).isRequired,
  associates: PropTypes.instanceOf(List).isRequired,
  common: PropTypes.instanceOf(Map).isRequired,
  isNoApproval: PropTypes.bool.isRequired,
  onOpenLink: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onUpdateCompany: PropTypes.func.isRequired,
};

export default BodySection;
