import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

import config from '../../../../config';
import Table from '../../../helpers/AgGridTable';
import AgHeader from './MidPanel/AgHeader';

class ApprovedFormat extends PureComponent {
  render() {
    const { value } = this.props;

    if (value === true) {
      return (
        <div>
          <i className="icon-approved fa fa-check" />
        </div>
      );
    }

    if (value === false) {
      return (
        <div>
          <i className="icon-rejected fa fa-times" />
        </div>
      );
    }

    return <div />;
  }
}

class Targets extends PureComponent {
  constructor(props) {
    super(props);
    this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
    this.onGridSizeChanged = this.onGridSizeChanged.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.onSort = this.onSort.bind(this);
    this.columnDefs = config.tables.getIn(['project', 'targetBuyer', 'columns']).map(col => {
      const field = col.get('field');

      if (field === 'approved') {
        return col.merge({
          headerComponentFramework: AgHeader,
          cellRendererFramework: ApprovedFormat,
          sortable: true,
        });
      }

      return col.merge({
        headerComponentFramework: AgHeader,
        sortable: true,
      });
    });
  }

  onRowDoubleClicked(params) {
    if (!this.props.canEditData) {
      return;
    }

    this.props.onDoubleClick({
      row: Map({
        id: params.data.id,
      }),
      type: 'targets',
    });
  }

  onGridSizeChanged() {
    if (this.api) {
      this.api.sizeColumnsToFit();
    }
  }

  onGridReady(params) {
    this.api = params.api;
  }

  componentDidUpdate() {
    if (this.api) {
      this.api.sizeColumnsToFit();
    }
  }

  onSort(e) {
    if (!this.props.canEditData) {
      return;
    }

    if (e && e.length) {
      this.props.onSort(e, {
        type: 'targets',
        direction: e[0].sort === 'asc' ? 'up' : 'down',
        field: e[0].colId,
      });
    }
  }

  render() {
    const { targets, common } = this.props;
    const rowData = targets.map((target, i) => target.set('i', i + 1));

    return (
      <div className="table-target">
        <Table
          columnDefs={this.columnDefs}
          isFetching={common.get('targetLoading')}
          onGridReady={this.onGridReady}
          onGridSizeChanged={this.onGridSizeChanged}
          onRowDoubleClicked={this.onRowDoubleClicked}
          onSortModelChanged={this.onSort}
          rowData={rowData}
          enableSorting
        />
      </div>
    );
  }
}

Targets.propTypes = {
  canEditData: PropTypes.bool.isRequired,
};

export default Targets;
