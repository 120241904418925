import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import outsideClick from 'react-click-outside';
import moment from 'moment';

import Grid from '../helpers/AgGridTable';
import config from '../../config';
import RowIndex from './RowIndex';
import CompanyCell from './CompanyCell';
import PriorityRadio from './PriorityRadio';
import Sales from './Sales';
import Checkbox from '../helpers/Checkbox';
import Products from './Products';
import Markets from './Markets';
import Facilities from './Facilities';
import CustomFields from './CustomFields';
import Description from './Description';
import MultilineTextEditor from './MultilineTextEditor';
import BuyerComment from './BuyerComment';

class OnlineBodySection extends PureComponent {
  state = {
    agree: false,
    requiredRows: [],
    firstName: this.props.onlineApproval.get('completedByFirstName'),
    lastName: this.props.onlineApproval.get('completedByLastName'),
  };

  columns = config.tables.getIn(['approval', 'online']).map(col => {
    const searchParams = new URLSearchParams(window.location.search);
    const optionalFields = [];
    const isCompletedList = Boolean(this.props.onlineApproval.get('dateReceived'));

    // eslint-disable-next-line no-restricted-syntax
    for (const value of searchParams.keys()) {
      optionalFields.push(value);
    }

    if (optionalFields.includes(col.get('field'))) {
      // eslint-disable-next-line no-param-reassign
      col = col.set('hide', false);
    }

    switch (col.get('field')) {
      case 'index':
        return col.merge({
          cellRendererFramework: RowIndex,
        });

      case 'companyLegalName':
        return col.merge({
          cellRendererFramework: CompanyCell,
        });

      case 'companyDescription':
        return col.merge({
          cellRendererFramework: Description,
        });

      case 'products':
        return col.merge({
          cellRendererFramework: Products,
        });

      case 'markets':
        return col.merge({
          cellRendererFramework: Markets,
        });

      case 'facilities':
        return col.merge({
          cellRendererFramework: Facilities,
        });

      case 'custom_fields':
        return col.merge({
          cellRendererFramework: CustomFields,
        });

      case 'companyRevenue':
        return col.merge({
          cellRendererFramework: Sales,
        });

      case 'priority':
        return col.merge({
          suppressFlash: true,
          cellRendererFramework: PriorityRadio,
          cellEditorFramework: PriorityRadio,
          cellRendererParams: {
            onChange: (event, data) => this.handlePriorityChange(event, data),
            isDisabled: isCompletedList,
          },
        });

      case 'bsnClient':
        return col.merge({
          hide: false,
          editable: true,
          cellRendererFramework: BuyerComment,
          cellEditorFramework: MultilineTextEditor,
        });

      default:
        return col;
    }
  });

  handleFirstDataRendered = () => {
    this.getRequiredRowsList();
  };

  getRequiredRowsList = () => {
    const approvals = this.props.onlineApproval.get('approvalListData').toJS();
    const requiredRows = [];
    const approvalsList = approvals.map(approval => approval.targetBuyerPriority);
    const emptyPriority = '';
    let indexes = approvalsList.indexOf(emptyPriority);

    while (indexes !== -1) {
      requiredRows.push(indexes + 1);
      indexes = approvalsList.indexOf(emptyPriority, indexes + 1);
    }

    this.setState({ requiredRows });
  };

  updateTable = data => {
    const { onlineApproval, onUpdate } = this.props;
    const approvals = onlineApproval
      .get('approvalListData')
      .toJS()
      .map(priority => {
        if (priority.id === data.id) {
          return data;
        }

        return priority;
      });

    onUpdate(approvals);
  };

  handlePriorityChange = async (event, data) => {
    await this.updateTable(data);

    const rowNode = this.api.getRowNode(data.id);

    this.api.refreshCells({
      columns: ['bsnClient', 'priority'],
      rowNodes: [rowNode],
      force: true,
    });

    this.getRequiredRowsList();
  };

  handleGridReady = params => {
    this.api = params.api;
    this.api.sizeColumnsToFit();
  };

  handleGridSizeChanged = () => {
    if (this.api) {
      this.api.sizeColumnsToFit();
    }
  };

  handleAgreeChange = event => {
    this.setState({
      agree: event.target.checked,
    });
  };

  handleFirstNameChange = event => {
    this.setState({
      firstName: event.target.value.trim(),
    });
  };

  handleLastNameChange = event => {
    this.setState({
      lastName: event.target.value.trim(),
    });
  };

  handleSubmit = () => {
    this.api.stopEditing();

    const { onlineList, firstName, lastName } = this.prepareSaveOnlineApprovalsParams();

    this.props.onSubmit(onlineList, { firstName, lastName });
  };

  handleSaveProgress = () => {
    this.api.stopEditing();

    const { onlineList, firstName, lastName } = this.prepareSaveOnlineApprovalsParams();

    this.props.onSaveProgress(onlineList, { firstName, lastName });
  };

  /** Prepare params for save online approvals and save progress online approvals requests. */
  prepareSaveOnlineApprovalsParams() {
    const { firstName, lastName } = this.state;
    const onlineList = this.props.onlineApproval
      .get('approvalListData')
      .map(approval => ({
        id: approval.get('id'),
        targetBuyerId: approval.get('targetBuyerId'),
        bsnClient: approval.get('bsnClient'),
        targetBuyerPriority: approval.get('targetBuyerPriority'),
      }))
      .toJS();

    return {
      firstName,
      lastName,
      onlineList,
    };
  }

  handleCellClicked = cell => {
    const {
      column: { colId },
      node: { rowIndex },
    } = cell;

    this.api.startEditingCell({ rowIndex, colKey: colId });
  };

  handleCellEditingStopped = ({ data }) => {
    this.updateTable(data);
    this.api.refreshCells({
      columns: ['bsnClient', 'priority'],
      force: true,
      suppressFlash: true,
    });
  };

  handleClickOutside = () => {
    if (this.api) {
      this.api.stopEditing();
    }
  };

  getRowNodeId(data) {
    return data.id;
  }

  render() {
    const { onlineApproval, saving } = this.props;
    const { agree, requiredRows, firstName, lastName } = this.state;
    const clientName = onlineApproval.get('buyerLegalName');
    const requiredRowsMessage = requiredRows.toString();
    const errorClassRequiredRows = classNames('col-sm-12 mb10', {
      hidden: requiredRows.length === 0,
    });
    const dateReceived = onlineApproval.get('dateReceived');
    const completedByFirstName = onlineApproval.get('completedByFirstName');
    const completedByLastName = onlineApproval.get('completedByLastName');
    const isCompletedList = Boolean(dateReceived);
    const isFulfilledName = firstName && lastName;

    return (
      <>
        <div className="mt20 table-generate-approval-list table-online">
          <div className="col-sm-12 full-height">
            <Grid
              columnDefs={this.columns}
              getRowNodeId={this.getRowNodeId}
              headerHeight={35}
              onCellClicked={this.handleCellClicked}
              onCellEditingStopped={this.handleCellEditingStopped}
              onFirstDataRendered={this.handleFirstDataRendered}
              onGridReady={this.handleGridReady}
              onGridSizeChanged={this.handleGridSizeChanged}
              rowData={onlineApproval.get('approvalListData')}
              rowHeight={130}
            />
          </div>
        </div>
        <div className="row mt20">
          <div className={errorClassRequiredRows}>
            <span className="text-danger pt10 pb10">Need to select a priority for {requiredRowsMessage} row(s)</span>
          </div>
          {!agree && (
            <div className="col-sm-12 mb10">
              <span className="text-danger pt10 pb10">
                Need to click Approval List acknowledgment in lower left corner
              </span>
            </div>
          )}
          {!isFulfilledName && (
            <div className="col-sm-12 mb10">
              <span className="text-danger pt10 pb10">
                Need to input First and Last name of Approval List submitter at bottom of page
              </span>
            </div>
          )}
          {isCompletedList ? (
            <div className="col-sm-6 ml20 text-danger">
              This Approval List has been completed and submitted by {completedByFirstName} {completedByLastName} on{' '}
              {moment(dateReceived).format('MM/DD/YYYY')}
            </div>
          ) : (
            <>
              <div className="col-sm-5">
                <Checkbox
                  capitalizeLabel={false}
                  id="agree"
                  label={`Pursuant to the engagement agreement, the prioritized companies (except for Excluded targets)
                  on the preceding Approval List shall be deemed Approved Target Companies to be approached
                  by Harvey & Company LLC on behalf of ${clientName}. This list constitutes Harvey Confidential Information under the agreement.`}
                  name="agree"
                  onChange={this.handleAgreeChange}
                />
              </div>
              <div className="col-sm-5">
                <label className="mr20">
                  <span>First Name</span>
                  <input className="form-control" onChange={this.handleFirstNameChange} value={firstName} />
                </label>
                <label>
                  <span>Last Name</span>
                  <input className="form-control" onChange={this.handleLastNameChange} value={lastName} />
                </label>
              </div>
              <div className="col-sm-2 text-right pr20">
                <button className="btn btn-primary btn-xs mr8" disabled={saving} onClick={this.handleSaveProgress}>
                  Save Progress
                </button>
                <button
                  className="btn btn-primary btn-xs"
                  disabled={!agree || requiredRows.length > 0 || saving || firstName === '' || lastName === ''}
                  onClick={this.handleSubmit}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

OnlineBodySection.propTypes = {
  onlineApproval: PropTypes.instanceOf(Map).isRequired,
  onSaveProgress: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default outsideClick(OnlineBodySection);
