import React from 'react';
import Helmet from 'react-helmet';

import Popup from './PopupWrapper';
import ApiErrorDisplay from './ApiErrorWrapper';
import UnloadWrapper from './UnloadWrapper';
import RuntimeErrorDisplay from './RuntimeErrorWrapper';
import ApprovalListOnlineRouting from '../routing/ApprovalListOnlineRouting';

/**
 * Wrapper Generate Online Approval Lists container component.
 */
const WrapperOnlineApprovalListsContainer = () => (
  <div className="full-height">
    <Helmet title="Generate Online Approval Lists" />

    <UnloadWrapper>
      <Popup>
        <ApiErrorDisplay>
          <RuntimeErrorDisplay global onDevOnly>
            <ApprovalListOnlineRouting />
          </RuntimeErrorDisplay>
        </ApiErrorDisplay>
      </Popup>
    </UnloadWrapper>
  </div>
);

export default WrapperOnlineApprovalListsContainer;
