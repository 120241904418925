import PropTypes from 'prop-types';
import React from 'react';
import Immutable from 'immutable';

import SearchInput from './SearchInput';
import SearchDropDown from './SearchDropDown';

/**
 * Stateless component for top search result navigation.
 *
 * @param props {Object}.
 * @returns {React.Component}
 * @class
 */
const SearchPanel = props => (
  <div className="search">
    <div className="row">
      <div className="col-md-6 col-sm-6">
        <SearchInput
          hasAccess={props.hasAccess}
          onOnlyOwnerChange={props.onOnlyOwnerChange}
          onSearch={props.onSearch}
          onSearchBy={props.onSearchBy}
          onSearchingTermChange={props.onSearchingTermChange}
          searchBy={props.searchBy}
          searchingTerm={props.searchingTerm}
          userName={props.userName}
        />
      </div>
      <div className="col-md-6 col-sm-6">
        <SearchDropDown dropdownItems={props.dropdownItems} hasAccess={props.hasAccess} onClick={props.onClick} />
      </div>
    </div>
  </div>
);

SearchPanel.propTypes = {
  dropdownItems: PropTypes.instanceOf(Immutable.List).isRequired,
  onClick: PropTypes.func.isRequired,
  onOnlyOwnerChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSearchingTermChange: PropTypes.func.isRequired,
  searchingTerm: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export default SearchPanel;
