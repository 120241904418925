import { CALL_API } from '../middleware/api';

export const FETCHING_MAILING_HEADER = 'FETCHING_MAILING_HEADER';
export const FETCHING_MAILING_HEADER_SUCCESS = 'FETCHING_MAILING_HEADER_SUCCESS';
export const FETCHING_MAILING_HEADER_FAILURE = 'FETCHING_MAILING_HEADER_FAILURE';
export const FETCHING_MAILING_TARGETS = 'FETCHING_MAILING_TARGETS';
export const FETCHING_MAILING_TARGETS_SUCCESS = 'FETCHING_MAILING_TARGETS_SUCCESS';
export const FETCHING_MAILING_TARGETS_FAILURE = 'FETCHING_MAILING_TARGETS_FAILURE';
export const UPDATE_MAILING_INFO = 'UPDATE_MAILING_INFO';
export const UPDATE_CELL_ERROR = 'UPDATE_CELL_ERROR';
export const CLEAR_UPDATE_CELL_ERROR = 'CLEAR_UPDATE_CELL_ERROR';
export const CANCEL_MAILING_START = 'CANCEL_MAILING_START';
export const CANCEL_MAILING_SUCCESS = 'CANCEL_MAILING_SUCCESS';
export const CANCEL_MAILING_FAILURE = 'CANCEL_MAILING_FAILURE';

export function getMailingHeaderInfo({ batchId, afterSuccess = () => {} }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/batch/${batchId}`,
      startType: FETCHING_MAILING_HEADER,
      successType: FETCHING_MAILING_HEADER_SUCCESS,
      errorType: FETCHING_MAILING_HEADER_FAILURE,
      afterSuccess,
    },
  };
}

export function getMailingTargets({ batchId, page = 1, sortBy = null, afterSuccess = () => {} }) {
  const query = {
    page,
    limit: 100,
  };

  if (sortBy) {
    query.sort = sortBy;
  }

  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/events/batch_targets/${batchId}`,
      query,
      startType: FETCHING_MAILING_TARGETS,
      successType: FETCHING_MAILING_TARGETS_SUCCESS,
      errorType: FETCHING_MAILING_TARGETS_FAILURE,
      afterSuccess,
    },
  };
}

export function updateMailingInfo({ filterName, filterData }) {
  return {
    type: UPDATE_MAILING_INFO,
    filterName,
    filterData,
  };
}

export function updateCompany(companyId, query, afterSuccess) {
  return {
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/companies/${companyId}`,
      query,
      skipGlobalErrorHandler: true,
      afterSuccess,
      errorType: UPDATE_CELL_ERROR,
    },
  };
}

export function updatePeople(peopleId, query, afterSuccess) {
  return {
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/people/${peopleId}`,
      query,
      skipGlobalErrorHandler: true,
      afterSuccess,
      errorType: UPDATE_CELL_ERROR,
    },
  };
}

export function updateContactTitle(peopleId, entityContactId, query, afterSuccess) {
  return {
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/people/${peopleId}/executive/positions/${entityContactId}`,
      query,
      skipGlobalErrorHandler: true,
      afterSuccess,
      errorType: UPDATE_CELL_ERROR,
    },
  };
}

export function updateEmail(peopleId, contactEmailId, query, afterSuccess) {
  return {
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/people/${peopleId}/emails/${contactEmailId}`,
      query,
      skipGlobalErrorHandler: true,
      afterSuccess,
      errorType: UPDATE_CELL_ERROR,
    },
  };
}

export function clearUpdateCellError() {
  return {
    type: CLEAR_UPDATE_CELL_ERROR,
  };
}

export function updateMarkCompleted({ batchId, body, callback: { beforeStart, afterSuccess, afterError } }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/batch/${batchId}`,
      body,
      beforeStart,
      afterSuccess,
      afterError,
    },
  };
}

export function updateSetNextActions({ batchId, body, callback: { beforeStart, afterSuccess, afterError } }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/batch/${batchId}/set_next_actions`,
      body,
      beforeStart,
      afterSuccess,
      afterError,
    },
  };
}

export function exportExcelMergeSource({ batchId }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/events/excel_merge_source/export?batch_id=${batchId}`,
    },
  };
}

export function updateMailingStatuses({ batchId, body, callback }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/events/batch_targets/${batchId}`,
      body,
      afterSuccess: callback,
    },
  };
}

export function cancelMailing({ batchId, afterSuccess }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/batch/${batchId}/cancel_mailing`,
      startType: CANCEL_MAILING_START,
      successType: CANCEL_MAILING_SUCCESS,
      errorType: CANCEL_MAILING_FAILURE,
      afterSuccess,
    },
  };
}
