import { fromJS } from 'immutable';

import config from '../config';

/**
 * Get default value if the inputed value is null or undefined.
 *
 * @param val {Any} Inputed value.
 * @param defaultVal {Any} Default value.
 * @returns {Any}
 */
function getSafeValue(val, defaultVal) {
  return val === null || val === undefined ? defaultVal : val;
}

/**
 * Return default number if the inputed value is null or undefined or empty string
 * Parse string to number before returning.
 *
 * @param val {Any} inputed value
 * @param defaultNumber {Number=0} default value
 * @returns {Any}
 */
function defaultNumber(val, defaultNumber = 0) {
  const ret = val === null || val === undefined || val === '' ? defaultNumber : parseInt(val, 10);

  return ret || defaultNumber;
}

/**
 * Return default float value if the inputed value is null or undefined or empty string.
 * Parse string to float before returning.
 *
 * @param value {Any} Inputed value.
 * @param defaultFloat {Number=0} Default value.
 * @returns {number} Parse string to float.
 */
function defaultFloat(value, defaultFloat = 0.0) {
  const ret = value === null || value === undefined || value === '' ? defaultFloat : parseFloat(value);

  return ret || defaultFloat;
}

/**
 * Uppercase first character in string.
 *
 * @param word {string} String to uppercase.
 * @returns {string} Word in upper case.
 */
function upperCaseWord(word) {
  if (word.length === 0) return '';

  return word[0].toUpperCase() + word.slice(1);
}

/**
 * Uppercase first character in every word in string (splits only by space).
 *
 * @param string {string} String to uppercase.
 * @returns {string} Uppercase string.
 */
function upperCaseWords(string) {
  return (string || '')
    .split(' ')
    .map(upperCaseWord)
    .join(' ');
}

/**
 * Read roles from configuration and return an array.
 *
 * @returns {Array} Immutable list of roles.
 */
function getUserRoleArray() {
  const roles = [];

  for (const p in config.userRoles) {
    if (config.userRoles.hasOwnProperty(p)) {
      roles.push({
        name: config.userRoles[p],
        value: p,
      });
    }
  }

  return fromJS(roles);
}

/**
 * Validate email address.
 *
 * @param email {String} String which needs to validate.
 * @returns {boolean} Is valid an email.
 */
function isValidEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
}

/**
 * Join string with connector in between.
 *
 * @param connector {string}.
 * @param rest {Any}.
 * @returns {string} Concatenated string.
 */
function joinString(connector, ...rest) {
  let ret = '';
  let prefix = '';

  for (let i = 0, len = rest.length; i < len; i++) {
    if (rest[i]) {
      ret += prefix + rest[i];
      prefix = connector;
    }
  }

  return ret;
}

function trimWhiteSpaceIf(reg, action) {
  if (action.value && reg.test(action.name)) {
    action.value = action.value.trim();
  }

  return action;
}

/**
 * Convert money to number.
 * Ex.: 10,000 to 10000.
 *
 * @param {string} currency Value for convert.
 * @returns {number} Converted value to number(Ex. 10000).
 */
function convertMoneyToNumber(currency) {
  return Number(currency.replace(/[^0-9.-]+/g, ''));
}

export {
  trimWhiteSpaceIf,
  upperCaseWord,
  upperCaseWords,
  getUserRoleArray,
  isValidEmail,
  getSafeValue,
  joinString,
  defaultNumber,
  defaultFloat,
  convertMoneyToNumber,
};
