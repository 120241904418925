export const schema = {
  Name: {
    prop: 'name',
    type: String,
  },
  Website: {
    prop: 'website',
    type: String,
  },
  Phone: {
    prop: 'phone',
    type: String,
  },
};
