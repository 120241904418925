import { fromJS, List } from 'immutable';

import config from '../config';
import {
  LOADING_BUYER_POPUP_SUGGESTION,
  LOADED_BUYER_POPUP_SUGGESTION_FAILURE,
  LOADED_BUYER_POPUP_SUGGESTION_SUCCESS,
  UPDATE_BUYER_POPUP_FIELD,
  SELECT_BUYER_POPUP_SUGGESTION,
  CLOSE_BUYER_POPUP_SUGGESTION,
  RESET_BUYER_POPUP_FORM,
} from '../actions/popup/buyerPopup';

const defaultState = fromJS({
  buyer: {
    id: 0,
    value: '',
    origin: '',
    mode: config.VIEW_MODE,
  },
  project: {
    id: 0,
    value: '',
    origin: '',
    mode: config.VIEW_MODE,
  },
  approval: {
    id: 0,
    value: '',
    origin: '',
    mode: config.VIEW_MODE,
  },
  currentStatus: '',
  highStatus: '',
  activeBuyer: false,
  overrideCurrent: false,
  overrideHigh: false,
  overrideProject: false,
  overrideApproval: false,
  buyerSuggestions: [],
  projectSuggestions: [],
  approvalSuggestions: [],
  errors: [],
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_BUYER_POPUP_FIELD:
      return validateData(updateField(state, action));

    case LOADING_BUYER_POPUP_SUGGESTION:
      return changeMode(state, action, config.LOADING_MODE);

    case LOADED_BUYER_POPUP_SUGGESTION_FAILURE:
      return changeMode(state, action, config.VIEW_MODE);

    case LOADED_BUYER_POPUP_SUGGESTION_SUCCESS:
      return mapSuggestion(state, action);

    case SELECT_BUYER_POPUP_SUGGESTION:
      return validateData(selectSuggestion(state, action));

    case CLOSE_BUYER_POPUP_SUGGESTION:
      return validateData(closeSuggestion(state, action));

    case RESET_BUYER_POPUP_FORM:
      return defaultState;

    default:
      return state;
  }
}

/**
 * Validate form.
 *
 * @param state {Immutable.Map} State.
 * @returns {Immutable.Map}
 */
function validateData(state) {
  const errors = [];

  if (state.getIn(['buyer', 'id']) <= 0) {
    errors.push('You must enter a buyer');
  }

  if (state.get('currentStatus', '').length === 0) {
    errors.push('You must enter a current status');
  }

  if (state.getIn(['project', 'id']) <= 0) {
    errors.push('You must enter a project');
  }

  return state.set('errors', List(errors));
}

/**
 * Close suggestion.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action object.
 * @returns {Immutable.Map}
 */
function closeSuggestion(state, action) {
  const { name } = action;

  return state.mergeDeep({
    [name]: {
      value: state.getIn([name, 'origin']),
      mode: config.VIEW_MODE,
    },
  });
}

/**
 * Update field in state.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action object.
 * @returns {Immutable.Map}
 */
function updateField(state, action) {
  const { name, value } = action;
  const suggestElements = ['buyer', 'project', 'approval'];

  if (name === 'currentStatus') {
    state = state.merge({ highStatus: value });
  }

  if (suggestElements.includes(name)) {
    return state.mergeDeep({
      [name]: {
        value,
      },
    });
  }

  return state.merge({
    [name]: value,
  });
}

/**
 * Change suggestion mode.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action object.
 * @param mode {String} Suggestion's mode.
 * @returns {Immutable.Map}
 */
function changeMode(state, action, mode) {
  const { name } = action;

  return state.mergeDeep({
    [name]: {
      mode,
    },
  });
}

/**
 * Map suggestion list from api reponse.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action object.
 * @returns {Immutable.Map}
 */
function mapSuggestion(state, action) {
  const { name, response } = action;

  if (name === 'buyer') {
    return mapBuyerSuggestion(state, response);
  }

  if (name === 'project') {
    return mapProjectSuggestion(state, response);
  }

  if (name === 'approval') {
    return mapApprovalSuggestion(state, response);
  }

  return state;
}

/**
 * Map approval suggestion list from api reponse.
 *
 * @param state {Immutable.Map} State.
 * @param response {Object} Response object.
 * @returns {Immutable.Map}
 */
function mapApprovalSuggestion(state, response) {
  if (response.status === 'success') {
    // mergeDeep won't work with empty list, so we have to set it to empty list first
    const ret = state.set('buyerSuggestions', List());

    return ret.mergeDeep({
      approval: {
        mode: config.SUGGEST_MODE,
      },
      approvalSuggestions: fromJS(
        response.data.map(s => ({
          id: s.id,
          name: s.text,
        })),
      ),
    });
  }

  return state;
}

/**
 * Map buyer suggestion list from api reponse.
 *
 * @param state {Immutable.Map} State.
 * @param response {Object} Response object.
 * @returns {Immutable.Map}
 */
function mapBuyerSuggestion(state, response) {
  if (response.status === 'success') {
    // mergeDeep won't work with empty list, so we have to set it to empty list first
    const ret = state.set('buyerSuggestions', List());

    return ret.mergeDeep({
      buyer: {
        mode: config.SUGGEST_MODE,
      },
      buyerSuggestions: fromJS(
        response.data.map(s => ({
          id: s.id,
          name: s.text,
        })),
      ),
    });
  }

  return state;
}

/**
 * Map project suggestion list from api reponse.
 *
 * @param state {Immutable.Map} State
 *@param response {Object} response object.
 * @returns {Immutable.Map}
 */
function mapProjectSuggestion(state, response) {
  if (response.status === 'success') {
    // mergeDeep won't work with empty list, so we have to set it to empty list first
    const ret = state.set('projectSuggestions', List());

    return ret.mergeDeep({
      project: {
        mode: config.SUGGEST_MODE,
      },
      projectSuggestions: fromJS(
        response.data.map(s => ({
          id: s.id,
          name: s.category,
        })),
      ),
    });
  }

  return state;
}

/**
 * Select a suggestion.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action object.
 * @returns {Immutable.Map}
 */
function selectSuggestion(state, action) {
  const { name, value, id } = action;

  return state.mergeDeep({
    [name]: {
      id,
      value,
      origin: value,
      mode: config.VIEW_MODE,
    },
  });
}
