import googleMap from '@google/maps';
import config from '../config';

const googleMapsClient = googleMap.createClient({
  key: config.GOOGLE_API_KEY,
});

const LANG = 'en';

const ADDRESSES_TYPES = ['street_address', 'room', 'street_number', 'premise', 'subpremise'];

const isAddress = types => types.some(type => ADDRESSES_TYPES.some(addressType => addressType === type));

export function validateAddress(address) {
  return new Promise((resolve, reject) =>
    googleMapsClient.geocode({ address, language: LANG }, (error, response) => {
      if (error) {
        return reject(error);
      }

      const { status, results } = response.json;

      if (status === 'OK') {
        if (isAddress(results[0].types)) {
          return resolve(results[0].formatted_address);
        }

        return reject({
          error: 'Result is not a street address',
        });
      }

      return reject({
        error: 'Status is not OK',
      });
    }),
  );
}
