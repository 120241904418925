import { CALL_API } from '../middleware/api';

export const FETCH_TARGET_STATUSES_SUCCESS = Symbol('FETCH_TARGET_STATUSES_SUCCESS');
export const FETCH_BUYER_STATUSES_SUCCESS = Symbol('FETCH_BUYER_STATUSES_SUCCESS');
export const FETCH_EXECUTIVE_STATUSES_SUCCESS = Symbol('FETCH_EXECUTIVE_STATUSES_SUCCESS');

/**
 * Fetch statuses for target company.
 *
 * @param {Function} afterSuccess Callback function.
 */
export function fetchTargetStatuses(afterSuccess) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/targets/statuses',
      successType: FETCH_TARGET_STATUSES_SUCCESS,
      afterSuccess,
    },
  };
}

/**
 * Fetch statuses for buyer company.
 *
 * @param {*} afterSuccess
 */
export function fetchBuyerStatuses(afterSuccess) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/buyers/statuses',
      successType: FETCH_BUYER_STATUSES_SUCCESS,
      afterSuccess,
    },
  };
}

/**
 * Fetch statuses for executives.
 *
 * @param {*} afterSuccess Callback after successful completion of the request.
 */
export function fetchExecutivesStatuses(afterSuccess) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/executives/statuses',
      successType: FETCH_EXECUTIVE_STATUSES_SUCCESS,
      afterSuccess,
    },
  };
}
