import React, { PureComponent } from 'react';

import FormGroup from '../../../helpers/FormGroup';
import Textarea from '../../../helpers/Textarea';
import Select from '../../../helpers/Select';
import Input from '../../../helpers/Input';
import FundCompanies from './FundCompanies';

export default class Fund extends PureComponent {
  render() {
    const {
      canEditData,
      fund: root,
      isResearcherUser,
      onChange,
      onGetNext,
      onRowDoubleClick,
      onSort,
      onCellClick,
      onCellValueChange,
      onRef,
    } = this.props;

    return (
      <div className="tab-pane active tab-target flex-through" id="buy-slide-6">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 flex-through">
          <form className="row" onChange={onChange}>
            <div className="col-xs-12 col-sm-3 col-md-2">
              <FormGroup>
                <Input
                  className="input-sm"
                  disabled={!canEditData || isResearcherUser}
                  name="currentFund"
                  placeholder="Current Fund"
                  root={root}
                  deep
                />
              </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-3 col-md-1">
              <div className="form-group">
                <Input
                  className="input-sm"
                  disabled={!canEditData || isResearcherUser}
                  name="fundSize"
                  placeholder="Size"
                  root={root}
                  deep
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-3 col-md-1">
              <div className="form-group">
                <Select
                  disabled={!canEditData || isResearcherUser}
                  label=""
                  name="fundYearRaised"
                  onChange={onChange}
                  options={root.get('years')}
                  root={root}
                  deep
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-3 col-md-8">
              <div className="form-group">
                <Textarea
                  disabled={!canEditData || isResearcherUser}
                  label=""
                  name="fundNotes"
                  placeholder="Notes"
                  root={root}
                  deep
                />
              </div>
            </div>
          </form>
          <div className="row flex-through">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 flex-through">
              <ul className="nav nav-tabs left-nav block-highlight mb20 pt0">
                <li className="active">
                  <a>Current</a>
                </li>
              </ul>
              <div className="tab-content row flex-through">
                <div className="tab-pane active tab-target flex-through" id="project-slide-1">
                  <div className="p0 col-xs-12 col-sm-12 col-md-12 col-lg-12 flex-through">
                    <div className="table-responsive flex-through">
                      <FundCompanies
                        funds={root.get('funds')}
                        loading={root.get('loading')}
                        onCellClick={onCellClick}
                        onCellValueChange={onCellValueChange}
                        onGetNext={onGetNext}
                        onRef={onRef}
                        onRowDoubleClick={onRowDoubleClick}
                        onSort={onSort}
                        page={root.get('page')}
                        totalPages={root.get('totalPages')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
