import { fromJS } from 'immutable';
import * as ActionType from '../actions/auth';

const DEFAULT_ERROR_MESSAGE = 'Unknown Error';

const defaultState = fromJS({
  isFetching: false,
  error: '',
  isAuthed: false,
  isTwoFa: false,
  user: null,
  google2FaStatus: null,
  google2FaSecret: null,
});

export default function auth(state = defaultState, action) {
  switch (action.type) {
    case ActionType.UNAUTH_USER:
      return state.merge({
        isAuthed: false,
      });

    case ActionType.VALIDATE_FAILURE:
      return state.merge({
        error: action.validateError,
      });

    case ActionType.FETCHING_USER_BY_ACTIVITY_DETECTOR:

    // fallthrough
    case ActionType.FETCHING_USER:
      return state.merge({
        error: '',
        isFetching: true,
      });

    case ActionType.FETCHING_USER_FAILURE:
      return state.merge({
        isFetching: false,
        error: getErrorMessage(action),
      });

    case ActionType.FETCHING_USER_BY_ACTIVITY_DETECTOR_SUCCESS:

    // fallthrough
    case ActionType.FETCHING_USER_SUCCESS:
      return state.merge({
        isFetching: false,
        isAuthed: true,
        isTwoFa: action.response.isTwoFa,
        error: '',
      });

    case ActionType.RESET_GOOGLE_2FA_STATUS:
      return state.merge({
        google2FaStatus: null,
        google2FaSecret: null,
        error: '',
      });

    case ActionType.FETCHING_GOOGLE_2FA_STATUS:
      return state.merge({
        isFetching: true,
      });

    case ActionType.FETCHING_GOOGLE_2FA_STATUS_FAILURE:
      return state.merge({
        isFetching: false,
        google2FaStatus: null,
        error: getErrorMessage(action),
      });

    case ActionType.FETCHING_GOOGLE_2FA_STATUS_SUCCESS:
      return state.merge({
        isFetching: false,
        google2FaStatus: action.response.data,
        error: '',
      });

    case ActionType.FETCHING_GOOGLE_2FA_SECRET:
      return state.merge({
        isFetching: true,
      });

    case ActionType.FETCHING_GOOGLE_2FA_SECRET_FAILURE:
      return state.merge({
        isFetching: false,
        google2FaSecret: null,
        error: getErrorMessage(action),
      });

    case ActionType.FETCHING_GOOGLE_2FA_SECRET_SUCCESS:
      return state.merge({
        isFetching: false,
        google2FaSecret: action.response.data,
        error: '',
      });

    case ActionType.SEND_GOOGLE_2FA_AUTH:

    // fallthrough
    case ActionType.CHECK_GOOGLE_2FA_SECRET:

    // fallthrough
    case ActionType.SAVE_GOOGLE_2FA_SECRET:
      return state.merge({
        isFetching: true,
      });

    case ActionType.SEND_GOOGLE_2FA_AUTH_FAILURE:

    // fallthrough
    case ActionType.CHECK_GOOGLE_2FA_SECRET_FAILURE:

    // fallthrough
    case ActionType.SAVE_GOOGLE_2FA_SECRET_FAILURE:
      return state.merge({
        isFetching: false,
        error: getErrorMessage(action),
      });

    case ActionType.SEND_GOOGLE_2FA_AUTH_SUCCESS:

    // fallthrough
    case ActionType.CHECK_GOOGLE_2FA_SECRET_SUCCESS:

    // fallthrough
    case ActionType.SAVE_GOOGLE_2FA_SECRET_SUCCESS:
      return state.merge({
        isFetching: false,
        error: '',
      });

    case ActionType.AUTH_USER:
      return state.merge({
        isAuthed: true,
        user: action.response.data,
      });

    case ActionType.UPDATE_DEAL_NOTE_WIDTH:
      return state.setIn(['user', 'tdnwidth'], action.width);

    default:
      return state;
  }
}

function getErrorMessage(action) {
  if (!action) return DEFAULT_ERROR_MESSAGE;
  if (!action.response) return DEFAULT_ERROR_MESSAGE;
  if (!action.response.message) return DEFAULT_ERROR_MESSAGE;

  return action.response.message;
}
