import React from 'react';
import Immutable from 'immutable';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Input from '../helpers/Input';
import Select from '../helpers/Select';

const options = Immutable.fromJS(['sk', 'ty', 'ru'].map(v => ({ name: v, value: v })));

const NewBuyerProfilePopup = () => {
  const header = 'New Buyer Profile';
  const footer = (
    <button className="btn btn-primary btn-xs" data-dismiss="modal" type="button">
      Create
    </button>
  );

  return (
    <Popup footer={footer} header={header} id="newBuyProf">
      <div className="row media-450">
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="f-primary-harvCo"
            label="Primary HarvCo Contact"
            name="primaryContact"
            placeholder="Primary HarvCo Contact"
            autoFocus
            required
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-xs-6">
          <Select
            id="f-buyer-type2"
            label="Buyer Type"
            name="buyerType"
            options={options}
            selectClassName="input-sm"
            showLabel
          />
        </FormGroup>
      </div>

      <div className="row media-450">
        <FormGroup className="col-lg-6 col-xs-6">
          <Input id="k-name2" label="Name" name="name" placeholder="Name" required showLabel />
        </FormGroup>
        <FormGroup className="col-lg-6 col-xs-6">
          <Input className="input-sm" id="k-abbr2" label="Abbr." name="abbr" placeholder="Abbr" required showLabel />
        </FormGroup>
      </div>
      <div className="row media-450">
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="k-dba2"
            label="DBA/Alt."
            name="dba"
            placeholder="DBA/Alt"
            required
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="k-web2"
            label={
              <a href="http://saritasa.com" rel="noopener noreferrer" target="_blank">
                Web
              </a>
            }
            name="web"
            placeholder="Web"
            required
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row media-450">
        <FormGroup className="col-lg-6 col-xs-6">
          <Input className="input-sm" id="k-phone2" label="Phone" name="phone" placeholder="Phone" required showLabel />
        </FormGroup>
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="k-address2"
            label="Address"
            name="address"
            placeholder="Address"
            required
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row media-450">
        <FormGroup className="col-lg-4 col-xs-4">
          <Input className="input-sm" id="k-city2" label="City" name="city" placeholder="City" required showLabel />
        </FormGroup>
        <FormGroup className="mt24 col-lg-4 col-xs-4">
          <Select id="some" label="Dont know" name="some" options={options} selectClassName="input-sm" />
        </FormGroup>
        <FormGroup className="input-group mt24 city-index2 col-lg-4 col-xs-4">
          <input className="form-control input-sm" id="index-city" placeholder="Index" type="text" required />
          <span className="input-group-addon">
            <i aria-hidden="true" className="fa fa-plus" />
            <i aria-hidden="true" className="fa fa-minus hide" />
          </span>
        </FormGroup>
      </div>
      <div className="row">
        <FormGroup className="hide f-country col-lg-12 col-sm-12">
          <Input
            className="input-sm"
            id="f-country2"
            label="Country"
            name="country"
            placeholder="Country"
            required
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row">
        <FormGroup className=" col-lg-6 col-xs-6">
          <Select id="k-type2" label="Type" name="type" options={options} selectClassName="input-sm" />
        </FormGroup>
      </div>
    </Popup>
  );
};

export default NewBuyerProfilePopup;
