import config from '../../config';

/**
 * Filter actions by user role.
 *
 * @param {string} userRole Role of logged user.
 * @returns {Array} Filtered actions.
 */
export function targetTableActions(userRole) {
  const contextActionList = config.browse.getIn(['contextActionList', 'targets']).toJS();

  return contextActionList.filter(action => action.access.some(role => role === userRole));
}
