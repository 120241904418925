import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { isResearcher } from 'src/utils/checkPermissions';
import UrlMaker from '../../../routing/UrlMaker';
import connectOptions, { mergeProps } from '../../../utils/connectOptions';
import {
  openContact,
  handleUpdateCompany,
  getFunds,
  startEditDetailEntity,
  saveInfo,
  closeValidationError,
  updateFundDate,
  updateFundSort,
} from '../../../actions/company/buyer/fund';
import { getChanged } from '../../../utils/ChangeSpy';

import Fund from '../../../components/Company/CompanyBuyer/Fund';

const FIELDS = {
  dateAcquired: 'date_acquired',
  legalName: 'legal_name',
  dsplIndustry: 'dspl_industry',
  currentStatus: 'current_status',
  highStatus: 'high_status',
};

class FundContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onSort = this.onSort.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onGetNext = this.onGetNext.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onErrorClose = this.onErrorClose.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.onCellValueChange = this.onCellValueChange.bind(this);
    this.onRef = this.onRef.bind(this);
    this.onRowDoubleClick = this.onRowDoubleClick.bind(this);
  }

  componentWillUnmount() {
    this.context.removeOnSaveCallback(this.onSubmit);
  }

  componentDidMount() {
    const { companyId, fund } = this.props;
    const sort = fund.get('sort');

    this.props.getFunds({ companyId, sort });
    this.context.addOnSaveCallback(this.onSubmit);
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  getChildContext() {
    return {
      onErrorClose: this.onErrorClose,
      inputErrors: this.props.inputErrors,
    };
  }

  onErrorClose(e, field) {
    this.props.closeValidationError({ field });
  }

  getCanEditData() {
    return !this.props.isDuplicateCompany;
  }

  onSubmit(event) {
    if (!this.getCanEditData()) {
      return;
    }

    event.preventDefault();
    if (this.props.fund.get('isValid')) {
      const body = getChanged(this.props.fund);
      const { companyId } = this.props.match.params;

      if (Object.keys(body).length) {
        this.props.saveInfo({ companyId, body });
      }
    }
  }

  onGetNext(page) {
    const { companyId, fund } = this.props;

    return this.props.getFunds({ companyId, sort: fund.get('sort'), page });
  }

  getSort(colId, sort) {
    return colId ? `${sort === 'asc' ? '' : '-'}${FIELDS[colId]}` : '';
  }

  onSort(data) {
    if (!this.getCanEditData()) {
      return;
    }

    if (data.length) {
      const { colId, sort } = data[0];
      const { companyId, updateFundSort, getFunds } = this.props;
      const sortExpression = this.getSort(colId, sort);

      updateFundSort(sortExpression);
      getFunds({ companyId, sort: sortExpression });
    }
  }

  onChange({ name, value }) {
    if (!this.getCanEditData()) {
      return;
    }

    this.props.handleUpdateCompany({ name, value });
  }

  onFormChange({ target: { name, type, value, checked } }) {
    if (type === 'checkbox') return this.onChange({ name, value: checked });

    return this.onChange({ name, value });
  }

  onCellClick(data) {
    if (!this.getCanEditData()) {
      return;
    }

    if (data.colDef.field === 'dateAcquired') {
      this.gridApi.startEditingCell({
        rowIndex: data.node.rowIndex,
        colKey: data.colDef.field,
      });
    } else {
      this.gridApi.stopEditing(true);
    }
  }

  onCellValueChange(event) {
    if (!this.getCanEditData()) {
      return;
    }

    const { data, node } = event;

    if (
      (event.newValue && event.oldValue && event.newValue.diff(event.oldValue)) ||
      event.newValue !== event.oldValue
    ) {
      this.props.updateFundDate({
        index: node.childIndex,
        companyId: data.entityId,
        value: data.dateAcquired,
      });
    }
  }

  onRef(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onRowDoubleClick({ data }) {
    if (!this.getCanEditData()) {
      return;
    }

    window.open(
      UrlMaker.create('/company/:companyId/target').mapParams({
        companyId: data.entityId,
      }),
    );
  }

  render() {
    const { fund } = this.props;
    const isResearcherUser = isResearcher(this.context.currentUser.getIn(['roles', 0, 'slug'], null));

    return (
      <Fund
        canEditData={this.getCanEditData()}
        fund={fund}
        isResearcherUser={isResearcherUser}
        onCellClick={this.onCellClick}
        onCellValueChange={this.onCellValueChange}
        onChange={this.onFormChange}
        onGetNext={this.onGetNext}
        onRef={this.onRef}
        onRowDoubleClick={this.onRowDoubleClick}
        onSort={this.onSort}
      />
    );
  }
}

FundContainer.contextTypes = {
  currentUser: PropTypes.instanceOf(Map),
  openPopup: PropTypes.func,
  closePopup: PropTypes.func,
  addOnSaveCallback: PropTypes.func.isRequired,
  removeOnSaveCallback: PropTypes.func.isRequired,
};

FundContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};

FundContainer.propTypes = {
  isDuplicateCompany: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    companyId: state.targetCompany.info.getIn(['info', 'id']),
    fund: state.targetCompany.buyer.fund,
    inputErrors: state.targetCompany.buyer.fund.get('inputErrors'),
    isDuplicateCompany: state.targetCompany.mergeInfo.get('isDuplicateCompany'),
  };
}

export { FundContainer };
export default connect(
  mapStateToProps,
  {
    openContact,
    handleUpdateCompany,
    startEditDetailEntity,
    saveInfo,
    closeValidationError,
    getFunds,
    updateFundDate,
    updateFundSort,
  },
  mergeProps,
  connectOptions,
)(FundContainer);
