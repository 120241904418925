import Immutable from 'immutable';
import moment from 'moment';
import * as ActionType from '../../actions/nextAction/main';
import { getSafeValue, joinString } from '../../utils/string';
import getErrorResponse from '../../utils/getErrorResponse';
import config from '../../config';

const defaultState = Immutable.fromJS({
  actions: [],
  common: {
    total: 0,
    nextPage: -1,
    requestId: '',
    loadingAction: false,
    loadingSetting: false,
    exporting: false,
    errors: [],
  },
  settings: {
    sorts: [],
    filters: [],
  },
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionType.FETCHING_USER_SETTING:
      return state.mergeDeep({
        common: { loadingSetting: true },
        settings: defaultState.get('settings'),
      });

    case ActionType.FETCH_USER_SETTING_SUCCESS:
      return state.mergeDeep({
        common: { loadingSetting: false },
        settings: Immutable.fromJS(action.response.config),
      });

    case ActionType.FETCHING_NEXT_ACTION:
      return fetching(state, action);

    case ActionType.REQUEST_NEXT_ACTION_API_FAILURE:
      return mapError(state, action);

    case ActionType.FETCH_NEXT_ACTION_SUCCESS:
      return mapActions(state, action);

    case ActionType.CHANGE_SORTING_FIELD:
      return state.mergeDeep({
        settings: { sorts: changeSorting(state, action) },
      });

    case ActionType.CHANGE_FILTERING_FIELD:
      return state.mergeDeep({
        settings: {
          filters: changeFiltering(state, action.field, action.checked),
        },
      });

    case ActionType.SELECT_ALL_NEXT_ACTION:
      return toggleActionState(state, null, true);

    case ActionType.DESELECT_ALL_NEXT_ACTION:
      return toggleActionState(state, null, false);

    case ActionType.SELECT_A_NEXT_ACTION:
      return toggleActionState(state, action.id, true);

    case ActionType.DESELECT_A_NEXT_ACTION:
      return toggleActionState(state, action.id, false);

    case ActionType.RESET_POPUP_INFO:
      return state.set('popup', defaultState.get('popup'));

    case ActionType.REQUEST_POPUP_API_FAILURE:
      return deleteFail(state, action);

    case ActionType.SETTING_BACKLOG:
      return toggleBLSpinner(state, action, true);

    case ActionType.SET_BACKLOG_FAILURE:
      return toggleBLSpinner(state, action, false);

    case ActionType.SET_BACKLOG_SUCCESS:
      return updateBLAction(state, action);

    case ActionType.REMOVE_NEXT_ACTION_ITEMS:
      return removeItems(state, action);

    case ActionType.UPDATE_SORT_ORDER:
      return updateOrder(state, action);

    case ActionType.UPDATE_EVENT_INFO:
      return updateEvent(state, action);

    default:
      return state;
  }
};

/**
 * Update event information.
 */
const updateEvent = (state, action) => {
  const { response } = action;
  const index = state.get('actions').findIndex(a => a.get('id') === response.data.id);

  if (index > -1) {
    return state.setIn(['actions', index], Immutable.Map(actionMapping(response.data)));
  }
};

/**
 * Update sorting order.
 *
 * @param state {Immutable.Map}.
 * @param action {Object}.
 * @returns {Immutable.Map}
 */
const updateOrder = (state, action) => {
  const { orderedList } = action;
  const sorts = state.getIn(['settings', 'sorts']);

  return state.setIn(
    ['settings', 'sorts'],
    sorts.map(s => s.set('order', orderedList[s.get('si')] || -1)),
  );
};

/**
 * Remove next action items from the list.
 *
 * @param state {Immutable.Map}.
 * @param action {Object}.
 * @returns {Immutable.Map}
 */
const removeItems = (state, action) => {
  const { idList } = action;
  const actionList = state.get('actions');

  return state.set(
    'actions',
    actionList.filter(a => !idList.includes(a.get('id'))),
  );
};

/**
 * Update action item after set backlog successfully.
 *
 * @param state {Immutable.Map}.
 * @param action {Object}.
 * @returns {Immutable.Map}
 */
const updateBLAction = (state, action) => {
  const { id, backlogged } = action;
  const index = state.get('actions').findKey(a => a.get('id') === id);

  if (index !== undefined && index > -1) {
    const item = state.getIn(['actions', index]);

    return state.setIn(
      ['actions', index],
      item.merge({
        spinner: false,
        backlogged,
      }),
    );
  }

  return state;
};

/**
 * Toggle spinner on action item.
 *
 * @param state {Immutable.Map}.
 * @param action {Object}.
 * @returns {Immutable.Map}
 */
const toggleBLSpinner = (state, action, enabled) => {
  const { id } = action;
  const index = state.get('actions').findKey(a => a.get('id') === id);

  if (index !== undefined && index > -1) {
    return state.setIn(['actions', index, 'spinner'], enabled);
  }

  return state;
};

/**
 * Update state after deleting fail.
 *
 * @param state {Immutable.Map}.
 * @param action {Object}.
 * @returns {Immutable.Map}
 */
const deleteFail = (state, action) =>
  state.mergeDeep({
    popup: {
      stage: 'error',
      errors: getErrorResponse(action.response),
    },
  });

/**
 * Update fetching state of action.
 *
 * @param state {Immutable.Map}.
 * @param action {Object}.
 * @returns {Immutable.Map}
 */
const fetching = (state, action) => {
  const { firstPage, requestId } = action;
  const common = state.get('common').merge({
    loadingAction: true,
    requestId,
    total: firstPage ? 0 : state.getIn(['common', 'total']),
    nextPage: firstPage ? -1 : state.getIn(['common', 'nextPage']),
  });

  return state.merge({
    common,
    actions: firstPage ? Immutable.List() : state.get('actions'),
  });
};

/**
 * Get error from api response.
 *
 * @param state {Immutable.Map}.
 * @param action {Object}.
 * @returns {Immutable.Map}
 */
const mapError = (state, action) => {
  if (!action.requestId || state.getIn(['common', 'requestId']) === action.requestId) {
    const errors = state.getIn(['common', 'errors']);

    return state.mergeDeep({
      common: {
        loadingAction: false,
        loadingSetting: false,
        exporting: false,
        requestId: '',
        nextPage: -1,
        errors: errors.concat(getErrorResponse(action.response)),
      },
    });
  }

  return state;
};

/**
 * Map action from api response to list.
 *
 * @param state {Immutable.Map} Next action state.
 * @param selected {boolean} Selected state.
 * @returns {Immutable.List} List of next action.
 */
const mapActions = (state, action) => {
  if (action.response.status === 'success' && state.getIn(['common', 'requestId']) === action.requestId) {
    const { currentPage, totalPages, total } = action.response.meta.pagination;
    const actions =
      currentPage === 1 ? mapAction(action.response) : state.get('actions').concat(mapAction(action.response));

    return state.set('actions', actions).mergeDeep({
      common: {
        nextPage: currentPage < totalPages ? currentPage + 1 : -1,
        loadingAction: false,
        total,
      },
    });
  }

  return state;
};

/**
 * Change next action states.
 *
 * @param state {Immutable.Map} Next action state.
 * @param id {Number} Action's id, will apply to all action if null.
 * @param selected {boolean} Selected state.
 * @returns {Immutable.List} List of next action.
 */
const toggleActionState = (state, id, selected) => {
  const actions = state.get('actions');

  if (id && id > 0) {
    const index = actions.findIndex(e => e.get('id') === id);

    if (index !== undefined && index > -1) {
      return state.set('actions', actions.setIn([index, 'selected'], selected));
    }

    return state;
  }

  return state.set(
    'actions',
    actions.map(e => e.set('selected', selected)),
  );
};

/**
 * Get events returned from api response.
 *
 * @param state {Immutable.Map} Next action state.
 * @param response {object} Response.
 * @returns {Immutable.List} List of event.
 */
const mapAction = response => {
  if (response.status === 'success') {
    return Immutable.fromJS(response.data.map(e => Immutable.Map(actionMapping(e))));
  }

  return Immutable.List();
};

const actionMapping = e => ({
  id: e.id,
  approach: e.approach,
  date: getDate(e.date),

  userName: getSafeValue(e.userName, ''),
  recordOwnerUserName: getSafeValue(e.recordOwnerUserName, ''),
  recordSubOwnerUserName: getSafeValue(e.recordSubOwnerUserName, ''),

  activity: getActivity(e),
  showBL: showBL(e),

  legalName: getLegalName(e),

  currentStatus: getCurrentStatus(e),
  highStatus: getHighStatus(e),

  priority: getPriority(e),
  entityType: getEntityType(e),
  buyerType: getSafeValue(e.buyerBuyerType, ''),

  targetId: e.targetId,
  buyerId: e.buyerId,
  execId: e.execId,

  targetRevenue: `$${getSafeValue(e.targetRevenue, '0')}`,
  targetEmployees: `${getSafeValue(e.targetEmployees, '0')} emp.`,
  address: getAddress(e),

  web: getWeb(e),
  webLink: getWebURL(e),

  buyerName: getBuyerName(e),
  buyerParent: getBuyerParent(e),
  industryLabel: getIndustryLabel(e),
  projectName: getProjectName(e),
  projectId: getProjectId(e),
  backlogged: checkBackLog(e),
});

/**
 * Get web name.
 *
 * @param event {Object} Event object.
 * @returns {string}
 */
const getWeb = event => {
  if (event.approach === 'buyer') {
    return event.buyerWeb;
  }

  if (event.approach === 'target') {
    return event.targetWeb;
  }

  return '';
};

/**
 * Get priority.
 *
 * @param event {Object} Event object.
 * @returns {string}
 */
const getBuyerParent = event => {
  if (event.approach === 'buyer') {
    return event.buyerParentLegalName ? `PARENT: ${event.buyerParentLegalName}` : '';
  }

  return '';
};

/**
 * Get priority.
 *
 * @param event {Object} Event object.
 * @returns {string}
 */
const getIndustryLabel = event => {
  if (event.approach === 'target') {
    return getSafeValue(event.targetIndustryLabel, '');
  }

  if (event.approach === 'buyer') {
    return '';
  }

  return getSafeValue(event.execIndustryLabel, '');
};

/**
 * Get priority.
 *
 * @param event {Object} Event object.
 * @returns {string}
 */
const getEntityType = event => {
  if (event.approach === 'target') {
    return getSafeValue(event.targetEntityType, '');
  }

  if (event.approach === 'buyer') {
    return getSafeValue(event.buyerEntityType, '');
  }

  return '';
};

/**
 * Get priority.
 *
 * @param event {Object} Event object.
 * @returns {string}
 */
const getPriority = event => {
  if (event.approach === 'target') {
    return getSafeValue(event.targetPriority, '');
  }

  if (event.approach === 'buyer') {
    return getSafeValue(event.buyerPriority, '');
  }

  return '';
};

/**
 * Get current status.
 *
 * @param event {Object} Event object.
 * @returns {boolean}
 */
const getHighStatus = event => {
  if (event.approach === 'target') {
    return getSafeValue(event.targetHighStatus, 'MISSING');
  }

  if (event.approach === 'buyer') {
    return getSafeValue(event.buyerHighStatus, 'MISSING');
  }

  return getSafeValue(event.execHighStatus, 'MISSING');
};

/**
 * Get legal name.
 *
 * @param event {Object} Event object.
 * @returns {string}
 */
const getLegalName = event => {
  if (event.approach === 'target') {
    return getSafeValue(event.targetLegalName, 'MISSING');
  }

  if (event.approach === 'buyer') {
    return getSafeValue(event.buyerLegalName, 'MISSING');
  }

  return getSafeValue(event.execFLName, '');
};

/**
 * Get current status.
 *
 * @param event {Object} Event object.
 * @returns {boolean}
 */
const getCurrentStatus = event => {
  if (event.approach === 'target') {
    return getSafeValue(event.targetCurrentStatus, 'MISSING');
  }

  if (event.approach === 'buyer') {
    return getSafeValue(event.buyerCurrentStatus, 'MISSING');
  }

  return getSafeValue(event.execCurrentStatus, 'MISSING');
};

/**
 * Check if backlog is set to true or not.
 *
 * @param event {Object} Event object.
 * @returns {string|null}
 */
const checkBackLog = event => {
  if (event.approach !== 'target') {
    return null;
  }

  return event.backlogged;
};

/**
 * Check and format date to mm/dd/yyyy.
 *
 * @param dtStr {string} Date string.
 * @returns {string}
 */
const getDate = dtStr => {
  const dt = moment(dtStr);

  if (dt.isValid()) {
    return dt.format('L');
  }

  return '';
};

/**
 * Check whether to show BL or not.
 *
 * @param event {Object} Event object.
 * @returns {string} Activity.
 */
const showBL = event =>
  (event.isletter || event.isemail || config.BACKLOGGED_EVENTS.includes(event.activity)) &&
  !event.blPending &&
  // event.approach === 'target' &&
  config.tables.getIn(['nextaction', 'backlogActivities']).includes(getSafeValue(event.activity, ''));

/**
 * Return activity.
 *
 * @param event {Object} Event object.
 * @returns {string} Activity.
 */
const getActivity = event => {
  const custom = event.activity === '(Custom)' ? '' : getSafeValue(event.activity, '');
  const description = getSafeValue(event.description, '');

  return joinString(' - ', custom, description);
};

/**
 * Return project name.
 *
 * @param event {Object} Event object.
 * @returns {string} Project name.
 */
const getProjectName = event => {
  let name = 'MISSING PROJECT';

  if (event.approach === 'target') {
    if (event.projectId) {
      name = getSafeValue(event.projectCategory, '');
      name += event.projectExecId ? ' - ' : '';
      name += getSafeValue(event.projectExecFLName, '');
      if (event.projectHarvcoLeadId) {
        name += ` (${getSafeValue(event.projectHarvcoLeadUserName)})`;
      }
    }

    return name;
  }

  if (event.approach === 'exec') {
    if (event.eprojectId) {
      name = getSafeValue(event.eprojectCategory, '');
    }

    return name;
  }

  return '';
};

const getProjectId = event => {
  let id = 0;

  if (event.approach === 'target') {
    if (event.projectId) {
      id = getSafeValue(event.projectId, 0);
    }

    return id;
  }

  if (event.approach === 'exec') {
    id = getSafeValue(event.eprojectId, 0);

    return id;
  }

  return '';
};

/**
 * Return buyer name.
 *
 * @param event {Object} Event object.
 * @returns {string} Buyer name.
 */
const getBuyerName = event => {
  let name = 'MISSING BUYER';

  if (event.buyerId) {
    name = getSafeValue(event.buyerAbbrName) || getSafeValue(event.buyerLegalName, '');
  }

  return name;
};

/**
 * Return an adress by combining city + state.
 *
 * @param event {Object} Event object.
 * @returns {string}
 */
const getAddress = event => {
  if (event.approach === 'target') {
    return joinString(', ', getSafeValue(event.targetCity, ''), getSafeValue(event.targetState, ''));
  }

  if (event.approach === 'buyer') {
    return joinString(', ', getSafeValue(event.buyerCity, ''), getSafeValue(event.buyerState, ''));
  }

  return getSafeValue(event.execState, '');
};

/**
 * Auto append http prefix if missing.
 *
 * @param event {Object} Event object.
 * @returns {string}
 */
const getWebURL = event => {
  if (event.approach === 'target') {
    if (!event.targetWeb) return '';

    return event.targetWeb.match(/^(http)|(https).*/i) ? event.targetWeb : `http://${event.targetWeb}`;
  }

  if (event.approach === 'buyer') {
    if (!event.buyerWeb) return '';

    return event.buyerWeb.match(/^(http)|(https).*/i) ? event.buyerWeb : `http://${event.buyerWeb}`;
  }
};

/**
 * Update direction of sorting field.
 *
 * @param state {Object} State.
 * @param action {Object} Action.
 * @returns Sorting list.
 */
const changeSorting = (state, { field, direction, included, order }) => {
  const sorts = state.getIn(['settings', 'sorts']);
  const index = sorts.findIndex(s => s.get('si') === field);

  if (index > -1) {
    const sort = sorts.get(index).merge({
      sd: direction,
      included,
      order,
    });

    return sorts.set(index, sort);
  }

  return sorts;
};

/**
 * Update direction of sorting field.
 *
 * @param state {Object} State.
 * @param field {String} Filtering field.
 * @param checked {Boolean} Filtering checked.
 * @returns Filtering list.
 */
const changeFiltering = (state, field, checked) => {
  const filters = state.getIn(['settings', 'filters']);

  for (let i = 0, len = filters.size; i < len; i++) {
    const subFilters = filters.getIn([i, 'filters']);
    const index = subFilters.findIndex(f => f.get('name') === field);

    if (index > -1) {
      return filters.setIn([i, 'filters'], subFilters.setIn([index, 'checked'], checked));
    }
  }

  return filters;
};
