import React, { PureComponent } from 'react';

class AgHeader extends PureComponent {
  constructor(props) {
    super(props);

    this.props.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
    this.onClick = this.onClick.bind(this);
    this.onSort = this.onSort.bind(this);
    this.state = {
      sorted: '',
    };
  }

  getGui() {
    return this.header;
  }

  onSortRequested(order, event) {
    this.props.setSort(order, event.shiftKey);
  }

  onSortChanged() {
    if (this.props.column.isSortAscending()) {
      this.setState({
        sorted: 'asc',
      });
    } else if (this.props.column.isSortDescending()) {
      this.setState({
        sorted: 'desc',
      });
    } else {
      this.setState({
        sorted: '',
      });
    }
  }

  onClick(event) {
    if (this.props.column.colDef.onClick) {
      this.props.column.colDef.onClick(event, this.props.api);
    }
  }

  onSort(event) {
    let sorted = '';

    if (this.state.sorted === '') {
      sorted = 'asc';
    } else if (this.state.sorted === 'asc') {
      sorted = 'desc';
    } else if (this.state.sorted === 'desc') {
      sorted = '';
    }
    this.props.setSort(sorted, event.shiftKey);
  }

  render() {
    const {
      column: { colDef },
    } = this.props;
    const { sorted } = this.state;

    let sortElements = null;
    let menuButton = null;
    let textElement = null;

    if (colDef.enableSorting || colDef.sortable) {
      if (sorted === 'asc') {
        sortElements = <i className="fa fa-caret-down" onClick={this.onClick} />;
      } else if (sorted === 'desc') {
        sortElements = <i className="fa fa-caret-up" onClick={this.onClick} />;
      }
      textElement = (
        <span className="mr5" onClick={this.onSort}>
          {colDef.headerName}
        </span>
      );
    } else {
      textElement = <span className="mr5">{colDef.headerName}</span>;
    }

    if (colDef.onClick) {
      menuButton = (
        <span onClick={this.onClick}>
          <i className="fa fa-plus mr5" />
        </span>
      );
    }

    return (
      <div
        ref={input => {
          this.header = input;
        }}
        className="ag-header-cell-label"
      >
        {menuButton}
        {textElement}
        {sortElements}
      </div>
    );
  }
}

export default AgHeader;
