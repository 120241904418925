import React from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import getZIndex from '../../utils/getZIndex';
import uniqueId from '../../utils/uniqueId';

const ErrorContent = ({ children, onClose }) => (
  <div className="alert alert-danger alert-dismissible input-custom-alert" role="alert">
    <button aria-label="Close" className="close" onClick={onClose} type="button">
      <span aria-hidden="true">&times;</span>
    </button>
    {children}
  </div>
);

class PhoneInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.id = uniqueId();
    this.savePortalRef = this.savePortalRef.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.rememberComponent = this.rememberComponent.bind(this);
    this.portal = null;
    this.component = null;
  }

  componentDidUpdate(oldProps) {
    if (this.props.autoFocus && !oldProps.autoFocus) {
      setTimeout(() => {
        try {
          const { id = this.id } = this.props;

          document.getElementById(id).focus();
        } catch (e) {
          console.warn(e);
        }
      }, 0);
    }
  }

  savePortalRef(portal) {
    if (this.portal) {
      if (this.portal.node) {
        const { node } = this.portal;

        if (Array.prototype.indexOf.call(node.parentNode, node) !== -1) {
          node.parentNode.removeChild(node); // portal have some problems with self-removing. PR created.
        }
      }
    }
    this.portal = portal;
  }

  renderError() {
    const { error, onErrorClose } = this.props;

    if (!error) return null;

    const targetElement = this.component;

    if (!targetElement) return null;

    const bodyRect = document.documentElement.getBoundingClientRect();
    const targetRect = targetElement.getBoundingClientRect();

    if (targetRect.bottom === 0 && targetRect.top === 0) return null;

    const zIndex = getZIndex(targetElement) + 1;
    const top = targetRect.bottom - bodyRect.top;
    const left = targetRect.left - bodyRect.left;
    const { width } = targetRect;

    const style = {
      position: 'absolute',
      minWidth: '150px',
      maxHeight: '200px',
      overflowY: 'auto',
      top,
      left,
      width,
      zIndex,
    };

    const container = (
      <div ref={this.savePortalRef} style={style}>
        <ErrorContent onClose={onErrorClose}>{error}</ErrorContent>
      </div>
    );

    const portal = createPortal(container, document.querySelector('body'));

    return portal;
  }

  rememberComponent(ref) {
    this.component = ref;
  }

  onFocus() {
    if (this.props.onErrorClose()) {
      this.props.onErrorClose();
    }
  }

  render() {
    const {
      onUndo,
      changed = false,
      value,
      onChange,
      className,
      name,
      onBlur,
      maxLength = 30,
      disabled,
      placeholder,
    } = this.props;
    const divClass = classNames('clearfix', className);
    const inputClass = classNames('form-control input-md', { changed });

    const label = onUndo ? (
      <label htmlFor={this.id}>
        Phone -
        <a href="#" onClick={onUndo} title="Undo">
          undo
        </a>
      </label>
    ) : (
      <label htmlFor={this.id}>Phone</label>
    );

    return (
      <div ref={this.rememberComponent} className={divClass}>
        {label}
        <span className="control-wrap">
          <input
            className={inputClass}
            disabled={disabled}
            id={this.id}
            maxLength={maxLength}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={this.onFocus}
            placeholder={placeholder}
            value={value}
          />
        </span>
        {this.renderError()}
      </div>
    );
  }
}

export default PhoneInput;
