import React, { Component } from 'react';

class DateRender extends Component {
  format(date) {
    return (date && date.isValid() && date.format('MM/DD/YYYY')) || '';
  }

  render() {
    const dateDisplay = this.format(this.props.value);

    return <span>{dateDisplay}</span>;
  }
}

export default DateRender;
