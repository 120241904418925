import * as React from 'react';
import classNames from 'classnames';

import AutoComplete from '../helpers/AutoComplete';
import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import Select from '../helpers/Select';

const RunWindwardReportPopup = props => {
  const loading = props.popupLoading ? (
    <div className="loading">
      <i className="fa fa-spinner fa-pulse fa-3x fa-fw login-spinner" />
    </div>
  ) : null;
  const header = 'Run Windward Report';
  const footer = [
    <Button
      key="run"
      className="btn-primary btn-xs"
      disabled={props.isDisabledRunButton}
      onClick={props.onRunWindwardReport}
    >
      Run
    </Button>,
    <Button key="close" className="btn-default btn-xs" onClick={props.onClose}>
      Close
    </Button>,
  ];

  const suggestionProps = {
    inputProps: {
      id: 'module',
      name: 'module',
      label: 'Select Module:',
      disabled: false,
      placeholder: '',
    },
    formGroupClass: 'modal-control',
    suggestions: props.suggestions,
    loading: props.moduleLoading,
    text: props.moduleText,
    onUpdateSuggestions: props.updateSuggestions,
    getSuggestion: props.getSuggestion,
    getNextSuggestion: props.getNextSuggestion,
    getSuggestionValue: props.getSuggestionValue,
    renderSuggestion: props.renderSuggestion,
    onSuggestionSelected: props.suggestionSelected,
    suggestIfEmpty: true,
    highlightFirstSuggestion: false,
    focusInputOnSuggestionClick: false,
  };

  return (
    <Popup footer={footer} header={header} id="runWindwardReportPopup" loading={loading}>
      <div className="flex-stretched">
        <AutoComplete {...suggestionProps} />
        <Select
          disabled={props.disabledTemplates}
          id="dropdownType"
          label="Select Report:"
          name="companyType"
          onChange={props.onSelectReport}
          options={props.templates}
          wrapperClassName={classNames('modal-control')}
          showLabel
        />
      </div>
    </Popup>
  );
};

export default RunWindwardReportPopup;
