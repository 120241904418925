import React from 'react';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Select from '../helpers/Select';
import Button from '../helpers/Button';

import uniqueId from '../../utils/uniqueId';

const selectId = uniqueId();

const AddFundPopup = props => {
  const { isValid, onClose, onChange, suggestCompany, suggestProject, onCreate, statuses } = props;

  const header = 'New Fund';
  const footer = [
    <Button key="create" className="btn-primary btn-xs" disabled={!isValid} onClick={onCreate}>
      Create
    </Button>,
    <Button key="cancel" className="btn-default btn-xs" onClick={onClose}>
      Cancel
    </Button>,
  ];

  return (
    <Popup footer={footer} header={header} id="newFundRecord">
      <div className="row media-450">
        <div className="col-md-12">{suggestCompany}</div>
      </div>
      <div className="row media-450">
        <FormGroup className="col-lg-6 col-sm-6 col-xs-6">{suggestProject}</FormGroup>
        <FormGroup className="col-lg-6 col-xs-6">
          <Select
            id={selectId}
            label="Current Status:"
            name="newFund.currentStatus"
            onChange={onChange}
            options={statuses}
            showLabel
            showValue
          />
        </FormGroup>
      </div>
    </Popup>
  );
};

export default AddFundPopup;
