import { fromJS } from 'immutable';

import * as ActionType from '../actions/country';

const defaultState = fromJS({
  loaded: false,
  data: [],
  pagination: null,
});

function mapCountries(state, action) {
  const {
    data,
    meta: { pagination },
  } = action.response;
  const countries = state
    .get('data')
    .toJS()
    .concat(data);

  return state.set('data', fromJS(countries)).set('pagination', fromJS(pagination));
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.FULL_LOADED_COUNTRIES:
      return state.set('loaded', true);

    case ActionType.LOADED_COUNTRIES_SUCCESS:
      return mapCountries(state, action);

    default:
      return state;
  }
}
