import PropTypes from 'prop-types';

import React, { Children, Component } from 'react';
import { List, Map, fromJS } from 'immutable';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import AutoComplete from '../AutoComplete';

import DeepValue from '../../decorators/DeepValue';
import ChangeDetection from '../../decorators/ChangeDetection';
import uniquieId from '../../../utils/uniqueId';
import getZIndex from '../../../utils/getZIndex';

/**
 * Dropdown wrapper.
 *
 * @param props {Object}.
 * @param props.id {string}.
 * @param props.btnContent {string|React.Component} Content of dropdown button. Caret is added by
 *   this element. Need not to pass it.
 * @param [props.wrapperClassName] {string} Class names for main div. `"dropdown"` class
 *   is already added to element. Need not to pass it.
 * @param [props.align=`"left"`] {string} Can be `"left"` or `"right"`. If align is left,
 *   all dropdown elements will start from left side of button.
 *   If align is right - from right part.
 * @param [props.className] {string} `<button>` class name. `"btn dropdown-toggle"` classes
 *    are already added to element. Need not to pass them.
 * @param [props.value=""] {string} Current value of dropdown. Hidden input has this value.
 * @param [props.name] {string} Name attribute for input.
 * @param [props.children] {React.Component[]} DropDown's content in XML format.
 * @param [props.options] {Immutable.List} DropDown's content in js format.
 * @param [props.className] {String} Extra classNames for button tag of DD.
 * @param [props.onChange] {Function} OnDropDown change callback (need change value manual).
 * @param [props.allowEmptyValue] {Boolean} Allow set empty value on blur.
 * @returns {React.Component}
 */
class DropDown extends Component {
  constructor(props, context) {
    super(props, context);

    this.id = props.id || uniquieId();
    this.shouldAutoOpen = !!props.autoFocus;
    this.options = fromJS([]);
    this.onClick = this.onClick.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.rememberCont = this.rememberCont.bind(this);
    this.renderOption = this.renderOption.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.triggerOpen = this.triggerOpen.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.ulRef = React.createRef();

    this.state = {
      isOpened: false,
    };
  }

  componentDidMount() {
    this.rememberOptions();
    if (this.shouldAutoOpen) {
      this.open();
    }
  }

  componentDidUpdate() {
    this.shouldAutoOpen = false;
    this.rememberOptions();
  }

  rememberOptions() {
    let opts = List();
    const { children = [], options = [] } = this.props;

    Children.forEach(children, child => {
      opts = opts.push(fromJS(child.props));
    });
    options.forEach(props => {
      opts = opts.push(props);
    });
    this.options = opts;
  }

  rememberCont(cont) {
    this.cont = cont;
  }

  onClick(event) {
    this.close();
    if (!this.props.onChange) return;
    if (!event.target) return;

    const index = this.indexOfTarget(event);

    if (index === -1) return;

    const value = this.options.getIn([index, 'value']);

    if (value === undefined) return;
    event.target.name = this.props.name;
    event.target.value = value;

    return this.props.onChange(event);
  }

  indexOfTarget(event) {
    const parent = this.ulRef.current;
    let currentElement = event.target;
    let currentParent = event.target.parentNode;

    while (currentParent !== parent && currentParent) {
      [currentElement, currentParent] = [currentParent, currentParent.parentNode];
    }

    if (currentParent === parent) {
      for (let i = 0; i < parent.childNodes.length; i++) {
        if (parent.childNodes[i] === currentElement) {
          return i;
        }
      }
    }

    return -1;
  }

  open() {
    this.setState({ isOpened: true });
  }

  close() {
    this.shouldAutoOpen = false;
    this.setState({ isOpened: false });
  }

  triggerOpen() {
    if (this.state.isOpened) {
      this.close();
    } else {
      this.open();
    }
  }

  renderOption(option) {
    return <div>{option.name || option.value}</div>;
  }

  getStyle() {
    if (!this.cont) return {};
    if (!document.documentElement) return {};

    const bodyRect = document.documentElement.getBoundingClientRect();
    const targetRect = this.cont.getBoundingClientRect();
    const top = targetRect.bottom - bodyRect.top;
    const left = targetRect.left - bodyRect.left;
    const width = Math.max(targetRect.width, 150);
    const zIndex = getZIndex(this.cont) + 10;

    return {
      position: 'absolute',
      top,
      left,
      width,
      zIndex,
    };
  }

  getOptions(value) {
    const regExp = new RegExp(String(value).replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1'), 'i');

    return this.options.filter(option => regExp.test(option.get('value')));
  }

  onBlur(event) {
    const { value, options, name, allowEmptyValue, onChange } = this.props;

    if (!onChange) return;

    if (value === undefined) return;
    if (value === 0) return;

    const regExp = new RegExp(`^${String(value).replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}$`, 'i');
    const firstValue = allowEmptyValue ? List() : options.get(0);
    const nextVal = this.options.find(option => regExp.test(option.get('value')), null, firstValue);

    event.target.name = name;
    event.target.value = nextVal.get('value') || '';

    return onChange(event);
  }

  onSelect(event, suggestion) {
    if (!this.props.onChange) return;

    const { suggestionValue: value } = suggestion;

    if (value === undefined) return;
    if (value === 0) return;
    event.target.name = this.props.name;
    event.target.value = value;

    return this.props.onChange(event);
  }

  render() {
    const { name, disabled = false, changed = false } = this.props;

    const wrapperClassName = classNames('dropdown', this.props.wrapperClassName);

    const inputProps = {
      name,
      disabled,
      className: classNames('form-control input-sm', this.props.className, {
        changed,
      }),
    };

    const values = getValues(this.props);

    return (
      <div ref={this.rememberCont} className={wrapperClassName}>
        <input name={name} type="hidden" value={values.value} />
        <AutoComplete
          canEverBeLoading={false}
          checkOn="text"
          formGroupClass=" "
          getSuggestion={suggestion => suggestion.value}
          getSuggestionValue={value => (value && value.value) || value}
          inputProps={{ ...inputProps, onBlur: this.onBlur }}
          loading={false}
          minSuggestLength={0}
          name={name}
          onSuggestionSelected={this.onSelect}
          renderSuggestion={this.renderOption}
          suggestions={this.getOptions(this.props.value)}
          text={String(this.props.value)}
          suggestIfEmpty
        />
        <span
          className="caret"
          onClick={this.triggerOpen}
          style={{
            position: 'absolute',
            right: 5,
            bottom: '40%',
            pointerEvents: 'none',
          }}
        />
      </div>
    );
  }
}

DropDown.propTypes = {
  align: PropTypes.string,
  btnContent: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool, // oneOfType([PropTypes.string, PropTypes.instanceOf(Map)]),
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.instanceOf(List),
  value: PropTypes.any,
  wrapperClassName: PropTypes.string,
};

DropDown.contextTypes = {
  inPopup: PropTypes.bool,
};

/**
 * DropDown element row. Wraps content by `<li><a> ... </a></li>`.
 *
 * @param props {Object} Attributes for `<a>` tag in element.
 * @param props.value {String} Value of this dropdown element.
 * @param [props.type="anchor"] {String} One of "anchor" or "link". If "anchor" - use `<a/>`
 *    If "link" - use ReactRouter's Link element.
 * For example `{ onClick:()=>{} }`.
 * @returns {React.Component}
 */
const DropDownElement = props => {
  const { value, children = value, type = 'anchor', ...rest } = props;
  const { className: appendClassName, ...properties } = rest;
  const className = classNames('min-height', appendClassName);

  let content;

  switch (type) {
    case 'link':
      content = (
        <Link className={className} {...properties}>
          {children}
        </Link>
      );
      break;

    case 'anchor':
      content = (
        <a className={className} {...properties}>
          {children}
        </a>
      );
      break;
  }

  return <li>{content}</li>;
};

DropDownElement.propTypes = {
  allowEmptyValue: PropTypes.bool,
  children: PropTypes.any,
  option: PropTypes.instanceOf(Map),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Map)]),
};

export { DropDownElement };

export default DeepValue(ChangeDetection(DropDown));

function getValues(props) {
  const result = {};

  if ('defaultValue' in props) {
    result.defaultValue = props.defaultValue;
  }

  if ('btnContent' in props) {
    result.btnContent = props.btnContent;
    result.value = props.btnContent;
  }

  if ('value' in props) {
    result.value = props.value;
  }

  if (Map.isMap(result.defaultValue)) {
    result.btnContent = result.defaultValue.get('name');
    result.value = result.defaultValue.get('value');
  }

  if (Map.isMap(result.value)) {
    result.btnContent = result.value.get('name');
    result.value = result.value.get('value');
  }

  return result;
}
