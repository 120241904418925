import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import DealsTable from './DealsTable';
import DealsByStateTable from './DealsByStateTable';
import CompaniesTable from './CompaniesTable';

const BottomPanel = ({ data }) => (
  <>
    <DealsTable data={data} />
    <DealsByStateTable data={data} />
    <CompaniesTable data={data} />
  </>
);

BottomPanel.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
};

export default BottomPanel;
