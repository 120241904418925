import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import DatePicker from '@vespaiach/react-datepicker';
import BrowseCheckbox from './BrowseCheckbox';
import BrowseAutoComplete from './BrowseAutoComplete';

class BrowseActionFilter extends PureComponent {
  constructor(props) {
    super(props);

    this.handleAddTag = this.handleAddTag.bind(this);
    this.handleRemoveTag = this.handleRemoveTag.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleEventDescriptionChange = this.handleEventDescriptionChange.bind(this);
  }

  handleStartDateChange(date) {
    if (date.isValid()) {
      this.props.onChange({
        filterName: this.props.filter.get('name'),
        filterData: {
          startDate: date,
        },
      });
    }
  }

  handleEndDateChange(date) {
    if (date.isValid()) {
      this.props.onChange({
        filterName: this.props.filter.get('name'),
        filterData: {
          endDate: date,
        },
      });
    }
  }

  handleEventDescriptionChange(event) {
    const { value } = event.target;

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        eventDescription: value,
      },
    });
  }

  handleAddTag() {
    const { filter, info } = this.props;
    const name = filter.get('name');
    const eventActivity = filter.get('eventActivity');
    let eventSelected = info.get('selected');

    if (!eventSelected) {
      eventSelected = '(any activity)';
    } else {
      eventSelected = eventSelected.get(eventActivity.get('renderField'), '(any activity)');
    }

    let eventDescription = info.get('eventDescription');

    if (eventDescription === '') eventDescription = '(any description)';

    let selectedList = info.get('selectedList');
    const selected = `${eventSelected} - ${eventDescription}`;

    if (!selectedList.includes(selected)) {
      selectedList = selectedList.push(selected);

      this.props.onChange({
        filterName: name,
        filterData: {
          selectedList,
          text: '',
          selected: null,
          eventDescription: '',
        },
      });
    }
  }

  handleRemoveTag(event) {
    const { name } = event.target;
    const tagIndex = parseInt(name.replace('buyerFilterTag', ''), 10);
    let selectedList = this.props.info.get('selectedList');

    selectedList = selectedList.delete(tagIndex);

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        selectedList,
      },
    });
  }

  render() {
    const { filter, info } = this.props;
    const selectedList = info.get('selectedList');
    const tagListContent = selectedList.map((tag, i) => (
      <div key={i} className="MultiselectItem">
        {tag}
        <button
          aria-hidden="true"
          aria-label="close"
          className="MultiselectItem-close close btn-xs"
          name={`buyerFilterTag${i}`}
          onClick={this.handleRemoveTag}
          type="button"
        >
          &times;
        </button>
      </div>
    ));

    return (
      <BrowseCheckbox {...this.props}>
        <div className="BrowseControl-content BrowseControl-content--activity">
          <div className="form-inline">
            <div className="form-group">
              <label>From</label>
              <DatePicker
                className="form-control"
                dateFormat="MM/DD/YYYY"
                dropdownMode="select"
                onChange={this.handleStartDateChange}
                selected={info.get('startDate')}
                showDayInMonthOnly
                showMonthDropdown
                showYearDropdown
              />
            </div>
            <div className="form-group">
              <label>To</label>
              <DatePicker
                className="form-control"
                dateFormat="MM/DD/YYYY"
                dropdownMode="select"
                onChange={this.handleEndDateChange}
                selected={info.get('endDate')}
                showDayInMonthOnly
                showMonthDropdown
                showYearDropdown
              />
            </div>
            {tagListContent}
            <BrowseAutoComplete
              className="form-control"
              filter={filter.get('eventActivity')}
              info={info}
              onChange={this.props.onChange}
              onGetSuggestion={this.props.onGetSuggestion}
            />
            <div className="form-group input-group">
              <input
                className="form-control"
                onChange={this.handleEventDescriptionChange}
                placeholder="Event Description"
                type="text"
                value={info.get('eventDescription')}
              />
              <span className="input-group-addon" onClick={this.handleAddTag}>
                <i aria-hidden="true" className="fa fa-plus" />
              </span>
            </div>
          </div>
        </div>
      </BrowseCheckbox>
    );
  }
}
BrowseActionFilter.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onGetSuggestion: PropTypes.func.isRequired,
};

export default BrowseActionFilter;
