/**
 * Count percent.
 *
 * @param {number} numerator Source string.
 * @param {number} denominator Fields for replace.
 * @returns {string|number}
 */
export default function getPercent(numerator, denominator) {
  if (!denominator) return 0;

  return ((numerator / denominator) * 100).toFixed(1);
}
