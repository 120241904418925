import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import InputErrorDisplay from '../decorators/InputErrorDisplay';

import AutoCompleteContainer from '../AutoComplete';
import { getChanged, unwrap } from '../../utils/ChangeSpy';
import config from '../../config';
import Popups from '../../components/Popup';
import {
  saveInfo,
  handleUpdateCompany,
  findUsersByRole,
  closeValidationError,
  saveBIC,
} from '../../actions/company/buyer/roleCreating';
import { TYPE_STRATEGIC } from '../../config/constants/common';

class BuyerRolePopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.fromPositionPopup = this.props.popup.getIn(['props', 'fromPositionPopup']);
    if (this.fromPositionPopup) {
      BuyerRolePopupContainer.closable = true;
      this.context.onClosePopup(this.props.popup.getIn(['props', 'cb']));
    } else {
      BuyerRolePopupContainer.closable = false;
    }

    this.onSuggestChange = this.onSuggestChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      industryCategory: '',
      businessModel: '',
    };
  }

  componentDidMount() {
    this.props.handleUpdateCompany({
      name: 'buyerHarvcoLeadName',
      value: this.context.currentUser.get('module') || '',
    });
    this.props.handleUpdateCompany({
      name: 'buyerHarvcoLeadId',
      value: this.context.currentUser.get('moduleId') || 0,
    });
    if (this.props.isTarget) {
      this.context.onClosePopup(() => {
        this.props.handleUpdateCompany({
          name: 'disabledType',
          value: false,
        });
      });
      this.props.handleUpdateCompany({
        name: 'disabledType',
        value: true,
      });
      this.props.handleUpdateCompany({
        name: 'buyerType',
        value: TYPE_STRATEGIC,
      });
    }
  }

  isValidIndustryCategory(ids, businessModelIds) {
    let result = true;

    if (!ids.length) {
      this.setState({
        industryCategory: 'Industry Tags is required',
      });
      result = false;
    }

    if (!businessModelIds.length) {
      this.setState({
        businessModel: 'Business Model is required',
      });
      result = false;
    }

    return result;
  }

  getBusinessModelIds(models) {
    const result = [];

    models.forEach(t => {
      if (t.get('checked')) {
        result.push(t.get('id'));
      }
    });

    return result;
  }

  getIndustryTagIds(tags) {
    const result = [];

    tags.forEach(t => {
      if (t.get('checked')) {
        result.push(t.get('id'));
      }
      t.get('children').forEach(c => {
        if (c.get('checked')) {
          result.push(c.get('id'));
        }
      });
    });

    return result;
  }

  handleClick() {
    this.context.openPopup('TagsManagementPopup', {
      addNew: true,
      clientView: true,
      cb: () =>
        this.context.openPopup('BuyerRolePopup', {
          fromPositionPopup: this.fromPositionPopup,
          cb: this.props.popup.getIn(['props', 'cb']),
          companyId: this.props.popup.get('props').get('companyId'),
        }),
    });
  }

  clearFormError() {
    this.setState({
      businessModel: '',
      industryCategory: '',
    });
  }

  onChange(event) {
    const { name, value } = event.target;

    this.props.handleUpdateCompany({
      name,
      value,
    });
  }

  onSuggestChange({ name, value }) {
    return this.props.handleUpdateCompany({ name, value });
  }

  getSuggests() {
    return {
      suggestHarvco: this.getUserSuggests(),
    };
  }

  getUserSuggests() {
    const prefix = '';

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={opts => {
          this.props.findUsersByRole({ ...opts, roles: [config.DIRECTOR] });
        }}
        idName={`${prefix}buyerHarvcoLeadId`}
        inputProps={{
          name: 'buyerHarvcoLeadName',
          className: 'form-control',
          label: 'Primary HarvCo Contact',
        }}
        name={`${prefix}buyerHarvcoLeadName`}
        rootPath={['targetCompany', 'buyer', 'roleCreating']}
        suggestsName={`${prefix}buyerHarvcoLeadSuggests`}
        valueName={`${prefix}buyerHarvcoLeadName`}
        suggestIfEmpty
      />
    );
  }

  onSave(event) {
    event.preventDefault();

    const current = getChanged(this.props.info);
    const companyId = this.props.popup.get('props').get('companyId');
    const industryCategoryIds = this.getIndustryTagIds(this.props.tags);
    const businessModelIds = this.getBusinessModelIds(this.props.businessModels);
    const body = {
      ...current,
      id: companyId,
    };

    if (this.isValidIndustryCategory(industryCategoryIds, businessModelIds)) {
      this.props.saveInfo({
        companyId,
        body,
        afterSuccess: () => {
          this.props.saveBIC({
            companyId,
            data: industryCategoryIds.concat(businessModelIds).map(id => ({
              industryCategoryId: id,
            })),
            afterSuccess: () => {
              this.context.redirectForce(window.location);
            },
          });
        },
      });
    }
  }

  onClose() {
    this.context.closePopup();
  }

  render() {
    const { children, info, businessModels, tags } = this.props;

    return (
      <div>
        <Popups.BuyerRolePopup
          info={info}
          {...this.getSuggests()}
          businessModels={businessModels}
          error={{
            businessModel: this.state.businessModel,
            industryCategory: this.state.industryCategory,
          }}
          onChange={this.onChange}
          onClick={this.handleClick}
          onClose={this.onClose}
          onErrorClose={this.clearFormError}
          onSave={this.onSave}
          tags={tags}
        />
        {children}
      </div>
    );
  }
}

BuyerRolePopupContainer.contextTypes = {
  redirectForce: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    ...props,
    isTarget: state.targetCompany.target.get('isTarget'),
    businessModels: unwrap(state.businessModels.get('models', List())),
    info: state.targetCompany.buyer.roleCreating,
    inputErrors: state.targetCompany.buyer.roleCreating.getIn(['inputErrors']),
    tags: state.industryTags.get('tags'),
  };
}

export { BuyerRolePopupContainer };
export default connect(
  mapStateToProps,
  {
    saveInfo,
    handleUpdateCompany,
    findUsersByRole,
    closeValidationError,
    saveBIC,
  },
  mergeProps,
  connectOptions,
)(InputErrorDisplay('closeValidationError', BuyerRolePopupContainer));
