import { connect } from 'react-redux';

import connectOptions, { mergeProps } from '../../../utils/connectOptions';
import AutoCompleteEditor from '../../../components/helpers/AutoCompleteCellEditor';
import {
  handleGetClientLeadSuggestion,
  clearClientLeadSuggestion,
  selectClientLeadSuggestion,
} from '../../../actions/company/buyer/ceo';

const mapStateToProps = state => {
  const activeProjects = state.targetCompany.buyer.ceo.get('activeProjects');

  return {
    suggestions: activeProjects.getIn(['suggestions', 'clientLead', 'list']).toJS(),
    loading: activeProjects.getIn(['suggestions', 'clientLead', 'loading']),
    placeholder: 'Client Lead',
  };
};

const mapDispatchToProps = {
  selectSuggestion: ({ selected, rowData }) =>
    selectClientLeadSuggestion({
      id: selected.id,
      text: selected.text,
      eprojectId: rowData.eprojectId,
    }),

  loadSuggestion: ({ value, rowData }) =>
    handleGetClientLeadSuggestion({
      value,
      data: { buyerId: rowData.buyerId },
    }),

  clearSuggestion: () => clearClientLeadSuggestion(),
};

export default AutoCompleteEditor(Comp =>
  connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    ...connectOptions,
    forwardRef: true,
  })(Comp),
);
