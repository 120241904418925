import { fromJS, List } from 'immutable';
import * as ActionType from '../actions/mailingHistory';

const defaultState = fromJS({
  queryResults: [],
  isFetching: false,
  page: 0,
  totalPages: 0,
  sortBy: {
    sortModel: [],
  },
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case ActionType.FETCHING_MAILING_HISTORY:
      return state.merge({
        isFetching: true,
        page: action.query.page,
      });

    case ActionType.FETCHING_MAILING_HISTORY_SUCCESS:
      if (action.response.meta.pagination.currentPage === 1) {
        state = state.set('queryResults', new List());
      }

      return state.merge({
        queryResults: state
          .get('queryResults')
          .concat(index(fromJS(action.response.data), state.get('queryResults').size)),
        isFetching: false,
        totalPages: action.response.meta.pagination.totalPages,
      });

    case ActionType.FETCHING_MAILING_HISTORY_FAILURE:
      return state.merge({
        isFetching: false,
      });

    case ActionType.UPDATE_MAILING_HISTORY:
      Object.keys(action.filterData).forEach(key => {
        if (List.isList(action.filterData[key]) || Array.isArray(action.filterData[key])) {
          state = state.setIn([action.filterName, key], new List());
        }
      });

      return state.mergeDeep({
        [action.filterName]: action.filterData,
      });

    default:
      return state;
  }
}

function index(data, startAt) {
  return data.map((d, i) => d.set('order', i + startAt));
}
