import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import Popups from '../../components/Popup';
import { getChanged } from '../../utils/ChangeSpy';
import { findUsers, changeField, makeContactLP, clearSuggest } from '../../actions/contact/contactLP';
import { reloadContactDetail } from '../../actions/contactDetail';

class LPRolePopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.onCreate = this.onCreate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFetchUsers = this.onFetchUsers.bind(this);
    this.onUpdateRelation = this.onUpdateRelation.bind(this);
    this.onSelectRelation = this.onSelectRelation.bind(this);
  }

  componentDidMount() {
    const module = {
      username: this.context.currentUser.get('module') || '',
      id: this.context.currentUser.get('moduleId') || 0,
    };

    this.props.changeField({ name: 'suggestRelation', value: module.username });
    this.props.changeField({ name: 'lpPrimaryRelId', value: module.id });
  }

  /** Create new LP handler. */
  onCreate() {
    const { contact, lp, makeContactLP, reloadContactDetail } = this.props;
    const id = contact.get('id', 0);
    const body = getChanged(lp);

    makeContactLP({ id, ...body }, () => {
      reloadContactDetail({ id }, this.onClose);
    });
  }

  onClose() {
    this.context.closePopup();
  }

  onChange(event) {
    const {
      target: { name, value },
    } = event;

    this.props.changeField({ name, value });
  }

  onFetchUsers({ value }) {
    this.props.findUsers({ filter: value });
  }

  static onGetValue(suggestion) {
    return suggestion.value;
  }

  onUpdateRelation(param) {
    if ('text' in param) {
      const name = 'suggestRelation';
      const value = param.text;

      this.props.changeField({ name, value });
    }

    if ('suggestions' in param) {
      const name = 'suggests';
      const value = param.suggestions;

      this.props.changeField({ name, value });
    }
  }

  onSelectRelation(e, { suggestion }) {
    const name = 'suggestRelation';

    this.props.changeField({ name, value: suggestion.text });
  }

  getSuggestRelation() {
    return {
      onFetch: this.onFetchUsers,
      onUpdate: this.onUpdateRelation,
      getValue: LPRolePopupContainer.onGetValue,
      onSelect: this.onSelectRelation,
      value: this.props.lp.get('suggestRelation', ''),
    };
  }

  render() {
    const { children, contact, lp } = this.props;
    const suggestRelation = this.getSuggestRelation();

    return (
      <div>
        <Popups.LPRolePopup
          contact={contact}
          lp={lp}
          onChange={this.onChange}
          onClose={this.onClose}
          onCreate={this.onCreate}
          suggestRelation={suggestRelation}
        />
        {children}
      </div>
    );
  }
}

LPRolePopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
};

function mapStateToProps(state, props) {
  return {
    ...props,
    contact: state.contact.info,
    lp: state.contact.lp,
  };
}

export { LPRolePopupContainer };
export default connect(
  mapStateToProps,
  {
    makeContactLP,
    changeField,
    findUsers,
    clearSuggest,
    reloadContactDetail,
  },
  mergeProps,
  connectOptions,
)(LPRolePopupContainer);
