import { fromJS, List, Map } from 'immutable';
import { createSelector } from 'reselect';

import config from '../../config';
import * as ActionType from '../../actions/contactDetail';
import * as ActionExecType from '../../actions/contact/contactExecutive';
import * as ActionLpType from '../../actions/contact/contactLP';
import getValidator from '../../utils/getValidator';
import handleApiError, { withFieldPrefix } from '../decorators/handleApiError';
import { spy, setIn, unwrap, toJS } from '../../utils/ChangeSpy';
import { getAge, getBirthYear } from '../../utils/yearAgeConverter';

const SCHEMA = {
  type: 'object',
  properties: {
    fullName: {
      type: 'string',
      title: 'Full Name',
      required: true,
      minLength: 1,
    },
    firstName: {
      type: 'string',
      title: 'First Name',
      required: true,
      minLength: 1,
    },
    lastName: {
      type: 'string',
      title: 'Last Name',
      required: true,
      minLength: 1,
    },
    linkedin: {
      title: 'LinkedIn',
      description: 'should be a URL string or empty',
      oneOf: [
        { type: 'string', format: 'uri' },
        { type: 'string', maxLength: 0 },
      ],
    },
    age: {
      title: 'Age',
      description: 'should be empty or positive integer less than 200',
      type: 'string',
      minLength: 0,
      maxLength: 3,
      pattern: /^[12]?[1234567890]{0,2}$/,
    },
    pronounce: {
      title: 'Pronounce',
      type: 'string',
      maxLength: 49,
    },
    phone: config.phoneValidation,
    phones: {
      type: 'array',
      items: {
        type: 'object',
        properties: config.phoneValidation,
      },
    },
    email: {
      title: 'E-mail',
      description: 'should be empty or valid e-mail address',
      oneOf: [
        { type: 'string', format: 'email' },
        { type: 'string', maxLength: 0 },
      ],
    },
    emails: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          value: {
            title: 'E-mail',
            description: 'should be a valid e-mail',
            type: 'string',
            format: 'email',
          },
        },
      },
    },
  },
};

const validator = getValidator(SCHEMA);

const VALUES_TO_SPY = [
  'firstName',
  'lastName',
  'prefix',
  'suffix',
  'fullName',
  'doNotMail',
  'nick',
  'pronounce',
  'linkedin',
  'age',
  'birthYear',
  'email',
];
const EMAIL_VALUES_TO_SPY = ['value', 'type'];
const PHONE_VALUES_TO_SPY = [...EMAIL_VALUES_TO_SPY];
const ADDRESS_VALUES_TO_SPY = [...EMAIL_VALUES_TO_SPY, 'address', 'city', 'zip', 'countryName', 'state', 'showCountry'];

const prefixes = config.values.getIn(['contact', 'prefixes']);
const suffixes = config.values.getIn(['contact', 'suffixes']);
const states = config.company.states.reduce((ret, st) => {
  if (st) {
    ret.push(Map({ name: st, value: st }));
  }

  return ret;
}, []);

const checkValue = value => valueObject => valueObject.get('value') === value;

const defaultState = fromJS(
  spy(
    {
      email: '',
      prefix: prefixes.get(0),
      prefixes,
      fullName: '',
      suffix: suffixes.get(0),
      suffixes,
      firstName: '',
      lastName: '',
      nick: '',
      pronounce: '',
      linkedin: '',
      birthYear: null,
      age: '',
      doNotMail: false,

      loading: true,
      hasTargets: false,
      hasBuyers: false,
      isExecutive: false,
      isLP: false,

      phones: [],
      emails: [],
      addresses: [],
      states,
      editing: [],
      inputError: {},
      inputErrors: {},
    },
    VALUES_TO_SPY,
  ),
);

function changeField(state, { name, value }) {
  switch (name) {
    case 'prefix': {
      return setIn(state, name, prefixes.find(checkValue(value), null, prefixes.get(0)));
    }

    case 'age': {
      return state.mergeDeep({
        age: { value },
        birthYear: { value: getBirthYear(value) },
      });
    }

    default:
      return setIn(state, name, value);
  }
}

function mapResponse({ data }) {
  const {
    id,
    fullName,
    firstName,
    lastName,
    doNotMail,
    nick,
    pronounce,
    linkedin,
    birthYear,

    hasTargets,
    hasBuyers,
    recordOwnerId,
    lpPrimaryRelId,

    phones,
    emails,
    addresses,
  } = data;
  let { prefix, suffix } = data;

  prefix = prefixes.find(checkValue(prefix), null, prefixes.get(0));
  suffix = suffixes.find(checkValue(suffix), null, fromJS({ name: suffix, value: suffix }));

  return spy(
    {
      id,
      prefix,
      firstName,
      lastName,
      suffix,
      fullName,
      doNotMail,
      nick,
      pronounce,
      linkedin,
      age: getAge(birthYear),
      birthYear: birthYear || null,

      hasTargets,
      hasBuyers,
      isLP: !!lpPrimaryRelId,
      isExecutive: !!recordOwnerId,

      editing: [],

      phones: phones.map(mapPhone),
      emails: emails.map(mapEmail),
      addresses: addresses.map(mapAddress),
    },
    VALUES_TO_SPY,
  );
}

function mapAddress(addr) {
  const {
    addressInherited: isInherited = false,
    entityId: companyId = 0,
    legalName: companyName = '',
    address,
    id,
    type,
    city,
    state,
    zip,
    countryName,
  } = addr;

  return spy(
    {
      isInherited,
      companyId,
      companyName,
      showCountry: !!countryName,
      value: getFullAddress(addr),
      address,
      id,
      type,
      city,
      zip,
      countryName,
      state,
    },
    ADDRESS_VALUES_TO_SPY,
  );
}

function mapEmail({ email: value, id, type, isVerify }) {
  return spy({ id, type, value, isVerify }, EMAIL_VALUES_TO_SPY);
}

function mapPhone(phoneObj) {
  const {
    phone: value,
    id,
    type,
    phoneInherited: isInherited = false,
    entityId: companyId = 0,
    legalName: companyName = '',
  } = phoneObj;

  return spy(
    {
      id,
      isInherited,
      companyId,
      companyName,
      type,
      value,
    },
    PHONE_VALUES_TO_SPY,
  );
}

let wrappedReducer = reducer;

wrappedReducer = handleApiError(ActionExecType.MAKE_ERROR_CONTACT_EXECUTIVE, wrappedReducer);
wrappedReducer = handleApiError(ActionLpType.MAKE_ERROR_CONTACT_LP, wrappedReducer);
wrappedReducer = handleApiError(ActionType.ERROR_SAVE_CONTACT_DETAIL, wrappedReducer);
wrappedReducer = withFieldPrefix(ActionType.ERRORED_SAVE_CONTACT_PHONE, wrappedReducer, fieldGetter('phones'));
wrappedReducer = withFieldPrefix(ActionType.ERRORED_SAVE_CONTACT_EMAIL, wrappedReducer, fieldGetter('emails'));
wrappedReducer = withFieldPrefix(ActionType.ERRORED_SAVE_CONTACT_ADDRESS, wrappedReducer, fieldGetter('addresses'));

function fieldGetter(type) {
  function find(state, type, id) {
    return state.get(type).findKey(ent => ent.get('id') === id);
  }

  return (state, action, errors) => {
    const path = [type, find(state, type, action.id), 'value'].join('.');
    const res = {};

    Object.keys(errors).forEach(key => {
      res[path] = errors[key];
    });

    return res;
  };
}

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.CREATE_CONTACT:
      return checkValidity(defaultState.merge({ loading: false }));

    case ActionType.CHANGE_CONTACT_INFO_FIELD:
      return checkValidity(changeField(state, action));

    case ActionType.LOAD_CONTACT_DETAIL:
      return defaultState;

    case ActionType.LOADED_CONTACT_DETAIL:
      return state.merge(mapResponse(action.response)).set('loading', false);

    case ActionType.ERROR_CONTACT_DETAIL:
      return state.set('loading', false);

    case ActionType.ADD_CONTACT_FIELD:
      return state.update(action.field, values =>
        values.push(
          fromJS(
            spy({ value: '', id: 'new', type: '' }, [
              ...EMAIL_VALUES_TO_SPY,
              ...ADDRESS_VALUES_TO_SPY,
              ...PHONE_VALUES_TO_SPY,
            ]),
          ),
        ),
      );

    case ActionType.SAVE_CONTACT_FIELD:
      return state.delete('_tempData');

    case ActionType.REVERT_CONTACT_FIELD: {
      if (!state.has('_tempData')) return state;

      return state.delete('_tempData').setIn(state.get('editing'), state.get('_tempData'));
    }

    case ActionType.EDIT_CONTACT_FIELD:
      return state
        .set('editing', List([action.field, action.index]))
        .deleteIn(state.get('editing').push('isEditing'))
        .setIn([action.field, action.index, 'isEditing'], true)
        .set('_tempData', state.getIn([action.field, action.index]));

    case ActionType.SAVED_CONTACT_DETAIL:
      return spy(
        action.fields.reduce((result, field) => result.set(field, unwrap(result.get(field))), state),
        VALUES_TO_SPY,
      );

    case ActionType.SAVED_CONTACT_PHONE: {
      const tmp = state.delete('_tempData');

      return tmp.update('phones', phones =>
        phones.map(phone =>
          phone.get('id') !== action.id
            ? phone
            : spy(
                setEntityId(
                  action.fields.reduce((result, field) => result.set(field, unwrap(result.get(field))), phone),
                  action,
                ),
                PHONE_VALUES_TO_SPY,
              ),
        ),
      );
    }

    case ActionType.SAVED_CONTACT_EMAIL:
      return state.update('emails', emails =>
        emails.map(email =>
          email.get('id') !== action.id
            ? email
            : spy(
                setEntityId(
                  action.fields.reduce((result, field) => result.set(field, unwrap(result.get(field))), email),
                  action,
                ),
                EMAIL_VALUES_TO_SPY,
              ),
        ),
      );

    case ActionType.SAVED_CONTACT_ADDRESS:
      return state.update('addresses', addresses =>
        addresses.map(address =>
          address.get('id') !== action.id
            ? address
            : spy(
                setEntityId(
                  action.fields.reduce((result, field) => result.set(field, unwrap(result.get(field))), address),
                  action,
                ),
                ADDRESS_VALUES_TO_SPY,
              ),
        ),
      );

    case ActionType.STOP_EDIT_CONTACT_FIELD:
      return state.set('editing', List([])).deleteIn(state.get('editing').push('isEditing'));

    case ActionType.CHANGE_CONTACT_FIELD:
      if (state.getIn(['editing', 0], null) === 'addresses') {
        return checkValidity(changeAddress(state, action));
      }

      if (action.field === 'emails' && action.name === 'value' && action.value) {
        action.value = action.value.trim();
      }

      return checkValidity(setIn(state, state.get('editing').push(action.name), action.value));

    case ActionType.DELETE_CONTACT_FIELD:
      return state.deleteIn([action.field, action.index]);

    case ActionType.CLOSE_CONTACT_VALIDATION_ERROR:
      return state.deleteIn(['inputErrors', action.field]);

    case ActionType.SET_NAME_FIELD_ERROR:
      return setIn(
        state.set('inputErrors', state.get('inputErrors', Map()).set(action.field, action.value)),
        action.field,
        '',
      );

    default:
      return state;
  }
}

function changeAddress(state, action) {
  let tmpState = state.setIn(state.get('editing').push(action.name, 'value'), action.value);

  tmpState = checkCountryAndZip(tmpState, action);

  const address = tmpState.getIn(state.get('editing'));
  const value = getFullAddress(address);

  return tmpState.setIn(state.get('editing').push('value', 'value'), value);
}

function checkCountryAndZip(state, action) {
  if (action.name === 'showCountry') {
    if (action.value) return state.setIn(state.get('editing').push('zip', 'value'), '');

    return state.setIn(state.get('editing').push('countryName', 'value'), '');
  }

  return state;
}

function safe(value) {
  return String(value || '').trim();
}

function getFullAddress(addr) {
  if (Map.isMap(addr)) {
    const countryName = safe(addr.getIn(['countryName', 'value'], ''));
    const state = safe(addr.getIn(['state', 'value'], ''));
    const zip = safe(addr.getIn(['zip', 'value'], ''));
    const city = safe(addr.getIn(['city', 'value'], ''));
    const address = safe(addr.getIn(['address', 'value'], ''));

    return [address, city, safe(`${state} ${zip}`), countryName].filter(v => v && v.length > 0).join(', ');
  }

  const countryName = safe(addr.countryName);
  const state = safe(addr.state);
  const zip = safe(addr.zip);
  const city = safe(addr.city);
  const address = safe(addr.address);

  return [address, city, safe(`${state} ${zip}`), countryName].filter(v => v && v.length > 0).join(', ');
}

const toJSSelector = createSelector(
  state => state,
  state => toJS(state),
);

function checkValidity(state) {
  const plainState = toJSSelector(state);

  const isValid = validator.isValid(plainState);

  const errors = validator.getFirstError(plainState);

  Object.keys(errors).forEach(key => {
    let newKey;

    if (/^addresses\.\d+\./.test(key)) {
      const temp = key.split('.');

      temp.pop();
      temp.push('value');
      newKey = temp.join('.');
    }
    if (!newKey) return;
    errors[newKey] = errors[key];
  });

  return state.merge({
    inputErrors: errors,
    isValid,
  });
}

function setEntityId(ent, action) {
  const id = ent.get('id');

  if (parseInt(id, 10)) return ent;
  if (action && action.response && action.response.id) {
    ent = ent.set('id', action.response.id);
  }

  return ent;
}
