import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Map, fromJS } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import shortcut from 'react-shortcut-hoc';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import stripOffChars from '../../utils/stripOffChars';
import Popups from '../../components/Popup';
import { unwrap } from '../../utils/ChangeSpy';
import * as TARGET from '../../actions/company/companyTarget';
import * as BUYER from '../../actions/company/buyer/marketing';
import getPhoneChanged, {
  checkPhoneIfNeeded,
  formatPhoneIfNeeded,
  checkExtensionIfNeeded,
  checkCountryIfNeeded,
} from '../services/phoneRules';

import { basicAccess } from '../../utils/checkPermissions';

class EditContactChannelPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onUndo = this.onUndo.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.setPhoneError = this.setPhoneError.bind(this);
    this.phoneOnBlur = this.phoneOnBlur.bind(this);
    this.extensionOnBlur = this.extensionOnBlur.bind(this);
    this.countryOnBlur = this.countryOnBlur.bind(this);
    this.state = {
      unformattedPhone: '',
      extension: '',
      country: '',
      isBasicAccess: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { phoneCodes, isNew, loadCountryPhoneCodes } = this.props;

    if (!phoneCodes.size) {
      loadCountryPhoneCodes();
    }

    if (isNew) {
      this.onChange({
        target: {
          name: 'type',
          value: 'Direct',
        },
      });
    }
  }

  componentDidMount() {
    const isBasicAccess = basicAccess(this.context.currentUser.getIn(['roles', 0, 'slug']));

    this.setState({ isBasicAccess });
    this.context.onClosePopup(() => {
      const field = this.props.pathPrefix.slice(0, -1);

      this.props.revertUpdate({ field });
    });
  }

  getChildContext() {
    return {
      onErrorClose: () => {
        this.setPhoneError({});
      },
      inputErrors: fromJS(this.state),
    };
  }

  onChange(event) {
    const { name, value } = event.target;
    const { phoneCodes, pathPrefix, handleUpdateCompany } = this.props;

    if (name === 'country') {
      const index = phoneCodes.findIndex(p => p.get('code') === value);

      if (index > -1) {
        handleUpdateCompany({
          name: `${pathPrefix}code`,
          value: phoneCodes.getIn([index, 'dialCode']),
        });
      }
    }

    return handleUpdateCompany({
      name: `${pathPrefix}${name}`,
      value: name === 'extension' ? stripOffChars(value, '', /[^\d]/gi) : value,
    });
  }

  onUndo() {
    this.onChange({ target: { name: 'type', value: 'Direct' } });
    this.onChange({ target: { name: 'code', value: '' } });
    this.onChange({ target: { name: 'extension', value: '' } });
    this.onChange({ target: { name: 'country', value: '' } });
  }

  onBlur(event) {
    if (event.target.name === 'unformattedPhone') {
      this.phoneOnBlur(event);
    } else if (event.target.name === 'extension') {
      this.extensionOnBlur(event);
    } else if (event.target.name === 'country') {
      this.countryOnBlur(event);
    }
  }

  countryOnBlur({ target: { value } }) {
    const type = unwrap(this.props.channel.get('type'));

    if (!checkCountryIfNeeded(type, value)) {
      this.setPhoneError({ extension: true });
    } else {
      this.setPhoneError({ extension: false });
    }
  }

  extensionOnBlur({ target: { value } }) {
    const type = unwrap(this.props.channel.get('type'));

    if (!checkExtensionIfNeeded(type, value)) {
      this.setPhoneError({ extension: true });
    } else {
      this.setPhoneError({ extension: false });
    }
  }

  phoneOnBlur({ target: { value } }) {
    const type = unwrap(this.props.channel.get('type'));

    if (!checkPhoneIfNeeded(type, value)) {
      this.setPhoneError({ phone: true });
    } else {
      this.onChange({
        target: {
          name: 'unformattedPhone',
          value: formatPhoneIfNeeded(type, value),
        },
      });
      this.setPhoneError({ phone: false });
    }
  }

  onSave() {
    const {
      channel,
      isNew,
      contactId,
      channelIndex,
      channelId,
      popup,
      saveCompanyContactChannel,
      updateLastResearcher,
    } = this.props;
    const { isBasicAccess } = this.state;
    const type = unwrap(channel.get('type'));
    const phone = unwrap(channel.get('unformattedPhone'));
    const extension = unwrap(channel.get('extension'));
    const country = unwrap(channel.get('country'));
    const phoneCheck = checkPhoneIfNeeded(type, phone);
    const extensionCheck = checkExtensionIfNeeded(type, extension);
    const countryCheck = checkCountryIfNeeded(type, country);
    const companyId = popup.getIn(['props', 'params', 'companyId']);
    const mode = popup.getIn(['props', 'mode']);

    if (phoneCheck && extensionCheck && countryCheck) {
      const body = getPhoneChanged(channel);

      saveCompanyContactChannel(
        {
          companyId,
          isNew,
          contactId,
          channelType: 'phones',
          channelId,
          channelIndex,
          body,
        },
        () => {
          this.context.closePopup();

          if (isBasicAccess && mode !== 'buyer') {
            updateLastResearcher(this.context.currentUser.get('userName'));
          }
        },
      );
      this.setPhoneError({});
    } else {
      this.setPhoneError({
        phone: !phoneCheck,
        extension: !extensionCheck,
        country: !countryCheck,
      });
    }
  }

  setPhoneError({ phone, extension, country }) {
    const errors = { unformattedPhone: '', extension: '', country: '' };

    if (phone === true) {
      errors.unformattedPhone =
        'Phone must be a valid North American area code and format (###-###-####) or +[numbers, spaces and dashes] if international';
    }

    if (extension === true) {
      errors.extension = 'The extension field is required when type is Extension.';
    }

    if (country === true) {
      errors.country = 'The country field is required when type is International.';
    }

    this.setState(errors);
  }

  render() {
    const { children, channel, phoneCodes, isNew, companyInfo } = this.props;

    return (
      <div>
        <Popups.EditPhoneContactChannelPopup
          channel={channel}
          companyInfo={companyInfo}
          isNew={isNew}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onSave={this.onSave}
          onUndo={this.onUndo}
          phoneCodes={phoneCodes}
        />
        {children}
      </div>
    );
  }
}

EditContactChannelPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
};

EditContactChannelPopupContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};

function mapStateToProps(state, props) {
  const openedContact = props.popup.getIn(['props', 'openedContact']);
  const channelType = 'phones';
  const channelIndex = props.popup.getIn(['props', 'channelIndex']);
  const channelId = props.popup.getIn(['props', 'channelId']);
  const companyInfo = props.popup.getIn(['props', 'companyInfo']);
  const isBuyer = props.popup.getIn(['props', 'mode']) === 'buyer';
  const root = isBuyer ? state.targetCompany.buyer.marketing : state.targetCompany.target;
  const contact = root.getIn(['contacts', openedContact]);
  const contactId = contact.get('id');
  const isNew = !parseInt(channelId, 10);
  const pathPrefix = isNew ? `newData.${channelType}.` : `contacts.${openedContact}.${channelType}.${channelIndex}.`;

  const channel = root.getIn(pathPrefix.split('.').slice(0, -1));
  const phoneCodes = root.get('phoneCodes');

  return {
    isNew,
    channel,
    channelId,
    channelIndex,
    contactId,
    pathPrefix,
    phoneCodes,
    contact,
    companyInfo,
  };
}

function mapDispatchToProps(dispatch, props) {
  const isBuyer = props.popup.getIn(['props', 'mode']) === 'buyer';

  if (isBuyer) {
    const { handleUpdateCompany, saveCompanyContactChannel } = BUYER;

    return bindActionCreators(
      {
        handleUpdateCompany,
        saveCompanyContactChannel,
        revertUpdate: TARGET.revertUpdate,
        loadCountryPhoneCodes: TARGET.loadCountryPhoneCodes,
      },
      dispatch,
    );
  }

  const {
    handleUpdateCompany,
    saveCompanyContactChannel,
    revertUpdate,
    loadCountryPhoneCodes,
    updateLastResearcher,
  } = TARGET;

  return bindActionCreators(
    {
      handleUpdateCompany,
      saveCompanyContactChannel,
      revertUpdate,
      loadCountryPhoneCodes,
      updateLastResearcher,
    },
    dispatch,
  );
}

export { EditContactChannelPopupContainer };

const shortcutWrapped = shortcut(EditContactChannelPopupContainer, 'ctrl+enter', 'onSave');
const Connected = connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions)(shortcutWrapped);

export default Connected;
