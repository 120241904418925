import { CALL_API } from '../../middleware/api';
import uniqueId from '../../utils/uniqueId';

export const CHANGE_TEXT = Symbol('CHANGE_TEXT');
export const CHANGE_MODE = Symbol('CHANGE_MODE');
export const LOADED_SUGGESTION_SUCCESS = Symbol('LOADED_SUGGESTION_SUCCESS');
export const SELECT_SUGGESTION = Symbol('SELECT_SUGGESTION');
export const REQUEST_FLIP_API_FAILURE = Symbol('REQUEST_FLIP_API_FAILURE');
export const SAVING_FORM = Symbol('SAVING_FORM');
export const SAVED_FORM_SUCCESS = Symbol('SAVED_FORM_SUCCESS');
export const RESET_POPUP = Symbol('RESET_POPUP');
export const LOADED_SUGGESTION_FAILURE = Symbol('LOADED_SUGGESTION_FAILURE');
export const LOADING_SUGGESTION = Symbol('LOADING_SUGGESTION');
export const SET_ERROR = Symbol('SET_ERROR');
export const SET_WARNING = Symbol('SET_WARNING');

/**
 * Save popup.
 *
 * @param moduleId {Number} Module id.
 * @param associateId {String} Associate id.
 * @param dealmakerId {String} Dealmaker id.
 * @param idList {Array(Int)} List of selected id.
 * @param afterSuccess Success callback function.
 * @returns Action.
 */
export function savePopup(moduleId, associateId = null, dealmakerId = null, idList = [], afterSuccess = () => {}) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/browse/targets/flip_to_different_team',
      body: {
        params: {
          analyst_id: associateId,
          module_id: moduleId,
          deal_maker_id: dealmakerId,
          selected_ids: idList,
        },
      },
      startType: SAVING_FORM,
      errorType: REQUEST_FLIP_API_FAILURE,
      successType: SAVED_FORM_SUCCESS,
      afterSuccess,
    },
  };
}

/**
 * Select user suggestion.
 *
 * @param id {Number} User's id.
 * @param name {String} User's name.
 * @returns Action.
 */
export function selectSuggestion(id, name, value) {
  return {
    type: SELECT_SUGGESTION,
    id,
    name,
    value,
  };
}

/**
 * Reset popup state to default.
 *
 * @returns Action.
 */
export function reset() {
  return {
    type: RESET_POPUP,
  };
}

/**
 * Change field text.
 *
 * @param name {String} Name of field.
 * @param value {String} Value.
 * @returns Action.
 */
export function changeText(name, value) {
  return {
    type: CHANGE_TEXT,
    name,
    value,
  };
}

/**
 * Load user suggestion.
 *
 * @param name {String} Name of field.
 * @param value {String} Value.
 * @returns Action.
 */
export function loadSuggestion(name, value) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users',
      query: { like: value },
      startType: LOADING_SUGGESTION,
      errorType: LOADED_SUGGESTION_FAILURE,
      successType: LOADED_SUGGESTION_SUCCESS,
    },
    name,
    requestId: uniqueId(),
  };
}

/**
 * Change field mode.
 *
 * @param name {String} Name of field.
 * @param mode {String} Mode.
 * @returns Action.
 */
export function changeMode(name, mode) {
  return {
    type: CHANGE_MODE,
    name,
    mode,
  };
}

export function setError(errors) {
  return {
    type: SET_ERROR,
    errors,
  };
}

export function setWarning(name, message) {
  return {
    type: SET_WARNING,
    message,
    name,
  };
}
