import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce, isEmpty } from 'underscore';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import moment from 'moment';

import { downloadBrowseFile } from 'src/utils/downloadFile';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import * as browseActionCreators from '../../actions/browse';
import config from '../../config';
import BrowseCheckbox from '../../components/Browse/BrowseCheckbox';
import BrowseInputTag from '../../components/Browse/BrowseInputTag';
import BrowseAutoCompleteTag from '../../components/Browse/BrowseAutoCompleteTag';
import BrowseDropDown from '../../components/Browse/BrowseDropDown';
import BrowseTable from '../../components/Browse/BrowseTable';
import BrowseActionFilter from '../../components/Browse/BrowseActionFilter';
import BrowseActivityFilter from '../../components/Browse/BrowseActivityFilter';
import BrowseFundFilter from '../../components/Browse/BrowseFundFilter';
import {
  buildRangeParam,
  buildDateParam,
  buildTagParam,
  buildActionParam,
  buildActivityParam,
  buildSortByParam,
  buildDropdownParam,
  buildCheckboxArrayParam,
} from '../../helpers/paramBuilder';
import { fetchExecutivesStatuses } from '../../actions/statuses';
import BrowseStatusesFilter from '../../components/Browse/BrowseStatusesFilter';
import { showError } from '../../utils/MessagePopup';
import { checkBrowsePageAccess } from '../../utils/checkPermissions';

const SAFE_EXPORT_EXECUTIVES_NUMBER = 300;

const executivesFilter = config.browse.getIn(['filter', 'executives']);
const columnDefs = config.browse.getIn(['table', 'columnDefs', 'executives']);
const states = fromJS(config.company.states.map(st => ({ name: st, value: st })));
const activities = config.values.get('activities');
const contextActionList = config.browse.getIn(['contextActionList', 'executives']);

export class BrowseExecutives extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
    this.handleGetSuggestionForModule = debounce(this.handleGetSuggestionForModule.bind(this), 200);
    this.handleGetSuggestionForAnalyst = debounce(this.handleGetSuggestionForAnalyst.bind(this), 200);
    this.handleGetSuggestionForDealmaker = debounce(this.handleGetSuggestionForDealmaker.bind(this), 200);
    this.handleGetSuggestionForResearcher = debounce(this.handleGetSuggestionForResearcher.bind(this), 200);
    this.handleGetSuggestionForActivityUser = debounce(this.handleGetSuggestionForActivityUser.bind(this), 200);
    this.handleGetSuggestionForNextAction = debounce(this.handleGetSuggestionForNextAction.bind(this), 200);
    this.handleGetSuggestionForActivity = debounce(this.handleGetSuggestionForActivity.bind(this), 200);
    this.handleGetSuggestionForStates = debounce(this.handleGetSuggestionForStates.bind(this), 200);
    this.handleQualitySubCheckboxChange = this.handleQualitySubCheckboxChange.bind(this);
    this.handleInterestSubCheckboxChange = this.handleInterestSubCheckboxChange.bind(this);
    this.handleRelationshipSubCheckboxChange = this.handleRelationshipSubCheckboxChange.bind(this);
    this.handleGetNextPageData = this.handleGetNextPageData.bind(this);
    this.handleSubFilterChange = this.handleSubFilterChange.bind(this);
    this.handleGetFundSuggestionForFund = debounce(this.handleGetFundSuggestionForFund.bind(this), 200);
    this.handleGetProjectSuggestionForFund = debounce(this.handleGetProjectSuggestionForFund.bind(this), 200);
    this.handleGetNextSuggestionForModule = debounce(this.handleGetNextSuggestionForModule.bind(this), 100);
    this.handleGetNextSuggestionForAnalyst = debounce(this.handleGetNextSuggestionForAnalyst.bind(this), 100);
    this.handleGetNextSuggestionForDealmaker = debounce(this.handleGetNextSuggestionForDealmaker.bind(this), 100);
    this.handleGetNextSuggestionForResearcher = debounce(this.handleGetNextSuggestionForResearcher.bind(this), 100);
    this.handleGetNextSuggestionForActivityUser = debounce(this.handleGetNextSuggestionForActivityUser.bind(this), 200);
    this.handleGetNextFundSuggestionForFund = debounce(this.handleGetNextFundSuggestionForFund.bind(this), 100);
    this.handleGetNextProjectSuggestionForFund = debounce(this.handleGetNextProjectSuggestionForFund.bind(this), 100);
    this.onToggle = this.onToggle.bind(this);
    this.handleClearFilter = this.handleClearFilter.bind(this);
    this.handleAbortRequest = this.handleAbortRequest.bind(this);
    this.keepSelection = false;
    this.buildFiltersStateParam = this.buildFiltersStateParam.bind(this);
    this.handleDownloadExport = this.handleDownloadExport.bind(this);
    this.handleSubActivityFilterChange = this.handleSubActivityFilterChange.bind(this);
  }

  componentDidMount() {
    const { onFetchStatuses, location, handleFetchCriteria } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const criteria = searchParams.get('criteria');

    onFetchStatuses();

    if (criteria) {
      handleFetchCriteria({
        name: 'executives',
        hash: criteria,
      });
    }

    if (!this.checkPageAccess()) {
      showError(this.context.openPopup, config.permisionError, () => {
        this.context.closePopup();
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const sortModel = this.props.executives.getIn(['sortBy', 'sortModel']);
    const newSortModel = nextProps.executives.getIn(['sortBy', 'sortModel']);

    if (newSortModel !== sortModel) {
      const requestParam = this.buildRequestParam(nextProps.executives);

      if (isEmpty(requestParam)) {
        return;
      }

      const sortBy = buildSortByParam(nextProps.executives);

      this.props.handleBrowse({
        name: 'executives',
        params: requestParam,
        page: 1,
        sortBy,
        keepSelection: this.keepSelection,
      });
      this.oldRequestParam = requestParam;
    }
  }

  handleChange(data) {
    if (data.filterName === 'sortBy') {
      this.keepSelection = true;
    }

    const extra = data.key ? { key: data.key } : {};

    this.props.handleUpdateFilter({
      ...extra,
      name: 'executives',
      filterName: data.filterName,
      filterData: data.filterData,
    });
  }

  handleGetSuggestionForModule({ value }) {
    this.props.handleGetModuleSuggestion('executives', value);
  }

  handleGetNextSuggestionForModule({ value }) {
    const info = this.props.executives.getIn(['module', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetModuleSuggestion('executives', value, currentPage + 1);
    }
  }

  handleGetSuggestionForAnalyst({ value }) {
    this.props.handleGetUsersSuggestion('executives', 'analyst', value, 1, true);
  }

  handleGetNextSuggestionForAnalyst({ value }) {
    const info = this.props.executives.getIn(['analyst', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetUsersSuggestion('executives', 'analyst', value, currentPage + 1, true);
    }
  }

  handleGetSuggestionForDealmaker({ value }) {
    this.props.handleGetUsersSuggestion('executives', 'dealmaker', value, 1, true);
  }

  handleGetNextSuggestionForDealmaker({ value }) {
    const info = this.props.executives.getIn(['dealmaker', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetUsersSuggestion('executives', 'dealmaker', value, currentPage + 1, true);
    }
  }

  handleGetSuggestionForResearcher({ value }) {
    this.props.handleGetUsersSuggestion('executives', 'researcher', value, 1, true);
  }

  handleGetSuggestionForActivityUser({ value }) {
    this.props.handleGetUsersSuggestion('executives', 'activityUsers', value, 1, true);
  }

  handleGetNextSuggestionForResearcher({ value }) {
    const info = this.props.executives.getIn(['researcher', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetUsersSuggestion('executives', 'researcher', value, currentPage + 1, true);
    }
  }

  handleGetNextSuggestionForActivityUser({ value }) {
    const info = this.props.executives.getIn(['activityUsers', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetUsersSuggestion('executives', 'activityUsers', value, currentPage + 1, true);
    }
  }

  handleGetSuggestionForNextAction({ value }) {
    const suggestions = activities.filter(
      activity =>
        activity
          .get('value')
          .toLowerCase()
          .indexOf(value.toLowerCase()) > -1,
    );

    this.props.handleUpdateFilter({
      name: 'executives',
      filterName: 'nextAction',
      filterData: {
        suggestions,
      },
    });
  }

  handleGetSuggestionForActivity({ value }) {
    const activities = config.values.getIn(['eventActivities', 'exec']);
    const suggestions = activities.filter(
      activity =>
        activity
          .get('value')
          .toLowerCase()
          .indexOf(value.toLowerCase()) > -1,
    );

    this.props.handleUpdateFilter({
      name: 'executives',
      filterName: 'activity',
      filterData: {
        suggestions,
      },
    });
  }

  handleGetSuggestionForStates({ value }) {
    const suggestions = states.filter(
      state =>
        state
          .get('value')
          .toLowerCase()
          .indexOf(value.toLowerCase()) > -1,
    );

    this.props.handleUpdateFilter({
      name: 'executives',
      filterName: 'companyState',
      filterData: {
        suggestions,
      },
    });
  }

  handleSubCheckboxChange(filterName, data) {
    this.props.handleUpdateFilter({
      name: 'executives',
      filterName,
      filterData: {
        [data.filterName]: data.filterData,
      },
    });
  }

  handleQualitySubCheckboxChange(data) {
    this.handleSubCheckboxChange('quality', data);
  }

  handleInterestSubCheckboxChange(data) {
    this.handleSubCheckboxChange('interest', data);
  }

  handleRelationshipSubCheckboxChange(data) {
    this.handleSubCheckboxChange('relationship', data);
  }

  handleGetNextPageData(page, keepSelection = true) {
    const requestParam = this.buildRequestParam(this.props.executives);
    const sortBy = buildSortByParam(this.props.executives);

    this.props.handleBrowse({
      name: 'executives',
      params: requestParam,
      page,
      sortBy,
      keepSelection,
    });
  }

  /**
   * Prepare moment() date object into ISO string.
   *
   * @param data Action data.
   */
  prepareActionData(data) {
    return {
      ...data,
      endDate: moment.isMoment(data.endDate) ? data.endDate.toISOString() : data.endDate,
      startDate: moment.isMoment(data.startDate) ? data.startDate.toISOString() : data.startDate,
    };
  }

  /**
   * Build all state data to restore it on a getting client.
   *
   * @param {object} props Component props.
   */
  buildFiltersStateParam(props) {
    const stateParams = {};

    // Collect needed filters if it's selected.
    if (props.getIn(['currentStatus', 'checked'])) stateParams.currentStatus = props.get('currentStatus').toJS();
    if (props.getIn(['highStatus', 'checked'])) stateParams.highStatus = props.get('highStatus').toJS();
    if (props.getIn(['module', 'checked'])) stateParams.module = props.get('module').toJS();
    if (props.getIn(['analyst', 'checked'])) stateParams.analyst = props.get('analyst').toJS();
    if (props.getIn(['researcher', 'checked'])) stateParams.researcher = props.get('researcher').toJS();
    if (props.getIn(['fund', 'checked'])) stateParams.fund = props.get('fund').toJS();
    if (props.getIn(['harvcoTags', 'checked'])) stateParams.harvcoTags = props.get('harvcoTags').toJS();
    if (props.getIn(['industry', 'checked'])) stateParams.industry = props.get('industry').toJS();
    if (props.getIn(['nextAction', 'checked'])) {
      stateParams.nextAction = this.prepareActionData(props.get('nextAction').toJS());
    }

    if (props.getIn(['activity', 'checked'])) {
      stateParams.activity = this.prepareActionData(props.get('activity').toJS());
    }
    if (props.getIn(['areaCode', 'checked'])) stateParams.areaCode = props.get('areaCode').toJS();
    if (props.getIn(['companyState', 'checked'])) stateParams.companyState = props.get('companyState').toJS();
    if (props.getIn(['keywords', 'checked'])) stateParams.keywords = props.get('keywords').toJS();
    if (props.getIn(['resume', 'checked'])) stateParams.resume = props.get('resume').toJS();
    if (props.getIn(['mbo', 'checked'])) stateParams.mbo = props.get('mbo').toJS();
    if (props.getIn(['corpBuyer', 'checked'])) stateParams.corpBuyer = props.get('corpBuyer').toJS();
    if (props.getIn(['needsFund', 'checked'])) stateParams.needsFund = props.get('needsFund').toJS();
    if (props.getIn(['peExperience', 'checked'])) stateParams.peExperience = props.get('peExperience').toJS();
    if (props.getIn(['events', 'checked'])) stateParams.events = props.get('events').toJS();
    if (props.getIn(['meet', 'checked'])) stateParams.meet = props.get('meet').toJS();
    if (props.getIn(['quality', 'checked'])) stateParams.quality = props.get('quality').toJS();
    if (props.getIn(['interest', 'checked'])) stateParams.interest = props.get('interest').toJS();
    if (props.getIn(['relationship', 'checked'])) stateParams.relationship = props.get('relationship').toJS();
    if (props.getIn(['doNotMail', 'checked'])) stateParams.doNotMail = props.get('doNotMail').toJS();
    if (props.getIn(['employed', 'checked'])) stateParams.employed = props.get('employed').toJS();

    return stateParams;
  }

  buildRequestParam(props) {
    const requestParams = {
      ...buildRangeParam(props, 'current_status_range', 'currentStatus'),
      ...buildRangeParam(props, 'high_status_range', 'highStatus'),
      ...buildTagParam(props, 'directors', 'module', 'id'),
      ...buildTagParam(props, 'analysts', 'analyst', 'id'),
      ...buildTagParam(props, 'dealmakers', 'dealmaker', 'id'),
      ...buildTagParam(props, 'researchers', 'researcher', 'id'),
      ...buildTagParam(props, 'tags', 'harvcoTags', 'value'),
      ...buildTagParam(props, 'industries', 'industry', 'value'),
      ...buildDateParam(props, 'next_action_dates', 'nextAction'),
      ...buildActionParam(props, 'next_actions', 'nextAction'),
      ...buildTagParam(props, 'area_codes', 'areaCode', 'value'),
      ...buildTagParam(props, 'states', 'companyState', 'value'),
      ...buildTagParam(props, 'keywords', 'keywords', 'value'),
      ...buildDropdownParam(props, 'resume', 'resume'),
      ...buildDropdownParam(props, 'mbo', 'mbo'),
      ...buildDropdownParam(props, 'corp_buyer', 'corpBuyer'),
      ...buildDropdownParam(props, 'needs_fund', 'needsFund'),
      ...buildDropdownParam(props, 'pe_experience', 'peExperience'),
      ...buildDropdownParam(props, 'events', 'events'),
      ...buildDropdownParam(props, 'meet', 'meet'),
      ...buildCheckboxArrayParam(props, 'quality', 'quality', 'value'),
      ...buildCheckboxArrayParam(props, 'interest', 'interest', 'value'),
      ...buildCheckboxArrayParam(props, 'relationship', 'relationship', 'value'),
      ...buildDropdownParam(props, 'do_not_mail', 'doNotMail'),
      ...buildDropdownParam(props, 'employed', 'employed'),
    };

    if (props.getIn(['activity', 'lastActivity'])) {
      Object.assign(requestParams, buildDateParam(props, 'last_action_dates', 'activity'));
      Object.assign(requestParams, buildActionParam(props, 'last_actions', 'activity'));
    } else {
      Object.assign(requestParams, buildActivityParam(props, 'activity', 'activity'));
    }

    if (Array.isArray(requestParams.activity)) {
      const users = buildTagParam(props, 'activityUsers', 'activityUsers', 'id');

      if (users) {
        requestParams.activity.push({
          users: users.activityUsers,
        });
      }
    }

    if (props.getIn(['activity', 'projectType', 'checked'])) {
      if (Array.isArray(requestParams.activity)) {
        const { activity } = requestParams;

        activity.push({
          project_type: props.getIn(['activity', 'projectType', 'selected', 'value']),
        });
        Object.assign(requestParams, { activity });
      }
    }

    if (props.getIn(['activity', 'statusChanged'])) {
      if (Array.isArray(requestParams.activity)) {
        const statusChangedFromValues = props.getIn(['activity', 'statusChangedFrom', 'text']).toJS();
        const statusChangedToValues = props.getIn(['activity', 'statusChangedTo', 'text']).toJS();
        const activity = requestParams.activity.slice();

        activity.push({
          status_changed_from: {
            min: statusChangedFromValues.min,
            max: statusChangedFromValues.max,
          },
        });
        activity.push({
          status_changed_to: {
            min: statusChangedToValues.min,
            max: statusChangedToValues.max,
          },
        });
        Object.assign(requestParams, { activity });
      }
    }

    if (props.getIn(['fund', 'checked'])) {
      const selectedListParam = props.getIn(['fund', 'selectedListParam']);

      if (selectedListParam.size > 0) {
        requestParams.funds = selectedListParam.toJS();
      }
    }

    return requestParams;
  }

  handleSubFilterChange(data) {
    this.props.handleUpdateExecutivesFundFilter(data);
  }

  handleSubActivityFilterChange(data) {
    this.props.handleUpdateExecutivesActivityFilter(data);
  }

  handleGetFundSuggestionForFund({ value }) {
    this.props.handleGetFundSuggestion(value);
  }

  handleGetNextFundSuggestionForFund({ value }) {
    const info = this.props.executives.getIn(['fund', 'fund', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetFundSuggestion(value, currentPage + 1);
    }
  }

  handleGetProjectSuggestionForFund({ value }) {
    const fundSelected = this.props.executives.getIn(['fund', 'fund', 'selected']);

    if (fundSelected) {
      this.props.handleGetFundProjectSuggestion(fundSelected.get('id'), value);
    }
  }

  handleGetNextProjectSuggestionForFund({ value }) {
    const info = this.props.executives.getIn(['fund', 'project', 'pagination']);
    const currentPage = info.get('currentPage', null);
    const fundSelected = this.props.executives.getIn(['fund', 'fund', 'selected']);

    if (fundSelected && currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetFundProjectSuggestion(fundSelected.get('id'), value, currentPage + 1);
    }
  }

  handleDownloadExport(idList, params) {
    const url = `${config.API_BASE_URL}/api/v1/browse/executives/export`;

    if (idList.length > SAFE_EXPORT_EXECUTIVES_NUMBER) {
      return this.props.handleExportWithSearchCriteria(url, idList, params);
    }

    downloadBrowseFile(url, { selected_ids: idList });
  }

  handleDoubleClickRow(row) {
    window.open(`/contact/${row.data.id}`, '_blank');
  }

  onToggle() {
    this.props.updateTabVisibility({
      field: 'executives.showTabControls',
      value: !this.props.executives.get('showTabControls'),
    });
  }

  handleClearFilter(name) {
    this.props.handleClearFilter({ name });
  }

  handleAbortRequest(name) {
    this.props.handleAbortRequest(name);
  }

  //* Check whether current user has access to current tab or not. */
  checkPageAccess() {
    return checkBrowsePageAccess(this.props.currentUser, 'executives');
  }

  render() {
    const {
      statuses,
      executives,
      handleExecutivesAddTag,
      massSelectRows,
      handleTargetSetNextActions,
      handleTargetPushNextActionsDates,
      handleSaveAddUpdateBuyer,
      handleTargetDelete,
      handleUpdateQueryResult,
      ...rest
    } = this.props;

    const controlClassName = classNames('BrowseControls', { hidden: !executives.get('showTabControls') });

    const params = {
      requestParams: this.buildRequestParam(executives),
      stateParams: this.buildFiltersStateParam(executives),
    };

    const hasParams = !isEmpty(params.requestParams);

    const hasPageAccess = this.checkPageAccess();

    return (
      <div className="tab-pane active" id="browse-executives" role="tabpanel">
        {hasPageAccess && (
          <div className="BrowseTabs-inner">
            <BrowseTable
              columnDefs={columnDefs}
              contextActionList={contextActionList}
              data={executives}
              hasParams={hasParams}
              name="executives"
              onAbortRequest={this.handleAbortRequest}
              onAddTag={handleExecutivesAddTag}
              onChange={this.handleChange}
              onClearFilter={this.handleClearFilter}
              onDelete={handleTargetDelete}
              onDoubleClickRow={this.handleDoubleClickRow}
              onDownloadExport={this.handleDownloadExport}
              onGetNextPageData={this.handleGetNextPageData}
              onGetPageData={this.handleGetNextPageData}
              onPushNextActionsDates={handleTargetPushNextActionsDates}
              onSaveAddUpdateBuyer={handleSaveAddUpdateBuyer}
              onSelectRows={massSelectRows}
              onSetNextActions={handleTargetSetNextActions}
              onToggle={this.onToggle}
              onUpdateQueryResult={handleUpdateQueryResult}
              requestParams={params.requestParams}
              stateParams={params.stateParams}
              {...rest}
            />
            <div className={controlClassName}>
              <BrowseStatusesFilter
                filter={executivesFilter.get('currentStatus')}
                info={executives.get('currentStatus')}
                onChange={this.handleChange}
                statuses={statuses}
              />
              <BrowseStatusesFilter
                filter={executivesFilter.get('highStatus')}
                info={executives.get('highStatus')}
                onChange={this.handleChange}
                statuses={statuses}
              />
              <BrowseAutoCompleteTag
                filter={executivesFilter.get('module')}
                info={executives.get('module')}
                onChange={this.handleChange}
                onGetNextSuggestion={this.handleGetNextSuggestionForModule}
                onGetSuggestion={this.handleGetSuggestionForModule}
              />
              <BrowseAutoCompleteTag
                filter={executivesFilter.get('analyst')}
                info={executives.get('analyst')}
                onChange={this.handleChange}
                onGetNextSuggestion={this.handleGetNextSuggestionForAnalyst}
                onGetSuggestion={this.handleGetSuggestionForAnalyst}
              />
              <BrowseAutoCompleteTag
                filter={executivesFilter.get('dealmaker')}
                info={executives.get('dealmaker')}
                onChange={this.handleChange}
                onGetNextSuggestion={this.handleGetNextSuggestionForDealmaker}
                onGetSuggestion={this.handleGetSuggestionForDealmaker}
              />
              <BrowseAutoCompleteTag
                filter={executivesFilter.get('researcher')}
                info={executives.get('researcher')}
                onChange={this.handleChange}
                onGetNextSuggestion={this.handleGetNextSuggestionForResearcher}
                onGetSuggestion={this.handleGetSuggestionForResearcher}
              />
              <BrowseFundFilter
                filter={executivesFilter.get('fund')}
                info={executives.get('fund')}
                onChange={this.handleChange}
                onGetFundSuggestion={this.handleGetFundSuggestionForFund}
                onGetNextFundSuggestion={this.handleGetNextFundSuggestionForFund}
                onGetNextProjectSuggestion={this.handleGetNextProjectSuggestionForFund}
                onGetProjectSuggestion={this.handleGetProjectSuggestionForFund}
                onSubFilterChange={this.handleSubFilterChange}
              />
              <BrowseInputTag
                filter={executivesFilter.get('harvcoTags')}
                info={executives.get('harvcoTags')}
                onChange={this.handleChange}
                submitOnEnter
              />
              <BrowseInputTag
                filter={executivesFilter.get('industry')}
                info={executives.get('industry')}
                onChange={this.handleChange}
                isBIC
                submitOnEnter
              />
              <BrowseActionFilter
                filter={executivesFilter.get('nextAction')}
                info={executives.get('nextAction')}
                onChange={this.handleChange}
                onGetSuggestion={this.handleGetSuggestionForNextAction}
              />
              <BrowseActivityFilter
                filter={executivesFilter.get('activity')}
                info={executives.get('activity')}
                onChange={this.handleChange}
                onGetActivityUserSuggestion={this.handleGetSuggestionForActivityUser}
                onGetNextActivityUserSuggestion={this.handleGetNextSuggestionForActivityUser}
                onGetSuggestion={this.handleGetSuggestionForActivity}
                onSubActivityFilterChange={this.handleSubActivityFilterChange}
                projectTypeFilter={executivesFilter.get('projectType')}
                projectTypeInfo={executives.getIn(['activity', 'projectType'])}
                statuses={statuses}
                usersFilter={executivesFilter.getIn(['activity', 'activityUsers'])}
                usersInfo={executives.get('activityUsers')}
              />
              <BrowseInputTag
                filter={executivesFilter.get('areaCode')}
                info={executives.get('areaCode')}
                onChange={this.handleChange}
                submitOnEnter
              />
              <BrowseAutoCompleteTag
                filter={executivesFilter.get('companyState')}
                info={executives.get('companyState')}
                onChange={this.handleChange}
                onGetSuggestion={this.handleGetSuggestionForStates}
              />
              <BrowseInputTag
                filter={executivesFilter.get('keywords')}
                info={executives.get('keywords')}
                onChange={this.handleChange}
                submitOnEnter
              />
              <BrowseDropDown
                filter={executivesFilter.get('resume')}
                info={executives.get('resume')}
                onChange={this.handleChange}
              />
              <BrowseDropDown
                filter={executivesFilter.get('mbo')}
                info={executives.get('mbo')}
                onChange={this.handleChange}
              />
              <BrowseDropDown
                filter={executivesFilter.get('corpBuyer')}
                info={executives.get('corpBuyer')}
                onChange={this.handleChange}
              />
              <BrowseDropDown
                filter={executivesFilter.get('needsFund')}
                info={executives.get('needsFund')}
                onChange={this.handleChange}
              />
              <BrowseDropDown
                filter={executivesFilter.get('peExperience')}
                info={executives.get('peExperience')}
                onChange={this.handleChange}
              />
              <BrowseDropDown
                filter={executivesFilter.get('events')}
                info={executives.get('events')}
                onChange={this.handleChange}
              />
              <BrowseDropDown
                filter={executivesFilter.get('meet')}
                info={executives.get('meet')}
                onChange={this.handleChange}
              />
              <BrowseCheckbox
                filter={executivesFilter.get('quality')}
                info={executives.get('quality')}
                onChange={this.handleChange}
              >
                <div className="BrowseControl-content BrowseControl-content--checkbox">
                  <BrowseCheckbox
                    filter={executivesFilter.getIn(['quality', 'wcm'])}
                    info={executives.getIn(['quality', 'wcm'])}
                    onChange={this.handleQualitySubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={executivesFilter.getIn(['quality', 'solid'])}
                    info={executives.getIn(['quality', 'solid'])}
                    onChange={this.handleQualitySubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={executivesFilter.getIn(['quality', 'good'])}
                    info={executives.getIn(['quality', 'good'])}
                    onChange={this.handleQualitySubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={executivesFilter.getIn(['quality', 'goodNotVerified'])}
                    info={executives.getIn(['quality', 'goodNotVerified'])}
                    onChange={this.handleQualitySubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={executivesFilter.getIn(['quality', 'average'])}
                    info={executives.getIn(['quality', 'average'])}
                    onChange={this.handleQualitySubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={executivesFilter.getIn(['quality', 'weaker'])}
                    info={executives.getIn(['quality', 'weaker'])}
                    onChange={this.handleQualitySubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={executivesFilter.getIn(['quality', 'poor'])}
                    info={executives.getIn(['quality', 'poor'])}
                    onChange={this.handleQualitySubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={executivesFilter.getIn(['quality', 'unsure'])}
                    info={executives.getIn(['quality', 'unsure'])}
                    onChange={this.handleQualitySubCheckboxChange}
                  />
                </div>
              </BrowseCheckbox>
              <BrowseCheckbox
                filter={executivesFilter.get('interest')}
                info={executives.get('interest')}
                onChange={this.handleChange}
              >
                <div className="BrowseControl-content BrowseControl-content--checkbox">
                  <BrowseCheckbox
                    filter={executivesFilter.getIn(['interest', 'very'])}
                    info={executives.getIn(['interest', 'very'])}
                    onChange={this.handleInterestSubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={executivesFilter.getIn(['interest', 'somewhat'])}
                    info={executives.getIn(['interest', 'somewhat'])}
                    onChange={this.handleInterestSubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={executivesFilter.getIn(['interest', 'no'])}
                    info={executives.getIn(['interest', 'no'])}
                    onChange={this.handleInterestSubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={executivesFilter.getIn(['interest', 'unknown'])}
                    info={executives.getIn(['interest', 'unknown'])}
                    onChange={this.handleInterestSubCheckboxChange}
                  />
                </div>
              </BrowseCheckbox>
              <BrowseCheckbox
                filter={executivesFilter.get('relationship')}
                info={executives.get('relationship')}
                onChange={this.handleChange}
              >
                <div className="BrowseControl-content BrowseControl-content--checkbox">
                  <BrowseCheckbox
                    filter={executivesFilter.getIn(['relationship', 'strong'])}
                    info={executives.getIn(['relationship', 'strong'])}
                    onChange={this.handleRelationshipSubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={executivesFilter.getIn(['relationship', 'semi'])}
                    info={executives.getIn(['relationship', 'semi'])}
                    onChange={this.handleRelationshipSubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={executivesFilter.getIn(['relationship', 'brief'])}
                    info={executives.getIn(['relationship', 'brief'])}
                    onChange={this.handleRelationshipSubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={executivesFilter.getIn(['relationship', 'no'])}
                    info={executives.getIn(['relationship', 'no'])}
                    onChange={this.handleRelationshipSubCheckboxChange}
                  />
                </div>
              </BrowseCheckbox>
              <BrowseDropDown
                filter={executivesFilter.get('doNotMail')}
                info={executives.get('doNotMail')}
                onChange={this.handleChange}
              />
              <BrowseDropDown
                filter={executivesFilter.get('employed')}
                info={executives.get('employed')}
                onChange={this.handleChange}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

BrowseExecutives.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

BrowseExecutives.propTypes = {
  handleUpdateExecutivesActivityFilter: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    executives: state.browse.get('executives'),
    statuses: state.statuses.get('executiveStatuses'),
    currentUser: state.auth.get('user'),
  };
}

const mapDispatchToProps = dispatch => ({
  onFetchStatuses: () => dispatch(fetchExecutivesStatuses()),
  ...bindActionCreators(browseActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions)(BrowseExecutives);
