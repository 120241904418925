import * as React from 'react';
import InputRange from 'react-input-range';
import Autosuggest from 'react-autosuggest';

function escapeRegexCharacters(str) {
  return str.replace(/[*+?^${}()|[\]\\]/g, '\\$&');
}

class SelectRange extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      min: this.props.rangeData[0].value,
      max: this.props.rangeData[this.props.rangeData.length - 1].value,
      suggestions: [],
    };

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.handleRangeValuesChange = this.handleRangeValuesChange.bind(this);
    this.handleSelectValueChange = this.handleSelectValueChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
    this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this);
  }

  componentDidMount() {
    const { filter, onChange, rangeData } = this.props;

    onChange({
      filterName: filter.get('name'),
      filterData: {
        values: {
          min: 0,
          max: rangeData.length - 1,
        },
        text: {
          min: rangeData[0].value,
          max: rangeData[rangeData.length - 1].value,
        },
      },
    });
  }

  componentDidUpdate(prevProps) {
    const { info } = this.props;
    const min = info.getIn(['text', 'min']);
    const max = info.getIn(['text', 'max']);

    if (prevProps.info.getIn(['text', 'min']) === '0.00' && min !== '0.00') {
      this.setState({ min });
    }

    if (prevProps.info.getIn(['text', 'max']) === '10.00' && max !== '10.00') {
      this.setState({ max });
    }
  }

  onChange(_, { newValue }, value) {
    this.setState({
      [value]: newValue,
    });
  }

  onBlur(event, _, name) {
    const existValue = this.props.rangeData.some(({ value }) => event.target.value === value);

    if (!existValue) {
      if (name === 'min') {
        this.setState({
          [name]: this.props.rangeData[0].value,
        });
        this.props.onChange({
          filterName: this.props.filter.get('name'),
          filterData: {
            values: {
              [name]: 0,
            },
            text: {
              [name]: this.props.rangeData[0].value,
            },
          },
        });
      }

      if (name === 'max') {
        this.setState({
          [name]: this.props.rangeData[this.props.rangeData.length - 1].value,
        });
        this.props.onChange({
          filterName: this.props.filter.get('name'),
          filterData: {
            values: {
              [name]: this.props.rangeData.length - 1,
            },
            text: {
              [name]: this.props.rangeData[this.props.rangeData.length - 1].value,
            },
          },
        });
      }
    } else {
      this.handleSelectValueChange(event, { suggestionValue: event.target.value }, name);
    }
  }

  handleRangeValuesChange(values) {
    const minRangeValue = values.min;
    const maxRangeValue = values.max;
    const minTextValue = this.props.rangeData[values.min].value;
    const maxTextValue = this.props.rangeData[values.max].value;

    this.setState({
      min: minTextValue,
      max: maxTextValue,
    });

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        values: {
          min: minRangeValue,
          max: maxRangeValue,
        },
        text: {
          min: minTextValue,
          max: maxTextValue,
        },
      },
    });
  }

  handleSelectValueChange(_, { suggestionValue }, name) {
    const min = this.props.info.getIn(['text', 'min']);
    const max = this.props.info.getIn(['text', 'max']);

    if (name === 'min' && Number(suggestionValue) >= Number(max)) {
      suggestionValue = max;
    }

    if (name === 'max' && Number(suggestionValue) <= Number(min)) {
      suggestionValue = min;
    }

    this.setState({
      [name]: suggestionValue,
    });

    const rangeValue = this.props.rangeData.findIndex(({ value }) => value === suggestionValue);

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        values: {
          [name]: rangeValue,
        },
        text: {
          [name]: suggestionValue,
        },
      },
    });
  }

  getSuggestions(value) {
    const { rangeData } = this.props;
    const escapedValue = escapeRegexCharacters(value.trim());
    const regex = new RegExp(`^${escapedValue}`, 'i');

    return rangeData.filter(({ value }) => regex.test(value));
  }

  onSuggestionsFetchRequested({ value }) {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  shouldRenderSuggestions() {
    return true;
  }

  render() {
    const { info, rangeData } = this.props;
    const { min, max, suggestions } = this.state;

    if (rangeData.length > 0) {
      const maxValue = rangeData.length - 1;
      const rangeValues = {
        min: info.getIn(['values', 'min']),
        max: info.getIn(['values', 'max']),
      };

      const suggestionValue = data => data.value;

      return (
        <div className={this.props.className}>
          <span>{this.props.title}</span>
          <InputRange
            maxValue={maxValue}
            minValue={0}
            onChange={this.handleRangeValuesChange}
            step={1}
            value={rangeValues}
          />
          <div className="form-inline flex-form select-range">
            <div className="form-group flex1">
              <Autosuggest
                getSuggestionValue={suggestionValue}
                inputProps={{
                  value: min,
                  onChange: (...args) => this.onChange(...args, 'min'),
                  onBlur: (...args) => this.onBlur(...args, 'min'),
                }}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={(...args) => this.handleSelectValueChange(...args, 'min')}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                renderSuggestion={suggestionValue}
                shouldRenderSuggestions={this.shouldRenderSuggestions}
                suggestions={suggestions}
                highlightFirstSuggestion
              />
            </div>
            <div className="form-group">&nbsp;-&nbsp;</div>
            <div className="form-group flex1">
              <Autosuggest
                getSuggestionValue={suggestionValue}
                inputProps={{
                  value: max,
                  onChange: (...args) => this.onChange(...args, 'max'),
                  onBlur: (...args) => this.onBlur(...args, 'max'),
                }}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={(...args) => this.handleSelectValueChange(...args, 'max')}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                renderSuggestion={suggestionValue}
                shouldRenderSuggestions={this.shouldRenderSuggestions}
                suggestions={suggestions}
                highlightFirstSuggestion
              />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default SelectRange;
