const patterns = (function(end) {
  const result = [];

  for (let i = 1900; i < end; i++) {
    result.push(new RegExp(`^${i}$`));
  }
  result.push(new RegExp(`^${end}$`));

  return result;
})(new Date().getFullYear());

export default function(descriptor) {
  const { anyOf = [] } = descriptor;

  return {
    ...descriptor,
    anyOf: [
      ...anyOf,
      ...patterns.map(pattern => ({ type: 'string', pattern })),
      { type: 'integer', minimum: 1900, maximum: new Date().getFullYear() },
    ],
  };
}
