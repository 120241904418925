import PropTypes from 'prop-types';

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';

import connectOptions, { mergeProps } from '../../utils/connectOptions';
import { assignTradeShowToTarget, findTradeShows, loadSelectedTradeShow } from '../../actions/popup';
import AssignTradeShowPopup from '../../components/Popup/AssignTradeShowPopup';
import { toJS } from '../../utils/ChangeSpy';

const AssignTradeShowPopupContainer = (props, context) => {
  const targetsIds = props.popup.getIn(['props', 'targetsIds']);

  const refreshGrid = props.popup.getIn(['props', 'refreshGrid']);

  const [suggestions, setSuggestions] = useState(List([]));

  const [suggestionText, setSuggestionText] = useState('');

  const [selectedTradeShow, setSelectedTradeShow] = useState({});
  const [selectedEventId, setSelectedEventId] = useState(null);

  const [waiting, setWaiting] = useState(false);

  const handleTradeShowSelect = (event, { suggestion }) => {
    setSuggestionText(suggestion.text);
    props.loadSelectedTradeShow(suggestion.id, action => {
      setSelectedTradeShow(action.response.data);
    });
  };

  const handleGetSuggestions = ({ value }) => {
    props.findTradeShows({
      filter: value,
      page: 1,
      afterSuccess: ({ response }) => setSuggestions(List(response.data)),
    });
  };

  const handleGetSuggestionValue = suggestion => suggestion.text;

  const handleRenderSuggestion = suggestion => <div>{suggestion.text}</div>;

  const handleSuggestionUpdate = update => {
    if ('text' in update) {
      setSuggestionText(update.text);
      setSelectedTradeShow({});
      setSelectedEventId(null);
    }
  };

  const handleEventSelected = event => {
    setSelectedEventId(event.target.value);
  };

  const handleAssign = () => {
    setWaiting(true);
    props.assignTradeShowToTarget({
      tradeShowId: selectedTradeShow.id,
      eventId: selectedEventId,
      targetsIds: toJS(targetsIds),
      afterSuccess: () => {
        context.closePopup();
        refreshGrid();
      },
    });
  };

  const handleClose = () => {
    context.closePopup();
  };

  const suggestProps = {
    getSuggestion: handleGetSuggestions,
    inputProps: {
      className: 'form-control',
      id: 'assignTradeShow',
      name: 'assignTradeShow',
      label: 'Trade Show',
      disabled: false,
      placeholder: '',
    },
    formGroupClass: 'trade-show-autocomplete',
    onSuggestionSelected: handleTradeShowSelect,
    suggestions,
    suggestsName: 'tradeShowsSuggests',
    getSuggestionValue: handleGetSuggestionValue,
    renderSuggestion: handleRenderSuggestion,
    text: suggestionText,
    onUpdateSuggestions: handleSuggestionUpdate,
  };

  return (
    <AssignTradeShowPopup
      disableAssign={!selectedEventId}
      onAssign={handleAssign}
      onClose={handleClose}
      onEventSelected={handleEventSelected}
      selectedTradeShow={selectedTradeShow}
      suggestProps={suggestProps}
      waiting={waiting}
    />
  );
};

AssignTradeShowPopupContainer.propTypes = {
  popup: PropTypes.instanceOf(Map).isRequired,
};

AssignTradeShowPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
  updatePopupFormData: PropTypes.func.isRequired,
  startPopupLoading: PropTypes.func.isRequired,
  endPopupLoading: PropTypes.func.isRequired,
};

export { AssignTradeShowPopupContainer };
export default connect(
  (state, props) => ({
    ...props,
  }),
  { findTradeShows, loadSelectedTradeShow, assignTradeShowToTarget },
  mergeProps,
  connectOptions,
)(AssignTradeShowPopupContainer);
