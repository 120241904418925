import uniqueId from '../../utils/uniqueId';
import { CALL_API } from '../../middleware/api';

export const CLEAR_USERS = Symbol('CLEAR_USERS');

export function getModuleSuggestions(value) {
  const path = `/api/v1/browse/users_by_role?role=director&like=${value}`;

  return {
    [CALL_API]: {
      method: 'get',
      path,
      unifier: `get ${path.replace(/[?&](like=.*)/, '$1')}`,
    },
    questId: uniqueId(),
  };
}

/**
 * Search users by role.
 *
 * @param params Autocomplete params.
 * @param {string} params.filter Search value.
 * @param {Function} params.afterSuccess {Function}.
 * @param {object} role User role.
 */
export function getUserSuggestionsByRole({ filter, page, afterSuccess }, role) {
  return {
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users_by_role',
      unifier: params => `${params.path}`,
      query: {
        role,
        like: filter,
        page,
      },
      afterSuccess,
    },
    questId: uniqueId(),
  };
}

/**
 * Search users.
 *
 * @param params Autocomplete params.
 * @param {string} params.filter Search value.
 * @param {Function} params.afterSuccess {Function}.
 */
export function getUserSuggestions({ filter, page, afterSuccess }) {
  return {
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users',
      unifier: params => `${params.path}`,
      query: {
        like: filter,
        page,
      },
      afterSuccess,
    },
    questId: uniqueId(),
  };
}

/**
 * Clear suggests of users.
 */
export function clearUsers() {
  return {
    type: CLEAR_USERS,
  };
}

export function getWindwardTemplates(userId) {
  const path = `/api/v1/windward/users/${userId}/templates`;

  return {
    [CALL_API]: {
      method: 'get',
      path,
      unifier: `get ${path.replace(/[?&](like=.*)/, '$1')}`,
    },
    questId: uniqueId(),
  };
}

/**
 * Get all templates list for windward report query params.
 *
 * @param {Function} afterSuccess Callback function.
 */
export function getWindwardAllTemplates(afterSuccess) {
  const path = `/api/v1/windward/all-templates`;

  return {
    [CALL_API]: {
      method: 'get',
      path,
      afterSuccess,
    },
  };
}
