import { CALL_API } from '../middleware/api';

export const LOADED_COUNTRIES_SUCCESS = Symbol('LOADED_COUNTRIES_SUCCESS');
export const FULL_LOADED_COUNTRIES = Symbol('FULL_LOADED_COUNTRIES');

/**
 * Load countries.
 *
 * @param {number} page  Page number.
 */
export function fetch(page = 1) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/countries',
      unifier: `get countries page = ${page} /api/v1/browse/countries`,
      query: {
        page,
        limit: 100,
      },
      successType: LOADED_COUNTRIES_SUCCESS,
    },
  };
}

/**
 * Mark loaded countries fully.
 */
export function fullLoad() {
  return {
    type: FULL_LOADED_COUNTRIES,
  };
}
