import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { toJS } from 'src/utils/ChangeSpy';

const Professionals = ({ data }) => {
  const professionals = toJS(data.getIn(['professionals', 'countProfessionals']));
  const { isFetching } = toJS(data.get('professionals'));

  return (
    <div className="icon-container bg-white d-flex mb20 p20">
      {isFetching === false && (
        <>
          <div className="h5 user-chart-icon">
            <i className="fa fa-user" />
          </div>
          <div>
            <p className="h3 text-bold m0 p5">{professionals}</p>
            <p className="h5 m0 p5">Professionals</p>
          </div>
        </>
      )}
    </div>
  );
};

Professionals.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
};

export default Professionals;
