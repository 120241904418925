import PropTypes from 'prop-types';

import React from 'react';
import Immutable from 'immutable';

import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Button from '../helpers/Button';
import AutoComplete from '../helpers/AutoComplete';
import Input from '../helpers/Input';

const AddNewPlatformProjectPopup = props => {
  const {
    onCreate,
    onClose,
    onChange,
    harvcoLeadSuggestions,
    clientLeadSuggestions,
    executiveSuggestions,
    formData,
    isPopupFetching,
    isPopupFetchingError,
  } = props;
  const header = 'Create a new platform project';
  const disableCreate = !formData.get('category');
  const footer = [
    <Button key="create" className="btn-primary btn-xs" disabled={disableCreate} onClick={onCreate}>
      Create
    </Button>,
    <Button key="close" className="btn-default btn-xs" onClick={onClose}>
      Close
    </Button>,
  ];
  const loading = isPopupFetching ? (
    <div className="loading">
      <i className="fa fa-spinner fa-pulse fa-3x fa-fw login-spinner" />
    </div>
  ) : null;
  const errorContent = isPopupFetchingError ? (
    <p className="error text-danger">{isPopupFetchingError.get('message')}</p>
  ) : null;

  return (
    <Popup footer={footer} header={header} id="setNextActionPopup" loading={loading}>
      <div className="row media-450 mb20">
        <div className="col-xs-12">
          <div className="form-inline row mb5">
            <FormGroup className="col-xs-8">
              <Input
                className="input-md"
                id="category"
                label="Category"
                name="category"
                onChange={onChange}
                placeholder=""
                autoFocus
                showLabel
              />
            </FormGroup>
          </div>
          <div className="form-inline row mb5">
            <FormGroup className="col-xs-4">
              <AutoComplete {...harvcoLeadSuggestions} />
            </FormGroup>
            <FormGroup className="col-xs-4">
              <AutoComplete {...clientLeadSuggestions} />
            </FormGroup>
          </div>
          <div className="form-inline row">
            <FormGroup className="col-xs-4">
              <AutoComplete {...executiveSuggestions} />
            </FormGroup>
          </div>
        </div>
      </div>
      {errorContent}
    </Popup>
  );
};

AddNewPlatformProjectPopup.propTypes = {
  clientLeadSuggestions: PropTypes.object.isRequired,
  executiveSuggestions: PropTypes.object.isRequired,
  formData: PropTypes.instanceOf(Immutable.Map).isRequired,
  harvcoLeadSuggestions: PropTypes.object.isRequired,
  isPopupFetching: PropTypes.bool,
  isPopupFetchingError: PropTypes.instanceOf(Immutable.Map),
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default AddNewPlatformProjectPopup;
