import React from 'react';
import { Map } from 'immutable';

function PureDeepValue(Component) {
  const checkProps = [
    'value',
    'className',
    'deep',
    'prefix',
    'emptyVal',
    'name',
    'disabled',
    'autoFocus',
    'loading',
    'status',
    'isLoading',
  ];

  /**
   * DeepValue decorator.
   *
   * @param props Any extra props will passed into children.
   * @param [props.deep=false] {bool|String} If True - activate deep value find.
   *     If string - use as name of property to pass value.
   * @param [props.prefix=''] {string}.
   * @param props.name {string} Name of input value.
   * @param props.root {*} If `deep==true` should be Map.
   * @class
   */
  class DeepValueWrapped extends Component {
    shouldComponentUpdate(nextProps) {
      if (!this.props.deep) return true;
      if (checkProps.some(property => nextProps[property] !== this.props[property])) {
        return true;
      }

      if (this.props.root === nextProps.root) return false;

      const currentVal = getProperty(this.props.root, getPath(this.props.prefix, this.props.name), this.props.emptyVal);
      const nextVal = getProperty(nextProps.root, getPath(nextProps.prefix, nextProps.name), nextProps.emptyVal);

      return currentVal !== nextVal;
    }

    render() {
      const { props } = this;
      const { deep, prefix, emptyVal, ...rest } = props;
      const { name, root, ...realRest } = rest;

      if (!deep) return <Component {...rest} />;
      checkIfMap(root);
      if (prefix) {
        const realName = `${prefix}.${name}`;
        const path = prefix.split('.').concat(name);

        checkIfHasSuchPath(root, path, emptyVal);

        return <Component {...realRest} name={realName} {...getPropertyObject(deep, root, path, emptyVal)} />;
      }

      return <Component {...realRest} name={name} {...getPropertyObject(deep, root, [name], emptyVal)} />;
    }
  }

  DeepValueWrapped.defaultProps = {
    deep: false,
    emptyVal: '',
    prefix: '',
  };

  DeepValueWrapped.displayName = `PureDeepValue(${Component.displayName || Component.name})`;

  return DeepValueWrapped;
}

export { PureDeepValue };

export default function DeepValue(Component) {
  /**
   * DeepValue decorator.
   *
   * @param props Any extra props will passed into children.
   * @param [props.deep=false] {bool|String} If True - activate deep value find.
   *     If string - use as name of property to pass value.
   * @param [props.prefix=''] {string}.
   * @param props.name {string} Name of input value.
   * @param props.root {*} If `deep==true` should be Map.
   * @class
   */
  const DeepValueWrapped = props => {
    const { deep = false, prefix = '', emptyVal = '', ...rest } = props;
    const { name, root, ...realRest } = rest;

    if (!deep) return <Component {...rest} />;
    checkIfMap(root);
    if (prefix) {
      const realName = `${prefix}.${name}`;
      const path = prefix.split('.').concat(name);

      checkIfHasSuchPath(root, path, emptyVal);

      return <Component {...realRest} name={realName} {...getPropertyObject(deep, root, path, emptyVal)} />;
    }

    return <Component {...realRest} name={name} {...getPropertyObject(deep, root, [name], emptyVal)} />;
  };

  DeepValueWrapped.displayName = `PureDeepValue(${Component.displayName || Component.name})`;

  return DeepValueWrapped;
}

function checkIfMap(value) {
  if (process.env.NODE_ENV !== 'production') {
    if (!Map.isMap(value)) {
      console.error(`DeepValue decorator error.
Need Immutable.Map as 'root' property. But has something else.`);
    }
  }
}

function checkIfHasSuchPath(value, path, emptyVal) {
  if (emptyVal === false) {
    if (process.env.NODE_ENV !== 'production') {
      if (!value.hasIn(path)) {
        console.error(`DeepValue decorator error.
Need root to has "${path.join('.')}". But it has not.`);
      }
    }
  }
}

function getPropertyObject(deep, root, path, emptyVal) {
  if (typeof deep === 'string') return { [deep]: root.getIn(path, emptyVal) };

  return { value: root.getIn(path, emptyVal) };
}

function getProperty(root, path, emptyVal) {
  if (!root) return null;

  return root.getIn(path, emptyVal);
}

function getPath(prefix, name) {
  if (prefix) return prefix.split('.').concat(name);

  return [name];
}
