import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { fromJS, Map } from 'immutable';
import { connect } from 'react-redux';
import classNames from 'classnames';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import {
  revertUpdate,
  findBuyers,
  findProjects,
  handleUpdateCompany,
  findApprovalLists,
  addBuyer,
  closeValidationError,
  updateLastResearcher,
} from '../../actions/company/companyTarget';
import Popups from '../../components/Popup';
import { unwrap, getChanged } from '../../utils/ChangeSpy';
import _AutoCompleteContainer from '../AutoComplete';
import PrettyError from '../../components/decorators/PrettyError';
import DisplayError from '../../components/decorators/DisplayError';

import { basicAccess } from '../../utils/checkPermissions';

const AutoCompleteContainer = PrettyError(DisplayError(_AutoCompleteContainer));
const PREFIX = 'newBuyer.';

class AddCompanyBuyerPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.onErrorClose = this.onErrorClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSuggestChange = this.onSuggestChange.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.state = {
      isBasicAccess: false,
    };
  }

  componentDidMount() {
    const isBasicAccess = basicAccess(this.context.currentUser.getIn(['roles', 0, 'slug']));

    this.setState({ isBasicAccess });
  }

  onErrorClose(e, field) {
    this.props.closeValidationError({ field });
  }

  getChildContext() {
    return {
      onErrorClose: this.onErrorClose,
      inputErrors: this.props.inputErrors,
    };
  }

  onChange(event) {
    const { name, value } = event.target;

    return this.props.handleUpdateCompany({ name: `newBuyer.${name}`, value });
  }

  onSuggestChange({ name, value }) {
    return this.props.handleUpdateCompany({ name, value });
  }

  onCreate() {
    const { companyId, updateLastResearcher } = this.props;
    const { isBasicAccess } = this.state;
    const body = getChanged(this.props.buyer);

    body.currentStatus = body.currentStatus || '0.00';
    body.highStatus = body.highStatus || '0.00';
    this.props.addBuyer({
      companyId,
      body,
      afterSuccess: () => {
        this.onClose();
        if (isBasicAccess) {
          updateLastResearcher(this.context.currentUser.get('userName'));
        }
      },
    });
  }

  onClose() {
    this.props.revertUpdate({ field: 'newBuyer' });
    this.context.closePopup();
  }

  getSuggests() {
    return {
      suggestBuyer: this.getBuyerSuggests(),
      suggestProject: this.getProjectSuggests(),
      suggestApprovalList: this.getApprovalListSuggests(),
    };
  }

  getBuyerSuggests() {
    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={opts => {
          this.props.findBuyers({
            ...opts,
            field: `${PREFIX}buyerName`,
          });
        }}
        highlightFirst={false}
        idName={`${PREFIX}buyerId`}
        inputProps={{ name: `${PREFIX}buyerName` }}
        name={`${PREFIX}buyerName`}
        rootPath={['targetCompany', 'target']}
        suggestsName={`${PREFIX}buyerSuggests`}
        valueName={`${PREFIX}buyerName`}
      />
    );
  }

  getProjectSuggests() {
    const buyerId = unwrap(this.props.buyer.getIn(['buyerId']));

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={opts => {
          this.props.findProjects({
            ...opts,
            field: `${PREFIX}dsplProjectCategory`,
            buyerIndex: 'newBuyer',
            buyerId,
          });
        }}
        highlightFirst={false}
        idName={`${PREFIX}projectId`}
        inputProps={{
          name: `${PREFIX}dsplProjectCategory`,
          disabled: !buyerId,
        }}
        name={`${PREFIX}dsplProjectCategory`}
        render={suggestion => {
          const className = classNames({ 'text-muted': !suggestion.isActive });

          return <span className={className}>{suggestion.text}</span>;
        }}
        rootPath={['targetCompany', 'target']}
        suggestsName={`${PREFIX}projectSuggests`}
        valueName={`${PREFIX}dsplProjectCategory`}
        suggestIfEmpty
      />
    );
  }

  getApprovalListSuggests() {
    const buyerId = unwrap(this.props.buyer.getIn(['buyerId']));
    const projectId = unwrap(this.props.buyer.getIn(['projectId']));

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={opts => {
          this.props.findApprovalLists({
            ...opts,
            field: `${PREFIX}dsplApplistLabel`,
            buyerIndex: 'newBuyer',
            buyerId,
            projectId,
          });
        }}
        highlightFirst={false}
        idName={`${PREFIX}approvalListId`}
        inputProps={{ name: `${PREFIX}dsplApplistLabel`, disabled: !projectId }}
        name={`${PREFIX}dsplApplistLabel`}
        rootPath={['targetCompany', 'target']}
        suggestsName={`${PREFIX}approveSuggests`}
        valueName={`${PREFIX}dsplApplistLabel`}
      />
    );
  }

  render() {
    const { children, isValid, buyer, statuses } = this.props;

    const buyerId = unwrap(buyer.getIn(['buyerId']));
    const projectId = unwrap(buyer.getIn(['projectId']));

    return (
      <div>
        <Popups.AddCompanyBuyerPopup
          buyer={buyer}
          statuses={statuses}
          {...this.getSuggests()}
          isValid={isValid && buyerId && projectId}
          onChange={this.onChange}
          onClose={this.onClose}
          onCreate={this.onCreate}
        />
        {children}
      </div>
    );
  }
}

AddCompanyBuyerPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
};

AddCompanyBuyerPopupContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map).isRequired,
};

function mapStateToProps(state, props) {
  const companyId = props.popup.getIn(['props', 'companyId']);

  return {
    ...props,
    companyId,
    isValid: state.targetCompany.target.get('newBuyerIsValid', false),
    inputErrors: state.targetCompany.target.get('newBuyerInputErrors', Map()),
    buyer: state.targetCompany.target.get('newBuyer'),
    statuses: fromJS(state.statuses.get('targetStatuses')),
  };
}

export { AddCompanyBuyerPopupContainer };
export default connect(
  mapStateToProps,
  {
    findBuyers,
    findProjects,
    handleUpdateCompany,
    findApprovalLists,
    addBuyer,
    closeValidationError,
    revertUpdate,
    updateLastResearcher,
  },
  mergeProps,
  connectOptions,
)(AddCompanyBuyerPopupContainer);
