import { fromJS } from 'immutable';

import * as ActionType from '../actions/popup';
import isObject from '../utils/isObject';

const defaultState = fromJS({
  name: null,
  props: {},
  isPopupFetching: false,
  isPopupFetchingError: null,
  formData: {},
});

function removeOldSuggestions(state, formData) {
  return Object.keys(formData).reduce((result, key) => {
    if (!result) return result;
    if (!result.has) return result;
    if (!result.has(key)) return result;
    switch (key) {
      case 'suggest':

      // fallthrough
      case 'suggests':

      // fallthrough
      case 'suggestion':

      // fallthrough
      case 'suggestions':
        return result.delete(key);

      default:
        if (isObject(formData[key])) return state.update(key, state => removeOldSuggestions(state, formData[key]));

        return result;
    }
  }, state);
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case ActionType.SHOW_POPUP:
      return state.merge({ name: action.name, props: action.props });

    case ActionType.HIDE_POPUP:
      return defaultState;

    case ActionType.FETCHING_POPUP:
      return state.merge({
        isPopupFetching: true,
        isPopupFetchingError: null,
      });

    case ActionType.FETCHING_POPUP_SUCCESS:
      return state.merge({
        isPopupFetching: false,
      });

    case ActionType.FETCHING_POPUP_FAILURE:
      return state.merge({
        isPopupFetching: false,
        isPopupFetchingError: action.error,
      });

    case ActionType.UPDATE_POPUP_FORM_DATA: {
      const tmp = state
        .update('formData', state => removeOldSuggestions(state, action.formData))
        .mergeDeep({
          formData: action.formData,
        });

      if (action.formData.formError) {
        // Issue: empty List can't be merged
        return tmp.setIn(['formData', 'formError'], action.formData.formError);
      }

      return tmp;
    }

    default:
      return state;
  }
}
