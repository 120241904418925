import PropTypes from 'prop-types';

import React from 'react';
import { Map } from 'immutable';

import PrettyError from '../decorators/PrettyError';
import DisplayError from '../decorators/DisplayError';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Button from '../helpers/Button';
import _Input from '../helpers/Input';
import _AutoComplete from '../helpers/AutoComplete';

const yearMap = {
  value: {
    '-1': '?',
  },
};

const Input = PrettyError(DisplayError(_Input));
const AutoComplete = PrettyError(DisplayError(_AutoComplete));

const renderSuggestion = ({ text, ...rest }) => <span {...rest}>{text}</span>;

const AddPositionPopup = props => {
  const { error, onClose, isValid, executive, suggestCompany, suggestTitles, onChange, onCreate, position } = props;
  const header = 'Add a New Position at a Company';
  const footer = [
    <Button key="save" className="btn-primary btn-xs" disabled={!isValid} onClick={onCreate}>
      Save
    </Button>,
    <Button key="cancel" className="btn-default btn-xs" onClick={onClose}>
      Cancel
    </Button>,
  ];

  const suggestions = executive.get('suggests');

  const title = position.get('title', '');
  const pnl = position.get('pnl', '');
  const pnlEbitda = position.get('pnlEbitda', '');
  const startYear = position.get('startYear', '');
  const endYear = position.get('endYear', '');

  return (
    <Popup footer={footer} header={header} id="newPosition">
      <div className="row media-450">
        <FormGroup className="col-lg-6 col-xs-6">
          <AutoComplete
            addNew={suggestCompany.onAdd}
            checkOn="text"
            formGroupClass=" "
            getSuggestion={suggestCompany.onFetch}
            getSuggestionValue={suggestCompany.getValue}
            id="fdffeer"
            inputProps={{
              autoFocus: true,
              id: '123sdw3', // TODO remove this after autosuggest will not require id
              className: 'form-control input-sm',
              label: 'Company',
              placeholder: 'Company',
              name: 'newPosition.suggestCompany',
            }}
            loading={suggestCompany.loading}
            minSuggestLength={1}
            name="suggestCompany"
            onSuggestionSelected={suggestCompany.onSelect}
            onUpdateSuggestions={suggestCompany.onUpdate}
            renderSuggestion={renderSuggestion}
            suggestions={suggestions}
            text={position.get('suggestCompany', '')}
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-xs-6">
          <AutoComplete
            checkOn="text"
            formGroupClass=" "
            getSuggestion={suggestTitles.onFetch}
            getSuggestionValue={suggestTitles.getValue}
            id="fdfaqfeer"
            inputProps={{
              id: '123sddw3', // TODO remove this after autosuggest will not require id
              className: 'form-control input-sm',
              label: 'Title',
              placeholder: 'Title',
              name: 'newPosition.title',
            }}
            loading={suggestTitles.loading}
            name="title"
            onSuggestionSelected={suggestTitles.onSelect}
            onUpdateSuggestions={suggestTitles.onUpdate}
            renderSuggestion={renderSuggestion}
            suggestions={suggestions}
            text={title}
            suggestIfEmpty
          />
        </FormGroup>
      </div>
      <div className="row media-450">
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            label="P&L-Revenue"
            name="pnl"
            onChange={onChange}
            placeholder="P&L-Revenue"
            root={position}
            value={pnl}
            deep
            required
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            label="P&L-EBITDA"
            name="pnlEbitda"
            onChange={onChange}
            placeholder="P&L-EBITDA"
            root={position}
            value={pnlEbitda}
            deep
            required
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row media-450">
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            label="Start"
            name="startYear"
            onChange={onChange}
            placeholder="Start"
            root={position}
            value={startYear}
            deep
            required
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            displayMap={yearMap}
            label="End"
            name="endYear"
            onChange={onChange}
            placeholder="End"
            root={position}
            value={endYear}
            deep
            required
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row media-450">{error}</div>
    </Popup>
  );
};

AddPositionPopup.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  position: PropTypes.instanceOf(Map).isRequired,
};

export default AddPositionPopup;
