import { CALL_API } from '../../middleware/api';

export const UPDATE_FIELD = Symbol('UPDATE_FIELD');
export const SAVE_COMPANY = Symbol('SAVE_COMPANY');
export const SAVE_COMPANY_SUCCESS = Symbol('SAVE_COMPANY_SUCCESS');
export const SAVE_COMPANY_ERROR = Symbol('SAVE_COMPANY_ERROR');
export const FETCHING_LIST_COMPANY_SUCCESS = Symbol('FETCHING_LIST_COMPANY_SUCCESS');
export const UPDATE_PARENT_COMPANY = Symbol('UPDATE_PARENT_COMPANY');
export const LOADED_HARVCOS = Symbol('LOADED_HARVCOS');
export const LOADED_ANALYSTS = Symbol('LOADED_ANALYSTS');

export const CLOSE_VALIDATION_ERROR = Symbol('CLOSE_VALIDATION_ERROR');

export function changeField({ name, value }) {
  return {
    type: UPDATE_FIELD,
    name,
    value,
  };
}

export function saveCompany({ body, afterSuccess, afterError }) {
  return {
    body,
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/companies',
      body: { ...body, role: 'buyer' },
      successType: SAVE_COMPANY_SUCCESS,
      afterSuccess,
      afterError,
      errorType: SAVE_COMPANY_ERROR,
    },
  };
}

export function closeValidationError({ field }) {
  return {
    type: CLOSE_VALIDATION_ERROR,
    field,
  };
}

/**
 * Update parent company info.
 *
 * @param info
 * @returns {{type: symbol, info: *}}
 */
export function handleUpdateParentCompany(info) {
  return {
    type: UPDATE_PARENT_COMPANY,
    info,
  };
}

export function handleGetSuggestion(value) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies?like=${value}`,
      successType: FETCHING_LIST_COMPANY_SUCCESS,
    },
  };
}

/**
 * Load harvcos by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findHarvcos({ filter, page, afterSuccess }) {
  return {
    filter,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
        page,
      },
      successType: LOADED_HARVCOS,
      afterSuccess,
    },
  };
}

/**
 * Load analysts by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findAnalyst({ filter, page, afterSuccess }) {
  return {
    filter,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
        page,
      },
      successType: LOADED_ANALYSTS,
      afterSuccess,
    },
  };
}
