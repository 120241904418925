import { fromJS, List } from 'immutable';
import * as ActionType from '../../../actions/company/buyer/projects';

const defaultState = fromJS({
  platform: {
    queryResults: [],
    isFetching: false,
    page: 0,
    totalPages: 0,
    sortBy: {
      sortModel: [],
    },
  },
  addon: {
    queryResults: [],
    isFetching: false,
    page: 0,
    totalPages: 0,
    sortBy: {
      sortModel: [],
    },
  },
});

const wrappedReducer = reducer;

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.FETCHING_BUYER_PROJECTS_PLATFORM:
      return state.mergeDeep({
        platform: {
          isFetching: true,
          page: action.query.page,
        },
      });

    case ActionType.FETCHING_BUYER_PROJECTS_PLATFORM_SUCCESS: {
      if (action.response.meta.pagination.currentPage === 1) {
        state = state.setIn(['platform', 'queryResults'], new List());
      }

      const startAt = state.getIn(['platform', 'queryResults']).size;

      return state.mergeDeep({
        platform: {
          queryResults: state.getIn(['platform', 'queryResults']).concat(addOrderToResponse(action, startAt)),
          isFetching: false,
          totalPages: action.response.meta.pagination.totalPages,
        },
      });
    }

    case ActionType.FETCHING_BUYER_PROJECTS_PLATFORM_FAILURE:
      return state.mergeDeep({
        platform: {
          isFetching: false,
        },
      });

    case ActionType.FETCHING_BUYER_PROJECTS_ADDON:
      return state.mergeDeep({
        addon: {
          isFetching: true,
          page: action.query.page,
        },
      });

    case ActionType.FETCHING_BUYER_PROJECTS_ADDON_SUCCESS: {
      if (action.response.meta.pagination.currentPage === 1) {
        state = state.setIn(['addon', 'queryResults'], new List());
      }

      const startAt = state.getIn(['addon', 'queryResults']).size;

      return state.mergeDeep({
        addon: {
          queryResults: state.getIn(['addon', 'queryResults']).concat(addOrderToResponse(action, startAt)),
          isFetching: false,
          totalPages: action.response.meta.pagination.totalPages,
        },
      });
    }

    case ActionType.FETCHING_BUYER_PROJECTS_ADDON_FAILURE:
      return state.mergeDeep({
        addon: {
          isFetching: false,
        },
      });

    case ActionType.UPDATE_PROJECTS:
      Object.keys(action.filterData).forEach(key => {
        if (List.isList(action.filterData[key]) || Array.isArray(action.filterData[key])) {
          state = state.setIn([action.filterName, key], new List());
        }
      });

      return state.mergeDeep({
        [action.filterName]: action.filterData,
      });

    default:
      return state;
  }
}

/**
 * Read response list and add order field.
 *
 * @param {object} action Response result.
 * @param {number} startAt Begining index.
 */
function addOrderToResponse(action, startAt = 1) {
  return fromJS(
    action.response.data.map(d => {
      startAt += 1;
      d.order = startAt;
      d.dsplCalledPercent = `${d.dsplCalledPercent}%`;
      d.dsplMailedPercent = `${d.dsplMailedPercent}%`;
      d.dsplApprovedPercent = `${d.dsplApprovedPercent}%`;

      return d;
    }),
  );
}
