import PropTypes from 'prop-types';

import React, { Component } from 'react';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import Popups from '../../components/Popup';
import { changeForm, updateStatuses, reset, validate } from '../../actions/approval/statusPopup';
import { showError, showInformation } from '../../utils/MessagePopup';
import targetStatuses from '../../utils/targetStatuses';
import { fetchTargetStatuses } from '../../actions/statuses';

/**
 * Change status popup components.
 *
 * @param props {Object}.
 * @param status {String} Value of drop down list.
 * @param approved {Boolean} Checked status of approved checkbox.
 * @param passed {Boolean} Checked status of passed checkbox.
 * @param noResponse {Boolean} Checked status of no response checkbox.
 * @param statuses {Immutable.List} List of status value in drop down list status.
 * @param errors {Immutable.List} List of error.
 * @param stage {String} Popup stage.
 * @param children {React.Component} Child elements.
 * @param props.changeForm {Function} To update changing on form.
 * @param props.updateStatuses {Function} To update new status.
 * @param props.reset {Function} To reset form.
 * @param props.validate: {Function} To validate form.
 * @class
 */
class ChangeStatusPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      statuses: Immutable.List(),
    };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.fetchTargetStatuses(({ response: { data } }) => {
      this.setState({
        statuses: targetStatuses(Immutable.fromJS(data)),
      });
    });
  }

  handleUpdate() {
    const { stage, projectId, approvalId, status, approved, passed, noResponse, validate, updateStatuses } = this.props;
    const { statuses } = this.state;
    const state = [];
    const statusText = statuses.filter(item => Number(item.get('value')) === status).getIn([0, 'text']);

    if (approved) {
      state.push('approved');
    }

    if (passed) {
      state.push('passed');
    }

    if (noResponse) {
      state.push('no response');
    }

    if (stage === 'ready') {
      updateStatuses(status, statusText, state, projectId, approvalId);
    } else {
      validate();
    }
  }

  handleClose() {
    this.props.reset();
    this.context.closePopup();
  }

  handleChange(event) {
    const { name, type, checked, value } = event.target;

    this.props.changeForm(
      name,
      type.toUpperCase() === 'CHECKBOX' ? checked : name === 'status' ? parseFloat(value) : value,
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.stage === 'saved') {
      this.context.closePopup();
      showInformation(this.context.openPopup, 'Updated successfully!', () => {
        this.props.reset();
        this.context.closePopup();
      });
    } else if (nextProps.stage === 'error') {
      this.context.closePopup();
      showError(this.context.openPopup, nextProps.errors, () => {
        this.props.reset();
        this.context.closePopup();
      });
    }
  }

  shouldShow(stage) {
    const displayingStatues = ['view', 'saving', 'validationError', 'ready'];

    return displayingStatues.indexOf(stage) >= 0;
  }

  render() {
    const { children, stage, ...rest } = this.props;
    const { statuses } = this.state;
    const disabled = stage === 'saving';
    const showSpinner = stage === 'saving';

    if (this.shouldShow(stage)) {
      return (
        <div>
          <Popups.ChangeStatusPopup
            {...rest}
            disabled={disabled}
            onChange={this.handleChange}
            onClose={this.handleClose}
            onDateChange={this.handleDateChange}
            onUpdate={this.handleUpdate}
            showSpinner={showSpinner}
            statuses={statuses}
          />
          {children}
        </div>
      );
    }

    return null;
  }
}

ChangeStatusPopupContainer.propTypes = {
  approvalId: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  approved: PropTypes.bool.isRequired,
  changeForm: PropTypes.func.isRequired,
  children: PropTypes.node,
  errors: PropTypes.instanceOf(Immutable.List).isRequired,
  noResponse: PropTypes.bool.isRequired,
  passed: PropTypes.bool.isRequired,
  projectId: PropTypes.number.isRequired,
  reset: PropTypes.func.isRequired,
  stage: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  updateStatuses: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
};

ChangeStatusPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  const popupState = state.approval.statusPopup;
  const { popup } = props;

  return {
    projectId: popup.getIn(['props', 'projectId']),
    approvalId: popup.getIn(['props', 'approvalId']),
    status: popupState.get('status'),
    approved: popupState.get('approved'),
    passed: popupState.get('passed'),
    noResponse: popupState.get('noResponse'),
    errors: popupState.get('errors'),
    stage: popupState.get('stage'),
  };
}

export { ChangeStatusPopupContainer };
export default connect(
  mapStateToProps,
  {
    changeForm,
    updateStatuses,
    reset,
    validate,
    fetchTargetStatuses,
  },
  mergeProps,
  connectOptions,
)(ChangeStatusPopupContainer);
