import PropTypes from 'prop-types';
import React from 'react';
import Immutable from 'immutable';

import Approvals from './Approvals';

const DELETE_APPROVAL_MENU_ID = 'projectApprovalTemplateContextMenu';

/**
 * Project component.
 *
 * @param props {Object}.
 * @returns {React.Component}
 * @class
 */
const MidPanel = props => {
  const {
    approvals,
    noApprovals,
    common,
    approvalColumns,
    onSort,
    onRowClick,
    onRowDoubleClick,
    onRowInsert,
    onRowChange,
    onNoApprovalDoubleClick,
  } = props;

  return (
    <div className="row approvals">
      <Approvals
        approvalColumns={approvalColumns}
        approvals={approvals}
        common={common}
        contextId={DELETE_APPROVAL_MENU_ID}
        noApprovals={noApprovals}
        onChange={onRowChange}
        onClick={onRowClick}
        onDoubleClick={onRowDoubleClick}
        onInsert={onRowInsert}
        onNoApprovalDoubleClick={onNoApprovalDoubleClick}
        onSort={onSort}
      />
    </div>
  );
};

MidPanel.propTypes = {
  approvalColumns: PropTypes.instanceOf(Immutable.List).isRequired,
  approvals: PropTypes.instanceOf(Immutable.List).isRequired,
  common: PropTypes.instanceOf(Immutable.Map).isRequired,
  noApprovals: PropTypes.instanceOf(Immutable.List).isRequired,
  onRowChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowInsert: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default MidPanel;
