import { unwrap } from './ChangeSpy';

/**
 * Configuration of company contact email fields.
 *
 * @param {Map} contact Current contact.
 * @param {List} contacts Contacts list.
 * @param {Map} activeBuyer Company active buyer.
 * @param {Map} companyInfo Company info.
 * @param {List} markets Markets list.
 * @param {List} products Products list.
 * @param {Map} company Company data.
 * @returns {object} Letter merged fields.
 */
export default function configureEmailMergedFields(
  contact,
  contacts,
  activeBuyer,
  companyInfo,
  markets,
  products,
  company,
) {
  return {
    PRIMARY_CONTACTNAME: contact.get('fullName'),
    PRIMARY_CONTACTSAL: contact.get('prefix'),
    PRIMARY_CONTACTFIRST: contact.get('firstName'),
    PRIMARY_CONTACTLAST: contact.get('lastName'),
    PRIMARY_CONTACTTITLE: contacts && unwrap(contact.getIn(['pivot', 'title'])),
    PRIMARY_CONTACTNICKNAME: contacts && unwrap(contacts.getIn([0, 'nick'])),

    ACTIVE_BUYER_NAMEABB: activeBuyer && unwrap(activeBuyer.get('dsplBuyerAbbrName')),

    DESC: companyInfo.get('description'),
    REV: companyInfo.get('revenue'),
    EMP: companyInfo.get('employees'),

    TCNAME: companyInfo && unwrap(companyInfo.getIn(['legalName'])),
    TCNAMEABB: companyInfo && unwrap(companyInfo.getIn(['abbrName'])),
    WEB: companyInfo && unwrap(companyInfo.getIn(['web'])),
    CITY: companyInfo && unwrap(companyInfo.getIn(['city'])),
    STATE: companyInfo && unwrap(companyInfo.getIn(['state'])),

    MARKET1: markets && unwrap(markets.getIn([0, 'market'])),
    MARKET2: markets && unwrap(markets.getIn([1, 'market'])),
    MARKET3: markets && unwrap(markets.getIn([2, 'market'])),

    PRODUCT1: products && unwrap(products.getIn([0, 'product'])),
    PRODUCT2: products && unwrap(products.getIn([1, 'product'])),
    PRODUCT3: products && unwrap(products.getIn([2, 'product'])),

    CLIENT_NAME: company && unwrap(company.getIn(['buyers', 0, 'dsplBuyerAbbrName'])),
  };
}

/**
 * Configuration of company executive email fields.
 *
 * @param {Map} executive Executive.
 * @param {Map} currentUser Current user.
 */
export function configureExecEmailMergedFields(executive, currentUser) {
  return {
    EXEC_FIRST: unwrap(executive.get('firstName')),
    EXEC_LAST: unwrap(executive.get('lastName')),
    EXEC_NAME: unwrap(executive.get('fullName')),
    EXEC_NICKNAME: unwrap(executive.get('nick')),
    SIGNATURE: currentUser.get('firstName'),
  };
}
