import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Map } from 'immutable';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import Table, { Column, Row } from '../../helpers/Table';
import Tooltip from '../../helpers/Tooltip';
import _Input from '../../helpers/Input';
import _Span from '../../helpers/Span';
import uniqueId from '../../../utils/uniqueId';
import { noop } from '../../../utils/noop';

const Input = _Input;
const Span = _Span;

export default function CreateTable({ namePrefix: NAME_PREFIX, tableName: TABLE_NAME, menu = true, columns: COLUMNS }) {
  const ContextMenuWrapper = onClick => {
    const ContextMenuWrapped = (value, data, isDisabled) => {
      if (!menu) {
        return (
          <span
            className={classNames({
              'text-danger': data.get('deprecated', false) || data.get('deletedAt'),
            })}
          >
            {value}
          </span>
        );
      }

      const id = uniqueId();

      return (
        <div>
          <ContextMenuTrigger disable={isDisabled} id={id}>
            {value}
          </ContextMenuTrigger>

          <ContextMenu id={id}>
            <MenuItem data={data.toJS()} onClick={onClick}>
              <i className="fa fa-remove" /> Delete
            </MenuItem>
          </ContextMenu>
        </div>
      );
    };

    return ContextMenuWrapped;
  };

  const getInput = ({ type, value, onChange, i, field, suggest, suggestCreate, columnIndex, addNew, ...rest }) => {
    if (suggest) return <div {...rest}>{suggestCreate(NAME_PREFIX, i, field, columnIndex, addNew, value)}</div>;

    return (
      <Input
        {...rest}
        autoFocus={columnIndex === 0}
        name={`${NAME_PREFIX}.${i}.${field}`}
        onChange={onChange}
        placeholder=""
        type={type}
        value={value}
      />
    );
  };

  const Wrapper = ({
    onChange,
    onDelete,
    field,
    type = 'text',
    getClassName,
    suggest,
    suggestCreate,
    columnIndex,
    editable,
    style,
    addNew,
    inputErrors,
    disabled,
  }) => (value, data, i) => {
    const handleDel = (...rest) => {
      onDelete(...rest, `${NAME_PREFIX}.${i}.${field}`, i);
    };

    let cls = '';

    if (getClassName) cls = getClassName(value, data, i);

    let content = null;

    if (editable && data.get('isEditing')) {
      content = getInput({
        value,
        type,
        onChange,
        i,
        field,
        suggest,
        suggestCreate,
        columnIndex,
        addNew,
        inputErrors,
      });
    } else {
      content = (
        <Span checkOn="children" className={cls} name={`${NAME_PREFIX}.${i}.${field}`} type={type} minSizes>
          {value}
        </Span>
      );
    }

    return (
      <div style={style}>
        <Tooltip content={inputErrors.get(`${NAME_PREFIX}.${i}.${field}`, '')} name={`${NAME_PREFIX}.${i}.${field}`}>
          {ContextMenuWrapper(handleDel)(content, data, disabled)}
        </Tooltip>
      </div>
    );
  };

  class CompanyDetailsTable extends Component {
    constructor(props, ...rest) {
      super(props, ...rest);

      this._columnsWidth = [];
      this.handleCellClick = this.handleCellClick.bind(this);

      this.tableWrapperRef = React.createRef();
      this.divRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
      return (
        nextProps.data !== this.props.data ||
        nextProps.sortedBy !== this.props.sortedBy ||
        nextProps.order !== this.props.order ||
        nextProps.canEditData !== this.props.canEditData ||
        this.context.inputErrors !== nextContext.inputErrors
      );
    }

    componentDidUpdate() {
      if (this.focusElName) {
        const input = document.getElementsByName(this.focusElName);

        if (input && input.length > 0) {
          input[0].focus();
        }
        this.focusElName = '';
      }
    }

    static calcColumnWidth(node) {
      return node.offsetWidth;
    }

    rememberWidth(node, index) {
      if (node) {
        this._columnsWidth[index] = CompanyDetailsTable.calcColumnWidth(node, index);
      }
    }

    getColumnWidth(index) {
      if (!this._columnsWidth[index]) return 'auto';

      return this._columnsWidth[index];
    }

    handleCellClick(name, NAME_PREFIX, i) {
      const canEdit = this.checkCanEditData(NAME_PREFIX);

      if (!canEdit) return;
      this.focusElName = `${NAME_PREFIX}.${i}.${name}`;
      this.props.onClick(name, NAME_PREFIX, i);
    }

    checkCanEditData(namePrefix) {
      const { disabled, canEditData } = this.props;

      if (disabled) {
        return false;
      }

      if (!canEditData && namePrefix === 'harvcoTags') {
        return true;
      }

      return canEditData;
    }

    render() {
      const {
        data,
        onAdd,
        onChange,
        onSort = noop,
        onDelete,
        extraClassName = '',
        sortedBy = null,
        order = 0,
        disabled = false,
        suggestCreate,
        loading = false,
      } = this.props;

      let suggestOne = null;

      const columns = COLUMNS.map((column, i) => {
        const {
          className: cls,
          field,
          title,
          type,
          suggest,
          editable = true,
          getClassName,
          maxWidth = 'unset',
          ...props
        } = column;
        const className = classNames(cls, {
          'plus-icon': i === 0,
        });
        const style = { maxWidth, width: this.getColumnWidth(i) };

        suggestOne = suggestOne || suggest;

        const titleContent = (function() {
          const t = i === 0 ? title || TABLE_NAME : title;

          if (field === sortedBy) {
            if (order === 1) {
              return (
                <span>
                  {t}
                  <i className="fa fa-caret-down" />
                </span>
              );
            }

            return (
              <span>
                {t}
                <i className="fa fa-caret-up" />
              </span>
            );
          }

          return <span>{t}</span>;
        })();

        let realTitle = (
          <div ref={node => this.rememberWidth(node, i)} onClick={event => onSort(event, column)} style={style}>
            {titleContent}
          </div>
        );
        const canEdit = this.checkCanEditData(NAME_PREFIX);

        if (i === 0) {
          const buttonClasses = classNames('fa fa-plus-square fa-2x', {
            'disabled-button': !canEdit,
          });

          realTitle = (
            <div ref={node => this.rememberWidth(node, i)} onClick={event => onSort(event, column)} style={style}>
              <span>
                <span className="icon-wrapper">
                  <i
                    className={buttonClasses}
                    onClick={event => {
                      event.preventDefault();
                      if (!disabled) {
                        onAdd(event, NAME_PREFIX);
                      }
                    }}
                  />
                </span>
                {titleContent}
              </span>
            </div>
          );
        }

        const wrapperOptions = {
          editable,
          onChange,
          onDelete: (event, ...rest) => onDelete(event, NAME_PREFIX, ...rest),
          field,
          columnIndex: i,
          type,
          suggest,
          suggestCreate,
          getClassName,
          style,
          addNew: this.context && this.context.addNewIndustry,
          inputErrors: this.context.inputErrors || Map(),
          disabled: !canEdit,
        };

        return (
          <Column
            key={`column_${i}`}
            title={realTitle}
            {...props}
            className={className}
            field={field}
            style={style}
            valueWrapper={Wrapper(wrapperOptions)}
          />
        );
      });

      const rows = data.map((data, i) => (
        <Row
          key={i}
          checkAlso={['data-suggest']}
          className="clickable"
          data={data}
          data-suggest={suggestOne}
          onCellClick={name => this.handleCellClick(name, NAME_PREFIX, i)}
          onChange={onChange}
        />
      ));

      const tableClassName = 'table table-bordered table-hover';
      const formClassName = classNames('company-details__table table-responsive', extraClassName);

      return (
        <form className={formClassName}>
          <div ref={this.divRef} className="company-details__table-wrapper w100p">
            <Table className={tableClassName} loading={loading}>
              {columns}
              {rows}
            </Table>
          </div>
        </form>
      );
    }
  }

  CompanyDetailsTable.propTypes = {};
  CompanyDetailsTable.contextTypes = {
    inputErrors: PropTypes.instanceOf(Map),
  };
  if (COLUMNS.some(col => col.addNew)) {
    CompanyDetailsTable.contextTypes = {
      addNewIndustry: PropTypes.func.isRequired,
    };
  }

  return CompanyDetailsTable;
}
