import { CALL_API } from '../middleware/api';

export const FETCHING_VERIFY_EMAIL = 'FETCHING_VERIFY_EMAIL';
export const FETCHING_VERIFY_EMAIL_FAILURE = 'FETCHING_VERIFY_EMAIL_FAILURE';
export const FETCHING_VERIFY_EMAIL_SUCCESS = 'FETCHING_VERIFY_EMAIL_SUCCESS';
export const CLEAR_VERIFIED_EMAIL = 'CLEAR_VERIFIED_EMAIL';

export function verifyEmail(email) {
  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/browse/email/verify`,
      body: {
        email,
      },
      skipGlobalErrorHandler: true,
      unifier: 'post /api/v1/browse/email/verify',
      startType: FETCHING_VERIFY_EMAIL,
      errorType: FETCHING_VERIFY_EMAIL_FAILURE,
      successType: FETCHING_VERIFY_EMAIL_SUCCESS,
    },
  };
}

export function clearVerifiedEmail() {
  return {
    type: CLEAR_VERIFIED_EMAIL,
  };
}
