import { isNew } from '../../../utils/uniqueId';

export default function(detailName, body, userId) {
  return (preparing[detailName] || noop)(body, userId);
}

function noop(body) {
  return { data: body };
}

const preparing = {
  buyerIndustries(body) {
    return {
      data: body
        .filter(industry => Object.prototype.hasOwnProperty.call(industry, 'pivot'))
        .map((industry, priority) => {
          const {
            pivot: { industryId },
            notes,
            contactId,
          } = industry;

          if (!industryId) throw new Error('No industry id');

          return { industryId, priority, notes, contactId: contactId || null };
        }),
    };
  },
  industries(body) {
    return {
      industry_tags: body
        .filter(industry => Object.prototype.hasOwnProperty.call(industry, 'pivot'))
        .map((industry, priority) => {
          const {
            pivot: { industryId },
          } = industry;

          if (!industryId) throw new Error('No industry id');

          return { industryId, priority };
        }),
    };
  },
  targetMarkets(body) {
    return {
      data: body.map(entity => {
        const { market, percent = null } = entity;

        if (isNew(entity.id)) return { market, percent: percent || undefined };

        return { market, percent: percent || undefined, id: entity.id };
      }),
    };
  },
  targetProducts(body) {
    return {
      data: body.map(entity => {
        const { product, percent = null } = entity;

        if (isNew(entity.id)) return { product, percent: percent || undefined };

        return { product, percent: percent || undefined, id: entity.id };
      }),
    };
  },
  targetCustomers(body) {
    return {
      data: body.map(entity => {
        const { customer, percent = null } = entity;

        if (isNew(entity.id)) return { customer, percent: percent || undefined };

        return { customer, percent: percent || undefined, id: entity.id };
      }),
    };
  },
  targetFacilities(body) {
    return {
      data: body.reduce((ret, entity) => {
        const { sqFootage, id, facility = '' } = entity;
        const convertSqFootage = sqFootage ? sqFootage.split(',').join('') : null;

        if (isNew(id) && facility.length > 0) {
          ret.push({ facility, sqFootage: convertSqFootage });
        } else {
          ret.push({ facility, sqFootage: convertSqFootage, id });
        }

        return ret;
      }, []),
    };
  },
  targetCompetitors(body) {
    return {
      data: body.map((entity, priority) => {
        const { id, ...rest } = entity;

        if (isNew(id)) return { ...rest, priority };

        return { ...rest, priority, id };
      }),
    };
  },
  customFields(body, _userId) {
    return {
      data: body.map(entity => {
        const { id, userId, ...rest } = entity;

        if (isNew(id)) return { ...rest, userId: userId || _userId };

        return { ...rest, userId: userId || _userId, id };
      }),
    };
  },
  harvcoTags(body, _userId) {
    return {
      data: body.map(entity => {
        const { id, userId = _userId, ...rest } = entity;

        if (isNew(id)) return { ...rest, userId: userId === _userId ? userId : undefined };

        return { ...rest, userId: userId === _userId ? userId : undefined, id };
      }),
    };
  },
  tradeShowTargets(body) {
    return {
      data: body
        .filter(trade => !!trade.pivot && !isNaN(trade.pivot.tradeshowId) && !!trade.pivot.tradeshowId)
        .map(trade => ({
          tradeshow_id: trade.pivot.tradeshowId,
        })),
    };
  },
};
