import PropTypes from 'prop-types';

import React from 'react';

import LoadAnimation from '../decorators/LoadAnimation';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import _Select from '../helpers/Select';
import Button from '../helpers/Button';
import PrettyError from '../decorators/PrettyError';
import DisplayError from '../decorators/DisplayError';
import _Board from './BIC';
import { createIndustryErrorMsg } from '../../utils/industryTagsHelper';

const Select = PrettyError(DisplayError(_Select));
const Board = PrettyError(DisplayError(_Board));

const BuyerRolePopup = props => {
  const { info, onClose } = props;
  const header = 'Activate Buyer role for this company';

  const footer = [
    <Button
      key="save"
      className="btn-primary btn-xs"
      disabled={!info.get('isValid')}
      form="popup-add-buyer-role-form"
      type="submit"
    >
      Save
    </Button>,
    <Button key="close" className="btn-default btn-xs" onClick={onClose}>
      Close
    </Button>,
  ];

  return (
    <Popup footer={footer} header={header} id="newPastMain">
      <LoadableForm {...props} />
    </Popup>
  );
};

BuyerRolePopup.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

const Form = props => {
  const { info, onChange, onSave, suggestHarvco, error, businessModels, onClick, tags, onErrorClose } = props;

  const errorMsg = createIndustryErrorMsg(error);

  return (
    <form id="popup-add-buyer-role-form" onChange={onChange} onSubmit={onSave}>
      <div className="row">
        <FormGroup className="col-lg-4 col-sm-12">
          <Select
            className="input-sm"
            disabled={info.get('disabledType')}
            label="Buyer Type"
            name="buyerType"
            onChange={onChange}
            options={info.get('buyerTypes')}
            root={info}
            autoFocus
            deep
            required
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-4 col-sm-12">{suggestHarvco}</FormGroup>
      </div>
      <div className="row">
        <Board
          error={errorMsg}
          models={businessModels}
          name="industryCategoryIds"
          onClick={onClick}
          onErrorClose={onErrorClose}
          tags={tags}
          canDisplayError
        />
      </div>
    </form>
  );
};

const LoadableForm = LoadAnimation(Form);

export default BuyerRolePopup;
