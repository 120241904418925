import { fromJS, List } from 'immutable';
import { sortBy } from 'underscore';

import * as ActionTargetType from '../../../actions/company/companyTarget';
import * as ActionMarketingType from '../../../actions/company/buyer/marketing';
import * as ActionDetailType from '../../../actions/companyDetail';
import * as ActionType from '../../../actions/company/buyer/criteria';
import intNormalize from '../../decorators/intNormalize';
import handleApiError, { withStatePrefix } from '../../decorators/handleApiError';
import { autoRemoveIdWithDynPaths, autoNullIdWithDynPaths } from '../../decorators/suggest';
import getValidator from '../../../utils/getValidator';
import { uniqueNewId } from '../../../utils/uniqueId';
import {
  spy,
  setIn,
  getIn,
  setOriginalIn,
  merge,
  unwrap,
  mergeDeepToOriginal,
  toJS,
  revertToOriginal,
  revert,
} from '../../../utils/ChangeSpy';

const SCHEMA = {
  type: 'object',
};

const range = name => [`${name}High`, `${name}Low`];
const interest = name => [`${name}Interest`, `${name}Notes`];

const FIELDS_TO_SPY = [
  ...range('revenue'),
  ...range('ebitda'),
  ...range('ev'),
  ...range('equity'),
  ...interest('mfg'),
  ...interest('dist'),
  ...interest('services'),
  ...interest('retail'),
  'lbo',
  'minority',
  'mezz',
  'buyout',
  'recap',
  'takePrivate',
  'pipe',
  'turnaround',
  'rollUp',
  'carveOut',
  'europe',
  'canada',
  'asia',
  'targetCriteria',
  'buyerFocus',
  'buyerCriteriaNotes',
  'geography',
  'industryCategories',
  'businessModels',
  'details',
  'comparable',
  'comparablesIds',
  'criteriaComparablesId1',
  'criteriaComparablesId2',
  'criteriaComparablesId3',
  'criteriaComparablesId4',
  'criteriaComparablesId5',
  'comparablesSuggest1',
  'comparablesSuggest2',
  'comparablesSuggest3',
  'comparablesSuggest4',
  'comparablesSuggest5',
];

const validator = getValidator(SCHEMA);

const defaultState = spy(
  fromJS({
    loaded: false,
    isValid: true,
    order: null,
    sortBy: null,
    inputErrors: {},
    revenueHigh: '',
    revenueLow: '',
    ebitdaHigh: '',
    ebitdaLow: '',
    evHigh: '',
    evLow: '',
    equityHigh: '',
    equityLow: '',
    mfgInterest: '',
    mfgNotes: '',
    distInterest: '',
    distNotes: '',
    servicesInterest: '',
    servicesNotes: '',
    retailInterest: '',
    retailNotes: '',
    lbo: '',
    minority: '',
    mezz: '',
    buyout: '',
    recap: '',
    takePrivate: '',
    pipe: '',
    turnaround: '',
    rollUp: '',
    carveOut: '',
    europe: '',
    canada: '',
    asia: '',
    targetCriteria: '',
    buyerFocus: '',
    buyerCriteriaNotes: '',
    geography: '',
    buyerIndustries: [],
    industriesSuggests: [],
    contactsSuggests: [],
    contacts: [],
    bic: [],
    industryCategories: [],
    businessModels: [],
    details: '',
    loadingComparables: false,
    comparable: [],
    comparablesIds: [],
    criteriaComparablesId1: null,
    criteriaComparablesId2: null,
    criteriaComparablesId3: null,
    criteriaComparablesId4: null,
    criteriaComparablesId5: null,
    comparablesSuggest1: '',
    comparablesSuggest2: '',
    comparablesSuggest3: '',
    comparablesSuggest4: '',
    comparablesSuggest5: '',
  }),
  FIELDS_TO_SPY,
);

const emptyEntity = {
  buyerIndustries: fromJS({
    id: 0,
    industryLabel: '',
    pivot: { industryId: null },
    contactId: null,
    contactLabel: '',
    notes: '',
  }),
};

const ENTITY_FIELDS_TO_SPY = {
  buyerIndustries: ['industryLabel', 'notes', 'contactLabel', 'contactId', 'pivot.industryId'],
  bic: ['category', 'notes', 'contact', 'contactId'],
};

const ENTITY_VALIDATORS = {
  buyerIndustries: getValidator({
    type: 'array',
    items: {
      type: 'object',
      properties: {
        industryLabel: {
          title: 'Industry',
          description: 'should not be empty',
          type: 'string',
          minLength: 1,
        },
      },
    },
  }),
};

const buyerCheck = ({ name }) => {
  if (/\.industryLabel$/.test(name)) {
    return {
      isSuggest: true,
      namePath: name,
      idPath: name.replace(/\.industryLabel$/, '.pivot.industryId'),
    };
  }

  if (/\.contactLabel/.test(name)) {
    return {
      isSuggest: true,
      namePath: name,
      idPath: name.replace(/\.contactLabel/, '.contactId'),
    };
  }

  return { isSuggest: false };
};

let wrappedReducer = reducer; // (state, action) => checkValidity(reducer(state, action), action);

wrappedReducer = [...range('revenue'), ...range('ebitda'), ...range('ev'), ...range('equity')].reduce(
  (reducer, field) => intNormalize(field, null, ActionType.UPDATE_COMPANY, reducer),
  wrappedReducer,
);

wrappedReducer = autoRemoveIdWithDynPaths(buyerCheck, ActionType.UPDATE_COMPANY, wrappedReducer);
wrappedReducer = autoNullIdWithDynPaths(buyerCheck, ActionType.UPDATE_COMPANY, wrappedReducer);

wrappedReducer = handleApiError(ActionType.ERRORED_COMPANY_BUYER, wrappedReducer);

wrappedReducer = withStatePrefix(ActionType.ERROR_SAVE_COMPANY_CONTACT, wrappedReducer, (state, action) =>
  action.isNew ? ['newContact', 'inputErrors'] : ['contacts', state.get('openedContact'), 'inputErrors'],
);

wrappedReducer = withStatePrefix(ActionType.ERROR_SAVE_COMPANY_CONTACT_CHANNEL, wrappedReducer, (state, action) => {
  if (action.isNew) return ['newData', action.channelType, 'inputErrors'];

  return ['contacts', state.get('openedContact'), action.channelType, action.channelIndex, 'inputErrors'];
});

export default wrappedReducer;

function sortIndustries(state) {
  const sortedBy = state.get('sortBy') || 'id';
  const order = state.get('order') || 1;

  return state.update('buyerIndustries', industries =>
    industries.sort((a, b) => {
      if (!sortedBy) return 0;

      const aS = getIn(a, sortedBy);
      const bS = getIn(b, sortedBy);

      return (aS - bS || String(aS).localeCompare(bS)) * order;
    }),
  );
}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.SORT_INDUSTRIES: {
      const cur = state.get('sortBy');
      const order = state.get('order');

      if (action.field === cur) {
        if (order === -1) {
          return sortIndustries(state.set('sortBy', null));
        }

        return sortIndustries(state.set('order', -1));
      }

      return sortIndustries(state.set('sortBy', action.field).set('order', 1));
    }

    case ActionType.UPDATE_COMPANY:
      return checkValidity(changeField(state, action));

    case ActionType.REVERT_UPDATE:
      return checkValidity(revertToOriginal(state, action.field));

    case ActionDetailType.FETCHING_COMPANY_SUCCESS:
      return getContactLabels(spy(state.merge(mapResponse(action)), FIELDS_TO_SPY), true);

    // fallthrough
    case ActionMarketingType.LOADED_COMPANY_CONTACT:

    // fallthrough
    case ActionTargetType.LOADED_COMPANY_CONTACT:

    // fallthrough
    case ActionType.LOADED_COMPANY_CONTACT:
      return state.merge({
        openedContact: 0,
        contacts: getContacts(action.response),
      });

    // fallthrough
    case ActionMarketingType.DEL_COMPANY_CONTACT:

    // fallthrough
    case ActionTargetType.DEL_COMPANY_CONTACT:

    // fallthrough
    case ActionType.DEL_COMPANY_CONTACT:
      return getContactLabels(
        state
          .update('contacts', contacts => contacts.filter(contact => contact.get('id') !== action.contactId))
          .set('openedContact', 0),
      );

    case ActionType.LOADED_COMPANY_CONTACTS:
      return state.setIn(
        ['contactsSuggests'],
        state
          .get('contacts')
          .filter(
            contact =>
              contact
                .get('fullName')
                .toLowerCase()
                .indexOf(action.filter.toLowerCase()) !== -1,
          )
          .map(contact =>
            fromJS({
              text: contact.get('fullName'),
              id: contact.get('id'),
            }),
          ),
      );

    case ActionType.LOADED_COMPANY_INDUSTRIES:
      return getIndustries(state, action);

    case ActionType.LOADED_TITLES: {
      const mask = new RegExp(String(action.filter).replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1'), 'i');

      return state.setIn(
        [...action.prefix.split('.'), 'suggestions'],
        fromJS(action.response.data.filter(title => mask.test(title)).map(title => ({ text: title }))),
      );
    }

    case ActionType.OPEN_COMPANY_CONTACT:
      return state.setIn(['openedContact'], action.index);

    case ActionType.ADD_COMPANY_DETAIL_ENTITY:
      return appendDetailEntity(state, action);

    case ActionType.EDIT_COMPANY_DETAIL_ENTITY:
      return checkValidity(editDetailEntity(state, action), action);

    case ActionType.DEL_COMPANY_DETAIL_ENTITY:
      return deleteDetailEntity(state, action);

    case ActionType.SAVED_COMPANY_BUYER:
      return spy(setValueAsOriginalByMap(state, action.fields), FIELDS_TO_SPY);

    case ActionType.ERRORED_COMPANY_BUYER: {
      return state;
    }

    case ActionType.CLOSE_COMPANY_BUYER_VALIDATION_ERROR:
      return state.deleteIn(['inputErrors', action.field]).deleteIn(['newContact', 'inputErrors', action.field]);

    case ActionType.SAVED_COMPANY_CONTACT:
      return saveContact(state, action);

    case ActionType.SAVED_COMPANY_CONTACT_CHANNEL: {
      return saveContactChannel(state, action);
    }

    case ActionType.SAVED_COMPANY_ENTITYES:
      return mergeDeepToOriginal(state, { [action.entity]: action.body });

    case ActionType.LOADED_COMPANY_ENTITYES:
      return getContactLabels(state.set(action.entity, fromJS(detailMapper[action.entity](action.response.data))));

    case ActionType.DEL_CONTACT_CHANNEL:
      return delChannelFromContact(state, action);

    case ActionType.BUYER_INDUSTRIES_UPDATE: {
      const { bic, selectedBICs } = updateBIC(state, action);

      return merge(state, {
        bic,
        businessModels: fromJS(action.buyerBusinessModels),
        industryCategories: fromJS(action.buyerIndustryCategories),
        selectedBICs: fromJS(selectedBICs || []),
      });
    }

    case ActionType.SAVED_BUYER_INDUSTRIES: {
      if (action.fields) {
        return spy(setValueAsOriginalByMap(state, action.fields), FIELDS_TO_SPY);
      }

      return state;
    }

    case ActionType.EDIT_INDUSTRY_ID: {
      const index = state.getIn(['bic', 'value']).findIndex(b => b.get('industryCategoryId') === action.id);

      if (index > -1) {
        return state.setIn(['bic', 'value', index, 'edit'], true);
      }

      return state;
    }

    case ActionType.REVERT_CHANGES: {
      return revert(state);
    }

    case ActionType.FETCHING_LIST_COMPARABLES: {
      return state.merge({
        loadingComparables: true,
        comparables: [],
      });
    }

    case ActionType.FETCHING_LIST_COMPARABLES_SUCCESS:
      return state.merge({
        loadingComparables: false,
        comparables: action.response.data,
      });

    default:
      return state;
  }
}

function updateBIC(state, action) {
  let bic = state.get('bic');
  let selecteds = action.buyerIndustryCategories.map(b => ({
    ...b,
    bic: true,
  }));

  selecteds = selecteds.concat(action.buyerBusinessModels.map(b => ({ ...b, bm: true })));
  bic = filterList(bic, selecteds);

  return bic;
}

function filterList(list, selecteds) {
  let tmp = list;
  const hash = selecteds.reduce((acc, ind) => {
    acc[ind.id] = {
      industryCategoryId: ind.id,
      category: ind.category,
      contact: '',
      contactId: null,
      notes: '',
      deletedAt: ind.deletedAt,
      bm: ind.bm,
      bic: ind.bic,
    };

    return acc;
  }, {});

  tmp = tmp.reduce((a, b) => {
    const id = getIn(b, 'industryCategoryId');

    if (hash[id]) {
      delete hash[id];

      return a.push(b);
    }

    if (b.get('deprecated')) {
      return a.push(b);
    }

    return a;
  }, List());

  Object.entries(hash).forEach(([, category]) => {
    tmp = tmp.push(fromJS(spy(category, ENTITY_FIELDS_TO_SPY.bic)));
  });

  return {
    bic: tmp,
    selectedBICs: sortBy(
      tmp
        .filter(b => !b.get('deprecated'))
        .map(b => b.get('industryCategoryId'))
        .toJS(),
    ),
  };
}

function getIndustries(state, action) {
  return state.setIn(
    ['industriesSuggests'],
    action.page === 1
      ? fromJS(action.response.data)
      : state.get('industriesSuggests').concat(fromJS(action.response.data)),
  );
}

function getContactLabels(state) {
  const tmp = state.update('buyerIndustries', industries =>
    industries.map(industry => {
      const contactId = unwrap(industry.get('contactId'));
      const val = state
        .get('contacts')
        .find(contact => contact.get('id') === contactId, null, fromJS({ fullName: '' }))
        .get('fullName');

      return setOriginalIn(setIn(industry, 'contactLabel', val), 'contactLabel', val);
    }),
  );

  return tmp.update('bic', industries =>
    industries.map(industry => {
      const contactId = unwrap(industry.get('contactId'));
      const val = state
        .get('contacts')
        .find(contact => contact.get('id') === contactId, null, fromJS({ fullName: '' }))
        .get('fullName');

      return setOriginalIn(setIn(industry, 'contact', val), 'contact', val);
    }),
  );
}

function setValueAsOriginalByMap(state, map) {
  return spy(
    map.reduce((result, key) => {
      const value = getIn(result, key);

      return result.setIn(key.split('.'), value);
    }, state),
    map,
  );
}

function appendDetailEntity(state, action) {
  const { entity } = action;
  const newEntity = emptyEntity[entity].set('id', uniqueNewId());

  action.index = state.get(entity).size;

  return editDetailEntity(
    state.set(entity, state.get(entity).push(spy(newEntity, ENTITY_FIELDS_TO_SPY[entity]))),
    action,
  );
}

function editDetailEntity(state, action) {
  return state.update(action.entity, table =>
    table.map((row, index) => row.set('isEditing', index === action.index && !row.get('deprecated', false))),
  );
}

function deleteDetailEntity(state, action) {
  return state.update(action.entity, details => details.filter(row => getIn(row, 'id') !== action.id));
}

function mapResponse(action) {
  const {
    revenueHigh,
    revenueLow,
    ebitdaHigh,
    ebitdaLow,
    evHigh,
    evLow,
    equityHigh,
    equityLow,
    mfgInterest,
    mfgNotes,
    distInterest,
    distNotes,
    servicesInterest,
    servicesNotes,
    retailInterest,
    retailNotes,
    lbo,
    minority,
    mezz,
    buyout,
    recap,
    takePrivate,
    pipe,
    turnaround,
    rollUp,
    carveOut,
    europe,
    canada,
    asia,
    targetCriteria,
    buyerFocus,
    buyerCriteriaNotes,
    geography,
    details,
    comparable,
  } = action.response.data;

  return {
    revenueHigh,
    revenueLow,
    ebitdaHigh,
    ebitdaLow,
    evHigh,
    evLow,
    equityHigh,
    equityLow,
    mfgInterest,
    mfgNotes,
    distInterest,
    distNotes,
    servicesInterest,
    servicesNotes,
    retailInterest,
    retailNotes,
    lbo,
    minority,
    mezz,
    buyout,
    recap,
    takePrivate,
    pipe,
    turnaround,
    rollUp,
    carveOut,
    europe,
    canada,
    asia,
    targetCriteria,
    buyerFocus,
    buyerCriteriaNotes,
    geography,
    comparable,
    details,
    ...getDetails(action.response),
    ...getBIC(action.response),
    openedContact: 0,
    contacts: getContacts(action.response),
    loaded: true,
  };
}

function getBIC({ data }) {
  const { buyerIndustries, buyerBusinessModels, buyerIndustryCategories } = data;
  let bic = [];

  bic = bic.concat(
    buyerIndustryCategories.map(c =>
      spy(
        {
          industryCategoryId: c.id,
          category: c.category,
          contact: '',
          contactId: c.pivot.contactId,
          notes: c.pivot.notes,
          bic: true,
          deprecated: false,
          deletedAt: c.deletedAt,
        },
        ENTITY_FIELDS_TO_SPY.bic,
      ),
    ),
  );
  bic = bic.concat(
    buyerBusinessModels.map(c =>
      spy(
        {
          industryCategoryId: c.id,
          category: c.category,
          contact: '',
          contactId: c.pivot.contactId,
          notes: c.pivot.notes,
          bm: true,
          deprecated: false,
          deletedAt: c.deletedAt,
        },
        ENTITY_FIELDS_TO_SPY.bic,
      ),
    ),
  );
  bic = bic.concat(
    buyerIndustries.map(c =>
      spy(
        {
          industryCategoryId: -1 * c.id,
          category: c.industryLabel,
          contact: '',
          contactId: c.contactId,
          notes: c.notes,
          deprecated: true,
          deletedAt: '',
        },
        ENTITY_FIELDS_TO_SPY.bic,
      ),
    ),
  );

  return {
    bic,
    selectedBICs: sortBy(bic.filter(b => !b.deprecated).map(b => Number(b.industryCategoryId))),
    businessModels: buyerBusinessModels,
    industryCategories: buyerIndustryCategories,
  };
}

function getDetails({ data }) {
  const { buyerIndustries } = data;

  return {
    buyerIndustries: buyerIndustries.map(value =>
      spy({ ...value, contactLabel: '' }, ENTITY_FIELDS_TO_SPY.buyerIndustries),
    ),
  };
}

const detailMapper = {
  buyerIndustries: data => data.map(value => spy(value, ENTITY_FIELDS_TO_SPY.buyerIndustries)),
};

function clearChangedFieldError(state, action) {
  const split = action.name.split('.');

  for (let i = 0; i < split.length; i++) {
    const pre = split.slice(0, i);
    const post = split.slice(i);

    state = state.deleteIn([...pre, 'inputErrors', post.join('.')]);
  }

  const split2 = action.name.replace(/\.pivot\./, '.').split('.');

  for (let i = 0; i < split2.length; i++) {
    const pre = split2.slice(0, i);
    const post = split2.slice(i);

    state = state.deleteIn([...pre, 'inputErrors', post.join('.')]);
  }

  return state;
}

function changeField(state, action) {
  if (/Year$/.test(action.name)) {
    state = setIn(state, action.name, action.value || null);
  }

  return clearChangedFieldError(setIn(state, action.name, action.value), action);
}

function getContacts({ data }) {
  return data.entityContacts.map(contact => ({ ...contact }));
}

function checkValidity(state) {
  const plainState = toJS(state);
  const isValid = validator.isValid(plainState);

  const inputErrors = validator.getFirstError(plainState);

  const detailsValidity = Object.keys(ENTITY_VALIDATORS).reduce((res, key) => {
    res[`${key}IsValid`] = ENTITY_VALIDATORS[key].isValid(plainState[key]);
    if (res[`${key}IsValid`]) return res;

    const error = ENTITY_VALIDATORS[key].getFirstError(plainState[key]);
    const errorField = Object.keys(error)[0];

    inputErrors[`${key}.${errorField}`] = error[errorField];

    return res;
  }, {});

  return state.merge({
    ...detailsValidity,
    inputErrors,
    isValid,
  });
}

function delChannelFromContact(state, action) {
  const contactIndex = state.get('contacts').findKey(contact => contact.get('id') === action.contactId);

  if (contactIndex === undefined) return state;

  const channelIndex = state
    .getIn(['contacts', contactIndex, action.channelType])
    .findKey(channel => channel.get('id') === action.id);

  if (channelIndex === undefined) return state;

  return state.deleteIn(['contacts', contactIndex, action.channelType, channelIndex]);
}

function saveContactChannel(state, action) {
  let { channelIndex } = action;

  if (action.isNew) {
    channelIndex = state.getIn(['contacts', state.get('openedContact'), action.channelType]).size;

    const dummy = merge(defaultState.getIn(['newData', action.channelType]).set('id', action.response.id), action.body);

    state = state.setIn(['contacts', state.get('openedContact'), action.channelType, channelIndex], dummy);
  }

  const dataForMerge = {
    contacts: {
      [state.get('openedContact')]: {
        [action.channelType]: {
          [channelIndex]: action.body,
        },
      },
    },
  };

  return mergeDeepToOriginal(state, dataForMerge).set('newData', defaultState.get('newData'));
}

function saveContact(state, action) {
  if (action.isNew) return state.set('newContact', defaultState.get('newContact'));

  const contactIndex = state.get('contacts').findKey(contact => contact.get('id') === action.contactId);

  const dataForMerge = {
    contacts: {
      [contactIndex]: action.body,
    },
  };

  return mergeDeepToOriginal(state, dataForMerge).set('newContact', defaultState.get('newContact'));
}
