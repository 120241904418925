import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';

import connectOptions, { mergeProps } from '../../utils/connectOptions';
import {
  createEmailTemplates,
  saveEmailTemplates,
  deleteEmailTemplates,
  clearValidationError,
} from '../../actions/emailTemplates';
import Popups from '../../components/Popup';
import stopPropagation from '../../decorators/stopPropagation';
import confirm from '../decorators/confirm';

function isChanged(original, changed) {
  return original !== changed;
}

class AddEmailTemplatePopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      submitting: false,
      values: {
        label: props.popup.getIn(['props', 'emailTemplate', 'label'], ''),
        subject: props.popup.getIn(['props', 'emailTemplate', 'subject'], ''),
        body: props.popup.getIn(['props', 'emailTemplate', 'body'], ''),
      },
      changed: {
        label: false,
        subject: false,
        body: false,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = stopPropagation(confirm('Delete this email template?', this.handleDelete.bind(this), context));
  }

  getChildContext() {
    return {
      inputErrors: this.props.inputErrors,
    };
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { popup, clearValidationError } = this.props;
    const { changed, values } = this.state;

    clearValidationError({ field: name });

    switch (name) {
      case 'label':
        this.setState({
          values: {
            ...values,
            label: value,
          },
          changed: {
            ...changed,
            label: isChanged(popup.getIn(['props', 'emailTemplate', 'label'], ''), value),
          },
        });
        break;

      case 'subject':
        this.setState({
          values: {
            ...values,
            subject: value,
          },
          changed: {
            ...changed,
            subject: isChanged(popup.getIn(['props', 'emailTemplate', 'subject'], ''), value),
          },
        });
        break;

      default:
        this.setState({
          values: {
            ...values,
            body: value,
          },
          changed: {
            ...changed,
            body: isChanged(popup.getIn(['props', 'emailTemplate', 'body'], ''), value),
          },
        });
        break;
    }
  }

  handleCreate() {
    const { popup, createEmailTemplates, saveEmailTemplates } = this.props;
    const { label, subject, body } = this.state.values;
    const type = this.props.popup.getIn(['props', 'templateType'], '');

    const afterError = () => {
      this.setState({ submitting: false });
    };

    this.setState({ submitting: true });

    const templateId = popup.getIn(['props', 'emailTemplate', 'id']);

    if (templateId) {
      saveEmailTemplates({
        id: templateId,
        userId: popup.getIn(['props', 'userId']),
        body: {
          label,
          subject,
          body,
          type,
        },
        afterSuccess: this.handleClose,
        afterError,
      });
    } else {
      createEmailTemplates({
        userId: popup.getIn(['props', 'userId']),
        body: {
          label,
          subject,
          body,
          type,
        },
        afterSuccess: this.handleClose,
        afterError,
      });
    }
  }

  handleClose() {
    this.context.closePopup();
  }

  handleDelete() {
    const { popup, deleteEmailTemplates } = this.props;

    deleteEmailTemplates({
      id: popup.getIn(['props', 'emailTemplate', 'id']),
      userId: popup.getIn(['props', 'userId']),
      afterSuccess: this.handleClose,
    });
  }

  render() {
    const { children, popup, inputErrors } = this.props;
    const { submitting, changed, values } = this.state;
    const isValid = inputErrors && inputErrors.size === 0;

    return (
      <div>
        <Popups.AddEmailTemplatePopup
          changed={changed}
          emailTemplate={popup.getIn(['props', 'emailTemplate'])}
          headerTitle={popup.getIn(['props', 'headerTitle'])}
          isNew={popup.getIn(['props', 'isNew'])}
          isValid={isValid}
          onChange={this.handleChange}
          onClose={this.handleClose}
          onCreate={this.handleCreate}
          onDelete={this.handleDelete}
          submitting={submitting}
          values={values}
        />
        {children}
      </div>
    );
  }
}

AddEmailTemplatePopupContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

AddEmailTemplatePopupContainer.childContextTypes = {
  inputErrors: PropTypes.instanceOf(Map),
};

function mapStateToProps(state, props) {
  return {
    ...props,
    inputErrors: state.emailTemplates.get('inputErrors'),
  };
}

export { AddEmailTemplatePopupContainer };

export default connect(
  mapStateToProps,
  {
    createEmailTemplates,
    saveEmailTemplates,
    deleteEmailTemplates,
    clearValidationError,
  },
  mergeProps,
  connectOptions,
)(AddEmailTemplatePopupContainer);
