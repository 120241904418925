import { reloadEvents } from '../companyEvents';
import { CALL_API, CHAIN_API } from '../../../middleware/api';
import config from '../../../config';
import { getCompanyInfo } from '../../companyDetail';

export const LOADED_COMPANY_CONTACT = Symbol('LOADED_COMPANY_CONTACT');
export const FETCH_SUBSIDIARIES_SUCCESS = Symbol('FETCH_SUBSIDIARIES_SUCCESS');
export const UPDATE_COMPANY = Symbol('UPDATE_COMPANY');
export const REVERT_UPDATE = Symbol('REVERT_UPDATE');
export const LOADED_USERS = Symbol('LOADED_USERS');
export const SAVED_COMPANY_BUYER = Symbol('SAVED_COMPANY_BUYER');
export const ERRORED_COMPANY_BUYER = Symbol('ERRORED_COMPANY_BUYER');
export const CLOSE_COMPANY_BUYER_VALIDATION_ERROR = Symbol('CLOSE_COMPANY_BUYER_VALIDATION_ERROR');
export const CHANGE_BUYER_TYPE_SUCCESS = Symbol('CHANGE_BUYER_TYPE_SUCCESS');

/**
 * Load users by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findUsers({ filter, page, afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users_by_role',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        role: config.DIRECTOR,
        like: filter,
        limit: 100,
        page,
      },
      successType: LOADED_USERS,
      afterSuccess,
    },
  };
}

export function handleUpdateCompany(info) {
  return {
    type: UPDATE_COMPANY,
    ...info,
  };
}

export function saveInfo({ companyId, body, afterSuccess }) {
  return {
    [CHAIN_API]: [
      () => ({
        fields: Object.keys(body),
        [CALL_API]: {
          method: 'put',
          unifier: `buyer put /api/v1/companies/${companyId} {${JSON.stringify(body)}}`,
          path: `/api/v1/companies/${companyId}`,
          body,
          successType: SAVED_COMPANY_BUYER,
          afterSuccess: ({ dispatch }) => {
            dispatch(reloadEvents({ entityId: companyId }));
            if (afterSuccess) {
              afterSuccess();
            }
          },
          errorType: ERRORED_COMPANY_BUYER,
          skipGlobalErrorHandler: true,
        },
      }),
      () => getCompanyInfo({ companyId, afterSuccess: () => {} }),
    ],
  };
}

export function getSubsidiaries({ companyId }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies/${companyId}/?include=portstat`,
      successType: FETCH_SUBSIDIARIES_SUCCESS,
    },
  };
}

export function closeValidationError({ field }) {
  return {
    type: CLOSE_COMPANY_BUYER_VALIDATION_ERROR,
    field,
  };
}

export function revertUpdate({ field }) {
  return {
    type: REVERT_UPDATE,
    field,
  };
}

/**
 * Change buyer type.
 *
 * @param companyId {Number}.
 * @param buyerType {String}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function changeBuyerType({ companyId, buyerType, afterSuccess, afterError }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/companies/${companyId}/buyer_type`,
      body: { buyer_type: buyerType },
      successType: CHANGE_BUYER_TYPE_SUCCESS,
      afterSuccess,
      afterError,
    },
  };
}
