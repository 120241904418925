import React from 'react';
import { Map } from 'immutable';
import { unwrap } from '../../utils/ChangeSpy';

export default function ValueGetter(Component) {
  /**
   * ValueGetter decorator.
   *
   * @param props Any extra props will passed into children.
   * @param props.valueGetter {*} Should be Immutable.Map to activate it.
   * @class
   */
  const ValueGetter = props => {
    const { valueFrom = 'value', ...rest } = props;
    const value = props[valueFrom];

    if (!Map.isMap(value)) return <Component {...props} />;

    const realValue = unwrap(value);
    const valueProperty = { [valueFrom]: realValue };

    return <Component {...rest} {...valueProperty} />;
  };

  ValueGetter.displayName = `ValueGetter(${Component.displayName || Component.name})`;

  return ValueGetter;
}
