import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import classNames from 'classnames';
import { SortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { SortableItem } from '../../decorators/SortableItem';
import { unwrap as _unwrap } from '../../../utils/ChangeSpy';
import uniqueId from '../../../utils/uniqueId';

const blockStyle = { display: 'block' };

const unwrap = value => {
  value = _unwrap(value);
  if (value && value.get) value = value.get('name', '');

  return value;
};

const ContactContextMenuWrapper = ({ id = uniqueId(), onDel, children, data }) => (
  <div>
    <ContextMenuTrigger collect={() => data.toJS()} holdToDisplay={-1} id={id}>
      {children}
    </ContextMenuTrigger>

    <ContextMenu id={id}>
      <MenuItem onClick={onDel}>
        <i className="fa fa-remove" /> Delete
      </MenuItem>
    </ContextMenu>
  </div>
);

class ContactLabel extends PureComponent {
  render() {
    const {
      childProps: { contact, isOpen, onDel, onClick, entityId, className: cls },
      ...rest
    } = this.props;

    const calcCls = classNames({
      active: isOpen,
      'text-italic text-muted': unwrap(contact.getIn(['pivot', 'endYear'], '')),
    });

    const firstName = unwrap(contact.get('firstName', ''));
    const lastName = unwrap(contact.get('lastName', ''));
    const title = unwrap(contact.getIn(['pivot', 'title'], ' '));
    const shouldContext = unwrap(contact.getIn(['pivot', 'entityId'], ' ')) === entityId;

    if (shouldContext) {
      return (
        <li className={classNames(cls, calcCls, 'project-contact-item--sortable')} onClick={onClick} {...rest}>
          <a className="clickable" style={blockStyle}>
            <ContactContextMenuWrapper data={contact} onDel={onDel}>
              {firstName} {lastName}
              <br />
              <small>{title}</small>
            </ContactContextMenuWrapper>
          </a>
        </li>
      );
    }

    {
      const entityName = unwrap(contact.getIn(['entityName'], ' '));

      return (
        <li className={classNames(cls, calcCls)} onClick={onClick} {...rest}>
          <a className="clickable" style={blockStyle}>
            <div>
              {firstName} {lastName}
              <br />
              <small>{title}</small> <br />
              <small>{entityName}</small>
            </div>
          </a>
        </li>
      );
    }
  }
}

const SortableListItem = SortableItem(ContactLabel);

const SortableList = SortableContainer(props => {
  const { items, onClick, onDel, opened, entityId, disabled } = props;

  const labels = items.map((contactJS, i) => {
    let indexInRedux = -1;
    let contact;

    props.contacts.some((cont, i) => {
      const isSame = cont.get('id') === contactJS.id;

      if (isSame) {
        contact = cont;
        indexInRedux = i;

        return true;
      }
    });

    return (
      <SortableListItem
        key={i}
        childProps={{
          onClick: event => onClick(event, indexInRedux),
          contact,
          onDel,
          isOpen: indexInRedux === opened,
          entityId,
        }}
        disabled={disabled}
        index={i}
      />
    );
  });

  return <ul className="project-contacts__nav nav nav-pills nav-stacked list">{labels}</ul>;
});

class CompanyContactsLabels extends PureComponent {
  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  getInitialState(props) {
    return {
      draggingIndex: null,
      data: {
        items: props.contacts.toJS(),
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      data: {
        items: nextProps.contacts.toJS(),
      },
    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ data: { items } }) => ({
        data: {
          items: arrayMove(items, oldIndex, newIndex),
        },
      }),
      () => {
        if (this.state.draggingIndex === null) {
          const conts = this.props.contacts.map((c, i, contacts) =>
            contacts.find(contact => this.state.data.items[i].id === contact.get('id')),
          );

          return this.props.reorderContacts(conts);
        }
      },
    );
  };

  render() {
    const { entityId } = this.context;
    const { canEditData } = this.props;
    const { items: contacts } = this.state.data;

    return (
      <SortableList
        {...this.props}
        disabled={!canEditData}
        distance={1}
        entityId={entityId}
        items={contacts}
        lockAxis="y"
        onSortEnd={this.onSortEnd}
      />
    );
  }
}

CompanyContactsLabels.propTypes = {
  canEditData: PropTypes.bool.isRequired,
};

CompanyContactsLabels.contextTypes = {
  entityId: PropTypes.any,
};

export default CompanyContactsLabels;
