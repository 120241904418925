/**
 * Get string and return null if value is not an int number or parsed value if is number.
 *
 * @param value {String} - some string from input.
 * @returns {null|number}
 */
export function intOrNull(value) {
  const parsed = parseInt(value, 10);
  const numbered = Math.floor(Number(value));

  if (parsed !== numbered) {
    // if NaN => (NaN !== anything) && (NaN !== NaN)
    return null;
  }

  return parsed;
}

/**
 * Get string with commas.
 *
 * @param x {number} - number.
 * @returns {string}
 */
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Convert number to US locale with "$" symbol.
 *
 * @example From 1000 to $1,000.
 */
export function getFormattedToCurrency(currency) {
  return currency.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

/**
 * Convert number to US locale.
 *
 * @example From 1000 to 1,000.
 */
export function getFormattedToLocale(value) {
  return value.toLocaleString('en-US');
}

/**
 * Convert number to US locale with rounded to two decimal places.
 *
 * @example From 1000,74848993 to 1,000,75.
 */
export function getFormattedToTwoDecimalLocale(value) {
  return value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
}
