import React from 'react';

import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import Input from '../helpers/Input';
import FormGroup from '../helpers/FormGroup';

const CCVisitReportPopup = props => {
  const { onOk, onChange, dates } = props;
  const header = 'Download CC/Visit Report';
  const footer = (
    <Button className="btn-default btn-xs btn-primary" onClick={onOk} type="button">
      Download
    </Button>
  );

  return (
    <Popup footer={footer} header={header} id="CCVisitPopup">
      <p className="mb4">Date Range:</p>
      <form onSubmit={onOk}>
        <div className="row">
          <FormGroup className="col-lg-4 col-sm-12">
            <Input
              label="From"
              maxDate={dates.get('to')}
              name="popupDates.from"
              onChange={onChange}
              placeholder="From"
              type="date"
              value={dates.get('from')}
              autoFocus
              required
              showLabel
            />
          </FormGroup>

          <p className="pull-left pt17">-</p>

          <FormGroup className="col-lg-4 col-sm-12">
            <Input
              label="To"
              minDate={dates.get('from')}
              name="popupDates.to"
              onChange={onChange}
              placeholder="To"
              type="date"
              value={dates.get('to')}
              required
              showLabel
            />
          </FormGroup>
        </div>
      </form>
    </Popup>
  );
};

export default CCVisitReportPopup;
