import React, { Component } from 'react';

class TextAreaEditor extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      value: this.props.value,
    };
  }

  componentDidMount() {
    this.editor.addEventListener('keydown', this.stopNavigation);
  }

  componentWillUnmount() {
    this.editor.removeEventListener('keydown', this.stopNavigation);
  }

  stopNavigation(event) {
    if ([32, 37, 38, 39, 40, 36, 35].indexOf(event.keyCode) > -1) {
      event.stopPropagation();
    }
  }

  getValue() {
    return this.state.value;
  }

  onChange(event) {
    this.setState({
      value: event.target.value,
    });
  }

  isPopup() {
    return false;
  }

  afterGuiAttached() {
    this.editor.select();
  }

  render() {
    const { value } = this.state;

    return (
      <textarea
        ref={input => {
          this.editor = input;
        }}
        onChange={this.onChange}
        placeholder="Input..."
        value={value}
      />
    );
  }
}

export default TextAreaEditor;
