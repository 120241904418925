import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Stateless component for render custom Company cell.
 *
 * @param {object} params Params.
 * @param {object} params.data Table row data.
 */
const CompanyCell = ({ data }) => (
  <div>
    <Link target="_blank" to={`/company/${data.targetId}`}>
      {data.targetName}
    </Link>
  </div>
);

export { CompanyCell };
