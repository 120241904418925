import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';

import connectOptions, { mergeProps } from '../../../utils/connectOptions';
import config from '../../../config';
import Popups from '../../../components/Popup';
import {
  savePopup,
  selectSuggestion,
  reset,
  changeText,
  loadSuggestion,
  changeMode,
  setError,
  setWarning,
} from '../../../actions/nextAction/flipPopup';

class InternalFlipPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleSave = this.handleSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSuggestionClose = this.handleSuggestionClose.bind(this);
    this.handleSuggestionSelect = this.handleSuggestionSelect.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  handleSave() {
    const { module, associate, dealmaker, idList } = this.props;
    const refreshGrid = this.props.popup.getIn(['props', 'refreshGrid'], () => {});

    if (this.validate()) {
      this.props.savePopup(module.get('id'), associate.get('id'), dealmaker.get('id'), idList.toArray(), () => {
        refreshGrid();
        this.context.closePopup();
      });
    }
  }

  validate() {
    const { module, setError } = this.props;

    if (!module.get('id') || module.get('id') <= 0) {
      setError(['Missing Module!']);

      return false;
    }

    return true;
  }

  handleClose() {
    this.context.closePopup();
  }

  handleInputChange({ event }) {
    const { name, value = '' } = event.target;
    const { module, setWarning } = this.props;

    this.props.changeText(name, value);
    this.props.loadSuggestion(name, value);
    this.props.setError([]); // Clear error
    if (name === 'module' && !value) {
      setWarning('associate', '');
      setWarning('dealmaker', '');
    } else if (name !== 'module' && module.get('value')) {
      if (name === 'associate' && !value) {
        setWarning('associate', 'Secondary DM /Analyst/Assoc. will be emptied');
      }

      if (name === 'dealmaker' && !value) {
        setWarning('dealmaker', '3rd DM / Analyst will be cleared/emptied');
      }
    }
  }

  handleSuggestionClose({ event }) {
    this.props.changeMode(event.target.name, config.VIEW_MODE);
  }

  handleSuggestionSelect({ event, suggestion }) {
    const { name } = event.target;
    const { associate, dealmaker, setWarning } = this.props;

    this.props.selectSuggestion(suggestion.get('id'), name, suggestion.get('name'));
    if (name === 'module') {
      if (!associate.get('id')) {
        setWarning('associate', 'Secondary DM /Analyst/Assoc. will be emptied');
      }

      if (!dealmaker.get('id')) {
        setWarning('dealmaker', '3rd DM / Analyst will be cleared/emptied');
      }
    } else if ((name === 'associate' || name === 'dealmaker') && suggestion.get('id') > 0) {
      setWarning(name, '');
    }
  }

  render() {
    return (
      <div>
        <Popups.InternalFlipPopup
          {...this.props}
          onInputChange={this.handleInputChange}
          onPopupClose={this.handleClose}
          onSave={this.handleSave}
          onSuggestionClose={this.handleSuggestionClose}
          onSuggestionSelect={this.handleSuggestionSelect}
        />
      </div>
    );
  }
}

InternalFlipPopupContainer.contextTypes = {
  onClosePopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
};

InternalFlipPopupContainer.propTypes = {
  associate: PropTypes.instanceOf(Map).isRequired,
  dealmaker: PropTypes.instanceOf(Map).isRequired,
  errors: PropTypes.instanceOf(List).isRequired,
  module: PropTypes.instanceOf(Map).isRequired,
  suggestions: PropTypes.instanceOf(List).isRequired,
};

function mapStateToProps(state, props) {
  const popup = state.nextAction.flipPopup;
  const idList = props.popup.getIn(['props', 'idList']);

  return {
    idList,
    associateWarning: popup.get('associateWarning'),
    dealmakerWarning: popup.get('dealmakerWarning'),
    waiting: popup.get('waiting'),
    errors: popup.get('errors'),
    suggestions: popup.get('suggestions'),
    module: popup.get('module'),
    associate: popup.get('associate'),
    dealmaker: popup.get('dealmaker'),
  };
}

export { InternalFlipPopupContainer };
export default connect(
  mapStateToProps,
  {
    savePopup,
    selectSuggestion,
    reset,
    changeText,
    loadSuggestion,
    changeMode,
    setError,
    setWarning,
  },
  mergeProps,
  connectOptions,
)(InternalFlipPopupContainer);
