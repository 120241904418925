import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import TransactionsChart from './TransactionsChart';
import TransactionsByYearsChart from './TransactionsByYearsChart';
import ExecutiveDeals from './ExecutiveDeals';
import Professionals from './Professionals';
import TargetsApproachedByYearsChart from './TargetsApproachedByYearsChart';

const ChartsPanel = ({ data }) => (
  <>
    <TransactionsChart data={data} />
    <TransactionsByYearsChart data={data} />
    <ExecutiveDeals data={data} />
    <Professionals data={data} />
    <TargetsApproachedByYearsChart data={data} />
  </>
);

ChartsPanel.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
};

export default ChartsPanel;
