import createTable from '../../Details/CompanyDetailsTable';
import PrettyError from '../../../decorators/PrettyError';
import DisplayError from '../../../decorators/DisplayError';

export default PrettyError(
  DisplayError(
    createTable({
      namePrefix: 'bic',
      tableName: 'Industry',
      menu: false,
      columns: [{ field: 'name', title: 'Industry', editable: false }],
    }),
  ),
);
