import isFunction from '../../utils/isFunction';
import { intOrNull } from '../../utils/valuesTransformer';

const u = undefined;

/**
 * Normalize #value name in action object to int or default value.
 *
 * @param name {String} Name's name need to be changed.
 * @param [defValue=null] {Number|String|null} Default value (if not succeds to be int).
 * @param type {String|Symbol} Action type that change value.
 * @param reducer {Function}.
 * @returns {*}
 */
export default function normalize(name, defValue = null, type, reducer) {
  if (isFunction(type)) return normalize(name, u, defValue, type);

  return function(state, action) {
    if (action.type === type) {
      if (action.name === name) {
        const normalized = intOrNull(action.value);

        action.value = normalized === null ? defValue : String(normalized);
      }
    }

    return reducer(state, action);
  };
}

/**
 * Normalize #value name in action object to int or default value.
 *
 * @param regExp {RegExp} Name's name regexp need to be changed.
 * @param [defValue=null] {Number|String|null} Default value (if not succeds to be int).
 * @param type {String|Symbol} Action type that change value.
 * @param reducer {Function}.
 * @returns {*}
 */
export function normalizeByRegexp(regExp, defValue = null, type, reducer) {
  if (isFunction(type)) return normalizeByRegexp(regExp, u, defValue, type);

  return function(state, action) {
    if (action.type === type) {
      if (regExp.test(action.name)) {
        const normalized = intOrNull(action.value);

        action.value = normalized === null ? defValue : String(normalized);
      }
    }

    return reducer(state, action);
  };
}
