import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import { random } from 'underscore';

import DropDown, { DropDownElement } from '../../components/helpers/DropDown';
import BrowseCheckbox from './BrowseCheckbox';

class BrowseDropDown extends PureComponent {
  constructor(props) {
    super(props);

    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.componentId = `dropdown${random(10000, 99999)}`;
  }

  handleDropDownChange(event) {
    const { value } = event.target;

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        selected: {
          name: value,
          value,
        },
      },
    });
  }

  render() {
    const { filter, info } = this.props;

    const dropdownContent = filter.get('options').map((item, i) => (
      <DropDownElement key={i} value={item.get('value')}>
        {item.get('name')}
      </DropDownElement>
    ));

    return (
      <BrowseCheckbox {...this.props}>
        <DropDown
          btnContent={info.get('selected')}
          className="btn btn-default btn-xs"
          id={this.componentId}
          name={this.componentId}
          onChange={this.handleDropDownChange}
        >
          {dropdownContent}
        </DropDown>
      </BrowseCheckbox>
    );
  }
}
BrowseDropDown.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BrowseDropDown;
