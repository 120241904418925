import React from 'react';
import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import Input from '../helpers/Input';
import Select from '../helpers/Select';

const StatusCodePopup = props => {
  const { onSave, onChange, buyer, statuses, statusUpdate } = props;
  const spinner = statusUpdate.get('waiting') ? <i className="fa fa-spinner fa-pulse fa-fw button" /> : null;
  const footer = [
    <Button
      key="save"
      className="btn-primary btn-xs"
      data-dismiss="modal"
      disabled={statusUpdate.get('waiting')}
      onClick={onSave}
      autoFocus
    >
      {spinner} Save
    </Button>,
  ];
  const start = statusUpdate.get('start', '');
  const mid = statusUpdate.get('mid', '');
  const end = statusUpdate.get('end', '');

  return (
    <Popup footer={footer} header="Status Code Update" id="statusCodePopup">
      <div>
        Buyer: <strong>{buyer}</strong>
      </div>
      <div className="status--panel">
        <div className="status status__start">
          <Input name="start" showLabel={false} value={start} readOnly />
        </div>
        <div className="status status__up">⇗</div>
        <div className="status status__mid">
          <Select
            label="Status"
            name="mid"
            onChange={onChange}
            options={statuses}
            selectClassName="input-sm text-bold"
            value={mid}
            showValue
          />
        </div>
        <div className="status status__down">⇘</div>
        <div className="status status__end">
          <Input name="end" showLabel={false} value={end} readOnly />
        </div>
      </div>
    </Popup>
  );
};

export default StatusCodePopup;
