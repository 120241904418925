import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Popup from '../../components/helpers/Popup';
import Button from '../../components/helpers/Button';
import { handleSendSearchCriteria, handleResetSearchCriteriaHash } from '../../actions/browse';

/** Share search criteria modal component. */
class ShareLinkPopup extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const { popup, handleSendSearchCriteria } = this.props;
    const requestParams = popup.getIn(['props', 'requestParams']).toJS();
    const stateParams = popup.getIn(['props', 'stateParams']).toJS();

    /** Send params to backend on mount modal. */
    handleSendSearchCriteria({ requestParams, stateParams });
  }

  componentWillUnmount() {
    this.props.handleResetSearchCriteriaHash();
  }

  /** Close popup method. */
  handleClose() {
    this.context.closePopup();
  }

  render() {
    const { searchCriteriaHash, popup } = this.props;
    const isFetching = popup.get('isPopupFetching');
    const name = popup.getIn(['props', 'name']);

    /** Modal header. */
    const header = `Sharing browse/${name} criteria`;

    /** Modal footer. */
    const footer = [
      <Button key="close" className="btn-default btn-xs" onClick={this.handleClose}>
        Close
      </Button>,
    ];

    /** Create share link method. */
    const createLink = () => {
      const link = searchCriteriaHash && `${window.location.origin}/browse/${name}?criteria=${searchCriteriaHash}`;

      return (
        <a href={link} rel="noopener noreferrer" target="_blank">
          {link}
        </a>
      );
    };

    /** Render modal body method. */
    const renderBody = isFetching ? (
      <i className="loader fa fa-spinner fa-pulse fa-3x fa-fw login-spinner" />
    ) : (
      <div>Your shared link: {createLink()}</div>
    );

    return (
      <Popup footer={footer} header={header} id="shareLinkPopup" loading>
        <div className="container share-link-popup__body">{renderBody}</div>
      </Popup>
    );
  }
}

ShareLinkPopup.contextTypes = {
  closePopup: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  ...props,
  searchCriteriaHash: state.browse.get('searchCriteriaHash'),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      handleSendSearchCriteria,
      handleResetSearchCriteriaHash,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareLinkPopup);
