const map = new WeakMap();

function slowGet(element) {
  if (!element) return 1;

  const style = window.getComputedStyle(element, null);

  if (style) {
    const z = style.getPropertyValue('z-index');
    const zIndex = parseInt(z, 10);

    if (zIndex) return Math.max(zIndex, getZIndex(element.parentElement));
  }

  return getZIndex(element.parentElement);
}

export default function getZIndex(element) {
  if (!element) return 1;
  if (map.has(element)) return map.get(element);

  const index = slowGet(element);

  map.set(element, index);

  return index;
}
