import { CALL_API } from '../../middleware/api';
import { loadAssociates } from './associate';

export const SAVE_APPROVAL_ERROR = Symbol('SAVE_APPROVAL_ERROR');
export const SAVE_APPROVAL_SUCCESS = Symbol('SAVE_APPROVAL_SUCCESS');
export const LOADING_PAGE = Symbol('LOADING_PAGE');
export const LOADED_APPROVALS_FAILURE = Symbol('LOADED_APPROVALS_FAILURE');
export const LOADED_APPROVALS_SUCCESS = Symbol('LOADED_APPROVALS_SUCCESS');
export const LOADING_ONLINE_APPROVALS = Symbol('LOADING_ONLINE_APPROVALS');
export const LOADING_ONLINE_APPROVALS_FAILURE = Symbol('LOADING_ONLINE_APPROVALS_FAILURE');
export const LOADING_ONLINE_APPROVALS_SUCCESS = Symbol('LOADING_ONLINE_APPROVALS_SUCCESS');
export const UPDATE_ONLINE_APPROVALS = Symbol('UPDATE_ONLINE_APPROVALS');
export const CLEAR_ERRORS = Symbol('CLEAR_ERRORS');
export const SELECT_APPROVAL = Symbol('SELECT_APPROVAL');
export const CHANGE_DATE = Symbol('CHANGE_DATE');
export const SAVE_COMPANY_SUCCESS = Symbol('SAVE_COMPANY_SUCCESS');
export const SAVE_ONLINE_APPROVALS = Symbol('SAVE_ONLINE_APPROVALS');
export const SAVE_ONLINE_APPROVALS_SUCCESS = Symbol('SAVE_ONLINE_APPROVALS_SUCCESS');
export const SAVE_ONLINE_APPROVALS_FAILURE = Symbol('SAVE_ONLINE_APPROVALS_FAILURE');
export const GENERATE_LINK = Symbol('GENERATE_LINK');
export const GENERATE_LINK_SUCCESS = Symbol('GENERATE_LINK_SUCCESS');
export const GENERATE_LINK_FAILURE = Symbol('GENERATE_LINK_FAILURE');
export const LOAD_LINKS_LIST_SUCCESS = Symbol('LOAD_LINKS_LIST_SUCCESS');

/**
 * Load project information and select approval with inputted approval id after loading.
 *
 * @param projectId {Number} Project id.
 * @param approvalId {Number} Approval id.
 * @param sort {string} Sort.
 * @returns Action.
 */
export function loadProject(projectId, approvalId, sort) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/${projectId}`,
      query: { include: 'buyer,harvco_lead,client_lead,approval_lists,no_approval_lists' },
      startType: LOADING_PAGE,
      errorType: LOADED_APPROVALS_FAILURE,
      successType: LOADED_APPROVALS_SUCCESS,
      afterSuccess: ({ dispatch }) => {
        dispatch(loadAssociates(projectId, approvalId, sort));
      },
    },
    approvalId,
  };
}

/**
 * Load preview/online approval list.
 *
 * @param projectId {Number} Project id.
 * @param approvalId {Number} Approval id.
 * @param query {string} Query params.
 * @param type {string} Type of request 'online' or 'preview'.
 * @returns Action.
 */
export function loadPreviewOnlineApprovals(projectId, approvalId, query, type) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/${projectId}/approval_lists/${approvalId}/${type}${query}`,
      startType: LOADING_ONLINE_APPROVALS,
      errorType: LOADING_ONLINE_APPROVALS_FAILURE,
      successType: LOADING_ONLINE_APPROVALS_SUCCESS,
    },
  };
}

/**
 * Load approval links list.
 *
 * @param projectId {Number} Project id.
 * @param approvalId {Number} Approval id.
 * @returns Action.
 */
export function loadLinksList(projectId, approvalId) {
  const LIMIT_LIST = 999;
  const query = {
    per_page: LIMIT_LIST,
  };

  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/${projectId}/approval_lists/${approvalId}/links`,
      query,
      successType: LOAD_LINKS_LIST_SUCCESS,
    },
  };
}

/**
 * Update online approval list.
 *
 * @param approvals {Object} Approvals list.
 * @returns Action.
 */
export function updateOnlineApprovals(approvals) {
  return {
    type: UPDATE_ONLINE_APPROVALS,
    approvals,
  };
}

/**
 * Select approval.
 *
 * @param id {Number} Approval id.
 * @returns Action.
 */
export function selectApproval(id) {
  return {
    type: SELECT_APPROVAL,
    id,
  };
}

/**
 * Change date.
 *
 * @param name {String} Date name.
 * @param value {Moment} Date value.
 * @returns Action.
 */
export function changeDate(name, value) {
  return {
    type: CHANGE_DATE,
    name,
    value,
  };
}

/**
 * Clear all error.
 *
 * @returns Action.
 */
export function clearError() {
  return {
    type: CLEAR_ERRORS,
  };
}

/**
 * Save approval changes.
 *
 * @returns Action.
 */
export function saveApproval(projectId, approvalId, body, originalBody) {
  return {
    body: originalBody,
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/projects/${projectId}/approval_lists/${approvalId}`,
      body,
      errorType: SAVE_APPROVAL_ERROR,
      successType: SAVE_APPROVAL_SUCCESS,
    },
  };
}

/**
 * Update company information. If update target buyer, need buyerId.
 *
 * @param {object} body Information to be updated.
 * @param {number} companyId Company id.
 * @param {number} [buyerId] Buyer id.
 */
export function updateCompany(body, companyId, buyerId, merge) {
  let path = `/api/v1/companies/${companyId}`;

  if (buyerId) {
    path += `/buyers/${buyerId}`;
  }

  return {
    merge,
    [CALL_API]: {
      method: 'put',
      path,
      body,
      successType: SAVE_COMPANY_SUCCESS,
    },
  };
}

/**
 * Make post request for create Windward reports.
 *
 * @param {object} param Params.
 * @param {number} param.selectedApprovalId - ID selected approval list.
 * @param {number} param.selectedUserId - ID of user from Module field.
 * @param {string} param.nameTemplate - Name of Windward report template.
 * @param {Array} param.templateParams - Template params.
 * @param {Function} param.afterSuccess - Function which call after success request.
 * @param {Function} param.afterError - A function that is called after a successful request.
 */
export function runWindwardReport({
  selectedApprovalId,
  selectedUserId,
  nameTemplate,
  templateParams,
  afterSuccess,
  afterError,
}) {
  const path = `/api/v1/windward/reports`;
  const params = [
    {
      approvalListId: selectedApprovalId,
    },
  ];

  return {
    [CALL_API]: {
      method: 'post',
      path,
      body: {
        moduleId: selectedUserId,
        templateType: nameTemplate,
        templateParams,
        params,
      },
      afterSuccess,
      afterError,
    },
  };
}

/**
 * Save online approval list.
 *
 * @param {object} param
 * @param {number} param.projectId - Project Id.
 * @param {number} param.approvalId - Approval Id.
 * @param {object} param.body - Array of approval list.
 * @param {Function} param.afterSuccess - Function which call after success request.
 * @param {Function} param.afterError - A function that is called after a successful request.
 */
export function saveOnlineApprovals({ projectId, approvalId, body, afterSuccess, afterError }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/projects/${projectId}/approval_lists/${approvalId}/online`,
      body,
      startType: SAVE_ONLINE_APPROVALS,
      errorType: SAVE_ONLINE_APPROVALS_FAILURE,
      successType: SAVE_ONLINE_APPROVALS_SUCCESS,
      afterSuccess,
      afterError,
    },
  };
}

/**
 * Save prepend data before saving online approval list.
 *
 * @param {object} param Params.
 * @param {number} param.projectId Project Id.
 * @param {number} param.approvalId Approval Id.
 * @param {object} param.body Array of approval list.
 * @param {Function} param.afterSuccess Function that is called after success request.
 * @param {Function} param.afterError Function that is called after error request.
 */
export function saveProgressOnlineApprovals({ projectId, approvalId, body, afterSuccess, afterError }) {
  return {
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/projects/${projectId}/approval_lists/${approvalId}/online`,
      body,
      startType: SAVE_ONLINE_APPROVALS,
      errorType: SAVE_ONLINE_APPROVALS_FAILURE,
      successType: SAVE_ONLINE_APPROVALS_SUCCESS,
      afterSuccess,
      afterError,
    },
  };
}

/**
 * Generates link to approval list.
 *
 * @param {number} param.projectId - Project Id.
 * @param {number} param.approvalId - Approval Id.
 * @param {object} param.body - Array of approval list.
 * @param {Function} param.afterSuccess - Function which call after success request.
 * @param {Function} param.afterError - A function that is called after a successful request.
 */
export function generateLink({ projectId, approvalId, body, afterSuccess, afterError }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/projects/${projectId}/approval_lists/${approvalId}/preview/generate_links`,
      body,
      skipGlobalErrorHandler: true,
      startType: GENERATE_LINK,
      errorType: GENERATE_LINK_FAILURE,
      successType: GENERATE_LINK_SUCCESS,
      afterSuccess: action => afterSuccess(action.response.data),
      afterError,
    },
  };
}
