import PropTypes from 'prop-types';

import React, { Component } from 'react';
import InputRange from 'react-input-range';
import { Map } from 'immutable';
import BrowseCheckbox from './BrowseCheckbox';

class BrowseInputRange extends Component {
  constructor(props) {
    super(props);

    this.state = { maxValue: props.filter.get('maxValue') };
    this.handleValuesChange = this.handleValuesChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleValuesChange(values) {
    const min = Math.round(values.min * 100) / 100;
    const max = Math.round(values.max * 100) / 100;

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        values: {
          min,
          max,
        },
        text: {
          min,
          max,
        },
      },
    });
  }

  handleChange(event) {
    const { name, value } = event.target;

    if (isNaN(value)) {
      event.preventDefault();

      return false;
    }

    if (name === 'max') {
      this.setState({ maxValue: Number(value) || 0 });
    }

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        text: {
          [name]: value,
        },
      },
    });
  }

  handleBlur(event) {
    const { name, value } = event.target;
    const { filter } = this.props;
    const minValue = filter.get('minValue');
    const { maxValue } = this.state;
    let updateValue = Math.round(value * 100) / 100;
    const min = this.props.info.get('values').get('min');
    const max = this.props.info.get('values').get('max');

    if (name === 'min') {
      if (updateValue >= max) {
        updateValue = max;
      } else if (updateValue < minValue) {
        updateValue = minValue;
      }
    } else if (name === 'max') {
      if (updateValue <= min) {
        updateValue = min;
      } else if (updateValue > maxValue) {
        updateValue = maxValue;
      }
    }

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        values: {
          [name]: updateValue,
        },
        text: {
          [name]: updateValue,
        },
      },
    });
  }

  render() {
    const { filter } = this.props;
    const minValue = filter.get('minValue');
    const { maxValue } = this.state;
    const step = filter.get('step');
    const { info } = this.props;
    const values = {
      min: info.get('values').get('min'),
      max: info.get('values').get('max'),
    };
    const text = {
      min: info.get('text').get('min'),
      max: info.get('text').get('max'),
    };

    return (
      <BrowseCheckbox {...this.props}>
        <div className="BrowseControl-content BrowseControl-content--slider">
          <InputRange
            maxValue={maxValue}
            minValue={minValue}
            onChange={this.handleValuesChange}
            step={step}
            value={values}
          />
          <div className="form-inline">
            <div className="form-group">
              <input
                className="form-control"
                name="min"
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                type="text"
                value={text.min}
              />
            </div>
            <div className="form-group">&nbsp;-&nbsp;</div>
            <div className="form-group">
              <input
                className="form-control"
                name="max"
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                type="text"
                value={text.max}
              />
            </div>
          </div>
        </div>
      </BrowseCheckbox>
    );
  }
}

BrowseInputRange.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BrowseInputRange;
