function areEqual(next, prev) {
  return next === prev;
}

export default {
  pure: true,
  areStatesEqual: areEqual,
  areOwnPropsEqual: areEqual,
  areStatePropsEqual: areEqual,
  areMergedPropsEqual: areEqual,
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});
