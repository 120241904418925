import PropTypes from 'prop-types';

import React from 'react';
import { Map } from 'immutable';
import GeneralResultRow from './GeneralResultRow';

/**
 * Stateless component for person result row.
 *
 * @param props {Object}.
 * @param props.person {Immutable.Map} Person information.
 * @returns {React.Component}
 * @class
 */
const PeopleResultRow = ({ person }) => {
  const type = person.get('type');
  const exec =
    type === 'exec' ? (
      <span>
        <span className="exec">Exec:</span>
        <strong>{person.get('execCurrentStatus', '')}</strong> / {person.get('execHighStatus', '')}
      </span>
    ) : (
      ''
    );
  const lp = type === 'lp' ? `LP: ${person.get('lpCurrentStatus', '')}/ ${person.get('lpHighStatus', '')}` : '';
  const funds = getFund(person.get('fund'));
  const classColor = type === 'exec' ? 'green' : type === 'lp' ? 'purple' : '';

  return (
    <GeneralResultRow linkTo={`/contact/${person.get('id')}`}>
      <div className="search-result__pitem-name">
        <h4>{person.get('personName', '')}</h4>
        <p>{person.get('personNickName', '')}</p>
      </div>
      <div className="search-result__pitem-contact">
        <h4>{person.get('companyName', '')}</h4>
        <p>{person.get('personTitle', '')}</p>
      </div>
      <div className={`search-result__pitem-exec ${classColor}`}>
        <div className="text-uppercase green">{exec}</div>
        <div className="lp-text text-uppercase purple">{lp}</div>
      </div>
      <div className={`search-result__pitem-exec ${classColor}`}>
        <div>{person.get('owner')}</div>
      </div>
      <div className={`search-result__pitem-exec-large ${classColor}`}>
        <div>{person.get('fundName', '')}</div>
        <div>{funds}</div>
      </div>
      <div className="search-result__pitem-category">{person.get('industry')}</div>
      <div className="search-result__pitem-activity">
        <div>
          <span className="na-title">Next action:</span>
          <span className="search-item-action__value">
            {getActionText(person.get('nextActionDate'), person.get('nextAction'))}
          </span>
        </div>
        <div>
          <span className="na-title">Last action:</span>
          <span className="search-item-action__value">
            {getActionText(person.get('lastActionDate'), person.get('lastAction'))}
          </span>
        </div>
      </div>
    </GeneralResultRow>
  );
};

PeopleResultRow.propTypes = {
  person: PropTypes.instanceOf(Map).isRequired,
};

export default PeopleResultRow;

const getActionText = (date, activity) => {
  let ret = '';

  if (date) {
    ret += date;
    if (activity) {
      ret += ` - ${activity}`;
    }
  } else if (activity) {
    ret = activity;
  }

  return ret;
};

const getFund = funds =>
  funds.size
    ? [
        <span key="q">
          <strong>Q: {funds.get(0)}</strong> /
        </span>,
        <span key="i">
          <strong>I: {funds.get(1)}</strong> /
        </span>,
        <span key="r">
          <strong>R: {funds.get(2)}</strong>
        </span>,
      ]
    : null;
