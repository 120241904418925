import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import Immutable from 'immutable';
import { push } from 'connected-react-router';

import config from '../config';
import { showError } from '../utils/MessagePopup';
import connectOptions, { mergeProps } from '../utils/connectOptions';
import Browse from '../components/Browse';
import { isResearcher } from '../utils/checkPermissions';
import BrowseRouting from '../routing/BrowseRouting';

const tabs = config.tabs.get('browse');

class BrowseContainer extends PureComponent {
  componentDidMount() {
    if (!this.hasAccess()) {
      showError(this.context.openPopup, config.permisionError, () => {
        this.props.push('/');
        this.context.closePopup();
      });
    }
  }

  hasAccess() {
    if (!this.context.currentUser) return false;

    return !isResearcher(this.context.currentUser.getIn(['roles', 0, 'slug'], null));
  }

  render() {
    if (!this.hasAccess()) return null;

    const { props } = this;

    return (
      <React.Fragment>
        <Helmet title="Browse Page" />
        <Browse {...props}>
          <BrowseRouting />
        </Browse>
      </React.Fragment>
    );
  }
}

BrowseContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Immutable.Map).isRequired,
  closePopup: PropTypes.func.isRequired,
};

function mapStateToProps() {
  return {
    tabs,
  };
}

export { BrowseContainer };
export default connect(mapStateToProps, { push }, mergeProps, connectOptions)(BrowseContainer);
