import humps from 'humps';

import { CALL_API } from '../middleware/api';
import uniqueId from '../utils/uniqueId';
import config from '../config';

export const LOADING_BL_USERS = 'LOADING_BL_USERS';
export const LOADED_BL_USERS_FAILURE = 'LOADED_BL_USERS_FAILURE';
export const LOADED_BL_USERS_SUCCESS = 'LOADED_BL_USERS_SUCCESS';
export const LOADING_BL = 'LOADING_BL';
export const LOADED_BL_FAILURE = 'LOADED_BL_FAILURE';
export const LOADED_BL_SUCCESS = 'LOADED_BL_SUCCESS';
export const FETCH_NEXT_BL_SUCCESS = 'FETCH_NEXT_BL_SUCCESS';
export const CLEAR_BL = 'CLEAR_BL';
export const UPDATE_BL_USERS = 'UPDATE_BL_USERS';
export const SORT_BL_LIST = 'SORT_BL_LIST';

/**
 * Load users for backlog page.
 */
export function loadUsers() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/users/backlog_filters',
      startType: LOADING_BL_USERS,
      errorType: LOADED_BL_USERS_FAILURE,
      successType: LOADED_BL_USERS_SUCCESS,
    },
  };
}

/**
 * Load backlogs.
 *
 * @param ids (Array) list of user id.
 * @param sortExpress (String) sorting expression.
 * @param date (String) date in ISO.
 */
export function loadBacklogs(ids, sortExpress, date) {
  if (!ids || ids.length === 0) {
    return {
      type: CLEAR_BL,
    };
  }

  const query = ids.map(id => `selected[]=${id}`).join('&');
  const limit = config.REQUEST_THROTTLING;
  const dateQuery = date ? `&date=${date}` : '';

  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/events/backlogged?${query}&limit=${limit}&page=1${_getSortExpression(sortExpress)}${dateQuery}`,
      startType: LOADING_BL,
      errorType: LOADED_BL_FAILURE,
      successType: LOADED_BL_SUCCESS,
    },
    identifier: uniqueId(),
  };
}

/**
 * Fetch next backlogs.
 *
 * @param ids (Array) list of user id.
 * @param page (Number) page number,.
 * @param sortExpress (String) sorting expression.
 */
export function fetchNextBacklogs(ids, page, sortExpress, date) {
  if (!ids || ids.length === 0) return;

  const query = ids.map(id => `selected[]=${id}`).join('&');
  const limit = config.REQUEST_THROTTLING;
  const dateQuery = date ? `&date=${date}` : '';

  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/events/backlogged?${query}&limit=${limit}&page=${page}${_getSortExpression(
        sortExpress,
      )}${dateQuery}`,
      startType: LOADING_BL,
      errorType: LOADED_BL_FAILURE,
      successType: FETCH_NEXT_BL_SUCCESS,
    },
  };
}

/**
 * Change selected user.
 */
export function changeUser(userId, selected) {
  return {
    type: UPDATE_BL_USERS,
    userId,
    selected,
  };
}

/**
 * Sort backlog table.
 */
export function sort(column, direction) {
  return {
    type: SORT_BL_LIST,
    column,
    direction,
  };
}

/**
 * Generate sorting expression.
 */
function _getSortExpression(sort) {
  if (sort === 'project') {
    return '&sort=project_category';
  }

  if (sort === '-project') {
    return '&sort=-project_category';
  }

  return sort ? `&sort=${humps.decamelize(sort)}` : '';
}
