import React from 'react';
import { List } from 'immutable';
import classNames from 'classnames';
import { unwrap } from '../../../utils/ChangeSpy';

const Broad = ({ data, businessModels, deprecatedIndustries, onClick, headerClass, disabled }) => {
  let rows = [];
  let deprecatedRows = [];
  const industries = unwrap(data) || List();
  const models = unwrap(businessModels) || List();
  const realData = models.concat(industries);

  if (realData.size) {
    rows = realData.map((t, i) => {
      const cssClass = classNames({ deleted: t.get('deletedAt') });

      return (
        <tr key={`${t.get('id')}${t.get('category')}${i}`} className={cssClass}>
          <td>
            <span>{t.get('category')}</span>
          </td>
        </tr>
      );
    });
  }

  if (deprecatedIndustries.size) {
    deprecatedRows = deprecatedIndustries.map((t, i) => {
      const cssClass = classNames('text-danger', {
        deleted: t.get('deletedAt'),
      });

      return (
        <tr key={`${t.getIn(['id', 'value'])}${t.getIn(['industryLabel', 'value'])}${i}`} className={cssClass}>
          <td>
            <span>{t.getIn(['industryLabel', 'value'])}</span>
          </td>
        </tr>
      );
    });
  }

  if (rows.length === 0 && deprecatedRows.length === 0) {
    rows.push(
      <tr key="norow">
        <td>No rows to display</td>
      </tr>,
    );
  }

  const buttonClasses = classNames(`fa ${headerClass ? 'fa-plus' : 'fa-plus-square'} fa-2x`);

  const handleClick = () => {
    if (!disabled) {
      return onClick();
    }
  };

  return (
    <table className="table table table-bordered table-hover empty-table">
      <thead>
        <tr>
          <th className="plus-icon">
            <div className={`d-flex ${headerClass}`}>
              <span>
                <div className="icon-wrapper">
                  <i className={buttonClasses} onClick={handleClick} />
                </div>
              </span>
              <span>Industries</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {rows}
        {deprecatedRows}
      </tbody>
    </table>
  );
};

export default Broad;
