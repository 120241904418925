import PropTypes from 'prop-types';

import React from 'react';
import { List } from 'immutable';

import WysiwygView from '../helpers/WysiwygView';

const CompanyLabel = ({ note }) => {
  const name = note.get('legalName', '');

  if (!name) return <span />;

  const projectName = note.get('projectName', '');

  if (!projectName) return <span className="text-muted">({name})</span>;

  return <span className="text-muted">({(note.get('fullActivity', '') || '').split(/\n\r?/).join(' ')})</span>;
};

/**
 * Component is used to display 1 contact's email.
 *
 * @param props {Object} Any extra props that allowed on <p> tag.
 * @param props.onMouseEnter {Function} OnMouseEnter callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.onMouseLeave {Function} OnMouseLeave callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.email {Immutable.Map} Map, that should describe email. #content {string} is note content.
 * #[isMarked=false] {boolean} uf `true` append .text-bold class to element. #eventId is email's eventId.
 * @returns {XML}
 * @private
 * @class
 */
const CompanyEmailLogLine = (props, { onEditEvent }) => {
  const { onMouseEnter, onMouseLeave, email, ...rest } = props;

  const topContent = (
    <div className="contact__deal-note" onDoubleClick={event => onEditEvent(event, email.set('id', email.get('eventId')))}>
      <strong>{email.get('date')}</strong> <span className="text-muted">{email.get('userName', '')}</span>{' '}
      <CompanyLabel note={email} />
    </div>
  );
  const content = email.get('content', '');

  return (
    <div {...rest} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {topContent}
      <WysiwygView html={content} onDoubleClick={event => onEditEvent(event, email.set('id', email.get('eventId')))} />
    </div>
  );
};

CompanyEmailLogLine.propTypes = {
  isMarked: PropTypes.bool,
};

CompanyEmailLogLine.contextTypes = {
  onEditEvent: PropTypes.func.isRequired,
};

const CompanyEmailPanel = props => {
  const { emails, onMouseEnter, onMouseLeave } = props;
  const content =
    emails.size === 0 ? (
      <p className="text-center"> No rows to display </p>
    ) : (
      emails.map((email, i) => (
        <CompanyEmailLogLine
          key={i}
          email={email}
          onMouseEnter={event => onMouseEnter(event, email.get('eventId'))}
          onMouseLeave={event => onMouseLeave(event, email.get('eventId'))}
        />
      ))
    );

  return (
    <div className="panel panel-default">
      <div className="panel-heading" data-parent="#accordion" data-target="#acc-slide-6" data-toggle="collapse">
        <h4 className="panel-title"> Email Log / Buyer Notes </h4>
      </div>
      <div className="panel-collapse collapse" id="acc-slide-6">
        <div className="panel-body company__email-panel-body">{content}</div>
      </div>
    </div>
  );
};

CompanyEmailPanel.propTypes = {
  emails: PropTypes.instanceOf(List).isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

export default CompanyEmailPanel;
