import PropTypes from 'prop-types';

import React from 'react';

/**
 * Stateless component about next action page container.
 *
 * @param props {Object}.
 * @param props.children {any} Children elements.
 * @returns {React.Component}
 */
const ContainerPanel = ({ children }) => (
  <div className="container" role="main">
    <div className="row">
      <div className="col-xs-12 wrap-next-actions">{children}</div>
    </div>
  </div>
);

ContainerPanel.propTypes = {
  children: PropTypes.any,
};

export default ContainerPanel;
