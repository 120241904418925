import project from './project';
import users from './user';
import industries from './industry';
import targets from './target';
import templates from './template';
import approvals from './approval';
import noApprovals from './noApproval';
import common from './common';
import industryPopup from './industryPopup';
import templatePopup from './templatePopup';

const projectReducer = (state = {}, action) => ({
  common: common(state.common, action),
  projectData: project(state.projectData, action),
  users: users(state.users, action, state.common),
  industries: industries(state.industries, action, state.common),
  approvals: approvals(state.approvals, action),
  noApprovals: noApprovals(state.noApprovals, action),
  templates: templates(state.templates, action),
  targets: targets(state.targets, action),
  industryPopup: industryPopup(state.industryPopup, action),
  templatePopup: templatePopup(state.templatePopup, action),
});

export default projectReducer;
