import PropTypes from 'prop-types';

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import Popups from '../../components/Popup';
import downloadFile from '../../utils/downloadFile';
import config from '../../config';

const ClientSummaryReportPopup = (props, context) => {
  const [loading, setLoading] = useState(false);
  const [selectedLowestStatus, setLowestStatus] = useState('5.00');
  const [selectedHighestStatus, setHighestStatus] = useState('9.00');

  const onGenerate = activeOnly => {
    const buyerId = props.popup.getIn(['props', 'buyerId']);

    setLoading(true);

    const afterSuccess = () => {
      context.closePopup();
    };

    downloadFile({
      url: buildDownloadUrl(buyerId, activeOnly, selectedLowestStatus, selectedHighestStatus),
      method: 'POST',
      afterSuccess,
    });
  };

  const onLowestStatusChange = event => {
    const { value } = event.target;

    setLowestStatus(value);

    if (Number(value) > Number(selectedHighestStatus)) {
      setHighestStatus(value);
    }
  };

  const onHighestStatusChange = event => {
    const { value } = event.target;

    setHighestStatus(value);

    if (Number(value) < Number(selectedLowestStatus)) {
      setLowestStatus(value);
    }
  };

  const buildDownloadUrl = (buyerId, activeOnly, lowestStatus, highestStatus) =>
    `${config.API_BASE_URL}/api/v1/buyer/${buyerId}/export_client_summary?active_only=${activeOnly}&low_status=${lowestStatus}&highest_status=${highestStatus}`;

  return (
    <Popups.ClientSummaryReportPopup
      currentHighestStatus={selectedHighestStatus}
      currentLowestStatus={selectedLowestStatus}
      loading={loading}
      onGenerate={onGenerate}
      onHighestStatusChange={onHighestStatusChange}
      onLowestStatusChange={onLowestStatusChange}
      statuses={props.targetStatuses}
    />
  );
};

ClientSummaryReportPopup.contextTypes = {
  closePopup: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    ...props,
    targetStatuses: fromJS(state.statuses.get('targetStatuses')),
  };
}

export { ClientSummaryReportPopup };
export default connect(mapStateToProps, {}, mergeProps, connectOptions)(ClientSummaryReportPopup);
