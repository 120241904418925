import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Map } from 'immutable';

import BrowseAutoComplete from './BrowseAutoComplete';
import BrowseCheckbox from './BrowseCheckbox';

class BrowseFundFilter extends PureComponent {
  constructor(props) {
    super(props);

    this.handleAddTag = this.handleAddTag.bind(this);
    this.handleRemoveTag = this.handleRemoveTag.bind(this);
    this.handleActiveChange = this.handleActiveChange.bind(this);
  }

  handleActiveChange(event) {
    const { checked } = event.target;

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        active: checked,
      },
    });
  }

  handleAddTag() {
    const { filter, info } = this.props;
    const fundSelected = info.get('fund').get('selected');

    if (fundSelected) {
      const fundSelectedText = fundSelected.get(filter.getIn(['fund', 'renderField']), '');
      let projectSelectedText = '(any project)';

      const selectedFund = {
        fund_id: fundSelected.get(filter.getIn(['fund', 'valueField'])),
      };
      const projectSelected = info.get('project').get('selected');

      if (projectSelected) {
        selectedFund.eproject_id = projectSelected.get(filter.getIn(['project', 'valueField']));
        projectSelectedText = projectSelected.get(filter.getIn(['project', 'renderField']));
      }

      let activeSelectedText = '';

      switch (info.get('active')) {
        case true:
          selectedFund.active_fund = true;
          activeSelectedText = 'active';
          break;

        default:
          selectedFund.active_fund = null;
          activeSelectedText = '(active or inactive)';
          break;
      }

      this.props.onChange({
        filterName: filter.get('name'),
        filterData: {
          selectedList: info
            .get('selectedList')
            .push(`${fundSelectedText}/${projectSelectedText}/${activeSelectedText}`),
          selectedListParam: info.get('selectedListParam').push(selectedFund),
          active: undefined,
          project: {
            text: '',
            selected: undefined,
          },
          fund: {
            text: '',
            selected: undefined,
          },
        },
      });
    }
  }

  handleRemoveTag(event) {
    const { name } = event.target;
    const tagIndex = parseInt(name.replace('fundFilterTag', ''), 10);
    let selectedList = this.props.info.get('selectedList');

    selectedList = selectedList.delete(tagIndex);

    let selectedListParam = this.props.info.get('selectedListParam');

    selectedListParam = selectedListParam.delete(tagIndex);

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        selectedList,
        selectedListParam,
      },
    });
  }

  render() {
    const { filter, info } = this.props;
    const selectedList = info.get('selectedList');
    const checked = !!info.get('active');
    const tagListContent = selectedList.map((tag, i) => (
      <div key={i} className="MultiselectItem">
        {tag}
        <button
          aria-hidden="true"
          aria-label="close"
          className="MultiselectItem-close close btn-xs"
          name={`fundFilterTag${i}`}
          onClick={this.handleRemoveTag}
          type="button"
        >
          &times;
        </button>
      </div>
    ));

    return (
      <BrowseCheckbox {...this.props}>
        <div className="BrowseControl-content BrowseControl-content--form">
          {tagListContent}
          <div className="form-inline">
            <BrowseAutoComplete
              filter={filter.get('fund')}
              info={info.get('fund')}
              onChange={this.props.onSubFilterChange}
              onGetNextSuggestion={this.props.onGetNextFundSuggestion}
              onGetSuggestion={this.props.onGetFundSuggestion}
            />
            <BrowseAutoComplete
              filter={filter.get('project')}
              info={info.get('project')}
              onChange={this.props.onSubFilterChange}
              onGetNextSuggestion={this.props.onGetNextProjectSuggestion}
              onGetSuggestion={this.props.onGetProjectSuggestion}
            />
            <div className="form-group form-group--inline form-group--checkbox">
              <label htmlFor="browse-active">Active</label>
              <div className="checkbox-wrap">
                <label>
                  <input onChange={this.handleActiveChange} type="checkbox" value={checked} />
                </label>
              </div>
            </div>
            <div className="form-group form-group--add">
              <button className="btn btn-default btn-xs" onClick={this.handleAddTag}>
                <span className="fa fa-plus" />
              </button>
            </div>
          </div>
        </div>
      </BrowseCheckbox>
    );
  }
}
BrowseFundFilter.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onGetFundSuggestion: PropTypes.func.isRequired,
  onGetNextFundSuggestion: PropTypes.func.isRequired,
  onGetNextProjectSuggestion: PropTypes.func.isRequired,
  onGetProjectSuggestion: PropTypes.func.isRequired,
  onSubFilterChange: PropTypes.func.isRequired,
};

export default BrowseFundFilter;
