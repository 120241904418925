import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import classnames from 'classnames';

import Popup from '../helpers/Popup';
import { InlineInput } from '../helpers/Input';
import FormGroup from '../helpers/FormGroup';
import Checkbox from '../helpers/Checkbox';
import Textarea from '../helpers/Textarea';
import ActivityDropdown from '../helpers/ActivityDropdown';
import Button from '../helpers/Button';
import AutoComplete from '../helpers/AutoComplete';
import config from '../../config';
import ComboSelectBox from '../helpers/ComboSelectBox';

const activities = config.values.get('activities');

const SetNextActionsPopup = props => {
  const {
    onUpdate,
    onClose,
    onChange,
    onActivityChange,
    onStatusChange,
    onDateChange,
    onDelete,
    assignedToSuggestions,
    formData,
    formError,
    statuses,
    isPopupFetching,
    isPopupFetchingError,
    descriptionHidden,
    backlogHidden,
    applyToHidden,
    currentUser,
  } = props;
  const [isDisabledDelete, setDisabledDelete] = useState(false);

  const header = 'Set Next Actions';
  const disableUpdate =
    !formData.get('date') ||
    !formData.getIn(['activity', 'text']) ||
    !formData.getIn(['activity', 'selected']) ||
    !formData.getIn(['assignedTo', 'selected']);
  const updateAction = isDisabledDelete ? onDelete : onUpdate;
  const footer = [
    <Button
      key="addTag"
      className="btn-primary btn-xs"
      disabled={disableUpdate && !isDisabledDelete}
      onClick={updateAction}
    >
      Update
    </Button>,
    <Button key="close" className="btn-default btn-xs" onClick={onClose}>
      Close
    </Button>,
  ];
  const applyToClass = classnames({
    hide: applyToHidden,
  });

  const backlogClass = classnames({
    mt10: true,
    hide: backlogHidden,
  });

  const descriptionClass = classnames({
    'form-inline row mb5': true,
    hide: descriptionHidden,
  });

  const loading = isPopupFetching ? (
    <div className="loading">
      <i className="fa fa-spinner fa-pulse fa-3x fa-fw login-spinner" />
    </div>
  ) : null;

  const errorContent = isPopupFetchingError ? (
    <p className="error text-danger">{isPopupFetchingError.get('message')}</p>
  ) : null;

  const formErrorContent = formError
    ? formError.map((error, index) => (
        <p key={index} className="error text-danger">
          {error}
        </p>
      ))
    : null;

  const userRole = currentUser.getIn(['roles', 0, 'slug']);
  const isAvailableDeleting = [config.DIRECTOR, config.ADMINISTRATOR].includes(userRole);

  const handleDeleteOutstandingChange = event => {
    setDisabledDelete(event.target.checked);
  };

  return (
    <Popup footer={footer} header={header} id="setNextActionPopup" loading={loading}>
      <div className="row media-450 mb20">
        <div className="col-xs-12">
          <div className="mb15">Schedule the following Next Action for the selected companies:</div>
          <div className="form-inline row mb5">
            <FormGroup className="col-xs-4">
              <InlineInput
                disabled={isDisabledDelete}
                label="Date"
                name="date"
                onChange={onDateChange}
                placeholder="date"
                type="date"
                value={formData.get('date')}
                autoFocus
                showLabel
              />
            </FormGroup>
            <FormGroup className="col-xs-4">
              <AutoComplete
                {...assignedToSuggestions}
                inputProps={{
                  ...assignedToSuggestions.inputProps,
                  disabled: isDisabledDelete,
                }}
              />
            </FormGroup>
            <FormGroup className="col-xs-4">
              <div>
                <label>New Status</label>
              </div>
              <ComboSelectBox
                className="na-cb-sel-box"
                disabled={isDisabledDelete}
                name="nextActionStatus"
                onChange={onStatusChange}
                options={statuses}
                value={formData.getIn(['status', 'selected'])}
              />
            </FormGroup>
          </div>
          <div className="form-inline row mb5">
            <FormGroup className="col-xs-4">
              <ActivityDropdown
                activities={activities}
                disabled={isDisabledDelete}
                info={formData.get('activity', Map())}
                onChange={onActivityChange}
              />
            </FormGroup>
          </div>
          <div className={descriptionClass}>
            <FormGroup className="col-xs-12">
              <Textarea
                disabled={isDisabledDelete}
                label="Description"
                name="description"
                onChange={onChange}
                placeholder="description"
                rows="3"
                value={formData.get('description')}
                showLabel
              />
            </FormGroup>
          </div>
          <div className="form-inline row">
            <FormGroup className="col-xs-12 group-checkbox">
              <div className={applyToClass}>
                <strong>Apply to:</strong>
                <Checkbox
                  checked={formData.get('approved', false)}
                  disabled={isDisabledDelete}
                  label="Approved"
                  name="approved"
                  onChange={onChange}
                />
                <Checkbox
                  checked={formData.get('passed', false)}
                  disabled={isDisabledDelete}
                  label="Excluded"
                  name="passed"
                  onChange={onChange}
                />
                <Checkbox
                  checked={formData.get('noResponse', false)}
                  disabled={isDisabledDelete}
                  label="No Response"
                  name="noResponse"
                  onChange={onChange}
                />
              </div>
              <div className={backlogClass}>
                <Checkbox
                  checked={formData.get('backlog', false)}
                  disabled={isDisabledDelete}
                  label="Backlog immediately"
                  name="backlog"
                  onChange={onChange}
                />
              </div>
              <div className="mt10">
                <Checkbox
                  capitalizeLabel={false}
                  checked={formData.get('override', false)}
                  disabled={isDisabledDelete}
                  label="Override existing Next Actions of companies that have outstanding Next Actions"
                  name="override"
                  onChange={onChange}
                />
              </div>
              {isAvailableDeleting && (
                <div className="mt10">
                  <Checkbox
                    capitalizeLabel={false}
                    label="Delete outstanding Next Actions for selected"
                    name="na_delete"
                    onChange={handleDeleteOutstandingChange}
                  />
                </div>
              )}
            </FormGroup>
          </div>
        </div>
      </div>
      {errorContent}
      {formErrorContent}
    </Popup>
  );
};

SetNextActionsPopup.propTypes = {
  applyToHidden: PropTypes.bool.isRequired,
  assignedToSuggestions: PropTypes.object.isRequired,
  backlogHidden: PropTypes.bool.isRequired,
  descriptionHidden: PropTypes.bool.isRequired,
  formData: PropTypes.instanceOf(Map).isRequired,
  isPopupFetching: PropTypes.bool,
  isPopupFetchingError: PropTypes.instanceOf(Map),
  onActivityChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  statuses: PropTypes.instanceOf(List).isRequired,
};

export default SetNextActionsPopup;
