import { CALL_API } from '../../middleware/api';

export const LOADING_TARGETS = Symbol('LOADING_TARGETS');
export const RELOADING_TARGETS = Symbol('RELOADING_TARGETS');
export const LOADED_TARGETS_FAILURE = Symbol('LOADED_TARGETS_FAILURE');
export const RELOADED_TARGETS_FAILURE = Symbol('RELOADED_TARGETS_FAILURE');
export const LOADED_TARGETS_SUCCESS = Symbol('LOADED_TARGETS_SUCCESS');
export const RELOADED_TARGETS_SUCCESS = Symbol('RELOADED_TARGETS_SUCCESS');
export const LOADING_SORTED_TARGETS = Symbol('LOADING_SORTED_TARGETS');
export const LOADED_SORTED_TARGETS_FAILURE = Symbol('LOADED_SORTED_TARGETS_FAILURE');
export const LOADED_SORTED_TARGETS_SUCCESS = Symbol('LOADED_SORTED_TARGETS_SUCCESS');
export const SORT_TARGETS = Symbol('SORT_TARGETS');
export const CHANGE_TARGET = Symbol('CHANGE_TARGET');
export const SAVED_TARGET = Symbol('SAVED_TARGET');

/**
 * Load target list.
 *
 * @param id {Number} Project's id.
 * @param queryParams {Object} - query params.
 * @param queryParams.limit {String}.
 * @param queryParams.sort {Number}.
 * @param queryParams.page {Number}.
 * @param afterSuccess {Function}.
 * @param isReload {Boolean}.
 * @returns Action.
 */
export function loadTargets(id, queryParams, { afterSuccess } = {}, isReload = false) {
  const query = queryParams
    ? {
        sort: queryParams.sort,
        limit: queryParams.limit || 60,
        page: queryParams.page || 1,
      }
    : null;

  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/${id}/targets`,
      query,
      startType: isReload ? RELOADING_TARGETS : LOADING_TARGETS,
      errorType: isReload ? RELOADED_TARGETS_FAILURE : LOADED_TARGETS_FAILURE,
      successType: isReload ? RELOADED_TARGETS_SUCCESS : LOADED_TARGETS_SUCCESS,
      afterSuccess,
    },
  };
}

/**
 * Load sorted target list.
 *
 * @param id {Number} Project's id.
 * @param queryParams {Object} - query params.
 * @param queryParams.limit {String}.
 * @param queryParams.sort {Number}.
 * @param afterSuccess {Function}.
 * @returns Action.
 */
export function loadSortedTargets(id, queryParams, { afterSuccess } = {}) {
  const query = queryParams
    ? {
        sort: queryParams.sort,
        limit: queryParams.limit || 30,
        page: 1,
      }
    : null;

  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/${id}/targets`,
      query,
      startType: LOADING_SORTED_TARGETS,
      errorType: LOADED_SORTED_TARGETS_FAILURE,
      successType: LOADED_SORTED_TARGETS_SUCCESS,
      afterSuccess,
    },
  };
}

/**
 * Sort target list.
 *
 * @param field {String} Field name.
 * @param direction {up|down} Sorting direction.
 * @returns Action.
 */
export function sortTarget(field, direction) {
  return {
    type: SORT_TARGETS,
    field,
    direction,
  };
}

export function saveTarget({ id, tbId, ...body }) {
  return {
    id,
    body,
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/companies/${id}/buyers/${tbId}`,
      body,
      successType: SAVED_TARGET,
    },
  };
}

export function changeTarget(info) {
  return {
    ...info,
    type: CHANGE_TARGET,
  };
}
