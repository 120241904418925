import { CALL_API } from '../middleware/api';

export const FETCHING_MAILING_HISTORY = 'FETCHING_MAILING_HISTORY';
export const FETCHING_MAILING_HISTORY_SUCCESS = 'FETCHING_MAILING_HISTORY_SUCCESS';
export const FETCHING_MAILING_HISTORY_FAILURE = 'FETCHING_MAILING_HISTORY_FAILURE';
export const UPDATE_MAILING_HISTORY = 'UPDATE_MAILING_HISTORY';

export function getMailingHistory({ userId, page = 1, sortBy = null }) {
  const query = {
    page,
    limit: 100,
  };

  if (sortBy) {
    query.sort = sortBy;
  }

  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/batch/mailing_history/${userId}`,
      query,
      startType: FETCHING_MAILING_HISTORY,
      successType: FETCHING_MAILING_HISTORY_SUCCESS,
      errorType: FETCHING_MAILING_HISTORY_FAILURE,
    },
  };
}

export function updateMailingHistory({ filterName, filterData }) {
  return {
    type: UPDATE_MAILING_HISTORY,
    filterName,
    filterData,
  };
}
