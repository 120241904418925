import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { List, Map } from 'immutable';

import WysiwygView from '../../helpers/WysiwygView';

class ContactExecutiveDealNote extends PureComponent {
  render() {
    return ContactExecutiveDealNoteRender(this.props, this.context);
  }
}

/**
 * Component is used to display 1 deal note.
 *
 * @param props {Object} Any extra props that allowed on <p> tag.
 * @param props.onMouseEnter {Function} OnMouseEnter callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.onMouseLeave {Function} OnMouseLeave callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.note {Immutable.Map} Map, that should describe note. #content {string} is note content.
 * #[isMarked=false] {boolean} uf `true` append .text-bold class to element. #eventId is deal note's eventId.
 * @returns {XML}
 * @private
 * @class
 */
const ContactExecutiveDealNoteRender = (props, { onEditEvent }) => {
  const { onMouseEnter, onMouseLeave, note, className = '', ...rest } = props;

  const isMarked = note.get('isMarked', false);
  const eventId = note.get('eventId');
  const isBoldContent = isMarked ? 'font-weight: bold;' : 'font-weight: normal;';

  const topContent = (
    <div className="contact__deal-note" onDoubleClick={event => onEditEvent(event, note.set('id', eventId))}>
      <strong>{note.get('date')}</strong> <span className="text-muted">{note.get('userName')}</span>
    </div>
  );
  const content = note.get('content', '');

  const wrapBoldContent = `<span style='${isBoldContent}'>${content}</span>`;

  return (
    <div
      {...rest}
      className={className}
      onMouseEnter={event => onMouseEnter(event, eventId)}
      onMouseLeave={event => onMouseLeave(event, eventId)}
    >
      {topContent}
      <WysiwygView html={wrapBoldContent} onDoubleClick={event => onEditEvent(event, note.set('id', eventId))} />
    </div>
  );
};

ContactExecutiveDealNoteRender.propTypes = {
  note: PropTypes.instanceOf(Map).isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

ContactExecutiveDealNote.contextTypes = {
  onEditEvent: PropTypes.func.isRequired,
};

class ContactExecutiveDealNotes extends PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.state = {
      closed: this.props.dealNotes.size === 0,
    };
  }

  onClick() {
    this.setState(prevState => ({
      closed: !prevState.closed,
    }));
  }

  render() {
    return ContactExecutiveDealNotesRender(this.props, this.state, this.onClick);
  }
}

/**
 * Component is used to display list of deal notes.
 *
 * @param props.onMouseEnter {Function} OnMouseEnter callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.onMouseLeave {Function} OnMouseLeave callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.dealNotes {Immutable.List} List of Maps, that should describe note. #content {string} is note content.
 * #[isMarked=false] {boolean} uf `true` append .text-bold class to element. #eventId is deal note's eventId.
 * @returns {XML}
 * @class
 */
const ContactExecutiveDealNotesRender = (props, state, onClick) => {
  const { onMouseEnter, onMouseLeave, dealNotes } = props;
  const initClosed = dealNotes.size === 0;

  if (state.closed === true) {
    return (
      <article className="note container-fluid w32">
        <div className="btn-switcher">
          <i aria-hidden="true" className="fa fa-caret-square-o-right fa-2x" onClick={onClick} />
        </div>
        <h3 className="note-head-contact">Notes</h3>
      </article>
    );
  }

  const content = initClosed ? (
    <p> No rows to display </p>
  ) : (
    dealNotes.map((note, i) => (
      <ContactExecutiveDealNote key={i} note={note} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
    ))
  );

  return (
    <article className="note container-fluid">
      <div className="btn-switcher">
        <i aria-hidden="true" className="fa fa-caret-square-o-left fa-2x" onClick={onClick} />
      </div>
      <div className="note-head-contact">Notes</div>
      <section id="acc2">
        <div className="note-content">{content}</div>
      </section>
    </article>
  );
};

ContactExecutiveDealNoteRender.propTypes = {
  dealNotes: PropTypes.instanceOf(List).isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

ContactExecutiveDealNotes.propTypes = {
  dealNotes: PropTypes.instanceOf(List).isRequired,
};

export { ContactExecutiveDealNote };
export default ContactExecutiveDealNotes;
