import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import DatePicker from '@vespaiach/react-datepicker';

import FormGroup from '../helpers/FormGroup';

/**
 * User datepicker input component.
 *
 * @param {object} props
 * @param {string} props.label Label of text control.
 * @param {string} props.name Name of text control.
 * @param {Function} props.formMode Form mode (view|edit).
 * @param {Immutable.Map} props.formError Error on form.
 * @param {Immutable.Map} props.selectedUser Selected user.
 * @param {string} props.className Input class name.
 * @returns {React.FunctionComponent}
 */
const UserDateInput = props => {
  const { onChange, label, className, formMode, formError, format, name, value, disabled } = props;
  const id = `${label.replace(/\s/g, '_')}_Input`;
  const hasError = formError.get(name, '').length > 0 && formMode === 'edit';
  const inputClassName = `form-control ${disabled ? '' : className}`;
  const formGroupClassName = hasError ? 'has-error' : '';

  return (
    <FormGroup className={formGroupClassName}>
      <DatePicker
        className={inputClassName}
        dateFormat={format}
        disabled={disabled}
        dropdownMode="select"
        id={id}
        name={name}
        onChange={onChange}
        placeholderText={label}
        selected={value}
        showDayInMonthOnly
        showMonthDropdown
        showYearDropdown
      />
    </FormGroup>
  );
};

UserDateInput.propTypes = {
  className: PropTypes.string,
  format: PropTypes.string.isRequired,
  formError: PropTypes.instanceOf(Immutable.Map).isRequired,
  formMode: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedUser: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default UserDateInput;
