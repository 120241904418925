import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Map } from 'immutable';

import uniqueId from '../../utils/uniqueId';
import { unwrap, isChanged } from '../../utils/ChangeSpy';

/**
 * WebInput will trim off protocol in display mode,
 * but show full url in edit mode.
 */
class WebInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.touched = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.touched) {
      const { value = '' } = nextProps;

      this.setState({ value: this.getShortURL(unwrap(value)) });
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleBlur() {
    const { name, onChange } = this.props;

    if (onChange) {
      onChange({ target: { name, value: this.state.value } });
    }
    this.setState(prevState => ({ value: this.getShortURL(prevState.value) }));
  }

  handleFocus() {
    this.touched = true;
    this.setState({ value: unwrap(this.props.value) });
  }

  getShortURL(url) {
    if (!url) return '';

    const tmp = new URL(this.getLinkUrl(url));

    const shortUrl = `${tmp.host}${tmp.pathname}${tmp.search}${tmp.hash}`;

    return shortUrl[shortUrl.length - 1] === '/' ? shortUrl.slice(0, -1) : shortUrl;
  }

  getLinkUrl(value) {
    const url = unwrap(value);

    return url.indexOf('http') > -1 ? url : `http://${url}`;
  }

  render() {
    const { id, name, maxLength, value, placeholder, ...extraProps } = this.props;
    const url = this.getLinkUrl(value);
    const inputClass = classNames('form-control input-md', {
      changed: isChanged(value),
    });

    return (
      <div className="form-group full-width-xxl b-web input-group">
        <div className="clearfix">
          <span className="control-wrap">
            <input
              className={inputClass}
              id={id}
              maxLength={maxLength}
              name={name}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              placeholder={placeholder}
              type="text"
              value={this.state.value}
              {...extraProps}
            />
          </span>
        </div>
        <span className="input-group-addon">
          <a href={url} rel="noopener noreferrer" target="_blank">
            <i className="fa fa-reply" title="Open link in a new tab" />
          </a>
        </span>
      </div>
    );
  }
}

WebInput.defaultProps = {
  maxLength: 150,
  name: 'web',
  placeholder: 'Web',
  id: uniqueId(),
};

WebInput.propTypes = {
  onChange: PropTypes.func, // Spy value
  value: PropTypes.instanceOf(Map).isRequired,
};

export default WebInput;
