import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import CompaniesTable from './CompaniesTable';

const BottomBlock = props => {
  const { data, events, onYearFilterChange, onCompaniesSortingChange, onGetNextPageData, onRowDoubleClicked } = props;

  return (
    <div className="row mr1 ml1 mb20">
      <CompaniesTable
        data={data}
        events={events}
        onCompaniesSortingChange={onCompaniesSortingChange}
        onGetNextPageData={onGetNextPageData}
        onRowDoubleClicked={onRowDoubleClicked}
        onYearFilterChange={onYearFilterChange}
      />
    </div>
  );
};

BottomBlock.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  events: PropTypes.instanceOf(Array).isRequired,
  onCompaniesSortingChange: PropTypes.func,
  onGetNextPageData: PropTypes.func,
  onRowDoubleClicked: PropTypes.func,
  onYearFilterChange: PropTypes.func,
};

export default BottomBlock;
