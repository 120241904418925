import React from 'react';

import config from '../../config';
import FormGroup from '../helpers/FormGroup';
import _Select from '../helpers/Select';
import _Input from '../helpers/Input';
import { getIn } from '../../utils/ChangeSpy';
import PrettyError from '../decorators/PrettyError';
import DisplayError from '../decorators/DisplayError';

const Select = PrettyError(DisplayError(_Select));
const Input = PrettyError(DisplayError(_Input));

const types = config.values.get('companyTypes');
const tickerExchanges = config.values.getIn(['company', 'tickerExchange']);

const EmptyTicker = () => null;

const Ticker = props => (
  <div className="row">
    <FormGroup className="col-md-6">
      <Select
        defaultText=" "
        label=""
        name={props.exchangeName}
        onChange={props.onChange}
        options={tickerExchanges}
        root={props.company}
        deep
      />
    </FormGroup>
    <FormGroup className="col-md-6">
      <Input name={props.symbolName} onChange={props.onChange} placeholder="" root={props.company} deep />
    </FormGroup>
  </div>
);

export default function getTicker(company) {
  const currentType = getIn(company, 'companyType') || getIn(company, 'entityType');
  const hasTicker = types.some(type => type.get('value') === currentType && type.get('hasTicker'));

  if (!hasTicker) return EmptyTicker;

  return Ticker;
}
