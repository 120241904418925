import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { unwrap } from '../../utils/ChangeSpy';
import stripOffChars from '../../utils/stripOffChars';

const DEFAULT_COUNTRY = 'US';

export default function getPhoneChanged(phone) {
  const type = unwrap(phone.get('type', ''));
  const phoneResult = {
    unformattedPhone: unwrap(phone.get('unformattedPhone', '')),
    type: unwrap(phone.get('type', '')),
  };

  if (type === 'Extension') {
    const ext = unwrap(phone.get('extension'));
    const stringExt = ext ? ` x${ext}` : '';

    phoneResult.extension = ext;
    phoneResult.phone = `${phoneResult.unformattedPhone}${stringExt}`;
  } else if (type === 'International') {
    phoneResult.code = unwrap(phone.get('code', ''));
    phoneResult.country = unwrap(phone.get('country', ''));
    phoneResult.phone = `${unwrap(phone.get('code', ''))} ${unwrap(phone.get('unformattedPhone'))}`;
  } else {
    phoneResult.phone = phoneResult.unformattedPhone;
    if (type !== 'Custom' && phoneResult.phone.indexOf('x') > -1) {
      phoneResult.unformattedPhone = phoneResult.phone.substring(0, phoneResult.phone.indexOf('x')).trim();
      phoneResult.type = 'Extension';
      phoneResult.extension = phoneResult.phone.substring(phoneResult.phone.indexOf('x') + 1).trim();
    }
  }

  return phoneResult;
}

export function checkPhone(phone) {
  if (!phone) return false;
  if (phone.trim() === '') return false;

  const phoneNumber = parsePhoneNumberFromString(phone, DEFAULT_COUNTRY);

  if (!phoneNumber) return false;

  return phoneNumber.isValid();
}

export function checkPhoneExtension(phone) {
  let tmp = (phone || '').toLowerCase();

  if (/^\+1\s+\.*/g.test(tmp)) {
    tmp = tmp.substring(tmp.indexOf(' ') + 1).trim();
  }

  if ((tmp.match(/ext\.?/g) || []).length === 1) {
    return /^\d{10}$/g.test(stripOffChars(tmp.substring(0, tmp.indexOf('ext')), '', /[^\d]/g));
  }

  if ((tmp.match(/extension/g) || []).length === 1) {
    return /^\d{10}$/g.test(stripOffChars(tmp.substring(0, tmp.indexOf('extension')), '', /[^\d]/g));
  }

  if ((tmp.match(/x/g) || []).length === 1) {
    return /^\d{10}$/g.test(stripOffChars(tmp.substring(0, tmp.indexOf('x')), '', /[^\d]/g));
  }

  return false;
}

export function checkPhoneIfNeeded(type, phone) {
  if (['Direct', 'Mobile', 'Home', 'Fax'].indexOf(type) > -1) {
    return checkPhone(phone);
  }

  if (type === 'Extension') {
    return checkPhone(phone);
  }

  return true;
}

export function checkExtensionIfNeeded(type, ext) {
  if (type === 'Extension') {
    return /\d+/g.test(ext);
  }

  return true;
}

export function checkCountryIfNeeded(type, country) {
  const tmp = country || '';

  if (type === 'International') {
    return tmp.length > 0;
  }

  return true;
}

export function formatPhone(phone) {
  const phoneNumber = parsePhoneNumberFromString(phone, DEFAULT_COUNTRY);
  const formattedPhone = phoneNumber
    .formatNational()
    .replace('(', '')
    .replace(')', '')
    .replace(' ', '-');

  return phoneNumber.country === DEFAULT_COUNTRY ? formattedPhone : phoneNumber.formatInternational();
}

function getPhoneParts(phone, extString) {
  const index = phone.indexOf(extString);

  return [
    stripOffChars(phone.substring(0, index), '', /[^\d]/g),
    stripOffChars(phone.substring(index + 1), '', /[^\d]/g),
  ];
}

export function formatPhoneExtension(phone) {
  const tmp = phone.toLowerCase();
  let phoneParts = '';

  if ((tmp.match(/ext/g) || []).length === 1) {
    phoneParts = getPhoneParts(tmp, 'ext');
  } else if ((tmp.match(/ext\./g) || []).length === 1) {
    phoneParts = getPhoneParts(tmp, 'ext.');
  } else if ((tmp.match(/extension/g) || []).length === 1) {
    phoneParts = getPhoneParts(tmp, 'extension');
  } else if ((tmp.match(/x/g) || []).length === 1) {
    phoneParts = getPhoneParts(tmp, 'x');
  } else {
    phoneParts = [tmp, ''];
  }

  return `${formatPhone(phoneParts[0])} ${phoneParts[1] ? `x${phoneParts[1]}` : ''}`;
}

export function formatPhoneIfNeeded(type, phone) {
  if (['Direct', 'Mobile', 'Home', 'Fax'].indexOf(type) > -1) {
    return formatPhone(phone);
  }

  if (type === 'Extension') {
    return formatPhone(phone);
  }

  return phone;
}
