import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { toJS } from 'src/utils/ChangeSpy';

const DealsTable = ({ data }) => {
  const statsDeals = toJS(data.get('dealsClosed'));

  return (
    <div className="dashboard-table-wrapper mb20">
      <table className="dashboard-table table-striped-white fixed-layout">
        <thead>
          <tr className="bg-black text-white">
            <th className="pl10" colSpan="4">
              <p className="h5 text-bold">Deals Closed In</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="pb30 text-center">
              <div className="pseudo-border">
                <p className="h3 text-bold">{statsDeals.countCountries}</p>
                <p className="h5">Countries</p>
              </div>
            </td>
            <td className="pb30 text-center">
              <div className="pseudo-border">
                <p className="h3 text-bold">{statsDeals.countCities}</p>
                <p className="h5">Cities</p>
              </div>
            </td>
            <td className="pb30 text-center">
              <div className="pseudo-border">
                <p className="h3 text-bold">{statsDeals.countCanada}</p>
                <p className="h5">In Canada</p>
              </div>
            </td>
            <td className="pb30 text-center">
              <p className="h3 text-bold">{statsDeals.countEurope}</p>
              <p className="h5">In Europe</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

DealsTable.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
};

export default DealsTable;
