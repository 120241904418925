import approvals from './approval';
import project from './project';
import common from './common';
import associates from './associate';
import naPopup from './naPopup';
import statusPopup from './statusPopup';

const approvalReducer = (state = {}, action) => ({
  approvals: approvals(state.approvals, action),
  project: project(state.project, action),
  associates: associates(state.associates, action, state.common),
  naPopup: naPopup(state.naPopup, action),
  statusPopup: statusPopup(state.statusPopup, action),
  common: common(state.common, action, state.approvals),
});

export default approvalReducer;
