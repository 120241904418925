import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { fromJS, List, Map } from 'immutable';
import { debounce, bindAll } from 'underscore';

import { getModuleSuggestions, getWindwardTemplates } from '../../actions/popup/windwardReportsPopup';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import RunWindwardReportPopup from '../../components/Popup/RunWindwardReportPopupOld';

class RunWindwardReportPopupContainer extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      popupLoading: false,
      module: {
        suggestions: List(),
        pagination: {},
      },
      moduleLoading: false,
      moduleText: '',
      selectedUserId: null,
      templates: List(),
      selectedTemplate: null,
    };
    bindAll(
      this,
      'handleClose',
      'updateModuleSuggestion',
      'handleUpdateFilter',
      'handleRenderSuggestion',
      'handleGetSuggestionValue',
      'handleSuggestionSelected',
      'handleRunWindwardReport',
      'handleSelectReport',
      'handleWindwardCallback',
    );
    this.handleGetSuggestionForModule = debounce(this.handleGetSuggestionForModule.bind(this), 400);
    this.handleGetNextSuggestionForModule = debounce(this.handleGetNextSuggestionForModule.bind(this), 400);
  }

  handleClose() {
    this.context.closePopup();
  }

  handleRunWindwardReport() {
    const { selectedUserId, selectedTemplate } = this.state;
    const runWindward = this.props.popup.getIn(['props', 'callback']);

    this.setState({ popupLoading: true });
    runWindward(selectedUserId, selectedTemplate);
  }

  handleWindwardCallback() {
    this.setState({ popupLoading: false });
    this.handleClose();
  }

  updateModuleSuggestion(suggestions) {
    if (!suggestions || !suggestions.hasOwnProperty('data')) {
      return;
    }

    this.setState({
      moduleLoading: false,
      module: {
        suggestions: fromJS([...suggestions.data]),
        pagination: suggestions.meta && suggestions.meta.pagination,
      },
      templates: List(),
    });
  }

  handleUpdateFilter(suggestion) {
    if (suggestion.hasOwnProperty('text')) {
      this.setState({ moduleText: suggestion.text });
      this.handleGetSuggestionForModule({ value: suggestion.text });
    }
  }

  handleGetSuggestionForModule({ value }) {
    this.setState({ moduleLoading: true });
    this.props.getModuleSuggestion(value).then(response => this.updateModuleSuggestion(response));
  }

  handleGetNextSuggestionForModule({ value }) {
    const { pagination } = this.state.module;

    if (pagination && pagination.currentPage < pagination.totalPages) {
      this.setState({ moduleLoading: true });
      this.props.getModuleSuggestion(value).then(response => this.updateModuleSuggestion(response));
    }
  }

  handleRenderSuggestion(suggestion) {
    return <div>{suggestion.text}</div>;
  }

  handleGetSuggestionValue(suggestion) {
    return suggestion.id;
  }

  handleSuggestionSelected(_, { suggestion }) {
    if (this.state.selectedUserId === suggestion.id) {
      return;
    }

    this.setState({
      moduleText: suggestion.text,
      selectedUserId: suggestion.id,
    });

    this.props.getWindwardTemplates(suggestion.id).then(({ data }) => {
      const templates = fromJS(data.map(template => Map({ name: template, value: template })));

      this.setState({
        templates,
        selectedTemplate: templates.getIn([0, 'name']),
      });
    });
  }

  handleSelectReport({ target: { value } }) {
    this.setState({ selectedTemplate: value });
  }

  render() {
    const moduleProps = {
      suggestions: this.state.module.suggestions,
      moduleLoading: this.state.moduleLoading,
      moduleText: this.state.moduleText,
      getSuggestion: this.handleGetSuggestionForModule,
      getNextSuggestion: this.handleGetNextSuggestionForModule,
      updateSuggestions: this.handleUpdateFilter,
      renderSuggestion: this.handleRenderSuggestion,
      getSuggestionValue: this.handleGetSuggestionValue,
      suggestionSelected: this.handleSuggestionSelected,
    };

    const windwardProps = {
      disabledTemplates: this.state.selectedUserId === null,
      templates: this.state.templates,
      onSelectReport: this.handleSelectReport,
    };

    const extraProps = {
      popupLoading: this.state.popupLoading,
      onClose: this.handleClose,
      onRunWindwardReport: this.handleRunWindwardReport,
      isDisabledRunButton: this.state.selectedUserId === null && this.state.selectedTemplate === null,
    };

    return <RunWindwardReportPopup {...extraProps} {...windwardProps} {...moduleProps} />;
  }
}

RunWindwardReportPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getModuleSuggestion: (actionFrom, value) => dispatch(getModuleSuggestions(actionFrom, value)),
  getWindwardTemplates: userId => dispatch(getWindwardTemplates(userId)),
});

export { RunWindwardReportPopupContainer };
export default connect(null, mapDispatchToProps, mergeProps, connectOptions)(RunWindwardReportPopupContainer);
