import React, { PureComponent } from 'react';
import Grid from '../../../helpers/AgGridTable';
import config from '../../../../config';
import DatePickerEditor from './DatePickerEditor';
import DateRender from './DateRender';

class FundCompanies extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: this.getColumnDefs(),
    };
    this.onGridReady = this.onGridReady.bind(this);
  }

  getColumnDefs() {
    return config.tables.getIn(['fund', 'buyer', 'columnDefs', 'columns']).map(f => {
      if (f.get('field') === 'dateAcquired') {
        return f.merge({
          cellRendererFramework: DateRender,
          cellEditorFramework: DatePickerEditor,
        });
      }

      return f;
    });
  }

  componentDidMount() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    if (this.props.onRef) {
      this.props.onRef(params);
    }
  }

  getRowNodeId(data) {
    return data.order;
  }

  render() {
    const { props } = this;
    const {
      loading,
      funds,
      page,
      totalPages,
      onSort,
      onGetNext,
      onRowDoubleClick,
      onCellClick,
      onCellValueChange,
    } = props;

    return (
      <div className="flexItem scrollable buyer-fund">
        <Grid
          columnDefs={this.state.columnDefs}
          getRowNodeId={this.getRowNodeId}
          isFetching={loading}
          onCellClicked={onCellClick}
          onCellValueChanged={onCellValueChange}
          onGetNextPageData={onGetNext}
          onGridReady={this.onGridReady}
          onRowDoubleClicked={onRowDoubleClick}
          onSortModelChanged={onSort}
          page={page}
          rowData={funds}
          totalPages={totalPages}
          sortable
        />
      </div>
    );
  }
}

export default FundCompanies;
