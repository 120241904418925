import React from 'react';
import config from '../../config';

function hasNAPermission(loggedUser) {
  if (!loggedUser) return false;

  return loggedUser.get('roles').reduce((ret, role) => {
    if (
      role.get('slug') === config.PROCESS_MANAGER ||
      role.get('slug') === config.ADMINISTRATOR ||
      role.get('slug') === config.ANALYST_ASSOCIATE ||
      role.get('slug') === config.DIRECTOR ||
      role.get('slug') === config.FINANCE_ADMIN
    ) {
      return true;
    }

    return ret;
  }, false);
}

/**
 * Check for permissions to delete buyer and event.
 *
 * @param {object} loggedUser Logged user information.
 * @param {Function} func Callback function.
 * @param {object} context React context.
 */
export default function deletionCheck(loggedUser, func, context) {
  return function(...rest) {
    if (hasNAPermission(loggedUser)) {
      func(...rest);
    } else {
      context.openPopup('InformationPopup', {
        message: {
          texts: ["You don't have permission to delete these items"],
          className: 'text-danger',
        },
        header: (
          <span>
            <i aria-hidden="true" className="fa fa-exclamation-triangle text-danger" />
            Error Alert
          </span>
        ),
      });
    }
  };
}
