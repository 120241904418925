import { isDeepChanged } from '../../utils/ChangeSpy';

/**
 * Check whether or not project data need to be updated.
 *
 * @param state {Immutable.Map} Project object.
 * @returns {boolean}
 * @class
 */
function checkProject(state) {
  const fields = ['category', 'status', 'details', 'clientLead', 'harvcoLead', 'exec', 'active'];

  for (let i = 0, len = fields.length; i < len; i++) {
    if (state.getIn([fields[i], 'dirty'])) {
      return true;
    }
  }

  return false;
}

/**
 * Check whether or not industry, user, approval list data need to be updated.
 *
 * @param state {Immutable.Map} Object to be checked.
 * @returns {boolean}
 * @class
 */
function checkGeneral(state) {
  for (let i = 0, len = state.size; i < len; i++) {
    if (state.getIn([i, 'dirty'])) {
      return true;
    }
  }

  return false;
}

/**
 * Check whether or not project page need to be updated.
 *
 * @param state {Immutable.Map} Project page object.
 * @returns {boolean}
 * @class
 */
export default function detectDirty(state) {
  const { users, approvals, projectData, common, targets } = state;

  if (
    checkProject(projectData) ||
    checkGeneral(users) ||
    checkGeneral(approvals) ||
    isDeepChanged(common) ||
    isDeepChanged(targets)
  ) {
    return true;
  }

  if (state.templates) {
    let res = false;

    try {
      res = state.templates.some(template => template.get('mode') === 'edit');
      if (res) return res;
    } catch (err) {
      console.error(err);
    }
  }

  return false;
}
