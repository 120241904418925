import React from 'react';

import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import AutoComplete from '../helpers/AutoComplete';
import ErrorList from '../helpers/ErrorList';

const mimeTypes =
  '.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const UploadTemplatePopup = props => {
  const {
    fileName,
    isValid,
    inputErrors,
    isUploading,
    suggestions,
    getNextSuggestion,
    getSuggestion,
    getSuggestionValue,
    renderSuggestion,
    onClose,
    onSelectFile,
    onUpload,
  } = props;

  const suggestionProps = {
    inputProps: {
      id: 'module',
      name: 'module',
      label: 'Select Module:',
      placeholder: '',
    },
    name: 'module',
    formGroupClass: 'modal-control',
    suggestions,
    loading: props.moduleLoading,
    text: props.moduleText,
    onUpdateSuggestions: props.updateSuggestions,
    getSuggestion,
    getNextSuggestion,
    getSuggestionValue,
    renderSuggestion,
    onSuggestionSelected: props.suggestionSelected,
    suggestIfEmpty: true,
    highlightFirstSuggestion: false,
    focusInputOnSuggestionClick: false,
  };

  const header = 'Template Upload';
  const footer = [
    <Button
      key="upload"
      className="btn-primary btn-xs"
      disabled={!isValid || isUploading}
      form="uploadTemplateForm"
      type="submit"
    >
      Upload
    </Button>,
    <Button key="cancel" className="btn-default btn-xs" onClick={onClose}>
      Cancel
    </Button>,
  ];

  return (
    <Popup footer={footer} header={header} id="uploadTemplate">
      <form id="uploadTemplateForm" onSubmit={onUpload}>
        <div className="row">
          <div className="col-sm-4">
            <AutoComplete {...suggestionProps} />
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <label>&nbsp;</label>
              <label
                className="btn btn-block btn-xs btn-primary pt3 pb3"
                htmlFor="upload-template-file"
                role="button"
                tabIndex="0"
              >
                <input
                  accept={mimeTypes}
                  className="input-file-button"
                  id="upload-template-file"
                  name="file"
                  onChange={onSelectFile}
                  type="file"
                />
                Select a Template
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-offset-4">
            <p>{fileName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <ErrorList errors={inputErrors} />
          </div>
        </div>
      </form>
    </Popup>
  );
};

export default UploadTemplatePopup;
