import moment from 'moment';
import { find } from 'underscore';

import constants from '../config/constants';

const FIRST_LINE_TARGET_ACTIVITIES = constants.company.firstLineTargetActivities;

/**
 * Compute active buyer last event string.
 *
 * @param {object} data Company data.
 * @param {Array} data.events Company events list.
 */
export function getLastEventBuyerText({ data }) {
  const sortedEvents = data.events.sort((a, b) => b.date - a.date);
  const event = find(
    sortedEvents,
    event => event.completed && FIRST_LINE_TARGET_ACTIVITIES.indexOf(event.activity) !== -1,
  );

  let lastEventBuyerText = '';

  if (event) {
    const date = moment(event.date);
    const name = event.abbrName || event.legalName;

    lastEventBuyerText = `${name} ${event.activity} ${date.format('MM-DD-YYYY')}`;
  }

  return {
    lastEventBuyerText,
  };
}
