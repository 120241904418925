import PropTypes from 'prop-types';

import React from 'react';

import Popup from '../helpers/Popup';
import AutoComplete from '../helpers/AutoComplete';
import Button from '../helpers/Button';

const AssignTradeShowPopup = props => {
  const header = 'Assign Trade Show';

  const { disableAssign, onAssign, onClose, onEventSelected, suggestProps, selectedTradeShow, waiting } = props;

  const footer = [
    waiting ? (
      <Button key="save" className="btn-primary btn-xs" data-dismiss="modal" disabled>
        <i className="fa fa-spinner fa-pulse fa-fw pos-relative" /> Save
      </Button>
    ) : (
      <Button key="addTag" className="btn-primary btn-xs" disabled={disableAssign} onClick={onAssign}>
        Assign
      </Button>
    ),
    <Button key="close" className="btn-default btn-xs" disabled={waiting} onClick={onClose}>
      Close
    </Button>,
  ];

  const setEventsSelect = () => {
    if (selectedTradeShow.events && selectedTradeShow.events.length) {
      if (selectedTradeShow.events.length) {
        return (
          <div className="col-md-6">
            <label>Event</label>
            <select className="form-control" onChange={onEventSelected}>
              <option value={null} disabled selected>
                Choose event...
              </option>
              {selectedTradeShow.events.map(event => (
                <option key={event.id} value={event.id}>
                  {`${event.year} ${event.title}`}
                </option>
              ))}
            </select>
          </div>
        );
      }

      return (
        <div className="col-md-6">
          <label>Event</label>
          <select className="form-control">
            <option value={null}>No events</option>{' '}
          </select>
        </div>
      );
    }

    return <div />;
  };

  return (
    <Popup footer={footer} header={header} id="assignTradeShowPopup">
      <div className="row">
        <div className="col-md-6">
          <AutoComplete {...suggestProps} />
        </div>
        {setEventsSelect()}
      </div>
    </Popup>
  );
};

AssignTradeShowPopup.propTypes = {
  disableAssign: PropTypes.bool,
  onAssign: PropTypes.func,
  onClose: PropTypes.func,
  onEventSelected: PropTypes.func,
  selectedTradeShow: PropTypes.instanceOf(Object),
  suggestProps: PropTypes.instanceOf(Object),
  waiting: PropTypes.bool,
};

export default AssignTradeShowPopup;
