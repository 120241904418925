/**
 * To detect if browser's scrollbar is reached bottom.
 *
 * @param bottomRatio {Number} The level of bottom should be considered as bottom.
 * @returns {boolean}
 */
export default function isReachedBottom(bottomRatio = 0.8) {
  const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
  const { body } = document;
  const html = document.documentElement;
  const docHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  );
  const windowBottom = windowHeight + window.pageYOffset;

  return windowBottom / docHeight >= bottomRatio;
}

/**
 * To detect if element's scrollbar is reached bottom.
 *
 * @param element {Object} Element is being to detect.
 * @param bottomRatio {Number} The level of bottom should be considered as bottom.
 * @returns {boolean}
 */
function detectBottom(element, bottomRatio = 0.8) {
  return element.scrollTop / (element.scrollHeight - element.offsetHeight) >= bottomRatio;
}

export { detectBottom };
