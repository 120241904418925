import { abortRequest, CALL_API } from '../../../../middleware/api';
import config from '../../../../config';
import uniqueId from '../../../../utils/uniqueId';
import { loadUsers, saveUsers } from './suggestion';
import { loadApprovals, saveApproval, saveApprovalAddOnProjectCustom } from './approval';
import { loadTemplates } from './template';
import { loadTargets } from './target';
import { fetchProjectTags, saveProjectTags } from './projectTags';

export const SHOW_PROJECT_LOADING = Symbol('SHOW_PROJECT_LOADING');
export const REQUEST_API_PROJECT_FAILURE = Symbol('REQUEST_API_PROJECT_FAILURE');
export const LOADED_PROJECT_SUCCESS = Symbol('LOADED_PROJECT_SUCCESS');
export const UPDATE_PROJECT_INFORMATION = Symbol('UPDATE_PROJECT_INFORMATION');
export const CHANGE_PROJECT_ROW_MODE = Symbol('CHANGE_PROJECT_ROW_MODE ');
export const RESET_ALL_ROW_TO_VIEW_MODE = Symbol('RESET_ALL_ROW_TO_VIEW_MODE');
export const RESET_ERROR_LIST = Symbol('RESET_ERROR_LIST');
export const SAVED_PROJECT_SUCCESS = Symbol('SAVED_PROJECT_SUCCESS');

export const SELECT_PROJECT_SUGGESTION = Symbol('SELECT_PROJECT_SUGGESTION');
export const LOADING_PROJECT_SUGGESTION = Symbol('LOADING_PROJECT_SUGGESTION');
export const LOADED_PROJECT_SUGGESTION_FAILURE = Symbol('LOADED_PROJECT_SUGGESTION_FAILURE');
export const LOADED_PROJECT_SUGGESTION_SUCCESS = Symbol('LOADED_PROJECT_SUGGESTION_SUCCESS');

export const ABORT_CURRENT_REQUEST = Symbol('ABORT_CURRENT_REQUEST');

export const REVERT_CHANGES = Symbol('REVERT_CHANGES');
export const DEL_ADDON_PROJECT = Symbol('DEL_ADDON_PROJECT');

/**
 * Save project general information.
 *
 * @returns Action.
 */
export function saveProject() {
  return (dispatch, getState) => {
    const state = getState().targetCompany.buyer.addOnProject.projectData;
    const projectId = state.get('id');
    const body = {
      category: state.getIn(['category', 'name']),
      details: state.getIn(['details', 'name']),
      status: state.getIn(['status', 'name']),
      active: state.getIn(['active', 'value']),
    };
    const harvco_lead_id = state.getIn(['harvcoLead', 'id']);

    if (harvco_lead_id > 0) {
      body.harvco_lead_id = harvco_lead_id;
    }

    const client_lead_id = state.getIn(['clientLead', 'id']);

    if (client_lead_id > 0) {
      body.client_lead_id = client_lead_id;
    }

    const exec_id = state.getIn(['exec', 'id']);

    if (exec_id > 0) {
      body.exec_id = exec_id;
    }

    dispatch({
      [CALL_API]: {
        method: 'put',
        path: `/api/v1/projects/${projectId}`,
        body,
        startType: SHOW_PROJECT_LOADING,
        errorType: REQUEST_API_PROJECT_FAILURE,
        successType: SAVED_PROJECT_SUCCESS,
      },
    });
  };
}

/**
 * Prevent previous request.
 */
export function handleAbortRequest() {
  return function(dispatch) {
    dispatch({ type: ABORT_CURRENT_REQUEST });
    dispatch(abortRequest('handle approvals'));
  };
}

/**
 * Load page's data initially.
 *
 * @param id {Number} Project's id.
 * @param projectId {Number} Project id.
 * @param isDefaultAddon {Boolean} If true load approvals with params "default_approval_lists,default_no_approval_lists".
 * @returns Action.
 */
export function loadPage(id, projectId, isDefaultAddon) {
  return dispatch => {
    dispatch(clearError());
    dispatch(loadProject(id));
    dispatch(loadUsers(id));
    dispatch(fetchProjectTags(id));
    dispatch(loadTemplates(id));
    dispatch(loadTargets(id));
    dispatch(loadApprovals({ projectId, isDefaultAddon }));
  };
}

/**
 * Save all page's information.
 *
 * @param singleApprovalSave {Boolean} If true save and load single approval(with params.id).
 * @returns Action.
 */
export function savePage(singleApprovalSave) {
  return dispatch => {
    dispatch(clearError());
    dispatch(saveUsers());
    dispatch(saveProjectTags());
    dispatch(saveProject());
    if (singleApprovalSave) {
      dispatch(saveApprovalAddOnProjectCustom());
    } else {
      dispatch(saveApproval());
    }
  };
}

/**
 * Load project information.
 *
 * @param id {Number} Project's id.
 * @returns Action.
 */
export function loadProject(id) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/${id}`,
      query: { include: 'buyer,harvco_lead,client_lead,exec' },
      startType: SHOW_PROJECT_LOADING,
      errorType: REQUEST_API_PROJECT_FAILURE,
      successType: LOADED_PROJECT_SUCCESS,
    },
  };
}

function getEndPoint(field, value, buyerId) {
  if (field === 'harvcoLead') {
    return {
      path: '/api/v1/browse/users_by_role',
      query: { like: value, role: 'director' },
    };
  }

  if (field === 'exec') {
    return {
      path: '/api/v1/browse/execs',
      query: { like: value },
    };
  }

  return {
    path: `/api/v1/browse/buyers/${buyerId}/people`,
    query: { like: value },
  };
}

/**
 * Update project and suggest information based on user's input.
 *
 * @param field {String} Name of project's field.
 * @param value {Any} Value to input.
 * @param {string | null} id Project id (if it custom project).
 * @returns Action.
 */
export function updateProjectInfo(field, value, id = null) {
  const sugs = ['harvcoLead', 'clientLead', 'exec'];

  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_PROJECT_INFORMATION,
      field,
      value,
      id,
    });
    if (sugs.indexOf(field) > -1 && value && value.length >= config.NUMBER_OF_MIN_CHARACTER) {
      const buyerId = getState().targetCompany.buyer.addOnProject.projectData.get('buyerId');

      dispatch({
        [CALL_API]: {
          ...getEndPoint(field, value, buyerId),
          method: 'get',
          startType: LOADING_PROJECT_SUGGESTION,
          errorType: LOADED_PROJECT_SUGGESTION_FAILURE,
          successType: LOADED_PROJECT_SUGGESTION_SUCCESS,
        },
        requestId: uniqueId(),
        field,
      });
    }
  };
}

/**
 * Switch project's row to loading mode.
 *
 * @param field {String} Field name.
 * @returns Action.
 */
export function switchProjectRowToLoading(field) {
  return {
    type: CHANGE_PROJECT_ROW_MODE,
    field,
    mode: config.LOADING_MODE,
  };
}

/**
 * Switch project's row to suggest mode.
 *
 * @param field {String} Field name.
 * @returns Action.
 */
export function switchProjectRowToSuggest(field) {
  return {
    type: CHANGE_PROJECT_ROW_MODE,
    field,
    mode: config.SUGGEST_MODE,
  };
}

/**
 * Switch all rows to view mode.
 *
 * @returns Action.
 */
export function reset() {
  return {
    type: RESET_ALL_ROW_TO_VIEW_MODE,
  };
}

/**
 * Select a suggestion.
 *
 * @param field {String(exec|harvcoLead|clientLead)} Project field.
 * @param suggestionId {Number} Suggestion id.
 * @param suggestionName {String} Suggestion name.
 * @returns Action.
 */
export function selectProjectSuggestion(field, suggestionId, suggestionName) {
  return {
    type: SELECT_PROJECT_SUGGESTION,
    field,
    suggestionId,
    suggestionName,
  };
}

/**
 * Reset error list.
 *
 * @returns Action.
 */
export function clearError() {
  return {
    type: RESET_ERROR_LIST,
  };
}

/**
 * Revert changes which didn't save.
 */
export function revertChanges() {
  return {
    type: REVERT_CHANGES,
  };
}

/**
 * Remove add-on project tab.
 *
 * @param addonProjectId {Number} AddonProjectId.
 * @param afterSuccess {Function} Callback.
 * @returns {{addonProjectId: *, MiddlewareApi.CallApi}}
 */
export function deleteAddonProject({ addonProjectId, afterSuccess }) {
  return {
    [CALL_API]: {
      method: 'delete',
      path: `/api/v1/projects/${addonProjectId}`,
      successType: DEL_ADDON_PROJECT,
      afterSuccess: ({ dispatch, ...r }, ...rest) => {
        afterSuccess({ dispatch, ...r }, ...rest);
      },
    },
  };
}
