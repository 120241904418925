import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fromJS, Map } from 'immutable';
import moment from 'moment';
import shortcut from 'react-shortcut-hoc';

import InputErrorDisplay from '../decorators/InputErrorDisplay';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import AutoCompleteContainer from '../AutoComplete';
import { unwrap, getChanged, getIn } from '../../utils/ChangeSpy';
import Popups from '../../components/Popup';
import {
  resetCurrentEvent,
  startEditCompanyEvent,
  changeCompanyEvent,
  saveCompanyEvent,
  findDirectors,
  findUsers,
  findAnalysts,
  closeValidationError,
  reloadEvents,
  findDealMakers,
} from '../../actions/company/companyEvents';
import isVisible from '../../utils/isVisible';

import { publish } from '../../decorators/tabChange';
import { showInformation } from '../../utils/MessagePopup';

const DATE_FORMAT = 'YYYY-MM-DD';

const MAX_VALUE = 1000;

const SUCCESSFUL_ACTIVITY_REPLICATION_MESSAGE = `<h4>Completed activity has been successfully replicated to the company(ies):</h4>`;
const FAILURE_ACTIVITY_REPLICATION_MESSAGE = `<h4>The following company(ies) cannot be used for the activity replication:</h4>`;

class EventCompanyPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      firstTime: true,
      selectedCompaniesIds: [],
    };

    this.onSuggestChange = this.onSuggestChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onActivityChange = this.onActivityChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onClose = this.onClose.bind(this);
    this.promptEvent = this.promptEvent.bind(this);
    this.isNaExisting = this.isNaExisting.bind(this);
    this.initPopupData = this.initPopupData.bind(this);
    this.saveCommandCapture = this.saveCommandCapture.bind(this);
    this.moveFocus = this.moveFocus.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.onCloseEventPopup = this.onCloseEventPopup.bind(this);
    this.onShowNextActionConfirmPopup = this.onShowNextActionConfirmPopup.bind(this);
    this.handleAddBuyerNote = this.handleAddBuyerNote.bind(this);
    this.handleChangeCompanyIds = this.handleChangeCompanyIds.bind(this);
    this.shouldPrompt = false;
    this.getReplicatedCompaniesMessages = this.getReplicatedCompaniesMessages.bind(this);
    this.getCompanyEventMessage = this.getCompanyEventMessage.bind(this);
    this.getCompanyEventMessages = this.getCompanyEventMessages.bind(this);
    this.reopenPopup = this.reopenPopup.bind(this);
  }

  /**
   * Let fill out default director, analyst and user data if users create a new event.
   */
  componentDidMount() {
    const {
      popup,
      companyInfo,
      companyBuyer,
      companyContacts,
      defaultAnalyst,
      defaultDealMaker,
      defaultDirector,
      startEditCompanyEvent,
    } = this.props;
    const approach = popup.getIn(['props', 'approach']);
    const eventId = popup.getIn(['props', 'event', 'id']);

    const extra = {
      defaultUser: {
        id: this.context.currentUser.get('id'),
        text: this.context.currentUser.get('userName'),
      },
    };

    if (!eventId) {
      extra.defaultAnalyst = defaultAnalyst;
      extra.defaultDirector = defaultDirector;
      extra.defaultDealMaker = defaultDealMaker;
    }

    if (this.isNaExisting()) {
      extra.completed = true;
    }

    startEditCompanyEvent({
      eventId,
      approach,
      companyInfo,
      companyBuyer,
      companyContacts,
      ...extra,
    });

    this.context.onClosePopup(this.onCloseEventPopup);
  }

  componentDidUpdate(prevProps) {
    const { events, findUsers } = this.props;
    const previousValue = prevProps.events.getIn(['current', 'userName', 'value']);
    const currentValue = events.getIn(['current', 'userName', 'value']);
    const currentId = events.getIn(['current', 'userId', 'value']);

    if (this.state.firstTime && previousValue !== currentValue) {
      this.setState({ firstTime: false });
      findUsers({
        filter: currentValue,
        page: 1,
        field: 'current.userName',
        afterSuccess: ({ response: { data } }) => {
          if (data.length === 0 || data.some(object => object.id === currentId)) return;
          this.onSuggestChange({
            name: 'current.userName',
            value: data[0].text,
          });
        },
      });
    }

    if (this.needMoveFocusToNext) this.moveFocus();
    this.needMoveFocusToNext = false;
  }

  onShowNextActionConfirmPopup() {
    this.context.openPopup('ConfirmPopup', {
      message:
        'Warning: You are about to close this window without setting a Next Action. Are you sure you wish to proceed?',
      onOk: this.onClose,
      onCancel: this.props.popup.getIn(['props', 'onReopen'], () => {}),
      yes: 'Yes',
      no: 'No',
    });
  }

  onCloseEventPopup() {
    if (!this.isNaExisting()) return this.onShowNextActionConfirmPopup();

    // Timeout prevents call maximum stack size.
    setTimeout(() => {
      this.onClose();
    }, 0);
  }

  saveCommandCapture(event) {
    const { events } = this.props;
    const currentEvent = events.get('current');
    const hasEverNext =
      events.get('all').filter(event => event.get('id') !== currentEvent.get('id') && unwrap(event.get('isNext')))
        .size > 0;

    if (this.isValid(currentEvent, hasEverNext)) {
      this.onSave(event);
    }
  }

  initPopupData() {
    this.props.changeCompanyEvent({
      field: 'userName',
      value: this.context.currentUser.get('userName'),
    });
    this.props.changeCompanyEvent({
      field: 'userId',
      value: this.context.currentUser.get('id'),
    });

    if (this.props.analyst) {
      this.props.changeCompanyEvent({
        field: 'analystName',
        value: this.props.analyst.text,
      });
      this.props.changeCompanyEvent({
        field: 'recordSubOwnerId',
        value: this.props.analyst.id,
      });
    }

    if (this.props.director) {
      this.props.changeCompanyEvent({
        field: 'directorName',
        value: this.props.director.text,
      });
      this.props.changeCompanyEvent({
        field: 'recordOwnerId',
        value: this.props.director.id,
      });
    }
  }

  onChange(event) {
    const { name, type, checked, value } = event.target;

    this.props.changeCompanyEvent({
      field: name,
      value: type && type.toUpperCase() === 'CHECKBOX' ? checked : value,
    });

    if (name === 'completed' && checked) {
      this.props.changeCompanyEvent({
        field: 'date',
        value: moment(),
      });
      this.props.changeCompanyEvent({
        field: 'userName',
        value: this.props.loggedUser.get('userName'),
      });
      this.props.changeCompanyEvent({
        field: 'userId',
        value: this.props.loggedUser.get('id'),
      });
    }
  }

  onActivityChange(data) {
    this.props.changeCompanyEvent({
      field: 'activityMap',
      value: data,
    });
    this.props.changeCompanyEvent({
      field: 'emailText.value',
      value: '',
    });
    if (data.selected && data.selected.value) {
      this.moveFocus();
    }
  }

  onSuggestChange({ name, value }) {
    return this.props.changeCompanyEvent({ field: name, value });
  }

  getSuggests() {
    return {
      suggestUser: this.getUserSuggests(),
      suggestDirector: this.getModuleSuggests(),
      suggestAnalyst: this.getAnalystSuggests(),
      suggestDealMaker: this.getDealMakerSuggests(),
    };
  }

  getUserSuggests() {
    const prefix = 'current.';

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={opts => {
          this.props.findUsers({
            ...opts,
            field: `${prefix}userName`,
          });
        }}
        idName={`${prefix}userId`}
        inputProps={{
          name: 'userName',
          className: 'form-control',
          label: 'Completed by',
          tabIndex: -1,
        }}
        name={`${prefix}userName`}
        rootPath={['targetCompany', 'events']}
        suggestsName={`${prefix}userSuggests`}
        valueName={`${prefix}userName`}
      />
    );
  }

  getModuleSuggests() {
    const prefix = 'current.';

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={opts => {
          this.props.findDirectors({
            ...opts,
            field: `${prefix}directorName`,
          });
        }}
        idName={`${prefix}recordOwnerId`}
        inputProps={{
          name: 'directorName',
          placeholder: 'Module',
          className: 'form-control',
          label: 'Team',
        }}
        name={`${prefix}directorName`}
        rootPath={['targetCompany', 'events']}
        suggestsName={`${prefix}directorSuggests`}
        valueName={`${prefix}directorName`}
      />
    );
  }

  getAnalystSuggests() {
    const prefix = 'current.';

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={opts => {
          this.props.findAnalysts({
            ...opts,
            field: `${prefix}analystName`,
          });
        }}
        idName={`${prefix}recordSubOwnerId`}
        inputProps={{
          name: 'analystName',
          placeholder: 'Analyst',
          className: 'form-control',
        }}
        name={`${prefix}analystName`}
        rootPath={['targetCompany', 'events']}
        suggestsName={`${prefix}analystSuggests`}
        valueName={`${prefix}analystName`}
      />
    );
  }

  getDealMakerSuggests() {
    const prefix = 'current.';

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={opts => {
          this.props.findDealMakers({
            ...opts,
            field: `${prefix}dealMakerName`,
          });
        }}
        idName={`${prefix}dealMakerId`}
        inputProps={{
          name: 'dealMakerName',
          placeholder: 'Dealmaker',
          className: 'form-control',
        }}
        name={`${prefix}dealMakerName`}
        rootPath={['targetCompany', 'events']}
        suggestsName={`${prefix}dealMakerSuggests`}
        valueName={`${prefix}dealMakerName`}
      />
    );
  }

  handleChangeCompanyIds(event) {
    const { selectedCompaniesIds } = this.state;
    const value = Number(event.target.value);

    if (selectedCompaniesIds.includes(value)) {
      const removedId = selectedCompaniesIds.filter(id => id !== value);

      this.setState({ selectedCompaniesIds: removedId });
    } else {
      this.setState({ selectedCompaniesIds: [...selectedCompaniesIds, value] });
    }
  }

  onSave(event) {
    event.preventDefault();

    const { selectedCompaniesIds } = this.state;
    const { events, entityType, entityId, saveCompanyEvent } = this.props;

    const eventCurrent = events.get('current');
    const current = getChanged(eventCurrent);

    if (current.date) {
      current.date = current.date.format(DATE_FORMAT);
    }

    if (current.projectedCloseDate) {
      current.projectedCloseDate = current.projectedCloseDate.format(DATE_FORMAT);
    }

    if (current.projectedCloseDate === '') {
      current.projectedCloseDate = null;
    }

    if (current.contactId === undefined || current.contactId === 'undefined') {
      current.contactId = null;
    }

    if (current.transactionCloseDate) {
      current.transactionCloseDate = current.transactionCloseDate.format(DATE_FORMAT);
    }

    Object.keys(current).forEach(key => {
      if (/Name$/.test(key)) {
        delete current[key];
      }
    });
    delete current.activityMap;

    const body = {
      ...current,
      id: events.getIn(['current', 'id']),
      approach: events.getIn(['current', 'approach']),
      activity: events.getIn(['current', 'activityMap', 'selected', 'name']),
      meta: false,
      backlogged: getIn(events, ['current', 'backlogged'], false) || false,
      selectedCompaniesIds,
    };

    if (body.contactId) {
      body[`${body.approach}ContactId`] = body.contactId;
    }

    const form = event.target;

    for (let i = 0; i < form.length; i++) {
      if (!isVisible(form[i]) && form[i].name !== 'description') delete body[form[i].name];
    }
    ['recordOwnerId', 'recordSubOwnerId', 'dealMakerId'].forEach(field => {
      body[field] = body[field] || undefined;
    });

    saveCompanyEvent({
      entityType,
      entityId,
      body,
      afterSuccess: ({ response }) => this.promptEvent(body, response),
      afterError: ({ resBody }) => {
        this.context.openPopup('InformationPopup', {
          message: {
            subheader: resBody.message
              ? resBody.message
              : 'Backlogging Failed due to  missing data, and no targets were Backlogged. The following profiles were missing information:',
            texts: resBody.errors.map(error => error.toString()),
            className: 'text-danger',
          },
          header: (
            <span>
              <i aria-hidden="true" className="fa fa-exclamation-triangle text-danger" />
              Error Alert
            </span>
          ),
          onClosePopupCallBack: () => {
            this.reopenPopup();
          },
        });
      },
    });
  }

  isNaExisting() {
    return this.props.events.get('all').filter(event => unwrap(event.get('isNext'))).size > 0;
  }

  /**
   * Return company-based information message string.
   *
   * @param {string} name Company name.
   * @param {number} id Company id.
   */
  getCompanyEventMessage({ companyLegalName, companyId }) {
    const { pathname, origin } = window.location;
    const endPath = pathname.split('/').pop() === 'target' ? 'target' : 'buyer';

    return `${companyLegalName} ( ${origin}/company/${companyId}/${endPath} )`;
  }

  /**
   * Configure messages section by company events.
   *
   * @param {Array} events Company events list.
   * @param {string} partitionHeader Messages partition header.
   */
  getCompanyEventMessages(events, partitionHeader) {
    const informationTexts = [];

    if (events.length > 0) {
      informationTexts.push(partitionHeader);

      const messages = events.map(event => this.getCompanyEventMessage(event));

      informationTexts.push(...messages);
    }

    return informationTexts;
  }

  /**
   * Parse companies events from the request payload and configure alert messages.
   *
   * @param {object} saveResponse Save company event response payload.
   */
  getReplicatedCompaniesMessages(saveResponse) {
    const messages = [];

    if (saveResponse && saveResponse.hasOwnProperty('replicatedCompanies')) {
      const { replicatedCompanies } = saveResponse;

      if (Object.entries(replicatedCompanies).length > 0) {
        const { addedEvents, skipped } = replicatedCompanies;

        const addedEventsMessages = this.getCompanyEventMessages(addedEvents, SUCCESSFUL_ACTIVITY_REPLICATION_MESSAGE);
        const skippedMessages = this.getCompanyEventMessages(skipped, FAILURE_ACTIVITY_REPLICATION_MESSAGE);

        messages.push(...addedEventsMessages, ...skippedMessages);
      }
    }

    return messages;
  }

  /**
   * Resolve reopening of the information popup.
   *
   * @param {object} approach Company approach type.
   */
  reopenPopup(approach) {
    if (this.isNaExisting()) return this.onCloseEventPopup();

    const {
      companyInfo,
      companyBuyer,
      companyContacts,
      defaultAnalyst,
      defaultDealMaker,
      defaultDirector,
      startEditCompanyEvent,
      popup,
    } = this.props;
    const { currentUser } = this.context;

    popup.getIn(['props', 'onReopen'], () => {})(); // reopen popup

    const extra = {
      defaultUser: {
        id: currentUser.get('id'),
        text: currentUser.get('userName'),
      },
      defaultAnalyst,
      defaultDealMaker,
      defaultDirector,
    };

    startEditCompanyEvent({
      approach,
      companyInfo,
      companyBuyer,
      companyContacts,
      ...extra,
    });

    this.context.onClosePopup(this.onCloseEventPopup);
  }

  /**
   * Resolve callback after the save company request.
   *
   * @param {object} body Save company event request body.
   * @param {object} saveResponse Save company event response payload.
   */
  promptEvent(body, saveResponse) {
    const { notifyTab, entityId, reloadEvents } = this.props;
    const { openPopup } = this.context;
    const messages = this.getReplicatedCompaniesMessages(saveResponse);

    notifyTab({ id: body.id }, 'onEventChange');

    reloadEvents({
      entityId,
      afterSuccess: () => {
        this.onClose();

        if (messages.length > 0) {
          showInformation(openPopup, messages.join('\n'), () => {
            this.reopenPopup(body.approach);
          });
        } else {
          this.reopenPopup(body.approach);
        }
      },
    });
  }

  /** Close popup and reset event data. */
  onClose() {
    this.props.resetCurrentEvent();
    this.context.closePopup();
  }

  isValidDate(date) {
    if (!date) return false;

    return !!date.isValid && date.isValid();
  }

  isValid(event, hasEverNext) {
    if (!this.isValidDate(unwrap(event.get('date')))) return false;
    if (unwrap(event.get('transactionValueAtClose')) > MAX_VALUE) return false;
    if (unwrap(event.get('ttmEbitdaAtClose')) > MAX_VALUE) return false;
    if (hasEverNext && !unwrap(event.get('completed'))) return false;

    return !!unwrap(event.getIn(['activityMap', 'selected', 'value']));
  }

  handleNext() {
    this.moveFocus();
  }

  moveFocus() {
    const element = document.querySelector('textarea[name="description"]');

    // Check if element is visible in DOM, otherwise delay moving focus until DOM updated
    if (element && element.offsetParent) {
      element.focus();
    } else {
      this.needMoveFocusToNext = true;
    }
  }

  handleAddBuyerNote() {
    const showEmail = !this.props.events.getIn(['current', 'activityMap', 'selected', 'showEmail']);

    this.props.changeCompanyEvent({
      field: 'activityMap.selected.showEmail',
      value: showEmail,
    });
  }

  render() {
    const { children, companyContacts, events, buyer } = this.props;
    const currentEvent = events.get('current');
    const hasEverNext =
      events.get('all').filter(event => event.get('id') !== currentEvent.get('id') && unwrap(event.get('isNext')))
        .size > 0;

    const contacts = companyContacts
      .map(contact =>
        fromJS({
          name: unwrap(contact.get('fullName')),
          value: contact.get('id'),
        }),
      )
      .insert(0, fromJS({ name: 'Select a Contact', value: '' }));
    const canSave = this.isValid(currentEvent, hasEverNext);

    return (
      <div>
        <Popups.EventPopup
          buyer={buyer}
          event={currentEvent}
          {...this.getSuggests()}
          canSave={canSave}
          contacts={contacts}
          hasEverNext={hasEverNext}
          loading={currentEvent.get('isLoading')}
          onActivityChange={this.onActivityChange}
          onAddBuyerNote={this.handleAddBuyerNote}
          onChange={this.onChange}
          onChangeCompanyIds={this.handleChangeCompanyIds}
          onClose={this.onCloseEventPopup}
          onNext={this.handleNext}
          onSave={this.onSave}
          shouldPrompt={this.shouldPrompt}
        />
        {children}
      </div>
    );
  }
}

EventCompanyPopupContainer.contextTypes = {
  currentUser: PropTypes.instanceOf(Map).isRequired,
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    ...props,
    defaultDirector: {
      id: state.targetCompany.target.getIn(['recordOwnerId', 'value']),
      text: state.targetCompany.target.getIn(['suggestDirector', 'value']),
    },
    defaultAnalyst: {
      id: state.targetCompany.target.getIn(['recordSubOwnerId', 'value']),
      text: state.targetCompany.target.getIn(['suggestAnalyst', 'value']),
    },
    defaultDealMaker: {
      id: state.targetCompany.target.getIn(['dealMakerId', 'value']),
      text: state.targetCompany.target.getIn(['suggestDealMaker', 'value']),
    },
    buyer: state.targetCompany.buyer.info,
    companyInfo: state.targetCompany.info.getIn(['info']),
    companyBuyer: state.targetCompany.target.getIn(['buyers', 0]),
    companyContacts: state.targetCompany.target.getIn(['contacts']),
    entityId: state.targetCompany.info.getIn(['info', 'id']),
    events: state.targetCompany.events,
    inputErrors: state.targetCompany.events.getIn(['current', 'inputErrors']),
    loggedUser: state.auth.get('user'),
  };
}

export { EventCompanyPopupContainer };

const shortcutWrap = publish(shortcut(EventCompanyPopupContainer, 'ctrl+enter', 'saveCommandCapture'));

export default connect(
  mapStateToProps,
  {
    reloadEvents,
    startEditCompanyEvent,
    changeCompanyEvent,
    saveCompanyEvent,
    findDirectors,
    findUsers,
    findAnalysts,
    findDealMakers,
    closeValidationError,
    resetCurrentEvent,
  },
  mergeProps,
  connectOptions,
)(InputErrorDisplay('closeValidationError', shortcutWrap));
