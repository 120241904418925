import { CALL_API } from '../../../middleware/api';

export const LEAD_MEMO_REPORT_REQUEST = Symbol('LEAD_MEMO_REPORT_REQUEST');

/**
 * Send lead memo request action.
 *
 * @param {object} body Request body.
 * @param {number} body.companyId Company id.
 * @param {Function} afterSuccess Callback after success request.
 */
export function leadMemoReportRequest({ companyId }, afterSuccess, afterError) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/windward/lead_memo',
      body: {
        companyId: Number(companyId),
      },
      afterSuccess,
      afterError,
    },
  };
}
