import { clone } from 'underscore';
import { CALL_API } from '../../../middleware/api';

export const FETCHING_CEO_CURRENT_EXECUTIVE_LEADS = Symbol('FETCHING_CEO_CURRENT_EXECUTIVE_LEADS');
export const FETCHING_CEO_CURRENT_EXECUTIVE_LEADS_SUCCESS = Symbol('FETCHING_CEO_CURRENT_EXECUTIVE_LEADS_SUCCESS');
export const FETCHING_CEO_CURRENT_EXECUTIVE_LEADS_FAILURE = Symbol('FETCHING_CEO_CURRENT_EXECUTIVE_LEADS_FAILURE');
export const FETCHING_CEO_ACTIVE_PROJECTS = Symbol('FETCHING_CEO_ACTIVE_PROJECTS');
export const FETCHING_CEO_ACTIVE_PROJECTS_SUCCESS = Symbol('FETCHING_CEO_ACTIVE_PROJECTS_SUCCESS');
export const FETCHING_CEO_ACTIVE_PROJECTS_FAILURE = Symbol('FETCHING_CEO_ACTIVE_PROJECTS_FAILURE');
export const UPDATE_CEO = Symbol('UPDATE_CEO');
export const UPDATE_COMPANY = Symbol('UPDATE_COMPANY');
export const SAVED_COMPANY_BUYER = Symbol('SAVED_COMPANY_BUYER');
export const ERRORED_COMPANY_BUYER = Symbol('ERRORED_COMPANY_BUYER');
export const SAVE_CEO_PROJECTS_SUCCESS = Symbol('SAVE_CEO_PROJECTS_SUCCESS');
export const SAVE_CEO_PROJECTS_FAILURE = Symbol('SAVE_CEO_PROJECTS_FAILURE');
export const FETCHING_SUGGESTIONS_SUCCESS = Symbol('FETCHING_SUGGESTIONS_SUCCESS');
export const FETCHING_CLIENT_SUGGESTIONS_SUCCESS = Symbol('FETCHING_CLIENT_SUGGESTIONS_SUCCESS');
export const FETCHING_CLIENT_SUGGESTIONS = Symbol('FETCHING_CLIENT_SUGGESTIONS');
export const FETCHING_SUGGESTIONS = Symbol('FETCHING_SUGGESTIONS');
export const CLEAR_HARVCO_LEAD_SUGGESTIONS = Symbol('CLEAR_HARVCO_LEAD_SUGGESTIONS');
export const CLEAR_CLIENT_LEAD_SUGGESTIONS = Symbol('CLEAR_CLIENT_LEAD_SUGGESTIONS');
export const SELECT_CLIENT_LEAD_SUGGESTIONS = Symbol('SELECT_CLIENT_LEAD_SUGGESTIONS');
export const SELECT_HARVCO_LEAD_SUGGESTIONS = Symbol('SELECT_HARVCO_LEAD_SUGGESTIONS');

export function getCurrentExecutiveLeads({ companyId, page = 1, sortBy = null }) {
  const query = {
    page,
    limit: 100,
  };

  if (sortBy) {
    query.sort = sortBy;
  }

  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies/${companyId}/buyer_exec_leads`,
      query,
      startType: FETCHING_CEO_CURRENT_EXECUTIVE_LEADS,
      errorType: FETCHING_CEO_CURRENT_EXECUTIVE_LEADS_FAILURE,
      successType: FETCHING_CEO_CURRENT_EXECUTIVE_LEADS_SUCCESS,
    },
  };
}

export function getActiveProjects({ companyId, page = 1, sortBy = null }) {
  const query = {
    page,
    limit: 100,
  };

  if (sortBy) {
    query.sort = sortBy;
  }

  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies/${companyId}/eprojects`,
      query,
      startType: FETCHING_CEO_ACTIVE_PROJECTS,
      errorType: FETCHING_CEO_ACTIVE_PROJECTS_FAILURE,
      successType: FETCHING_CEO_ACTIVE_PROJECTS_SUCCESS,
    },
  };
}

export function createCEOProject({ company_id, body, callback: { beforeStart, afterSuccess, afterError } }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/companies/${company_id}/bulk/eproject`,
      body,
      beforeStart,
      afterSuccess,
      afterError,
    },
  };
}

export function saveCEOProject({ companyId, body }) {
  let requestBody = clone(body);

  requestBody = requestBody.map(item => {
    if (item.clientLeadId === '') {
      delete item.clientLeadId;
    }

    if (item.harvcoLeadId === '') {
      delete item.harvcoLeadId;
    }

    return item;
  });

  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/companies/${companyId}/bulk/eproject`,
      body: {
        data: requestBody,
      },
      successType: SAVE_CEO_PROJECTS_SUCCESS,
      errorType: SAVE_CEO_PROJECTS_FAILURE,
    },
    body,
  };
}

export function updateCEO({ filterName, filterData }) {
  return {
    type: UPDATE_CEO,
    filterName,
    filterData,
  };
}

export function handleUpdateCompany(info) {
  return {
    type: UPDATE_COMPANY,
    ...info,
  };
}

export function saveInfo({ companyId, body }) {
  const requestBody = clone(body);

  if (requestBody.ceo123) {
    requestBody.ceo_123 = requestBody.ceo123;
    delete requestBody.ceo123;
  }

  return {
    fields: Object.keys(body),
    body,
    [CALL_API]: {
      method: 'put',
      unifier: `buyer put /api/v1/companies/${companyId} {${JSON.stringify(requestBody)}}`,
      path: `/api/v1/companies/${companyId}`,
      body: requestBody,
      successType: SAVED_COMPANY_BUYER,
      errorType: ERRORED_COMPANY_BUYER,
      skipGlobalErrorHandler: true,
    },
  };
}

export function handleGetHarvcoLeadSuggestion({ value, data }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/users_by_role?like=${value}&role=director`,
      startType: FETCHING_SUGGESTIONS,
      successType: FETCHING_SUGGESTIONS_SUCCESS,
    },
    data,
  };
}

export function clearHarvcoLeadSuggestion() {
  return {
    type: CLEAR_HARVCO_LEAD_SUGGESTIONS,
  };
}

export function handleGetClientLeadSuggestion({ value, data }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/buyers/${data.buyerId}/people?like=${value}`,
      startType: FETCHING_CLIENT_SUGGESTIONS,
      successType: FETCHING_CLIENT_SUGGESTIONS_SUCCESS,
    },
  };
}

export function clearClientLeadSuggestion() {
  return {
    type: CLEAR_CLIENT_LEAD_SUGGESTIONS,
  };
}

export function selectClientLeadSuggestion({ ...rest }) {
  return {
    type: SELECT_CLIENT_LEAD_SUGGESTIONS,
    ...rest,
  };
}

export function selectHarvcoLeadSuggestion({ ...rest }) {
  return {
    type: SELECT_HARVCO_LEAD_SUGGESTIONS,
    ...rest,
  };
}
