import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CompanyTarget from '../containers/Company/CompanyTarget';
import CompanyBuyer from '../containers/Company/CompanyBuyer';
import CompanyExecutive from '../containers/Company/CompanyExecutive';

export default function CompanyRouting() {
  return (
    <Switch>
      <Route component={CompanyTarget} path="/company/:companyId/target" />
      <Route component={CompanyBuyer} path="/company/:companyId/buyer" />
      <Route component={CompanyExecutive} path="/company/:companyId/executive" />
    </Switch>
  );
}
