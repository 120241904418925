import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Immutable from 'immutable';
import classNames from 'classnames';

import Spinner from '../../helpers/Spinner';
import config from '../../../config';
import { execIfFunction } from '../../../utils/isFunction';
import TemplateTableRow from '../MidPanel/TemplateTableRow';
import OneColTableRow from '../MidPanel/OneColTableRow';

const types = config.values.getIn(['project', 'types']);

/**
 * Table with only one column component.
 *
 * @param props {Object}.
 * @param title {String} Table title.
 * @param rows {Immutable.List} Table row list.
 * @param contextId {String} Context menu's id.
 * @param isLoading {Boolean} Show spinner icon if table is being loaded data.
 * @param suggestions {Immutable.List} Suggestion list.
 * @param type {String} Use type to differentiate which tables are firing events.
 * @param onRowClick {Function} Handle event of clicking on a table row.
 * @param onRowDoubleClick {Function} Handle event of double clicking on a table row.
 * @param onRowInsert {Function} Handle event of adding a new row.
 * @param onSuggestionClose {Function} Handle event of closing suggestion.
 * @param onTextChange {Function} Handle event of changing text.
 * @param onSuggestionSelect {Function} Handle event of selecting a suggestion.
 * @param onKeyPress {Function} Handle event of key pressing.
 * @returns {React.Component}
 * @class
 */
class OneColTable extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleRowInsert = this.handleRowInsert.bind(this);
    this.setInputElement = this.setInputElement.bind(this);
    this.setContainerElement = this.setContainerElement.bind(this);
  }

  componentDidUpdate() {
    if (this.props.rows.findLastIndex(r => r.get('id') === -1) > 0) {
      this.container.scrollTop = this.container.scrollHeight;
    }

    if (this.inputElement) {
      this.inputElement.focus();
    }
  }

  handleRowInsert(event) {
    event.preventDefault();
    event.stopPropagation();

    const { onRowInsert, type } = this.props;

    execIfFunction(onRowInsert, type);
  }

  setInputElement(input) {
    this.inputElement = input;
  }

  setContainerElement(input) {
    this.container = input;
  }

  render() {
    const {
      title,
      rows,
      contextId,
      isLoading,
      suggestions,
      type,
      onRowClick,
      onRowDoubleClick,
      onSuggestionClose,
      onTextChange,
      onSuggestionSelect,
      onTemplateUpdate,
      onKeyPress,
      skipMenu,
      changed = false,
    } = this.props;
    const noItems = rows.size === 0 ? <div className="table-message">No items to display</div> : null;
    const spinner = isLoading ? <Spinner /> : null;
    let rowContents = null;

    if (type === types.get('TEMPLATES')) {
      rowContents = rows.map((row, index) => (
        <TemplateTableRow
          key={index}
          contextId={contextId}
          onKeyPress={onKeyPress}
          onRowClick={onRowClick}
          onTemplateUpdate={onTemplateUpdate}
          onTextChange={onTextChange}
          row={row}
          setInputElement={this.setInputElement}
          type={type}
        />
      ));
    } else {
      rowContents = rows.map((row, index) => (
        <OneColTableRow
          key={index}
          contextId={contextId}
          onRowClick={onRowClick}
          onRowDoubleClick={onRowDoubleClick}
          onSuggestionClose={onSuggestionClose}
          onSuggestionSelect={onSuggestionSelect}
          onTextChange={onTextChange}
          row={row}
          setInputElement={this.setInputElement}
          skipMenu={skipMenu}
          suggestions={suggestions}
          type={type}
        />
      ));
    }

    const changedClass = classNames({ changed });

    return (
      <div className="col-md-2">
        <label>&nbsp;</label>
        <div ref={this.setContainerElement} className="table-edit-one-col">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>
                  <span className="add-row" onClick={this.handleRowInsert} title="Add New">
                    +
                  </span>
                  <span className={changedClass}>{title}</span>
                </th>
              </tr>
            </thead>
            <tbody>{rowContents}</tbody>
          </table>
          {noItems}
          {spinner}
        </div>
      </div>
    );
  }
}

OneColTable.propTypes = {
  contextId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onKeyPress: PropTypes.func,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowInsert: PropTypes.func,
  onSuggestionClose: PropTypes.func,
  onSuggestionSelect: PropTypes.func,
  onTemplateUpdate: PropTypes.func,
  onTextChange: PropTypes.func,
  rows: PropTypes.instanceOf(Immutable.List).isRequired,
  suggestions: PropTypes.instanceOf(Immutable.List),
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default OneColTable;
