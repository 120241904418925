import React from 'react';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import DropDown, { DropDownElement } from '../helpers/DropDown';
import Button from '../helpers/Button';
import AutoComplete from '../helpers/AutoComplete';
import _Input from '../helpers/Input';
import DisplayError from '../decorators/DisplayError';
import PrettyError from '../decorators/PrettyError';
import _Board from './BIC';

const Board = PrettyError(DisplayError(_Board));
const Input = PrettyError(DisplayError(_Input));

const renderSuggestion = suggest => <span>{suggest.text}</span>;

const AddExecutiveContactPopup = props => {
  const {
    onChangeInfo,
    contact,
    isValid,
    executive,
    suggestDirector,
    suggestAnalyst,
    suggestSuffix,
    onCreate,
    closable,
    submitting,
    onFocus,
    onBlur,
    tags,
    models,
    onBICClick,
    onFindEmail,
  } = props;

  const prefix = contact.get('prefix');
  const prefixes = contact.get('prefixes');

  const suggestions = executive.get('suggests');

  const header = 'Create new Executive profile';
  const onClickProps = submitting ? {} : { onClick: onCreate };
  const footer = (
    <Button {...onClickProps} className="btn-primary btn-xs" disabled={!isValid || submitting}>
      Create {submitting ? <i className="fa fa-spinner fa-spin" style={{ position: 'static' }} /> : null}
    </Button>
  );

  const prefixesContent = prefixes.map((prefix, i) => {
    const attributes = prefix.get('attributes').toJS();

    return (
      <DropDownElement {...attributes} key={i} value={prefix.get('value')}>
        {prefix.get('name')}
      </DropDownElement>
    );
  });

  return (
    <Popup closable={closable} footer={footer} header={header} id="newExecProf">
      <div className="row media-450">
        <AutoComplete
          checkOn="text"
          formGroupClass="col-lg-6 col-xs-6"
          getSuggestion={suggestDirector.onFetch}
          getSuggestionValue={suggestDirector.getValue}
          inputProps={{
            autoFocus: true,
            className: 'form-control',
            label: 'Module',
            name: 'suggestDirector',
          }}
          onSuggestionSelected={suggestDirector.onSelect}
          onUpdateSuggestions={suggestDirector.onUpdate}
          renderSuggestion={renderSuggestion}
          suggestions={suggestions}
          text={suggestDirector.value}
        />
        <AutoComplete
          checkOn="text"
          formGroupClass="col-lg-6 col-xs-6"
          getSuggestion={suggestAnalyst.onFetch}
          getSuggestionValue={suggestAnalyst.getValue}
          inputProps={{
            className: 'form-control input-sm',
            label: 'Analyst/Assoc.',
            name: 'suggestAnalyst',
          }}
          onSuggestionSelected={suggestAnalyst.onSelect}
          onUpdateSuggestions={suggestAnalyst.onUpdate}
          renderSuggestion={renderSuggestion}
          suggestions={suggestions}
          text={suggestAnalyst.value}
        />
      </div>
      <div className="row media-450">
        <FormGroup className="col-lg-3 col-sm-3 col-xs-4">
          <label className="control-label" htmlFor="w-full-name3">
            Full Name
          </label>
          <DropDown
            btnContent={prefix}
            className="btn-default btn-xs full-width text-right"
            id="newExecProfilePrefix"
            name="prefix"
            onChange={onChangeInfo}
            root={contact}
            deep
          >
            {prefixesContent}
          </DropDown>
        </FormGroup>

        <FormGroup className="col-lg-6 col-sm-6 col-xs-4">
          <label className="control-label" htmlFor="w-full-name3" />
          <Input
            className="input-md"
            id="w-full-name7"
            label="Full Name"
            name="fullName"
            onBlur={onBlur}
            onChange={onChangeInfo}
            onFocus={onFocus}
            placeholder="Full Name"
            root={contact}
            deep
          />
        </FormGroup>

        <FormGroup className="col-lg-3 col-sm-3 col-xs-4">
          <label className="control-label" htmlFor="w-full-name3" />
          {suggestSuffix}
        </FormGroup>
      </div>

      <div className="clearfix" />

      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-md"
            label="First Name"
            name="firstName"
            onBlur={onBlur}
            onChange={onChangeInfo}
            onFocus={onFocus}
            placeholder="First Name"
            root={contact}
            deep
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-md"
            label="Last Name"
            name="lastName"
            onBlur={onBlur}
            onChange={onChangeInfo}
            onFocus={onFocus}
            placeholder="Last Name"
            root={contact}
            deep
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Input label="Nick" name="nick" onChange={onChangeInfo} placeholder="Nick" root={contact} deep showLabel />
        </FormGroup>
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            label="Pronunciation"
            name="pronounce"
            onChange={onChangeInfo}
            placeholder="Pronunciation"
            root={contact}
            deep
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row">
        <FormGroup className="col-lg-5 col-sm-5">
          <Input
            className="input-sm"
            id="f-email3"
            label="Email"
            name="email"
            onChange={onChangeInfo}
            placeholder="Email"
            root={contact}
            type="emailVerify"
            deep
            required
            showLabel
          />
        </FormGroup>
        <div className="col-lg-1 col-sm-1">
          <label>&nbsp;</label>
          <div className="clearfix">
            <Button className="btn-primary btn-xs pull-right" onClick={onFindEmail}>
              Finder
            </Button>
          </div>
        </div>

        <FormGroup className="col-lg-6 col-xs-6">
          <Board models={models} name="bic" onClick={onBICClick} tags={tags} />
        </FormGroup>
      </div>
    </Popup>
  );
};

export default AddExecutiveContactPopup;
