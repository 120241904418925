import { fromJS, List } from 'immutable';
import { spy, getIn, getOriginal, setIn, revertToOriginal } from '../utils/ChangeSpy';
import {
  LOADED_BUSINESS_MODELS_SUCCESS,
  RESET_BUSINESS_MODELS,
  MERGE_BUSINESS_MODELS,
  SELECT_BUSINESS_MODEL,
} from '../actions/company/companyTarget';

const defaultState = fromJS({
  models: [],
  loading: false,
});

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOADED_BUSINESS_MODELS_SUCCESS: {
      const tmp = spy(state.set('models', fromJS(action.response.industryCategories)), ['models']);
      let models = getIn(tmp, ['models']);

      models = models.map(m => {
        const id = m.get('id');

        return m.set('checked', action.ids[id]);
      });

      return setIn(tmp, ['models'], models);
    }

    case RESET_BUSINESS_MODELS:
      return revertToOriginal(state, ['models']);

    case MERGE_BUSINESS_MODELS: {
      let tmp = getOriginal(state.get('models'), List());

      tmp = tmp.map(m => {
        const id = m.get('id');

        return m.set('checked', action.ids[id] || false);
      });

      return setIn(state, ['models'], tmp);
    }

    case SELECT_BUSINESS_MODEL: {
      let tmp = getIn(state, ['models']);

      tmp = tmp.map(m => {
        const id = m.get('id');

        if (id === action.id) {
          return m.set('checked', !m.get('checked'));
        }

        return m;
      });

      return setIn(state, ['models'], tmp);
    }

    default:
      return state;
  }
};

export default reducer;
