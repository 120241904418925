import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import config from '../../../config';
import { unwrap } from '../../../utils/ChangeSpy';
import GridRow from './GridRow';

/**
 * Stateless component for a target next action row.
 *
 * @param props {Object}.
 * @param props.action {Immutable.Map} Next action row.
 * @param props.index {Number} Row number.
 * @param props.onClick {Function} Handle setting backlogged.
 * @param props.onRowSelected {function} To handle checkbox change event.
 * @returns {React.Component}
 */
const TargetRow = props => {
  const { action, index, onRowSelected, onClick } = props;
  const actionId = action.get('id');
  const blClassName = classNames({ backlogged: action.get('backlogged') });
  const project = (
    <span
      className={classNames({
        'text-uppercase text-danger text-bold': !action.get('projectId'),
      })}
    >
      {action.get('projectName')}
    </span>
  );
  const buyer = (
    <span
      className={classNames({
        'text-uppercase text-danger text-bold': !action.get('buyerId'),
      })}
    >
      {action.get('buyerName')}
    </span>
  );
  const bl = config.BACKLOGGED_EVENTS.includes(unwrap(action.get('activity'))) ? drawBL(action, onClick) : null;
  const checked = action.get('selected', false);
  const date = action.get('date');
  const userName = action.get('userName');
  const secondUserName = (
    <div>
      {action.get('recordOwnerUserName')}
      <br />
      {action.get('recordSubOwnerUserName')}
    </div>
  );
  const activity = action.get('activity');
  const linkTo = `/company/${action.get('targetId')}`;
  const legalName = action.get('legalName');
  const currentStatus = action.get('currentStatus');
  const highStatus = action.get('highStatus');
  const priority = action.get('priority');
  const entityType = action.get('entityType');
  const targetRevenue = action.get('targetRevenue');
  const targetEmployees = action.get('targetEmployees');
  const address = action.get('address');
  const web = action.get('web');
  const webLink = action.get('webLink');
  const industryLabel = action.get('industryLabel');

  return (
    <GridRow action={action} className={blClassName}>
      <td>{index}</td>
      <td>
        <input checked={checked} name="selected" onChange={onRowSelected} type="checkbox" value={actionId} />
      </td>
      <td>{date}</td>
      <td>{userName}</td>
      <td>{secondUserName}</td>
      <td>{activity}</td>
      <td>{bl}</td>
      <td>
        <Link target="_blank" to={linkTo}>
          {legalName}
        </Link>
      </td>
      <td>
        <strong>{currentStatus}</strong>&nbsp;/&nbsp;{highStatus}
      </td>
      <td className="text-uppercase">{priority}</td>
      <td colSpan="2">{entityType}</td>
      <td>{targetRevenue}</td>
      <td>{targetEmployees}</td>
      <td>{address}</td>
      <td>
        <a href={webLink} rel="noopener noreferrer" target="_blank">
          {web}
        </a>
      </td>
      <td>
        {buyer} - {project}
      </td>
      <td>{industryLabel}</td>
    </GridRow>
  );
};

TargetRow.propTypes = {
  action: PropTypes.instanceOf(Map).isRequired,
  index: PropTypes.number,
  onClick: PropTypes.func,
  onRowSelected: PropTypes.func.isRequired,
};

export default TargetRow;

/**
 * Draw backlog field.
 *
 * @param action {Object} Action information.
 * @param onClick {Function} To handle setting backlogged.
 * @returns {React.Component}
 */
function drawBL(action, onClick) {
  if (!action.get('showBL', false)) return null;

  const spinner = action.get('spinner');
  const backlogged = action.get('backlogged');
  const id = action.get('id');

  if (spinner) {
    return (
      <a className="btn btn-primary btn-xs bl">
        <i className="fa fa-spinner fa-pulse fa-fw" />
      </a>
    );
  }

  return (
    <a className="btn btn-primary btn-xs bl" onClick={event => onClick({ event, id, backlogged })}>
      BL
    </a>
  );
}
