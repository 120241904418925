import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Map } from 'immutable';

import { isResearcher } from 'src/utils/checkPermissions';
import CompanyBuyerCEO from '../../../components/Company/CompanyBuyer/CompanyBuyerCEO';
import { buildSortByParam } from '../../../helpers/paramBuilder';
import {
  getActiveProjects,
  getCurrentExecutiveLeads,
  updateCEO,
  createCEOProject,
  handleUpdateCompany,
  saveInfo,
  saveCEOProject,
  handleGetHarvcoLeadSuggestion,
  handleGetClientLeadSuggestion,
} from '../../../actions/company/buyer/ceo';
import connectOptions, { mergeProps } from '../../../utils/connectOptions';
import { getChanged, isDeepChanged, toJS } from '../../../utils/ChangeSpy';
import ClientLeadEditor from './ClientLeadEditor';
import HarvcoLeadEditor from './HarvcoLeadEditor';

const { func } = PropTypes;

class CompanyBuyerCEOContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleDoubleClickRow = this.handleDoubleClickRow.bind(this);
    this.handleGetNextPageDataForCurrentExecutiveLeads = this.handleGetNextPageDataForCurrentExecutiveLeads.bind(this);
    this.handleGetNextPageDataForActiveProjects = this.handleGetNextPageDataForActiveProjects.bind(this);
    this.handleCurrentExecutiveLeadsChange = this.handleCurrentExecutiveLeadsChange.bind(this);
    this.handleActiveProjectsChange = this.handleActiveProjectsChange.bind(this);
    this.handleAddCEOProject = this.handleAddCEOProject.bind(this);
    this.handleCreateNewCEOProject = this.handleCreateNewCEOProject.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onUpdateCell = this.onUpdateCell.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSaveActiveProject = this.onSaveActiveProject.bind(this);
    this.onErrorClose = this.onErrorClose.bind(this);
    this.onGetSuggestion = this.onGetSuggestion.bind(this);

    this.handleUpdateClientLeadSuggestions = this.handleUpdateClientLeadSuggestions.bind(this);
    this.handleGetClientLeadSuggestion = this.handleGetClientLeadSuggestion.bind(this);
    this.handleClientLeadSuggestionSelected = this.handleClientLeadSuggestionSelected.bind(this);
  }

  componentWillUnmount() {
    this.context.removeOnSaveCallback(this.onSubmit);
  }

  componentDidMount() {
    const { companyId } = this.props.match.params;
    const { getCurrentExecutiveLeads, getActiveProjects } = this.props;

    getCurrentExecutiveLeads({
      companyId,
    });
    getActiveProjects({
      companyId,
    });
    this.context.addOnSaveCallback(this.onSubmit);
  }

  getChildContext() {
    return {
      onErrorClose: this.onErrorClose,
      inputErrors: this.props.inputErrors,
    };
  }

  onErrorClose(e, field) {
    this.props.closeValidationError({ field });
  }

  getCanEditData() {
    return !this.props.isDuplicateCompany;
  }

  onSaveActiveProject() {
    const base = this.props.ceo.getIn(['activeProjects', 'queryResults']);
    const { companyId } = this.props.match.params;

    if (!isDeepChanged(base)) return;

    const body = toJS(base);

    this.props.saveCEOProject({
      companyId,
      body,
    });
  }

  onSubmit(event) {
    if (!this.getCanEditData()) {
      return;
    }

    event.preventDefault();
    if (this.props.ceo.get('isValid')) {
      const body = getChanged(this.props.ceo);
      const { companyId } = this.props.match.params;

      if (Object.keys(body).length) {
        this.props.saveInfo({ companyId, body });
      }
    }
    // Wait untill other actions done
    setTimeout(() => {
      this.onSaveActiveProject();
    }, 1);
  }

  componentDidUpdate(oldProps) {
    const { ceo, getCurrentExecutiveLeads, getActiveProjects } = this.props;
    const { companyId } = this.props.match.params;
    const oldCEO = oldProps.ceo;

    const currentExecutiveLeads = ceo.get('currentExecutiveLeads');
    const oldCurrentExecutiveLeads = oldCEO.get('currentExecutiveLeads');
    const activeProjects = ceo.get('activeProjects');
    const oldActiveProjects = oldCEO.get('activeProjects');

    if (oldCurrentExecutiveLeads !== currentExecutiveLeads) {
      const oldSortBy = buildSortByParam(oldCurrentExecutiveLeads);
      const sortBy = buildSortByParam(currentExecutiveLeads);

      if (sortBy !== oldSortBy) {
        getCurrentExecutiveLeads({
          companyId,
          page: 1,
          sortBy,
        });
      }
    }

    if (oldActiveProjects !== activeProjects) {
      const oldSortBy = buildSortByParam(oldActiveProjects);
      const sortBy = buildSortByParam(activeProjects);

      if (sortBy !== oldSortBy) {
        getActiveProjects({
          companyId,
          page: 1,
          sortBy,
        });
      }
    }
  }

  handleGetNextPageDataForCurrentExecutiveLeads(page) {
    const { companyId } = this.props.match.params;
    const { ceo, getCurrentExecutiveLeads } = this.props;
    const currentExecutiveLeads = ceo.get('currentExecutiveLeads');
    const sortBy = buildSortByParam(currentExecutiveLeads);

    getCurrentExecutiveLeads({
      companyId,
      page,
      sortBy,
    });
  }

  handleGetNextPageDataForActiveProjects(page) {
    const { companyId } = this.props.match.params;
    const { ceo, getActiveProjects } = this.props;
    const activeProjects = ceo.get('activeProjects');
    const sortBy = buildSortByParam(activeProjects);

    getActiveProjects({
      companyId,
      page,
      sortBy,
    });
  }

  handleDoubleClickRow(row) {
    if (!this.getCanEditData()) {
      return;
    }

    window.open(`/contact/${row.data.execId}/executive`, '_blank');
  }

  handleCurrentExecutiveLeadsChange(data) {
    this.props.updateCEO({
      filterName: 'currentExecutiveLeads',
      filterData: {
        [data.filterName]: data.filterData,
      },
    });
  }

  handleActiveProjectsChange(data) {
    this.props.updateCEO({
      filterName: 'activeProjects',
      filterData: {
        [data.filterName]: data.filterData,
      },
    });
  }

  onUpdateCell({ updateField, newValue, data, newId = null, focusElement = null }) {
    if (!this.getCanEditData()) {
      return;
    }

    const { ceo } = this.props;
    let queryResults = ceo.getIn(['activeProjects', 'queryResults']);

    queryResults = queryResults.map(row => {
      if (row.get('eprojectId') === data.eprojectId) {
        row = row.setIn([updateField, 'value'], newValue);
        if (updateField === 'dsplHarvcoLeadUserName') {
          row = row.set('harvcoLeadId', newId);
        } else if (updateField === 'dsplClientLeadFLName') {
          row = row.set('clientLeadId', newId);
        }
      }

      return row;
    });
    this.handleActiveProjectsChange({
      filterName: 'queryResults',
      filterData: queryResults,
    });
    this.props.updateCEO({
      filterName: 'activeProjects',
      filterData: {
        focusElement,
      },
    });
  }

  onGetSuggestion({ currentCol, value, data }) {
    if (!this.getCanEditData()) {
      return;
    }

    if (/harvco/i.test(currentCol)) {
      this.props.handleGetHarvcoLeadSuggestion({
        value,
        data,
      });
    } else this.handleGetClientLeadSuggestion({ value, data });
  }

  handleUpdateClientLeadSuggestions(update) {
    this.context.updatePopupFormData({
      clientLead: update,
    });
  }

  handleGetClientLeadSuggestion({ value }) {
    const buyerId = this.props.match.params.companyId;

    this.props.handleGetClientLeadSuggestion(buyerId, value);
  }

  handleClientLeadSuggestionSelected(event, { suggestion }) {
    this.context.updatePopupFormData({
      clientLead: {
        text: suggestion.text,
        suggestions: [],
        selected: suggestion,
      },
    });
  }

  handleAddCEOProject() {
    if (!this.getCanEditData()) {
      return;
    }

    const { companyId } = this.props.match.params;

    this.context.openPopup('AddNewCEOProject', {
      buyerId: companyId,
      onCreate: this.handleCreateNewCEOProject,
      onSuccessResponse: () => {
        this.handleGetNextPageDataForActiveProjects(1);
      },
    });
  }

  handleCreateNewCEOProject(formData, callback) {
    const { companyId } = this.props.match.params;
    const { createCEOProject, ceo } = this.props;
    let queryResults = toJS(ceo.getIn(['activeProjects', 'queryResults']));

    queryResults = queryResults.map(item => {
      if (item.clientLeadId === '') {
        delete item.clientLeadId;
      }

      if (item.harvcoLeadId === '') {
        delete item.harvcoLeadId;
      }

      return item;
    });

    const body = {
      active: true,
      category: formData.get('category'),
    };

    if (formData.getIn(['harvcoLead', 'selected'])) {
      body.harvco_lead_id = formData.getIn(['harvcoLead', 'selected', 'id']);
    }

    if (formData.getIn(['clientLead', 'selected'])) {
      body.client_lead_id = formData.getIn(['clientLead', 'selected', 'id']);
    }
    queryResults.push(body);

    createCEOProject({
      company_id: companyId,
      body: {
        data: queryResults,
      },
      callback,
    });
  }

  handleInfoChange({ target: { name, value } }) {
    this.props.handleUpdateCompanyInfo({
      [name]: value,
    });
  }

  onChange({ name, value }) {
    if (!this.getCanEditData()) {
      return;
    }

    this.props.handleUpdateCompany({ name, value });
  }

  onFormChange({ target: { name, type, value, checked } }) {
    if (type === 'checkbox') return this.onChange({ name, value: checked });

    return this.onChange({ name, value });
  }

  render() {
    const { ceo } = this.props;
    const isResearcherUser = isResearcher(this.context.currentUser.getIn(['roles', 0, 'slug'], null));

    return (
      <div className="tab-pane active" id="slide-7">
        <CompanyBuyerCEO
          canEditData={this.getCanEditData()}
          ceo={ceo}
          clientLeadEditor={ClientLeadEditor}
          harvcoLeadEditor={HarvcoLeadEditor}
          isResearcherUser={isResearcherUser}
          onActiveProjectsChange={this.handleActiveProjectsChange}
          onAddCEOProject={this.handleAddCEOProject}
          onChange={this.onFormChange}
          onCurrentExecutiveLeadsChange={this.handleCurrentExecutiveLeadsChange}
          onDoubleClickRow={this.handleDoubleClickRow}
          onGetNextPageDataForActiveProjects={this.handleGetNextPageDataForActiveProjects}
          onGetNextPageDataForCurrentExecutiveLeads={this.handleGetNextPageDataForCurrentExecutiveLeads}
          onGetSuggestion={this.onGetSuggestion}
          onInfoChange={this.handleInfoChange}
          onUpdateCell={this.onUpdateCell}
        />
      </div>
    );
  }
}

CompanyBuyerCEOContainer.propTypes = {
  ceo: PropTypes.instanceOf(Map),
  createCEOProject: func.isRequired,
  getActiveProjects: func.isRequired,
  getCurrentExecutiveLeads: func.isRequired,
  handleGetHarvcoLeadSuggestion: func.isRequired,
  handleUpdateCompany: func.isRequired,
  isDuplicateCompany: PropTypes.bool.isRequired,
  saveCEOProject: func.isRequired,
  saveInfo: func.isRequired,
  updateCEO: func.isRequired,
};
CompanyBuyerCEOContainer.contextTypes = {
  currentUser: PropTypes.instanceOf(Map),
  openPopup: PropTypes.func.isRequired,
  addOnSaveCallback: PropTypes.func.isRequired,
  removeOnSaveCallback: PropTypes.func.isRequired,
};
CompanyBuyerCEOContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};

export { CompanyBuyerCEOContainer };
export default connect(
  state => ({
    ceo: state.targetCompany.buyer.ceo,
    inputErrors: state.targetCompany.buyer.ceo.get('inputErrors'),
    isDuplicateCompany: state.targetCompany.mergeInfo.get('isDuplicateCompany'),
  }),
  dispatch =>
    bindActionCreators(
      {
        getActiveProjects,
        getCurrentExecutiveLeads,
        updateCEO,
        createCEOProject,
        handleUpdateCompany,
        saveCEOProject,
        saveInfo,
        handleGetHarvcoLeadSuggestion,
        handleGetClientLeadSuggestion,
      },
      dispatch,
    ),
  mergeProps,
  connectOptions,
)(CompanyBuyerCEOContainer);
