const prefix = 'NEW_';
const regExp = new RegExp(`^${String(prefix.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1'))}`);
let increment = 0;

export default function uniqueId() {
  return `${Date.now()}-${increment++}`;
}

export function uniqueNewId() {
  return `${prefix}${uniqueId()}`;
}

export function isNew(id) {
  return regExp.test(id);
}
