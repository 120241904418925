import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import AutoComplete from '../helpers/AutoComplete';
import isFunction from '../../utils/isFunction';

class BrowseAutoComplete extends PureComponent {
  constructor(props) {
    super(props);

    this.handleUpdateSuggestions = this.handleUpdateSuggestions.bind(this);
    this.handleGetSuggestionValue = this.handleGetSuggestionValue.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
    this.handleRenderSuggestion = this.handleRenderSuggestion.bind(this);
  }

  componentDidMount() {
    this.props.onGetSuggestion({
      value: '',
    });
  }

  handleUpdateSuggestions(update) {
    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: update,
    });
  }

  handleGetSuggestionValue(suggestion) {
    return suggestion[this.props.filter.get('valueField')];
  }

  handleSuggestionSelected(event, { suggestion }) {
    const { filter } = this.props;
    const keepText = filter.get('keepText', false);
    const render = this.props.filter.get('renderField');

    this.props.onChange({
      filterName: filter.get('name'),
      filterData: {
        text: keepText
          ? render && isFunction(render)
            ? render(suggestion)
            : suggestion[filter.get('renderField')]
          : '',
        suggestions: [],
        selected: suggestion,
      },
    });
  }

  handleRenderSuggestion(suggestion) {
    const render = this.props.filter.get('renderField');

    if (render && isFunction(render)) {
      return <div>{render(suggestion)}</div>;
    }

    return <div>{suggestion[render]}</div>;
  }

  render() {
    const { isHidden, filter, info } = this.props;

    if (isHidden) return null;

    const suggestions = info.get('suggestions');
    const text = info.get('text');
    const loading = info.get('loading', null);
    const suggestionProps = {
      inputProps: {
        className: filter.get('className'),
        id: filter.get('name'),
        name: filter.get('name'),
        label: filter.get('inputLabel'),
        disabled: false,
        placeholder: filter.get('placeholder'),
      },
      formGroupClass: filter.get('formGroupClass'),
      suggestions,
      loading,
      onUpdateSuggestions: this.handleUpdateSuggestions,
      getSuggestion: this.props.onGetSuggestion,
      getNextSuggestion: this.props.onGetNextSuggestion,
      getSuggestionValue: this.handleGetSuggestionValue,
      renderSuggestion: this.handleRenderSuggestion,
      onSuggestionSelected: this.handleSuggestionSelected,
      suggestIfEmpty: true,
      highlightFirstSuggestion: false,
      text,
    };

    return <AutoComplete {...suggestionProps}>{this.props.children}</AutoComplete>;
  }
}
BrowseAutoComplete.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onGetNextSuggestion: PropTypes.func,
  onGetSuggestion: PropTypes.func.isRequired,
};

export default BrowseAutoComplete;
