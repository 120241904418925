import { CALL_API, CHAIN_API } from '../../../middleware/api';
import config from '../../../config';
import { isNew } from '../../../utils/uniqueId';
import { reorderContacts, REORDER_CONTACTS, reorderChannels, REORDER_CHANNELS } from '../companyTarget';

export const LOADED_COMPANY_CONTACT = Symbol('LOADED_COMPANY_CONTACT');

export const ADD_COMPANY_DETAIL_ENTITY = Symbol('ADD_COMPANY_DETAIL_ENTITY');
export const EDIT_COMPANY_DETAIL_ENTITY = Symbol('EDIT_COMPANY_DETAIL_ENTITY');
export const DEL_COMPANY_DETAIL_ENTITY = Symbol('DEL_COMPANY_DETAIL_ENTITY');

export const DEL_COMPANY_CONTACT = Symbol('DEL_COMPANY_CONTACT');
export const OPEN_COMPANY_CONTACT = Symbol('OPEN_COMPANY_CONTACT');
export const UPDATE_COMPANY = Symbol('UPDATE_COMPANY');
export const REVERT_UPDATE = Symbol('REVERT_UPDATE');
export const LOADED_USERS = Symbol('LOADED_USERS');
export const SAVED_COMPANY_BUYER = Symbol('SAVED_COMPANY_BUYER');
export const ERRORED_COMPANY_BUYER = Symbol('ERRORED_COMPANY_BUYER');
export const CLOSE_COMPANY_BUYER_VALIDATION_ERROR = Symbol('CLOSE_COMPANY_BUYER_VALIDATION_ERROR');

export const LOADED_TITLES = Symbol('LOADED_TITLES');

export const ERROR_SAVE_COMPANY_CONTACT = Symbol('ERROR_SAVE_COMPANY_CONTACT');
export const ERROR_SAVE_COMPANY_CONTACT_CHANNEL = Symbol('ERROR_SAVE_COMPANY_CONTACT_CHANNEL');
export const SAVED_COMPANY_CONTACT = Symbol('SAVED_COMPANY_CONTACT');
export const SAVED_COMPANY_CONTACT_CHANNEL = Symbol('SAVED_COMPANY_CONTACT_CHANNEL');
export const SAVED_COMPANY_ENTITYES = Symbol('SAVED_COMPANY_ENTITYES');
export const SAVE_COMPANY_ENTITYES_ERROR = Symbol('SAVE_COMPANY_ENTITYES_ERROR');
export const LOADED_COMPANY_ENTITYES = Symbol('LOADED_COMPANY_ENTITYES');
export const DEL_CONTACT_CHANNEL = Symbol('DEL_CONTACT_CHANNEL');
export const ADD_VALIDATION_ERROR = Symbol('ADD_VALIDATION_ERROR');
export { reorderContacts, REORDER_CONTACTS, reorderChannels, REORDER_CHANNELS };
export const LOADED_DIRECTORS = Symbol('LOADED_DIRECTORS');
export const REVERT_CHANGES = Symbol('REVERT_CHANGES');
export const DELETE_COMPANY_FILE = Symbol('DELETE_COMPANY_FILE');
export const FETCHING_COMPANY_FILES = Symbol('FETCHING_COMPANY_FILES');

/**
 * Load directors by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findDirectors({ filter, page, afterSuccess }) {
  return {
    filter,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users_by_role',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        role: config.DIRECTOR,
        like: filter,
        limit: 100,
        page,
      },
      successType: LOADED_DIRECTORS,
      afterSuccess,
    },
  };
}

/**
 * Load users by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findUsers({ filter, page, afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
        limit: 100,
        page,
      },
      successType: LOADED_USERS,
      afterSuccess,
    },
  };
}

/**
 * Load titles by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findTitles({ filter, prefix }) {
  return {
    filter,
    prefix,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/people/executive/titles',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
      },
      successType: LOADED_TITLES,
    },
  };
}

export function handleUpdateCompany(info) {
  return {
    type: UPDATE_COMPANY,
    ...info,
  };
}

export function openContact({ index }) {
  return {
    type: OPEN_COMPANY_CONTACT,
    index,
  };
}

export function addDetailEntity({ name }) {
  return {
    type: ADD_COMPANY_DETAIL_ENTITY,
    entity: name,
  };
}

export function startEditDetailEntity({ name, index }) {
  return {
    type: EDIT_COMPANY_DETAIL_ENTITY,
    entity: name,
    index,
  };
}

export function delDetailEntity({ companyId, name, id, body, realBody }, afterSuccess) {
  const entity = name;

  name = name.replace(/[A-Z]/g, str => `_${str.toLowerCase()}`);

  const method = 'post';
  let path = `/api/v1/companies/${companyId}/bulk/${name}`;

  switch (entity) {
    case 'industries':
      path = `/api/v1/companies/${companyId}/target_industries`;
      break;
  }

  if (isNew(id)) {
    return dispatch => {
      dispatch({
        entity,
        id,
        type: DEL_COMPANY_DETAIL_ENTITY,
      });
      afterSuccess({ dispatch });
    };
  }

  if (isNew(id)) {
    // deprecated
    return {
      body,
      entity,
      [CALL_API]: {
        method: 'get',
        path: `/api/v1/companies/${companyId}/detail/${name}`,
        unifier: `get /api/v1/companies/${companyId}/detail/${name}`,
        successType: LOADED_COMPANY_ENTITYES,
        afterSuccess: ({ dispatch, ...props }, ...rest) => {
          dispatch({
            entity,
            id,
            type: DEL_COMPANY_DETAIL_ENTITY,
          });
          dispatch({
            body,
            entity,
            type: SAVED_COMPANY_ENTITYES,
          });
          afterSuccess({ dispatch, ...props }, ...rest);
        },
      },
    };
  }

  return {
    [CHAIN_API]: [
      () => ({
        body,
        entity,
        [CALL_API]: {
          method,
          path,
          unifier: `put /api/v1/companies/${companyId}/${entity} {${JSON.stringify(body)}}`,
          body: realBody,
          errorType: SAVE_COMPANY_ENTITYES_ERROR,
          successType: SAVED_COMPANY_ENTITYES,
          afterError: afterSuccess,
        },
      }),
      () => ({
        body,
        entity,
        [CALL_API]: {
          method: 'get',
          path: `/api/v1/companies/${companyId}/detail/${name}`,
          successType: LOADED_COMPANY_ENTITYES,
          afterError: afterSuccess,
          afterSuccess: ({ dispatch, ...props }, ...rest) => {
            dispatch({
              entity,
              id,
              type: DEL_COMPANY_DETAIL_ENTITY,
            });
            dispatch({
              body,
              entity: name,
              type: SAVED_COMPANY_ENTITYES,
            });
            afterSuccess({ dispatch, ...props }, ...rest);
          },
        },
      }),
    ],
  };
}

export function saveInfo({ companyId, body }) {
  return {
    fields: Object.keys(body),
    [CALL_API]: {
      method: 'put',
      unifier: `buyer put /api/v1/companies/${companyId} {${JSON.stringify(body)}}`,
      path: `/api/v1/companies/${companyId}`,
      body,
      successType: SAVED_COMPANY_BUYER,
      errorType: ERRORED_COMPANY_BUYER,
      skipGlobalErrorHandler: true,
    },
  };
}

export function closeValidationError({ field }) {
  return {
    type: CLOSE_COMPANY_BUYER_VALIDATION_ERROR,
    field,
  };
}

/**
 * Save contact tags.
 *
 * @param companyId {Number} Contact id.
 * @param name {String} Tags to save.
 * @param body {Object[]} Tags to save.
 * @returns {MiddlewareApi.CallApi}
 */
export function saveCompanyDetailEntity({ companyId, name, body, realBody }) {
  const entity = name;

  name = name.replace(/[A-Z]/g, str => `_${str.toLowerCase()}`);

  const method = 'post';
  let path = `/api/v1/companies/${companyId}/bulk/${name}`;

  switch (entity) {
    case 'industries':
      path = `/api/v1/companies/${companyId}/target_industries`;
      break;

    case 'customFields':
      path = `/api/v1/companies/${companyId}/bulk/target_custom_fields`;
  }

  return {
    [CHAIN_API]: [
      () => ({
        body,
        entity,
        [CALL_API]: {
          method,
          path,
          unifier: `put /api/v1/companies/${companyId}/${name} {${JSON.stringify(body)}}`,
          body: realBody,
          successType: SAVED_COMPANY_ENTITYES,
          errorType: SAVE_COMPANY_ENTITYES_ERROR,
        },
      }),
      () => ({
        body,
        entity,
        [CALL_API]: {
          method: 'get',
          path: `/api/v1/companies/${companyId}/detail/${name}`,
          successType: LOADED_COMPANY_ENTITYES,
        },
      }),
    ],
  };
}

export function delContactChannel({ contactId, type, channelId }, afterSuccess) {
  const method = 'delete';
  const path = `/api/v1/people/${contactId}/${type}/${channelId}`;

  return {
    channelType: type,
    id: channelId,
    contactId,
    [CALL_API]: {
      method,
      unifier: `delete ${path}`,
      path,
      successType: DEL_CONTACT_CHANNEL,
      afterError: afterSuccess,
      afterSuccess,
    },
  };
}

export function delContact({ contactId, entityContactsId, companyId }, afterSuccess) {
  const method = 'delete';
  const path = `/api/v1/companies/${companyId}/entity_contacts/${entityContactsId}`;

  return {
    contactId,
    [CALL_API]: {
      method,
      unifier: `delete ${path}`,
      path,
      successType: DEL_COMPANY_CONTACT,
      afterError: afterSuccess,
      afterSuccess,
    },
  };
}

export function revertUpdate({ field }) {
  return {
    type: REVERT_UPDATE,
    field,
  };
}

export function saveCompanyContact({ companyId, contactId, isNew, body, saved }, afterSuccess) {
  let method = 'put';
  let path = `/api/v1/companies/${companyId}/contact/${contactId}`;

  if (isNew) {
    method = 'post';
    path = `/api/v1/companies/${companyId}/contact`;
  }

  return {
    isNew,
    contactId,
    body: saved,
    [CALL_API]: {
      method,
      path,
      unifier: `put /api/v1/people/${contactId} {${JSON.stringify(body)}}`,
      body,
      successType: SAVED_COMPANY_CONTACT,
      afterSuccess: ({ dispatch, ...rest }, ...args) => {
        if (!isNew) return afterSuccess({ dispatch, ...rest }, ...args);

        return dispatch({
          [CALL_API]: {
            method: 'get',
            path: `/api/v1/companies/${companyId}`,
            query: {
              include: 'entity_contacts',
            },
            successType: LOADED_COMPANY_CONTACT,
            afterSuccess,
          },
        });
      },
      skipGlobalErrorHandler: true,
      errorType: ERROR_SAVE_COMPANY_CONTACT,
    },
  };
}

export function saveCompanyContactChannel(options, afterSuccess) {
  const { isNew, contactId, channelType, channelId, channelIndex, body, saved = body } = options;
  let path = `/api/v1/people/${contactId}/${channelType}/${channelId}`;
  let method = 'put';

  if (isNew) {
    path = `/api/v1/people/${contactId}/${channelType}`;
    method = 'post';
  }

  return {
    isNew,
    contactId,
    channelType,
    channelId,
    channelIndex,
    body: saved,
    [CALL_API]: {
      method,
      path,
      body,
      successType: SAVED_COMPANY_CONTACT_CHANNEL,
      afterSuccess,
      skipGlobalErrorHandler: true,
      errorType: ERROR_SAVE_COMPANY_CONTACT_CHANNEL,
    },
  };
}

export function addValidationError({ ...rest }) {
  return {
    type: ADD_VALIDATION_ERROR,
    ...rest,
  };
}

/**
 * Revert changes which didn't save.
 */
export function revertChanges() {
  return {
    type: REVERT_CHANGES,
  };
}

/**
 * Delete file.
 *
 * @param {object} param .
 * @param {number} param.companyId Company id.
 * @param {number} param.id File id.
 * @param {Function} afterSuccess Callback after successfully response.
 */
export function deleteFile({ companyId, id }, afterSuccess) {
  return {
    [CHAIN_API]: [
      () => ({
        companyId,
        id,
        [CALL_API]: {
          method: 'delete',
          path: `/api/v1/companies/${companyId}/files/${id}`,
          successType: DELETE_COMPANY_FILE,
          afterSuccess,
        },
      }),
    ],
  };
}

/**
 * Get files list.
 *
 * @param {object} param .
 * @param {number} param.companyId Company id.
 */
export function getFiles({ companyId }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies/${companyId}/files`,
      query: {
        company_type: 'buyer',
      },
      successType: FETCHING_COMPANY_FILES,
    },
  };
}

/**
 * Upload new file.
 *
 * @param {object} param .
 * @param {number} param.companyId Company id.
 * @param {object} param.formData Form object data.
 * @param {Function} param.afterSuccess Callback after successfully response.
 */
export function uploadFile({ companyId, formData, afterSuccess }) {
  return {
    [CHAIN_API]: [
      () => ({
        [CALL_API]: {
          method: 'post',
          path: `/api/v1/companies/${companyId}/files`,
          query: {
            company_type: 'buyer',
          },
          body: formData,
        },
      }),
      () => ({
        [CALL_API]: {
          method: 'get',
          path: `/api/v1/companies/${companyId}/files`,
          query: {
            company_type: 'buyer',
          },
          successType: FETCHING_COMPANY_FILES,
          afterSuccess,
        },
      }),
    ],
  };
}
