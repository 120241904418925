import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS, Map } from 'immutable';

import Popup from '../../../../components/helpers/Popup';
import Button from '../../../../components/helpers/Button';
import Select from '../../../../components/helpers/Select';
import FormGroup from '../../../../components/helpers/FormGroup';
import { InputProps } from '../../../../components/helpers/AutoComplete';
import AutoComplete from '../../../AutoComplete';
import { findDirectors } from '../../../../actions/company/companyTarget';
import { unwrap } from '../../../../utils/ChangeSpy';
import { ndaReportRequest } from '../../../../actions/company/target/ndaReport';
import { showError, showInformationCustomTitle } from '../../../../utils/MessagePopup';

const DIRECTOR_SUGGEST_ID = 'suggestDirectorId';
const NDA_TYPE_HARVEY = 'harvey';
const NDA_TYPE_CLIENT = 'client';

/**
 * NDA request popup component.
 *
 * @component
 */
class NdaRequestPopup extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      suggestDirectorValue: '',
      suggestDirectorId: null,
      selectedBuyer: null,
      ndaType: NDA_TYPE_HARVEY,
      loading: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleDirectorSuggestChange = this.handleDirectorSuggestChange.bind(this);
    this.handleNdaTypeChange = this.handleNdaTypeChange.bind(this);
    this.convertBuyersToSelectFormat = this.convertBuyersToSelectFormat.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBuyerChange = this.handleBuyerChange.bind(this);
    this.isValid = this.isValid.bind(this);
    this.handleAfterRequestSuccess = this.handleAfterRequestSuccess.bind(this);
    this.handleAfterRequestError = this.handleAfterRequestError.bind(this);
  }

  componentDidMount() {
    const { companyInfo } = this.props;
    const companyBuyers = companyInfo.get('buyers');
    const defaultSelectedBuyer = companyBuyers.get(0).get('buyerId');

    this.setState({
      suggestDirectorId: companyInfo.getIn(['suggestDirectorData', 'id']),
      suggestDirectorValue: companyInfo.getIn(['suggestDirectorData', 'name']),
      selectedBuyer: defaultSelectedBuyer,
    });
  }

  componentWillUnmount() {
    this.setState({ loading: true });
  }

  /** Close popup method. */
  handleClose() {
    this.context.closePopup();
  }

  /** Director suggests handler. */
  handleDirectorSuggestChange({ name, value }) {
    if (name === 'directorText') this.setState({ suggestDirectorValue: value, suggestDirectorId: '' });
    else if (name === DIRECTOR_SUGGEST_ID) this.setState({ suggestDirectorId: value });
  }

  /** Buyer change handler. */
  handleBuyerChange({ target: { value } }) {
    this.setState({ selectedBuyer: value });
  }

  /** Submit form handler. */
  handleSubmit() {
    const { ndaType, selectedBuyer, suggestDirectorId } = this.state;
    const { ndaReportRequest, popup } = this.props;
    const companyId = parseInt(popup.getIn(['props', 'companyId'], null), 10);
    const isNdaClient = !!(ndaType === NDA_TYPE_CLIENT);
    const buyerId = isNdaClient ? parseInt(selectedBuyer, 10) : null;

    const body = {
      companyId,
      isNdaClient,
      buyerId,
      directorId: suggestDirectorId,
    };

    this.setState({ loading: true });
    ndaReportRequest(body, this.handleAfterRequestSuccess, this.handleAfterRequestError);
  }

  /**
   * After successful request handler.
   *
   * @param {object} payload Server payload data.
   * @param {object} payload.response Server success response.
   */
  handleAfterRequestSuccess({ response }) {
    const { fileName, fileUrl } = response.data;
    const message = [
      {
        name: fileName,
        url: fileUrl,
        headers: {},
      },
    ];

    showInformationCustomTitle(this.context.openPopup, 'The report has been generated successfully', message);
  }

  /** After request error handler. */
  handleAfterRequestError({ resBody }) {
    const errorMessage = resBody.message;

    showError(this.context.openPopup, [errorMessage]);
    this.setState({ loading: false });
  }

  /** Nda radio control change handler. */
  handleNdaTypeChange(event) {
    this.setState({ ndaType: event.target.value });
  }

  /**
   * Convert buyers list to style-friendly list for autocomplete.
   *
   * @param {List} buyers Target buyers list.
   * @returns {List} Buyers formatted buyers list.
   */
  convertBuyersToSelectFormat(buyers) {
    return buyers.map(buyer => fromJS({ value: buyer.get('buyerId'), name: unwrap(buyer.get('dsplBuyerLegalName')) }));
  }

  /** Returns form validity boolean value. */
  isValid() {
    const { suggestDirectorValue, suggestDirectorId, ndaType, selectedBuyer, loading } = this.state;
    const isHarveyType = ndaType === NDA_TYPE_HARVEY;
    const isClientType = ndaType === NDA_TYPE_CLIENT;
    const ndaValid = (!!isClientType && selectedBuyer) || isHarveyType;
    const directorValid = suggestDirectorValue && suggestDirectorId;

    return directorValid && ndaValid && !loading;
  }

  render() {
    const { findDirectors, companyInfo } = this.props;
    const { suggestDirectorValue, ndaType, selectedBuyer } = this.state;
    const isNdaActive = companyInfo.getIn(['nda', 'isActive'], null);
    const directorSuggests = companyInfo.get('suggests');
    const buyers = this.convertBuyersToSelectFormat(companyInfo.get('buyers'));
    const isValid = this.isValid();

    const header = 'You are generating a new NDA';
    const warningMessage = '(This company already has an existing NDA)';
    const subheader = isNdaActive && warningMessage;
    const footer = [
      <Button
        key="run"
        className="btn btn-primary btn-xs"
        disabled={!isValid}
        onClick={this.handleSubmit}
        type="submit"
      >
        Run
      </Button>,
      <Button key="close" className="btn-default btn-xs" onClick={this.handleClose} type="button">
        Close
      </Button>,
    ];

    const renderTargetBuyers = ndaType === NDA_TYPE_CLIENT && (
      <FormGroup className="w160 form-inline-block">
        <Select
          label="Buyer"
          name="buyer"
          onChange={this.handleBuyerChange}
          options={buyers}
          selectClassName="input-sm text-bold"
          value={selectedBuyer}
          showLabel
        />
      </FormGroup>
    );

    return (
      <Popup footer={footer} header={header} id="ndaRequestPopup" subheader={subheader} loading>
        <div className="container">
          <div className="row">
            <FormGroup className="w160 form-inline-block">
              <label>
                Director
                <InputProps className="form-control input-sm text-bold" placeholder="Director">
                  <AutoComplete
                    change={this.handleDirectorSuggestChange}
                    find={findDirectors}
                    highlightFirst={false}
                    idName={DIRECTOR_SUGGEST_ID}
                    idValue={DIRECTOR_SUGGEST_ID}
                    name="director"
                    suggestions={directorSuggests}
                    suggestsName="suggests"
                    value={suggestDirectorValue}
                    valueName="directorText"
                  />
                </InputProps>
              </label>
            </FormGroup>
          </div>
          <div className="row">
            <FormGroup className="w105 form-inline-block">
              <div className="radio-wrap radio-wrap--reverse">
                <label htmlFor={`ndaType_${NDA_TYPE_HARVEY}`}>Harvey NDA</label>
                <input
                  checked={ndaType === NDA_TYPE_HARVEY}
                  id={`ndaType_${NDA_TYPE_HARVEY}`}
                  name="ndaType"
                  onChange={this.handleNdaTypeChange}
                  type="radio"
                  value={NDA_TYPE_HARVEY}
                />
              </div>
            </FormGroup>
            <FormGroup className="w135 form-inline-block">
              <div className="radio-wrap radio-wrap--reverse">
                <label htmlFor={`ndaType_${NDA_TYPE_CLIENT}`}>Client Specific NDA</label>
                <input
                  checked={ndaType === NDA_TYPE_CLIENT}
                  id={`ndaType_${NDA_TYPE_CLIENT}`}
                  name="ndaType"
                  onChange={this.handleNdaTypeChange}
                  type="radio"
                  value={NDA_TYPE_CLIENT}
                />
              </div>
            </FormGroup>
          </div>
          <div className="row">{renderTargetBuyers}</div>
        </div>
      </Popup>
    );
  }
}

NdaRequestPopup.contextTypes = {
  /**
   * Close popup function.
   */
  closePopup: PropTypes.func.isRequired,
  /**
   * Open popup function.
   */
  openPopup: PropTypes.func.isRequired,
};

NdaRequestPopup.propTypes = {
  /**
   * Company info data.
   */
  companyInfo: PropTypes.instanceOf(Map).isRequired,
  /**
   * Find directors action.
   */
  findDirectors: PropTypes.func.isRequired,
  /**
   * Nda request action.
   */
  ndaReportRequest: PropTypes.func.isRequired,
  /**
   * Popup props.
   */
  popup: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => ({
  ...props,
  companyInfo: state.targetCompany.target,
});

export default connect(mapStateToProps, {
  findDirectors,
  ndaReportRequest,
})(NdaRequestPopup);
