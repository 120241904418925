import formatString from './string-template-builder';
import getEmailBody from './getEmailBody';

/**
 * Get result mailto letter link to the company contact.
 *
 * @param {string} email Target email.
 * @param {Map} template Letter template.
 * @param {object} mergeableFields Merged letter fields.
 * @param {Map} user Sender user.
 * @param {Map} contact Current contact.
 * @returns {string} Mailto link.
 */
export default function getContactMailLink(email, template, mergeableFields, user, contact) {
  let subject = template.get('subject');
  let emailBody = template.get('body');

  const isSubjectHaveMergeableFields = Object.keys(mergeableFields).some(filed => subject.includes(filed));
  const isBodyHaveMergeableFields = Object.keys(mergeableFields).some(field => emailBody.includes(field));

  if (isSubjectHaveMergeableFields) {
    subject = formatString(subject, mergeableFields);
  }

  if (isBodyHaveMergeableFields) {
    emailBody = formatString(emailBody, mergeableFields);
  }

  const emailRecipient = contact.get('firstName');
  const emailSender = user.get('firstName');

  const subjectQueryString = `subject=${encodeURIComponent(subject)}`;
  const bodyQueryString = `body=${encodeURIComponent(getEmailBody(emailRecipient, emailSender, emailBody))}`;

  return `mailto:${email}?${subjectQueryString}&${bodyQueryString}`;
}
