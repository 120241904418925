import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import shortcut from 'react-shortcut-hoc';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import Popups from '../../components/Popup';
import { getChanged } from '../../utils/ChangeSpy';
import * as TARGET from '../../actions/company/companyTarget';
import * as BUYER from '../../actions/company/buyer/marketing';

import { basicAccess } from '../../utils/checkPermissions';

class EditContactChannelPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onErrorClose = this.onErrorClose.bind(this);
    this.onFindEmail = this.onFindEmail.bind(this);
    this.state = {
      isBasicAccess: false,
    };
  }

  componentDidMount() {
    const isBasicAccess = basicAccess(this.context.currentUser.getIn(['roles', 0, 'slug']));

    this.setState({ isBasicAccess });
    this.context.onClosePopup(() => {
      const field = this.props.pathPrefix.slice(0, -1);

      this.props.revertUpdate({ field });
    });
  }

  getChildContext() {
    return {
      onErrorClose: this.onErrorClose,
      inputErrors: this.props.inputErrors,
    };
  }

  onErrorClose(e, field) {
    this.props.closeValidationError({ field });
  }

  onChange(event) {
    const { name, value } = event.target;

    return this.props.handleUpdateCompany({
      name: `${this.props.pathPrefix}${name}`,
      value,
    });
  }

  onSave() {
    const {
      isValid,
      isNew,
      contactId,
      channelIndex,
      channelId,
      channel,
      popup,
      verifyEmail,
      updateLastResearcher,
      saveCompanyContactChannel,
    } = this.props;
    const { isBasicAccess } = this.state;
    const companyId = popup.getIn(['props', 'params', 'companyId']);
    const mode = popup.getIn(['props', 'mode']);

    const isVerify = verifyEmail.get('status') === 'valid';

    if (isValid) {
      const body = {
        ...getChanged(channel),
        ...(isVerify && { isVerify }),
      };

      saveCompanyContactChannel(
        {
          companyId,
          isNew,
          contactId,
          channelType: 'emails',
          channelId,
          channelIndex,
          body,
        },
        () => {
          this.context.closePopup();
          if (isBasicAccess && mode !== 'buyer') {
            updateLastResearcher(this.context.currentUser.get('userName'));
          }
        },
      );
    }
  }

  onFindEmail() {
    this.context.openPopup('EmailFinderPopup', this.props);
  }

  render() {
    const { children, channel, isValid, isNew } = this.props;

    return (
      <div>
        <Popups.EditEmailContactChannelPopup
          channel={channel}
          isNew={isNew}
          isValid={isValid}
          onChange={this.onChange}
          onFindEmail={this.onFindEmail}
          onSave={this.onSave}
        />
        {children}
      </div>
    );
  }
}

EditContactChannelPopupContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopupUnsafe: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
};

EditContactChannelPopupContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};

function mapStateToProps(state, props) {
  const openedContact = props.popup.getIn(['props', 'openedContact']);
  const channelType = 'emails';
  const channelIndex = props.popup.getIn(['props', 'channelIndex']);
  const channelId = props.popup.getIn(['props', 'channelId']);
  const isBuyer = props.popup.getIn(['props', 'mode']) === 'buyer';
  const root = isBuyer ? state.targetCompany.buyer.marketing : state.targetCompany.target;
  const contact = root.getIn(['contacts', openedContact]);
  const contactId = contact.get('id');
  const isNew = !parseInt(channelId, 10);
  const pathPrefix = isNew ? `newData.${channelType}.` : `contacts.${openedContact}.${channelType}.${channelIndex}.`;

  const channel = root.getIn(pathPrefix.split('.').slice(0, -1));

  return {
    isValid: channel.get('isValid', true),
    isNew,
    inputErrors: channel.get('inputErrors', Map()),
    channel,
    channelId,
    channelIndex,
    contactId,
    pathPrefix,
    contact,
    verifyEmail: state.verifyEmail,
  };
}

function mapDispatchToProps(dispatch, props) {
  const isBuyer = props.popup.getIn(['props', 'mode']) === 'buyer';

  if (isBuyer) {
    const { handleUpdateCompany, saveCompanyContactChannel, revertUpdate, closeValidationError } = BUYER;

    return bindActionCreators(
      {
        handleUpdateCompany,
        saveCompanyContactChannel,
        revertUpdate,
        closeValidationError,
      },
      dispatch,
    );
  }

  const {
    handleUpdateCompany,
    saveCompanyContactChannel,
    revertUpdate,
    closeValidationError,
    updateLastResearcher,
  } = TARGET;

  return bindActionCreators(
    {
      handleUpdateCompany,
      saveCompanyContactChannel,
      revertUpdate,
      closeValidationError,
      updateLastResearcher,
    },
    dispatch,
  );
}

export { EditContactChannelPopupContainer };

const shortcutWrapped = shortcut(EditContactChannelPopupContainer, 'ctrl+enter', 'onSave');
const Connected = connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions)(shortcutWrapped);

export default Connected;
