import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { toJS } from 'src/utils/ChangeSpy';

const ExecutiveDeals = ({ data }) => {
  const deals = toJS(data.getIn(['executiveDeals', 'countExecutiveDeals']));
  const { isFetching } = toJS(data.get('executiveDeals'));

  return (
    <div className="icon-container bg-white d-flex mb20 p20">
      {isFetching === false && (
        <>
          <div className="h5 user-chart-icon">
            <i className="fa fa-briefcase" />
          </div>
          <div>
            <p className="h3 text-bold m0 p5">{deals}</p>
            <p className="h5 m0 p5">Executive Placed/Initiated Deals For</p>
          </div>
        </>
      )}
    </div>
  );
};

ExecutiveDeals.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
};

export default ExecutiveDeals;
