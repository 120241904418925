import { CALL_API } from '../../../../middleware/api';

export const UPDATE_FIELD = Symbol('UPDATE_FIELD');
export const SAVE_ADD_ON_PROJECT = Symbol('SAVE_ADD_ON_PROJECT');
export const SAVE_ADD_ON_PROJECT_SUCCESS = Symbol('SAVE_ADD_ON_PROJECT_SUCCESS');
export const SAVE_ADD_ON_PROJECT_ERROR = Symbol('SAVE_ADD_ON_PROJECT_ERROR');
export const REVERT_UPDATE = Symbol('REVERT_UPDATE');
export const LOADED_USERS = Symbol('LOADED_USERS');
export const LOADED_CLIENTS = Symbol('LOADED_CLIENTS');
export const LOADED_EXECUTIVE = Symbol('LOADED_EXECUTIVE');
export const FETCHING_PROJECTS_APPROVAL_LISTS = Symbol('FETCHING_PROJECTS_APPROVAL_LISTS');
export const FETCHING_PROJECTS_APPROVAL_LISTS_SUCCESS = Symbol('FETCHING_PROJECTS_APPROVAL_LISTS_SUCCESS');
export const FETCHING_PROJECTS_APPROVAL_LISTS_FAILURE = Symbol('FETCHING_PROJECTS_APPROVAL_LISTS_FAILURE');
export const CLOSE_VALIDATION_ERROR = Symbol('CLOSE_VALIDATION_ERROR');

export function changeField({ name, value }) {
  return {
    type: UPDATE_FIELD,
    name,
    value,
  };
}

export function saveAddOnProject({ body, afterSuccess, afterError }) {
  return {
    body,
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/projects',
      body,
      successType: SAVE_ADD_ON_PROJECT_SUCCESS,
      errorType: SAVE_ADD_ON_PROJECT_ERROR,
      afterSuccess,
      afterError,
    },
  };
}

export function closeValidationError({ field }) {
  return {
    type: CLOSE_VALIDATION_ERROR,
    field,
  };
}

export function revertUpdate() {
  return {
    type: REVERT_UPDATE,
  };
}

export function findUsers({ filter, page, afterSuccess }) {
  return {
    filter,
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/users_by_role?like=${filter}&role=director`,
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
        page,
      },
      successType: LOADED_USERS,
      afterSuccess,
    },
  };
}

export function findClients({ data, page, filter, afterSuccess }) {
  return {
    filter,
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/buyers/${data.companyId}/people?like=${filter}`,
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
        page,
      },
      successType: LOADED_CLIENTS,
      afterSuccess,
    },
  };
}

export function findExecutive({ filter, page, afterSuccess }) {
  return {
    filter,
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/execs?like=${filter}`,
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
        page,
      },
      successType: LOADED_EXECUTIVE,
      afterSuccess,
    },
  };
}

export function getProjectsApprovalLists({ companyId, afterSuccess }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies/${companyId}/buyer/projects_approval_lists`,
      startType: FETCHING_PROJECTS_APPROVAL_LISTS,
      errorType: FETCHING_PROJECTS_APPROVAL_LISTS_FAILURE,
      successType: FETCHING_PROJECTS_APPROVAL_LISTS_SUCCESS,
      afterSuccess,
    },
  };
}
