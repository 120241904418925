import { sortBy } from 'underscore';
import { fromJS, Map, List } from 'immutable';

import config from '../../config';
import {
  SHOW_PROJECT_LOADING,
  REQUEST_API_PROJECT_FAILURE,
  LOADED_PROJECT_SUCCESS,
  SAVED_PROJECT_SUCCESS,
  LOADING_PROJECT_SUGGESTION,
  LOADED_PROJECT_SUGGESTION_SUCCESS,
  RESET_ERROR_LIST,
  REVERT_PROJECT_CHANGES,
  UPDATE_PROJECT_COMMON_INFO,
  FETCHING_LIST_COMPARABLES_SUCCESS,
  FETCHING_LIST_COMPARABLES,
  LOADED_PROJECT_COMPARABLES_SUCCESS,
  SAVED_PROJECT_COMPARABLES_SUCCESS,
  LOADED_PROJECT_COMPARABLES,
  CLEAR_COMPARABLES,
  CHANGE_COMPARABLES,
  LOADING_PROJECT_CRITERIA,
  LOADED_PROJECT_CRITERIA_SUCCESS,
  SAVED_PROJECT_CRITERIA_SUCCESS,
  SAVED_PROJECT_CRITERIA_FAILURE,
} from '../../actions/project/main';
import {
  SHOW_USERS_LOADING,
  REQUEST_API_USERS_FAILURE,
  LOADED_USERS_SUCCESS,
  LOADED_USERS_SUGGESTIONS_SUCCESS,
  LOADING_USERS_SUGGESTION,
  SAVED_USERS_SUCCESS,
} from '../../actions/project/user';
import {
  SHOW_INDUSTRIES_LOADING,
  REQUEST_API_INDUSTRIES_FAILURE,
  LOAD_INDUSTRIES_SUCCESS,
  LOADED_INDUSTRIES_SUGGESTIONS_SUCCESS,
  LOADING_INDUSTRIES_SUGGESTION,
  DELETING_INDUSTRY_ROW,
  DELETED_INDUSTRY_ROW_SUCCESS,
  DELETED_INDUSTRY_ROW_FAILURE,
  SAVED_INDUSTRIES_SUCCESS,
  UPDATE_INDUSTRY_TAGS,
} from '../../actions/project/industry';
import {
  LOADING_APPROVALS,
  LOADED_APPROVALS_FAILURE,
  LOADED_APPROVALS_SUCCESS,
  SORT_APPROVALS,
} from '../../actions/project/approval';
import { LOADING_TEMPLATES, LOADED_TEMPLATES_FAILURE, LOADED_TEMPLATES_SUCCESS } from '../../actions/project/template';
import {
  LOADING_TARGETS,
  LOADED_TARGETS_FAILURE,
  LOADED_TARGETS_SUCCESS,
  LOADING_SORTED_TARGETS,
  LOADED_SORTED_TARGETS_FAILURE,
  LOADED_SORTED_TARGETS_SUCCESS,
  SORT_TARGETS,
  RELOADING_TARGETS,
  RELOADED_TARGETS_SUCCESS,
  RELOADED_TARGETS_FAILURE,
} from '../../actions/project/target';
import getErrorResponse from '../../utils/getErrorResponse';
import { spy, setIn, setOriginalIn, revert, getIn } from '../../utils/ChangeSpy';

const range = name => [`${name}High`, `${name}Low`];

const FIELDS_TO_SPY = [
  ...range('revenue'),
  ...range('ebitda'),
  ...range('ev'),
  'minority',
  'mezz',
  'buyout',
  'recap',
  'takePrivate',
  'turnaround',
  'carveOut',
  'geography',
  'selectedBICs',
  'selectedBMs',
  'comparablesIds',
  'comparables',
  'projectComparablesId1',
  'projectComparablesId2',
  'projectComparablesId3',
  'projectComparablesId4',
  'projectComparablesId5',
  'comparablesSuggest1',
  'comparablesSuggest2',
  'comparablesSuggest3',
  'comparablesSuggest4',
  'comparablesSuggest5',
];

const defaultState = spy(
  fromJS({
    projectLoading: false,
    userLoading: false,
    industryLoading: false,
    approvalLoading: false,
    templateLoading: false,
    targetLoading: false,
    userSuggestions: [],
    execSuggestions: [],
    harvcoLeadSuggestions: [],
    clientLeadSuggestions: [],
    industrySuggestions: [],
    revenueHigh: '',
    revenueLow: '',
    ebitdaHigh: '',
    ebitdaLow: '',
    evHigh: '',
    evLow: '',
    minority: '',
    mezz: '',
    buyout: '',
    recap: '',
    takePrivate: '',
    carveOut: '',
    turnaround: '',
    geography: '',
    industrySuggestionRequestId: '',
    userSuggestionRequestId: '',
    projectRequestId: '',
    targetSortField: '',
    targetSortDirection: '',
    approvalSortDirection: '',
    approvalSortField: '',
    errors: [''],
    bic: [],
    bm: [],
    selectedBICs: [],
    selectedBMs: [],
    loadingComparables: true,
    comparables: [],
    comparablesIds: [],
    projectComparablesId1: null,
    projectComparablesId2: null,
    projectComparablesId3: null,
    projectComparablesId4: null,
    projectComparablesId5: null,
    comparablesSuggest1: '',
    comparablesSuggest2: '',
    comparablesSuggest3: '',
    comparablesSuggest4: '',
    comparablesSuggest5: '',
  }),
  FIELDS_TO_SPY,
);

export default function(state = defaultState, action) {
  switch (action.type) {
    case SHOW_PROJECT_LOADING:
      return state.set('projectLoading', true);

    case SHOW_USERS_LOADING:
      return state.set('userLoading', true);

    case DELETING_INDUSTRY_ROW:

    // fallthrough
    case SHOW_INDUSTRIES_LOADING:
      return state.set('industryLoading', true);

    case LOADING_APPROVALS:
      return state.set('approvalLoading', true);

    case LOADING_TEMPLATES:
      return state.set('templateLoading', true);

    case LOADING_TARGETS:

    // fallthrough
    case RELOADING_TARGETS:

    // fallthrough
    case LOADING_SORTED_TARGETS:
      return state.set('targetLoading', true);

    case REQUEST_API_PROJECT_FAILURE:
      return state.merge({
        projectLoading: false,
        errors: state.get('errors').concat(getErrorResponse(action.response)),
      });

    case REQUEST_API_USERS_FAILURE:
      return state.merge({
        userLoading: false,
        errors: state.get('errors').concat(getErrorResponse(action.response)),
      });

    case REQUEST_API_INDUSTRIES_FAILURE:
      return state.merge({
        industryLoading: false,
        errors: state.get('errors').concat(getErrorResponse(action.response)),
      });

    case LOADED_APPROVALS_FAILURE:
      return state.merge({
        approvalLoading: false,
        errors: state.get('errors').concat(getErrorResponse(action.response)),
      });

    case LOADED_TEMPLATES_FAILURE:
      return state.merge({
        templateLoading: false,
        errors: state.get('errors').concat(getErrorResponse(action.response)),
      });

    case LOADED_TARGETS_FAILURE:

    // fallthrough
    case RELOADED_TARGETS_FAILURE:

    // fallthrough
    case LOADED_SORTED_TARGETS_FAILURE:
      return state.merge({
        targetLoading: false,
        errors: state.get('errors').concat(getErrorResponse(action.response)),
      });

    case LOADING_PROJECT_CRITERIA:
      return state.merge({ criteriaLoading: true });

    case LOADED_PROJECT_CRITERIA_SUCCESS:
      if (action.response) {
        return spy(state.merge(mapResponse(action)), FIELDS_TO_SPY);
      }

      return state;

    case SAVED_PROJECT_CRITERIA_SUCCESS:
      return spy(setValueAsOriginalByMap(state, action.fields), FIELDS_TO_SPY);

    case SAVED_PROJECT_CRITERIA_FAILURE:
      return state.merge({
        errors: state.get('errors').concat(getErrorResponse(action.response)),
      });

    case FETCHING_LIST_COMPARABLES: {
      return state.merge({
        loadingComparables: true,
        comparables: [],
      });
    }

    case FETCHING_LIST_COMPARABLES_SUCCESS:
      return state.merge({
        loadingComparables: false,
        comparables: action.response.data,
      });

    case LOADED_PROJECT_COMPARABLES:
      return state.set('loadingComparables', true);

    case LOADED_PROJECT_COMPARABLES_SUCCESS:
      return state.merge({
        comparables: action.response.data,
        loadingComparables: false,
      });

    case SAVED_PROJECT_COMPARABLES_SUCCESS:
      return state.set('projectLoading', false);

    case CLEAR_COMPARABLES:
      return state.merge({ comparables: [] });

    case CHANGE_COMPARABLES:
      return state.merge({
        comparables: action.data.filter(item => Object.keys(item).length !== 0),
        comparablesIds: action.ids,
      });

    case DELETED_INDUSTRY_ROW_FAILURE:
      return state.merge({
        industryLoading: false,
        errors: state.get('errors').concat(getErrorResponse(action.response)),
      });

    case SAVED_PROJECT_SUCCESS:
      return spy(setValueAsOriginalByMap(state, action.fields), FIELDS_TO_SPY).set('projectLoading', false);

    // fallthrough
    case LOADED_PROJECT_SUCCESS: {
      if (action.response) {
        return spy(state.merge(mapResponse(action)), FIELDS_TO_SPY);
      }

      return state.set('projectLoading', false);
    }

    // fallthrough
    case SAVED_USERS_SUCCESS:

    // fallthrough
    case LOADED_USERS_SUCCESS:
      return state.set('userLoading', false);

    case SAVED_INDUSTRIES_SUCCESS:

    // fallthrough
    case DELETED_INDUSTRY_ROW_SUCCESS:
      return state.set('industryLoading', false);

    case LOAD_INDUSTRIES_SUCCESS:
      return loadIndustry(state.set('industryLoading', false), action);

    case LOADED_APPROVALS_SUCCESS:
      return state.set('approvalLoading', false);

    case LOADED_TEMPLATES_SUCCESS:
      return state.set('templateLoading', false);

    case LOADED_TARGETS_SUCCESS:

    // fallthrough
    case RELOADED_TARGETS_SUCCESS:

    // fallthrough
    case LOADED_SORTED_TARGETS_SUCCESS:
      return state.set('targetLoading', false);

    case LOADING_INDUSTRIES_SUGGESTION:
      return state.set('industrySuggestionRequestId', action.requestId);

    case LOADING_USERS_SUGGESTION:
      return state.set('userSuggestionRequestId', action.requestId);

    case LOADING_PROJECT_SUGGESTION:
      return state.merge({
        projectRequestId: action.requestId,
        [`${action.field}Suggestion`]: List(),
      });

    case LOADED_USERS_SUGGESTIONS_SUCCESS:
      return state.set('userSuggestions', loadSuggestions(state, action, 'user'));

    case LOADED_PROJECT_SUGGESTION_SUCCESS:
      return loadProjectSuggestions(state, action);

    case LOADED_INDUSTRIES_SUGGESTIONS_SUCCESS:
      return state.set('industrySuggestions', loadSuggestions(state, action, 'industry'));

    case SORT_TARGETS:
      return state.merge({
        targetSortField: action.field,
        targetSortDirection: action.direction,
      });

    case SORT_APPROVALS:
      return state.merge({
        approvalSortField: action.field,
        approvalSortDirection: action.direction,
      });

    case RESET_ERROR_LIST:
      return state.set('errors', List());

    case UPDATE_INDUSTRY_TAGS:
      return updateSelectedIndustryTags(state, action);

    case UPDATE_PROJECT_COMMON_INFO:
      return setIn(state, action.field, action.value);

    case REVERT_PROJECT_CHANGES: {
      return revert(state);
    }

    default:
      return state;
  }
}

function loadIndustry(state, { response: { data } }) {
  let selectedBICs = [];
  let bic = data.projectIndustryCategories.map(i => {
    selectedBICs.push(i.id);

    return {
      id: i.id,
      name: i.category,
      deprecated: false,
      deletedAt: i.deletedAt,
    };
  });

  bic = bic.concat(
    data.industries.map(t => ({
      id: -1 * t.id,
      name: t.industryLabel,
      deprecated: true,
      deletedAt: t.deletedAt,
    })),
  );

  let selectedBMs = [];
  const bm = data.projectBusinessModels.map(i => {
    selectedBMs.push(i.id);

    return {
      id: i.id,
      name: i.category,
      deprecated: false,
      deletedAt: i.deletedAt,
    };
  });

  selectedBICs = fromJS(sortBy(selectedBICs));
  selectedBMs = fromJS(sortBy(selectedBMs));

  let tmp = state.set('bm', fromJS(bm)).set('bic', fromJS(bic));

  tmp = setOriginalIn(tmp, 'selectedBICs', selectedBICs);
  tmp = setOriginalIn(tmp, 'selectedBMs', selectedBMs);
  tmp = setIn(tmp, 'selectedBICs', selectedBICs);

  return setIn(tmp, 'selectedBMs', selectedBMs);
}

function updateSelectedIndustryTags(state, action) {
  const { tags, models } = action;
  const selectedBICs = [];
  const selectedBMs = [];
  const ind = state.get('bic').filter(b => b.get('deprecated'));
  const bic = tags.map(t => {
    selectedBICs.push(t.id);

    return {
      id: t.id,
      name: t.category,
      deprecated: false,
      deletedAt: t.deletedAt,
    };
  });
  const bm = models.map(t => {
    selectedBMs.push(t.id);

    return {
      id: t.id,
      name: t.category,
      deprecated: false,
      deletedAt: t.deletedAts,
    };
  });

  return state
    .set('bm', fromJS(bm))
    .set('bic', fromJS(bic).concat(ind))
    .setIn(['selectedBICs', 'value'], fromJS(sortBy(selectedBICs)))
    .setIn(['selectedBMs', 'value'], fromJS(sortBy(selectedBMs)));
}

/**
 * Insert an empty row to list.
 *
 * @param list {Immutable.List} List.
 * @returns {Immutable.List}
 */
export function addEmptyRow(list) {
  let newList = list.map(it => it.merge({ mode: config.VIEW_MODE, name: it.get('origin') }));
  const index = newList.findKey(it => it.get('id') === -1 && it.get('origin') === '');

  if (index === undefined || index < 0) {
    newList = newList.push(
      Map({
        id: -1,
        name: '',
        origin: '',
        mode: config.VIEW_MODE,
      }),
    );
  }

  return switchToMode(newList, -1, config.EDIT_MODE);
}

/**
 * Switch a row in list to other mode.
 *
 * @param list {Immutable.List} List.
 * @param id {Number} Row id.
 * @param mode {String} Row's mode.
 * @returns {Immutable.List}
 */
export function switchToMode(list, id, mode) {
  return list.map(item => {
    if (item.get('id') === id) {
      return item.merge({
        name: mode === config.VIEW_MODE ? item.get('origin') : item.get('name'),
        mode,
      });
    }

    return item.set('mode', config.VIEW_MODE);
  });
}

/**
 * Switch all rows to view mode.
 *
 * @param list {Immutable.List} List.
 * @returns {Immutable.List}
 */
export function switchToView(list) {
  return list.map(item => item.merge({ mode: config.VIEW_MODE, name: item.get('origin') }));
}

/**
 * Change text of a row in list.
 *
 * @param list {Immutable.List} List.
 * @param id {Number} Row id.
 * @param text {String} Text.
 * @returns {Immutable.List}
 */
export function changeText(list, id, text = '') {
  const index = list.findKey(i => i.get('id') === id);

  if (index !== undefined && index > -1) {
    if (text.length) {
      return list.setIn([index, 'name'], text);
    }

    return list.set(
      index,
      Map({
        id: -1,
        name: '',
        origin: '',
        mode: config.VIEW_MODE,
        dirty: true,
      }),
    );
  }

  return list;
}

/**
 * Remove a row from list.
 *
 * @param list {Immutable.List} List.
 * @param id {Number} Row id.
 * @returns {Immutable.List}
 */
export function deleteRow(list, id) {
  const index = list.findKey(i => i.get('id') === id);

  if (index > -1) {
    return list.delete(index);
  }

  return list;
}

/**
 * Save selected suggestion to row.
 *
 * @param list {Immutable.List} List.
 * @param action {Object} Action.
 * @returns {Immutable.List}
 */
export function saveSuggestionToRow(list, action) {
  return list.map(item => {
    if (item.get('id') === action.id) {
      return Map({
        id: action.suggestionId,
        name: action.suggestionName,
        origin: action.suggestionName,
        mode: config.VIEW_MODE,
        dirty: true,
      });
    }

    return item.set('mode', config.VIEW_MODE);
  });
}

/**
 * Set all dirty fields to false.
 *
 * @param state {Immutable.Map} State response.
 * @returns {Immutable.Map}
 */
export function clearDirty(state) {
  return state.map(u => u.set('dirty', false));
}

/**
 * Load suggestion list.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action.
 * @returns {Immutable.Map}
 */
function loadProjectSuggestions(state, action) {
  if (action.response.status === 'success' && state.get('projectRequestId') === action.requestId) {
    return state.set(
      `${action.field}Suggestions`,
      fromJS(
        action.response.data.map(s => ({
          id: s.id,
          name: s.text,
        })),
      ),
    );
  }

  return state;
}

/**
 * Load suggestion list from response, check if request id matchs before loading.
 *
 * @param state {Immutable.Map} State.
 * @param action {Immutable.Map} Action.
 * @param type {String(users|industries)} Type.
 * @returns {Immutable.List}
 */
function loadSuggestions(state, action, type) {
  let ret = List();

  if (action.response.status === 'success' && state.get(`${type}SuggestionRequestId`) === action.requestId) {
    ret = fromJS(
      action.response.data.map(u => ({
        id: u.id,
        name: u.text,
      })),
    );
  }

  if (type === 'industry') {
    return insertAddingRow(ret);
  }

  return ret;
}

/**
 * Insert an adding row at bottom.
 *
 * @param list {Immutable.List} List.
 * @returns {Immutable.List}
 */
function insertAddingRow(list) {
  return list.push(
    Map({
      id: -1,
      name: 'Add New Industry',
    }),
  );
}

function mapResponse(action) {
  return {
    ...action.response.data,
    projectLoading: false,
  };
}

function setValueAsOriginalByMap(state, map) {
  return spy(
    map.reduce((result, key) => {
      const value = getIn(result, key);

      return result.setIn(key.split('.'), value);
    }, state),
    map,
  );
}
