import PropTypes from 'prop-types';

import React from 'react';
import Immutable from 'immutable';

const ErrorList = props => {
  const { errors, ...rest } = props;

  if (errors.size > 0) {
    return (
      <ul {...rest}>
        {errors.map((error, index) => (
          <li key={index} className="text-danger">
            {error}
          </li>
        ))}
      </ul>
    );
  }

  return null;
};

ErrorList.propTypes = {
  errors: PropTypes.instanceOf(Immutable.List).isRequired,
};

export default ErrorList;
