const DEFAULT_TIMEOUT = 250;

export default function debounce(callback, timeout = DEFAULT_TIMEOUT) {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), timeout);
  };
}

export function byKeyCalculation(keyCalculation, callback, timeout = DEFAULT_TIMEOUT) {
  const res = new Map();

  return (...args) => {
    const key = keyCalculation(...args);

    clearTimeout(res.get(key));

    res.set(
      key,
      setTimeout(() => {
        res.delete(key);

        return callback(...args);
      }, timeout),
    );
  };
}

export function passFirst(callback, timeout = DEFAULT_TIMEOUT) {
  let timer = null;

  return (...args) => {
    if (timer !== null) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        callback(...args);
      }, timeout);
    } else {
      return callback(...args);
    }
  };
}
