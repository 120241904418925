import { fromJS } from 'immutable';
import { camelizeKeys } from 'humps';

import * as ActionType from '../actions/findEmail';
import { setIn, spy, toJS } from '../utils/ChangeSpy';
import getValidator from '../utils/getValidator';

const SCHEMA = {
  type: 'object',
  properties: {
    fullName: {
      name: 'Name',
      description: 'Should not be empty',
      type: 'string',
      required: true,
    },
    domain: {
      name: 'Domain',
      description: 'Please enter a domain name, for example "google.com"',
      type: 'string',
      required: true,
      oneOf: [
        { type: 'string', pattern: /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/ },
      ],
    },
  },
};

const FIELDS_TO_SPY = ['fullName', 'domain'];

const defaultState = fromJS(
  spy(
    {
      loading: false,
      status: '',
      data: null,
      fullName: '',
      domain: '',
      inputErrors: {},
    },
    FIELDS_TO_SPY,
  ),
);

export default function finder(state = defaultState, action) {
  switch (action.type) {
    case ActionType.UPDATE_FINDER:
      return checkValidity(setIn(state, action.name, action.value));

    case ActionType.FETCHING_FIND_EMAIL:
      return state.merge({
        loading: true,
      });

    case ActionType.FETCHING_FIND_EMAIL_SUCCESS:
      if (action.response.data.contentData.email === '') {
        const errorMessage = "We didn't find the email address of this person.";
        const inputErrors = camelizeKeys({ full_name: [errorMessage] });

        return state.merge({
          status: 'invalid',
          data: null,
          loading: false,
          inputErrors,
        });
      }

      return state.merge({
        inputErrors: {},
        status: action.response.status,
        data: action.response.data.contentData,
        loading: false,
      });

    case ActionType.FETCHING_FIND_EMAIL_FAILURE:
      return state.merge({
        status: 'invalid',
        data: null,
        loading: false,
        inputErrors: camelizeKeys(action.response.errors),
      });

    case ActionType.CLEAR_FOUND_EMAIL:
      return state.merge({
        status: '',
        fullName: '',
        domain: '',
        data: null,
        inputErrors: {},
        loading: false,
      });

    default:
      return state;
  }
}

const validator = getValidator(SCHEMA);

function checkValidity(state) {
  const plainState = toJS(state);
  const isValid = validator.isValid(plainState);

  const inputErrors = validator.getFirstError(plainState);

  return state.merge({
    inputErrors,
    isValid,
  });
}
