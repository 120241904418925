import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable from 'immutable';
import { connect } from 'react-redux';

import connectOptions, { mergeProps } from '../../utils/connectOptions';
import Popups from '../../components/Popup';
import {
  changeName,
  changeFile,
  upload,
  clearValidationError,
  validate,
  reset,
} from '../../actions/project/templatePopup';
import { showInformation } from '../../utils/MessagePopup';

class TemplatePopupContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChoose = this.handleChoose.bind(this);
    this.onErrorClose = this.onErrorClose.bind(this);
    this.setFormElement = this.setFormElement.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.stage === 'done') {
      this.context.closePopup();
      this.props.reset();
      showInformation(this.context.openPopup, 'Uploaded successfully!');
    } else if (nextProps.stage === 'error') {
      this.context.closePopup();
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  handleUpload() {
    const { stage, validate, projectId, upload, isUploading } = this.props;

    if (isUploading) return;

    if (stage === 'start' || stage === 'invalid') {
      validate();

      return;
    }

    if (stage === 'ready') {
      upload(projectId, new FormData(this.form));
    }
  }

  handleClose() {
    this.context.closePopup();
  }

  handleChange(event) {
    this.props.changeName(event.target.value);
  }

  handleChoose(event) {
    this.props.changeFile(event.target.files[0].name || '');
  }

  getChildContext() {
    const nameError = this.props.name.length < 255 ? null : 'Name is too long';
    const inputErrors = nameError ? this.props.inputErrors.set('name', nameError) : this.props.inputErrors;

    return {
      onErrorClose: this.onErrorClose,
      inputErrors,
    };
  }

  onErrorClose(_, field) {
    this.props.clearValidationError(field);
  }

  setFormElement(input) {
    this.form = input;
  }

  render() {
    const { children, isUploading, ...rest } = this.props;

    return (
      <div>
        <Popups.UploadPopup
          {...rest}
          disabledUpload={isUploading}
          hasErrors={this.getChildContext().inputErrors.size > 0}
          mimeType=".docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          onChange={this.handleChange}
          onChoose={this.handleChoose}
          onClose={this.handleClose}
          onUpload={this.handleUpload}
          setFormElement={this.setFormElement}
        />
        {children}
      </div>
    );
  }
}

TemplatePopupContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Immutable.Map),
};

TemplatePopupContainer.propTypes = {
  children: PropTypes.any,
  errors: PropTypes.instanceOf(Immutable.List),
  file: PropTypes.object,
  inputErrors: PropTypes.instanceOf(Immutable.Map),
  isUploading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
  stage: PropTypes.string.isRequired,
};

TemplatePopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const popupState = state.project.templatePopup;

  return {
    projectId: props.popup.getIn(['props', 'projectId']),
    name: popupState.get('name'),
    inputErrors: popupState.get('inputErrors', Immutable.Map()),
    isUploading: popupState.get('isUploading'),
    stage: popupState.get('stage'),
    errors: popupState.get('errors'),
  };
};

export { TemplatePopupContainer };
export default connect(
  mapStateToProps,
  {
    changeName,
    changeFile,
    upload,
    clearValidationError,
    validate,
    reset,
  },
  mergeProps,
  connectOptions,
)(TemplatePopupContainer);
