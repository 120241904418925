import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { camelize, camelizeKeys } from 'humps';
import { List, Map } from 'immutable';
import classnames from 'classnames';
import moment from 'moment';

import config from '../../config';
import Grid from '../helpers/AgGridTable';
import DropDown, { DropDownElement } from '../helpers/DropDown';
import { showError, showInformation, showInformationCustomTitle } from '../../utils/MessagePopup';
import { clickIfNotDouble } from '../../utils/doubleClickCheck';
import checkActionPermission from '../../utils/browsePermission';
import { targetTableActions } from '../../utils/factories';
import { successWindwardGeneration } from '../../utils/successWindwardGeneration';
import { DELETE_FAIL_TEXT, DELETE_SUCCESSFULLY_TEXT, DELETION_REQUEST_TEXT } from '../../config/constants/common';
import { handleSortModelChanged } from '../../utils/sorting';

const { BACKLOGGED_EVENTS } = config;
const BACKLOGGED_KEYS = BACKLOGGED_EVENTS.map(camelize);

class CheckboxCellRenderer extends PureComponent {
  render() {
    if (!this.props.data) return <span />;

    return <input defaultChecked={this.props.data.selected} type="checkbox" />;
  }
}

class ApprovedCellRenderer extends PureComponent {
  render() {
    if (!this.props.data) return <span />;

    const { approved } = this.props.data;
    const content = approved ? (
      <i className="text-success fa fa-check" />
    ) : approved === false ? (
      <i className="text-danger fa fa-times" />
    ) : null;

    return <div className="approvedWrapper">{content}</div>;
  }
}

class Titled extends PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div className="truncate-text" title={data.description}>
        {data.description}
      </div>
    );
  }
}
class NextActionCellRenderer extends PureComponent {
  render() {
    if (!this.props.data) return <span />;

    const { data, value } = this.props;

    if (value) {
      return data.backlogged ? (
        <span>
          <i className="fa fa-clock-o" /> {value}
        </span>
      ) : (
        <span>{value}</span>
      );
    }

    return <span />;
  }
}

class Colored extends PureComponent {
  render() {
    if (!this.props.data) return <span />;

    const { value } = this.props;

    return value ? <span className="colorPositive">Y</span> : <span className="colorNegative">N</span>;
  }
}

class Substring extends PureComponent {
  render() {
    if (!this.props.data) return <span />;

    const { value } = this.props;

    return value ? <span>{`${value.substr(5, 2)}/${value.substr(8, 2)}/${value.substr(0, 4)}`}</span> : null;
  }
}

class ProjectCell extends PureComponent {
  render() {
    if (!this.props.data) return <span />;

    const { data } = this.props;
    const projectName = data.projectName ? data.projectName : '';
    const flName = data.projectExecFLName ? ` - ${data.projectExecFLName}` : '';
    const username = data.projectHarvcoLeadUserName ? ` (${data.projectHarvcoLeadUserName})` : '';

    return <span>{projectName + flName + username}</span>;
  }
}

class BrowseTable extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.gridApi = null;
    this.gridColumnApi = null;
    this.hasAccess = this.hasAccess.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleDeselectAll = this.handleDeselectAll.bind(this);
    this.handleGetSelectedIds = this.handleGetSelectedIds.bind(this);
    [this.onCellClicked, this.handleRowDoubleClicked] = clickIfNotDouble(
      this.onCellClicked.bind(this),
      this.handleRowDoubleClicked.bind(this),
      row => row.rowIndex,
    );
    this.handleContextMenuItemClick = this.handleContextMenuItemClick.bind(this);
    this.onSort = this.onSort.bind(this);
    this.handleAddTag = this.handleAddTag.bind(this);
    this.handleUpdateNextActions = this.handleUpdateNextActions.bind(this);
    this.handleDeleteNextActions = this.handleDeleteNextActions.bind(this);
    this.handleUpdateNextActionsDates = this.handleUpdateNextActionsDates.bind(this);
    this.handleSaveAddUpdateBuyer = this.handleSaveAddUpdateBuyer.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.onGridSizeChanged = this.onGridSizeChanged.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.handleResetSelection = this.handleResetSelection.bind(this);
    this.handleUpdateIndustryTags = this.handleUpdateIndustryTags.bind(this);
    this.confirmSelectedTags = this.confirmSelectedTags.bind(this);
    this.handleSetActions = checkActionPermission(this.props.user, this.handleSetActions.bind(this), context);
    this.handlePushNextActionDates = checkActionPermission(
      this.props.user,
      this.handlePushNextActionDates.bind(this),
      context,
    );
    this.handleAddUpdateBuyer = checkActionPermission(this.props.user, this.handleAddUpdateBuyer.bind(this), context);
    this.handleInternalFlip = checkActionPermission(this.props.user, this.handleInternalFlip.bind(this), context);
    this.handleDownloadExport = checkActionPermission(this.props.user, this.handleDownloadExport.bind(this), context);
    this.handleSetBacklog = checkActionPermission(this.props.user, this.handleSetBacklog.bind(this), context);
    this.handleRunWindwardReport = checkActionPermission(
      this.props.user,
      this.handleRunWindwardReport.bind(this),
      context,
    );
    this.contextActionList = targetTableActions(this.props.user.getIn(['roles', 0, 'slug']));
    this.handleShareLinkButton = this.handleShareLinkButton.bind(this);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.data.getIn(['sortBy', 'sortModel']) !== this.props.data.getIn(['sortBy', 'sortModel'])) {
      this.gridApi.setSortModel(this.props.data.getIn(['sortBy', 'sortModel']).toJS());
    }
  }

  getColumnDefs() {
    if (this.props.columnDefs !== this.lastColumnDefs) {
      this.lastColumnDefs = this.props.columnDefs;
      this.columnDefs = this.props.columnDefs.map(columnDef => {
        const field = columnDef.get('field');

        switch (field) {
          case 'selected':
            return columnDef
              .set('cellRendererFramework', CheckboxCellRenderer)
              .set('onRowChange', this.props.onRowChange);

          case 'approved':
            return columnDef.set('cellRendererFramework', ApprovedCellRenderer);

          case 'nextAction':
            return columnDef.set('cellRendererFramework', NextActionCellRenderer);

          case 'employed':

          // fallthrough
          case 'resume':

          // fallthrough
          case 'needsFund':
            return columnDef.set('cellRendererFramework', Colored);

          case 'nextActionDate':

          // fallthrough
          case 'lastActionDate':

          // fallthrough
          case 'lastResearchedDate':
            return columnDef.set('cellRendererFramework', Substring);

          case 'projectName':
            return columnDef.get('renderType') !== 'projectGroup'
              ? columnDef
              : columnDef.set('cellRendererFramework', ProjectCell);

          case 'description':
            return columnDef.set('cellRendererFramework', Titled);

          default:
            return columnDef;
        }
      });
    }

    return this.columnDefs;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    if (this.props.onGridReady) {
      this.props.onGridReady(params);
    }
  }

  handleSelectAll(event) {
    event.preventDefault();

    if (this.props.data.get('queryResults').size > 0) {
      this.props.handleUpdateAllRows({
        name: this.props.name,
        field: 'selected',
        value: true,
      });
    }
  }

  handleDeselectAll(event) {
    event.preventDefault();

    if (this.props.data.get('queryResults').size > 0) {
      this.props.handleUpdateAllRows({
        name: this.props.name,
        field: 'selected',
        value: false,
      });
    }
  }

  handleGetSelectedIds() {
    const { data } = this.props;

    return data.get('selectList').reduce((acc, i) => {
      acc.push(i.get('id'));

      return acc;
    }, []);
  }

  onCellClicked(params) {
    const {
      colDef: { field },
      data: { order, selected },
    } = params;

    if (field === 'selected') {
      this.props.handleUpdateSelectedRow({
        name: this.props.name,
        index: order,
        field: 'selected',
        value: !selected,
      });
    }
  }

  handleRowDoubleClicked(row) {
    this.props.onDoubleClickRow(row);
  }

  handleAddTag(tag, callback) {
    const { user, onAddTag } = this.props;

    onAddTag({
      body: {
        params: {
          tag,
          user_id: user.get('id'),
          selected_ids: this.handleGetSelectedIds(),
        },
      },
      callback,
    });
  }

  handleUpdateNextActions(formData, callback) {
    const { onSetNextActions } = this.props;
    const params = {
      selected_ids: this.handleGetSelectedIds(),
      override: formData.get('override'),
      backlogged: formData.get('backlog', false),
    };

    if (formData.get('date')) {
      params.date = formData.get('date').format('YYYY-MM-DD');
    }

    if (formData.getIn(['assignedTo', 'selected'])) {
      params.user_id = formData.getIn(['assignedTo', 'selected', 'id']);
    }

    if (formData.getIn(['activity', 'selected'])) {
      params.activity = formData.getIn(['activity', 'selected', 'value']);
    }

    if (formData.get('description')) {
      params.description = formData.get('description');
    }

    if (formData.getIn(['status', 'index']) > 0) {
      params.statusId = formData.getIn(['status', 'index']);
    }

    onSetNextActions({
      body: {
        params,
      },
      callback,
    });
  }

  handleDeleteNextActions(callback) {
    const { onSetNextActions } = this.props;
    const params = {
      selected_ids: this.handleGetSelectedIds(),
      na_delete: true,
    };

    onSetNextActions({
      body: {
        params,
      },
      callback,
    });
  }

  handleUpdateNextActionsDates(formData, callback) {
    const { onPushNextActionsDates } = this.props;
    const params = {
      selected_ids: this.handleGetSelectedIds(),
    };

    if (formData.get('forward')) {
      params.push_forward = true;
      params.days = parseInt(formData.get('forwardDays', 0), 10);
    }

    if (formData.get('backward')) {
      params.push_backward = true;
      params.days = parseInt(formData.get('backwardDays', 0), 10);
    }

    if (formData.get('following')) {
      params.set_date = true;
      params.date = formData.get('followingDate', moment()).format('YYYY-MM-DD');
    }

    onPushNextActionsDates({
      body: {
        params,
      },
      callback,
    });
  }

  handleSaveAddUpdateBuyer(formData, callback) {
    const { onSaveAddUpdateBuyer } = this.props;
    const params = Object.assign(
      {
        selected_ids: this.handleGetSelectedIds(),
      },
      formData,
    );

    onSaveAddUpdateBuyer({
      body: {
        params,
      },
      callback,
    });
  }

  handleDelete(deleteProp, successMessage) {
    const { data, name, onUpdateQueryResult, onGetPageData } = this.props;

    deleteProp({
      body: {
        params: {
          selected_ids: this.handleGetSelectedIds(),
        },
      },
      callback: {
        beforeStart: this.context.startPopupLoading,
        afterSuccess: () => {
          this.context.endPopupLoading();
          showInformation(this.context.openPopup, successMessage);
          onUpdateQueryResult(
            name,
            data.get('queryResults').filter(item => item.get('selected') !== true),
          );
          onGetPageData(1, false);
        },
        afterError: () => {
          this.context.endPopupLoading();
          showError(this.context.openPopup, [DELETE_FAIL_TEXT]);
        },
      },
    });
  }

  confirmSelectedTags(selectedList, tags, models) {
    const industryCategoryIds = [...tags, ...models].map(category => category.id);

    if (industryCategoryIds.length === 0) return;

    const confirmMessage = [
      'You have chosen to add the following Industry Tags to the selected targets: \n',
      models
        .reduce((arr, { category }) => {
          arr.push(`${category} (Business Model)`);

          return arr;
        }, [])
        .join('\n'),
      tags
        .reduce((arr, { category, parentId }) => {
          if (parentId) {
            arr.push(`${category} (Sub Category)`);
          } else {
            arr.push(`${category} (Broad Industry Category)`);
          }

          return arr;
        }, [])
        .join('\n'),
      '\nIs this correct?',
    ].join('\n');

    // This is necessary to open "ConfirmPopup" after closing "TagsManagementPopup".
    setTimeout(() => {
      this.context.openPopup('ConfirmPopup', {
        message: confirmMessage,
        onOk: () => this.handleUpdateIndustryTags(selectedList, industryCategoryIds),
        onCancel: () =>
          this.context.openPopup('TagsManagementPopup', {
            addNew: false,
            callback: (tags, models) => this.confirmSelectedTags(selectedList, tags, models),
          }),
        yes: 'Add Tags',
        no: 'Cancel',
      });
    }, 0);
  }

  handleUpdateIndustryTags(selectedIds, industryCategoryIds) {
    const { updateIndustryTags, onGetPageData } = this.props;

    updateIndustryTags({
      body: {
        params: {
          selected_ids: selectedIds,
          override: false,
          industry_category_ids: industryCategoryIds,
        },
      },
      callback: {
        beforeStart: this.context.startPopupLoading,
        afterSuccess: () => {
          this.context.endPopupLoading();
          showInformation(this.context.openPopup, 'Update Successfully');
          onGetPageData(1, false);
        },
        afterError: () => {
          this.context.endPopupLoading();
          showError(this.context.openPopup, ['Update Fail. Please try again!']);
        },
      },
    });
  }

  handleSetActions(currentPage, refreshGrid) {
    this.context.openPopup('SetNextActionsPopup', {
      currentPage,
      onUpdate: this.handleUpdateNextActions,
      onDelete: this.handleDeleteNextActions,
      refreshGrid,
    });
  }

  handlePushNextActionDates(refreshGrid) {
    this.context.openPopup('PushNextActionDatesPopup', {
      onUpdate: this.handleUpdateNextActionsDates,
      refreshGrid,
    });
  }

  handleAddUpdateBuyer(refreshGrid) {
    this.context.openPopup('AddUpdateBuyerPopup', {
      onSave: this.handleSaveAddUpdateBuyer,
      refreshGrid,
    });
  }

  handleInternalFlip(idList, refreshGrid) {
    this.context.openPopup('InternalFlipPopup', { idList, refreshGrid });
  }

  handleDownloadExport(selectedList) {
    const params = {
      requestParams: this.props.requestParams,
      stateParams: this.props.stateParams,
    };

    this.props.onDownloadExport(selectedList, params);
  }

  handleSetBacklog(selectedIds) {
    const { setBacklog, onGetPageData } = this.props;

    setBacklog({
      body: {
        params: {
          selected_ids: selectedIds,
        },
      },
      callback: {
        beforeStart: this.context.startPopupLoading,
        afterSuccess: ({ response: { list } }) => {
          const countSkipped = selectedIds.length - list.skipE.length - list.skipNA.length - list.skipRF.length;

          const header = `${countSkipped} out of ${selectedIds.length} Companies have been backlogged, including:`;

          const popupMessages = [];

          const backLoggerMessage = BACKLOGGED_KEYS.reduce((accum, key, index) => {
            if (!list.hasOwnProperty(key)) return accum;

            accum.push(`${list[key]} ${BACKLOGGED_EVENTS[index]}'s`);

            return accum;
          }, []).join('\n');

          if (backLoggerMessage) {
            popupMessages.push(backLoggerMessage);
          }

          if (list.skipNA.length) {
            const missingNATitle =
              'The following companies have been skipped because they do not have Next Actions that can be Backlogged: \n';
            let skipNAMessage = '';

            list.skipNA.forEach(error => {
              const link = `( ${window.location.origin}/company/${error.companyId}/target )`;
              const companyName = `${error.companyName} ${link}`;

              skipNAMessage = `${skipNAMessage} ${companyName} \n`;
            });
            popupMessages.push(`${missingNATitle} ${skipNAMessage}`);
          }

          if (list.skipRF.length) {
            const missingRFTitle = 'The following profiles were missing information: \n';
            let skipRFMessage = '';

            list.skipRF.forEach(error => {
              let errors = '';
              const link = `( ${window.location.origin}/company/${error.companyId}/target )`;
              const companyName = `${error.companyName} ${link}\n`;

              error.emptyRequiredFields.forEach(message => {
                errors += `- ${message}.\n`;
              });
              skipRFMessage = `${skipRFMessage} ${companyName} ${errors}`;
            });
            popupMessages.push(`${missingRFTitle} ${skipRFMessage}`);
          }

          if (list.skipE.length) {
            const missingETitle = 'The following companies have been skipped because: \n';
            let skipEMessage = '';

            list.skipE.forEach(error => {
              const link = `( ${window.location.origin}/company/${error.companyId}/target )`;
              const errorLink = `${error.error} ${link}\n`;

              skipEMessage = `${skipEMessage} ${errorLink}`;
            });
            popupMessages.push(`${missingETitle} ${skipEMessage}`);
          }

          this.context.endPopupLoading();
          showInformationCustomTitle(this.context.openPopup, header, popupMessages, () => {
            this.context.closePopup();
          });
          onGetPageData(1, true);
        },
        afterError: () => {
          this.context.endPopupLoading();
          showError(this.context.openPopup, ['Set backlog is fail. Please try again!']);
        },
      },
    });
  }

  handleRunWindwardReport(idList, selectedUserId, nameTemplate, name, templateParams, searchBuyerId) {
    const { data, runWindwardReport, requestParams } = this.props;
    const selectedTargets = idList.map(id =>
      data
        .get('queryResults')
        .find(obj => obj.get('id') === id)
        .toJS(),
    );

    runWindwardReport({
      name,
      selectedUserId,
      nameTemplate,
      templateParams,
      selectedTargets,
      searchBuyerId,
      searchParams: requestParams,
      afterSuccess: ({ response: { data } }) => successWindwardGeneration(data, this.context),
      afterError: () => this.context.endPopupLoading(),
    });
  }

  handleAssignTradeShow(idList, refreshGrid) {
    this.context.openPopup('AssignTradeShowPopup', {
      targetsIds: idList,
      refreshGrid,
    });
  }

  handleConfirmMerging(tag, refreshGrid) {
    const { onConfirmMerging } = this.props;

    onConfirmMerging({
      body: {
        params: {
          selected_ids: this.handleGetSelectedIds(),
        },
      },
      callback: {
        beforeStart: this.context.startPopupLoading(),
        afterSuccess: () => {
          this.context.endPopupLoading();
          refreshGrid();
        },
        afterError: () => {
          this.context.endPopupLoading();
        },
      },
    });
  }

  hasAccess() {
    if (!this.context.currentUser) return false;

    return [config.DIRECTOR, config.FINANCE_ADMIN].includes(this.context.currentUser.getIn(['roles', 0, 'slug'], null));
  }

  handleContextMenuItemClick(event, { key }) {
    const { data, name, onGetPageData, onDelete, onDeleteConfirmation } = this.props;
    const selectList = data.get('selectList');
    const limit = this.hasAccess() ? selectList.size : 1000;

    let includedBuyers = null;

    if (data.getIn(['buyer', 'selectedList'])) {
      const buyersSelectedList = data.getIn(['buyer', 'selectedList']).toJS();
      const buyersSelectedListParam = data.getIn(['buyer', 'selectedListParam']).toJS();

      includedBuyers = buyersSelectedListParam.filter((_, i) => !buyersSelectedList[i].exclude);
    }

    const selectedList = selectList.slice(0, limit).reduce((acc, i) => {
      acc.push(i.get('id'));

      return acc;
    }, []);

    const refreshGrid = () => {
      onGetPageData(1, false);
    };

    switch (key) {
      case config.actionList.getIn([0, 'key']):
        this.context.openPopup('AddHarvcoTagPopup', {
          onAddTag: this.handleAddTag,
          refreshGrid,
        });
        break;

      case config.actionList.getIn([1, 'key']):
        this.handleSetActions('browse', refreshGrid);
        break;

      case config.actionList.getIn([2, 'key']):
        this.handlePushNextActionDates(refreshGrid);
        break;

      case config.actionList.getIn([3, 'key']):
        this.handleAddUpdateBuyer(refreshGrid);
        break;

      case config.actionList.getIn([4, 'key']):
        this.handleInternalFlip(selectedList, refreshGrid);
        break;

      case config.actionList.getIn([5, 'key']):
        this.handleDownloadExport(selectedList);
        break;

      case config.actionList.getIn([6, 'key']):
        this.context.openPopup('DeleteConfirmPopup', {
          delete: () => this.handleDelete(onDelete, DELETE_SUCCESSFULLY_TEXT),
          title: 'Delete target(s)',
        });
        break;

      case config.actionList.getIn([7, 'key']):
        this.context.openPopup('DeleteConfirmPopup', {
          delete: () => this.handleDelete(onDeleteConfirmation, DELETION_REQUEST_TEXT),
          message:
            'Are you sure you want to request your supervisor to delete selected database record(s)? After the supervisor approval, the records and all associate data will be permanently deleted.',
          title: 'Delete target(s)',
        });
        break;

      case config.actionList.getIn([8, 'key']):
        this.context.openPopup('TagsManagementPopup', {
          addNew: false,
          callback: (tags, models) => this.confirmSelectedTags(selectedList, tags, models),
        });
        break;

      case config.actionList.getIn([9, 'key']):
        this.context.openPopup('ConfirmPopup', {
          message: `You are about to Backlog ${selectedList.length} Target(s). Are you sure you want to proceed?`,
          yes: 'Yes',
          no: 'No',
          onOk: () => {
            this.handleSetBacklog(selectedList);
            this.context.closePopup();
          },
          onCancel: () => this.context.closePopup(),
        });
        break;

      case config.actionList.getIn([10, 'key']):
        this.context.openPopup('RunWindwardReportPopup', {
          callback: (selectedUserId, nameTemplate, templateParams) => {
            if (includedBuyers) {
              const searchBuyerId = (includedBuyers.length > 0 && includedBuyers[0].buyer_id) || null;

              return this.handleRunWindwardReport(
                selectedList,
                selectedUserId,
                nameTemplate,
                name,
                templateParams,
                searchBuyerId,
              );
            }

            return this.handleRunWindwardReport(selectedList, selectedUserId, nameTemplate, name, templateParams);
          },
        });
        break;

      case config.actionList.getIn([11, 'key']):
        this.handleAssignTradeShow(selectedList, refreshGrid);
        break;

      case config.actionList.getIn([12, 'key']):
        this.context.openPopup('ConfirmPopup', {
          message: 'Are you really sure you want to confirm merge for selected companies?\nThis operation can not be reverted.',
          yes: 'Yes',
          no: 'No',
          onOk: () => {
            this.handleConfirmMerging(selectedList, refreshGrid);
            this.context.closePopup();
          },
          onCancel: () => this.context.closePopup(),
        });
        break;

      default:
        break;
    }
  }

  onSort(sortModel) {
    handleSortModelChanged(sortModel, this.props.data, this.props.onChange);
  }

  onGridSizeChanged() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi.getSelectedRows();

    if (this.props.onSelectRows && selectedRows.length > 1) {
      this.props.onSelectRows({
        name: this.props.name,
        rowIndexs: selectedRows.map(row => row.order),
      });
      setTimeout(() => {
        this.gridApi.deselectAll();
      }, 2000);
    }
  }

  getRowNodeId(data) {
    return data.order.toString();
  }

  handleResetSelection(event) {
    event.preventDefault();

    this.props.handleResetSelection({
      name: this.props.name,
    });
  }

  handleShareLinkButton(event) {
    event.preventDefault();

    const requestParams = camelizeKeys(this.props.requestParams);
    const { stateParams, name } = this.props;

    this.context.openPopup('ShareLinkPopup', { requestParams, stateParams, name });
  }

  render() {
    const {
      data,
      onGetNextPageData,
      onClearFilter,
      hideContextDropDown,
      onToggle,
      onGetPageData,
      onAbortRequest,
      name,
      hasParams,
      onConfirmMerging,
    } = this.props;

    const selectedNumber = data.get('selectList').size;
    const selectedContent =
      selectedNumber > 0 ? (
        <span className="BrowseTable-results">
          {selectedNumber} selected
          <a className="BrowseTable-control ml10" href="#reset" onClick={this.handleResetSelection}>
            Reset Selection
          </a>
        </span>
      ) : null;

    const mergeResultOnlyChecked = data.getIn(['mergeResultOnly', 'checked']);

    const actionContents = this.contextActionList
      .filter(action => {
        if (name !== 'targets') {
          return action.name !== 'Assign Trade Show to Selected Targets';
        }

        return action;
      })
      .filter(action => {
        // display "Merge Confirm" checkbox
        if (action.key === 'mergeConfirm' && onConfirmMerging) {
          if (mergeResultOnlyChecked === false) {
            return null;
          }
        }

        if (action.key === 'mergeConfirm' && !onConfirmMerging) {
          return null;
        }

        return action;
      })
      .map(({ attributes, key, name }) => (
        <DropDownElement {...attributes} key={key} onClick={event => this.handleContextMenuItemClick(event, { key })}>
          {name}
        </DropDownElement>
      ));

    const caretClassName = classnames({
      'fa fa-caret-right': data.get('showTabControls'),
      'fa fa-caret-left': !data.get('showTabControls'),
    });

    const contextDropDownClass = classnames({
      'btn btn-default btn-xs': true,
      hide: hideContextDropDown === true,
    });

    const selectClass = classnames({
      'BrowseTable-control': true,
      hide: hideContextDropDown === true,
    });

    const searchButtonClasses = classnames({
      'btn btn-default btn-xs': true,
      'btn-disabled': !hasParams,
    });

    const cancelButtonClasses = classnames({
      'btn btn-default btn-xs': true,
      'btn-disabled': !data.get('isFetching'),
    });

    const massSelectProps = name === 'targets' || name === 'executives' ? { rowSelection: 'multiple' } : {};

    const renderShareButton = (name === 'targets' || name === 'buyers' || name === 'executives') && (
      <a className="btn btn-default btn-xs" href="#shareLink" onClick={this.handleShareLinkButton}>
        <span className="fa fa-share" />
        Share link
      </a>
    );

    return (
      <div className="BrowseTable">
        <div className="BrowseTable-controlsWrap">
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <div className="BrowseTable-controls BrowseTable-controls--main">
                <a className={selectClass} href="#selectAll" onClick={this.handleSelectAll}>
                  Select all
                </a>
                <a className={selectClass} href="#selectNone" onClick={this.handleDeselectAll}>
                  Select none
                </a>
                <DropDown
                  btnContent="Actions"
                  className={contextDropDownClass}
                  disabled={selectedNumber === 0}
                  id="actionDropDown"
                >
                  {actionContents}
                </DropDown>
                <span className="BrowseTable-results">{data.get('totalRecord')} results</span>
                {selectedContent}
              </div>
            </div>
            <div className="col-sm-6 col-xs-12">
              <div className="BrowseTable-controls BrowseTable-controls--buttons">
                <a className={searchButtonClasses} onClick={() => onGetPageData(1, false)}>
                  <span className="fa-search fa" />
                  Search
                </a>
                <a className={cancelButtonClasses} onClick={() => onAbortRequest(name)}>
                  <span className="fa-ban fa" />
                  Cancel
                </a>
                <a className="btn btn-default btn-xs" onClick={() => onGetPageData(1, false)}>
                  <span className="fa-refresh fa" />
                  Refresh
                </a>
                <a className="btn btn-default btn-xs" onClick={() => onClearFilter(name)}>
                  <span className="fa-times fa" />
                  Clear
                </a>
                {renderShareButton}
                {onToggle && (
                  <a className="btn btn-default btn-direct btn-xs" onClick={onToggle}>
                    <span className={caretClassName} />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="ag-theme-fresh">
          <Grid
            {...massSelectProps}
            columnDefs={this.getColumnDefs()}
            getRowNodeId={this.getRowNodeId}
            isFetching={data.get('isFetching')}
            onCellClicked={this.onCellClicked}
            onGetNextPageData={onGetNextPageData}
            onGridReady={this.onGridReady}
            onRowDoubleClicked={this.handleRowDoubleClicked}
            onSelectionChanged={this.onSelectionChanged}
            onSortModelChanged={this.onSort}
            page={data.get('page')}
            rowData={data.get('queryResults')}
            suppressMovableColumns={false}
            totalPages={data.get('totalPages')}
            sortable
          />
        </div>
      </div>
    );
  }
}

BrowseTable.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  startPopupLoading: PropTypes.func.isRequired,
  endPopupLoading: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
};

BrowseTable.propTypes = {
  columnDefs: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  handleUpdateSelectedRow: PropTypes.func,
  hideContextDropDown: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onAddTag: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onConfirmMerging: PropTypes.func,
  onDelete: PropTypes.func,
  onDownloadExport: PropTypes.func,
  onGetNextPageData: PropTypes.func.isRequired,
  onPushNextActionsDates: PropTypes.func,
  onSaveAddUpdateBuyer: PropTypes.func,
  onSetNextActions: PropTypes.func,
  onUpdateQueryResult: PropTypes.func,
  setBacklog: PropTypes.func,
  updateIndustryTags: PropTypes.func,
};

export { BrowseTable };
export default connect(state => ({
  user: state.auth.get('user', null),
}))(BrowseTable);
