import React from 'react';
import FormGroup from '../helpers/FormGroup';

const PriorityRadio = ({ data, colDef }) => {
  const handlePriorityChange = (event, data) => {
    colDef.cellRendererParams.onChange(event.target, { ...data, targetBuyerPriority: event.target.value });
  };

  const name = `target-${data.id}`;
  const idA = `target-${data.id}-A`;
  const idB = `target-${data.id}-B`;
  const idC = `target-${data.id}-C`;
  const idExclude = `target-${data.id}-Exclude`;

  const isDisabledInput = colDef.cellRendererParams.isDisabled;

  return (
    <>
      <FormGroup>
        <div className="radio-wrap radio-wrap--reverse">
          <label className="font-size-14" htmlFor={idA}>
            A
          </label>
          <input
            checked={data.targetBuyerPriority === 'A'}
            disabled={isDisabledInput}
            id={idA}
            name={name}
            onChange={event => handlePriorityChange(event, data)}
            type="radio"
            value="A"
          />
        </div>
      </FormGroup>
      <FormGroup>
        <div className="radio-wrap radio-wrap--reverse">
          <label htmlFor={idB}>B</label>
          <input
            checked={data.targetBuyerPriority === 'B'}
            disabled={isDisabledInput}
            id={idB}
            name={name}
            onChange={event => handlePriorityChange(event, data)}
            type="radio"
            value="B"
          />
        </div>
      </FormGroup>
      <FormGroup>
        <div className="radio-wrap radio-wrap--reverse">
          <label htmlFor={idC}>C</label>
          <input
            checked={data.targetBuyerPriority === 'C'}
            disabled={isDisabledInput}
            id={idC}
            name={name}
            onChange={event => handlePriorityChange(event, data)}
            type="radio"
            value="C"
          />
        </div>
      </FormGroup>
      <FormGroup>
        <div className="radio-wrap radio-wrap--reverse">
          <label htmlFor={idExclude}>Exclude</label>
          <input
            checked={data.targetBuyerPriority === 'X'}
            disabled={isDisabledInput}
            id={idExclude}
            name={name}
            onChange={event => handlePriorityChange(event, data)}
            type="radio"
            value="X"
          />
        </div>
      </FormGroup>
    </>
  );
};

export default PriorityRadio;
