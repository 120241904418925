import React from 'react';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Input from '../helpers/Input';
import Button from '../helpers/Button';

const NewIndustryPopup = props => {
  const { onCreate, onClose, onChange, industryLabel, errors, status } = props;
  const header = 'Add a new industry to the master list';
  const errorSummary = renderErrorSummary(errors);
  const footer = [
    <Button
      key="create"
      className="btn-primary btn-xs"
      data-dismiss="modal"
      disabled={industryLabel.length === 0 || status === 'saving'}
      onClick={onCreate}
    >
      {status === 'saving' ? <i className="fa fa-spinner fa-pulse fa-fw" /> : null} Create
    </Button>,
    <Button key="close" className="btn-default btn-xs" data-dismiss="modal" onClick={onClose}>
      Cancel
    </Button>,
  ];

  return (
    <Popup footer={footer} header={header} id="newIndustryPopup">
      <div className="row">
        <FormGroup className="col-lg-6 col-xs-12">
          <div className="mb10">
            <Input
              className="input-sm"
              id="k-industry-name"
              label="Industry Label"
              name="industryLabel"
              onChange={onChange}
              placeholder="Industry Label"
              value={industryLabel}
              autoFocus
              required
              showLabel
            />
          </div>
        </FormGroup>
        <div>{errorSummary}</div>
      </div>
    </Popup>
  );
};

export default NewIndustryPopup;

function renderErrorSummary(errors) {
  if (errors.size > 0) {
    return (
      <div className="col-lg-6 col-xs-12">
        <ul className="mt10 text-danger">
          {errors.map((e, i) => (
            <li key={i} className="text-danger">
              {e}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
}
