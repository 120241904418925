import company from './company';
import contact from './contact';
import project from './project';
import search from './search';
import shared from './shared';
import approval from './approval';

export default {
  company,
  contact,
  project,
  search,
  shared,
  approval,
};
