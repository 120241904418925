const KEY = '.123gfjlglfgdlhuyryubaweASE@3';

let activationCode = '';

export function setCode(actCode) {
  try {
    localStorage.setItem(KEY, actCode);
  } finally {
    activationCode = actCode;
  }
}

export function clearCode() {
  try {
    localStorage.setItem(KEY, '');
  } finally {
    activationCode = '';
  }
}

export function getCode() {
  try {
    activationCode = localStorage.getItem(KEY) || '';
  } catch (error) {
  }

  return activationCode;
}

export function hasCode() {
  return getCode() !== '';
}

export default function activate(requestObject) {
  const activationCode = getCode();

  if (!activationCode) return requestObject;

  const { headers = {}, ...rest } = requestObject;

  return {
    ...rest,
    headers: {
      ...headers,
      Authorization: `Bearer ${activationCode}`,
    },
  };
}

const IS_2FA = 'isTwoFa';

export function setIsTwoFa(isTwoFa) {
  localStorage.setItem(IS_2FA, isTwoFa);
}

export function clearIsTwoFa() {
  localStorage.removeItem(IS_2FA);
}

export function getIsTwoFa() {
  return localStorage.getItem(IS_2FA) === 'true'
}
