import { fromJS } from 'immutable';
import moment from 'moment';

import * as ActionDetailType from '../../../actions/companyDetail';
import * as ActionType from '../../../actions/company/buyer/fund';
import intNormalize from '../../decorators/intNormalize';
import handleApiError, { withStatePrefix } from '../../decorators/handleApiError';
import getValidator from '../../../utils/getValidator';
import { uniqueNewId } from '../../../utils/uniqueId';
import {
  spy,
  setIn,
  getIn,
  setOriginalIn,
  merge,
  unwrap,
  mergeDeepToOriginal,
  toJS,
  revertToOriginal,
} from '../../../utils/ChangeSpy';

const SCHEMA = {
  type: 'object',
};

const FIELDS_TO_SPY = ['currentFund', 'fundSize', 'fundNotes', 'fundYearRaised'];

const validator = getValidator(SCHEMA);

const defaultState = spy(
  fromJS({
    loaded: false,
    isValid: true,
    inputErrors: {},
    sort: '',
    currentFund: '',
    fundSize: '',
    fundNotes: '',
    fundYearRaised: '',
    years: (function() {
      const res = [{ value: '', name: '' }];
      const target = new Date().getFullYear() + 3;

      for (let i = target; i > 1969; i--) {
        res.push({ value: String(i), name: String(i) });
      }

      return res;
    })(),
    funds: [],
  }),
  FIELDS_TO_SPY,
);

const emptyEntity = {
  buyerIndustries: fromJS({
    id: 0,
    industryLabel: '',
    pivot: { industryId: null },
    contactId: null,
    contactLabel: '',
    notes: '',
  }),
};

const ENTITY_FIELDS_TO_SPY = {
  buyerIndustries: ['industryLabel', 'notes', 'contactLabel', 'contactId', 'pivot.industryId'],
};

const ENTITY_VALIDATORS = {
  buyerIndustries: getValidator({
    type: 'array',
    items: {
      type: 'object',
      properties: {
        industryLabel: {
          title: 'Industry',
          description: 'should not be empty',
          type: 'string',
          minLength: 1,
        },
      },
    },
  }),
};

let wrappedReducer = reducer; // (state, action) => checkValidity(reducer(state, action), action);

wrappedReducer = ['fundSize', 'fundYearRaised'].reduce(
  (reducer, field) => intNormalize(field, null, ActionType.UPDATE_COMPANY, reducer),
  wrappedReducer,
);

wrappedReducer = handleApiError(ActionType.ERRORED_COMPANY_BUYER, wrappedReducer);

wrappedReducer = withStatePrefix(ActionType.ERROR_SAVE_COMPANY_CONTACT, wrappedReducer, (state, action) => {
  if (action.isNew) return ['newContact', 'inputErrors'];

  return ['contacts', state.get('openedContact'), 'inputErrors'];
});

wrappedReducer = withStatePrefix(ActionType.ERROR_SAVE_COMPANY_CONTACT_CHANNEL, wrappedReducer, (state, action) => {
  if (action.isNew) return ['newData', action.channelType, 'inputErrors'];

  return ['contacts', state.get('openedContact'), action.channelType, action.channelIndex, 'inputErrors'];
});

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.UPDATE_COMPANY:
      return checkValidity(changeField(state, action));

    case ActionType.LOADING_FUNDS:
      return state.merge({
        sort: action.sort,
        page: action.page,
        limit: action.limit,
        loading: true,
      });

    case ActionType.LOADED_COMPANY_FUNDS: {
      const startAt = state.get('funds').size;

      return state.merge({
        sort: action.sort,
        page: action.page,
        limit: action.limit,
        totalPages: action.response.meta.pagination.totalPages,
        funds:
          action.page === 1
            ? action.response.data.map((v, i) => ({
                ...v,
                dateAcquired: moment(v.dateAcquired),
                order: startAt + i,
              }))
            : state.get('funds').concat(
                fromJS(
                  action.response.data.map((v, i) => ({
                    ...v,
                    dateAcquired: moment(v.dateAcquired),
                    order: startAt + i,
                  })),
                ),
              ),
        loading: false,
      });
    }

    case ActionType.REVERT_UPDATE:
      return checkValidity(revertToOriginal(state, action.field));

    case ActionDetailType.FETCHING_COMPANY_SUCCESS:
      return spy(state.merge(mapResponse(action)), FIELDS_TO_SPY);

    case ActionType.LOADED_COMPANY_INDUSTRIES:
      return state.setIn(
        ['industriesSuggests'],
        action.page === 1 ? fromJS(action.response.data) : state.get('industriesSuggests').concat(action.response.data),
      );

    case ActionType.LOADED_TITLES: {
      const mask = new RegExp(String(action.filter).replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1'), 'i');

      return state.setIn(
        [...action.prefix.split('.'), 'suggestions'],
        fromJS(action.response.data.filter(title => mask.test(title)).map(title => ({ text: title }))),
      );
    }

    case ActionType.OPEN_COMPANY_CONTACT:
      return state.setIn(['openedContact'], action.index);

    case ActionType.ADD_COMPANY_DETAIL_ENTITY:
      return appendDetailEntity(state, action);

    case ActionType.EDIT_COMPANY_DETAIL_ENTITY:
      return checkValidity(editDetailEntity(state, action), action);

    case ActionType.DEL_COMPANY_DETAIL_ENTITY:
      return deleteDetailEntity(state, action);

    case ActionType.SAVED_COMPANY_BUYER:
      return spy(setValueAsOriginalByMap(state, action.fields), FIELDS_TO_SPY);

    case ActionType.ERRORED_COMPANY_BUYER:
      return state;

    case ActionType.CLOSE_COMPANY_BUYER_VALIDATION_ERROR:
      return state.deleteIn(['inputErrors', action.field]).deleteIn(['newContact', 'inputErrors', action.field]);

    case ActionType.SAVED_COMPANY_CONTACT:
      return saveContact(state, action);

    case ActionType.SAVED_COMPANY_CONTACT_CHANNEL:
      return saveContactChannel(state, action);

    case ActionType.SAVED_COMPANY_ENTITYES:
      return mergeDeepToOriginal(state, { [action.entity]: action.body });

    case ActionType.LOADED_COMPANY_ENTITYES:
      return getContactLabels(state.set(action.entity, fromJS(detailMapper[action.entity](action.response.data))));

    case ActionType.DEL_CONTACT_CHANNEL:
      return delChannelFromContact(state, action);

    case ActionType.UPDATE_FUND:
      return updateFund(state, action);

    case ActionType.UPDATE_SORTING:
      return state.set('sort', action.sort);

    default:
      return state;
  }
}

function updateFund(state, action) {
  const fund = state.getIn(['funds', action.index]);

  if (fund) {
    return state.setIn(['funds', action.index], fund.set(action.field, action.value));
  }

  return state;
}

function getContactLabels(state) {
  return state.update('buyerIndustries', industries =>
    industries.map(industry => {
      const contactId = unwrap(industry.get('contactId'));
      const val = state
        .get('contacts')
        .find(contact => contact.get('id') === contactId, null, fromJS({ fullName: '' }))
        .get('fullName');

      return setOriginalIn(setIn(industry, 'contactLabel', val), 'contactLabel', val);
    }),
  );
}

function setValueAsOriginalByMap(state, map) {
  return spy(
    map.reduce((result, key) => {
      const value = getIn(result, key);

      return result.setIn(key.split('.'), value);
    }, state),
    map,
  );
}

function appendDetailEntity(state, action) {
  const { entity } = action;
  const newEntity = emptyEntity[entity].set('id', uniqueNewId());

  action.index = state.get(entity).size;

  return editDetailEntity(
    state.set(entity, state.get(entity).push(spy(newEntity, ENTITY_FIELDS_TO_SPY[entity]))),
    action,
  );
}

function editDetailEntity(state, action) {
  return state.update(action.entity, table => table.map((row, index) => row.set('isEditing', index === action.index)));
}

function deleteDetailEntity(state, action) {
  return state.update(action.entity, details => details.filter(row => getIn(row, 'id') !== action.id));
}

function mapResponse(action) {
  const { currentFund, fundSize, fundNotes, fundYearRaised } = action.response.data;

  return {
    currentFund,
    fundSize,
    fundNotes,
    fundYearRaised,
    loaded: true,
  };
}

const detailMapper = {
  buyerIndustries: data => data.map(value => spy(value, ENTITY_FIELDS_TO_SPY.buyerIndustries)),
};

function clearChangedFieldError(state, action) {
  const split = action.name.split('.');

  for (let i = 0; i < split.length; i++) {
    const pre = split.slice(0, i);
    const post = split.slice(i);

    state = state.deleteIn([...pre, 'inputErrors', post.join('.')]);
  }

  const split2 = action.name.replace(/\.pivot\./, '.').split('.');

  for (let i = 0; i < split2.length; i++) {
    const pre = split2.slice(0, i);
    const post = split2.slice(i);

    state = state.deleteIn([...pre, 'inputErrors', post.join('.')]);
  }

  return state;
}

function changeField(state, action) {
  if (/Year$/.test(action.name)) {
    state = setIn(state, action.name, action.value || null);
  }

  return clearChangedFieldError(setIn(state, action.name, action.value), action);
}

function checkValidity(state) {
  const plainState = toJS(state);
  const isValid = validator.isValid(plainState);

  const inputErrors = validator.getFirstError(plainState);

  const detailsValidity = Object.keys(ENTITY_VALIDATORS).reduce(
    res =>
      /* res[`${key}IsValid`] = ENTITY_VALIDATORS[key].isValid(plainState[key]);
    if (res[`${key}IsValid`]) return res;
    const error = ENTITY_VALIDATORS[key].getFirstError(plainState[key]);
    const errorField = Object.keys(error)[0];
    inputErrors[`${key}.${errorField}`] = error[errorField]; */
      res,
    {},
  );

  return state.merge({
    ...detailsValidity,
    inputErrors,
    isValid,
  });
}

function delChannelFromContact(state, action) {
  const contactIndex = state.get('contacts').findKey(contact => contact.get('id') === action.contactId);

  if (contactIndex === undefined) return state;

  const channelIndex = state
    .getIn(['contacts', contactIndex, action.channelType])
    .findKey(channel => channel.get('id') === action.id);

  if (channelIndex === undefined) return state;

  return state.deleteIn(['contacts', contactIndex, action.channelType, channelIndex]);
}

function saveContactChannel(state, action) {
  let { channelIndex } = action;

  if (action.isNew) {
    channelIndex = state.getIn(['contacts', state.get('openedContact'), action.channelType]).size;

    const dummy = merge(defaultState.getIn(['newData', action.channelType]).set('id', action.response.id), action.body);

    state = state.setIn(['contacts', state.get('openedContact'), action.channelType, channelIndex], dummy);
  }

  const dataForMerge = {
    contacts: {
      [state.get('openedContact')]: {
        [action.channelType]: {
          [channelIndex]: action.body,
        },
      },
    },
  };

  return mergeDeepToOriginal(state, dataForMerge).set('newData', defaultState.get('newData'));
}

function saveContact(state, action) {
  if (action.isNew) return state.set('newContact', defaultState.get('newContact'));

  const contactIndex = state.get('contacts').findKey(contact => contact.get('id') === action.contactId);

  const dataForMerge = {
    contacts: {
      [contactIndex]: action.body,
    },
  };

  return mergeDeepToOriginal(state, dataForMerge).set('newContact', defaultState.get('newContact'));
}
