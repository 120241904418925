import { CALL_API } from '../../middleware/api';
import config from '../../config';
import uniqueId from '../../utils/uniqueId';
import { getIn } from '../../utils/ChangeSpy';

export const SHOW_INDUSTRIES_LOADING = Symbol('SHOW_INDUSTRIES_LOADING');
export const REQUEST_API_INDUSTRIES_FAILURE = Symbol('REQUEST_API_INDUSTRIES_FAILURE');
export const LOAD_INDUSTRIES_SUCCESS = Symbol('LOAD_INDUSTRIES_SUCCESS');
export const LOADING_INDUSTRIES_SUGGESTION = Symbol('LOADING_INDUSTRIES_SUGGESTION');
export const LOADED_INDUSTRIES_SUGGESTIONS_SUCCESS = Symbol('LOADED_INDUSTRIES_SUGGESTIONS_SUCCESS');
export const INSERT_INDUSTRY_ROW = Symbol('INSERT_INDUSTRY_ROW');
export const CHANGE_INDUSTRY_ROW_MODE = Symbol('CHANGE_INDUSTRY_ROW_MODE');
export const CHANGE_INDUSTRY_ROW_TEXT = Symbol('CHANGE_INDUSTRY_ROW_TEXT');
export const SELECT_INDUSTRY_SUGGESTION = Symbol('SELECT_INDUSTRY_SUGGESTION');
export const DELETING_INDUSTRY_ROW = Symbol('DELETING_INDUSTRY_ROW');
export const DELETED_INDUSTRY_ROW_FAILURE = Symbol('DELETED_INDUSTRY_ROW_FAILURE');
export const DELETED_INDUSTRY_ROW_SUCCESS = Symbol('DELETED_INDUSTRY_ROW_SUCCESS');
export const SAVED_INDUSTRIES_SUCCESS = Symbol('SAVED_INDUSTRIES_SUCCESS');
export const UPDATE_INDUSTRY_TAGS = Symbol('UPDATE_INDUSTRY_TAGS');

export function updateIndustryTags(tags, models) {
  return {
    type: UPDATE_INDUSTRY_TAGS,
    tags,
    models,
  };
}

/**
 * Save industries in industry table.
 *
 * @param projectId {Number|string} Project's id.
 * @returns Action.
 */
export function saveIndustries(projectId) {
  return (dispatch, getState) => {
    const state = getState().project;
    const project = projectId || state.projectData.get('id');
    const data = getIn(state.common, 'selectedBMs')
      .concat(getIn(state.common, 'selectedBICs'))
      .reduce((acc, b) => {
        acc.push({
          industryCategoryId: b,
          priority: 0,
        });

        return acc;
      }, []);

    dispatch({
      [CALL_API]: {
        method: 'post',
        path: `/api/v1/projects/${project}/bulk/industries`,
        body: { data },
        startType: SHOW_INDUSTRIES_LOADING,
        errorType: REQUEST_API_INDUSTRIES_FAILURE,
        successType: SAVED_INDUSTRIES_SUCCESS,
      },
    }).then(() => dispatch(loadIndustries(project)));
  };
}

/**
 * Load industries list.
 *
 * @param id {Number} Project's id.
 * @returns Action.
 */
export function loadIndustries(id) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/${id}`,
      query: {
        include: 'industries,project_industry_categories,project_business_models',
      },
      startType: SHOW_INDUSTRIES_LOADING,
      errorType: REQUEST_API_INDUSTRIES_FAILURE,
      successType: LOAD_INDUSTRIES_SUCCESS,
    },
  };
}

/**
 * Update industry's text input.
 *
 * @param id {Number} Row id.
 * @param text {String} Inputted text.
 * @returns Action.
 */
export function updateIndustryText(id, text) {
  return dispatch => {
    dispatch({
      type: CHANGE_INDUSTRY_ROW_TEXT,
      id,
      text,
    });
    if (text && text.length >= config.NUMBER_OF_MIN_CHARACTER) {
      dispatch(loadIndustriesSuggestion(id, text));
    } else {
      dispatch(switchIndustryRowToEdit(id));
    }
  };
}

/**
 * Switch an industry row to view mode.
 *
 * @param id {Number} Row id.
 * @returns Action.
 */
export function switchIndustryRowToView(id) {
  return {
    type: CHANGE_INDUSTRY_ROW_MODE,
    id,
    mode: config.VIEW_MODE,
  };
}

/**
 * Switch an industry row to edit mode.
 *
 * @param id {Number} Row id.
 * @returns Action.
 */
export function switchIndustryRowToEdit(id) {
  return {
    type: CHANGE_INDUSTRY_ROW_MODE,
    id,
    mode: config.EDIT_MODE,
  };
}

/**
 * Insert a new industry row to grid.
 *
 * @returns Action.
 */
export function insertIndustryRow() {
  return {
    type: INSERT_INDUSTRY_ROW,
  };
}

/**
 * Load industry's suggestion list.
 *
 * @param id {Number} Row id.
 * @param text {String} Inptted text.
 * @returns Action.
 */
function loadIndustriesSuggestion(id, text) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/industries',
      query: { like: text },
      startType: LOADING_INDUSTRIES_SUGGESTION,
      successType: LOADED_INDUSTRIES_SUGGESTIONS_SUCCESS,
    },
    requestId: uniqueId(),
    id,
  };
}

/**
 * Select an industry suggestion.
 *
 * @param id {Number} Row id.
 * @param suggestionId {Number} Suggestion id.
 * @param suggestionName {String} Suggestion name.
 * @returns Action.
 */
export function selectIndustrySuggestion(id, suggestionId, suggestionName) {
  return {
    type: SELECT_INDUSTRY_SUGGESTION,
    id,
    suggestionId,
    suggestionName,
  };
}

/**
 * Delete an industry row.
 *
 * @param id {Number} Row id.
 * @returns Action.
 */
export function deleteIndustryRow(id) {
  return (dispatch, getState) => {
    const projectId = getState().project.projectData.get('id');

    dispatch({
      [CALL_API]: {
        method: 'delete',
        path: `/api/v1/projects/${projectId}/industries/${id}`,
        startType: DELETING_INDUSTRY_ROW,
        errorType: DELETED_INDUSTRY_ROW_FAILURE,
        successType: DELETED_INDUSTRY_ROW_SUCCESS,
      },
      id,
    });
  };
}
