import action from './action.json';

const blActivities = [
  'M1',
  'M2',
  'CM2',
  'eM1',
  'eM2',
  'eCM2',
  'M1b',
  'M2b',
  'CM2b',
  'E1',
  'E2',
  'E3',
  'E4',
  'E5',
  'E6',
  'E7',
  'E8',
  'E9',
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'F6',
  'F7',
  'F8',
  'F9',
];

export default {
  action,
  blActivities,
};
