import { CALL_API } from '../../middleware/api';

// loading
export const LOAD_CONTACT_BUYER = Symbol('LOAD_CONTACT_BUYER');
export const ERROR_CONTACT_BUYER = Symbol('ERROR_CONTACT_BUYER');
export const LOADED_CONTACT_BUYER = Symbol('LOADED_CONTACT_BUYER');

/**
 * Load contact byers.
 *
 * @param id {Number} Contact id.
 * @returns {MiddlewareApi.CallApi} Action to middleware/api.
 */
export function loadContactBuyer({ id }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/people/${id}/buyers`,
      startType: LOAD_CONTACT_BUYER,
      successType: LOADED_CONTACT_BUYER,
      errorType: ERROR_CONTACT_BUYER,
    },
  };
}
