import { CALL_API } from '../middleware/api';
import config from '../config';

export const UPDATE_FIELD = Symbol('UPDATE_FIELD');
export const CREATE_ONE_OFF_APPROACH = Symbol('CREATE_ONE_OFF_APPROACH');
export const CREATE_ONE_OFF_APPROACH_SUCCESS = Symbol('CREATE_ONE_OFF_APPROACH_SUCCESS');
export const CREATE_ONE_OFF_APPROACH_ERROR = Symbol('CREATE_ONE_OFF_APPROACH_ERROR');
export const REVERT_UPDATE = Symbol('REVERT_UPDATE');
export const LOADED_USERS = Symbol('LOADED_USERS');
export const LOADED_BUYERS_LIST = Symbol('LOADED_BUYERS_LIST');
export const LOADED_BUYERS_PROJECTS_LIST = Symbol('LOADED_BUYERS_PROJECTS_LIST');
export const CLOSE_VALIDATION_ERROR = Symbol('CLOSE_VALIDATION_ERROR');
export const LOAD_COUNT_TARGETS = Symbol('LOAD_COUNT_TARGETS');
export const LOADED_COUNT_TARGETS_FAILURE = Symbol('LOADED_COUNT_TARGETS_FAILURE');
export const LOADED_COUNT_TARGETS_SUCCESS = Symbol('LOADED_COUNT_TARGETS_SUCCESS');

/**
 * Change field of one-off.
 *
 * @param name {String} Field name.
 * @param value {String|Object} New value.
 * @returns {{type: symbol, name: string, value: string|object}}
 */
export function changeField({ name, value }) {
  return {
    type: UPDATE_FIELD,
    name,
    value,
  };
}

/**
 * Get count of targets by tag.
 *
 * @param tag {String} Field name.
 * @returns {MiddlewareApi.CallApi}
 */
export function getCountTargets(tag) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/harvco_tag/count_targets?tag=${encodeURIComponent(tag)}`,
      startType: LOAD_COUNT_TARGETS,
      errorType: LOADED_COUNT_TARGETS_FAILURE,
      successType: LOADED_COUNT_TARGETS_SUCCESS,
    },
  };
}

/**
 * Load directors by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findDirectors({ filter, page, afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users_by_role',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        role: config.DIRECTOR,
        like: filter,
        limit: 100,
        page,
      },
      successType: LOADED_USERS,
      afterSuccess,
    },
  };
}

/**
 * Load analysts by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findAnalysts({ filter, page, afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users_by_role',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        role: config.ANALYST_ASSOCIATE,
        like: filter,
        limit: 100,
        page,
      },
      successType: LOADED_USERS,
      afterSuccess,
    },
  };
}

/**
 * Load buyers by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findBuyers({ filter, page, afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/buyer_names',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
      },
      successType: LOADED_BUYERS_LIST,
      afterSuccess,
    },
  };
}

/**
 * Load projects by filter.
 *
 * @param buyerId {Number}.
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findProjects({ buyerId, filter, page, afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/buyers/${buyerId}/projects`,
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        page,
        like: filter,
      },
      successType: LOADED_BUYERS_PROJECTS_LIST,
      afterSuccess,
    },
  };
}

/**
 * Create One-Off.
 *
 * @param body {Object} One-off data.
 * @param afterSuccess {Function}.
 * @param afterError {Function}.
 * @returns Action.
 */
export function createOneOff({ body, afterSuccess, afterError }) {
  return {
    body,
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/events/one_off',
      body,
      successType: CREATE_ONE_OFF_APPROACH_SUCCESS,
      errorType: CREATE_ONE_OFF_APPROACH_ERROR,
      afterSuccess,
      afterError,
    },
  };
}

/**
 * Remove validation errors.
 *
 * @returns {{type: symbol, field: string}}
 */
export function closeValidationError({ field }) {
  return {
    type: CLOSE_VALIDATION_ERROR,
    field,
  };
}

/**
 * Revert changes.
 *
 * @returns {{type: symbol}}
 */
export function revertUpdate() {
  return {
    type: REVERT_UPDATE,
  };
}
