import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { bindActionCreators } from 'redux';
import Immutable, { Map } from 'immutable';
import connectOptions, { mergeProps } from '../utils/connectOptions';
import MailingHistory from '../components/MailingHistory';
import { buildSortByParam } from '../helpers/paramBuilder';

import { getMailingHistory, updateMailingHistory } from '../actions/mailingHistory';
import { showError } from '../utils/MessagePopup';
import config from '../config';
import { isResearcher } from '../utils/checkPermissions';

const { func, object } = PropTypes;

/** @class
 *  Mailing history page container.
 *  Change title to `"Mailing History Page"`.
 */
class MailingHistoryContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleGetNextPageData = this.handleGetNextPageData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDoubleClickRow = this.handleDoubleClickRow.bind(this);
    this.handleReload = this.handleReload.bind(this);
  }

  componentDidMount() {
    if (!this.hasAccess()) {
      showError(this.context.openPopup, config.permisionError, () => {
        this.props.history.push('/');
        this.context.closePopup();
      });

      return;
    }

    const { loggedUser } = this.props;

    this.props.getMailingHistory({
      userId: loggedUser.get('id'),
    });
  }

  hasAccess() {
    if (!this.context.currentUser) return false;

    return !isResearcher(this.context.currentUser.getIn(['roles', 0, 'slug'], null));
  }

  componentDidUpdate(oldProps) {
    const { mailingHistory, loggedUser, getMailingHistory } = this.props;

    if (oldProps.mailingHistory !== mailingHistory) {
      const oldSortBy = buildSortByParam(oldProps.mailingHistory);
      const sortBy = buildSortByParam(mailingHistory);

      if (sortBy !== oldSortBy) {
        getMailingHistory({
          userId: loggedUser.get('id'),
          page: 1,
          sortBy,
        });
      }
    }
  }

  handleGetNextPageData(page) {
    const { mailingHistory, loggedUser, getMailingHistory } = this.props;
    const sortBy = buildSortByParam(mailingHistory);

    getMailingHistory({
      userId: loggedUser.get('id'),
      page,
      sortBy,
    });
  }

  handleChange(data) {
    this.props.updateMailingHistory({
      filterName: data.filterName,
      filterData: data.filterData,
    });
  }

  handleDoubleClickRow(row) {
    window.open(`/mailing-info?id=${row.data.recordId}`, '_blank');
  }

  handleReload() {
    const { loggedUser, getMailingHistory } = this.props;

    getMailingHistory({
      userId: loggedUser.get('id'),
      page: 1,
    });
  }

  render() {
    if (!this.hasAccess()) return null;

    const { loggedUser, mailingHistory } = this.props;

    return (
      <div className="full-height">
        <Helmet title="Mailing History Page" />
        <MailingHistory
          loggedUser={loggedUser}
          mailingHistory={mailingHistory}
          onChange={this.handleChange}
          onDoubleClickRow={this.handleDoubleClickRow}
          onGetNextPageData={this.handleGetNextPageData}
          onReload={this.handleReload}
        />
      </div>
    );
  }
}

MailingHistoryContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Immutable.Map).isRequired,
  closePopup: PropTypes.func.isRequired,
};

MailingHistoryContainer.propTypes = {
  getMailingHistory: func.isRequired,
  loggedUser: PropTypes.instanceOf(Map).isRequired,
  mailingHistory: object.isRequired,
};

export { MailingHistoryContainer };
export default connect(
  state => ({
    mailingHistory: state.mailingHistory,
    loggedUser: state.auth.get('user'),
  }),
  dispatch =>
    bindActionCreators(
      {
        getMailingHistory,
        updateMailingHistory,
      },
      dispatch,
    ),
  mergeProps,
  connectOptions,
)(MailingHistoryContainer);
