import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import Immutable from 'immutable';
import classNames from 'classnames';
import LoadAnimation from '../decorators/LoadAnimation';
import Button from '../helpers/Button';
/**
 * User table component.
 *
 * @param props {Object}.
 * @param props.tableCells {Immutable.List} Array of cells in table.
 * @param props.users {Immutable.List} List of user needed to display in table.
 * @param props.onRowClick {function} Handle row click.
 * @param props.selectedUserId {number} Selected user id.
 * @returns {React.Component}
 * @class
 */
class UserTable extends PureComponent {
  render() {
    const { tableCells, users, selectedUserId, userLoading, onRowClick, onRemove2FaClick } = this.props;

    return (
      <div className="table-responsive mb20 user-table">
        <TableBody
          columns={tableCells}
          loading={userLoading}
          onRemove2FaClick={onRemove2FaClick}
          onRowClick={onRowClick}
          selectedId={selectedUserId}
          users={users}
        />
      </div>
    );
  }
}

UserTable.propTypes = {
  onRowClick: PropTypes.func.isRequired,
  selectedUserId: PropTypes.number,
  tableCells: PropTypes.instanceOf(Immutable.List).isRequired,
  userLoading: PropTypes.bool,
  users: PropTypes.instanceOf(Immutable.List).isRequired,
};

export default UserTable;

/**
 * Table body component.
 *
 * @param props {Object}.
 * @param props.columns {Immutable.List} Column list.
 * @param props.users {Immutable.List} User list.
 * @param props.selectedId {Number} Selected user's id.
 * @returns {React.Component}
 */
const TableBody = LoadAnimation(props => {
  const { columns, users, selectedId, onRowClick, onRemove2FaClick } = props;

  const getRowContent = (row, field) => {
    if (field === 'inactive') {
      return <input checked={row.get('inactive')} disabled="disabled" type="checkbox" />;
    }

    if (field === 'remove2Fa') {
      return (
        <Button key={row.get('id')} className="btn-danger btn-xs" onClick={() => onRemove2FaClick(row.get('id'))}>
          Remove 2FA
        </Button>
      );
    }

    return row.get(field);
  };
  const mapCol = (col, row, index) => (
    <td
      key={index}
      className={classNames({ 'text-center': col.get('field') === 'inactive' })}
      style={{ width: `${col.get('width')}%` }}
    >
      {getRowContent(row, col.get('field'))}
    </td>
  );
  const mapRow = (row, index) => (
    <tr
      key={index}
      className={classNames({ active: row.get('id') === selectedId })}
      onClick={() => onRowClick(row.get('id'))}
    >
      {columns.map((col, colIndex) => mapCol(col, row, colIndex))}
    </tr>
  );

  return (
    <table className="table table table-bordered table-hover table-fixed" id="userTableBody">
      <tbody>{users.map(mapRow)}</tbody>
    </table>
  );
});

TableBody.propTypes = {
  columns: PropTypes.instanceOf(Immutable.List).isRequired,
  loading: PropTypes.bool,
  onRowClick: PropTypes.func.isRequired,
  selectedId: PropTypes.number,
  users: PropTypes.instanceOf(Immutable.List).isRequired,
};
