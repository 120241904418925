import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import $ from 'jquery';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import Immutable from 'immutable';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import moment from 'moment';

import User from '../components/User';
import connectOptions, { mergeProps } from '../utils/connectOptions';
import {
  loadUsers,
  toggleShowingInactiveUser,
  updateSelectedUser,
  changeMode,
  updateOrInsertUser,
  loadRoles,
  updateSupervisor,
  updateModule,
  clearSelectedUser,
  selectUser,
  updateAttempts,
  closeSuggestion,
  loadReports,
  deleteGoogle2FAAuth,
} from '../actions/userManage';
import config from '../config';
import { showError } from '../utils/MessagePopup';
import downloadFile from '../utils/downloadFile';

/**
 * User container component.
 *
 * @param props {Object}.
 * @param context {Object}.
 * @param context.currentUser {Immutable.Map} Logged user.
 * @param props.users {Immutable.List} User list.
 * @param props.roles {Immutable.List} Role list.
 * @param props.formMode {String} Form mode.
 * @param props.formError {Immutable.Map} Form error.
 * @param props.selectedUser {Immutable.Map} Selected user.
 * @param props.hideInactive {Boolean} If set to true will hide inaction users.
 * @param props.saveStatus {String} Saving status.
 * @param props.userLoading {Boolean} If set to true will show user loading spinner icon.
 * @param props.moduleSuggestionMode {String} Mode of module suggestion.
 * @param props.supervisorSuggestionMode {String} Mode of supervisor suggestion.
 * @param props.suggestions {Immutable.List} Suggestion list.
 * @param props.tableCells {Immutable.List} Table cell configuration.
 * @param props.errors {Immutable.List} Error list.
 * @returns {React.Component}
 * @class
 */
class UserContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.handleHideInactive = this.handleHideInactive.bind(this);
    this.handleRowSelect = this.handleRowSelect.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleSaveForm = this.handleSaveForm.bind(this);
    this.handleUserSuggestion = this.handleUserSuggestion.bind(this);
    this.handleChangeMode = this.handleChangeMode.bind(this);
    this.handleAddNew = this.handleAddNew.bind(this);
    this.showWarningPopup = this.showWarningPopup.bind(this);
    this.handleUserSuggestionClose = this.handleUserSuggestionClose.bind(this);
    this.handleHireEndDateChange = this.handleHireEndDateChange.bind(this);
    this.handleRemove2FaClick = this.handleRemove2FaClick.bind(this);
    this.handleExportUserList = this.handleExportUserList.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.loadReports();
  }

  componentWillUnmount() {
    $(window).off('resize');
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    if (this.hasAccess()) {
      this.props.loadUsers(parseInt(id, 10));
      this.props.loadRoles();
    } else {
      this.showWarningPopup();
    }

    $(window).on('resize', this.setHeaderWidth);
  }

  componentDidUpdate() {
    this.setHeaderWidth();
  }

  hasAccess() {
    if (!this.context.currentUser) return false;

    const slug = this.context.currentUser.getIn(['roles', 0, 'slug'], null);

    return slug === config.ADMINISTRATOR;
  }

  setHeaderWidth() {
    const $headers = $('#userTableHeader>tbody>tr:eq(0) th');
    const $tds = $('#userTableBody>tbody>tr:eq(0) td');

    $('#userTableHeader').width($('#userTableBody').width());
    $tds.each(function(index) {
      $headers.eq(index).width($(this).width());
    });
  }

  showWarningPopup() {
    showError(this.context.openPopup, config.permisionError, () => {
      this.props.push('/');
      this.context.closePopup();
    });
  }

  handleHideInactive(event) {
    this.props.toggleShowingInactiveUser(event.target.checked);
  }

  handleFormChange(event) {
    const { name, checked, value } = event.target;

    if (name === 'reset') {
      this.props.updateAttempts(checked);
    } else if (name === 'inactive') {
      const { selectedUser } = this.props;
      const endDate = selectedUser.get('endDate', null);

      this.props.updateSelectedUser(name, checked);
      if (checked && !endDate) {
        // Set current date by default.
        this.props.updateSelectedUser('endDate', moment(new Date()));
      }

      if (!checked && endDate) {
        this.props.updateSelectedUser('endDate', '');
      }
    } else if (name === 'isMailingBacklog') {
      this.props.updateSelectedUser(name, checked);
    } else {
      this.props.updateSelectedUser(name, value, checked);
    }
  }

  handleExportUserList() {
    const url = `${config.API_BASE_URL}/api/v1/users/export`;

    downloadFile({ url, params: { is_hide_inactive: this.props.hideInactive ? '1' : '0' } });
  }

  /**
   * Update selected user hire or end date.
   *
   * @param {Moment} date Hire / End date of selected user.
   * @param {string} fieldName Type of date ('hireDate' / 'endDate').
   */
  handleHireEndDateChange(date, fieldName) {
    if (!fieldName) return;

    this.props.updateSelectedUser(fieldName, date);
  }

  handleChangeMode(mode) {
    this.props.changeMode(mode);
    this.props.updateAttempts(false);
  }

  handleRowSelect(userId) {
    // If row is currently selected, deselect it
    if (userId === this.props.selectedUser.get('id')) {
      this.props.clearSelectedUser();
    } else {
      this.props.selectUser(userId);
    }
  }

  handleUserSuggestionClose() {
    this.props.closeSuggestion();
  }

  handleSaveForm() {
    const { selectedUser, saveStatus } = this.props;
    const postingUser = selectedUser.toJS();

    if (saveStatus !== 'error' || saveStatus !== 'saving') {
      if (!postingUser.supervisorId || postingUser.supervisorId < 0) {
        postingUser.supervisorId = null;
      }

      if (!postingUser.moduleId || postingUser.moduleId < 0) {
        delete postingUser.moduleId;
      }

      const isUpdateRequest = postingUser.id > 0;
      const endDate = postingUser.endDate ? moment(postingUser.endDate).format('YYYY-MM-DD') : null;
      let hireDate = null;

      if (postingUser.hireDate) {
        hireDate = moment(postingUser.hireDate).format('YYYY-MM-DD');
      } else if (!isUpdateRequest) {
        hireDate = moment(new Date()).format('YYYY-MM-DD');
      }

      const formattedData = {
        // Transform moment() date to valid for backend string.
        ...postingUser,
        hireDate,
        endDate,
      };

      this.props.updateOrInsertUser(formattedData);
    }
  }

  handleUserSuggestion({ data: { name }, suggestion }) {
    if (name === 'module') {
      this.props.updateModule(suggestion.get('id'), suggestion.get('name'));
    } else {
      this.props.updateSupervisor(suggestion.get('id'), suggestion.get('name'));
    }
  }

  handleAddNew() {
    this.props.changeMode('edit');
    this.props.clearSelectedUser();
  }

  handleRemove2FaClick(userId) {
    this.context.openPopup('ConfirmPopup', {
      message: 'Are you sure to confirm remove 2FA?',
      onOk: () =>
        this.props.deleteGoogle2FAAuth({
          userId,
          afterSuccess: () => {
            this.context.closePopup();
          },
        }),
      onCancel: () => {
        this.context.closePopup();
      },
      yes: 'Yes',
      no: 'No',
    });
  }

  render() {
    const { users, hideInactive, ...rest } = this.props;
    const content = this.hasAccess() ? (
      <User
        {...rest}
        hideInactive={hideInactive}
        onAddClick={this.handleAddNew}
        onEditClick={this.handleChangeMode}
        onExportUserList={this.handleExportUserList}
        onFormChange={this.handleFormChange}
        onHideInactiveChange={this.handleHideInactive}
        onHireEndDateChange={this.handleHireEndDateChange}
        onRemove2FaClick={this.handleRemove2FaClick}
        onRowClick={this.handleRowSelect}
        onSaveClick={this.handleSaveForm}
        onUserSuggestionClose={this.handleUserSuggestionClose}
        onUserSuggestionSelected={this.handleUserSuggestion}
        users={hideInactive ? users.filter(u => !u.get('inactive')) : users}
      />
    ) : null;

    return (
      <div className="full-height">
        <Helmet title="User Page" />
        {content}
      </div>
    );
  }
}

UserContainer.propTypes = {
  changeMode: PropTypes.func.isRequired,
  clearSelectedUser: PropTypes.func.isRequired,
  closeSuggestion: PropTypes.func.isRequired,
  deleteGoogle2FAAuth: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Immutable.List).isRequired,
  formError: PropTypes.instanceOf(Immutable.Map).isRequired,
  formMode: PropTypes.string.isRequired,
  hideInactive: PropTypes.bool.isRequired,
  loadReports: PropTypes.func.isRequired,
  loadRoles: PropTypes.func.isRequired,
  loadUsers: PropTypes.func.isRequired,
  moduleSuggestionMode: PropTypes.string.isRequired,
  roles: PropTypes.instanceOf(Immutable.List).isRequired,
  saveStatus: PropTypes.string.isRequired,
  selectedUser: PropTypes.instanceOf(Immutable.Map).isRequired,
  selectUser: PropTypes.func.isRequired,
  suggestions: PropTypes.instanceOf(Immutable.List).isRequired,
  supervisorSuggestionMode: PropTypes.string.isRequired,
  tableCells: PropTypes.instanceOf(Immutable.List).isRequired,
  toggleShowingInactiveUser: PropTypes.func.isRequired,
  updateAttempts: PropTypes.func.isRequired,
  updateModule: PropTypes.func.isRequired,
  updateOrInsertUser: PropTypes.func.isRequired,
  updateSelectedUser: PropTypes.func.isRequired,
  updateSupervisor: PropTypes.func.isRequired,
  userLoading: PropTypes.bool.isRequired,
  users: PropTypes.instanceOf(Immutable.List).isRequired,
};

UserContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Immutable.Map).isRequired,
  closePopup: PropTypes.func.isRequired,
};

const selectReport = createSelector(
  createSelector(
    state => state.user,
    state => state.get('reports'),
  ),
  base => {
    if (base.toJS) return base.toJS();

    return base;
  },
);

const selectUsers = createSelector(
  state => state.user,
  state => state.get('users'),
);

function mapStateToProps(state) {
  return {
    users: selectUsers(state),
    suggestions: state.user.get('suggestions'),
    roles: state.user.get('roles'),
    formMode: state.user.get('formMode'),
    formError: state.user.get('formError'),
    selectedUser: state.user.get('selectedUser'),
    hideInactive: state.user.get('hideInactive'),
    saveStatus: state.user.get('saveStatus'),
    userLoading: state.user.get('userLoading'),
    moduleSuggestionMode: state.user.get('moduleSuggestionMode'),
    supervisorSuggestionMode: state.user.get('supervisorSuggestionMode'),
    tableCells: config.tables.getIn(['userList', 'columns']),
    errors: state.user.get('errors'),
    reports: selectReport(state),
  };
}

const mapDispatchToProps = {
  loadUsers,
  toggleShowingInactiveUser,
  updateSelectedUser,
  changeMode,
  updateOrInsertUser,
  loadRoles,
  updateSupervisor,
  updateModule,
  clearSelectedUser,
  selectUser,
  updateAttempts,
  closeSuggestion,
  loadReports,
  push,
  deleteGoogle2FAAuth,
};

export { UserContainer };
export default connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions)(withRouter(UserContainer));
