import React, { PureComponent } from 'react';
import { Map } from 'immutable';

import config from '../../../../config';
import FormGroup from '../../../helpers/FormGroup';
import Textarea from '../../../helpers/Textarea';
import Checkbox from '../../../helpers/Checkbox';
import Ceo123 from './Ceo123';
import CeoAbc from './CeoAbc';
import CompanyBuyerCEOTable from './CompanyBuyerCEOTable';
import { noop } from '../../../../utils/noop';

const currentExecutiveLeadsColumnDefs = config.tables.getIn(['companyBuyerCEO', 'currentExecutiveLeads', 'columnDefs']);
const activeProjectsColumnDefs = config.tables.getIn(['companyBuyerCEO', 'activeProjects', 'columnDefs']);

export default class CompanyBuyerCEO extends PureComponent {
  render() {
    const { props } = this;
    const {
      canEditData,
      ceo: root = Map(),
      isResearcherUser,
      onDoubleClickRow,
      onGetNextPageDataForCurrentExecutiveLeads,
      onGetNextPageDataForActiveProjects,
      onAddCEOProject,
      onChange,
      onUpdateCell,
      onCurrentExecutiveLeadsChange,
      onActiveProjectsChange,
      onGetSuggestion,
      harvcoLeadEditor,
      clientLeadEditor,
    } = props;

    const currentExecutiveLeads = root.get('currentExecutiveLeads');
    const activeProjects = root.get('activeProjects');

    return (
      <div className="full-height flexWrapper">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="row">
            <form className="form-inline" />
            <div className="col-xs-12 col-sm-3 col-md-2">
              <CeoAbc disabled={!canEditData || isResearcherUser} name="ceoAbc" onChange={onChange} root={root} deep />
            </div>
            <div className="col-xs-12 col-sm-3 col-md-2">
              <Ceo123 disabled={!canEditData || isResearcherUser} name="ceo123" onChange={onChange} root={root} deep />
            </div>
            <div className="col-xs-12 col-sm-3 col-md-2">
              <div className="form-group group-inline b-checkbox ib group-checkbox">
                <Checkbox
                  deep="checked"
                  disabled={!canEditData || isResearcherUser}
                  label="Show Execs"
                  name="showExecs"
                  onChange={onChange}
                  root={root}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flexItem">
          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 full-height">
            <div className="flexWrapper full-height">
              <CompanyBuyerCEOTable
                canEditData={canEditData}
                columnDefs={currentExecutiveLeadsColumnDefs}
                data={currentExecutiveLeads}
                onChange={onCurrentExecutiveLeadsChange}
                onDoubleClickRow={onDoubleClickRow}
                onGetNextPageData={onGetNextPageDataForCurrentExecutiveLeads}
                onUpdateCell={() => {}}
                title="Current Executive Leads"
              />
              <CompanyBuyerCEOTable
                canEditData={canEditData}
                clientLeadEditor={clientLeadEditor}
                columnDefs={activeProjectsColumnDefs}
                data={activeProjects}
                harvcoLeadEditor={harvcoLeadEditor}
                onAddCEOProject={onAddCEOProject}
                onChange={onActiveProjectsChange}
                onDoubleClickRow={noop}
                onGetNextPageData={onGetNextPageDataForActiveProjects}
                onGetSuggestion={onGetSuggestion}
                onUpdateCell={onUpdateCell}
                title="Active Projects"
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <FormGroup>
              <Textarea
                disabled={!canEditData || isResearcherUser}
                label="Notes"
                name="execLeadNotes"
                onChange={onChange}
                placeholder="Notes"
                root={root}
                rows="19"
                deep
                showLabel
              />
            </FormGroup>
          </div>
        </div>
      </div>
    );
  }
}
