import { CALL_API, CHAIN_API } from '../../middleware/api';
import config from '../../config';

export const UPDATE_FIELD = Symbol('UPDATE_FIELD');
export const SAVE_COMPANY = Symbol('SAVE_COMPANY');
export const SAVE_COMPANY_SUCCESS = Symbol('SAVE_COMPANY_SUCCESS');
export const SAVE_COMPANY_ERROR = Symbol('SAVE_COMPANY_ERROR');
export const FETCHING_LIST_COMPANY_SUCCESS = Symbol('FETCHING_LIST_COMPANY_SUCCESS');
export const UPDATE_PARENT_COMPANY = Symbol('UPDATE_PARENT_COMPANY');
export const LOADED_DIRECTORS = Symbol('LOADED_DIRECTORS');
export const LOADED_ANALYSTS = Symbol('LOADED_ANALYSTS');
export const REVERT_FIELDS = Symbol('REVERT_FIELDS');
export const CHECK_COMPANY_SUCCESS = Symbol('CHECK_COMPANY_SUCCESS');

export const CLOSE_VALIDATION_ERROR = Symbol('CLOSE_VALIDATION_ERROR');

export function activateTargetRole({ body, companyId, afterSuccess, afterError }) {
  return {
    [CHAIN_API]: [
      () => ({
        body,
        [CALL_API]: {
          method: 'put',
          path: `/api/v1/companies/${companyId}`,
          body,
          afterError,
        },
      }),

      () => ({
        [CALL_API]: {
          method: 'post',
          path: `/api/v1/companies/${companyId}/buyers`,
          body: {
            buyerId: 1,
            buyerName: 'Harvey',
            dsplProjectCategory: 'Random',
            projectId: 1,
            currentStatus: '1.20',
            highStatus: '1.20',
          },
          afterSuccess,
          afterError,
        },
      }),
    ],
  };
}

export function changeField({ name, value }) {
  return {
    type: UPDATE_FIELD,
    name,
    value,
  };
}

export function checkCompanyByWebsite(site, afterSuccess) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/exist_company_with_website?site=${site}`,
      successType: CHECK_COMPANY_SUCCESS,
      afterSuccess,
    },
  };
}

export function saveCompany({ body, afterSuccess, afterError }) {
  return {
    body,
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/companies',
      body: { ...body, role: 'target' },
      successType: SAVE_COMPANY_SUCCESS,
      afterSuccess,
      afterError,
      errorType: SAVE_COMPANY_ERROR,
    },
  };
}

export function closeValidationError({ field }) {
  return {
    type: CLOSE_VALIDATION_ERROR,
    field,
  };
}

/**
 * Update parent company info.
 *
 * @param info
 * @returns {{type: symbol, info: *}}
 */
export function handleUpdateParentCompany(info) {
  return {
    type: UPDATE_PARENT_COMPANY,
    info,
  };
}

export function handleGetSuggestion(value) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies?like=${value}`,
      successType: FETCHING_LIST_COMPANY_SUCCESS,
    },
  };
}

/**
 * Load directors by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findDirectors({ filter, page, afterSuccess }) {
  return {
    filter,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users_by_role',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        role: config.DIRECTOR,
        like: filter,
        page,
      },
      successType: LOADED_DIRECTORS,
      afterSuccess,
    },
  };
}

/**
 * Load analysts by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findAnalyst({ filter, page, afterSuccess }) {
  return {
    filter,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users_by_role',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        role: config.ANALYST_ASSOCIATE,
        like: filter,
        page,
      },
      successType: LOADED_ANALYSTS,
      afterSuccess,
    },
  };
}

export function revertFields() {
  return {
    type: REVERT_FIELDS,
  };
}
