import { fromJS, Map } from 'immutable';
import moment from 'moment';

import * as ActionTargetType from '../../../actions/company/companyTarget';
import * as ActionMarketingType from '../../../actions/company/buyer/marketing';
import * as ActionCriteriaType from '../../../actions/company/buyer/criteria';
import * as ActionDetailType from '../../../actions/companyDetail';
import * as ActionType from '../../../actions/company/buyer/marketing';
import config from '../../../config';
import intNormalize from '../../decorators/intNormalize';
import handleApiError, { withStatePrefix } from '../../decorators/handleApiError';
import getValidator from '../../../utils/getValidator';
import { uniqueNewId } from '../../../utils/uniqueId';
import { trimWhiteSpaceIf } from '../../../utils/string';
import {
  spy,
  setIn,
  getIn,
  merge,
  mergeDeepToOriginal,
  toJS,
  revertToOriginal,
  revert,
} from '../../../utils/ChangeSpy';
import { getAge } from '../../../utils/yearAgeConverter';
import { check, autoNullId } from '../../decorators/suggest';

const prefixes = config.values.getIn(['contact', 'prefixes']);
const suffixes = config.values.getIn(['contact', 'suffixes']);

const statuses = config.values.getIn(['company', 'statuses']);

const SCHEMA = {
  type: 'object',
  properties: {
    revenue: {
      title: 'Revenue',
      description: 'should be a number or empty',
      oneOf: [{ type: 'string', pattern: /^\d*\.?\d*$/ }, { type: 'number', minimum: 0 }, { type: 'null' }],
    },
    employees: {
      title: 'Employees',
      description: 'should be a number or empty',
      oneOf: [{ type: 'string', pattern: /^\d*$/ }, { type: 'number', minimum: 0 }, { type: 'null' }],
    },
  },
};

const FIELDS_TO_SPY = [
  'revenue',
  'employees',
  'buyerDescription',
  'buyerResearchNotes',
  'buyerLastResearchedDate',
  'buyerLastResearcherId',
  'suggestResearcher',
];

const EMAIL_FIELDS_TO_SPY = ['email', 'type'];
const PHONE_FIELDS_TO_SPY = ['phone', 'type', 'code', 'extension', 'country', 'unformattedPhone'];
const CONTACT_FIELDS_TO_SPY = [
  'prefix',
  'suffix',
  'birthYear',
  'pivot.title',
  'pivot.ownershipPercent',
  'pivot.startYear',
  'pivot.endYear',
  'pivot.notes',
  'age',
  'fullName',
  'firstName',
  'lastName',
  'nick',
  'pronounce',
];

const validator = getValidator(SCHEMA);
const defaultState = spy(
  fromJS({
    loaded: false,
    isValid: true,
    inputErrors: {},
    revenue: null,
    employees: null,
    buyerDescription: '',
    buyerResearchNotes: '',
    buyerLastResearchedDate: null,
    buyerLastResearcherId: null,
    suggests: [],
    suggestResearcher: '',
    statuses,
    harvcoTags: [],
    phoneCodes: [],
    contacts: [],
    openedContact: 0,
    files: [],
    newData: {
      emails: spy(
        {
          email: '',
          type: '',
        },
        EMAIL_FIELDS_TO_SPY,
      ),
      phones: spy(
        {
          phone: '',
          type: '',
          code: '',
          extension: '',
          country: '',
          unformattedPhone: '',
        },
        PHONE_FIELDS_TO_SPY,
      ),
    },
    newContact: spy(
      {
        fullName: '',
        firstName: '',
        lastName: '',
        nick: '',
        pronounce: '',
        pivot: {
          title: '',
          notes: '',
        },
        prefix: prefixes.get(0),
        suffix: suffixes.get(0),
        phone: '',
        type: 'Direct',
        code: '',
        extension: '',
        country: '',
        unformattedPhone: '',
        email: '',
        phones: [],
        suggestions: [],
      },
      ['phone', 'email', ...CONTACT_FIELDS_TO_SPY, ...PHONE_FIELDS_TO_SPY],
    ),
  }),
  FIELDS_TO_SPY,
);

const emptyEntity = {
  harvcoTags: fromJS({ id: 0, tag: '' }),
};

const ENTITY_FIELDS_TO_SPY = {
  harvcoTags: ['tag'],
};

const ENTITY_VALIDATORS = {
  harvcoTags: getValidator({
    type: 'array',
    items: {
      type: 'object',
      properties: {
        tag: {
          title: 'Tag',
          description: 'should not be empty',
          type: 'string',
          minLength: 1,
        },
      },
    },
  }),
};

let wrappedReducer = reducer; // (state, action) => checkValidity(reducer(state, action), action);

wrappedReducer = intNormalize('revenue', null, ActionType.UPDATE_COMPANY, wrappedReducer);
wrappedReducer = intNormalize('employees', null, ActionType.UPDATE_COMPANY, wrappedReducer);

wrappedReducer = handleApiError(ActionType.ERRORED_COMPANY_BUYER, wrappedReducer);

wrappedReducer = withStatePrefix(ActionType.ERROR_SAVE_COMPANY_CONTACT, wrappedReducer, (state, action) => {
  if (action.isNew) return ['newContact', 'inputErrors'];

  return ['contacts', state.get('openedContact'), 'inputErrors'];
});

wrappedReducer = withStatePrefix(ActionType.ERROR_SAVE_COMPANY_CONTACT_CHANNEL, wrappedReducer, (state, action) => {
  if (action.isNew) return ['newData', action.channelType, 'inputErrors'];

  return ['contacts', state.get('openedContact'), action.channelType, action.channelIndex, 'inputErrors'];
});

wrappedReducer = check('suggestResearcher', 'suggests', ActionType.LOADED_USERS, wrappedReducer);

wrappedReducer = autoNullId('suggestResearcher', 'buyerLastResearcherId', ActionType.UPDATE_COMPANY, wrappedReducer);

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.UPDATE_COMPANY:
      return checkValidity(changeField(state, action));

    case ActionType.REVERT_UPDATE:

    // fallthrough
    case ActionTargetType.REVERT_UPDATE:
      return checkValidity(revertToOriginal(state, action.field));

    case ActionDetailType.FETCHING_COMPANY_SUCCESS:
      return spy(state.merge(mapResponse(action)), FIELDS_TO_SPY);

    case ActionCriteriaType.LOADED_COMPANY_CONTACT:

    // fallthrough
    case ActionMarketingType.LOADED_COMPANY_CONTACT:

    // fallthrough
    case ActionTargetType.LOADED_COMPANY_CONTACT:

    // fallthrough
    case ActionType.LOADED_COMPANY_CONTACT:
      return state.merge({
        openedContact: 0,
        contacts: getContacts(action.response),
      });

    case ActionCriteriaType.DEL_COMPANY_CONTACT:

    // fallthrough
    case ActionMarketingType.DEL_COMPANY_CONTACT:

    // fallthrough
    case ActionTargetType.DEL_COMPANY_CONTACT:

    // fallthrough
    case ActionType.DEL_COMPANY_CONTACT:
      return state
        .update('contacts', contacts => contacts.filter(contact => contact.get('id') !== action.contactId))
        .set('openedContact', 0);

    case ActionType.LOADED_TITLES: {
      const mask = new RegExp(String(action.filter).replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1'), 'i');

      return state.setIn(
        [...action.prefix.split('.'), 'suggestions'],
        fromJS(action.response.data.filter(title => mask.test(title)).map(title => ({ text: title }))),
      );
    }

    case ActionType.OPEN_COMPANY_CONTACT:
      return state.setIn(['openedContact'], action.index);

    case ActionType.ADD_COMPANY_DETAIL_ENTITY:
      return appendDetailEntity(state, action);

    case ActionType.EDIT_COMPANY_DETAIL_ENTITY:
      return checkValidity(editDetailEntity(state, action), action);

    case ActionType.DEL_COMPANY_DETAIL_ENTITY:
      return deleteDetailEntity(state, action);

    case ActionType.SAVED_COMPANY_BUYER:
      return spy(setValueAsOriginalByMap(state, action.fields), FIELDS_TO_SPY);

    case ActionType.ERRORED_COMPANY_BUYER:
      return state;

    case ActionType.CLOSE_COMPANY_BUYER_VALIDATION_ERROR:
      return state
        .get('contacts')
        .reduce(
          (state, _, index) => state.deleteIn(['contacts', index, 'inputErrors', action.field]),
          state.deleteIn(['inputErrors', action.field]).deleteIn(['newContact', 'inputErrors', action.field]),
        );

    case ActionType.SAVED_COMPANY_CONTACT:
      return saveContact(state, action);

    case ActionType.SAVED_COMPANY_CONTACT_CHANNEL:

    // fallthrough
    case ActionTargetType.SAVED_COMPANY_CONTACT_CHANNEL:
      return saveContactChannel(state, action);

    case ActionType.SAVED_COMPANY_ENTITYES:
      return mergeDeepToOriginal(state, { [action.entity]: action.body });

    case ActionType.LOADED_COMPANY_ENTITYES:
      return state.set(action.entity, fromJS(detailMapper[action.entity](action.response.data)));

    case ActionType.DEL_CONTACT_CHANNEL:
      return delChannelFromContact(state, action);

    case ActionType.REORDER_CONTACTS:
      return state.setIn(['contacts'], action.contacts);

    case ActionType.REORDER_CHANNELS: {
      const contacts = state.getIn(['contacts', action.openedContact, action.name]);

      const newOrder = Object.keys(action.data).reduce((ret, key) => {
        const index = ret.findIndex(c => c.get('id') === action.data[key].id);

        if (index > -1) {
          ret = ret.setIn([index, 'priority'], action.data[key].priority);
        }

        return ret;
      }, contacts);

      return state.setIn(['contacts', action.openedContact, action.name], newOrder);
    }

    case ActionType.ADD_VALIDATION_ERROR: {
      const path = action.path.split('.').concat(['inputErrors']);
      const inputErrors = state.getIn(path, Map());

      return state.setIn(path, inputErrors.set(action.field, action.message));
    }

    case ActionType.LOADED_DIRECTORS:
      return state.setIn(['suggests'], fromJS(action.response.data));

    case ActionType.LOADED_USERS:
      return state.setIn(
        ['suggests'],
        action.page === 1 ? fromJS(action.response.data) : state.get('suggests').concat(action.response.data),
      );

    case ActionTargetType.LOADED_PHONE_CODES_SUCCESS:
      return state.set(
        'phoneCodes',
        fromJS(action.response.data).insert(
          0,
          Map({
            name: '--',
            code: '',
          }),
        ),
      );

    case ActionType.REVERT_CHANGES:
      return revert(state);

    case ActionType.DELETE_COMPANY_FILE:
      return state.set(
        'files',
        state.get('files').filter(file => file.get('id') !== action.id),
      );

    case ActionType.FETCHING_COMPANY_FILES:
      return state.merge({ files: action.response.data });

    default:
      return state;
  }
}

function setValueAsOriginalByMap(state, map) {
  return spy(
    map.reduce((result, key) => {
      const value = getIn(result, key);

      return result.setIn(key.split('.'), value);
    }, state),
    map,
  );
}

function appendDetailEntity(state, action) {
  const { entity } = action;
  const newEntity = emptyEntity[entity].set('id', uniqueNewId());

  action.index = state.get(entity).size;

  return editDetailEntity(
    state.set(entity, state.get(entity).push(spy(newEntity, ENTITY_FIELDS_TO_SPY[entity]))),
    action,
  );
}

function editDetailEntity(state, action) {
  return state.update(action.entity, table => table.map((row, index) => row.set('isEditing', index === action.index)));
}

function deleteDetailEntity(state, action) {
  return state.update(action.entity, details => details.filter(row => getIn(row, 'id') !== action.id));
}

function mapResponse(action) {
  return {
    ...getDetails(action.response),
    ...getMainInfo(action.response),
    openedContact: 0,
    contacts: getContacts(action.response),
    loaded: true,
  };
}

function getMainInfo({ data }) {
  const {
    revenue,
    employees,
    buyerDescription,
    buyerResearchNotes,
    buyerLastResearchedDate,
    buyerLastResearcherId,
    buyerResearchUser,
  } = data;

  return {
    revenue,
    employees,
    buyerDescription,
    buyerResearchNotes,
    buyerLastResearchedDate: moment(buyerLastResearchedDate),
    buyerLastResearcherId,
    suggestResearcher: buyerResearchUser?.userName ?? '',
  };
}

function getDetails({ data }) {
  const { harvcoTags } = data;

  return {
    harvcoTags: detailMapper.harvcoTags(harvcoTags),
  };
}

const detailMapper = {
  harvcoTags: data =>
    data.map(value =>
      spy(
        {
          ...value,
          tooltip: `${value['user.userName'] || value.userName || 'No user'}
${moment(value.date).format('MM/DD/YYYY')}`,
        },
        ENTITY_FIELDS_TO_SPY.harvcoTags,
      ),
    ),
};

function clearChangedFieldError(state, action) {
  const split = action.name.split('.');

  for (let i = 0; i < split.length; i++) {
    const pre = split.slice(0, i);
    const post = split.slice(i);

    state = state.deleteIn([...pre, 'inputErrors', post.join('.')]);
  }

  const split2 = action.name.replace(/\.pivot\./, '.').split('.');

  for (let i = 0; i < split2.length; i++) {
    const pre = split2.slice(0, i);
    const post = split2.slice(i);

    state = state.deleteIn([...pre, 'inputErrors', post.join('.')]);
  }

  return state;
}

function changeField(state, action) {
  if (/Year$/.test(action.name)) {
    state = setIn(state, action.name, action.value || null);
  }
  action = trimWhiteSpaceIf(/contacts.\d+.emails.\d+.email/i, action);
  action = trimWhiteSpaceIf(/newData.emails.email/i, action);
  action = trimWhiteSpaceIf(/newContact.email/i, action);

  state = clearChangedFieldError(setIn(state, action.name, action.value), action);

  switch (action.name) {
    case 'suggestResearcher':
      state = setIn(state, 'buyerLastResearcherId', 0);
      break;

    default:
      return state;
  }

  return state;
}

// Remove duplicated contact profiles
function getContacts({ data }) {
  const existings = {};

  return data.entityContacts.reduce((res, item) => {
    if (existings[item.id]) return res;

    const contact = { ...item };

    contact.prefix = prefixes.find(prefix => prefix.get('value') === contact.prefix, null, prefixes.get(0));
    contact.suffix = suffixes.find(
      suffix => suffix.get('value') === contact.suffix,
      null,
      fromJS({ name: contact.suffix, value: contact.suffix }),
    );

    contact.emails = contact.emails.map(email => spy(email, EMAIL_FIELDS_TO_SPY));
    contact.phones = contact.phones.map(phone => spy(phone, PHONE_FIELDS_TO_SPY));
    contact.age = getAge(contact.birthYear);
    contact.suggestions = [];

    res.push(spy(contact, CONTACT_FIELDS_TO_SPY));
    existings[item.id] = item.id;

    return res;
  }, []);
}

function checkValidity(state) {
  const plainState = toJS(state);
  const isValid = validator.isValid(plainState);

  const inputErrors = validator.getFirstError(plainState);

  const detailsValidity = Object.keys(ENTITY_VALIDATORS).reduce((res, key) => {
    res[`${key}IsValid`] = ENTITY_VALIDATORS[key].isValid(plainState[key]);
    if (res[`${key}IsValid`]) return res;

    const error = ENTITY_VALIDATORS[key].getFirstError(plainState[key]);
    const errorField = Object.keys(error)[0];

    inputErrors[`${key}.${errorField}`] = error[errorField];

    return res;
  }, {});

  let tempState = state;

  state.getIn(['contacts']).forEach((contact, contactIndex) => {
    contact.get('phones').forEach((phone, phoneIndex) => {
      tempState = tempState.updateIn(['contacts', contactIndex, 'phones', phoneIndex], phone =>
        phone.merge({
          inputErrors: {},
          isValid: true,
        }),
      );
    });
  });

  tempState = tempState.merge({
    ...detailsValidity,
    inputErrors,
    isValid,
  });

  return tempState;
}

function delChannelFromContact(state, action) {
  const contactIndex = state.get('contacts').findKey(contact => contact.get('id') === action.contactId);

  if (contactIndex === undefined) return state;

  const channelIndex = state
    .getIn(['contacts', contactIndex, action.channelType])
    .findKey(channel => channel.get('id') === action.id);

  if (channelIndex === undefined) return state;

  return state.deleteIn(['contacts', contactIndex, action.channelType, channelIndex]);
}

function saveContactChannel(state, action) {
  let { channelIndex } = action;

  if (action.isNew) {
    channelIndex = state.getIn(['contacts', state.get('openedContact'), action.channelType]).size;

    const dummy = merge(defaultState.getIn(['newData', action.channelType]).set('id', action.response.id), action.body);

    state = state.setIn(['contacts', state.get('openedContact'), action.channelType, channelIndex], dummy);
  }

  const dataForMerge = {
    contacts: {
      [state.get('openedContact')]: {
        [action.channelType]: {
          [channelIndex]: action.body,
        },
      },
    },
  };

  return mergeDeepToOriginal(state, dataForMerge).set('newData', defaultState.get('newData'));
}

function saveContact(state, action) {
  if (action.isNew) return state.set('newContact', defaultState.get('newContact'));

  const contactIndex = state.get('contacts').findKey(contact => contact.get('id') === action.contactId);

  const dataForMerge = {
    contacts: {
      [contactIndex]: action.body,
    },
  };

  return mergeDeepToOriginal(state, dataForMerge).set('newContact', defaultState.get('newContact'));
}
