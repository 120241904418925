import PropTypes from 'prop-types';

import React from 'react';
import { Map } from 'immutable';
import moment from 'moment';
import config from '../../config';
import MailingHistoryTable from './MailingHistoryTable';

const columnDefs = config.mailingHistory.getIn(['table', 'columnDefs']);

/**
 * Mailing history page component.
 *
 * @param props {Object}.
 * @param props.targetCompany {Immutable.Map} Map with company information.
 * @param props.onCompanyInfoChange {Function} Handle company info change event.
 * @param props.onToggleDBA {Function} Handle toggle dba input event.
 * @param props.onToggleCountry {Function} Handle toggle country input event.
 */
const MailingHistory = props => {
  const { loggedUser, mailingHistory, onGetNextPageData, onChange, onDoubleClickRow, onReload } = props;

  return (
    <div className="mailHistory">
      <div className="top_side">
        <div className="container text-center">
          <strong>Mailing History</strong> -<strong>{loggedUser.get('userName')}</strong> -{' '}
          {moment().format('MM/DD/YYYY')}
        </div>
      </div>
      <div className="main">
        <div className="container" role="main">
          <div className="position-relative full-height">
            <MailingHistoryTable
              columnDefs={columnDefs}
              data={mailingHistory}
              onChange={onChange}
              onDoubleClickRow={onDoubleClickRow}
              onGetNextPageData={onGetNextPageData}
            />
          </div>
        </div>
      </div>
      <footer className="navbar-fixed-bottom">
        <div className="container">
          <div className="row">
            <div className="container-fluid text-right">
              <div className="btns">
                <button className="btn btn-default btn-xs" onClick={onReload} type="submit">
                  <i className="icon-user icon-white" />
                  Reload
                </button>
                <button className="btn btn-default btn-xs" type="submit">
                  <i className="icon-user icon-white" />
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

MailingHistory.propTypes = {
  loggedUser: PropTypes.instanceOf(Map).isRequired,
  mailingHistory: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
  onGetNextPageData: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
};

export default MailingHistory;
