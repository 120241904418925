import PropTypes from 'prop-types';

import React from 'react';
import Immutable from 'immutable';
import { ContextMenuTrigger } from 'react-contextmenu';
import classNames from 'classnames';
import AutoSuggestion from '../../helpers/AutoSuggestion';
import config from '../../../config';
import doubleClickCheck from '../../../utils/doubleClickCheck';

/**
 * Column of one row table component.
 *
 * @param props {Object}.
 * @param props.row {Immutable.Map} Row Object.
 * @param props.contextId {String} Context menu's id.
 * @param props.type {String} Use type to differentiate which tables are firing events.
 * @param props.suggestions {Immutable.List} Suggestion list.
 * @param props.onRowClick {Function} Handle event of clicking on a table row.
 * @param props.onRowDoubleClick {Function} Handle event of double clicking on a table row.
 * @param props.onTextChange {Function} Handle event of changing text.
 * @param props.onSuggestionClose {Function} Handle event of closing suggestion.
 * @param props.onSuggestionSelect {Function} Handle event of selecting a suggestion.
 * @param props.setInputElement {Function} To hold a ref to input element.
 * @returns {React.Component}
 * @class
 */
const OneColTableRow = props => {
  const {
    row,
    type,
    contextId,
    suggestions,
    onRowClick,
    onRowDoubleClick,
    onTextChange,
    onSuggestionClose,
    onSuggestionSelect,
    setInputElement,
    skipMenu,
  } = props;
  const id = row.get('id');
  const mode = row.get('mode');
  const cellClass = classNames('cell', {
    'cell--marked': row.get('dirty'),
    'text-danger': row.get('deprecated') || row.get('deletedAt'),
  });

  const click = ({ eventType, data }) => {
    if (eventType === 'click') {
      onRowClick(data);
    } else {
      onRowDoubleClick(data);
    }
  };

  const Tr = ({ children, ...extra }) => <tr {...extra}>{children}</tr>;

  switch (mode) {
    case config.EDIT_MODE:

    // fallthrough
    case config.LOADING_MODE:

    // fallthrough
    case config.SUGGEST_MODE:
      return (
        <tr>
          <td className={cellClass}>
            <AutoSuggestion
              className="form-control form-control--table"
              data={{ type, row }}
              id={`input_${type}${id}`}
              mode={mode}
              name={type}
              onSuggestionClose={onSuggestionClose}
              onSuggestionSelect={onSuggestionSelect}
              onTextChange={onTextChange}
              setInputElement={setInputElement}
              suggestions={suggestions}
              value={row.get('name')}
            />
          </td>
        </tr>
      );

    default:
      return skipMenu ? (
        <tr>
          <td className={cellClass}>{row.get('name')}</td>
        </tr>
      ) : (
        <ContextMenuTrigger collect={() => ({ id, type })} id={contextId} renderTag={Tr}>
          <td
            className={cellClass}
            onClick={event => {
              event.stopPropagation();
              doubleClickCheck({ row, type }, click);
            }}
          >
            {row.get('name')}
          </td>
        </ContextMenuTrigger>
      );
  }
};

OneColTableRow.propTypes = {
  contextId: PropTypes.string.isRequired,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onSuggestionClose: PropTypes.func,
  onSuggestionSelect: PropTypes.func,
  onTextChange: PropTypes.func,
  row: PropTypes.instanceOf(Immutable.Map).isRequired,
  setInputElement: PropTypes.func.isRequired,
  suggestions: PropTypes.instanceOf(Immutable.List),
  type: PropTypes.string.isRequired,
};

export default OneColTableRow;
