import React from 'react';
import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import AutoComplete from '../helpers/AutoComplete';

const renderSuggestion = suggestion => <span>{suggestion.text}</span>;

const ExecutiveRolePopup = props => {
  const { contact, onClose, executive, suggestDirector, suggestAnalyst, onCreate } = props;

  const name = contact.getIn(['fullName', 'value']);
  const header = (
    <span>
      Activate the "Executive" role for {name}, marking them as
      <nobr>a CEO-1st executive?</nobr>
    </span>
  );

  const footer = [
    <Button key="create" className="btn-primary btn-xs" data-dismiss="modal" onClick={onCreate}>
      Create
    </Button>,
    <Button key="close" className="btn-default btn-xs" data-dismiss="modal" onClick={onClose}>
      Close
    </Button>,
  ];

  const suggestions = executive.get('suggests');

  return (
    <Popup footer={footer} header={header} id="ExecRole">
      <div className="row media-450">
        <AutoComplete
          checkOn="text"
          formGroupClass="col-lg-6 col-xs-6"
          getSuggestion={suggestDirector.onFetch}
          getSuggestionValue={suggestDirector.getValue}
          inputProps={{
            autoFocus: true,
            id: '2siggstimexecitoverolepopiup', // TODO remove this after autosuggest will not require id
            className: 'form-control input-sm',
            label: 'Module',
            name: 'suggestDirector',
          }}
          onSuggestionSelected={suggestDirector.onSelect}
          onUpdateSuggestions={suggestDirector.onUpdate}
          renderSuggestion={renderSuggestion}
          suggestions={suggestions}
          text={suggestDirector.value}
        />
      </div>
      <div className="row media-450">
        <AutoComplete
          checkOn="text"
          formGroupClass="col-lg-6 col-xs-6"
          getSuggestion={suggestAnalyst.onFetch}
          getSuggestionValue={suggestAnalyst.getValue}
          inputProps={{
            id: '12siggstimexecitoverolepopiup3qwe', // TODO remove this after autosuggest will not require id
            className: 'form-control input-sm',
            label: 'Analyst/Assoc.',
            name: 'suggestAnalyst',
          }}
          onSuggestionSelected={suggestAnalyst.onSelect}
          onUpdateSuggestions={suggestAnalyst.onUpdate}
          renderSuggestion={renderSuggestion}
          suggestions={suggestions}
          text={suggestAnalyst.value}
        />
      </div>
    </Popup>
  );
};

export default ExecutiveRolePopup;
