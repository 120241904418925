import { connect } from 'react-redux';

import connectOptions, { mergeProps } from '../../../utils/connectOptions';
import AutoCompleteEditor from '../../../components/helpers/AutoCompleteCellEditor';
import {
  handleGetHarvcoLeadSuggestion,
  clearHarvcoLeadSuggestion,
  selectHarvcoLeadSuggestion,
} from '../../../actions/company/buyer/ceo';

const mapStateToProps = state => {
  const activeProjects = state.targetCompany.buyer.ceo.get('activeProjects');

  return {
    suggestions: activeProjects.getIn(['suggestions', 'harvcoLead', 'list']).toJS(),
    loading: activeProjects.getIn(['suggestions', 'harvcoLead', 'loading']),
    placeholder: 'Harvco Lead',
  };
};

const mapDispatchToProps = dispatch => ({
  selectSuggestion: ({ selected, rowData }) => {
    dispatch(
      selectHarvcoLeadSuggestion({
        id: selected.id,
        text: selected.text,
        eprojectId: rowData.eprojectId,
      }),
    );
  },

  loadSuggestion: ({ value, rowData }) => {
    dispatch(
      handleGetHarvcoLeadSuggestion({
        value,
        data: { eprojectId: rowData.eprojectId },
      }),
    );
  },

  clearSuggestion: data => {
    dispatch(clearHarvcoLeadSuggestion(data.rowData.eprojectId));
  },
});

export default AutoCompleteEditor(Comp =>
  connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    ...connectOptions,
    forwardRef: true,
  })(Comp),
);
