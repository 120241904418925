import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import classNames from 'classnames';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import Popups from '../../components/Popup';
import { getChanged, isDeepChanged, getIn, unwrap } from '../../utils/ChangeSpy';
import { showError } from '../../utils/MessagePopup';
import {
  findUsers,
  changeField,
  findIndustries,
  clearSuggest,
  makeContactExecutive,
  findAnalysts,
  findDirectors,
  loadContactExecutive,
} from '../../actions/contact/contactExecutive';
import {
  changeContactInfoField,
  closeValidationError,
  setNameFieldError,
  reloadContactDetail,
} from '../../actions/contactDetail';

import config from '../../config';
import AutoComplete from '../AutoComplete';
import { checkNameChangable, checkFirstName, checkLastName, parseFullName } from '../services/nameRules';

const emptyMap = new Map();

class AddExecutiveContactPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.onChangeExecutive = this.onChange.bind(this, 'executive');
    this.onChangeInfo = this.onChange.bind(this, 'contact');
    this.onCreate = this.onCreate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onFetchUsers = this.onFetchUsers.bind(this);
    this.onFetchIndustries = this.onFetchIndustries.bind(this);
    this.onUpdateIndustry = this.onUpdateSuggestions.bind(this, 'suggestIndustry');
    this.onSelectIndustry = this.onSelectIndustry.bind(this);

    this.onUpdateDirector = this.onUpdateSuggestions.bind(this, 'suggestDirector');
    this.onUpdateAnalyst = this.onUpdateSuggestions.bind(this, 'suggestAnalyst');
    this.onSelectDirector = this.onSelectUser.bind(this, 'suggestDirector');
    this.onSelectAnalyst = this.onSelectUser.bind(this, 'suggestAnalyst');
    this.onErrorClose = this.onErrorClose.bind(this);
    this.onChangeSuggestion = this.onChangeSuggestion.bind(this);
    this.updateFirstName = this.updateFirstName.bind(this);
    this.updateLastName = this.updateLastName.bind(this);
    this.updateFullName = this.updateFullName.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBICClick = this.onBICClick.bind(this);
    this.onFindEmail = this.onFindEmail.bind(this);

    this.state = { submitting: false };
    this.changable = {
      fullName: false,
      firstName: false,
      lastName: false,
    };
  }

  componentDidMount() {
    const user = this.context.currentUser;

    if (user) {
      this.props.changeField({
        name: 'suggestDirector',
        value: user.get('module') || '',
      });
      this.props.changeField({
        name: 'recordOwnerId',
        value: user.get('moduleId') || 0,
      });

      const roles = user.get('roles', List());

      if (roles.size && roles.getIn([0, 'slug']) === config.ANALYST_ASSOCIATE) {
        this.props.changeField({
          name: 'suggestAnalyst',
          value: user.get('userName'),
        });
        this.props.changeField({
          name: 'recordSubOwnerId',
          value: user.get('id'),
        });
      }
    }
  }

  getChildContext() {
    return {
      onErrorClose: this.onErrorClose,
      inputErrors: this.props.inputErrors,
    };
  }

  onErrorClose(e, field) {
    this.props.closeValidationError({ field });
  }

  onChange(field, e) {
    const { name, value } = e.target;

    switch (name) {
      case 'firstName':
        this.updateFirstName(value);

        return;

      case 'lastName':
        this.updateLastName(value);

        return;

      case 'fullName':
        this.updateFullName(value);

        return;

      default:
        return this.props.changeContactInfoField({
          name,
          value,
        });
    }
  }

  updateFirstName(name) {
    const { contact, changeContactInfoField } = this.props;
    const tmp = this.trimDoubleSpaces(name);

    const firstName = unwrap(contact.get('firstName') || '');
    let fullName = unwrap(contact.get('fullName') || '');

    fullName = fullName.indexOf(firstName) > -1 ? fullName.substring(firstName.length) : fullName;

    if (this.changable.firstName) {
      changeContactInfoField({
        name: 'firstName',
        value: tmp,
      });
    }

    if (this.changable.fullName) {
      changeContactInfoField({
        name: 'fullName',
        value: this.trimDoubleSpaces(`${tmp} ${fullName}`),
      });
    }
  }

  updateLastName(name) {
    const { contact, changeContactInfoField } = this.props;
    const tmp = this.trimDoubleSpaces(name);

    const lastName = unwrap(contact.get('lastName') || '');
    let fullName = unwrap(contact.get('fullName') || '');

    fullName = fullName.lastIndexOf(lastName) > -1 ? fullName.substring(0, fullName.lastIndexOf(lastName)) : fullName;

    if (this.changable.lastName) {
      changeContactInfoField({
        name: 'lastName',
        value: tmp,
      });
    }

    if (this.changable.fullName) {
      changeContactInfoField({
        name: 'fullName',
        value: this.trimDoubleSpaces(`${fullName} ${tmp}`),
      });
    }
  }

  updateFullName(name) {
    const { changeContactInfoField } = this.props;
    const arrayStr = parseFullName(name);

    if (this.changable.firstName) {
      changeContactInfoField({
        name: 'firstName',
        value: arrayStr[0],
      });
    }

    if (this.changable.lastName) {
      changeContactInfoField({
        name: 'lastName',
        value: arrayStr[1],
      });
    }

    if (this.changable.fullName) {
      changeContactInfoField({
        name: 'fullName',
        value: arrayStr[2],
      });
    }
  }

  trimDoubleSpaces(name) {
    const tmp = name || '';

    return tmp.replace(/\s{2,}/g, ' ');
  }

  onCreate() {
    const {
      tags,
      models,
      contact,
      executive,
      loadContactExecutive,
      reloadContactDetail,
      makeContactExecutive,
    } = this.props;
    const { openPopup } = this.context;

    if (contact.get('isValid')) {
      const id = contact.get('id') || 'new';
      const body = getChanged(contact);
      const execFields = getChanged(executive);
      const prefix = unwrap(contact.get('prefix')).get('value');
      const suffix = unwrap(contact.get('suffix')).get('value');

      const afterError = ({ resBody }) => {
        this.setState({ submitting: false });

        if (resBody) {
          showError(openPopup, [resBody.message], null, () => openPopup('AddExecutiveContactPopup'));
        }
      };

      const afterSuccess = ({ response }) => {
        const { id } = response;

        loadContactExecutive({ id });
        reloadContactDetail({ id }, this.onClose);
      };

      this.setState({ submitting: true }, () => {
        makeContactExecutive(
          {
            ...body,
            ...execFields,
            prefix,
            suffix,
            id,
            industryCategoryIds: tags.concat(models).reduce((a, i) => {
              if (i.get('checked')) {
                a.push(i.get('id'));
              }

              return a;
            }, []),
          },
          afterSuccess,
          afterError,
        );
      });
    }
  }

  onClose() {
    this.context.closePopupUnsafe();
  }

  static onGetValue(suggestion) {
    return suggestion.value;
  }

  onFetchUsers({ value }) {
    this.props.findUsers({ filter: value });
  }

  onFetchIndustries({ value }) {
    this.props.findIndustries({ filter: value });
  }

  onUpdateSuggestions(suggestionName, param) {
    if ('text' in param) {
      const name = suggestionName;
      const value = param.text;

      this.props.changeField({ name, value });
    }

    if ('suggestions' in param) {
      const name = 'suggests';
      const value = param.suggestions;

      this.props.changeField({ name, value });
    }
  }

  onSelectUser(name, e, { suggestion }) {
    this.props.changeField({ name, value: suggestion.text });
  }

  onSelectIndustry(e, { suggestion }) {
    this.props.changeField({ name: 'suggestIndustry', value: suggestion.text });
    this.props.changeField({ name: 'industryId', value: suggestion.id });
  }

  getSuggestProps(name) {
    let onFetch = this.props.findUsers;

    if (name === 'Industry') {
      onFetch = this.onFetchIndustries;
    } else if (name === 'Director') {
      onFetch = ({ value }) => {
        this.props.findDirectors({ filter: value });
      };
    } else if (name === 'Analyst') {
      onFetch = ({ value }) => {
        this.props.findAnalysts({ filter: value });
      };
    }

    return {
      onFetch,
      onUpdate: this[`onUpdate${name}`],
      getValue: AddExecutiveContactPopupContainer.onGetValue,
      onSelect: this[`onSelect${name}`],
      value: this.props.executive.get(`suggest${name}`, ''),
    };
  }

  onChangeSuggestion(obj) {
    if (typeof obj.value === 'string') {
      const { value } = obj;
      const nextValue = this.props.contactInfo.get('suffixes').find(
        suffix => suffix.get('name') === value,
        null,
        Map({
          name: value || '',
          value: value || '',
        }),
      );

      this.props.changeContactInfoField({ name: 'suffix', value: nextValue });
    }
  }

  onBlur(event) {
    const {
      target: { name, value },
    } = event;

    if (name === 'firstName' && !checkFirstName(value)) {
      this.props.setNameFieldError({
        field: 'firstName',
        value: 'Enter only the first name with no initials, etc.',
      });
    } else if (name === 'lastName' && !checkLastName(value)) {
      this.props.setNameFieldError({
        field: 'lastName',
        value: 'Enter only the last name with no initials, suffixes, etc.',
      });
    }
  }

  onFocus({ target: { name } }) {
    const { contact } = this.props;
    const firstName = unwrap(contact.get('firstName') || '');
    const lastName = unwrap(contact.get('lastName') || '');
    const fullName = unwrap(contact.get('fullName') || '');

    if (['firstName', 'lastName', 'fullName'].indexOf(name) > -1) {
      this.changable = checkNameChangable(name, {
        firstName,
        lastName,
        fullName,
      });
    }
  }

  getSuffixesSuggest() {
    const value = getIn(this.props.contactInfo, 'suffix').get('name');
    const idValue = value;
    const regExp = new RegExp(String(value).replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1'), 'i');
    const className = classNames('form-control input-sm', {
      changed: isDeepChanged(this.props.contactInfo.get('suffix')),
    });
    const suggestions = getIn(this.props.contactInfo, 'suffixes')
      .filter(suffix => regExp.test(suffix.get('name')))
      .map(suffix => ({
        id: suffix.get('value'),
        text: suffix.get('name'),
      }));

    return (
      <AutoComplete
        change={this.onChangeSuggestion}
        find={args => {
          setTimeout(
            () =>
              args.afterSuccess({
                response: {
                  data: getIn(this.props.contactInfo, 'suffixes')
                    .filter(suffix => regExp.test(suffix.get('name')))
                    .map(suffix => ({
                      id: suffix.get('value'),
                      text: suffix.get('name'),
                    })),
                },
              }),
            0,
          );
        }}
        idValue={idValue}
        inputProps={{ className }}
        loading={false}
        name="suffix"
        suggestions={suggestions}
        suggestsName="suffixes"
        value={value}
        suggestIfEmpty
      />
    );
  }

  onBICClick() {
    this.context.openPopup('TagsManagementPopup', {
      addNew: true,
      clientView: true,
      cb: () =>
        this.context.openPopup('AddExecutiveContactPopup', {
          fromPositionPopup: this.fromPositionPopup,
          cb: this.props.popup.getIn(['props', 'cb']),
        }),
    });
  }

  onFindEmail() {
    this.context.openPopup('EmailFinderPopup', this.props);
  }

  render() {
    const { children, contact, executive, models, tags, inputErrors } = this.props;

    const suggestDirector = this.getSuggestProps('Director');
    const suggestAnalyst = this.getSuggestProps('Analyst');
    const suggestIndustry = this.getSuggestProps('Industry');
    const selectedTags = tags.filter(tag => tag.get('checked'));
    const selectedModels = models.filter(tag => tag.get('checked'));
    const isValid = inputErrors && inputErrors.size === 0 && selectedTags.size > 0 && selectedModels.size > 0;

    return (
      <div>
        <Popups.AddExecutiveContactPopup
          contact={contact}
          executive={executive}
          isValid={isValid}
          models={models}
          onBICClick={this.onBICClick}
          onBlur={this.onBlur}
          onChangeExecutive={this.onChangeExecutive}
          onChangeInfo={this.onChangeInfo}
          onCreate={this.onCreate}
          onFindEmail={this.onFindEmail}
          onFocus={this.onFocus}
          prefix="newContact"
          submitting={this.state.submitting}
          suggestAnalyst={suggestAnalyst}
          suggestDirector={suggestDirector}
          suggestIndustry={suggestIndustry}
          suggestSuffix={this.getSuffixesSuggest()}
          tags={tags}
        />
        {children}
      </div>
    );
  }
}

AddExecutiveContactPopupContainer.contextTypes = {
  closePopupUnsafe: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
  openPopup: PropTypes.func.isRequired,
};

AddExecutiveContactPopupContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};

function mapStateToProps(state, props) {
  return {
    ...props,
    inputErrors: state.contact.info
      .get('inputErrors', emptyMap)
      .merge(state.contact.executive.get('inputErrors', emptyMap)),
    contact: state.contact.info,
    contactInfo: state.contact.info,
    executive: state.contact.executive,
    models: getIn(state.businessModels, 'models', List()),
    tags: getIn(state.industryTags, 'tags', List()),
  };
}

export { AddExecutiveContactPopupContainer };

const Connected = connect(
  mapStateToProps,
  {
    findUsers,
    closeValidationError,
    changeContactInfoField,
    findIndustries,
    clearSuggest,
    makeContactExecutive,
    reloadContactDetail,
    loadContactExecutive,
    changeField,
    findAnalysts,
    findDirectors,
    setNameFieldError,
  },
  mergeProps,
  connectOptions,
)(AddExecutiveContactPopupContainer);

Connected.closable = false;

export default Connected;
