import React, { Component } from 'react';

class FormPreventEnterSubmit extends Component {
  constructor(props) {
    super(props);

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleKeyPress(event) {
    this.enterHit = event.key === 'Enter';
  }

  handleOnSubmit(event) {
    event.preventDefault();
    if (!this.enterHit) {
      this.props.onSubmit(event);
    }
  }

  render() {
    const { children, onSubmit, ...rest } = this.props;

    return (
      <form {...rest} onKeyPress={this.handleKeyPress} onSubmit={this.handleOnSubmit}>
        {children}
      </form>
    );
  }
}

export default FormPreventEnterSubmit;
