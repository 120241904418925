import React from 'react';
import PropTypes from 'prop-types';

const ADDITIONAL_BOTTOM_PADDING = 10;

export default class WysiwygView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.content = null;

    this.setIframeRef = element => {
      this.content = element;
    };
    this.handleLoadContent = this.handleLoadContent.bind(this);
  }

  handleLoadContent() {
    const that = this;

    this.content.contentWindow.addEventListener('resize', function() {
      that.content.style.height = `${this.document.body.scrollHeight + ADDITIONAL_BOTTOM_PADDING}px`;
    });

    const { onDoubleClick } = this.props;
    const { body } = this.content.contentWindow.document;
    const { head } = this.content.contentWindow.document;
    const linkElement = this.content.contentWindow.document.createElement('link');

    setTimeout(() => {
      if (that.content) {
        that.content.style.height = `${body.scrollHeight + ADDITIONAL_BOTTOM_PADDING}px`;
      }
    }, 500);

    linkElement.setAttribute('rel', 'stylesheet');
    linkElement.setAttribute('type', 'text/css');
    linkElement.setAttribute('href', '/css/wysiwyg-preview.css');

    head.appendChild(linkElement);

    if (onDoubleClick) {
      this.content.contentWindow.document.ondblclick = function() {
        onDoubleClick();
      };
    }

    // observe content change
    const config = {
      attributes: true,
      childList: true,
      subtree: true,
    };

    const callback = function(mutationsList) {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          that.content.style.height = `${body.scrollHeight + ADDITIONAL_BOTTOM_PADDING}px`;
        }
      }
    };

    const observer = new MutationObserver(callback);

    observer.observe(body, config);
  }

  render() {
    const { html } = this.props;

    return (
      <iframe
        ref={this.setIframeRef}
        className="wysiwygView"
        marginHeight="0"
        marginWidth="0"
        onLoad={this.handleLoadContent}
        sandbox="allow-same-origin allow-scripts"
        scrolling="no"
        srcDoc={html}
        title="content"
      />
    );
  }
}

WysiwygView.propTypes = {
  html: PropTypes.string.isRequired,
};
