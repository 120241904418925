import React from 'react';
import { List } from 'immutable';

import _Input from '../helpers/Input';
import _Textarea from '../helpers/Textarea';
import PrettyError from '../decorators/PrettyError';
import DisplayError from '../decorators/DisplayError';
import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import Checkbox from '../helpers/Checkbox';
import FormGroup from '../helpers/FormGroup';
import Table from '../helpers/AgGridTable';
import config from '../../config';

const Input = PrettyError(DisplayError(_Input));
const Textarea = PrettyError(DisplayError(_Textarea));
const columnDefs = config.tables.getIn(['project', 'approvalListIds', 'columns']);

const CheckboxCellRenderer = ({ onChange }) => <input onChange={onChange} type="checkbox" />;

const AddAddonProjectPopup = props => {
  const {
    approvalList,
    addOnProjectCreate,
    isValid,
    suggestUser,
    suggestClient,
    suggestExecutive,
    onChange,
    onChangeApprovalListIds,
    onSave,
    loading,
  } = props;
  const checkedApprovalListId = new Set();
  const disabledButton = !isValid || loading;

  const notEmptyApprovalList = approvalList
    .get('list')
    .filter(approval => approval.get('projectApprovalLists').size > 0)
    .map(approval => ({
      ...approval.toJS(),
      projectApprovalLists: approval
        .get('projectApprovalLists')
        .map(projectApprovalList => ({
          ...projectApprovalList.toJS(),
          projectCategory: approval.get('projectCategory'),
        }))
        .toJS(),
    }));

  const data = [];

  notEmptyApprovalList.forEach(approval => {
    data.push(...approval.projectApprovalLists);
  });

  const header = <div>Add Add-on Project</div>;
  const footer = (
    <Button className="btn-primary btn-xs" disabled={disabledButton} onClick={onSave}>
      Save
    </Button>
  );

  const getColumnDefs = () => {
    const columns = columnDefs.map(columnDef => {
      const field = columnDef.get('field');

      switch (field) {
        case 'selected':
          return columnDef.set('cellRendererFramework', params => (
            <CheckboxCellRenderer onChange={() => handleChangeClick(params)} />
          ));

        default:
          return columnDef;
      }
    });

    return columns;
  };

  const handleChangeClick = params => {
    const {
      colDef: { field },
      data: { approvalListId },
    } = params;

    if (field === 'selected') {
      if (checkedApprovalListId.has(approvalListId)) {
        checkedApprovalListId.delete(approvalListId);
      } else {
        checkedApprovalListId.add(approvalListId);
      }

      onChangeApprovalListIds('approvalListIds', [...checkedApprovalListId]);
    }
  };

  const getRowNodeId = data => data.approvalListId;

  return (
    <Popup footer={footer} header={header} id="newAddOnProject">
      <div className="row new-add-on-project">
        <FormGroup className="col-md-12">
          <Input
            className="input-sm"
            label="Project Label"
            name="category"
            onChange={onChange}
            root={addOnProjectCreate}
            autoFocus
            canDisplayError
            deep
            required
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-md-12">
          <Textarea
            label="Project Scope"
            name="details"
            onChange={onChange}
            placeholder=""
            root={addOnProjectCreate}
            rows="4"
            deep
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-md-12">
          <Textarea
            label="Status"
            name="status"
            onChange={onChange}
            placeholder=""
            root={addOnProjectCreate}
            rows="4"
            deep
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-md-6">{suggestUser}</FormGroup>
        <FormGroup className="col-md-6">{suggestClient}</FormGroup>
        <FormGroup className="col-md-6">{suggestExecutive}</FormGroup>
        <FormGroup className="col-md-6">
          <label>&nbsp;</label>
          <Checkbox
            checked={addOnProjectCreate.get('active')}
            label="Active"
            name="active"
            onChange={onChange}
            onClick={event => event.stopPropagation()}
          />
        </FormGroup>
        <FormGroup className="col-md-12">
          <label>Approval Lists (Select which lists, if any, to move to the new Project tab)</label>
          <div className="table-approval-list">
            <Table
              columnDefs={getColumnDefs()}
              getRowNodeId={getRowNodeId}
              isFetching={approvalList.get('loading')}
              rowData={List(data)}
            />
          </div>
        </FormGroup>
      </div>
    </Popup>
  );
};

export default AddAddonProjectPopup;
