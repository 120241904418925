import React from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import Grid from '../helpers/AgGridTable';
import { getLeads, updateSort } from '../../actions/leadsReport';
import config from '../../config';
import { CompanyCell } from './CompanyCell';
import { buildSortByParam } from '../../helpers/paramBuilder';

/**
 * Leads report table component.
 *
 * @param {object} props Props.
 * @param {boolean} props.isResultsFetching Show spinner icon when set to true.
 * @param {Immutable.List} props.usersIds Selected user IDs list.
 * @param {number} props.page Current page.
 * @param {number} props.totalPages Total pages count.
 * @param {Immutable.List} props.leads Leads companies list.
 * @param {string} props.sort Sort.
 * @param {object} props.sorts Multi sort model by smart filter.
 * @param {Function} props.getLeads Method for getting leads.
 * @param {Function} props.updateSort Method for update table sort.
 */
const LeadsReportTableComponent = props => {
  const { isResultsFetching, usersIds, page, totalPages, leads, sort, sorts, getLeads, updateSort } = props;

  /** Get columns definitions. */
  function getColumnDefs() {
    return config.tables.getIn(['leadsReport', 'columnDefs']).map(col => {
      switch (col.get('field')) {
        case 'targetName':
          return col.merge({
            cellRendererFramework: CompanyCell,
          });

        case 'tail':
          return col.merge({
            cellClassRules: {
              'text-italic': params => params.data.isItalicTail === true,
              'highlighted-tail-cell': params => params.data.isRedTail === true,
            },
          });

        default:
          return col;
      }
    });
  }

  /**
   * Get next page data handler.
   *
   * @param {number} page Page.
   */
  function handleGetNextPageData(page) {
    getLeads({
      usersIds: usersIds.toJS(),
      page,
      sort,
      sorts: sorts.toJS(),
    });
  }

  /**
   * Change sort data handler.
   *
   * @param {object} sortData Sort object (field and direction).
   */
  function handleChangeSortData(sortData) {
    const sortModel = buildSortByParam(
      fromJS({
        sortBy: {
          sortModel: sortData,
        },
      }),
    );

    updateSort(sortModel);

    const ids = usersIds.toJS();

    if (ids.length > 0) {
      getLeads({
        usersIds: ids,
        sort: sortModel,
        sorts: sorts.toJS(),
      });
    }
  }

  const rowClassRules = {
    'grey-shaded-effect': params => params.data.isActive === false && params.data.isActiveBuyerHarvey === false,
    'blue-shaded-effect': params => params.data.isActive === false && params.data.isActiveBuyerHarvey === true,
  };

  return (
    <Grid
      columnDefs={getColumnDefs()}
      isFetching={isResultsFetching}
      onGetNextPageData={handleGetNextPageData}
      onSortModelChanged={handleChangeSortData}
      page={page}
      rowClassRules={rowClassRules}
      rowData={leads}
      totalPages={totalPages}
      sortable
    />
  );
};

const mapStateToProps = state => ({
  isResultsFetching: state.leadsReport.get('fetchingResults'),
  page: state.leadsReport.get('page'),
  totalPages: state.leadsReport.get('totalPages'),
  leads: state.leadsReport.get('queryResults'),
  usersIds: state.leadsReport.getIn(['filters', 'usersIds']),
  sort: state.leadsReport.get('sort'),
  sorts: state.leadsReport.get('sorts'),
});

const mapActionToProps = {
  updateSort,
  getLeads,
};

const LeadsReportTable = connect(mapStateToProps, mapActionToProps)(React.memo(LeadsReportTableComponent));

export { LeadsReportTable };
