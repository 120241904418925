/**
 * Target statuses selector.
 *
 * @param {object} state Store state.
 */
export const targetStatuses = state => state.statuses.get('targetStatuses');

/**
 * Buyer statuses selector.
 *
 * @param {object} state Store state.
 */
export const buyerStatuses = state => state.statuses.get('buyerStatuses');
