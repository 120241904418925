import { fromJS } from 'immutable';

import * as ActionType from '../../actions/companyDetail';

const defaultState = fromJS({
  queryResults: [],
  isFetching: false,
  page: 0,
  totalPages: 0,
});

const wrappedReducer = reducer;

function mapResponse(exec = []) {
  return exec.map(ex => ({
    ...ex,
    endYear: Number(ex.endYear) ? (Number(ex.endYear) === -1 ? '?' : ex.endYear) : null,
  }));
}

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.FETCHING_COMPANY_SUCCESS:
      return state.mergeDeep({
        queryResults: mapResponse(action.response.data.executives),
        page: 1,
        totalPages: 1,
      });

    default:
      return state;
  }
}
