import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';

import DropDown, { DropDownElement } from '../helpers/DropDown';
import DashboardPanel from './DashboardPanel';
import MainHeader from './MainHeader';

/**
 * MainHeader panel. On left part title is shown. On right - navigation buttons.
 *
 * @param props {Object}.
 * @param props.onLogOff {Function} Callback for logoff.
 * @param props.title {String} Saritasa user at example.
 * @param props.controls {Immutable.Map} Map with search bar and dropdown (show/hide panels) definition.
 * @param props.widgets {Immutable.Map} Widgets definition (shown, cords, data, ...).
 * @returns {React.Component} Main component.
 * @class
 */
function Main(props) {
  const {
    onLogOff,
    currentUser,
    controls,
    widgets,
    onRowDoubleClick,
    onCellDoubleClick,
    onDragStart,
    onDragEnd,
    onSort,
    onResizeEnd,
    onWidgetClick,
    searchText,
    onSearchChange,
    onSearch,
    triggerVisibility,
    onCCVisitsReport,
    onRetainerReportClick,
    onOutreachReport,
    onCeoOutreachReport,
    onOffersAndBetterClick,
    onLeadsAndBetterClick,
    widgetsOrder,
    onColumnResize,
    onActivityReportClick,
    onChangePD,
    onChangeAllPD,
    onConfirmDeletion,
    onConfirmReject,
    onConflictReportClick,
  } = props;

  const searchButtonText = controls.getIn(['search', 'buttonText']);
  const searchInputText = controls.getIn(['search', 'inputText']);
  const content = [];

  widgets.map((widget, key) =>
    content.push(
      <DashboardPanel
        key={key}
        isDataFiltered={props.isDataFiltered}
        name={key}
        onCellDoubleClick={onCellDoubleClick}
        onChangeAllPD={onChangeAllPD}
        onChangePD={onChangePD}
        onClick={onWidgetClick}
        onClose={triggerVisibility}
        onColumnResize={onColumnResize}
        onConfirmDeletion={onConfirmDeletion}
        onConfirmReject={onConfirmReject}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onResizeEnd={onResizeEnd}
        onRowDoubleClick={onRowDoubleClick}
        onSort={onSort}
        onSwitchFilterData={props.onSwitchFilterData}
        widget={widget}
        zIndex={widgetsOrder.indexOf(key) + 20}
      />,
    ),
  );

  const togglerButtonText = controls.getIn(['dropdown', 'text']);
  const userRole = currentUser.getIn(['roles', 0, 'slug'], null);

  const togglerContent = controls
    .getIn(['dropdown', 'elements'])
    .map(element => {
      const widget = element.get('key');
      const access = element.get('access');
      const attributes = element.toJS();
      const className = classNames({
        'text-bold': widgets.getIn([widget, 'visible']),
      });

      // check if a user has access to this menu item
      if (access && !access.includes(userRole)) {
        return '';
      }

      return (
        <DropDownElement {...attributes} key={widget} name={widget} onClick={event => triggerVisibility(event, widget)}>
          <span className={className}>{element.get('name')}</span>
        </DropDownElement>
      );
    })
    .toJS();

  const handleKeyUp = event => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  const title = `${currentUser.get('firstName', '')} ${currentUser.get('lastName', '')}`;
  let browsLink = null;
  let widgetsDropDown = null;

  if (props.hasAccess) {
    browsLink = (
      <Link className="controls-search__link" target="_blank" to="browse">
        browse/query
      </Link>
    );
    widgetsDropDown = (
      <DropDown align="right" btnContent={togglerButtonText} className="btn-default btn-xs" id="toggleDashboard">
        {togglerContent}
      </DropDown>
    );
  }

  return (
    <div className="container flex-through">
      <MainHeader
        hasAccess={props.hasAccess}
        onActivityReportClick={onActivityReportClick}
        onCCVisitsReport={onCCVisitsReport}
        onCeoOutreachReport={onCeoOutreachReport}
        onConflictReportClick={onConflictReportClick}
        onLeadsAndBetterClick={onLeadsAndBetterClick}
        onLogOff={onLogOff}
        onOffersAndBetterClick={onOffersAndBetterClick}
        onOutreachReport={onOutreachReport}
        onRetainerReportClick={onRetainerReportClick}
        title={title}
        userRole={currentUser.getIn(['roles', 0])}
      />

      <div className="row controls">
        <div className="col-md-6 col-sm-8 col-xs-8 controls__search controls-search">
          <div className="input-group controls-search__input">
            <input
              className="form-control"
              name="searchText"
              onChange={onSearchChange}
              onKeyUp={handleKeyUp}
              placeholder={searchInputText}
              type="text"
              value={searchText}
            />
            <span className="input-group-btn">
              <button className="btn btn-xs btn-primary" onClick={onSearch} type="button">
                <i className="fa fa-search" /> {searchButtonText}
              </button>
            </span>
          </div>
          {browsLink}
        </div>

        <div className="col-md-6 col-sm-4 col-xs-4 controls__toggle">
          <div className="pull-right">{widgetsDropDown}</div>
        </div>
      </div>

      <div className="main-modals flexItem" role="main">
        {content}
      </div>
    </div>
  );
}

Main.propTypes = {
  controls: PropTypes.instanceOf(Map).isRequired,
  /**
   * On activity report click event handler.
   */
  onActivityReportClick: PropTypes.func.isRequired,
  onChangePD: PropTypes.func.isRequired,
  onConflictReportClick: PropTypes.func.isRequired,
  onLogOff: PropTypes.func.isRequired,
  widgets: PropTypes.instanceOf(Map).isRequired,
};

export default Main;
