import { fromJS } from 'immutable';

import { FETCHING_COMPANY_SUCCESS } from '../../../../actions/companyDetail';
import {
  UPDATE_PROJECT_INFORMATION,
  LOADED_PROJECT_SUCCESS,
} from '../../../../actions/company/buyer/addOnProject/main';

const defaultState = fromJS([]);

export default function(state = defaultState, action) {
  switch (action.type) {
    case FETCHING_COMPANY_SUCCESS:
      return fromJS(action.response.data.buyerProjects);

    case UPDATE_PROJECT_INFORMATION:
      return updateProjectName(state, action);

    case LOADED_PROJECT_SUCCESS:
      return uploadProjectNameAfterNavigation(state, action);

    default:
      return state;
  }
}

/**
 * Update project name in state by project id.
 *
 * @param {List} state Company buyers list state.
 * @param {object} action Action.
 * @param {string|null} action.id Project id (if it custom).
 * @param {string} action.field Updated field name.
 * @param {string} action.value Updated field value.
 * @returns {List} Updated state.
 */
function updateProjectName(state, { id, field, value }) {
  if (id && field === 'category') {
    const updateIndex = state.findIndex(project => project.get('id') === parseInt(id, 10));

    return state.update(updateIndex, project => project.set('category', value));
  }

  return state;
}

/**
 * Update project name after changing navigation between custom project tabs.
 *
 * @param {List} state Company buyers list state.
 * @param {object} action Action.
 * @returns {List} Updated state.
 */
function uploadProjectNameAfterNavigation(state, action) {
  const { id, category } = action.response.data;

  const updateIndex = state.findIndex(project => project.get('id') === id);

  return state.update(updateIndex, project => project.set('category', category));
}
