import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import BrowseTargets from '../containers/Browse/BrowseTargets';
import BrowseBuyers from '../containers/Browse/BrowseBuyers';
import BrowseExecutives from '../containers/Browse/BrowseExecutives';
import BrowseProjects from '../containers/Browse/BrowseProjects';

export default function BrowseRouting() {
  return (
    <Switch>
      <Route component={BrowseTargets} path="/browse/targets" />
      <Route component={BrowseBuyers} path="/browse/buyers" />
      <Route component={BrowseExecutives} path="/browse/executives" />
      <Route component={BrowseProjects} path="/browse/projects" />
      <Redirect from="/browse" to="/browse/targets" />
    </Switch>
  );
}
