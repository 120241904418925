import { fromJS, List } from 'immutable';

import {
  SHOW_PROJECT_LOADING,
  REQUEST_API_PROJECT_FAILURE,
  LOADED_PROJECT_SUCCESS,
  SAVED_PROJECT_SUCCESS,
  LOADING_PROJECT_SUGGESTION,
  LOADED_PROJECT_SUGGESTION_SUCCESS,
  RESET_ERROR_LIST,
} from '../../../../actions/company/buyer/addOnProject/main';
import {
  SHOW_USERS_LOADING,
  REQUEST_API_USERS_FAILURE,
  LOADED_USERS_SUCCESS,
  LOADING_USERS_SUGGESTION,
  SAVED_USERS_SUCCESS,
  DELETED_USER_SUCCESS,
} from '../../../../actions/company/buyer/addOnProject/suggestion';
import {
  LOADING_APPROVALS,
  LOADED_APPROVALS_FAILURE,
  LOADED_APPROVALS_SUCCESS,
  SORT_APPROVALS,
} from '../../../../actions/company/buyer/addOnProject/approval';
import {
  LOADING_TEMPLATES,
  LOADED_TEMPLATES_FAILURE,
  LOADED_TEMPLATES_SUCCESS,
} from '../../../../actions/company/buyer/addOnProject/template';
import {
  LOADING_TARGETS,
  LOADED_TARGETS_FAILURE,
  LOADED_TARGETS_SUCCESS,
  SORT_TARGETS,
} from '../../../../actions/company/buyer/addOnProject/target';
import getErrorResponse from '../../../../utils/getErrorResponse';

const defaultState = fromJS({
  projectLoading: false,
  userLoading: false,
  approvalLoading: false,
  templateLoading: false,
  targetLoading: false,
  userSuggestions: [],
  execSuggestions: [],
  harvcoLeadSuggestions: [],
  clientLeadSuggestions: [],
  userSuggestionRequestId: '',
  projectRequestId: '',
  targetSortField: '',
  targetSortDirection: '',
  approvalSortDirection: '',
  approvalSortField: '',
  errors: [''],
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case SHOW_PROJECT_LOADING:
      return state.set('projectLoading', true);

    case SHOW_USERS_LOADING:
      return state.set('userLoading', true);

    case LOADING_APPROVALS:
      return state.set('approvalLoading', true);

    case LOADING_TEMPLATES:
      return state.set('templateLoading', true);

    case LOADING_TARGETS:
      return state.set('targetLoading', true);

    case REQUEST_API_PROJECT_FAILURE:
      return state.merge({
        projectLoading: false,
        errors: state.get('errors').concat(getErrorResponse(action.response)),
      });

    case REQUEST_API_USERS_FAILURE:
      return state.merge({
        userLoading: false,
        errors: state.get('errors').concat(getErrorResponse(action.response)),
      });

    case LOADED_APPROVALS_FAILURE:
      return state.merge({
        approvalLoading: false,
        errors: state.get('errors').concat(getErrorResponse(action.response)),
      });

    case LOADED_TEMPLATES_FAILURE:
      return state.merge({
        templateLoading: false,
        errors: state.get('errors').concat(getErrorResponse(action.response)),
      });

    case LOADED_TARGETS_FAILURE:
      return state.merge({
        targetLoading: false,
        errors: state.get('errors').concat(getErrorResponse(action.response)),
      });

    case DELETED_USER_SUCCESS:
      return state.set('userLoading', false);

    case SAVED_PROJECT_SUCCESS:

    // fallthrough
    case LOADED_PROJECT_SUCCESS:
      return state.set('projectLoading', false);

    case SAVED_USERS_SUCCESS:

    // fallthrough
    case LOADED_USERS_SUCCESS:
      return state.set('userLoading', false);

    case LOADED_APPROVALS_SUCCESS:
      return state.set('approvalLoading', false);

    case LOADED_TEMPLATES_SUCCESS:
      return state.set('templateLoading', false);

    case LOADED_TARGETS_SUCCESS:
      return state.set('targetLoading', false);

    case LOADING_USERS_SUGGESTION:
      return state.set('userSuggestionRequestId', action.requestId);

    case LOADING_PROJECT_SUGGESTION:
      return state.merge({
        projectRequestId: action.requestId,
        [`${action.field}Suggestion`]: List(),
      });

    case LOADED_PROJECT_SUGGESTION_SUCCESS:
      return loadProjectSuggestions(state, action);

    case SORT_TARGETS:
      return state.merge({
        targetSortField: action.field,
        targetSortDirection: action.direction,
      });

    case SORT_APPROVALS:
      return state.merge({
        approvalSortField: action.field,
        approvalSortDirection: action.direction,
      });

    case RESET_ERROR_LIST:
      return state.set('errors', List());

    default:
      return state;
  }
}

/**
 * Load suggestion list.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action.
 * @return {Immutable.Map}
 */
function loadProjectSuggestions(state, action) {
  if (action.response.status === 'success' && state.get('projectRequestId') === action.requestId) {
    return state.set(
      `${action.field}Suggestions`,
      fromJS(
        action.response.data.map(s => ({
          id: s.id,
          name: s.text,
        })),
      ),
    );
  }

  return state;
}
