import PropTypes from 'prop-types';

import React from 'react';
import { List } from 'immutable';

import ResultGrid from './ResultGrid';
import CompanyResultRow from './CompanyResultRow';
import PeopleResultRow from './PeopleResultRow';

/**
 * Stateless component for search result table.
 *
 * @param props {Object}.
 * @param props.people {Immutable.List} List of person returned in api request.
 * @param props.companies {Immutable.List} List of target returned in api request.
 * @param props.searchingPeople {Boolean} Is loading person list.
 * @param props.searchingCompanies {Boolean} Is loading buyer list.
 * @param props.activeTab {String} Name of the current active tab.
 * @returns {React.Component}
 * @class
 */
const SearchResultTable = props => (
  <div className="tab-content flexItem scrollable" onScroll={props.onScroll}>
    <ResultGrid id="companyGrid" isActive={props.activeTab === 'companies'} loading={props.searchingCompanies}>
      {props.companies.map((t, i) => (
        <CompanyResultRow key={i} company={t} hasAccess={props.hasAccess} />
      ))}
    </ResultGrid>
    <ResultGrid id="peopleGrid" isActive={props.activeTab === 'people'} loading={props.searchingPeople}>
      {props.people.map((p, i) => (
        <PeopleResultRow key={i} person={p} />
      ))}
    </ResultGrid>
  </div>
);

SearchResultTable.propTypes = {
  activeTab: PropTypes.string.isRequired,
  companies: PropTypes.instanceOf(List).isRequired,
  people: PropTypes.instanceOf(List).isRequired,
  searchingCompanies: PropTypes.bool.isRequired,
  searchingPeople: PropTypes.bool.isRequired,
};

export default SearchResultTable;
