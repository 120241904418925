import validator from 'is-my-json-valid';

const WHITE_LIST = [
  'is required',
  //  'is the wrong type',
  //  'has additional items',
  //  'must be FORMAT format (FORMAT is the format property from the schema)',
  //  'must be unique',
  //  'must be an enum value',
  //  'dependencies not set',
  //  'has additional properties',
  //  'referenced schema does not match',
  //  'negative schema matches',
  //  'pattern mismatch',
  //  'no schemas match',
  //  'no (or more than one) schemas match',
  //  'has a remainder',
  'has more properties than allowed',
  'has less properties than allowed',
  'has more items than allowed',
  'has less items than allowed',
  'has longer length than allowed',
  'has less length than allowed',
  'is less than minimum',
  'is more than maximum',
];

export default function getValidator(...schema) {
  const checkValidity = validator(...schema, {
    verbose: process.env.NODE_ENV !== 'production',
  });
  const _schema = schema[0];

  const map = new WeakMap();

  function isValid(data) {
    if (map.has(data)) return map.get(data);

    const valid = checkValidity(data);

    map.set(data, valid);

    return valid;
  }

  function getErrors(data) {
    if (isValid(data)) return [];

    return checkValidity.errors.map(error => {
      error.field = error.field.replace(/^data\./, '');

      return error;
    });
  }

  function getErrorsWithDesc(data) {
    const errors = getErrors(data);

    return errors.reduce((res, error) => {
      const target = getTarget(_schema, error.field);

      res[target] = res[target] || '';

      let { message } = error;

      if (WHITE_LIST.indexOf(message) === -1) {
        message = getDescription(_schema, error.field);
      }

      if (!res[target].includes(message)) {
        res[target] = `${res[target]}\n${getTitle(_schema, error.field)} ${message}.`;
      }

      return res;
    }, {});
  }

  function getFirstError(data) {
    if (isValid(data)) return {};

    const errors = getErrorsWithDesc(data);
    const errorName = Object.keys(errors)[0];

    return { [errorName]: errors[errorName] };
  }

  function getFormError(data) {
    if (isValid(data)) return [];

    const errors = getErrorsWithDesc(data);

    return Object.keys(errors).reduce((ret, key) => {
      ret.push(errors[key].trim());

      return ret;
    }, []);
  }

  return {
    isValid,
    getFormError,
    getRawErrors: getErrors,
    getErrors: getErrorsWithDesc,
    getErrorsWithDesc,
    getFirstError,
  };
}

function getTitle(schema, field) {
  const target = getDescriptor(schema, field);

  return (target && target.title) || 'This field';
}

function getDescription(schema, field) {
  const target = getDescriptor(schema, field);

  return (target && target.description) || 'has some problem';
}

function getTarget(schema, field) {
  const target = getDescriptor(schema, field);

  if (target && target.errorTo) {
    const fieldPath = field.split('.');

    return target.errorTo
      .split('.')
      .map((pathPart, i) => (pathPart === '#' ? fieldPath[i] : pathPart))
      .join('.');
  }

  return field;
}

function getDescriptor(schema, field) {
  return field.split('.').reduce((obj, propName) => {
    if (!obj) return obj;
    if (obj.type === 'array') return obj.items;

    return obj && obj.properties && obj.properties[propName];
  }, schema);
}
