import PropTypes from 'prop-types';

import React from 'react';
import { ContextMenu, MenuItem } from 'react-contextmenu';

/**
 * Project context menu.
 *
 * @param props
 * @param props.id {String} Menu id.
 * @param props.isTemplate {Boolean(default=false)} If set to true will show rename and download menu items.
 * @param props.onDelete {Function} Handle menu item's event click.
 * @param props.onDownload {Function} To handle downloading file.
 * @class
 */
const ProjectMenu = props => {
  const { id, isTemplate = false, onDelete, onDownload } = props;
  const Download = (
    <MenuItem onClick={onDownload}>
      <i aria-hidden="true" className="fa fa-download" />
      Download
    </MenuItem>
  );

  const templateItems = isTemplate ? Download : null;

  return (
    <ContextMenu id={id}>
      {templateItems}
      <MenuItem onClick={onDelete}>
        <i aria-hidden="true" className="fa fa-trash" />
        Delete
      </MenuItem>
    </ContextMenu>
  );
};

ProjectMenu.propTypes = {
  id: PropTypes.string.isRequired,
  isTemplate: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onDownload: PropTypes.func,
};

export default ProjectMenu;
