import { fromJS, List, Map, Set } from 'immutable';
import moment from 'moment';

import * as ActionType from '../actions/userManage';
import config from '../config';
import getErrorResponse from '../utils/getErrorResponse';

/** Initial state for user manage page */
const defaultState = fromJS({
  users: [],
  hideInactive: false,
  selectedUser: {},
  formMode: 'view',
  userLoading: false,
  formError: {
    userName: '',
    module: '',
    email: '',
    rePassword: '',
  },
  roles: [],
  saveStatus: 'enabled',
  moduleSuggestionMode: config.VIEW_MODE,
  supervisorSuggestionMode: config.VIEW_MODE,
  suggestions: [],
  errors: [],
  reports: [],
  isMailingBacklog: false,
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case ActionType.FETCHING_USERS:
      return state.merge({
        userLoading: true,
        users: List(),
        errors: [],
      });

    case ActionType.FETCHED_USERS_SUCCESS:
      return state.merge({
        users: getUsersFromResponse(state, action),
        userLoading: false,
        errors: [],
      });

    case ActionType.FETCHED_USERS_FAILURE:
      return state.merge({
        userLoading: false,
        errors: ['Error in loading users'],
      });

    case ActionType.TOGGLE_SHOWING_INACTIVE_USER:
      return state.set('hideInactive', !state.get('hideInactive'));

    case ActionType.SELECT_USER:
      return validateSelectedUser(selectUser(state, action));

    case ActionType.CLEAR_SELECTED_USER:
      return validateSelectedUser(state.merge({ selectedUser: {}, errors: [] }));

    case ActionType.UPDATE_SELECTED_USER:
      return validateSelectedUser(updateSelectedUser(state, action));

    case ActionType.CHANGE_MODE:
      return validateSelectedUser(
        state.merge({
          formMode: action.mode,
          selectedUser: action.mode === 'view' ? defaultState.get('selectedUser') : state.get('selectedUser'),
          errors: [],
        }),
      );

    case ActionType.FETCHED_ROLES_SUCCESS:
      return state.set('roles', getRoles(action.response));

    case ActionType.SAVING_USER:
      return state.merge({
        saveStatus: 'saving',
        errors: [],
      });

    case ActionType.SAVED_USER_FAILURE:
      return state.merge({
        saveStatus: 'enabled',
        errors: getErrorResponse(action.response),
      });

    case ActionType.SAVED_USER_SUCCESS:
      return state.merge({
        saveStatus: 'enabled',
        errors: [],
      });

    case ActionType.UPDATE_MODULE_FIELD:
      return validateSelectedUser(
        state.mergeDeep(
          fromJS({
            selectedUser: { module: action.module, moduleId: action.moduleId },
            moduleSuggestionMode: config.VIEW_MODE,
            supervisorSuggestionMode: config.VIEW_MODE,
          }),
        ),
      );

    case ActionType.UPDATE_SUPERVISOR_FIELD:
      return validateSelectedUser(
        state.mergeDeep(
          fromJS({
            selectedUser: {
              supervisor: action.supervisor,
              supervisorId: action.supervisorId,
            },
            moduleSuggestionMode: config.VIEW_MODE,
            supervisorSuggestionMode: config.VIEW_MODE,
          }),
        ),
      );

    case ActionType.UPDATE_LOGIN_ATTEMPTS:
      return updateLoginAttempts(state, action.checked);

    case ActionType.CLOSE_SUGGESTION:
      return validateSelectedUser(resetModuleSupervisor(state));

    case ActionType.FETCHED_REPORT_SUCCESS:
      return state.set('reports', fromJS(action.response.data));

    default:
      return state;
  }
}

/**
 * Lookup and get a user.
 *
 * @param state {Immutable.Map} Sate.
 * @param action {Object} Action.
 * @returns {Immutable.List}
 */
function selectUser(state, action) {
  const users = state.get('users');
  const index = users.findKey(u => u.get('id') === action.id);

  if (index !== undefined && index >= 0) {
    return state.merge({ selectedUser: users.get(index), errors: [] });
  }

  return state;
}

/**
 * Get users in response.
 *
 * @param state {Immutable.Map} Sate.
 * @param action {Object} Action.
 * @returns {Immutable.List}
 */
function getUsersFromResponse(state, action) {
  if (action.response.status === 'success') {
    return lookupUserList(action.response.data);
  }

  return state.get('users');
}

/**
 * Lookup and sort user list.
 *
 * @param {Immutable.List} users List of user.
 * @return {Immutable.List}
 */
function lookupUserList(users) {
  return sortByUserName(users.map(mapUser));
}

/**
 * Map returned user to processed user.
 *
 * @param {object} user Returned user.
 * @return {Immutable.Map} Mapped user with comfortable data.
 */
function mapUser(user) {
  if (!user) return;

  let role = '';
  let roleName = '';

  if (user.roles.length) {
    roleName = user.roles[0].name;
    role = user.roles[0].slug;
  }

  const fullName = `${user.lastName}, ${user.firstName}`;
  const supervisor = user.supervisor || '';
  const supervisorId = user.supervisorId || 0;
  const module = user.module || '';
  const moduleId = user.moduleId || 0;
  const reportIds = Set(user.reports.map(r => r.id));
  const hireDate = user.hireDate && moment(user.hireDate);
  const endDate = user.endDate && moment(user.endDate);
  const browseAccesses = Set(user.browseAccesses.map(item => item.id));

  return Map({
    id: user.id,
    userName: user.userName,
    officePhoneExt: user.officePhoneExt,
    officePhone: user.officePhone,
    cellPhone: user.cellPhone,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    inactive: user.inactive,
    fullName,
    supervisor,
    supervisorId,
    module,
    moduleId,
    roleName,
    role,
    reportIds,
    isMailingBacklog: user.isMailingBacklog,
    hireDate,
    endDate,
    title: user.title,
    browseAccesses,
  });
}

/**
 * Sort users list by userName.
 *
 * @param {Immutable.List} users List of user.
 * @return {Immutable.List} Sorted users list.
 */
function sortByUserName(users) {
  return users.sort((a, b) => a.get('userName').localeCompare(b.get('userName')));
}

/**
 * Get list of roles from response.
 *
 * @return {Immutable.List} Roles.
 */
function getRoles(response) {
  if (response.status === 'success') {
    return fromJS(response.data);
  }

  return List();
}

/**
 * Validate selected user.
 *
 * @param {object} state Current state.
 * @return New state.
 */
function validateSelectedUser(state) {
  const { id, userName, module, password, role, rePassword } = state.get('selectedUser').toJS();

  // Field validators
  const isPasswordsNotMatch = password !== rePassword;

  // Validation messages
  const userNameMessage = !userName ? 'Please input user name' : '';
  const moduleMessage = !module ? 'Please input module' : '';
  const passwordMessage = !id && !password ? 'Please input password' : '';
  const roleMessage = !role ? 'Please select a role' : '';
  const rePasswordMessage = isPasswordsNotMatch ? "Password doesn't match" : '';

  // Result error
  const formError = Map({
    userName: userNameMessage,
    module: moduleMessage,
    password: passwordMessage,
    role: roleMessage,
    rePassword: rePasswordMessage,
  });

  // Error status
  const saveStatus = formError.filter(error => error.length > 0).size > 0 ? 'error' : 'enabled';

  return state.merge({
    formError,
    saveStatus,
  });
}

/**
 * Validate selected user.
 *
 * @param state {Object} State.
 * @param checked {Boolean} Checkbox state.
 * @returns New state.
 */
function updateLoginAttempts(state, checked) {
  if (checked) {
    return state.setIn(['selectedUser', 'incorrectLoginAttempts'], 0);
  }

  return state.set('selectedUser', state.get('selectedUser').delete('incorrectLoginAttempts'));
}

/**
 * Get suggestion list of user.
 *
 * @param users {Immutable.List} List of user.
 * @param value {String} Checkbox state.
 * @returns {Immutable.List}
 */
function getSuggestions(users, value, inRoles = null) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return users
    .filter(s => {
      if (inRoles) {
        return (
          s
            .get('userName')
            .toLowerCase()
            .slice(0, inputLength) === inputValue && inRoles.indexOf(s.get('role')) >= 0
        );
      }

      return (
        s
          .get('userName')
          .toLowerCase()
          .slice(0, inputLength) === inputValue
      );
    })
    .map(user => Map({ id: user.get('id'), name: user.get('userName') }));
}

/**
 * Validate selected user.
 *
 * @param state {Object} State.
 * @param checked {Boolean} Checkbox state.
 * @returns New state.
 */
function updateSelectedUser(state, action) {
  const newState = state.merge({
    suggestions: [],
    errors: [],
  });

  if (action.name === 'module') {
    return updateSelectedUserModule(newState, action.value);
  }

  if (action.name === 'supervisor') {
    return updateSelectedUserSupervisor(newState, action.value);
  }

  if (action.name === 'reports') {
    let reports = state.getIn(['selectedUser', 'reportIds'], Set());

    reports = action.checked ? reports.add(parseInt(action.value, 10)) : reports.remove(parseInt(action.value, 10));

    return newState
      .mergeDeep({
        supervisorSuggestionMode: config.VIEW_MODE,
        moduleSuggestionMode: config.VIEW_MODE,
      })
      .setIn(['selectedUser', 'reportIds'], reports);
  }

  if (action.name === 'accesses') {
    let accesses = state.getIn(['selectedUser', 'browseAccesses'], Set());

    accesses = action.checked ? accesses.add(parseInt(action.value, 10)) : accesses.remove(parseInt(action.value, 10));

    return newState
      .mergeDeep({
        supervisorSuggestionMode: config.VIEW_MODE,
        moduleSuggestionMode: config.VIEW_MODE,
      })
      .setIn(['selectedUser', 'browseAccesses'], accesses);
  }

  if (action.name === 'role') {
    let keys = [];
    let accesses = new Set();

    if ([config.DIRECTOR, config.FINANCE_ADMIN, config.ADMINISTRATOR].includes(action.value)) {
      keys = config.browse.get('accesses').map(item => parseInt(item.get('key'), 10));
    } else if (
      [config.RESEARCH_INTERN, config.JUNIOR_ANALYST, config.ANALYST_ASSOCIATE, config.PROCESS_MANAGER].includes(
        action.value,
      )
    ) {
      keys = config.browse
        .get('accesses')
        .filter(item => item.get('name') !== 'buyers')
        .map(item => parseInt(item.get('key'), 10));
    }
    accesses = accesses.union(keys);

    return newState
      .mergeDeep({
        supervisorSuggestionMode: config.VIEW_MODE,
        moduleSuggestionMode: config.VIEW_MODE,
      })
      .setIn(['selectedUser', 'role'], action.value)
      .setIn(['selectedUser', 'browseAccesses'], accesses);
  }

  return newState.mergeDeep({
    supervisorSuggestionMode: config.VIEW_MODE,
    moduleSuggestionMode: config.VIEW_MODE,
    selectedUser: { [action.name]: action.value },
  });
}

/**
 * Update module field of selected user.
 *
 * @param state {Object} State.
 * @returns {Immutable.Map}
 */
function updateSelectedUserModule(state, value) {
  return state.mergeDeep({
    moduleSuggestionMode: value ? config.SUGGEST_MODE : config.VIEW_MODE,
    supervisorSuggestionMode: config.VIEW_MODE,
    suggestions: getSuggestions(state.get('users'), value),
    selectedUser: {
      module: value,
      moduleId: value ? state.getIn(['selectedUser', 'moduleId']) : null,
    },
  });
}

/**
 * Update supervisor field of selected user.
 *
 * @param state {Object} State.
 * @returns {Immutable.Map}
 */
function updateSelectedUserSupervisor(state, value) {
  return state.mergeDeep({
    supervisorSuggestionMode: value ? config.SUGGEST_MODE : config.VIEW_MODE,
    moduleSuggestionMode: config.VIEW_MODE,
    suggestions: getSupervisor(state.get('users'), value),
    selectedUser: {
      supervisor: value,
      supervisorId: value ? state.getIn(['selectedUser', 'supervisorId']) : null,
    },
  });
}

/**
 * If users don't select any suggestion, reset module and supervisor to previous value.
 *
 * @param state {Object} State.
 * @returns {Immutable.Map}
 */
function resetModuleSupervisor(state) {
  const users = state.get('users');
  const moduleId = state.getIn(['selectedUser', 'moduleId']);
  const supervisorId = state.getIn(['selectedUser', 'supervisorId']);
  const lookedModule = lookupUser(users, moduleId);
  const lookedSupervisor = lookupUser(users, supervisorId);

  return state.mergeDeep({
    selectedUser: {
      module: lookedModule ? lookedModule.get('userName') : '',
      supervisor: lookedSupervisor ? lookedSupervisor.get('userName') : '',
    },
    supervisorSuggestionMode: config.VIEW_MODE,
    moduleSuggestionMode: config.VIEW_MODE,
  });
}

/**
 * Lookup user.
 *
 * @param users {Immutable.List} List of users.
 * @param userId {Number} User's id.
 * @returns {Immutable.Map}
 */
function lookupUser(users, userId) {
  if (userId) {
    return users.find(user => user.get('id') === userId);
  }

  return null;
}

/**
 * A supervisor can be all user roles except Research Intern and Junior Analyst.
 */
function getSupervisor(users, value = '') {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return users
    .filter(
      s =>
        s
          .get('userName')
          .toLowerCase()
          .slice(0, inputLength) === inputValue &&
        s.get('role') !== config.RESEARCH_INTERN &&
        s.get('role') !== config.JUNIOR_ANALYST,
    )
    .map(user => Map({ id: user.get('id'), name: user.get('userName') }));
}
