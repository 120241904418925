import { fromJS } from 'immutable';

import * as ActionType from '../actions/verifyEmail';

const defaultState = fromJS({
  loading: false,
  status: '',
  inputErrors: {},
});

export default function verifyEmail(state = defaultState, action) {
  switch (action.type) {
    case ActionType.FETCHING_VERIFY_EMAIL:
      return state.merge({
        loading: true,
      });

    case ActionType.FETCHING_VERIFY_EMAIL_FAILURE:
      return state.merge({
        status: 'invalid',
        loading: false,
      });

    case ActionType.FETCHING_VERIFY_EMAIL_SUCCESS:
      return state.merge({
        inputErrors: {},
        status: action.response.data.status,
        loading: false,
      });

    case ActionType.CLEAR_VERIFIED_EMAIL:
      return state.merge({
        status: '',
        inputErrors: {},
        loading: false,
      });

    default:
      return state;
  }
}
