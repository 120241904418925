import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { toJS } from 'src/utils/ChangeSpy';

const CompaniesTable = ({ data }) => {
  const targetsStats = toJS(data.get('targetsStats'));
  const buyersStats = toJS(data.get('buyersStats'));
  const executivesStats = toJS(data.get('executivesStats'));

  return (
    <div className="dashboard-table-wrapper mb20">
      <table className="dashboard-table table-striped-white dashboard-companies">
        <thead>
          <tr className="bg-black text-white">
            <th className="text-center p12" colSpan="2">
              <p className="h3 text-bold">{targetsStats.count}</p>
              <p className="h5 text-bold">Target Companies</p>
            </th>
            <th className="text-center p12 border-left border-right" colSpan="2">
              <p className="h3 text-bold">{buyersStats.count}</p>
              <p className="h5 text-bold">Buyers</p>
            </th>
            <th className="text-center p12" colSpan="2">
              <p className="h3 text-bold">{executivesStats.count}</p>
              <p className="h5 text-bold">Executives</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="h5 p10">
              <p className="mb0">Approachable Targets</p>
              <p className="stats-description">(Targets with &gt;= 1.5 Current Status)</p>
            </td>
            <td className="h5 p10 text-bold">{targetsStats.countApproachableTargets}</td>
            <td className="h5 p10 border-left">
              <p className="mb0">Buyers Approached</p>
              <p className="stats-description">(Buyers with &gt;= 4.0 High Status)</p>
            </td>
            <td className="h5 p10 border-right text-bold">{buyersStats.countBuyersApproached}</td>
            <td className="h5 p10">
              <p className="mb0">Profiled Executives</p>
              <p className="stats-description">(Executives with &gt;= 1.5 Current Status)</p>
            </td>
            <td className="h5 p10 text-bold">{executivesStats.countProfiledExecutives}</td>
          </tr>
          <tr>
            <td className="h5 p10">
              <p className="mb0">Approached Targets</p>
              <p className="stats-description">(Targets with &gt;= 4.0 High Status)</p>
            </td>
            <td className="h5 p10 text-bold">{targetsStats.countApproachedTargets}</td>
            <td className="h5 p10 border-left">
              <p className="mb0">Agreements Signed</p>
              <p className="stats-description">(Buyers with &gt;= 9.0 High Status)</p>
            </td>
            <td className="h5 p10 border-right text-bold">{buyersStats.countAgreementsSigned}</td>
            <td className="h5 p10">
              <p className="mb0">Executives Approached</p>
              <p className="stats-description">(Executives with &gt;= 4.0 High Status)</p>
            </td>
            <td className="h5 p10 text-bold">{executivesStats.countExecutivesApproached}</td>
          </tr>
          <tr>
            <td className="h5 p10">
              <p className="mb0">Leads Generated</p>
              <p className="stats-description">(Targets with &gt;= 5.0 High Status)</p>
            </td>
            <td className="h5 p10 text-bold">{targetsStats.countLeadsGenerated}</td>
            <td className="h5 p10 border-left">
              <p className="mb0">All-Time Retainer Clients</p>
              <p className="stats-description">(Buyers with = 10.0 High Status)</p>
            </td>
            <td className="h5 p10 border-right text-bold">{buyersStats.countAllTimeRetainerClients}</td>
            <td className="h5 p10">
              <p className="mb0">Executives Leads</p>
              <p className="stats-description">(Executives with &gt;= 5.0 High Status)</p>
            </td>
            <td className="h5 p10 text-bold">{executivesStats.countExecutiveLeads}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

CompaniesTable.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
};

export default CompaniesTable;
