import { fromJS, List } from 'immutable';

import {
  LOADED_USERS_SUCCESS,
  INSERT_USER_ROW,
  CHANGE_USER_ROW_MODE,
  CHANGE_USER_ROW_TEXT,
  DELETED_USER_ROW_SUCCESS,
  LOADING_USERS_SUGGESTION,
  LOADED_USERS_SUGGESTIONS_SUCCESS,
  SELECT_USER_SUGGESTION,
  SAVED_USERS_SUCCESS,
} from '../../actions/project/user';
import { INSERT_INDUSTRY_ROW, CHANGE_INDUSTRY_ROW_MODE } from '../../actions/project/industry';
import { RESET_ALL_ROW_TO_VIEW_MODE } from '../../actions/project/main';
import { INSERT_APPROVAL_ROW, CHANGE_APPROVAL_ROW_MODE } from '../../actions/project/approval';
import config from '../../config';
import { switchToMode, addEmptyRow, changeText, switchToView, saveSuggestionToRow, clearDirty } from './common';

const defaultState = fromJS([]);

export default function(state = defaultState, action, common) {
  switch (action.type) {
    case LOADED_USERS_SUCCESS:
      return getList(action.response, 'users');

    case INSERT_USER_ROW:
      return addEmptyRow(state);

    case CHANGE_USER_ROW_MODE:
      return switchToMode(state, action.id, action.mode);

    case CHANGE_USER_ROW_TEXT:
      return changeText(state, action.id, action.text);

    case DELETED_USER_ROW_SUCCESS:
      return state;

    case RESET_ALL_ROW_TO_VIEW_MODE:

    // fallthrough
    case INSERT_APPROVAL_ROW:

    // fallthrough
    case INSERT_INDUSTRY_ROW:

    // fallthrough
    case CHANGE_APPROVAL_ROW_MODE:

    // fallthrough
    case CHANGE_INDUSTRY_ROW_MODE:
      return switchToView(state);

    case LOADING_USERS_SUGGESTION:
      return switchToMode(state, action.id, config.LOADING_MODE);

    case LOADED_USERS_SUGGESTIONS_SUCCESS:
      return switchToSuggest(state, action, common);

    case SELECT_USER_SUGGESTION:
      return saveSuggestionToRow(state, action);

    case SAVED_USERS_SUCCESS:
      return clearDirty(state);

    default:
      return state;
  }
}

/**
 * Get a list from response.
 *
 * @param response {Object} Response.
 * @returns {Immutable.List}
 */
function getList(response) {
  if (response.status === 'success') {
    return fromJS(
      response.data.users.map(u => ({
        id: u.id,
        name: u.userName,
        origin: u.userName,
        mode: config.VIEW_MODE,
        dirty: false,
      })),
    );
  }

  return List();
}

/**
 * Switch a row to suggest mode, check if request id matches before switching.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action.
 * @param common {Immutable.Map} Common state.
 * @returns {Immutable.List}
 */
function switchToSuggest(state, action, common) {
  if (action.response.status === 'success' && action.requestId === common.get('userSuggestionRequestId')) {
    return switchToMode(state, action.id, config.SUGGEST_MODE);
  }

  return state;
}
