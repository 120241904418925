import Immutable from 'immutable';
import moment from 'moment';

import { defaultNumber } from '../../utils/string';
import { spy } from '../../utils/ChangeSpy';
import { LOADED_APPROVALS_SUCCESS } from '../../actions/approval/main';
import percent from '../../helpers/percent';

const defaultState = Immutable.fromJS([]);

export default function(state = defaultState, action) {
  switch (action.type) {
    case LOADED_APPROVALS_SUCCESS:
      return getApproval(state, action);

    default:
      return state;
  }
}

/**
 * Get approval list data from api response.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action object.
 * @returns {Immutable.Map}
 */
function getApproval(state, action) {
  const res = action.response;

  if (res.status === 'success') {
    return Immutable.List(res.data.approvalLists.map(mapApproval));
  }

  return state;
}

/**
 * Map approval from api return to approval entity.
 *
 * @param ap {Object} Approval object.
 * @returns {Immutable.Map}
 */
export function mapApproval(ap) {
  const dateSent = moment(ap.dateSent);
  const dateReceived = moment(ap.dateReceived);
  const numberOfTargets = defaultNumber(ap.numberOfTargets);
  const numberOfApprovedTargets = defaultNumber(ap.numberOfApprovedTargets);
  const numberOfMailedTargets = defaultNumber(ap.numberOfMailedTargets);
  const numberOfCalledTargets = defaultNumber(ap.numberOfCalledTargets);

  return Immutable.Map(
    spy(
      {
        id: ap.id,
        label: ap.applistLabel,
        dateSent: dateSent.isValid() ? dateSent : null,
        dateReturned: dateReceived.isValid() ? dateReceived : null,
        numberTargets: numberOfTargets,
        numberApproved: numberOfApprovedTargets,
        percentApproved: percent(numberOfApprovedTargets, numberOfTargets),
        numberMailed: numberOfMailedTargets,
        percentMailed: percent(numberOfMailedTargets, numberOfTargets),
        numberCalled: numberOfCalledTargets,
        percentCalled: percent(numberOfCalledTargets, numberOfTargets),
      },
      ['dateSent', 'dateReturned'],
    ),
  );
}

/**
 * Map no approval from api return to no approval entity.
 *
 * @param {object} ap Approval object.
 * @return {Immutable.Map} No approval entity.
 */
export function mapNoApproval(ap) {
  const numberOfTargets = defaultNumber(ap.numberOfTargets);
  const numberOfApprovedTargets = defaultNumber(ap.numberOfApprovedTargets);
  const numberOfMailedTargets = defaultNumber(ap.numberOfMailedTargets);
  const numberOfCalledTargets = defaultNumber(ap.numberOfCalledTargets);

  return Immutable.Map(
    spy({
      numberTargets: numberOfTargets,
      numberApproved: numberOfApprovedTargets,
      percentApproved: percent(numberOfApprovedTargets, numberOfTargets),
      numberMailed: numberOfMailedTargets,
      percentMailed: percent(numberOfMailedTargets, numberOfTargets),
      numberCalled: numberOfCalledTargets,
      percentCalled: percent(numberOfCalledTargets, numberOfTargets),
    }),
  );
}
