import { CALL_API } from '../middleware/api';

export const LOADED_WIDGETS = Symbol('LOADED_WIDGETS');
export const LOADING_WIDGET_DATA = Symbol('LOADING_WIDGET_DATA');
export const LOADED_WIDGET_DATA = Symbol('LOADED_WIDGET_DATA');
export const UPDATE_WIDGETS_SUCCESS = Symbol('UPDATE_WIDGETS_SUCCESS');
export const SORT_WIDGET_DATA = Symbol('SORT_WIDGET_DATA');
export const START_DRAG = Symbol('START_DRAG');
export const DRAG = Symbol('DRAG');
export const STOP_DRAG = Symbol('STOP_DRAG');
export const CHANGE_SEARCH_TEXT = Symbol('CHANGE_SEARCH_TEXT');
export const TRIGGER_WIDGET_VISIBILITY = Symbol('TRIGGER_WIDGET_VISIBILITY');
export const MOVE_WIDGET_TO_TOP = Symbol('MOVE_WIDGET_TO_TOP');
export const CHANGE_WIDGET_SIZES = Symbol('CHANGE_WIDGET_SIZES');
export const CHANGE_FIELD = Symbol('CHANGE_MAIN_FIELD');
export const DELETE_CONFIRMATION = Symbol('DELETE_CONFIRMATION');
export const DELETE_CONFIRMATION_SUCCESS = Symbol('DELETE_CONFIRMATION_SUCCESS');
export const DELETE_CONFIRMATION_FAILURE = Symbol('DELETE_CONFIRMATION_FAILURE');
export const SELECTED_IDS = Symbol('SELECTED_IDS');
export const SELECTED_ALL_IDS = Symbol('SELECTED_ALL_IDS');
export const RESET_ALL_IDS = Symbol('RESET_ALL_IDS');

export function sortWidgetData({ widget, order }) {
  return {
    type: SORT_WIDGET_DATA,
    widget,
    order,
  };
}

export function loadWidgets({ userId }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/users/${userId}/dashprefs`,
      successType: LOADED_WIDGETS,
    },
  };
}

export function loadWidgetData({ name, query = {} }) {
  return {
    name,
    [CALL_API]: {
      method: 'get',
      query,
      path: `/api/v1/users/dashboard/${name}`,
      startType: LOADING_WIDGET_DATA,
      successType: LOADED_WIDGET_DATA,
    },
  };
}

export function saveWidgets({ userId, widgets }) {
  return {
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/users/${userId}`,
      body: { dashprefs: widgets },
      unifier: `put /api/v1/users/${userId}/dashboards`,
      maxCount: 2,
      successType: UPDATE_WIDGETS_SUCCESS,
    },
  };
}

export function startDrag({ name, x, y }) {
  return {
    type: START_DRAG,
    name,
    x,
    y,
  };
}

export function drag({ x, y }) {
  return {
    type: DRAG,
    x,
    y,
  };
}

export function stopDrag({ name, x, y }) {
  return {
    type: STOP_DRAG,
    name,
    x,
    y,
  };
}

export function setWidgetSizes({ name, height, width }) {
  return {
    type: CHANGE_WIDGET_SIZES,
    name,
    height,
    width,
  };
}

export function changeSearchText(text) {
  return {
    type: CHANGE_SEARCH_TEXT,
    text,
  };
}

export function triggerWidgetVisibility({ name }) {
  return {
    type: TRIGGER_WIDGET_VISIBILITY,
    name,
  };
}

export function moveWidgetToTop({ name }) {
  return {
    type: MOVE_WIDGET_TO_TOP,
    name,
  };
}

export function changeField({ field, value }) {
  return {
    type: CHANGE_FIELD,
    value,
    field,
  };
}

export function confirmOrRejectDeletion(action, selectedIds = [], afterSuccess = () => {}) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/browse/targets/confirm_or_reject_deletion',
      body: {
        params: {
          action,
          selected_ids: selectedIds,
        },
      },
      startType: DELETE_CONFIRMATION,
      errorType: DELETE_CONFIRMATION_FAILURE,
      successType: DELETE_CONFIRMATION_SUCCESS,
      afterSuccess,
    },
  };
}

export function selectIds({ order, name }) {
  return {
    type: SELECTED_IDS,
    order,
    name,
  };
}

export function selectAllIds({ name }) {
  return {
    type: SELECTED_ALL_IDS,
    name,
  };
}

export function resetAllIds({ name }) {
  return {
    type: RESET_ALL_IDS,
    name,
  };
}
