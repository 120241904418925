export const countAddonKey = 'Add-Ons';
export const countPlatformKey = 'Platform';

export const defaultPieConfig = {
  colors: ['rgb(83, 140, 194)', 'rgb(237, 173, 24)'],
  margin: { left: 10, bottom: 50 },
  enableArcLinkLabels: false,
  arcLabelsSkipAngle: 16,
  legends: [
    {
      anchor: 'bottom-left',
      direction: 'row',
      itemsSpacing: 30,
      itemWidth: 100,
      itemHeight: 20,
      symbolSize: 14,
      symbolShape: 'circle',
      text: {
        fontSize: 14,
      },
      translateY: 40,
    },
  ],
  theme: {
    fontSize: 14,
    textColor: '#fff',
    legends: {
      text: {
        fill: '#000',
      },
    },
    tooltip: {
      container: {
        fontSize: 14,
      },
      chip: {
        borderRadius: '50%',
      },
    },
  },
};
