import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { debounce } from 'underscore';

import connectOptions, { mergeProps } from '../../../utils/connectOptions';
import {
  handleUpdateCompany,
  getRetainer,
  saveInfo,
  closeValidationError,
  updateRetainer,
  updateCompanyRetainers,
  addCompanyRetainer,
  removeCompanyRetainer,
  findTargets,
} from '../../../actions/company/buyer/retainer';
import { getCompanyInfo } from '../../../actions/companyDetail';
import { getChanged, getIn, toJS, unwrap } from '../../../utils/ChangeSpy';

import Retainer from '../../../components/Company/CompanyBuyer/Retainer';
import { uniqueNewId } from '../../../utils/uniqueId';
import { dateToString } from '../../../utils/dateFormat';
import { convertMoneyToNumber } from '../../../utils/string';
import config from '../../../config';

const { func, object, bool } = PropTypes;

class RetainerContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onSubmit = this.onSubmit.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onSuggestsChange = this.onSuggestsChange.bind(this);
    this.onAddRetainer = this.onAddRetainer.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onErrorClose = this.onErrorClose.bind(this);
    this.onFindTargets = debounce(this.onFindTargets.bind(this), 500);
  }

  componentWillUnmount() {
    this.context.removeOnSaveCallback(this.onSubmit);
  }

  componentDidMount() {
    const { companyId, getRetainer } = this.props;

    getRetainer({ companyId });
    this.context.addOnSaveCallback(this.onSubmit);
  }

  getChildContext() {
    return {
      onErrorClose: this.onErrorClose,
      inputErrors: this.props.inputErrors,
    };
  }

  onErrorClose(e, field) {
    this.props.closeValidationError({ field });
  }

  getCanEditData() {
    return !this.props.isDuplicateCompany;
  }

  onChange({ name, value }) {
    this.props.handleUpdateCompany({ name, value });
  }

  onFormChange({ target: { name, type, value, checked } }) {
    if (type === 'checkbox') return this.onChange({ name, value: checked });

    return this.onChange({ name, value });
  }

  onSuggestsChange({ name, value }) {
    this.onChange({ name, value });
  }

  /**
   * Handle adding retainer to company retainers list.
   *
   * @param {string} type Retainer type, one of that values - 'retainer', 'one-off', 'blanket').
   */
  onAddRetainer(type) {
    const { retainer, addCompanyRetainer } = this.props;
    const retainerOriginal = {
      contractualName: unwrap(retainer.get('contractualName', '')),
      retainer: unwrap(retainer.get('retainer', '')),
      startDate: unwrap(retainer.get('startDate', '')),
      endDate: unwrap(retainer.get('endDate', '')),
      notificationCancellationDate: unwrap(retainer.get('notificationCancellationDate', '')),
      notificationStartDate: unwrap(retainer.get('notificationStartDate', '')),
      type,
      tailMonths: unwrap(retainer.get('tailMonths', '')),
      tailLastIntroduced: unwrap(retainer.get('tailLastIntroduced', '')),
      tailTermination: unwrap(retainer.get('tailTermination', '')),
      retainerNote: unwrap(retainer.get('retainerNote', '')),
      id: uniqueNewId(),
    };

    addCompanyRetainer(retainerOriginal);

    // We need setTimeout because we wait for new element to be rendered.
    // TODO: replace setTimeout() with a better solution.
    setTimeout(() => {
      const firstElement = document.querySelector(`.${type}-contractual-input`);

      firstElement.focus();
    }, 0);
  }

  onDelete(id) {
    this.props.removeCompanyRetainer(id);
  }

  isValidDate(date) {
    if (!date) return false;

    return !!date.isValid && date.isValid();
  }

  onSubmit(event) {
    event.preventDefault();

    const { retainer, match, saveInfo, updateCompanyRetainers, getCompanyInfo } = this.props;
    const { companyId } = match.params;

    if (retainer.get('isValid')) {
      const body = getChanged(retainer);
      const companyRetainers = toJS(retainer.get('companyRetainers')).map(companyRetainer => {
        const start_date = this.isValidDate(companyRetainer.startDate) ? dateToString(companyRetainer.startDate) : null;
        const end_date = this.isValidDate(companyRetainer.endDate) ? dateToString(companyRetainer.endDate) : null;
        const notification_cancellation_date = this.isValidDate(companyRetainer.notificationCancellationDate)
          ? dateToString(companyRetainer.notificationCancellationDate)
          : null;
        const notification_start_date = this.isValidDate(companyRetainer.notificationStartDate)
          ? dateToString(companyRetainer.notificationStartDate)
          : null;
        const id = companyRetainer.id.toString().includes('NEW_') ? null : { id: companyRetainer.id };

        delete companyRetainer.startDate;
        delete companyRetainer.endDate;
        delete companyRetainer.notificationCancellationDate;
        delete companyRetainer.notificationStartDate;

        return {
          ...id,
          retainer: convertMoneyToNumber(companyRetainer.retainer.toString()),
          contractual_name: companyRetainer.contractualName,
          company_id: companyRetainer.companyId || companyId,
          type: companyRetainer.type,
          tailMonths: companyRetainer.tailMonths === '' ? null : companyRetainer.tailMonths,
          tailLastIntroduced: companyRetainer.tailLastIntroduced === '' ? null : companyRetainer.tailLastIntroduced,
          tailTermination: companyRetainer.tailTermination === '' ? null : companyRetainer.tailTermination,
          retainer_note: companyRetainer.retainerNote,
          start_date,
          end_date,
          notification_cancellation_date,
          notification_start_date,
          targetId: companyRetainer.targetId === '' ? null : companyRetainer.targetId,
        };
      });

      updateCompanyRetainers({
        companyId,
        body: companyRetainers,
        afterSuccess: () => {
          getCompanyInfo({ companyId });
        },
      });
      if (Object.keys(body).length) {
        saveInfo({ companyId, body });
      }
    }
  }

  isReadOnly() {
    if (!this.getCanEditData()) {
      return true;
    }

    const buyerHighStatus = Number(getIn(this.props.buyer, 'buyerHighStatus'));
    const userRole = this.context.currentUser.getIn(['roles', 0, 'slug']);

    if (buyerHighStatus >= 9.0 && [config.FINANCE_ADMIN, config.ADMINISTRATOR].includes(userRole)) {
      return false;
    }

    return true;
  }

  isHighestStatus() {
    if (getIn(this.props.buyer, 'buyerHighStatus') === '10.00') {
      return true;
    }

    return false;
  }

  /** Handle find targets list for retainer target by search value.
   *
   * @param {object} params Params.
   * @param {string} params.filter Search value.
   * @param {number} params.page Infinity loading page number.
   * @param {Function} params.afterSuccess Callback after successfully realization.
   */
  onFindTargets({ filter, page, afterSuccess }) {
    this.props.findTargets({ filter, page, afterSuccess });
  }

  render() {
    const { retainer } = this.props;

    return (
      <Retainer
        canEditData={this.getCanEditData()}
        isHighestStatus={this.isHighestStatus()}
        isReadOnly={this.isReadOnly()}
        onAddRetainer={this.onAddRetainer}
        onChange={this.onFormChange}
        onDelete={this.onDelete}
        onFindTargets={this.onFindTargets}
        onSuggestsChange={this.onSuggestsChange}
        retainer={retainer}
      />
    );
  }
}

RetainerContainer.propTypes = {
  inputErrors: object.isRequired,
  isDuplicateCompany: bool.isRequired,
  retainer: object.isRequired,
  saveInfo: func.isRequired,
};

RetainerContainer.contextTypes = {
  currentUser: PropTypes.instanceOf(Map),
  openPopup: PropTypes.func,
  closePopup: PropTypes.func,
  addOnSaveCallback: PropTypes.func.isRequired,
  removeOnSaveCallback: PropTypes.func.isRequired,
};

RetainerContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};

function mapStateToProps(state) {
  return {
    companyId: state.targetCompany.info.getIn(['info', 'id']),
    retainer: state.targetCompany.buyer.retainer,
    inputErrors: state.targetCompany.buyer.retainer.get('inputErrors'),
    buyer: state.targetCompany.buyer.info,
    isDuplicateCompany: state.targetCompany.mergeInfo.get('isDuplicateCompany'),
  };
}

export { RetainerContainer };
export default connect(
  mapStateToProps,
  {
    handleUpdateCompany,
    saveInfo,
    closeValidationError,
    getRetainer,
    updateRetainer,
    updateCompanyRetainers,
    addCompanyRetainer,
    removeCompanyRetainer,
    getCompanyInfo,
    findTargets,
  },
  mergeProps,
  connectOptions,
)(RetainerContainer);
