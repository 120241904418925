import { CALL_API } from '../middleware/api';

let c = 0;
const getUnifier = () => `______search_id_${c++}`;

export const SEARCHING_PEOPLE = 'SEARCHING_PEOPLE';
export const SEARCH_PEOPLE_FAILURE = 'SEARCH_PEOPLE_FAILURE';
export const SEARCH_PEOPLE_SUCCESS = 'SEARCH_PEOPLE_SUCCESS';
export const SEARCHING_NEXT_PEOPLE = 'SEARCHING_NEXT_PEOPLE';

export const SEARCHING_COMPANIES = 'SEARCHING_COMPANIES';
export const SEARCH_COMPANIES_FAILURE = 'SEARCH_COMPANIES_FAILURE';
export const SEARCH_COMPANIES_SUCCESS = 'SEARCH_COMPANIES_SUCCESS';
export const SEARCHING_NEXT_COMPANIES = 'SEARCHING_NEXT_COMPANIES';

export const CHANGE_SEARCHING_TERM = 'CHANGE_SEARCHING_TERM';
export const CHANGE_TAB = 'CHANGE_TAB';

const limit = 1000;

const requests = {
  people: {
    next: SEARCHING_NEXT_PEOPLE,
    start: SEARCHING_PEOPLE,
    error: SEARCH_PEOPLE_FAILURE,
    success: SEARCH_PEOPLE_SUCCESS,
    api: '/api/v1/browse/people',
  },
  companies: {
    next: SEARCHING_NEXT_COMPANIES,
    start: SEARCHING_COMPANIES,
    error: SEARCH_COMPANIES_FAILURE,
    success: SEARCH_COMPANIES_SUCCESS,
    api: '/api/v1/browse/companies',
  },
};

/**
 * Change result tab.
 *
 * @param {string} tab  Tab name.
 * @returns Action.
 */
export function changeTab(tab) {
  return {
    type: CHANGE_TAB,
    tab,
  };
}

/**
 * Action for change searching keyword.
 *
 * @param searchingTerm {string} Searching keyword.
 * @returns Action.
 */
export function changeSearchingTerm(searchingTerm) {
  return {
    type: CHANGE_SEARCHING_TERM,
    searchingTerm,
  };
}

/**
 * Action for searching all.
 *
 * @returns Action.
 */
export function searchAll(keyword, params) {
  return dispatch => {
    dispatch(search(keyword, 'people', params));
    dispatch(search(keyword, 'companies', params));
  };
}

/**
 * Action for executing searching.
 *
 * @param keywords {Array} List of keywords for fuzzy search.
 * @param type {string} Type of search.
 * @param params {Array} List of search params.
 * @returns Action.
 */
export function search(keywords, type, params) {
  const query = {
    page: 1,
    limit,
    keywords,
  };

  if (params.includes('onlyOwner')) {
    query.only_owner = true;
  }

  if (params.includes('searchByWebsite')) {
    query.searchByWebsite = 1;
  }

  if (params.includes('searchByPhone')) {
    query.searchByPhone = 1;
  }

  return {
    isFirstPage: true,
    searchId: getUnifier(),
    [CALL_API]: {
      method: 'get',
      path: requests[type].api,
      query,
      startType: requests[type].start,
      errorType: requests[type].error,
      successType: requests[type].success,
    },
  };
}

/**
 * Action for fetching next page.
 *
 * @param keywords {Array[string]} List of keywords for fuzzy search.
 * @param type {String(companies|people)} Tab name.
 * @param page {Number} Page number.
 * @returns Action.
 */
export function fetchNextPage(keywords, type, page) {
  return {
    [CALL_API]: {
      method: 'get',
      path: requests[type].api,
      query: { page, limit, keywords },
      startType: requests[type].next,
      errorType: requests[type].error,
      successType: requests[type].success,
    },
  };
}
