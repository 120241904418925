import createTable from './CompanyDetailsTable';

export default createTable({
  namePrefix: 'targetProducts',
  tableName: 'Products',
  columns: [
    { field: 'product' },
    {
      field: 'percent',
      title: '%',
      className: 'text-right',
      type: 'number',
      maxWidth: 40,
    },
  ],
});
