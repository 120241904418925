import { fromJS } from 'immutable';

import { toJS, spy, setIn } from '../../../../utils/ChangeSpy';
import * as ActionType from '../../../../actions/company/buyer/addOnProject/addOnProjectCreate';
import handleApiError from '../../../decorators/handleApiError';
import { check } from '../../../decorators/suggest';
import getValidator from '../../../../utils/getValidator';

const SCHEMA = {
  type: 'object',
  properties: {
    category: {
      title: 'Category',
      description: 'Should not be empty',
      type: 'string',
      required: true,
      minLength: 1,
    },
  },
};

const FIELDS_TO_SPY = [
  'type',
  'active',
  'category',
  'details',
  'status',
  'harvcoLeadId',
  'clientLeadId',
  'execId',
  'buyerId',
  'harvcoLead',
  'clientLead',
  'executive',
  'approvalListIds',
];

const defaultState = fromJS(
  spy(
    {
      type: 'Add-on',
      inputErrors: {},
      active: true,
      category: '',
      details: '',
      status: '',
      harvcoLeadId: '',
      clientLeadId: '',
      execId: '',
      buyerId: '',
      isValid: false,
      isDefaultAddOn: false,
      approvalListIds: [],
    },
    FIELDS_TO_SPY,
  ),
);

const validator = getValidator(SCHEMA);

let wrappedReducer = handleApiError(ActionType.SAVE_ADD_ON_PROJECT_ERROR, reducer);

wrappedReducer = check('harvcoLead', 'suggestsUser', ActionType.LOADED_USERS, wrappedReducer);
wrappedReducer = check('clientLead', 'suggestsClient', ActionType.LOADED_CLIENTS, wrappedReducer);
wrappedReducer = check('executive', 'suggestsExecutive', ActionType.LOADED_EXECUTIVE, wrappedReducer);

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.UPDATE_FIELD:
      return checkValidity(setIn(state, action.name, action.value));

    case ActionType.SAVE_ADD_ON_PROJECT:
      return state.set('isFetching', true);

    case ActionType.SAVE_ADD_ON_PROJECT_SUCCESS:
      return defaultState;

    case ActionType.CLOSE_VALIDATION_ERROR:
      return state.deleteIn(['inputErrors', action.field]);

    case ActionType.REVERT_UPDATE:
      return defaultState;

    default:
      return state;
  }
}

function checkValidity(state) {
  const plainState = toJS(state);
  const isValid = validator.isValid(plainState);

  const inputErrors = validator.getFirstError(plainState);

  return state.merge({
    inputErrors,
    isValid,
  });
}
