import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Map, fromJS } from 'immutable';

import config from '../../../../config';
import getPercent from '../../../../utils/getPercent';
import CompanyBuyerPlatformProjectTable from './CompanyBuyerPlatformProjectTable';

const platformColumnDefs = config.tables.getIn(['companyBuyerProjects', 'platform', 'columnDefs']);
const addonColumnDefs = config.tables.getIn(['companyBuyerProjects', 'addon', 'columnDefs']);

const updateDataTable = dataTable =>
  dataTable
    .get('queryResults')
    .toJS()
    .map(data => {
      const numberApproved = Number(data.dsplApprovedCount);

      Object.keys(data).forEach(item => {
        const value = data.numberOfContacted;

        if (item === 'numberOfContacted') {
          data.numberOfContacted = `${value} (${getPercent(data[item], numberApproved)})%`;
        }
      });

      Object.keys(data).forEach(item => {
        const value = data.numberOfLeads;

        if (item === 'numberOfLeads') {
          data.numberOfLeads = `${value} (${getPercent(data[item], numberApproved)})%`;
        }
      });

      Object.keys(data).forEach(item => {
        const value = data.numberOfCc;

        if (item === 'numberOfCc') {
          data.numberOfCc = `${value} (${getPercent(data[item], numberApproved)})%`;
        }
      });

      Object.keys(data).forEach(item => {
        const value = data.numberOfVisit;

        if (item === 'numberOfVisit') {
          data.numberOfVisit = `${value} (${getPercent(data[item], numberApproved)})%`;
        }
      });

      return data;
    });

class ActiveCellRenderer extends PureComponent {
  render() {
    return (
      <div className="approvedWrapper">{this.props.value ? <i className="text-success fa fa-check" /> : null}</div>
    );
  }
}

function getColumnDefs(platformColumnDefs) {
  return platformColumnDefs.map(columnDef => {
    const field = columnDef.get('field');

    switch (field) {
      case 'active':
        return columnDef.set('cellRendererFramework', ActiveCellRenderer);

      default:
        return columnDef;
    }
  });
}

const CompanyBuyerProjects = props => {
  const {
    canEditData,
    projects,
    onDoubleClickRow,
    onGenerateReport,
    onGetNextPageDataForPlatform,
    onGetNextPageDataForAddon,
    onPlatformChange,
    onAddonChange,
    onAddPlatformProject,
  } = props;
  const platform = projects.get('platform');
  const addOn = projects.get('addon');

  const dataPlatform = platform.set('queryResults', fromJS(updateDataTable(platform)));
  const dataAddon = projects.set('queryResults', fromJS(updateDataTable(addOn)));

  return (
    <div className="flexWrapper full-height">
      <div className="d-flex">
        <label>Platform Projects</label>
        <div className="client-summary-report" onClick={onGenerateReport}>
          Client Summary Report
        </div>
      </div>
      <button className="addCategoryBtn" onClick={onAddPlatformProject}>
        <i className="fa fa-plus" />
      </button>
      <CompanyBuyerPlatformProjectTable
        canEditData={canEditData}
        columnDefs={getColumnDefs(platformColumnDefs)}
        data={dataPlatform}
        onChange={onPlatformChange}
        onDoubleClickRow={onDoubleClickRow}
        onGetNextPageData={onGetNextPageDataForPlatform}
      />
      <label>Add-On Projects</label>
      <CompanyBuyerPlatformProjectTable
        canEditData={canEditData}
        columnDefs={addonColumnDefs}
        data={dataAddon}
        onChange={onAddonChange}
        onDoubleClickRow={onDoubleClickRow}
        onGetNextPageData={onGetNextPageDataForAddon}
      />
    </div>
  );
};

CompanyBuyerProjects.propTypes = {
  canEditData: PropTypes.bool.isRequired,
  onAddonChange: PropTypes.func.isRequired,
  onAddPlatformProject: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
  onGenerateReport: PropTypes.func.isRequired,
  onGetNextPageDataForAddon: PropTypes.func.isRequired,
  onGetNextPageDataForPlatform: PropTypes.func.isRequired,
  onPlatformChange: PropTypes.func.isRequired,
  projects: PropTypes.instanceOf(Map).isRequired,
};

export default CompanyBuyerProjects;
