import { List, Map, fromJS } from 'immutable';
import moment from 'moment';

import config from '../../config';
import {
  LOADED_APPROVALS_SUCCESS,
  UPDATE_APPROVAL_INFORMATION,
  INSERT_APPROVAL_ROW,
  CHANGE_APPROVAL_ROW_MODE,
  SORT_APPROVALS,
} from '../../actions/project/approval';
import { RESET_ALL_ROW_TO_VIEW_MODE } from '../../actions/project/main';
import { INSERT_INDUSTRY_ROW, CHANGE_INDUSTRY_ROW_MODE } from '../../actions/project/industry';
import { INSERT_USER_ROW, CHANGE_USER_ROW_MODE } from '../../actions/project/user';
import { sortByNumber, sortByString, sortByDate } from '../../utils/sorting';
import { defaultNumber } from '../../utils/string';
import { spy, setIn, isChanged } from '../../utils/ChangeSpy';

const FIELDS_TO_SPY = ['dateSent', 'dateReceived', 'applistLabel'];
const defaultState = List();

export default function(state = defaultState, action) {
  switch (action.type) {
    case LOADED_APPROVALS_SUCCESS: {
      return getApprovals(action.response);
    }

    case UPDATE_APPROVAL_INFORMATION:
      return update(state, action);

    case INSERT_APPROVAL_ROW:
      return insert(state);

    case INSERT_INDUSTRY_ROW:

    // fallthrough
    case INSERT_USER_ROW:

    // fallthrough
    case CHANGE_INDUSTRY_ROW_MODE:

    // fallthrough
    case CHANGE_USER_ROW_MODE:

    // fallthrough
    case RESET_ALL_ROW_TO_VIEW_MODE:
      return switchToView(state);

    case CHANGE_APPROVAL_ROW_MODE:
      return switchToEdit(state, action);

    case SORT_APPROVALS:
      return sortApprovals(state, action);

    default:
      return state;
  }
}

/**
 * Sort approval list.
 *
 * @param list {Immutable.List} List of target.
 * @param action {Object} Action object.
 * @returns {Immutable.List} Sorted approvals list.
 */
function sortApprovals(list, action) {
  if (!action.field || !action.direction) return list;

  const byDate = ['dateSent', 'dateReceived'];
  const byNumber = [
    'numberTargets',
    'numberApproved',
    'numberOfContacted',
    'numberOfLeads',
    'numberOfCc',
    'numberOfVisit',
    'numberOfNextActions',
    'numberOfPriorityA',
    'numberOfPriorityB',
    'numberOfPriorityC',
    'numberOfApprovedX',
    'percentApproved',
    'numberMailed',
    'percentMailed',
    'numberCalled',
    'percentCalled',
  ];
  const { field, direction } = action;
  const dir = direction === 'up' ? -1 : 1;

  if (byDate.indexOf(field) > -1) {
    return sortByDate(list, field, dir);
  }

  if (byNumber.indexOf(field) > -1) {
    return sortByNumber(list, field, dir);
  }

  return sortByString(list, field, dir);
}

/**
 * Switch all rows to view mode.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action object.
 * @return {Immutable.List}
 */
export function switchToEdit(state, action) {
  const newState = switchToView(state);
  const { index } = action;
  const key = newState.findKey(k => k.get('index') === index);

  if (key !== undefined && key >= 0) {
    return newState.setIn([key, 'mode'], config.EDIT_MODE);
  }
}

/**
 * Switch all rows to view mode.
 *
 * @param list {Immutable.List} List.
 * @returns {Immutable.List}
 */
export function switchToView(list) {
  return list.map(item => item.set('mode', config.VIEW_MODE));
}

/**
 * Insert a new row.
 *
 * @param state {Immutable.List} State.
 * @returns {Immutable.List}
 */
function insert(state) {
  return resetToView(state).push(createEmptyRow(state.size));
}

/**
 * Reset all items to view mode.
 *
 * @param state {Immutable.List} State.
 * @returns {Immutable.List}
 */
function resetToView(state) {
  return state.map(a => a.set('mode', config.VIEW_MODE));
}

/**
 * Get approvals from response.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action object.
 * @return {Immutable.List} Approvals list.
 */
function update(state, action) {
  const { index, name, value } = action;
  const key = state.findKey(a => a.get('index') === index);

  if (key !== undefined && key >= 0) {
    let item = setIn(state.get(key), name, value);

    item = item.set(
      'dirty',
      FIELDS_TO_SPY.reduce((changed, value) => changed || isChanged(item.get(value)), false),
    );

    return state.set(key, item);
  }

  return state;
}

/**
 * Get approvals from response.
 *
 * @param response {Object} Response.
 * @returns {Immutable.List} Approvals list.
 */
function getApprovals(response) {
  if (response.status === 'success') {
    const list = response.data.hasOwnProperty('approvalLists') ? 'approvalLists' : 'defaultApprovalLists';

    return fromJS(
      response.data[list].map((approval, index) => {
        const dateSent = moment(approval.dateSent);
        const dateReceived = moment(approval.dateReceived);
        const numberOfTargets = defaultNumber(approval.numberOfTargets);
        const numberOfApprovedTargets = defaultNumber(approval.numberOfApprovedTargets);
        const numberOfMailedTargets = defaultNumber(approval.numberOfMailedTargets);
        const numberOfCalledTargets = defaultNumber(approval.numberOfCalledTargets);
        const numberOfContacted = defaultNumber(approval.numberOfContacted);
        const numberOfLeads = defaultNumber(approval.numberOfLeads);
        const numberOfCc = defaultNumber(approval.numberOfCc);
        const numberOfVisit = defaultNumber(approval.numberOfVisit);
        const numberOfNextActions = defaultNumber(approval.numberOfNextActions);
        const numberOfPriorityA = defaultNumber(approval.numberOfPriorityA);
        const numberOfPriorityB = defaultNumber(approval.numberOfPriorityB);
        const numberOfPriorityC = defaultNumber(approval.numberOfPriorityC);
        const numberOfApprovedX = defaultNumber(approval.numberOfApprovedX);

        return spy(
          Map({
            index,
            id: approval.id,
            projectId: approval.projectId,
            dateSent: dateSent.isValid() ? dateSent : null,
            dateReceived: dateReceived.isValid() ? dateReceived : null,
            applistLabel: approval.applistLabel,
            numberTargets: numberOfTargets,
            numberApproved: numberOfApprovedTargets,
            percentApproved: countPercent(numberOfApprovedTargets, numberOfTargets),
            numberMailed: numberOfMailedTargets,
            percentMailed: countPercent(numberOfMailedTargets, numberOfTargets),
            numberCalled: numberOfCalledTargets,
            percentCalled: countPercent(numberOfCalledTargets, numberOfTargets),
            mode: config.VIEW_MODE,
            dirty: false,
            numberOfContacted,
            percentContacted: countPercent(numberOfContacted, numberOfTargets),
            numberOfLeads,
            percentLeads: countPercent(numberOfLeads, numberOfTargets),
            numberOfCc,
            percentCc: countPercent(numberOfCc, numberOfTargets),
            numberOfVisit,
            percentVisit: countPercent(numberOfVisit, numberOfTargets),
            numberOfNextActions,
            percentNextActions: countPercent(numberOfNextActions, numberOfTargets),
            numberOfPriorityA,
            percentOfPriorityA: countPercent(numberOfPriorityA, numberOfTargets),
            numberOfPriorityB,
            percentOfPriorityB: countPercent(numberOfPriorityB, numberOfTargets),
            numberOfPriorityC,
            percentOfPriorityC: countPercent(numberOfPriorityC, numberOfTargets),
            numberOfApprovedX,
            percentOfApprovedX: countPercent(numberOfApprovedX, numberOfTargets),
          }),
          FIELDS_TO_SPY,
        );
      }),
    );
  }

  return defaultState;
}

/**
 * Get percent.
 */
function countPercent(numerator, denominator) {
  if (denominator === 0) {
    return 0;
  }

  return Math.round((numerator / denominator) * 100);
}

/**
 * Create an empty row and set it to edit mode.
 *
 * @returns {Immutable.Map}
 */
function createEmptyRow(index) {
  return spy(
    fromJS({
      index,
      id: -1,
      dateSent: null,
      dateReceived: null,
      applistLabel: '',
      target: '',
      numberApproved: 0,
      numberOfContacted: 0,
      percentContacted: 0,
      numberOfLeads: 0,
      percentLeads: 0,
      numberOfCc: 0,
      percentCc: 0,
      numberOfVisit: 0,
      percentVisit: 0,
      numberOfNextActions: 0,
      percentNextActions: 0,
      numberOfPriorityA: 0,
      numberOfPriorityB: 0,
      numberOfPriorityC: 0,
      percentApproved: 0,
      numberMailed: 0,
      percentMailed: 0,
      numberCalled: 0,
      percentCalled: 0,
      mode: config.EDIT_MODE,
      dirty: false,
    }),
    FIELDS_TO_SPY,
  );
}
