import { fromJS, Map } from 'immutable';

import * as actions from '../actions/emailTemplates';

const defaultState = fromJS({
  data: [],
  executivesData: [],
  errors: {},
  inputErrors: {},
});

export default function statuses(state = defaultState, action) {
  switch (action.type) {
    case actions.FETCHING_EMAIL_TEMPLATES:
      return state.set('loading', true);

    case actions.FETCHED_EMAIL_TEMPLATES_SUCCESS:
      return state.merge({
        inputErrors: {},
        data: action.response.data,
        loading: false,
      });

    case actions.FETCHED_EXECUTIVES_EMAIL_TEMPLATES_SUCCESS:
      return state.merge({
        inputErrors: {},
        executivesData: action.response.data,
        loading: false,
      });

    case actions.FETCHED_EMAIL_TEMPLATES_FAIL:
      return state.set('loading', false);

    case actions.SAVE_EMAIL_TEMPLATES_SUCCESS:
      return state.set('inputErrors', Map());

    case actions.SAVE_EMAIL_TEMPLATES_FAIL:
      return state.set('inputErrors', fromJS({ ...action.response.errors }));

    case actions.CLOSE_VALIDATION_ERROR:
      return state.deleteIn(['inputErrors', action.field]);

    case actions.CLEAR_VALIDATION_ERROR:
      return state.deleteIn(['inputErrors', action.field]);

    default:
      return state;
  }
}
