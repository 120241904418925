import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';

import Spinner from '../helpers/Spinner';

/**
 * Top section component.
 *
 * @param props {Object}.
 * @param props.project {Immutable.Map} Project information.
 * @param props.common {Immutable.Map} General information.
 * @returns {React.Component}
 * @class
 */
const TopSection = props => {
  const { project, common, isNoApproval } = props;
  const approval = common.get('approval');
  const loading = common.get('topLoading');
  const spinner = loading ? <Spinner className="bg-white" /> : null;
  const harvcoLeadEl = project.get('harvcoLead') ? `(${project.get('harvcoLead')})` : '';

  const renderLabel =
    approval.get('label') || (isNoApproval && <span className="no-approval-list__title">​(No Approval List)</span>);

  const content = !loading ? (
    <div>
      <div className="text-center">
        <h3>{renderLabel}</h3>
      </div>
      <div className="row mb15">
        <div className="col-md-6">
          <div>
            Buyer: <strong>{project.get('buyer')}</strong>
          </div>
          <div>
            Project:
            <strong>{`${project.get('category', '')} ${harvcoLeadEl}`}</strong>
          </div>
        </div>
        <div className="col-md-6">
          <div className="pull-right">
            <div>
              HarvCo Lead:
              <strong>{project.get('harvcoLead') || '(None)'}</strong>
            </div>
            <div>
              Client Lead:
              <strong>{project.get('clientLead') || '(None)'}</strong>
            </div>
          </div>
          <div className="clearfix" />
        </div>
        <div className="clearfix" />
      </div>
      <div className="clearfix" />
    </div>
  ) : null;

  return (
    <div className="top-section">
      {spinner}
      {content}
    </div>
  );
};

TopSection.propTypes = {
  common: PropTypes.instanceOf(Map).isRequired,
  isNoApproval: PropTypes.bool.isRequired,
  project: PropTypes.instanceOf(Map).isRequired,
};

export default TopSection;
