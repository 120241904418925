import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import Input from '../../../helpers/Input';
import Checkbox from '../../../helpers/Checkbox';
import Textarea from '../../../helpers/Textarea';
import { unwrap } from '../../../../utils/ChangeSpy';
import { dayToString } from '../../../../utils/dateFormat';
import RestartAgreements from './RestartAgreements';
import { OneOffAgreements } from './OneOffAgreements';
import { BlanketAgreements } from './BlanketAgreements';

export default class Retainer extends PureComponent {
  render() {
    const {
      isHighestStatus,
      isReadOnly,
      retainer: root,
      onAddRetainer,
      onChange,
      onFindTargets,
      onSuggestsChange,
      onDelete,
    } = this.props;
    const startDate = unwrap(root.get('startDate'));
    const originalAgreement = startDate && startDate.isValid() ? `- ${dayToString(startDate)}` : null;
    const originalAgreementTitle = isHighestStatus ? 'Original Retainer Agreement' : 'Original Agreement';
    const retainers = root.get('companyRetainers');

    return (
      <div className="tab-pane active tab-target full-height" id="buy-slide-6">
        <div className="col-md-12 full-height">
          <div>
            {isHighestStatus ? (
              <button
                className="btn btn-default btn-xs mr16"
                disabled={isReadOnly}
                onClick={() => onAddRetainer('retainer')}
                type="button"
              >
                Restart
              </button>
            ) : (
              <>
                <button
                  className="btn btn-default btn-xs mr16"
                  disabled={isReadOnly}
                  onClick={() => onAddRetainer('one-off')}
                  type="button"
                >
                  One-Off
                </button>
                <button
                  className="btn btn-default btn-xs"
                  disabled={isReadOnly}
                  onClick={() => onAddRetainer('blanket')}
                  type="button"
                >
                  Blanket
                </button>
              </>
            )}
          </div>
          <form className="formRetainers" onChange={onChange}>
            <RestartAgreements
              isReadOnly={isReadOnly || !isHighestStatus}
              onChange={onChange}
              onDelete={onDelete}
              retainers={retainers}
            />
            <OneOffAgreements
              isReadOnly={isReadOnly}
              onChange={onChange}
              onDelete={onDelete}
              onFindTargets={onFindTargets}
              onSuggestsChange={onSuggestsChange}
              root={root}
            />
            <BlanketAgreements isReadOnly={isReadOnly} onChange={onChange} onDelete={onDelete} retainers={retainers} />
            <div className="formRetainersItem">
              <div className="formRetainersItemTitleWrap">
                <h5 className="formRetainersItemTitle">
                  {originalAgreementTitle} {originalAgreement}{' '}
                </h5>
              </div>
              <div>
                <div className="row">
                  <div className="col-xs-12 col-sm-2 col-md-2">
                    <div className="form-group">
                      <Input
                        className="input-sm"
                        disabled={isReadOnly}
                        label="Contractual Name"
                        name="contractualName"
                        onChange={onChange}
                        root={root}
                        deep
                        showLabel
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-2 col-md-2">
                    <div className="form-group">
                      <Input
                        className="input-sm"
                        disabled={isReadOnly}
                        label="Monthly Retainer"
                        name="retainer"
                        root={root}
                        type="money"
                        deep
                        showLabel
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-2 col-md-2">
                    <div className="form-group">
                      <Input
                        className="input-sm"
                        disabled={isReadOnly}
                        label="Start Date"
                        name="startDate"
                        onChange={onChange}
                        placeholder="Start Date"
                        root={root}
                        title="This is the date, per the contract, that the project kicks off"
                        type="date"
                        deep
                        showLabel
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-2 col-md-2">
                    <div className="form-group">
                      <Input
                        className="input-sm"
                        disabled={isReadOnly}
                        label="End Date"
                        name="endDate"
                        onChange={onChange}
                        placeholder="End Date"
                        root={root}
                        title="This is the date the agreement officially terminates"
                        type="date"
                        deep
                        showLabel
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-2 col-md-2">
                    <div className="form-group">
                      <Input
                        className="input-sm"
                        disabled={isReadOnly}
                        label="Notification to Start Date"
                        name="notificationStartDate"
                        onChange={onChange}
                        placeholder="Notification to Start Date"
                        root={root}
                        title="This is the date we were informed of the client's intent to start"
                        type="date"
                        deep
                        showLabel
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-2 col-md-2">
                    <div className="form-group">
                      <Input
                        className="input-sm"
                        disabled={isReadOnly}
                        label="Notification to Cancel Date"
                        name="notificationCancellationDate"
                        onChange={onChange}
                        placeholder="Notification to Cancel Date"
                        root={root}
                        title="This is the date that the client informed us of their intent to cancel"
                        type="date"
                        deep
                        showLabel
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-4 text-bold">Tail</div>
                </div>
                <div className="row">
                  <div className="col-xs-4">
                    <div className="row agreement-tail">
                      <div className="col-xs-4">
                        <div className="form-group d-flex">
                          <Input
                            className="input-sm tail-months-input"
                            disabled={isReadOnly}
                            id="tail-months"
                            min="0"
                            name="tailMonths"
                            onChange={onChange}
                            root={root}
                            type="number"
                            deep
                          />
                          <label htmlFor="tail-months">Months from</label>
                        </div>
                      </div>
                      <div className="col-xs-4">
                        <Checkbox
                          deep="checked"
                          disabled={isReadOnly}
                          id="tail-last-introduced"
                          label="Last Introduced"
                          labelClassName="last-introduced-label"
                          name="tailLastIntroduced"
                          onChange={onChange}
                          root={root}
                        />
                      </div>
                      <div className="col-xs-4">
                        <Checkbox
                          deep="checked"
                          disabled={isReadOnly}
                          id="tail-termination"
                          label="Termination"
                          labelClassName="termination-label"
                          name="tailTermination"
                          onChange={onChange}
                          root={root}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb10">
                  <div className="col-xs-12 col-sm-4 col-md-4">
                    <Textarea
                      disabled={isReadOnly}
                      label="Notes"
                      name="retainerNote"
                      onChange={onChange}
                      placeholder=""
                      root={root}
                      rows="4"
                      deep
                      showLabel
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

Retainer.propTypes = {
  isHighestStatus: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  onAddRetainer: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onFindTargets: PropTypes.func.isRequired,
  onSuggestsChange: PropTypes.func.isRequired,
  retainer: PropTypes.instanceOf(Map).isRequired,
};
