import React from 'react';

class TextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.getValue = this.getValue.bind(this);
    this.isCancelAfterEnd = this.isCancelAfterEnd.bind(this);
    this.stopEditing = this.stopEditing.bind(this);
    this.onRef = this.onRef.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = {
      value: this.props.value,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.stopEditing);
    this.input.addEventListener('click', this.stopPropagation);
    setTimeout(() => {
      this.input.select();
    }, 100);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.stopEditing);
    this.input.removeEventListener('click', this.stopPropagation);
  }

  stopEditing() {
    setTimeout(() => {
      this.props.api.stopEditing();
    }, 0);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  getValue() {
    return this.state.value;
  }

  isPopup() {
    return false;
  }

  isCancelAfterEnd() {
    return this.state.value === this.props.value;
  }

  onChange(event) {
    this.setState({
      value: event.target.value,
    });
  }

  onRef(input) {
    this.input = input;
  }

  render() {
    return (
      <input
        ref={this.onRef}
        className="ag-cell-edit-input"
        onChange={this.onChange}
        type="text"
        value={this.state.value}
      />
    );
  }
}

export default TextEditor;
