import { fromJS, List, Map } from 'immutable';

import {
  CHANGE_STATUS_POPUP_FORM,
  LOADED_STATUSES_SUCCESS,
  APPLYING_NEW_STATUS,
  APPLIED_NEW_STATUS_FAILURE,
  APPLIED_NEW_STATUS_SUCCESS,
  RESET,
  VALIDATE,
} from '../../actions/approval/statusPopup';
import getErrorResponse from '../../utils/getErrorResponse';

const defaultState = fromJS({
  status: 0.0,
  approved: false,
  passed: false,
  noResponse: false,
  statuses: [],
  stage: 'view',
  errors: [],
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case APPLYING_NEW_STATUS:
      return state.merge({
        stage: 'saving',
        errors: [],
      });

    case APPLIED_NEW_STATUS_FAILURE:
      return state.merge({
        stage: 'error',
        errors: state.get('errors').concat(getErrorResponse(action.response)),
      });

    case APPLIED_NEW_STATUS_SUCCESS:
      return state.set('stage', 'saved');

    case CHANGE_STATUS_POPUP_FORM:
      return validateData(state.set(action.name, action.value));

    case LOADED_STATUSES_SUCCESS:
      return mapStatuses(state, action);

    case RESET:
      return defaultState;

    case VALIDATE:
      return validateData(state);

    default:
      return state;
  }
}

/**
 * Map status return from api to status List.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action.
 * @returns {Immutable.Map}
 */
function mapStatuses(state, action) {
  if (action.response.status === 'success') {
    return state.set(
      'statuses',
      List(
        action.response.data.map((s, index) =>
          Map({
            index,
            value: s.c,
            fc: s.fc,
            name: `${s.c} - ${s.d}`,
          }),
        ),
      ),
    );
  }

  return state;
}

/**
 * Validate data.
 *
 * @param state {Immutable.Map} State.
 * @returns {Immutable.Map}
 */
function validateData(state) {
  if (!state.get('passed') && !state.get('approved') && !state.get('noResponse')) {
    return state.merge({
      stage: 'validationError',
      errors: List(['Select at least one of "Approved", "Passed" or "No Response" to apply this status to']),
    });
  }

  return state.merge({
    stage: 'ready',
    errors: [],
  });
}
