import React from 'react';

import Spinner from '../helpers/Spinner';
import TagTable from './TagTable';
import Footer from './Footer';
import Editor from './Editor';

const TagManagement = props => {
  const {
    loading,
    mode,
    error,
    category,
    parentId,
    tagTypeId,
    types,
    parents,
    tags,
    sort,
    isSuper,
    showParent,
    onAdd,
    onEdit,
    onSave,
    onCancel,
    onDelete,
    onDeleteParent,
    onChange,
    onClick,
    onExport,
  } = props;
  const spinnerEl = loading ? <Spinner /> : null;

  return (
    <div className="full-height tag-page">
      {spinnerEl}
      <TagTable
        isSuper={isSuper}
        mode={mode}
        onClick={onClick}
        onDelete={onDelete}
        onDeleteParent={onDeleteParent}
        onEdit={onEdit}
        sort={sort}
        tags={tags}
      />
      <Editor
        category={category}
        error={error}
        mode={mode}
        onChange={onChange}
        parentId={parentId}
        parents={parents}
        showParent={showParent}
        tagTypeId={tagTypeId}
        types={types}
      />
      <Footer
        isSuper={isSuper}
        loading={loading}
        mode={mode}
        onAdd={onAdd}
        onCancel={onCancel}
        onExport={onExport}
        onSave={onSave}
      />
    </div>
  );
};

export default TagManagement;
