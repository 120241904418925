import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { List } from 'immutable';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import FormSubmitOnBlur from '../../helpers/FormSubmitOnBlur';
import uniqueId from '../../../utils/uniqueId';
import { unwrap } from '../../../utils/ChangeSpy';
import _Input from '../../helpers/Input';
import _Textarea from '../../helpers/Textarea';
import AutoComplete from '../../helpers/AutoComplete';
import FormGroup from '../../helpers/FormGroup';
import LoadAnimation from '../../decorators/LoadAnimation';
import PrettyError from '../../decorators/PrettyError';
import DisplayError from '../../decorators/DisplayError';

const Input = PrettyError(DisplayError(_Input));
const Textarea = PrettyError(DisplayError(_Textarea));

const yearMap = {
  value: {
    '-1': '?',
  },
};

const renderSuggestion = suggestion => <span>{suggestion.text}</span>;

const ContextMenuWrapper = onClick => {
  const ContextMenuWrapped = (value, data) => {
    const id = uniqueId();

    return (
      <div>
        <ContextMenuTrigger id={id}>{value}</ContextMenuTrigger>

        <ContextMenu id={id}>
          <MenuItem data={data.toJS()} onClick={onClick}>
            <i className="fa fa-remove" /> Delete
          </MenuItem>
        </ContextMenu>
      </div>
    );
  };

  return ContextMenuWrapped;
};

class ContactExecutivePosition extends PureComponent {
  render() {
    return ContactExecutivePositionRender(this.props);
  }
}

const ContactExecutivePositionRender = props => {
  const { position, prefix, onChange, onDel, onClick, id = uniqueId(), suggestTitles, suggestions } = props;

  const isOpened = position.get('isOpened');
  const className = classNames('panel-collapse collapse', { in: isOpened });
  const companyName = position.get('companyName', '');
  const companyType = position.get('companyType', '');
  const title = position.get('title', '');
  const pnl = position.get('pnl', '');
  const pnlEbitda = position.get('pnlEbitda', '');
  const startYear = position.get('startYear', '');
  const startYearValue = unwrap(position.get('startYear')) || '?';
  const endYear = position.get('endYear');
  const endYearValue = (function() {
    const year = unwrap(position.get('endYear'));

    if (year in yearMap.value) return yearMap.value[year];

    return year || 'current';
  })();

  const companyBio = position.get('companyBio', '');

  let companyNameContent = companyName;

  if (isOpened) {
    companyNameContent = (
      <Link target="_blank" to={`/company/${position.get('entityId')}`}>
        {companyNameContent}
      </Link>
    );
  }

  const header = ContextMenuWrapper(onDel)(
    <div className="panel-heading" onClick={onClick}>
      <div>
        <span className="pull-right mt15">
          <span>
            <b>
              {startYearValue} - {endYearValue}
            </b>
          </span>
        </span>
        <h4 className="panel-title">{companyNameContent}</h4>
        <i>{companyType}</i>
        <div>{unwrap(title)}</div>
      </div>
    </div>,
    position,
  );

  const autocompleteName = `${prefix}.title`;
  const onUpdate = (...params) => suggestTitles.onUpdate(autocompleteName, ...params);
  const onSelect = (...params) => suggestTitles.onSelect(autocompleteName, ...params);

  return (
    <div className="panel panel-default">
      {header}
      <div className={className} id={id}>
        <div className="panel-body">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <FormGroup>
                <AutoComplete
                  checkOn="text"
                  formGroupClass=" "
                  getSuggestion={(...rest) => {
                    suggestTitles.onFetch({ ...rest, id: position.get('id') });
                  }}
                  getSuggestionValue={suggestTitles.getValue}
                  inputProps={{
                    className: 'form-control input-sm',
                    placeholder: 'Title',
                    name: autocompleteName,
                  }}
                  loading={position.get('loading', false)}
                  onSuggestionSelected={onSelect}
                  onUpdateSuggestions={onUpdate}
                  renderSuggestion={renderSuggestion}
                  suggestions={suggestions}
                  text={title}
                  suggestIfEmpty
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <FormGroup>
                <Input
                  className="input-sm"
                  label="P&amp;L-Revenue"
                  name={`${prefix}.pnl`}
                  onChange={onChange}
                  placeholder="P&amp;L-Revenue"
                  type="number"
                  value={pnl}
                />
              </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <FormGroup>
                <Input
                  className="input-sm"
                  label="P&amp;L-Revenue"
                  name={`${prefix}.pnlEbitda`}
                  onChange={onChange}
                  placeholder="P&amp;L-EBITDA"
                  type="number"
                  value={pnlEbitda}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <FormGroup>
                <Input
                  className="input-sm"
                  label="Start"
                  name={`${prefix}.startYear`}
                  onChange={onChange}
                  placeholder="Start"
                  value={startYear}
                />
              </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <FormGroup>
                <Input
                  className="input-sm"
                  displayMap={yearMap}
                  label="End"
                  name={`${prefix}.endYear`}
                  onChange={onChange}
                  placeholder="End"
                  value={endYear}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <FormGroup>
                <Textarea
                  label="Company Bio"
                  name={`${prefix}.companyBio`}
                  onChange={onChange}
                  placeholder="Company Bio"
                  value={companyBio}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

class ContactExecutivePositions extends PureComponent {
  render() {
    return ContactExecutivePositionsRender(this.props);
  }
}

const ContactExecutivePositionsRender = props => {
  const { positions, onChange, suggestions, suggestTitles, onDel, onClick, onSubmit, onBlur, addPosition } = props;
  const positionsContent = positions.map((position, i) => (
    <ContactExecutivePosition
      key={i}
      onChange={onChange}
      onClick={event => onClick(event, i)}
      onDel={onDel}
      position={position}
      prefix={`positions.${i}`}
      suggestions={suggestions}
      suggestTitles={suggestTitles}
    />
  ));

  return (
    <div className="accordion-right-side">
      <FormSubmitOnBlur onComponentBlur={onBlur} onSubmit={onSubmit}>
        {positionsContent}
      </FormSubmitOnBlur>
      <div className="text-center highlight wrap-border-full">
        <a onClick={addPosition}> + Add position </a>
      </div>
    </div>
  );
};

ContactExecutivePositionsRender.propTypes = {
  positions: PropTypes.instanceOf(List).isRequired,
};

export default LoadAnimation(ContactExecutivePositions);
