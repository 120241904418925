export function parseFullName(fullName) {
  const tmp = (fullName || '').replace(/\s{2,}/g, ' ');
  const arr = tmp
    .trim()
    .split(' ')
    .reduce((acc, it) => {
      if (!/[\~\!\@\#\$\%\^\&\*\.\,]$/g.test(it)) {
        acc.push(it);
      }

      return acc;
    }, []);

  return [arr[0], arr.length > 1 ? arr[arr.length - 1] : '', tmp];
}

export function checkFirstName(firstName) {
  const tmp = (firstName || '')
    .replace(/\s{2,}/g, ' ')
    .trim()
    .split(' ');

  return !/[\~\!\@\#\$\%\^\&\*\.\,]$/g.test(tmp[0]);
}

export function checkLastName(lastName) {
  const tmp = (lastName || '')
    .replace(/\s{2,}/g, ' ')
    .trim()
    .split(' ');

  return !tmp.some(it => /[\~\!\@\#\$\%\^\&\*\.\,]$/g.test(it));
}

/**
 * Return changable status of three fields: [fisrt, last, full].
 */
export function checkNameChangable(focusField, { firstName, lastName, fullName }) {
  const full = (fullName || '')
    .replace(/\s{2,}/g, ' ')
    .trim()
    .split(' ');

  switch (focusField) {
    case 'firstName':
      return {
        firstName: true,
        lastName: !lastName,
        fullName: full.length <= 1,
      };

    case 'lastName':
      return {
        firstName: !firstName,
        lastName: true,
        fullName: full.length <= 1,
      };

    case 'fullName':
      return { firstName: !firstName, lastName: !lastName, fullName: true };

    default:
      return { firstName: false, lastName: false, fullName: false };
  }
}
