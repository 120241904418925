import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'immutable';
import classNames from 'classnames';
import moment from 'moment';

import { unwrap } from 'src/utils/ChangeSpy';
import { dayToString } from 'src/utils/dateFormat';
import Input from 'src/components/helpers/Input';
import Textarea from 'src/components/helpers/Textarea';
import Checkbox from 'src/components/helpers/Checkbox';

/**
 * Component is used to blanket type agreements list.
 *
 * @param {object} props Props.
 * @param {boolean} props.isReadOnly Whether all fields will be read-only or not.
 * @param {Function} props.onChange Handler for changing retainers controls.
 * @param {Function} props.onDelete Handler for deleting retainer from list.
 * @param {Immutable.List} props.retainers Retainers list.
 */
export const BlanketAgreements = props => {
  const { isReadOnly, onChange, onDelete, retainers } = props;
  const NAME_PREFIX = 'companyRetainers';

  return retainers.map((retainer, index) => {
    const startDate = unwrap(retainer.get('startDate'));
    const dateTitle = moment.isMoment(startDate) && startDate.isValid() ? `- ${dayToString(startDate)}` : null;
    const formClassName = classNames('formRetainersItem', {
      'new-agreement': typeof retainer.get('id') === 'string',
    });

    if (unwrap(retainer.get('type') === 'blanket')) {
      return (
        <div key={retainer.get('id')} className={formClassName}>
          <div className="formRetainersItemTitleWrap">
            <h5 className="formRetainersItemTitle">Blanket Agreement {dateTitle} </h5>
          </div>
          <button
            className="formRetainersItemDelete"
            disabled={isReadOnly}
            onClick={() => onDelete(retainer.get('id'))}
            title="Delete"
            type="button"
          >
            <i className="fa fa-remove" />
          </button>
          <div className="row">
            <div className="col-xs-12 col-sm-2 col-md-2">
              <div className="form-group">
                <Input
                  className="input-sm blanket-contractual-input"
                  disabled={isReadOnly}
                  label="Contractual Name"
                  name={`${NAME_PREFIX}.${index}.contractualName`}
                  onChange={onChange}
                  value={retainer.get('contractualName')}
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-2 col-md-2">
              <div className="form-group">
                <Input
                  className="input-sm"
                  disabled={isReadOnly}
                  label="Start Date"
                  name={`${NAME_PREFIX}.${index}.startDate`}
                  onChange={onChange}
                  placeholder="Start Date"
                  title="This is the date, per the contract, that the project kicks off"
                  type="date"
                  value={retainer.get('startDate')}
                  showLabel
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-2 col-md-2">
              <div className="form-group">
                <Input
                  className="input-sm"
                  disabled={isReadOnly}
                  label="End Date"
                  name={`${NAME_PREFIX}.${index}.endDate`}
                  onChange={onChange}
                  placeholder="End Date"
                  title="This is the date the agreement officially terminates"
                  type="date"
                  value={retainer.get('endDate')}
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-4 text-bold">Tail</div>
          </div>
          <div className="row">
            <div className="col-xs-4">
              <div className="row agreement-tail">
                <div className="col-xs-4">
                  <div className="form-group d-flex">
                    <Input
                      className="input-sm tail-months-input"
                      disabled={isReadOnly}
                      id={`retainer-tail-months-${index}`}
                      min="0"
                      name={`${NAME_PREFIX}.${index}.tailMonths`}
                      onChange={onChange}
                      type="number"
                      value={retainer.get('tailMonths')}
                    />
                    <label htmlFor={`retainer-tail-months-${index}`}>Months from</label>
                  </div>
                </div>
                <div className="col-xs-4">
                  <Checkbox
                    checked={retainer.get('tailLastIntroduced')}
                    disabled={isReadOnly}
                    id={`retainer-tail-last-introduced-${index}`}
                    label="Last Introduced"
                    labelClassName="last-introduced-label"
                    name={`${NAME_PREFIX}.${index}.tailLastIntroduced`}
                    onChange={onChange}
                  />
                </div>
                <div className="col-xs-4">
                  <Checkbox
                    checked={retainer.get('tailTermination')}
                    disabled={isReadOnly}
                    id={`retainer-tail-termination-${index}`}
                    label="Termination"
                    labelClassName="termination-label"
                    name={`${NAME_PREFIX}.${index}.tailTermination`}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-4 col-md-4">
              <Textarea
                disabled={isReadOnly}
                label="Notes"
                name={`${NAME_PREFIX}.${index}.retainerNote`}
                onChange={onChange}
                placeholder=""
                rows="4"
                value={retainer.get('retainerNote')}
                showLabel
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  });
};

BlanketAgreements.propTypes = {
  isReadOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  retainers: PropTypes.instanceOf(List).isRequired,
};
