import React from 'react';
import classNames from 'classnames';
import { fromJS } from 'immutable';

import FormGroup from '../../../helpers/FormGroup';
import Select from '../../../helpers/Select';

const OPTIONS = fromJS([
  { value: '', name: '' },
  { value: 'A', name: 'A' },
  { value: 'B', name: 'B' },
  { value: 'C', name: 'C' },
]);

const CeoAbc = props => {
  const { className, ...rest } = props;
  const cls = classNames('input-sm', className);

  return (
    <FormGroup>
      <Select {...rest} className={cls} label="ABC" options={OPTIONS} />
    </FormGroup>
  );
};

export default CeoAbc;
