import { getFormattedToLocale } from 'src/utils/valuesTransformer';

export const countAddonKey = 'Add-Ons';
export const countPlatformKey = 'Platform';
export const countBothKey = 'Both';
export const yearKey = 'Year';

export const defaultBarConfig = {
  keys: [countAddonKey, countPlatformKey, countBothKey],
  indexBy: yearKey,
  colors: ['rgb(83, 140, 194)', 'rgb(237, 173, 24)', 'rgb(238, 80, 80)'],
  padding: 0.4,
  axisBottom: {
    tickSize: 0,
    tickPadding: 10,
  },
  gridYValues: 7,
  axisLeft: {
    tickValues: 7,
    tickSize: 0,
    tickPadding: 6,
    format: value => getFormattedToLocale(value),
  },
  enableLabel: false,
  theme: {
    fontSize: 14,
    grid: {
      line: {
        stroke: 'rgb(173, 173, 173)',
        strokeWidth: 0.5,
      },
    },
    tooltip: {
      container: {
        fontSize: 14,
      },
    },
  },
};
