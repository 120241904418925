import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'immutable';
import classnames from 'classnames';
import parse from 'html-react-parser';

import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import downloadFile from '../../utils/downloadFile';

/**
 * Popup for displaying general information.
 *
 * @param props {Object}.
 * @param context {Object}.
 * @param props.message {Object{texts,className}} message to show.
 * @param props.onClosePopupCallBack {Function} This callback function will be called after popup closed.
 * @param props.classes {String} Additional classes for modal body content.
 * @param {boolean} props.isCustomSubheader Whether subheader is custom or not (for example html element instead simple string).
 * @returns {React.Component}
 * @class
 */
const InformationPopup = (props, context) => {
  const popup = props.popup.get('props');
  const texts = popup.getIn(['message', 'texts'], ['Message']);
  const className = popup.getIn(['message', 'className'], 'text-info');
  const header = popup.get('header');
  const subheader = popup.getIn(['message', 'subheader'], '');
  const onClosePopupCallBack = popup.get('onClosePopupCallBack') || context.closePopup;
  const classes = popup.get('classes');
  const isCustomSubheader = popup.getIn(['message', 'isCustomSubheader'], false);
  const footer = [
    <Button key="ok" className="btn-primary btn-xs" data-dismiss="modal" onClick={onClosePopupCallBack} autoFocus>
      OK
    </Button>,
  ];

  /**
   * Find link in string and make it as <a href="url">url</a>.
   *
   * @param {string} text Message text.
   */
  const delinkText = text => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    if (text) {
      return text.replace(urlRegex, url => `<a href=${url} target="_blank" rel="noopener noreferrer">${url}</a>`);
    }
  };

  let errors;

  // if errors as a simple array of strings(ex: ['Address', 'Phone']) display every element in the new line.
  // if errors as an object of strings(ex: {name: 'Name', ur: 'http://', download: true, fileName: 'file.txt'}) display every element in the new line with URls.
  // Otherwise display detailed message. Ex: [{ key: 'Title', value: ['Address', 'Phone'] }]
  if (texts.getIn([0, 0, 'url'])) {
    errors = (
      <ul>
        {texts.get(0).map((text, i) => (
          <li key={i} className={className}>
            {text.get('download', false) ? (
              <a
                onClick={event => {
                  event.preventDefault();
                  downloadFile({
                    url: text.get('url'),
                    headers: text.get('headers').toJS(),
                    name: text.get('name'),
                    noAuth: true,
                  });
                }}
              >
                {text.get('name')}
              </a>
            ) : (
              <a href={text.get('url')} rel="noopener noreferrer" target="_blank">
                {text.get('name')}
              </a>
            )}
          </li>
        ))}
      </ul>
    );
  } else if (List.isList(texts.get(0)) || typeof texts.get(0) === 'string') {
    const getFilteredTextArray = text => text.filter(message => message);

    errors = (
      <ul>
        {texts.map((text, i) => {
          if (List.isList(text)) {
            return getFilteredTextArray(text).map(message => (
              <li key={i} className={className}>
                {parse(delinkText(message))}
              </li>
            ));
          }

          return (
            <li key={i} className={className}>
              {parse(delinkText(text))}
            </li>
          );
        })}
      </ul>
    );
  } else {
    errors = (
      <ul>
        {texts.map((error, i) => (
          <li key={i} className={className}>
            <b>{error.key}</b>
            <ol>
              {error.value.map((text, i) => (
                <li key={i}>{text}</li>
              ))}
            </ol>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <Popup footer={footer} header={header ? header.toJS() : 'Information'} id="accessWarning">
      <div className={classnames(['row media-450', classes])}>
        <span className="subheader">{isCustomSubheader ? subheader.toJS() : subheader}</span>
        <div className="error-content">{errors}</div>
      </div>
    </Popup>
  );
};

InformationPopup.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
};

export default InformationPopup;
