import { Iterable, fromJS, List } from 'immutable';
import moment from 'moment';

import * as ActionType from '../../actions/contactDetail';
import * as EventActionType from '../../actions/contact/contactEvents';
import config from '../../config';
import { withStatePrefix } from '../decorators/handleApiError';
import { autoNullId, autoRemoveId, check } from '../decorators/suggest';
import { spy, merge, mergeDeep, setIn } from '../../utils/ChangeSpy';

const DATE_STRING_FORMAT = 'MM/DD/YYYY';

const isValidDate = date => {
  if (!date) return '';

  return date.isValid && date.isValid();
};

const dateToString = date => {
  if (!date) return '';
  if (moment(date).isValid()) return moment(date).format(DATE_STRING_FORMAT);

  return date;
};

const FIELDS_TO_SPY = [
  'targetName',
  'buyerName',
  'projectName',
  'completed',
  'userName',
  'date',
  'ownerName',
  'subOwnerName',
  'targetId',
  'buyerId',
  'userId',
  'contactId',
  'ndaTerm',
  'offerSize',
  'projectId',
  'directorName',
  'recordOwnerId',
  'analystName',
  'recordSubOwnerId',
  'contactName',
  'activity',
  'description',
  'dealNotes',
  'emailText',
  'activityMap.text',
];

const defaultShowAdditionField = fromJS({
  showDescription: false,
  showDealNotes: false,
  showEmail: false,
  showNDAExecuted: false,
  showOfferAmount: false,
  showFuture: false,
  showPast: false,
});

const defaultState = fromJS({
  all: [],
  dealNotes: [],
  emails: [],
  current: spy(
    {
      id: null,
      initialFetch: false,
      analystSuggests: [],
      directorSuggests: [],
      userSuggests: [],
      showTooltip: false,
      tooltipText: '',
      tooltipTop: 0,
      tooltipLeft: 0,
      isLoading: false,
      error: '',
      targetName: '',
      buyerName: '',
      projectName: '',
      buyerId: null,
      projectId: null,
      targetId: null,
      contactId: null,
      completed: null,
      userName: '',
      userId: 0,
      ownerName: '',
      subOwnerName: '',
      directorName: '',
      analystName: '',
      description: '',
      dealNotes: '',
      emailText: '',
      ndaTerm: '',
      offerSize: '',
      activityMap: {
        text: '',
        selected: {},
        suggestions: [],
      },
      inputErrors: {},
    },
    FIELDS_TO_SPY,
  ),
});

const defaultSuggests = List();
let wrappedReducer = reducer;

wrappedReducer = withStatePrefix(EventActionType.SAVE_CONTACT_EVENT_ERROR, wrappedReducer, () => [
  'current',
  'inputErrors',
]);

wrappedReducer = check('current.userName', 'current.userSuggests', EventActionType.LOADED_USERS, wrappedReducer);
wrappedReducer = check(
  'current.directorName',
  'current.directorSuggests',
  EventActionType.LOADED_DIRECTORS,
  wrappedReducer,
);
wrappedReducer = check(
  'current.analystName',
  'current.analystSuggests',
  EventActionType.LOADED_ANALYSTS,
  wrappedReducer,
);

wrappedReducer = autoNullId(
  'userName',
  'current.userId',
  EventActionType.CHANGE_CONTACT_EVENT,
  wrappedReducer,
  'field',
);
wrappedReducer = autoNullId(
  'directorName',
  'current.recordOwnerId',
  EventActionType.CHANGE_CONTACT_EVENT,
  wrappedReducer,
  'field',
);
wrappedReducer = autoNullId(
  'analystName',
  'current.recordSubOwnerId',
  EventActionType.CHANGE_CONTACT_EVENT,
  wrappedReducer,
  'field',
);

wrappedReducer = autoRemoveId(
  'userName',
  'current.userId',
  EventActionType.CHANGE_CONTACT_EVENT,
  wrappedReducer,
  'field',
);
wrappedReducer = autoRemoveId(
  'directorName',
  'current.recordOwnerId',
  EventActionType.CHANGE_CONTACT_EVENT,
  wrappedReducer,
  'field',
);
wrappedReducer = autoRemoveId(
  'analystName',
  'current.recordSubOwnerId',
  EventActionType.CHANGE_CONTACT_EVENT,
  wrappedReducer,
  'field',
);

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.LOAD_CONTACT_DETAIL:
      return defaultState;

    case EventActionType.LOADED_EVENT_LIST:

    // fallthrough
    case ActionType.LOADED_CONTACT_DETAIL:
      return defaultState.merge(mapResponse(action.response));

    case EventActionType.ENTER_CONTACT_EMAIL:

    // fallthrough
    case EventActionType.ENTER_CONTACT_DEAL_NOTE:
      return state.updateIn(['all'], events =>
        events.map(event => event.set('isMarked', action.eventId === event.get('id'))),
      );

    case EventActionType.LEAVE_CONTACT_EMAIL:

    // fallthrough
    case EventActionType.LEAVE_CONTACT_DEAL_NOTE:
      return state.updateIn(['all'], events => events.map(event => event.set('isMarked', false)));

    case EventActionType.ENTER_CONTACT_EVENT: {
      const tooltipText = getTooltipString(getCurrentEvent(state, action.eventId));

      return state
        .update('dealNotes', notes => notes.map(note => note.set('isMarked', action.eventId === note.get('eventId'))))
        .update('emails', emails => emails.map(email => email.set('isMarked', action.eventId === email.get('eventId'))))
        .mergeDeep({
          current: {
            id: action.eventId,
            showTooltip: !!tooltipText,
            tooltipTop: action.top,
            tooltipLeft: action.left,
            tooltipText,
          },
        });
    }

    case EventActionType.LEAVE_CONTACT_EVENT:
      return state
        .update('dealNotes', notes => notes.map(note => note.set('isMarked', false)))
        .update('emails', emails => emails.map(email => email.set('isMarked', false)))
        .mergeDeep({
          current: {
            showTooltip: false,
          },
        });

    case EventActionType.FETCHING_EVENT:
      return state.merge({
        current: defaultState.get('current').merge({
          isLoading: true,
          initialFetch: true,
          error: null,
        }),
      });

    case EventActionType.FETCHING_EVENT_FAILURE:
      return state.mergeDeep({
        current: {
          isLoading: false,
          initialFetch: false,
          error: action.response,
        },
      });

    case EventActionType.FETCHING_EVENT_SUCCESS:
      return state.mergeDeep({
        current: spy(
          {
            ...eventMapper(action.response.data),
            initialFetch: false,
            isLoading: false,
          },
          FIELDS_TO_SPY,
        ),
      });

    case EventActionType.START_EDIT_CONTACT_EVENT:
      return startEditContactEvent(state, action);

    case EventActionType.CHANGE_CONTACT_EVENT: {
      action.field = action.field.replace(/^current\./, '');

      if (action.field === 'activityMap') {
        Object.keys(action.value).forEach(key => {
          if (key === 'selected') {
            state = setIn(state, 'current.activityMap.selected', defaultShowAdditionField.merge(action.value.selected));
          } else if (Iterable.isIterable(action.value[key])) {
            state = setIn(state, `current.activityMap.${key}`, action.value[key]);
          } else {
            state = mergeDeep(state, {
              [`current.activityMap.${key}`]: action.value[key],
            });
          }
        });

        return state;
      }

      if (action.field === 'date') {
        state = setIn(state, 'current.dateString', dateToString(action.value));
        if (isValidDate(action.value)) {
          action.value = action.value.endOf('day');
          state = state.deleteIn(['current', 'inputErrors', 'date']);
        } else {
          state = state.setIn(['current', 'inputErrors', 'date'], 'Date should be valid date.');
        }
      }

      return setIn(state, `current.${action.field}`, fromJS(action.value));
    }

    case EventActionType.SAVE_CONTACT_EVENT:
      return state.mergeDeep({
        current: {
          isLoading: true,
          error: null,
        },
      });

    case EventActionType.SAVE_CONTACT_EVENT_ERROR:
      return state.mergeDeep({ current: { isLoading: false } });

    case EventActionType.DELETE_CONTACT_EVENT:
      return state
        .update('all', events => events.filter(event => event.get('id') !== action.eventId))
        .update('emails', emails => emails.filter(email => email.get('eventId') !== action.eventId))
        .update('dealNotes', dealNotes => dealNotes.filter(dn => dn.get('eventId') !== action.eventId));

    case EventActionType.CLOSE_VALIDATION_ERROR:
      return state.deleteIn(['current', 'inputErrors', action.field]);

    case EventActionType.RESET_CURRENT_EVENT:
      return state.set('current', defaultState.get('current'));

    default:
      return state;
  }
}

function getCurrentEvent(state, id) {
  return state.get('all').find(value => value.get('id') === id);
}

function eventMapper(event) {
  const result = {};

  result.id = event.id;
  result.userName = event.userUserName || event['user.userName'];
  result.userId = event.userId;
  result.backlogged = event.backlogged;
  result.ndaTerm = event.ndaTerm;
  result.offerSize = event.offerSize;
  result.date = moment(event.date).endOf('day');
  if (!result.date.isValid() || result.date.format('YYYYMMDD') === '19700101') {
    result.dateString = 'no date';
  } else {
    result.dateString = result.date.format(DATE_STRING_FORMAT);
  }
  result.activity = event.activity;
  if (event.description) result.fullActivity = `${event.activity} - ${event.description}`;
  else result.fullActivity = event.activity;
  result.description = event.description || '';
  result.dealNotes = event.dealNotes || '';
  result.emailText = event.emailText || '';
  result.abbrName = event['buyer.abbrName'] || event.buyerAbbrName;
  result.legalName = event['buyer.legalName'] || event.buyerLegalName;
  result.completed = event.completed;
  result.isNext = !event.completed;
  result.meta = event.meta;
  result.targetName = event['target.legalName'] || event.targetLegalName;
  result.contactName = event.targetContactFLName;
  if (event.approach === 'buyer') {
    result.contactName = event.buyerContactFLName;
  }
  result.buyerName = event['buyer.legalName'] || event.buyerLegalName;
  result.projectName = event['project.category'] || event.projectCategory;
  result.eprojectName = event['eproject.category'] || event.eprojectCategory;
  result.eprojectId = event['eproject.id'] || event.eprojectId;
  result.ownerUsername = event.recordOwnerUserName;
  result.subOwnerUsername = event.recordSubOwnerUserName;
  result.approach = event.approach;

  const activities = config.values.getIn(['eventActivities', event.approach]);

  result.activityMap = {
    suggestions: [],
    selected: activities.filter(item => item.get('value') === event.activity).get(0),
    text: event.activity,
  };
  result.activityMap.selected = defaultShowAdditionField.merge(result.activityMap.selected);
  if (result.isNext) {
    if (moment(event.date).add(5180000, 'seconds') < moment()) {
      result.warnLevel = 3;
    } else if (moment(event.date).add(1210000, 'seconds') < moment()) {
      result.warnLevel = 2;
    } else if (moment(event.date) < moment()) {
      result.warnLevel = 1;
    } else {
      result.warnLevel = 0;
    }
  }

  if (moment(event.date).endOf('day') <= moment()) {
    result.isFuture = false;
  } else {
    result.isFuture = true;
  }

  if (/status change/i.test(event.activity)) {
    result.fullActivity = `Status Change ${event.oldStatus} => ${event.newStatus} (${event['buyer.abbrName'] ||
      event['buyer.legalName']})`;
  }

  result.recordOwnerId = event.recordOwnerId || null;
  result.recordSubOwnerId = event.recordSubOwnerId || null;
  result.directorName = result.ownerUsername || '';
  result.analystName = result.subOwnerUsername || '';
  result.directorSuggests = result.analystSuggests = result.userSuggests = defaultSuggests;

  return result;
}

function mapResponse({ data }) {
  const { events = [] } = data;

  const all = events
    .filter(event => !event.deletedAt)
    .map(eventMapper)
    .sort((a, b) => b.date - a.date || b.id - a.id);

  return {
    ...getEmailsAndNotesFromData(data),
    all,
  };
}

function getEmails(events) {
  return events
    .filter(v => !!v.emailText)
    .map(v => ({
      isMarked: false,
      content: v.emailText,
      legalName: v.legalName,
      date: v.date.isValid() ? v.dateString : 'no date',
      userName: v.userName,
      eventId: v.id,
    }));
}

function getDealNotes(events) {
  return events
    .filter(v => v.dealNotes)
    .map(v => ({
      isMarked: false,
      content: v.dealNotes,
      legalName: v.legalName,
      projectName: v.projectName,
      date: v.date.isValid() ? v.dateString : 'no date',
      userName: v.userName,
      eventId: v.id,
    }));
}

function getEmailsAndNotesFromData(data) {
  let events = (data && data.events) || [];

  events = events
    .filter(event => !event.deletedAt)
    .map(eventMapper)
    .sort((a, b) => b.date - a.date || b.id - a.id);

  return {
    dealNotes: getDealNotes(events),
    emails: getEmails(events),
  };
}

function getEventFills() {
  return {
    completed: false,
  };
}

function getTooltipString(event) {
  if (event.get('approach') === 'exec') {
    const name = `${event.get('abbrName') || event.get('legalName') || ''}`;
    const project = event.get('eprojectId') ? ` - ${event.get('eprojectName')}` : '';

    return `${name}${project}`;
  }

  return '';
}

function startEditContactEvent(state, action) {
  let current = spy((action.eventId ? state : defaultState).get('current'), FIELDS_TO_SPY);

  current = mergeDeep(current, eventMapper({ approach: action.approach }));
  current = current.updateIn(['activityMap', 'suggestions'], a => fromJS(a));
  current = merge(current, action.eventId ? {} : getEventFills(action));
  current = setIn(current, ['activityMap', 'text'], '');
  if (action.defaultDirector) {
    current = setIn(current, ['directorName'], action.defaultDirector.text);
    current = setIn(current, ['recordOwnerId'], action.defaultDirector.id);
  }

  if (action.defaultAnalyst) {
    current = setIn(current, ['analystName'], action.defaultAnalyst.text);
    current = setIn(current, ['recordSubOwnerId'], action.defaultAnalyst.id);
  }

  if (action.defaultUser) {
    current = setIn(current, ['userName'], action.defaultUser.text);
    current = setIn(current, ['userId'], action.defaultUser.id);
  }

  if ('completed' in action) {
    current = setIn(current, ['completed'], action.completed);
  }

  return state.merge({ current });
}
