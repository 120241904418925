import { fromJS, List } from 'immutable';

import { spy, setIn } from '../../../../utils/ChangeSpy';
import {
  LOADED_TEMPLATES_SUCCESS,
  LOADING_TEMPLATES,
  CHANGE_EDIT_MODE,
  CHANGE_TEMPLATE_NAME,
  CHANGE_TEMPLATE_ROW_TO_EDIT,
  DELETE_FILE,
} from '../../../../actions/company/buyer/addOnProject/template';
import { RESET_ALL_ROW_TO_VIEW_MODE } from '../../../../actions/company/buyer/addOnProject/main';
import config from '../../../../config';

const defaultState = fromJS([]);

export default function(state = defaultState, action) {
  switch (action.type) {
    case LOADED_TEMPLATES_SUCCESS:
      return getTemplates(state, action);

    case LOADING_TEMPLATES:
      return defaultState;

    case CHANGE_EDIT_MODE:
      return changeToEditMode(state, action);

    case CHANGE_TEMPLATE_NAME:
      return changeName(state, action);

    case CHANGE_TEMPLATE_ROW_TO_EDIT:
      return changeToEdit(state, action);

    case RESET_ALL_ROW_TO_VIEW_MODE:
      return switchToView(state);

    case DELETE_FILE:
      return deleteFile(state, action);

    default:
      return state;
  }
}

/**
 * Delete template.
 */
function deleteFile(state, { id }) {
  const key = state.findKey(template => template.get('id') === id);

  if (key !== undefined && key > -1) {
    return state.delete(key);
  }

  return state;
}

/**
 * Change all rows to view mode.
 */
function switchToView(state) {
  return state.map(t => t.set('mode', config.VIEW_MODE));
}

/**
 * Change a row to edit mode.
 */
function changeToEdit(state, { id }) {
  const key = state.findKey(t => t.get('id') === id);

  if (key !== undefined && key > -1) {
    return state.setIn([key, 'mode'], config.EDIT_MODE);
  }

  return state;
}

/**
 * Change template name.
 */
function changeName(state, { id, name }) {
  const key = state.findKey(t => t.get('id') === id);

  if (key !== undefined && key > -1) {
    return setIn(state, [key, 'name'], name);
  }

  return state;
}

/**
 * Get email templates from response.
 */
function getTemplates(state, { response: { data, status } }) {
  if (status === 'success') {
    return fromJS(data.map(mapTemplate));
  }

  return List();
}

/**
 * @param template {Object} Template inforamtion.
 */
function mapTemplate(template) {
  return spy(
    {
      id: template.id,
      name: template.name,
      fileName: template.fileName,
      url: template.url,
      mode: config.VIEW_MODE,
    },
    ['name'],
  );
}

/**
 * Change a row to edit mode.
 */
function changeToEditMode(state, { id }) {
  const key = state.findKey(t => t.get('id') === id);

  if (key !== undefined && key > -1) {
    return state.setIn([key, 'mode'], config.EDIT_MODE);
  }

  return state;
}
