import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import Popups from '../../components/Popup';
import { getChanged } from '../../utils/ChangeSpy';
import { findUsers, changeField, clearSuggest, makeContactExecutive } from '../../actions/contact/contactExecutive';
import { reloadContactDetail } from '../../actions/contactDetail';

class ExecutiveRolePopupContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.onCreate = this.onCreate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onFetchUsers = this.onFetchUsers.bind(this);
    this.onUpdateDirector = this.onUpdateSuggestions.bind(this, 'suggestDirector');
    this.onUpdateAnalyst = this.onUpdateSuggestions.bind(this, 'suggestAnalyst');
    this.onSelectDirector = this.onSelectUser.bind(this, 'suggestDirector');
    this.onSelectAnalyst = this.onSelectUser.bind(this, 'suggestAnalyst');
  }

  componentDidMount() {
    const module = {
      username: this.context.currentUser.get('module') || '',
      id: this.context.currentUser.get('moduleId') || 0,
    };
    const supervisor = {
      username: this.context.currentUser.get('userName') || '',
      id: this.context.currentUser.get('id') || 0,
    };

    this.props.changeField({ name: 'suggestDirector', value: module.username });
    this.props.changeField({
      name: 'suggestAnalyst',
      value: supervisor.username,
    });
    this.props.changeField({ name: 'recordOwnerId', value: module.id });
    this.props.changeField({ name: 'recordSubOwnerId', value: supervisor.id });
  }

  onCreate() {
    const id = this.props.contact.get('id', 0);
    const body = getChanged(this.props.executive);

    this.props.makeContactExecutive({ ...body, id }, () => {
      this.props.reloadContactDetail({ id }, this.onClose);
    });
  }

  onClose() {
    this.context.closePopup();
  }

  static onGetValue(suggestion) {
    return suggestion.value;
  }

  onFetchUsers({ value }) {
    this.props.findUsers({ filter: value });
  }

  onUpdateSuggestions(suggestionName, param) {
    if ('text' in param) {
      const name = suggestionName;
      const value = param.text;

      this.props.changeField({ name, value });
    }

    if ('suggestions' in param) {
      const name = 'suggests';
      const value = param.suggestions;

      this.props.changeField({ name, value });
    }
  }

  onSelectUser(name, e, { suggestion }) {
    this.props.changeField({ name, value: suggestion.text });
  }

  getSuggestProps(name) {
    return {
      onFetch: this.onFetchUsers,
      onUpdate: this[`onUpdate${name}`],
      getValue: ExecutiveRolePopupContainer.onGetValue,
      onSelect: this[`onSelect${name}`],
      value: this.props.executive.get(`suggest${name}`, ''),
    };
  }

  render() {
    const { children, contact, executive } = this.props;

    const suggestDirector = this.getSuggestProps('Director');
    const suggestAnalyst = this.getSuggestProps('Analyst');

    return (
      <div>
        <Popups.ExecutiveRolePopup
          contact={contact}
          executive={executive}
          onClose={this.onClose}
          onCreate={this.onCreate}
          suggestAnalyst={suggestAnalyst}
          suggestDirector={suggestDirector}
        />
        {children}
      </div>
    );
  }
}

ExecutiveRolePopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
};

function mapStateToProps(state, props) {
  return {
    ...props,
    contact: state.contact.info,
    executive: state.contact.executive,
  };
}

export { ExecutiveRolePopupContainer };
export default connect(
  mapStateToProps,
  {
    findUsers,
    clearSuggest,
    makeContactExecutive,
    reloadContactDetail,
    changeField,
  },
  mergeProps,
  connectOptions,
)(ExecutiveRolePopupContainer);
