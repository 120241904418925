import { Map, Iterable } from 'immutable';
import moment from 'moment';

import isObject from './isObject';
import isArray from './isArray';

export const WRAPPED_FIELDS = '__WRAPPED_FIELDS__';
export const ORIGINAL_VALUE_KEY = '__ORIGINAL_VALUE_KEY__';
export const VALUE_KEY = 'value';

function wrap(value) {
  return Map({
    [VALUE_KEY]: value,
    [ORIGINAL_VALUE_KEY]: value,
  });
}

function isWrappedObject(value) {
  if (isObject(value)) {
    if (value.hasOwnProperty(ORIGINAL_VALUE_KEY)) {
      return true;
    }
  }

  return false;
}

export function isWrapped(value) {
  if (!Map.isMap(value)) return isWrappedObject(value);

  return value.has(ORIGINAL_VALUE_KEY);
}

export function unwrap(value) {
  if (isWrappedObject(value)) {
    return value[VALUE_KEY];
  }

  if (isWrapped(value)) {
    return value.get(VALUE_KEY);
  }

  return value;
}

function spyMap(valuesObject, fields) {
  if (isWrapped(valuesObject)) return valuesObject;

  const result = fields.reduce((result, field) => {
    const path = field.split('.');

    if (isWrapped(result.getIn(path))) return result;

    return result.setIn(path, wrap(result.getIn(path)));
  }, valuesObject);

  return result.merge({ [WRAPPED_FIELDS]: fields });
}

function getInObject(object, path) {
  return path.reduce((result, path) => result[path], object);
}

function setInObject(object, path, value) {
  const last = path.pop();

  path.reduce((temp, key) => {
    if (!(key in temp)) temp[key] = {};

    return temp[key];
  }, object)[last] = value;
}

function wrapInObject(object, path) {
  const last = path.pop();
  const linkToDeep = getInObject(object, path);

  linkToDeep[last] = wrap(linkToDeep[last]);
}

export function spy(valuesObject, fields = ['value']) {
  if (Map.isMap(valuesObject)) return spyMap(valuesObject, fields);
  if (!isObject(valuesObject)) return wrap(valuesObject);

  const result = fields.reduce(
    (result, field) => {
      const path = field.split('.');

      if (isWrapped(getInObject(result, path))) return result;
      wrapInObject(result, path);

      return result;
    },
    { ...valuesObject },
  );

  result[WRAPPED_FIELDS] = fields;

  return result;
}

export function isChanged(value) {
  if (isWrapped(value)) {
    if (!Map.isMap(value)) {
      return value[VALUE_KEY] !== value[ORIGINAL_VALUE_KEY];
    }

    return value.get(VALUE_KEY) !== value.get(ORIGINAL_VALUE_KEY);
  }

  return false;
}

export function setIn(valuesMap, path, value) {
  if (typeof path === 'string') path = path.split('.');
  if (isWrapped(valuesMap.getIn(path))) {
    return valuesMap.setIn(path.concat(VALUE_KEY), value);
  }

  return valuesMap.setIn(path, value);
}

export function setOriginalIn(valuesMap, path, value) {
  if (typeof path === 'string') path = path.split('.');
  if (isWrapped(valuesMap.getIn(path))) {
    return valuesMap.setIn(path.concat(ORIGINAL_VALUE_KEY), value);
  }

  return valuesMap;
}

function isDeepChangedObject(object) {
  if (isChanged(object)) return true;
  if (!isObject(object)) return false;

  return Object.keys(object).some(key => isDeepChanged(object[key]));
}

function isDeepChangedImmutable(iterable) {
  if (isChanged(iterable)) return true;
  if (!isObject(iterable)) return false;

  return iterable.some(value => isDeepChanged(value));
}

export function isDeepChanged(value, ...rest) {
  let res;

  if (Iterable.isIterable(value)) res = isDeepChangedImmutable(value);
  else res = isDeepChangedObject(value);

  return res || rest.some(value => isDeepChanged(value));
}

export function merge(state, keyValues) {
  return Object.keys(keyValues).reduce((result, key) => setIn(result, key, keyValues[key]), state);
}

export function mergeDeep(state, keyValues) {
  if (Iterable.isIterable(keyValues)) keyValues = keyValues.toJS();

  return Object.keys(keyValues).reduce((result, key) => {
    let val = keyValues[key];

    if (Map.isMap(val)) val = val.toJS();
    if (isObject(val)) if (!moment.isMoment(val)) val = mergeDeep(getIn(result, key), val);

    return setIn(result, key, val);
  }, state);
}

export function mergeToOriginal(state, keyValues) {
  return Object.keys(keyValues).reduce((result, key) => {
    if (!result) return;

    let val = keyValues[key];

    if (!Map.isMap(val) && isObject(val)) val = mergeToOriginal(getIn(result, key), val);

    return setOriginalIn(result, key, val);
  }, state);
}

function plainValues(object) {
  return Object.keys(object).reduce((result, key) => {
    if (Map.isMap(object[key])) result[key] = object[key];
    else if (typeof object[key] === 'object' && object[key]) {
      const tmp = plainValues(object[key]);

      Object.keys(tmp).forEach(subKey => {
        result[`${key}.${subKey}`] = tmp[subKey];
      });
    } else {
      result[key] = object[key];
    }

    return result;
  }, {});
}

export function mergeDeepToOriginal(state, keyValues) {
  const plained = plainValues(keyValues);

  return mergeToOriginal(state, plained);
}

export function revert(state) {
  if (typeof state !== 'object') return state;
  if (!isDeepChanged(state)) return state;
  if (!isWrapped(state)) return state.map(revert);

  return state.set(VALUE_KEY, getOriginal(state));
}

export function revertToOriginal(state, path) {
  if (typeof path === 'string') path = path.split('.');

  const statePart = state.getIn(path);

  return state.setIn(path, revert(statePart));
}

export function getIn(valuesMap, path, defaultValue) {
  if (typeof path === 'string') path = path.split('.');
  if (isWrapped(valuesMap.getIn(path))) {
    return valuesMap.getIn(path.concat(VALUE_KEY), defaultValue);
  }

  return valuesMap.getIn(path, defaultValue);
}

export function getOriginalIn(valuesMap, path, defaultValue) {
  if (typeof path === 'string') path = path.split('.');
  if (isWrapped(valuesMap.getIn(path))) {
    return valuesMap.getIn(path.concat(ORIGINAL_VALUE_KEY), defaultValue);
  }

  return valuesMap.getIn(path, defaultValue);
}

export function getOriginal(value, defaultValue) {
  if (isWrapped(value)) {
    return value.get(ORIGINAL_VALUE_KEY, defaultValue);
  }

  return value;
}

export function getChanged(valuesMap) {
  if (!valuesMap) return {};
  if (!valuesMap.get(WRAPPED_FIELDS)) return {};

  return valuesMap.get(WRAPPED_FIELDS).reduce((res, field) => {
    const path = field.split('.');
    const value = valuesMap.getIn(path);

    if (isChanged(value)) setInObject(res, path, unwrap(value));

    return res;
  }, {});
}

function toJSObject(value) {
  if (isWrapped(value)) return unwrap(value);
  if (isArray(value)) return Array.prototype.map.call(value, part => toJSObject(part));
  if (moment.isMoment(value)) return value;
  if (isObject(value))
    return Object.keys(value).reduce((res, key) => {
      res[key] = toJSObject(value[key]);

      return res;
    }, {});

  return value;
}

export function toJS(valuesMap) {
  if (Iterable.isIterable(valuesMap)) {
    return toJSObject(valuesMap.toJS());
  }

  return toJSObject(valuesMap);
}
