import { CALL_API } from '../../../middleware/api';

export const LOADED_COMPANY_CONTACT = Symbol('LOADED_COMPANY_CONTACT');
export const LOADED_COMPANY_FUNDS = Symbol('LOADED_COMPANY_FUNDS');
export const LOADING_FUNDS = Symbol('LOADING_FUNDS');

export const ADD_COMPANY_DETAIL_ENTITY = Symbol('ADD_COMPANY_DETAIL_ENTITY');
export const EDIT_COMPANY_DETAIL_ENTITY = Symbol('EDIT_COMPANY_DETAIL_ENTITY');
export const DEL_COMPANY_DETAIL_ENTITY = Symbol('DEL_COMPANY_DETAIL_ENTITY');

export const DEL_COMPANY_CONTACT = Symbol('DEL_COMPANY_CONTACT');
export const OPEN_COMPANY_CONTACT = Symbol('OPEN_COMPANY_CONTACT');
export const UPDATE_COMPANY = Symbol('UPDATE_COMPANY');
export const REVERT_UPDATE = Symbol('REVERT_UPDATE');
export const LOADED_USERS = Symbol('LOADED_USERS');
export const SAVED_COMPANY_BUYER = Symbol('SAVED_COMPANY_BUYER');
export const ERRORED_COMPANY_BUYER = Symbol('ERRORED_COMPANY_BUYER');
export const CLOSE_COMPANY_BUYER_VALIDATION_ERROR = Symbol('CLOSE_COMPANY_BUYER_VALIDATION_ERROR');

export const LOADED_TITLES = Symbol('LOADED_TITLES');
export const LOADED_COMPANY_INDUSTRIES = Symbol('LOADED_COMPANY_INDUSTRIES');
export const LOADED_COMPANY_CONTACTS = Symbol('LOADED_COMPANY_CONTACTS');

export const ERROR_SAVE_COMPANY_CONTACT = Symbol('ERROR_SAVE_COMPANY_CONTACT');
export const ERROR_SAVE_COMPANY_CONTACT_CHANNEL = Symbol('ERROR_SAVE_COMPANY_CONTACT_CHANNEL');
export const SAVED_COMPANY_CONTACT = Symbol('SAVED_COMPANY_CONTACT');
export const SAVED_COMPANY_CONTACT_CHANNEL = Symbol('SAVED_COMPANY_CONTACT_CHANNEL');
export const SAVED_COMPANY_ENTITYES = Symbol('SAVED_COMPANY_ENTITYES');
export const SAVE_COMPANY_ENTITYES_ERROR = Symbol('SAVE_COMPANY_ENTITYES_ERROR');
export const LOADED_COMPANY_ENTITYES = Symbol('LOADED_COMPANY_ENTITYES');
export const DEL_CONTACT_CHANNEL = Symbol('DEL_CONTACT_CHANNEL');
export const UPDATE_FUND = Symbol('UPDATE_FUND');
export const UPDATE_FUND_FAILED = Symbol('UPDATE_FUND_FAILED');
export const UPDATE_SORTING = Symbol('UPDATE_SORTING');

/**
 * Load users by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findUsers({ filter, page, afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
        limit: 100,
        page,
      },
      successType: LOADED_USERS,
      afterSuccess,
    },
  };
}

/**
 * Load industries by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findIndustries({ filter, page, afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/industries',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        page,
        limit: 100,
        like: filter,
      },
      afterSuccess,
      successType: LOADED_COMPANY_INDUSTRIES,
    },
  };
}

/**
 * Load industries by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findContacts({ filter, page, afterSuccess }) {
  return dispatch => {
    afterSuccess(dispatch);

    return dispatch({
      type: LOADED_COMPANY_CONTACTS,
      page,
      filter,
      response: {
        meta: {
          pagination: { currentPage: 1, totalPages: 1 },
        },
      },
    });
  };
}

export function handleUpdateCompany(info) {
  return {
    type: UPDATE_COMPANY,
    ...info,
  };
}

export function openContact({ index }) {
  return {
    type: OPEN_COMPANY_CONTACT,
    index,
  };
}

export function addDetailEntity({ name }) {
  return {
    type: ADD_COMPANY_DETAIL_ENTITY,
    entity: name,
  };
}

export function startEditDetailEntity({ name, index }) {
  return {
    type: EDIT_COMPANY_DETAIL_ENTITY,
    entity: name,
    index,
  };
}

export function getFunds({ companyId, page = 1, limit = 100, sort = '' }) {
  return {
    sort,
    limit,
    page,
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies/${companyId}/buyer_portfolio`,
      query: {
        sort: sort.replace(/[A-Z]/g, char => `_${char.toLowerCase()}`),
        limit,
        page,
      },
      startType: LOADING_FUNDS,
      successType: LOADED_COMPANY_FUNDS,
    },
  };
}

export function saveInfo({ companyId, body }) {
  return {
    fields: Object.keys(body),
    [CALL_API]: {
      method: 'put',
      unifier: `buyer put /api/v1/companies/${companyId} {${JSON.stringify(body)}}`,
      path: `/api/v1/companies/${companyId}`,
      body,
      successType: SAVED_COMPANY_BUYER,
      errorType: ERRORED_COMPANY_BUYER,
      skipGlobalErrorHandler: true,
    },
  };
}

export function closeValidationError({ field }) {
  return {
    type: CLOSE_COMPANY_BUYER_VALIDATION_ERROR,
    field,
  };
}

export function revertUpdate({ field }) {
  return {
    type: REVERT_UPDATE,
    field,
  };
}

export function updateFund({ index, field, value }) {
  return {
    type: UPDATE_FUND,
    index,
    field,
    value,
  };
}

export function updateFundDate({ index, companyId, value }) {
  return function(dispatch) {
    dispatch(
      updateFund({
        index,
        field: 'dateAcquired',
        value,
      }),
    );
    dispatch({
      [CALL_API]: {
        method: 'put',
        unifier: `buyer date acquired put /api/v1/companies/${companyId}`,
        path: `/api/v1/companies/${companyId}`,
        body: {
          date_acquired: value && value.isValid() ? value.format('YYYY-MM-DD') : null,
        },
        afterError: ({ dispatch }) => {
          dispatch(getFunds({ companyId }));
        },
        skipGlobalErrorHandler: true,
      },
    });
  };
}

export function updateFundSort(sort) {
  return {
    type: UPDATE_SORTING,
    sort,
  };
}
