import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as authActionCreators from '../actions/auth';
import LoginForm from '../components/LoginForm/LoginForm';
import { hasCode } from '../middleware/activate';

/**
 * @class
 * Login container.
 */
class Login extends PureComponent {
  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const backUrl = searchParams.get('backUrl');

    const afterSuccess = () => {
      this.props.push(backUrl);
    };

    this.props.checkAuth({ afterSuccess });

    window.addEventListener('visibilitychange', () => this.redirectToBackUrl(backUrl));
  }

  componentWillUnmount() {
    window.removeEventListener('visibilitychange', this.redirectToBackUrl);
  }

  redirectToBackUrl = url => {
    if (hasCode() && url) {
      this.props.history.replace(url);
    }
  };

  getValue(form, fieldName, id) {
    try {
      return form[fieldName].value;
    } catch (e) {
      try {
        return document.getElementById(id).value;
      } catch (e2) {
        return new window.FormData(form).get(fieldName);
      }
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    const form = event.currentTarget;
    const { location, handleInputValidation, handleLogin } = this.props;
    const inputUsername = this.getValue(form, 'username', 'inputUsername');
    const inputPassword = this.getValue(form, 'password', 'inputPassword');
    const searchParams = new URLSearchParams(location.search);
    const backUrl = searchParams.get('backUrl') || '/main';

    if (!inputUsername) return handleInputValidation('Please input username!');
    if (!inputPassword) return handleInputValidation('Please input password!');

    handleLogin({
      inputUsername,
      inputPassword,
      backUrl,
    });
  }

  handleGoogle2FAAuth(event) {
    event.preventDefault();

    const searchParams = new URLSearchParams(this.props.location.search);
    const backUrl = searchParams.get('backUrl') || '/main';
    const form = event.currentTarget;
    const confirmationCode = this.getValue(form, 'confirmationCode', 'confirmationCode');

    this.props.authGoogle2FA(confirmationCode, backUrl);
  }

  render() {
    return (
      <React.Fragment>
        <Helmet
          link={[
            {
              rel: 'prefetch',
              as: 'font',
              href: '/fonts/fontawesome-webfont.woff2',
            },
            {
              rel: 'prefetch',
              as: 'font',
              href: '/fonts/fontawesome-webfont.woff',
            },
          ]}
          title="Login Page"
        />

        <div className="container bg-width">
          <LoginForm
            {...this.props}
            onGoogle2FAAuth={event => this.handleGoogle2FAAuth(event)}
            onSubmit={event => this.handleSubmit(event)}
          />
        </div>
      </React.Fragment>
    );
  }
}

const { string, bool, func } = PropTypes;

Login.propTypes = {
  authGoogle2FA: func.isRequired,
  error: string.isRequired,
  handleInputValidation: func.isRequired,
  handleLogin: func.isRequired,
  isFetching: bool.isRequired,
};

export { Login };
export default connect(
  state => ({
    isFetching: state.auth.get('isFetching'),
    isAuthed: state.auth.get('isAuthed'),
    google2FaStatus: state.auth.get('google2FaStatus'),
    google2FaSecret: state.auth.get('google2FaSecret'),
    error: state.auth.get('error'),
    location: state.router.location,
  }),
  dispatch => ({ ...bindActionCreators(authActionCreators, dispatch), push }),
)(Login);
