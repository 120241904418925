/**
 * Update order of emails and phones in contact panel.
 * This logic will be shared between contact panel in target tab and in buyer tab.
 *
 * @param {Array(Object)} addresses List of email and phone contacts.
 * @param {Integer} contactIndex Opening contact index.
 */
export function orderContactService(dispatch, reorderChannels) {
  return (addresses, contactIndex) => {
    let emailCounter = 0;
    let phoneCounter = 0;
    const list = addresses.reduce(
      (ret, val) => {
        if (val.email) {
          ret.emails[emailCounter] = {
            contactId: val.contactId,
            deletedAt: val.deletedAt,
            id: val.id,
            email: val.email.value,
            priority: emailCounter + phoneCounter,
            type: val.type.value,
          };
          emailCounter++;
        } else {
          ret.phones[phoneCounter] = {
            contactId: val.contactId,
            deletedAt: val.deletedAt,
            id: val.id,
            phone: val.phone.value,
            priority: emailCounter + phoneCounter,
            type: val.type.value,
          };
          phoneCounter++;
        }

        return ret;
      },
      { phones: {}, emails: {} },
    );

    if (Object.keys(list.phones).length) {
      dispatch(
        reorderChannels({
          name: 'phones',
          contactId: list.phones['0'].contactId,
          data: list.phones,
          openedContact: contactIndex,
        }),
      );
    }

    if (Object.keys(list.emails).length) {
      dispatch(
        reorderChannels({
          name: 'emails',
          contactId: list.emails['0'].contactId,
          data: list.emails,
          openedContact: contactIndex,
        }),
      );
    }
  };
}
