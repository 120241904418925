import React from 'react';
import classNames from 'classnames';

import { ResizablePopup } from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Button from '../helpers/Button';
import _Input from '../helpers/Input';
import _Textarea from '../helpers/Textarea';
import DisplayError from '../decorators/DisplayError';
import PrettyError from '../decorators/PrettyError';
import config from '../../config';

const Input = PrettyError(DisplayError(_Input));
const Textarea = PrettyError(DisplayError(_Textarea));

const AddEmailTemplatePopup = props => {
  const { headerTitle, isNew, isValid, submitting, values, changed, onCreate, onClose, onChange, onDelete } = props;

  const footer = (
    <div>
      <Button key="create" className="btn-primary btn-xs" disabled={!isValid || submitting} onClick={onCreate}>
        {isNew ? 'Create' : 'Save'}
      </Button>
      <Button key="cancel" className="btn-default btn-xs" onClick={onClose}>
        Cancel
      </Button>
      {!isNew && (
        <Button key="delete" className="btn-danger btn-xs pull-left" onClick={onDelete}>
          Delete
        </Button>
      )}
    </div>
  );

  const popupDefaultPosition = {
    x: Math.floor((window.innerWidth - 700) / 2),
    y: 125,
  };

  return (
    <ResizablePopup
      {...config.createEmailTemplatePopupSize}
      defaultPosition={popupDefaultPosition}
      footer={footer}
      header={headerTitle}
      id="newEmailTemplate"
    >
      <form className="flexWrapper h100p">
        <div className="row">
          <FormGroup className="col-sm-12">
            <Input
              className={classNames('input-md', { changed: changed.label })}
              label="Label"
              name="label"
              onChange={onChange}
              placeholder="Label"
              type="type"
              value={values.label}
              autoFocus
              showLabel
            />
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup className="col-sm-12">
            <Input
              className={classNames('input-md', { changed: changed.subject })}
              label="Subject"
              name="subject"
              onChange={onChange}
              placeholder="Subject"
              type="type"
              value={values.subject}
              showLabel
            />
          </FormGroup>
        </div>
        <div className="row h100p">
          <FormGroup className="col-sm-12 all-h100p">
            <Textarea
              className={classNames('input-md', { changed: changed.body })}
              label="Body"
              name="body"
              onChange={onChange}
              placeholder="Body"
              rows="4"
              value={values.body}
              showLabel
            />
          </FormGroup>
        </div>
      </form>
    </ResizablePopup>
  );
};

export default AddEmailTemplatePopup;
