import React, { PureComponent } from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import classNames from 'classnames';

import Table, { Column, Row } from '../helpers/Table';
import uniqueId from '../../utils/uniqueId';

const ContextMenuWrapper = (onDeleteChild, onDeleteParent, onEdit, isSuper = false) => {
  const ContextMenuWrapped = (value, data) => {
    const id = uniqueId();
    const tmp = data.toJS();
    const delEl = tmp.isParent ? (
      <MenuItem data={tmp} disable={!isSuper} onClick={onDeleteParent}>
        <i className="fa fa-remove" /> Delete
      </MenuItem>
    ) : (
      <MenuItem data={tmp} disable={!isSuper} onClick={onDeleteChild}>
        <i className="fa fa-remove" /> Delete
      </MenuItem>
    );

    return (
      <div>
        <ContextMenuTrigger disable={!isSuper} id={id}>
          <div>{value}</div>
        </ContextMenuTrigger>
        <ContextMenu id={id}>
          <MenuItem data={tmp} disable={!isSuper} onClick={onEdit}>
            <i className="fa fa-pencil" /> Edit
          </MenuItem>
          {delEl}
        </ContextMenu>
      </div>
    );
  };

  return ContextMenuWrapped;
};

const sortIcon = (sort, name) => {
  const direction = sort.get(name);

  if (direction) {
    return direction > 0 ? (
      <i aria-hidden="true" className="fa fa-caret-up" />
    ) : (
      <i aria-hidden="true" className="fa fa-caret-down" />
    );
  }

  return null;
};

// Tag type
const BROAD_INDUSTRY_CATEGORY = 6;

class TagTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      height: '100%',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.mode !== nextProps.mode) {
      if (nextProps.mode === 'edit' || nextProps.mode === 'add') {
        this.setState({ height: 'calc(100% - 180px)' });
      } else {
        this.setState({ height: '100%' });
      }
    }
  }

  isIndustryTag(tag) {
    const { tagTypeId } = tag.toJS();

    if (tagTypeId === BROAD_INDUSTRY_CATEGORY) {
      return true;
    }
  }

  render() {
    const { onDelete, onClick, onDeleteParent, onEdit, tags, sort, isSuper } = this.props;
    const { height } = this.state;
    const rows = tags.map(tag => (
      <Row key={tag.get('id')} className={classNames({ 'bold-table-row': this.isIndustryTag(tag) })} data={tag} />
    ));

    return (
      <div style={{ height }}>
        <table className="table table table-striped table-hover tag-table tag-header mb0">
          <thead>
            <tr>
              <th onClick={() => onClick('category')} style={{ width: '30%', cursor: 'pointer' }}>
                Tag Name {sortIcon(sort, 'category')}
              </th>
              <th onClick={() => onClick('parent')} style={{ width: '20%', cursor: 'pointer' }}>
                Parent Category {sortIcon(sort, 'parent')}
              </th>
              <th onClick={() => onClick('tagType')} style={{ width: '18%', cursor: 'pointer' }}>
                Tag Type {sortIcon(sort, 'tagType')}
              </th>
              <th onClick={() => onClick('targetCount')} style={{ width: '8%', cursor: 'pointer' }}>
                Targets {sortIcon(sort, 'targetCount')}
              </th>
              <th onClick={() => onClick('buyerCount')} style={{ width: '8%', cursor: 'pointer' }}>
                Buyers {sortIcon(sort, 'buyerCount')}
              </th>
              <th onClick={() => onClick('contactCount')} style={{ width: '8%', cursor: 'pointer' }}>
                Execs {sortIcon(sort, 'contactCount')}
              </th>
              <th onClick={() => onClick('leadsCount')} style={{ width: '8%', cursor: 'pointer' }}>
                Leads {sortIcon(sort, 'leadsCount')}
              </th>
            </tr>
          </thead>
        </table>
        <div className="tag-panel">
          <Table bodyClassName="flexItem" className="table table-striped table-hover tag-table tag-body">
            <Column
              field="category"
              style={{ width: '30%' }}
              title="Tag Name"
              valueWrapper={ContextMenuWrapper(onDelete, onDeleteParent, onEdit, isSuper)}
            />
            <Column
              field="parent.category"
              style={{ width: '20%' }}
              title="Parent Category"
              valueWrapper={ContextMenuWrapper(onDelete, onDeleteParent, onEdit, isSuper)}
            />
            <Column
              field="tagType.type"
              style={{ width: '18%' }}
              title="Tag Type"
              valueWrapper={ContextMenuWrapper(onDelete, onDeleteParent, onEdit, isSuper)}
            />
            <Column
              field="targetCount"
              style={{ width: '8%' }}
              title="Targets"
              valueWrapper={ContextMenuWrapper(onDelete, onDeleteParent, onEdit, isSuper)}
            />
            <Column
              field="buyerCount"
              style={{ width: '8%' }}
              title="Buyer"
              valueWrapper={ContextMenuWrapper(onDelete, onDeleteParent, onEdit, isSuper)}
            />
            <Column
              field="contactCount"
              style={{ width: '8%' }}
              title="Execs"
              valueWrapper={ContextMenuWrapper(onDelete, onDeleteParent, onEdit, isSuper)}
            />
            <Column
              field="leadCount"
              style={{ width: '8%' }}
              title="Leads"
              valueWrapper={ContextMenuWrapper(onDelete, onDeleteParent, onEdit, isSuper)}
            />
            {rows}
          </Table>
        </div>
      </div>
    );
  }
}

export default TagTable;
