import config from '../config';

/**
 * Access will be allowed if a user has a role from an array.
 */
export function checkAccess(role) {
  const hasPermissions = [
    config.ADMINISTRATOR,
    config.PROCESS_MANAGER,
    config.DIRECTOR,
    config.ANALYST_ASSOCIATE,
    config.JUNIOR_ANALYST,
    config.RESEARCH_INTERN,
    config.FINANCE_ADMIN,
  ];

  return hasPermissions.includes(role.get('slug'));
}

/**
 * Check roles which can approve a Target.
 */
export function canApprove(role) {
  const hasPermissions = [
    config.ADMINISTRATOR,
    config.DIRECTOR,
    config.FINANCE_ADMIN,
    config.ANALYST_ASSOCIATE,
    config.JUNIOR_ANALYST,
    config.RESEARCH_INTERN,
  ];

  return hasPermissions.includes(role.get('slug'));
}

/**
 * Check roles is Researcher.
 *
 * @param role {string} - slug.
 */
export function isResearcher(role) {
  const hasPermissions = [config.RESEARCHER];

  return hasPermissions.includes(role);
}

/**
 * Check roles is Researcher intern.
 *
 * @param role {string} - slug.
 */
export function isResearcherIntern(role) {
  const hasPermissions = [config.RESEARCH_INTERN];

  return hasPermissions.includes(role);
}

/**
 * Check roles is Junior Analyst.
 *
 * @param role {string} - slug.
 */
export function isJuniorAnalyst(role) {
  const hasPermissions = [config.JUNIOR_ANALYST];

  return hasPermissions.includes(role);
}

/**
 * Check roles is Process Manager.
 *
 * @param role {string} - slug.
 */
export function isProcessManager(role) {
  const hasPermissions = [config.PROCESS_MANAGER];

  return hasPermissions.includes(role);
}

/**
 * Check roles is Finance Admin.
 *
 * @param role {string} - slug.
 */
export function isFinanceAdmin(role) {
  const hasPermissions = [config.FINANCE_ADMIN];

  return hasPermissions.includes(role.get('slug'));
}

export function basicAccess(role) {
  const hasPermissions = [config.RESEARCHER, config.RESEARCH_INTERN, config.JUNIOR_ANALYST];

  return hasPermissions.includes(role);
}

/**
 * Get user role.
 *
 * @param role {string} - slug.
 */
export function getUserRole(role) {
  return role.get('slug');
}

/**
 * Check whether user has access to current Browse page tab or not.
 *
 * @param {Immutable.Map} user User.
 * @param {string} tab Browse page tab ('targets' | 'buyers' | 'executives' | 'projects').
 */
export function checkBrowsePageAccess(user, tab) {
  const accesses = new Set(
    user
      .get('browseAccesses')
      .map(item => item.get('id'))
      .toJS(),
  );
  const accessKey = config.browse
    .get('accesses')
    .filter(item => item.get('name') === tab)
    .get('0')
    .get('key');

  return accesses.has(accessKey);
}
