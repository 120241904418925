import PropTypes from 'prop-types';

import React, { Children, Component } from 'react';
import { List, Map, fromJS } from 'immutable';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import DeepValue from '../../decorators/DeepValue';
import ChangeDetection from '../../decorators/ChangeDetection';
import uniquieId from '../../../utils/uniqueId';
import getZIndex from '../../../utils/getZIndex';
/**
 * Dropdown wrapper.
 *
 * @param props {Object}.
 * @param props.id {string}.
 * @param props.btnContent {string|React.Component} Content of dropdown button. Caret is added by
 *   this element. Need not to pass it.
 * @param [props.wrapperClassName] {string} Class names for main div. `"dropdown"` class
 *   is already added to element. Need not to pass it.
 * @param [props.align=`"left"`] {string} Can be `"left"` or `"right"`. If align is left,
 *   all dropdown elements will start from left side of button.
 *   If align is right - from right part.
 * @param [props.className] {string} `<button>` class name. `"btn dropdown-toggle"` classes
 *    are already added to element. Need not to pass them.
 * @param [props.value=""] {string} Current value of dropdown. Hidden input has this value.
 * @param [props.name] {string} Name attribute for input.
 * @param [props.children] {React.Component[]} DropDown's content in XML format.
 * @param [props.options] {Immutable.List} DropDown's content in js format.
 * @param [props.className] {String} Extra classNames for button tag of DD.
 * @param [props.onChange] {Function} OnDropDown change callback (need change value manual).
 * @returns {React.Component}
 */
class DropDown extends Component {
  constructor(props, context) {
    super(props, context);

    this.id = props.id || uniquieId();
    this.shouldAutoOpen = !!props.autoFocus;
    this.options = fromJS([]);
    this.onClick = this.onClick.bind(this);
    this.close = this.close.bind(this);
    this.mapOptionToChildren = this.mapOptionToChildren.bind(this);

    this.ulRef = React.createRef();
    this.rememberContRef = React.createRef();
  }

  componentDidMount() {
    this.rememberOptions();
    if (this.shouldAutoOpen) {
      this.open();
    }
  }

  componentDidUpdate() {
    this.shouldAutoOpen = false;
    this.rememberOptions();
  }

  rememberOptions() {
    let opts = List();
    const { children = [], options = [] } = this.props;

    Children.forEach(children, child => {
      opts = opts.push(fromJS(child.props));
    });
    options.forEach(props => {
      opts = opts.push(props);
    });
    this.options = opts;
  }

  mapOptionToChildren(option, i) {
    const { name } = this.props;
    const key = -1 - i;

    return <DropDownElement {...option.toJS()} key={key} name={name} />;
  }

  onClick(event) {
    this.close();
    if (!this.props.onChange) return;
    if (!event.target) return;

    const index = this.indexOfTarget(event);

    if (index === -1) return;

    const value = this.options.getIn([index, 'value']);

    if (value === undefined) return;
    event.target.name = this.props.name;
    event.target.value = value;

    return this.props.onChange(event);
  }

  indexOfTarget(event) {
    const parent = this.ulRef.current;
    let currentElement = event.target;
    let currentParent = event.target.parentNode;

    while (currentParent !== parent && currentParent) {
      [currentElement, currentParent] = [currentParent, currentParent.parentNode];
    }

    if (currentParent === parent) {
      for (let i = 0; i < parent.childNodes.length; i++) {
        if (parent.childNodes[i] === currentElement) return i;
      }
    }

    return -1;
  }

  close() {
    this.shouldAutoOpen = false;
  }

  getStyle() {
    if (!this.cont) return {};
    if (!document.documentElement) return {};

    const bodyRect = document.documentElement.getBoundingClientRect();
    const targetRect = this.cont.getBoundingClientRect();
    const top = targetRect.bottom - bodyRect.top;
    const left = targetRect.left - bodyRect.left;
    const width = Math.max(targetRect.width, 150);
    const zIndex = getZIndex(this.cont) + 10;

    return {
      position: 'absolute',
      top,
      left,
      width,
      zIndex,
    };
  }

  getOptions() {
    const { children, options = List(), align = 'left' } = this.props;
    // const { inPopup = false } = this.context;
    const ulClassName = classNames('dropdown-menu', {
      'dropdown-menu-right': align === 'right',
    });
    const optionsContent = options.map(this.mapOptionToChildren);

    const content = [];

    Children.forEach(children, child => content.push(child));
    optionsContent.forEach(child => content.push(child));

    const listContent = (
      <ul ref={this.ulRef} aria-labelledby={this.id} className={ulClassName} onClick={this.onClick}>
        {content}
      </ul>
    );

    return listContent;
  }

  render() {
    const { name, disabled = false, changed = false } = this.props;

    const wrapperClassName = classNames('dropdown', this.props.wrapperClassName);
    const className = classNames('btn dropdown-toggle', this.props.className, {
      changed,
    });

    const values = getValues(this.props);

    return (
      <div ref={this.rememberContRef} className={wrapperClassName}>
        <input name={name} type="hidden" value={values.value} />
        <button
          aria-expanded="true"
          aria-haspopup="true"
          className={className}
          data-toggle="dropdown"
          disabled={disabled}
          id={this.id}
          onClick={this.open}
          type="button"
        >
          {values.btnContent} <span className="caret" />
        </button>
        {this.getOptions()}
      </div>
    );
  }
}

DropDown.propTypes = {
  align: PropTypes.string,
  btnContent: PropTypes.any.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool, // oneOfType([PropTypes.string, PropTypes.instanceOf(Map)]),
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.instanceOf(List),
  value: PropTypes.any,
  wrapperClassName: PropTypes.string,
};

DropDown.contextTypes = {
  inPopup: PropTypes.bool,
};

/**
 * DropDown element row. Wraps content by `<li><a> ... </a></li>`.
 *
 * @param props {Object} Attributes for `<a>` tag in element.
 * @param props.value {String} Value of this dropdown element.
 * @param [props.type="anchor"] {String} One of "anchor" or "link". If "anchor" - use `<a/>`
 *    If "link" - use ReactRouter's Link element.
 * For example `{ onClick:()=>{} }`.
 * @returns {React.Component}
 */
const DropDownElement = props => {
  const { value, children = value, type = 'anchor', ...rest } = props;
  const { className: appendClassName, ...properties } = rest;
  const className = classNames('min-height', appendClassName);

  let content;

  switch (type) {
    case 'link':
      content = (
        <Link className={className} {...properties}>
          {children}
        </Link>
      );
      break;

    case 'anchor':
      content = (
        <a className={className} {...properties}>
          {children}
        </a>
      );
      break;
  }

  return <li>{content}</li>;
};

DropDownElement.defaultProps = {
  to: '#',
};

DropDownElement.propTypes = {
  children: PropTypes.any,
  option: PropTypes.instanceOf(Map),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Map)]),
};

export { DropDownElement };

export default DeepValue(ChangeDetection(DropDown));

function getValues(props) {
  const result = {};

  if ('defaultValue' in props) {
    result.defaultValue = props.defaultValue;
  }

  if ('btnContent' in props) {
    result.btnContent = props.btnContent;
    result.value = props.btnContent;
  }

  if ('value' in props) {
    result.value = props.value;
  }

  if (Map.isMap(result.defaultValue)) {
    result.btnContent = result.defaultValue.get('name');
    result.value = result.defaultValue.get('value');
  }

  if (Map.isMap(result.value)) {
    result.btnContent = result.value.get('name');
    result.value = result.value.get('value');
  }

  return result;
}
