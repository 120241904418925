import * as React from 'react';

import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import Checkbox from '../helpers/Checkbox';
import FormGroup from '../helpers/FormGroup';

const GenerateOnlineApprovalListPopup = props => {
  const footer = [
    <Button
      key="run"
      className="btn-primary btn-xs"
      disabled={props.disabled}
      onClick={props.onGenerateOnlineApprovalList}
    >
      Preview
    </Button>,
  ];

  return (
    <Popup footer={footer} header="Online Approval List" id="generateOnlineApprovalListPopup">
      <b>Optional Fields</b>
      <div className="row">
        <FormGroup className="col-sm-12 mt20 mb20">
          <Checkbox className="center-block" label="Products" name="products" onChange={props.onChange} />
        </FormGroup>
        <FormGroup className="col-sm-12 mb20">
          <Checkbox className="center-block" label="Markets" name="markets" onChange={props.onChange} />
        </FormGroup>
        <FormGroup className="col-sm-12 mb20">
          <Checkbox className="center-block" label="Facilities" name="facilities" onChange={props.onChange} />
        </FormGroup>
        <FormGroup className="col-sm-12">
          <Checkbox className="center-block" label="Custom Field" name="custom_fields" onChange={props.onChange} />
        </FormGroup>
      </div>
    </Popup>
  );
};

export default GenerateOnlineApprovalListPopup;
