import React, { PureComponent } from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';

class TableCellWrapper extends PureComponent {
  render() {
    const { data, id, onFocus, onBlur } = this.props;

    return (
      <td>
        <ContextMenuTrigger id={id.toString()}>
          <div onBlur={() => onBlur(id - 1)} onClick={() => onFocus(id - 1)}>
            {data}
          </div>
        </ContextMenuTrigger>
      </td>
    );
  }
}

export default TableCellWrapper;
