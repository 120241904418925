import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';

/**
 * Just a wrapper on form-group. Allow not to write `<div className="form-group"></div>`
 * every time you need.
 *
 * @param props {Object}.
 * @param [props.className] {String} Extra class names for `<div className="form-group"></div>`.
 * @returns {React.Component}
 */
const FormGroup = props => {
  const { className = '', ...rest } = props;
  const eleClass = classNames('form-group', className);

  return (
    <div {...rest} className={eleClass}>
      {props.children}
    </div>
  );
};

FormGroup.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default FormGroup;
