import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import Popups from '../../components/Popup';
import { getChanged } from '../../utils/ChangeSpy';
import {
  handleUpdateCompany,
  saveFiscalYear,
  revertUpdate,
  closeValidationError,
} from '../../actions/company/companyTarget';

class EditFiscalYearPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onErrorClose = this.onErrorClose.bind(this);
  }

  componentDidMount() {
    this.context.onClosePopup(() => {
      const field = this.props.pathPrefix.slice(0, -1);

      this.props.revertUpdate({ field });
    });
  }

  getChildContext() {
    return {
      onErrorClose: this.onErrorClose,
      inputErrors: this.props.inputErrors,
    };
  }

  onErrorClose(e, field) {
    this.props.closeValidationError({ field });
  }

  onChange(event) {
    const { name, value } = event.target;

    return this.props.handleUpdateCompany({
      name: `${this.props.pathPrefix}${name}`,
      value,
    });
  }

  onSave() {
    if (this.props.isValid) {
      const { isNew } = this.props;
      const { companyId } = this.props;
      const { yearId } = this.props;
      const body = getChanged(this.props.year);

      this.props.saveFiscalYear({ isNew, yearId, companyId, body }, () => {
        this.context.closePopup();
      });
    }
  }

  render() {
    const { children, year, isValid, isNew } = this.props;

    return (
      <div>
        <Popups.EditFiscalYearPopup
          isNew={isNew}
          isValid={isValid}
          onChange={this.onChange}
          onSave={this.onSave}
          year={year}
        />
        {children}
      </div>
    );
  }
}

EditFiscalYearPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
};

EditFiscalYearPopupContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};

function mapStateToProps(state, props) {
  const companyId = props.popup.getIn(['props', 'companyId']);
  const yearId = props.popup.getIn(['props', 'yearId']);
  const isNew = !parseInt(yearId, 10);
  let pathPrefix = 'newYear.';
  let year = state.targetCompany.target.getIn(['newYear']);

  if (!isNew) {
    const yearIndex = state.targetCompany.target.get('targetFiscalYears').findKey(year => year.get('id') === yearId);

    pathPrefix = `targetFiscalYears.${yearIndex}.`;
    year = state.targetCompany.target.getIn(['targetFiscalYears', yearIndex]);
  }

  return {
    isValid: true,
    isNew,
    inputErrors: year.get('inputErrors', Map()),
    companyId,
    year,
    yearId,
    pathPrefix,
  };
}

export { EditFiscalYearPopupContainer };

const Connected = connect(
  mapStateToProps,
  { handleUpdateCompany, saveFiscalYear, revertUpdate, closeValidationError },
  mergeProps,
  connectOptions,
)(EditFiscalYearPopupContainer);

export default Connected;
