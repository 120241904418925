import { CALL_API } from '../../../middleware/api';

export const FETCHING_BUYER_PROJECTS_PLATFORM = Symbol('FETCHING_BUYER_PROJECTS_PLATFORM');
export const FETCHING_BUYER_PROJECTS_PLATFORM_SUCCESS = Symbol('FETCHING_BUYER_PROJECTS_PLATFORM_SUCCESS');
export const FETCHING_BUYER_PROJECTS_PLATFORM_FAILURE = Symbol('FETCHING_BUYER_PROJECTS_PLATFORM_FAILURE');
export const FETCHING_BUYER_PROJECTS_ADDON = Symbol('FETCHING_BUYER_PROJECTS_ADDON');
export const FETCHING_BUYER_PROJECTS_ADDON_SUCCESS = Symbol('FETCHING_BUYER_PROJECTS_ADDON_SUCCESS');
export const FETCHING_BUYER_PROJECTS_ADDON_FAILURE = Symbol('FETCHING_BUYER_PROJECTS_ADDON_FAILURE');
export const UPDATE_PROJECTS = Symbol('UPDATE_PROJECTS');

export function getBuyerProjectsPlatform({ companyId, page = 1, sortBy = null, afterSuccess }) {
  const query = {
    page,
    limit: 100,
  };

  if (sortBy) {
    query.sort = sortBy;
  }

  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/buyer_projects_platform/${companyId}`,
      query,
      startType: FETCHING_BUYER_PROJECTS_PLATFORM,
      errorType: FETCHING_BUYER_PROJECTS_PLATFORM_FAILURE,
      successType: FETCHING_BUYER_PROJECTS_PLATFORM_SUCCESS,
      afterSuccess,
    },
  };
}

export function getBuyerProjectsAddon({ companyId, page = 1, sortBy = null, afterSuccess }) {
  const query = {
    page,
    limit: 100,
  };

  if (sortBy) {
    query.sort = sortBy;
  }

  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/buyer_projects_addon/${companyId}`,
      query,
      startType: FETCHING_BUYER_PROJECTS_ADDON,
      errorType: FETCHING_BUYER_PROJECTS_ADDON_FAILURE,
      successType: FETCHING_BUYER_PROJECTS_ADDON_SUCCESS,
      afterSuccess,
    },
  };
}

export function getStrategicBuyerProjects({ companyId, afterSuccess }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies/${companyId}`,
      query: {
        include: 'buyer_addon_projects',
      },
      afterSuccess,
    },
  };
}

export function updateProjects({ filterName, filterData }) {
  return {
    type: UPDATE_PROJECTS,
    filterName,
    filterData,
  };
}

export function createPlatformProject({ body, callback: { beforeStart, afterSuccess, afterError } }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/projects',
      body,
      beforeStart,
      afterSuccess,
      afterError,
    },
  };
}
