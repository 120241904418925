import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import connectOptions, { mergeProps } from '../../utils/connectOptions';
import Popups from '../../components/Popup';

/**
 *  Deleting confirmation popup container.
 *
 * @param props {Object}.
 * @param {Function} props.delete Function to call deleting api.
 * @param {boolean} props.isPopupFetching Show spinner icon and disable ok button if true.
 * @param {string} props.message Popup message.
 * @param {string} props.title Popup title.
 * @returns {React.Component}
 * @class
 */
class DeleteConfirmPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.onOk = this.onOk.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onOk() {
    if (!this.props.isPopupFetching) {
      this.props.delete();
    }
  }

  onCancel() {
    this.context.closePopup();
  }

  render() {
    const {
      isPopupFetching,
      message = 'Are you sure you want to delete the selected database record(s)? The records and all associate data will be permanently deleted.',
      title = 'Delete selected',
    } = this.props;
    const header = (
      <span>
        <i aria-hidden="true" className="fa fa-exclamation-triangle text-danger" />
        {title}
      </span>
    );

    return (
      <div>
        <Popups.ConfirmPopup
          header={header}
          message={message}
          onCancel={this.onCancel}
          onOk={this.onOk}
          waiting={isPopupFetching}
        />
      </div>
    );
  }
}

DeleteConfirmPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
};

DeleteConfirmPopupContainer.propTypes = {
  delete: PropTypes.func.isRequired,
  isPopupFetching: PropTypes.bool.isRequired,
  message: PropTypes.string,
  title: PropTypes.string,
};

function mapStateToProps(state, props) {
  const { popup } = props;

  return {
    isPopupFetching: popup.get('isPopupFetching'),
    delete: popup.getIn(['props', 'delete']),
    message: popup.getIn(['props', 'message']),
    title: popup.getIn(['props', 'title']),
  };
}

export default connect(mapStateToProps, {}, mergeProps, connectOptions)(DeleteConfirmPopupContainer);
