import { CALL_API } from '../../../../middleware/api';

export const LOADING_TARGETS = Symbol('LOADING_TARGETS');
export const LOADED_TARGETS_FAILURE = Symbol('LOADED_TARGETS_FAILURE');
export const LOADED_TARGETS_SUCCESS = Symbol('LOADED_TARGETS_SUCCESS');
export const SORT_TARGETS = Symbol('SORT_TARGETS');

/**
 * Load target list.
 *
 * @param id {Number} Project's id.
 * @returns Action.
 */
export function loadTargets(id) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/${id}`,
      query: { include: 'targets' },
      startType: LOADING_TARGETS,
      errorType: LOADED_TARGETS_FAILURE,
      successType: LOADED_TARGETS_SUCCESS,
    },
  };
}

/**
 * Sort target list.
 *
 * @param field {String} Field name.
 * @param direction {up|down} Sorting direction.
 * @returns Action.
 */
export function sortTarget(field, direction) {
  return {
    type: SORT_TARGETS,
    field,
    direction,
  };
}
