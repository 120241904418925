import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import { Map } from 'immutable';
import classNames from 'classnames';
import uniqueId from '../utils/uniqueId';
import { unwrap, isChanged } from '../utils/ChangeSpy';
import { fetch, fullLoad } from '../actions/country';

class CountryDropDown extends PureComponent {
  constructor(props) {
    super(props);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.setValue = this.setValue.bind(this);
    this.state = {
      countries: [],
    };
  }

  componentDidMount() {
    this.props.fetch();
  }

  componentDidUpdate() {
    const { fetch, fullLoad, countries } = this.props;
    const totalPages = countries.getIn(['pagination', 'totalPages']);
    const currentPage = countries.getIn(['pagination', 'currentPage']);
    const loaded = countries.get('loaded');

    if (!loaded) {
      if (currentPage >= totalPages) {
        fullLoad();
      } else {
        fetch(currentPage + 1);
      }
    }
  }

  onSuggestionSelected(event, selection) {
    if (this.props.onSelect) {
      this.props.onSelect(selection.suggestion);
    }
  }

  renderSuggestion(suggestion) {
    return <div>{suggestion.text}</div>;
  }

  onSuggestionsFetchRequested({ value }) {
    if (value) {
      const upperValue = value.toUpperCase();

      this.setState({
        countries: this.props.countries
          .get('data')
          .filter(c =>
            c
              .get('text')
              .toUpperCase()
              .includes(upperValue),
          )
          .toJS(),
      });
    }
  }

  onSuggestionsClearRequested() {
    this.setState({
      countries: [],
    });
  }

  getSuggestionValue(suggestion) {
    return suggestion.id;
  }

  onChange(event, { method }) {
    if (this.props.onChange && method === 'type') {
      this.props.onChange(event);
    }
  }

  onBlur(event, { highlightedSuggestion }) {
    const { countries } = this.props;

    if (highlightedSuggestion) {
      this.setValue(highlightedSuggestion.id, highlightedSuggestion.text);
    } else {
      const value = (event.target.value || '').toUpperCase();
      const index = countries.get('data').findIndex(c => c.get('text').toUpperCase() === value);

      if (index && index >= 0) {
        this.setValue(countries.getIn(['data', index, 'id']), countries.getIn(['data', index, 'text']));
      } else {
        this.setValue();
      }
    }
  }

  setValue(id, text) {
    if (this.props.onSelect) {
      if (id > 0) {
        this.props.onSelect({
          id,
          text,
        });
      } else {
        this.props.onSelect({
          id: null,
          text: '',
        });
      }
    }
  }

  render() {
    const { id = uniqueId(), className = '', value = '', name, disabled = false } = this.props;
    let realValue = value;
    let changed = null;

    if (Map.isMap(value)) {
      realValue = unwrap(value);
      changed = isChanged(value);
    }

    const inputProps = {
      className: classNames(className, 'form-control', { changed }),
      id,
      name,
      disabled,
      placeholder: 'Country',
      value: realValue,
      onChange: this.onChange,
      onBlur: this.onBlur,
    };

    return (
      <Autosuggest
        getSuggestionValue={this.getSuggestionValue}
        inputProps={inputProps}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        renderSuggestion={this.renderSuggestion}
        suggestions={this.state.countries}
        highlightFirstSuggestion
      />
    );
  }
}

CountryDropDown.propTypes = {
  countries: PropTypes.instanceOf(Map).isRequired,
  fetch: PropTypes.func.isRequired,
  fullLoad: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    ...props,
    countries: state.countries,
  };
}

export default connect(mapStateToProps, {
  fetch,
  fullLoad,
})(CountryDropDown);
