import contact from './contact';
import project from './project';
import nextaction from './nextaction';
import approval from './approval';
import user from './user';
import backlog from './backlog';
import fund from './fund';
import tradeshow from './tradeshow';
import leadsreport from './leadsreport';

export default {
  contact,
  project,
  nextaction,
  approval,
  backlog,
  fund,
  user,
  tradeshow,
  leadsreport,
};
