import { fromJS } from 'immutable';

import {
  LOADING_TARGETS,
  LOADED_TARGETS_SUCCESS,
  LOADED_SORTED_TARGETS_SUCCESS,
  CHANGE_TARGET,
  SAVED_TARGET,
  RELOADED_TARGETS_SUCCESS,
  RELOADING_TARGETS,
} from '../../actions/project/target';
import { spy, setIn, setOriginalIn } from '../../utils/ChangeSpy';

const defaultState = fromJS({ data: [], pagination: {} });

export default function(state = defaultState, action) {
  switch (action.type) {
    case LOADED_TARGETS_SUCCESS:
      return getTargets(state, action.response);

    case RELOADED_TARGETS_SUCCESS:
      return getTargets(state, action.response, true);

    case RELOADING_TARGETS:

    // fallthrough
    case LOADING_TARGETS:
      return state;

    case LOADED_SORTED_TARGETS_SUCCESS:
      return getSortedTargets(state, action.response);

    case CHANGE_TARGET: {
      const index = state.get('data').findKey(target => target.get('id') === action.id);

      if (index === -1) return state;

      return setIn(state, `data.${index}.${action.field}`, action.value);
    }

    case SAVED_TARGET: {
      const index = state.get('data').findKey(target => target.get('id') === action.id);

      if (index === -1) return state;

      if (action.body.approved === null) {
        action.body.approved = null;
      }

      const target = setIn(state, `data.${index}.processing`, false);

      return setOriginalIn(target, `data.${index}.approved`, action.body.approved);
    }

    default:
      return state;
  }
}

/**
 * Get targets from response.
 *
 * @param state {Object} Current state.
 * @param response {Object} Response.
 * @param isReload {Boolean} If false concat data, for lazy loading.
 * @return {Immutable.List}
 */
function getTargets(state, response, isReload = false) {
  if (response.status === 'success') {
    const data = response.data.map(t =>
      spy(
        {
          ...t,
          id: t.id,
          curr: t.currentStatus,
          high: t.highStatus,
          target: t.legalName,
          appList: t.applistLabel,
          priority: t.priority,
          approved: t.approved === '' ? null : t.approved,
          revenue: t.revenue,
          employees: t.employees,
          ebitda: t.ebitda,
          city: t.city,
          state: t.state,
          web: t.web,
          harvcoLead: t.harvcoLead,
          processing: false,
        },
        ['priority', 'approved'],
      ),
    );

    if (isReload) {
      return fromJS({
        data,
        pagination: response.meta.pagination,
      });
    }

    return fromJS({
      data: state
        .get('data')
        .toJS()
        .concat(data),
      pagination: response.meta.pagination,
    });
  }

  return defaultState;
}

/**
 * Get sorted targets from response.
 *
 * @param state {Object} Current state.
 * @param response {Object} Response.
 * @returns {Immutable.List}
 */
function getSortedTargets(state, response) {
  if (response.status === 'success') {
    return fromJS({
      data: response.data.map(t =>
        spy(
          {
            ...t,
            id: t.id,
            curr: t.currentStatus,
            high: t.highStatus,
            target: t.legalName,
            appList: t.applistLabel,
            priority: t.priority,
            approved: t.approved === '' ? null : t.approved,
            revenue: t.revenue,
            employees: t.employees,
            ebitda: t.ebitda,
            city: t.city,
            state: t.state,
            web: t.web,
            harvcoLead: t.harvcoLead,
            processing: false,
          },
          ['priority', 'approved'],
        ),
      ),
      pagination: state.get('pagination'),
    });
  }

  return defaultState;
}
