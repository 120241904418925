import React, { PureComponent } from 'react';

import Popup from '../../helpers/Popup';
import FormGroup from '../../helpers/FormGroup';
import Button from '../../helpers/Button';
import _Input from '../../helpers/Input';
import ErrorDisplay from '../../decorators/DisplayError';
import PrettyError from '../../decorators/PrettyError';
import { unwrap } from '../../../utils/ChangeSpy';

const Input = PrettyError(ErrorDisplay(_Input));

class ExtensionFormat extends PureComponent {
  componentDidMount() {
    const { isNew, onChange, companyInfo } = this.props;
    const companyPhone = unwrap(companyInfo.get('phone'));

    if (isNew) {
      onChange({ target: { name: 'unformattedPhone', value: companyPhone } });
    }
  }

  render() {
    const { isNew, onChange, channel, onSave, onUndo, onBlur } = this.props;

    const header = (
      <div>
        {`${isNew ? 'Create' : 'Change'} contact profile info`} - Extension{' '}
        <a href="#" onClick={onUndo} title="undo">
          undo
        </a>
      </div>
    );

    const footer = (
      <Button className="btn-primary btn-xs" onClick={onSave}>
        {isNew ? 'Create' : 'Save'}
      </Button>
    );

    return (
      <Popup footer={footer} header={header} id="extFormatContact">
        <div className="row">
          <FormGroup className="col-sm-5">
            <Input
              className="input-md"
              label="Phone"
              maxLength="50"
              name="unformattedPhone"
              onBlur={onBlur}
              onChange={onChange}
              placeholder="Phone"
              root={channel}
              autoFocus
              deep
              showLabel
            />
          </FormGroup>
          <FormGroup className="col-sm-3">
            <Input
              className="input-md"
              label="Extension"
              maxLength="20"
              name="extension"
              onBlur={onBlur}
              onChange={onChange}
              placeholder="Extention"
              root={channel}
              canDisplayError
              deep
              showLabel
            />
          </FormGroup>
          <FormGroup className="col-sm-4">
            <_Input className="input-md" label="Type" name="type" placeholder="Type" value="Extension" showLabel />
          </FormGroup>
        </div>
      </Popup>
    );
  }
}

export default ExtensionFormat;
