import React from 'react';
import { Map } from 'immutable';
import { isChanged, unwrap } from '../../utils/ChangeSpy';

export function PureChangeDetection(Component) {
  /**
   * ChangeDetection decorator.
   *
   * @param props Any extra props will passed into children.
   * @param props.value {*} Should be Immutable.Map to activate it.
   * @class
   */
  class PureChangeDetectionWrapped extends React.Component {
    render() {
      const { props } = this;
      const { checkOn = 'value', ...rest } = props;
      const value = props[checkOn];

      if (!Map.isMap(value)) return <Component {...rest} />;

      const realValue = unwrap(value);
      const changed = isChanged(value);

      const valueProperty = { [checkOn]: realValue };

      return <Component {...rest} changed={changed} {...valueProperty} />;
    }
  }

  PureChangeDetectionWrapped.displayName = `PureChangeDetection(${Component.displayName || Component.name})`;

  return PureChangeDetectionWrapped;
}

export default function ChangeDetection(Component) {
  /**
   * ChangeDetection decorator.
   *
   * @param props Any extra props will passed into children.
   * @param props.value {*} Should be Immutable.Map to activate it.
   * @class
   */
  const ChangeDetectionWrapped = props => {
    const { checkOn = 'value', ...rest } = props;
    const value = props[checkOn];

    if (!Map.isMap(value)) return <Component {...rest} />;

    const realValue = unwrap(value);
    const changed = isChanged(value);

    const valueProperty = { [checkOn]: realValue };

    return <Component {...rest} changed={changed} {...valueProperty} />;
  };

  ChangeDetectionWrapped.displayName = `ChangeDetection(${Component.displayName || Component.name})`;

  return ChangeDetectionWrapped;
}

function CheckboxChangeDetection(Component) {
  /**
   * ChangeDetection decorator.
   *
   * @param props Any extra props will passed into children.
   * @param props.value {*} Should be Immutable.Map to activate it.
   * @class
   */
  const CheckboxChangeDetection = props => {
    const { checked, ...rest } = props;

    if (!Map.isMap(checked)) return <Component {...rest} />;

    const realChecked = unwrap(checked);
    const changed = isChanged(checked);

    return <Component {...props} changed={changed} checked={realChecked} />;
  };

  CheckboxChangeDetection.displayName = `CheckboxChangeDetection(${Component.displayName || Component.name})`;

  return CheckboxChangeDetection;
}

export { CheckboxChangeDetection };
