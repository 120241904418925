import React from 'react';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Input from '../helpers/Input';
import Button from '../helpers/Button';

// TODO replace boilerplate by components
const NewAddFinancePopup = () => {
  const header = 'Add new year to financials';
  const footer = [
    <Button key="create" className="btn-primary btn-xs" data-dismiss="modal">
      Add
    </Button>,
    <Button key="cancel" className="btn-default btn-xs" data-dismiss="modal">
      Cancel
    </Button>,
  ];

  return (
    <Popup footer={footer} header={header} id="newAddFinans">
      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Input className="input-sm" id="f-fy" label="FY" name="fy" placeholder="FY" autoFocus required showLabel />
        </FormGroup>
      </div>
    </Popup>
  );
};

export default NewAddFinancePopup;
