const DEFAULT_PREFIX = '__default__';
let increment = 1;

export default function getUnifier(params, isOriginallySingleCall) {
  if (!isOriginallySingleCall) return `${DEFAULT_PREFIX}${increment++}`;

  if (typeof params.unifier === 'function') {
    return params.unifier(params);
  }

  if (typeof params.unifier === 'string') {
    return params.unifier;
  }

  if (params.method && params.method.toLowerCase() !== 'get') {
    return `NOT_GET (${params.method}) ${DEFAULT_PREFIX}${increment++}`;
  }

  return `${params.method} ${params.path} ${JSON.stringify(params.query)}`;
}
