import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';

import FilterList from './FilterList';

/**
 * Stateless component for showing filter panel.
 *
 * @param props {Object}.
 * @param props.onFilterChange {function} To handle checkbox changings.
 * @param props.typeFilters {Immutable.Map} Approach type filters data.
 * @param props.mailFilters {Immutable.Map} Mailing filters data.
 * @param props.activityFilters {Immutable.Map} Activity filters data.
 * @param props.userFilters {Immutable.Map} User type filters data.
 * @returns {React.Component}
 */
const Filter = props => {
  const { onFilterChange, typeFilters, mailFilters, activityFilters, userFilters } = props;

  /**
   * Because this event will be debounced, so we should process data first before returning to prevent issue about synthetic event.
   *
   * @param synthetic Event.
   */
  const onChange = event => {
    const { name, value, checked } = event.target;

    onFilterChange({
      target: {
        name,
        value,
        checked,
      },
    });
  };

  return (
    <form onChange={onChange}>
      <div className="mt20 row">
        <div className="col-xs-4 col-sm-4 col-md-12 col-lg-12 w50">
          <p className="mb10">Filters</p>
          <FilterList filters={typeFilters} />
          <FilterList filters={mailFilters} />
          <FilterList filters={activityFilters} />
          <FilterList capitalizeLabel={false} filters={userFilters} />
        </div>
      </div>
    </form>
  );
};

Filter.propTypes = {
  activityFilters: PropTypes.instanceOf(Map),
  mailFilters: PropTypes.instanceOf(Map),
  onFilterChange: PropTypes.func.isRequired,
  typeFilters: PropTypes.instanceOf(Map),
  userFilters: PropTypes.instanceOf(Map),
};

export default Filter;
