import { CALL_API } from '../../middleware/api';

export const LOAD_CONTACT_TARGET = Symbol('LOAD_CONTACT_TARGET');
export const ERROR_CONTACT_TARGET = Symbol('ERROR_CONTACT_TARGET');
export const LOADED_CONTACT_TARGET = Symbol('LOADED_CONTACT_TARGET');

/**
 * Load contact targets list.
 *
 * @param id {Number} Contact id.
 * @returns {MiddlewareApi.CallApi}
 */
export function loadContactTarget({ id }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/people/${id}/targets`,
      startType: LOAD_CONTACT_TARGET,
      successType: LOADED_CONTACT_TARGET,
      errorType: ERROR_CONTACT_TARGET,
    },
  };
}
