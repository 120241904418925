import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import Immutable, { Map } from 'immutable';

import config from '../../config';
import { isResearcher } from '../../utils/checkPermissions';
import { showError } from '../../utils/MessagePopup';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

class ContactExecutiveCreateContainer extends Component {
  componentDidMount() {
    if (!this.context.currentUser) return;

    if (!this.hasAccess()) {
      showError(this.context.openPopup, config.permisionError, () => {
        this.props.history.push('/');
        this.context.closePopup();
      });
    }

    if (this.context.hasPopup()) return;
    this.context.openPopup('AddExecutiveContactPopup');
  }

  componentDidUpdate() {
    this.componentDidMount();
  }

  hasAccess() {
    if (!this.context.currentUser) return false;

    return !isResearcher(this.context.currentUser.getIn(['roles', 0, 'slug'], null));
  }

  render() {
    return <Helmet title="Create Executive Contact" />;
  }
}

ContactExecutiveCreateContainer.contextTypes = {
  hasPopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Immutable.Map).isRequired,
  closePopup: PropTypes.func.isRequired,
};

ContactExecutiveCreateContainer.contextTypes = {
  currentUser: PropTypes.instanceOf(Map),
  hasPopup: PropTypes.func,
  openPopup: PropTypes.func,
};

export { ContactExecutiveCreateContainer };
export default connect(() => ({}), {}, mergeProps, connectOptions)(ContactExecutiveCreateContainer);
