import React from 'react';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Select from '../helpers/Select';
import Button from '../helpers/Button';
import { InputProps } from '../helpers/AutoComplete';

const AddCompanyBuyerPopup = props => {
  const {
    onCreate,
    onClose,
    onChange,
    buyer,
    statuses,
    suggestBuyer,
    suggestProject,
    suggestApprovalList,
    isValid,
  } = props;

  const header = 'Add buyer record';
  const footer = [
    <Button key="create" className="btn-primary btn-xs" disabled={!isValid} onClick={onCreate}>
      Create
    </Button>,
    <Button key="cancel" className="btn-default btn-xs" onClick={onClose}>
      Cancel
    </Button>,
  ];

  return (
    <Popup footer={footer} header={header} id="newAddBuyer">
      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <InputProps className="form-control input-sm text-bold" label="Buyer" placeholder="Buyer" autoFocus>
            {suggestBuyer}
          </InputProps>
        </FormGroup>
        <FormGroup className="col-lg-6 col-sm-6">
          <InputProps className="form-control input-sm text-bold" label="Project" placeholder="Project">
            {suggestProject}
          </InputProps>
        </FormGroup>
      </div>
      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <InputProps className="form-control input-sm text-bold" label="Approval List" placeholder="Approval List">
            {suggestApprovalList}
          </InputProps>
        </FormGroup>
        <FormGroup className="col-lg-6 col-sm-6">
          <Select
            emptyVal=""
            label="Current Status"
            name="currentStatus"
            onChange={onChange}
            options={statuses}
            root={buyer}
            selectClassName="input-sm text-bold"
            deep
            showLabel
            showValue
          />
        </FormGroup>
      </div>
    </Popup>
  );
};

export default AddCompanyBuyerPopup;
