import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { fromJS } from 'immutable';
import { EventEmitter } from 'fbemitter';
import 'react-sortable-tree/style.css';

import initAppSpy from './client/getAppState';
import configureStore from './store/configureStore';
import RootRouter from './routing/RootRouter';
/*
 * Append styles for client build.
 * It will be built by Webpack.
 * It is placed here because:
 *  - node.js can not require stylesheets
 *  - server does not need style at all
 */
import './styles/style.scss';
import 'react-input-range/lib/css/index.css';
/**
 * Append react-datepicker styles.
 */
import '@vespaiach/react-datepicker/dist/react-datepicker.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-fresh.css';

/*
 * Activate bootstrap bindings (dropdowns, modals and so) on client.
 * It is needed only on client side.
 */
import './client/bootstrap_bind';

window.__emitter__ = new EventEmitter();

const reduxState = {};

if (window.__REDUX_STATE__) {
  try {
    const plain = JSON.parse(decodeURIComponent(window.__REDUX_STATE__));

    Object.keys(plain).forEach(key => {
      reduxState[key] = fromJS(plain[key]);
    });
  } catch (e) {
    console.warn('Error in ReduxState parse. Use empty state.', e);
  }
}

const store = configureStore(reduxState);

initAppSpy(store);

render(
  <Provider store={store}>
    <RootRouter />
  </Provider>,
  document.getElementById('root'),
);
