import { fromJS, List, Map } from 'immutable';
import moment from 'moment';

import * as ActionType from '../actions/search';
import { getSafeValue, joinString } from '../utils/string';

const defaultState = fromJS({
  people: [],
  companies: [],
  searchingPeople: false,
  searchingCompanies: false,
  errors: {},
  searchingTerm: '',
  companiesNextPage: 0,
  peopleNextPage: 0,
  activeTab: 'companies',
  totalPeople: 0,
  totalCompanies: 0,
  firstComming: {
    // Will help to determine which tab should be shown first
    loaded: false,
    peopleLoaded: false,
    companyLoaded: false,
  },
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionType.SEARCHING_PEOPLE:
      return state.merge({
        searchPeopleId: action.searchId,
        searchingPeople: true,
        people: List(),
        peopleNextPage: 0,
      });

    case ActionType.SEARCHING_NEXT_PEOPLE:
      return state.set('searchingPeople', true);

    case ActionType.SEARCH_PEOPLE_SUCCESS: {
      if (action.searchId !== state.get('searchPeopleId')) return state;

      const tmp = state.mergeDeep({
        people: action.isFirstPage
          ? getPeople(state, action.response)
          : state.get('people').concat(getPeople(state, action.response)),
        peopleNextPage: getNextPage(action.response),
        searchingPeople: false,
        totalPeople: getTotal(action.response),
        firstComming: {
          peopleLoaded: true,
        },
      });

      return switchTab(tmp);
    }

    case ActionType.SEARCH_PEOPLE_FAILURE:
      return state.mergeDeep({
        searchingPeople: false,
        errors: Map({
          people: 'Error in searching people',
        }),
      });

    case ActionType.SEARCHING_COMPANIES:
      return state.merge({
        searchCompaniesId: action.searchId,
        searchingCompanies: true,
        companies: List(),
        companiesNextPage: 0,
      });

    case ActionType.SEARCHING_NEXT_COMPANIES:
      return state.set('searchingCompanies', true);

    case ActionType.SEARCH_COMPANIES_SUCCESS: {
      if (action.searchId !== state.get('searchCompaniesId')) return state;

      const tmp = state.mergeDeep({
        companies: action.isFirstPage
          ? getCompanies(state, action.response)
          : state.get('companies').concat(getCompanies(state, action.response)),
        companiesNextPage: getNextPage(action.response),
        searchingCompanies: false,
        totalCompanies: getTotal(action.response),
        firstComming: {
          companyLoaded: true,
        },
      });

      return switchTab(tmp);
    }

    case ActionType.SEARCH_COMPANIES_FAILURE:
      return state.mergeDeep({
        searchingCompanies: false,
        errors: Map({
          companies: 'Error in searching companies',
        }),
      });

    case ActionType.CHANGE_SEARCHING_TERM:
      return state.merge({
        searchingTerm: action.searchingTerm,
      });

    case ActionType.CHANGE_TAB:
      return state.set('activeTab', action.tab);

    default:
      return state;
  }
};

/**
 * Get people list from response.
 *
 * @param state {Object} Current state.
 * @param response {Object} Response.
 * @returns people {Immutable.List} people list.
 */
const getPeople = (state, response) => {
  if (response.status === 'success') {
    return response.data.map(p =>
      fromJS({
        id: p.id,
        personName: getSafeValue(p.fullName, ''),
        personNickName: p.nick,
        companyName: p.companyLegalName,
        personTitle: p.companyTitle,
        execCurrentStatus: getExecCurrentStatus(p),
        execHighStatus: getExecHighStatus(p),
        lpCurrentStatus: getLPCurrentStatus(p),
        lpHighStatus: getLPHighStatus(p),
        fund: getFund(p),
        fundName: getFundName(p),
        industry: p.execIndustryLabel,
        nextAction: getNextAction(p),
        lastAction: getLastAction(p),
        owner: getRecordOwner(p),
        type: p.execCurrentStatus || p.execHighStatus ? 'exec' : p.lpCurrentStatus || p.lpHighStatus ? 'lp' : '',
      }),
    );
  }

  return Map();
};

/**
 * Get target list from response.
 *
 * @param state {Object} Current state.
 * @param response {Object} Response.
 * @returns target {Immutable.List} target list.
 */
const getCompanies = (state, response) => {
  if (response.status === 'success') {
    return response.data.map(p =>
      Map({
        id: p.id,
        companyName: p.legalName,
        abbreviatedName: p.abbrName,
        entityType: p.companyType,
        headQuarters: getHeadQuarter(p),
        primaryContact: p.contactFullName,
        contactTitle: getContactTitle(p),
        recordOwnerId: p.recordOwnerId,
        targetStatus: getTargetStatus(p),
        targetRecordOwner: getTargetRecordOwner(p),
        assignedToTarget: getActiveBuyer(p),
        buyerType: p.buyerType,
        buyerStatus: getBuyerTarget(p),
        nextAction: getNextAction(p),
        lastAction: getLastAction(p),
        parentCompany: getParentName(p),
        type: p.recordOwnerId ? 'target' : p.buyerType ? 'buyer' : '',
        website: p.website,
      }),
    );
  }

  return Map();
};

/**
 * Get target record owner.
 *
 * @param p {Object} Response object.
 * @returns {string}
 */
const getTargetRecordOwner = p => joinString('/ ', p.recordOwnerUserName, p.recordSubOwnerUserName);

/**
 * Get address.
 *
 * @param p {Object} Response object.
 * @returns {string}
 */
const getHeadQuarter = p => joinString(', ', getSafeValue(p.city, ''), getSafeValue(p.state, ''));

/**
 * Get record owner user name.
 *
 * @param p {Object} Response object.
 * @returns {string}
 */
const getRecordOwner = p => {
  if (p.recordSubOwnerUserName) {
    return `${p.recordOwnerUserName}/ ${p.recordSubOwnerUserName}`;
  }

  return p.recordOwnerUserName;
};

/**
 * Get next action.
 *
 * @param p {Object} Response object.
 * @returns {string}
 */
const getNextAction = p => {
  const date = moment(p.naDate);

  return `${date.isValid() ? date.format('L') : ''} - ${getSafeValue(p.naActivity, '')}`;
};

/**
 * Get next action.
 *
 * @param p {Object} Response object.
 * @returns {string}
 */
const getLastAction = p => {
  const date = moment(p.laDate);

  return `${date.isValid() ? date.format('L') : ''} - ${getSafeValue(p.laActivity, '')}`;
};

/**
 * Get exec high status from response.
 *
 * @param person {Object} Person object.
 * @returns {string}
 */
const getFundName = person => {
  let ret = '';

  if (person.recordOwnerId) {
    ret = person.execActiveFundCoalAbbrName;
    if (!person.execActiveFundCoalAbbrName) {
      ret = `no active fund${
        person.execActiveFundEprojectCategory ? ` - ${person.execActiveFundEprojectCategory}` : ''
      }`;
    }
  }

  return ret;
};

/**
 * Get quality, interest and relationship from response.
 *
 * @param person {Object} Person object.
 * @returns {string}
 */
const getFund = person => (person.recordOwnerId ? [person.quality, person.interest, person.relationship] : []);

/**
 * Get exec high status from response.
 *
 * @param person {Object} Person object.
 * @returns {string}
 */
const getLPHighStatus = person => (person.lpPrimaryRelId ? person.lpHighStatus : '');

/**
 * Get exec current status from response.
 *
 * @param person {Object} Person object.
 * @returns {string}
 */
const getLPCurrentStatus = person => (person.lpPrimaryRelId ? person.lpCurrentStatus : '');

/**
 * Get exec high status from response.
 *
 * @param person {Object} Person object.
 * @returns {string}
 */
const getExecHighStatus = person => (person.recordOwnerId ? person.execHighStatus : '');

/**
 * Get exec current status from response.
 *
 * @param person {Object} Person object.
 * @returns {string}
 */
const getExecCurrentStatus = person => (person.recordOwnerId ? person.execCurrentStatus : '');

/**
 * Get buyer status from response.
 *
 * @param p {Object} Project object.
 * @returns {string}
 */
const getBuyerTarget = p => joinString('/ ', p.buyerCurrentStatus, p.buyerHighStatus);

/**
 * Get target status from response.
 *
 * @param project {Object} Project object.
 * @returns {string}
 */
const getTargetStatus = project =>
  joinString('/ ', getSafeValue(project.targetCurrentStatus, '0.00'), project.targetHighStatus);

/**
 * Get the next page.
 *
 * @param response {Object} Response.
 * @returns {string}
 */
const getNextPage = response => {
  if (response.status === 'success') {
    const { pagination } = response.meta;

    return pagination.currentPage < pagination.totalPages ? ++pagination.currentPage : 0;
  }

  return '';
};

/**
 * Get total record from response.
 *
 * @param response {Object} Response.
 * @returns {Immutable.List}
 */
const getTotal = response => {
  if (response.status === 'success') {
    return response.meta.pagination.total;
  }

  return 0;
};

/**
 * Get contact title from response.
 *
 * @param project {Object} Project object.
 * @returns {string}
 */
const getContactTitle = project => {
  if (project.contactTitle === 'Advisor' && project.contactAdvCompany) {
    return project.contactAdvTitle
      ? project.contactAdvTitle
      : joinString(', ', project.contactTitle, project.advCompany);
  }

  if (project.contactEntityId !== project.id) {
    return joinString(
      ', ',
      project.contactTitle,
      project.contactEntityAbbrName ? project.contactEntityAbbrName : project.contactEntityLegalName,
    );
  }

  return project.contactTitle;
};

/**
 * Get active buyer from response.
 *
 * @param project {Object} Project object.
 * @returns {string}
 */
const getActiveBuyer = project => {
  let ret = '';

  ret += `${project.targetActiveBuyerCoalAbbrName ? project.targetActiveBuyerCoalAbbrName : 'no active buyer'} - `;

  return ret + getProjectInfo(project);
};

/**
 * Get project info.
 *
 * @param project {Object} Project object.
 * @returns {string}
 */
const getProjectInfo = project => {
  let ret = '';

  ret += project.targetActiveBuyerProjectCategory ? project.targetActiveBuyerProjectCategory : 'missing project';
  ret += project.targetActiveBuyerProjectExecId ? ' - ' : '';
  ret += project.targetActiveBuyerProjectExecFLName ? project.targetActiveBuyerProjectExecFLName : '';
  if (project.targetActiveBuyerProjectHarvcoLeadId) {
    ret += `(${project.targetActiveBuyerProjectHarvcoLeadUserName})`;
  }

  return ret;
};

/**
 * Get parent name.
 *
 * @param project {Object} Project object.
 * @returns {string}
 */
const getParentName = project => {
  if (project.parentLegalName) {
    return `Parent: ${project.parentLegalName} ${project.parentTicker}`;
  }

  return '';
};

/**
 * Determine which tab should be shown at first loading.
 * If there is no company in result, show people tab first.
 *
 * @param {Map} state Searching state.
 */
function switchTab(state) {
  if (
    !state.getIn(['firstComming', 'loaded']) &&
    state.getIn(['firstComming', 'companyLoaded']) &&
    state.getIn(['firstComming', 'peopleLoaded'])
  ) {
    return state.mergeDeep({
      activeTab: state.get('companies').size > 0 ? 'companies' : state.get('people').size > 0 ? 'people' : 'companies',
      firstComming: {
        loaded: true,
      },
    });
  }

  return state;
}
