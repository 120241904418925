import { fromJS, List } from 'immutable';
import * as ActionType from '../actions/mailingCreate';

const defaultState = fromJS({
  isFetching: false,
  isFetchingHeader: false,
  isFetchingTargets: false,
  isUnableToCreateMailing: true,
  header: {},
  queryResults: [],
  page: 0,
  totalPages: 0,
  sortBy: {
    sortModel: [],
  },
  isCreatingMailing: false,
  createMailingError: null,
  selectList: [],
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case ActionType.INIT_CREATE_MAILING:
      return state.merge({
        isCreatingMailing: false,
        queryResults: [],
      });

    case ActionType.FETCHING_PM_HEADER:
      return state.merge({
        isFetching: true,
        isFetchingHeader: true,
      });

    case ActionType.FETCHING_PM_TARGETS:
      return state.merge({
        isFetching: true,
        isFetchingTargets: true,
        page: action.query.page,
      });

    case ActionType.FETCHING_PM_HEADER_SUCCESS:
      return state
        .mergeDeep({
          isFetchingHeader: false,
          header: action.response.data,
        })
        .update('isFetching', () => state.get('isFetchingTargets'));

    case ActionType.FETCHING_PM_HEADER_FAILURE:
      return state
        .merge({
          isFetchingHeader: false,
        })
        .update('isFetching', () => state.get('isFetchingTargets'));

    case ActionType.FETCHING_PM_TARGETS_FAILURE:
      return state
        .merge({
          isFetchingTargets: false,
        })
        .update('isFetching', () => state.get('isFetchingHeader'))
        .set('queryResults', fromJS([]));

    case ActionType.FETCHING_PM_TARGETS_SUCCESS:
      if (action.response.meta.pagination.currentPage === 1) {
        state = state.set('queryResults', new List());
      }

      return state
        .merge({
          queryResults: state
            .get('queryResults')
            .concat(index(fromJS(action.response.data), state.get('queryResults').size)),
          isFetchingTargets: false,
          isUnableToCreateMailing: isUnableToCreateMailing(action.response.data),
          totalPages: action.response.meta.pagination.totalPages,
        })
        .update('isFetching', () => state.get('isFetchingHeader'));

    case ActionType.UPDATE_MAILING_CREATE:
      return state.mergeDeep({
        [action.filterName]: action.filterData,
      });

    case ActionType.CREATING_MAILING:
      return state.merge({
        isCreatingMailing: true,
      });

    case ActionType.CREATING_MAILING_FAILURE:
      return state.merge({
        createMailingError: true,
      });

    case ActionType.CLEAR_CREATE_MAILING_ERROR:
      return state.merge({
        createMailingError: null,
        isCreatingMailing: false,
      });

    case ActionType.UPDATE_MAILING_SELECTED_ROW: {
      const selectedItemId = state.getIn(['queryResults', action.index, 'id']);

      state = state.update('selectList', selectList => {
        if (!action.value) {
          return selectList.filter(item => item !== selectedItemId);
        }

        const existId = selectList.some(item => item === selectedItemId);

        return existId ? selectList : selectList.push(selectedItemId);
      });

      return state.setIn(['queryResults', action.index, action.field], action.value);
    }

    case ActionType.UPDATE_MAILING_ALL_ROWS: {
      state = action.value
        ? state.set(
            'selectList',
            state.get('queryResults').map(queryResult => queryResult.get('id')),
          )
        : state.set('selectList', new List());

      return state.update('queryResults', list => list.map(item => item.set(action.field, action.value)));
    }

    case ActionType.UPDATE_MAILING_SELECT_ROWS_BY_QUANTITY: {
      if (action.quantity < state.get('selectList').size) {
        state = state
          .set('selectList', new List())
          .update('queryResults', list => list.map(item => item.set(action.field, false)));
      }

      for (let i = 0; i < action.quantity; i++) {
        const queryResultId = state.getIn(['queryResults', i, 'id']);

        state = state
          .update('selectList', selectList => {
            const existId = selectList.some(item => item === queryResultId);

            return existId ? selectList : selectList.push(queryResultId);
          })
          .setIn(['queryResults', i, action.field], action.value);
      }

      return state;
    }

    default:
      return state;
  }
}

function isUnableToCreateMailing(data) {
  if (data.length === 0) return true;

  const status = data.reduce((acc, i) => {
    if (i.targetId && i.buyerId && i.projectId && i.activity && i.recordOwnerId) {
      acc = false;
    } else {
      acc = true;
    }

    return acc;
  }, false);

  return status;
}

function index(data, startAt) {
  return data.map((d, i) => d.set('order', i + startAt));
}
