import { fromJS } from 'immutable';

import * as ActionType from '../../actions/companyDetail';

const defaultState = fromJS({
  isFetching: false,
  isDuplicateCompany: false,
  mergeResultCompanyId: '',
  isNotApprovedMerge: false,
  sourceCompanies: [],
});

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.FETCHING_COMPANY:
      return defaultState.merge({
        isFetching: true,
      });

    case ActionType.FETCHING_COMPANY_FAILURE:
      return state.merge({
        isFetching: false,
      });

    case ActionType.FETCHING_COMPANY_SUCCESS:
      return state.merge({
        isFetching: false,
        ...mapResponse(action.response),
      });

    default:
      return state;
  }
}

function mapResponse({ data }) {
  const { isDuplicate, mergeResultCompanyId, isNotApprovedMerge, sourceCompanies } = data;

  return {
    isDuplicateCompany: isDuplicate || false,
    mergeResultCompanyId,
    isNotApprovedMerge: isNotApprovedMerge || false,
    sourceCompanies: sourceCompanies || [],
  };
}
