import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

import { LeadsReport } from '../components/LeadsReport';
import { checkAccess } from '../utils/checkPermissions';

/**
 * Leads report container component.
 *
 * @param {object} props Props.
 * @param {string} props.usersRole Current user role.
 */
const LeadsReportContainer = props => {
  const { userRole } = props;

  return (
    <div className="full-height">
      <Helmet title="Leads Report" />
      {checkAccess(userRole.get(0)) && <LeadsReport />}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userRole: state.auth.getIn(['user', 'roles']),
  };
}

export default connect(mapStateToProps)(React.memo(LeadsReportContainer));
