import { fromJS } from 'immutable';

import {
  LOADING_TARGETS,
  LOADED_TARGETS_SUCCESS,
  SORT_TARGETS,
} from '../../../../actions/company/buyer/addOnProject/target';
import { sortByBoolean, sortByNumber, sortByString } from '../../../../utils/sorting';

const defaultState = fromJS([]);

export default function(state = defaultState, action) {
  switch (action.type) {
    case LOADED_TARGETS_SUCCESS:
      return getTargets(action.response);

    case LOADING_TARGETS:
      return defaultState;

    case SORT_TARGETS:
      return index(sortTargets(state, action));

    default:
      return state;
  }
}

/**
 * Get targets from response.
 *
 * @param response {Object} Response.
 * @returns {Immutable.List}
 */
function getTargets(response) {
  if (response.status === 'success') {
    return fromJS(
      response.data.targets.map((t, i) => ({
        order: i,
        id: t.id,
        curr: t.currentStatus,
        high: t.highStatus,
        target: t.legalName,
        appList: t.applistLabel,
        pri: t.priority,
        approved: t.approved === '' ? null : t.approved,
        revenue: t.revenue,
        employees: t.employees,
        ebitda: t.ebitda,
        city: t.city,
        state: t.state,
        web: t.web,
        harvcoLead: t.harvcoLead,
      })),
    );
  }

  return defaultState;
}

/**
 * Sort target list.
 *
 * @param state {Immutable.List} State.
 * @param action {Object} Action.
 * @returns {Immutable.List}
 */
function sortTargets(state, action) {
  const byNumber = ['curr', 'high', 'revenue', 'employees', 'ebitda'];
  const { field, direction } = action;
  const dir = direction === 'up' ? -1 : 1;

  if (field === 'approved') {
    return sortByBoolean(state, field, dir);
  }

  if (byNumber.indexOf(field) > -1) {
    return sortByNumber(state, field, dir);
  }

  return sortByString(state, field, dir);
}

function index(data) {
  return data.map((d, i) => d.set('order', i));
}
