import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import Helmet from 'react-helmet';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import connectOptions, { mergeProps } from '../utils/connectOptions';
import * as authActionCreators from '../actions/auth';
import AppRouting from '../routing/AppRouting';
import Popup from './PopupWrapper';
import ActivityDetector from './ActivityDetector';
import ApiErrorDisplay from './ApiErrorWrapper';
import RuntimeErrorDisplay from './RuntimeErrorWrapper';
import UnloadWrapper from './UnloadWrapper';
import parseJwt from '../utils/token';
import { getCode } from '../middleware/activate';

const { appName, color } = require('../config');

const INTERVAL_TOKEN_REFRESH = 60 * 1000; // 1 minute

class App extends PureComponent {
  static fetchData({ store }) {
    return store.dispatch(authActionCreators.checkAuth({}));
  }

  componentDidMount() {
    const { checkAuth } = this.props;

    checkAuth({
      afterSuccess: () => {
        this.initRefreshTokenByTimer();
      },
    });
  }

  componentWillUnmount() {
    clearInterval(this.refeshInterval);
  }

  initRefreshTokenByTimer() {
    this.refeshInterval = setInterval(() => {
      const parsedToken = parseJwt(getCode());

      if (parsedToken) {
        const now = moment(new Date());
        const expiration = moment(new Date(parsedToken.exp * 1000));

        if (expiration.diff(now, 'minutes') <= 5) {
          this.props.refreshToken();
        }
      }
    }, INTERVAL_TOKEN_REFRESH);
  }

  getChildContext() {
    return {
      currentUser: this.props.user,
    };
  }

  render() {
    const { user } = this.props;

    const content = user ? (
      <RuntimeErrorDisplay global onDevOnly>
        <ActivityDetector>
          <AppRouting />
        </ActivityDetector>
      </RuntimeErrorDisplay>
    ) : null;

    return (
      <div className="full-height">
        <Helmet
          defaultTitle=""
          htmlAttributes={{ lang: 'en' }}
          link={[{ rel: 'manifest', href: '/assets/manifest.json' }]}
          meta={[
            { charset: 'urt8' },
            { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
            { name: 'description', content: '' },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
            },
            { name: 'msapplication-tap-highlight', content: 'no' },
            { name: 'mobile-web-app-capable', content: 'yes' },
            { name: 'application-name', content: appName },
            { name: 'apple-mobile-web-app-capable', content: 'yes' },
            { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
            { name: 'apple-mobile-web-app-title', content: appName },
            { name: 'msapplication-TileColor', content: color },
            { name: 'theme-color', content: color },
          ]}
          titleTemplate="%s"
        />
        <UnloadWrapper>
          <Popup>
            <ApiErrorDisplay>{content}</ApiErrorDisplay>
          </Popup>
        </UnloadWrapper>
      </div>
    );
  }
}

App.childContextTypes = {
  currentUser: PropTypes.instanceOf(Map),
};

App.propTypes = {
  checkAuth: PropTypes.func.isRequired,
  refreshToken: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    user: state.auth.get('user', null),
    location: state.router.location,
  }),
  dispatch => bindActionCreators(authActionCreators, dispatch),
  mergeProps,
  connectOptions,
)(App);
