import { fromJS } from 'immutable';
import * as Types from '../actions/tag';

const defaultState = fromJS({
  sort: {},
  tags: [],
  types: [],
  parents: [],
  loading: false,
  typesLoading: false,
  parentTagsLoading: false,
  mode: 'view',
});

const reducers = (state = defaultState, action) => {
  switch (action.type) {
    case Types.FETCHING_TAGS:
      return state.set('loading', true);

    case Types.FETCHED_TAGS_SUCCESS:
      return getTags(state, action);

    case Types.FETCHED_TAGS_FAIL:
      return state.set('loading', false);

    case Types.DELETE_TAG_SUCCESS:
      return deleteTag(state, action);

    case Types.FETCHING_TAG_TYPE:
      return state.set('typesLoading', true);

    case Types.FETCHED_TAG_TYPE_SUCCESS:
      return state.merge({
        types: fromJS(action.response.tagTypes),
        typesLoading: false,
      });

    case Types.FETCHED_TAG_TYPE_FAIL:
      return state.set('typesLoading', false);

    case Types.TAG_LOOKUP:
      return lookup(state, action);

    case Types.TAG_STATE_UPDATE:
      return state.setIn(action.field.split('.'), action.value);

    case Types.FETCHING_PARENT_TAGS:
      return state.set('parentTagsLoading', true);

    case Types.FETCHED_PARENT_TAGS_FAIL:
      return state.set('parentTagsLoading', false);

    case Types.FETCHED_PARENT_TAGS_SUCCESS:
      return state.set('parentTagsLoading', false).set('parents', fromJS(excludeDeletedItem(action.response.data)));

    case Types.TAG_SORT: {
      const tmp = state.set(
        'sort',
        fromJS({
          [action.field]: (state.getIn(['sort', action.field]) || -1) * -1,
        }),
      );

      return tmp.set('tags', mapper[action.field](tmp, action));
    }

    default:
      return state;
  }
};

export default reducers;

const mapper = {
  category(state, action) {
    const direction = state.getIn(['sort', action.field]);

    return state.get('tags').sort((a, b) => {
      const v1 = a.get('category', '');
      const v2 = b.get('category', '');

      return v1.localeCompare(v2) * direction;
    });
  },
  parent(state, action) {
    const direction = state.getIn(['sort', action.field]);

    return state.get('tags').sort((a, b) => {
      const v1 = a.getIn(['parent', 'category'], '');
      const v2 = b.getIn(['parent', 'category'], '');

      return v1.localeCompare(v2) * direction;
    });
  },
  tagType(state, action) {
    const direction = state.getIn(['sort', action.field]);

    return state.get('tags').sort((a, b) => {
      const v1 = a.getIn(['tagType', 'type'], '');
      const v2 = b.getIn(['tagType', 'type'], '');

      return v1.localeCompare(v2) * direction;
    });
  },
  targetCount(state, action) {
    const direction = state.getIn(['sort', action.field]);

    return state.get('tags').sort((a, b) => {
      const v1 = a.get('targetCount');
      const v2 = b.get('targetCount');

      return (v1 - v2) * direction;
    });
  },
  buyerCount(state, action) {
    const direction = state.getIn(['sort', action.field]);

    return state.get('tags').sort((a, b) => {
      const v1 = a.get('buyerCount');
      const v2 = b.get('buyerCount');

      return (v1 - v2) * direction;
    });
  },
  contactCount(state, action) {
    const direction = state.getIn(['sort', action.field]);

    return state.get('tags').sort((a, b) => {
      const v1 = a.get('contactCount');
      const v2 = b.get('contactCount');

      return (v1 - v2) * direction;
    });
  },
  leadsCount(state, action) {
    const direction = state.getIn(['sort', action.field]);

    return state.get('tags').sort((a, b) => {
      const v1 = a.get('leadCount');
      const v2 = b.get('leadCount');

      return (v1 - v2) * direction;
    });
  },
};

function deleteTag(state, action) {
  const index = state.get('tags').findIndex(t => t.get('id') === action.id);

  if (index > -1) {
    return state.deleteIn(['tags', index]);
  }

  return state;
}

function lookup(state, action) {
  const parent = action.parent.reduce((a, p) => {
    a[p.id] = p;

    return a;
  }, {});
  const types = action.types.reduce((a, t) => {
    a[t.id] = t;

    return a;
  }, {});

  return state.merge({
    loading: false,
    tags: state.get('tags').map(tag =>
      tag.merge({
        isParent: !!parent[tag.get('id')],
        parent: fromJS(parent[tag.get('parentId')] || {}),
        tagType: fromJS(types[tag.get('tagTypeId')] || {}),
      }),
    ),
  });
}

function excludeDeletedItem(data) {
  return data.reduce((acc, it) => {
    if (!it.deletedAt) {
      acc.push(it);
    }

    return acc;
  }, []);
}

function getTags(state, action) {
  return state.set('loading', false).set('tags', fromJS(excludeDeletedItem(action.response.data)));
}
