import PropTypes from 'prop-types';
import React from 'react';
import Immutable from 'immutable';
import moment from 'moment';
import { isEqual } from 'underscore';

import { getOriginalIn, getIn } from '../../../utils/ChangeSpy';
import Checkbox from '../../helpers/Checkbox';
import config from '../../../config';
import Spinner from '../../helpers/Spinner';
import ProjectInfo from './ProjectInfo';
import ProjectSuggestionInput from './ProjectSuggestionInput';
import ProjectTextArea from './ProjectTextArea';
import ProjectTextInput from './ProjectTextInput';
import OneColTable from './OneColTable';
import ProjectMenu from './ProjectMenu';

const DELETE_MENU_ID = 'projectDeleteContextMenu';
const TEMPLATE_MENU_ID = 'projectTemplateContextMenu';
const DELETE_APPROVAL_MENU_ID = 'projectApprovalTemplateContextMenu';

/**
 * Project component.
 *
 * @param props {Object}.
 * @param props.projectData {Immutable.Map} Project information data.
 * @param props.common {Immutable.Map} Common Object.
 * @param props.onTextChange {Function} Handle event of changing text.
 * @param props.onSuggestionClose {Function} Handle event of closing suggestion.
 * @param props.onSuggestionSelect {Function} Handle event of selecting a suggestion.
 * @param props.onSuggestionInputClick {Function} Handle event of clicking on input element.
 * @returns {React.Component}
 * @class
 */
const TopPanel = props => {
  const {
    users,
    projectData,
    templates,
    common,
    onDeleteRow,
    onDeleteApprovalRow,
    onRowInsert,
    onRowDoubleClick,
    onRowClick,
    onDownload,
    onKeyPress,
    onTemplateUpdate,
    onFocus,
    onTextChange,
    onSuggestionClose,
    onSuggestionSelect,
    onSuggestionInputClick,
  } = props;
  const createdDate = moment(projectData.get('dateCreated'));
  const createdDateString = createdDate.isValid() ? createdDate.format('L') : null;
  const type = config.values.getIn(['project', 'types', 'PROJECT']);
  const loading = common.get('projectLoading');
  const spinner = loading ? <Spinner className="text-white" /> : null;

  const types = config.values.getIn(['project', 'types']);
  const changed =
    !isEqual(getIn(common, 'selectedBICs').toJS(), getOriginalIn(common, 'selectedBICs').toJS()) ||
    !isEqual(getIn(common, 'selectedBMs').toJS(), getOriginalIn(common, 'selectedBMs').toJS());

  const handleOpenExecutiveClick = () => {
    window.open(`/contact/${common.get('execId')}`, '_blank');
  };

  return (
    <div className="project-header mb10">
      <div className="row">
        <div className="col-md-2 col-sm-4">
          <ProjectTextInput field={projectData.get('category')} onTextChange={onTextChange} type={type} />
          <div className="row">
            <div className="col-md-8 col-md-offset-4">
              <ProjectInfo
                buyerId={projectData.get('buyerId')}
                showLabel={false}
                value={projectData.get('buyerName')}
                isCompanyName
              />
              <ProjectInfo showLabel={false} value={projectData.get('companyType')} />
            </div>
          </div>
        </div>
        <div className="col-md-2 col-md-offset-1 col-sm-4">
          <ProjectSuggestionInput
            id="projectHarvCoLead"
            label="HarvCo Lead:"
            name="harvcoLead"
            onFocus={onFocus}
            onSuggestionClose={onSuggestionClose}
            onSuggestionInputClick={onSuggestionInputClick}
            onSuggestionSelect={onSuggestionSelect}
            onTextChange={onTextChange}
            row={projectData.get('harvcoLead')}
            suggestions={common.get('harvcoLeadSuggestions')}
          />
          <ProjectSuggestionInput
            id="projectClientLead"
            label="Client Lead:"
            name="clientLead"
            onFocus={onFocus}
            onSuggestionClose={onSuggestionClose}
            onSuggestionInputClick={onSuggestionInputClick}
            onSuggestionSelect={onSuggestionSelect}
            onTextChange={onTextChange}
            row={projectData.get('clientLead')}
            suggestions={common.get('clientLeadSuggestions')}
          />
          <div className="execute-dropdown-with-external-button form-group">
            <ProjectSuggestionInput
              id="projectExecutive"
              label="Executive:"
              name="exec"
              onFocus={onFocus}
              onSuggestionClose={onSuggestionClose}
              onSuggestionInputClick={onSuggestionInputClick}
              onSuggestionSelect={onSuggestionSelect}
              onTextChange={onTextChange}
              row={projectData.get('exec')}
              suggestions={common.get('execSuggestions')}
            />
            <button className="input-group-addon" onClick={handleOpenExecutiveClick} title="Open in external tab">
              <i className="fa-reply fa" />
            </button>
          </div>
        </div>
        <div className="col-md-2 col-md-offset-1 col-sm-4">
          <ProjectInfo label="Created:" value={createdDateString} />
          <ProjectInfo label="Type:" value={projectData.get('type')} />
          <div className="project-header-info group-checkbox">
            <Checkbox
              checked={projectData.get('active')}
              label="Active"
              name="active"
              onChange={event => onTextChange({ event, data: { type } })}
              onClick={event => event.stopPropagation()}
            />
          </div>
        </div>
      </div>
      <div className="row project-widgets">
        <ProjectTextArea
          field={projectData.get('details')}
          id="projectDetails"
          label="Project Scope"
          name="details"
          onTextChange={onTextChange}
        />
        <ProjectTextArea
          field={projectData.get('status')}
          id="projectStatus"
          label="Status"
          name="status"
          onTextChange={onTextChange}
        />
        <OneColTable
          changed={changed}
          contextId={DELETE_MENU_ID}
          isLoading={common.get('industryLoading')}
          onRowDoubleClick={onRowDoubleClick}
          onRowInsert={onRowInsert}
          rows={common.get('bic').concat(common.get('bm'))}
          title="Industries"
          type={types.get('INDUSTRIES')}
          skipMenu
        />
        <OneColTable
          contextId={DELETE_MENU_ID}
          isLoading={common.get('userLoading')}
          onRowClick={onRowClick}
          onRowDoubleClick={onRowDoubleClick}
          onRowInsert={onRowInsert}
          onSuggestionClose={onSuggestionClose}
          onSuggestionSelect={onSuggestionSelect}
          onTextChange={onTextChange}
          rows={users}
          suggestions={common.get('userSuggestions')}
          title="Users"
          type={types.get('USERS')}
        />
        <OneColTable
          contextId={TEMPLATE_MENU_ID}
          isLoading={common.get('templateLoading')}
          onKeyPress={onKeyPress}
          onRowClick={onRowClick}
          onRowInsert={onRowInsert}
          onTemplateUpdate={onTemplateUpdate}
          onTextChange={onTextChange}
          rows={templates}
          title="Templates"
          type={types.get('TEMPLATES')}
        />
        <ProjectMenu id={DELETE_MENU_ID} onDelete={onDeleteRow} />
        <ProjectMenu id={DELETE_APPROVAL_MENU_ID} onDelete={onDeleteApprovalRow} />
        <ProjectMenu id={TEMPLATE_MENU_ID} onDelete={onDeleteRow} onDownload={onDownload} isTemplate />
      </div>
      {spinner}
    </div>
  );
};

TopPanel.propTypes = {
  common: PropTypes.instanceOf(Immutable.Map).isRequired,
  onDeleteRow: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  onSuggestionClose: PropTypes.func.isRequired,
  onSuggestionInputClick: PropTypes.func.isRequired,
  onSuggestionSelect: PropTypes.func.isRequired,
  onTemplateUpdate: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  projectData: PropTypes.instanceOf(Immutable.Map).isRequired,
  templates: PropTypes.instanceOf(Immutable.List).isRequired,
  users: PropTypes.instanceOf(Immutable.List).isRequired,
};

export default TopPanel;
