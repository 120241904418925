import moment from 'moment';

const DATE_FORMAT = 'MM/DD/YYYY';

export function dateToString(date) {
  if (moment.isDate(date)) {
    date = moment(date);
  }

  if (moment.isMoment(date)) {
    return date.format('YYYY-MM-DD HH:mm:ss');
  }

  return date;
}

export function dateToShortString(date) {
  if (moment.isDate(date)) {
    date = moment(date);
  }

  if (moment.isMoment(date)) {
    return date.format('YYYY-MM-DD');
  }

  return date;
}

export function dayToString(date) {
  if (moment.isDate(date)) {
    date = moment(date);
  }

  if (moment.isMoment(date)) {
    return date.format(DATE_FORMAT);
  }

  return date;
}

export function stringToDate(date) {
  if (date === '') return moment(new Date(), DATE_FORMAT);

  return moment(date, DATE_FORMAT);
}

export function convertDateString(date) {
  if (!date) return '';

  return moment(date).format(DATE_FORMAT);
}
