import { CALL_API } from '../../../middleware/api';

export const FETCHING_BUYER_TARGETS = Symbol('FETCHING_BUYER_TARGETS');
export const FETCHING_BUYER_TARGETS_SUCCESS = Symbol('FETCHING_BUYER_TARGETS_SUCCESS');
export const FETCHING_BUYER_TARGETS_FAILURE = Symbol('FETCHING_BUYER_TARGETS_FAILURE');
export const UPDATE_LEADS = Symbol('UPDATE_LEADS');

export function getBuyerTargets({ companyId, current_status = 5, page = 1, sortBy = null }) {
  const query = {
    page,
    current_status,
    limit: 100,
  };

  if (sortBy) {
    query.sort = sortBy;
  }

  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies/${companyId}/buyer_targets`,
      query,
      startType: FETCHING_BUYER_TARGETS,
      errorType: FETCHING_BUYER_TARGETS_FAILURE,
      successType: FETCHING_BUYER_TARGETS_SUCCESS,
    },
  };
}

export function updateLeads({ filterName, filterData }) {
  return {
    type: UPDATE_LEADS,
    filterName,
    filterData,
  };
}
