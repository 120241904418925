import React from 'react';

const PlainPriority = () => (
  <div className="font-size-14">
    <div>A</div>
    <div>B</div>
    <div>C</div>
    <div>Exclude</div>
  </div>
);

export default PlainPriority;
