import superAgent from 'superagent';
import Promise from 'bluebird';
import { camelizeKeys, decamelizeKeys } from 'humps';

import activate from './activate';
import config from '../config';
import createQueue, { AbortingError } from '../utils/queue';
import AbortAttempt from './AbortAttempt';
import getUnifier from './getUnifier';

function createAbortAttempt(unifierOrDescriptor, ...rest) {
  if (typeof unifierOrDescriptor === 'string') return new AbortAttempt(unifierOrDescriptor, ...rest);

  return new AbortAttempt(getUnifier(unifierOrDescriptor, true), ...rest);
}

export function abortRequest(unifierOrDescriptor) {
  return createAbortAttempt(unifierOrDescriptor, false);
}

const requestsQueue = createQueue();

/**
 * Dispatching event with object with such key generates 1 API request.
 * Value of CALL_API key should be an object with fields.
 * Fields available:
 * @param method {String} - HTTP method (GET, POST, PUT, ...)
 * @param cookie {Object} - pass an src/client/cookie module from client
 * @param path {String} - HTTP url pathname (remember, that middleware append API_BASE_URL env before this pathname)
 * @param query {Object} - query object
 * @param body {Object|String} - HTTP request's body.
 * @param startType {String|Symbol} - ActionType to dispatch before api call. Action object will be:
 *    ` { type: params.startType, url : params.url , query : params.query} ` There url is full url.
 * @param beforeStart {Function} - Function to call before api call.
 * @param successType {String|Symbol} - ActionType to dispatch on success api call. Action object will be:
 *    ` { type: params.successType, response : responseBody} `
 * @param errorType {String|Symbol} - ActionType to dispatch on error api call. Action object will be:
 *    ` { type: params.successType, error : anErrorObject} `
 * @param afterSuccess {Function} - Function to call then api call succeeds.
 * @param afterError {Function} - Function to call then api call errors.
 *
 *
 * @example
 *     export function loadQuestions() {
 *       return {
 *         [CALL_API]: {
 *           method: 'get',
 *           path: '/api/questions',
 *           successType: LOADED_QUESTIONS
 *         }
 *       }
 *     }
 * @type {Symbol}
 */
export const CALL_API = Symbol('CALL_API');
export const CLEAN_QUEUE = Symbol('CLEAN_QUEUE');

/**
 * Dispatching event with object with such key generates many chained API request.
 * Value of CHAIN_API key should be an array with functions returning CALL_API actions.
 * Every function will got previous response's body as an argument.
 *
 * @example
 *     export function loadQuestionDetail ({ id, history }) {
 *      return {
 *        [CHAIN_API]: [
 *          ()=> {
 *            return {
 *              [CALL_API]: {
 *                method: 'get',
 *                path: `/api/questions/${id}`,
 *                startType: LOAD_QUESTION_DETAIL,
 *                successType: LOADED_QUESTION_DETAIL,
 *                afterError: ()=> {
 *                  history.push('/')
 *                }
 *              }
 *            }
 *          },
 *          (question) => {
 *            return {
 *              [CALL_API]: {
 *                method: 'get',
 *                path: `/api/users/${question.userId}`,
 *                successType: LOADED_QUESTION_USER
 *              }
 *            }
 *          }
 *        ]
 *      }
 *    }
 */
export const CHAIN_API = Symbol('CHAIN_API');

const IS_CALL_API_ORIGINAL = Symbol('IS_CALL_API_ORIGINAL');

const globalErrorTypes = [];
const globalAfterErrors = [];
const middlewareErrorTypes = [];

class ApiError extends Error {
  constructor(error) {
    super();

    this.name = this.constructor.name;
    this.message = error.message;
    this.stack = error.stack;
  }
}

export default ({ dispatch, getState }) => next => action => {
  if (action[CALL_API]) {
    action[CALL_API][IS_CALL_API_ORIGINAL] = true;

    return dispatch({
      [CHAIN_API]: [() => action],
    });
  }

  let resolve, reject;
  const deferred = new Promise(function(res, rej) {
    [resolve, reject] = [res, rej];
  });

  if (!action[CHAIN_API]) {
    if (action.type === CLEAN_QUEUE) {
      requestsQueue.clear();
    }

    if (action instanceof AbortAttempt) {
      if (action.shouldAbortAll()) {
        requestsQueue.abortQueue(action.getUnifier());
      } else {
        requestsQueue.abortCurrentTask(action.getUnifier());
      }

      return null;
    }

    return next(action);
  }

  const promiseCreators = action[CHAIN_API].map(apiActionCreator => {
    return createRequestPromise(apiActionCreator, next, getState, dispatch);
  });

  const overall = promiseCreators.reduce((promise, creator) => promise.then(body => creator(body)), Promise.resolve());
  let resData;

  overall
    .then(result => {
      resData = result;

      return resData;
    })
    .finally(() => resolve(resData))
    .catch(error => {
      if (error instanceof ApiError) return;
      if (error instanceof AbortingError) return;
      middlewareErrorTypes.map(type => {
        dispatch(
          actionWith(action, {
            error,
            type,
          }),
        );
      });
    });

  return deferred;
};

function isObject(value) {
  const type = typeof value;

  return !!value && (type === 'object' || type === 'function');
}

function isFunction(value) {
  // The use of `Object#toString` avoids issues with the `typeof` operator
  // in older versions of Chrome and Safari which return 'function' for regexes
  // and Safari 8 which returns 'object' for typed array constructors.
  return isObject(value) && Object.prototype.toString.call(value) === '[object Function]';
}

function actionWith(action, toMerge) {
  const ret = Object.assign({}, action, toMerge);

  delete ret[CALL_API];
  delete ret[CHAIN_API];

  return ret;
}

function performPreStart({ dispatch, params, apiAction, getState }) {
  if (params.startType) {
    dispatch(
      actionWith(apiAction, {
        type: params.startType,
        url: params.url,
        query: params.query,
      }),
    );
  }

  if (isFunction(params.beforeStart)) {
    params.beforeStart({ dispatch, getState });
  }
}

function getRequestObject({ params }) {
  const { headers = {}, download = false } = params;
  const tempReq = Object.keys(headers).reduce(
    (innerReq, header) => innerReq.set(header, headers[header]),
    (setCookieHeader(superAgent[params.method](params.url), params)),
  );
  let req = tempReq;

  if (download) {
    req = tempReq.responseType('blob');
  }

  if (Object.prototype.toString.call(params.body) === '[object FormData]') {
    return req.send(params.body);
  } else {
    return req.send(decamelizeKeys(params.body));
  }
}

function performError({ dispatch, err, params, apiAction, getState, resBody, reject, response }) {
  err = new ApiError(err);
  {
    const { cookieHeader, ...requestParams } = params;

    globalAfterErrors.map(cb => cb({ dispatch, getState, response, resBody }));

    if (!params.skipGlobalErrorHandler) {
      globalErrorTypes.map(type => {
        dispatch(
          actionWith(apiAction, {
            requestParams,
            type,
            response,
            resBody,
          }),
        );
      });
    }
  }

  if (params.errorType) {
    dispatch(
      actionWith(apiAction, {
        ...params.errorParams,
        type: params.errorType,
        error: err,
        response: resBody,
      }),
    );
  }

  if (isFunction(params.afterError)) {
    params.afterError({ dispatch, getState, response, resBody });
  }
  reject(err);
}

function performSuccess({ dispatch, params, apiAction, getState, resBody, resolve, response }) {
  setCookie(response, params.setCookie);

  if (Array.isArray(params.successType)) {
    params.successType(type =>
      dispatch(
        actionWith(apiAction, {
          ...params.successParams,
          type,
          response: resBody,
        }),
      ),
    );
  } else if (params.successType) {
    dispatch(
      actionWith(apiAction, {
        ...params.successParams,
        type: params.successType,
        response: resBody,
      }),
    );
  }

  if (isFunction(params.afterSuccess)) {
    params.afterSuccess({ getState, dispatch, response: resBody });
  }
  resolve(resBody);
}

function getRequestQueueCallback({ dispatch, params, apiAction, getState }, callbacks) {
  return error => {
    if (error) return callbacks.reject(error);

    let request;
    const prom = new Promise((resolve, reject) => {
      const baseOptions = { dispatch, params, apiAction, getState };

      performPreStart({ ...baseOptions });

      request = getRequestObject({ params })
        .withCredentials()
        .query(params.query)
        .end((err, response) => {
          const baseAfterResp = { ...baseOptions, response };

          let resBody;

          if (response && response.body) {
            resBody = camelizeKeys(response.body);
          }

          if (err) {
            const error = err instanceof Error ? err : new Error(err);

            return performError({ ...baseAfterResp, err: error, resBody, reject });
          }

          return performSuccess({ ...baseAfterResp, resBody, resolve });
        });
    })
      .then((...rest) => callbacks.resolve(...rest))
      .catch((...rest) => callbacks.reject(...rest));

    return {
      abort: () => request.abort(),
      then: cb => prom.then(cb),
      catch: cb => prom.catch(cb),
    };
  };
}

function createRequestPromise(apiActionCreator, next, getState, dispatch) {
  return prevBody => {
    const apiAction = apiActionCreator(prevBody);

    if (apiAction[CHAIN_API]) {
      const promiseCreators = apiAction[CHAIN_API].map(apiActionCreator =>
        createRequestPromise(apiActionCreator, next, getState, dispatch),
      );

      return promiseCreators.reduce(
        (promise, creator) => promise.then(body => creator(body)),
        Promise.resolve(prevBody),
      );
    }

    const requestCallbacks = {
      resolve: (result) => {
        throw new Error(`Call resolve before present. ${result}`);
      },
      reject: (result) => {
        throw new Error(`Call reject before present. ${result}`);
      },
    };
    const deferred = new Promise((res, rej) => {
      requestCallbacks.resolve = res;
      requestCallbacks.reject = rej;
    });

    const params = extractParams(apiAction[CALL_API]);

    requestsQueue.push(
      params.unifier,
      params.maxCount,
      getRequestQueueCallback({ dispatch, params, apiAction, getState }, requestCallbacks),
      params.dislodging,
    );

    return deferred;
  };
}

function extractParams(callApi) {
  const {
    method,
    cookie,
    path,
    query,
    body,
    startType,
    beforeStart,
    successType,
    successParams,
    errorType,
    errorParams,
    afterSuccess,
    afterError,
    maxCount,
    dislodging = false,
    ...changeableParams
  } = callApi;
  let unifier = changeableParams.unifier || defaultUnifier(callApi);
  const url = `${config.API_BASE_URL}${path}`;
  const cookieHeader = getCookieHeader(cookie);
  const setCookie = getCookieSetter(url, cookie);

  if (isFunction(unifier)) unifier = unifier(callApi);

  return activate({
    ...changeableParams,
    dislodging,
    method,
    url,
    cookieHeader,
    setCookie,
    query,
    body,
    startType,
    beforeStart,
    successType,
    successParams,
    errorType,
    errorParams,
    afterSuccess,
    afterError,
    unifier,
    maxCount,
  });
}

function getCookieHeader(cookie) {
  if (!isObject(cookie)) return '';
  if (!isFunction(cookie.getHeader)) return '';

  return cookie.getHeader();
}

function defaultCookieSetter(url, cookieName) {
  if (process.env.ON_SERVER === false) return;
  console.warn(`Try set cookie (Set-Cookie: ${cookieName}) without \`cookie\` object in action. Requested url: ${url}`);
}

function getCookieSetter(url, cookie) {
  if (!isObject(cookie)) return (...params) => defaultCookieSetter(url, ...params);
  if (!isFunction(cookie.setCookie)) return (...params) => defaultCookieSetter(url, ...params);

  return cookie.setCookie;
}

function setCookie({ headers = {} }, setCookie) {
  if (headers && headers['set-cookie']) {
    setCookie(headers['set-cookie']);
  }
}

function setCookieHeader(superagent, { cookieHeader }) {
  if (process.env.ON_SERVER === false) return superagent;
  if (!cookieHeader) return superagent;

  return superagent.set('Cookie', cookieHeader);
}

export function addGlobalErrorType(type) {
  if (globalErrorTypes.indexOf(type) === -1) {
    globalErrorTypes.push(type);
  }
}

export function addGlobalAfterError(cb) {
  if (globalAfterErrors.indexOf(cb) === -1) {
    globalAfterErrors.push(cb);
  }
}

export function addMiddlewareErrorType(type) {
  if (middlewareErrorTypes.indexOf(type) === -1) {
    middlewareErrorTypes.push(type);
  }
}

const defaultUnifier = (function() {
  let increment = 1;
  const defaultPrefix = '__default__';

  return function getDefaultUnifier(params) {
    if (!params[IS_CALL_API_ORIGINAL]) return `${defaultPrefix}${increment++}`;
    if (params.method && params.method.toLowerCase() !== 'get') {
      return `NOT_GET (${params.method}) ${defaultPrefix}${increment++}`;
    }

    return `${params.method} ${params.path} ${JSON.stringify(params.query)}`;
  };
})();
