import React from 'react';

const CustomFields = ({ data }) => (
  <>
    <div className="mb5">{data.customField1}</div>
    <div className="mb5">{data.customField2}</div>
    <div>{data.customField3}</div>
  </>
);

export default CustomFields;
