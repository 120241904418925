import { CALL_API } from '../middleware/api';

import config from '../config';

export const UPDATE_FILTER = 'UPDATE_FILTER';
export const FETCHING_TRADE_SHOW_LIST = Symbol('FETCHING_TRADE_SHOW_LIST');
export const FETCHING_TRADE_SHOW_LIST_SUCCESS = Symbol('FETCHING_TRADE_SHOW_LIST_SUCCESS');

export const LOADING_STATISTICS = Symbol('LOADING_STATISTICS');
export const LOADING_STATISTICS_SUCCESS = Symbol('LOADING_STATISTICS_SUCCESS');

export const LOADING_COMPANIES = Symbol('LOADING_COMPANIES');
export const LOADING_COMPANIES_SUCCESS = Symbol('LOADING_COMPANIES_SUCCESS');

export const FETCHING_TRADE_SHOW = Symbol('FETCHING_TRADE_SHOW');
export const FETCHING_TRADE_SHOW_SUCCESS = Symbol('FETCHING_TRADE_SHOW_SUCCESS');

export const SEND_TRADE_SHOW = Symbol('SEND_TRADE_SHOW');
export const SEND_TRADE_SHOW_SUCCESS = Symbol('SEND_TRADE_SHOW_SUCCESS');

export const UPDATE_TEXT_FIELD = Symbol('UPDATE_TEXT_FIELD');
export const UPDATE_INDUSTRIES = Symbol('UPDATE_INDUSTRIES');
export const ADD_NEW_EVENT = Symbol('ADD_NEW_EVENT');
export const REMOVE_EVENT = Symbol('REMOVE_EVENT');

export const ADD_USER = Symbol('ADD_USER');
export const REMOVE_USER = Symbol('REMOVE_USER');

export const TRADE_SHOW_INDUSTRIES_UPDATE = Symbol('TRADE_SHOW_INDUSTRIES_UPDATE');

export const UPDATE_COMPANIES_TABLE_SORT = Symbol('UPDATE_COMPANIES_TABLE_SORT');

/**
 * Loads trade show list with sorting option.
 *
 * @param page Page number.
 * @param sortBy Sorting option.
 * @returns {{}}
 */
export function getTradeShowList({ page = 1, sortBy = null }) {
  const query = {
    page,
    limit: 1000,
  };

  if (sortBy) query.sort = sortBy;

  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/tradeshows',
      query,
      startType: FETCHING_TRADE_SHOW_LIST,
      successType: FETCHING_TRADE_SHOW_LIST_SUCCESS,
    },
  };
}

export function handleTableSorting({ filterName, filterData }) {
  return {
    type: UPDATE_FILTER,
    filterName,
    filterData,
  };
}

/**
 * Handle sorting of companies table.
 *
 * @param filterName State sort field name.
 * @param filterData Sorting model.
 * @returns Action.
 */
export function handleCompaniesTableSorting({ filterName, filterData }) {
  return {
    type: UPDATE_COMPANIES_TABLE_SORT,
    filterName,
    filterData,
  };
}

/**
 * Gets trade show by id.
 *
 * @param tradeShowId Trade Show id.
 * @returns Action.
 */
export function loadTradeShow(tradeShowId) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/tradeshows/${tradeShowId}`,
      startType: FETCHING_TRADE_SHOW,
      successType: FETCHING_TRADE_SHOW_SUCCESS,
    },
  };
}

/**
 * Adds or updates trade show depending on whether it already existed.
 *
 * @param tradeShowId Trade show id (if it existed).
 * @param data Data to send.
 * @param afterSuccess Callback function.
 * @returns Action.
 */
export function sendTradeShow(tradeShowId, data, afterSuccess) {
  const industries = data.industryCategories.map(tag => tag.id).concat(data.businessModels.map(model => model.id));
  const body = {
    name: data.name.value,
    abbr: data.abbr.value,
    website: data.website.value,
    description: data.description.value,
    users: data.users.map(item => item.id.value),
    industries,
    events: data.events,
  };

  return {
    [CALL_API]: {
      method: tradeShowId ? 'put' : 'post',
      path: tradeShowId ? `/api/v1/tradeshows/${tradeShowId}` : `/api/v1/tradeshows`,
      body,
      startType: SEND_TRADE_SHOW,
      successType: SEND_TRADE_SHOW_SUCCESS,
      afterSuccess,
    },
  };
}

/**
 * Loads trade show's companies.
 *
 * @param tradeShowId Trade Show id.
 * @param year Query year.
 * @param sortBy Sorting model.
 * @param page Query page.
 * @returns Action.
 */
export function loadTradeShowCompanies({ tradeShowId, year, sortBy = null, page = 1 }) {
  const query = {
    limit: config.REQUEST_THROTTLING,
    page,
  };

  if (sortBy) {
    query.sort = sortBy;
  }

  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/tradeshows/${tradeShowId}/companies?year=${year}`,
      query,
      startType: LOADING_COMPANIES,
      successType: LOADING_COMPANIES_SUCCESS,
    },
  };
}

/**
 * Loads trade show's statistics.
 *
 * @param tradeShowId Trade Show id.
 * @returns Action.
 */
export function loadTradeShowStatistics(tradeShowId, year) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/tradeshows/${tradeShowId}/statistics?year=${year}`,
      startType: LOADING_STATISTICS,
      successType: LOADING_STATISTICS_SUCCESS,
    },
  };
}

/**
 * Handles trade show text fields change.
 *
 * @param field Field name.
 * @param value Value.
 * @returns {{field, type: symbol, value}}
 */
export function updateTextField(field, value) {
  return {
    type: UPDATE_TEXT_FIELD,
    field,
    value,
  };
}

/**
 * Handles event adding.
 *
 * @param value Value.
 * @returns {{type: symbol, value}}
 */
export function addEvent(value) {
  return {
    type: ADD_NEW_EVENT,
    value,
  };
}

/**
 * Handle event removing.
 *
 * @param id Event id.
 * @returns {{id, type: symbol}}
 */
export function removeEvent(id) {
  return {
    type: REMOVE_EVENT,
    id,
  };
}

/**
 * Handles trade show industry tags and business models change.
 *
 * @param industryCategories Trade show's industry tags.
 * @param businessModels Trade show;s business models.
 * @returns {{models, type: symbol, tags}}
 */
export function updateTradeShowIndustries(industryCategories, businessModels) {
  return {
    type: TRADE_SHOW_INDUSTRIES_UPDATE,
    industryCategories,
    businessModels,
  };
}

/**
 * Handles user adding.
 *
 * @param index Table row index.
 * @param id User's id.
 * @param text User's name.
 * @returns {{index, id, text, type: symbol}}
 */
export function addUser({ index, id, text }) {
  return {
    type: ADD_USER,
    index,
    id,
    text,
  };
}

/**
 * Handles users removing.
 *
 * @param id User's id.
 * @returns {{id, type: symbol}}
 */
export function removeUser(id) {
  return {
    type: REMOVE_USER,
    id,
  };
}
