import PropTypes from 'prop-types';

import React from 'react';
import { List } from 'immutable';

import Table, { Column, Row } from '../helpers/Table';
import LoadAnimation from '../decorators/LoadAnimation';

/**
 * Component to display contact targets table.
 *
 * @param props {Object} Component properties.
 * @param props.targets {Immutable.List} List of contact targets.
 * @param props.columns {Immutable.List} List of columns to display.
 * @param props.onDoubleClick {Function} OnDoubleClick callback.
 * @returns {React.Component}
 * @class
 */
const ContactTarget = props => {
  const { targets, columns, onDoubleClick } = props;
  /**
   * A variable for Target table rows.
   * If targets is empty - use `<tr><td colSpan="13"> No rows to display</td></tr>`.
   *
   * @member {React.Component[]}
   */
  const cols = columns.map((column, i) => <Column key={i} field={column.get('field')} title={column.get('title')} />);

  const rows = targets.map((row, i) => (
    <Row key={i} className="clickable" data={row} onDoubleClick={event => onDoubleClick(event, i)} />
  ));

  return (
    <div className="tab-pane tab-target active full-height">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p0 full-height">
        <div className="b-project table-responsive full-height">
          <Table className="table table-bordered table-hover">
            {cols}
            {rows}
          </Table>
        </div>
      </div>
    </div>
  );
};

ContactTarget.propTypes = {
  columns: PropTypes.instanceOf(List).isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  targets: PropTypes.instanceOf(List).isRequired,
};

export default LoadAnimation(ContactTarget);
