import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import TransactionsTable from './TransactionsTable';
import BuyersTable from './BuyersTable';

const TopPanel = ({ data }) => (
  <>
    <TransactionsTable data={data} />
    <BuyersTable data={data} />
  </>
);

TopPanel.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
};

export default TopPanel;
