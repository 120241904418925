import Immutable, { fromJS, List } from 'immutable';
import * as ActionType from '../actions/tradeShow';
import { convertDateString } from '../utils/dateFormat';

const defaultState = fromJS({
  queryResults: [],
  isFetching: false,
  page: 0,
  totalPages: 0,
  sortBy: {
    sortModel: [],
  },
});

const wrappedReducer = reducer;

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.UPDATE_FILTER: {
      Object.keys(action.filterData).forEach(key => {
        if (List.isList(action.filterData[key]) || Array.isArray(action.filterData[key])) {
          state = state.setIn([action.name, action.filterName, key], Immutable.List());
        }
      });

      return state.merge({
        [action.filterName]: action.filterData,
      });
    }

    case ActionType.FETCHING_TRADE_SHOW_LIST:
      return state.mergeDeep({
        isFetching: true,
      });

    case ActionType.FETCHING_TRADE_SHOW_LIST_SUCCESS:
      return state.mergeDeep({
        queryResults: action.response.data.map(tradeShow => ({
          ...tradeShow,
          startDate: convertDateString(tradeShow.startDate),
          endDate: convertDateString(tradeShow.endDate),
        })),
        isFetching: false,
        page: action.response.meta.pagination.currentPage,
        totalPages: action.response.meta.pagination.totalPages,
      });

    default:
      return state;
  }
}
