import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import * as mainApprovalActions from '../actions/approval/main';
import connectOptions, { mergeProps } from '../utils/connectOptions';
import { PreviewOnlineApprovalLists } from '../components/OnlineApprovalLists';
import Spinner from '../components/helpers/Spinner';
import { showError, showInformation } from '../utils/MessagePopup';
import { getApprovalMailToLink } from '../utils/getApprovalMailToLink';
import copyToClipboard from '../utils/copyToClipboard';

/**
 * Generate Preview Online Approval Lists component.
 *
 * @class
 */
class PreviewOnlineApprovalListsContainer extends PureComponent {
  constructor() {
    super();
    this.state = {
      link: '',
      expirationDate: '',
      emailLink: '',
      emails: [],
    };
  }

  componentDidMount() {
    const {
      history,
      loadPreviewOnlineApprovals,
      match: { params },
    } = this.props;
    const projectId = parseInt(params.projectId, 10);
    const approvalId = parseInt(params.approvalId, 10) || params.approvalId;

    loadPreviewOnlineApprovals(projectId, approvalId, history.location.search, 'preview');
  }

  generateEmailLink = emails => {
    const { onlineApproval } = this.props;

    this.setState({ emails });

    const emailFields = {
      approvalListLabel: onlineApproval.get('approvalListLabel'),
      buyerLegalName: onlineApproval.get('buyerLegalName'),
      link: this.state.link,
      expirationDate: this.state.expirationDate,
      emails,
    };
    const mailToLink = getApprovalMailToLink(emailFields);

    this.setState({ emailLink: mailToLink });
  };

  handleGenerateLink = linkData => {
    const {
      match: { params },
      history,
      generateLink,
    } = this.props;
    const { openPopup } = this.context;

    const optionalFields = new URLSearchParams(history.location.search);
    const data = {};

    for (const params of optionalFields.entries()) {
      data[params[0]] = Boolean(params[1]);
    }

    const body = {
      ...data,
      expiresAt: linkData.expiresAt,
    };

    generateLink({
      projectId: params.projectId,
      approvalId: params.approvalId,
      body,
      afterSuccess: data => {
        this.setState({
          link: data.link,
          expirationDate: data.expiresAt,
        });

        this.generateEmailLink(this.state.emails);

        copyToClipboard(data.link);
        showInformation(openPopup, 'Online Approval List link has been created and copied to clipboard.');
      },
      afterError: () => {
        showError(openPopup, ['The given data failed to pass validation.']);
      },
    });
  };

  render() {
    const { onlineApprovalLoading, onlineApproval, linksSending } = this.props;

    return (
      <div className="full-height">
        <Helmet title="Generate Online Approval Lists" />

        {onlineApprovalLoading ? (
          <Spinner />
        ) : (
          <PreviewOnlineApprovalLists
            emailLink={this.state.emailLink}
            generateEmailLink={this.generateEmailLink}
            onGenerateLink={this.handleGenerateLink}
            onlineApproval={onlineApproval}
            sending={linksSending}
          />
        )}
      </div>
    );
  }
}

PreviewOnlineApprovalListsContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  ...state.approval,
  linksSending: state.approval.common.get('linksSending'),
  onlineApprovalLoading: state.approval.common.get('onlineApprovalLoading'),
  onlineApproval: state.approval.common.get('onlineApproval'),
  loggedUser: state.auth.get('user'),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(mainApprovalActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
  connectOptions,
)(PreviewOnlineApprovalListsContainer);
