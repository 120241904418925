import React from 'react';
import Button from '../helpers/Button';

const Footer = props => {
  const { mode, loading, isSuper, onAdd, onSave, onCancel, onExport } = props;

  let buttons = [];
  const exportBtn = (
    <Button key="exportBtn" className="ml5 btn-xs" onClick={onExport}>
      Export
    </Button>
  );
  const addBtn = (
    <Button key="addBtn" className="ml5 btn-xs" disabled={loading || !isSuper} onClick={onAdd}>
      <i aria-hidden="true" className="fa fa-plus" /> Add New
    </Button>
  );
  const saveBtn = (
    <Button key="saveBtn" className="ml5 btn-xs" disabled={loading || !isSuper} onClick={onSave}>
      <i aria-hidden="true" className="fa fa-floppy-o" /> Save
    </Button>
  );
  const cancelBtn = (
    <Button key="cancelBtn" className="ml5 btn-xs" disabled={loading || !isSuper} onClick={onCancel}>
      Cancel
    </Button>
  );

  switch (mode) {
    case 'add':

    // fallthrough
    case 'edit':
      buttons = [saveBtn, cancelBtn];
      break;

    default:
      buttons = [exportBtn, addBtn];
      break;
  }

  return (
    <footer className="navbar-fixed-bottom">
      <div className="container">
        <div className="pull-right row">{buttons}</div>
      </div>
    </footer>
  );
};

export default Footer;
