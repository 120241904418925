import { CALL_API } from '../../middleware/api';

export const CHANGE_INDUSTRY_LABEL = Symbol('CHANGE_INDUSTRY_LABEL');
export const SAVING_INDUSTRY = Symbol('SAVING_INDUSTRY');
export const SAVED_INDUSTRY_FAILURE = Symbol('SAVED_INDUSTRY_FAILURE');
export const SAVED_INDUSTRY_SUCCESS = Symbol('SAVED_INDUSTRY_SUCCESS');

/**
 * Load industries list.
 *
 * @param id {Number} Project's id.
 * @returns Action.
 */
export function save({ afterSuccess = () => {} } = {}) {
  return (dispatch, getState) => {
    const label = getState().project.industryPopup.get('industryLabel');
    const id = getState().popup.getIn(['props', 'id']);

    dispatch({
      id,
      label,
      [CALL_API]: {
        method: 'post',
        path: '/api/v1/browse/industries',
        body: {
          industry_label: label,
        },
        startType: SAVING_INDUSTRY,
        errorType: SAVED_INDUSTRY_FAILURE,
        successType: SAVED_INDUSTRY_SUCCESS,
        afterSuccess: ({ response }) => {
          afterSuccess({ label, id: response.id });
        },
      },
    });
  };
}

/**
 * Change industry label.
 *
 * @param label {String} Label.
 * @returns Action.
 */
export function changeLabel(label) {
  return {
    type: CHANGE_INDUSTRY_LABEL,
    label,
  };
}
