import { CALL_API } from '../../../../middleware/api';
import { unwrap } from '../../../../utils/ChangeSpy';
import { BUSINESS_MODELS, INDUSTRY_CATEGORIES } from '../../../../utils/industryTagsHelper';

export const START_LOAD_PROJECT_TAGS = Symbol('START_LOAD_PROJECT_TAGS');
export const LOAD_PROJECT_TAGS_SUCCESS = Symbol('LOAD_PROJECT_TAGS_SUCCESS');
export const UPDATE_PROJECT_TAGS = Symbol('UPDATE_PROJECT_TAGS');
export const SAVE_PROJECT_TAGS_SUCCESS = Symbol('SAVE_PROJECT_TAGS_SUCCESS');
export const SET_PROJECT_TAGS_VALIDATION_ERROR = Symbol('SET_PROJECT_TAGS_VALIDATION_ERROR');
export const CLOSE_PROJECT_TAGS_ERRORS = Symbol('CLOSE_PROJECT_TAGS_ERRORS');

/**
 * Fetch project tags.
 *
 * @param {number} id Project's id.
 */
export function fetchProjectTags(id) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/${id}?include=project_industry_categories,project_business_models`,
      startType: START_LOAD_PROJECT_TAGS,
      successType: LOAD_PROJECT_TAGS_SUCCESS,
    },
  };
}

/**
 * Save selected project industry tags.
 */
export function saveProjectTags() {
  return (dispatch, getState) => {
    const state = getState().targetCompany.buyer.addOnProject;
    const projectId = state.projectData.get('id');
    const businessModels = unwrap(state.tags.get(BUSINESS_MODELS));
    const industryCategories = unwrap(state.tags.get(INDUSTRY_CATEGORIES));
    const data = [...businessModels, ...industryCategories].map(tag => ({
      industryCategoryId: tag.get('id'),
      priority: 0,
    }));

    dispatch({
      [CALL_API]: {
        method: 'post',
        path: `/api/v1/projects/${projectId}/bulk/industries`,
        body: { data },
        startType: START_LOAD_PROJECT_TAGS,
        successType: SAVE_PROJECT_TAGS_SUCCESS,
      },
    });
  };
}

/**
 * Update store by selected project tags.
 *
 * @param {Array<{}>} categories List of selected industry category tags.
 * @param {Array<{}>} models List of selected business models.
 */
export function updateProjectTags(categories, models) {
  return {
    type: UPDATE_PROJECT_TAGS,
    categories,
    models,
  };
}

/**
 * Update tags error message.
 *
 * @param {string} error Error message.
 */
export function setTagsValidationError(error) {
  return {
    type: SET_PROJECT_TAGS_VALIDATION_ERROR,
    error,
  };
}

/**
 * Remove tags errors.
 */
export function closeTagsErrors() {
  return {
    type: CLOSE_PROJECT_TAGS_ERRORS,
  };
}
