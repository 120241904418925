import PropTypes from 'prop-types';

import React from 'react';
import moment from 'moment';

/**
 * Stateless component for top header.
 *
 * @param props {Object}.
 * @param props.userName {string} Name of logined user.
 * @returns {React.Component}
 */
const TopHeader = props => {
  const { userName } = props;

  return <h1 className="text-center">{`Next Actions - ${userName} - ${moment().format('L')}`}</h1>;
};

TopHeader.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default TopHeader;
