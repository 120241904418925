import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

class CompanyTargetCreateContainer extends Component {
  componentDidMount() {
    if (!this.context.currentUser) return;
    if (this.context.hasPopup()) return;
    this.context.openPopup('AddCompanyTargetPopup');
  }

  componentDidUpdate() {
    this.componentDidMount();
  }

  render() {
    return <Helmet title="Create Target Company" />;
  }
}

CompanyTargetCreateContainer.contextTypes = {
  currentUser: PropTypes.instanceOf(Map),
  hasPopup: PropTypes.func,
  openPopup: PropTypes.func,
};

export { CompanyTargetCreateContainer };
export default connect(() => ({}), {}, mergeProps, connectOptions)(CompanyTargetCreateContainer);
