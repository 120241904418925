import { fromJS, List, Map } from 'immutable';
import * as ActionType from '../actions/browse';

import config from '../config';
import { TYPE_FINANCIAL, TYPE_STRATEGIC } from '../config/constants/common';

const projectTypes = config.browse.getIn(['filter', 'targets', 'projectType', 'options']);
const buyersTypes = config.browse.getIn(['filter', 'buyers', 'activity', 'buyerType', 'options']);
const resumeTypes = config.browse.getIn(['filter', 'executives', 'resume', 'options']);
const mboTypes = config.browse.getIn(['filter', 'executives', 'mbo', 'options']);
const corpBuyerTypes = config.browse.getIn(['filter', 'executives', 'corpBuyer', 'options']);
const needsFundTypes = config.browse.getIn(['filter', 'executives', 'needsFund', 'options']);
const peExperienceTypes = config.browse.getIn(['filter', 'executives', 'peExperience', 'options']);
const eventsTypes = config.browse.getIn(['filter', 'executives', 'events', 'options']);
const meetTypes = config.browse.getIn(['filter', 'executives', 'meet', 'options']);
const doNotMailTypes = config.browse.getIn(['filter', 'executives', 'doNotMail', 'options']);
const employedTypes = config.browse.getIn(['filter', 'executives', 'employed', 'options']);
const activeTypes = config.browse.getIn(['filter', 'projects', 'active', 'options']);

const defaultState = fromJS({
  targets: {
    currentStatus: {
      values: {
        min: 0,
        max: 10,
      },
      text: {
        min: 0,
        max: 10,
      },
      checked: false,
    },
    highStatus: {
      values: {
        min: 0,
        max: 10,
      },
      text: {
        min: 0,
        max: 10,
      },
      checked: false,
    },
    revenue: {
      values: {
        min: 0,
        max: 500,
      },
      text: {
        min: 0,
        max: 500,
      },
      checked: false,
    },
    ebitda: {
      values: {
        min: 0,
        max: 500,
      },
      text: {
        min: 0,
        max: 500,
      },
      checked: false,
    },
    employees: {
      text: {
        min: 0,
        max: 500,
      },
      checked: false,
    },
    states: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    country: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    module: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    analyst: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    dealMaker2: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    researcher: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    isNewProfile: false,
    isUpdatedProfile: false,
    isConfirmedProfile: false,
    buyer: {
      checked: false,
      selectedList: [],
      selectedListParam: [],
      buyer: {
        suggestions: [],
        pagination: {},
        text: '',
        selected: null,
      },
      currentStatus: {
        values: {
          min: 0,
          max: 10,
        },
        text: {
          min: 0,
          max: 10,
        },
      },
      highStatus: {
        values: {
          min: 0,
          max: 10,
        },
        text: {
          min: 0,
          max: 10,
        },
      },
      project: {
        suggestions: [],
        pagination: {},
        text: '',
        selected: null,
      },
      approval: {
        suggestions: [],
        pagination: {},
        text: '',
        selected: null,
      },
      priority: {
        suggestions: [],
        pagination: {},
        text: '',
        selected: null,
      },
      approved: {
        name: '',
        value: '',
      },
      active: null,
    },
    harvcoTags: {
      checked: false,
      text: '',
      selectedList: [],
    },
    industry: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
      selectedTags: {},
      selectedModels: {},
    },
    nextAction: {
      checked: false,
      startDate: null,
      endDate: null,
      eventDescription: '',
      suggestions: [],
      pagination: {},
      text: '',
      selected: null,
      selectedList: [],
    },
    lastAction: {
      // Now is Activity filter. (Has many dependencies so the name stands old.)
      checked: false,
      startDate: null,
      endDate: null,
      eventDescription: '',
      suggestions: [],
      pagination: {},
      text: '',
      selected: null,
      selectedList: [],
      lastActivity: false,
      statusChanged: false,
      statusChangedFrom: {
        values: {
          min: 0,
          max: 10,
        },
        text: {
          min: 0,
          max: 10,
        },
      },
      statusChangedTo: {
        values: {
          min: 0,
          max: 10,
        },
        text: {
          min: 0,
          max: 10,
        },
      },
      projectType: {
        checked: false,
        selected: projectTypes.get(0),
      },
    },
    activityUsers: {
      checked: true,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    description: {
      checked: false,
      text: '',
      selectedList: [],
    },
    companyType: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    areaCode: {
      checked: false,
      text: '',
      selectedList: [],
    },
    keywords: {
      checked: false,
      text: '',
      selectedList: [],
    },
    projectType: {
      checked: false,
      selected: projectTypes.get(0),
    },
    uploadSearch: {
      checked: false,
      data: [],
    },
    mergeResultOnly: {
      checked: false,
    },
    queryResults: [],
    isFetching: false,
    page: 0,
    totalPages: 0,
    totalRecord: 0,
    selectionSize: 0,
    selectList: [],
    sortBy: {
      sortModel: [],
    },
    showTabControls: true,
  },
  buyers: {
    buyerType: {
      checked: false,
      financial: {
        paramName: TYPE_FINANCIAL,
        checked: false,
      },
      strategic: {
        paramName: TYPE_STRATEGIC,
        checked: false,
      },
    },
    priority: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    currentStatus: {
      values: {
        min: 5,
        max: 6,
      },
      text: {
        min: 5,
        max: 6,
      },
      checked: false,
    },
    highStatus: {
      values: {
        min: 0,
        max: 10,
      },
      text: {
        min: 0,
        max: 10,
      },
      checked: false,
    },
    projectActivity: {
      checked: false,
      contacted: {
        checked: false,
        min: 0,
        max: 0,
        type: '',
      },
      currentLead: {
        checked: false,
        min: 0,
        max: 0,
        type: '',
      },
      totalLead: {
        checked: false,
        min: 0,
        max: 0,
        type: '',
      },
      cc: {
        checked: false,
        min: 0,
        max: 0,
        type: '',
      },
      visit: {
        checked: false,
        min: 0,
        max: 0,
        type: '',
      },
      offer: {
        checked: false,
        min: 0,
        max: 0,
        type: '',
      },
    },
    revenue: {
      values: {
        min: 0,
        max: 500,
      },
      text: {
        min: 0,
        max: 500,
      },
      checked: false,
    },
    ebitda: {
      values: {
        min: 0,
        max: 500,
      },
      text: {
        min: 0,
        max: 500,
      },
      checked: false,
    },
    ev: {
      values: {
        min: 0,
        max: 10,
      },
      text: {
        min: 0,
        max: 10,
      },
      checked: false,
    },
    equity: {
      values: {
        min: 0,
        max: 10,
      },
      text: {
        min: 0,
        max: 10,
      },
      checked: false,
    },
    transactionTypes: {
      checked: false,
      buyout: {
        checked: false,
      },
      recap: {
        checked: false,
      },
      mezz: {
        checked: false,
      },
      carveout: {
        checked: false,
      },
      takeprivate: {
        checked: false,
      },
      minority: {
        checked: false,
      },
      turnaround: {
        checked: false,
      },
    },
    geography: {
      checked: false,
      text: '',
      selectedList: [],
    },
    industry: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
      selectedTags: {},
      selectedModels: {},
    },
    harvcoContact: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    companyType: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    companyState: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    areaCode: {
      checked: false,
      text: '',
      selectedList: [],
    },
    nextAction: {
      checked: false,
      startDate: null,
      endDate: null,
      eventDescription: '',
      suggestions: [],
      pagination: {},
      text: '',
      selected: null,
      selectedList: [],
    },
    activity: {
      checked: false,
      startDate: null,
      endDate: null,
      eventDescription: '',
      suggestions: [],
      pagination: {},
      text: '',
      selected: null,
      selectedList: [],
      lastActivity: false,
      statusChanged: false,
      statusChangedFrom: {
        values: {
          min: 0,
          max: 10,
        },
        text: {
          min: 0,
          max: 10,
        },
      },
      statusChangedTo: {
        values: {
          min: 0,
          max: 10,
        },
        text: {
          min: 0,
          max: 10,
        },
      },
      buyerType: {
        checked: false,
        selected: buyersTypes.get(0),
      },
    },
    activityUsers: {
      checked: true,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    harvcoTags: {
      checked: false,
      text: '',
      selectedList: [],
    },
    country: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    startDate: {
      checked: false,
      min: '',
      max: '',
    },
    endDate: {
      checked: false,
      values: {
        min: '',
        max: '',
      },
    },
    keywords: {
      checked: false,
      text: '',
      selectedList: [],
    },
    filteringStatus: false,
    queryResults: [],
    isFetching: false,
    page: 0,
    totalPages: 0,
    totalRecord: 0,
    selectionSize: 0,
    sortBy: {
      sortModel: [],
    },
    showTabControls: true,
    selectList: [],
    mergeResultOnly: {
      checked: false,
    },
  },
  executives: {
    currentStatus: {
      values: {
        min: 5,
        max: 6,
      },
      text: {
        min: 5,
        max: 6,
      },
      checked: false,
    },
    highStatus: {
      values: {
        min: 0,
        max: 10,
      },
      text: {
        min: 0,
        max: 10,
      },
      checked: false,
    },
    module: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    analyst: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    dealmaker: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    researcher: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    fund: {
      checked: false,
      selectedList: [],
      selectedListParam: [],
      fund: {
        suggestions: [],
        pagination: {},
        text: '',
        selected: null,
      },
      project: {
        suggestions: [],
        pagination: {},
        text: '',
        selected: null,
      },
      active: null,
    },
    harvcoTags: {
      checked: false,
      text: '',
      selectedList: [],
    },
    industry: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
      selectedTags: {},
      selectedModels: {},
    },
    nextAction: {
      checked: false,
      startDate: null,
      endDate: null,
      eventDescription: '',
      suggestions: [],
      pagination: {},
      text: '',
      selected: null,
      selectedList: [],
    },
    activity: {
      checked: false,
      startDate: null,
      endDate: null,
      eventDescription: '',
      suggestions: [],
      pagination: {},
      text: '',
      selected: null,
      selectedList: [],
      lastActivity: false,
      statusChanged: false,
      statusChangedFrom: {
        values: {
          min: 0,
          max: 10,
        },
        text: {
          min: 0,
          max: 10,
        },
      },
      statusChangedTo: {
        values: {
          min: 0,
          max: 10,
        },
        text: {
          min: 0,
          max: 10,
        },
      },
      projectType: {
        checked: false,
        selected: projectTypes.get(0),
      },
    },
    activityUsers: {
      checked: true,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    areaCode: {
      checked: false,
      text: '',
      selectedList: [],
    },
    companyState: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    keywords: {
      checked: false,
      text: '',
      selectedList: [],
    },
    resume: {
      checked: false,
      selected: resumeTypes.get(0),
    },
    mbo: {
      checked: false,
      selected: mboTypes.get(0),
    },
    corpBuyer: {
      checked: false,
      selected: corpBuyerTypes.get(0),
    },
    needsFund: {
      checked: false,
      selected: needsFundTypes.get(0),
    },
    peExperience: {
      checked: false,
      selected: peExperienceTypes.get(0),
    },
    events: {
      checked: false,
      selected: eventsTypes.get(0),
    },
    meet: {
      checked: false,
      selected: meetTypes.get(0),
    },
    quality: {
      checked: false,
      wcm: {
        value: 5,
        checked: false,
      },
      solid: {
        value: 4.5,
        checked: false,
      },
      good: {
        value: 4,
        checked: false,
      },
      goodNotVerified: {
        value: 3.9,
        checked: false,
      },
      average: {
        value: 3,
        checked: false,
      },
      weaker: {
        value: 2,
        checked: false,
      },
      poor: {
        value: 1,
        checked: false,
      },
      unsure: {
        value: 0,
        checked: false,
      },
    },
    interest: {
      checked: false,
      very: {
        value: 3,
        checked: false,
      },
      somewhat: {
        value: 2,
        checked: false,
      },
      no: {
        value: 1,
        checked: false,
      },
      unknown: {
        value: 0,
        checked: false,
      },
    },
    relationship: {
      checked: false,
      strong: {
        value: 3,
        checked: false,
      },
      semi: {
        value: 2,
        checked: false,
      },
      brief: {
        value: 1,
        checked: false,
      },
      no: {
        value: 0,
        checked: false,
      },
    },
    doNotMail: {
      checked: false,
      selected: doNotMailTypes.get(1),
    },
    employed: {
      checked: false,
      selected: employedTypes.get(1),
    },
    queryResults: [],
    isFetching: false,
    page: 0,
    totalPages: 0,
    totalRecord: 0,
    selectionSize: 0,
    sortBy: {
      sortModel: [],
    },
    showTabControls: true,
    selectList: [],
  },
  projects: {
    buyer: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    harvcoContact: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    users: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    active: {
      checked: false,
      selected: activeTypes.get(0),
    },
    category: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
    },
    queryResults: [],
    isFetching: false,
    page: 0,
    totalPages: 0,
    totalRecord: 0,
    selectionSize: 0,
    sortBy: {
      sortModel: [],
    },
    showTabControls: true,
    selectList: [],
    industry: {
      checked: false,
      suggestions: [],
      pagination: {},
      text: '',
      selectedList: [],
      selectedTags: {},
      selectedModels: {},
    },
  },
  searchCriteriaHash: '',
});

export default function browse(state = defaultState, action) {
  switch (action.type) {
    case ActionType.UPDATE_FILTER: {
      Object.keys(action.filterData).forEach(key => {
        if (List.isList(action.filterData[key]) || Array.isArray(action.filterData[key])) {
          state = state.setIn([action.name, action.filterName, key], new List());
        }
      });
      if (action.key === 'selectedTags' || action.key === 'selectedModels') {
        return state.setIn([action.name, action.filterName, action.key], action.filterData[action.key]);
      }

      return state.mergeDeep({
        [action.name]: {
          [action.filterName]: action.filterData,
        },
      });
    }

    case ActionType.UPDATE_TARGETS_BUYER_FILTER:
      Object.keys(action.filterData).forEach(key => {
        if (List.isList(action.filterData[key]) || Array.isArray(action.filterData[key])) {
          state = state.setIn(['targets', 'buyer', action.filterName, key], new List());
        }
      });

      return state.mergeDeep({
        targets: {
          buyer: {
            [action.filterName]: action.filterData,
          },
        },
      });

    case ActionType.UPDATE_TARGETS_ACTIVITY_FILTER:
      Object.keys(action.filterData).forEach(key => {
        if (List.isList(action.filterData[key]) || Array.isArray(action.filterData[key])) {
          state = state.setIn(['targets', 'lastAction', action.filterName, key], new List());
        }
      });

      return state.mergeDeep({
        targets: {
          lastAction: {
            [action.filterName]: action.filterData,
          },
        },
      });

    case ActionType.UPDATE_BUYERS_ACTIVITY_FILTER:
      Object.keys(action.filterData).forEach(key => {
        if (List.isList(action.filterData[key]) || Array.isArray(action.filterData[key])) {
          state = state.setIn(['buyers', 'activity', action.filterName, key], new List());
        }
      });

      return state.mergeDeep({
        buyers: {
          activity: {
            [action.filterName]: action.filterData,
          },
        },
      });

    case ActionType.UPDATE_EXECUTIVES_ACTIVITY_FILTER:
      Object.keys(action.filterData).forEach(key => {
        if (List.isList(action.filterData[key]) || Array.isArray(action.filterData[key])) {
          state = state.setIn(['executives', 'activity', action.filterName, key], new List());
        }
      });

      return state.mergeDeep({
        executives: {
          activity: {
            [action.filterName]: action.filterData,
          },
        },
      });

    case ActionType.UPDATE_EXECUTIVES_FUND_FILTER:
      Object.keys(action.filterData).forEach(key => {
        if (List.isList(action.filterData[key]) || Array.isArray(action.filterData[key])) {
          state = state.setIn(['executives', 'fund', action.filterName, key], new List());
        }
      });

      return state.mergeDeep({
        executives: {
          fund: {
            [action.filterName]: action.filterData,
          },
        },
      });

    case ActionType.FETCHING_SUGGESTIONS_START: {
      const tempState = state.mergeIn([action.actionFrom, action.fieldName], {
        loading: true,
        questId: action.questId,
      });

      if (!action.next) {
        return tempState.setIn([action.actionFrom, action.fieldName, 'suggestions'], List());
      }

      return tempState;
    }

    case ActionType.FETCHING_SUGGESTIONS_SUCCESS: {
      if (state.getIn([action.actionFrom, action.fieldName, 'questId']) === action.questId) {
        return state.mergeIn([action.actionFrom, action.fieldName], {
          suggestions: state
            .getIn([action.actionFrom, action.fieldName, 'suggestions'])
            .concat(List(action.response.data)),
          loading: false,
          pagination:
            action.response.meta && action.response.meta.pagination ? Map(action.response.meta.pagination) : Map(),
        });
      }

      return state;
    }

    case ActionType.FETCHING_BUYER_SUGGESTIONS_START: {
      const tempState = state.mergeIn(['targets', 'buyer', action.fieldName], {
        loading: true,
        questId: action.questId,
      });

      if (!action.next) {
        return tempState.setIn(['targets', 'buyer', action.fieldName, 'suggestions'], List());
      }

      return tempState;
    }

    case ActionType.FETCHING_BUYER_SUGGESTIONS_SUCCESS: {
      if (state.getIn(['targets', 'buyer', action.fieldName, 'questId']) === action.questId) {
        return state.mergeIn(['targets', 'buyer', action.fieldName], {
          suggestions: state
            .getIn(['targets', 'buyer', action.fieldName, 'suggestions'])
            .concat(List(action.response.data)),
          loading: false,
          pagination:
            action.response.meta && action.response.meta.pagination ? Map(action.response.meta.pagination) : Map(),
        });
      }

      return state;
    }

    case ActionType.FETCHING_FUND_SUGGESTIONS_START: {
      const tempState = state.mergeIn(['executives', 'fund', action.fieldName], {
        loading: true,
        questId: action.questId,
      });

      if (!action.next) {
        return tempState.setIn(['executives', 'fund', action.fieldName, 'suggestions'], List());
      }

      return tempState;
    }

    case ActionType.FETCHING_FUND_SUGGESTIONS_SUCCESS: {
      if (state.getIn(['executives', 'fund', action.fieldName, 'questId']) === action.questId) {
        return state.mergeIn(['executives', 'fund', action.fieldName], {
          suggestions: state
            .getIn(['executives', 'fund', action.fieldName, 'suggestions'])
            .concat(List(action.response.data)),
          loading: false,
          pagination:
            action.response.meta && action.response.meta.pagination ? Map(action.response.meta.pagination) : Map(),
        });
      }

      return state;
    }

    case ActionType.FETCHING_BROWSE:
      return state.mergeDeep({
        [action.actionFrom]: {
          isFetching: true,
          questId: action.questId,
        },
      });

    case ActionType.STOP_FETCHING_BROWSE:
      return state.mergeDeep({
        [action.actionFrom]: {
          isFetching: false,
          questId: action.questId,
        },
      });

    case ActionType.FETCHING_BROWSE_SUCCESS: {
      if (action.questId === state.getIn([action.actionFrom, 'questId']) && action.response.status === 'success') {
        if (action.response.meta.pagination.currentPage === 1) {
          state = state.setIn([action.actionFrom, 'queryResults'], new List());
        }
        state = state.mergeDeep({
          [action.actionFrom]: {
            page: action.response.meta.pagination.currentPage,
            queryResults: state
              .getIn([action.actionFrom, 'queryResults'])
              .concat(fromJS(getResult(state.getIn([action.actionFrom, 'queryResults']).size, action.response.data))),
            isFetching: false,
            totalPages: action.response.meta.pagination.totalPages,
            totalRecord: action.response.meta.pagination.total,
          },
        });
        if (action.keepSelection) {
          return restoreSelection(action, state);
        }
        state = state.setIn([action.actionFrom, 'selectList'], new List());

        return state;
      }

      return state;
    }

    case ActionType.FETCHING_BROWSE_FAILURE: {
      if (action.questId === state.get('questId')) {
        return state.mergeDeep({
          [action.actionFrom]: {
            isFetching: false,
          },
        });
      }

      return state;
    }

    case ActionType.UPDATE_SELECTED_ROW: {
      const selectedItem = state.getIn([action.name, 'queryResults', action.index]);

      state = state.updateIn([action.name, 'selectList'], selectList => {
        if (action.value) {
          if (selectList.some(item => item.get('id') === selectedItem.get('id'))) {
            return selectList;
          }

          return selectList.push(
            new Map({
              id: selectedItem.get('id'),
            }),
          );
        }

        return selectList.delete(selectList.findIndex(item => item.get('id') === selectedItem.get('id')));
      });

      return state.setIn([action.name, 'queryResults', action.index, action.field], action.value);
    }

    case ActionType.UPDATE_ALL_ROWS: {
      state.getIn([action.name, 'queryResults']).forEach(q => {
        state = state.updateIn([action.name, 'selectList'], selectList => {
          const index = selectList.findIndex(item => item.get('id') === q.get('id'));

          if (index === -1) {
            if (action.value) {
              return selectList.push(
                new Map({
                  id: q.get('id'),
                }),
              );
            }
          } else if (!action.value) {
            return selectList.delete(index);
          }

          return selectList;
        });

        return true;
      });

      return state.updateIn([action.name, 'queryResults'], list =>
        list.map(item => item.set(action.field, action.value)),
      );
    }

    case ActionType.CLEAR_FILTER:
      return state.set(action.name, defaultState.get(action.name)).set('questId', undefined);

    case ActionType.ABORT_CURRENT_REQUEST:
      return state.setIn([action.name, 'isFetching'], false);

    case ActionType.UPDATE_QUERY_RESULTS:
      state = state.setIn([action.actionFrom, 'queryResults'], new List());

      return state.mergeDeep({
        [action.actionFrom]: {
          queryResults: action.data,
        },
      });

    case ActionType.UPDATE_TAB_CONTROL_VISIBILITY:
      return state.setIn(action.field.split('.'), action.value);

    case ActionType.CLEAR_SEARCH_RESULTS:
      return state.mergeIn([action.tab], {
        queryResults: List(),
        isFetching: false,
        page: 0,
        totalPages: 0,
        totalRecord: 0,
        questId: '',
      });

    case ActionType.MASS_ROW_SELECTIONS: {
      const { name, rowIndexs } = action;
      const tmp = state.getIn([name, 'queryResults']);

      rowIndexs.forEach(item => {
        const selectedItem = state.getIn([action.name, 'queryResults', item]);

        state = state.updateIn([action.name, 'selectList'], selectList => {
          if (selectList.some(item => item.get('id') === selectedItem.get('id'))) {
            return selectList;
          }

          return selectList.push(
            new Map({
              id: selectedItem.get('id'),
            }),
          );
        });
      });

      return state.setIn(
        [name, 'queryResults'],
        tmp.map(row => {
          if (rowIndexs.indexOf(row.get('order')) > -1) {
            return row.set('selected', true);
          }

          return row;
        }),
      );
    }

    case ActionType.RESET_SELECTION:
      state = state.setIn([action.name, 'selectList'], new List());

      return state.updateIn([action.name, 'queryResults'], list => list.map(item => item.set('selected', false)));

    case ActionType.SEND_SEARCH_CRITERIA_SUCCESS:
      return state.set('searchCriteriaHash', action.response.hash);

    case ActionType.RESET_SEARCH_CRITERIA_HASH:
      return state.set('searchCriteriaHash', '');

    default:
      return state;
  }
}

/**
 * Add order number to result.
 *
 * @param {number} start Start number.
 * @param {object} data Return data.
 */
function getResult(start = 0, data) {
  return data.map((d, i) => {
    d.order = start + i;

    return d;
  });
}

function restoreSelection(action, state) {
  const selectList = state.getIn([action.actionFrom, 'selectList']);

  return state.updateIn([action.actionFrom, 'queryResults'], list =>
    list.map(item => {
      const index = selectList.findIndex(selectItem => selectItem.get('id') === item.get('id'));

      return item.set('selected', index !== -1);
    }),
  );
}
