import { CALL_API } from '../../middleware/api';
import config from '../../config';
import uniqueId from '../../utils/uniqueId';
import { loadUsers, saveUsers } from './user';
import { loadIndustries } from './industry';
import { loadApprovals, saveApproval } from './approval';
import { loadTemplates } from './template';
import { loadTargets } from './target';

export const SHOW_PROJECT_LOADING = Symbol('SHOW_PROJECT_LOADING');
export const REQUEST_API_PROJECT_FAILURE = Symbol('REQUEST_API_PROJECT_FAILURE');
export const LOADED_PROJECT_SUCCESS = Symbol('LOADED_PROJECT_SUCCESS');
export const UPDATE_PROJECT_INFORMATION = Symbol('UPDATE_PROJECT_INFORMATION');
export const CHANGE_PROJECT_ROW_MODE = Symbol('CHANGE_PROJECT_ROW_MODE ');
export const RESET_ALL_ROW_TO_VIEW_MODE = Symbol('RESET_ALL_ROW_TO_VIEW_MODE');
export const RESET_ERROR_LIST = Symbol('RESET_ERROR_LIST');
export const SAVED_PROJECT_SUCCESS = Symbol('SAVED_PROJECT_SUCCESS');
export const REVERT_PROJECT_CHANGES = Symbol('REVERT_PROJECT_CHANGES');
export const UPDATE_PROJECT_COMMON_INFO = Symbol('UPDATE_PROJECT_COMMON_INFO');

export const SELECT_PROJECT_SUGGESTION = Symbol('SELECT_PROJECT_SUGGESTION');
export const LOADING_PROJECT_SUGGESTION = Symbol('LOADING_PROJECT_SUGGESTION');
export const LOADED_PROJECT_SUGGESTION_FAILURE = Symbol('LOADED_PROJECT_SUGGESTION_FAILURE');
export const LOADED_PROJECT_SUGGESTION_SUCCESS = Symbol('LOADED_PROJECT_SUGGESTION_SUCCESS');

export const FETCHING_LIST_COMPARABLES = Symbol('FETCHING_LIST_COMPARABLES');
export const FETCHING_LIST_COMPARABLES_SUCCESS = Symbol('FETCHING_LIST_COMPARABLES_SUCCESS');

export const LOADED_PROJECT_COMPARABLES = Symbol('LOADED_PROJECT_COMPARABLES');
export const LOADED_PROJECT_COMPARABLES_SUCCESS = Symbol('LOADED_PROJECT_COMPARABLES_SUCCESS');
export const SAVED_PROJECT_COMPARABLES_SUCCESS = Symbol('SAVED_PROJECT_COMPARABLES_SUCCESS');
export const CLEAR_COMPARABLES = Symbol('CLEAR_COMPARABLES');
export const CHANGE_COMPARABLES = Symbol('CHANGE_COMPARABLES');

export const LOADING_PROJECT_CRITERIA = Symbol('LOADING_PROJECT_CRITERIA');
export const LOADED_PROJECT_CRITERIA_SUCCESS = Symbol('LOADED_PROJECT_CRITERIA_SUCCESS');
export const SAVED_PROJECT_CRITERIA_SUCCESS = Symbol('SAVED_PROJECT_CRITERIA_SUCCESS');
export const SAVED_PROJECT_CRITERIA_FAILURE = Symbol('SAVED_PROJECT_CRITERIA_FAILURE');

/**
 * Save project general information.
 *
 * @returns Action.
 */
export function saveProject() {
  return (dispatch, getState) => {
    const state = getState().project.projectData;
    const { common } = getState().project;
    const projectId = state.get('id');
    const body = {
      category: state.getIn(['category', 'name']),
      details: state.getIn(['details', 'name']),
      status: state.getIn(['status', 'name']),
      active: state.getIn(['active', 'value']),
      geography: common.getIn(['geography', 'value']),
    };
    const harvcoLeadId = state.getIn(['harvcoLead', 'id']);

    if (harvcoLeadId === -1) {
      body.harvco_lead_id = null;
    }

    if (harvcoLeadId > 0) {
      body.harvco_lead_id = harvcoLeadId;
    }

    const clientLeadId = state.getIn(['clientLead', 'id']);

    if (clientLeadId === -1) {
      body.client_lead_id = null;
    }

    if (clientLeadId > 0) {
      body.client_lead_id = clientLeadId;
    }

    const execId = state.getIn(['exec', 'id']);

    if (execId === -1) {
      body.exec_id = null;
    }

    if (execId > 0) {
      body.exec_id = execId;
    }

    dispatch({
      fields: Object.keys(body),
      [CALL_API]: {
        method: 'put',
        path: `/api/v1/projects/${projectId}`,
        body,
        startType: SHOW_PROJECT_LOADING,
        errorType: REQUEST_API_PROJECT_FAILURE,
        successType: SAVED_PROJECT_SUCCESS,
      },
    });
  };
}

/**
 * Load page's data initially.
 *
 * @param id {Number} Project's id.
 * @param afterSuccess {Function}.
 * @param isReload {Boolean} If false concat data, for lazy loading.
 * @returns Action.
 */
export function loadPage(id, { afterSuccess } = {}, isReload = false) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/${id}`,
      afterSuccess: ({ dispatch, response }) => {
        dispatch(clearError());
        dispatch(loadProject(id));
        dispatch(loadUsers(id));
        dispatch(loadIndustries(id));
        dispatch(loadApprovals({ projectId: id, isDefaultAddon: response.data.isDefaultAddOn }));
        dispatch(loadTemplates(id));
        dispatch(loadTargets(id, { limit: 60 }, { afterSuccess }, isReload));
      },
    },
  };
}

/**
 * Save all page's information.
 *
 * @returns Action.
 */
export function savePage({ approval }) {
  return dispatch => {
    dispatch(clearError());
    dispatch(saveUsers());
    dispatch(saveProject());
    dispatch(saveApproval(approval));
  };
}

/**
 * Load project information.
 *
 * @param id {Number} Project's id.
 * @returns Action.
 */
export function loadProject(id) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/${id}`,
      query: { include: 'buyer,harvco_lead,client_lead,exec' },
      startType: SHOW_PROJECT_LOADING,
      errorType: REQUEST_API_PROJECT_FAILURE,
      successType: LOADED_PROJECT_SUCCESS,
    },
  };
}

function getEndPoint(field, value, buyerId) {
  if (field === 'harvcoLead') {
    return {
      path: '/api/v1/browse/users_by_role',
      query: { like: value, role: 'director' },
    };
  }

  if (field === 'exec') {
    return {
      path: '/api/v1/browse/execs',
      query: { like: value },
    };
  }

  return {
    path: `/api/v1/browse/buyers/${buyerId}/people`,
    query: { like: value },
  };
}

/**
 * Update project and suggest information based on user's input.
 *
 * @param field {String} Name of project's field.
 * @param value {*} Value to input.
 * @returns Action.
 */
export function updateProjectInfo(field, value = '') {
  const sugs = ['harvcoLead', 'clientLead', 'exec'];

  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_PROJECT_INFORMATION,
      field,
      value,
    });
    if (sugs.indexOf(field) > -1) {
      const buyerId = getState().project.projectData.get('buyerId');

      dispatch({
        [CALL_API]: {
          ...getEndPoint(field, value, buyerId),
          method: 'get',
          startType: LOADING_PROJECT_SUGGESTION,
          errorType: LOADED_PROJECT_SUGGESTION_FAILURE,
          successType: LOADED_PROJECT_SUGGESTION_SUCCESS,
        },
        requestId: uniqueId(),
        field,
      });
    }
  };
}

/**
 * Switch project's row to loading mode.
 *
 * @param field {String} Field name.
 * @returns Action.
 */
export function switchProjectRowToLoading(field) {
  return {
    type: CHANGE_PROJECT_ROW_MODE,
    field,
    mode: config.LOADING_MODE,
  };
}

/**
 * Switch project's row to suggest mode.
 *
 * @param field {String} Field name.
 * @returns Action.
 */
export function switchProjectRowToSuggest(field) {
  return {
    type: CHANGE_PROJECT_ROW_MODE,
    field,
    mode: config.SUGGEST_MODE,
  };
}

/**
 * Switch all rows to view mode.
 *
 * @returns Action.
 */
export function reset() {
  return {
    type: RESET_ALL_ROW_TO_VIEW_MODE,
  };
}

/**
 * Select a suggestion.
 *
 * @param field {exec|harvcoLead|clientLead} Project field.
 * @param suggestionId {Number} Suggestion id.
 * @param suggestionName {String} Suggestion name.
 * @returns Action.
 */
export function selectProjectSuggestion(field, suggestionId, suggestionName) {
  return {
    type: SELECT_PROJECT_SUGGESTION,
    field,
    suggestionId,
    suggestionName,
  };
}

/**
 * Reset error list.
 *
 * @returns Action.
 */
export function clearError() {
  return {
    type: RESET_ERROR_LIST,
  };
}

/**
 * Revert changes which didn't save.
 */
export function revertProjectChanges() {
  return {
    type: REVERT_PROJECT_CHANGES,
  };
}

/**
 * Updates project's common information (includes geography, comparables, etc.).
 *
 * @param field Field's name.
 * @param value Field's new value.
 * @returns {{field, type: symbol, value}} Action object.
 */
export function updateProjectCommonInfo(field, value) {
  return {
    type: UPDATE_PROJECT_COMMON_INFO,
    field,
    value,
  };
}

/**
 * Sends post request to save project's comparables.
 *
 * @param projectId Project's id.
 * @param comparablesIds Array of project's comparables ids.
 * @returns {{}}
 */
export function saveProjectsComparables(projectId, comparablesIds) {
  const body = {
    company_ids: comparablesIds.filter(id => id !== ''),
  };

  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/projects/${projectId}/comparable`,
      body,
      successType: SAVED_PROJECT_COMPARABLES_SUCCESS,
    },
  };
}

/**
 * Sends get request to load project's comparables.
 *
 * @param projectId Project's id.
 * @returns Action.
 */
export function loadProjectsComparables(projectId) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/${projectId}/comparable`,
      startType: LOADED_PROJECT_COMPARABLES,
      successType: LOADED_PROJECT_COMPARABLES_SUCCESS,
    },
  };
}

/**
 * Clears comparables array after project change.
 *
 * @returns {{type: symbol}}
 */
export function clearComparables() {
  return {
    type: CLEAR_COMPARABLES,
  };
}

/**
 * Action that handles comparables changes.
 *
 * @param ids Array with ids.
 * @param data Array with data.
 * @returns {{data, ids, type: symbol}}
 */
export function changeComparables(ids, data) {
  return {
    type: CHANGE_COMPARABLES,
    data,
    ids,
  };
}

/**
 * Sends get request to lad project's criteria.
 *
 * @param companyId Company Id.
 * @param projectId Project Id.
 * @returns Action.
 */
export function loadProjectCriteria(companyId, projectId) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/buyer/${companyId}/project/${projectId}/project_criteria`,
      startType: LOADING_PROJECT_CRITERIA,
      successType: LOADED_PROJECT_CRITERIA_SUCCESS,
    },
  };
}

/**
 * Sends post request to save projects criteria.
 *
 * @param companyId Company Id.
 * @returns Action.
 */
export function saveProjectCriteria(companyId) {
  return (dispatch, getState) => {
    const state = getState().project.projectData;
    const { common } = getState().project;
    const projectId = state.get('id');
    const body = {
      revenueHigh: common.getIn(['revenueHigh', 'value']),
      revenueLow: common.getIn(['revenueLow', 'value']),
      ebitdaHigh: common.getIn(['ebitdaHigh', 'value']),
      ebitdaLow: common.getIn(['ebitdaLow', 'value']),
      evHigh: common.getIn(['evHigh', 'value']),
      evLow: common.getIn(['evLow', 'value']),
      minority: common.getIn(['minority', 'value']),
      mezz: common.getIn(['mezz', 'value']),
      buyout: common.getIn(['buyout', 'value']),
      recap: common.getIn(['recap', 'value']),
      takePrivate: common.getIn(['takePrivate', 'value']),
      carveOut: common.getIn(['carveOut', 'value']),
      turnaround: common.getIn(['turnaround', 'value']),
    };

    dispatch({
      fields: Object.keys(body),
      [CALL_API]: {
        method: 'post',
        path: `/api/v1/buyer/${companyId}/project/${projectId}/project_criteria`,
        body,
        successType: SAVED_PROJECT_CRITERIA_SUCCESS,
        errorType: SAVED_PROJECT_CRITERIA_FAILURE,
      },
    });
  };
}
