/**
 * Get percent.
 *
 * @param {number} numerator Numerator.
 * @param {number} denominator Denominator.
 */
export default function percent(numerator, denominator) {
  if (denominator === 0) {
    return 0;
  }

  return ((numerator / denominator) * 100).toFixed(2);
}
