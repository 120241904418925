import { fromJS } from 'immutable';

import * as ActionType from '../../../actions/company/buyer/roleCreating';
import getValidator from '../../../utils/getValidator';
import { spy, setIn, toJS, revertToOriginal } from '../../../utils/ChangeSpy';
import { TYPE_FINANCIAL, TYPE_STRATEGIC } from '../../../config/constants/common';

const types = fromJS([
  { name: '', value: '', disabled: true },
  { name: TYPE_STRATEGIC, value: TYPE_STRATEGIC },
  { name: TYPE_FINANCIAL, value: TYPE_FINANCIAL },
]);

const SCHEMA = {
  type: 'object',
  properties: {
    buyerType: {
      title: 'Buyer Type',
      description: 'should be valid type',
      oneOf: [
        { type: 'string', pattern: /^Strategic$/ },
        { type: 'string', pattern: /^Financial/ },
      ],
    },
    buyerHarvcoLeadName: {
      title: 'Harvco Contact',
      description: 'should not be empty',
      oneOf: [{ type: 'string', pattern: /./ }],
    },
    buyerHarvcoLeadId: {
      title: 'Harvco Contact',
      description: 'should be valid user',
      errorTo: 'buyerHarvcoLeadName',
      type: 'number',
      minimum: 1,
    },
  },
};

const FIELDS_TO_SPY = ['buyerType', 'buyerHarvcoLeadName', 'buyerHarvcoLeadId'];

const validator = getValidator(SCHEMA);

const defaultState = spy(
  fromJS({
    buyerTypes: types,
    isValid: true,
    inputErrors: {},
    disabledType: false,
    buyerType: '',
    buyerHarvcoLeadId: 0,
    buyerHarvcoLeadName: '',
    buyerHarvcoLeadSuggests: [],
  }),
  FIELDS_TO_SPY,
);

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.UPDATE_COMPANY:
      return checkValidity(changeField(state, action));

    case ActionType.REVERT_UPDATE:
      return checkValidity(revertToOriginal(state, action.field));

    case ActionType.LOADED_USERS:
      return state.setIn(
        ['buyerHarvcoLeadSuggests'],
        action.page === 1
          ? fromJS(action.response.data)
          : state.get('buyerHarvcoLeadSuggests').concat(action.response.data),
      );

    case ActionType.ERRORED_COMPANY_BUYER:
      return state;

    case ActionType.CLOSE_COMPANY_BUYER_VALIDATION_ERROR:
      return state.deleteIn(['inputErrors', action.field]).deleteIn(['newContact', 'inputErrors', action.field]);

    default:
      return state;
  }
}

function clearChangedFieldError(state, action) {
  const split = action.name.split('.');

  for (let i = 0; i < split.length; i++) {
    const pre = split.slice(0, i);
    const post = split.slice(i);

    state = state.deleteIn([...pre, 'inputErrors', post.join('.')]);
  }

  const split2 = action.name.replace(/\.pivot\./, '.').split('.');

  for (let i = 0; i < split2.length; i++) {
    const pre = split2.slice(0, i);
    const post = split2.slice(i);

    state = state.deleteIn([...pre, 'inputErrors', post.join('.')]);
  }

  return state;
}

function changeField(state, action) {
  return clearChangedFieldError(setIn(state, action.name, action.value), action);
}

function checkValidity(state) {
  const plainState = toJS(state);
  const isValid = validator.isValid(plainState);

  const inputErrors = validator.getFirstError(plainState);

  return state.merge({
    inputErrors,
    isValid,
  });
}
