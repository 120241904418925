import React from 'react';
import config from '../config';

function hasPermission(loggedUser) {
  const hasDeletion = [
    config.ADMINISTRATOR,
    config.PROCESS_MANAGER,
    config.DIRECTOR,
    config.ANALYST_ASSOCIATE,
    config.FINANCE_ADMIN,
  ];

  if (!loggedUser) return false;

  return loggedUser.get('roles').reduce((ret, role) => {
    if (hasDeletion.indexOf(role.get('slug')) > -1) return true;

    return ret;
  }, false);
}

export default function deletionCheck(loggedUser, func, context) {
  return function(...rest) {
    if (hasPermission(loggedUser)) {
      func(...rest);
    } else {
      context.openPopup('InformationPopup', {
        message: {
          texts: ["You don't have permission to delete these items"],
          className: 'text-danger',
        },
        header: (
          <span>
            <i aria-hidden="true" className="fa fa-exclamation-triangle text-danger" />
            Error Alert
          </span>
        ),
      });
    }
  };
}
