import PropTypes from 'prop-types';
import React, { PureComponent, useRef } from 'react';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import classNames from 'classnames';
import { ContextMenu, MenuItem, ContextMenuTrigger, showMenu } from 'react-contextmenu';
import CopyToClipboard from 'react-copy-to-clipboard';

import ChangeDetection from '../decorators/ChangeDetection';
import { unwrap } from '../../utils/ChangeSpy';
import uniqueId from '../../utils/uniqueId';
import getContactMailLink from '../../utils/getContactMailLink';
import { configureExecEmailMergedFields } from '../../utils/configureEmailMergedFields';
import _Input from '../helpers/Input';
import _Span from '../helpers/Span';
import { EmailTemplatesContextMenuWrapper } from '../helpers/EmailTemplatesContextMenuWrapper';
import DisplayError from '../decorators/DisplayError';
import PrettyError from '../decorators/PrettyError';
import { fetchExecutivesEmailTemplates } from '../../actions/emailTemplates';
import { findDirectors } from '../../actions/contact/contactExecutive';

const emptyList = List();

const Input = PrettyError(DisplayError(_Input));
const Span = PrettyError(DisplayError(_Span));

const ContextMenuWrapper = props => {
  const {
    component,
    contactId,
    contactValue,
    executiveInfo,
    emailTemplates,
    fetchEmailTemplates,
    findDirectors,
    isEmail,
    onClick,
    onOpenEmail,
    value,
  } = props;
  const templatesMenuRef = useRef(null);

  const id = uniqueId();
  const templatesMenuId = uniqueId();

  const onSendEmailItemClick = event => {
    event.stopPropagation();

    const coords = templatesMenuRef.current.getBoundingClientRect();

    showMenu({
      position: { x: coords.right - 50, y: coords.bottom },
      id: templatesMenuId,
    });
  };

  const menuItems = contactId !== 'new' && (
    <React.Fragment>
      {isEmail && (
        <MenuItem onClick={onSendEmailItemClick}>
          <div>
            <i className="fa fa-envelope" />
            Send an email
          </div>
        </MenuItem>
      )}
      <MenuItem onClick={() => {}}>
        <CopyToClipboard text={value}>
          <div>
            <strong>
              <i className="fa fa-clipboard" />
            </strong>
            Copy
          </div>
        </CopyToClipboard>
      </MenuItem>
    </React.Fragment>
  );

  return (
    <div className="contact-cell-value">
      {isEmail && (
        <EmailTemplatesContextMenuWrapper
          companyInfo={executiveInfo}
          email={contactValue}
          emailTemplates={emailTemplates}
          fetchEmailTemplates={fetchEmailTemplates}
          findDirectors={findDirectors}
          id={templatesMenuId}
          onOpenEmail={onOpenEmail}
          templateType="executive"
        >
          <div ref={templatesMenuRef} />
        </EmailTemplatesContextMenuWrapper>
      )}
      <ContextMenuTrigger id={id}>{component}</ContextMenuTrigger>

      <ContextMenu id={id}>
        <MenuItem data={{ type: 'delete' }} onClick={onClick}>
          <div>
            <strong>
              <i className="fa fa-remove" />
            </strong>
            Delete
          </div>
        </MenuItem>
        {menuItems}
      </ContextMenu>
    </div>
  );
};

const LinkValue = ChangeDetection(({ ...props }) => <a {...props} />);

class ContactValue extends PureComponent {
  render() {
    return ContactValueRender(this.props);
  }
}

const ContactValueRender = props => {
  const {
    companyId,
    contactId,
    contactValue,
    companyName,
    onChange,
    onClick,
    onOpenEmail,
    isEditing,
    isInherited,
    isEmail,
    executiveInfo,
    emailTemplates,
    fetchEmailTemplates,
    findDirectors,
    name,
    autoFocus,
    phoneType,
    ...rest
  } = props;

  const { value = '' } = rest;

  if (isInherited) {
    return (
      <LinkValue checkOn="children" href={`/company/${companyId}`} target="_blank" title={companyName}>
        {value}
      </LinkValue>
    );
  }

  let result = (
    <Span checkOn="children" data-clipboard-action="copy" name={name} minSizes>
      {value}
    </Span>
  );

  if (isEditing) {
    result = (
      <Input
        autoFocus={autoFocus}
        className="form-control input-sm inner-table-input"
        name={name}
        onChange={onChange}
        phoneType={phoneType}
        placeholder=""
        value={value}
      />
    );
  }

  return (
    <ContextMenuWrapper
      component={result}
      contactId={contactId}
      contactValue={unwrap(contactValue)}
      emailTemplates={emailTemplates}
      executiveInfo={executiveInfo}
      fetchEmailTemplates={fetchEmailTemplates}
      findDirectors={findDirectors}
      isEmail={isEmail}
      onClick={onClick}
      onOpenEmail={onOpenEmail}
      value={unwrap(value)}
    />
  );
};

class ContactRow extends PureComponent {
  render() {
    return ContactRowRender(this.props);
  }
}

/**
 * Stateless component for contact table row.
 *
 * @link http://screencloud.net/v/rY4W in component
 * @param props {Object}.
 * @param props.contact {Immutable.Map} Contact's info.
 * Need *value* ket at this moment.
 * @private
 * @returns {React.Component}
 */
const ContactRowRender = props => {
  const {
    phoneType,
    name,
    isEmail,
    contact,
    onChange,
    onDel,
    onOpenEmail,
    executiveInfo,
    emailTemplates,
    fetchEmailTemplates,
    findDirectors,
    ...rest
  } = props;
  const isEditing = contact.has('isEditing');
  const isInherited = contact.get('isInherited');
  const companyId = contact.get('companyId');
  const companyName = contact.get('companyName');

  const value = contact.get('value');
  const isVerify = contact.get('isVerify');
  const type = contact.get('type');
  const contactId = contact.get('id');
  let extraColumn = null;
  let verifiedColumn = null;
  const extra = {
    ...rest,
  };

  if (isEmail) {
    extraColumn = <td className="clickable" style={{ width: '1em' }} />;
    verifiedColumn = (
      <td>
        <i className={classNames('fa', { 'text-success fa-check': isVerify, 'text-danger fa-times': !isVerify })} />
      </td>
    );
  }

  return (
    <tr {...extra}>
      <td className={classNames({ clickable: !isEditing })}>
        <ContactValue
          companyId={companyId}
          companyName={companyName}
          contactId={contactId}
          contactValue={value}
          emailTemplates={emailTemplates}
          executiveInfo={executiveInfo}
          fetchEmailTemplates={fetchEmailTemplates}
          findDirectors={findDirectors}
          isEditing={isEditing}
          isEmail={isEmail}
          isInherited={isInherited}
          name={`${name}.value`}
          onChange={onChange}
          onClick={onDel}
          onOpenEmail={onOpenEmail}
          phoneType={phoneType}
          value={value}
          autoFocus
        />
      </td>
      {verifiedColumn}
      <td className={classNames({ clickable: !isEditing })}>
        <ContactValue
          companyId={companyId}
          companyName={companyName}
          contactId={contactId}
          contactValue={value}
          emailTemplates={emailTemplates}
          executiveInfo={executiveInfo}
          fetchEmailTemplates={fetchEmailTemplates}
          findDirectors={findDirectors}
          isEditing={isEditing}
          isEmail={isEmail}
          isInherited={isInherited}
          name={`${name}.type`}
          onChange={onChange}
          onClick={onDel}
          onOpenEmail={onOpenEmail}
          phoneType={phoneType}
          value={type}
        />
      </td>
      {extraColumn}
    </tr>
  );
};

ContactRowRender.propTypes = {
  contact: PropTypes.instanceOf(Map).isRequired,
};

class ContactTypedInfoTable extends PureComponent {
  render() {
    return ContactTypedInfoTableRender(this.props);
  }
}

/**
 * Stateless component for contacts values of 1 type (phones/emails/addresses).
 *
 * @link http://screencloud.net/v/l6sv component usage example
 * @param props {Object}.
 * @param props.type {String} Contact's type name. Placed on top of table.
 * @param props.onAdd {Function} Callback. Called on click `plus` button.
 * @param props.contacts {Immutable.List} List of contact values' Immutable.Map.
 * @returns {React.Component}
 */
const ContactTypedInfoTableRender = props => {
  const {
    type,
    onAdd,
    onClick,
    onDel,
    onChange,
    contacts,
    executiveContact,
    executiveInfo,
    emailTemplates,
    fetchEmailTemplates,
    findDirectors,
    user,
  } = props;
  const isEmail = type === 'Emails';

  let rows = (
    <tr>
      <td colSpan={isEmail ? 4 : 2}> No rows to display. </td>
    </tr>
  );

  /**
   * Get object with email's merged fields.
   *
   * @return {object} Merged fields.
   */
  const getEmailMergedFields = () => configureExecEmailMergedFields(executiveContact, user);

  /**
   * Parse email fields and open mailto link.
   *
   * @param {string} email Recipient email.
   * @param {Map} template Email template.
   */
  const handleOpenEmail = (email, template) => {
    const mergedFields = getEmailMergedFields();
    const mailLink = getContactMailLink(email, template, mergedFields, user, executiveContact);

    window.open(mailLink, '_blank');
  };

  if (contacts.size) {
    rows = contacts.map((contact, i) => (
      <ContactRow
        key={i}
        contact={contact}
        emailTemplates={emailTemplates}
        executiveInfo={executiveInfo}
        fetchEmailTemplates={fetchEmailTemplates}
        findDirectors={findDirectors}
        isEmail={isEmail}
        name={`${type.toLowerCase()}.${i}`}
        onChange={onChange}
        onClick={event => onClick(event, i)}
        onDel={(event, data) => onDel(event, i, data)}
        onOpenEmail={handleOpenEmail}
        phoneType={type === 'Phones'}
      />
    ));
  }

  return (
    <table className="table table-bordered table-hover mb0">
      <thead>
        <tr>
          <th className="plus-icon">
            <i aria-hidden="true" className="fa fa-plus fa-2x" onClick={onAdd} />
            <span>{type}</span>
          </th>
          <th />
          {isEmail ? (
            <React.Fragment>
              <th />
              <th />
            </React.Fragment>
          ) : null}
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

ContactTypedInfoTableRender.propTypes = {
  contacts: PropTypes.instanceOf(List).isRequired,
  emailTemplates: PropTypes.instanceOf(List).isRequired,
  executiveContact: PropTypes.instanceOf(Map).isRequired,
  executiveInfo: PropTypes.instanceOf(Map).isRequired,
  fetchEmailTemplates: PropTypes.func.isRequired,
  findDirectors: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onDel: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
};

const mapStateToProps = state => ({
  executiveInfo: state.contact.executive,
  executiveContact: state.contact.info,
  user: state.auth.get('user'),
  emailTemplates: state.emailTemplates.get('executivesData', emptyList),
});

const mapActionToProps = {
  fetchEmailTemplates: fetchExecutivesEmailTemplates,
  findDirectors,
};

export default connect(mapStateToProps, mapActionToProps)(React.memo(ContactTypedInfoTable));
