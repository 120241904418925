import React from 'react';
import Popup from '../helpers/Popup';
import Button from '../helpers/Button';

const ConfirmPopup = props => {
  const {
    onOk,
    onCancel,
    header = 'Confirm Action',
    waiting = false,
    message = '',
    submessage = '',
    yes = 'OK',
    no = 'Cancel',
  } = props;
  const spinner = waiting ? <i className="fa fa-spinner fa-pulse fa-fw button" /> : null;
  const footer = [
    <Button key="ok" className="btn-primary btn-xs" data-dismiss="modal" disabled={waiting} onClick={onOk} autoFocus>
      {spinner} {yes}
    </Button>,
    <Button key="close" className="btn-default btn-xs" data-dismiss="modal" onClick={onCancel}>
      {no}
    </Button>,
  ];

  return (
    <Popup footer={footer} header={header} id="LPRole">
      {message}
      <p className="mb0 font-size-11">{submessage}</p>
    </Popup>
  );
};

export default ConfirmPopup;
