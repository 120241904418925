import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { isEmpty } from 'underscore';

import connectOptions, { mergeProps } from '../../utils/connectOptions';
import Popups from '../../components/Popup';
import { unwrap } from '../../utils/ChangeSpy';

import {
  changeContactField,
  saveContactField,
  revertContactField,
  closeValidationError,
} from '../../actions/contactDetail';
import checkZip from '../services/zipRules';

function validate(values, { zip: zipBlurred = false }) {
  const { zip, showCountry } = values;
  const errors = {};

  if (zipBlurred && !showCountry && !checkZip(zip)) {
    errors.zip =
      'Zip code should follow U.S. (XXXXX or XXXXX-XXXX) or Canadian (A1A 1A1) format; otherwise, please set address to International ("...")';
  }

  return errors;
}

class EditAddressPopup extends Component {
  constructor(props, context) {
    super(props, context);

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onErrorClose = this.onErrorClose.bind(this);
    this.triggerShowCountry = this.triggerShowCountry.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.state = { errors: {} };
  }

  validateForm(blurred = {}) {
    const address = this.props.contactAddress;
    const zip = unwrap(address.get('zip'));
    const showCountry = unwrap(address.get('showCountry'));
    const errors = validate({ zip, showCountry }, blurred);

    this.setState({ errors });

    return errors;
  }

  getChildContext() {
    return {
      closePopup: this.onClose,
      onErrorClose: this.onErrorClose,
      inputErrors: Map(this.state.errors),
    };
  }

  onErrorClose(e, field) {
    this.setState({ errors: { [field]: '' } });
  }

  onChange(event) {
    const {
      target: { name, value },
    } = event;
    const { addressIndex: index } = this.props;
    const field = 'addresses';

    this.props.changeContactField({ field, name, value, index });
  }

  onSave() {
    const errors = this.validateForm({ zip: true });

    if (isEmpty(errors)) {
      this.props.saveContactField();
      this.context.closePopup();
    }
  }

  onClose() {
    this.context.closePopup();
  }

  onBlur({ target: { name } }) {
    this.validateForm({ [name]: true });
  }

  triggerShowCountry() {
    const name = 'showCountry';
    const value = !this.props.contactAddress.getIn(['showCountry', 'value'], false);
    const { addressIndex: index } = this.props;
    const field = 'addresses';

    this.props.changeContactField({ field, name, value, index });
  }

  render() {
    const { children, states, contactAddress } = this.props;

    return (
      <div>
        <Popups.EditAddressPopup
          address={contactAddress}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onClose={this.onClose}
          onSave={this.onSave}
          states={states}
          triggerShowCountry={this.triggerShowCountry}
        />
        {children}
      </div>
    );
  }
}

EditAddressPopup.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
};

EditAddressPopup.childContextTypes = {
  closePopup: PropTypes.func.isRequired,
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};

function mapStateToProps(state, props) {
  return {
    ...props,
    addressIndex: props.popup.getIn(['props', 'index']),
    states: state.contact.info.get('states'),
    contactAddress: state.contact.info.getIn(state.contact.info.get('editing')),
  };
}

export { EditAddressPopup };
export default connect(
  mapStateToProps,
  {
    changeContactField,
    saveContactField,
    revertContactField,
    closeValidationError,
  },
  mergeProps,
  connectOptions,
)(EditAddressPopup);
