import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';

import _Table from '../../../../helpers/AgGridTable';
import { unwrap } from '../../../../../utils/ChangeSpy';
import PrettyError from '../../../../decorators/PrettyError';
import DisplayError from '../../../../decorators/DisplayError';
import AgHeader from './AgHeader';
import { BUSINESS_MODELS, INDUSTRY_CATEGORIES } from '../../../../../utils/industryTagsHelper';

const Table = PrettyError(DisplayError(_Table));

/**
 * Table industries.
 *
 * @class
 */
export default class Industries extends PureComponent {
  constructor(props) {
    super(props);

    this.onGridReady = this.onGridReady.bind(this);
    this.columnDefs = fromJS([
      {
        headerName: 'Industries',
        field: 'category',
        width: 150,
        headerComponentFramework: AgHeader,
        onClick: props.onUpdateTags,
      },
    ]);
  }

  onGridReady(params) {
    this.api = params.api;
    this.api.sizeColumnsToFit();
    if (this.props.onGridReady) {
      this.props.onGridReady(params);
    }
  }

  getRowNodeId(data) {
    return data.id;
  }

  render() {
    const { isFetching, tags, onErrorClose } = this.props;
    const businessModels = unwrap(tags.get(BUSINESS_MODELS));
    const industryCategories = unwrap(tags.get(INDUSTRY_CATEGORIES));
    const data = fromJS([...businessModels, ...industryCategories]);

    return (
      <div className="col-md-2 col-industries full-height">
        <div className="table-edit full-height">
          <Table
            columnDefs={this.columnDefs}
            error={tags.get('validationErrors')}
            getRowNodeId={this.getRowNodeId}
            isFetching={isFetching}
            name="tags"
            onErrorClose={onErrorClose}
            onGridReady={this.onGridReady}
            rowData={data}
            style={{ height: '177px' }}
            canDisplayError
            sortable
          />
        </div>
      </div>
    );
  }
}
