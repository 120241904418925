/**
 * Calculate cell's height by the number of characters
 * Assumption: a letter has 6px length.
 *
 * @param {string} text text that will be displayed in cell
 * @param {*} width cell's width
 */
export default function(text = '', width) {
  const len = text.length === 0 ? 1 : text.length;

  return Math.ceil((len * 6) / width) * 20; // 20 is a line's height in pixel
}
