import PropTypes from 'prop-types';

import React from 'react';
import { Map } from 'immutable';

const emptyMap = Map();

function shallowDiff(obj1, obj2) {
  if (obj1 === obj2) return false;
  try {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return true;

    return keys1.some(key => obj1[key] !== obj2[key]);
  } catch (e) {
    return true;
  }
}

export default function PrettyErrorDecorator(Component) {
  const displayName = `PrettyError(${Component.displayName || Component.name})`;

  /**
   * PrettyError decorator.
   *
   * @param props Any extra props will passed into children.
   * @param [props.error=''] {null|bool|string|React.Component}.
   * @param props.onErrorClose {Function}.
   * @param context {Object} React component's context.
   * @class
   */
  class PrettyError extends React.Component {
    constructor(...props) {
      super(...props);
      this.onBlur = this.onBlur.bind(this);
      this.onFocus = this.onFocus.bind(this);
      this.state = {
        canDisplayError: this.props.canDisplayError,
      };
    }

    shouldComponentUpdate(props, state, context) {
      return shallowDiff(this.props, props) || shallowDiff(this.state, state) || shallowDiff(this.context, context);
    }

    componentDidUpdate() {
      if (this.state.canDisplayError === false) {
        if (this.context.inputErrors) {
          if (this.context.inputErrors.get('errorsFromApi')) {
            this.setState({ canDisplayError: true });
          }
        }
      }
    }

    onBlur(event) {
      this.setState({ canDisplayError: true });
      if (this.props.onBlur) {
        this.props.onBlur(event);
      }
    }

    onFocus(...props) {
      const showErrorOnFocus = this.props.showErrorOnFocus || false;
      const onErrorClose = this.context.onErrorClose || this.props.onErrorClose;

      if (!showErrorOnFocus) {
        setImmediate(() => {
          if (typeof onErrorClose === 'function') onErrorClose(props[0], 'errorsFromApi');
          this.setState({ canDisplayError: false });
        });
      }

      if (this.props.onFocus) {
        this.props.onFocus(...props);
      }
    }

    render() {
      const { onErrorClose: contextOnErrorClose, inputErrors = emptyMap } = this.context;

      const {
        name,
        error = inputErrors.get(name, ''),
        onErrorClose = contextOnErrorClose,
        showErrorOnFocus,
        ...props
      } = this.props;

      if (this.state.canDisplayError === false) {
        return <Component name={name} {...props} error={null} onBlur={this.onBlur} onFocus={this.onFocus} />;
      }

      if (!error) {
        return <Component name={name} {...props} error={null} onBlur={this.onBlur} onFocus={this.onFocus} />;
      }

      if (process.env.NODE_ENV !== 'production' && !onErrorClose) {
        console.error(`${displayName} has error but no onErrorClose callback provided`);
      }

      let errorContent;

      if (error === true) {
        errorContent = <ErrorContent error="Some error happened" name={name} onErrorClose={onErrorClose} />;
      } else {
        errorContent = <ErrorContent error={error} name={name} onErrorClose={onErrorClose} />;
      }

      return <Component name={name} {...props} error={errorContent} onBlur={this.onBlur} onFocus={this.onFocus} />;
    }
  }

  PrettyError.propTypes = {
    error: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    name: PropTypes.string,
    onErrorClose: PropTypes.func,
  };

  PrettyError.defaultProps = {
    canDisplayError: false, // set to true if you want to show error message immediately
  };

  PrettyError.contextTypes = {
    onErrorClose: PropTypes.func,
    inputErrors: PropTypes.instanceOf(Map),
  };

  PrettyError.displayName = displayName;

  return PrettyError;
}

const ErrorContent = ({ error, name, onErrorClose }) => (
  <div className="alert alert-danger alert-dismissible input-custom-alert" role="alert">
    <button aria-label="Close" className="close" onClick={event => onErrorClose(event, name)} type="button">
      <span aria-hidden="true">&times;</span>
    </button>
    {error}
  </div>
);
