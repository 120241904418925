import moment from 'moment';

export function getAge(birthYear = '') {
  if (birthYear === '') {
    return '';
  }

  return String(moment().diff(moment(birthYear, 'YYYY'), 'years'));
}

export function getBirthYear(age = '') {
  if (age === '') {
    return null;
  }

  return String(
    moment()
      .subtract(age, 'years')
      .format('YYYY'),
  );
}
