import PropTypes from 'prop-types';

import React, { useCallback, useEffect } from 'react';
import { Map, List } from 'immutable';
import AgGridTable from '../../helpers/AgGridTable';
import { handleSortModelChanged } from '../../../utils/sorting';
import { noop } from '../../../utils/noop';

const CompanyTradeGroupsTable = props => {
  let gridApi = null;
  let lastColumnDefs = null;
  let columnDefs = null;

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  });

  const getColumnDefs = () => {
    if (props.columnDefs !== lastColumnDefs) {
      lastColumnDefs = props.columnDefs;
      columnDefs = props.columnDefs.map(columnDef => {
        const field = columnDef.get('field');

        switch (field) {
          default:
            return columnDef;
        }
      });
    }

    return columnDefs;
  };

  const onSort = sortModel => {
    handleSortModelChanged(sortModel, props.data, props.onChange);
  };

  const onGridReady = useCallback(params => {
    gridApi = params.api;
    gridApi.sizeColumnsToFit();
  }, []);

  function getRowNodeId(data) {
    return data.id;
  }

  return (
    <div className="trade-groups-table">
      <AgGridTable
        columnDefs={getColumnDefs()}
        getRowNodeId={getRowNodeId}
        isFetching={props.data.get('isFetching')}
        onGetNextPageData={noop}
        onGridReady={onGridReady}
        onRowDoubleClicked={props.onDoubleClickRow}
        onSortModelChanged={onSort}
        page={props.data.get('page')}
        rowData={props.data.get('queryResults')}
        totalPages={props.data.get('totalPages')}
        sortable
      />
    </div>
  );
};

CompanyTradeGroupsTable.propTypes = {
  columnDefs: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
};

export default CompanyTradeGroupsTable;
