import * as React from 'react';

import GenerateOnlineApprovalListPopup from '../../components/Popup/GenerateOnlineApprovalListPopup';

class GenerateOnlineApprovalListPopupContainer extends React.PureComponent {
  state = {
    checkedBoxes: [],
  };

  handleGenerateOnlineApprovalList = () => {
    const generateOnlineApprovalList = this.props.popup.getIn(['props', 'callback']);

    generateOnlineApprovalList(this.state.checkedBoxes);
  };

  handleChange = event => {
    const { name, checked } = event.target;

    if (checked) {
      this.setState(prevState => ({
        checkedBoxes: [...prevState.checkedBoxes, { [name]: checked }],
      }));
    } else {
      this.setState(prevState => ({
        checkedBoxes: prevState.checkedBoxes.filter(checkbox => Object.keys(checkbox)[0] !== name),
      }));
    }
  };

  render() {
    return (
      <GenerateOnlineApprovalListPopup
        disabled={this.state.checkedBoxes.length < 1}
        onChange={this.handleChange}
        onGenerateOnlineApprovalList={this.handleGenerateOnlineApprovalList}
      />
    );
  }
}

export default GenerateOnlineApprovalListPopupContainer;
