import { fromJS, List } from 'immutable';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import * as ActionType from '../actions/leadsReport';

const defaultState = fromJS({
  filters: {
    usersIds: [],
    modules: [],
  },
  // We have single (as sortable table columns) and multi available sort.
  sort: '',
  sorts: [],
  queryResults: [],
  page: 0,
  totalPages: 0,
  fetchingResults: false,
  fetchingAvailableFilters: false,
  fetchingStartSortSettings: false,
  exporting: false,
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case ActionType.FETCHING_FILTERS:
      return state.merge({
        fetchingAvailableFilters: true,
      });

    case ActionType.FETCHING_FILTERS_SUCCESS:
      return state.mergeDeep({
        fetchingAvailableFilters: false,
        filters: {
          modules: getModulesFromResponse(action),
        },
      });

    case ActionType.FETCHING_FILTERS_FAILURE:
      return state.merge({
        fetchingAvailableFilters: false,
      });

    case ActionType.FETCHING_SORT_SETTINGS:
      return state.merge({
        fetchingStartSortSettings: true,
      });

    case ActionType.FETCHING_SORT_SETTINGS_SUCCESS:
      return state.merge({
        fetchingStartSortSettings: false,
        sorts: fromJS(action.response.data),
      });

    case ActionType.FETCHING_SORT_SETTINGS_FAILURE:
      return state.merge({
        fetchingStartSortSettings: false,
      });

    case ActionType.FETCHING_LEADS:
      return state.merge({
        fetchingResults: true,
        page: action.query.page,
      });

    case ActionType.FETCHING_LEADS_SUCCESS:
      if (action.response.meta.pagination.currentPage === 1) {
        state = state.set('queryResults', new List());
      }

      return state.merge({
        fetchingResults: false,
        queryResults: state.get('queryResults').concat(
          action.response.data.map(item =>
            fromJS({
              ...item,
              conflictCheck: getDate(item.conflictCheck),
              sentLead: getDate(item.sentLead),
              conferenceCall: getDate(item.conferenceCall),
              visit: getDate(item.visit),
              offer: getDate(item.offer),
              loi: getDate(item.loi),
              dealClosed: getDate(item.dealClosed),
              tail: getDate(item.tail),
              buyerPassed: getDate(item.buyerPassed),
            }),
          ),
        ),
        totalPages: action.response.meta.pagination.totalPages,
      });

    case ActionType.FETCHING_LEADS_FAILURE:
      return state.merge({
        fetchingResults: false,
      });

    case ActionType.CHANGING_FILTERS:
      return state.mergeDeep({
        filters: {
          modules: updateModules(state, action),
        },
      });

    case ActionType.CHANGING_SORTING_FIELDS:
      return state.merge({
        sorts: updateSortingFields(state, action),
      });

    case ActionType.CHANGING_SORTING_ORDER:
      return updateSortingOrder(state, action);

    case ActionType.GENERATE_REPORT:
      return state.merge({
        filters: updateFilters(state),
      });

    case ActionType.UPDATE_SORT:
      return state.merge({
        sort: action.sort,
      });

    default:
      return state;
  }
}

/**
 * Get a list of possible modules with users.
 *
 * @param {object} action Action.
 */
function getModulesFromResponse(action) {
  const modules = action.response.data
    .filter(item => item.isModule)
    .map(module => {
      const moduleUser = {
        userId: module.id,
        userName: module.userName,
        isChecked: false,
      };

      let users;

      if (module.users === null) {
        users = [moduleUser];
      } else {
        const moduleUsers = module.users.map(user => ({
          userId: user.id,
          userName: user.userName,
          isChecked: false,
        }));

        users = sortBy([moduleUser].concat(moduleUsers), [
          function(user) {
            return user.userName.toLowerCase();
          },
        ]);
      }

      return {
        moduleId: module.id,
        moduleName: module.userName,
        users,
        isChecked: false,
      };
    });

  return sortBy(modules, [
    function(module) {
      return module.moduleName.toLowerCase();
    },
  ]);
}

/**
 * Update modules after filter change.
 *
 * @param {object} state State.
 * @param {object} action Updated field.
 */
function updateModules(state, action) {
  const modules = state.getIn(['filters', 'modules']);

  if (action.field.name === 'module') {
    return modules.setIn([action.moduleIndex, 'isChecked'], action.field.checked);
  }

  return modules.setIn([action.moduleIndex, 'users', action.userIndex, 'isChecked'], action.field.checked);
}

/**
 * Update users IDs list after filter change.
 *
 * @param {object} state State.
 */
function updateFilters(state) {
  const filters = state.get('filters');
  const modules = filters.get('modules');

  const usersIds = modules.reduce((acc, module) => {
    if (module.get('isChecked')) {
      const idsList = module.get('users').map(user => user.get('userId'));
      const checkedIdsList = module
        .get('users')
        .filter(user => user.get('isChecked'))
        .map(user => user.get('userId'));

      return acc.concat(checkedIdsList.size > 0 ? checkedIdsList : idsList);
    }

    return acc;
  }, List());

  return filters.set('usersIds', usersIds);
}

/**
 * Get date at correct format.
 *
 * @param {string} date Date at string format.
 */
const getDate = date => {
  const formattedDate = moment(date);

  return formattedDate.isValid() ? formattedDate.format('L') : '';
};

/**
 * Update sorts after sorting fields change.
 *
 * @param {object} state State.
 * @param {object} action Action.
 */
function updateSortingFields(state, { field, direction, included, order }) {
  const sorts = state.get('sorts');
  const index = sorts.findIndex(item => item.get('name') === field);

  if (index > -1) {
    const sort = sorts.get(index).merge({
      sd: direction,
      include: included,
      order,
    });

    return sorts.set(index, sort);
  }

  return sorts;
}

/**
 * Update sorts after sorting order change.
 *
 * @param {object} state State.
 * @param {object} action Action.
 */
const updateSortingOrder = (state, { orderedList }) => {
  const sorts = state.get('sorts');

  return state.set(
    'sorts',
    sorts.map(item => {
      if (orderedList[item.get('name')] !== undefined) {
        return item.set('order', orderedList[item.get('name')]);
      }

      return item;
    }),
  );
};
