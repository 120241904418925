import React from 'react';
import PropTypes from 'prop-types';

import EmptyRow from '../EmptyRow';

const OneRowTable = props => {
  const { rowData, companies, yearFilter } = props;

  return (
    <>
      <div className="year-filter-block">{yearFilter}</div>
      <table className="table table-bordered one-row-table">
        <thead>
          <tr>
            <th># Targets</th>
            <th>{`<= $10`}</th>
            <th>$11 - $25</th>
            <th>$26 - $50</th>
            <th>$51 - $100</th>
            <th>{`>100`}</th>
            <th>Private</th>
            <th>{`Status>4.6`}</th>
          </tr>
        </thead>
        <tbody>
          {companies.length ? (
            <tr>
              <td>{rowData.targets}</td>
              <td>{rowData.lt10}</td>
              <td>{rowData.in1125}</td>
              <td>{rowData.in2650}</td>
              <td>{rowData.in51100}</td>
              <td>{rowData.gt100}</td>
              <td>{rowData.purPri}</td>
              <td>{rowData.statusGt46}</td>
            </tr>
          ) : (
            <tr>
              <EmptyRow colSpan={8} text="No Data To Display" />
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

OneRowTable.propTypes = {
  companies: PropTypes.instanceOf(Array).isRequired,
  rowData: PropTypes.instanceOf(Object).isRequired,
  yearFilter: PropTypes.string,
};

export default OneRowTable;
