import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { toJS } from 'src/utils/ChangeSpy';
import Form from './Form';
import Buttons from './Buttons';

/**
 * Top section component.
 *
 * @param {object} props
 * @param {Immutable.Map} props.common General information.
 * @param {boolean} props.disableSetNA If set to true, will disable set next action button.
 * @param {boolean} props.disableChangeStatus If set to true, will disable change status popup.
 * @param props.runWindwardReport {Function} Open Windward modal window.
 * @param {Function} props.onChange Handle date change.
 * @param {Function} props.onChangeStatus To open change status popup.
 * @param {Function} props.onExport To export associate list.
 * @param {boolean} props.isNoApproval No approval list selected state.
 * @returns {React.Component}
 */
const FootSection = props => {
  const { common, onChange, onSaveDates, isNoApproval, ...rest } = props;

  const isGenerateButtonDisabled = toJS(common.getIn(['approval', 'dateReturned'])) !== null;

  return (
    <div className="approval__footer">
      <Form common={common} isNoApproval={isNoApproval} onChange={onChange} onSubmit={onSaveDates} />
      <Buttons isGenerateButtonDisabled={isGenerateButtonDisabled} isNoApproval={isNoApproval} {...rest} />
    </div>
  );
};

FootSection.propTypes = {
  common: PropTypes.instanceOf(Map).isRequired,
  disableChangeStatus: PropTypes.bool.isRequired,
  disableSetNA: PropTypes.bool.isRequired,
  generateOnlineApprovalList: PropTypes.func.isRequired,
  isNoApproval: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
};

export default FootSection;
