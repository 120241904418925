import React from 'react';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Input from '../helpers/Input';
import Button from '../helpers/Button';

const NewPlatformProjectPopup = () => {
  const header = 'Create a new platform project';
  const footer = [
    <Button key="create" className="btn btn-primary btn-xs" data-dismiss="modal" type="button">
      Create
    </Button>,
    <Button key="close" className="btn btn-default btn-xs" data-dismiss="modal" type="button">
      Close
    </Button>,
  ];

  return (
    <Popup footer={footer} header={header} id="newPlatformProject">
      <div className="row media-450">
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="k-category"
            label="Category"
            name="category"
            placeholder="Category"
            autoFocus
            required
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="k-harvco-lead"
            label="Harvco Lead"
            name="harvco-lead"
            placeholder="Harvco Lead"
            required
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row media-450">
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="k-client-lead"
            label="Harvco Contact"
            name="client-lead"
            placeholder="Harvco Contact"
            required
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="k-executive"
            label="Harvco Executive"
            name="executive"
            placeholder="Harvco Executive"
            required
            showLabel
          />
        </FormGroup>
      </div>
    </Popup>
  );
};

export default NewPlatformProjectPopup;
