import { CALL_API } from '../middleware/api';

export const FETCHING_USERS = Symbol('FETCHING_USERS');
export const FETCHED_USERS_FAILURE = Symbol('FETCHED_USERS_FAILURE');
export const FETCHED_USERS_SUCCESS = Symbol('FETCHED_USERS_SUCCESS');
export const TOGGLE_SHOWING_INACTIVE_USER = Symbol('TOGGLE_SHOWING_INACTIVE_USER');
export const CHANGE_MODE = Symbol('CHANGE_MODE');
export const UPDATE_SELECTED_USER = Symbol('UPDATE_SELECTED_USER');
export const CLEAR_SELECTED_USER = Symbol('CLEAR_SELECTED_USER');
export const FETCHED_ROLES_SUCCESS = Symbol('FETCHED_ROLES_SUCCESS');
export const SAVING_USER = Symbol('SAVING_USER');
export const SAVED_USER_FAILURE = Symbol('SAVED_USER_FAILURE');
export const SAVED_USER_SUCCESS = Symbol('SAVED_USER_SUCCESS');
export const UPDATE_MODULE_FIELD = Symbol('UPDATE_MODULE_FIELD');
export const UPDATE_SUPERVISOR_FIELD = Symbol('UPDATE_SUPERVISOR_FIELD');
export const SELECT_USER = Symbol('SELECT_USER');
export const UPDATE_LOGIN_ATTEMPTS = Symbol('UPDATE_LOGIN_ATTEMPTS');
export const CLOSE_SUGGESTION = Symbol('CLOSE_SUGGESTION');
export const FETCHED_REPORT_SUCCESS = Symbol('FETCHED_REPORT_SUCCESS');

/**
 * Request user list from server.
 *
 * @param id {Number} User's id.
 * @returns Action.
 */
export function loadReports() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/users/reports',
      successType: FETCHED_REPORT_SUCCESS,
    },
  };
}

/**
 * Request user list from server.
 *
 * @param id {Number} User's id.
 * @returns Action.
 */
export function loadUsers(id) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/users',
      startType: FETCHING_USERS,
      errorType: FETCHED_USERS_FAILURE,
      successType: FETCHED_USERS_SUCCESS,
      afterSuccess: ({ dispatch }) => {
        if (id > 0) {
          dispatch(selectUser(id));
        }
      },
    },
  };
}

/**
 * Create an action for updating supervisor field of selected user.
 *
 * @param moduleId {number} Module's id.
 * @param supervisor {string} Module's name.
 * @returns Action.
 */
export function updateSupervisor(supervisorId, supervisor) {
  return {
    type: UPDATE_SUPERVISOR_FIELD,
    supervisorId,
    supervisor,
  };
}

/**
 * Create an action for updating module field of selected user.
 *
 * @param moduleId {number} Module's id.
 * @param module {string} Module's name.
 * @returns Action.
 */
export function updateModule(moduleId, module) {
  return {
    type: UPDATE_MODULE_FIELD,
    moduleId,
    module,
  };
}

/**
 * Create an action for updating login attempts.
 *
 * @param checked {Boolean} Set to true will reset login attempts.
 * @returns Action.
 */
export function updateAttempts(checked) {
  return {
    type: UPDATE_LOGIN_ATTEMPTS,
    checked,
  };
}

/**
 * Create an action for calling api to update or insert selected user to database.
 *
 * @param {Immutable.Map} userData User data.
 * @returns Action.
 */
export function updateOrInsertUser(userData) {
  const { id } = userData;
  const isUpdateRequest = id > 0;

  return {
    [CALL_API]: {
      method: isUpdateRequest ? 'put' : 'post',
      path: isUpdateRequest ? `/api/v1/users/${id}` : '/api/v1/users',
      body: userData,
      startType: SAVING_USER,
      errorType: SAVED_USER_FAILURE,
      successType: SAVED_USER_SUCCESS,
      afterSuccess: ({ dispatch }) =>
        // Reload user list after saving
        dispatch(loadUsers()),
    },
  };
}

/**
 * Create an action for selecting a user.
 *
 * @param id {Number} User's id.
 * @returns Action.
 */
export function selectUser(id) {
  return {
    type: SELECT_USER,
    id: parseInt(id, 10),
  };
}

/**
 * Create an action for updating selected user in redux state.
 *
 * @param name {string} Name of selectedUser field.
 * @param value {any} Value of selectedUser field.
 * @returns Action.
 */
export function updateSelectedUser(name, value, checked) {
  return {
    type: UPDATE_SELECTED_USER,
    name,
    value,
    checked,
  };
}

/**
 * Create an action for deleting selected user in redux state.
 *
 * @param selectedUser {Immutable.Map} User data.
 * @returns Action.
 */
export function clearSelectedUser() {
  return {
    type: CLEAR_SELECTED_USER,
  };
}

/**
 * Remove Google 2FA.
 *
 * @param {number} userId Id of user.
 * @param afterSuccess {Function}.
 */
export function deleteGoogle2FAAuth({ userId, afterSuccess }) {
  return {
    [CALL_API]: {
      method: 'delete',
      path: `/api/v1/users/${userId}/google2fa`,
      afterSuccess,
    },
  };
}

/**
 * Create an action for changing form mode on the page (view|edit).
 *
 * @returns Action.
 */
export function changeMode(mode) {
  return {
    type: CHANGE_MODE,
    mode,
  };
}

/**
 * Create an action for hiding/showing all inactive users.
 *
 * @returns Action.
 */
export function toggleShowingInactiveUser() {
  return {
    type: TOGGLE_SHOWING_INACTIVE_USER,
  };
}

/**
 * Create an action for calling api to fetch system roles.
 *
 * @returns Action.
 */
export function loadRoles() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/users/roles',
      successType: FETCHED_ROLES_SUCCESS,
    },
  };
}

/**
 * Close suggestion.
 *
 * @returns Action.
 */
export function closeSuggestion() {
  return {
    type: CLOSE_SUGGESTION,
  };
}
