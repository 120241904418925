import prefixes from './prefixes.json';
import suffixes from './suffixes.json';
import executiveStatuses from './executiveStatuses.json';
import executiveInterests from './executiveInterests.json';
import executiveCheckboxes from './executiveCheckboxes.json';
import executiveQualities from './executiveQualities.json';
import executiveRelations from './executiveRelations.json';
import lpStatuses from './lpStatuses.json';

export default {
  prefixes,
  suffixes,
  executiveStatuses,
  executiveInterests,
  executiveCheckboxes,
  executiveRelations,
  executiveQualities,
  lpStatuses,
};
