import { Map } from 'immutable';

/**
 * Convert target status list to keypair value list (name|value).
 *
 * @param statuses {Immutable.List} Target statuses from configuration.
 * @returns {Immutable.List}
 */
export default function targetStatuses(statuses) {
  return statuses.map(ts =>
    Map({
      name: `${ts.get('fc')} - ${ts.get('d')}`,
      value: ts.get('fc'),
      text: ts.get('d'),
    }),
  );
}

/**
 * Get list of target status with blank default value.
 * Default value = { name: '', value: '', index: -1 }.
 *
 * @param {Immutable.List} statuses target statuses from configuration{*} statuses.
 */
export function targetStatusesWithDefault(statuses) {
  const tmp = statuses.map((ts, index) =>
    Map({
      index,
      name: `${ts.get('fc')} - ${ts.get('d')}`,
      value: ts.get('fc'),
    }),
  );

  return tmp.insert(0, Map({ name: '', value: '', index: -1 }));
}
