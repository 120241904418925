import React, { PureComponent } from 'react';

class RecipientsHeader extends PureComponent {
  getGui() {
    return this.header;
  }

  onClick = event => {
    const { api, column } = this.props;

    column.colDef.onClick(event, api);
  };

  render() {
    const {
      column: { colDef },
    } = this.props;

    return (
      <div
        ref={input => {
          this.header = input;
        }}
        className="ag-header-cell-label"
      >
        <button aria-label="add" className="ag-header-button" onClick={this.onClick} title="add" type="button">
          <i className="fa fa-plus mr5" />
        </button>
        <span className="mr5">{colDef.headerName}</span>
      </div>
    );
  }
}

export default RecipientsHeader;
