import { CALL_API } from '../../../middleware/api';

export const LOADED_COMPANY_CONTACT = Symbol('LOADED_COMPANY_CONTACT');
export const FETCH_SUBSIDIARIES_SUCCESS = Symbol('FETCH_SUBSIDIARIES_SUCCESS');

export const UPDATE_COMPANY = Symbol('UPDATE_COMPANY');
export const REVERT_UPDATE = Symbol('REVERT_UPDATE');
export const LOADED_USERS = Symbol('LOADED_USERS');
export const SAVED_COMPANY_BUYER = Symbol('SAVED_COMPANY_BUYER');
export const ERRORED_COMPANY_BUYER = Symbol('ERRORED_COMPANY_BUYER');
export const CLOSE_COMPANY_BUYER_VALIDATION_ERROR = Symbol('CLOSE_COMPANY_BUYER_VALIDATION_ERROR');

/**
 * Load users by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findUsers({ filter, page, afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
        limit: 100,
        page,
      },
      successType: LOADED_USERS,
      afterSuccess,
    },
  };
}

/**
 * Load users by filter and role.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param roles {Array}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findUsersByRole({ filter, page, roles = [], afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users_by_role',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
        role: roles.join(','),
        limit: 100,
        page,
      },
      successType: LOADED_USERS,
      afterSuccess,
    },
  };
}

export function handleUpdateCompany(info) {
  return {
    type: UPDATE_COMPANY,
    ...info,
  };
}

export function saveInfo({ companyId, body, afterSuccess }) {
  return {
    fields: Object.keys(body),
    [CALL_API]: {
      method: 'put',
      unifier: `buyer put /api/v1/companies/${companyId} {${JSON.stringify(body)}}`,
      path: `/api/v1/companies/${companyId}`,
      body,
      afterSuccess,
      successType: SAVED_COMPANY_BUYER,
      errorType: ERRORED_COMPANY_BUYER,
      skipGlobalErrorHandler: true,
    },
  };
}

/**
 * Saves buyer industry tags (industry categories, business models).
 *
 * @param companyId Id of the company.
 * @param data Data to send.
 * @param afterSuccess After success function.
 * @returns {MiddlewareApi.CallApi}
 */
export function saveBIC({ companyId, data, afterSuccess }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/companies/${companyId}/bulk/buyer_industry_categories`,
      body: { data },
      afterSuccess,
    },
  };
}

export function closeValidationError({ field }) {
  return {
    type: CLOSE_COMPANY_BUYER_VALIDATION_ERROR,
    field,
  };
}

export function revertUpdate({ field }) {
  return {
    type: REVERT_UPDATE,
    field,
  };
}
