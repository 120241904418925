import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { toJS } from 'src/utils/ChangeSpy';

const TransactionsTable = ({ data }) => {
  const allTransactions = toJS(data.get('allTransactions'));
  const addOnTransactions = toJS(data.get('addOnTransactions'));
  const platformTransactions = toJS(data.get('platformTransactions'));

  return (
    <div className="dashboard-table-wrapper mb20">
      <table className="dashboard-table table-striped-white dashboard-transactions">
        <thead>
          <tr className="bg-black text-white">
            <th className="text-center p12" colSpan="2">
              <p className="h3 text-bold">{allTransactions.countTransactions}</p>
              <p className="h5 text-bold">Closed Transactions</p>
            </th>
            <th className="text-center p12 border-left border-right" colSpan="2">
              <p className="h3 text-bold">{addOnTransactions.countTransactions}</p>
              <p className="h5 text-bold">Add-Ons</p>
            </th>
            <th className="text-center p12" colSpan="2">
              <p className="h3 text-bold">{platformTransactions.countTransactions}</p>
              <p className="h5 text-bold">Platforms</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="h5 p10">Total Transactions Size</td>
            <td className="h5 p10 text-bold">{allTransactions.totalTransactionSize}</td>
            <td className="h5 p10 border-left">Total Transactions Size</td>
            <td className="h5 p10 border-right text-bold">{addOnTransactions.totalTransactionSize}</td>
            <td className="h5 p10">Total Transactions Size</td>
            <td className="h5 p10 text-bold">{platformTransactions.totalTransactionSize}</td>
          </tr>
          <tr>
            <td className="h5 p10">Avg Transactions Size</td>
            <td className="h5 p10 text-bold">{allTransactions.avgTransactionSize}</td>
            <td className="h5 p10 border-left">Avg Transactions Size</td>
            <td className="h5 p10 border-right text-bold">{addOnTransactions.avgTransactionSize}</td>
            <td className="h5 p10">Avg Transactions Size</td>
            <td className="h5 p10 text-bold">{platformTransactions.avgTransactionSize}</td>
          </tr>
          <tr>
            <td className="h5 p10">Avg Multiple</td>
            <td className="h5 p10 text-bold">{allTransactions.avgMultiple}</td>
            <td className="h5 p10 border-left">Avg Multiple</td>
            <td className="h5 p10 border-right text-bold">{addOnTransactions.avgMultiple}</td>
            <td className="h5 p10">Avg Multiple</td>
            <td className="h5 p10 text-bold">{platformTransactions.avgMultiple}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

TransactionsTable.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
};

export default TransactionsTable;
