import React from 'react';

const BIC = ({ tags, models, onClick, className }) => {
  const rows = [];

  if (models.size) {
    models.forEach(t => {
      if (t.get('checked')) {
        rows.push(
          <tr key={`${t.get('id')}${t.get('category')}`}>
            <td>
              <span>{t.get('category')}</span>
            </td>
          </tr>,
        );
      }
    });
  }

  if (tags.size) {
    tags.forEach(t => {
      if (t.get('checked')) {
        rows.push(
          <tr key={t.get('id')}>
            <td>
              <span>{t.get('category')}</span>
            </td>
          </tr>,
        );
      }
      t.get('children').forEach(c => {
        if (c.get('checked')) {
          rows.push(
            <tr key={c.get('id')}>
              <td>
                <span>{c.get('category')}</span>
              </td>
            </tr>,
          );
        }
      });
    });
  }

  if (rows.length === 0) {
    rows.push(
      <tr key="norow">
        <td>No rows to display</td>
      </tr>,
    );
  }

  return (
    <table className={`table table table-bordered table-hover empty-table mt15 mb0 ${className}`}>
      <thead>
        <tr>
          <th className="plus-icon">
            <div>
              <span>
                <i className="fa fa-plus-square fa-2x" onClick={onClick} />
                <span>Industry Tags</span>
              </span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

export default BIC;
