import React, { PureComponent } from 'react';

import _Textarea from '../../helpers/Textarea';
import _Input from '../../helpers/Input';
import _Select from '../../helpers/Select';
import _Checkbox from '../../helpers/Checkbox';
import FormGroup from '../../helpers/FormGroup';
import PrettyError from '../../decorators/PrettyError';
import DisplayError from '../../decorators/DisplayError';

const Textarea = PrettyError(DisplayError(_Textarea));
const Input = PrettyError(DisplayError(_Input));
const Select = PrettyError(DisplayError(_Select));
const Checkbox = PrettyError(DisplayError(_Checkbox));

class ContactExecutiveInfo extends PureComponent {
  render() {
    return ContactExecutiveInfoRender(this.props);
  }
}

/**
 * Contact executive main info (top part tab).
 *
 * @param props {Object}.
 * @returns {XML}
 * @class
 */
const ContactExecutiveInfoRender = props => {
  const { onChange, contact, qualities, interests, relations, qualitiesOfExecutive } = props;

  const quality = contact.get('quality');
  const interest = contact.get('interest');
  const relationship = contact.get('relationship');
  const legacySource = contact.get('legacySource');
  const qualOfExecContent = qualitiesOfExecutive.map((qoe, i) => (
    <Checkbox
      key={i}
      checked={contact.get(qoe.get('field'))}
      id={qoe.get('id')}
      label={qoe.get('name')}
      name={qoe.get('field')}
      onChange={onChange}
      boldOnChecked
    />
  ));
  const nextStep = contact.get('legacyNextStep');

  return (
    <div className="highlight wrap-border-full mb10">
      <div className="row">
        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 sm-p0">
          <FormGroup>
            <Select
              className="input-sm"
              id="f-quality"
              label="Quality"
              name="quality"
              onChange={onChange}
              options={qualities}
              value={quality}
              showValue
            />
          </FormGroup>
          <FormGroup>
            <Select
              id="f-interest"
              label="Interest"
              name="interest"
              onChange={onChange}
              options={interests}
              selectClassName="input-sm"
              value={interest}
              showValue
            />
          </FormGroup>
          <FormGroup>
            <Select
              id="f-relation"
              label="Relation"
              name="relationship"
              onChange={onChange}
              options={relations}
              selectClassName="input-sm"
              value={relationship}
              showValue
            />
          </FormGroup>
          <FormGroup>
            <Input
              className="input-sm"
              id="f-source"
              label="Source"
              name="legacySource"
              onChange={onChange}
              placeholder="Source"
              value={legacySource}
            />
          </FormGroup>
        </div>
        <div className="col-xs-12 col-sm-4 col-md-2 col-lg-4 col-xl-3 col-xxl-2 pr1 sm-p0">
          <div className="group-checkbox">{qualOfExecContent}</div>
        </div>

        <div className="col-xs-12 col-sm-5 col-md-7 col-lg-5 col-xl-6 col-xxl-7 sm-p0">
          <FormGroup>
            <Textarea
              id="f-next-step"
              label="Next Step"
              name="legacyNextStep"
              onChange={onChange}
              placeholder="Next Step"
              rows="6"
              value={nextStep}
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

ContactExecutiveInfo.propTypes = {};

export default ContactExecutiveInfo;
