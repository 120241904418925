import React from 'react';
import PropTypes from 'prop-types';
import WebsiteField from './WebsiteField';

const TradeShowTextInput = props => {
  const { field, error = false, onTextChange, label, name } = props;
  const className =
    field.value !== field.origin
      ? `form-control dirty-field ${error ? 'required-error-block' : ''}`
      : `form-control ${error ? 'required-error-block' : ''}`;

  const input = () => {
    if (label === 'Description') {
      return (
        <textarea
          className={className}
          name={name}
          onChange={event => {
            onTextChange(event, name);
          }}
          placeholder={label}
          value={field.value}
        />
      );
    }

    if (label === 'Website') {
      return (
        <WebsiteField
          className={className}
          name={name}
          onTextChange={onTextChange}
          placeholder={label}
          value={field.value}
        />
      );
    }

    return (
      <input
        className={className}
        name={name}
        onChange={event => {
          onTextChange(event, name);
        }}
        placeholder={label}
        type="text"
        value={field.value}
      />
    );
  };

  return (
    <div className="trade-show-field-text">
      <label className="col-md-8 mb5" htmlFor={name}>
        {label}
      </label>
      {input()}
    </div>
  );
};

TradeShowTextInput.propTypes = {
  error: PropTypes.bool,
  field: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  onTextChange: PropTypes.func,
};

export default TradeShowTextInput;
