import React from 'react';
import { Map, List } from 'immutable';
import PropTypes from 'prop-types';

import AutoSuggestion from '../../../../helpers/AutoSuggestion';
import config from '../../../../../config';

/**
 * Project text input component with suggestion supported.
 *
 * @param props {Object}.
 * @param props.name {String} Input name.
 * @param props.value {String} Input value.
 * @param props.label {String} Input label.
 * @param props.row {Immutable.Map} Data for input element.
 * @param props.suggestions {Immutable.List} Suggestion List.
 * @param props.onTextChange {Function} Handle event of changing text.
 * @param props.onSuggestionClose {Function} Handle event of closing suggestion.
 * @param props.onSuggestionInputClick {Function} Handle event of clicking on input element.
 * @param props.onSuggestionSelect {Function} Handle event of selecting a suggestion.
 * @param {boolean} props.canEditData Whether user can edit any active element that can be changed or clicked.
 * @returns {React.Component}
 * @constructor
 */
const ProjectSuggestionInput = props => {
  const {
    canEditData,
    label,
    name,
    row,
    suggestions,
    onTextChange,
    onSuggestionClose,
    onSuggestionInputClick,
    onSuggestionSelect,
  } = props;
  const className = row.get('dirty') ? 'form-control changed' : 'form-control';
  const id = `project_input_${name}`;
  const type = config.values.getIn(['project', 'types', 'PROJECT']);

  return (
    <div className="form-group clearfix">
      <label className="col-md-4" htmlFor={id}>
        {label}
      </label>
      <div className="col-md-8">
        <AutoSuggestion
          className={className}
          data={{ type, row, name }}
          disabled={!canEditData}
          id={id}
          mode={row.get('mode')}
          name={name}
          onSuggestionClose={onSuggestionClose}
          onSuggestionInputClick={onSuggestionInputClick}
          onSuggestionSelect={onSuggestionSelect}
          onTextChange={onTextChange}
          suggestions={suggestions}
          value={row.get('name')}
        />
      </div>
    </div>
  );
};

ProjectSuggestionInput.propTypes = {
  canEditData: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onSuggestionClose: PropTypes.func.isRequired,
  onSuggestionInputClick: PropTypes.func.isRequired,
  onSuggestionSelect: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  row: PropTypes.instanceOf(Map).isRequired,
  suggestions: PropTypes.instanceOf(List).isRequired,
};

export default ProjectSuggestionInput;
