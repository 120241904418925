import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import { isEqual } from 'underscore';

import DropDown, { DropDownElement } from '../helpers/DropDown';

const Dropdown = ({ onDelete }) => (
  <div className="btn-group">
    <DropDown btnContent={<span />} className="btn-default btn-xs" id="main-stats-dropdown">
      <DropDownElement onClick={onDelete}>Delete</DropDownElement>
    </DropDown>
  </div>
);

export class S3FilesTree extends PureComponent {
  state = {
    searchString: '',
    searchFocusIndex: 0,
    searchFoundCount: null,
    treeData: [],
  };

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (!isEqual(prevProps.data, data)) {
      this.setState({ treeData: data });
    }
  }

  handleUpdateTreeData = treeData => {
    this.setState({ treeData });
  };

  handleConfirmDelete = node => {
    this.context.openPopup('ConfirmPopup', {
      header: 'Delete file',
      message: 'Are you sure you want to permanently delete this file?',
      yes: 'Confirm',
      no: 'Cancel',
      onOk: () => {
        this.props.onDelete(node);
        this.context.closePopup();
      },
      onCancel: () => this.context.closePopup(),
    });
  };

  handleUpdateSearch = event => {
    this.setState({ searchString: event.target.value });
  };

  handleSelectPrevMatch = () => {
    const { searchFocusIndex, searchFoundCount } = this.state;

    this.setState({
      searchFocusIndex:
        searchFocusIndex !== null ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount : searchFoundCount - 1,
    });
  };

  handleSelectNextMatch = () => {
    const { searchFocusIndex, searchFoundCount } = this.state;

    this.setState({
      searchFocusIndex: searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0,
    });
  };

  handleSearchCallback = matches => {
    const { searchFocusIndex } = this.state;

    this.setState({
      searchFoundCount: matches.length,
      searchFocusIndex: matches.length > 0 ? searchFocusIndex % matches.length : 0,
    });
  };

  handleSearch = ({ node, searchQuery }) =>
    searchQuery && node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;

  render() {
    const { treeData, searchString, searchFocusIndex, searchFoundCount } = this.state;

    return (
      <React.Fragment>
        <div className="s3-search-group input-group input-group-sm">
          <span className="input-group-addon">
            {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}/{searchFoundCount || 0}
          </span>

          <input
            className="s3-search form-control"
            onChange={event => this.handleUpdateSearch(event)}
            placeholder="Search"
            type="search"
            value={searchString}
          />

          <span className="input-group-btn">
            <button
              className="btn btn-default"
              disabled={!searchFoundCount}
              onClick={this.handleSelectPrevMatch}
              type="button"
            >
              <i className="fa fa-angle-left mr0" />
            </button>
            <button
              className="btn btn-default"
              disabled={!searchFoundCount}
              onClick={this.handleSelectNextMatch}
              type="button"
            >
              <i className="fa fa-angle-right mr0" />
            </button>
          </span>
        </div>

        <SortableTree
          canDrag={false}
          generateNodeProps={rowInfo => ({
            icons: rowInfo.node.isDirectory
              ? [<i key="folderIcon" className="fa fa-folder s3-folder" />]
              : [<i key="fileIcon" className="fa fa-file" />],
            title: rowInfo.node.isDirectory
              ? [<span key="folder">{rowInfo.node.title}</span>]
              : [
                  <a
                    key="file"
                    className="s3-filename"
                    href={rowInfo.node.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {rowInfo.node.title}
                  </a>,
                ],
            buttons:
              rowInfo.node.isDirectory || this.props.readOnly
                ? []
                : [<Dropdown key="dropdown" onDelete={() => this.handleConfirmDelete(rowInfo.node)} />],
          })}
          onChange={this.handleUpdateTreeData}
          searchFinishCallback={matches => this.handleSearchCallback(matches)}
          searchFocusOffset={searchFocusIndex}
          searchMethod={this.handleSearch}
          searchQuery={searchString}
          theme={FileExplorerTheme}
          treeData={treeData}
        />
      </React.Fragment>
    );
  }
}

S3FilesTree.propTypes = {
  data: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

S3FilesTree.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

export default S3FilesTree;
