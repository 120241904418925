export default function(detailName) {
  const skip = () => true;

  const preCheck = {
    targetMarkets(body) {
      return !body.some(m => {
        const percent = m.percent || '0';

        return !m.market || isNaN(percent);
      });
    },
    targetProducts(body) {
      return !body.some(p => !p.product || isNaN(p.percent || '0'));
    },
    targetCustomers(body) {
      return !body.some(c => !c.customer || isNaN(c.percent || '0'));
    },
    targetFacilities(body) {
      return !body.some(c => !c.facility);
    },
    targetCompetitors(body) {
      return !body.some(c => !c.competitor);
    },
    customFields(body) {
      return !body.some(f => !f.fieldName);
    },
    harvcoTags(body) {
      return !body.some(f => !f.tag);
    },
  };

  return preCheck[detailName] || skip;
}
