import React from 'react';
import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import AutoComplete from '../helpers/AutoComplete';

const renderSuggestion = suggestion => <span>{suggestion.text}</span>;

const LPRolePopup = props => {
  const { contact, onClose, onCreate, lp, suggestRelation } = props;
  const name = contact.getIn(['fullName', 'value']);
  const header = `Activate the "LP" role for ${name}, marking them as an approachable LP prospect?`;
  const footer = [
    <Button key="create" className="btn-primary btn-xs" data-dismiss="modal" onClick={onCreate}>
      Create
    </Button>,
    <Button key="close" className="btn-default btn-xs" data-dismiss="modal" onClick={onClose}>
      Close
    </Button>,
  ];

  const suggestions = lp.get('suggests');

  return (
    <Popup footer={footer} header={header} id="LPRole">
      <div className="row">
        <AutoComplete
          checkOn="text"
          formGroupClass="col-lg-6"
          getSuggestion={suggestRelation.onFetch}
          getSuggestionValue={suggestRelation.getValue}
          inputProps={{
            autoFocus: true,
            id: '2siggstimeewexecitoverolepopiup', // TODO remove this after autosuggest will not require id
            className: 'form-control input-sm',
            label: 'Harvco Contact',
            name: 'suggestRelation',
          }}
          onSuggestionSelected={suggestRelation.onSelect}
          onUpdateSuggestions={suggestRelation.onUpdate}
          renderSuggestion={renderSuggestion}
          suggestions={suggestions}
          text={suggestRelation.value}
        />
      </div>
    </Popup>
  );
};

export default LPRolePopup;
