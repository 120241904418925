import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';

import { InlineInput } from '../../helpers/Input';
import FormGroup from '../../helpers/FormGroup';
import FormSubmitOnBlur from '../../helpers/FormSubmitOnBlur';
import { toJS } from '../../../utils/ChangeSpy';
import LinksTable from './LinksTable';

/**
 * Top section component.
 *
 * @param props {Object}.
 * @param props.common {Immutable.Map} General information.
 * @param props.onChange {Function} Handle date change.
 * @param {boolean} props.isNoApproval No approval list selected state.
 * @returns {React.Component}
 * @class
 */
const Form = props => {
  const { common, isNoApproval, onChange, onSubmit } = props;
  const ap = common.get('approval');
  const noAp = common.get('noApproval');
  const linksList = toJS(common.get('linksList'));
  const dateReturned = ap.get('dateReturned');
  const dateSent = ap.get('dateSent');
  const numberTargets = isNoApproval ? noAp.get('numberTargets') : ap.get('numberTargets');
  const numberApproved = isNoApproval ? noAp.get('numberApproved') : ap.get('numberApproved');
  const percentApproved = isNoApproval ? noAp.get('percentApproved') : ap.get('percentApproved');
  const numberMailed = isNoApproval ? noAp.get('numberMailed') : ap.get('numberMailed');
  const percentMailed = isNoApproval ? noAp.get('percentMailed') : ap.get('percentMailed');
  const numberCalled = isNoApproval ? noAp.get('numberCalled') : ap.get('numberCalled');
  const percentCalled = isNoApproval ? noAp.get('percentCalled') : ap.get('percentCalled');

  const renderDatesInputs = isNoApproval ? null : (
    <>
      <FormSubmitOnBlur onSubmit={onSubmit} focusOnClick>
        <FormGroup>
          <InlineInput
            label="Date Created:"
            name="dateSent"
            onChange={onChange}
            placeholder="date sent"
            type="date"
            value={dateSent}
            showLabel
          />
        </FormGroup>
      </FormSubmitOnBlur>
      <FormSubmitOnBlur onSubmit={onSubmit} focusOnClick>
        <FormGroup>
          <InlineInput
            label="Date Returned:"
            name="dateReturned"
            onChange={onChange}
            placeholder="date returned"
            type="date"
            value={dateReturned}
            showLabel
          />
        </FormGroup>
      </FormSubmitOnBlur>
    </>
  );

  return (
    <div className="row mb10">
      <div className="col-md-2" />
      <div className="col-sm-1">{renderDatesInputs}</div>
      {toJS(dateSent) && (
        <div className="col-sm-3">
          <LinksTable linksList={linksList} />
        </div>
      )}
      <div className="col-sm-2">
        <div>
          Number of Targets: <strong>{numberTargets}</strong>
        </div>
        <div>
          Number Approved: <strong>{numberApproved}</strong>
        </div>
        <div>
          % Approved: <strong>{percentApproved}</strong>
        </div>
      </div>
      <div className="col-sm-2">
        <div>
          Number mailed: <strong>{numberMailed}</strong>
        </div>
        <div>
          % of app. Mailed: <strong>{percentMailed}</strong>
        </div>
      </div>
      <div className="col-sm-2">
        <div>
          Number Called: <strong>{numberCalled}</strong>
        </div>
        <div>
          % of app. Called: <strong>{percentCalled}</strong>
        </div>
      </div>
    </div>
  );
};

Form.propTypes = {
  common: PropTypes.instanceOf(Map).isRequired,
  isNoApproval: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Form;
