import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import connectOptions, { mergeProps } from '../../utils/connectOptions';
import config from '../../config';
import Popups from '../../components/Popup';
import { changeField } from '../../actions/dashboards';
import downloadFile from '../../utils/downloadFile';

const { main } = config;

class CeoOutreachReportPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.onOk = this.onOk.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onOk(event) {
    const { url, dates, popup } = this.props;

    event.preventDefault();

    const from = dates.get('from').format('YYYY-MM-DD');
    const to = dates.get('to').format('YYYY-MM-DD');

    downloadFile({
      url,
      params: {
        from,
        to,
      },
    });

    const onOk = popup.getIn(['props', 'onOk'], () => {});

    onOk();
  }

  onChange(event) {
    const { name: field, value } = event.target;

    this.props.changeField({ field, value });
  }

  render() {
    const { url, dates } = this.props;

    return <Popups.CeoOutreachReportPopup dates={dates} onChange={this.onChange} onOk={this.onOk} url={url} />;
  }
}

CeoOutreachReportPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    url: main.get('CeoOutreachReport'),
    dates: state.dashboards.get('popupDates'),
  };
}

export { CeoOutreachReportPopupContainer };
export default connect(mapStateToProps, { changeField }, mergeProps, connectOptions)(CeoOutreachReportPopupContainer);
