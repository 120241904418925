import React from 'react';

const Markets = ({ data }) => (
  <>
    <div className="mb5">{data.market1}</div>
    <div className="mb5">{data.market2}</div>
    <div>{data.market3}</div>
  </>
);

export default Markets;
