import { fromJS } from 'immutable';

import {
  CHANGE_TEMPLATE_NAME,
  CHANGE_UPLOADING_FILE,
  CLEAR_VALIDATION_ERROR,
  VALIDATE_FORM,
  UPLOADING_FORM,
  UPLOADED_FORM_FAILURE,
  UPLOADED_FORM_SUCCESS,
  RESET_POPUP,
  CLEAR_TEMPLATE_POPUP_ERROR,
} from '../../../../actions/company/buyer/addOnProject/templatePopup';
import getErrorResponse from '../../../../utils/getErrorResponse';
import getValidator from '../../../../utils/getValidator';

const defaultState = fromJS({
  name: '',
  file: '',
  isUploading: false,
  stage: 'start',
  inputErrors: {},
  errors: [],
});

const SCHEMA = {
  type: 'object',
  properties: {
    name: {
      title: 'File name',
      description: 'should not be empty',
      type: 'string',
      required: true,
      minLength: 1,
    },
    file: {
      title: 'File',
      description: 'should not be empty',
      type: 'string',
      required: true,
      minLength: 1,
    },
  },
};

const validator = getValidator(SCHEMA);

export default function(state = defaultState, action) {
  switch (action.type) {
    case CHANGE_TEMPLATE_NAME:
      return validateForm(state.set('name', action.name));

    case CHANGE_UPLOADING_FILE:
      return validateForm(state.set('file', action.file));

    case CLEAR_VALIDATION_ERROR:
      return state.deleteIn(['inputErrors', action.field]);

    case VALIDATE_FORM:
      return validateForm(state);

    case UPLOADING_FORM:
      return state.set('isUploading', true);

    case UPLOADED_FORM_FAILURE:
      return state.merge({
        isUploading: false,
        stage: 'error',
        errors: getErrorResponse(action.response),
      });

    case UPLOADED_FORM_SUCCESS:
      return state.merge({ isUploading: false, stage: 'done', errors: [] });

    case RESET_POPUP:
      return defaultState;

    case CLEAR_TEMPLATE_POPUP_ERROR:
      return state.merge({ errors: [] });

    default:
      return state;
  }
}

/**
 * Validate form fields.
 *
 * @param state {Immutable.Map} State object.
 * @returns {Immutable.Map}
 */
function validateForm(state) {
  const plainState = state.toJS();

  const isValid = validator.isValid(plainState);

  return state.merge({
    inputErrors: validator.getErrorsWithDesc(plainState),
    stage: isValid ? 'ready' : 'invalid',
  });
}
