import * as React from 'react';
import DatePicker from '@vespaiach/react-datepicker';

import SelectRange from '../helpers/SelectRange';
import BrowseCheckbox from './BrowseCheckbox';
import BrowseAutoComplete from './BrowseAutoComplete';
import BrowseDropDown from '../../components/Browse/BrowseDropDown';
import BrowseAutoCompleteTag from './BrowseAutoCompleteTag';

class BrowseActivityFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.renderStatusRange = this.renderStatusRange.bind(this);
    this.handleValuesChange = this.handleValuesChange.bind(this);
    this.handleAddTag = this.handleAddTag.bind(this);
    this.handleRemoveTag = this.handleRemoveTag.bind(this);
    this.handleChangeProjectType = this.handleChangeProjectType.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleEventDescriptionChange = this.handleEventDescriptionChange.bind(this);
    this.handleLastActivityChange = this.handleLastActivityChange.bind(this);
    this.handleStatusChangedChange = this.handleStatusChangedChange.bind(this);
  }

  handleChangeProjectType = data => {
    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        [data.filterName]: data.filterData,
      },
    });
  };

  renderStatusRange() {
    const { info, filter, statuses } = this.props;
    const statusChanged = info.get('statusChanged');

    if (!statusChanged) return null;

    return (
      <div>
        <span>From</span>
        <SelectRange
          filter={filter.get('statusChangedFrom')}
          info={info.get('statusChangedFrom')}
          onChange={this.handleValuesChange}
          rangeData={statuses}
        />
        <span>To</span>
        <SelectRange
          filter={filter.get('statusChangedTo')}
          info={info.get('statusChangedTo')}
          onChange={this.handleValuesChange}
          rangeData={statuses}
        />
      </div>
    );
  }

  handleValuesChange({ filterName, filterData }) {
    this.props.onSubActivityFilterChange({
      filterName,
      filterData,
    });
  }

  handleStartDateChange(date) {
    if (date.isValid()) {
      this.props.onChange({
        filterName: this.props.filter.get('name'),
        filterData: {
          startDate: date,
        },
      });
    }
  }

  handleEndDateChange(date) {
    if (date.isValid()) {
      this.props.onChange({
        filterName: this.props.filter.get('name'),
        filterData: {
          endDate: date,
        },
      });
    }
  }

  handleEventDescriptionChange(event) {
    const { value } = event.target;

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        eventDescription: value,
      },
    });
  }

  handleAddTag() {
    const { filter, info, onChange } = this.props;
    const name = filter.get('name');
    const eventActivity = filter.get('eventActivity');
    const eventSelected = info.getIn(['selected', eventActivity.get('renderField')]) || '(any activity)';
    const eventDescription = info.get('eventDescription') || '(any description)';
    const selected = `${eventSelected} - ${eventDescription}`;
    let selectedList = info.get('selectedList');

    if (!selectedList.includes(selected)) {
      selectedList = selectedList.push(selected);

      onChange({
        filterName: name,
        filterData: {
          selectedList,
          text: '',
          selected: null,
          eventDescription: '',
        },
      });
    }
  }

  handleRemoveTag(event) {
    const { name } = event.target;
    const tagIndex = parseInt(name.replace('buyerFilterTag', ''), 10);
    const selectedList = this.props.info.get('selectedList').delete(tagIndex);

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        selectedList,
      },
    });
  }

  handleLastActivityChange(event) {
    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        lastActivity: event.target.checked,
        statusChanged: !event.target.checked,
      },
    });
  }

  handleStatusChangedChange(event) {
    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        statusChanged: event.target.checked,
        lastActivity: !event.target.checked,
      },
    });
  }

  render() {
    const {
      filter,
      info,
      projectTypeFilter,
      projectTypeInfo,
      usersFilter,
      usersInfo,
      onChange,
      onGetSuggestion,
      onGetActivityUserSuggestion,
      onGetNextActivityUserSuggestion,
    } = this.props;
    const hasActivityUser = usersFilter && usersInfo;

    const selectedList = info.get('selectedList');
    const statusChanged = info.get('statusChanged');
    const tagListContent = statusChanged
      ? null
      : selectedList.map((tag, i) => (
          <div key={i} className="MultiselectItem">
            {tag}
            <button
              aria-hidden="true"
              aria-label="close"
              className="MultiselectItem-close close btn-xs"
              name={`buyerFilterTag${i}`}
              onClick={this.handleRemoveTag}
              type="button"
            >
              &times;
            </button>
          </div>
        ));

    return (
      <BrowseCheckbox {...this.props}>
        <div className="BrowseControl-content BrowseControl-content--activity">
          <div className="form-inline">
            <div className="form-group">
              <div className="form-group form-group--inline form-group--checkbox">
                <label htmlFor="last-activity-checkbox">
                  <div className="checkbox-wrap">
                    <input
                      checked={info.get('lastActivity')}
                      id="last-activity-checkbox"
                      onChange={this.handleLastActivityChange}
                      type="checkbox"
                    />
                  </div>
                  Last Activity Only
                </label>
              </div>

              <label>From</label>
              <DatePicker
                className="form-control"
                dateFormat="MM/DD/YYYY"
                dropdownMode="select"
                onChange={this.handleStartDateChange}
                selected={info.get('startDate')}
                showDayInMonthOnly
                showMonthDropdown
                showYearDropdown
              />
            </div>
            <div className="form-group">
              <label>To</label>
              <DatePicker
                className="form-control"
                dateFormat="MM/DD/YYYY"
                dropdownMode="select"
                onChange={this.handleEndDateChange}
                selected={info.get('endDate')}
                showDayInMonthOnly
                showMonthDropdown
                showYearDropdown
              />
            </div>
            <div className="form-group form-group--inline form-group--checkbox">
              <label htmlFor="status-changed-checkbox">
                <div className="checkbox-wrap">
                  <input
                    checked={statusChanged}
                    id="status-changed-checkbox"
                    onChange={this.handleStatusChangedChange}
                    type="checkbox"
                  />
                </div>
                Status Changed
              </label>
            </div>
            {this.renderStatusRange()}
            {tagListContent}
            <BrowseAutoComplete
              className="form-control"
              filter={filter.get('eventActivity')}
              info={info}
              isHidden={statusChanged}
              onChange={onChange}
              onGetSuggestion={onGetSuggestion}
            />
            {!statusChanged && (
              <div className="form-group input-group">
                <input
                  className="form-control"
                  onChange={this.handleEventDescriptionChange}
                  placeholder="Event Description"
                  type="text"
                  value={info.get('eventDescription')}
                />
                <span className="input-group-addon" onClick={this.handleAddTag}>
                  <i aria-hidden="true" className="fa fa-plus" />
                </span>
              </div>
            )}
            {hasActivityUser && (
              <BrowseAutoCompleteTag
                filter={usersFilter}
                info={usersInfo}
                onChange={onChange}
                onGetNextSuggestion={onGetNextActivityUserSuggestion}
                onGetSuggestion={onGetActivityUserSuggestion}
                noCheckbox
              />
            )}
            {projectTypeFilter && projectTypeInfo && (
              <div className="form-group">
                <BrowseDropDown
                  filter={projectTypeFilter}
                  info={projectTypeInfo}
                  onChange={this.handleChangeProjectType}
                />
              </div>
            )}
          </div>
        </div>
      </BrowseCheckbox>
    );
  }
}

export default BrowseActivityFilter;
