import React, { PureComponent } from 'react';

import If from '../helpers/If';
import { unwrap } from '../../utils/ChangeSpy';
import FixedPanel from './CompanyTargetFixedPanel';
import Status from './CompanyStatus';
import Details from './CompanyDetails';
import DealNotes from './CompanyDealNotes';
import EmailPanel from './CompanyEmailPanel';
import FilesPanel from './CompanyFilesPanel';
import Contacts from './CompanyContacts';
import Financials from './CompanyFinancials';
import Subsidiaries from './CompanySubsidiaries';
import Buyers from './CompanyBuyers';

class CompanyTarget extends PureComponent {
  constructor(props) {
    super(props);
    this.setHeight = this.setHeight.bind(this);

    this.state = {
      canEditData: false,
      currentStatus: null,
      dealNotesDimensions: this.props.dealNoteSize,
    };
  }

  getHeight(elm) {
    let elmHeight;
    let elmMargin;

    if (document.all) {
      // IE
      elmHeight = parseInt(elm.currentStyle.height, 10);
      elmMargin = parseInt(elm.currentStyle.marginTop, 10) + parseInt(elm.currentStyle.marginBottom, 10);
    } else {
      // Mozilla
      elmHeight = parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('height'), 10);
      elmMargin =
        parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-top'), 10) +
        parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-bottom'), 10);
    }

    return elmHeight + elmMargin;
  }

  setHeight() {
    const height = this.getHeight(this.mainPanel) - this.getHeight(this.topPanel) - 20;

    this.bottomPanel.setAttribute('style', `height: ${height}px;`);
  }

  setCanEditData() {
    const {
      hasPermissions,
      isOwnerResearcher,
      roleIsResearcher,
      isUnapproved,
      companyInfo,
      isDuplicateCompany,
    } = this.props;
    const currentStatus = Number(unwrap(companyInfo.getIn(['buyers', 0, 'currentStatus'])));

    if (isDuplicateCompany) {
      this.setState({ canEditData: false });

      return;
    }

    if (!(!roleIsResearcher && isUnapproved)) {
      if (currentStatus < 2) {
        this.setState({ canEditData: true });
      } else if (isOwnerResearcher) {
        this.setState({ canEditData: true });
      } else if (hasPermissions) {
        this.setState({ canEditData: true });
      }
    } else if (this.state.canEditData) this.setState({ canEditData: false });
  }

  setDealNotesDimensions() {
    this.setState(prevState => ({
      dealNotesDimensions: {
        ...prevState.dealNotesDimensions,
        height: this.bottomPanel.offsetHeight,
      },
    }));
  }

  componentDidMount() {
    window.addEventListener('resize', this.setHeight);

    const { companyInfo } = this.props;

    if (companyInfo.get('buyers')) this.setCanEditData();
    this.setDealNotesDimensions();
  }

  componentDidUpdate(oldProps, oldState) {
    this.setHeight();

    const { companyInfo, isUnapproved } = this.props;
    const currentStatus = Number(unwrap(companyInfo.getIn(['buyers', 0, 'currentStatus'])));

    if (isNaN(currentStatus)) return; // To avoid comparison NaN with NaN below.
    if (this.state.currentStatus !== currentStatus) this.setState({ currentStatus });
    if (oldProps.isUnapproved !== isUnapproved || (!oldProps.companyInfo.get('buyers') && companyInfo.get('buyers')))
      this.setCanEditData();

    if (
      oldState.dealNotesDimensions.hasOwnProperty('height') &&
      oldState.dealNotesDimensions.height !== this.bottomPanel.offsetHeight
    ) {
      this.setDealNotesDimensions();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeight);
  }

  render() {
    const {
      companyId,
      hasPermissions,
      isUnapproved,
      statuses,
      dealNotes,
      isClosed,
      onEnterDealNotes,
      onLeaveDealNotes,
      emails,
      onEnterEmail,
      onLeaveEmail,
      contactsProps,
      uploadFile,
      files,
      suggestDirector,
      suggestAnalyst,
      suggestDealMaker,
      suggestResearcher,
      companyInfo,
      onChange,
      onLeadMemoReport,
      isLoadingLeadMemoReport,
      onToggle,
      onUpdateTags,
      onUpdateHighStatus,
      onErrorClose,
      details,
      detailsCallbacks,
      tradeShowSuggestCreate,
      industryCategories,
      businessModels,
      financialsProps,
      error,
      subsidiariesProps,
      showInstruction,
      onInstructionClick,
      onDescriptionBlur,
      buyersProps,
      currentUser,
      findDirectors,
      fetchEmailTemplates,
      emailTemplates,
      lastEventBuyerText,
      onDeleteFile,
      isDuplicateCompany,
    } = this.props;
    const { canEditData, currentStatus, dealNotesDimensions } = this.state;

    return (
      <div
        ref={input => {
          this.mainPanel = input;
        }}
        className="tab-pane tab-target active full-height"
        id="slide-1"
        onChange={onChange}
      >
        <Status
          canEditData={canEditData}
          companyId={companyId}
          companyInfo={companyInfo}
          currentStatus={currentStatus}
          currentUser={currentUser}
          hasPermissions={hasPermissions}
          isDuplicateCompany={isDuplicateCompany}
          isLoadingLeadMemoReport={isLoadingLeadMemoReport}
          isUnapproved={isUnapproved}
          lastEventBuyerText={lastEventBuyerText}
          onChange={onChange}
          onLeadMemoReport={onLeadMemoReport}
          onRef={input => {
            this.topPanel = input;
          }}
          statuses={statuses}
          suggestAnalyst={suggestAnalyst}
          suggestDealMaker={suggestDealMaker}
          suggestDirector={suggestDirector}
        />
        <div
          ref={input => {
            this.bottomPanel = input;
          }}
          className="mt10"
        >
          <If value={hasPermissions}>
            <DealNotes
              dealNotes={dealNotes}
              dealNoteSize={dealNotesDimensions}
              isClosed={isClosed}
              onChange={onChange}
              onMouseEnter={onEnterDealNotes}
              onMouseLeave={onLeaveDealNotes}
              onToggle={onToggle}
            />
          </If>
          <div className="container-fluid w100p full-height">
            <div className="row flex-row">
              <div className="col-xs-12 col-sm-12 col-md-7 flexWrapper company-target-left-column">
                <div className="panel-group accordion-middle-side company-target-accordion flex-through" id="accordion">
                  <FixedPanel
                    canEditData={canEditData}
                    companyInfo={companyInfo}
                    onChange={onChange}
                    onClick={onInstructionClick}
                    onDescriptionBlur={onDescriptionBlur}
                    showInstruction={showInstruction}
                    suggestResearcher={suggestResearcher}
                  />

                  <Details
                    {...detailsCallbacks}
                    businessModels={businessModels}
                    canEditData={canEditData}
                    details={details}
                    error={error}
                    hasPermissions={hasPermissions}
                    industryCategories={industryCategories}
                    isDuplicateCompany={isDuplicateCompany}
                    onErrorClose={onErrorClose}
                    onUpdateTags={onUpdateTags}
                    suggestCreate={tradeShowSuggestCreate}
                  />

                  <If value={hasPermissions}>
                    <Financials {...financialsProps} canEditData={canEditData} />
                  </If>

                  <Subsidiaries {...subsidiariesProps} disabled={isDuplicateCompany} />

                  <If value={hasPermissions}>
                    <EmailPanel emails={emails} onMouseEnter={onEnterEmail} onMouseLeave={onLeaveEmail} />
                  </If>

                  <If value={hasPermissions}>
                    <FilesPanel
                      canEditData={canEditData}
                      files={files}
                      onDeleteFile={onDeleteFile}
                      uploadFile={uploadFile}
                    />
                  </If>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-5 full-height">
                <div className="right-siderbar container-fluid full-height flexWrapper">
                  <div className="row">
                    <Contacts
                      {...contactsProps}
                      canEditData={!isDuplicateCompany}
                      companyInfo={companyInfo}
                      emailTemplates={emailTemplates}
                      fetchEmailTemplates={fetchEmailTemplates}
                      findDirectors={findDirectors}
                      hasPermissions={hasPermissions}
                    />
                  </div>
                  <If value={hasPermissions && statuses}>
                    <div className="row">
                      <div className="flexItem">
                        <Buyers
                          statuses={statuses}
                          {...buyersProps}
                          canEditData={canEditData}
                          onUpdateHighStatus={onUpdateHighStatus}
                        />
                      </div>
                    </div>
                  </If>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyTarget;
