import { CALL_API } from '../../../../middleware/api';
import config from '../../../../config';
import { getIn } from '../../../../utils/ChangeSpy';

export const LOADING_APPROVALS = Symbol('LOADING_APPROVALS');
export const LOADED_APPROVALS_FAILURE = Symbol('LOADED_APPROVALS_FAILURE');
export const LOADED_APPROVALS_SUCCESS = Symbol('LOADED_APPROVALS_SUCCESS');
export const UPDATE_APPROVAL_INFORMATION = Symbol('UPDATE_APPROVAL_INFORMATION');
export const INSERT_APPROVAL_ROW = Symbol('INSERT_APPROVAL_ROW');
export const CHANGE_APPROVAL_ROW_MODE = Symbol('CHANGE_APPROVAL_ROW_MODE');
export const SORT_APPROVALS = 'SORT_APPROVALS';
export const REQUEST_API_APPROVAL_FAILURE = Symbol('REQUEST_API_APPROVAL_FAILURE');

/**
 * Delete approval.
 *
 * @param {number} projectId Id of project.
 * @param {number} approvalId Id of approval list.
 */
export function deleteApproval({ projectId, approvalId, afterSuccess }) {
  return {
    [CALL_API]: {
      method: 'delete',
      path: `/api/v1/projects/${projectId}/approval_lists/${approvalId}`,
      afterSuccess,
    },
  };
}

/**
 * Load approval list.
 *
 * @param ids {Number} List of project's id.
 * @returns Action.
 */
export function loadApprovals({ projectId, field, direction, isDefaultAddon }) {
  const include = isDefaultAddon
    ? 'default_approval_lists,default_no_approval_lists'
    : 'approval_lists,no_approval_lists';

  return {
    field,
    direction,
    projectId,
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/${projectId}`,
      query: { include },
      unifier: 'handle approvals',
      startType: LOADING_APPROVALS,
      successType: LOADED_APPROVALS_SUCCESS,
      errorType: LOADED_APPROVALS_FAILURE,
    },
  };
}

/**
 * Save approval list.
 *
 * @returns Action.
 */
export function saveApproval(afterSuccess) {
  return (dispatch, getState) => {
    const state = getState().targetCompany.buyer.addOnProject;
    const projectId = state.projectData.get('id');
    const field = state.common.get('approvalSortField');
    const direction = state.common.get('approvalSortDirection');
    const body = {
      approval_lists: getApprovalList(state.approvals),
    };

    dispatch({
      [CALL_API]: {
        method: 'post',
        path: `/api/v1/projects/approval_lists`,
        body,
        startType: LOADING_APPROVALS,
        errorType: REQUEST_API_APPROVAL_FAILURE,
        afterSuccess: ({ dispatch }) => {
          dispatch(loadApprovals({ projectId, afterSuccess, field, direction, isDefaultAddon: true }));
        },
      },
    });
  };
}

/**
 * Save approval list for custom(additional) project.
 *
 * @returns Action.
 */
export function saveApprovalAddOnProjectCustom() {
  const afterSuccess = () => {};

  return (dispatch, getState) => {
    const state = getState().targetCompany.buyer.addOnProject;
    const projectId = state.projectData.get('id');
    const field = state.common.get('approvalSortField');
    const direction = state.common.get('approvalSortDirection');
    const body = {
      approval_lists: getApprovalList(state.approvals),
    };

    if (body.approval_lists.length > 0) {
      dispatch({
        [CALL_API]: {
          method: 'post',
          path: `/api/v1/projects/${projectId}/approval_lists`,
          body,
          startType: LOADING_APPROVALS,
          errorType: REQUEST_API_APPROVAL_FAILURE,
          afterSuccess: ({ dispatch }) => {
            dispatch(
              loadApprovals({
                projectId,
                afterSuccess,
                field,
                direction,
                isDefaultAddon: false,
              }),
            );
          },
        },
      });
    } else {
      dispatch(loadApprovals({ projectId, afterSuccess, field, direction, isDefaultAddon: false }));
    }
  };
}

/**
 * Get approval list for api posting.
 *
 * @param approvals {Immutable.List} Approval list.
 * @returns {Array}
 */
function getApprovalList(approvals) {
  const list = [];

  approvals.forEach(ap => {
    let changed = false;
    const ret = {};
    const id = ap.get('id');
    const project_id = ap.get('projectId');
    const applist_label = getIn(ap, 'applistLabel');
    const date_sent = convertDateToString(getIn(ap, 'dateSent'));
    const date_received = convertDateToString(getIn(ap, 'dateReceived'));

    if (applist_label) {
      ret.applist_label = applist_label;
      changed = true;
    }

    if (date_sent) {
      ret.date_sent = date_sent;
      changed = true;
    } else {
      ret.date_sent = null;
      changed = true;
    }

    if (date_received) {
      ret.date_received = date_received;
      changed = true;
    } else {
      ret.date_received = null;
      changed = true;
    }

    if (id > 0) {
      ret.id = id;
    }

    if (project_id > 0) {
      ret.project_id = project_id;
    }

    if (changed && id !== 'no_approval_lists') {
      list.push(ret);
    }
  });

  return list;
}

function convertDateToString(dt) {
  return dt && dt.isValid() ? dt.format('YYYY-MM-DD') : '';
}

/**
 * Upload approval label.
 *
 * @param index, {Number} Approval's index.
 * @param name {String} Name field.
 * @param value {String} Value field.
 * @returns Action.
 */
export function updateApprovals(index, name, value) {
  return {
    type: UPDATE_APPROVAL_INFORMATION,
    index,
    name,
    value,
  };
}

/**
 * Upload approval label.
 *
 * @param data {Object} Approval's data.
 * @returns Action.
 */
export function insertApprovalRow(data) {
  return {
    type: INSERT_APPROVAL_ROW,
    data,
  };
}

/**
 * Upload approval label.
 *
 * @param index(Number} Approval's id.
 * @returns Action.
 */
export function switchApprovalRowToEdit(index) {
  return {
    type: CHANGE_APPROVAL_ROW_MODE,
    index,
    mode: config.EDIT_MODE,
  };
}

/**
 * Sort target table.
 *
 * @param field {String} Field used for sorting.
 * @param direction {String} Sorting direction.
 * @returns Action.
 */
export function sortApproval(field, direction) {
  return {
    type: SORT_APPROVALS,
    field,
    direction,
  };
}
