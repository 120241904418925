import Immutable from 'immutable';
import getErrorResponse from '../../utils/getErrorResponse';
import config from '../../config';
import {
  CHANGE_TEXT,
  CHANGE_MODE,
  LOADED_SUGGESTION_SUCCESS,
  SELECT_SUGGESTION,
  REQUEST_FLIP_API_FAILURE,
  SAVING_FORM,
  SAVED_FORM_SUCCESS,
  RESET_POPUP,
  LOADED_SUGGESTION_FAILURE,
  LOADING_SUGGESTION,
  SET_ERROR,
  SET_WARNING,
} from '../../actions/nextAction/flipPopup';

const defaultState = Immutable.fromJS({
  errors: [],
  suggestions: [],
  requestId: '',
  module: {
    id: 0,
    value: '',
    origin: '',
    mode: config.VIEW_MODE,
    validation: '',
  },
  associate: {
    id: null,
    value: '',
    origin: '',
    mode: config.VIEW_MODE,
    validation: '',
  },
  dealmaker: {
    id: null,
    value: '',
    origin: '',
    mode: config.VIEW_MODE,
    validation: '',
  },
  waiting: false,
  associateWarning: '',
  dealmakerWarning: '',
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_TEXT:
      return changeText(state, action);

    case CHANGE_MODE:
      return changeMode(state, action);

    case LOADING_SUGGESTION:
      return state.mergeDeep({
        [action.name]: { mode: config.LOADING_MODE },
        requestId: action.requestId,
      });

    case LOADED_SUGGESTION_SUCCESS:
      return mapSuggestions(state, action);

    case SELECT_SUGGESTION:
      return state.mergeDeep({
        [action.name]: {
          id: action.id,
          value: action.value,
          origin: action.value,
          mode: config.VIEW_MODE,
        },
      });

    case REQUEST_FLIP_API_FAILURE:
      return state.mergeDeep({
        module: { mode: config.VIEW_MODE },
        associate: { mode: config.VIEW_MODE },
        dealmaker: { mode: config.VIEW_MODE },
        errors: getErrorResponse(action.response),
      });

    case SAVING_FORM:
      return state.set('waiting', true);

    case SAVED_FORM_SUCCESS:
      return state.set('waiting', false);

    case RESET_POPUP:
      return defaultState;

    case SET_ERROR:
      return state.set('errors', Immutable.List(action.errors));

    case SET_WARNING:
      return state.set(`${action.name}Warning`, action.message);

    case LOADED_SUGGESTION_FAILURE:
      return resetSuggestion(state, action);

    default:
      return state;
  }
};

/**
 * Change mode of module/analyst fields.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action.
 * @returns {Immutable.Map}
 */
function changeMode(state, action) {
  const { name, mode } = action;
  const origin = state.getIn([name, 'origin']);

  if (mode === config.VIEW_MODE) {
    return state.mergeDeep({
      requestId: '',
      [name]: {
        mode,
        value: origin,
      },
    });
  }

  return state.mergeDeep({
    [name]: { mode },
  });
}

/**
 * Reset suggestions when api request get error.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action.
 * @returns {Immutable.Map}
 */
function resetSuggestion(state, action) {
  const { name } = action;

  return state.mergeDeep({
    validations: getErrorResponse(action.response),
    stage: 'edit',
    [name]: { mode: config.VIEW_MODE },
  });
}

/**
 * Update input value.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action.
 * @returns {Immutable.Map}
 */
function changeText(state, action) {
  const { name, value = '' } = action;

  if (value.length) {
    return state.setIn([name, 'value'], value);
  }

  return state.set(name, defaultState.get(name));
}

/**
 * Map suggestion.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action.
 * @returns {Immutable.Map}
 */
function mapSuggestions(state, action) {
  if (action.response.status === 'success' && state.get('requestId') === action.requestId) {
    const suggestions = Immutable.fromJS(action.response.data.map(s => ({ id: s.id, name: s.text })));

    // mergeDeep won't work with empty list, so we have to set it to empty list first
    return state.set('suggestions', Immutable.List()).mergeDeep({
      suggestions,
      [action.name]: { mode: config.SUGGEST_MODE },
    });
  }

  return state;
}
