import { FETCHING_COMPANY_SUCCESS } from '../../../../actions/companyDetail';

export default function(state = 0, action) {
  if (action.type === FETCHING_COMPANY_SUCCESS) {
    return action.response.data.buyerProjects.reduce((res, project) => {
      if (res) return res;
      if (project.type && project.type.toLowerCase() === 'add-on') {
        return project.id;
      }

      return res;
    }, 0);
  }

  return state;
}
