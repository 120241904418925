import { CALL_API } from '../../../../middleware/api';

export const LOADING_TEMPLATES = Symbol('LOADING_TEMPLATES');
export const LOADED_TEMPLATES_FAILURE = Symbol('LOADED_TEMPLATES_FAILURE');
export const LOADED_TEMPLATES_SUCCESS = Symbol('LOADED_TEMPLATES_SUCCESS');
export const CHANGE_EDIT_MODE = Symbol('CHANGE_EDIT_MODE');
export const CHANGE_TEMPLATE_NAME = Symbol('CHANGE_TEMPLATE_NAME');
export const CHANGE_TEMPLATE_ROW_TO_EDIT = Symbol('CHANGE_TEMPLATE_ROW_TO_EDIT');
export const DELETE_FILE = Symbol('DELETE_FILE');

/**
 * Loading mailing template list.
 *
 * @param id {Number} Project's id.
 * @returns Action.
 */
export function loadTemplates(id) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/${id}/templates`,
      startType: LOADING_TEMPLATES,
      errorType: LOADED_TEMPLATES_FAILURE,
      successType: LOADED_TEMPLATES_SUCCESS,
    },
  };
}

/**
 * Change a template row to edit mode.
 *
 * @param id {Number} Template's id.
 * @returns Action.
 */
export function changeToEditMode(id) {
  return {
    type: CHANGE_EDIT_MODE,
    id,
  };
}

/**
 * Update template name.
 *
 * @param id {Number} Template's id.
 * @param name {String} Template's name.
 * @returns Action.
 */
export function updateTemplateName(id, name) {
  return {
    type: CHANGE_TEMPLATE_NAME,
    id,
    name,
  };
}

/**
 * Switch template row to edit mode.
 *
 * @param id {Number} Template's id.
 * @returns Action.
 */
export function switchTemplateRowToEdit(id) {
  return {
    type: CHANGE_TEMPLATE_ROW_TO_EDIT,
    id,
  };
}

/**
 * Persit file name changing.
 */
export function saveTemplateName(projectId, templateId, name) {
  return {
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/projects/${projectId}/templates/${templateId}`,
      body: { name },
      afterSuccess: ({ dispatch }) => {
        dispatch(loadTemplates(projectId));
      },
    },
  };
}

/**
 * Delete file.
 */
export function deleteTemplateRow(projectId, templateId) {
  return {
    [CALL_API]: {
      method: 'delete',
      path: `/api/v1/projects/${projectId}/templates/${templateId}`,
      afterSuccess: ({ dispatch }) => {
        dispatch({
          type: DELETE_FILE,
          id: templateId,
        });
      },
      afterError: ({ dispatch }) => {
        dispatch(loadTemplates(projectId));
      },
    },
  };
}
