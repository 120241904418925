import React, { PureComponent } from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';

class GeneralFormat extends PureComponent {
  collect = () => ({
    rowIndex: this.props.rowIndex,
    rowData: this.props.data,
  });

  render() {
    const { value, colDef } = this.props;

    return (
      <ContextMenuTrigger collect={this.collect} id={colDef.contextMenuId}>
        <div>{value}</div>
      </ContextMenuTrigger>
    );
  }
}

export default GeneralFormat;
