import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import Immutable from 'immutable';
import * as actionCreators from '../actions/mailingCreate';
import MailingCreate from '../components/MailingCreate';
import { buildSortByParam } from '../helpers/paramBuilder';
import connectOptions, { mergeProps } from '../utils/connectOptions';
import { showError } from '../utils/MessagePopup';
import { isResearcher } from '../utils/checkPermissions';
import config from '../config';

const { func, object } = PropTypes;

/** @class
 *  Mailing create page container.
 *  Change title to `"Mailing Create Page"`.
 */
export class MailingCreateContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      selectionQuantity: 0,
    };

    this.handleGetNextPageData = this.handleGetNextPageData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDoubleClickRow = this.handleDoubleClickRow.bind(this);
    this.handleReload = this.handleReload.bind(this);
    this.handleCreateMailing = this.handleCreateMailing.bind(this);
    this.getQuery = this.getQuery.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.handleChangeSelectionQuantity = this.handleChangeSelectionQuantity.bind(this);
    this.handleSelectinoByQuantity = this.handleSelectinoByQuantity.bind(this);
  }

  getQuery() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const recordOwnerId = searchParams.get('recordOwnerId');
    const recordSubOwnerId = searchParams.get('recordSubOwnerId');
    const buyerId = searchParams.get('buyerId');
    const projectId = searchParams.get('projectId');
    const activity = searchParams.get('activity');
    const isLetter = searchParams.get('isLetter');
    const isEmail = searchParams.get('isEmail');
    const beforeDate = searchParams.get('beforeDate');

    return {
      recordOwnerId,
      recordSubOwnerId,
      buyerId,
      projectId,
      activity,
      beforeDate,
      isLetter: isLetter === 'true' ? 1 : 0,
      isEmail: isEmail === 'true' ? 1 : 0,
    };
  }

  componentDidMount() {
    if (!this.hasAccess()) {
      showError(this.context.openPopup, config.permisionError, () => {
        this.props.history.push('/');
        this.context.closePopup();
      });

      return;
    }

    const { getPMHeader, getPMTargets, updateMailingCreate, initCreateMailing } = this.props;
    const query = this.getQuery();

    initCreateMailing();
    getPMHeader(query);
    getPMTargets(query);

    updateMailingCreate({
      filterName: 'header',
      filterData: query,
    });
  }

  componentDidUpdate(oldProps) {
    const { mailingCreate, getPMTargets, clearCreateMailingError } = this.props;
    const createMailingError = mailingCreate.get('createMailingError');

    if (oldProps.mailingCreate !== mailingCreate) {
      const oldSortBy = buildSortByParam(oldProps.mailingCreate);
      const sortBy = buildSortByParam(mailingCreate);

      if (sortBy !== oldSortBy) {
        const query = this.getQuery();

        query.page = 1;
        query.sortBy = sortBy;

        getPMTargets(query);
      }
    }

    if (createMailingError) {
      clearCreateMailingError();
      showError(this.context.openPopup, ['Error creating mailing'], null);
    }
  }

  hasAccess() {
    if (!this.context.currentUser) return false;

    return !isResearcher(this.context.currentUser.getIn(['roles', 0, 'slug'], null));
  }

  handleGetNextPageData(page) {
    const { mailingCreate, getPMTargets } = this.props;
    const sortBy = buildSortByParam(mailingCreate);
    const query = this.getQuery();

    query.page = page;
    query.sortBy = sortBy;

    getPMTargets(query);
  }

  handleChange(data) {
    this.props.updateMailingCreate({
      filterName: data.filterName,
      filterData: data.filterData,
    });
  }

  handleDoubleClickRow(row) {
    window.open(`/company/${row.data.targetId}/target`, '_blank');
  }

  handleReload() {
    const { getPMTargets, initCreateMailing } = this.props;
    const query = this.getQuery();

    query.page = 1;
    initCreateMailing();
    getPMTargets(query);
  }

  handleCreateMailing() {
    const { mailingCreate, loggedUser, createMailing } = this.props;
    const isCreatingMailing = mailingCreate.get('isCreatingMailing');
    const isUnableToCreateMailing = mailingCreate.get('isUnableToCreateMailing');

    if (!isCreatingMailing && !isUnableToCreateMailing) {
      let sourceList = '';
      let confirmMessage = '';

      if (mailingCreate.get('selectList').size > 0) {
        sourceList = mailingCreate.get('selectList');
        confirmMessage = `You are about to create a mailing from ${sourceList.size} selected targets. Proceed?`;
      } else {
        sourceList = mailingCreate.get('queryResults').map(queryResult => queryResult.get('id'));
        confirmMessage = `You are about to create a mailing from all targets (${sourceList.size} total). Proceed?`;
      }

      const selected_ids = sourceList.reduce((acc, id) => {
        acc.push(id);

        return acc;
      }, []);

      this.context.openPopup('ConfirmPopup', {
        message: confirmMessage,
        onOk: () => {
          createMailing({
            selected_ids,
            user_id: loggedUser.get('id'),
          });
          this.context.closePopup();
        },
        onCancel: () => this.context.closePopup(),
        yes: 'Yes',
        no: 'No',
      });
    }
  }

  handleChangeSelectionQuantity(event) {
    const queryResultSize = this.props.mailingCreate.get('queryResults').size;
    const selectionQuantity = event.target.value;

    if (Number(selectionQuantity) > queryResultSize) return;

    this.setState({ selectionQuantity });
  }

  handleSelectinoByQuantity() {
    this.props.updateSelectedRowsByQuantity({
      field: 'selected',
      value: true,
      quantity: this.state.selectionQuantity,
    });
  }

  handleSelection(value) {
    this.props.updateAllRows({
      field: 'selected',
      value,
    });
  }

  render() {
    if (!this.hasAccess()) return null;

    const { mailingCreate, updateSelectedRow } = this.props;

    return (
      <div className="full-height">
        <Helmet title="Create New Mailing Page" />
        <MailingCreate
          mailingCreate={mailingCreate}
          onChange={this.handleChange}
          onChangeSelectionQuantity={this.handleChangeSelectionQuantity}
          onClickSelectionControl={this.handleSelection}
          onCreateMailing={this.handleCreateMailing}
          onDoubleClickRow={this.handleDoubleClickRow}
          onGetNextPageData={this.handleGetNextPageData}
          onReload={this.handleReload}
          onSelectByQuantity={this.handleSelectinoByQuantity}
          onUpdateSelectedRow={updateSelectedRow}
          selectionQuantity={this.state.selectionQuantity}
        />
      </div>
    );
  }
}

MailingCreateContainer.propTypes = {
  getPMHeader: func.isRequired,
  getPMTargets: func.isRequired,
  mailingCreate: object.isRequired,
  updateMailingCreate: func.isRequired,
};

MailingCreateContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Immutable.Map).isRequired,
};

const mapStateToProps = state => ({
  mailingCreate: state.mailingCreate,
  loggedUser: state.auth.get('user'),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions)(MailingCreateContainer);
