import { showError, showInformation } from './MessagePopup';

/**
 * Windward response type.
 *
 * @typedef {object<string, any>} WindwardResponse
 * @property {string} fileName - Name of Windward report.
 * @property {string} message - Response from server.
 * @property {boolean} skipE - SkipE parameter.
 * @property {number} id - Target or Approval Id.
 * @property {string} name - Target or Approval Name.
 */

/**
 * Success Windward Generations.
 *
 * @param {Array<WindwardResponse>} data - Response data.
 * @param {React.Context} context - Context for open modal windows.
 */
export function successWindwardGeneration(data, context) {
  if (data.errors?.length > 0) {
    showError(context.openPopup, ['Error. The report was not generated.']);
  } else {
    const successMessage = [
      {
        name: data.fileName,
        url: data.fileUrl,
      },
    ];

    context.endPopupLoading();
    showInformation(context.openPopup, successMessage, context.closePopup, 'modal-scrollable-content');
  }
}
