import React from 'react';
import { fromJS } from 'immutable';

import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Input from '../helpers/Input';
import Select from '../helpers/Select';
import Button from '../helpers/Button';

// TODO don't use (Edis address popup has)
const options = fromJS(['sk', 'ty', 'ru'].map(v => ({ name: v, value: v })));

const AddAddressPopup = props => {
  const { saveAddress } = props;
  const header = 'Add Address';
  const footer = (
    <Button className="btn-primary btn-xs" data-dismiss="modal" onClick={saveAddress}>
      Save
    </Button>
  );

  return (
    <Popup footer={footer} header={header} id="editAddress">
      <div className="row media-450">
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="k-address3"
            label="Address"
            name="address"
            placeholder="Address"
            autoFocus
            required
            showLabel
          />
        </FormGroup>

        <FormGroup className="col-lg-6 col-xs-6">
          <Input className="input-sm" id="k-type3" label="Type" name="address" placeholder="Type" required showLabel />
        </FormGroup>
      </div>
      <div className="row media-450">
        <FormGroup className="col-lg-4 col-xs-4">
          <Input className="input-sm" id="k-city3" label="City" name="address" placeholder="City" required showLabel />
        </FormGroup>

        <FormGroup className="mt17 col-lg-4 col-xs-4">
          <Select id="k-some3.1" label="" name="dd" options={options} selectClassName="input-sm" />
        </FormGroup>

        <FormGroup className="input-group mt17 city-index2 pr16 col-lg-4 col-xs-4">
          <input className="form-control input-sm" id="index-city" placeholder="Index" type="text" required />
          <span className="input-group-addon">
            <i aria-hidden="true" className="fa fa-plus" />
            <i aria-hidden="true" className="fa fa-minus hide" />
          </span>
        </FormGroup>

        <FormGroup className="hide f-country col-lg-4 col-sm-12">
          <Input
            className="input-sm"
            id="o-country"
            label="Country"
            name="country"
            placeholder="Country"
            required
            showLabel
          />
        </FormGroup>
      </div>
    </Popup>
  );
};

export default AddAddressPopup;
