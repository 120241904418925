import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import Dashboard from '../components/Dashboard';
import {
  getStatsAllTransactions,
  getStatsAddOnTransactions,
  getStatsPlatformTransactions,
  getStatsBuyers,
  getStatsDeals,
  getStatsDealsByState,
  getStatsDealsByYears,
  getStatsExecutiveDeals,
  getStatsProfessionals,
  getTargetsStatsByStatus,
  getBuyersStatsByStatus,
  getExecutivesStatsByStatus,
  getTargetsApproachedByYears,
} from '../actions/statistics';

/**
 * State container component for dashboard page.
 */
const DashboardContainer = props => {
  useEffect(() => {
    props.getStatsAllTransactions();
    props.getStatsAddOnTransactions();
    props.getStatsPlatformTransactions();
    props.getStatsBuyers();
    props.getStatsDeals();
    props.getStatsDealsByState();
    props.getStatsDealsByYears();
    props.getStatsExecutiveDeals();
    props.getStatsProfessionals();
    props.getTargetsStatsByStatus();
    props.getBuyersStatsByStatus();
    props.getExecutivesStatsByStatus();
    props.getTargetsApproachedByYears();
  }, []);

  return (
    <div className="full-height">
      <Helmet title="Dashboard Page" />
      <Dashboard data={props.statistics} />
    </div>
  );
};

const mapStateToProps = state => ({
  statistics: state.statistics,
});

const mapActionToProps = {
  getStatsAllTransactions,
  getStatsAddOnTransactions,
  getStatsPlatformTransactions,
  getStatsBuyers,
  getStatsDeals,
  getStatsDealsByState,
  getStatsDealsByYears,
  getStatsExecutiveDeals,
  getStatsProfessionals,
  getTargetsStatsByStatus,
  getBuyersStatsByStatus,
  getExecutivesStatsByStatus,
  getTargetsApproachedByYears,
};

export default connect(mapStateToProps, mapActionToProps)(React.memo(DashboardContainer));
