import React from 'react';
import PropTypes from 'prop-types';

/**
 * Project component.
 *
 * @param props {Object}.
 * @param props.label {String} Label.
 * @param props.value {String} Value.
 * @param props.showLabel {Boolean} Set to false will hide the label.
 * @returns {React.Component}
 * @constructor
 */
const ProjectInfo = props => {
  const { showLabel = true, label, value } = props;
  const labelContent = showLabel ? `${label} ` : null;

  return (
    <div>
      {labelContent}
      <strong>{value}</strong>
    </div>
  );
};

ProjectInfo.propTypes = {
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  value: PropTypes.string,
};

export default ProjectInfo;
