import isFunction from './isFunction';

/**
 * Check if argument is an instance of Object.
 *
 * @param arg {*}.
 * @returns {boolean}
 */
export default function isObject(arg) {
  return isFunction(arg) || Object.prototype.toString.call(arg) === '[object Object]';
}
