import React from 'react';
import classNames from 'classnames';

import DeepValue from '../decorators/DeepValue';
import ChangeDetection from '../decorators/ChangeDetection';

const Span = ({ className = '', changed = false, minSizes = false, type, children, canDisplayError, ...props }) => {
  className = classNames(className, { changed, 'min-sizes': minSizes });
  if (type === 'date') {
    if (children && children.isValid && children.isValid()) {
      children = children.format('MM/DD/YYYY');
    }
  }

  return (
    <span {...props} className={className}>
      {children}
    </span>
  );
};

export default DeepValue(ChangeDetection(Span));
