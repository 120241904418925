import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { Map, List } from 'immutable';
import AgGridTable from '../helpers/AgGridTable';
import { convertDateString } from '../../utils/dateFormat';
import { handleSortModelChanged } from '../../utils/sorting';

class IconRenderer extends PureComponent {
  render() {
    const { data } = this.props;

    if (data.isemail) return <span className="fa fa-envelope-o" />;
    if (data.isletter) return <span className="fa fa-folder-o" />;

    return null;
  }
}

class DateRenderer extends PureComponent {
  render() {
    return <span>{convertDateString(this.props.value)}</span>;
  }
}

class MailingHistoryTable extends PureComponent {
  static getRowNodeId(data) {
    return data.order;
  }

  constructor(props) {
    super(props);

    this.gridApi = null;
    this.gridColumnApi = null;
    this.onGridReady = this.onGridReady.bind(this);
    this.onSort = this.onSort.bind(this);
    this.handleRowDoubleClicked = this.handleRowDoubleClicked.bind(this);
  }

  getColumnDefs() {
    if (this.props.columnDefs !== this.lastColumnDefs) {
      this.lastColumnDefs = this.props.columnDefs;
      this.columnDefs = this.props.columnDefs.map(columnDef => {
        const field = columnDef.get('field');

        switch (field) {
          case 'icon':
            return columnDef.set('cellRendererFramework', IconRenderer);

          case 'date':
            return columnDef.set('cellRendererFramework', DateRenderer);

          default:
            return columnDef;
        }
      });
    }

    return this.columnDefs;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  handleRowDoubleClicked(row) {
    this.props.onDoubleClickRow(row);
  }

  onSort(sortModel) {
    handleSortModelChanged(sortModel, this.props.data, this.props.onChange);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.data.getIn(['sortBy', 'sortModel']) !== this.props.data.getIn(['sortBy', 'sortModel'])) {
      this.gridApi.setSortModel(this.props.data.getIn(['sortBy', 'sortModel']).toJS());
    }

    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  render() {
    const { data, onGetNextPageData, onDoubleClickRow } = this.props;

    return (
      <div className="MailingHistoryTable">
        <AgGridTable
          columnDefs={this.getColumnDefs()}
          getRowNodeId={MailingHistoryTable.getRowNodeId}
          isFetching={data.get('isFetching')}
          onGetNextPageData={onGetNextPageData}
          onGridReady={this.onGridReady}
          onRowDoubleClicked={onDoubleClickRow}
          onSortModelChanged={this.onSort}
          page={data.get('page')}
          rowData={data.get('queryResults')}
          totalPages={data.get('totalPages')}
          sortable
        />
      </div>
    );
  }
}

MailingHistoryTable.propTypes = {
  columnDefs: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
  onGetNextPageData: PropTypes.func.isRequired,
};

export default MailingHistoryTable;
