import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import {
  showPopup,
  hidePopup,
  handleStartPopupLoading,
  handleEndPopupLoading,
  handleUpdatePopupFormData,
} from '../actions/popup';
import connectOptions, { mergeProps } from '../utils/connectOptions';
import Popup, { POPUPS } from './Popup';

class PopupWrapperContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this._onCloseCallbacks = [];
    this.onClosePopup = this.onClosePopup.bind(this);
    this.openPopup = this.openPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.hasPopup = this.hasPopup.bind(this);
    this.handleEscapeKeyUp = this.handleEscapeKeyUp.bind(this);
    this.startPopupLoading = this.startPopupLoading.bind(this);
    this.endPopupLoading = this.endPopupLoading.bind(this);
    this.updatePopupFormData = this.updatePopupFormData.bind(this);
    this.isClosable = this.isClosable.bind(this);
    this.safeClose = this.safeClose.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener('keyup', this.handleEscapeKeyUp);
  }

  componentWillUnmount() {
    document.body.removeEventListener('keyup', this.handleEscapeKeyUp);
  }

  handleEscapeKeyUp(event) {
    if (event.key === 'Escape') {
      if (this.isClosable()) {
        return this.closePopup();
      }
    }
  }

  isClosable() {
    const popup = POPUPS[this.props.popup.get('name')];

    return popup && popup.closable !== false;
  }

  safeClose(...rest) {
    if (this.isClosable()) {
      return this.closePopup(...rest);
    }
  }

  onClosePopup(cb) {
    this._onCloseCallbacks.push(cb);
  }

  openPopup(name, props) {
    this.props.showPopup({ name, props });
    this._onCloseCallbacks = [];
  }

  closePopup() {
    this.props.hidePopup();
    this._onCloseCallbacks.forEach(cb => cb());
    this._onCloseCallbacks = [];
  }

  hasPopup() {
    return this.props.popup.get('name') !== null;
  }

  getChildContext() {
    return {
      openPopup: this.openPopup,
      onClosePopup: this.onClosePopup,
      closePopup: this.closePopup,
      closePopupUnsafe: this.closePopup,
      hasPopup: this.hasPopup,
      startPopupLoading: this.startPopupLoading,
      endPopupLoading: this.endPopupLoading,
      updatePopupFormData: this.updatePopupFormData,
      isClosablePopup: () => this.isClosable(),
    };
  }

  startPopupLoading() {
    this.props.handleStartPopupLoading();
  }

  endPopupLoading(error) {
    this.props.handleEndPopupLoading(error);
    if (!error) {
      this.closePopup();
    }
  }

  updatePopupFormData(formData) {
    this.props.handleUpdatePopupFormData(formData);
  }

  render() {
    const { children, popup } = this.props;

    return (
      <div className="full-height">
        <Popup popup={popup} safeClose={this.safeClose} />
        {children}
      </div>
    );
  }
}

PopupWrapperContainer.childContextTypes = {
  isClosablePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  closePopupUnsafe: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  hasPopup: PropTypes.func.isRequired,
  startPopupLoading: PropTypes.func.isRequired,
  endPopupLoading: PropTypes.func.isRequired,
  updatePopupFormData: PropTypes.func.isRequired,
};

PopupWrapperContainer.propTypes = {
  popup: PropTypes.instanceOf(Map).isRequired,
};

function mapStateToProps(state) {
  return {
    popup: state.popup,
  };
}

export { PopupWrapperContainer };
export default connect(
  mapStateToProps,
  {
    showPopup,
    hidePopup,
    handleStartPopupLoading,
    handleEndPopupLoading,
    handleUpdatePopupFormData,
  },
  mergeProps,
  connectOptions,
)(PopupWrapperContainer);
