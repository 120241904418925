import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import classNames from 'classnames';

import uniqueId from '../../../utils/uniqueId';
import DeepValue from '../../decorators/DeepValue';
import ChangeDetection from '../../decorators/ChangeDetection';

const modules = {
  toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
};
const formats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'link'];

class WysiwygReactQuill extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { text: props.value };

    this.handleChange = this.handleChange.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleRef = this.handleRef.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener('keyup', event => this.handleTabKeyUp(event, this));
    this.wysiwyg.editingArea.addEventListener('paste', this.handlePaste);
  }

  componentWillUnmount() {
    document.body.removeEventListener('keyup', event => this.handleTabKeyUp(event, this));
    this.wysiwyg.editingArea.removeEventListener('paste', this.handlePaste);
  }

  handlePaste(event) {
    const paste = (event.clipboardData || window.clipboardData).getData('text');
    const selection = window.getSelection();

    selection.getRangeAt(0).insertNode(document.createTextNode(paste));

    event.preventDefault();
  }

  handleTabKeyUp(event, that) {
    if (event.key === 'Tab' && event.target.classList.contains('ql-container')) {
      if (that.wysiwyg) {
        that.wysiwyg.focus();
      }
    }
  }

  handleChange(value) {
    this.setState({ text: value }, () => {
      this.props.onChange({ target: { name: this.props.name, value } });
    });
  }

  handleRef(component) {
    this.wysiwyg = component;
  }

  render() {
    const { id, label, name, placeholder, showLabel, changed, ...rest } = this.props;
    const labelClassName = classNames({ hidden: !showLabel });
    const className = classNames('wysiwyg', this.props.className, { changed });

    return (
      <div className="wysiwygWrap">
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
        <div className={className}>
          <ReactQuill
            {...rest}
            ref={this.handleRef}
            formats={formats}
            modules={modules}
            name={name}
            onChange={this.handleChange}
            placeholder={placeholder}
            value={this.state.text}
          />
        </div>
      </div>
    );
  }
}

export default DeepValue(ChangeDetection(WysiwygReactQuill));

WysiwygReactQuill.defaultProps = {
  id: uniqueId(),
  showLabel: false,
  changed: false,
};

WysiwygReactQuill.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  showLabel: PropTypes.bool,
  value: PropTypes.string,
};
