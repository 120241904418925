import project from './project';
import users from './user';
import targets from './target';
import templates from './template';
import approvals from './approval';
import common from './common';
import industryPopup from './industryPopup';
import templatePopup from './templatePopup';
import projectId from './projectId';
import suggestion from './suggestion';
import tags from './tags';
import addOnProjectCreate from './addOnProjectCreate';
import buyerProjects from './buyerProjects';
import approvalList from './approvalList';

const projectReducer = (state = {}, action) => ({
  buyerProjects: buyerProjects(state.buyerProjects, action),
  common: common(state.common, action),
  projectData: project(state.projectData, action),
  users: users(state.users, action, state.common),
  approvals: approvals(state.approvals, action),
  templates: templates(state.templates, action),
  targets: targets(state.targets, action),
  industryPopup: industryPopup(state.industryPopup, action),
  templatePopup: templatePopup(state.templatePopup, action),
  projectId: projectId(state.projectId, action),
  suggestions: suggestion(state.suggestions, action),
  tags: tags(state.tags, action),
  addOnProjectCreate: addOnProjectCreate(state.addOnProjectCreate, action),
  approvalList: approvalList(state.approvalList, action),
});

export default projectReducer;
