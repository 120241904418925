import PropTypes from 'prop-types';

import React from 'react';

export default function MapValues(Component) {
  const MappingValues = props => {
    const { displayMap, ...rest } = props;

    if (!displayMap) return <Component {...rest} />;

    const mappedProps = {};

    Object.keys(displayMap).forEach(field => {
      if (!(field in rest)) return;

      const propVal = String(rest[field]);

      Object.keys(displayMap[field]).some(value => {
        if (value !== propVal) return false;
        mappedProps[field] = displayMap[field][value];

        return true;
      });
    });

    return <Component {...rest} {...mappedProps} />;
  };

  MappingValues.displayName = `MappingValues(${Component.displayName || Component.name})`;

  MappingValues.propTypes = {
    displayMap: PropTypes.any,
  };

  return MappingValues;
}
