import React, { PureComponent } from 'react';
import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import { unwrap } from '../../utils/ChangeSpy';

const Checkbox = ({ id, parentId, name, checked = false, collapsed, onChange, onClick }) => (
  <div className="checkbox">
    <label>
      <input checked={checked} onChange={() => onChange(id, parentId)} type="checkbox" />
      {name}
      {onClick && (
        <i
          aria-hidden="true"
          className={collapsed ? 'fa fa-caret-square-o-down' : 'fa fa-caret-square-o-up'}
          onClick={event => {
            event.stopPropagation();
            event.preventDefault();
            onClick(id);
          }}
          title={collapsed ? 'Click to expand' : 'Click to collapse'}
        />
      )}
    </label>
  </div>
);

const TagRow = props => <div className="tag-row">{props.children}</div>;

TagRow.Item = ({ tag, onChange, onClick }) => {
  if (!tag) {
    return <div />;
  }

  const subs = tag.get('children');
  const els =
    subs.size > 0 && !tag.get('collapsed', true) ? (
      <ul>
        {subs.map(sub => (
          <li key={sub.get('id')}>
            <Checkbox
              checked={sub.get('checked', false)}
              id={sub.get('id')}
              name={sub.get('category')}
              onChange={onChange}
              parentId={tag.get('id')}
            />
          </li>
        ))}
      </ul>
    ) : null;

  return (
    <div>
      <Checkbox
        checked={tag.get('checked', false)}
        collapsed={tag.get('collapsed', true)}
        id={tag.get('id')}
        name={tag.get('category')}
        onChange={onChange}
        onClick={onClick}
      />
      {els}
    </div>
  );
};
TagRow.Item.displayName = 'TagRow.Item';

const Row = ({ tag1, tag2, tag3, onChange, onClick }) => {
  const ids = [tag1.get('id')];

  if (tag2) {
    ids.push(tag2.get('id'));
  }

  if (tag3) {
    ids.push(tag3.get('id'));
  }

  const handleClick = id => onClick(id, ids);

  return (
    <TagRow>
      <TagRow.Item onChange={onChange} onClick={handleClick} tag={tag1} />
      <TagRow.Item onChange={onChange} onClick={handleClick} tag={tag2} />
      <TagRow.Item onChange={onChange} onClick={handleClick} tag={tag3} />
    </TagRow>
  );
};

const ModelBusiness = ({ businessModels, onModelChange, collapsed, onClick }) => {
  const rows = !collapsed ? (
    <ul>
      {unwrap(businessModels).map(bm => (
        <li key={bm.get('id')}>
          <Checkbox
            checked={bm.get('checked', false)}
            id={bm.get('id')}
            name={bm.get('category')}
            onChange={onModelChange}
          />
        </li>
      ))}
    </ul>
  ) : null;

  return (
    <div className="tag-row">
      <div>
        <div className="checkbox">
          <label className="pl0">
            Business Model
            <i
              aria-hidden="true"
              className={collapsed ? 'fa fa-caret-square-o-down' : 'fa fa-caret-square-o-up'}
              onClick={onClick}
              title={collapsed ? 'Click to expand' : 'Click to collapse'}
            />
          </label>
        </div>
        {rows}
      </div>
      <div />
    </div>
  );
};

/**
 * The component which renders reminder text.
 *
 * @param {boolean} hide Flag for show or hide reminder.
 * @param {string} message Reminder message.
 */
const TagReminders = ({ hide, message }) => (hide ? null : <div className="tag-error">{message}</div>);

const BUSINESS_MODELS_ERROR = 'Select at least one Business Model tag';
const TAGS_ERROR =
  'Select at least one Broad Industry tag (Sub-Category, End Market, Theme, and Business Function tags are optional)';

class TagsManagementPopup extends PureComponent {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      collapsed: true,
    };
  }

  handleClick() {
    this.setState(prevState => ({ collapsed: !prevState.collapsed }));
  }

  render() {
    const { businessModels, tags, onChange, onModelChange, onClick, onClose, clientView = false } = this.props;

    const hasCheckedBM = businessModels.has('value')
      ? businessModels
          .get('value')
          .toJS()
          .some(({ checked }) => checked)
      : false;
    const hasCheckedTags =
      tags.size > 0 ? tags.toJS().some(tag => tag.checked || tag.children.some(({ checked }) => checked)) : false;

    const footer = [
      <Button key="close" className="btn-primary btn-xs" data-dismiss="modal" onClick={onClose} autoFocus>
        Close
      </Button>,
    ];
    let i = 0;
    const rows = [];

    while (i < tags.size) {
      rows.push(
        <Row
          key={i}
          onChange={onChange}
          onClick={onClick}
          tag1={tags.get(i)}
          tag2={tags.get(i + 1)}
          tag3={tags.get(i + 2)}
        />,
      );
      i += 3;
    }

    const header = (
      <div>
        Industry Tags
        <a className="tag-link" href="/tag" rel="noopener noreferrer" target="_blank">
          Tags Management
        </a>
      </div>
    );

    if (clientView) {
      return (
        <Popup footer={footer} header={header} id="tagsManagementPopup">
          <div className="tag-list">
            <div className="business-model">
              <ModelBusiness
                businessModels={businessModels}
                collapsed={this.state.collapsed}
                onClick={this.handleClick}
                onModelChange={onModelChange}
              />
              <TagReminders hide={hasCheckedBM} message={BUSINESS_MODELS_ERROR} />
            </div>
            <div className="industry-tags">
              <span>
                <b>Broad Industry Category Tags</b> (Click on the arrow to view Sub-Category, End Market, Theme, and
                Business Function Tags)
              </span>
              <TagReminders hide={hasCheckedTags} message={TAGS_ERROR} />
              {rows}
            </div>
          </div>
        </Popup>
      );
    }

    return (
      <Popup footer={footer} header={header} id="tagsManagementPopup">
        <div className="tag-list">
          <ModelBusiness
            businessModels={businessModels}
            collapsed={this.state.collapsed}
            onClick={this.handleClick}
            onModelChange={onModelChange}
          />
          {rows}
        </div>
      </Popup>
    );
  }
}

export default TagsManagementPopup;
