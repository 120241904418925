import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import Popups from '../../components/Popup';

import AddExecutiveContactPopup from './AddExecutiveContactPopup';
import AddLpContactPopup from './AddLpContactPopup';
import AddCompanyBuyerPopup from './AddCompanyBuyerPopup';
import AddEmailTemplatePopup from './AddEmailTemplatePopup';
import AddPositionPopup from './AddPositionPopup';
import AddCompanyContactPopup from './AddCompanyContactPopup';
import AddCompanyTargetPopup from './AddCompanyTargetPopup';
import AddFundPopup from './AddFundPopup';
import EventPopup from './EventPopup';
import ExecutiveRolePopup from './ExecutiveRolePopup';
import LPRolePopup from './LPRolePopup';
import EditAddressPopup from './EditAddressPopup';
import TemplatePopup from './TemplatePopup';
import NewIndustryPopup from './NewIndustryPopup';
import ConfirmPopup from './ConfirmPopup';
import CCVisitReportPopup from './CCVisitReportPopup';
import OutreachReportPopup from './OutreachReportPopup';
import CeoOutreachReportPopup from './CeoOutreachReportPopup';
import SetNextActionsPopup from './SetNextActionsPopup';
import ChangeStatusPopup from './ChangeStatusPopup';
import EditFiscalYearPopup from './EditFiscalYearPopup';
import AddHarvcoTagPopup from './AddHarvcoTagPopup';
import DeleteConfirmPopup from './DeleteConfirmPopup';
import InternalFlipPopup from './nextAction/InternalFlipPopup';
import PushNextActionDatesPopup from './PushNextActionDatesPopup';
import ApiErrorPopup from './ApiErrorPopup';
import AddUpdateBuyerPopup from './AddUpdateBuyerPopup';
import RuntimeErrorPopup from './RuntimeErrorPopup';
import ErrorPopup from './ErrorPopup';
import EventCompanyPopup from './EventCompanyPopup';
import EditContactPopup from './EditContactPopup';
import EditPhoneContactChannelPopup from './EditPhoneContactChannelPopup';
import EditEmailContactChannelPopup from './EditEmailContactChannelPopup';
import EmailFinderPopup from './EmailFinderPopup';
import MarkMailingCompletedPopup from './MarkMailingCompletedPopup';
import AddNewPlatformProject from './AddNewPlatformProject';
import AddNewCEOProject from './AddNewCEOProject';
import BuyerRolePopup from './BuyerRolePopup';
import AddBuyerCompanyPopup from './AddBuyerCompanyPopup';
import AddCompanyTargetForPositionPopup from './AddCompanyTargetForPositionPopup';
import StatusCodePopup from './StatusCodePopup';
import ActiveTargetPopup from './ActiveTargetPopup';
import TagsManagementPopup from './TagsManagementPopup';
import UpdateHighStatusPopup from './UpdateHighStatusPopup';
import DescriptionChangePopup from './DescriptionChangePopup';
import RunWindwardReportPopupOld from './RunWindwardReportPopupOld';
import RunWindwardReportPopup from './RunWindwardReportPopup';
import GenerateOnlineApprovalListPopup from './GenerateOnlineApprovalListPopup';
import ShareLinkPopup from './ShareLinkPopup';
import OneOffApproachPopup from './OneOffApproachPopup';
import AddAddonProjectPopup from './AddAddonProjectPopup';
import NdaRequestPopup from './Company/Target/NdaRequestPopup';
import UploadTemplatePopup from './UploadTemplatePopup';
import ClientSummaryReportPopup from './ClientSummaryReportPopup';
import AddTradeShowEventPopup from './AddTradeShowEventPopup';
import AssignTradeShowPopup from './AssignTradeShowPopup';
import ConflictReportPopup from './ConflictReportPopup';

/**
 * All application's popups.
 * These popups will available to open via `this.context.openPopup('<String component name>', {<props>});`.
 *
 * @type {object}
 */
export const POPUPS = {
  ...Popups,
  AddExecutiveContactPopup,
  AddCompanyBuyerPopup,
  AddEmailTemplatePopup,
  AddLpContactPopup,
  AddPositionPopup,
  AddFundPopup,
  AddCompanyContactPopup,
  AddTradeShowEventPopup,
  AssignTradeShowPopup,
  EventPopup,
  CeoOutreachReportPopup,
  CCVisitReportPopup,
  OutreachReportPopup,
  ExecutiveRolePopup,
  EditFiscalYearPopup,
  AddBuyerCompanyPopup,
  AddCompanyTargetPopup,
  LPRolePopup,
  ConfirmPopup,
  EditAddressPopup,
  TemplatePopup,
  NewIndustryPopup,
  SetNextActionsPopup,
  ChangeStatusPopup,
  AddHarvcoTagPopup,
  InternalFlipPopup,
  DeleteConfirmPopup,
  PushNextActionDatesPopup,
  ApiErrorPopup,
  RuntimeErrorPopup,
  ErrorPopup,
  ClientSummaryReportPopup,
  AddUpdateBuyerPopup,
  EventCompanyPopup,
  EditPhoneContactChannelPopup,
  EditEmailContactChannelPopup,
  EmailFinderPopup,
  EditContactPopup,
  MarkMailingCompletedPopup,
  AddNewPlatformProject,
  AddNewCEOProject,
  StatusCodePopup,
  BuyerRolePopup,
  AddCompanyTargetForPositionPopup,
  ActiveTargetPopup,
  TagsManagementPopup,
  UpdateHighStatusPopup,
  DescriptionChangePopup,
  RunWindwardReportPopupOld,
  RunWindwardReportPopup,
  GenerateOnlineApprovalListPopup,
  ShareLinkPopup,
  OneOffApproachPopup,
  AddAddonProjectPopup,
  NdaRequestPopup,
  UploadTemplatePopup,
  ConflictReportPopup,
};

/**
 * Popup wrapper to display backdrop and popup itself if need.
 *
 * @param props {Object}.
 * @param props.popup {Immutable.Map} Popup's meta info (props, name).
 * @returns {React.Component}
 * @class
 */
class PopupContainer extends Component {
  getChildContext() {
    return {
      inPopup: true,
      closePopup: (...rest) => this.props.safeClose(...rest),
    };
  }

  render() {
    const { popup } = this.props;
    const backdrop = getBackdrop({ popup });
    const popupContent = getPopupContent({ popup });

    return (
      <div>
        {backdrop}
        {popupContent}
      </div>
    );
  }
}

PopupContainer.childContextTypes = {
  inPopup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return props;
}

export { PopupContainer };
export default connect(mapStateToProps, {}, mergeProps, connectOptions)(PopupContainer);

/**
 * Check if popup with this name exists.
 *
 * @param name {String}.
 * @returns {boolean}
 */
function hasPopupWithName(name) {
  return POPUPS.hasOwnProperty(name);
}

/**
 * Returns Popup content is application has popup with such name.
 *
 * @param popup {Immutable.Map} Popup's meta info (props, name).
 * @private
 * @returns {React.Component}
 */
function getPopupContent({ popup }) {
  const name = popup.get('name');

  if (!hasPopupWithName(name)) {
    if (name !== null) {
      console.error(`No Popup with name "${name}". Display empty <div/>`);
    }

    return <div />;
  }

  const PopupContent = POPUPS[name];

  return <PopupContent popup={popup} />;
}

/**
 * Returns Popup's backdrop. If no such popup - do not display anything (fade has opacity=0).
 *
 * @param popup {Immutable.Map} Popup's meta info (props, name).
 * @private
 * @returns {React.Component}
 */
function getBackdrop({ popup }) {
  const name = popup.get('name');

  let className = 'fade';

  if (hasPopupWithName(name)) {
    className = classNames('modal-backdrop fade in', {
      unclosable: POPUPS[name].closable === false,
    });
  }

  return <div className={className} />;
}
