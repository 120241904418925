import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'immutable';

import SearchPanel from './SearchPanel';
import SearchResultHead from './SearchResultHead';
import SearchResultTable from './SearchResultTable';

/**
 * Search component.
 *
 * @param props {Object}.
 * @returns {React.Component}
 * @class
 */
function Search(props) {
  return (
    <div className="container-fluid full-height flexWrapper">
      <SearchPanel
        dropdownItems={props.dropdownItems}
        hasAccess={props.hasAccess}
        onClick={props.onClick}
        onOnlyOwnerChange={props.onOnlyOwnerChange}
        onSearch={props.onSearch}
        onSearchBy={props.onSearchBy}
        onSearchingTermChange={props.onSearchingTermChange}
        searchBy={props.searchBy}
        searchingTerm={props.searchingTerm}
        userName={props.userName}
      />
      <SearchResultHead
        activeTab={props.activeTab}
        companiesCount={props.totalCompanies}
        hasAccess={props.hasAccess}
        onTabChange={props.onTabChange}
        peopleCount={props.totalPeople}
      />
      <SearchResultTable
        activeTab={props.activeTab}
        companies={props.companies}
        hasAccess={props.hasAccess}
        onScroll={props.onScroll}
        people={props.people}
        searchingCompanies={props.searchingCompanies}
        searchingPeople={props.searchingPeople}
      />
    </div>
  );
}

Search.propTypes = {
  activeTab: PropTypes.string.isRequired,
  companies: PropTypes.instanceOf(List).isRequired,
  dropdownItems: PropTypes.instanceOf(List).isRequired,
  onClick: PropTypes.func.isRequired,
  onOnlyOwnerChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSearchingTermChange: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired,
  people: PropTypes.instanceOf(List).isRequired,
  searchingCompanies: PropTypes.bool.isRequired,
  searchingPeople: PropTypes.bool.isRequired,
  searchingTerm: PropTypes.string.isRequired,
  totalCompanies: PropTypes.number.isRequired,
  totalPeople: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
};

export default Search;
