import PropTypes from 'prop-types';

import React from 'react';
import { Map } from 'immutable';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import uniqueId from '../../../utils/uniqueId';
import { unwrap } from '../../../utils/ChangeSpy';
import { PureInlineInput as _PureInlineInput } from '../../helpers/Input';
import PrettyError from '../../decorators/PrettyError';
import DisplayError from '../../decorators/DisplayError';

const noErrors = Map();
const PureInlineInput = PrettyError(DisplayError(_PureInlineInput));

const cellStyle = {
  height: '17px',
};

const ContextMenuWrapper = ({ onDel, onEdit, children, data }) => {
  const id = uniqueId();

  return (
    <div>
      <ContextMenuTrigger collect={() => data.toJS()} id={id}>
        {children}
      </ContextMenuTrigger>

      <ContextMenu id={id}>
        <MenuItem onClick={onDel}>
          <i className="fa fa-remove" /> Delete
        </MenuItem>
        <MenuItem onClick={onEdit}>
          <i className="fa fa-edit" /> Edit
        </MenuItem>
      </ContextMenu>
    </div>
  );
};

const percent = (target, base) => {
  const perc = (100 * target) / base;

  if (isNaN(perc) || !Number.isFinite(perc)) return null;

  return `${perc.toFixed(2)}%`;
};

const filter = (fYear, year, field, revenue, key) => {
  const tmp = unwrap(fYear.get(field));

  if (!tmp) return <td key={key} />;

  return (
    <td key={key}>
      <div>{percent(tmp, revenue)}</div>
    </td>
  );
};

const getGrowth = (revenue, prevReven, index) => {
  if (index === 0) return null;
  if (!revenue) return null;
  if (!prevReven) return null;

  const diff = revenue - prevReven;

  if (isNaN(diff)) return null;

  return percent(diff, prevReven);
};

const FiscalYearTable = (props, context) => {
  const { disabled, targetFiscalYears, onDel, onAdd, onEdit, onChange } = props;
  const { onFiscalErrorClose: closeError } = context;

  const handleAddClick = (event, ...rest) => {
    if (!disabled) {
      return onAdd(event, ...rest);
    }
  };

  const handleDeleteClick = (event, ...rest) => {
    if (!disabled) {
      return onDel(event, ...rest);
    }
  };

  const handleEditClick = (event, ...rest) => {
    if (!disabled) {
      return onEdit(event, ...rest);
    }
  };

  const cells = {
    revenue: [],
    gross: [],
    net: [],
    ebit: [],
    ebitda: [],
    capex: [],
  };

  const calcCells = {
    growth: [],
    grossPerc: [],
    ebitPerc: [],
    ebitdaPerc: [],
    netPerc: [],
  };

  const keys = Object.keys(cells);

  const columns = targetFiscalYears.map((fiscalYear, i, years) => {
    const year = unwrap(fiscalYear.get('fiscalYear'));
    const revenue = unwrap(fiscalYear.get('revenue'));
    const prevReven = unwrap(years.getIn([i - 1, 'revenue']));
    const inputErrors = fiscalYear.get('inputErrors', noErrors);
    const onErrorClose = (event, name) => closeError(event, name, i);

    keys.forEach(key => {
      cells[key].push(
        <td key={`${year}_${key}`}>
          <PureInlineInput
            data-id={fiscalYear.get('id')}
            disabled={disabled}
            error={inputErrors.get(key, null)}
            name={key}
            onChange={event => onChange(event)}
            onErrorClose={onErrorClose}
            placeholder=""
            root={fiscalYear}
            type="currency"
            deep
          />
        </td>,
      );
    });

    calcCells.growth.push(<td key={`${year}_growth`}>{getGrowth(revenue, prevReven, i)}</td>);
    calcCells.grossPerc.push(filter(fiscalYear, year, 'gross', revenue, `${year}_grossPerc`));
    calcCells.ebitPerc.push(filter(fiscalYear, year, 'ebit', revenue, `${year}_ebitPerc`));
    calcCells.ebitdaPerc.push(filter(fiscalYear, year, 'ebitda', revenue, `${year}_ebitdaPerc`));
    calcCells.netPerc.push(filter(fiscalYear, year, 'net', revenue, `${year}_netPerc`));

    return (
      <th key={year}>
        <ContextMenuWrapper data={fiscalYear} onDel={handleDeleteClick} onEdit={handleEditClick}>
          <div style={cellStyle}>{year}</div>
        </ContextMenuWrapper>
      </th>
    );
  });

  return (
    <form className="table-responsive text-left">
      <table className="table table-bordered table-hover table-striped company-target__fiscal-years-table">
        <thead>
          <tr>
            <th className="plus-icon">
              <i aria-hidden="true" className="fa fa-plus fa-2x" onClick={handleAddClick} />
            </th>
            {columns}
            <th />
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Revenue</td>
            {cells.revenue}
            <td />
          </tr>
          <tr>
            <td>Growth</td>
            {calcCells.growth}
            <td />
          </tr>

          <tr>
            <td>Gross</td>
            {cells.gross}
            <td />
          </tr>
          <tr>
            <td>%</td>
            {calcCells.grossPerc}
            <td />
          </tr>

          <tr>
            <td>EBIT</td>
            {cells.ebit}
            <td />
          </tr>
          <tr>
            <td>%</td>
            {calcCells.ebitPerc}
            <td />
          </tr>

          <tr>
            <td>EBITDA</td>
            {cells.ebitda}
            <td />
          </tr>
          <tr>
            <td>%</td>
            {calcCells.ebitdaPerc}
            <td />
          </tr>

          <tr>
            <td>Net Inc...</td>
            {cells.net}
            <td />
          </tr>
          <tr>
            <td>%</td>
            {calcCells.netPerc}
            <td />
          </tr>

          <tr>
            <td>CapEx</td>
            {cells.capex}
            <td />
          </tr>
        </tbody>
      </table>
    </form>
  );
};

FiscalYearTable.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

FiscalYearTable.contextTypes = {
  onFiscalErrorClose: PropTypes.func,
};

export default FiscalYearTable;
