import React from 'react';
import FormGroup from '../../helpers/FormGroup';
import Input from '../../helpers/Input';
import PhoneInput from './PhoneInput';

const CustomFormat = props => {
  const { onChange, contact, onUndo } = props;

  return (
    <div className="row">
      <PhoneInput className="col-sm-5" contact={contact} onChange={onChange} onUndo={onUndo} />
      <div className="col-sm-2 text-right">
        <br />
        Custom -
      </div>
      <FormGroup className="col-sm-5">
        <Input
          className="input-md"
          label="Type"
          maxLength="30"
          name="type"
          onChange={onChange}
          placeholder="Type"
          root={contact}
          deep
          showLabel
        />
      </FormGroup>
    </div>
  );
};

export default CustomFormat;
