import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { history } from '../store/configureStore';
import App from '../containers/App';
import Login from '../containers/Login';
import WrapperOnlineApprovalLists from '../containers/WrapperOnlineApprovalLists';

export default function RootRouter() {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route component={Login} path="/login" />
        <Route component={WrapperOnlineApprovalLists} path="/project/:projectId/approval/:approvalId/online" />
        <Route component={App} path="/" />
      </Switch>
    </ConnectedRouter>
  );
}
