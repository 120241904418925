import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { changeFilters, getFilters } from '../../actions/leadsReport';
import Checkbox from '../helpers/Checkbox';

/**
 * Leads report filters component.
 *
 * @param {object} props Props.
 * @param {boolean} props.isFiltersFetching Show spinner icon when set to true.
 * @param {Immutable.List} props.modules Available filters.
 * @param {Function} props.getFilters Method for getting available filters for getting leads.
 * @param {Function} props.changeFilters Method for changing filters for getting leads.
 */
const FiltersComponent = props => {
  const { isFiltersFetching, modules, changeFilters, getFilters } = props;

  useEffect(() => {
    getFilters();
  }, []);

  const spinner = isFiltersFetching ? (
    <div className="text-center">
      <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
    </div>
  ) : null;

  const content = modules.map((module, moduleIndex) => {
    const moduleId = module.get('moduleId');
    const moduleName = module.get('moduleName');
    const isCheckedModule = module.get('isChecked');
    const users = module.get('users').map((user, userIndex) => {
      const userId = user.get('userId');
      const userName = user.get('userName');
      const isCheckedUser = user.get('isChecked');

      return (
        <Checkbox
          key={userId}
          capitalizeLabel={false}
          defaultChecked={isCheckedUser}
          label={userName}
          name="user"
          onChange={event => changeFilters(event.target, moduleIndex, userIndex)}
          value={userId}
        />
      );
    });

    return (
      <div key={moduleId}>
        <Checkbox
          capitalizeLabel={false}
          defaultChecked={isCheckedModule}
          label={moduleName}
          name="module"
          onChange={event => changeFilters(event.target, moduleIndex)}
          value={moduleId}
        />
        {isCheckedModule && (
          <div className="ml20 mb10">
            <p className="mt4 mb0 leads-settings__filters-title">Users:</p>
            {users}
          </div>
        )}
      </div>
    );
  });

  return (
    <>
      <p className="mt10 mb10 leads-settings__filters-title">Filters</p>
      <p className="mt4 mb2 leads-settings__filters-title">Modules:</p>
      {spinner}
      {isFiltersFetching === false && <div className="leads-settings__filters-list">{content}</div>}
    </>
  );
};

const mapStateToProps = state => ({
  isFiltersFetching: state.leadsReport.get('fetchingAvailableFilters'),
  modules: state.leadsReport.getIn(['filters', 'modules']),
});

const mapActionToProps = {
  getFilters,
  changeFilters,
};

const Filters = connect(mapStateToProps, mapActionToProps)(React.memo(FiltersComponent));

export { Filters };
