import { combineReducers } from 'redux';
import flipPopup from './flipPopup';
import main from './nextAction';

const nextActionReducer = combineReducers({
  flipPopup,
  main,
});

export default nextActionReducer;
