import PropTypes from 'prop-types';

import React from 'react';
import { Map } from 'immutable';

import { joinString } from '../../../utils/string';
import If from '../../helpers/If';
import GeneralResultRow from './GeneralResultRow';

/**
 * Stateless component for company result row.
 *
 * @param props {Object}.
 * @param props.company {String} Company information.
 * @returns {React.Component}
 * @class
 */
const CompanyResultRow = ({ hasAccess, company }) => {
  const statusText = getStatus(company, hasAccess);
  const ownerText = getOwner(company);
  const assignedText = getAssigned(company);
  const type = company.get('type');
  const classColor = type === 'target' ? 'blue' : type === 'buyer' ? 'red' : '';

  return (
    <GeneralResultRow linkTo={`/company/${company.get('id')}/${company.get('type')}`}>
      <div className="search-result__item search-result__item-name">
        <h4>{company.get('companyName')}</h4>
        <p>{company.get('abbreviatedName')}</p>
      </div>
      <div className="search-result__item search-result__item-location">
        <div>{company.get('entityType')}</div>
        <div>{company.get('headQuarters')}</div>
      </div>
      <div className="search-result__item search-result__item-website">{company.get('website')}</div>
      <If value={hasAccess}>
        <div className="search-result__item search-result__item-contact">
          <h4>{company.get('primaryContact')}</h4>
          <p>{company.get('contactTitle')}</p>
        </div>
      </If>
      <div className={`search-result__item search-result__item-target ${classColor}`}>{statusText}</div>
      <div className={`search-result__item search-result__item-account ${classColor}`}>{ownerText}</div>
      <If value={hasAccess}>
        <div className={`search-result__item search-result__item-commentary ${classColor}`}>{assignedText}</div>
      </If>
      <If value={hasAccess}>
        <div className="search-result__item search-result__item-activity">
          <div>
            <span className="na-title">Next action:</span>
            <span>{company.get('nextAction')}</span>
          </div>
          <div>
            <span className="na-title">Last action:</span>
            <span>{company.get('lastAction')}</span>
          </div>
        </div>
      </If>
      <div className="search-result__item search-result__item-category">{company.get('parentCompany')}</div>
    </GeneralResultRow>
  );
};

CompanyResultRow.propTypes = {
  company: PropTypes.instanceOf(Map).isRequired,
};

export default CompanyResultRow;

const getAssigned = company => {
  if (company.get('recordOwnerId')) {
    return company.get('assignedToTarget');
  }

  return null;
};

const getStatus = (company, hasAccess) => {
  const recordOwnerId = company.get('recordOwnerId');
  const buyerType = company.get('buyerType');
  const targetStatus = company
    .get('targetStatus')
    .split('/')
    .map(i => i.trim());
  const buyerStatus = company.get('buyerStatus');

  if (hasAccess) {
    return (
      <div>
        {recordOwnerId ? (
          <div className="text-uppercase target blue">
            TARGET:
            <strong>
              {targetStatus[0]}/ {targetStatus[1]}
            </strong>
          </div>
        ) : null}
        {buyerType ? (
          <div className="text-uppercase buyer red">
            {joinString(' ', buyerType, 'BUYER:')} <strong>{buyerStatus}</strong>
          </div>
        ) : null}
      </div>
    );
  }

  let statuses = null;

  if (recordOwnerId) {
    const statusValue = parseFloat(targetStatus[0]);

    if (statusValue < 2) {
      statuses = (
        <div>
          <div className="text-uppercase target blue">
            <strong>{targetStatus[0]}</strong>
          </div>
          <div className="text-uppercase target blue">
            <strong>{targetStatus[1]}</strong>
          </div>
        </div>
      );
    } else if (statusValue >= 2 && statusValue < 2.99) {
      statuses = <strong>Client No Interest</strong>;
    } else if (statusValue >= 2.99 && statusValue <= 10) {
      statuses = <strong>Research Complete</strong>;
    }
  }

  return statuses;
};

const getOwner = company => {
  if (company.get('recordOwnerId')) {
    return company.get('targetRecordOwner');
  }

  return null;
};
