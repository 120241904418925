import { decamelize } from 'humps';
import moment from 'moment';

import config from '../config';
import { toJS } from '../utils/ChangeSpy';

const researcherFilters = config.browse.getIn(['filter', 'targets', 'researcher', 'filters']);

export function buildRangeParam(props, requestFieldName, fieldName) {
  if (!props.getIn([fieldName, 'checked'])) return {};

  return {
    [requestFieldName]: {
      min: Number(props.getIn([fieldName, 'text', 'min'])),
      max: Number(props.getIn([fieldName, 'text', 'max'])),
    },
  };
}

export function buildDateRangeParam(props, requestFieldName, fieldName) {
  if (!props.getIn([fieldName, 'checked'])) return {};

  let startDate = props.getIn([fieldName, 'min']);
  let endDate = props.getIn([fieldName, 'max']);

  if (startDate) {
    startDate = startDate.format('YYYY-MM-DD');
  } else {
    startDate = null;
  }

  if (endDate) {
    endDate = endDate.format('YYYY-MM-DD');
  } else {
    endDate = null;
  }

  return {
    [requestFieldName]: {
      min: startDate,
      max: endDate,
    },
  };
}

export function buildTagParam(props, requestFieldName, fieldName, key = 'text') {
  if (!props.getIn([fieldName, 'checked'])) return {};

  const selectedList = props.get(fieldName).get('selectedList');
  const bicTag = buildBICParam(props, fieldName, 'selectedTags');
  const bicModel = buildBICParam(props, fieldName, 'selectedModels');
  const industry = fieldName === 'industry' && isIndustryTagChanged(props);

  if (selectedList.size > 0 || industry) {
    const included = selectedList
      .filter(f => !f.get('exclude'))
      .reduce((acc, i) => {
        acc.push(i.get(key));

        return acc;
      }, []);
    const excluded = selectedList
      .filter(f => f.get('exclude'))
      .reduce((acc, i) => {
        acc.push(i.get(key));

        return acc;
      }, []);

    // All `researcher` fields should be in `activityOfResearchers`
    if (fieldName === 'researcher') {
      return bicModel(
        bicTag({
          activityOfResearchers: {
            [requestFieldName]: {
              included,
              excluded,
            },
          },
        }),
      );
    }

    return bicModel(
      bicTag({
        [requestFieldName]: {
          included,
          excluded,
        },
      }),
    );
  }
}

export function buildCheckParam(props, requestFieldName, fieldName) {
  if (!props.get(fieldName)) return {};

  const selectedList = props.getIn(['researcher', 'selectedList']);
  const bicTag = buildBICParam(props, fieldName, 'selectedTags');
  const bicModel = buildBICParam(props, fieldName, 'selectedModels');
  const industry = fieldName === 'industry' && isIndustryTagChanged(props);

  if (selectedList.size > 0 || industry) {
    const included = selectedList
      .filter(f => !f.get('exclude'))
      .reduce((acc, i) => {
        acc.push(i.get('id'));

        return acc;
      }, []);
    const excluded = selectedList
      .filter(f => f.get('exclude'))
      .reduce((acc, i) => {
        acc.push(i.get('id'));

        return acc;
      }, []);

    // All `checkboxes` should be in `activityOfResearchers` as `researchers`
    return bicModel(
      bicTag({
        activityOfResearchers: {
          ...getResearcherFilters(props),
          [fieldName]: true,
          researchers: {
            included,
            excluded,
          },
        },
      }),
    );
  }
}

/**
 * Method returns object with active researchers filters.
 *
 * @param props {Map}.
 * @returns Object.
 */
function getResearcherFilters(props) {
  const filters = {};

  researcherFilters.forEach(filter => {
    const filterIsActive = props.get(filter.get('name'));

    if (filterIsActive) filters[filter.get('name')] = true;
  });

  return filters;
}

function isIndustryTagChanged(props) {
  const selectedModels = props.getIn(['industry', 'selectedModels']);
  const selectedTags = props.getIn(['industry', 'selectedTags']);

  return (selectedModels && selectedModels.size > 0) || (selectedTags && selectedTags.size > 0);
}

function buildBICParam(props, fieldName, key) {
  if (fieldName !== 'industry') {
    return o => o;
  }

  return obj => {
    const empty = {
      included: [],
      excluded: [],
    };
    const selectedList = props.get('industry').toJS()[key] || [];
    const included = Object.entries(selectedList)
      .filter(([_, v]) => !v.exclude)
      .reduce((acc, [_, v]) => {
        acc.push(v.id);

        return acc;
      }, [])
      .concat((obj.industryCategory || empty).included);
    const excluded = Object.entries(selectedList)
      .filter(([_, v]) => v.exclude)
      .reduce((acc, [_, v]) => {
        acc.push(v.id);

        return acc;
      }, [])
      .concat((obj.industryCategory || empty).excluded);

    return Object.assign(obj, {
      industryCategory: {
        included,
        excluded,
      },
    });
  };
}

export function buildDateParam(props, requestFieldName, fieldName) {
  if (!props.getIn([fieldName, 'checked'])) return {};

  const dates = {};

  const startDate = props.getIn([fieldName, 'startDate']);
  const endDate = props.getIn([fieldName, 'endDate']);

  if (startDate && moment.isMoment(startDate)) {
    dates.from = startDate.format('YYYY-MM-DD');
  }

  if (endDate && moment.isMoment(endDate)) {
    dates.to = endDate.format('YYYY-MM-DD');
  }

  return {
    [requestFieldName]: dates,
  };
}

export function buildActionParam(props, requestFieldName, fieldName) {
  if (props.getIn([fieldName, 'checked'])) {
    const selectedList = props.getIn([fieldName, 'selectedList']);

    if (selectedList.size > 0) {
      const ANY_ACTIVITY_PARAM = '(any activity)';
      const ANY_DESCRIPTION_PARAM = '(any description)';

      const actions = selectedList.reduce((acc, curr) => {
        const SPLIT_DIVIDER = ' - ';
        const currentSplit = curr.split(SPLIT_DIVIDER);

        if (currentSplit[0] === ANY_ACTIVITY_PARAM && currentSplit[1] === ANY_DESCRIPTION_PARAM) {
          return acc;
        }

        const action = {};
        const splitLength = currentSplit.length;

        const description = currentSplit[splitLength - 1];
        const activitySplit = currentSplit.slice(0, -1);
        let activity = null;

        // If Filter name contains `SPLIT_DIVIDER` symbol.
        if (activitySplit.length > 1) {
          activity = activitySplit.join(SPLIT_DIVIDER);
        } else {
          activity = activitySplit[0];
        }

        if (activity !== ANY_ACTIVITY_PARAM) action.activity = activity;
        if (description !== ANY_DESCRIPTION_PARAM) action.description = description;

        acc.push(action);

        return acc;
      }, []);

      return {
        [requestFieldName]: actions,
      };
    }
  }

  return {};
}

export function buildActivityParam(props, requestFieldName, fieldName) {
  if (!props.getIn([fieldName, 'checked'])) return {};

  const activity = [];
  const dates = buildDateParam(props, 'action_dates', fieldName);
  const actions = buildActionParam(props, 'activities', fieldName);

  activity.push({ ...dates });

  if (Object.keys(actions).length !== 0) {
    activity.push({ ...actions });
  }

  return {
    [requestFieldName]: activity,
  };
}

export function buildBuyerActivityParam(props, requestFieldName, fieldName) {
  if (!props.getIn([fieldName, 'checked'])) return {};

  const activity = toJS(props.get(fieldName));

  Object.entries(activity).forEach(([key, value]) => {
    if ((value.checked && (value.min === '' || value.max === '')) || !value.checked) {
      delete activity[key];
    } else {
      delete activity[key].checked;
    }
  });

  return {
    [requestFieldName]: activity,
  };
}

export function buildCheckboxArrayParam(props, requestFieldName, fieldName, valueField) {
  if (!props.getIn([fieldName, 'checked'])) return {};

  const arr = props.get(fieldName).reduce((acc, val, key) => {
    if (key === 'checked') {
      return acc;
    }

    if (val.get('checked') === true) {
      acc.push(val.get(valueField));

      return acc;
    }

    return acc;
  }, []);

  if (arr.length === 0) {
    return {};
  }

  return {
    [requestFieldName]: arr,
  };
}

export function buildCheckboxObjectParam(props, requestFieldName, fieldName) {
  if (!props.getIn([fieldName, 'checked'])) return {};

  const arr = props.get(fieldName).reduce((acc, val, key) => {
    if (key === 'checked') {
      return acc;
    }

    if (val.get('checked') === true) {
      acc[key] = true;

      return acc;
    }

    return acc;
  }, {});

  return {
    [requestFieldName]: arr,
  };
}

export function buildSortByParam(props) {
  const sortBy = props.getIn(['sortBy', 'sortModel']).reduce((acc, i) => {
    if (i.get('sort') === 'desc') {
      acc += '-';
    }
    acc += decamelize(i.get('colId'));

    return acc;
  }, '');

  return sortBy;
}

export function buildDropdownParam(props, requestFieldName, fieldName) {
  if (!props.getIn([fieldName, 'checked'])) return {};

  return {
    [requestFieldName]: props.getIn([fieldName, 'selected', 'value']) === 'Yes',
  };
}

export function buildUploadParam(props, requestFieldName, fieldName) {
  if (!props.getIn([fieldName, 'checked']) || !props.getIn([fieldName, 'data']).size) return {};

  return {
    [requestFieldName]: Array.from(props.getIn([fieldName, 'data'])).map(item => toJS(item)),
  };
}
