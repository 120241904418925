import { combineReducers } from 'redux';
import info from './companyInfo';
import events from './companyEvents';
import target from './companyTarget';
import targetCreate from './targetCreate';
import buyerCreate from './buyerCreate';
import executive from './companyExecutive';
import lp from './companyLP';
import buyer from './companyBuyer';
import mergeInfo from './mergeInfo';

const companyReducer = combineReducers({
  targetCreate,
  buyerCreate,
  target,
  info,
  events,
  executive,
  lp,
  buyer,
  mergeInfo,
});

export default companyReducer;
