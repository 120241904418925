import PropTypes from 'prop-types';
import React from 'react';

/**
 * NullAsEmptyString decorator.
 *
 * @param Component {React.Component}.
 * @class
 */
export default function NullAsEmptyString(Component) {
  /**
   * NullAsEmptyString decorated.
   *
   * @param props Any extra props will passed into children.
   * @param props.value {*} Should be null to replace it by this decorator.
   * @class
   */
  const NullAsEmptyStringDecorated = props => {
    const { value, ...rest } = props;

    if (value === null) {
      return <Component {...rest} value="" />;
    }

    return <Component {...props} />;
  };

  NullAsEmptyStringDecorated.displayName = `NullAsEmptyString(${Component.displayName || Component.name})`;

  NullAsEmptyStringDecorated.propTypes = {
    value: PropTypes.any,
  };

  return NullAsEmptyStringDecorated;
}
