import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import connectOptions, { mergeProps } from '../utils/connectOptions';
import { removeRuntimeError, addRuntimeError } from '../actions/applicationErrors';

/**
 * Wrapper that displays Runtime's errors.
 *
 * @augments React.Component
 */
class RuntimeErrorDisplayWrapper extends PureComponent {
  componentDidMount() {
    if (this.props.global) {
      window.addEventListener('error', error => {
        if (
          error.message === 'ResizeObserver loop completed with undelivered notifications.' ||
          error.message === 'ResizeObserver loop limit exceeded'
        ) {
          error.stopImmediatePropagation();
        } else {
          this.props.addRuntimeError({ error });
        }
      });
    }
  }

  componentDidUpdate() {
    const { errors, onDevOnly, removeRuntimeError } = this.props;

    if (errors.size === 0) return;
    if (this.context.hasPopup()) return;

    const error = errors.get(0);

    if (onDevOnly) {
      if (process.env.NODE_ENV === 'production') {
        return removeRuntimeError({ error });
      }
    }
    this.openPopup(error);
  }

  openPopup(error) {
    this.context.openPopup('RuntimeErrorPopup', {
      error,
    });
    this.context.onClosePopup(() => {
      this.onClose(error);
    });
  }

  onClose(error) {
    this.props.removeRuntimeError({ error });
  }

  /**
   * Render function.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { children } = this.props;

    return <div className="full-height">{children}</div>;
  }
}

RuntimeErrorDisplayWrapper.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  hasPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
};

/**
 * Map application's state to appState property.
 *
 * @param state {Object} Application's state.
 * @returns {{appState: object}}
 */
function mapStateToProps(state) {
  return {
    errors: state.applicationErrors.get('runtime'),
  };
}

export { RuntimeErrorDisplayWrapper };
export default connect(
  mapStateToProps,
  { removeRuntimeError, addRuntimeError },
  mergeProps,
  connectOptions,
)(RuntimeErrorDisplayWrapper);
