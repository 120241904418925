import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fromJS, List, Map } from 'immutable';
import { debounce, bindAll } from 'underscore';

import AutoCompleteContainer from '../AutoComplete';
import {
  clearUsers,
  getUserSuggestionsByRole,
  getUserSuggestions,
  getWindwardAllTemplates,
} from '../../actions/popup/windwardReportsPopup';
import RunWindwardReportPopup from '../../components/Popup/RunWindwardReportPopup';
import config from '../../config';

const defaultSuggests = fromJS({
  firstDealMaker: {
    suggestText: '',
    suggestId: null,
    suggests: List(),
  },
  secondDealMaker: {
    suggestText: '',
    suggestId: null,
    suggests: List(),
  },
  researchAnalyst: {
    suggestText: '',
    suggestId: null,
    suggests: List(),
  },
});

class RunWindwardReportPopupContainer extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      popupLoading: false,
      module: {
        suggestText: '',
        suggestId: null,
        suggests: List(),
      },
      suggestsParams: defaultSuggests,
      templates: List(),
      selectedTemplateType: '',
      templateParams: Map(),
    };
    bindAll(
      this,
      'handleClose',
      'handleRunWindwardReport',
      'handleUpdateCheckboxField',
      'handleUpdateRadioField',
      'handleSelectReport',
    );
    this.handleGetModuleSuggestions = debounce(this.handleGetModuleSuggestions.bind(this), 400);
    this.handleGetUserSuggestions = debounce(this.handleGetUserSuggestions.bind(this), 400);
  }

  componentDidMount() {
    this.getTemplatesList();
  }

  getTemplatesList() {
    this.setState({ popupLoading: true });
    this.props.getWindwardAllTemplates().then(response => {
      this.setState({ popupLoading: false });
      if (!response || !response.data) {
        return;
      }

      const filteredList = response.data.map(item => {
        const checkboxFields = [];
        const suggestedFields = [];
        const radioFields = [];
        const fields = Object.values(item.fields);

        fields.forEach(field => {
          switch (field.typeField) {
            case 'checkbox':
              checkboxFields.push({
                name: field.name,
                title: field.text,
              });
              break;

            case 'dropdown':
              suggestedFields.push({
                name: field.name,
                title: field.text,
              });
              break;

            case 'radiobutton':
              radioFields.push({
                name: field.name,
                title: field.text,
                values: field.values.map(value => ({
                  name: value.name,
                  title: value.text,
                })),
              });
              break;

            default:
              break;
          }
        });

        return {
          name: item.templateType,
          title: item.templateText,
          checkboxFields,
          suggestedFields,
          radioFields,
        };
      });

      const templatesList = fromJS(filteredList);

      if (templatesList && templatesList.size > 0) {
        this.setState({
          templates: templatesList,
          selectedTemplateType: templatesList.get(0).get('name'),
        });
      }
    });
  }

  handleClose() {
    this.context.closePopup();
  }

  handleRunWindwardReport() {
    const selectedUserId = this.state.module.suggestId;
    const { selectedTemplateType } = this.state;
    const templateParams = Object.values(this.state.templateParams.toJS());
    const runWindward = this.props.popup.getIn(['props', 'callback']);

    this.setState({ popupLoading: true });
    runWindward(selectedUserId, selectedTemplateType, templateParams);
  }

  handleModuleSuggestionsChange({ name, value }) {
    this.setState(prevState => ({
      module: {
        ...prevState.module,
        [name]: value,
      },
    }));
  }

  handleUserSuggestionsChange(key, { name, value }) {
    if (name === 'suggestId') {
      this.setState(prevState => ({ templateParams: prevState.templateParams.set(key, Map({ field: key, value })) }));
    }
    this.setState(prevState => ({ suggestsParams: prevState.suggestsParams.setIn([key, name], value) }));
  }

  handleGetModuleSuggestions(value) {
    this.props.getModuleSuggestion(value).then(response => {
      if (!response || !response.data) {
        return;
      }

      if (response.meta.pagination.currentPage === 1) {
        this.setState(prevState => ({
          module: {
            ...prevState.module,
            suggests: fromJS([...response.data]),
          },
        }));
      } else {
        this.setState(prevState => ({
          module: {
            ...prevState.module,
            suggests: fromJS(prevState.module.suggests.concat([...response.data])),
          },
        }));
      }
    });
  }

  handleGetUserSuggestions(key, value) {
    let find;

    switch (key) {
      case 'firstDealMaker':
        find = this.props.getAnalystSuggestions;
        break;

      case 'secondDealMaker':
        find = this.props.getUserSuggestions;
        break;

      case 'researchAnalyst':
        find = this.props.getUserSuggestions;
        break;

      default:
        return;
    }

    find(value).then(response => {
      if (!response || !response.data) {
        return;
      }

      if (response.meta.pagination.currentPage === 1) {
        this.setState(prevState => ({
          suggestsParams: prevState.suggestsParams.setIn([key, 'suggests'], fromJS([...response.data])),
        }));
      } else {
        this.setState(prevState => ({
          suggestsParams: prevState.suggestsParams.setIn(
            [key, 'suggests'],
            fromJS(prevState.suggestsParams.getIn([key, 'suggests']).concat([...response.data])),
          ),
        }));
      }
    });
  }

  handleUpdateCheckboxField({ target: { name, checked } }) {
    const { templateParams } = this.state;

    if (checked === true) {
      this.setState({ templateParams: templateParams.set(name, Map({ field: name, value: checked })) });
    } else {
      this.setState({ templateParams: templateParams.delete(name) });
    }
  }

  handleUpdateRadioField({ target: { name, value } }) {
    const { templateParams } = this.state;

    this.setState({ templateParams: templateParams.set(name, Map({ field: value, value: true })) });
  }

  getSuggest(key) {
    return (
      <AutoCompleteContainer
        change={value => this.handleUserSuggestionsChange(key, value)}
        find={value => this.handleGetUserSuggestions(key, value)}
        focusInputOnSuggestionClick={false}
        idName="suggestId"
        idValue={this.state.suggestsParams.getIn([key, 'suggestId'])}
        suggestions={this.state.suggestsParams.getIn([key, 'suggests'])}
        suggestsName="suggests"
        value={this.state.suggestsParams.getIn([key, 'suggestText'])}
        valueName="suggestText"
        suggestIfEmpty
      />
    );
  }

  handleSelectReport({ target: { value } }) {
    this.setState({
      selectedTemplateType: value,
      suggestsParams: defaultSuggests,
      templateParams: Map(),
    });
  }

  render() {
    const module = (
      <AutoCompleteContainer
        change={value => this.handleModuleSuggestionsChange(value)}
        find={value => this.handleGetModuleSuggestions(value)}
        focusInputOnSuggestionClick={false}
        idName="suggestId"
        idValue={this.state.module.suggestId}
        suggestions={this.state.module.suggests}
        suggestsName="suggests"
        value={this.state.module.suggestText}
        valueName="suggestText"
        suggestIfEmpty
      />
    );

    const firstDealMaker = this.getSuggest('firstDealMaker');
    const secondDealMaker = this.getSuggest('secondDealMaker');
    const researchAnalyst = this.getSuggest('researchAnalyst');

    const moduleProps = {
      module,
    };

    const fieldsProps = {
      firstDealMaker,
      secondDealMaker,
      researchAnalyst,
      onCheckboxFieldUpdate: this.handleUpdateCheckboxField,
      onRadioFieldUpdate: this.handleUpdateRadioField,
    };

    const windwardProps = {
      templates: this.state.templates,
      selectedTemplateType: this.state.selectedTemplateType,
      onSelectReport: this.handleSelectReport,
    };

    const extraProps = {
      popupLoading: this.state.popupLoading,
      onClose: this.handleClose,
      onRunWindwardReport: this.handleRunWindwardReport,
      isDisabledRunButton: this.state.module.suggestId === null || this.state.selectedTemplateType === '',
    };

    return <RunWindwardReportPopup {...extraProps} {...windwardProps} {...moduleProps} {...fieldsProps} />;
  }
}

RunWindwardReportPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    popupState: state.windwardReportsPopup,
  };
}

const mapDispatchToProps = dispatch => ({
  getModuleSuggestion: value => dispatch(getUserSuggestionsByRole(value, config.DIRECTOR)),
  getAnalystSuggestions: value => dispatch(getUserSuggestionsByRole(value, config.ANALYST_ASSOCIATE)),
  clearSuggests: () => dispatch(clearUsers()),
  ...bindActionCreators({ getWindwardAllTemplates, getUserSuggestions }, dispatch),
});

export { RunWindwardReportPopupContainer };
export default connect(mapStateToProps, mapDispatchToProps)(RunWindwardReportPopupContainer);
