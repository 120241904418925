import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fromJS } from 'immutable';

import { fetchBuyerStatuses } from '../../actions/statuses';
import { findDirectors, findUsers, clearUsers } from '../../actions/company/companyTarget';
import { companyInfo } from '../../selectors/targetCompany';
import { buyerStatuses } from '../../selectors/statuses';
import Popups from '../../components/Popup';

const ConflictReportPopup = _props => {
  const dispatch = useDispatch();

  const companyInfoData = useSelector(companyInfo);
  const statuses = useSelector(buyerStatuses);

  useEffect(() => {
    dispatch(fetchBuyerStatuses());
  }, []);

  /**
   * Find directors by search pattern.
   *
   * @param {string} pattern Search pattern.
   */
  const findDirectorsByParams = pattern => {
    dispatch(findDirectors(pattern));
  };

  /**
   * Find users by search pattern.
   *
   * @param {string} pattern Search pattern.
   */
  const findUsersByParams = pattern => {
    dispatch(findUsers(pattern));
  };

  /** Clear users list. */
  const clearUsersList = () => {
    dispatch(clearUsers());
  };

  return (
    <Popups.ConflictReportPopup
      clearUsers={clearUsersList}
      companyInfoData={companyInfoData}
      findDirectors={findDirectorsByParams}
      findUsers={findUsersByParams}
      statuses={fromJS(statuses)}
    />
  );
};

ConflictReportPopup.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
};

export default ConflictReportPopup;
