import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import ContactBuyer from '../../components/Contact/ContactBuyer';
import { loadContactBuyer } from '../../actions/contact/contactBuyer';

import config from '../../config';

const columns = config.tables.getIn(['contactBuyer', 'columns']);

/**
 * ContactBuyers tab container.
 *
 * @param props {Object}.
 * @param props.buyers {Immutable.List} Container get this from app's state.
 * It is list of buyers to display.
 * @param props.columns {Immutable.List} Container get this from app's config.
 * It is a meta info about table's columns.
 * @param loadContactBuyer {Function} Action creator to load buyer list for contact.
 */
class ContactBuyerContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onDoubleClick = this.onDoubleClick.bind(this);
  }

  /**
   * Before component will mount request buyers data.
   */
  UNSAFE_componentWillMount() {
    const { contactId } = this.props.match.params;

    this.props.loadContactBuyer({ id: contactId });
  }

  /**
   * On table row DoubleClick open company page.
   *
   * @param e {React.SyntheticEvent}.
   * @param index {Number} Row indexs.
   */
  onDoubleClick(e, index) {
    const companyId = this.props.buyersState.getIn(['buyers', index, 'entityId']);

    window.open(`/company/${companyId}`);
  }

  render() {
    const { buyersState, ...rest } = this.props;
    const loading = buyersState.get('loading');
    const buyers = buyersState.get('buyers');

    return <ContactBuyer {...rest} buyers={buyers} loading={loading} onDoubleClick={this.onDoubleClick} />;
  }
}

function mapStateToProps(state) {
  return {
    buyersState: state.contact.buyers,
    columns,
  };
}

ContactBuyerContainer.propTypes = {
  buyersState: PropTypes.instanceOf(Map).isRequired,
  columns: PropTypes.instanceOf(List).isRequired,
  loadContactBuyer: PropTypes.func.isRequired,
};

export { ContactBuyerContainer };
export default connect(mapStateToProps, { loadContactBuyer }, mergeProps, connectOptions)(ContactBuyerContainer);
