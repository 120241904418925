import { List } from 'immutable';

/**
 * Get error list from error response.
 *
 * @param response {Object} Error response object.
 * @returns {Immutable.List}
 */
export default function getErrorResponse(response) {
  const { errors } = response;
  let ret = [];

  if (errors) {
    for (const prop in errors) {
      if (errors.hasOwnProperty(prop)) {
        ret = ret.concat(errors[prop]);
      }
    }
  } else {
    ret = [response.message];
  }

  return List(ret);
}
