import PropTypes from 'prop-types';
import React from 'react';
import { List, Map } from 'immutable';
import classNames from 'classnames';

import Spinner from '../../helpers/Spinner';

/**
 * Side menu component.
 *
 * @param props {Object}.
 * @param props.approvals {Immutable.List} List of approval.
 * @param props.common {Immutable.Map} Common data.
 * @param props.onClick {String} Handle event of clicking on a menu item.
 * @param {boolean} props.isNoApproval No approval list selected state.
 * @returns {React.Component}
 * @class
 */
const Menu = props => {
  const { approvals, common, onClick, isNoApproval } = props;
  const approval = common.get('approval');
  const loading = common.get('appListLoading');
  const noApproval = common.get('noApproval');
  const id = approval.get('id');
  const spinner = loading ? <Spinner className="bg-white" /> : null;

  const renderNoApprovalItem = noApproval.size > 0 && (
    <li>
      <a
        className={classNames({ active: isNoApproval })}
        onClick={() => onClick('no_approval_lists')}
        role="button"
        tabIndex={0}
      >
        (No Approval List)
      </a>
    </li>
  );

  const content = !loading ? (
    <ul>
      {approvals.map((ap, i) => (
        <li key={i}>
          <a className={classNames({ active: ap.get('id') === id })} onClick={() => onClick(ap.get('id'))}>
            {ap.get('label')}
          </a>
        </li>
      ))}
      {renderNoApprovalItem}
    </ul>
  ) : null;

  return (
    <div className="left-menu">
      {spinner}
      {content}
    </div>
  );
};

Menu.propTypes = {
  approvals: PropTypes.instanceOf(List).isRequired,
  common: PropTypes.instanceOf(Map).isRequired,
  isNoApproval: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Menu;
