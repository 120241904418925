import { BUSINESS_MODEL_ERROR, INDUSTRY_CATEGORY_ERROR } from './industryTagsHelper';

const NAME_ERROR = 'Name field is required.';
const ABBR_ERROR = 'Abbreviated name field is required.';
const USERS_ERROR = 'Users are required (at least one).';
const DESCRIPTION_ERROR = 'Description is required.';

export function createTradeShowErrorMessages(errors) {
  let errorsArr = [];

  errorsArr = errors.nameError ? errorsArr.concat(NAME_ERROR) : errorsArr;
  errorsArr = errors.abbrError ? errorsArr.concat(ABBR_ERROR) : errorsArr;
  errorsArr = errors.descriptionError ? errorsArr.concat(DESCRIPTION_ERROR) : errorsArr;
  errorsArr = errors.industryCategoriesError ? errorsArr.concat(INDUSTRY_CATEGORY_ERROR) : errorsArr;
  errorsArr = errors.businessModelsError ? errorsArr.concat(BUSINESS_MODEL_ERROR) : errorsArr;
  errorsArr = errors.usersError ? errorsArr.concat(USERS_ERROR) : errorsArr;

  return errorsArr;
}
