import React from 'react';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Button from '../helpers/Button';
import _Input from '../helpers/Input';
import ErrorDisplay from '../decorators/DisplayError';
import PrettyError from '../decorators/PrettyError';

const Input = PrettyError(ErrorDisplay(_Input));

const EditFiscalYearPopup = props => {
  const { isNew, onChange, year, isValid, onSave } = props;

  const prefix = isNew ? 'Create' : 'Change';
  const header = `${prefix} fiscal year`;
  const footer = (
    <Button className="btn-primary btn-xs" disabled={!isValid} onClick={onSave}>
      {isNew ? 'Create' : 'Save'}
    </Button>
  );

  return (
    <Popup footer={footer} header={header} id="newExecProf">
      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-md"
            label="Year"
            name="fiscalYear"
            onChange={onChange}
            placeholder="Year"
            root={year}
            autoFocus
            deep
            showLabel
          />
        </FormGroup>
      </div>
    </Popup>
  );
};

export default EditFiscalYearPopup;
