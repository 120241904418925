import React from 'react';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Input from '../helpers/Input';
import Button from '../helpers/Button';
import DropDown from '../helpers/DropDown';

const AddContactPopup = props => {
  const { isLp = false, isExecutive = false } = props;

  let header = 'Create new contact';

  if (isLp) {
    header = 'Create new LP profile';
  } else if (isExecutive) {
    header = 'Create new Executive profile';
  }

  const footer = [
    <Button key="create" className="btn-primary btn-xs" data-dismiss="modal">
      Create
    </Button>,
    <Button key="cancel" className="btn-default btn-xs" data-dismiss="modal">
      Cancel
    </Button>,
  ];

  return (
    <Popup footer={footer} header={header} id="newAddOne">
      <div className="row media-450">
        <FormGroup className="col-lg-3 col-sm-3 col-xs-4">
          <label className="control-label" htmlFor="w-full-name3">
            Full Name
          </label>
          <DropDown
            btnContent="Dropdown"
            className="btn-default btn-xs"
            id="dropdownMenu3"
            wrapperClassName="ib"
            autoFocus
          >
            {/* TODO append dropdown content */}
          </DropDown>
        </FormGroup>

        <FormGroup className="col-lg-6 col-sm-6 col-xs-4 ml-20">
          <Input
            className="input-sm"
            id="w-full-name3"
            label="Full Name"
            name="project"
            placeholder="Full Name"
            required
          />
        </FormGroup>

        <FormGroup className="col-lg-3 col-sm-3 col-xs-4">
          <DropDown btnContent="Dropdown" className="btn-default btn-xs" id="dropdownMenu4" wrapperClassName="ib">
            {/* TODO append dropdown content */}
          </DropDown>
        </FormGroup>
      </div>
      <div className="clearfix" />
      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-sm"
            id="f-first-name3"
            label="First Name"
            name="firstName"
            placeholder="First Name"
            required
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-sm"
            id="f-last-name-3"
            label="Last Name"
            name="lastName"
            placeholder="Last Name"
            required
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Input className="input-sm" id="f-nick3" label="Nick" name="nick" placeholder="Nick" required showLabel />
        </FormGroup>

        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-sm"
            id="f-pronunciation3"
            label="Pronunciation"
            name="pronunciation"
            placeholder="Pronunciation"
            required
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row">
        <FormGroup className="col-lg-2 col-sm-2">
          <label className="control-label" htmlFor="dropdownMenu5">
            Title
          </label>
          <DropDown btnContent="Dropdown" className="btn-default btn-xs" id="dropdownMenu5" wrapperClassName="ib">
            {/* TODO append dropdown content */}
          </DropDown>
        </FormGroup>
        <FormGroup className="col-lg-5 col-sm-5">
          <Input className="input-sm" id="f-phone" label="Phone" name="phone" placeholder="Phone" required showLabel />
        </FormGroup>

        <FormGroup className="col-lg-5 col-sm-5">
          <Input
            className="input-sm"
            id="f-email3"
            label="Email"
            name="email"
            placeholder="Email"
            type="email"
            required
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row">
        <FormGroup className="col-lg-12 col-sm-12">
          <label className="control-label" htmlFor="c-Notes">
            Notes:
          </label>
          <textarea className="form-control" id="c-Notes" rows="4" />
        </FormGroup>
      </div>
    </Popup>
  );
};

export default AddContactPopup;
