import { combineReducers } from 'redux';
import marketing from './buyer/marketing';
import leads from './buyer/leads';
import criteria from './buyer/criteria';
import projects from './buyer/projects';
import addOnProject from './buyer/addOnProject';
import fund from './buyer/fund';
import retainer from './buyer/retainer';
import ceo from './buyer/ceo';
import info from './buyer/info';
import roleCreating from './buyer/roleCreating';

const companyReducer = combineReducers({
  roleCreating,
  info,
  marketing,
  leads,
  criteria,
  addOnProject,
  projects,
  fund,
  retainer,
  ceo,
});

export default companyReducer;
