import { CALL_API } from '../../../middleware/api';

export const NDA_REPORT_REQUEST = Symbol('NDA_REPORT_REQUEST');

/**
 * Send NDA request action.
 *
 * @param {object} body Request body.
 * @param {boolean} body.isNdaClient Is NDA client type request flag.
 * @param {number} body.companyId Company id.
 * @param {number} body.buyerId Buyer id.
 * @param {number} body.directorId Director id.
 * @param {Function} afterSuccess Callback after success request.
 */
export function ndaReportRequest({ isNdaClient, companyId, buyerId, directorId }, afterSuccess, afterError) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/windward/nda_report',
      body: {
        isNdaClient,
        companyId,
        buyerId,
        directorId,
      },
      afterSuccess,
      afterError,
    },
  };
}
