import { fromJS } from 'immutable';
import {
  LOADED_INDUSTRY_TAGS_SUCCESS,
  LOADED_INDUSTRY_TAGS_FAIL,
  LOADING_INDUSTRY_TAGS,
  INDUSTRY_TAG_SELECTION,
  INDUSTRY_TAG_SELECTIONS,
  TOGGLE_TAG_LIST,
  RESET_TAG_LIST,
} from '../actions/industryTag';

const defaultState = fromJS({
  tags: [],
  __tags: [],
  loading: false,
  saving: false,
});

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOADED_INDUSTRY_TAGS_SUCCESS: {
      const __tags = originTags(action.response.data);

      return state.merge({
        tags: mapTags(action.response.data, action.selectedTags),
        __tags,
        loading: false,
      });
    }

    case LOADED_INDUSTRY_TAGS_FAIL:
      return state.set('loading', false);

    case LOADING_INDUSTRY_TAGS:
      return state.set('loading', true);

    case INDUSTRY_TAG_SELECTIONS:
      return state.set('tags', selectTags(state.get('__tags'), action.ids));

    case INDUSTRY_TAG_SELECTION: {
      const tags = state.get('tags');

      // If has parent Id, update child
      if (action.parentId) {
        return state.set('tags', updateChildren(tags, action.parentId, action.id));
      }

      return state.set('tags', updateParent(tags, action.id));
    }

    case TOGGLE_TAG_LIST: {
      let tags = state.get('tags');
      const index = tags.findIndex(t => t.get('id') === action.id);

      if (index > -1) {
        const collapsed = !tags.getIn([index, 'collapsed']);

        tags = tags.map(t => {
          if (action.ids.indexOf(t.get('id')) > -1) {
            return t.set('collapsed', collapsed);
          }

          return t;
        });
      }

      return state.set('tags', tags);
    }

    case RESET_TAG_LIST: {
      if (action.previousTags) {
        return state.set('tags', action.previousTags);
      }

      return state.set('tags', state.get('__tags'));
    }

    default:
      return state;
  }
};

/**
 * Handle tags list from API.
 *
 * @param {Array} tags List of tag from API.
 * @param {object} ids List of selected tags.
 */
const mapTags = (tags, ids) =>
  fromJS(
    tags.map(t => {
      t.collapsed = true;
      t.checked = ids[t.id];
      t.children = t.children.map(c => {
        c.checked = ids[c.id];

        return c;
      });

      return t;
    }),
  );

const originTags = tags =>
  fromJS(
    tags.map(t => {
      t.collapsed = true;

      return t;
    }),
  );

const updateParent = (tags, id) => {
  const index = tags.findIndex(t => t.get('id') === id);

  if (index > -1) {
    const checked = !tags.getIn([index, 'checked']);

    return tags.setIn([index, 'checked'], checked);
  }

  return tags;
};

const updateChildren = (tags, parentId, id) => {
  const index = tags.findIndex(t => t.get('id') === parentId);

  if (index > -1) {
    const subIndex = tags.getIn([index, 'children']).findIndex(s => s.get('id') === id);

    if (subIndex > -1) {
      const checked = !tags.getIn([index, 'children', subIndex, 'checked']);

      if (checked) {
        tags = tags.setIn([index, 'checked'], true);
      }

      return tags.setIn([index, 'children', subIndex, 'checked'], checked);
    }
  }

  return tags;
};

const selectTags = (tags, ids) => {
  const tmp = tags.toJS();

  for (let i = 0; i < tmp.length; i++) {
    if (ids[tmp[i].id]) {
      tmp[i].checked = true;
      if (tmp[i].children && tmp[i].children.length) {
        for (let j = 0; j < tmp[i].children.length; j++) {
          if (ids[tmp[i].children[j].id]) {
            tmp[i].children[j].checked = true;
          }
        }
      }
    }
  }

  return fromJS(tmp);
};

export default reducer;
