import { fromJS } from 'immutable';

import {
  FETCHING_PROJECTS_APPROVAL_LISTS,
  FETCHING_PROJECTS_APPROVAL_LISTS_SUCCESS,
  FETCHING_PROJECTS_APPROVAL_LISTS_FAILURE,
} from '../../../../actions/company/buyer/addOnProject/addOnProjectCreate';

const defaultState = fromJS({
  list: [],
  loading: false,
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case FETCHING_PROJECTS_APPROVAL_LISTS:
      return state.merge({
        loading: true,
        list: [],
      });

    case FETCHING_PROJECTS_APPROVAL_LISTS_SUCCESS:
      return state.merge({
        loading: false,
        list: action.response.data,
      });

    case FETCHING_PROJECTS_APPROVAL_LISTS_FAILURE:
      return state.set('loading', false);

    default:
      return state;
  }
}
