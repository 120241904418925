import { fromJS, List } from 'immutable';
import {
  LOADED_SUGGESTIONS,
  SET_SUGGESTIONS,
  ERROR_LOADED_SUGGESTIONS,
  LOADING_SUGGESTIONS,
} from '../../../../actions/company/buyer/addOnProject/suggestion';

const defaultState = fromJS({
  user: {
    list: [],
    loading: false,
  },
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case SET_SUGGESTIONS:
      return state.setIn(action.path.split('.'), action.value);

    case LOADED_SUGGESTIONS:
      return state.setIn([action.path, 'list'], List(action.response.data)).setIn([action.path, 'loading'], false);

    case LOADING_SUGGESTIONS:
      return state.setIn([action.path, 'loading'], true);

    case ERROR_LOADED_SUGGESTIONS:
      return state.setIn([action.path, 'loading'], false);

    default:
      return state;
  }
}
