import { fromJS } from 'immutable';
import * as ActionType from '../actions/autoComplete';

const defaultState = fromJS({});

const emptyCounter = fromJS({
  page: 1,
  loading: false,
  hasOther: true,
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case ActionType.AUTOCOMPL_APPEND_COUNTER:
      return state.set(action.id, emptyCounter);

    case ActionType.AUTOCOMPL_DELETE_COUNTER:
      return state.delete(action.id);

    case ActionType.AUTOCOMPL_FETCHING:
      return state.mergeDeep({
        [action.id]: {
          page: state.getIn([action.id, 'page']) + 1,
          loading: true,
          hasOther: false,
        },
      });

    case ActionType.AUTOCOMPL_FETCHED:
      return state.mergeDeep({
        [action.id]: {
          hasOther: hasOther(action),
          loading: false,
        },
      });

    case ActionType.AUTOCOMPL_REFRESH_COUNTER:
      return state.set(action.id, emptyCounter);

    default:
      return state;
  }
}

function hasOther(action) {
  let res;

  try {
    const { meta } = action.response;
    const { pagination } = meta;
    const { currentPage, totalPages } = pagination;

    res = currentPage < totalPages;
  } catch (e) {
    res = false;
  }

  return res;
}
