import * as React from 'react';
import config from '../config';

function hasPermission(loggedUser) {
  const hasPermission = [
    config.ADMINISTRATOR,
    config.PROCESS_MANAGER,
    config.DIRECTOR,
    config.ANALYST_ASSOCIATE,
    config.FINANCE_ADMIN,
    config.JUNIOR_ANALYST,
  ];

  return loggedUser.get('roles').some(role => hasPermission.includes(role.get('slug')));
}

export default function checkActionPermission(loggedUser, func, context) {
  return function(...rest) {
    if (hasPermission(loggedUser)) {
      func(...rest);
    } else {
      context.openPopup('InformationPopup', {
        message: {
          texts: ['You do not have permission for this action.'],
          className: 'text-danger',
        },
        header: (
          <span>
            <i aria-hidden="true" className="fa fa-exclamation-triangle text-danger" />
            Error Alert
          </span>
        ),
      });
    }
  };
}
