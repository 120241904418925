import { CALL_API } from '../../middleware/api';
import config from '../../config';
import uniqueId from '../../utils/uniqueId';

export const SHOW_USERS_LOADING = Symbol('SHOW_USERS_LOADING');
export const REQUEST_API_USERS_FAILURE = Symbol('REQUEST_API_USERS_FAILURE');
export const LOADED_USERS_SUCCESS = Symbol('LOADED_USERS_SUCCESS');
export const LOADING_USERS_SUGGESTION = Symbol('LOADING_USERS_SUGGESTION');
export const LOADED_USERS_SUGGESTIONS_SUCCESS = Symbol('LOADED_USERS_SUGGESTIONS_SUCCESS');
export const INSERT_USER_ROW = Symbol('INSERT_USER_ROW');
export const CHANGE_USER_ROW_MODE = Symbol('CHANGE_USER_ROW_MODE');
export const CHANGE_USER_ROW_TEXT = Symbol('CHANGE_USER_ROW_TEXT');
export const DELETED_USER_ROW_SUCCESS = Symbol('DELETED_USER_ROW_SUCCESS');
export const SELECT_USER_SUGGESTION = Symbol('SELECT_USER_SUGGESTION');
export const SAVED_USERS_SUCCESS = Symbol('SAVED_USERS_SUCCESS');

/**
 * Save users in user table.
 *
 * @returns Action.
 */
export function saveUsers() {
  return (dispatch, getState) => {
    const state = getState().project;
    const projectId = state.projectData.get('id');
    const project_users = state.users
      .filter(u => u.get('id') > 0)
      .map(u => u.get('id'))
      .toJS();

    dispatch({
      [CALL_API]: {
        method: 'post',
        path: `/api/v1/projects/${projectId}/users`,
        body: { project_users },
        startType: SHOW_USERS_LOADING,
        errorType: REQUEST_API_USERS_FAILURE,
        successType: SAVED_USERS_SUCCESS,
      },
    });
  };
}

/**
 * Load user for users grid.
 *
 * @param id {Number} Project's id.
 * @returns Action.
 */
export function loadUsers(id) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/${id}`,
      query: { include: 'users' },
      startType: SHOW_USERS_LOADING,
      errorType: REQUEST_API_USERS_FAILURE,
      successType: LOADED_USERS_SUCCESS,
    },
  };
}

/**
 * Insert a new user row into users grid.
 *
 * @returns Action.
 */
export function insertUserRow() {
  return {
    type: INSERT_USER_ROW,
  };
}

/**
 * Swith a user row to edit mode.
 *
 * @param id {Number} Row id.
 * @returns Action.
 */
export function switchUserRowToEdit(id) {
  return {
    type: CHANGE_USER_ROW_MODE,
    id,
    mode: config.EDIT_MODE,
  };
}

/**
 * Switch a user row to loading mode.
 *
 * @returns Action.
 */
export function switchUserRowToLoading(id) {
  return {
    type: CHANGE_USER_ROW_MODE,
    id,
    mode: config.LOADING_MODE,
  };
}

/**
 * Switch a user row to suggest mode.
 *
 * @returns Action.
 */
export function switchtUserRowToSuggest(id) {
  return {
    type: CHANGE_USER_ROW_MODE,
    id,
    mode: config.SUGGEST_MODE,
  };
}

/**
 * Load user's suggestion list.
 *
 * @param id {Number} User's id.
 * @param text {Number} Inputted text.
 * @returns Action.
 */
const loadUsersSuggestion = (id, text) => ({
  [CALL_API]: {
    method: 'get',
    path: '/api/v1/browse/users',
    query: { like: text },
    startType: LOADING_USERS_SUGGESTION,
    successType: LOADED_USERS_SUGGESTIONS_SUCCESS,
  },
  requestId: uniqueId(),
  id,
});

/**
 * Action for updating user's text input and searching for user's suggestion.
 *
 * @returns Action.
 */
export function updateUserText(id, text) {
  return dispatch => {
    dispatch({
      type: CHANGE_USER_ROW_TEXT,
      id,
      text,
    });
    if (text && text.length >= config.NUMBER_OF_MIN_CHARACTER) {
      dispatch(switchUserRowToLoading(id));
      dispatch(loadUsersSuggestion(id, text, switchtUserRowToSuggest));
    } else {
      dispatch(switchUserRowToEdit(id));
    }
  };
}

/**
 * Delete a user row.
 *
 * @param id {Number} Row id.
 * @returns Action.
 */
export function deleteUserRow(id) {
  return (dispatch, getState) => {
    const projectId = getState().project.projectData.get('id');

    dispatch({
      [CALL_API]: {
        method: 'delete',
        path: `/api/v1/projects/${projectId}/users/${id}`,
        startType: SHOW_USERS_LOADING,
        errorType: REQUEST_API_USERS_FAILURE,
        afterSuccess: ({ dispatch }) => {
          dispatch(loadUsers(projectId));
        },
        afterError: ({ dispatch }) => dispatch(loadUsers(projectId)),
      },
    });
  };
}

/**
 * Select a user suggestion.
 *
 * @param id {Number} Row id.
 * @param suggestionId {Number} Suggestion id.
 * @param suggestionName {String} Suggestion name.
 * @returns Action.
 */
export function selectUserSuggestion(id, suggestionId, suggestionName) {
  return {
    type: SELECT_USER_SUGGESTION,
    id,
    suggestionId,
    suggestionName,
  };
}
