import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Map } from 'immutable';

class BrowseCheckbox extends PureComponent {
  constructor(props) {
    super(props);

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  handleCheckboxChange(event) {
    const { checked } = event.target;

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        checked,
      },
    });
  }

  render() {
    const { filter, info } = this.props;
    const label = filter.get('label');
    const checked = info.get('checked');
    const dropdownContent = checked ? this.props.children : null;

    return (
      <div className="BrowseControl">
        <label className="BrowseControl-label">
          <input checked={info.get('checked')} onChange={this.handleCheckboxChange} type="checkbox" />
          {label}
        </label>
        {dropdownContent}
      </div>
    );
  }
}

BrowseCheckbox.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BrowseCheckbox;
