import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'immutable';

import { TableFiles } from './Files/TableFiles';

class CompanyFilesPanel extends React.PureComponent {
  constructor(props) {
    super(props);

    this.inputSubmitRef = React.createRef();
    this.setTextInputRef = element => {
      this.inputSubmitRef = element;
    };

    this.handleOpenFileDialog = () => {
      if (this.inputSubmitRef) this.inputSubmitRef.current.click();
    };
  }

  render() {
    const { files, uploadFile, canEditData, onDeleteFile } = this.props;

    return (
      <div className="panel panel-default">
        <div className="panel-heading" data-parent="#accordion" data-target="#acc-slide-5" data-toggle="collapse">
          <h4 className="panel-title">Files</h4>
        </div>
        <div className="panel-collapse collapse" id="acc-slide-5">
          <div className="panel-body">
            <form className="p20" onSubmit={uploadFile}>
              <TableFiles
                canEditData={canEditData}
                files={files}
                onDeleteFile={onDeleteFile}
                onUploadFile={this.handleOpenFileDialog}
              />
              <button ref={this.inputSubmitRef} className="hidden" type="submit" />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

CompanyFilesPanel.propTypes = {
  files: PropTypes.instanceOf(List).isRequired,
  uploadFile: PropTypes.func.isRequired,
};

export default CompanyFilesPanel;
