import React from 'react';
import { fromJS } from 'immutable';
import Popup from '../../helpers/Popup';
import FormGroup from '../../helpers/FormGroup';
import Button from '../../helpers/Button';
import Select from '../../helpers/Select';
import _Input from '../../helpers/Input';
import ErrorDisplay from '../../decorators/DisplayError';
import PrettyError from '../../decorators/PrettyError';

const Input = PrettyError(ErrorDisplay(_Input));
const options = fromJS([
  { value: 'Direct', name: 'Direct' },
  { value: 'Extension', name: 'Extension' },
  { value: 'Mobile', name: 'Mobile' },
  { value: 'Home', name: 'Home' },
  { value: 'Fax', name: 'Fax' },
  { value: 'International', name: 'International' },
  { value: 'Custom', name: 'Custom' },
]);

const OtherFormat = props => {
  const { isNew, onChange, channel, onSave, onBlur } = props;

  const prefix = isNew ? 'Create' : 'Change';
  const header = `${prefix} contact profile info`;
  const footer = (
    <Button className="btn-primary btn-xs" onClick={onSave}>
      {isNew ? 'Create' : 'Save'}
    </Button>
  );

  return (
    <Popup footer={footer} header={header} id="otherFormatPopup">
      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-md"
            label="Phone"
            name="unformattedPhone"
            onBlur={onBlur}
            onChange={onChange}
            placeholder="Phone"
            root={channel}
            type="type"
            autoFocus
            deep
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-sm-6">
          <Select
            className="input-md"
            label="Type"
            name="type"
            onChange={onChange}
            options={options}
            placeholder="Type"
            root={channel}
            deep
            showLabel
          />
        </FormGroup>
      </div>
    </Popup>
  );
};

export default OtherFormat;
