import React from 'react';
import $ from 'jquery';

/**
 * Tooltip component for showing error message.
 */
class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.createElement = this.createElement.bind(this);
    this.hideElement = this.hideElement.bind(this);
    this.showElement = this.showElement.bind(this);
    this.shouldShowTooltip = this.shouldShowTooltip.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    this.getId = this.getId.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.shouldShowTooltip(nextProps.content);
  }

  componentDidMount() {
    $(document).on('click', this.hideElement);
    if (window.__emitter__) {
      this.token = window.__emitter__.addListener('invokeErrorTooltip', arg => {
        const content = (arg || {})[this.props.name];

        this.shouldShowTooltip(content);
      });
    }
    this.shouldShowTooltip(this.props.content);
  }

  shouldShowTooltip(content) {
    if (content) {
      this.showElement(content);
    } else {
      this.hideElement();
    }
  }

  componentWillUnmount() {
    $(document).off('click', this.hideElement);
    if (this.token) {
      this.token.remove();
    }
  }

  updatePosition() {
    const rect = this.span.getBoundingClientRect();

    $(`#${this.getId()}`)
      .css({
        position: 'absolute',
        top: `${rect.top + rect.height}px`,
        left: `${rect.left}px`,
      })
      .removeClass('hide');
  }

  getId() {
    return this.props.name.replace(/\./gi, '_');
  }

  createElement(text) {
    const div = $('<div>')
      .attr('id', this.getId())
      .attr('class', 'alert alert-danger alert-dismissible input-custom-alert hide')
      .on('click', event => {
        event.stopPropagation();

        return false;
      });

    div.html(
      `<span class="text">${text}</span><button type="button" class="close" aria-label="Close"><span aria-hidden="true">×</span></button>`,
    );
    div.find('button').on('click', function(event) {
      event.stopPropagation();
      $(this)
        .parent()
        .remove();
    });
    $('body').append(div);
  }

  hideElement() {
    $(`#${this.getId()}`).remove();
  }

  showElement(text) {
    this.createElement(text);
    this.updatePosition();
  }

  render() {
    const { children } = this.props;

    return (
      <span
        ref={el => {
          this.span = el;
        }}
        data-toggle="tooltip"
      >
        {children}
      </span>
    );
  }
}

export default Tooltip;
