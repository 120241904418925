import React from 'react';
import { Route, Switch } from 'react-router-dom';

import OnlineApprovalLists from '../containers/OnlineApprovalLists';

export default function ApprovalListOnlineRouting() {
  return (
    <Switch>
      <Route component={OnlineApprovalLists} path="/project/:projectId/approval/:approvalId" />
    </Switch>
  );
}
