import { CALL_API } from '../middleware/api';

export const FETCHING_WINDWARD_TEMPLATES = Symbol('FETCHING_WINDWARD_TEMPLATES');
export const FETCHED_WINDWARD_TEMPLATES_FAILURE = Symbol('FETCHED_WINDWARD_TEMPLATES_FAILURE');
export const FETCHED_WINDWARD_TEMPLATES_SUCCESS = Symbol('FETCHED_WINDWARD_TEMPLATES_SUCCESS');

export const FETCHING_WINDWARD_REPORTS = Symbol('FETCHING_WINDWARD_REPORTS');
export const FETCHED_WINDWARD_REPORTS_FAILURE = Symbol('FETCHED_WINDWARD_REPORTS_FAILURE');
export const FETCHED_WINDWARD_REPORTS_SUCCESS = Symbol('FETCHED_WINDWARD_REPORTS_SUCCESS');

export const UPLOADING_WINDWARD_REPORTS = Symbol('UPLOADING_WINDWARD_REPORTS');
export const UPLOADED_WINDWARD_REPORTS_FAILURE = Symbol('UPLOADED_WINDWARD_REPORTS_FAILURE');
export const UPLOADED_WINDWARD_REPORTS_SUCCESS = Symbol('UPLOADED_WINDWARD_REPORTS_SUCCESS');

export const DELETING_WINDWARD_TEMPLATE = Symbol('DELETING_WINDWARD_TEMPLATE');
export const DELETE_WINDWARD_TEMPLATE_FAILURE = Symbol('DELETE_WINDWARD_TEMPLATE_FAILURE');
export const DELETE_WINDWARD_TEMPLATE_SUCCESS = Symbol('DELETE_WINDWARD_TEMPLATE_SUCCESS');

export const UPDATE_WINDWARD_TEMPLATES = Symbol('UPDATE_WINDWARD_TEMPLATES');
export const CLEAR_WINDWARD_TEMPLATE = Symbol('CLEAR_WINDWARD_TEMPLATE');

export const fetchWindwardTemplates = () => ({
  [CALL_API]: {
    method: 'get',
    path: '/api/v1/windward/templates',
    startType: FETCHING_WINDWARD_TEMPLATES,
    successType: FETCHED_WINDWARD_TEMPLATES_SUCCESS,
    errorType: FETCHED_WINDWARD_TEMPLATES_FAILURE,
  },
});

export const deleteWindwardTemplate = ({ moduleId, fileName, afterSuccess, afterError }) => ({
  [CALL_API]: {
    method: 'delete',
    path: `/api/v1/windward/templates/${moduleId}/${fileName}`,
    startType: DELETING_WINDWARD_TEMPLATE,
    successType: DELETE_WINDWARD_TEMPLATE_SUCCESS,
    errorType: DELETE_WINDWARD_TEMPLATE_FAILURE,
    afterSuccess,
    afterError,
  },
});

export const fetchWindwardReports = () => ({
  [CALL_API]: {
    method: 'get',
    path: '/api/v1/windward/reports',
    startType: FETCHING_WINDWARD_REPORTS,
    successType: FETCHED_WINDWARD_REPORTS_SUCCESS,
    errorType: FETCHED_WINDWARD_REPORTS_FAILURE,
  },
});

export const uploadTemplate = ({ formData, afterSuccess, afterError }) => ({
  [CALL_API]: {
    method: 'post',
    path: '/api/v1/windward/templates',
    body: formData,
    skipGlobalErrorHandler: true,
    startType: UPLOADING_WINDWARD_REPORTS,
    successType: UPLOADED_WINDWARD_REPORTS_SUCCESS,
    errorType: UPLOADED_WINDWARD_REPORTS_FAILURE,
    afterSuccess,
    afterError,
  },
});

export function handleUpdateWindwardTemplates(info) {
  return {
    type: UPDATE_WINDWARD_TEMPLATES,
    ...info,
  };
}

export function clearWindwardTemplate() {
  return {
    type: CLEAR_WINDWARD_TEMPLATE,
  };
}
