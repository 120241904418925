import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { List, Map } from 'immutable';
import { push } from 'connected-react-router';

import connectOptions, { mergeProps } from '../utils/connectOptions';
import Search from '../components/Search';
import config from '../config';
import { detectBottom } from '../utils/scrollbar';
import { searchAll, fetchNextPage, changeSearchingTerm, changeTab } from '../actions/search';
import { openInternalLink } from '../utils/url';
import { checkAccess } from '../utils/checkPermissions';

/**
 * State component for search page.
 *
 * @param props {Object}.
 * @param props.people {Immutable.List} List of person returned in api request.
 * @param props.companies {Immutable.List} List of company returned in api request.
 * @param props.searchingPeople {boolean} Is loading person list.
 * @param props.searchingCompanies {boolean} Is loading company list.
 * @param props.searchingPeopleError {boolean} People api error.
 * @param props.searchingCompaniesError {boolean} Company api error.
 * @param props.searchingTerm {boolean} Searching searchingTerm.
 * @returns {React.Component}
 * @class
 */
export class SearchContainer extends Component {
  state = {
    hasAccess: false,
    searchBy: '',
    onlyOwner: null,
    searchByParams: [],
  };

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const keyword = searchParams.get('text');
    const hasAccess = checkAccess(this.props.loggedUser.getIn(['roles', 0]));

    this.setState({ hasAccess });

    if (keyword) {
      this.props.changeSearchingTerm(keyword);
      this.props.searchAll(keyword, this.state.searchByParams);
    }
  }

  handleSearch = () => {
    const { searchingTerm, history, location, searchAll } = this.props;

    searchAll(searchingTerm, this.state.searchByParams);

    history.push({
      pathname: location.pathname,
      search: `text=${searchingTerm}`,
    });
  };

  setOnlyOwner = (name, checked, callback) => {
    this.setState({ onlyOwner: checked ? 'onlyOwner' : null }, () => {
      this.prepareParams(callback);
    });
  };

  setSearchBy = (name, checked, callback) => {
    this.setState({ searchBy: checked ? name : null }, () => {
      this.prepareParams(callback);
    });
  };

  prepareParams = callback => {
    const { onlyOwner, searchBy } = this.state;
    const searchByParams = [onlyOwner, searchBy].filter(params => Boolean(params));

    this.setState({ searchByParams }, () => {
      callback();
    });
  };

  handleItemClick = ({ to }) => {
    openInternalLink(to);
  };

  handleTabChange = tabName => {
    this.props.changeTab(tabName);
  };

  handleOnlyOwnerChange = ({ target: { name, checked } }) => {
    const { searchingTerm, searchAll } = this.props;

    this.setOnlyOwner(name, checked, () => {
      searchAll(searchingTerm, this.state.searchByParams);
    });
  };

  handleSearchBy = ({ target: { name, checked } }) => {
    const { searchingTerm, searchAll } = this.props;

    this.setSearchBy(name, checked, () => {
      searchAll(searchingTerm, this.state.searchByParams);
    });
  };

  handleSearchingTermChange = ({ target: { value } }) => {
    this.props.changeSearchingTerm(value);
  };

  handleScroll = event => {
    const { target: container } = event;

    if (detectBottom(container)) {
      const {
        searchingTerm,
        activeTab,
        companiesNextPage,
        peopleNextPage,
        searchingCompanies,
        searchingPeople,
      } = this.props;

      if (activeTab === 'people' && peopleNextPage && !searchingPeople) {
        this.props.fetchNextPage(searchingTerm, 'people', peopleNextPage);
      } else if (activeTab === 'companies' && companiesNextPage && !searchingCompanies) {
        this.props.fetchNextPage(searchingTerm, 'companies', companiesNextPage);
      }
    }
  };

  render() {
    const name = this.props.loggedUser.get('module') || '';

    return (
      <div className="full-height">
        <Helmet title="Search Page" />
        <Search
          {...this.props}
          hasAccess={this.state.hasAccess}
          onClick={this.handleItemClick}
          onOnlyOwnerChange={this.handleOnlyOwnerChange}
          onScroll={this.handleScroll}
          onSearch={this.handleSearch}
          onSearchBy={this.handleSearchBy}
          onSearchingTermChange={this.handleSearchingTermChange}
          onTabChange={this.handleTabChange}
          searchBy={this.state.searchBy}
          userName={name}
        />
      </div>
    );
  }
}

SearchContainer.propTypes = {
  activeTab: PropTypes.string.isRequired,
  companies: PropTypes.instanceOf(List).isRequired,
  companiesNextPage: PropTypes.number.isRequired,
  dropdownItems: PropTypes.instanceOf(List).isRequired,
  errors: PropTypes.instanceOf(Map).isRequired,
  loggedUser: PropTypes.instanceOf(Map).isRequired,
  people: PropTypes.instanceOf(List).isRequired,
  peopleNextPage: PropTypes.number.isRequired,
  searchingCompanies: PropTypes.bool.isRequired,
  searchingPeople: PropTypes.bool.isRequired,
  searchingTerm: PropTypes.string.isRequired,
  totalCompanies: PropTypes.number.isRequired,
  totalPeople: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    loggedUser: state.auth.get('user'),
    people: state.search.get('people'),
    companies: state.search.get('companies'),
    searchingPeople: state.search.get('searchingPeople'),
    searchingCompanies: state.search.get('searchingCompanies'),
    searchingTerm: state.search.get('searchingTerm'),
    activeTab: state.search.get('activeTab'),
    errors: state.search.get('errors'),
    totalPeople: state.search.get('totalPeople'),
    totalCompanies: state.search.get('totalCompanies'),
    companiesNextPage: state.search.get('companiesNextPage'),
    peopleNextPage: state.search.get('peopleNextPage'),
    dropdownItems: config.values.getIn(['search', 'menu']),
  };
}

export default connect(
  mapStateToProps,
  {
    searchAll,
    fetchNextPage,
    changeSearchingTerm,
    changeTab,
    push,
  },
  mergeProps,
  connectOptions,
)(SearchContainer);
