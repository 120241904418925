import { CALL_API, CHAIN_API } from '../../middleware/api';
import config from '../../config';
import { unwrap, isChanged } from '../../utils/ChangeSpy';

export const LOADING_APPROVALS = Symbol('LOADING_APPROVALS');
export const LOADED_APPROVALS_FAILURE = Symbol('LOADED_APPROVALS_FAILURE');
export const LOADED_APPROVALS_SUCCESS = Symbol('LOADED_APPROVALS_SUCCESS');
export const UPDATE_APPROVAL_INFORMATION = Symbol('UPDATE_APPROVAL_INFORMATION');
export const INSERT_APPROVAL_ROW = Symbol('INSERT_APPROVAL_ROW');
export const CHANGE_APPROVAL_ROW_MODE = Symbol('CHANGE_APPROVAL_ROW_MODE');
export const SORT_APPROVALS = 'SORT_APPROVALS';
export const REQUEST_API_APPROVAL_FAILURE = Symbol('REQUEST_API_APPROVAL_FAILURE');

/**
 * Delete approval.
 *
 * @param {number} projectId Id of project.
 * @param {number} approvalId Id of approval list.
 * @param field
 * @param direction
 */
export function deleteApproval({ projectId, approvalId, field, direction }) {
  if (approvalId === -1) {
    return loadApprovals({ projectId, field, direction });
  }

  return {
    [CHAIN_API]: [
      () => ({
        [CALL_API]: {
          method: 'delete',
          path: `/api/v1/projects/${projectId}/approval_lists/${approvalId}`,
          afterSuccess: ({ dispatch }) => {
            dispatch(loadApprovals({ projectId, field, direction }));
          },
        },
      }),
    ],
  };
}

/**
 * Load approval list.
 *
 * @param id {Number} Project's id.
 * @param field
 * @param direction
 * @param isDefaultAddon
 * @returns Action.
 */
export function loadApprovals({ projectId, field, direction, isDefaultAddon }) {
  return (dispatch, getState) => {
    const state = getState().project.projectData;
    const isDefaultAddOnFromState = state.get('isDefaultAddOn');
    const include =
      isDefaultAddon || isDefaultAddOnFromState
        ? 'default_approval_lists,default_no_approval_lists'
        : 'approval_lists,no_approval_lists';

    dispatch({
      field,
      direction,
      [CALL_API]: {
        method: 'get',
        path: `/api/v1/projects/${projectId}`,
        query: { include },
        startType: LOADING_APPROVALS,
        errorType: LOADED_APPROVALS_FAILURE,
        successType: LOADED_APPROVALS_SUCCESS,
      },
    });
  };
}

/**
 * Save approval list.
 *
 * @returns Action.
 */
export function saveApproval({ field, direction }) {
  return (dispatch, getState) => {
    const state = getState().project;
    const projectId = state.projectData.get('id');
    const body = {
      approvalLists: getApprovalList(state.approvals),
    };

    if (body.approvalLists.length > 0) {
      dispatch({
        [CALL_API]: {
          method: 'post',
          path: `/api/v1/projects/${projectId}/approval_lists`,
          body,
          startType: LOADING_APPROVALS,
          errorType: REQUEST_API_APPROVAL_FAILURE,
          afterSuccess: ({ dispatch }) => {
            dispatch(loadApprovals({ projectId, field, direction }));
          },
        },
      });
    } else {
      dispatch(loadApprovals({ projectId, field, direction }));
    }
  };
}

/**
 * Get approval list for api posting.
 *
 * @param approvals {Immutable.List} Approval list.
 * @returns {Immutable.List}
 */
function getApprovalList(approvals) {
  const list = [];
  const SPY_FIELDS = ['applistLabel', 'dateSent', 'dateReceived'];

  approvals.forEach(ap => {
    const ret = {};
    let changed = false;
    const id = ap.get('id');

    if (id > 0) {
      ret.id = id;
    }
    SPY_FIELDS.reduce((result, field) => {
      if (isChanged(ap.get(field))) {
        changed = true;
        result[field] = field !== SPY_FIELDS[0] ? dateToString(unwrap(ap.get(field))) : unwrap(ap.get(field));
      }

      return result;
    }, ret);

    if (changed) {
      list.push(ret);
    }
  });

  return list;
}

function dateToString(dt) {
  return dt && dt.isValid() ? dt.format('YYYY-MM-DD') : null;
}

/**
 * Upload approval label.
 *
 * @param index {Number} Approval's index.
 * @param name {String} Name field.
 * @param value {String} Value field.
 * @returns Action.
 */
export function updateApprovals(index, name, value) {
  return {
    type: UPDATE_APPROVAL_INFORMATION,
    index,
    name,
    value,
  };
}

/**
 * Upload approval label.
 *
 * @returns Action.
 */
export function insertApprovalRow() {
  return {
    type: INSERT_APPROVAL_ROW,
  };
}

/**
 * Upload approval label.
 *
 * @param index {Number} Approval's index.
 * @returns Action.
 */
export function switchApprovalRowToEdit(index) {
  return {
    type: CHANGE_APPROVAL_ROW_MODE,
    index,
    mode: config.EDIT_MODE,
  };
}

/**
 * Sort target table.
 *
 * @param field {String} Field used for sorting.
 * @param direction {String} Sorting direction.
 * @returns Action.
 */
export function sortApproval(field, direction) {
  return {
    type: SORT_APPROVALS,
    field,
    direction,
  };
}
