import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import classNames from 'classnames';
import LinkOut from '../helpers/LinkOut';

import { getIn } from '../../utils/ChangeSpy';
import FormSubmitOnBlur from '../helpers/FormSubmitOnBlur';
import FormGroup from '../helpers/FormGroup';

import Checkbox from '../helpers/Checkbox';
import DropDown, { DropDownElement } from '../helpers/DropDown';

import DeepValue from '../decorators/DeepValue';
import ValueGetter from '../decorators/ValueGetter';

import _Input from '../helpers/Input';
import ErrorDisplay from '../decorators/DisplayError';
import PrettyError from '../decorators/PrettyError';
import ContactTypedInfoTable from './ContactTypedInfoTable';

const Input = PrettyError(ErrorDisplay(_Input));

const Link = DeepValue(ValueGetter(LinkOut));

/**
 * Stateless component for contact's info.
 * Also used to change contact info.
 *
 * @link http://screencloud.net/v/jS6q on page (left top part)
 * @link http://screencloud.net/v/n4j7 component itself
 * @param props {Object}.
 * @param props.info {Immutable.Map} Map with contact info
 *     (name, title, suffix, age and so).
 * @returns {React.Component}
 */
class ContactInfo extends PureComponent {
  render() {
    const { props, context } = this;

    const { onChange, onBlur, onSubmit, suffixSuggest, info } = props;
    const {
      onAddAddress,
      onAddEmail,
      onAddPhone,
      onEditAddress,
      onEditEmail,
      onEditPhone,
      onChangePhone,
      onChangeEmail,
      onChangeAddress,
      onDelAddress,
      onDelPhone,
      onDelEmail,
    } = context;

    const phones = info.get('phones');
    const emails = info.get('emails');
    const addresses = info.get('addresses');

    const prefix = info.get('prefix');
    const prefixes = info.get('prefixes');

    const prefixesContent = prefixes.map((prefix, i) => {
      const attributes = prefix.get('attributes').toJS();

      return (
        <DropDownElement {...attributes} key={i} value={prefix.get('value')}>
          {prefix.get('name')}
        </DropDownElement>
      );
    });

    const age = Number(getIn(info, 'age'));
    const ageWrapperClassName = classNames('contact-age', {
      'more-than-59': age >= 60 && age < 65,
      'more-than-64': age >= 65,
    });

    let dNMClassName = '';

    if (info.getIn(['doNotMail', 'value'])) {
      dNMClassName = 'text-info';
    }

    return (
      <div className="highlight wrap-border-full" onChange={onChange} role="form">
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <FormSubmitOnBlur className="information" onComponentBlur={onBlur} onSubmit={onSubmit}>
              <div>
                <div className="col-xs-2 col-sm-2 col-md-3 col-lg-2 p0">
                  <DropDown
                    btnContent={prefix}
                    className="btn-default btn-xs full-width text-right"
                    id="dropdownMenu7"
                    name="prefix"
                    onChange={onChange}
                    root={info}
                    deep
                  >
                    {prefixesContent}
                  </DropDown>
                </div>
                <div className="col-xs-8 col-sm-8 col-md-6 col-lg-8">
                  <FormGroup>
                    <Input
                      className="input-md"
                      id="w-full-name7"
                      label="Full Name"
                      name="fullName"
                      placeholder="Full Name"
                      root={info}
                      deep
                    />
                  </FormGroup>
                </div>
                <div className="col-xs-2 col-sm-2 col-md-3 col-lg-2 p0">{suffixSuggest}</div>
                <div className="clearfix" />
              </div>

              <FormGroup>
                <Input
                  className="input-md text-bold"
                  id="f-f-name"
                  label="First Name"
                  name="firstName"
                  placeholder="First Name"
                  root={info}
                  deep
                />
              </FormGroup>

              <FormGroup>
                <Input
                  className="input-md text-bold"
                  id="first name"
                  label="Last Name"
                  name="lastName"
                  placeholder="Last Name"
                  root={info}
                  deep
                />
              </FormGroup>

              <FormGroup>
                <Checkbox
                  deep="checked"
                  id="f-dnm"
                  label="DNM"
                  labelClassName={dNMClassName}
                  name="doNotMail"
                  root={info}
                  boldOnChecked
                />
              </FormGroup>
            </FormSubmitOnBlur>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <FormSubmitOnBlur className="information" onComponentBlur={onBlur} onSubmit={onSubmit}>
              <FormGroup>
                <Input id="f-nick3" label="Nick" name="nick" placeholder="Nick" root={info} deep />
              </FormGroup>
              <FormGroup>
                <Input
                  id="f-pronounce3"
                  label="Pronunciation"
                  name="pronounce"
                  placeholder="Pronunciation"
                  root={info}
                  deep
                />
              </FormGroup>
              <FormGroup className="input-group">
                <Input id="f-linkedIn" label="Linkedin" name="linkedin" placeholder="LinkedIn" root={info} deep />
                <Link
                  className="input-group-addon"
                  deep="to"
                  name="linkedin"
                  root={info}
                  target="_blank"
                  valueFrom="to"
                >
                  <i aria-hidden="true" className="fa fa-linkedin-square" />
                </Link>
              </FormGroup>
              <FormGroup className={ageWrapperClassName}>
                <Input id="f-age" label="Age" name="age" placeholder="Age" root={info} deep />
              </FormGroup>
            </FormSubmitOnBlur>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p0">
            <FormSubmitOnBlur className="highlight table-responsive" onComponentBlur={onBlur} onSubmit={onSubmit}>
              <ContactTypedInfoTable
                contacts={phones}
                onAdd={onAddPhone}
                onChange={onChangePhone}
                onClick={onEditPhone}
                onDel={onDelPhone}
                type="Phones"
              />
            </FormSubmitOnBlur>
          </div>

          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p0">
            <FormSubmitOnBlur className="highlight table-responsive" onComponentBlur={onBlur} onSubmit={onSubmit}>
              <ContactTypedInfoTable
                contacts={emails}
                onAdd={onAddEmail}
                onChange={onChangeEmail}
                onClick={onEditEmail}
                onDel={onDelEmail}
                type="Emails"
              />
            </FormSubmitOnBlur>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 p0">
            <FormSubmitOnBlur
              className="highlight table-responsive"
              onComponentBlur={onBlur}
              onSubmit={onSubmit}
              focusOnClick
            >
              <ContactTypedInfoTable
                contacts={addresses}
                onAdd={onAddAddress}
                onBlur={onBlur}
                onChange={onChangeAddress}
                onClick={onEditAddress}
                onDel={onDelAddress}
                type="Addresses"
              />
            </FormSubmitOnBlur>
          </div>
        </div>
      </div>
    );
  }
}

ContactInfo.propTypes = {
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
};

ContactInfo.contextTypes = {
  onAddAddress: PropTypes.func.isRequired,
  onAddEmail: PropTypes.func.isRequired,
  onDelAddress: PropTypes.func.isRequired,
  onDelEmail: PropTypes.func.isRequired,
  onDelPhone: PropTypes.func.isRequired,
  onEditEmail: PropTypes.func.isRequired,
  onAddPhone: PropTypes.func.isRequired,
  onChangePhone: PropTypes.func.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onEditPhone: PropTypes.func.isRequired,
  onEditAddress: PropTypes.func.isRequired,
  onChangeAddress: PropTypes.func.isRequired,
};

export default ContactInfo;
