import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Popups from '../../components/Popup';
import { addEvent } from '../../actions/tradeShow';

const AddTradeShowEventPopupContainer = (props, context) => {
  const [event, setEvent] = useState({
    title: '',
    city: '',
    state: '',
    year: '',
    startDate: '',
    endDate: '',
    yearSuffix: '',
  });
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    const emptyFields = [];

    Object.keys(event).forEach(field => {
      if (field !== 'title' && field !== 'yearSuffix' && event[field] === '') {
        emptyFields.push(field);
      }
    });

    setCanSave(!emptyFields.length);
  }, [event]);

  const handleClose = () => {
    context.closePopup();
  };

  const handleSave = () => {
    if (canSave) {
      props.addEvent(event);
      handleClose();
    }
  };

  const handleChange = (value, name) => {
    setEvent({
      ...event,
      [name]: value,
    });
  };

  return (
    <div>
      <Popups.AddTradeShowEventPopup
        canSave={canSave}
        event={event}
        onChange={handleChange}
        onClose={handleClose}
        onSave={handleSave}
      />
    </div>
  );
};

AddTradeShowEventPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export { AddTradeShowEventPopupContainer };
export default connect(mapStateToProps, { addEvent })(AddTradeShowEventPopupContainer);
