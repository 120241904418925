import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Map } from 'immutable';

import connectOptions, { mergeProps } from '../../utils/connectOptions';
import Popups from '../../components/Popup';
import { getIn, unwrap } from '../../utils/ChangeSpy';
import {
  createOneOff,
  changeField,
  revertUpdate,
  findDirectors,
  findAnalysts,
  findBuyers,
  findProjects,
  getCountTargets,
} from '../../actions/oneOff';
import AutoCompleteContainer from '../AutoComplete';
import { dateToString } from '../../utils/dateFormat';
import { showError } from '../../utils/MessagePopup';

class OneOffApproachPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
    };

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleUserSuggestChange = this.handleUserSuggestChange.bind(this);
    this.handleCountTarget = this.handleCountTarget.bind(this);
  }

  onChange(event) {
    const { name, value: val, checked, type } = event.target;
    const value = type === 'checkbox' ? checked : val;

    return this.props.changeField({ name, value });
  }

  onSave() {
    const { popup, oneOff, createOneOff } = this.props;
    const callback = popup.get('props').get('callback');

    this.setState({ loading: true });

    const body = {
      tag: getIn(oneOff, 'tag'),
      buyerId: getIn(oneOff, 'buyerId'),
      projectId: getIn(oneOff, 'projectId'),
      recordOwnerId: getIn(oneOff, 'recordOwnerId'),
      recordSubOwnerId: getIn(oneOff, 'recordSubOwnerId'),
      description: getIn(oneOff, 'description'),
      date: dateToString(popup.get('props').get('dateQuery')),
    };

    createOneOff({
      body,
      afterSuccess: () => {
        this.setState({ loading: false });
        this.onClose();
        callback();
      },
      afterError: ({ resBody }) => {
        this.setState({ loading: false });
        showError(
          this.context.openPopup,
          Object.entries(resBody.errors).map(val => val[1].toString()),
        );
      },
    });
  }

  onClose() {
    this.props.revertUpdate();
    this.context.closePopup();
  }

  handleUserSuggestChange({ name, value }) {
    return this.props.changeField({ name, value });
  }

  handleCountTarget() {
    const { oneOff, getCountTargets } = this.props;
    const tag = unwrap(oneOff.getIn(['tag']));

    getCountTargets(tag);
  }

  getModuleSuggests() {
    return (
      <AutoCompleteContainer
        change={this.handleUserSuggestChange}
        find={opts => {
          this.props.findDirectors({
            ...opts,
            field: 'recordOwner',
          });
        }}
        idName="recordOwnerId"
        inputProps={{
          name: 'recordOwner',
          className: 'form-control',
          label: 'Module',
        }}
        name="recordOwner"
        rootPath={['oneOff']}
        suggestsName="suggestModule"
        valueName="recordOwner"
      />
    );
  }

  getAnalystSuggests() {
    return (
      <AutoCompleteContainer
        change={this.handleUserSuggestChange}
        find={opts => {
          this.props.findAnalysts({
            ...opts,
            field: 'recordSubOwner',
          });
        }}
        idName="recordSubOwnerId"
        inputProps={{
          name: 'recordSubOwner',
          className: 'form-control',
          label: 'A/A',
        }}
        name="recordSubOwner"
        rootPath={['oneOff']}
        suggestsName="suggestAnalyst"
        valueName="recordSubOwner"
      />
    );
  }

  getBuyerSuggests() {
    return (
      <AutoCompleteContainer
        change={this.handleUserSuggestChange}
        find={opts => {
          this.props.findBuyers({
            ...opts,
            field: 'buyer',
          });
        }}
        idName="buyerId"
        inputProps={{
          name: 'buyer',
          className: 'form-control',
          label: 'Buyer',
        }}
        name="buyer"
        rootPath={['oneOff']}
        suggestsName="suggestBuyer"
        valueName="buyer"
      />
    );
  }

  getProjectSuggests() {
    const buyerId = unwrap(this.props.oneOff.getIn(['buyerId']));

    return (
      <AutoCompleteContainer
        change={this.handleUserSuggestChange}
        find={opts => {
          this.props.findProjects({
            ...opts,
            field: 'project',
            buyerId,
          });
        }}
        idName="projectId"
        inputProps={{
          name: 'project',
          className: 'form-control',
          label: 'Project',
          disabled: !buyerId,
        }}
        name="project"
        rootPath={['oneOff']}
        suggestsName="suggestProject"
        valueName="project"
        suggestIfEmpty
      />
    );
  }

  render() {
    const { loadingCountTargets, isValid, oneOff, countTargets, onErrorClose } = this.props;
    const { loading } = this.state;

    return (
      <Popups.OneOffApproachPopup
        countTargets={countTargets}
        isValid={isValid}
        loading={loading}
        loadingCountTargets={loadingCountTargets}
        onChange={this.onChange}
        onClose={this.onClose}
        onCountTargets={this.handleCountTarget}
        oneOff={oneOff}
        onErrorClose={onErrorClose}
        onSave={this.onSave}
        suggestAnalyst={this.getAnalystSuggests()}
        suggestBuyer={this.getBuyerSuggests()}
        suggestModule={this.getModuleSuggests()}
        suggestProject={this.getProjectSuggests()}
      />
    );
  }
}

OneOffApproachPopupContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};

function mapStateToProps(state, props) {
  return {
    ...props,
    oneOff: state.oneOff,
    loadingCountTargets: state.oneOff.get('loadingCountTargets'),
    countTargets: state.oneOff.get('countTargets'),
    isValid: state.oneOff.get('isValid', false),
    inputErrors: state.oneOff.get('inputErrors'),
  };
}

export { OneOffApproachPopupContainer };
export default connect(
  mapStateToProps,
  {
    createOneOff,
    changeField,
    revertUpdate,
    findDirectors,
    findAnalysts,
    findBuyers,
    findProjects,
    getCountTargets,
  },
  mergeProps,
  connectOptions,
)(withRouter(OneOffApproachPopupContainer));
