import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import { bindActionCreators } from 'redux';
import { debounce, isEmpty } from 'underscore';
import moment from 'moment';

import { downloadBrowseFile } from 'src/utils/downloadFile';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import BrowseCheckbox from '../../components/Browse/BrowseCheckbox';
import BrowseInputRange from '../../components/Browse/BrowseInputRange';
import BrowseInputTag from '../../components/Browse/BrowseInputTag';
import BrowseAutoCompleteTag from '../../components/Browse/BrowseAutoCompleteTag';
import BrowseActionFilter from '../../components/Browse/BrowseActionFilter';
import config from '../../config';
import * as browseActionCreators from '../../actions/browse';
import {
  buildRangeParam,
  buildDateParam,
  buildTagParam,
  buildActionParam,
  buildCheckboxArrayParam,
  buildCheckboxObjectParam,
  buildSortByParam,
  buildDateRangeParam,
  buildBuyerActivityParam,
  buildActivityParam,
} from '../../helpers/paramBuilder';
import BrowseTable from '../../components/Browse/BrowseTable';
import { fetchTargetStatuses } from '../../actions/statuses';
import BrowseStatusesFilter from '../../components/Browse/BrowseStatusesFilter';
import BrowseDateFilter from '../../components/Browse/BrowseDateFilter';
import BrowseBuyerActivityFilter from '../../components/Browse/BrowseBuyerActivityFilter';
import { showError } from '../../utils/MessagePopup';
import { checkBrowsePageAccess } from '../../utils/checkPermissions';
import BrowseActivityFilter from '../../components/Browse/BrowseActivityFilter';

const SAFE_EXPORT_BUYERS_NUMBER = 300;

const companyTypes = config.values.get('companyTypes');
const states = fromJS(config.company.states.map(st => ({ name: st, value: st })));
const activities = config.values.get('activities');
const buyersFilter = config.browse.getIn(['filter', 'buyers']);
const columnDefs = config.browse.getIn(['table', 'columnDefs', 'buyers']);
const contextActionList = config.browse.getIn(['contextActionList', 'buyers']);

export class BrowseBuyers extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubActivityFilterChange = this.handleSubActivityFilterChange.bind(this);
    this.handleGetSuggestionForStates = debounce(this.handleGetSuggestionForStates.bind(this), 200);
    this.handleGetSuggestionForHarvcoContact = debounce(this.handleGetSuggestionForHarvcoContact.bind(this), 200);
    this.handleGetSuggestionForNextAction = debounce(this.handleGetSuggestionForNextAction.bind(this), 200);
    this.handleGetSuggestionForActivityUser = debounce(this.handleGetSuggestionForActivityUser.bind(this), 200);
    this.handleGetNextSuggestionForActivityUser = debounce(this.handleGetNextSuggestionForActivityUser.bind(this), 200);
    this.handleGetSuggestionForActivity = debounce(this.handleGetSuggestionForActivity.bind(this), 200);
    this.handleGetSuggestionForCompanyType = debounce(this.handleGetSuggestionForCompanyType.bind(this), 200);
    this.handleGetSuggestionForPriority = debounce(this.handleGetSuggestionForPriority.bind(this), 200);
    this.handleGetNextPageData = this.handleGetNextPageData.bind(this);
    this.handleBuyerTypeSubCheckboxChange = this.handleBuyerTypeSubCheckboxChange.bind(this);
    this.handleBuyerActivityChange = this.handleBuyerActivityChange.bind(this);
    this.handleTransactionTypeSubCheckboxChange = this.handleTransactionTypeSubCheckboxChange.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.handleGetNextSuggestionForHarvcoContact = debounce(
      this.handleGetNextSuggestionForHarvcoContact.bind(this),
      200,
    );
    this.handleClearFilter = this.handleClearFilter.bind(this);
    this.handleGetSuggestionForCountry = debounce(this.handleGetSuggestionForCountry.bind(this), 200);
    this.handleGetNextSuggestionForCountry = debounce(this.handleGetNextSuggestionForCountry.bind(this), 200);
    this.handleAbortRequest = this.handleAbortRequest.bind(this);
    this.keepSelection = false;
    this.buildFiltersStateParam = this.buildFiltersStateParam.bind(this);
    this.handleDownloadExport = this.handleDownloadExport.bind(this);
  }

  componentDidMount() {
    const { onFetchStatuses, location, handleFetchCriteria } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const criteria = searchParams.get('criteria');

    onFetchStatuses();

    if (criteria) {
      handleFetchCriteria({
        name: 'buyers',
        hash: criteria,
      });
    }

    if (!this.checkPageAccess()) {
      showError(this.context.openPopup, config.permisionError, () => {
        this.context.closePopup();
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const sortModel = this.props.buyers.getIn(['sortBy', 'sortModel']);
    const newSortModel = nextProps.buyers.getIn(['sortBy', 'sortModel']);

    if (newSortModel !== sortModel) {
      const requestParam = this.buildRequestParam(nextProps.buyers);

      if (isEmpty(requestParam)) {
        return;
      }

      const sortBy = buildSortByParam(nextProps.buyers);

      this.props.handleBrowse({
        name: 'buyers',
        params: requestParam,
        page: 1,
        sortBy,
        keepSelection: this.keepSelection,
      });
      this.keepSelection = false;
      this.oldRequestParam = requestParam;
    }
  }

  handleSubActivityFilterChange(data) {
    this.props.handleUpdateBuyersActivityFilter(data);
  }

  handleGetNextPageData(page, keepSelection = true) {
    const requestParam = this.buildRequestParam(this.props.buyers);
    const sortBy = buildSortByParam(this.props.buyers);

    this.props.handleBrowse({
      name: 'buyers',
      params: requestParam,
      page,
      sortBy,
      keepSelection,
    });
  }

  /**
   * Prepare moment() date object into ISO string.
   *
   * @param data Action data.
   */
  prepareActionData(data) {
    return {
      ...data,
      endDate: moment.isMoment(data.endDate) ? data.endDate.toISOString() : data.endDate,
      startDate: moment.isMoment(data.startDate) ? data.startDate.toISOString() : data.startDate,
    };
  }

  /**
   * Prepare moment() date object into ISO string.
   *
   * @param data Date data.
   */
  prepareDateData(data) {
    return {
      ...data,
      min: moment.isMoment(data.min) ? data.min.toISOString() : data.min,
      max: moment.isMoment(data.max) ? data.max.toISOString() : data.max,
    };
  }

  /**
   * Build all state data to restore it on a getting client.
   *
   * @param {object} props Component props.
   */
  buildFiltersStateParam(props) {
    const stateParams = {};

    // Collect needed filters if it's selected.
    if (props.getIn(['buyerType', 'checked'])) stateParams.buyerType = props.get('buyerType').toJS();
    if (props.getIn(['priority', 'checked'])) stateParams.priority = props.get('priority').toJS();
    if (props.getIn(['currentStatus', 'checked'])) stateParams.currentStatus = props.get('currentStatus').toJS();
    if (props.getIn(['highStatus', 'checked'])) stateParams.highStatus = props.get('highStatus').toJS();
    if (props.getIn(['activity', 'checked'])) stateParams.activity = props.get('activity').toJS();
    if (props.getIn(['projectActivity', 'checked'])) stateParams.projectActivity = props.get('projectActivity').toJS();
    if (props.getIn(['revenue', 'checked'])) stateParams.revenue = props.get('revenue').toJS();
    if (props.getIn(['ebitda', 'checked'])) stateParams.ebitda = props.get('ebitda').toJS();
    if (props.getIn(['ev', 'checked'])) stateParams.ev = props.get('ev').toJS();
    if (props.getIn(['transactionTypes', 'checked']))
      stateParams.transactionTypes = props.get('transactionTypes').toJS();
    if (props.getIn(['geography', 'checked'])) stateParams.geography = props.get('geography').toJS();
    if (props.getIn(['industry', 'checked'])) stateParams.industry = props.get('industry').toJS();
    if (props.getIn(['harvcoContact', 'checked'])) stateParams.harvcoContact = props.get('harvcoContact').toJS();
    if (props.getIn(['companyType', 'checked'])) stateParams.companyType = props.get('companyType').toJS();
    if (props.getIn(['companyState', 'checked'])) stateParams.companyState = props.get('companyState').toJS();
    if (props.getIn(['areaCode', 'checked'])) stateParams.areaCode = props.get('areaCode').toJS();
    if (props.getIn(['nextAction', 'checked'])) {
      stateParams.nextAction = this.prepareActionData(props.get('nextAction').toJS());
    }

    if (props.getIn(['mergeResultOnly', 'checked'])) {
      stateParams.mergeResultOnly = this.prepareActionData(props.get('mergeResultOnly').toJS());
    }

    if (props.getIn(['activity', 'checked'])) {
      stateParams.activity = this.prepareActionData(props.get('activity').toJS());
    }
    if (props.getIn(['harvcoTags', 'checked'])) stateParams.harvcoTags = props.get('harvcoTags').toJS();
    if (props.getIn(['country', 'checked'])) stateParams.country = props.get('country').toJS();
    if (props.getIn(['startDate', 'checked'])) {
      stateParams.startDate = this.prepareDateData(props.get('startDate').toJS());
    }

    if (props.getIn(['endDate', 'checked'])) {
      stateParams.endDate = this.prepareDateData(props.get('endDate').toJS());
    }
    if (props.getIn(['keywords', 'checked'])) stateParams.keywords = props.get('keywords').toJS();

    return stateParams;
  }

  buildRequestParam(props) {
    const requestParams = {
      ...buildCheckboxArrayParam(props, 'buyer_type', 'buyerType', 'paramName'),
      ...buildTagParam(props, 'priorities', 'priority', 'value'),
      ...buildRangeParam(props, 'current_status_range', 'currentStatus'),
      ...buildRangeParam(props, 'high_status_range', 'highStatus'),
      ...buildBuyerActivityParam(props, 'activity_search', 'projectActivity'),
      ...buildRangeParam(props, 'revenue_range', 'revenue'),
      ...buildRangeParam(props, 'ebitda_range', 'ebitda'),
      ...buildRangeParam(props, 'ev_range', 'ev'),
      ...buildCheckboxObjectParam(props, 'transaction_types', 'transactionTypes'),
      ...buildTagParam(props, 'geography', 'geography', 'value'),
      ...buildTagParam(props, 'industries', 'industry', 'value'),
      ...buildTagParam(props, 'contacts', 'harvcoContact', 'id'),
      ...buildTagParam(props, 'company_types', 'companyType', 'value'),
      ...buildTagParam(props, 'states', 'companyState', 'value'),
      ...buildTagParam(props, 'area_codes', 'areaCode', 'value'),
      ...buildDateParam(props, 'next_action_dates', 'nextAction'),
      ...buildActionParam(props, 'next_actions', 'nextAction'),
      ...buildTagParam(props, 'tags', 'harvcoTags', 'value'),
      ...buildTagParam(props, 'countries', 'country'),
      ...buildTagParam(props, 'keywords', 'keywords', 'value'),
      date_range: {
        ...buildDateRangeParam(props, 'start_date', 'startDate'),
        ...buildDateRangeParam(props, 'end_date', 'endDate'),
      },
    };

    if (props.getIn(['mergeResultOnly', 'checked'])) {
      requestParams.merge_result_only = props.getIn(['mergeResultOnly', 'checked']);
    }

    if (props.getIn(['activity', 'lastActivity'])) {
      Object.assign(requestParams, buildDateParam(props, 'last_action_dates', 'activity'));
      Object.assign(requestParams, buildActionParam(props, 'last_actions', 'activity'));
    } else {
      Object.assign(requestParams, buildActivityParam(props, 'activity', 'activity'));
    }

    if (Array.isArray(requestParams.activity)) {
      const users = buildTagParam(props, 'activityUsers', 'activityUsers', 'id');

      if (users) {
        requestParams.activity.push({
          users: users.activityUsers,
        });
      }
    }

    if (props.getIn(['activity', 'buyerType', 'checked'])) {
      if (Array.isArray(requestParams.activity)) {
        const { activity } = requestParams;

        activity.push({
          buyer_type: props.getIn(['activity', 'buyerType', 'selected', 'value']),
        });
        Object.assign(requestParams, { activity });
      }
    }

    if (props.getIn(['activity', 'statusChanged'])) {
      if (Array.isArray(requestParams.activity)) {
        const statusChangedFromValues = props.getIn(['activity', 'statusChangedFrom', 'text']).toJS();
        const statusChangedToValues = props.getIn(['activity', 'statusChangedTo', 'text']).toJS();
        const activity = requestParams.activity.slice();

        activity.push({
          status_changed_from: {
            min: statusChangedFromValues.min,
            max: statusChangedFromValues.max,
          },
        });
        activity.push({
          status_changed_to: {
            min: statusChangedToValues.min,
            max: statusChangedToValues.max,
          },
        });
        Object.assign(requestParams, { activity });
      }
    }

    return requestParams;
  }

  handleChange(data) {
    if (data.filterName === 'sortBy') {
      this.keepSelection = true;
    }

    const extra = data.key ? { key: data.key } : {};

    this.props.handleUpdateFilter({
      ...extra,
      name: 'buyers',
      filterName: data.filterName,
      filterData: data.filterData,
    });
  }

  handleGetSuggestionForStates({ value }) {
    const suggestions = states.filter(
      state =>
        state
          .get('value')
          .toLowerCase()
          .indexOf(value.toLowerCase()) > -1,
    );

    this.props.handleUpdateFilter({
      name: 'buyers',
      filterName: 'companyState',
      filterData: {
        suggestions,
      },
    });
  }

  handleGetSuggestionForActivityUser({ value }) {
    this.props.handleGetUsersSuggestion('buyers', 'activityUsers', value, 1, true);
  }

  handleGetNextSuggestionForActivityUser({ value }) {
    const info = this.props.buyers.getIn(['activityUsers', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetUsersSuggestion('buyers', 'activityUsers', value, currentPage + 1, true);
    }
  }

  handleGetSuggestionForHarvcoContact({ value }) {
    this.props.handleGetHarvCoLeadsSuggestion('buyers', value);
  }

  handleGetNextSuggestionForHarvcoContact({ value }) {
    const info = this.props.buyers.getIn(['harvcoContact', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetHarvCoLeadsSuggestion('buyers', value, currentPage + 1);
    }
  }

  handleGetSuggestionForNextAction({ value }) {
    const suggestions = activities.filter(
      activity =>
        activity
          .get('value')
          .toLowerCase()
          .indexOf(value.toLowerCase()) > -1,
    );

    this.props.handleUpdateFilter({
      name: 'buyers',
      filterName: 'nextAction',
      filterData: {
        suggestions,
      },
    });
  }

  handleGetSuggestionForActivity({ value }) {
    const activities = config.values.getIn(['eventActivities', 'buyer']);
    const suggestions = activities.filter(
      activity =>
        activity
          .get('value')
          .toLowerCase()
          .indexOf(value.toLowerCase()) > -1,
    );

    this.props.handleUpdateFilter({
      name: 'buyers',
      filterName: 'activity',
      filterData: {
        suggestions,
      },
    });
  }

  handleGetSuggestionForCompanyType({ value }) {
    const suggestions = companyTypes.filter(
      companyType =>
        companyType
          .get('value')
          .toLowerCase()
          .indexOf(value.toLowerCase()) > -1,
    );

    this.props.handleUpdateFilter({
      name: 'buyers',
      filterName: 'companyType',
      filterData: {
        suggestions,
      },
    });
  }

  handleBuyerTypeSubCheckboxChange(data) {
    this.props.handleUpdateFilter({
      name: 'buyers',
      filterName: 'buyerType',
      filterData: {
        [data.filterName]: data.filterData,
      },
    });
  }

  handleBuyerActivityChange(data) {
    this.props.handleUpdateFilter({
      name: 'buyers',
      filterName: 'projectActivity',
      filterData: {
        [data.filterName]: data.filterData,
      },
    });
  }

  handleTransactionTypeSubCheckboxChange(data) {
    this.props.handleUpdateFilter({
      name: 'buyers',
      filterName: 'transactionTypes',
      filterData: {
        [data.filterName]: data.filterData,
      },
    });
  }

  handleGetSuggestionForPriority({ value }) {
    const suggestions = [
      { value: 'A', name: 'A' },
      { value: 'B', name: 'B' },
      { value: 'C', name: 'C' },
    ];

    if (value === '') {
      this.props.handleUpdateFilter({
        name: 'buyers',
        filterName: 'priority',
        filterData: {
          suggestions,
        },
      });
    } else {
      this.props.handleUpdateFilter({
        name: 'buyers',
        filterName: 'priority',
        filterData: {
          suggestions: [],
        },
      });
    }
  }

  handleGetSuggestionForCountry({ value }) {
    this.props.handleGetCountrySuggestion('buyers', value);
  }

  handleGetNextSuggestionForCountry({ value }) {
    const info = this.props.buyers.getIn(['country', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetCountrySuggestion('buyers', value, currentPage + 1);
    }
  }

  handleDoubleClickRow(row) {
    window.open(`/company/${row.data.id}`, '_blank');
  }

  onToggle() {
    this.props.updateTabVisibility({
      field: 'buyers.showTabControls',
      value: !this.props.buyers.get('showTabControls'),
    });
  }

  handleClearFilter(name) {
    this.props.handleClearFilter({ name });
  }

  handleDownloadExport(idList, params) {
    const url = `${config.API_BASE_URL}/api/v1/browse/buyers/export`;

    if (idList.length > SAFE_EXPORT_BUYERS_NUMBER) {
      return this.props.handleExportWithSearchCriteria(url, idList, params);
    }

    downloadBrowseFile(url, { selected_ids: idList });
  }

  handleAbortRequest(name) {
    this.props.handleAbortRequest(name);
  }

  //* Check whether current user has access to current tab or not. */
  checkPageAccess() {
    return checkBrowsePageAccess(this.props.currentUser, 'buyers');
  }

  render() {
    const {
      statuses,
      buyers,
      massSelectRows,
      handleTargetsAddTag,
      handleTargetSetNextActions,
      handleTargetPushNextActionsDates,
      handleSaveAddUpdateBuyer,
      handleTargetDelete,
      handleConfirmMerging,
      handleUpdateQueryResult,
      ...rest
    } = this.props;
    const controlClassName = classNames('BrowseControls', { hidden: !buyers.get('showTabControls') });

    const params = {
      requestParams: this.buildRequestParam(buyers),
      stateParams: this.buildFiltersStateParam(buyers),
    };

    const hasParams = !isEmpty(params.requestParams);

    const hasPageAccess = this.checkPageAccess();

    return (
      <div className="tab-pane active" id="browse-buyers" role="tabpanel">
        {hasPageAccess && (
          <div className="BrowseTabs-inner">
            <BrowseTable
              columnDefs={columnDefs}
              contextActionList={contextActionList}
              data={buyers}
              hasParams={hasParams}
              name="buyers"
              onAbortRequest={this.handleAbortRequest}
              onAddTag={handleTargetsAddTag}
              onChange={this.handleChange}
              onClearFilter={this.handleClearFilter}
              onConfirmMerging={handleConfirmMerging}
              onDelete={handleTargetDelete}
              onDoubleClickRow={this.handleDoubleClickRow}
              onDownloadExport={this.handleDownloadExport}
              onGetNextPageData={this.handleGetNextPageData}
              onGetPageData={this.handleGetNextPageData}
              onPushNextActionsDates={handleTargetPushNextActionsDates}
              onSaveAddUpdateBuyer={handleSaveAddUpdateBuyer}
              onSelectRows={massSelectRows}
              onSetNextActions={handleTargetSetNextActions}
              onToggle={this.onToggle}
              onUpdateQueryResult={handleUpdateQueryResult}
              requestParams={params.requestParams}
              stateParams={params.stateParams}
              {...rest}
            />
            <div className={controlClassName}>
              <BrowseCheckbox
                filter={buyersFilter.get('buyerType')}
                info={buyers.get('buyerType')}
                onChange={this.handleChange}
              >
                <div className="BrowseControl-content BrowseControl-content--checkbox">
                  <BrowseCheckbox
                    filter={buyersFilter.getIn(['buyerType', 'financial'])}
                    info={this.props.buyers.getIn(['buyerType', 'financial'])}
                    onChange={this.handleBuyerTypeSubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={buyersFilter.getIn(['buyerType', 'strategic'])}
                    info={this.props.buyers.getIn(['buyerType', 'strategic'])}
                    onChange={this.handleBuyerTypeSubCheckboxChange}
                  />
                </div>
              </BrowseCheckbox>
              <BrowseAutoCompleteTag
                filter={buyersFilter.get('priority')}
                info={buyers.get('priority')}
                onChange={this.handleChange}
                onGetSuggestion={this.handleGetSuggestionForPriority}
              />
              <BrowseStatusesFilter
                filter={buyersFilter.get('currentStatus')}
                info={buyers.get('currentStatus')}
                onChange={this.handleChange}
                statuses={statuses}
              />
              <BrowseStatusesFilter
                filter={buyersFilter.get('highStatus')}
                info={buyers.get('highStatus')}
                onChange={this.handleChange}
                statuses={statuses}
              />
              <BrowseCheckbox
                filter={buyersFilter.get('projectActivity')}
                info={buyers.get('projectActivity')}
                onChange={this.handleChange}
              >
                <div className="BrowseControl-content BrowseControl-content--checkbox">
                  <BrowseBuyerActivityFilter
                    filter={buyersFilter.getIn(['projectActivity', 'contacted'])}
                    info={buyers.getIn(['projectActivity', 'contacted'])}
                    onChange={this.handleBuyerActivityChange}
                  />
                  <BrowseBuyerActivityFilter
                    filter={buyersFilter.getIn(['projectActivity', 'currentLead'])}
                    info={buyers.getIn(['projectActivity', 'currentLead'])}
                    onChange={this.handleBuyerActivityChange}
                  />
                  <BrowseBuyerActivityFilter
                    filter={buyersFilter.getIn(['projectActivity', 'totalLead'])}
                    info={buyers.getIn(['projectActivity', 'totalLead'])}
                    onChange={this.handleBuyerActivityChange}
                  />
                  <BrowseBuyerActivityFilter
                    filter={buyersFilter.getIn(['projectActivity', 'cc'])}
                    info={buyers.getIn(['projectActivity', 'cc'])}
                    onChange={this.handleBuyerActivityChange}
                  />
                  <BrowseBuyerActivityFilter
                    filter={buyersFilter.getIn(['projectActivity', 'visit'])}
                    info={buyers.getIn(['projectActivity', 'visit'])}
                    onChange={this.handleBuyerActivityChange}
                  />
                  <BrowseBuyerActivityFilter
                    filter={buyersFilter.getIn(['projectActivity', 'offer'])}
                    info={buyers.getIn(['projectActivity', 'offer'])}
                    onChange={this.handleBuyerActivityChange}
                  />
                </div>
              </BrowseCheckbox>
              <BrowseInputRange
                filter={buyersFilter.get('revenue')}
                info={buyers.get('revenue')}
                onChange={this.handleChange}
              />
              <BrowseInputRange
                filter={buyersFilter.get('ebitda')}
                info={buyers.get('ebitda')}
                onChange={this.handleChange}
              />
              <BrowseInputRange filter={buyersFilter.get('ev')} info={buyers.get('ev')} onChange={this.handleChange} />
              <BrowseCheckbox
                filter={buyersFilter.get('transactionTypes')}
                info={buyers.get('transactionTypes')}
                onChange={this.handleChange}
              >
                <div className="BrowseControl-content BrowseControl-content--checkbox">
                  <BrowseCheckbox
                    filter={buyersFilter.getIn(['transactionTypes', 'buyout'])}
                    info={buyers.getIn(['transactionTypes', 'buyout'])}
                    onChange={this.handleTransactionTypeSubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={buyersFilter.getIn(['transactionTypes', 'recap'])}
                    info={buyers.getIn(['transactionTypes', 'recap'])}
                    onChange={this.handleTransactionTypeSubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={buyersFilter.getIn(['transactionTypes', 'mezz'])}
                    info={buyers.getIn(['transactionTypes', 'mezz'])}
                    onChange={this.handleTransactionTypeSubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={buyersFilter.getIn(['transactionTypes', 'carveout'])}
                    info={buyers.getIn(['transactionTypes', 'carveout'])}
                    onChange={this.handleTransactionTypeSubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={buyersFilter.getIn(['transactionTypes', 'takeprivate'])}
                    info={buyers.getIn(['transactionTypes', 'takeprivate'])}
                    onChange={this.handleTransactionTypeSubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={buyersFilter.getIn(['transactionTypes', 'minority'])}
                    info={buyers.getIn(['transactionTypes', 'minority'])}
                    onChange={this.handleTransactionTypeSubCheckboxChange}
                  />
                  <BrowseCheckbox
                    filter={buyersFilter.getIn(['transactionTypes', 'turnaround'])}
                    info={buyers.getIn(['transactionTypes', 'turnaround'])}
                    onChange={this.handleTransactionTypeSubCheckboxChange}
                  />
                </div>
              </BrowseCheckbox>
              <BrowseInputTag
                filter={buyersFilter.get('geography')}
                info={buyers.get('geography')}
                onChange={this.handleChange}
                submitOnEnter
              />
              <BrowseInputTag
                filter={buyersFilter.get('industry')}
                info={buyers.get('industry')}
                onChange={this.handleChange}
                isBIC
                submitOnEnter
              />
              <BrowseAutoCompleteTag
                filter={buyersFilter.get('harvcoContact')}
                info={buyers.get('harvcoContact')}
                onChange={this.handleChange}
                onGetNextSuggestion={this.handleGetNextSuggestionForHarvcoContact}
                onGetSuggestion={this.handleGetSuggestionForHarvcoContact}
              />
              <BrowseAutoCompleteTag
                filter={buyersFilter.get('companyType')}
                info={buyers.get('companyType')}
                onChange={this.handleChange}
                onGetSuggestion={this.handleGetSuggestionForCompanyType}
              />
              <BrowseAutoCompleteTag
                filter={buyersFilter.get('companyState')}
                info={buyers.get('companyState')}
                onChange={this.handleChange}
                onGetSuggestion={this.handleGetSuggestionForStates}
              />
              <BrowseInputTag
                filter={buyersFilter.get('areaCode')}
                info={buyers.get('areaCode')}
                onChange={this.handleChange}
                submitOnEnter
              />
              <BrowseActionFilter
                filter={buyersFilter.get('nextAction')}
                info={buyers.get('nextAction')}
                onChange={this.handleChange}
                onGetSuggestion={this.handleGetSuggestionForNextAction}
              />
              <BrowseActivityFilter
                filter={buyersFilter.get('activity')}
                info={buyers.get('activity')}
                onChange={this.handleChange}
                onGetActivityUserSuggestion={this.handleGetSuggestionForActivityUser}
                onGetNextActivityUserSuggestion={this.handleGetNextSuggestionForActivityUser}
                onGetSuggestion={this.handleGetSuggestionForActivity}
                onSubActivityFilterChange={this.handleSubActivityFilterChange}
                projectTypeFilter={buyersFilter.getIn(['activity', 'buyerType'])}
                projectTypeInfo={buyers.getIn(['activity', 'buyerType'])}
                statuses={statuses}
                usersFilter={buyersFilter.getIn(['activity', 'activityUsers'])}
                usersInfo={buyers.get('activityUsers')}
              />
              <BrowseInputTag
                filter={buyersFilter.get('harvcoTags')}
                info={buyers.get('harvcoTags')}
                onChange={this.handleChange}
                submitOnEnter
              />
              <BrowseAutoCompleteTag
                filter={buyersFilter.get('country')}
                info={buyers.get('country')}
                onChange={this.handleChange}
                onGetNextSuggestion={this.handleGetNextSuggestionForCountry}
                onGetSuggestion={this.handleGetSuggestionForCountry}
              />
              <BrowseDateFilter
                filter={buyersFilter.get('startDate')}
                info={buyers.get('startDate')}
                onChange={this.handleChange}
                submitOnEnter
              />
              <BrowseDateFilter
                filter={buyersFilter.get('endDate')}
                info={buyers.get('endDate')}
                onChange={this.handleChange}
                submitOnEnter
              />
              <BrowseInputTag
                filter={buyersFilter.get('keywords')}
                info={buyers.get('keywords')}
                onChange={this.handleChange}
                submitOnEnter
              />
              <BrowseCheckbox
                filter={buyersFilter.get('mergeResultOnly')}
                info={buyers.get('mergeResultOnly')}
                onChange={this.handleChange}
              >
                <>&nbsp;</>
              </BrowseCheckbox>
            </div>
          </div>
        )}
      </div>
    );
  }
}

BrowseBuyers.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    buyers: state.browse.get('buyers'),
    statuses: state.statuses.get('targetStatuses'),
    currentUser: state.auth.get('user'),
  };
}

const mapDispatchToProps = dispatch => ({
  onFetchStatuses: () => dispatch(fetchTargetStatuses()),
  ...bindActionCreators(browseActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions)(BrowseBuyers);
