import { fromJS } from 'immutable';

import * as ActionType from '../../actions/popup/windwardReportsPopup';

const defaultState = fromJS({
  suggests: [],
});

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.CLEAR_USERS:
      return state.merge({ suggests: [] });

    default:
      return state;
  }
}
