import PropTypes from 'prop-types';
import React from 'react';

import Checkbox from '../../helpers/Checkbox';
import Button from '../../helpers/Button';
import FormGroup from '../../helpers/FormGroup';

/**
 * Stateless component for search text input.
 *
 * @param props {Object}.
 * @returns {React.Component}
 * @class
 */
const SearchInput = props => {
  const handleSearch = event => {
    if (event.key === 'Enter') {
      props.onSearch(event.key);
    }
  };
  const searchByUser = props.hasAccess ? (
    <Checkbox
      capitalizeLabel={false}
      id="onlyUserCheckbox"
      label={`Only ${props.userName}`}
      name="onlyOwner"
      onChange={props.onOnlyOwnerChange}
    />
  ) : null;

  return (
    <div className="search__form">
      <div className="input-group">
        <input
          className="form-control search-text"
          id="searchingInput"
          name="searchingTerm"
          onChange={props.onSearchingTermChange}
          onKeyPress={handleSearch}
          placeholder="a search term..."
          value={props.searchingTerm}
        />
        <span className="input-group-btn">
          <Button className="btn btn-default btn-xs" onClick={props.onSearch}>
            <i aria-hidden="true" className="fa fa-search" /> Search
          </Button>
        </span>
      </div>
      <div className="group-checkbox flex mt5">
        {searchByUser}
        <FormGroup>
          <div className="radio-wrap radio-wrap--reverse">
            <label htmlFor="searchByWebsiteCheckbox">Search by Website</label>
            <input
              checked={props.searchBy === 'searchByWebsite'}
              id="searchByWebsiteCheckbox"
              name="searchByWebsite"
              onChange={props.onSearchBy}
              type="checkbox"
              value="searchByWebsite"
            />
          </div>
        </FormGroup>
        <FormGroup className="ml20">
          <div className="radio-wrap radio-wrap--reverse">
            <label htmlFor="searchByPhoneCheckbox">Search by Phone Number</label>
            <input
              checked={props.searchBy === 'searchByPhone'}
              id="searchByPhoneCheckbox"
              name="searchByPhone"
              onChange={props.onSearchBy}
              type="checkbox"
              value="searchByPhone"
            />
          </div>
        </FormGroup>
      </div>
    </div>
  );
};

SearchInput.propTypes = {
  onOnlyOwnerChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSearchingTermChange: PropTypes.func.isRequired,
  searchingTerm: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export default SearchInput;
