import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';

import Checkbox from '../helpers/Checkbox';
import { spy } from '../../utils/ChangeSpy';

/**
 * Stateless component for role dropdown list.
 *
 * @param {object} props Props.
 * @param {string} props.formMode Disabled state of control (view|edit).
 * @param {Immutable.Map} props.selectedUser Current selected user.
 * @returns {React.Component}
 */
const UserInactiveCheckbox = props => {
  const { formMode, selectedUser } = props;

  const disabled = formMode === 'view';
  const inactiveChecked = spy(selectedUser.get('inactive', false));
  const incorrectLoginAttemptsChecked = spy(selectedUser.has('incorrectLoginAttempts'));

  return (
    <div className="group-checkbox mt5">
      <div className="pull-left mr10">
        <Checkbox
          checked={inactiveChecked}
          disabled={disabled}
          id="inactiveCheckbox"
          label="Inactive"
          name="inactive"
          value="inactive"
        />
      </div>
      <div className="pull-left">
        <Checkbox
          checked={incorrectLoginAttemptsChecked}
          disabled={disabled}
          id="resetAttemptLoginCheckbox"
          label="Reset attempts of loggin"
          name="reset"
          value="reset"
        />
      </div>
    </div>
  );
};

UserInactiveCheckbox.propTypes = {
  formMode: PropTypes.string.isRequired,
  selectedUser: PropTypes.instanceOf(Map).isRequired,
};

export default UserInactiveCheckbox;
