import React, { Component } from 'react';
import { Resizable } from 'react-resizable';
import classNames from 'classnames';
import 'react-resizable/css/styles.css';

export default class ResizablePanel extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      width: props.width,
      height: props.height,
    };

    this.onResize = this.onResize.bind(this);
    this.onResizeStop = this.onResizeStop.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.width !== nextProps.width || this.props.height !== nextProps.height) {
      this.setState({
        width: nextProps.width,
        height: nextProps.height,
      });
    }
  }

  onResize(event, extra) {
    const { size } = extra;

    this.setState({ width: size.width, height: size.height });
    if (this.props.onResize) {
      return this.props.onResize(event, extra);
    }
  }

  onResizeStop(...rest) {
    this.props.onResizeEnd(...rest);
  }

  render() {
    const { children, onResizeEnd, className: cls, ...rest } = this.props;
    const { width, height } = this.state;
    const className = classNames('panel panel-default panel-widget', cls);

    return (
      <Resizable
        height={height}
        minConstraints={[560, 70]}
        onResize={this.onResize}
        onResizeStop={this.onResizeStop}
        width={width}
      >
        <div {...rest} className={className} style={this.state}>
          {children}
        </div>
      </Resizable>
    );
  }
}

export const PanelHeader = props => {
  const { className: cls, children, onClose, ...rest } = props;
  const className = classNames('panel-heading', cls);

  return (
    <div {...rest} className={className}>
      {children}
      <button aria-label="close" className="close" onClick={onClose} type="button">
        <span aria-hidden="true"> &times; </span>
      </button>
    </div>
  );
};

export const PanelBody = props => {
  const { className: cls, children, ...rest } = props;
  const className = classNames('panel-body', cls);

  return (
    <div {...rest} className={className}>
      {children}
    </div>
  );
};
