import { CALL_API } from '../middleware/api';

export const FETCHING_EMAIL_TEMPLATES = Symbol('FETCHING_EMAIL_TEMPLATES');
export const FETCHED_EMAIL_TEMPLATES_SUCCESS = Symbol('FETCHED_EMAIL_TEMPLATES_SUCCESS');
export const FETCHED_EXECUTIVES_EMAIL_TEMPLATES_SUCCESS = Symbol('FETCHED_EXECUTIVES_EMAIL_TEMPLATES_SUCCESS');
export const FETCHED_EMAIL_TEMPLATES_FAIL = Symbol('FETCHED_EMAIL_TEMPLATES_FAIL');

export const SAVE_EMAIL_TEMPLATES = Symbol('SAVE_EMAIL_TEMPLATES');
export const SAVE_EMAIL_TEMPLATES_SUCCESS = Symbol('SAVE_EMAIL_TEMPLATES_SUCCESS');
export const SAVE_EMAIL_TEMPLATES_FAIL = Symbol('SAVE_EMAIL_TEMPLATES_FAIL');

export const DELETE_EMAIL_TEMPLATES = Symbol('DELETE_EMAIL_TEMPLATES');
export const DELETE_EMAIL_TEMPLATES_SUCCESS = Symbol('DELETE_EMAIL_TEMPLATES_SUCCESS');
export const DELETE_EMAIL_TEMPLATES_FAIL = Symbol('DELETE_EMAIL_TEMPLATES_FAIL');

export const CLOSE_VALIDATION_ERROR = Symbol('CLOSE_VALIDATION_ERROR');
export const CLEAR_VALIDATION_ERROR = Symbol('CLEAR_VALIDATION_ERROR');

export function fetchEmailTemplates({ userId, afterSuccess }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/users/${userId}/email_templates`,
      startType: FETCHING_EMAIL_TEMPLATES,
      successType: FETCHED_EMAIL_TEMPLATES_SUCCESS,
      errorType: FETCHED_EMAIL_TEMPLATES_FAIL,
      afterSuccess,
    },
  };
}

export function fetchExecutivesEmailTemplates({ userId, afterSuccess }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/users/${userId}/email_templates?type=executive`,
      startType: FETCHING_EMAIL_TEMPLATES,
      successType: FETCHED_EXECUTIVES_EMAIL_TEMPLATES_SUCCESS,
      errorType: FETCHED_EMAIL_TEMPLATES_FAIL,
      afterSuccess,
    },
  };
}

export function createEmailTemplates({ userId, body, afterSuccess, afterError }) {
  return {
    body,
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/users/${userId}/email_templates`,
      body,
      startType: SAVE_EMAIL_TEMPLATES,
      successType: SAVE_EMAIL_TEMPLATES_SUCCESS,
      errorType: SAVE_EMAIL_TEMPLATES_FAIL,
      skipGlobalErrorHandler: true,
      afterSuccess,
      afterError,
    },
  };
}

export function saveEmailTemplates({ id, userId, body, afterSuccess, afterError }) {
  return {
    body,
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/users/${userId}/email_templates/${id}`,
      body,
      startType: SAVE_EMAIL_TEMPLATES,
      successType: SAVE_EMAIL_TEMPLATES_SUCCESS,
      errorType: SAVE_EMAIL_TEMPLATES_FAIL,
      skipGlobalErrorHandler: true,
      afterSuccess,
      afterError,
    },
  };
}

export function deleteEmailTemplates({ id, userId, afterSuccess, afterError }) {
  return {
    [CALL_API]: {
      method: 'delete',
      path: `/api/v1/users/${userId}/email_templates/${id}`,
      startType: DELETE_EMAIL_TEMPLATES,
      successType: DELETE_EMAIL_TEMPLATES_SUCCESS,
      errorType: DELETE_EMAIL_TEMPLATES_FAIL,
      afterSuccess,
      afterError,
    },
  };
}

export function closeValidationError({ field }) {
  return {
    type: CLOSE_VALIDATION_ERROR,
    field,
  };
}

export function clearValidationError({ field }) {
  return {
    type: CLEAR_VALIDATION_ERROR,
    field,
  };
}
