import { fromJS, List } from 'immutable';

import {
  LOAD_INDUSTRIES_SUCCESS,
  INSERT_INDUSTRY_ROW,
  CHANGE_INDUSTRY_ROW_MODE,
  CHANGE_INDUSTRY_ROW_TEXT,
  LOADING_INDUSTRIES_SUGGESTION,
  LOADED_INDUSTRIES_SUGGESTIONS_SUCCESS,
  SELECT_INDUSTRY_SUGGESTION,
  DELETED_INDUSTRY_ROW_SUCCESS,
  SAVED_INDUSTRIES_SUCCESS,
} from '../../actions/project/industry';
import { SAVED_INDUSTRY_SUCCESS } from '../../actions/project/industryPopup';
import { CHANGE_USER_ROW_MODE, INSERT_USER_ROW } from '../../actions/project/user';
import { RESET_ALL_ROW_TO_VIEW_MODE } from '../../actions/project/main';
import { INSERT_APPROVAL_ROW, CHANGE_APPROVAL_ROW_MODE } from '../../actions/project/approval';
import config from '../../config';
import {
  switchToMode,
  addEmptyRow,
  changeText,
  deleteRow,
  switchToView,
  saveSuggestionToRow,
  clearDirty,
} from './common';

const defaultState = fromJS([]);

export default function(state = defaultState, action, common) {
  switch (action.type) {
    case LOAD_INDUSTRIES_SUCCESS:
      return getList(action.response);

    case INSERT_INDUSTRY_ROW:
      return addEmptyRow(state);

    case CHANGE_INDUSTRY_ROW_MODE:
      return switchToMode(state, action.id, action.mode);

    case CHANGE_INDUSTRY_ROW_TEXT:
      return changeText(state, action.id, action.text);

    case SAVED_INDUSTRY_SUCCESS:
      return saveSuggestionToRow(changeText(state, action.id, action.label), {
        ...action,
        suggestionId: action.response.id,
        suggestionName: action.label,
      });

    case DELETED_INDUSTRY_ROW_SUCCESS:
      return deleteRow(state, action.id);

    case INSERT_USER_ROW:

    // fallthrough
    case INSERT_APPROVAL_ROW:

    // fallthrough
    case RESET_ALL_ROW_TO_VIEW_MODE:

    // fallthrough
    case CHANGE_APPROVAL_ROW_MODE:

    // fallthrough
    case CHANGE_USER_ROW_MODE:
      return switchToView(state);

    case LOADING_INDUSTRIES_SUGGESTION:
      return switchToMode(state, action.id, config.LOADING_MODE);

    case LOADED_INDUSTRIES_SUGGESTIONS_SUCCESS:
      return switchToSuggest(state, action, common);

    case SELECT_INDUSTRY_SUGGESTION:
      return saveSuggestionToRow(state, action);

    case SAVED_INDUSTRIES_SUCCESS:
      return clearDirty(state);

    default:
      return state;
  }
}

/**
 * Get a list from response.
 *
 * @param response {Object} Response.
 * @returns {Immutable.List}
 */
function getList(response) {
  if (response.status === 'success') {
    return fromJS(
      response.data.industries.map(u => ({
        id: u.id,
        name: u.industryLabel,
        origin: u.industryLabel,
        mode: config.VIEW_MODE,
        dirty: false,
      })),
    );
  }

  return List();
}

/**
 * Switch a row to suggest mode, check if request id matches before switching.
 *
 * @param state {Immutable.Map} State.
 * @param action {Object} Action.
 * @param common {Immutable.Map} Common state.
 * @returns {Immutable.Map}
 */
function switchToSuggest(state, action, common) {
  if (action.response.status === 'success' && action.requestId === common.get('industrySuggestionRequestId')) {
    return switchToMode(state, action.id, config.SUGGEST_MODE);
  }

  return state;
}
