import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from '@vespaiach/react-datepicker';

const EventPopupInput = props => {
  const { min, max, field, onChange, label, name, type } = props;
  const className = field !== '' ? 'form-control dirty-field' : 'form-control';

  return (
    <div className="">
      <label className="col-md-8 mb5 mt5" htmlFor={name}>
        {label}
      </label>
      {type === 'date' ? (
        <DatePicker
          className={`form-control ${className}`}
          dateFormat="MM/DD/YYYY"
          dropdownMode="select"
          maxDate={max}
          minDate={min}
          onChange={date => {
            onChange(date, name);
          }}
          selected={field}
          showDayInMonthOnly
          showMonthDropdown
          showYearDropdown
        />
      ) : (
        <input
          className={className}
          max={max}
          min={min}
          name={name}
          onChange={event => {
            onChange(event.target.value, name);
          }}
          type={type}
          value={field}
        />
      )}
    </div>
  );
};

EventPopupInput.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
};

export default EventPopupInput;
