import React from 'react';
import classNames from 'classnames';
import { isDeepChanged } from '../../../utils/ChangeSpy';
import FormGroup from '../../helpers/FormGroup';
import _Textarea from '../../helpers/Textarea';
import _Input from '../../helpers/Input';

import PrettyError from '../../decorators/PrettyError';
import DisplayError from '../../decorators/DisplayError';
import FiscalYearTable from './FiscalYearTable';
import EntityType from './EntityType';
import DataQuality from './DataQuality';
import FinancialYearEnd from './FinancialYearEnd';

const Textarea = PrettyError(DisplayError(_Textarea));
const Input = PrettyError(DisplayError(_Input));

/**
 * Stateless component for contact's activities.
 * Display table where rows are activities.
 *
 * @link http://screencloud.net/v/jS6q on page (right top part)
 * @link http://screencloud.net/v/7yc2 component itself
 * @param props {Object}.
 * @param props.events {Immutable.List} List with activities info. If now activities
 *     are on contact - should be an empty List.
 * @returns {React.Component}
 */
const CompanyFinancials = props => {
  const { canEditData, company, onChange, onYearChange, ...rest } = props;

  const headerClass = classNames('panel-title', {
    'pl5 changed': isDeepChanged(company.get('targetFiscalYears')),
  });

  return (
    <div className="panel panel-default">
      <div className="panel-heading" data-parent="#accordion" data-target="#acc-slide-7" data-toggle="collapse">
        <h4 className={headerClass}>Financials</h4>
      </div>
      <div className="panel-collapse collapse acc-slide-7" id="acc-slide-7">
        <div className="panel-body">
          <div className="highlight">
            <form className="row flex-grid flex-grid__start">
              <div className="col-xl-2 col-lg-3 col-md-4">
                <FinancialYearEnd disabled={!canEditData} name="fyeMonth" onChange={onChange} root={company} deep />
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4">
                <DataQuality disabled={!canEditData} name="dataQuality" onChange={onChange} root={company} deep />
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4">
                <EntityType disabled={!canEditData} name="corporationType" onChange={onChange} root={company} deep />
              </div>
            </form>

            <div className="row">
              <div className="container-fluid">
                <FiscalYearTable
                  {...rest}
                  disabled={!canEditData}
                  onChange={onYearChange}
                  targetFiscalYears={company.get('targetFiscalYears')}
                />
              </div>
            </div>

            <form className="row flex-grid mt25">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <div>
                  <b>Balance Sheet Data</b>
                </div>
                <table className="accordion-financials__table table-aligning" role="presentation">
                  <tbody>
                    <tr>
                      <td>
                        <label className="control-label" htmlFor="f-date">
                          Date
                        </label>
                      </td>
                      <td>
                        <FormGroup>
                          <Input
                            disabled={!canEditData}
                            name="balanceSheetDate"
                            onChange={onChange}
                            placeholder="Date"
                            root={company}
                            type="date"
                            deep
                          />
                        </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="control-label" htmlFor="f-cash">
                          Current Cash
                        </label>
                      </td>
                      <td>
                        <FormGroup>
                          <Input
                            disabled={!canEditData}
                            name="balanceSheetCurrentCash"
                            placeholder="Current Cash"
                            root={company}
                            deep
                          />
                        </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="control-label" htmlFor="f-debt">
                          Current Debt
                        </label>
                      </td>
                      <td>
                        <FormGroup>
                          <Input
                            disabled={!canEditData}
                            name="balanceSheetCurrentDebt"
                            placeholder="Current Debt"
                            root={company}
                            deep
                          />
                        </FormGroup>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2">
                <FormGroup>
                  <Input
                    className="company-target__financicals-ebitda"
                    disabled={!canEditData}
                    label="Curr. EBITDA"
                    name="ebitda"
                    placeholder=""
                    root={company}
                    deep
                    showLabel
                  />
                </FormGroup>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
                <FormGroup>
                  <Textarea
                    disabled={!canEditData}
                    label="Financial Notes"
                    name="financialNotes"
                    placeholder="Financial Notes"
                    root={company}
                    rows="5"
                    deep
                    showLabel
                  />
                </FormGroup>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyFinancials;
