import PropTypes from 'prop-types';

import React from 'react';
import { Map } from 'immutable';
import Textarea from '../../components/helpers/Textarea';
import _Input from '../../components/helpers/Input';
import _Select from '../../components/helpers/Select';
import FormGroup from '../../components/helpers/FormGroup';
import FormSubmitOnBlur from '../helpers/FormSubmitOnBlur';
import { InputProps } from '../helpers/AutoComplete';
import LoadAnimation from '../decorators/LoadAnimation';

import PrettyError from '../decorators/PrettyError';
import DisplayError from '../decorators/DisplayError';

const Input = PrettyError(DisplayError(_Input));
const Select = PrettyError(DisplayError(_Select));

/**
 * Contact LP tab.
 *
 * @param props
 * @param props.onChange {Function} On contact lp changed callback.
 * @param props.contactLP {Immutable.Map}.
 * @returns {XML}
 * @class
 */
const ContactLP = props => {
  const { suggestRelation, onChange, onSubmit, onBlur, contactLP } = props;

  const statuses = contactLP.get('statuses');
  const status = contactLP.get('lpCurrentStatus');
  const highStatus = contactLP.get('lpHighStatus') || '';
  const notes = contactLP.get('lpNotes');

  return (
    <div className="tab-pane active" id="slide-4">
      <div className="row col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="table-responsive">
          <FormSubmitOnBlur
            className="form-inline highlight status-bar top-contact-lp-line"
            onChange={onChange}
            onComponentBlur={onBlur}
            onSubmit={onSubmit}
          >
            <FormGroup className="ib mobile-ml0">
              <InputProps
                className="form-control input-sm text-bold"
                id="1233ffr33"
                name="suggestRelation"
                placeholder="Primary Relationship"
              >
                {suggestRelation}
              </InputProps>
            </FormGroup>

            <FormGroup className="ib">
              <Select
                className="input-sm"
                id="f-current-status"
                label="Current Status"
                name="lpCurrentStatus"
                onChange={onChange}
                options={statuses}
                selectClassName="text-bold"
                value={status}
              />
            </FormGroup>

            <FormGroup className="ib">
              <Input
                className="input-sm text-bold"
                id="f-high-status"
                label="High Status"
                name="lpHighStatus"
                placeholder="High Status"
                value={highStatus}
                readOnly
              />
            </FormGroup>
          </FormSubmitOnBlur>

          <div className="clearfix" />
          <FormSubmitOnBlur onChange={onChange} onComponentBlur={onBlur} onSubmit={onSubmit}>
            <FormGroup>
              <Textarea
                className="form-control"
                name="lpNotes"
                onChange={onChange}
                placeholder="Notes"
                rows="4"
                value={notes}
              />
            </FormGroup>
          </FormSubmitOnBlur>
        </div>
      </div>
    </div>
  );
};

ContactLP.propTypes = {
  contactLP: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LoadAnimation(ContactLP);
