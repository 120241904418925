import { fromJS, List } from 'immutable';
import moment from 'moment';

import {
  LOADING_BL_USERS,
  LOADED_BL_USERS_FAILURE,
  LOADED_BL_USERS_SUCCESS,
  LOADING_BL,
  LOADED_BL_FAILURE,
  LOADED_BL_SUCCESS,
  CLEAR_BL,
  UPDATE_BL_USERS,
  SORT_BL_LIST,
  FETCH_NEXT_BL_SUCCESS,
} from '../actions/backlog';
import config from '../config';

const defaultState = fromJS({
  users: [],
  backlogs: [],
  userLoading: false,
  backlogLoading: false,
  columns: config.tables.getIn(['backlog', 'columns']),
  identifier: '',
  totalPages: 0,
  currentPage: 0,
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case LOADING_BL_USERS:
      return state.set('userLoading', true);

    case LOADED_BL_USERS_FAILURE:
      return state.set('userLoading', false);

    case LOADED_BL_USERS_SUCCESS:
      return state.merge({
        userLoading: false,
        users: getUsers(action),
      });

    case LOADING_BL:
      return state.merge({
        backlogLoading: true,
        identifier: action.identifier,
      });

    case LOADED_BL_FAILURE:
      return state.set('backlogLoading', false);

    case LOADED_BL_SUCCESS:
      return getBacklogs(state, action);

    case FETCH_NEXT_BL_SUCCESS:
      return fetchBacklogs(state, action);

    case CLEAR_BL:
      return state.set('backlogs', defaultState.get('backlogs'));

    case UPDATE_BL_USERS:
      return state.set('users', updateUsers(state.get('users'), action));

    case SORT_BL_LIST:
      return state.set('columns', updateColumnSorting(state.get('columns'), action.column, action.direction));

    default:
      return state;
  }
}

/**
 * Fetch backlog list from api response.
 *
 * @param action {Object} Action object.
 * @returns {Immutable.List}
 */
function fetchBacklogs(state, { response: { status, data, meta } }) {
  if (status === 'success') {
    const bls = state.get('backlogs');

    return state.merge({
      backlogLoading: false,
      backlogs: bls.concat(mapBacklog(bls.size, data)),
      totalPages: meta.pagination.totalPages,
      currentPage: meta.pagination.currentPage,
    });
  }

  return state;
}

/**
 * Get backlog list from api response.
 *
 * @param action {Object} Action object.
 * @returns {Immutable.List}
 */
function getBacklogs(state, { response: { status, data, meta }, identifier }) {
  if (status === 'success' && identifier === state.get('identifier')) {
    return state.merge({
      backlogLoading: false,
      backlogs: mapBacklog(0, data),
      totalPages: meta.pagination.totalPages,
      currentPage: meta.pagination.currentPage,
    });
  }

  return state;
}

/**
 * Update column configs.
 */
function updateColumnSorting(columns, column, direction) {
  return columns.map(col => {
    if (col.get('field') === column) {
      return col.set('sort', direction);
    }

    return col.set('sort', '');
  });
}

/**
 * Update user selected states.
 *
 * @param users {Immutable.List} User list.
 * @param action.userId {Number} User id.
 * @param action.selected {Boolean} Selected state.
 * @returns {Immutable.List}
 */
function updateUsers(users, { userId, selected }) {
  const key = users.findKey(user => user.get('id') === userId);

  if (key !== undefined && key >= 0) {
    return users.setIn([key, 'selected'], selected);
  }

  return users;
}

/**
 * Get user list from api response.
 *
 * @param action {Object} Action object.
 * @returns {Immutable.List}
 */
function getUsers({ response: { status, data } }) {
  if (status === 'success') {
    return fromJS(data.map(mapUser));
  }

  return List();
}

/**
 * Map user information.
 *
 * @returns {object}
 */
function mapUser(user) {
  return {
    id: user.id,
    name: user.lastName,
    selected: user.selected,
  };
}

/**
 * Map backlog information.
 *
 * @returns {object}
 */
function mapBacklog(start, backlogs) {
  return fromJS(
    backlogs.map((backlog, index) => {
      const momentDate = moment(backlog.latestDate);

      return {
        order: start + index,
        activity: backlog.activity,
        isLetter: backlog.isletter,
        isEmail: backlog.isemail,
        buyerId: backlog.buyerId,
        buyerLegalName: backlog.buyerLegalName,
        buyerAbbrName: backlog.buyerAbbrName,
        buyerDbaName: backlog.buyerDbaName,
        projectId: backlog.projectId,
        projectCategory: backlog.projectCategory,
        projectExecId: backlog.projectExecId,
        projectExecFLNname: backlog.projectExecFLNname,
        projectHarvcoLeadId: backlog.projectHarvcoLeadId,
        projectHarvcoLeadUserName: backlog.projectHarvcoLeadUserName,
        project: `${backlog.projectCategory} (${backlog.recordOwnerUserName})`,
        recordOwnerId: backlog.recordOwnerId,
        recordOwnerUserName: backlog.recordOwnerUserName,
        recordSubOwnerId: backlog.recordSubOwnerId,
        recordSubOwnerUserName: backlog.recordSubOwnerUserName,
        targetCount: backlog.targetCount,
        latestDate: momentDate.isValid() ? momentDate : null,
      };
    }),
  );
}
