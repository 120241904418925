import { push } from 'connected-react-router';

import { CALL_API, CHAIN_API } from '../../middleware/api';
import { isNew } from '../../utils/uniqueId';
import config from '../../config';
import { reloadEvents } from './contactEvents';

export const MAKE_CONTACT_EXECUTIVE = Symbol('MAKE_CONTACT_EXECUTIVE');
export const MAKE_ERROR_CONTACT_EXECUTIVE = Symbol('MAKE_ERROR_CONTACT_EXECUTIVE');

export const LOAD_CONTACT_EXECUTIVE = Symbol('LOAD_CONTACT_EXECUTIVE');
export const ERROR_CONTACT_EXECUTIVE = Symbol('ERROR_CONTACT_EXECUTIVE');
export const LOADED_CONTACT_EXECUTIVE = Symbol('LOADED_CONTACT_EXECUTIVE');
export const LOAD_CONTACT_EXECUTIVE_POSITIONS = Symbol('LOAD_CONTACT_EXECUTIVE_POSITIONS');
export const LOADED_CONTACT_EXECUTIVE_POSITIONS = Symbol('LOADED_CONTACT_EXECUTIVE_POSITIONS');
export const LOAD_CONTACT_EXECUTIVE_FUNDS = Symbol('LOAD_CONTACT_EXECUTIVE_FUNDS');
export const LOADED_CONTACT_EXECUTIVE_FUNDS = Symbol('LOADED_CONTACT_EXECUTIVE_FUNDS');
export const LOADED_CONTACT_EXECUTIVE_COMPANY = Symbol('LOADED_CONTACT_EXECUTIVE_COMPANY');
export const ERROR_CONTACT_EXECUTIVE_COMPANY = Symbol('ERROR_CONTACT_EXECUTIVE_COMPANY');

export const LOADED_EXEC_USERS = Symbol('LOADED_EXEC_USERS');
export const LOADED_EXEC_COMPANIES = Symbol('LOADED_EXEC_COMPANIES');
export const FETCHING_EXEC_COMPANIES = Symbol('FETCHING_EXEC_COMPANIES');
export const LOADED_EXEC_TITLES = Symbol('LOADED_EXEC_TITLES');
export const LOADING_EXEC_TITLES = Symbol('LOADING_EXEC_TITLES');
export const LOADED_EXEC_PROJECTS = Symbol('LOADED_EXEC_PROJECTS');
export const LOADED_EXEC_INDUSTRIES = Symbol('LOADED_EXEC_INDUSTRIES');
export const CLEAR_SUGGEST = Symbol('CLEAR_SUGGEST');

export const ADD_CONTACT_COMPANY = Symbol('ADD_CONTACT_COMPANY');
export const ADD_CONTACT_POSITION = Symbol('ADD_CONTACT_POSITION');
export const ADD_CONTACT_FUND = Symbol('ADD_CONTACT_FUND');
export const DEL_CONTACT_FUND = Symbol('DEL_CONTACT_FUND');
export const SAVE_CONTACT_FUND = Symbol('SAVE_CONTACT_FUND');
export const SAVE_CONTACT_POSITION = Symbol('SAVE_CONTACT_POSITION');
export const SAVED_CONTACT_EXECUTIVE = Symbol('SAVED_CONTACT_EXECUTIVE');
export const ERRORED_SAVE_CONTACT_EXECUTIVE = Symbol('ERRORED_SAVE_CONTACT_EXECUTIVE');
export const SAVED_CONTACT_INDUSTRY = Symbol('SAVED_CONTACT_INDUSTRY');
export const SAVED_CONTACT_TAGS = Symbol('SAVED_CONTACT_TAGS');
export const MAKE_CONTACT_FUND_ACTIVE = Symbol('MAKE_CONTACT_FUND_ACTIVE');
export const ADD_CONTACT_POSITION_ERROR = Symbol('ADD_CONTACT_POSITION_ERROR');
export const ADD_CONTACT_INDUSTRY = Symbol('ADD_CONTACT_INDUSTRY');
export const DEL_CONTACT_INDUSTRY = Symbol('DEL_CONTACT_INDUSTRY');
export const ADD_CONTACT_TAG = Symbol('ADD_CONTACT_TAG');
export const DEL_CONTACT_TAG = Symbol('DEL_CONTACT_TAG');
export const DEL_CONTACT_POSITION = Symbol('DEL_CONTACT_POSITION');
export const RESET_NEW_POSITION = Symbol('RESET_NEW_POSITION');

export const CHANGE_CONTACT_EXECUTIVE_FIELD = Symbol('CHANGE_CONTACT_EXECUTIVE_FIELD');
export const TRIGGER_CONTACT_ACCORDION = Symbol('TRIGGER_CONTACT_ACCORDION');
export const SET_FIELD_ON_ONE = Symbol('SET_FIELD_ON_ONE');

export const CLOSE_CONTACT_EXEC_VALIDATION_ERROR = Symbol('CLOSE_CONTACT_EXEC_VALIDATION_ERROR');
export const UPDATE_INDUSTRY_TAGS = Symbol('UPDATE_INDUSTRY_TAGS');

export function updateIndustryTags(tags, models) {
  return {
    type: UPDATE_INDUSTRY_TAGS,
    tags,
    models,
  };
}

/**
 * Append exec role to contact.
 *
 * @param id {Number|String} Contact id.
 * @param body {Object} Contact data to send.
 * @param afterSuccess {Function} Callback.
 * @returns {MiddlewareApi.CallApi}
 */
export function makeContactExecutive({ id, ...body }, afterSuccess, afterError) {
  let method = 'put';
  let path = `/api/v1/people/${id}`;

  if (id === 'new') {
    method = 'post';
    path = '/api/v1/people';
  }

  return {
    [CALL_API]: {
      method,
      path,
      body,
      successType: MAKE_CONTACT_EXECUTIVE,
      afterSuccess: ({ dispatch, response, ...other }) => {
        if (id === 'new') {
          dispatch(push(`/contact/${response.id}/executive`));
        }
        afterSuccess({ response, ...other });
      },
      afterError,
      skipGlobalErrorHandler: id === 'new',
      errorType: MAKE_ERROR_CONTACT_EXECUTIVE,
    },
  };
}

/**
 * Load contact executive data.
 *
 * @param id {Number} Contact id.
 * @returns {MiddlewareApi.CallApi}
 */
function loadExecData({ id }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/people/${id}`,
      query: {
        include: [
          'contact_industries',
          'buyer_industries',
          'harvco_tags',
          'contact_industry_categories',
          'contact_business_models',
        ].join(','),
      },
      startType: LOAD_CONTACT_EXECUTIVE,
      successType: LOADED_CONTACT_EXECUTIVE,
      errorType: ERROR_CONTACT_EXECUTIVE,
    },
  };
}

/**
 * Load contact positions.
 *
 * @param id {Number} Contact id.
 * @returns {MiddlewareApi.CallApi}
 */
function loadPositions({ id }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/people/${id}/executive/positions`,
      startType: LOAD_CONTACT_EXECUTIVE_POSITIONS,
      successType: LOADED_CONTACT_EXECUTIVE_POSITIONS,
      errorType: ERROR_CONTACT_EXECUTIVE,
    },
  };
}

/**
 * Load contact funds.
 *
 * @param id {Number} Contact id.
 * @returns {MiddlewareApi.CallApi}
 */
function loadFunds({ id }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/people/${id}/executive/funds`,
      startType: LOAD_CONTACT_EXECUTIVE_FUNDS,
      successType: LOADED_CONTACT_EXECUTIVE_FUNDS,
      errorType: ERROR_CONTACT_EXECUTIVE,
    },
  };
}

/**
 * Load full contact executive data.
 *
 * @param id {Number} Contact id.
 * @returns {MiddlewareApi.ChainApi}
 */
export function loadContactExecutive({ id }) {
  return {
    [CHAIN_API]: [() => loadExecData({ id }), () => loadPositions({ id }), () => loadFunds({ id })],
  };
}

/**
 * Change field in state.
 *
 * @param name {String} Value name to change.
 * @param value {*} New value.
 * @returns {{type: symbol, name: string, value: *}}
 */
export function changeField({ name, value }) {
  return {
    type: CHANGE_CONTACT_EXECUTIVE_FIELD,
    name,
    value,
  };
}

/**
 * Load users by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findUsers({ filter, page, afterSuccess, role }) {
  return {
    filter,
    [CALL_API]: {
      method: 'get',
      path: role ? '/api/v1/browse/users_by_role' : '/api/v1/browse/users',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        role,
        like: filter,
        page,
      },
      successType: LOADED_EXEC_USERS,
      afterSuccess,
    },
  };
}

/**
 * Load directors by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findDirectors({ filter, page, afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users_by_role',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        role: config.DIRECTOR,
        like: filter,
        page,
      },
      successType: LOADED_EXEC_USERS,
      afterSuccess,
    },
  };
}

/**
 * Load directors by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findAnalysts({ filter, page }) {
  return {
    filter,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users_by_role',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        role: config.ANALYST_ASSOCIATE,
        like: filter,
        page,
      },
      successType: LOADED_EXEC_USERS,
    },
  };
}

/**
 * Load industries by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findIndustries({ filter }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/industries',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
      },
      successType: LOADED_EXEC_INDUSTRIES,
    },
  };
}

/**
 * Load companies by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findCompanies({ filter, page, afterSuccess }) {
  return {
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/company_names',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
        page,
      },
      startType: FETCHING_EXEC_COMPANIES,
      successType: LOADED_EXEC_COMPANIES,
      afterSuccess,
    },
  };
}

/**
 * Load funds by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findFunds({ filter, page, afterSuccess }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/buyer_names',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
        page,
      },
      successType: LOADED_EXEC_COMPANIES,
      afterSuccess,
    },
  };
}

/**
 * Load projects by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findProjects({ fundId, page, filter, afterSuccess }) {
  return {
    page,
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/funds/${fundId}/projects`,
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
        page,
      },
      successType: LOADED_EXEC_PROJECTS,
      afterSuccess,
    },
  };
}

/**
 * Load titles by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findTitles({ filter, positionId }) {
  return {
    filter,
    positionId,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/people/executive/titles',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
      },
      startType: LOADING_EXEC_TITLES,
      successType: LOADED_EXEC_TITLES,
    },
  };
}

/**
 * Adds position to contact.
 *
 * @param id {Number} Contact is.
 * @param body {Object} Position data.
 * @param afterSuccess {Function} Callback.
 * @returns {{position: *, MiddlewareApi.ChainApi}}
 */
export function addContactPosition({ id, ...body }, afterSuccess) {
  return {
    [CHAIN_API]: [
      () => ({
        position: body,
        [CALL_API]: {
          method: 'post',
          path: `/api/v1/people/${id}/executive/positions`,
          body,
          successType: ADD_CONTACT_POSITION,
          afterSuccess,
        },
      }),
      () => loadPositions({ id }),
    ],
  };
}

/**
 * Adds fund to contact and reload list.
 *
 * @param id {Number} Contact is.
 * @param body {Object} Position data.
 * @param afterSuccess {Function} Callback.
 * @returns {MiddlewareApi.ChainApi}
 */
export function addContactFund({ id, ...body }, afterSuccess) {
  return {
    [CHAIN_API]: [
      () => ({
        fund: body,
        [CALL_API]: {
          method: 'post',
          path: `/api/v1/people/${id}/executive/funds`,
          body,
          successType: ADD_CONTACT_FUND,
          afterSuccess,
        },
      }),
      () => loadFunds({ id }),
    ],
  };
}

/**
 * Remove fund from contact.
 *
 * @param contactId {Number} Contact is.
 * @param id {Number} Fund id.
 * @param afterSuccess {Function} Callback.
 * @returns {{id: *, MiddlewareApi.CallApi}}
 */
export function delContactFund({ contactId, id }, afterSuccess) {
  return {
    id,
    [CALL_API]: {
      method: 'delete',
      path: `/api/v1/people/${contactId}/executive/funds/${id}`,
      successType: DEL_CONTACT_FUND,
      afterSuccess,
      afterError: afterSuccess,
    },
  };
}

/**
 * Remove position from contact.
 *
 * @param contactId {Number} Contact is.
 * @param id {Number} Position id.
 * @param afterSuccess {Function} Callback.
 * @returns {{id: *, MiddlewareApi.CallApi}}
 */
export function delContactPosition({ contactId, id }, afterSuccess) {
  return {
    id,
    [CALL_API]: {
      method: 'delete',
      path: `/api/v1/people/${contactId}/executive/positions/${id}`,
      successType: DEL_CONTACT_POSITION,
      afterSuccess,
      afterError: afterSuccess,
    },
  };
}

/**
 * Mark fund as active.
 *
 * @param index {Number} Index of fund.
 * @returns {{type: symbol, index: *}}
 */
export function activateContactFund({ index }) {
  return {
    type: MAKE_CONTACT_FUND_ACTIVE,
    index,
  };
}

/**
 * Save contact fund data.
 *
 * @param contactId {Number} Contact id.
 * @param id {Number} Id of fund.
 * @param body {Object} Fund data.
 * @returns {{id: *}}
 */
export function saveContactFund({ contactId, id, ...body }) {
  return {
    id,
    fields: Object.keys(body),
    [CALL_API]: {
      method: 'put',
      unifier: `put /api/v1/people/${contactId}/executive/funds/${id}`,
      path: `/api/v1/people/${contactId}/executive/funds/${id}`,
      body,
      successType: SAVE_CONTACT_FUND,
      afterSuccess: ({ dispatch }) => dispatch(reloadEvents({ entityId: contactId })),
    },
  };
}

/**
 * Save contact position data.
 *
 * @param contactId {Number} Contact id.
 * @param id {Number} Id of position.
 * @param body {Object} Position data.
 * @returns {{id: *, fields: Array}}
 */
export function saveContactPosition({ contactId, id, ...body }) {
  return {
    [CHAIN_API]: [
      () => ({
        id,
        fields: Object.keys(body),
        [CALL_API]: {
          method: 'put',
          unifier: `put /api/v1/people/${contactId}/executive/positions/${id}`,
          path: `/api/v1/people/${contactId}/executive/positions/${id}`,
          body,
          successType: SAVE_CONTACT_POSITION,
        },
      }),
      () => loadPositions({ id: contactId }),
    ],
  };
}

/**
 * @deprecated
 * @param entityId
 * @param id
 * @param body
 * @returns {{}}
 */
export function saveContactCompany({ entityId, id, ...body }) {
  return {
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/companies/${entityId}/entity_contacts/${id}`,
      body,
      successType: ADD_CONTACT_COMPANY,
    },
  };
}

/**
 * Save contact exec data.
 *
 * @param contactId {Number}.
 * @param body {Object} Exec data to save.
 * @returns {{fields: Array}}
 */
export function saveContactExecutive({ contactId, ...body }) {
  return {
    fields: Object.keys(body),
    [CALL_API]: {
      method: 'put',
      unifier: `put /api/v1/people/${contactId}/exec {${JSON.stringify(body)}}`,
      path: `/api/v1/people/${contactId}`,
      body,
      successType: SAVED_CONTACT_EXECUTIVE,
      errorType: ERRORED_SAVE_CONTACT_EXECUTIVE,
      afterSuccess: ({ dispatch }) => dispatch(reloadEvents({ entityId: contactId })),
    },
  };
}

/**
 * Save contact industries.
 *
 * @param contactId {Number} Contact id.
 * @param body {Object[]} Industries to save.
 * @returns {{fields: Array}}
 */
export function saveContactIndustries(contactId, bic, bm) {
  const method = 'post';
  const path = `/api/v1/people/${contactId}/executive/bulk/industries`;
  const data = bic.concat(bm).map(b => ({ industryCategoryId: b, priority: 0 }));

  return {
    [CALL_API]: {
      method,
      unifier: `put /api/v1/people/${contactId}/executive/industries {${JSON.stringify(bic)}}`,
      path,
      body: { data },
      successType: SAVED_CONTACT_INDUSTRY,
    },
  };
}

/**
 * Save contact tags.
 *
 * @param contactId {Number} Contact id.
 * @param body {Object[]} Tags to save.
 * @returns {MiddlewareApi.CallApi}
 */
export function saveContactTags({ contactId, body }) {
  const method = 'post';
  const path = `/api/v1/people/${contactId}/executive/harvco_tags`;

  return {
    [CALL_API]: {
      method,
      path,
      unifier: `put /api/v1/people/${contactId}/executive/harvco_tags {${JSON.stringify(body)}}`,
      body: {
        harvcoTags: body.map(tag => {
          if (isNew(tag.id)) delete tag.id;

          return tag;
        }),
      },
      successType: '',
      afterSuccess: ({ dispatch }) => {
        dispatch({
          [CALL_API]: {
            body,
            fields: body[0],
            method: 'get',
            path: `/api/v1/people/${contactId}`,
            query: {
              include: 'harvco_tags',
            },
            successType: SAVED_CONTACT_TAGS,
          },
        });
      },
    },
  };
}

/**
 * Clear suggests values.
 *
 * @returns {{type: symbol}}
 */
export function clearSuggest() {
  return {
    type: CLEAR_SUGGEST,
  };
}

/**
 * Trigger accordion in ui.
 *
 * @param index
 * @param field
 * @returns {{type: symbol, index: *, field: *}}
 */
export function triggerAccordion({ index, field }) {
  return {
    type: TRIGGER_CONTACT_ACCORDION,
    index,
    field,
  };
}

/**
 * Change deep field in array.
 *
 * @param field {String} Path to array.
 * @param name {String} Field name.
 * @param index {Number} Index in array.
 * @param value {*} New value.
 * @param other {*} Value for other in array.
 * @returns {{type: symbol, field: *, name: *, index: *, value: *, other: *}}
 */
export function setFieldOnOne({ field, name, index, value, other }) {
  return {
    type: SET_FIELD_ON_ONE,
    field,
    name,
    index,
    value,
    other,
  };
}

/**
 * Add industry to contact.
 *
 * @returns {{type: symbol}}
 */
export function addContactIndustry() {
  return {
    type: ADD_CONTACT_INDUSTRY,
  };
}

/**
 * Remove industry from contact.
 *
 * @param contactId {Number|String} Id of contact.
 * @param industryId {Number} Industries id.
 * @param afterSuccess {Function}.
 * @returns {*}
 */
export function delContactIndustry({ contactId, industryId, body = [] }, afterSuccess) {
  if (isNew(industryId)) {
    afterSuccess();

    return {
      id: industryId,
      type: DEL_CONTACT_INDUSTRY,
    };
  }

  const method = 'post';
  const path = `/api/v1/people/${contactId}/executive/industries`;

  return {
    fields: Object.keys(body[0]),
    [CALL_API]: {
      method,
      unifier: `put /api/v1/people/${contactId}/executive/industries {${JSON.stringify(body)}}`,
      path,
      body: { industryTags: body },
      successType: SAVED_CONTACT_INDUSTRY,
      afterSuccess: ({ dispatch, ...rest }) => {
        dispatch({
          id: industryId,
          type: DEL_CONTACT_INDUSTRY,
        });
        afterSuccess({ dispatch, ...rest });
      },
    },
  };
}

/**
 * Add tag to contact.
 *
 * @returns {{type: symbol}}
 */
export function addContactTag() {
  return {
    type: ADD_CONTACT_TAG,
  };
}

/**
 * Remove tag from contact.
 *
 * @param contactId {Number|String} Id of contact.
 * @param tagId {Number} Tag id.
 * @param afterSuccess {Function}.
 * @returns {*}
 */
export function delContactTag({ contactId, tagId }, afterSuccess) {
  if (isNew(tagId)) {
    afterSuccess();

    return {
      id: tagId,
      type: DEL_CONTACT_TAG,
    };
  }

  return {
    id: tagId,
    [CALL_API]: {
      method: 'delete',
      path: `/api/v1/people/${contactId}/executive/harvco_tags/${tagId}`,
      successType: DEL_CONTACT_TAG,
      afterSuccess,
    },
  };
}

export function resetPosition() {
  return {
    type: RESET_NEW_POSITION,
  };
}

export function closeValidationError({ field }) {
  return {
    type: CLOSE_CONTACT_EXEC_VALIDATION_ERROR,
    field,
  };
}
