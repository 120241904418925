import createTable from './CompanyDetailsTable';

export default createTable({
  namePrefix: 'targetFacilities',
  tableName: 'Facilities',
  columns: [
    { field: 'facility' },
    {
      field: 'sqFootage',
      title: 'Sq Ft',
      className: 'text-right',
      type: 'string',
    },
  ],
});
