import PropTypes from 'prop-types';

import React, { Component } from 'react';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import Popups from '../../components/Popup';
import { save, changeLabel } from '../../actions/project/industryPopup';
import { showInformation } from '../../utils/MessagePopup';

/**
 * Popup for creating a new industry.
 *
 * @param props {Object}.
 * @param props.industryLabel {String} Industry label.
 * @param props.status {String(edit|saving|saved)} Popup state.
 * @param props.error {Immutable.List} Error list.
 * @returns {React.Component}
 * @class
 */
class NewIndustryPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleCreate = this.handleCreate.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const initialText = this.props.popup.getIn(['props', 'initialText'], '');

    if (initialText) {
      this.props.changeLabel(initialText);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.status === 'saved') {
      this.context.closePopup();
      this.props.showInformation(this.context.openPopup, 'Saved Successfully!');
    }
  }

  handleCreate() {
    this.props.save({ afterSuccess: this.props.afterClose });
  }

  handleClose() {
    const closeWithoutSave = this.props.popup.getIn(['props', 'closeWithoutSave']);

    if (closeWithoutSave) {
      closeWithoutSave();
    }
    this.context.closePopup();
  }

  handleChange(event) {
    this.props.changeLabel(event.target.value);
  }

  render() {
    const { children, ...rest } = this.props;

    return (
      <div>
        <Popups.NewIndustryPopup
          {...rest}
          onChange={this.handleChange}
          onClose={this.handleClose}
          onCreate={this.handleCreate}
        />
        {children}
      </div>
    );
  }
}

NewIndustryPopupContainer.propTypes = {
  errors: PropTypes.instanceOf(Immutable.List).isRequired,
  industryLabel: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

NewIndustryPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const popupState = state.project.industryPopup;

  return {
    industryLabel: popupState.get('industryLabel'),
    status: popupState.get('status'),
    errors: popupState.get('errors'),
    afterClose: ownProps.popup.getIn(['props', 'afterClose']),
    showInformation,
  };
};

export default connect(
  mapStateToProps,
  {
    save,
    changeLabel,
  },
  mergeProps,
  connectOptions,
)(NewIndustryPopupContainer);
