import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { List, Map } from 'immutable';
import { Link } from 'react-router-dom';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import classNames from 'classnames';

import AutoComplete from '../../helpers/AutoComplete';
import FormSubmitOnBlur from '../../helpers/FormSubmitOnBlur';
import UrlMaker from '../../../routing/UrlMaker';
import Span from '../../helpers/Span';
import { unwrap, isChanged } from '../../../utils/ChangeSpy';
import uniqueId from '../../../utils/uniqueId';
import _Input from '../../helpers/Input';
import FormGroup from '../../helpers/FormGroup';
import LoadAnimation from '../../decorators/LoadAnimation';

import PrettyError from '../../decorators/PrettyError';
import DisplayError from '../../decorators/DisplayError';

const Input = PrettyError(DisplayError(_Input));

const renderSuggestion = suggestion => <span>{suggestion.text}</span>;

const ContextMenuWrapper = onClick => {
  const ContextMenuWrapper = (value, data) => {
    const id = uniqueId();

    return (
      <div>
        <ContextMenuTrigger id={id}>{value}</ContextMenuTrigger>

        <ContextMenu id={id}>
          <MenuItem data={data.toJS()} onClick={onClick}>
            <i className="fa fa-remove" /> Delete
          </MenuItem>
        </ContextMenu>
      </div>
    );
  };

  return ContextMenuWrapper;
};

class ContactExecutiveFund extends PureComponent {
  render() {
    return ContactExecutiveFundRender(this.props);
  }
}

/**
 * Contact executive fund component.
 *
 * @param props {Object}.
 * @param props.fund {Immutable.Map} Fund definition.
 * @returns {XML}
 * @class
 */
const ContactExecutiveFundRender = props => {
  const {
    id = uniqueId(),
    headerClassName: hCls,
    onDel,
    onClick,
    fund,
    onActivate,
    projectFieldName,
    suggestProject,
  } = props;

  const isOpened = fund.get('isOpened');
  const className = classNames('panel-collapse collapse', { in: isOpened });
  const currentStatus = fund.get('currentStatus');
  const highStatus = fund.get('highStatus');

  let headerContent = fund.get('fundLegalName');

  if (isOpened) {
    const companyId = fund.get('fundId');
    const url = UrlMaker.create('/company/:companyId/').mapParams({
      companyId,
    });

    headerContent = (
      <Link target="_blank" to={url}>
        {headerContent}
      </Link>
    );
  }

  const activeState = unwrap(fund.get('activeFund', false)) ? (
    'currently active'
  ) : (
    <a onClick={onActivate}>make active</a>
  );
  const stateClass = classNames('pull-right', {
    changed: isChanged(fund.get('activeFund')),
  });

  const headerClassName = classNames('panel-title', hCls);
  const header = ContextMenuWrapper(onDel)(
    <div className="panel-heading" onClick={onClick}>
      <h4 className={headerClassName}> {headerContent} </h4>
      <span className="pull-right">
        <b>
          <Span checkOn="children">{currentStatus}</Span>
        </b>
        <span> / </span>
        <Span checkOn="children">{highStatus}</Span>
      </span>
    </div>,
    fund,
  );

  return (
    <div className="panel panel-default">
      {header}
      <div className={className} id={id}>
        <div className="panel-body">
          <div className="row highlight">
            <AutoComplete
              checkOn="text"
              formGroupClass="ib"
              getSuggestion={params =>
                suggestProject.onFetch({
                  ...params,
                  fundId: fund.get('fundId'),
                })
              }
              getSuggestionValue={suggestProject.getValue}
              inputProps={{
                className: 'form-control input-sm',
                label: 'Project',
                name: projectFieldName,
              }}
              onSuggestionSelected={(...rest) => suggestProject.onSelect(projectFieldName, ...rest)}
              onUpdateSuggestions={event => suggestProject.onUpdate(projectFieldName, event)}
              renderSuggestion={renderSuggestion}
              suggestions={suggestProject.suggests}
              text={fund.get('eprojectCategory', '')}
            />
            <FormGroup className="ib">
              <Input
                className="input-sm"
                label="Priority"
                name="source"
                placeholder="Priority"
                value=""
                readOnly
                showLabel
              />
              {/* TODO check label */}
            </FormGroup>

            <div className="clearfix" />
            <i className={stateClass}> {activeState} </i>
          </div>
        </div>
      </div>
    </div>
  );
};

ContactExecutiveFundRender.propTypes = {
  fund: PropTypes.instanceOf(Map).isRequired,
  headerClassName: PropTypes.string,
  id: PropTypes.string,
  onActivate: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onDel: PropTypes.func.isRequired,
};

class ContactExecutiveFunds extends PureComponent {
  render() {
    return ContactExecutiveFundsRender(this.props);
  }
}

/**
 * Contact executive funds' list component.
 *
 * @param props {Object}.
 * @param props.funds {Immutable.List} Fund definition.
 * @param props.onClick {Function} On fund's click callback.
 * @param props.addFund {Function} On add Fund click callback.
 * @returns {XML}
 * @class
 */
const ContactExecutiveFundsRender = props => {
  const { funds, id = uniqueId(), onActivate, addFund, onDel, onClick, onSubmit, suggestProject, onBlur } = props;

  const fundsContent = funds.map((fund, i) => (
    <ContactExecutiveFund
      key={i}
      fund={fund}
      headerClassName={classNames({
        'text-bolder': i === 0,
        'text-regular': i !== 0,
      })}
      onActivate={event => onActivate(event, i)}
      onClick={event => onClick(event, i)}
      onDel={onDel}
      parentId={id}
      projectFieldName={`funds.${i}.eprojectCategory`}
      suggestProject={suggestProject}
    />
  ));

  return (
    <div className="accordion-right-side mt10" id={id}>
      <p className="mb0"> Funds </p>
      <FormSubmitOnBlur onComponentBlur={onBlur} onSubmit={onSubmit} focusOnClick>
        {fundsContent}
      </FormSubmitOnBlur>

      <div className="clearfix" />
      <div aria-hidden="true" className="plus-buyer" onClick={addFund}>
        <i className="fa fa-plus-square fa-2x" />
        <span> + Add new fund... </span>
      </div>
    </div>
  );
};

ContactExecutiveFunds.propTypes = {
  addFund: PropTypes.func.isRequired,
  funds: PropTypes.instanceOf(List).isRequired,
  id: PropTypes.string,
  onActivate: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onDel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default LoadAnimation(ContactExecutiveFunds);
