import moment from 'moment';
import { Map } from 'immutable';
import { unwrap } from './ChangeSpy';

/**
 * Sort list by number field.
 *
 * @param list {Immutable.List} List.
 * @param field {string} Field to sort on.
 * @param direction {1|-1} Sorting direction.
 * @returns {Immutable.List}
 */
export function sortByNumber(list, field, direction) {
  return list.sort((a, b) => {
    const valueA = parseFloat(a.get(field, 0)) || 0;
    const valueB = parseFloat(b.get(field, 0)) || 0;

    if (valueA > valueB) {
      return -1 * direction;
    }

    if (valueA < valueB) {
      return Number(direction);
    }

    return 0;
  });
}

/**
 * Sort list by string field.
 *
 * @param list {Immutable.List} List.
 * @param field {string} Field to sort on.
 * @param direction {1|-1} Sorting direction.
 * @returns {Immutable.List}
 */
export function sortByString(list, field, direction) {
  return list.sort((a, b) => {
    const valueA = unwrap(a.get(field, '')).toLowerCase();
    const valueB = unwrap(b.get(field, '')).toLowerCase();

    if (valueA > valueB) {
      return -1 * direction;
    }

    if (valueA < valueB) {
      return Number(direction);
    }

    return 0;
  });
}

function getBoolEqual(val) {
  return val === null ? -1 : val ? 1 : 0;
}

/**
 * Sort list by boolean field.
 *
 * @param list {Immutable.List} List.
 * @param field {string} Field to sort on.
 * @param direction {(1|-1)} Sorting direction.
 * @returns {Immutable.List}
 */
export function sortByBoolean(list, field, direction) {
  return list.sort((a, b) => {
    const valueA = getBoolEqual(a.get(field));
    const valueB = getBoolEqual(b.get(field));

    if (valueA > valueB) {
      return -1 * direction;
    }

    if (valueA < valueB) {
      return Number(direction);
    }

    return 0;
  });
}

/**
 * Sort list by date field.
 *
 * @param list {Immutable.List} List.
 * @param field {string} Field to sort on.
 * @param direction {(1|-1)} Sorting direction.
 * @returns {Immutable.List}
 */
export function sortByDate(list, field, direction) {
  return list.sort((a, b) => {
    const valueA = getSafeDate(unwrap(a.get(field)));
    const valueB = getSafeDate(unwrap(b.get(field)));

    if (valueA.isBefore(valueB)) {
      return Number(direction);
    }

    if (valueA.isAfter(valueB)) {
      return -1 * direction;
    }

    return 0;
  });
}

/**
 * Convert string to date, if not valid, return minimun date.
 *
 * @param date {String} Date.
 * @returns {moment}
 */
const getSafeDate = (date, defaultValue = moment(new Date(-8640000000000000))) => {
  let dt;

  if (Map.isMap(date)) {
    dt = unwrap(moment(date));
  } else {
    dt = moment(date);
  }

  if (!dt.isValid()) {
    return defaultValue;
  }

  return dt;
};

/**
 * Sorting rule for fiscal years or non-numeric years.
 *
 * @param {*} aYear
 * @param {*} bYear
 */
export function nonNumericYearSort(aYear, bYear) {
  const digitReg = /\d/g;
  const charReg = /\D/g;

  if (digitReg.test(aYear)) {
    if (digitReg.test(bYear)) {
      const sub = parseInt(aYear.replace(charReg, ''), 10) - parseInt(bYear.replace(charReg, ''), 10);

      return sub > 0 ? 1 : sub === 0 ? 0 : -1;
    }

    return -1;
  }

  if (digitReg.test(bYear)) {
    return 1;
  }

  return String(aYear).localeCompare(String(bYear));
}

/**
 * Handles ad grid table's sorting model change.
 *
 * @param sortModel Sorting model.
 * @param data Current sorting data.
 * @param onChange Change callback.
 */
export const handleSortModelChanged = (sortModel, data, onChange) => {
  if (sortModel.length > 0) {
    if (['icon', 'selected', 'industry'].includes(sortModel[0].colId)) return;
    if (
      !(
        sortModel[0].colId === data.getIn(['sortBy', 'sortModel', 0, 'colId']) &&
        sortModel[0].sort === data.getIn(['sortBy', 'sortModel', 0, 'sort'])
      )
    ) {
      onChange({
        filterName: 'sortBy',
        filterData: {
          sortModel,
        },
      });
    }
  } else if (data.get('sortBy').get('sortModel').size > 0) {
    onChange({
      filterName: 'sortBy',
      filterData: {
        sortModel,
      },
    });
  }
};
