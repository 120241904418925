import React, { PureComponent } from 'react';

import UrlMaker from '../../../../routing/UrlMaker';
import { getIn } from '../../../../utils/ChangeSpy';

import DropDown, { DropDownElement } from '../../../helpers/DropDown';

export default class CompanyProfile extends PureComponent {
  static getLink(company) {
    return UrlMaker.create('/company/:companyId/buyer').mapParams({
      companyId: company.get('id'),
    });
  }

  getTitle(status) {
    return this.props.statuses.size
      ? this.props.statuses
          .find(statusTmp => getIn(statusTmp, ['value']) === status, null, this.props.statuses.get(0))
          .get('name')
      : '';
  }

  getLine(i) {
    const company = this.props.subsidiaries.get(i);
    const currentStatus = company.get('buyerCurrentStatus', '?');
    const highStatus = company.get('buyerHighStatus', '?');

    return (
      <DropDownElement
        key={i}
        className="company-buyer-profile-dropdown display-block"
        href={CompanyProfile.getLink(company)}
        target="_blank"
      >
        <span className="buyer-profile-dropdown-span">
          <span>{company.get('legalName', '')}</span>
          <span>
            <span title={this.getTitle(currentStatus)}>{currentStatus}</span>/
            <span title={this.getTitle(highStatus)}>{highStatus}</span>
          </span>
        </span>
      </DropDownElement>
    );
  }

  getBtnContent() {
    const company = this.props.subsidiaries.get(0);
    const currentStatus = company.get('buyerCurrentStatus', '?');
    const highStatus = company.get('buyerHighStatus', '?');

    return (
      <span>
        Portfolio
        <span title={this.getTitle(currentStatus)}>{currentStatus}</span>/
        <span title={this.getTitle(highStatus)}>{highStatus}</span>
      </span>
    );
  }

  render() {
    const { className, id, subsidiaries, disabled } = this.props;

    if (!this.props.subsidiaries.get(0)) return null;

    return (
      <div className={className}>
        <DropDown
          btnContent={this.getBtnContent()}
          className="btn btn-xs btn-default"
          disabled={disabled}
          id={id}
          wrapperClassName="portfolio-class"
        >
          {subsidiaries.map((v, i) => this.getLine(i))}
        </DropDown>
      </div>
    );
  }
}
