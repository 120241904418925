import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

const { appName, color } = require('../config');

class NotFound extends Component {
  render() {
    return (
      <div className="full-height">
        <Helmet
          defaultTitle=""
          htmlAttributes={{ lang: 'en' }}
          meta={[
            { charset: 'urt8' },
            { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
            { name: 'description', content: '' },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
            },
            { name: 'msapplication-tap-highlight', content: 'no' },
            { name: 'mobile-web-app-capable', content: 'yes' },
            { name: 'application-name', content: appName },
            { name: 'apple-mobile-web-app-capable', content: 'yes' },
            { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
            { name: 'apple-mobile-web-app-title', content: appName },
            {
              name: 'msapplication-TileImage',
              content: '/assets/img/general/touch/ms-touch-icon-144x144-precomposed.png',
            },
            { name: 'msapplication-TileColor', content: color },
            { name: 'theme-color', content: color },
          ]}
          title="Not Found"
          titleTemplate="%s"
        />
        <p>Page not found.</p>
        <p>
          <Link to="/">Go to Home page.</Link>
        </p>
      </div>
    );
  }
}

export default connect()(NotFound);
