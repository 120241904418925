import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS, Map } from 'immutable';
import shortcut from 'react-shortcut-hoc';

import connectOptions, { mergeProps } from '../../utils/connectOptions';
import InputErrorDisplay from '../decorators/InputErrorDisplay';
import AutoCompleteContainer from '../AutoComplete';
import { unwrap, getIn, getChanged } from '../../utils/ChangeSpy';
import Popups from '../../components/Popup';
import isVisible from '../../utils/isVisible';
import {
  startEditContactEvent,
  changeContactEvent,
  saveContactEvent,
  findDirectors,
  findUsers,
  findAnalysts,
  closeValidationError,
  resetCurrentEvent,
} from '../../actions/contact/contactEvents';
import { publish } from '../../decorators/tabChange';

class EventContactPopupContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onSuggestChange = this.onSuggestChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onActivityChange = this.onActivityChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onClose = this.onClose.bind(this);
    this.isNaExisting = this.isNaExisting.bind(this);
    this.saveCommandCapture = this.saveCommandCapture.bind(this);
    this.getFundInfo = this.getFundInfo.bind(this);
    this.moveFocus = this.moveFocus.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.onCloseEventPopup = this.onCloseEventPopup.bind(this);
    this.handleAddBuyerNote = this.handleAddBuyerNote.bind(this);
    this.shouldPrompt = false;
  }

  componentDidUpdate() {
    if (this.needMoveFocusToNext) this.moveFocus();
    this.needMoveFocusToNext = false;
  }

  componentDidMount() {
    const eventId = this.props.popup.getIn(['props', 'event', 'id']);
    const approach = this.props.popup.getIn(['props', 'approach']);
    const {
      contactInfo,
      contactBuyer,
      contactContacts,
      defaultAnalyst,
      defaultDirector,
      defaultDealMaker,
    } = this.props;
    const extra = {
      defaultUser: {
        id: this.context.currentUser.get('id'),
        text: this.context.currentUser.get('userName'),
      },
    };

    if (!eventId) {
      extra.defaultAnalyst = defaultAnalyst;
      extra.defaultDirector = defaultDirector;
      extra.defaultDealMaker = defaultDealMaker;
    }

    if (this.isNaExisting()) {
      extra.completed = true;
    }

    this.props.startEditContactEvent({
      eventId,
      approach: approach === 'executive' ? 'exec' : approach,
      contactInfo,
      contactBuyer,
      contactContacts,
      ...extra,
    });
    this.context.onClosePopup(this.onCloseEventPopup);
  }

  onCloseEventPopup() {
    if (!this.isNaExisting()) {
      this.context.openPopup('ConfirmPopup', {
        message:
          'Warning: You are about to close this window without setting a Next Action. Are you sure you wish to proceed?',
        onOk: this.onClose,
        onCancel: this.props.popup.getIn(['props', 'onReopen'], () => {}),
        yes: 'Yes',
        no: 'No',
      });
    }
  }

  saveCommandCapture(event) {
    const { events } = this.props;
    const currentEvent = events.get('current');
    const hasEverNext =
      events.get('all').filter(event => event.get('id') !== currentEvent.get('id') && unwrap(event.get('isNext')))
        .size > 0;

    if (this.isValid(currentEvent, hasEverNext)) {
      this.onSave(event);
    }
  }

  isNaExisting() {
    return this.props.events.get('all').filter(event => unwrap(event.get('isNext'))).size > 0;
  }

  onChange(event) {
    const { name, type, checked, value } = event.target;

    this.props.changeContactEvent({
      field: name,
      value: type && type.toUpperCase() === 'CHECKBOX' ? checked : value,
    });

    if (name === 'completed' && checked) {
      this.props.changeContactEvent({
        field: 'userName',
        value: this.props.loggedUser.get('userName'),
      });
      this.props.changeContactEvent({
        field: 'userId',
        value: this.props.loggedUser.get('id'),
      });
    }
  }

  onActivityChange(data) {
    this.props.changeContactEvent({
      field: 'activityMap',
      value: data,
    });
    this.props.changeContactEvent({
      field: 'emailText.value',
      value: '',
    });
    if (data.selected && data.selected.value) {
      this.moveFocus();
    }
  }

  onSuggestChange({ name, value }) {
    return this.props.changeContactEvent({ field: name, value });
  }

  getSuggests() {
    return {
      suggestUser: this.getUserSuggests(),
      suggestDirector: this.getModuleSuggests(),
      suggestAnalyst: this.getAnalystSuggests(),
    };
  }

  getUserSuggests() {
    const prefix = 'current.';

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={opts => {
          this.props.findUsers({
            ...opts,
            field: `${prefix}userName`,
          });
        }}
        idName={`${prefix}userId`}
        inputProps={{
          name: 'userName',
          className: 'form-control',
          label: 'Completed by',
        }}
        name={`${prefix}userName`}
        rootPath={['contact', 'events']}
        suggestsName={`${prefix}userSuggests`}
        valueName={`${prefix}userName`}
      />
    );
  }

  getModuleSuggests() {
    const prefix = 'current.';

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={opts => {
          this.props.findDirectors({
            ...opts,
            field: `${prefix}directorName`,
          });
        }}
        idName={`${prefix}recordOwnerId`}
        inputProps={{
          name: 'directorName',
          placeholder: 'Module',
          className: 'form-control',
          label: 'Team',
        }}
        name={`${prefix}directorName`}
        rootPath={['contact', 'events']}
        suggestsName={`${prefix}directorSuggests`}
        valueName={`${prefix}directorName`}
      />
    );
  }

  getAnalystSuggests() {
    const prefix = 'current.';

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={opts => {
          this.props.findAnalysts({
            ...opts,
            field: `${prefix}analystName`,
          });
        }}
        idName={`${prefix}recordSubOwnerId`}
        inputProps={{
          name: 'analystName',
          placeholder: 'Analyst',
          className: 'form-control',
        }}
        name={`${prefix}analystName`}
        rootPath={['contact', 'events']}
        suggestsName={`${prefix}analystSuggests`}
        valueName={`${prefix}analystName`}
      />
    );
  }

  onSave(event) {
    event.preventDefault();

    const current = getChanged(this.props.events.get('current'));

    if (current.date) {
      current.date = current.date.format('YYYY-MM-DD');
    }

    const { entityType, entityId } = this.props;

    Object.keys(current).forEach(key => {
      if (/Name$/.test(key)) {
        delete current[key];
      }
    });
    delete current.activityMap;

    const body = {
      ...current,
      id: this.props.events.getIn(['current', 'id']),
      approach: this.props.events.getIn(['current', 'approach']),
      activity: this.props.events.getIn(['current', 'activityMap', 'selected', 'name']),
      meta: false,
      backlogged: getIn(this.props.events, ['current', 'backlogged'], false) || false,
    };

    this.getFundInfo(body);

    body.execId = entityId;

    const form = event.target;

    for (let i = 0; i < form.length; i++) {
      if (!isVisible(form[i]) && form[i].name !== 'description') delete body[form[i].name];
    }
    ['recordOwnerId', 'recordSubOwnerId', 'dealMakerId'].forEach(field => {
      body[field] = body[field] || undefined;
    });

    this.props.saveContactEvent({
      entityType,
      entityId,
      body,
      afterSuccess: () => this.promptEvent(body),
    });
  }

  promptEvent(body) {
    this.props.notifyTab(body, 'onEventChange');
    if (!this.isNaExisting() && !this.shouldPrompt) {
      const {
        contactInfo,
        contactBuyer,
        contactContacts,
        defaultAnalyst,
        defaultDirector,
        defaultDealMaker,
      } = this.props;
      const extra = {
        defaultUser: {
          id: this.context.currentUser.get('id'),
          text: this.context.currentUser.get('userName'),
        },
        defaultAnalyst,
        defaultDirector,
        defaultDealMaker,
      };

      this.shouldPrompt = true;
      this.props.startEditContactEvent({
        approach: body.approach === 'executive' ? 'exec' : body.approach,
        contactInfo,
        contactBuyer,
        contactContacts,
        ...extra,
      });
    } else {
      this.onClose();
      this.shouldPrompt = false;
    }
  }

  /**
   * Get fund information if it is a creating new exec next action.
   *
   * @param {*} body: Is data being to post.
   */
  getFundInfo(body) {
    const { funds, events } = this.props;
    const event = events.get('current');
    const key = funds.findKey(f => unwrap(f.get('activeFund')));

    if (!event.get('id') && body.approach === 'exec' && key !== undefined && key !== null) {
      body.buyerId = funds.getIn([key, 'fundId'], 0);
      body.eprojectId = unwrap(funds.getIn([key, 'eprojectId'])) || 0;
    }
  }

  onClose() {
    this.props.resetCurrentEvent();
    this.context.closePopup();
  }

  isValidDate(date) {
    if (!date) return false;

    return !!date.isValid && date.isValid();
  }

  isValid(event, hasEverNext) {
    if (!this.isValidDate(unwrap(event.get('date')))) return false;
    if (hasEverNext && !unwrap(event.get('completed'))) return false;

    return !!unwrap(event.getIn(['activityMap', 'selected', 'value']));
  }

  handleNext() {
    this.moveFocus();
  }

  moveFocus() {
    const element = document.querySelector('textarea[name="description"]');

    // Check if element is visible in DOM, otherwise delay moving focus unstill DOM updated
    if (element && element.offsetParent) {
      element.focus();
    } else {
      this.needMoveFocusToNext = true;
    }
  }

  handleAddBuyerNote() {
    const showEmail = !this.props.events.getIn(['current', 'activityMap', 'selected', 'showEmail']);

    this.props.changeContactEvent({
      field: 'activityMap.selected.showEmail',
      value: showEmail,
    });
  }

  render() {
    const { children, contactInfo, events, funds } = this.props;
    const currentEvent = events.get('current');
    const hasEverNext =
      events.get('all').filter(event => event.get('id') !== currentEvent.get('id') && unwrap(event.get('isNext')))
        .size > 0;
    const contacts = fromJS([
      {
        name: unwrap(contactInfo.get('fullName')),
        value: contactInfo.get('id'),
      },
    ]);
    const canSave = this.isValid(currentEvent, hasEverNext);

    return (
      <div>
        <Popups.EventPopup
          canSave={canSave}
          contacts={contacts}
          event={currentEvent}
          noteText={this.props.popup.getIn(['props', 'noteText'])}
          {...this.getSuggests()}
          funds={funds}
          hasEverNext={hasEverNext}
          loading={currentEvent.get('isLoading')}
          onActivityChange={this.onActivityChange}
          onAddBuyerNote={this.handleAddBuyerNote}
          onChange={this.onChange}
          onClose={this.onClose}
          onNext={this.handleNext}
          onSave={this.onSave}
          shouldPrompt={this.shouldPrompt}
        />
        {children}
      </div>
    );
  }
}

EventContactPopupContainer.contextTypes = {
  currentUser: PropTypes.instanceOf(Map).isRequired,
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    ...props,
    defaultDirector: {
      id: state.contact.executive.getIn(['recordOwnerId', 'value']),
      text: state.contact.executive.getIn(['suggestDirector', 'value']),
    },
    defaultAnalyst: {
      id: state.contact.executive.getIn(['recordSubOwnerId', 'value']),
      text: state.contact.executive.getIn(['suggestAnalyst', 'value']),
    },
    defaultDealMaker: {
      id: state.contact.executive.getIn(['dealMakerId', 'value']),
      text: state.contact.executive.getIn(['suggestDealMaker', 'value']),
    },
    contactInfo: state.contact.info,
    entityId: state.contact.info.getIn(['id']),
    events: state.contact.events,
    inputErrors: state.contact.events.getIn(['current', 'inputErrors']),
    funds: state.contact.executive.get('funds'),
    loggedUser: state.auth.get('user'),
  };
}

export { EventContactPopupContainer };

const shortcutWrap = publish(shortcut(EventContactPopupContainer, 'ctrl+enter', 'saveCommandCapture'));

export default connect(
  mapStateToProps,
  {
    startEditContactEvent,
    changeContactEvent,
    saveContactEvent,
    findDirectors,
    findUsers,
    findAnalysts,
    closeValidationError,
    resetCurrentEvent,
  },
  mergeProps,
  connectOptions,
)(InputErrorDisplay('closeValidationError', shortcutWrap));
