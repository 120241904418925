import PropTypes from 'prop-types';

import React from 'react';
import { Map } from 'immutable';
import classNames from 'classnames';
import uniqueId from '../../utils/uniqueId';
import DeepValue from '../decorators/DeepValue';
import ChangeDetection from '../decorators/ChangeDetection';

/**
 * Textarea input with hidden label.
 * If you need to show label - use `showLabel={true}` property.
 *
 * @param props {Object} You can provide any other available on <textarea/>.
 * @param [props.id = uniqueId()] {string}.
 * @param props.name {string}.
 * @param props.placeholder {string}.
 * @param [props.defaultValue] {any}.
 * @param [props.value] {any} Can be instance of Immutable.Map. Then try to find originalValue and value keys in it.
 * @param [props.originalValue=null] {string|null} Is `null` will not check current value with originalValue.
 *    Else if value !== currentValue mark input by red triangle.
 * @param [props.label] {string|React.Component}.
 * @param [props.className=`''`] {string} Extra textarea`s class names. Need not to add `"form-control"`.
 * @param [props.showLabel=`false`] {boolean}.
 * @returns {React.Component}
 */
const Textarea = DeepValue(
  ChangeDetection(props => {
    const {
      id = uniqueId(),
      label,
      name,
      placeholder,
      showLabel = false,
      changed = false,
      disabled = false,
      canDisplayError,
      ...extraProps
    } = props;

    const values = getValues(props);

    const className = classNames('form-control', props.className, { changed });

    const labelClassName = classNames({ hidden: !showLabel });

    return (
      <div>
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
        <textarea
          disabled={disabled}
          {...extraProps}
          {...values}
          className={className}
          id={id}
          name={name}
          placeholder={placeholder}
        />
      </div>
    );
  }),
);

Textarea.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  id: PropTypes.string,
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  originalValue: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.any,
};

export default Textarea;

function getValues(props) {
  const result = {};

  if ('defaultValue' in props) {
    result.defaultValue = props.defaultValue;
  }

  if ('value' in props) {
    result.value = props.value;
  }

  if (Map.isMap(result.value)) {
    result.value = result.value.get('value');
  }

  return result;
}
