import React from 'react';
import Popup from '../helpers/Popup';
import Button from '../helpers/Button';

const LogoutPopup = props => {
  const { popup } = props;
  const { onConfirm, onCancel } = popup.get('props').toJS();
  const header = 'Log Out';
  const footer = [
    <Button key="confirm" className="btn-primary btn-xs" data-dismiss="modal" onClick={onConfirm}>
      Confirm
    </Button>,
    <Button key="cancel" className="btn-default btn-xs" data-dismiss="modal" onClick={onCancel} autoFocus>
      Cancel
    </Button>,
  ];

  return (
    <Popup footer={footer} header={header} id="logout">
      <div className="row media-450">
        <p>Do you really want to log out?</p>
      </div>
    </Popup>
  );
};

export default LogoutPopup;
