import PropTypes from 'prop-types';

import React from 'react';
import Immutable from 'immutable';
import { ContextMenuTrigger } from 'react-contextmenu';
import config from '../../../config';
import Input from '../../helpers/Input';
import { getIn } from '../../../utils/ChangeSpy';

/**
 * Column of one row table component.
 *
 * @param props {Object}.
 * @param props.row {Immutable.Map} Row Object.
 * @param props.contextId {String} Context menu's id.
 * @param props.type {String} Use type to differentiate which tables are firing events.
 * @param props.onTextChange {Function} Handle event of changing text.
 * @param props.onRowClick {Function} Handle event of clicking on a row.
 * @param props.onKeyPress {Function} Handle event of key pressing.
 * @returns {React.Component}
 * @class
 */
const TemplateTableRow = props => {
  const { row, type, contextId, onTextChange, onRowClick, setInputElement, onTemplateUpdate, onKeyPress } = props;
  const id = row.get('id');
  const mode = row.get('mode');

  if (mode === config.EDIT_MODE) {
    return (
      <tr>
        <td className="cell" onClick={event => event.stopPropagation()}>
          <Input
            className="form-control form-control--table"
            id={`input_${type}${id}`}
            inputRef={setInputElement}
            label="template"
            name="templateName"
            onBlur={event => onTemplateUpdate({ event, row })}
            onChange={event => onTextChange({ event, data: { type, row } })}
            onKeyPress={event => onKeyPress({ event, row })}
            placeholder="file name"
            showLabel={false}
            value={getIn(row, 'name')}
          />
        </td>
      </tr>
    );
  }

  return (
    <ContextMenuTrigger collect={() => ({ id, type })} id={contextId} renderTag="tr">
      <td
        className="cell"
        onClick={event => {
          event.stopPropagation();
          onRowClick({ row, type });
        }}
      >
        {getIn(row, 'name')}
      </td>
    </ContextMenuTrigger>
  );
};

TemplateTableRow.propTypes = {
  contextId: PropTypes.string.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onTemplateUpdate: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  row: PropTypes.instanceOf(Immutable.Map).isRequired,
  setInputElement: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default TemplateTableRow;
