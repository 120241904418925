import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import resetOnLogout from './decorators/resetOnLogout';
import resetOnLogin from './decorators/resetOnLogin';
import contact from './contact';
import search from './search';
import user from './user';
import auth from './auth';
import nextAction from './nextAction';
import targetCompany from './company';
import companyTradeGroups from './companyTradeGroups';
import dashboards from './dashboards';
import popup from './popup';
import browse from './browse';
import project from './project';
import approval from './approval';
import autoComplete from './autoComplete';
import applicationErrors from './applicationErrors';
import buyerPopup from './buyerPopup';
import backlog from './backlog';
import mailingHistory from './mailingHistory';
import mailingCreate from './mailingCreate';
import mailingInfo from './mailingInfo';
import countries from './countries';
import tags from './tags';
import statuses from './statuses';
import industryTags from './industryTags';
import businessModels from './businessModels';
import oneOff from './oneOff';
import verifyEmail from './verifyEmail';
import findEmail from './findEmail';
import windward from './windward';
import tradeShow from './tradeShow';
import statistics from './statistics';
import leadsReport from './leadsReport';
import windwardReportsPopup from './popup/windwardReportsPopup';
import emailTemplates from './emailTemplates';

const createRootReducer = history =>
  resetOnLogout(
    combineReducers({
      router: connectRouter(history),
      contact,
      search,
      user,
      auth,
      nextAction,
      dashboards,
      popup: resetOnLogin(popup),
      browse,
      targetCompany,
      tradeShow,
      statistics,
      companyTradeGroups,
      project,
      approval,
      autoComplete,
      applicationErrors: resetOnLogin(applicationErrors),
      buyerPopup,
      mailingHistory,
      mailingCreate,
      mailingInfo,
      backlog,
      countries,
      tags,
      statuses,
      industryTags,
      businessModels,
      oneOff,
      verifyEmail,
      findEmail,
      windward,
      leadsReport,
      windwardReportsPopup,
      emailTemplates,
    }),
  );

export default createRootReducer;
