import { FETCHING_USER_SUCCESS, FETCHING_USER } from '../../actions/auth';

/**
 * Always check if current action is auth and remove state.
 *
 * @param reducer {Function} Reducer.
 * @returns {Function}
 */
export default function resetOnLogin(reducer) {
  return function(state, action) {
    if (action.type === FETCHING_USER_SUCCESS) {
      state = undefined;
    } else if (action.type === FETCHING_USER) {
      state = undefined;
    }

    return reducer(state, action);
  };
}
