import moment from 'moment';
import { isIsoString } from './isISOString';

/**
 * Check object's fields on ISO date format and transform it to the moment() instance.
 *
 * @param {object} obj Input object.
 */
export function momentizeObjValues(obj) {
  Object.keys(obj).map(key => {
    if (isIsoString(obj[key])) {
      obj[key] = moment(obj[key]);
    }
  });
}
