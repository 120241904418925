import React, { PureComponent } from 'react';
import classNames from 'classnames';

import CompanyContacts from '../CompanyContacts';
import CompanyHarvcoTags from '../Details/CompanyHarvcoTags';
import FormGroup from '../../helpers/FormGroup';
import _Input from '../../helpers/Input';
import BuyerDealNotes from './BuyerDealNotes';
import PrettyError from '../../decorators/PrettyError';
import DisplayError from '../../decorators/DisplayError';
import _Broad from '../Details/Broad';
import _Textarea from '../../helpers/Textarea';
import { InputProps } from '../../helpers/AutoComplete';
import { createIndustryErrorMsg } from '../../../utils/industryTagsHelper';
import { TableFiles } from '../Files/TableFiles';

const Broad = PrettyError(DisplayError(_Broad));
const Textarea = PrettyError(DisplayError(_Textarea));
const Input = PrettyError(DisplayError(_Input));

export default class Marketing extends PureComponent {
  constructor(props) {
    super(props);

    this.inputSubmitRef = React.createRef();
    this.handleOpenFileDialog = () => {
      if (this.inputSubmitRef) {
        this.inputSubmitRef.current.click();
      }
    };
  }

  render() {
    const { props } = this;
    const {
      contactsProps,
      companyInfo: company,
      buyerType,
      dealNotes,
      details,
      detailsCallbacks: callbacks,
      onChange,
      onEnterDealNotes,
      onLeaveDealNotes,
      canEditData,
      hasPermissions,
      fetchEmailTemplates,
      findDirectors,
      emailTemplates,
      error,
      businessModels,
      industryCategories,
      onClick,
      onErrorClose,
      suggestResearcher,
      onDeleteFile,
      uploadFile,
      files,
    } = props;

    const buyerDealNotesProps = {
      buyerDealNotes: dealNotes,
      onMouseEnter: onEnterDealNotes,
      onMouseLeave: onLeaveDealNotes,
    };

    const revenClassName = classNames('col-xs-6', 'pb20', {
      hide: /financial/i.test(buyerType),
    });

    const errorMsg = createIndustryErrorMsg(error);

    const disabled = !canEditData || !hasPermissions;

    return (
      <div className="tab-pane active tab-target" id="buy-slide-1">
        <div className="col-xs-7 full-height deal-notes">
          {hasPermissions && <BuyerDealNotes {...buyerDealNotesProps} />}
        </div>
        <div className="col-xs-5 flex-through full-height">
          <div className="row flex-through full-height">
            <div className="right-siderbar flexItem full-height">
              <div className="col-md-12">
                <CompanyContacts
                  {...contactsProps}
                  canEditData={canEditData}
                  companyInfo={company}
                  emailTemplates={emailTemplates}
                  fetchEmailTemplates={fetchEmailTemplates}
                  findDirectors={findDirectors}
                  hasPermissions={hasPermissions}
                />
              </div>

              <form>
                <div className="col-xs-6 mb8">
                  <FormGroup>
                    <Textarea
                      className="pb6"
                      disabled={disabled}
                      name="buyerDescription"
                      onChange={onChange}
                      placeholder="Buyer Description"
                      root={company}
                      rows="5"
                      deep
                    />
                  </FormGroup>
                </div>
                <div className="col-xs-6 mb8">
                  <div className="row">
                    <div className="col-xs-6">
                      <FormGroup>
                        <InputProps
                          className="form-control input-sm text-bold"
                          disabled={disabled}
                          placeholder="Last Researcher"
                        >
                          {suggestResearcher}
                        </InputProps>
                      </FormGroup>
                    </div>
                    <div className="col-xs-6">
                      <FormGroup>
                        <Input
                          className="input-sm bold-control-md"
                          disabled={disabled}
                          name="buyerLastResearchedDate"
                          onChange={onChange}
                          placeholder="Last Researched Date"
                          popoverAttachment="top right"
                          popoverTargetAttachment="bottom right"
                          root={company}
                          type="date"
                          deep
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <FormGroup>
                    <Textarea
                      disabled={disabled}
                      name="buyerResearchNotes"
                      onChange={onChange}
                      placeholder="Buyer Research Notes"
                      root={company}
                      rows="4"
                      deep
                    />
                  </FormGroup>
                </div>
              </form>
              <div className="table-responsive col-sm-12">
                <CompanyHarvcoTags
                  {...callbacks}
                  canEditData={canEditData && hasPermissions}
                  data={details.get('harvcoTags')}
                  disabled={disabled}
                />
              </div>

              <div className="col-sm-12">
                <Broad
                  businessModels={businessModels}
                  data={industryCategories}
                  deprecatedIndustries={[]}
                  disabled={disabled}
                  error={errorMsg}
                  name="industryCategories"
                  onClick={onClick}
                  onErrorClose={onErrorClose}
                  canDisplayError
                />
              </div>

              <form className="info">
                <div className={revenClassName}>
                  <FormGroup>
                    <Input
                      className="input-sm"
                      disabled={disabled}
                      label="Revenue"
                      name="revenue"
                      onChange={onChange}
                      placeholder="Revenue"
                      root={company}
                      deep
                      showLabel
                    />
                  </FormGroup>
                </div>
                <div className={revenClassName}>
                  <FormGroup>
                    <Input
                      className="input-sm"
                      disabled={disabled}
                      label="Employees"
                      name="employees"
                      onChange={onChange}
                      placeholder="Employees"
                      root={company}
                      deep
                      showLabel
                    />
                  </FormGroup>
                </div>
              </form>

              <div className="col-sm-12 mb20">
                <div className="panel panel-default accordion-middle-side">
                  <div className="panel-heading" data-target="#acc-slide" data-toggle="collapse">
                    <h4 className="panel-title">Files</h4>
                  </div>
                  <div className="panel-collapse collapse" id="acc-slide">
                    <div className="panel-body">
                      <form className="p20" onSubmit={uploadFile}>
                        <TableFiles
                          canEditData={canEditData && hasPermissions}
                          files={files}
                          onDeleteFile={onDeleteFile}
                          onUploadFile={this.handleOpenFileDialog}
                        />
                        <button ref={this.inputSubmitRef} className="hidden" type="submit" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
