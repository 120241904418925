import buyerStatuses from './buyerStatuses.json';
import targetStatuses from './targetStatuses.json';
import emailTemplate from './emailTemplate.json';
import tickerExchange from './tickerExchange.json';
import countries from './countries.json';
import firstLineTargetActivities from './firstLineTargetActivities.json';

export default {
  tickerExchange,
  buyerStatuses,
  targetStatuses,
  emailTemplate: emailTemplate.join(''),
  countries,
  firstLineTargetActivities,
};
