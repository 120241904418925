import React from 'react';
import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import Input from '../helpers/Input';

const NewTargetCompanyPopup = () => {
  const header = 'New Target Company Profile';
  const footer = (
    <button className="btn btn-primary btn-xs" data-dismiss="modal" type="button">
      Create
    </button>
  );

  return (
    <Popup footer={footer} header={header} id="newTarget">
      <div className="row media-450">
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="f-module"
            label="Module"
            name="module"
            placeholder="Module"
            autoFocus
            required
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-xs-6">
          <Input
            className="input-sm"
            id="f-analyst"
            label="Analyst/Associate"
            name="analyst"
            placeholder="Analyst/Associate"
            required
            showLabel
          />
        </FormGroup>
        <div className="row media-450">
          <FormGroup className="col-lg-6 col-xs-6">
            <Input className="input-sm" id="k-name" label="Name" name="name" placeholder="Name" required showLabel />
          </FormGroup>
          <FormGroup className="col-lg-6 col-xs-6">
            <Input className="input-sm" id="k-abbr" label="Abbr" name="abbr" placeholder="Abbr" required showLabel />
          </FormGroup>
        </div>
        <div className="row media-450">
          <FormGroup className="col-lg-6 col-xs-6">
            <Input
              className="input-sm"
              id="k-dba"
              label="DBA/Alt."
              name="dba"
              placeholder="DBA/Alt"
              required
              showLabel
            />
          </FormGroup>

          <FormGroup className="col-lg-6 col-xs-6">
            <Input className="input-sm" id="k-web" label="Web" name="web" placeholder="Web" required showLabel />
          </FormGroup>
        </div>
        <div className="row media-450">
          <FormGroup className="col-lg-6 col-xs-6">
            <Input
              className="input-sm"
              id="k-phone"
              label="Phone"
              name="phone"
              placeholder="Phone"
              required
              showLabel
            />
          </FormGroup>
          <FormGroup className="col-lg-6 col-xs-6">
            <Input
              className="input-sm"
              id="k-address"
              label="Address/Alt."
              name="address"
              placeholder="Address"
              required
              showLabel
            />
          </FormGroup>
        </div>
        <div className="row media-450">
          <FormGroup className=" col-lg-4 col-xs-4">
            <Input className="input-sm" id="k-city" label="City" name="dba" placeholder="City" required showLabel />
          </FormGroup>
          <FormGroup className="mt24 col-lg-4 col-xs-4">
            <select className="form-control input-sm">
              <option> sk</option>
              <option> ty</option>
              <option> ru</option>
            </select>
          </FormGroup>

          <FormGroup className="input-group mt24 city-index2 container col-lg-4 col-xs-4">
            <input className="form-control input-sm" id="index-city" placeholder="Index" type="text" required />
            <span className="input-group-addon">
              <i aria-hidden="true" className="fa fa-plus" />
              <i aria-hidden="true" className="fa fa-minus hide" />
            </span>
          </FormGroup>
        </div>

        <div className="row">
          <FormGroup className="hide f-country col-lg-12 col-sm-12">
            <Input
              className="input-sm"
              id="f-country"
              label="Country"
              name="country"
              placeholder="Country"
              required
              showLabel
            />
          </FormGroup>
        </div>
        <div className="row media-450">
          <FormGroup className=" col-lg-4 col-xs-4">
            <Input
              className="input-sm"
              id="k-revenue"
              label="Revenue"
              name="revenue"
              placeholder="Revenue"
              required
              showLabel
            />
          </FormGroup>
          <FormGroup className=" col-lg-4 col-xs-4">
            <Input
              className="input-sm"
              id="k-employee"
              label="Employees"
              name="employee"
              placeholder="Employees"
              required
              showLabel
            />
          </FormGroup>
          <FormGroup className=" col-lg-4 col-xs-4">
            <label className="control-label" htmlFor="k-type">
              Type
            </label>
            <select className="form-control input-sm" id="k-type">
              <option> Mustard</option>
              <option> Ketchup</option>
              <option> Relish</option>
            </select>
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup className=" col-lg-12 col-sm-12">
            <label className="control-label" htmlFor="k-description">
              Description
            </label>
            <textarea className="form-control" id="k-description" placeholder="Description" rows="4" />
          </FormGroup>
        </div>
      </div>
    </Popup>
  );
};

export default NewTargetCompanyPopup;
