import React, { PureComponent } from 'react';
import DatePicker from '@vespaiach/react-datepicker';

class DateEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);

    const dt = this.props.value.value;

    this.state = {
      value: dt && dt.isValid() ? dt : null,
    };
  }

  getValue() {
    return Object.assign({}, this.props.value, { value: this.state.value });
  }

  onChange(date) {
    this.setState({
      value: date,
    });
  }

  isPopup() {
    return false;
  }

  render() {
    const { value } = this.state;

    return (
      <DatePicker
        className="form-control input-sm"
        dropdownMode="select"
        onChange={this.onChange}
        selected={value}
        showDayInMonthOnly
        showMonthDropdown
        showYearDropdown
      />
    );
  }
}

export default DateEditor;
