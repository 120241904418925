import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import $ from 'jquery';
import { fromJS } from 'immutable';

import LoadAnimation from '../../decorators/LoadAnimation';

import _Input from '../../helpers/Input';
import _Select from '../../helpers/Select';
import { InputProps } from '../../helpers/AutoComplete';
import FormGroup from '../../helpers/FormGroup';
import FormSubmitOnBlur from '../../helpers/FormSubmitOnBlur';
import PrettyError from '../../decorators/PrettyError';
import DisplayError from '../../decorators/DisplayError';

import ContactExecutiveDealNotes from './ContactExecutiveDealNotes';
import ContactExecutiveInfo from './ContactExecutiveInfo';
import ContactExecutiveDetailsPanel from './ContactExecutiveDetailsPanel';
import ContactExecutiveEmailPanel from './ContactExecutiveEmailPanel';
import ContactExecutiveFunds from './ContactExecutiveFunds';
import ContactExecutivePositions from './ContactExecutivePositions';

const Input = PrettyError(DisplayError(_Input));
const Select = PrettyError(DisplayError(_Select));

class ContactExecutive extends PureComponent {
  componentDidMount() {
    $(window).on('resize', this.setPositionPanel);
    this.setPositionPanel();
  }

  componentWillUnmount() {
    $(window).off('resize', this.setPositionPanel);
  }

  setPositionPanel() {
    $('#position_right_panel').height($('#exec_row_panel').height());
  }

  render() {
    return ContactExecutiveRender(this.props);
  }
}

const ContactExecutiveRender = props => {
  const {
    highestFundIndex,
    suggestDirector,
    suggestAnalyst,
    suggestDealmaker,
    suggestResearcher,

    dealNotes,
    emails,

    contact,
    statuses,
    qualities,
    interests,
    relations,
    qualitiesOfExecutive,

    suggestIndustry,
    delIndustry,
    onSubmit,
    onBlur,
    onBlurDetails,
    onSubmitDetails,
    onClickIndustries,
    addIndustry,
    onClickTags,
    addTag,
    delTag,

    onEmailMouseEnter,
    onDialNotesMouseEnter,
    onEmailMouseLeave,
    onDialNotesMouseLeave,

    addFund,
    triggerFund,
    delFund,
    submitFund,
    onBlurFund,
    activateFund,
    suggestProject,

    suggestTitles,
    addPosition,
    triggerPosition,
    submitPosition,
    onBlurPosition,
    delPosition,

    onChange,
  } = props;

  const loadingFunds = contact.get('loadingFunds');
  const funds = contact.get('funds');
  const lastResearchedDate = contact.get('lastResearchedDate');
  const loadingPositions = contact.get('loadingPositions');
  const positions = contact.get('positions');

  const suggestions = contact.get('suggests');
  const employmentStatus = contact.get('isEmployed')
    ? 'Employed'
    : contact.get('isEmployed') === false
    ? 'Transition'
    : null;
  const fundPrefix = `funds.${highestFundIndex}`;

  return (
    <div className="tab-pane active full-height" id="slide-3">
      <div className="row">
        <FormSubmitOnBlur
          className="form-inline status-bar"
          onChange={onChange}
          onComponentBlur={onBlur}
          onSubmit={onSubmit}
        >
          <div className="row d-flex">
            <div className="col-xs-12 col-sm-3 col-xl-2">
              <InputProps className="form-control input-sm text-bold" name="suggestDirector" placeholder="Module">
                {suggestDirector}
              </InputProps>
            </div>

            <div className="col-xs-12 col-sm-3 col-xl-2">
              <InputProps className="form-control input-sm text-bold" name="suggestAnalyst" placeholder="Dealmaker 1">
                {suggestAnalyst}
              </InputProps>
            </div>

            <div className="col-xs-12 col-sm-3 col-xl-2">
              <InputProps className="form-control input-sm text-bold" name="suggestDealmaker" placeholder="Dealmaker 2">
                {suggestDealmaker}
              </InputProps>
            </div>

            <div className="col-xs-12 col-sm-6 col-xl-2">
              <FormGroup>
                <Select
                  emptyVal=""
                  id="f-status"
                  label="Status"
                  name="currentStatus"
                  options={fromJS(statuses)}
                  prefix="funds.0"
                  root={contact}
                  selectClassName="input-sm text-bold"
                  deep
                  showValue
                />
              </FormGroup>
            </div>

            <div className="col-xs-12 col-sm-3 col-xl-2">
              <FormGroup>
                <Input
                  className="input-sm text-bold"
                  emptyVal=""
                  id="f-High"
                  label="High"
                  name="highStatus"
                  placeholder="High"
                  prefix={fundPrefix}
                  root={contact}
                  deep
                  readOnly
                />
              </FormGroup>
            </div>

            <div className="pt2 col-xs-12 col-sm-2 col-xl-1">
              <FormGroup>
                <span
                  className={classNames({
                    'text-bold': contact.get('isEmployed') === false,
                  })}
                >
                  {' '}
                  {employmentStatus}{' '}
                </span>
              </FormGroup>
            </div>

            <div className="col-xs-12 text-right">
              <FormGroup>
                <InputProps
                  className="form-control input-sm text-bold"
                  name="suggestResearcher"
                  placeholder="Researcher"
                >
                  {suggestResearcher}
                </InputProps>
              </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-2 col-xl-1">
              <FormGroup>
                <Input
                  className="input-sm"
                  id="f-researched-date"
                  label="Last Researched"
                  name="lastResearchedDate"
                  onChange={onChange}
                  placeholder="Last Researched"
                  type="date"
                  value={lastResearchedDate}
                />
              </FormGroup>
            </div>
          </div>
        </FormSubmitOnBlur>
      </div>

      <div
        className="flex-grid flex-wrap-md row mt10 p-relative"
        id="exec_row_panel"
        style={{ height: 'calc(100% - 30px)' }}
      >
        <ContactExecutiveDealNotes
          dealNotes={dealNotes}
          onMouseEnter={onDialNotesMouseEnter}
          onMouseLeave={onDialNotesMouseLeave}
        />
        <section className="flex-grow1 container-fluid">
          <div className="row">
            <div className="col-xs-6 col-xl-7 col-xxl-8">
              <FormSubmitOnBlur
                className="panel-group accordion-middle-side"
                id="accordion"
                onComponentBlur={onBlurDetails}
                onSubmit={onSubmitDetails}
              >
                <ContactExecutiveInfo
                  contact={contact}
                  interests={interests}
                  onChange={onChange}
                  qualities={qualities}
                  qualitiesOfExecutive={qualitiesOfExecutive}
                  relations={relations}
                />

                <ContactExecutiveDetailsPanel
                  addIndustry={addIndustry}
                  addTag={addTag}
                  contact={contact}
                  delIndustry={delIndustry}
                  delTag={delTag}
                  onChange={onChange}
                  onClickIndustries={onClickIndustries}
                  onClickTags={onClickTags}
                  suggestIndustry={suggestIndustry}
                  suggestions={suggestions}
                />

                <ContactExecutiveEmailPanel
                  emails={emails}
                  onMouseEnter={onEmailMouseEnter}
                  onMouseLeave={onEmailMouseLeave}
                />
              </FormSubmitOnBlur>
            </div>
            <div className="col-xs-6 col-xl-5 col-xxl-4 panel-auto-scroll-y" id="position_right_panel">
              <div className="right-siderbar container-fluid">
                <div className="row">
                  <ContactExecutivePositions
                    addPosition={addPosition}
                    loading={loadingPositions}
                    onBlur={onBlurPosition}
                    onChange={onChange}
                    onClick={triggerPosition}
                    onDel={delPosition}
                    onSubmit={submitPosition}
                    positions={positions}
                    suggestions={suggestions}
                    suggestTitles={suggestTitles}
                  />
                </div>
                <div className="row">
                  <ContactExecutiveFunds
                    addFund={addFund}
                    funds={funds}
                    loading={loadingFunds}
                    onActivate={activateFund}
                    onBlur={onBlurFund}
                    onClick={triggerFund}
                    onDel={delFund}
                    onSubmit={submitFund}
                    suggestProject={suggestProject}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

ContactExecutiveRender.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default LoadAnimation(ContactExecutive);
