import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable from 'immutable';
import { createPortal } from 'react-dom';
import config from '../../config';
import uniqueId from '../../utils/uniqueId';
import isFunction, { execIfFunction } from '../../utils/isFunction';

/**
 * Auto-suggestion component.
 *
 * @param props {Object}.
 * @param props.id {String} Id of text input element.
 * @param props.name {String} Name of text input element.
 * @param props.value {String} Initial value of text input element.
 * @param props.className {String} Class of text input element.
 * @param props.suggestions {Immutable.List} List of suggestion.
 * @param props.mode {String(loading|suggest)} Loading: to show spinner on suggestion, suggest: to show suggestion list.
 * @param props.data {Object} This data will be passed to return functions.
 * @param props.onTextChange {Function} To handle onChange event of text input element.
 * @param props.setInputElement {Function(inputElement)} To get input element.
 * @param props.onSuggestionSelect {Function} To handle user selection of a suggestion.
 * @param props.onSuggestionClose {Function} To handle close suggestion drop down when users click outside.
 * @returns {React.Component}
 * @class
 */
class AutoSuggestion extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputClick = this.handleInputClick.bind(this);
    this.handleSuggestionSelect = this.handleSuggestionSelect.bind(this);
    this.menuId = uniqueId();
    this.inputId = this.props.id || uniqueId();
    this.setInputElement = this.setInputElement.bind(this);
  }

  componentDidUpdate() {
    const menu = document.getElementById(this.menuId);
    const input = document.getElementById(this.inputId);
    const { width } = this.props;

    if (menu && input) {
      const rect = input.getBoundingClientRect();

      menu.style.display = 'block';
      menu.style.left = `${rect.left}px`;
      menu.style.top = `${rect.top + rect.height}px`;
      menu.style.width = width ? `${width}px` : `${rect.width}px`;
      menu.style.zIndex = '1200';
    }
  }

  renderMenu(mode, suggestions) {
    const empty =
      suggestions && suggestions.size === 0 && mode !== config.LOADING_MODE ? (
        <li>
          <a role="menuitem">No data</a>
        </li>
      ) : null;

    if (mode === config.SUGGEST_MODE) {
      const container = (
        <ul className="auto-suggestion dropdown-menu" id={this.menuId}>
          {empty}
          {suggestions.map((s, i) => (
            <li key={i}>
              <a onClick={event => this.handleSuggestionSelect(event, s)}>{s.get('name')}</a>
            </li>
          ))}
        </ul>
      );
      const portal = createPortal(container, document.querySelector('body'));

      return portal;
    }

    if (mode === config.LOADING_MODE) {
      const container = (
        <ul className="dropdown-menu" id={this.menuId}>
          <li className="text-center">
            <i className="fa fa-spinner fa-pulse fa-fw" />
          </li>
        </ul>
      );
      const portal = createPortal(container, document.querySelector('body'));

      return portal;
    }

    return null;
  }

  handleSuggestionSelect(event, suggestion) {
    event.preventDefault();
    event.stopPropagation();

    const { name, value } = this.props;

    execIfFunction(this.props.onSuggestionSelect, {
      event: { target: { name, value } },
      data: this.props.data,
      suggestion,
    });
  }

  handleInputChange(event) {
    execIfFunction(this.props.onTextChange, { event, data: this.props.data });
  }

  handleInputClick(event) {
    if (isFunction(this.props.onSuggestionInputClick)) {
      this.props.onSuggestionInputClick({ event });
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  setInputElement(input) {
    execIfFunction(this.props.setInputElement, input);
  }

  render() {
    const {
      className = 'form-control',
      mode,
      suggestions,
      onFocus,
      value = '',
      name,
      disabled,
      hasError,
      placeholder,
      autoFocus = false,
    } = this.props;
    const errorClass = hasError ? 'has-error' : '';

    return (
      <div className={errorClass}>
        <input
          ref={this.setInputElement}
          autoComplete="off"
          autoFocus={autoFocus}
          className={className}
          disabled={disabled}
          id={this.inputId}
          name={name}
          onChange={this.handleInputChange}
          onClick={this.handleInputClick}
          onFocus={onFocus}
          placeholder={placeholder}
          type="text"
          value={value}
        />
        {this.renderMenu(mode, suggestions)}
      </div>
    );
  }
}

AutoSuggestion.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  mode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onSuggestionClose: PropTypes.func,
  onSuggestionInputClick: PropTypes.func,
  onSuggestionSelect: PropTypes.func,
  onTextChange: PropTypes.func,
  placeholder: PropTypes.string,
  setInputElement: PropTypes.func,
  suggestions: PropTypes.instanceOf(Immutable.List).isRequired,
  value: PropTypes.string,
  width: PropTypes.number,
};

export default AutoSuggestion;
