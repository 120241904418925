import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fromJS, List, Map } from 'immutable';

import connectOptions, { mergeProps } from '../../utils/connectOptions';
import { handleGetUsersSuggestion } from '../../actions/popup';
import SetNextActionsPopup from '../../components/Popup/SetNextActionsPopup';
import { showError } from '../../utils/MessagePopup';
import { targetStatusesWithDefault } from '../../utils/targetStatuses';
import { fetchTargetStatuses } from '../../actions/statuses';

class SetNextActionsPopupContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      statuses: List(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleActivityChange = this.handleActivityChange.bind(this);
    this.isFormValid = this.isFormValid.bind(this);

    this.handleSuccessResponse = this.handleSuccessResponse.bind(this);
    this.handleUpdateSuggestions = this.handleUpdateSuggestions.bind(this);
    this.handleGetSuggestionValue = this.handleGetSuggestionValue.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
    this.handleRenderSuggestion = this.handleRenderSuggestion.bind(this);
    this.handleGetSuggestion = this.handleGetSuggestion.bind(this);
  }

  componentDidMount() {
    const { currentUser, updatePopupFormData } = this.context;
    const userName = currentUser.get('userName');
    const id = currentUser.get('id');
    const { popup, fetchTargetStatuses } = this.props;
    const currentPage = popup.getIn(['props', 'currentPage'], 'default');
    const defaultSelected = Map({ name: '', value: '', index: -1 });
    const assignedTo = popup.getIn(['props', 'assignedTo'], {
      suggestions: [],
      text: userName,
      selected: { text: userName, id },
    });
    const formError = List();

    updatePopupFormData({
      override: false,
      passed: false,
      noResponse: currentPage === 'browse',
      backlog: false,
      approved: false,
      activity: Map(),
      assignedTo,
      status: {
        defaultSelected,
        index: defaultSelected.get('index'),
      },
      formError,
    });

    fetchTargetStatuses(({ response: { data } }) => {
      this.setState({
        statuses: targetStatusesWithDefault(fromJS(data)),
      });
    });
  }

  handleChange(event) {
    if (event.target) {
      const { name, type, checked, value } = event.target;

      this.context.updatePopupFormData({
        [name]: type.toUpperCase() === 'CHECKBOX' ? checked : value,
        formError: List(),
      });
    } else {
      this.context.updatePopupFormData(event);
    }
  }

  handleActivityChange(data) {
    this.context.updatePopupFormData({
      activity: data,
      formError: List(),
    });
  }

  handleDateChange({ target }) {
    if (target.value.isValid()) {
      this.context.updatePopupFormData({
        [target.name]: target.value,
        formError: List(),
      });
    }
  }

  handleStatusChange(event) {
    const { value } = event.target;
    const { statuses } = this.state;
    const index = statuses.findIndex(s => s.get('value') === value);
    const selected = statuses.get(index);

    this.context.updatePopupFormData({
      status: {
        selected,
        index: selected.get('index'),
      },
      formError: List(),
    });
  }

  handleSuccessResponse({ skipB, skipP, skipRO, skipTC, skipE, skipBA, skipDAB, skipBE, skipBN, skipRF }) {
    let messageArray = [];
    let subheader = '';

    if (skipBA) {
      const message =
        skipBA === 1
          ? `${skipBA} company was skipped because it was not Approved`
          : `${skipBA} companies were skipped because they were not Approved`;

      messageArray.push({ key: '', value: [message] });
    }

    if (skipDAB) {
      const message =
        skipDAB === 1
          ? `${skipDAB} company was skipped because it have a different Active Buyer`
          : `${skipDAB} companies were skipped because they have a different Active Buyer`;

      messageArray.push({ key: '', value: [message] });
    }

    if (skipBE) {
      const message =
        skipBE === 1
          ? `${skipBE} company was skipped because it was not Excluded`
          : `${skipBE} companies were skipped because they were not Excluded`;

      messageArray.push({ key: '', value: [message] });
    }

    if (skipBN) {
      const message =
        skipBN === 1
          ? `${skipBN} company was skipped because the Approval field was not blank`
          : `${skipBN} companies were skipped because the Approval field was not blank`;

      messageArray.push({ key: '', value: [message] });
    }

    if (skipB) {
      const message =
        skipB === 1
          ? `${skipB} company was skipped because it has no active buyer`
          : `${skipB} companies were skipped because they had no active buyer`;

      messageArray.push({ key: '', value: [message] });
    }

    if (skipP) {
      const message =
        skipP === 1
          ? `${skipP} company was skipped because it has no active project`
          : `${skipP} companies were skipped because they had no active project`;

      messageArray.push({ key: '', value: [message] });
    }

    if (skipRO) {
      const message =
        skipRO === 1
          ? `${skipRO} company was skipped because it was missing primary deal maker info`
          : `${skipRO} companies were skipped because they were missing primary deal maker info`;

      messageArray.push({ key: '', value: [message] });
    }

    if (skipTC) {
      const message =
        skipTC === 1
          ? `${skipTC} company has next actions successfully created, but it wasn't backlogged yet due to missing contact info. It will need to be fixed and backlogged manually`
          : `${skipTC} companies had next actions successfully created, but they weren't backlogged yet due to missing contact info. They will need to be fixed and backlogged manually`;

      messageArray.push({ key: '', value: [message] });
    }

    if (skipE) {
      const message =
        skipE === 1
          ? `${skipE} company encountered internal database errors and should be reviewed`
          : `${skipE} companies encountered internal database errors and should be reviewed`;

      messageArray.push({ key: '', value: [message] });
    }

    if (skipRF) {
      const errors = [];

      skipRF.forEach(error => {
        errors.push({
          key: error.companyName,
          value: error.emptyRequiredFields,
        });
      });
      subheader = `Backlogging Failed due to missing data, and no targets were Backlogged. The following profiles were missing information:`;
      messageArray = [...messageArray, ...errors];
    }

    if (messageArray.length > 0) showError(this.context.openPopup, List(messageArray), null, subheader);

    return messageArray;
  }

  handleUpdate() {
    if (this.isFormValid()) {
      const { popup } = this.props;
      const onUpdate = popup.getIn(['props', 'onUpdate'], () => {});
      const onSuccessResponse = popup.getIn(['props', 'onSuccessResponse'], this.handleSuccessResponse);
      const refreshGrid = popup.getIn(['props', 'refreshGrid'], () => {});

      onUpdate(popup.get('formData'), {
        beforeStart: this.context.startPopupLoading,
        afterSuccess: ({ response }) => {
          this.context.endPopupLoading();
          onSuccessResponse(response.skipped);
          refreshGrid();
        },
        afterError: ({ resBody }) => {
          this.context.endPopupLoading(resBody);
        },
      });
    }
  }

  handleDelete() {
    const { popup } = this.props;
    const onDelete = popup.getIn(['props', 'onDelete'], () => {});
    const refreshGrid = popup.getIn(['props', 'refreshGrid'], () => {});

    this.context.openPopup('ConfirmPopup', {
      message: `You are about to DELETE the Next Actions for the selected targets.
                Are you sure you want to proceed?`,
      onOk: () => {
        onDelete({
          afterSuccess: () => {
            this.context.closePopup();
            refreshGrid();
          },
        });
      },
      onCancel: () => this.context.closePopup(),
      yes: 'Yes',
      no: 'No',
    });
  }

  isFormValid() {
    const { popup } = this.props;
    const assignedTo = popup.getIn(['formData', 'assignedTo', 'selected', 'id']);
    const activity = popup.getIn(['formData', 'activity', 'selected', 'value']);
    const error = [];

    if (!assignedTo) {
      error.push('Assigned To field is required.');
    }

    if (!activity) {
      error.push('Activity field is required.');
    }
    this.context.updatePopupFormData({ formError: List(error) });

    return error.length === 0;
  }

  handleClose() {
    this.context.closePopup();
  }

  handleUpdateSuggestions(update) {
    this.context.updatePopupFormData({
      assignedTo: update,
      formError: List(),
    });
  }

  handleGetSuggestionValue(suggestion) {
    return suggestion.text;
  }

  handleSuggestionSelected(event, { suggestion }) {
    this.context.updatePopupFormData({
      assignedTo: {
        text: suggestion.text,
        suggestions: [],
        selected: suggestion,
      },
    });
  }

  handleRenderSuggestion(suggestion) {
    return <div>{suggestion.text}</div>;
  }

  handleGetSuggestion({ value }) {
    this.props.handleGetUsersSuggestion(value, ({ response }) => {
      this.context.updatePopupFormData({
        assignedTo: {
          suggestions: response.data,
        },
      });
    });
  }

  render() {
    const { popup } = this.props;
    const { statuses } = this.state;
    const currentPage = popup.getIn(['props', 'currentPage'], 'default');
    const suggestions = popup.getIn(['formData', 'assignedTo', 'suggestions'], new List());
    const text = popup.getIn(['formData', 'assignedTo', 'text'], '');
    const formData = popup.get('formData');
    const activityGroup = formData.getIn(['activity', 'selected', 'group'], 5);
    const assignedToSuggestionsProps = {
      inputProps: {
        className: 'form-control',
        id: 'assignToDropdown',
        name: 'assignToDropdown',
        label: 'Assigned To',
        disabled: false,
        placeholder: '',
      },
      suggestions,
      onUpdateSuggestions: this.handleUpdateSuggestions,
      getSuggestion: this.handleGetSuggestion,
      getSuggestionValue: this.handleGetSuggestionValue,
      renderSuggestion: this.handleRenderSuggestion,
      onSuggestionSelected: this.handleSuggestionSelected,
      text,
    };
    const descriptionHidden =
      activityGroup < 6 ||
      activityGroup === 7 ||
      (activityGroup === 9 && formData.getIn(['activity', 'selected', 'value']) === 'HarvCo Meeting');
    const backlogHidden = activityGroup > 4 && activityGroup !== 7;
    const applyToHidden = currentPage === 'browse';

    return (
      <div>
        <SetNextActionsPopup
          applyToHidden={applyToHidden}
          assignedToSuggestions={assignedToSuggestionsProps}
          backlogHidden={backlogHidden}
          currentPage={currentPage}
          currentUser={this.context.currentUser}
          descriptionHidden={descriptionHidden}
          formData={formData}
          formError={formData.get('formError')}
          isPopupFetching={popup.get('isPopupFetching')}
          isPopupFetchingError={popup.get('isPopupFetchingError')}
          onActivityChange={this.handleActivityChange}
          onChange={this.handleChange}
          onClose={this.handleClose}
          onDateChange={this.handleDateChange}
          onDelete={this.handleDelete}
          onStatusChange={this.handleStatusChange}
          onUpdate={this.handleUpdate}
          statuses={statuses}
        />
      </div>
    );
  }
}

SetNextActionsPopupContainer.propTypes = {
  popup: PropTypes.instanceOf(Map).isRequired,
};

SetNextActionsPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
  updatePopupFormData: PropTypes.func.isRequired,
  startPopupLoading: PropTypes.func.isRequired,
  endPopupLoading: PropTypes.func.isRequired,
};

export { SetNextActionsPopupContainer };
export default connect(
  (state, props) => ({
    ...props,
  }),
  { fetchTargetStatuses, handleGetUsersSuggestion },
  mergeProps,
  connectOptions,
)(SetNextActionsPopupContainer);
