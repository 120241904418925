import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'immutable';

import DropDown, { DropDownElement } from '../../helpers/DropDown';

/**
 * Stateless component for top menu of grid.
 *
 * @param props {Object}.
 * @param props.actionConfigs {Immutable.List} List of available actionList for each event.
 * @param props.selected {Immutable.List} List of selected next actions.
 * @param props.disabled {Boolean} If true, disable drop down list.
 * @param props.onSelectAll {Function} To select all next Actions.
 * @param props.onDeselectAll {Function} To deselect all next actions.
 * @returns {React.Component}
 */
const TopMenu = props => {
  const { actionConfigs, onSelectAll, selected, disabled, onDeselectAll, onChange } = props;
  const selectedText = selected.size > 0 ? `${selected.size} selected` : null;
  const actionContents = actionConfigs.map((a, i) => (
    <DropDownElement key={i} value={a.get('key')}>
      {a.get('name')}
    </DropDownElement>
  ));

  return (
    <table className="no-hover-table">
      <tbody>
        <tr>
          <td className="w150">
            <div className="BrowseTable-controls BrowseTable-controls--main mb5">
              <a className="BrowseTable-control" href="#" onClick={onSelectAll}>
                Select all
              </a>
              <a className="BrowseTable-control" href="#" onClick={onDeselectAll}>
                Select none
              </a>
            </div>
          </td>
          <td className="w70">
            <DropDown
              btnContent="Actions"
              className="btn btn-default btn-xs"
              disabled={disabled}
              id="actionDropDown"
              name="actionddl"
              onChange={event => onChange(event, { key: event.target.value, selected })}
              wrapperClassName="BrowseTable-controls mb5"
            >
              {actionContents}
            </DropDown>
          </td>
          <td>
            <div className="BrowseTable-controls BrowseTable-controls--main mb5">{selectedText}</div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

TopMenu.propTypes = {
  actionConfigs: PropTypes.instanceOf(List).isRequired,
  disabled: PropTypes.bool.isRequired,
  onDeselectAll: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  selected: PropTypes.instanceOf(List).isRequired,
};

export default TopMenu;
