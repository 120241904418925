import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import FormGroup from '../helpers/FormGroup';

/**
 * Download merge source popup component.
 *
 * @component
 */
const DownloadMergeSourcePopup = (props, context) => {
  const [format, setFileFormat] = useState('xlsx');
  const [blankFields, setBlankFields] = useState(true);
  const [isSaveDefault, setSaveDefault] = useState(false);

  /**
   * Form submit handler.
   *
   * @param {SyntheticEvent} event The react `SyntheticEvent`.
   */
  const handleSubmit = event => {
    event.preventDefault();

    const onDownload = props.popup.getIn(['props', 'onDownload']);

    onDownload({
      format,
      blankFields,
      isSaveDefault,
    });

    context.closePopup();
  };

  /**
   * "File Format" radio input change handler.
   *
   * @param {SyntheticEvent} event The react `SyntheticEvent`.
   */
  const handleFileFormatChange = event => {
    setFileFormat(event.target.value);
  };

  /**
   * "Blank Field" radio input change handler.
   *
   * @param {SyntheticEvent} event The react `SyntheticEvent`.
   */
  const handleAllowBlankFieldsChange = event => {
    setBlankFields(event.target.value === 'yes');
  };

  /**
   * "Is save default" checkbox input change handler.
   *
   * @param {SyntheticEvent} event The react `SyntheticEvent`.
   */
  const handleSetNewDefaultChange = event => {
    setSaveDefault(event.target.checked);
  };

  const header = 'Download Merge Source';
  const footer = (
    <Button className="btn-default btn-xs btn-primary" onClick={handleSubmit} type="button">
      Download
    </Button>
  );

  return (
    <Popup footer={footer} header={header} id="OutreachPopup">
      <form onSubmit={handleSubmit}>
        <p className="mt15 mb4">File Format:</p>
        <FormGroup>
          <div className="radio-wrap radio-wrap--reverse">
            <label htmlFor="xlsx">.xlsx</label>
            <input
              checked={format === 'xlsx'}
              id="xlsx"
              name="file_format"
              onChange={handleFileFormatChange}
              type="radio"
              value="xlsx"
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="radio-wrap radio-wrap--reverse">
            <label htmlFor="txt">.txt</label>
            <input
              checked={format === 'txt'}
              id="txt"
              name="file_format"
              onChange={handleFileFormatChange}
              type="radio"
              value="txt"
            />
          </div>
        </FormGroup>

        <p className="mt20 mb4">Allow blank fields?</p>
        <FormGroup>
          <div className="radio-wrap radio-wrap--reverse">
            <label htmlFor="blankFieldsYes">Yes</label>
            <input
              checked={blankFields}
              id="blankFieldsYes"
              name="blank_fields"
              onChange={handleAllowBlankFieldsChange}
              type="radio"
              value="yes"
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="radio-wrap radio-wrap--reverse">
            <label htmlFor="blankFieldsNo">No</label>
            <input
              checked={!blankFields}
              id="blankFieldsNo"
              name="blank_fields"
              onChange={handleAllowBlankFieldsChange}
              type="radio"
              value="no"
            />
          </div>
        </FormGroup>

        <FormGroup className="mt20">
          <div className="radio-wrap radio-wrap--reverse">
            <label htmlFor="isSaveDefault">Set new default?</label>
            <input
              checked={isSaveDefault}
              id="isSaveDefault"
              name="is_save_default"
              onChange={handleSetNewDefaultChange}
              type="checkbox"
              value={isSaveDefault}
            />
          </div>
        </FormGroup>
      </form>
    </Popup>
  );
};

DownloadMergeSourcePopup.contextTypes = {
  /**
   * Close current popup.
   */
  closePopup: PropTypes.func.isRequired,
};

export default DownloadMergeSourcePopup;
