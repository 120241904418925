import { push } from 'connected-react-router';

import { CALL_API } from '../middleware/api';

export const FETCHING_PM_HEADER = 'FETCHING_PM_HEADER';
export const FETCHING_PM_HEADER_SUCCESS = 'FETCHING_PM_HEADER_SUCCESS';
export const FETCHING_PM_HEADER_FAILURE = 'FETCHING_PM_HEADER_FAILURE';
export const FETCHING_PM_TARGETS = 'FETCHING_PM_TARGETS';
export const FETCHING_PM_TARGETS_SUCCESS = 'FETCHING_PM_TARGETS_SUCCESS';
export const FETCHING_PM_TARGETS_FAILURE = 'FETCHING_PM_TARGETS_FAILURE';
export const UPDATE_MAILING_CREATE = 'UPDATE_MAILING_CREATE';
export const CREATING_MAILING = 'CREATING_MAILING';
export const CREATING_MAILING_FAILURE = 'CREATING_MAILING_FAILURE';
export const CLEAR_CREATE_MAILING_ERROR = 'CLEAR_CREATE_MAILING_ERROR';
export const UPDATE_MAILING_SELECTED_ROW = 'UPDATE_MAILING_SELECTED_ROW';
export const UPDATE_MAILING_ALL_ROWS = 'UPDATE_MAILING_ALL_ROWS';
export const UPDATE_MAILING_SELECT_ROWS_BY_QUANTITY = 'UPDATE_MAILING_SELECT_ROWS_BY_QUANTITY';
export const INIT_CREATE_MAILING = 'INIT_CREATE_MAILING';

export function initCreateMailing() {
  return {
    type: INIT_CREATE_MAILING,
  };
}

export function getPMHeader({ recordOwnerId, recordSubOwnerId, buyerId, projectId }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/pm_header?record_owner_id=${recordOwnerId}&record_sub_owner_id=${recordSubOwnerId}&buyer_id=${buyerId}&project_id=${projectId}`,
      startType: FETCHING_PM_HEADER,
      successType: FETCHING_PM_HEADER_SUCCESS,
      errorType: FETCHING_PM_HEADER_FAILURE,
    },
  };
}

export function getPMTargets({
  recordOwnerId,
  recordSubOwnerId,
  buyerId,
  projectId,
  activity,
  isLetter,
  isEmail,
  beforeDate,
  page = 1,
  sortBy = null,
}) {
  const query = {
    page,
    limit: 100,
    record_owner_id: recordOwnerId,
    record_sub_owner_id: recordSubOwnerId,
    buyer_id: buyerId,
    project_id: projectId,
    activity,
    isletter: isLetter,
    isemail: isEmail,
    before_date: beforeDate,
  };

  if (sortBy) {
    query.sort = sortBy;
  }

  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/events/pm_targets',
      query,
      startType: FETCHING_PM_TARGETS,
      successType: FETCHING_PM_TARGETS_SUCCESS,
      errorType: FETCHING_PM_TARGETS_FAILURE,
    },
  };
}

export function updateMailingCreate({ filterName, filterData }) {
  return {
    type: UPDATE_MAILING_CREATE,
    filterName,
    filterData,
  };
}

export function createMailing({ selected_ids, user_id }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/batch/create_mailing',
      body: {
        selected_ids,
        user_id,
      },
      startType: CREATING_MAILING,
      skipGlobalErrorHandler: true,
      errorType: CREATING_MAILING_FAILURE,
      afterSuccess: ({ dispatch, response }) => {
        if (response.status === 'success') {
          dispatch(push(`/mailing-info?id=${response.id}`));
        } else {
          return dispatch({ type: CREATING_MAILING_FAILURE });
        }
      },
    },
  };
}

export function clearCreateMailingError() {
  return {
    type: CLEAR_CREATE_MAILING_ERROR,
  };
}

export function updateSelectedRow({ index, field, value }) {
  return {
    type: UPDATE_MAILING_SELECTED_ROW,
    index,
    field,
    value,
  };
}

export function updateAllRows({ field, value }) {
  return {
    type: UPDATE_MAILING_ALL_ROWS,
    field,
    value,
  };
}

export function updateSelectedRowsByQuantity({ field, value, quantity }) {
  return {
    type: UPDATE_MAILING_SELECT_ROWS_BY_QUANTITY,
    field,
    value,
    quantity: Number(quantity),
  };
}
