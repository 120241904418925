import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { toJS } from 'src/utils/ChangeSpy';

const BuyersTable = ({ data }) => {
  const statsBuyers = toJS(data.get('buyers'));

  return (
    <div className="dashboard-table-wrapper mb20">
      <table className="dashboard-table table-striped-white">
        <thead>
          <tr className="bg-black text-white">
            <th className="text-center p12" colSpan="4">
              <p className="h3 text-bold">{statsBuyers.allCount}</p>
              <p className="h5 text-bold">Buyers</p>
            </th>
          </tr>
          <tr>
            <th className="text-center p6 border-right" colSpan="2">
              <p className="h4 text-bold">{statsBuyers.financialCount}</p>
              <p className="h5 text-regular">Financial Buyers</p>
            </th>
            <th className="text-center p6" colSpan="2">
              <p className="h4 text-bold">{statsBuyers.strategicCount}</p>
              <p className="h5 text-regular">Strategic Buyers</p>
            </th>
          </tr>
        </thead>
        <tbody className="h5">
          <tr>
            <td className="h5 p10">Financial Buyers w/ 2+ Closed Deals</td>
            <td className="h5 p10 text-bold">{statsBuyers.financial2Count}</td>
            <td className="h5 p10 border-left">Strategic Buyers w/ 2+ Closed Deals</td>
            <td className="h5 p10 text-bold">{statsBuyers.strategic2Count}</td>
          </tr>
          <tr>
            <td className="h5 p10">Financial Buyers w/ 5+ Closed Deals</td>
            <td className="h5 p10 text-bold">{statsBuyers.financial5Count}</td>
            <td className="h5 p10 border-left">Strategic Buyers w/ 5+ Closed Deals</td>
            <td className="h5 p10 text-bold">{statsBuyers.strategic5Count}</td>
          </tr>
          <tr className="">
            <td className="h5 p10">Financial Buyers w/ 10+ Closed Deals</td>
            <td className="h5 p10 text-bold">{statsBuyers.financial10Count}</td>
            <td className="h5 p10 border-left">Strategic Buyers w/ 10+ Closed Deals</td>
            <td className="h5 p10 text-bold">{statsBuyers.strategic10Count}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

BuyersTable.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
};

export default BuyersTable;
