import React from 'react';
import Input from '../helpers/Input';
import Select from '../helpers/Select';

const Editor = ({ mode, category, error, showParent, tagTypeId, parentId, parents, types, onChange }) => {
  if (mode === 'view') return null;

  const parentEl = showParent ? (
    <div className="col-md-4">
      <Select
        defaultText=" "
        defaultValue=""
        label="Parent Category"
        name="parentId"
        nameKey="category"
        onChange={onChange}
        options={parents}
        value={parentId}
        valueKey="id"
        showLabel
      />
    </div>
  ) : null;
  let errEl = null;

  if (error && error.length > 0) {
    errEl = (
      <div className="row">
        <div className="col-md-12">
          {error.map(e => (
            <h5 key={e} className="text-danger">
              {e}
            </h5>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <h4>{mode === 'edit' ? 'Edit Tag' : 'Add Tag'}</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <Input label="Tag Name" name="category" onChange={onChange} value={category} showLabel />
        </div>
        <div className="col-md-4">
          <Select
            defaultText=" "
            defaultValue=""
            label="Tag Type"
            name="tagTypeId"
            nameKey="type"
            onChange={onChange}
            options={types}
            value={tagTypeId}
            valueKey="id"
            showLabel
          />
        </div>
        {parentEl}
      </div>
      {errEl}
    </div>
  );
};

export default Editor;
