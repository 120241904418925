import download from 'downloadjs';

import { getCode } from '../middleware/activate';

/**
 * Download file by custom URl with parameters.
 *
 * @param {URLMaker|string} url URL for download.
 * @param {string} method Method (get, post).
 * @param {object} params Optional URL parameters.
 * @param {object} body Optional body params.
 * @param {Function} onResponseJSON Optional callback on json.
 * @param {Function} afterSuccess Optional callback after successful request.
 * @param {object} headers Optional headers.
 * @param {string|null} name Optional file name.
 * @param {boolean} noAuth Do not send Authorization in the headers.
 */
export default function downloadFile({
  url,
  method = 'GET',
  params = {},
  body: postBody = {},
  headers = {},
  onResponseJSON = () => {},
  afterSuccess = () => {},
  name = null,
  noAuth = false,
}) {
  const searchParams = new URLSearchParams(params).toString();
  const fetchUrl = searchParams ? `${url}?${searchParams}` : url;

  let fileName;

  const spinner = `<div class="global-spinner" id="global-download-file-spinner">
      <div class="spinner--curtain"></div>
      <div class="spinner--icon text-center">
        <i class="fa fa-spinner fa-pulse fa-fw fa-3x"></i>
      </div>
    </div>`;

  document.body.insertAdjacentHTML('beforeend', spinner);

  let body;

  if (method === 'post') {
    body = JSON.stringify(postBody);
  }

  fetch(fetchUrl, {
    method,
    headers: {
      ...headers,
      ...(!noAuth && { Authorization: `Bearer ${getCode()}` }),
    },
    body,
  })
    .then(response => {
      const contentDisposition = response.headers.get('content-disposition');

      if (contentDisposition) {
        fileName = contentDisposition.split('filename="')[1].split('"')[0];

        return response.blob();
      }

      if (name) {
        fileName = name;

        return response.blob();
      }

      return response.json();
    })
    .then(data => {
      if (data.constructor.name === 'Blob') {
        download(data, fileName);
        afterSuccess();
      } else {
        onResponseJSON(data);
      }

      removeSpinner();
    })
    .catch(() => {
      removeSpinner();
    });
}

function removeSpinner() {
  document.querySelector('#global-download-file-spinner').remove();
}

/**
 * Download Browse page file by params.
 *
 * @param {string} url URL for request.
 * @param {object} params Params for request.
 */
export function downloadBrowseFile(url, params) {
  downloadFile({
    url,
    headers: {
      'content-type': 'application/json',
    },
    method: 'post',
    body: {
      params,
    },
  });
}
