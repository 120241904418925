import React from 'react';

const Products = ({ data }) => (
  <>
    <div className="mb5">{data.facility1}</div>
    <div className="mb5">{data.facility2}</div>
    <div>{data.facility3}</div>
  </>
);

export default Products;
