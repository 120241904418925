import React from 'react';
import { List } from 'immutable';
import FormGroup from '../../helpers/FormGroup';
import _Select from '../../helpers/Select';
import Input from '../../helpers/Input';
import ErrorDisplay from '../../decorators/DisplayError';
import PrettyError from '../../decorators/PrettyError';
import PhoneInput from './PhoneInput';

const Select = PrettyError(ErrorDisplay(_Select));

const InternationalFormat = props => {
  const { phoneCodes, onChange, onUndo, contact } = props;

  return (
    <div className="row">
      <FormGroup className="col-sm-2">
        <Input
          className="input-md"
          label="Country Code"
          maxLength="10"
          name="code"
          placeholder="Country Code"
          root={contact}
          deep
          showLabel
        />
      </FormGroup>
      <PhoneInput className="col-sm-4" contact={contact} onChange={onChange} onUndo={onUndo} />
      <FormGroup className="col-sm-3">
        <Select
          className="input-md"
          label="Country"
          name="country"
          nameKey="name"
          onChange={onChange}
          options={phoneCodes}
          placeholder="Country"
          root={contact}
          valueKey="code"
          canDisplayError
          deep
          showLabel
        />
      </FormGroup>
      <FormGroup className="col-sm-3">
        <Input className="input-md" label="Type" name="type" placeholder="Type" value="International" showLabel />
      </FormGroup>
    </div>
  );
};

InternationalFormat.defaultProps = {
  phoneCodes: List(),
};

export default InternationalFormat;
