import Immutable from 'immutable';

import config from '../../config';
import { LOADED_APPROVALS_SUCCESS } from '../../actions/project/approval';
import { sortByNumber, sortByString } from '../../utils/sorting';
import { defaultNumber } from '../../utils/string';

const defaultState = Immutable.List();

export default function(state = defaultState, action) {
  switch (action.type) {
    case LOADED_APPROVALS_SUCCESS: {
      const tmp = getNoApprovals(action.response);

      if (action.field && action.direction) {
        return sortNoApprovals(tmp, action);
      }

      return tmp;
    }

    default:
      return state;
  }
}

/**
 * Sort approval list.
 *
 * @param list {Immutable.List} List of target.
 * @param action {Object} Action object.
 * @return {Immutable.List}
 */
function sortNoApprovals(list, action) {
  if (!action.field || !action.direction) return list;

  const byNumber = [
    'numberTargets',
    'numberApproved',
    'numberOfContacted',
    'numberOfLeads',
    'numberOfCc',
    'numberOfVisit',
    'numberOfNextActions',
    'numberOfPriorityA',
    'numberOfPriorityB',
    'numberOfPriorityC',
    'numberOfApprovedX',
    'percentApproved',
    'numberMailed',
    'percentMailed',
    'numberCalled',
    'percentCalled',
  ];
  const { field, direction } = action;
  const dir = direction === 'up' ? -1 : 1;

  if (byNumber.indexOf(field) > -1) {
    return sortByNumber(list, field, dir);
  }

  return sortByString(list, field, dir);
}

/**
 * Get approvals from response.
 *
 * @param response {Object} Response.
 * @return {Immutable.List} Approvals list.
 */
function getNoApprovals(response) {
  if (response.status === 'success') {
    const list = response.data.hasOwnProperty('noApprovalLists') ? 'noApprovalLists' : 'defaultNoApprovalLists';

    return Immutable.fromJS(
      response.data[list].map(approval => {
        const numberOfTargets = defaultNumber(approval.numberOfTargets);
        const numberOfApprovedTargets = defaultNumber(approval.numberOfApprovedTargets);
        const numberOfMailedTargets = defaultNumber(approval.numberOfMailedTargets);
        const numberOfCalledTargets = defaultNumber(approval.numberOfCalledTargets);
        const numberOfContacted = defaultNumber(approval.numberOfContacted);
        const numberOfLeads = defaultNumber(approval.numberOfLeads);
        const numberOfCc = defaultNumber(approval.numberOfCc);
        const numberOfVisit = defaultNumber(approval.numberOfVisit);
        const numberOfNextActions = defaultNumber(approval.numberOfNextActions);
        const numberOfPriorityA = defaultNumber(approval.numberOfPriorityA);
        const numberOfPriorityB = defaultNumber(approval.numberOfPriorityB);
        const numberOfPriorityC = defaultNumber(approval.numberOfPriorityC);
        const numberOfApprovedX = defaultNumber(approval.numberOfApprovedX);

        return Immutable.Map({
          numberTargets: numberOfTargets,
          numberApproved: numberOfApprovedTargets,
          percentApproved: countPercent(numberOfApprovedTargets, numberOfTargets),
          numberMailed: numberOfMailedTargets,
          percentMailed: countPercent(numberOfMailedTargets, numberOfTargets),
          numberCalled: numberOfCalledTargets,
          percentCalled: countPercent(numberOfCalledTargets, numberOfTargets),
          mode: config.VIEW_MODE,
          dirty: false,
          numberOfContacted,
          percentContacted: countPercent(numberOfContacted, numberOfTargets),
          numberOfLeads,
          percentLeads: countPercent(numberOfLeads, numberOfTargets),
          numberOfCc,
          percentCc: countPercent(numberOfCc, numberOfTargets),
          numberOfVisit,
          percentVisit: countPercent(numberOfVisit, numberOfTargets),
          numberOfNextActions,
          percentNextActions: countPercent(numberOfNextActions, numberOfTargets),
          numberOfPriorityA,
          percentOfPriorityA: countPercent(numberOfPriorityA, numberOfTargets),
          numberOfPriorityB,
          percentOfPriorityB: countPercent(numberOfPriorityB, numberOfTargets),
          numberOfPriorityC,
          percentOfPriorityC: countPercent(numberOfPriorityC, numberOfTargets),
          numberOfApprovedX,
          percentOfApprovedX: countPercent(numberOfApprovedX, numberOfTargets),
        });
      }),
    );
  }

  return defaultState;
}

/**
 * Get percent.
 */
function countPercent(numerator, denominator) {
  if (denominator === 0) {
    return 0;
  }

  return Math.round((numerator / denominator) * 100);
}
