import moment from 'moment';
import Immutable from 'immutable';
import {
  LOADED_ASSOCIATES_SUCCESS,
  CHANGE_APPROVAL_GRID,
  SAVE_APPROVAL_SUCCESS,
  SETTING_APPROVED_STATUS,
  SET_APPROVED_STATUS_FAIL,
  SET_APPROVED_STATUS_SUCCESS,
} from '../../actions/approval/associate';
import { SAVE_COMPANY_SUCCESS } from '../../actions/approval/main';
import { spy, setIn, setOriginalIn, mergeToOriginal } from '../../utils/ChangeSpy';

const defaultState = Immutable.fromJS([]);

export default function(state = defaultState, action, common) {
  switch (action.type) {
    case LOADED_ASSOCIATES_SUCCESS:
      return mapAssociates(state, action, common.get('associateRequestId'));

    case CHANGE_APPROVAL_GRID:
      return setIn(state, [action.index, action.field], action.value);

    case SAVE_APPROVAL_SUCCESS: {
      const key = state.findKey(val => val.get('linkId') === action.linkId);

      if (key === undefined) return state;

      return state.updateIn([key], state => mergeToOriginal(state, action.body));
    }

    case SETTING_APPROVED_STATUS:
      return state.setIn([action.index, 'loading'], true);

    case SET_APPROVED_STATUS_FAIL:
      return state.setIn([action.index, 'loading'], false);

    case SET_APPROVED_STATUS_SUCCESS: {
      const tmp = setIn(state.setIn([action.index, 'loading'], false), `${action.index}.approved`, action.approved);

      return setOriginalIn(tmp, `${action.index}.approved`, action.approved);
    }

    case SAVE_COMPANY_SUCCESS: {
      const { index, name, value } = action.merge;

      if (['pri', 'bsnClient', 'harveyComment'].indexOf(name) > -1) {
        const tmp = setIn(state, [index, name], value);

        return setOriginalIn(tmp, [index, name], value);
      }

      return state.setIn([index, name], value);
    }

    default:
      return state;
  }
}

/**
 * Map associate list return from api call.
 *
 * @param state {Immutable.List} State.
 * @param action {Object} Action.
 * @returns {Immutable.List}
 */
function mapAssociates(state, action, id) {
  const {
    response: { status, data, meta },
    requestId,
  } = action;

  if (status === 'success' && id === requestId) {
    if (meta.pagination.currentPage === 1) {
      return Immutable.List(mapAssociate(0, data));
    }

    return state.concat(mapAssociate(state.size, data));
  }

  return state;
}

/**
 * Map an associate.
 *
 * @param associate {Object} Associate information.
 * @returns {Immutable.Map}
 */
function mapAssociate(start, associates) {
  return Immutable.fromJS(
    associates.map((associate, index) => {
      const naDate = moment(associate.naDate);
      const laDate = moment(associate.laDate);

      return Immutable.Map(
        spy(
          {
            order: start + index,
            linkId: associate.targetBuyerId,
            targetId: associate.targetId,
            companyName: associate.targetLegalName,
            curr: associate.currentStatus,
            high: associate.highStatus,
            revenue: associate.targetRevenue,
            employees: associate.targetEmployees,
            state: associate.targetState,
            type: associate.targetCompanyType,
            pri: associate.priority,
            approved: associate.approved,
            module: associate.recordOwnerUserName,
            aa: associate.recordSubOwnerUserName,
            naDate,
            nextAction: associate.naActivity,
            laDate,
            lastAction: associate.laActivity,
            bsnClient: associate.bsnClient,
            harveyComment: associate.harveyComment,
            isTarget: associate.recordOwnerId > 0,
          },
          ['pri', 'approved', 'bsnClient', 'harveyComment'],
        ),
      );
    }),
  );
}
