import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Map, List, fromJS } from 'immutable';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import uniqueId from '../../utils/uniqueId';
import debounce, { passFirst } from '../../utils/debounce';
import AutoComplete from './AutoComplete';
import { noop } from '../../utils/noop';

class ActivityDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.inputId = uniqueId();
    this.handleGetSuggestion = this.handleGetSuggestion.bind(this);
    this.handleUpdateSuggestions = this.handleUpdateSuggestions.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
    this.handleDropdownBtnClick = this.handleDropdownBtnClick.bind(this);
    this.handleActivityClick = this.handleActivityClick.bind(this);
    this.handlePortalClose = this.handlePortalClose.bind(this);
    this.updatePosition = passFirst(this.updatePosition.bind(this), 100);
    this.setPanelElement = this.setPanelElement.bind(this);
    this.handleActivityChange = debounce(this.handleActivityChange.bind(this), 100);
    this.getEqualsActivity = this.getEqualsActivity.bind(this);
    this.getPanelStyle = this.getPanelStyle.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  static handleGetSuggestionValue(suggestion) {
    return suggestion.value;
  }

  static handleRenderSuggestion(suggestion) {
    return <div>{suggestion.value}</div>;
  }

  updatePosition() {
    this.forceUpdate();
  }

  componentDidMount() {
    window.addEventListener('resize', this.updatePosition);
    window.addEventListener('scroll', this.updatePosition);
    this.input = document.getElementById(this.inputId);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePosition);
    window.removeEventListener('scroll', this.updatePosition);
  }

  getEqualsActivity(possibleActivity) {
    const val = String(possibleActivity).toLowerCase();

    return this.props.activities.find(activity => activity.get('value').toLowerCase() === val, false);
  }

  handleGetSuggestion({ value }) {
    const suggestions = this.props.activities.filter(
      activity =>
        activity
          .get('value')
          .toLowerCase()
          .indexOf(value.toLowerCase()) > -1,
    );

    this.props.onChange({
      suggestions,
    });
  }

  handleUpdateSuggestions(update) {
    const activity = this.getEqualsActivity(update.text);

    if (activity) {
      update.selected = activity.toJS();
    }
    this.props.onChange(update);
  }

  handleSuggestionSelected(event, { suggestion }) {
    this.props.onChange({
      suggestions: [],
      selected: suggestion,
      text: suggestion.value,
    });
  }

  handleDropdownBtnClick(event) {
    event.stopPropagation();

    const { info, disabled } = this.props;

    if (!disabled) {
      this.handleActivityChange(!info.get('isDropdownPanelOpen'));
    }
  }

  handleActivityChange(isDropdownPanelOpen) {
    this.props.onChange({
      isDropdownPanelOpen,
    });
  }

  handleActivityClick(event) {
    const text = event.target.innerText;
    const selected = this.props.activities
      .filter(activity => activity.get('value').toLowerCase() === text.toLowerCase())
      .get(0);

    this.props.onChange({
      text,
      selected,
    });
    setTimeout(() => this.handleActivityChange(false), 0);
  }

  renderGroupContent(groupIndex) {
    return this.props.activities
      .filter(item => item.get('group') === groupIndex)
      .map((item, i) => {
        const disable = item.get('disable', false);

        return (
          <li
            key={i}
            onClick={disable ? noop : this.handleActivityClick}
            style={ disable ? { color: '#CCC' } : null }
            value={item.get('value')}
          >
            {item.get('value')}
          </li>
        );
      });
  }

  handlePortalClose(event) {
    if (event) {
      if (event.relatedTarget === null) return;
      if (event.target !== event.currentTarget) return;
    }
    setTimeout(() => this.handleActivityChange(false), 0);
  }

  setPanelElement(panel) {
    this.panel = panel;
  }

  getPanelStyle() {
    if (this.input) {
      const rect = this.input.getBoundingClientRect();
      const style = {};

      style.display = 'block';
      style.left = rect.left;
      style.top = rect.bottom + window.pageYOffset;

      return style;
    }

    return { display: 'none' };
  }

  handleKeyPress(event) {
    if (event.key === 'Enter' && this.props.onNext) {
      this.props.onNext();
    }
  }

  renderActivityDropdownPortal() {
    const { info, isSinglePanel = false } = this.props;
    const isDropdownPanelOpen = info.get('isDropdownPanelOpen', false);

    const panelClass = classNames({
      activityPanelBlock: true,
      hide: !isDropdownPanelOpen,
    });
    const panelCategoryClass = classNames('panelCategory', {
      hide: isSinglePanel,
    });
    const panelTitleClass = classNames({
      hide: isSinglePanel,
    });

    const container = (
      <div ref={this.setPanelElement} className={panelClass} style={this.getPanelStyle()}>
        <div className="panelCategory">
          <h4 className={panelTitleClass}>process</h4>
          <div className="panelBlockContent">
            <ul className="panelGroup">
              {this.renderGroupContent(1)}
              <li>&nbsp;</li>
              {this.renderGroupContent(7)}
            </ul>
            <ul className="panelGroup">{this.renderGroupContent(2)}</ul>
            <ul className="panelGroup">{this.renderGroupContent(3)}</ul>
            <ul className="panelGroup">{this.renderGroupContent(4)}</ul>
            <ul className="panelGroup">{this.renderGroupContent(5)}</ul>
            <ul className="panelGroup">{this.renderGroupContent(6)}</ul>
          </div>
        </div>
        <div className={panelCategoryClass}>
          <h4>
            general <br />
            communication
          </h4>
          <div className="panelBlockContent">
            <ul className="panelGroup">{this.renderGroupContent(8)}</ul>
          </div>
        </div>
        <div className={panelCategoryClass}>
          <h4>lead progression</h4>
          <div className="panelBlockContent">
            <ul className="panelGroup">{this.renderGroupContent(9)}</ul>
          </div>
        </div>
        <div className={panelCategoryClass}>
          <h4>other</h4>
          <div className="panelBlockContent">
            <ul className="panelGroup">
              {this.renderGroupContent(10)}
              <li>&nbsp;</li>
              {this.renderGroupContent(11)}
            </ul>
          </div>
        </div>
      </div>
    );
    const portal = createPortal(container, document.querySelector('body'));

    return info.get('isDropdownPanelOpen') && portal;
  }

  render() {
    const { info, tabIndex, disabled } = this.props;
    const suggestions = info.get('suggestions', List());
    const sections = fromJS([
      {
        name: 'Default Section',
        section: suggestions,
      },
    ]);
    const text = info.get('text', '');
    const suggestionProps = {
      inputProps: {
        className: 'form-control',
        id: this.inputId,
        name: 'activityDropdown',
        disabled,
        placeholder: '',
        onKeyPress: this.handleKeyPress,
      },
      suggestions: sections,
      checkOn: 'text',
      onUpdateSuggestions: this.handleUpdateSuggestions,
      getSuggestion: this.handleGetSuggestion,
      getSuggestionValue: ActivityDropdown.handleGetSuggestionValue,
      renderSuggestion: ActivityDropdown.handleRenderSuggestion,
      onSuggestionSelected: this.handleSuggestionSelected,
      text,
      multiSection: true,
      highlightFirstSuggestion: true,
      renderSectionTitle: () => null,
      getSectionSuggestions: list => list.section,
    };

    if (tabIndex) {
      suggestionProps.inputProps.tabIndex = tabIndex;
    }

    const dropdownButton = classNames({
      'input-group-addon': true,
      disabled,
    });

    return (
      <React.Fragment>
        <div>
          <label>Activity</label>
        </div>
        <AutoComplete {...suggestionProps}>
          <span
            className={dropdownButton}
            onBlur={this.handlePortalClose}
            onClick={this.handleDropdownBtnClick}
            style={{ outline: 0 }}
            tabIndex="-1"
          >
            <i aria-hidden="true" className="fa fa-caret-down" />
          </span>
        </AutoComplete>
        {this.renderActivityDropdownPortal()}
      </React.Fragment>
    );
  }
}

ActivityDropdown.propTypes = {
  activities: PropTypes.instanceOf(List).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  isSinglePanel: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func,
};

export default ActivityDropdown;
