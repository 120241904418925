import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import shortcut from 'react-shortcut-hoc';

import connectOptions, { mergeProps } from '../../utils/connectOptions';
import Popups from '../../components/Popup';
import * as TARGET from '../../actions/findEmail';
import { unwrap } from '../../utils/ChangeSpy';

class EmailFinderPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.onChange = this.onChange.bind(this);
    this.onFind = this.onFind.bind(this);
    this.onErrorClose = this.onErrorClose.bind(this);
  }

  componentWillUnmount() {
    this.props.clearFoundEmail();
  }

  componentDidUpdate(prevProps) {
    if (this.context.hasPopup()) {
      return this.context.onClosePopup(() => {
        const popupName = prevProps.popup.getIn(['props', 'popup', 'name']);
        const popupProps = prevProps.popup.getIn(['props', 'popup', 'props']);

        // popupName - name of popup which should be reopen after close email finder
        // popupProps - props of popup which should be reopen after close email finder
        this.context.openPopup(popupName, popupProps);
      });
    }
  }

  getChildContext() {
    return {
      onErrorClose: this.onErrorClose,
      inputErrors: this.props.inputErrors,
    };
  }

  onErrorClose(event, field) {
    this.props.closeValidationError({ field });
  }

  onChange(event) {
    const { name, value } = event.target;

    return this.props.handleUpdateFinder({
      name,
      value,
    });
  }

  onFind() {
    const { isValid, finder, findEmail } = this.props;

    if (isValid) {
      findEmail({
        fullName: unwrap(finder.get('fullName')),
        domain: unwrap(finder.get('domain')),
      });
    }
  }

  render() {
    const { finder, children, isValid } = this.props;

    return (
      <div>
        <Popups.EmailFinderPopup finder={finder} isValid={isValid} onChange={this.onChange} onFind={this.onFind} />
        {children}
      </div>
    );
  }
}

EmailFinderPopupContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  hasPopup: PropTypes.func.isRequired,
};

EmailFinderPopupContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};

function mapStateToProps(state) {
  return {
    isValid: state.findEmail.get('isValid', false),
    inputErrors: state.findEmail.get('inputErrors'),
    finder: state.findEmail,
  };
}

function mapDispatchToProps(dispatch) {
  const { findEmail, clearFoundEmail, closeValidationError, handleUpdateFinder } = TARGET;

  return bindActionCreators(
    {
      findEmail,
      clearFoundEmail,
      closeValidationError,
      handleUpdateFinder,
    },
    dispatch,
  );
}

export { EmailFinderPopupContainer };

const shortcutWrapped = shortcut(EmailFinderPopupContainer, 'ctrl+enter', 'onSave');
const Connected = connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions)(shortcutWrapped);

export default Connected;
