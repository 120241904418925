import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import { verifyEmail, clearVerifiedEmail } from '../../actions/verifyEmail';
import Spinner from './Spinner';

class EmailVerificationInput extends PureComponent {
  constructor(props) {
    super(props);
    this.onBlur = this.onBlur.bind(this);
    this.onVerify = this.onVerify.bind(this);
    this.inputRef = this.inputRef.bind(this);
  }

  componentWillUnmount() {
    this.props.clearVerifiedEmail();
  }

  inputRef(input) {
    this.input = input;
    if (this.props.inputRef) {
      this.props.inputRef(input);
    }
  }

  onBlur(...rest) {
    if (this.props.onBlur) return this.props.onBlur(...rest);
  }

  onVerify() {
    this.props.verifyEmail(this.input.value);
  }

  render() {
    const {
      id,
      isLoading,
      label,
      values,
      canDisplayError,
      status,
      showLabel = false,
      verifyEmail,
      clearVerifiedEmail,
      ...extraProps
    } = this.props;
    const labelClassName = classNames({ hidden: !showLabel });
    let statusEmail = null;

    if (status === 'valid') {
      statusEmail = <span className="icon-verify fa fa-check" />;
    } else if (status === 'invalid') {
      statusEmail = <span className="icon-verify fa fa-close" />;
    }

    return (
      <div className="has-feedback-email-verify">
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
        <div className="input-group">
          <input {...extraProps} {...values} ref={this.inputRef} autoComplete="off" onBlur={this.onBlur} />
          {statusEmail}
          <span className="input-group-btn">
            <button className="btn btn-default" disabled={isLoading} onClick={this.onVerify} type="button">
              {isLoading ? (
                <Spinner className="email-verify-spinner" size="small" />
              ) : (
                <i className="fa fa-search mr0" title="Verify Email" />
              )}
            </button>
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  status: state.verifyEmail.get('status'),
  isLoading: state.verifyEmail.get('loading'),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      verifyEmail,
      clearVerifiedEmail,
    },
    dispatch,
  ),
});

const ConnectedEmailVerifitation = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
  connectOptions,
)(EmailVerificationInput);

export { ConnectedEmailVerifitation };
export default ConnectedEmailVerifitation;
