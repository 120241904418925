import Immutable, { fromJS } from 'immutable';

import handleApiError from './decorators/handleApiError';
import { autoRemoveId, check, resetFieldByOther } from './decorators/suggest';
import { toJS, spy, setIn } from '../utils/ChangeSpy';
import * as ActionType from '../actions/oneOff';
import getValidator from '../utils/getValidator';

const SCHEMA = {
  type: 'object',
  properties: {
    tag: {
      title: 'Harvey Tag',
      description: 'Should not be empty',
      type: 'string',
      required: true,
      minLength: 1,
    },
    buyerId: {
      title: 'Buyer',
      description: 'Should not be empty',
      type: 'number',
      minValue: 1,
    },
    projectId: {
      title: 'Project',
      description: 'Should not be empty',
      type: 'number',
      minValue: 1,
    },
    recordOwnerId: {
      title: 'Module',
      description: 'Should not be empty',
      type: 'number',
      minValue: 1,
    },
    recordSubOwnerId: {
      title: 'A/A',
      description: 'Should not be empty',
      type: 'number',
      minValue: 1,
    },
  },
};

const FIELDS_TO_SPY = [
  'tag',
  'buyerId',
  'buyer',
  'projectId',
  'project',
  'recordOwnerId',
  'recordOwner',
  'recordSubOwnerId',
  'recordSubOwner',
  'description',
  'date',
];

const defaultState = Immutable.fromJS(
  spy(
    {
      tag: '',
      buyerId: '',
      buyer: '',
      projectId: '',
      project: '',
      recordOwnerId: '',
      recordOwner: '',
      recordSubOwnerId: '',
      recordSubOwner: '',
      description: '',
      date: '',
      isValid: false,
      countTargets: 0,
      loadingCountTargets: false,
    },
    FIELDS_TO_SPY,
  ),
);

const validator = getValidator(SCHEMA);

let wrappedReducer = handleApiError(ActionType.CREATE_ONE_OFF_APPROACH_ERROR, reducer);

wrappedReducer = check('buyer', 'suggestBuyer', ActionType.LOADED_BUYERS_LIST, wrappedReducer);
wrappedReducer = check('project', 'suggestProject', ActionType.LOADED_BUYERS_PROJECTS_LIST, wrappedReducer);
wrappedReducer = check('recordOwner', 'suggestModule', ActionType.LOADED_USERS, wrappedReducer);
wrappedReducer = check('recordSubOwner', 'suggestAnalyst', ActionType.LOADED_USERS, wrappedReducer);
wrappedReducer = autoRemoveId('recordOwner', 'recordOwnerId', ActionType.UPDATE_FIELD, wrappedReducer);
wrappedReducer = autoRemoveId('recordSubOwner', 'recordSubOwnerId', ActionType.UPDATE_FIELD, wrappedReducer);
wrappedReducer = autoRemoveId('buyer', 'buyerId', ActionType.UPDATE_FIELD, wrappedReducer);
wrappedReducer = autoRemoveId('buyer', 'projectId', ActionType.UPDATE_FIELD, wrappedReducer);
wrappedReducer = resetFieldByOther('buyerId', 'projectId', ActionType.UPDATE_FIELD, wrappedReducer);
wrappedReducer = resetFieldByOther('buyerId', 'project', ActionType.UPDATE_FIELD, wrappedReducer);

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.UPDATE_FIELD:
      return checkValidity(setIn(state, action.name, action.value));

    case ActionType.LOADED_BUYERS_PROJECTS_LIST:
      return state.set(
        'suggestProject',
        fromJS(
          action.response.data.map(project => ({
            text: project.category,
            id: project.id,
          })),
        ),
      );

    case ActionType.CREATE_ONE_OFF_APPROACH:
      return state.set('isFetching', true);

    case ActionType.CREATE_ONE_OFF_APPROACH_SUCCESS:
      return defaultState;

    case ActionType.CLOSE_VALIDATION_ERROR:
      return state.deleteIn(['inputErrors', action.field]);

    case ActionType.REVERT_UPDATE:
      return defaultState;

    case ActionType.LOAD_COUNT_TARGETS:
      return state.merge({ loadingCountTargets: true });

    case ActionType.LOADED_COUNT_TARGETS_SUCCESS:
      return state.merge({
        countTargets: action.response.targetCount,
        loadingCountTargets: false,
      });

    case ActionType.LOADED_COUNT_TARGETS_FAILURE:
      return state.merge({ loadingCountTargets: false });

    default:
      return state;
  }
}

function checkValidity(state) {
  const plainState = toJS(state);
  const isValid = validator.isValid(plainState);

  const inputErrors = validator.getFirstError(plainState);

  return state.merge({
    inputErrors,
    isValid,
  });
}
