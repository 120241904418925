let clickCounter = 0;

/**
 * Delay 250ms and check whether or not it is a double click event before calling the handler.
 *
 * @param data {Any} Data to be passed to Callback function.
 * @param callback {Function} Callback function.
 * @returns
 */
const doubleClickCheck = (data, callback) => {
  if (clickCounter === 0) {
    clickCounter = 1;
    setTimeout(() => {
      const eventType = clickCounter > 1 ? 'dbclick' : 'click';

      clickCounter = 0;

      return callback({ eventType, data });
    }, 250);
  } else {
    clickCounter++;
  }
};

export default doubleClickCheck;

export const clickIfNotDouble = (callback, dblCallback, find = a => a) => {
  const doubleClickMap = new Map();

  const doubleClick = function(...rest) {
    const key = find(...rest);

    doubleClickMap.set(key, true);
    setTimeout(() => {
      doubleClickMap.delete(key);
    }, 250);

    return dblCallback(...rest);
  };

  const click = function(...rest) {
    const key = find(...rest);

    doubleClickMap.delete(key);
    setTimeout(() => {
      if (!doubleClickMap.has(key)) {
        return callback(...rest);
      }
    }, 250);
  };

  return [click, doubleClick];
};
