import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';

const Spinner = props => {
  const { size, className = '', spinnerClassName = '' } = props;
  const spinnerCss = classNames('fa fa-spinner fa-pulse fa-fw', spinnerClassName, {
    'fa-1x': size === Spinner.size.SMALL,
    'fa-3x': size === Spinner.size.MIDDLE,
    'fa-5x': size === Spinner.size.LARGE,
  });

  return (
    <div className={className}>
      <div className="spinner--curtain" />
      <div className="spinner--icon text-center">
        <i className={spinnerCss} />
      </div>
    </div>
  );
};

Spinner.size = {
  SMALL: 'small',
  MIDDLE: 'middle',
  LARGE: 'large',
};

Spinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(Spinner.size)),
};

Spinner.defaultProps = {
  size: Spinner.size.MIDDLE,
};

export default Spinner;
