import PropTypes from 'prop-types';

import React, { Component } from 'react';
import Immutable from 'immutable';

import { connect } from 'react-redux';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import Popups from '../../components/Popup';
import config from '../../config';
import {
  updateField,
  loadSuggestion,
  selectSuggestion,
  closeSuggestion,
  resetForm,
} from '../../actions/popup/buyerPopup';
import { fetchTargetStatuses } from '../../actions/statuses';

/**
 * Add/Update buyer popup container component.
 *
 * @param props {Object}.
 * @param props.buyer {Immutable.Map} Buyer information.
 * @param props.project {Immutable.Map} Project information.
 * @param props.approval {Immutable.Map} Approval list information.
 * @param props.currentStatus {String} Current status.
 * @param props.highStatus {String} High status.
 * @param props.statuses {Immutable.List} List of statuses.
 * @param props.waiting {Boolean} Show spinner icon if set to true.
 * @param props.activeBuyer {Boolean} Checkbox value of buyer.
 * @param props.overrideCurrent {Boolean} Checkbox value of current status.
 * @param props.overrideHigh {Boolean} Checkbox value of high status.
 * @param props.overrideProject {Boolean} Checkbox value of project.
 * @param props.overrideApproval {Boolean} Checkbox value of approval list.
 * @param props.disableProject {Boolean} If set to true, will disable project.
 * @param props.disableApproval {Boolean} If set to true, will disable approval.
 * @param props.onSave {Function} Function to handle on save button.
 * @returns {React.Component}
 */
class AddUpdateBuyerPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      statuses: Immutable.List(),
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSuggestionClose = this.handleSuggestionClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.context.onClosePopup(this.props.resetForm);

    this.props.fetchTargetStatuses(({ response: { data } }) => {
      this.setState({
        statuses: generateStatusList(Immutable.fromJS(data)),
      });
    });
  }

  handleClose() {
    this.context.closePopup();
  }

  handleChange({ event }) {
    const { name, value, checked } = event.target;
    const checkboxes = ['activeBuyer', 'overrideCurrent', 'overrideHigh', 'overrideProject', 'overrideApproval'];

    this.props.updateField(name, checkboxes.includes(name) ? checked : value);
    if (value && value.length >= config.NUMBER_OF_MIN_CHARACTER) {
      if (name === 'project') {
        this.props.selectSuggestion(name, null, value);
        this.props.selectSuggestion('approval', null, '');
        this.props.loadSuggestion(name, value, this.props.buyer.get('id'));
      } else if (name === 'approval') {
        this.props.selectSuggestion(name, null, value);
        this.props.loadSuggestion(name, value, this.props.project.get('id'));
      } else if (name === 'buyer') {
        this.props.selectSuggestion(name, null, value);
        this.props.selectSuggestion('project', null, '');
        this.props.selectSuggestion('approval', null, '');
        this.props.loadSuggestion(name, value);
      }
    }
  }

  handleSelect({ event, suggestion }) {
    const { name } = event.target;
    const value = suggestion.get('name');
    const id = suggestion.get('id');

    this.props.selectSuggestion(name, id, value);
  }

  handleSuggestionClose({ event }) {
    const { name } = event.target;

    this.props.closeSuggestion(name);
  }

  handleSave() {
    const onSave = this.props.popup.getIn(['props', 'onSave'], () => {});
    const {
      buyer,
      activeBuyer,
      currentStatus,
      overrideCurrent,
      highStatus,
      overrideHigh,
      project,
      overrideProject,
      approval,
      overrideApproval,
    } = this.props;
    const { statuses } = this.state;
    const selectedCurrentStatus = statuses.filter(item => item.get('value') === currentStatus).get(0);
    const current_status_id = statuses.indexOf(selectedCurrentStatus);
    const selectedHighStatus = statuses.filter(item => item.get('value') === highStatus).get(0);
    const high_status_id = statuses.indexOf(selectedHighStatus);
    const refreshGrid = this.props.popup.getIn(['props', 'refreshGrid'], () => {});

    onSave(
      {
        buyer_id: buyer.get('id'),
        active_buyer: activeBuyer || undefined,
        current_status_id,
        override_current_status: overrideCurrent,
        high_status_id,
        override_high_status: overrideHigh,
        project_id: project.get('id'),
        override_project: overrideProject,
        approval_list_id: approval.get('id') === 0 ? null : approval.get('id'),
        override_approval_list: overrideApproval,
      },
      {
        beforeStart: this.context.startPopupLoading,
        afterSuccess: () => {
          this.context.endPopupLoading();
          refreshGrid();
        },
        afterError: ({ resBody }) => {
          this.context.endPopupLoading(resBody);

          const errors = [];

          Object.keys(resBody.errors).forEach(key => {
            errors.push(resBody.errors[key]);
          });
          this.props.updateField(errors);
        },
      },
    );
  }

  render() {
    const canSave = this.props.buyer.get('id') && this.props.project.get('id');
    const { statuses } = this.state;

    return (
      <Popups.AddUpdateBuyerPopup
        {...this.props}
        canSave={canSave}
        onChange={this.handleChange}
        onClose={this.handleClose}
        onSave={this.handleSave}
        onSelect={this.handleSelect}
        onSuggestionClose={this.handleSuggestionClose}
        statuses={statuses}
      />
    );
  }
}

AddUpdateBuyerPopupContainer.propTypes = {
  activeBuyer: PropTypes.bool.isRequired,
  approval: PropTypes.instanceOf(Immutable.Map).isRequired,
  buyer: PropTypes.instanceOf(Immutable.Map).isRequired,
  currentStatus: PropTypes.string.isRequired,
  disableApproval: PropTypes.bool.isRequired,
  // statuses: PropTypes.instanceOf(Immutable.List).isRequired,
  disableProject: PropTypes.bool.isRequired,
  errors: PropTypes.instanceOf(Immutable.List).isRequired,
  highStatus: PropTypes.string.isRequired,
  overrideApproval: PropTypes.bool.isRequired,
  overrideCurrent: PropTypes.bool.isRequired,
  overrideHigh: PropTypes.bool.isRequired,
  overrideProject: PropTypes.bool.isRequired,
  project: PropTypes.instanceOf(Immutable.Map).isRequired,
  waiting: PropTypes.bool.isRequired,
};

AddUpdateBuyerPopupContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  startPopupLoading: PropTypes.func.isRequired,
  endPopupLoading: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  const form = state.buyerPopup;
  const { popup } = props;

  return {
    buyer: form.get('buyer'),
    project: form.get('project'),
    approval: form.get('approval'),
    currentStatus: form.get('currentStatus'),
    highStatus: form.get('highStatus'),
    // statuses: generateStatusList(statuses),
    waiting: popup.get('isPopupFetching'),
    activeBuyer: form.get('activeBuyer'),
    overrideCurrent: form.get('overrideCurrent'),
    overrideHigh: form.get('overrideHigh'),
    overrideProject: form.get('overrideProject'),
    overrideApproval: form.get('overrideApproval'),
    buyerSuggestions: form.get('buyerSuggestions'),
    projectSuggestions: form.get('projectSuggestions'),
    approvalSuggestions: form.get('approvalSuggestions'),
    disableProject: form.getIn(['buyer', 'id'], 0) <= 0,
    disableApproval: form.getIn(['project', 'id'], 0) <= 0,
    errors: form.get('errors'),
  };
}

/**
 * Recreate target status list.
 *
 * @param statuses {Immutable.List} Status list.
 * @returns {Immutable.List}
 */
function generateStatusList(statuses) {
  return statuses.map(s => {
    const value = s.get('fc');
    const name = s.get('d');

    return Immutable.Map({
      value,
      name: `${value} - ${name}`,
    });
  });
}

export default connect(
  mapStateToProps,
  {
    updateField,
    loadSuggestion,
    selectSuggestion,
    closeSuggestion,
    resetForm,
    fetchTargetStatuses,
  },
  mergeProps,
  connectOptions,
)(AddUpdateBuyerPopupContainer);
