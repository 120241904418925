import Immutable from 'immutable';
import { isEmpty } from 'underscore';

import {
  LOADING_PAGE,
  LOADED_APPROVALS_FAILURE,
  LOADED_APPROVALS_SUCCESS,
  CLEAR_ERRORS,
  SELECT_APPROVAL,
  CHANGE_DATE,
  SAVE_APPROVAL_SUCCESS,
  LOADING_ONLINE_APPROVALS,
  LOADING_ONLINE_APPROVALS_SUCCESS,
  LOADING_ONLINE_APPROVALS_FAILURE,
  UPDATE_ONLINE_APPROVALS,
  SAVE_ONLINE_APPROVALS,
  SAVE_ONLINE_APPROVALS_SUCCESS,
  SAVE_ONLINE_APPROVALS_FAILURE,
  GENERATE_LINK,
  GENERATE_LINK_SUCCESS,
  GENERATE_LINK_FAILURE,
  LOAD_LINKS_LIST_SUCCESS,
} from '../../actions/approval/main';
import {
  LOADING_ASSOCIATES,
  LOADING_ASSOCIATES_PAGE,
  LOADED_ASSOCIATES_SUCCESS,
  LOADED_ASSOCIATES_FAILURE,
  SORT_TABLE,
  CHANGE_APPROVAL_NUMBER_OF_APPROVED,
  CHANGE_NO_APPROVAL_NUMBER_OF_APPROVED,
  CHANGE_APPROVAL_PERCENT_OF_APPROVED,
  CHANGE_NO_APPROVAL_PERCENT_OF_APPROVED,
} from '../../actions/approval/associate';
import getErrorResponse from '../../utils/getErrorResponse';
import { setIn, setOriginalIn } from '../../utils/ChangeSpy';
import { mapApproval, mapNoApproval } from './approval';

const defaultState = Immutable.fromJS({
  appListLoading: false,
  associatesLoading: false,
  topLoading: false,
  approval: {},
  noApproval: {},
  linksList: [],
  onlineApprovalLoading: false,
  onlineApprovalSaving: false,
  linksSending: false,
  onlineApproval: {},
  errors: [],
  associateRequestId: '',
  sorting: '',
  paging: {},
});

export default function(state = defaultState, action, approvals) {
  switch (action.type) {
    case SELECT_APPROVAL:
      return state.set('approval', lookupApproval(action.id, approvals));

    case LOADING_ASSOCIATES:

    // fallthrough
    case LOADING_ASSOCIATES_PAGE:
      return state.merge({
        associatesLoading: true,
        associateRequestId: action.requestId,
      });

    case CLEAR_ERRORS:
      return state.set('errors', Immutable.List());

    case LOADING_PAGE:
      return state.merge({
        appListLoading: true,
        associatesLoading: true,
        topLoading: true,
      });

    case LOADED_APPROVALS_SUCCESS:
      return afterLoadApprovals(state, action);

    case LOADED_ASSOCIATES_SUCCESS: {
      if (action.requestId === state.get('associateRequestId')) {
        return state.set('associatesLoading', false).merge({ paging: action.response.meta.pagination });
      }

      return state;
    }

    case LOADED_ASSOCIATES_FAILURE: {
      if (action.requestId === state.get('associateRequestId')) {
        return state.set('associatesLoading', false);
      }

      return state;
    }

    case LOADED_APPROVALS_FAILURE:
      return state.merge({
        appListLoading: false,
        associatesLoading: false,
        topLoading: false,
        errors: state.get('errors').concat(getErrorResponse(action.response)),
      });

    case LOADING_ONLINE_APPROVALS:
      return state.merge({
        onlineApprovalLoading: true,
      });

    case LOADING_ONLINE_APPROVALS_FAILURE:
      return state.merge({
        onlineApprovalLoading: false,
        errors: state.get('errors').concat(getErrorResponse(action.response)),
      });

    case LOADING_ONLINE_APPROVALS_SUCCESS:
      return state.merge({
        onlineApprovalLoading: false,
        onlineApproval: action.response.data,
        errors: [],
      });

    case GENERATE_LINK:
      return state.merge({
        linksSending: true,
      });

    case GENERATE_LINK_FAILURE:
      return state.merge({
        linksSending: false,
        errors: getErrorResponse(action.response),
      });

    case GENERATE_LINK_SUCCESS:
      return state.merge({
        linksSending: false,
        errors: [],
      });

    case SAVE_ONLINE_APPROVALS:
      return state.merge({
        onlineApprovalSaving: true,
      });

    case SAVE_ONLINE_APPROVALS_FAILURE:
      return state.merge({
        onlineApprovalSaving: false,
        errors: state.get('errors').concat(getErrorResponse(action.response)),
      });

    case SAVE_ONLINE_APPROVALS_SUCCESS:
      return state.merge({
        onlineApprovalSaving: false,
        errors: [],
      });

    case UPDATE_ONLINE_APPROVALS:
      return state.setIn(['onlineApproval', 'approvalListData'], Immutable.fromJS(action.approvals));

    case LOAD_LINKS_LIST_SUCCESS:
      return state.merge({
        linksList: action.response.data,
      });

    case CHANGE_DATE:
      return setIn(state, ['approval', action.name], action.value);

    case SAVE_APPROVAL_SUCCESS:
      return state.update('approval', state =>
        Object.keys(action.body).reduce((state, key) => setOriginalIn(state, key, action.body[key]), state),
      );

    case SORT_TABLE:
      return state.set('sorting', action.sorting);

    case CHANGE_APPROVAL_NUMBER_OF_APPROVED:
      return state.mergeDeep({ approval: { numberApproved: action.approvedCount } });

    case CHANGE_NO_APPROVAL_NUMBER_OF_APPROVED:
      return state.mergeDeep({ noApproval: { numberApproved: action.approvedCount } });

    case CHANGE_APPROVAL_PERCENT_OF_APPROVED:
      return state.mergeDeep({ approval: { percentApproved: action.percent } });

    case CHANGE_NO_APPROVAL_PERCENT_OF_APPROVED:
      return state.mergeDeep({ noApproval: { percentApproved: action.percent } });

    default:
      return state;
  }
}

/**
 * Turn of spinner and lookup approval if approvalId is provided.
 *
 * @param {Immutable.Map} state State.
 * @param {object} action Action.
 * @return {Immutable.List}
 */
function afterLoadApprovals(state, action) {
  const { approvalId, response } = action;
  const { approvalLists, noApprovalLists } = response.data;

  if (response.status === 'success' && approvalId && approvalLists) {
    const index = approvalLists.findIndex(a => a.id === approvalId);
    const noApproval = isEmpty(noApprovalLists) ? Immutable.Map() : mapNoApproval(noApprovalLists[0]);

    return state.mergeDeep({
      appListLoading: false,
      topLoading: false,
      approval: index >= 0 ? mapApproval(approvalLists[index]) : Immutable.Map(),
      noApproval,
    });
  }

  return state.merge({ appListLoading: false, topLoading: false });
}

/**
 * Lookup approval by id.
 *
 * @param id {Number} Approval id.
 * @param approvals {Immutable.List} Approval list.
 * @returns {Immutable.List}
 */
function lookupApproval(id, approvals) {
  const ret = approvals.find(ap => ap.get('id') === id);

  return ret || Immutable.Map();
}
