import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { getIn } from 'src/utils/ChangeSpy';
import logo from '../../assets/img/content/logo-2.png';
import TopPanel from './TopPanel';
import BottomPanel from './BottomPanel';
import ChartsPanel from './ChartsPanel';
import Spinner from '../helpers/Spinner';

const Dashboard = ({ data }) => {
  const isFetching =
    getIn(data, ['allTransactions', 'isFetching']) &&
    getIn(data, ['addOnTransactions', 'isFetching']) &&
    getIn(data, ['platformTransactions', 'isFetching']) &&
    getIn(data, ['buyers', 'isFetching']) &&
    getIn(data, ['dealsClosed', 'isFetching']) &&
    getIn(data, ['dealsClosedByState', 'isFetching']) &&
    getIn(data, ['targetsStats', 'isFetching']) &&
    getIn(data, ['buyersStats', 'isFetching']) &&
    getIn(data, ['executivesStats', 'isFetching']) &&
    getIn(data, ['dealsClosedByYears', 'isFetching']) &&
    getIn(data, ['executiveDeals', 'isFetching']) &&
    getIn(data, ['professionals', 'isFetching']) &&
    getIn(data, ['targetsApproachedByYears', 'isFetching']);

  const spinner = isFetching ? <Spinner className="bg-white" /> : null;

  return (
    <>
      <header className="p20 border-bottom">
        <img alt="logo" className="img-responsive img-logo dashboard-logo" src={logo} />
      </header>
      <div className="container-fluid dashboard-page pl20 pr20">
        <h1 className="text-bold pt25 pb15">Statistics</h1>
        <div className="row">
          <div className="col-xs-8">
            <TopPanel data={data} />
            <BottomPanel data={data} />
          </div>
          <div className="col-xs-4">
            <ChartsPanel data={data} />
          </div>
          {spinner}
        </div>
      </div>
    </>
  );
};

Dashboard.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
};

export default Dashboard;
