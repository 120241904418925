import React from 'react';
import classNames from 'classnames';
import { fromJS } from 'immutable';

import FormGroup from '../../helpers/FormGroup';
import Select from '../../helpers/Select';

const OPTIONS = fromJS(
  ['', 'S Corp', 'C Corp', 'LLC', 'LP', 'LLP', 'PC'].map(v => ({
    value: v,
    name: v,
  })),
);

const EntityType = props => {
  const { className, ...rest } = props;
  const cls = classNames('input-sm', className);

  return (
    <FormGroup>
      <Select {...rest} className={cls} label="Entity Type" options={OPTIONS} showLabel />
    </FormGroup>
  );
};

export default EntityType;
