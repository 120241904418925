import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import Input from '../helpers/Input';
import FormGroup from '../helpers/FormGroup';
import { dashboardDates } from '../../selectors/dashboard';
import { companyInfo } from '../../selectors/targetCompany';
import { changeField } from '../../actions/dashboards';
import { findDirectors, findAnalysts, clearUsers } from '../../actions/company/companyTarget';
import config from '../../config';
import { InputProps } from '../helpers/AutoComplete';
import AutoComplete from '../../containers/AutoComplete';
import downloadFile from '../../utils/downloadFile';
import { showInformation } from '../../utils/MessagePopup';

const SUGGEST_ID = 'suggestDirectorId';

/**
 * Activity report popup component.
 *
 * @component
 */
const ActivityReportPopup = (_props, context) => {
  const dispatch = useDispatch();
  const dates = useSelector(dashboardDates);
  const [include, setInclude] = useState('owner');
  const [activity, setActivity] = useState('inactive');
  const companyInfoData = useSelector(companyInfo);

  // Suggest state
  const [suggestValue, setSuggestValue] = useState('');
  const [selectedInclude, setSelectedInclude] = useState([]);

  const [formError, setFormError] = useState(null);

  useEffect(() => {
    setFormError(null);
  }, [include, activity, dates]);

  /**
   * Date changed handler.
   *
   * @param {SyntheticEvent} event The react `SyntheticEvent`.
   */
  const onDateChange = event => {
    const { name: field, value } = event.target;

    dispatch(changeField({ field, value }));
  };

  /**
   * Form submit handler.
   *
   * @param {SyntheticEvent} event The react `SyntheticEvent`.
   */
  const handleSubmit = event => {
    event.preventDefault();

    if (isFormValid()) {
      const url = config.main.get('activityReport');
      const from = dates.get('from').format('YYYY-MM-DD');
      const to = dates.get('to').format('YYYY-MM-DD');
      const selectedQuery =
        selectedInclude.length > 0 && `&${selectedInclude.map(item => `selected[]=${item.id}`).join('&')}`;

      const onResponseJSON = data => {
        showInformation(context.openPopup, data.message);
      };

      if (selectedQuery) {
        downloadFile({
          url: `${url}?from=${from}&to=${to}&include=${include}&activity=${activity}${selectedQuery}`,
          onResponseJSON,
        });
      } else {
        downloadFile({
          url,
          params: { from, to, include, activity },
          onResponseJSON,
        });
      }

      context.closePopup();
    }
  };

  /**
   * "Include" radio input change handler.
   *
   * @param {SyntheticEvent} event The react `SyntheticEvent`.
   */
  const handleIncludeChange = event => {
    setInclude(event.target.value);
    setSuggestValue('');
    setSelectedInclude([]);
    dispatch(clearUsers());
  };

  /**
   * "Activity" radio input change handler.
   *
   * @param {SyntheticEvent} event The react `SyntheticEvent`.
   */
  const handleActivityChange = event => {
    setActivity(event.target.value);
  };

  /**
   * Check "Activity" field on click with the same value.
   *
   * @param {SyntheticEvent} event The react `SyntheticEvent`.
   */
  const handleActivitySameClick = event => {
    const newValue = event.target.value;

    if (newValue === activity) {
      setActivity('inactive');
    }
  };

  /**
   * Suggests changed handler.
   *
   * @param {object} payload Autocomplete payload.
   * @param {string} payload.name Suggest field name.
   * @param {number} payload.value Suggest field value.
   */
  const handleSuggestChange = ({ name, value }) => {
    if (name === 'suggestText') {
      setSuggestValue(value);
    }
  };

  /**
   * Suggests selected handler.
   *
   * @param {object} payload Autocomplete select payload.
   * @param {string} payload.name Suggest id.
   * @param {number} payload.value Suggest field value.
   */
  const handleSuggestSelect = ({ id, text }) => {
    setSelectedInclude([...selectedInclude, { id, name: text }]);
    setSuggestValue('');
  };

  /**
   * Suggests selected handler.
   *
   * @param {SyntheticEvent} event Autocomplete select payload.
   */
  const handleRemoveSelectedInclude = event => {
    const { name } = event.target;
    const id = parseInt(name.replace('include_', ''), 10);

    setSelectedInclude(selectedInclude.filter(item => item.id !== id));
  };

  const isFormValid = () => {
    setFormError(null);

    if (include === 'owner' || include === 'sub-owner') {
      if (selectedInclude.length === 0) {
        setFormError('Please select individuals');

        return false;
      }
    }

    return true;
  };

  const renderSelectedInclude =
    selectedInclude.length > 0 &&
    selectedInclude.map(include => (
      <div key={include.id} className="w160 MultiselectItem">
        {include.name}
        <button
          aria-label="close"
          className="MultiselectItem-close close btn-xs"
          name={`include_${include.id}`}
          onClick={handleRemoveSelectedInclude}
          type="button"
        >
          &times;
        </button>
      </div>
    ));

  const renderDirectors = include === 'owner' && (
    <>
      <FormGroup className="w160 form-inline-block">
        <InputProps className="form-control input-sm text-bold" placeholder="Director">
          <AutoComplete
            change={handleSuggestChange}
            find={params => {
              dispatch(findDirectors(params));
            }}
            highlightFirst={false}
            idName={SUGGEST_ID}
            idValue={SUGGEST_ID}
            onSelectCallback={handleSuggestSelect}
            suggestions={companyInfoData.get('suggests')}
            suggestsName="suggests"
            value={suggestValue}
            valueName="suggestText"
          />
        </InputProps>
      </FormGroup>

      {renderSelectedInclude}
    </>
  );

  const renderAnalyst = include === 'sub-owner' && (
    <>
      <FormGroup className="w160 form-inline-block">
        <InputProps className="form-control input-sm text-bold" placeholder="Analyst/Associate">
          <AutoComplete
            change={handleSuggestChange}
            find={params => {
              dispatch(findAnalysts(params));
            }}
            highlightFirst={false}
            idName={SUGGEST_ID}
            idValue={SUGGEST_ID}
            onSelectCallback={handleSuggestSelect}
            suggestions={companyInfoData.get('suggests')}
            suggestsName="suggests"
            value={suggestValue}
            valueName="suggestText"
          />
        </InputProps>
      </FormGroup>

      {renderSelectedInclude}
    </>
  );

  const renderFormError = formError && <p className="block-center mb0 mt15 error-text">{formError}</p>;

  const header = 'Download Activity Report';
  const footer = (
    <Button className="btn-default btn-xs btn-primary" onClick={handleSubmit} type="button">
      Download
    </Button>
  );

  return (
    <Popup footer={footer} header={header} id="OutreachPopup">
      <p className="mb4">Date Range:</p>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <FormGroup className="col-lg-4 col-sm-12">
            <Input
              label="From"
              maxDate={dates.get('to')}
              name="popupDates.from"
              onChange={onDateChange}
              placeholder="From"
              type="date"
              value={dates.get('from')}
              required
              showLabel
            />
          </FormGroup>

          <p className="pull-left pt17">-</p>

          <FormGroup className="col-lg-4 col-sm-12">
            <Input
              label="To"
              minDate={dates.get('from')}
              name="popupDates.to"
              onChange={onDateChange}
              placeholder="To"
              type="date"
              value={dates.get('to')}
              required
              showLabel
            />
          </FormGroup>
        </div>

        <p className="mt15 mb4">Individuals Included:</p>

        <FormGroup>
          <div className="radio-wrap radio-wrap--reverse">
            <label htmlFor="includeDirectors">Directors</label>
            <input
              checked={include === 'owner'}
              id="includeDirectors"
              name="include"
              onChange={handleIncludeChange}
              type="radio"
              value="owner"
            />
          </div>
          {renderDirectors}
        </FormGroup>
        <FormGroup>
          <div className="radio-wrap radio-wrap--reverse">
            <label htmlFor="includeAnalystAssociate">Analyst/Associate</label>
            <input
              checked={include === 'sub-owner'}
              id="includeAnalystAssociate"
              name="include"
              onChange={handleIncludeChange}
              type="radio"
              value="sub-owner"
            />
          </div>
          {renderAnalyst}
        </FormGroup>
        <FormGroup>
          <div className="radio-wrap radio-wrap--reverse">
            <label htmlFor="includeAll">All</label>
            <input
              checked={include === 'all'}
              id="includeAll"
              name="include"
              onChange={handleIncludeChange}
              type="radio"
              value="all"
            />
          </div>
        </FormGroup>

        <p className="mt20 mb4">Active/Inactive Projects:</p>

        <FormGroup>
          <div className="radio-wrap radio-wrap--reverse">
            <label htmlFor="activityActive">Active: Only include "Active" Projects</label>
            <input
              checked={activity === 'active'}
              id="activityActive"
              name="activity"
              onChange={handleActivityChange}
              onClick={handleActivitySameClick}
              type="radio"
              value="active"
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="radio-wrap radio-wrap--reverse radio-wrap--start">
            <label htmlFor="activityAll">
              All: Include both "Active" and "Inactive" Projects <br />
              where activity occurred during the date range provided
            </label>
            <input
              checked={activity === 'all'}
              id="activityAll"
              name="activity"
              onChange={handleActivityChange}
              onClick={handleActivitySameClick}
              type="radio"
              value="all"
            />
          </div>
        </FormGroup>
        {renderFormError}
      </form>
    </Popup>
  );
};

ActivityReportPopup.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
};

export default ActivityReportPopup;
