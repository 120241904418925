import React from 'react';
import { Route, Switch } from 'react-router-dom';

import User from '../containers/User';
import TagManagement from '../containers/TagManagement';
import ProjectContainer from '../containers/Project';
import NextAction from '../containers/NextAction';
import SearchContainer from '../containers/Search';
import Main from '../containers/Main';
import Approval from '../containers/Approval';
import PreviewOnlineApprovalLists from '../containers/PreviewOnlineApprovalLists';
import Backlog from '../containers/Backlog';
import MailingHistory from '../containers/MailingHistory';
import MailingCreate from '../containers/MailingCreate';
import MailingInfo from '../containers/MailingInfo';
import Windward from '../containers/Windward';
import NotFound from '../containers/NotFound';
import Browse from '../containers/Browse';
import Contact from '../containers/Contact';
import Company from '../containers/Company';
import CompanyTargetCreate from '../containers/Company/CompanyTargetCreate';
import CompanyBuyerCreate from '../containers/Company/CompanyBuyerCreate';
import CompanyTradeGroups from '../containers/Company/CompanyTradeGroups';
import TradeShow from '../containers/TradeShow/TradeShow';
import DashboardContainer from '../containers/Dashboard';
import LeadsReport from '../containers/LeadsReport';

export default function AppRouting() {
  return (
    <Switch>
      <Route component={Main} path="/" exact />
      <Route component={Browse} path="/browse" />
      <Route component={Contact} path="/contact/:contactId" />
      <Route component={CompanyTradeGroups} path="/trade-groups" />
      <Route component={TradeShow} path="/trade-show/add" />
      <Route component={TradeShow} path="/trade-show/:tradeShowId" />
      <Route component={CompanyTargetCreate} path="/company/new/target" />
      <Route component={CompanyBuyerCreate} path="/company/new/buyer" />
      <Route component={Company} path="/company/:companyId" />
      <Route component={User} path="/user" />
      <Route component={TagManagement} path="/tag" />
      <Route component={User} path="/user/:id" />
      <Route component={PreviewOnlineApprovalLists} path="/project/:projectId/approval/:approvalId/preview" />
      <Route component={Approval} path="/project/:projectId/approval/:approvalId" />
      <Route component={ProjectContainer} path="/project/:projectId" />
      <Route component={SearchContainer} path="/search" />
      <Route component={NextAction} path="/next-actions" />
      <Route component={LeadsReport} path="/leads-report" />
      <Route component={Main} path="/main" />
      <Route component={Backlog} path="/backlog" />
      <Route component={MailingHistory} path="/mailing-history" />
      <Route component={MailingCreate} path="/mailing-create" />
      <Route component={MailingInfo} path="/mailing-info" />
      <Route component={Windward} path="/windward" />
      <Route component={DashboardContainer} path="/dashboard" />
      <Route component={NotFound} />
    </Switch>
  );
}
