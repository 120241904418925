import React from 'react';

export function showError(openPopup, errorMessages, callback, subheader = null) {
  openPopup('InformationPopup', {
    message: {
      subheader,
      texts: errorMessages,
      className: 'text-danger',
    },
    header: (
      <span>
        <i aria-hidden="true" className="fa fa-exclamation-triangle text-danger" /> Error Alert
      </span>
    ),
    onClosePopupCallBack: callback,
  });
}

export function showInformation(openPopup, messages, callback, classes) {
  openPopup('InformationPopup', {
    message: {
      texts: [messages],
      className: 'text-info',
    },
    header: (
      <span>
        <i aria-hidden="true" className="fa fa-exclamation-circle text-info" /> Information Alert
      </span>
    ),
    onClosePopupCallBack: callback,
    classes,
  });
}

export function showInformationCustomTitle(openPopup, header, messages, callback, classes) {
  openPopup('InformationPopup', {
    message: {
      texts: [messages],
      className: 'text-info mb10',
    },
    header: (
      <span>
        <i aria-hidden="true" className="fa fa-exclamation-circle text-info" /> {header}
      </span>
    ),
    onClosePopupCallBack: callback,
    classes,
  });
}
