import createTable from './CompanyDetailsTable';

export default createTable({
  namePrefix: 'tradeShowTargets',
  tableName: 'Trade Groups',
  columns: [
    { field: 'name', suggest: true, className: 'trade-show-name' },
    { field: `year`, title: 'Year', editable: false, className: 'trade-show-year' },
    { field: 'event.city', title: 'City', editable: false },
    { field: 'event.state', title: 'State', editable: false },
    { field: 'event.startDate', title: 'Date', type: 'date', editable: false },
    { field: 'booth', title: 'Booth', editable: true },
  ],
});
