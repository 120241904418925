import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Map, fromJS } from 'immutable';

import BrowseAutoComplete from './BrowseAutoComplete';
import BrowseCheckbox from './BrowseCheckbox';
import Tag from './Tag';

class BrowseInputTag extends PureComponent {
  constructor(props) {
    super(props);

    this.handleRemoveTag = this.handleRemoveTag.bind(this);
    this.handleAddTag = this.handleAddTag.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.focusOut = false;
  }

  componentDidUpdate() {
    if (this.focusOut) {
      this.button.focus();
      this.focusOut = false;
    }
  }

  handleChange({ filterName, filterData }) {
    if (filterData.selected) {
      let selectedList = this.props.info.get('selectedList');
      const selected = fromJS(filterData.selected);
      const field = selected.has('id') ? 'id' : 'value';

      if (selectedList.findIndex(s => s.get(field) === selected.get(field)) === -1) {
        selectedList = selectedList.push(selected.set('include', true));
        filterData.selectedList = selectedList;
      }
      this.focusOut = true;
    }
    this.props.onChange({
      filterName,
      filterData,
    });
  }

  handleRemoveTag(event) {
    event.stopPropagation();

    const { name } = event.target;
    const tagIndex = parseInt(name.replace('tag', ''), 10);
    let selectedList = this.props.info.get('selectedList');

    selectedList = selectedList.delete(tagIndex);

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        selectedList,
      },
    });
  }

  handleChangeStatus(index) {
    const { filter, info, onChange } = this.props;
    const selectedList = info.get('selectedList');

    onChange({
      filterName: filter.get('name'),
      filterData: {
        selectedList: selectedList.setIn([index, 'exclude'], !selectedList.getIn([index, 'exclude'])),
      },
    });
  }

  handleAddTag() {
    const { filter, info, onChange } = this.props;

    if (!filter.get('enableDynamicAdd', false) || info.get('text', '') === '') return false;

    let selectedList = info.get('selectedList');
    const selected = fromJS({
      value: info.get('text'),
      name: info.get('text'),
    });

    if (selectedList.findIndex(s => s.get('value') === selected.get('value')) === -1) {
      selectedList = selectedList.push(selected);

      onChange({
        filterName: this.props.filter.get('name'),
        filterData: {
          text: '',
          selectedList,
        },
      });
    }
  }

  renderFilterCheckboxes() {
    const { onChange } = this.props;

    if (!this.props.filter.get('filters')) return null;

    const filters = this.props.filter.get('filters').map(filter => (
      <label key={filter.get('name')} className="BrowseControl-label">
        <input
          onChange={event => {
            onChange({
              filterName: event.currentTarget.value,
              filterData: event.currentTarget.checked,
            });
          }}
          type="checkbox"
          value={filter.get('name')}
        />
        {filter.get('title')}
      </label>
    ));

    return <div className="form-inline">{filters}</div>;
  }

  render() {
    const { filter, info, noCheckbox } = this.props;
    const selectedList = info.get('selectedList');
    const tagListContent = selectedList
      .filter(tag => tag)
      .map((tag, i) => (
        <Tag
          key={i}
          exclude={tag.get('exclude')}
          index={i}
          onChangeStatus={this.handleChangeStatus}
          onRemoveTag={this.handleRemoveTag}
          value={tag.get(filter.get('renderField'))}
        />
      ));

    const autocomplete = (
      <>
        {tagListContent}
        <div className="form-inline">
          <BrowseAutoComplete
            filter={filter}
            info={info}
            onChange={this.handleChange}
            onGetNextSuggestion={this.props.onGetNextSuggestion}
            onGetSuggestion={this.props.onGetSuggestion}
            highlightFirstSuggestion
          >
            <span
              ref={input => {
                this.button = input;
              }}
              className="input-group-addon"
              onClick={this.handleAddTag}
              tabIndex="-1"
            >
              <i aria-hidden="true" className="fa fa-plus" />
            </span>
          </BrowseAutoComplete>
        </div>
        {this.renderFilterCheckboxes()}
      </>
    );

    if (noCheckbox) {
      return autocomplete;
    }

    return (
      <BrowseCheckbox {...this.props}>
        <div className="BrowseControl-content BrowseControl-content--add">{autocomplete}</div>
      </BrowseCheckbox>
    );
  }
}
BrowseInputTag.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onGetSuggestion: PropTypes.func.isRequired,
};

export default BrowseInputTag;
