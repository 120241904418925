import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { LinkButton } from '../helpers/Button';
import DropDown, { DropDownElement } from '../helpers/DropDown';
import config from '../../config';
import { getUserRole } from '../../utils/checkPermissions';

/**
 * MainHeader panel.
 * On left part title is shown. On right - navigation buttons.
 *
 * @param {object} props Props.
 * @returns {React.Component} Component.
 */
const MainHeader = props => {
  const {
    hasAccess,
    userRole,
    onCCVisitsReport,
    onOffersAndBetterClick,
    onLeadsAndBetterClick,
    onOutreachReport,
    onCeoOutreachReport,
    onRetainerReportClick,
    title,
    onLogOff,
    onActivityReportClick,
    onConflictReportClick,
  } = props;
  let statusButton = null;
  let addUserButton = null;
  let nextActionButton = null;
  let leadsReportButton = null;
  let backlogButton = null;
  let windwardButton = null;
  let tagButton = null;

  if (hasAccess) {
    tagButton = (
      <LinkButton icon="tag" target="_blank" to="/tag">
        Tag
      </LinkButton>
    );
    nextActionButton = (
      <LinkButton icon="flag" target="_blank" to="/next-actions">
        Next Actions
      </LinkButton>
    );
    backlogButton = (
      <LinkButton icon="clock-o" target="_blank" to="/backlog">
        Backlog
      </LinkButton>
    );
    windwardButton = (
      <LinkButton icon="file-word-o" target="_blank" to="/windward">
        Windward
      </LinkButton>
    );
  }

  const role = getUserRole(userRole);

  if ([config.FINANCE_ADMIN, config.ADMINISTRATOR].includes(role)) {
    statusButton = (
      <div className="btn-group">
        <DropDown
          btnContent={
            <span>
              <i className="fa fa-cog" />
              Stats
            </span>
          }
          className="btn-default btn-xs"
          id="main-stats-dropdown"
        >
          <DropDownElement onClick={onCCVisitsReport} type="link">
            Download CC/Visits Report
          </DropDownElement>
          <DropDownElement onClick={onOffersAndBetterClick} type="link">
            Download Offers &amp; Better Report
          </DropDownElement>
          <DropDownElement onClick={onLeadsAndBetterClick} type="link">
            Download Leads &amp; Better Report
          </DropDownElement>
          <DropDownElement onClick={onOutreachReport} type="link">
            Download Outreach Report
          </DropDownElement>
          <DropDownElement onClick={onCeoOutreachReport} type="link">
            Download CEO Outreach Report
          </DropDownElement>
          <DropDownElement onClick={onActivityReportClick} type="link">
            Download Activity Report
          </DropDownElement>
          <DropDownElement onClick={onConflictReportClick} type="link">
            Download Conflict Report
          </DropDownElement>
          <DropDownElement onClick={onRetainerReportClick} type="link">
            Download Retainer Report
          </DropDownElement>
          <DropDownElement noreferrer="true" target="_blank" to="/dashboard" type="link">
            View Harvey &amp; Co. Dashboard
          </DropDownElement>
        </DropDown>
      </div>
    );
  }

  if ([config.DIRECTOR].includes(role)) {
    statusButton = (
      <div className="btn-group">
        <DropDown
          btnContent={
            <span>
              <i className="fa fa-cog" />
              Stats
            </span>
          }
          className="btn-default btn-xs"
          id="main-stats-dropdown"
        >
          <DropDownElement onClick={onCCVisitsReport} type="link">
            Download CC/Visits Report
          </DropDownElement>
          <DropDownElement onClick={onOffersAndBetterClick} type="link">
            Download Offers &amp; Better Report
          </DropDownElement>
          <DropDownElement onClick={onLeadsAndBetterClick} type="link">
            Download Leads &amp; Better Report
          </DropDownElement>
          <DropDownElement onClick={onOutreachReport} type="link">
            Download Outreach Report
          </DropDownElement>
          <DropDownElement onClick={onCeoOutreachReport} type="link">
            Download CEO Outreach Report
          </DropDownElement>
          <DropDownElement onClick={onActivityReportClick} type="link">
            Download Activity Report
          </DropDownElement>
          <DropDownElement onClick={onConflictReportClick} type="link">
            Download Conflict Report
          </DropDownElement>
          <DropDownElement noreferrer="true" target="_blank" to="/dashboard" type="link">
            View Harvey &amp; Co. Dashboard
          </DropDownElement>
        </DropDown>
      </div>
    );
  }

  if ([config.ANALYST_ASSOCIATE].includes(role)) {
    statusButton = (
      <div className="btn-group">
        <DropDown
          btnContent={
            <span>
              <i className="fa fa-cog" />
              Stats
            </span>
          }
          className="btn-default btn-xs"
          id="main-stats-dropdown"
        >
          <DropDownElement onClick={onOutreachReport} type="link">
            Download Outreach Report
          </DropDownElement>
          <DropDownElement onClick={onCeoOutreachReport} type="link">
            Download CEO Outreach Report
          </DropDownElement>
          <DropDownElement onClick={onActivityReportClick} type="link">
            Download Activity Report
          </DropDownElement>
          <DropDownElement noreferrer="true" target="_blank" to="/dashboard" type="link">
            View Harvey &amp; Co. Dashboard
          </DropDownElement>
        </DropDown>
      </div>
    );
  }

  if ([config.ADMINISTRATOR].includes(role)) {
    addUserButton = (
      <LinkButton icon="plus" target="_blank" to="/user">
        Add user
      </LinkButton>
    );
  }

  if ([config.RESEARCHER, config.PROCESS_MANAGER].includes(role) === false) {
    leadsReportButton = (
      <LinkButton icon="filter" target="_blank" to="/leads-report">
        Leads
      </LinkButton>
    );
  }

  return (
    <header className="navbar navbar-inverse navigation navigation--main">
      <div className="flex-grid">
        <div className="flex-grow1">
          <h3 className="navigation__heading">{title}</h3>
        </div>
        <div className="navigation__buttons">
          {statusButton}
          {addUserButton}
          {nextActionButton}
          {leadsReportButton}
          {backlogButton}
          {tagButton}
          {windwardButton}
          <LinkButton className="btn-danger" icon="key" onClick={onLogOff} to="#">
            Log off
          </LinkButton>
        </div>
      </div>
    </header>
  );
};

MainHeader.propTypes = {
  /**
   * On activity report click event handler.
   */
  onActivityReportClick: PropTypes.func.isRequired,
  /**
   * On CC visits report click event handler.
   */
  onCCVisitsReport: PropTypes.func.isRequired,
  /**
   * On CEO outreach report click event handler.
   */
  onCeoOutreachReport: PropTypes.func.isRequired,
  /**
   * On conflict report click event handler.
   */
  onConflictReportClick: PropTypes.func.isRequired,
  /**
   * On leads & better report click event handler.
   */
  onLeadsAndBetterClick: PropTypes.func.isRequired,
  /**
   * On log off click event handler.
   */
  onLogOff: PropTypes.func.isRequired,
  /**
   * On offers & better click event handler.
   */
  onOffersAndBetterClick: PropTypes.func.isRequired,
  /**
   * On outreach report click event handler.
   */
  onOutreachReport: PropTypes.func.isRequired,
  /**
   * Header title.
   */
  title: PropTypes.string,
  /**
   * User role.
   */
  userRole: PropTypes.instanceOf(Map).isRequired,
};

export default MainHeader;
