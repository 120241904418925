import React, { PureComponent } from 'react';

class TextEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      value: this.props.value.value,
    };
  }

  componentDidMount() {
    if (this.editor) {
      this.editor.addEventListener('keydown', this.stopNavigation);
    }
  }

  componentWillUnmount() {
    if (this.editor) {
      this.editor.removeEventListener('keydown', this.stopNavigation);
    }
  }

  stopNavigation(event) {
    if ([32, 37, 38, 39, 40, 36, 35, 9].indexOf(event.keyCode) > -1) {
      event.stopPropagation();
    }
  }

  getValue() {
    return Object.assign({}, this.props.value, { value: this.state.value });
  }

  onChange(event) {
    this.setState({
      value: event.target.value,
    });
  }

  isPopup() {
    return false;
  }

  afterGuiAttached() {
    setTimeout(() => {
      if (this.editor) this.editor.select();
    }, 1);
  }

  render() {
    const { value } = this.state;

    return (
      <span className="control-wrap">
        <input
          ref={input => {
            this.editor = input;
          }}
          className="form-control input-sm"
          name="applistLabel"
          onChange={this.onChange}
          placeholder="Approval List"
          type="text"
          value={value}
        />
      </span>
    );
  }
}

export default TextEditor;
