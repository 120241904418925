import PropTypes from 'prop-types';

import React from 'react';
import Immutable from 'immutable';

import moment from 'moment';
import UserInput from './UserInput';
import UserRoleList from './UserRoleList';
import UserAutocomplete from './UserAutocomplete';
import UserInactiveCheckbox from './UserInactiveCheckbox';
import UserDateInput from './UserDateInput';
import config from '../../config';

/**
 * User's form component.
 *
 * @param props {Object}.
 * @param props.roles {Immutable.List} List of roles.
 * @param props.formMode {string} Form mode (view|edit).
 * @param props.formError {string} Error on form.
 * @param props.selectedUser {string} Selected user.
 * @param props.onFormChange {function} Handle form changing.
 * @param props.onUserSuggestionSelected {function} Handle user suggestion.
 * @returns {React.Component}
 */
const UserInfo = props => {
  const {
    suggestions,
    roles,
    formMode,
    formError,
    selectedUser,
    supervisorSuggestionMode,
    moduleSuggestionMode,
    onFormChange,
    onUserSuggestionSelected,
    onUserSuggestionClose,
    reports,
    onHireEndDateChange,
  } = props;
  const inputProps = { selectedUser, formMode, formError };
  const userReport = selectedUser.get('reportIds', Immutable.List()).toJS();
  const userBrowseAccesses = selectedUser.get('browseAccesses', Immutable.List()).toJS();
  const isMailingBacklog = selectedUser.size ? selectedUser.get('isMailingBacklog') : false;
  const autoCompleteFuncs = {
    suggestions,
    onSuggestionSelect: onUserSuggestionSelected,
    onSuggestionClose: onUserSuggestionClose,
  };
  const disabled = formMode === 'view';
  const reportElements = reports.map(r => (
    <div key={r.id} className="form-inline-block mr20">
      <label htmlFor={`report${r.id}`}>{r.name}</label>
      <br />
      <input
        checked={userReport.indexOf(r.id) > -1}
        disabled={disabled}
        id={`report${r.id}`}
        name="reports"
        type="checkbox"
        value={r.id}
      />
    </div>
  ));

  const browseAccessElements = config.browse.get('accesses').map(item => {
    const access = item.toJS();

    return (
      <div key={access.key} className="form-inline-block mr20">
        <label htmlFor={`access-${access.name}`}>{access.title}</label>
        <br />
        <input
          checked={userBrowseAccesses.indexOf(access.key) > -1}
          disabled={disabled}
          id={`access-${access.name}`}
          name="accesses"
          type="checkbox"
          value={access.key}
        />
      </div>
    );
  });

  const inputDateFormat = 'MM/DD/YYYY';
  const isNewUser = selectedUser.get('id', -1) === -1;

  const hireDateInputValue = isNewUser ? moment(new Date()) : selectedUser.get('hireDate', '') || '';
  const endDateInputValue = selectedUser.get('endDate', '');

  return (
    <form onChange={onFormChange}>
      <div className="col-xs-2">
        <UserInput {...inputProps} className="bg-warning" label="User Name" name="userName" />
        <UserInput {...inputProps} label="First Name" name="firstName" />
        <UserInput {...inputProps} label="Last Name" name="lastName" />
        <UserInput {...inputProps} label="Title" name="title" />
        <UserInput {...inputProps} label="Office Phone" name="officePhone" />
        <UserInput {...inputProps} label="Office Phone Ext" name="officePhoneExt" />
        <UserInput {...inputProps} label="Cell Phone" name="cellPhone" />
        <UserInactiveCheckbox {...inputProps} />
      </div>

      <div className="col-xs-2">
        <div className="mb2">
          <UserAutocomplete
            {...inputProps}
            className="form-group"
            {...autoCompleteFuncs}
            id="moduleSuggestion"
            label="Module"
            mode={moduleSuggestionMode}
            name="module"
          />
        </div>

        <div className="mb2">
          <UserAutocomplete
            {...inputProps}
            className="form-group"
            {...autoCompleteFuncs}
            id="supervisorSuggestion"
            label="Supervisor"
            mode={supervisorSuggestionMode}
            name="supervisor"
          />
        </div>

        <UserInput {...inputProps} label="Email" name="email" />
        <UserRoleList {...inputProps} roles={roles} />
        <UserInput {...inputProps} label="Password" name="password" type="password" />
        <UserInput {...inputProps} label="Re-Enter Password" name="rePassword" type="password" />
      </div>
      <div className="col-xs-8">
        {reportElements}
        <label>
          <div>Mailing Backlog</div>
          <input checked={isMailingBacklog} disabled={disabled} name="isMailingBacklog" type="checkbox" />
        </label>
      </div>
      <div className="col-xs-8">{browseAccessElements}</div>
      <div className="col-xs-2 mt8">
        <UserDateInput
          {...inputProps}
          format={inputDateFormat}
          label="Hire Date"
          name="hireDate"
          onChange={date => onHireEndDateChange(date, 'hireDate')}
          value={hireDateInputValue}
          disabled
        />
        <UserDateInput
          {...inputProps}
          disabled={formMode === 'view'}
          format={inputDateFormat}
          label="End Date"
          name="endDate"
          onChange={date => onHireEndDateChange(date, 'endDate')}
          value={endDateInputValue}
        />
      </div>
    </form>
  );
};

UserInfo.propTypes = {
  formError: PropTypes.instanceOf(Immutable.Map).isRequired,
  formMode: PropTypes.string.isRequired,
  moduleSuggestionMode: PropTypes.string.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onUserSuggestionClose: PropTypes.func.isRequired,
  onUserSuggestionSelected: PropTypes.func.isRequired,
  roles: PropTypes.instanceOf(Immutable.List).isRequired,
  selectedUser: PropTypes.instanceOf(Immutable.Map).isRequired,
  suggestions: PropTypes.instanceOf(Immutable.List).isRequired,
  supervisorSuggestionMode: PropTypes.string.isRequired,
};

export default UserInfo;
