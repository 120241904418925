import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Map, List } from 'immutable';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import { replace } from 'connected-react-router';

import connectOptions, { mergeProps } from '../utils/connectOptions';

import config from '../config';
import { isDeepChanged, getChanged, getIn } from '../utils/ChangeSpy';

import deletionCheck from '../utils/deletingPermission';
import { isResearcher } from '../utils/checkPermissions';
import stopProp from '../decorators/stopPropagation';
import Contact from '../components/Contact';
import { publish } from '../decorators/tabChange';
import {
  loadContactDetail,
  saveContactDetail,
  saveContactPhone,
  changeContactInfoField,
  addContactField,
  delContactField,
  editContactField,
  changeContactField,
  saveContactField,
  revertContactField,
  stopEditContactField,
  saveContactEmail,
  saveContactAddress,
  delContact,
  closeValidationError,
} from '../actions/contactDetail';

import { enterContactEvent, deleteContactEvent, leaveContactEvent } from '../actions/contact/contactEvents';
import { saveContactCompany } from '../actions/contact/contactExecutive';
import { showError } from '../utils/MessagePopup';
import naDeletionCheck from './services/deletionCheck';
import AutoComplete from './AutoComplete';
import confirm from './decorators/confirm';
import ContactExecutiveCreate from './Contact/ContactExecutiveCreate';
import ContactLpCreate from './Contact/ContactLpCreate';

const tabs = config.tabs.get('contact');
const values = config.values.get('contact');

/**
 *  Contact page container.
 *  Change title to `"Contact Page"`.
 *  Note: Tabs are subroutes.
 */
class ContactContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onAddAddress = this.onAddAddress.bind(this);
    this.onAddEmail = this.onAddEmail.bind(this);
    this.onEditEmail = this.onEditEmail.bind(this);
    this.onEditAddress = this.onEditAddress.bind(this);
    this.onAddPhone = this.onAddPhone.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onEditPhone = this.onEditPhone.bind(this);
    this.onAddEvent = this.onAddEvent.bind(this);
    this.onEditEvent = this.onEditEvent.bind(this);
    this.onDeleteEvent = stopProp(
      naDeletionCheck(
        this.props.loggedUser,
        confirm('Delete this event?', this.onDeleteEvent.bind(this), context),
        context,
      ),
    );
    this.onDelAddress = stopProp(
      deletionCheck(
        this.props.loggedUser,
        confirm('Delete this address?', this.onDelAddress.bind(this), context),
        context,
      ),
    );
    this.onDelEmail = stopProp(
      deletionCheck(this.props.loggedUser, confirm('Delete this email?', this.onDelEmail.bind(this), context), context),
    );
    this.onDelPhone = stopProp(
      deletionCheck(this.props.loggedUser, confirm('Delete this phone?', this.onDelPhone.bind(this), context), context),
    );
    this.onDelContact = stopProp(
      deletionCheck(
        this.props.loggedUser,
        confirm('Delete this contact?', this.onDelContact.bind(this), context),
        context,
      ),
    );
    this.onEventMouseEnter = this.onEventMouseEnter.bind(this);
    this.onEventMouseLeave = this.onEventMouseLeave.bind(this);
    this.onBlurInfo = this.onBlurInfo.bind(this);
    this.onErrorClose = this.onErrorClose.bind(this);
    this.onChangeSuggestion = this.onChangeSuggestion.bind(this);
    this.addOnSaveCallback = cb => this._onSaveCallbacks.add(cb);
    this.removeOnSaveCallback = cb => this._onSaveCallbacks.delete(cb);

    this.eventPropSelector = createSelector(
      props => props.contactEvents,
      events => ({
        events,
        onMouseEnter: this.onEventMouseEnter,
        onMouseLeave: this.onEventMouseLeave,
      }),
    );
  }

  UNSAFE_componentWillMount() {
    const { contactId } = this.props.match.params;

    this._onSaveCallbacks = new Set();
    this.props.loadContactDetail({ id: contactId });
  }

  componentDidMount() {
    if (!this.hasAccess()) {
      showError(this.context.openPopup, config.permisionError, () => {
        this.props.history.push('/');
        this.context.closePopup();
      });
    }
  }

  componentDidUpdate(oldProps) {
    if (this.props.contactInfo.get('loading') === true) return;
    if (oldProps.contactInfo.get('loading') === false) return;

    const { contactId } = this.props.match.params;

    if (this.props.location.pathname === `/contact/${contactId}`) {
      this.tryRedirect(contactId);
    } else if (this.props.location.pathname === `/contact/${contactId}/`) {
      this.tryRedirect(contactId);
    }
  }

  hasAccess() {
    if (!this.context.currentUser) return false;

    return !isResearcher(this.context.currentUser.getIn(['roles', 0, 'slug'], null));
  }

  getChildContext() {
    return {
      onAddAddress: this.onAddAddress,
      onAddEmail: this.onAddEmail,
      onAddPhone: this.onAddPhone,
      onEditEmail: this.onEditEmail,
      onEditPhone: this.onEditPhone,
      onEditAddress: this.onEditAddress,
      onChangeEmail: this.onChangeEmail,
      onChangePhone: this.onChangePhone,
      onAddEvent: this.onAddEvent,
      onChangeAddress: this.onChangeAddress,
      onEditEvent: this.onEditEvent,
      onDeleteEvent: this.onDeleteEvent,
      onDelAddress: this.onDelAddress,
      onDelEmail: this.onDelEmail,
      onDelPhone: this.onDelPhone,
      addOnSaveCallback: this.addOnSaveCallback,
      removeOnSaveCallback: this.removeOnSaveCallback,
      onErrorClose: this.onErrorClose,
      inputErrors: this.props.inputErrors,
    };
  }

  tryRedirect(contactId) {
    const { replace } = this.props;

    if (this.props.contactInfo.get('isExecutive'))
      return setTimeout(() => replace(`/contact/${contactId}/executive`), 0);
    if (this.props.contactInfo.get('hasTargets')) return setTimeout(() => replace(`/contact/${contactId}/target`), 0);
    if (this.props.contactInfo.get('hasBuyers')) return setTimeout(() => replace(`/contact/${contactId}/buyer`), 0);
    if (this.props.contactInfo.get('isLP')) return setTimeout(() => replace(`/contact/${contactId}/lp`), 0);
  }

  onErrorClose(event, field) {
    this.props.closeValidationError({ field });
  }

  onDelContact() {
    const { contactId } = this.props.match.params;
    const {
      context: { closePopup },
    } = this;

    this.props.delContact({
      contactId,
      afterSuccess: closePopup,
    });
  }

  onEventMouseEnter(event, eventId) {
    const { target, clientX: left } = event;
    const rect = target.getBoundingClientRect();
    const { bottom: top } = rect;

    this.props.enterContactEvent({ eventId, left, top });
  }

  onEventMouseLeave(event, eventId) {
    this.props.leaveContactEvent({ eventId });
  }

  onChange(event) {
    const { name } = event.target;

    switch (event.target.type) {
      case 'checkbox':
        return this.props.changeContactInfoField({
          name,
          value: event.target.checked,
        });

      default:
        return this.props.changeContactInfoField({
          name,
          value: event.target.value,
        });
    }
  }

  onAddAddress() {
    const field = 'addresses';

    this._onAddField(field);
    this.onEditAddress(null, this.props.contactInfo.get(field).size);
  }

  onAddEmail() {
    const field = 'emails';

    this._onAddField(field);
  }

  onAddPhone() {
    const field = 'phones';

    this._onAddField(field);
  }

  onDelAddress(event, index) {
    const field = 'addresses';

    this._onDelField({ field, index });
  }

  onDelEmail(event, index) {
    const field = 'emails';

    this._onDelField({ field, index });
  }

  onDelPhone(event, index) {
    const field = 'phones';

    this._onDelField({ field, index });
  }

  _onAddField(field) {
    this.props.addContactField({ field });
    this._onEditField(field, this.props.contactInfo.get(field).size);
  }

  _onDelField({ field, index }) {
    const id = this.props.match.params.contactId;
    const fieldId = this.props.contactInfo.getIn([field, index, 'id'], 'new');
    const {
      context: { closePopup },
    } = this;

    this.props.delContactField({
      id,
      field,
      fieldId,
      index,
      afterSuccess: closePopup,
    });
  }

  _onEditField(field, index) {
    if (this.props.contactInfo.getIn([field, index, 'isInherited'])) return;
    this.props.editContactField({ field, index });
  }

  onEditEmail(_, index) {
    const field = 'emails';

    this._onEditField(field, index);
  }

  onEditPhone(_, index) {
    const field = 'phones';

    this._onEditField(field, index);
  }

  onEditAddress(_, index) {
    const field = 'addresses';

    if (this.props.contactInfo.getIn([field, index, 'isInherited'])) return;
    this.props.editContactField({ field, index });

    const name = 'EditAddressPopup';
    const { contactId } = this.props.match.params;
    const props = {
      contactId,
      onChange: this.onChangeAddress,
      index,
    };

    this.context.openPopup(name, props);
    this.context.onClosePopup(this.props.revertContactField);
    this.context.onClosePopup(this.props.stopEditContactField);
  }

  onChangeField({ field, name, value, index }) {
    this.props.changeContactField({ field, name, value, index });
  }

  onChangeEmail(event, index) {
    const {
      target: { name: _name, value },
    } = event;
    const name = _name.split('.').pop();
    const field = 'emails';

    this.onChangeField({ field, name, value, index });
  }

  onChangePhone(event, index) {
    const {
      target: { name: _name, value },
    } = event;
    const name = _name.split('.').pop();
    const field = 'phones';

    this.onChangeField({ field, name, value, index });
  }

  onChangeAddress(event, index) {
    const {
      target: { name: _name, value },
    } = event;
    const name = _name.split('.').pop();
    const field = 'addresses';

    this.onChangeField({ field, name, value, index });
  }

  onAddEvent() {
    const name = 'EventPopup';
    const { contactId } = this.props.match.params;
    const isExecutiveRoute = window.location.pathname.includes('/executive');

    if (!isExecutiveRoute) return;
    if (isExecutiveRoute) {
      this.context.openPopup(name, {
        contactId,
        event: null,
        approach: 'executive',
        noteText: 'Notes',
        onReopen: this.onAddEvent,
      });
    }
  }

  onEditEvent(e, event) {
    const name = 'EventPopup';
    const { contactId } = this.props.match.params;

    if (event.get('id') && !event.get('meta')) {
      const isExecutiveRoute = window.location.pathname.includes('/executive');

      this.context.openPopup(name, {
        contactId,
        event,
        noteText: isExecutiveRoute ? 'Notes' : undefined,
        onReopen: this.onAddEvent,
      });
    }
  }

  onDeleteEvent(e, event) {
    const { id } = event;
    const {
      props: { contactId },
      context: { closePopup },
    } = this;
    const { notifyTab } = this.props;

    this.props.deleteContactEvent({
      id,
      contactId,
      afterSuccess: () => {
        notifyTab({ id: event.id }, 'onEventChange');
        closePopup();
      },
    });
  }

  _filterUnchanged(value) {
    if (Map.isMap(value)) {
      const val = value.get('value');

      if (Map.isMap(val)) {
        return val.get('value') !== val.get('originalValue');
      }

      return value.get('value') !== value.get('originalValue');
    }

    return false;
  }

  _getFieldData(value) {
    if (Map.isMap(value)) {
      const val = value.get('value');

      if (Map.isMap(val)) {
        return val.get('value');
      }

      return value.get('value');
    }

    return value;
  }

  _wasChanged(value) {
    if (!Map.isMap(value)) return false;

    return value.get('isChanged') || value.get('value') !== value.get('originalValue');
  }

  onSubmitInfo(event) {
    event.preventDefault();
  }

  onBlurInfo() {
    if (this.props.contactInfo.get('isValid')) {
      const contact = getChanged(this.props.contactInfo);
      const { contactId: id } = this.props.match.params;

      if (Object.keys(contact).length) {
        if (contact.prefix) contact.prefix = contact.prefix.get('value');
        if (contact.suffix) contact.suffix = contact.suffix.get('value');
        this.props.saveContactDetail({ id, contact });
      }
    }
    this.submitPhones();
    this.submitEmails();
    this.submitAddresses();
  }

  submitPhones() {
    const phonesToSend = this.props.contactInfo.get('phones').filter(phone => isDeepChanged(phone));
    const { contactId } = this.props.match.params;

    phonesToSend.map(phone => {
      const body = getChanged(phone);
      const id = phone.get('id');

      if ('value' in body) body.phone = body.value;
      this.props.saveContactPhone({ contactId, id, body });
    });
  }

  submitEmails() {
    const emailsToSend = this.props.contactInfo.get('emails').filter(email => isDeepChanged(email));
    const { contactId } = this.props.match.params;

    emailsToSend.map(email => {
      const body = getChanged(email);
      const id = email.get('id');

      if ('value' in body) body.email = body.value;
      this.props.saveContactEmail({ contactId, id, body });
    });
  }

  submitAddresses() {
    if (this.props.contactInfo.getIn(['editing', 0]) === 'addresses') return;

    const addressesToSend = this.props.contactInfo.get('addresses').filter(address => isDeepChanged(address));
    const { contactId } = this.props.match.params;

    addressesToSend.map(address => {
      const body = getChanged(address);
      const id = address.get('id');

      this.props.saveContactAddress({ contactId, id, body });
    });
  }

  onSave(event) {
    this.onBlurInfo(event);
    this._onSaveCallbacks.forEach(cb => cb(event));

    const { formError } = this.props;

    if (formError.size) {
      this.context.openPopup('ErrorPopup', { formError });
    }
  }

  static onAddTarget() {
    window.open('/company/new/target');
  }

  static onAddBuyer() {
    window.open('/company/new/buyer');
  }

  static onAddExecutive() {
    window.open('/contact/new/executive');
  }

  static onAddLp() {
    window.open('/contact/new/lp');
  }

  onChangeSuggestion(obj) {
    if (typeof obj.value === 'string') {
      const { value } = obj;
      const nextValue = this.props.contactInfo.get('suffixes').find(
        suffix => suffix.get('name') === value,
        null,
        Map({
          name: value || '',
          value: value || '',
        }),
      );

      this.props.changeContactInfoField({ name: 'suffix', value: nextValue });
    }
  }

  getSuffixesSuggest() {
    const value = getIn(this.props.contactInfo, 'suffix').get('name');
    const idValue = value;
    const regExp = new RegExp(String(value).replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1'), 'i');
    const className = classNames('form-control input-sm', {
      changed: isDeepChanged(this.props.contactInfo.get('suffix')),
    });
    const suggestions = getIn(this.props.contactInfo, 'suffixes')
      .filter(suffix => regExp.test(suffix.get('name')))
      .map(suffix => ({
        id: suffix.get('value'),
        text: suffix.get('name'),
      }));

    return (
      <AutoComplete
        change={this.onChangeSuggestion}
        find={args => {
          setTimeout(
            () =>
              args.afterSuccess({
                response: {
                  data: getIn(this.props.contactInfo, 'suffixes')
                    .filter(suffix => regExp.test(suffix.get('name')))
                    .map(suffix => ({
                      id: suffix.get('value'),
                      text: suffix.get('name'),
                    })),
                },
              }),
            0,
          );
        }}
        idValue={idValue}
        inputProps={{ className, name: 'suffix' }}
        loading={false}
        name="suffix"
        suggestions={suggestions}
        suggestsName="suffixes"
        value={value}
        suggestIfEmpty
      />
    );
  }

  renderContactContent() {
    const { children, location } = this.props;

    if (children) return children;
    if (location.pathname === '/contact/new/executive') return <ContactExecutiveCreate />;
    if (location.pathname === '/contact/new/lp') return <ContactLpCreate />;

    return null;
  }

  render() {
    if (!this.hasAccess()) return null;

    const { props } = this;
    const { contactInfo, showSave } = props;
    const loading = contactInfo.get('loading');
    const contactEventProps = this.eventPropSelector(props);

    return (
      <div className="full-height">
        <Helmet title={`${getIn(contactInfo, 'fullName', '')} [Contact Page]`.trim()} />
        <Contact
          {...props}
          contactEventProps={contactEventProps}
          contactInfo={contactInfo}
          loading={loading}
          onBlurInfo={this.onBlurInfo}
          onChange={this.onChange}
          onDel={this.onDelContact}
          onNewBuyer={ContactContainer.onAddBuyer}
          onNewExecutive={ContactContainer.onAddExecutive}
          onNewLp={ContactContainer.onAddLp}
          onNewTarget={ContactContainer.onAddTarget}
          onSave={this.onSave}
          onSubmitInfo={this.onSubmitInfo}
          params={props.match.params}
          showSave={showSave}
          suffixSuggest={this.getSuffixesSuggest()}
        >
          {!loading ? this.renderContactContent() : null}
        </Contact>
      </div>
    );
  }
}

const changedInfoSelector = createSelector(
  state => state.contact.info,
  info => isDeepChanged(info),
);

const changedLpSelector = createSelector(
  state => state.contact.lp,
  lp => isDeepChanged(lp),
);

const changedExecSelector = createSelector(
  state => state.contact.executive,
  executive => isDeepChanged(executive),
);

function mapStateToProps(state) {
  const formError = (state.contact.info.get('inputErrors') || List()).concat(
    state.contact.executive.get('inputErrors') || List(),
  );

  return {
    formError,
    inputErrors: state.contact.info.get('inputErrors'),
    contactInfo: state.contact.info,
    contactEvents: state.contact.events,
    showSave: changedInfoSelector(state) || changedLpSelector(state) || changedExecSelector(state),
    tabs,
    values,
    loggedUser: state.auth.get('user'),
  };
}

ContactContainer.propTypes = {
  changeContactInfoField: PropTypes.func.isRequired,
  loadContactDetail: PropTypes.func.isRequired,
};

ContactContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
};

ContactContainer.childContextTypes = {
  onAddAddress: PropTypes.func.isRequired,
  onAddEmail: PropTypes.func.isRequired,
  onAddPhone: PropTypes.func.isRequired,
  onEditEmail: PropTypes.func.isRequired,
  onEditAddress: PropTypes.func.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onChangeAddress: PropTypes.func.isRequired,
  onChangePhone: PropTypes.func.isRequired,
  onEditPhone: PropTypes.func.isRequired,
  onAddEvent: PropTypes.func.isRequired,
  onEditEvent: PropTypes.func.isRequired,
  onDeleteEvent: PropTypes.func.isRequired,
  onDelAddress: PropTypes.func.isRequired,
  onDelPhone: PropTypes.func.isRequired,
  onDelEmail: PropTypes.func.isRequired,
  addOnSaveCallback: PropTypes.func.isRequired,
  removeOnSaveCallback: PropTypes.func.isRequired,
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};

export { ContactContainer };
export default connect(
  mapStateToProps,
  {
    loadContactDetail,
    changeContactInfoField,
    saveContactDetail,
    addContactField,
    editContactField,
    changeContactField,
    saveContactField,
    revertContactField,
    stopEditContactField,
    enterContactEvent,
    leaveContactEvent,
    deleteContactEvent,
    saveContactCompany,
    delContactField,
    saveContactPhone,
    saveContactEmail,
    saveContactAddress,
    delContact,
    closeValidationError,
    replace,
  },
  mergeProps,
  connectOptions,
)(publish(ContactContainer));
