/**
 * Remove all unwanted characters.
 *
 * @param {*} inputVal Json object/ string.
 * @param {*} field Field that is needed to be strip off.
 * @param {*} regex Regular expression pattern.
 */
export default function stripOffChars(inputVal, field, regex) {
  if (typeof inputVal === 'object') {
    if (!(field in inputVal)) return inputVal;

    return {
      ...inputVal,
      [field]: (inputVal[field] || '').replace(regex, ''),
    };
  }

  if (typeof inputVal === 'string') {
    return inputVal.replace(regex, '');
  }

  return inputVal;
}
