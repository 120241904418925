import * as React from 'react';
import SelectRange from '../helpers/SelectRange';
import BrowseCheckbox from './BrowseCheckbox';

class BrowseStatusesFilter extends React.PureComponent {
  render() {
    const { filter, info, statuses } = this.props;

    let statusesFilter = <p>There is no statuses, please contact the system's administrator</p>;

    if (statuses.length > 0) {
      statusesFilter = (
        <SelectRange
          className="BrowseControl-content BrowseControl-content--slider"
          filter={filter}
          info={info}
          onChange={this.props.onChange}
          rangeData={statuses}
        />
      );
    }

    return <BrowseCheckbox {...this.props}>{statusesFilter}</BrowseCheckbox>;
  }
}

export default BrowseStatusesFilter;
