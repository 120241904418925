import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isResearcher } from 'src/utils/checkPermissions';
import { getCompanyInfo } from '../../../../actions/companyDetail';
import { changeBuyerType } from '../../../../actions/company/buyer/info';
import { PureInlineInput as Input } from '../../../../components/helpers/Input';
import Select from '../../../../components/helpers/Select';
import { getIn, unwrap } from '../../../../utils/ChangeSpy';
import uniqueId from '../../../../utils/uniqueId';
import UrlMaker from '../../../../routing/UrlMaker';
import confirm from '../../../../containers/decorators/confirm';
import CompanyProfile from './CompanyProfile';
import config from '../../../../config';
import { TYPE_STRATEGIC, TYPE_FINANCIAL } from '../../../../config/constants/common';

const curId = uniqueId();
const highId = uniqueId();
const priId = uniqueId();
const contId = uniqueId();

class CompanyBuyerStatus extends PureComponent {
  componentDidMount() {
    this.checkIsEditableBuyerType();

    const { companyInfo } = this.props;

    const recordOwnerId = unwrap(companyInfo.get('recordOwnerId'));
    let confirmMessage = 'Are you sure you wish to proceed?';

    if (recordOwnerId) {
      confirmMessage = `Caution: This Buyer is also a Target Company. By changing the Buyer Type to "Financial", you will disable the Target Company record (Financial Buyers cannot be Targets).
      Please make sure you have saved all relevant data under the Buyer Record before proceeding. \n\n${confirmMessage}`;
    }

    this.handleConfirmChangeBuyerType = confirm(
      confirmMessage,
      this.handleConfirmChangeBuyerType.bind(this),
      this.context,
    );
  }

  getNextBuyerType() {
    return this.props.type === TYPE_FINANCIAL ? TYPE_STRATEGIC : TYPE_FINANCIAL;
  }

  checkIsEditableBuyerType() {
    const { userRole } = this.props;

    return userRole === config.DIRECTOR;
  }

  handleConfirmChangeBuyerType() {
    const { companyId, history, changeBuyerType, getCompanyInfo } = this.props;
    const buyerType = this.getNextBuyerType();

    const afterSuccess = () => {
      history.replace(`/company/${companyId}/buyer`);
      getCompanyInfo({
        companyId,
        afterSuccess: () => {
          this.context.closePopup();
        },
        afterError: this.context.closePopup,
      });
    };

    changeBuyerType({ companyId, buyerType, afterSuccess, afterError: this.context.closePopup });
  }

  handleHighStatusUpdate = event => {
    event.preventDefault();

    const { userRole, onHighStatusUpdate } = this.props;

    if (!isResearcher(userRole)) {
      onHighStatusUpdate();
    }
  };

  render() {
    const { onChange, harvcoSuggest, type, buyer, statuses, isDuplicateCompany } = this.props;
    const highStatus = getIn(buyer, 'buyerHighStatus');
    const title = statuses.size
      ? statuses.find(status => getIn(status, ['value']) === highStatus, null, statuses.get(0)).get('name')
      : '';
    const finCls = classNames('form-group form-inline-block', { hidden: type !== TYPE_FINANCIAL });
    const parentLink = UrlMaker.create('/company/:companyId/buyer').mapParams({
      companyId: buyer.get('parentId'),
    });
    let parentBlock = (
      <div className="form-group form-inline-block">
        <label>
          <a href={parentLink} rel="noopener noreferrer" target="_blank">
            Parent: {buyer.getIn(['parent', 'buyerCurrentStatus'], '')}/{buyer.getIn(['parent', 'buyerHighStatus'], '')}
          </a>
        </label>
      </div>
    );

    if (type !== TYPE_STRATEGIC || !buyer.get('parentId')) {
      parentBlock = null;
    }

    const buyerTypeButton = this.checkIsEditableBuyerType() ? (
      <button className="btn btn-default btn-xs" onClick={this.handleConfirmChangeBuyerType} type="button">
        Change to {this.getNextBuyerType()}
      </button>
    ) : null;

    const { userRole } = this.props;
    const isDisabled = isResearcher(userRole) || isDuplicateCompany;

    return (
      <form onChange={onChange}>
        <div className="col-xs-3 form-inline buyer-top-status-form">
          <div className="form-group select-w120 form-inline-block">
            <label>
              Buyer Type: {type} {buyerTypeButton}
            </label>
          </div>
        </div>
        <div className="col-xs-6 form-inline buyer-top-status-form">
          <div className="form-group select-w120 form-inline-block">
            <label htmlFor={curId}>Status</label>
            <Select
              disabled={isDisabled}
              emptyVal=""
              id={curId}
              label="Status"
              name="buyerCurrentStatus"
              onChange={onChange}
              options={statuses}
              root={buyer}
              selectClassName="input-sm"
              wrapperClassName="control-wrap"
              deep
              showValue
            />
          </div>

          <div className="form-group input-w120 form-inline-block">
            <label htmlFor={highId}>High</label>
            <Input
              className="buyer-high-status input-sm bold-control-md"
              id={highId}
              label="High"
              name="buyerHighStatus"
              onClick={this.handleHighStatusUpdate}
              placeholder="High"
              root={buyer}
              title={title}
              deep
              readOnly
            />
          </div>

          <div className={finCls}>
            <label>
              CEO:
              <strong>
                {buyer.get('ceoAbc', '')}/{buyer.get('ceo123', '')}
              </strong>
            </label>
          </div>

          <CompanyProfile
            className={finCls}
            disabled={isDuplicateCompany}
            id={curId}
            statuses={statuses}
            subsidiaries={buyer.get('subsidiaries')}
          />

          {parentBlock}

          <div className="form-group input-w120 form-inline-block">
            <label htmlFor={priId}>Priority</label>
            <Input
              className="input-sm bold-control-md"
              disabled={isDisabled}
              id={priId}
              label="Priority"
              name="buyerPriority"
              onChange={onChange}
              placeholder="Priority"
              root={buyer}
              deep
            />
          </div>
        </div>
        <div className="col-xs-3 text-right form-inline buyer-top-status-form">
          <div className="form-group form-inline-block">
            <label htmlFor={contId}>HarvCo Contact</label>
          </div>
          <div className="form-group form-inline-block input-w120">{harvcoSuggest}</div>
        </div>
      </form>
    );
  }
}

CompanyBuyerStatus.propTypes = {
  isDuplicateCompany: PropTypes.bool.isRequired,
};

CompanyBuyerStatus.contextTypes = {
  openPopup: PropTypes.func,
  closePopup: PropTypes.func,
};

const mapStateToProps = state => ({
  userRole: state.auth.getIn(['user', 'roles', 0, 'slug']),
  companyInfo: state.targetCompany.info.get('info'),
});

export { CompanyBuyerStatus };
export default connect(mapStateToProps, {
  changeBuyerType,
  getCompanyInfo,
})(withRouter(CompanyBuyerStatus));
