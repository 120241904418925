import { fromJS, List } from 'immutable';
import * as ActionType from '../actions/mailingInfo';
import { convertDateString } from '../utils/dateFormat';

const defaultState = fromJS({
  isFetching: false,
  isUnableToCreateMailing: true,
  header: {},
  queryResults: [],
  page: 0,
  totalPages: 0,
  sortBy: {
    sortModel: [],
  },
  isCreatingMailing: false,
  createMailingError: null,
  updateCellError: null,
  errors: [],
  setNextAction: false,
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case ActionType.FETCHING_MAILING_HEADER:
      return state.merge({
        isFetching: true,
      });

    case ActionType.FETCHING_MAILING_TARGETS:
      return state.merge({
        isFetching: true,
        page: action.query.page,
      });

    case ActionType.FETCHING_MAILING_HEADER_SUCCESS:
      return state.mergeDeep({
        header: mapMailingHeaderResponse(action.response.data[0]),
      });

    case ActionType.FETCHING_MAILING_TARGETS_FAILURE:
      return state.merge({
        isFetching: false,
      });

    case ActionType.FETCHING_MAILING_TARGETS_SUCCESS:
      if (action.response.meta.pagination.currentPage === 1) {
        state = state.set('queryResults', new List());
      }

      return state.merge({
        queryResults: state
          .get('queryResults')
          .concat(getMailing(action.response.data, state.get('queryResults').size)),
        isFetching: false,
        setNextAction: action.response.meta.setNextAction,
        totalPages: action.response.meta.pagination.totalPages,
      });

    case ActionType.UPDATE_MAILING_INFO:
      return state.mergeDeep({
        [action.filterName]: action.filterData,
      });

    case ActionType.CLEAR_UPDATE_CELL_ERROR:
      return state.merge({
        updateCellError: null,
      });

    case ActionType.UPDATE_CELL_ERROR:
      state = state.set('errors', new List());

      return state.merge({
        updateCellError: true,
        errors: mapUpdateCellErrorResponse(action.response),
      });

    default:
      return state;
  }
}

function mapMailingHeaderResponse(data) {
  data.date = convertDateString(data.date);

  return data;
}

function mapUpdateCellErrorResponse(data) {
  const errors = Object.keys(data.errors).reduce((acc, i) => {
    acc.push(data.errors[i][0]);

    return acc;
  }, []);

  return errors;
}

function getMailing(list, startAt) {
  return list.map((d, i) => ({
    ...d,
    index: i + startAt,
  }));
}
