import React, { PureComponent } from 'react';

import uniqueId from '../../../../utils/uniqueId';
import Input from '../../../helpers/Input';

class CriteriaRange extends PureComponent {
  constructor(...rest) {
    super(...rest);

    this.id = uniqueId();
  }

  render() {
    const { id, props } = this;
    const { buyer: root, label, name, onChange, disabled } = props;

    const [highName, lowName] = ['High', 'Low'].map(post => `${name}${post}`);

    return (
      <div className="form-group">
        <label htmlFor={id}>{label}</label>
        <div className="row">
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div className="i-dash">
              <Input
                className="input-sm"
                disabled={disabled}
                name={lowName}
                onChange={onChange}
                placeholder=""
                root={root}
                deep
              />
            </div>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <Input
              className="input-sm"
              disabled={disabled}
              name={highName}
              onChange={onChange}
              placeholder=""
              root={root}
              deep
            />
          </div>
        </div>
      </div>
    );
  }
}

export default class CriteriaRanges extends PureComponent {
  render() {
    const { buyer: root, onChange, disabled } = this.props;

    return (
      <form onChange={onChange}>
        <CriteriaRange buyer={root} disabled={disabled} label="Revenue" name="revenue" onChange={onChange} />
        <CriteriaRange buyer={root} disabled={disabled} label="EBITDA" name="ebitda" onChange={onChange} />
        <CriteriaRange buyer={root} disabled={disabled} label="EV" name="ev" onChange={onChange} />
      </form>
    );
  }
}
