import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import classNames from 'classnames';

import FormGroup from '../helpers/FormGroup';
import _Input from '../helpers/Input';
import _Select from '../helpers/Select';
import DropDown from '../helpers/DropDown/Changable';
import WebInput from '../helpers/WebInput';
import _AutoComplete from '../helpers/AutoComplete';
import PhoneInput from '../helpers/PhoneInput';
import { unwrap } from '../../utils/ChangeSpy';
import PrettyError from '../decorators/PrettyError';
import DisplayError from '../decorators/DisplayError';
import MismissAlert from '../decorators/MismissAlert';
import getTickerExchange from './getTickerExchange';
import canHasParent from './canHasParent';

const Alert = () => (
  <div>
    <p>Please ensure that this address does not contain any of the following:</p>
    <ul>
      <li>abbreviated street labels (e.g. st., Blvd., Ave., etc.)</li>
      <li>abbreviated cardinal directions (i.e. N, S, E, W)</li>
      <li>any other abbreviations, including Ste., etc.</li>
    </ul>
  </div>
);
const Input = PrettyError(DisplayError(_Input));
const Select = PrettyError(DisplayError(_Select));
const AutoComplete = PrettyError(DisplayError(_AutoComplete));
const Address = MismissAlert(_Input, Alert);

/**
 * Stateless Company Information component.
 *
 * @param props {Object}.
 * @param props.targetCompany {Immutable.Map} Map with company information.
 * @param props.onCompanyInfoChange {Function} Handle company info change event.
 * @param props.onToggleDBA {Function} Handle toggle dba input event.
 * @param props.onToggleCountry {Function} Handle toggle country input event.
 * @returns {React.Component}
 * @class
 */
const CompanyInfo = props => {
  const {
    canEditData,
    targetCompany,
    companyInfoProps,
    suggestionProps,
    countrySuggestion,
    onErrorClose,
    error,
    onBlur,
  } = props;

  const { onCompanyInfoChange, onToggleDBA, onToggleCountry, onParentCompanyClick } = companyInfoProps;

  const info = targetCompany.get('info');
  const states = targetCompany.get('states');
  const entityTypes = targetCompany.get('entityTypes').map(type => Map({ name: type, value: type }));
  const companyStates = states.map(state => Map({ name: state, value: state }));
  const parentCompany = targetCompany.get('parentCompany');

  const legalName = info.get('legalName');
  const abbrName = info.get('abbrName');
  const dbaName = info.get('dbaName');
  const web = info.get('web');
  const phone = info.get('phone');
  const address = info.get('address');
  const city = info.get('city');
  const companyState = info.get('state');
  const entityType = info.get('companyType');
  const zip = info.get('zip');
  const showDBA = info.get('showDBA');
  const toggleDBAButton = (
    <i aria-hidden="true" className={classNames('fa', { 'fa-minus': showDBA, 'fa-plus': !showDBA })} />
  );
  const dbaClass = showDBA ? '' : 'hidden';
  const showCountry = info.get('showCountry');
  const parentCompanyClass = canHasParent(entityType) ? '' : 'hidden';
  const isValidAddress = info.get('isValidAddress');
  const verifyAddressContent =
    isValidAddress === null ? (
      <i className="fa fa-spinner fa-spin" style={{ position: 'static' }} />
    ) : isValidAddress ? (
      <i aria-hidden="true" className="fa fa-check" />
    ) : (
      <i aria-hidden="true" className="fa fa-question" />
    );

  const selectedCompany = parentCompany.get('selected');
  const selectedCompanyInfo = selectedCompany ? <div>{parentCompany.getIn(['shortInfoText', 'value'], '')}</div> : null;
  const Ticker = getTickerExchange(info);
  const countryClassName = classNames({ hidden: !showCountry }, 'country-w100p');

  // When DBA is filled, don't allow users to close DBA field
  const dba = unwrap(dbaName);
  const toggleDBAClass = classNames('input-group-addon', {
    'dba-disabled': dba && showDBA,
  });

  const dbaToggle = event => {
    if (dba && showDBA) return;
    onToggleDBA(event);
  };

  return (
    <form className="highlight wrap-border-full" onChange={onCompanyInfoChange}>
      <div className="row">
        <div className="col-lg-12 col-xl-12 col-xxl-12">
          <FormGroup>
            <Input
              className="input-md"
              disabled={!canEditData}
              error={error.legalName}
              id="legalName"
              label="Name"
              name="legalName"
              onBlur={onBlur}
              placeholder="Full Name"
              value={legalName}
              canDisplayError
            />
          </FormGroup>
          <FormGroup className="form-group input-group abbr">
            <Input
              className="input-md"
              disabled={!canEditData}
              id="abbrName"
              label="Name"
              name="abbrName"
              placeholder="Abbreviated Name"
              value={abbrName}
            />
            <span className={toggleDBAClass} onClick={dbaToggle}>
              {toggleDBAButton}
            </span>
          </FormGroup>
          <FormGroup className={dbaClass}>
            <Input
              className="input-md"
              disabled={!canEditData}
              id="dbaName"
              name="dbaName"
              placeholder="DBA/Alt"
              value={dbaName}
            />
          </FormGroup>
          <FormGroup>
            <div className="row">
              <div className="col-md-4">
                <Select
                  className="form-control"
                  disabled={!canEditData}
                  id="dropdownType"
                  label="Type"
                  name="companyType"
                  options={entityTypes}
                  value={entityType}
                />
              </div>
              <div className="col-md-8">
                <Ticker
                  company={info}
                  exchangeName="tickerExchange"
                  onChange={onCompanyInfoChange}
                  symbolName="tickerSymbol"
                />
              </div>
            </div>
          </FormGroup>
          <div className={parentCompanyClass}>
            <AutoComplete {...suggestionProps}>
              <span className="input-group-addon">
                <i aria-hidden="true" className="fa fa-external-link" onClick={onParentCompanyClick} />
              </span>
            </AutoComplete>
            {selectedCompanyInfo}
          </div>
          <WebInput
            disabled={!canEditData}
            id="web"
            maxLength={150}
            name="web"
            placeholder="Web"
            type="web"
            value={web}
          />
          <FormGroup>
            <PhoneInput
              disabled={!canEditData}
              error={error.phone}
              maxLength="30"
              name="phone"
              onBlur={onBlur}
              onErrorClose={event => {
                onErrorClose(event, 'phone');
              }}
              placeholder="Phone"
              value={unwrap(phone)}
            />
          </FormGroup>
          <FormGroup className="addressGroup">
            <Address
              className="input-md"
              disabled={!canEditData}
              id="address"
              name="address"
              placeholder="Address"
              value={address}
            />
            {verifyAddressContent}
          </FormGroup>
          <div className="row flex-grid">
            <div className="col-lg-2 col-xl-2 col-xxl-2 flex-grow1">
              <FormGroup>
                <Input
                  className="input-md"
                  disabled={!canEditData}
                  id="city"
                  name="city"
                  placeholder="City"
                  value={city}
                />
              </FormGroup>
            </div>
            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
              <FormGroup>
                <DropDown
                  className="form-control"
                  disabled={!canEditData}
                  displayAll={false}
                  id="dropdownMenu2"
                  label="State"
                  name="state"
                  onChange={onCompanyInfoChange}
                  options={companyStates}
                  value={companyState}
                  allowEmptyValue
                />
              </FormGroup>
            </div>
            <div className="col-xs-4 col-sm-3 col-md-3 col-lg-4 col-xl-3">
              <FormGroup className="city-index">
                <Input
                  className="input-md"
                  disabled={!canEditData}
                  error={error.zip}
                  id="zip"
                  name="zip"
                  onBlur={onBlur}
                  placeholder="Zip"
                  value={zip}
                  canDisplayError
                />
              </FormGroup>
            </div>
          </div>
          <FormGroup className={countryClassName} title="ONLY enter for countries outside the U.S. and Canada">
            {countrySuggestion}
          </FormGroup>
          <div>
            <i aria-hidden="true" className="fa fa-ellipsis-h pull-right clickable mr2" onClick={onToggleCountry} />
          </div>
        </div>
      </div>
    </form>
  );
};

CompanyInfo.propTypes = {
  targetCompany: PropTypes.instanceOf(Map).isRequired,
};

export default CompanyInfo;
