import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Map } from 'immutable';

import { upperCaseWords } from '../../utils/string';
import DeepValue from '../decorators/DeepValue';
import { CheckboxChangeDetection } from '../decorators/ChangeDetection';
import uniqueId from '../../utils/uniqueId';

/**
 * Checkbox component wrapped into `<div>` (display: block).
 * Label is placed after input and is visible.
 *
 * @param props Some other props available on <input type="checkbox"/>.
 * @param {string} props.id Id.
 * @param {string} props.label Label.
 * @param {string} props.name Name.
 * @param {string} props.className Input's class names.
 * @param {string} props.labelClassName Label's class names.
 * @param {boolean} props.capitalizeLabel=true Set to true to automatically capitalize the label.
 */
const Checkbox = DeepValue(
  CheckboxChangeDetection(props => {
    const {
      id = uniqueId(),
      label,
      name,
      className = '',
      labelClassName = '',
      originalChecked,
      boldOnChecked = false,
      checked: _checked,
      defaultChecked,
      capitalizeLabel = true,
      changed = false,
      canDisplayError,
      ...rest
    } = props;

    const checked = getChecked(props);
    const checkboxLabel = capitalizeLabel ? upperCaseWords(label) : label;

    const wrapperClassName = classNames('checkbox-wrap', {
      changed,
      'text-bold': boldOnChecked && checked.checked,
    });

    return (
      <div className={wrapperClassName}>
        <input {...rest} {...checked} className={className} id={id} name={name} type="checkbox" />
        <label className={labelClassName} htmlFor={id}>
          {checkboxLabel}
        </label>
      </div>
    );
  }),
);

Checkbox.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Map)]),
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default Checkbox;

function getChecked(props) {
  const result = {};

  if ('defaultChecked' in props) {
    result.defaultChecked = props.defaultChecked;
  }

  if ('checked' in props) {
    result.checked = props.checked;
  }

  if (Map.isMap(result.checked)) {
    result.checked = result.checked.get('value');
  }

  return result;
}
