import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import uniqueId from '../../utils/uniqueId';

const SortableItem = Comp => {
  Comp = SortableElement(Comp);

  const Composed = props => {
    const id = uniqueId();

    return <Comp id={id} {...props} />;
  };

  Composed.displayName = `SortableItem(${Comp.name || Comp.displayName})`;

  return Composed;
};

export { SortableItem };
