import { all } from 'bluebird';
import { CALL_API } from '../middleware/api';

export const FETCHING_TAGS = Symbol('FETCHING_TAGS');
export const FETCHED_TAGS_FAIL = Symbol('FETCHED_TAGS_FAIL');
export const FETCHED_TAGS_SUCCESS = Symbol('FETCHED_TAGS_SUCCESS');

export const FETCHING_TAG_TYPE = Symbol('FETCHING_TAG_TYPE');
export const FETCHED_TAG_TYPE_FAIL = Symbol('FETCHED_TAG_TYPE_FAIL');
export const FETCHED_TAG_TYPE_SUCCESS = Symbol('FETCHED_TAG_TYPE_SUCCESS');

export const FETCHING_PARENT_TAGS = Symbol('FETCHING_PARENT_TAGS');
export const FETCHED_PARENT_TAGS_FAIL = Symbol('FETCHED_PARENT_TAGS_FAIL');
export const FETCHED_PARENT_TAGS_SUCCESS = Symbol('FETCHED_PARENT_TAGS_SUCCESS');

export const DELETE_TAG_SUCCESS = Symbol('DELETE_TAG_SUCCESS');
export const TAG_STATE_UPDATE = Symbol('TAG_STATE_UPDATE');
export const TAG_LOOKUP = Symbol('TAG_LOOKUP');
export const TAG_SORT = Symbol('TAG_SORT');

export const sortTag = field => ({ type: TAG_SORT, field });

export const fetchTags = () => dispatch =>
  all([
    dispatch({
      [CALL_API]: {
        method: 'get',
        path: '/api/v1/industry_categories?include_business_model=1',
        startType: FETCHING_TAGS,
        successType: FETCHED_TAGS_SUCCESS,
        errorType: FETCHED_TAGS_FAIL,
      },
    }),
    dispatch({
      [CALL_API]: {
        method: 'get',
        path: '/api/v1/browse/tag_types',
        startType: FETCHING_TAG_TYPE,
        successType: FETCHED_TAG_TYPE_SUCCESS,
        errorType: FETCHED_TAG_TYPE_FAIL,
      },
    }),
    dispatch({
      [CALL_API]: {
        method: 'get',
        path: '/api/v1/industry_categories/parents',
        startType: FETCHING_PARENT_TAGS,
        successType: FETCHED_PARENT_TAGS_SUCCESS,
        errorType: FETCHED_PARENT_TAGS_SUCCESS,
      },
    }),
  ]);

export const lookup = (types, parent) => ({ type: TAG_LOOKUP, types, parent });

export const update = (field, value) => ({
  type: TAG_STATE_UPDATE,
  field,
  value,
});

export const fetchTagTypes = () => ({
  [CALL_API]: {
    method: 'get',
    path: '/api/v1/browse/tag_types',
    successType: FETCHED_TAG_TYPE_SUCCESS,
  },
});

export const deleteTag = id => ({
  id,
  [CALL_API]: {
    method: 'delete',
    path: `/api/v1/industry_categories/${id}`,
    successType: DELETE_TAG_SUCCESS,
  },
});

export const saveTag = ({ id, category, tagTypeId, parentId, afterSuccess, afterError }) => {
  const api = {
    method: 'post',
    path: '/api/v1/industry_categories',
    body: { category, tagTypeId, parentId },
    skipGlobalErrorHandler: true,
    afterSuccess,
    afterError,
  };

  if (id) {
    api.method = 'put';
    api.path = `/api/v1/industry_categories/${id}`;
  }

  return { [CALL_API]: api };
};
