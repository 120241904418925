import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import chunk from 'lodash/chunk';

import { toJS } from 'src/utils/ChangeSpy';

const STATES_TABLE_COLUMNS = 4;

const DealsByStateTable = ({ data }) => {
  const statsData = toJS(data.getIn(['dealsClosedByState', 'statesCounts']));

  const rowsCount = Math.ceil(statsData?.length / STATES_TABLE_COLUMNS);
  const statsDataByColumns = chunk(statsData, rowsCount);

  const statsContent = useMemo(() => {
    const transformStats = [];

    for (let i = 0; i < rowsCount; i++) {
      transformStats.push(statsDataByColumns.map(item => item[i]));
    }

    return transformStats;
  }, [rowsCount, statsDataByColumns]);

  return (
    <div className="dashboard-table-wrapper mb20">
      <table className="dashboard-table table-striped-white deals-by-state fixed-layout">
        <thead>
          <tr className="bg-black text-white">
            <th className="pl10" colSpan="4">
              <p className="h5 text-bold">Deals Closed By State</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {statsContent.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => {
                if (cell === undefined) {
                  return <td key={cellIndex} className="p10" />;
                }

                return (
                  <td key={cellIndex} className="h5 p10">
                    <span className="state-title">{cell.state}</span>
                    <span className="state-dot" />
                    <span className="text-bold state-stats">{cell.count}</span>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

DealsByStateTable.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
};

export default DealsByStateTable;
