import React from 'react';

import _Input from '../helpers/Input';
import _Textarea from '../helpers/Textarea';
import PrettyError from '../decorators/PrettyError';
import DisplayError from '../decorators/DisplayError';
import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import FormGroup from '../helpers/FormGroup';
import { InputProps } from '../helpers/AutoComplete';

const Input = PrettyError(DisplayError(_Input));
const Textarea = PrettyError(DisplayError(_Textarea));

const OneOffApproachPopup = props => {
  const {
    oneOff,
    countTargets,
    loadingCountTargets,
    isValid,
    loading,
    suggestModule,
    suggestAnalyst,
    suggestBuyer,
    suggestProject,
    onChange,
    onSave,
    onClose,
    onCountTargets,
  } = props;
  const disabledButton = !isValid || loading;
  const header = <div>Create One-off Approach</div>;
  const footer = (
    <Button className="btn-primary btn-xs" disabled={disabledButton} onClick={onSave}>
      Create
    </Button>
  );

  return (
    <Popup footer={footer} header={header} id="oneOffApproach" onClose={onClose}>
      <div className="row">
        <FormGroup className="col-md-6">
          <Input
            className="input-sm"
            label="Harvey Tag"
            name="tag"
            onBlur={onCountTargets}
            onChange={onChange}
            root={oneOff}
            autoFocus
            canDisplayError
            deep
            required
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-md-6">
          <label>Targets</label>
          <div>
            <Button className="btn-xs" disabled={loadingCountTargets} onClick={onCountTargets}>
              <i aria-hidden="true" className="fa fa-refresh mr0" />
            </Button>
            {countTargets}
          </div>
        </FormGroup>
        <FormGroup className="col-md-6">
          <InputProps className="form-control input-sm text-bold" name="suggestBuyer">
            {suggestBuyer}
          </InputProps>
        </FormGroup>
        <FormGroup className="col-md-6">
          <InputProps className="form-control input-sm text-bold" name="suggestModule">
            {suggestModule}
          </InputProps>
        </FormGroup>
        <FormGroup className="col-md-6">
          <InputProps className="form-control input-sm text-bold" name="suggestProject">
            {suggestProject}
          </InputProps>
        </FormGroup>
        <FormGroup className="col-md-6">
          <InputProps className="form-control input-sm text-bold" name="suggestAnalyst">
            {suggestAnalyst}
          </InputProps>
        </FormGroup>
        <FormGroup className="col-md-12">
          <Textarea
            label="Description"
            name="description"
            onChange={onChange}
            placeholder=""
            root={oneOff}
            rows="4"
            deep
            showLabel
          />
        </FormGroup>
      </div>
    </Popup>
  );
};

export default OneOffApproachPopup;
