import { List, Map } from 'immutable';
import { spy, setIn, setOriginalIn, getIn, revert } from '../../../../utils/ChangeSpy';
import {
  LOADED_USERS_SUCCESS,
  SELECT_USER_SUGGESTION,
  SAVED_USERS_SUCCESS,
  DELETED_USER_SUCCESS,
  ADD_EMPTY_USER,
  REMOVE_EMPTY_USER,
} from '../../../../actions/company/buyer/addOnProject/suggestion';
import { REVERT_CHANGES } from '../../../../actions/company/buyer/addOnProject/main';

const FIELDS_TO_SPY = ['id', 'text'];
const defaultState = List();

export default function(state = defaultState, action) {
  switch (action.type) {
    case LOADED_USERS_SUCCESS:
      return List(
        action.response.data.users.map((ind, index) =>
          spy(
            Map({
              index,
              id: ind.id,
              text: ind.userName,
            }),
            FIELDS_TO_SPY,
          ),
        ),
      );

    case SELECT_USER_SUGGESTION: {
      let { rowIndex } = action;
      let tmpState = state;

      if (!state.get(rowIndex, null)) {
        tmpState = tmpState.push(
          spy(
            Map({
              index: state.size,
              id: null,
              text: '',
            }),
            FIELDS_TO_SPY,
          ),
        );
        rowIndex = tmpState.size - 1;
      }
      tmpState = setIn(tmpState, `${rowIndex}.id`, action.id);

      return setIn(tmpState, `${rowIndex}.text`, action.text);
    }

    case SAVED_USERS_SUCCESS:
      return state.map(ind => {
        const temp = setOriginalIn(ind, 'id', getIn(ind, 'id'));

        return setOriginalIn(temp, 'text', getIn(temp, 'text'));
      });

    case DELETED_USER_SUCCESS: {
      const index = state.findKey(i => getIn(i, 'id') === action.id);

      if (index !== undefined && index !== null) {
        return state.delete(index);
      }

      return state;
    }

    case ADD_EMPTY_USER: {
      const { size } = state;

      return state.push(
        spy(
          Map({
            index: size,
            id: null,
            text: '',
          }),
          FIELDS_TO_SPY,
        ),
      );
    }

    case REMOVE_EMPTY_USER:
      return state.filter(u => getIn(u, 'id'));

    case REVERT_CHANGES:
      return revert(state);

    default:
      return state;
  }
}
